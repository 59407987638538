let polygonCoords_TresRios = [
  {
    lng: -43.2590659,
    lat: -22.1024762
  },
  {
    lng: -43.2498465,
    lat: -22.0971998
  },
  {
    lng: -43.2140137,
    lat: -22.0666062
  },
  {
    lng: -43.2128073,
    lat: -22.0662512
  },
  {
    lng: -43.1987557,
    lat: -22.0621198
  },
  {
    lng: -43.142932,
    lat: -22.0456948
  },
  {
    lng: -43.1407354,
    lat: -22.0390415
  },
  {
    lng: -43.1388487,
    lat: -22.0389634
  },
  {
    lng: -43.1308951,
    lat: -22.0354932
  },
  {
    lng: -43.1308737,
    lat: -22.0364685
  },
  {
    lng: -43.129775,
    lat: -22.0378241
  },
  {
    lng: -43.1299057,
    lat: -22.0392059
  },
  {
    lng: -43.1294749,
    lat: -22.0398947
  },
  {
    lng: -43.1300169,
    lat: -22.041928
  },
  {
    lng: -43.1300395,
    lat: -22.0434461
  },
  {
    lng: -43.1289999,
    lat: -22.0447879
  },
  {
    lng: -43.1282622,
    lat: -22.0455256
  },
  {
    lng: -43.1276826,
    lat: -22.0457579
  },
  {
    lng: -43.127449,
    lat: -22.0459794
  },
  {
    lng: -43.1274702,
    lat: -22.0464974
  },
  {
    lng: -43.1293333,
    lat: -22.0478899
  },
  {
    lng: -43.1306533,
    lat: -22.0487946
  },
  {
    lng: -43.1312579,
    lat: -22.0496225
  },
  {
    lng: -43.1333787,
    lat: -22.0509851
  },
  {
    lng: -43.133604,
    lat: -22.0517031
  },
  {
    lng: -43.1335487,
    lat: -22.0520658
  },
  {
    lng: -43.1343973,
    lat: -22.0523728
  },
  {
    lng: -43.1350959,
    lat: -22.0525023
  },
  {
    lng: -43.1350065,
    lat: -22.0532379
  },
  {
    lng: -43.1336484,
    lat: -22.0533208
  },
  {
    lng: -43.1336763,
    lat: -22.0539838
  },
  {
    lng: -43.1345859,
    lat: -22.054687
  },
  {
    lng: -43.1349494,
    lat: -22.054892
  },
  {
    lng: -43.1349811,
    lat: -22.0554598
  },
  {
    lng: -43.1348467,
    lat: -22.055837
  },
  {
    lng: -43.1353091,
    lat: -22.0562876
  },
  {
    lng: -43.1354039,
    lat: -22.0566941
  },
  {
    lng: -43.1346609,
    lat: -22.056533
  },
  {
    lng: -43.1336374,
    lat: -22.0564927
  },
  {
    lng: -43.1335425,
    lat: -22.0565916
  },
  {
    lng: -43.1334832,
    lat: -22.0573059
  },
  {
    lng: -43.1331473,
    lat: -22.0577893
  },
  {
    lng: -43.1332342,
    lat: -22.0583169
  },
  {
    lng: -43.1416809,
    lat: -22.0660648
  },
  {
    lng: -43.1422702,
    lat: -22.0665133
  },
  {
    lng: -43.1449365,
    lat: -22.0685423
  },
  {
    lng: -43.1501659,
    lat: -22.0711598
  },
  {
    lng: -43.1528234,
    lat: -22.0739529
  },
  {
    lng: -43.1537037,
    lat: -22.0774957
  },
  {
    lng: -43.1509334,
    lat: -22.0795834
  },
  {
    lng: -43.1497998,
    lat: -22.0815812
  },
  {
    lng: -43.1506032,
    lat: -22.0881279
  },
  {
    lng: -43.1477711,
    lat: -22.0892917
  },
  {
    lng: -43.1471805,
    lat: -22.0895344
  },
  {
    lng: -43.1469898,
    lat: -22.0896129
  },
  {
    lng: -43.1459466,
    lat: -22.093692
  },
  {
    lng: -43.144597,
    lat: -22.1000709
  },
  {
    lng: -43.1401615,
    lat: -22.1059841
  },
  {
    lng: -43.1385212,
    lat: -22.1081709
  },
  {
    lng: -43.1361254,
    lat: -22.1091843
  },
  {
    lng: -43.131184,
    lat: -22.1076395
  },
  {
    lng: -43.129463,
    lat: -22.1068204
  },
  {
    lng: -43.1269927,
    lat: -22.1047284
  },
  {
    lng: -43.1264378,
    lat: -22.103442
  },
  {
    lng: -43.1262166,
    lat: -22.1005274
  },
  {
    lng: -43.1258529,
    lat: -22.0982864
  },
  {
    lng: -43.1249008,
    lat: -22.0965085
  },
  {
    lng: -43.1232118,
    lat: -22.0949526
  },
  {
    lng: -43.1224956,
    lat: -22.0937096
  },
  {
    lng: -43.1221155,
    lat: -22.0920109
  },
  {
    lng: -43.1226074,
    lat: -22.0911202
  },
  {
    lng: -43.1225292,
    lat: -22.0900119
  },
  {
    lng: -43.1208077,
    lat: -22.0889451
  },
  {
    lng: -43.1187028,
    lat: -22.0879688
  },
  {
    lng: -43.1164581,
    lat: -22.0850684
  },
  {
    lng: -43.114972,
    lat: -22.0844532
  },
  {
    lng: -43.1123637,
    lat: -22.0842335
  },
  {
    lng: -43.1085065,
    lat: -22.083252
  },
  {
    lng: -43.1061353,
    lat: -22.0829883
  },
  {
    lng: -43.1045945,
    lat: -22.0833273
  },
  {
    lng: -43.1034794,
    lat: -22.0844678
  },
  {
    lng: -43.1021515,
    lat: -22.0850684
  },
  {
    lng: -43.0995115,
    lat: -22.0850538
  },
  {
    lng: -43.0981204,
    lat: -22.0855225
  },
  {
    lng: -43.0969506,
    lat: -22.0862256
  },
  {
    lng: -43.095686,
    lat: -22.0865333
  },
  {
    lng: -43.0890698,
    lat: -22.0874615
  },
  {
    lng: -43.0866682,
    lat: -22.0872615
  },
  {
    lng: -43.0775968,
    lat: -22.0836262
  },
  {
    lng: -43.0760634,
    lat: -22.0839313
  },
  {
    lng: -43.0752252,
    lat: -22.0848325
  },
  {
    lng: -43.0750285,
    lat: -22.0857672
  },
  {
    lng: -43.0754309,
    lat: -22.0874659
  },
  {
    lng: -43.0747977,
    lat: -22.0909391
  },
  {
    lng: -43.074349,
    lat: -22.0919897
  },
  {
    lng: -43.0737109,
    lat: -22.0928573
  },
  {
    lng: -43.0717407,
    lat: -22.0923998
  },
  {
    lng: -43.0688002,
    lat: -22.0907007
  },
  {
    lng: -43.0657493,
    lat: -22.0896754
  },
  {
    lng: -43.0623823,
    lat: -22.087892
  },
  {
    lng: -43.0603586,
    lat: -22.0873903
  },
  {
    lng: -43.0585724,
    lat: -22.0863209
  },
  {
    lng: -43.0572563,
    lat: -22.0848733
  },
  {
    lng: -43.0550116,
    lat: -22.0839944
  },
  {
    lng: -43.0527409,
    lat: -22.082052
  },
  {
    lng: -43.0517392,
    lat: -22.080757
  },
  {
    lng: -43.049131,
    lat: -22.0790724
  },
  {
    lng: -43.0454159,
    lat: -22.0750292
  },
  {
    lng: -43.0438036,
    lat: -22.0738426
  },
  {
    lng: -43.0413848,
    lat: -22.0732566
  },
  {
    lng: -43.0396291,
    lat: -22.0717063
  },
  {
    lng: -43.0368544,
    lat: -22.0691183
  },
  {
    lng: -43.032855,
    lat: -22.0662176
  },
  {
    lng: -43.0325481,
    lat: -22.0625918
  },
  {
    lng: -43.0321815,
    lat: -22.059747
  },
  {
    lng: -43.036026,
    lat: -22.0565262
  },
  {
    lng: -43.0381285,
    lat: -22.0525264
  },
  {
    lng: -43.0388714,
    lat: -22.0506216
  },
  {
    lng: -43.0393456,
    lat: -22.048878
  },
  {
    lng: -43.0395196,
    lat: -22.0467827
  },
  {
    lng: -43.0393141,
    lat: -22.0450245
  },
  {
    lng: -43.0382865,
    lat: -22.0441013
  },
  {
    lng: -43.0357887,
    lat: -22.0433687
  },
  {
    lng: -43.0331962,
    lat: -22.0434713
  },
  {
    lng: -43.0292916,
    lat: -22.041332
  },
  {
    lng: -43.0262406,
    lat: -22.0416983
  },
  {
    lng: -43.0242803,
    lat: -22.0411122
  },
  {
    lng: -43.0187316,
    lat: -22.037654
  },
  {
    lng: -43.0170559,
    lat: -22.0369214
  },
  {
    lng: -43.0130248,
    lat: -22.0338734
  },
  {
    lng: -43.0106377,
    lat: -22.0325106
  },
  {
    lng: -43.0074603,
    lat: -22.0311478
  },
  {
    lng: -43.0055,
    lat: -22.0308108
  },
  {
    lng: -43.004234,
    lat: -22.0309845
  },
  {
    lng: -43.002629,
    lat: -22.0319015
  },
  {
    lng: -43.009727,
    lat: -22.0393948
  },
  {
    lng: -43.0084859,
    lat: -22.0415187
  },
  {
    lng: -43.0083168,
    lat: -22.0429343
  },
  {
    lng: -43.0091295,
    lat: -22.0443429
  },
  {
    lng: -43.0085709,
    lat: -22.0451518
  },
  {
    lng: -43.0048418,
    lat: -22.0466345
  },
  {
    lng: -43.0042651,
    lat: -22.0486829
  },
  {
    lng: -43.0055434,
    lat: -22.0513254
  },
  {
    lng: -43.0105987,
    lat: -22.0559826
  },
  {
    lng: -43.0084904,
    lat: -22.0605845
  },
  {
    lng: -43.0096832,
    lat: -22.0624726
  },
  {
    lng: -43.0077115,
    lat: -22.0664418
  },
  {
    lng: -43.0077595,
    lat: -22.0716598
  },
  {
    lng: -43.0098209,
    lat: -22.0744879
  },
  {
    lng: -43.0110126,
    lat: -22.0751373
  },
  {
    lng: -43.0131812,
    lat: -22.0749965
  },
  {
    lng: -43.0129332,
    lat: -22.0801504
  },
  {
    lng: -43.0142043,
    lat: -22.0831473
  },
  {
    lng: -43.010882,
    lat: -22.0834387
  },
  {
    lng: -42.9688695,
    lat: -22.0882665
  },
  {
    lng: -42.9709523,
    lat: -22.0911854
  },
  {
    lng: -42.9714312,
    lat: -22.0942482
  },
  {
    lng: -42.9728687,
    lat: -22.0966887
  },
  {
    lng: -42.9727312,
    lat: -22.0987104
  },
  {
    lng: -42.9712687,
    lat: -22.0995132
  },
  {
    lng: -42.9703176,
    lat: -22.1019712
  },
  {
    lng: -42.9673745,
    lat: -22.1051537
  },
  {
    lng: -42.9669873,
    lat: -22.1108243
  },
  {
    lng: -42.965512,
    lat: -22.1127659
  },
  {
    lng: -42.9658207,
    lat: -22.1176168
  },
  {
    lng: -42.9643434,
    lat: -22.1266179
  },
  {
    lng: -42.961012,
    lat: -22.1300587
  },
  {
    lng: -42.9603157,
    lat: -22.1322312
  },
  {
    lng: -42.9568418,
    lat: -22.1353057
  },
  {
    lng: -42.9559868,
    lat: -22.1376895
  },
  {
    lng: -42.9740757,
    lat: -22.1429209
  },
  {
    lng: -43.0012112,
    lat: -22.1507659
  },
  {
    lng: -43.0021512,
    lat: -22.1506762
  },
  {
    lng: -43.0033168,
    lat: -22.149194
  },
  {
    lng: -43.0087934,
    lat: -22.1488984
  },
  {
    lng: -43.0114337,
    lat: -22.1474615
  },
  {
    lng: -43.011709,
    lat: -22.1466765
  },
  {
    lng: -43.0134468,
    lat: -22.1468134
  },
  {
    lng: -43.0168712,
    lat: -22.1482582
  },
  {
    lng: -43.0209537,
    lat: -22.1614987
  },
  {
    lng: -43.023124,
    lat: -22.1649315
  },
  {
    lng: -43.0259307,
    lat: -22.1673284
  },
  {
    lng: -43.0265454,
    lat: -22.1701323
  },
  {
    lng: -43.0313165,
    lat: -22.1754679
  },
  {
    lng: -43.032434,
    lat: -22.1789648
  },
  {
    lng: -43.0367104,
    lat: -22.1844273
  },
  {
    lng: -43.0376507,
    lat: -22.1869498
  },
  {
    lng: -43.0403154,
    lat: -22.1890332
  },
  {
    lng: -43.0394623,
    lat: -22.1919726
  },
  {
    lng: -43.0412065,
    lat: -22.1932834
  },
  {
    lng: -43.0445407,
    lat: -22.1994234
  },
  {
    lng: -43.0477257,
    lat: -22.2072773
  },
  {
    lng: -43.0587309,
    lat: -22.1893715
  },
  {
    lng: -43.0565698,
    lat: -22.1881684
  },
  {
    lng: -43.0556262,
    lat: -22.1857073
  },
  {
    lng: -43.0510912,
    lat: -22.1817976
  },
  {
    lng: -43.0460637,
    lat: -22.1792004
  },
  {
    lng: -43.0423684,
    lat: -22.1792987
  },
  {
    lng: -43.0419026,
    lat: -22.1750018
  },
  {
    lng: -43.0396218,
    lat: -22.173344
  },
  {
    lng: -43.0386268,
    lat: -22.1705415
  },
  {
    lng: -43.0396004,
    lat: -22.1686632
  },
  {
    lng: -43.0365687,
    lat: -22.1681493
  },
  {
    lng: -43.0344284,
    lat: -22.1664393
  },
  {
    lng: -43.035017,
    lat: -22.1651315
  },
  {
    lng: -43.0377107,
    lat: -22.1630123
  },
  {
    lng: -43.0390459,
    lat: -22.1627176
  },
  {
    lng: -43.0486868,
    lat: -22.164229
  },
  {
    lng: -43.0520143,
    lat: -22.1654298
  },
  {
    lng: -43.0549884,
    lat: -22.1643173
  },
  {
    lng: -43.0575143,
    lat: -22.1669332
  },
  {
    lng: -43.0709523,
    lat: -22.1737251
  },
  {
    lng: -43.0726551,
    lat: -22.1763159
  },
  {
    lng: -43.0781512,
    lat: -22.1767782
  },
  {
    lng: -43.0809309,
    lat: -22.1807407
  },
  {
    lng: -43.0849029,
    lat: -22.1817884
  },
  {
    lng: -43.0872962,
    lat: -22.1833818
  },
  {
    lng: -43.090322,
    lat: -22.1822337
  },
  {
    lng: -43.0932304,
    lat: -22.1830695
  },
  {
    lng: -43.0944254,
    lat: -22.1848343
  },
  {
    lng: -43.0948834,
    lat: -22.1908309
  },
  {
    lng: -43.097732,
    lat: -22.1923751
  },
  {
    lng: -43.1008254,
    lat: -22.1921423
  },
  {
    lng: -43.103954,
    lat: -22.1919076
  },
  {
    lng: -43.1062876,
    lat: -22.1981423
  },
  {
    lng: -43.1084882,
    lat: -22.1994723
  },
  {
    lng: -43.1140962,
    lat: -22.2001204
  },
  {
    lng: -43.1190498,
    lat: -22.1991498
  },
  {
    lng: -43.1210159,
    lat: -22.198534
  },
  {
    lng: -43.1253759,
    lat: -22.1938643
  },
  {
    lng: -43.1307912,
    lat: -22.1908676
  },
  {
    lng: -43.1317351,
    lat: -22.1909834
  },
  {
    lng: -43.1333687,
    lat: -22.1933376
  },
  {
    lng: -43.1354295,
    lat: -22.1943548
  },
  {
    lng: -43.1419251,
    lat: -22.1940876
  },
  {
    lng: -43.1448498,
    lat: -22.1924968
  },
  {
    lng: -43.1544637,
    lat: -22.1906232
  },
  {
    lng: -43.1570134,
    lat: -22.1865734
  },
  {
    lng: -43.1593754,
    lat: -22.1852137
  },
  {
    lng: -43.163982,
    lat: -22.1850665
  },
  {
    lng: -43.1660893,
    lat: -22.1859876
  },
  {
    lng: -43.167569,
    lat: -22.1868737
  },
  {
    lng: -43.1978479,
    lat: -22.2004759
  },
  {
    lng: -43.2006579,
    lat: -22.1774748
  },
  {
    lng: -43.2376426,
    lat: -22.1443426
  },
  {
    lng: -43.2425118,
    lat: -22.1399784
  },
  {
    lng: -43.2452515,
    lat: -22.1375223
  },
  {
    lng: -43.2482934,
    lat: -22.1347951
  },
  {
    lng: -43.2497368,
    lat: -22.1308695
  },
  {
    lng: -43.2501604,
    lat: -22.1297165
  },
  {
    lng: -43.2590659,
    lat: -22.1024762
  }
];

export default polygonCoords_TresRios;