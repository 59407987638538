import React, { Component, forwardRef } from 'react';
import api from '../services/api';
import apiString from '../services/apiString';

import ReactDOM from 'react-dom';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import ArrowBack from '@material-ui/icons/ArrowBack';
import SkipNext from '@material-ui/icons/SkipNext';
import Done from '@material-ui/icons/Done';
import DoneAll from '@material-ui/icons/DoneAll';
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';
import Save from '@material-ui/icons/Save';
import Publish from '@material-ui/icons/Publish';

import Dropzone from 'react-dropzone';

import theme from '../themes/theme';
import { ThemeProvider } from '@material-ui/core';

import Header from '../components/Header';
import FooterHome from '../components/FooterHome';

import UrbanProjectReport from '../pages/reports/UrbanProjectReport';

import { Map, GoogleApiWrapper, Marker, Polygon } from 'google-maps-react';
import Geocode from "react-geocode";
import { Refresh } from '@material-ui/icons';

import html2canvas from 'html2canvas';

import { getAreaOfPolygon } from 'geolib';
import { isThisSecond } from 'date-fns';
Geocode.setApiKey("AIzaSyDC6df7s7nceeG2sHhFGu0-nzLppDiHpe0");
Geocode.setLanguage("pt-BR");

let generatedPdf = null;

export class UrbanProject2 extends Component {

  state = {
    login: this.props.location.state.fields.login,
    option: this.props.location.state.fields.option,
    user_type: this.props.location.state.fields.user_type,
    selected_area: this.props.location.state.fields.selected_area,
    selected_city: this.props.location.state.fields.selected_city,

    area_id: '',
    area_name: '',
    area: '',
    cep: '',
    street: '',
    number: '',
    district: '',
    city: '',
    state: '',
    description: '',
    latitude: this.props.location.state.fields.latitude,
    longitude: this.props.location.state.fields.longitude,
    polygonCoords: this.props.location.state.fields.polygonCoords,

    file: null,

    mapPreview: null,
    mapImage: null,
    preview: null,
    preview2: null,
    preview3: null,
    preview4: null,
    preview5: null,
    preview6: null,
    preview7: null,
    preview8: null,
    preview9: null,
    preview10: null,
    preview11: null,

    mapImageDescription: 'Imagem de satélite da área selecionada: ' + this.props.location.state.fields.area_name,

    steps: ['Objeto', 'Situação', 'Localização', 'Justificativa', 'Infraestrutura', 'Serviço de transporte coletivo', 
    'Rede de água potável', 'Rede de drenagem de águas pluviais', 'Sistema de coleta e tratamento do esgotamento sanitário', 
    'Rede de energia elétrica', 'Rede de telecomunicações', 'Coleta de resíduos sólidos', 'Unidade de saúde', 'Praça pública', 
    'Vias e acessibilidade'],
    activeStep: 0,
    completed: {},

    form1_title: '',
    form1_description: '',
    form1_informations: '',
    form1_complement: '',

    form2_area: '',
    form2_blocks: '',
    form2_plots: '',
    form2_complement: '',

    form4_justification: '',

    form5_description: '',

    form6_description: '',

    form7_description: '',

    form8_description: '',

    form9_description: '',

    form10_description: '',

    form11_description: '',

    form12_description: '',

    form13_description: '',

    form14_description: '',

    form15_description: '',

    open3: false,
    mapRef: React.createRef(),
  }

  async componentDidMount() {

    if (this.props.location.state) {
      await this.setState({ login: this.props.location.state.fields.login });
      await this.setState({ option: this.props.location.state.fields.option });
      await this.setState({ user_type: this.props.location.state.fields.user_type });
      await this.setState({ selected_area: this.props.location.state.fields.selected_area });
      await this.setState({ selected_city: this.props.location.state.fields.selected_city });
      await this.setState({ area_id: this.props.location.state.fields.area_id });
      await this.setState({ area_name: this.props.location.state.fields.area_name });
      await this.setState({ area: this.props.location.state.fields.area });
      await this.setState({ form2_area: this.state.area });
      await this.setState({ cep: this.props.location.state.fields.cep });
      await this.setState({ street: this.props.location.state.fields.street });
      await this.setState({ number: this.props.location.state.fields.number });
      await this.setState({ district: this.props.location.state.fields.district });
      await this.setState({ city: this.props.location.state.fields.city });
      await this.setState({ state: this.props.location.state.fields.state });
      await this.setState({ description: this.props.location.state.fields.description });
      await this.setState({ latitude: this.props.location.state.fields.latitude });
      await this.setState({ longitude: this.props.location.state.fields.longitude });
      await this.setState({ polygonCoords: this.props.location.state.fields.polygonCoords });
    }

    let polygonCoords = `${this.state.polygonCoords}]`;
    let polygonCoordsJSON = JSON.parse(polygonCoords);
    this.setState({ polygonCoords: polygonCoordsJSON });

    //alert(JSON.stringify(this.state.polygonCoords));

  }

  totalSteps = () => {
    return this.state.steps.length;
  }

  completedSteps = () => {
    return Object.keys(this.state.completed).length;
  }

  isLastStep = () => {
    return this.state.activeStep === this.totalSteps() - 1;
  }

  allStepsCompleted = () => {
    return this.completedSteps() === this.totalSteps();
  }

  handleNext = () => {
    const newActiveStep =
      this.isLastStep() && !this.allStepsCompleted()
        ?
        this.state.steps.findIndex((step, i) => !(i in this.state.completed))
        : this.state.activeStep + 1;
    this.setState({ activeStep: newActiveStep });
    document.body.scrollTop = 0; 
    document.documentElement.scrollTop = 0; 
  }

  handleBack = async () => {
    this.setState({ activeStep: this.state.activeStep - 1 });
  }

  handleStep = (step) => () => {
    this.setState({ activeStep: step });
  }

  handleComplete = () => {
    const newCompleted = this.state.completed;
    newCompleted[this.state.activeStep] = true;
    this.setState({ completed: newCompleted });
    document.body.scrollTop = 0; 
    document.documentElement.scrollTop = 0; 
    this.handleNext();
  }

  handleReset = () => {
    this.setState({ activeStep: 0 });
    this.setState({ completed: {} });
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleDrop = async (files) => {

    const file = files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      this.setState({ preview: reader.result });
    };

    if (file) {
      reader.readAsDataURL(file);
    }

  }

  handleDrop2 = async (files) => {

    const file = files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      this.setState({ preview2: reader.result });
    };

    if (file) {
      reader.readAsDataURL(file);
    }

  }

  handleDrop3 = async (files) => {

    const file = files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      this.setState({ preview3: reader.result });
    };

    if (file) {
      reader.readAsDataURL(file);
    }

  }

  handleDrop4 = async (files) => {

    const file = files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      this.setState({ preview4: reader.result });
    };

    if (file) {
      reader.readAsDataURL(file);
    }

  }

  handleDrop5 = async (files) => {

    const file = files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      this.setState({ preview5: reader.result });
    };

    if (file) {
      reader.readAsDataURL(file);
    }

  }

  handleDrop6 = async (files) => {

    const file = files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      this.setState({ preview6: reader.result });
    };

    if (file) {
      reader.readAsDataURL(file);
    }

  }

  handleDrop7 = async (files) => {

    const file = files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      this.setState({ preview7: reader.result });
    };

    if (file) {
      reader.readAsDataURL(file);
    }

  }

  handleDrop8 = async (files) => {

    const file = files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      this.setState({ preview8: reader.result });
    };

    if (file) {
      reader.readAsDataURL(file);
    }

  }

  handleDrop9 = async (files) => {

    const file = files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      this.setState({ preview9: reader.result });
    };

    if (file) {
      reader.readAsDataURL(file);
    }

  }

  handleDrop10 = async (files) => {

    const file = files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      this.setState({ preview10: reader.result });
    };

    if (file) {
      reader.readAsDataURL(file);
    }

  }

  handleDrop11 = async (files) => {

    const file = files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      this.setState({ preview11: reader.result });
    };

    if (file) {
      reader.readAsDataURL(file);
    }

  }

  getStepContent = (step) => {

    switch (step) {

      case 0:
        return (

          <div>

            <div style={styles.containerStep}>
              <Typography variant="h6" align="left">
                {'Objeto'}
              </Typography>

              <Typography variant="subtitle1" align="left">
                {'Preencha os campos abaixo com os dados que serão utilizados para a construção do projeto urbanístico de sua área.'}
              </Typography>
            </div>

            <div style={styles.containerStep}>

              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="form1_title"
                  id="form1_title"
                  label="Título do projeto"
                  type="text"
                  style={styles.textField2}
                  onChange={this.handleChange}
                  value={this.state.form1_title}
                />

                <TextField
                  variant="outlined"
                  margin="normal"
                  maxRows={10}
                  multiline
                  required
                  fullWidth
                  name="form1_description"
                  id="form1_description"
                  label="Descrição do projeto"
                  type="text"
                  style={styles.textField2}
                  onChange={this.handleChange}
                  value={this.state.form1_description}
                />

                <TextField
                  variant="outlined"
                  margin="normal"
                  maxRows={10}
                  multiline
                  fullWidth
                  name="form1_informations"
                  id="form1_informations"
                  label="Informações gerais"
                  type="text"
                  onChange={this.handleChange}
                  value={this.state.form1_informations}
                />
              </div>

              <TextField
                variant="outlined"
                margin="normal"
                maxRows={10}
                multiline
                fullWidth
                name="form1_complement"
                id="form1_complement"
                label="Complemento"
                type="text"
                onChange={this.handleChange}
                value={this.state.form1_complement}
              />

            </div>

          </div>

        );

      case 1:
        return (

          <div>

            <div style={styles.containerStep}>
              <Typography variant="h6" align="left">
                {'Situação'}
              </Typography>

              <Typography variant="subtitle1" align="left">
                {'Preencha os campos abaixo com os dados que serão utilizados para a construção do projeto urbanístico de sua área.'}
              </Typography>
            </div>

            <div style={styles.containerStep}>

              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  required
                  disabled
                  name="form2_area"
                  id="form2_area"
                  label="Área"
                  style={styles.textField2}
                  onChange={this.handleChange}
                  value={this.state.form2_area}
                />

                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="form2_blocks"
                  id="form2_blocks"
                  label="Quadras"
                  type="number"
                  style={styles.textField2}
                  onChange={this.handleChange}
                  value={this.state.form2_blocks}
                />

                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="form2_plots"
                  id="form2_plots"
                  label="Lotes"
                  type="number"
                  style={styles.textField2}
                  onChange={this.handleChange}
                  value={this.state.form2_plots}
                />

                <TextField
                  variant="outlined"
                  margin="normal"
                  maxRows={10}
                  multiline
                  fullWidth
                  name="form2_complement"
                  id="form2_complement"
                  label="Complemento"
                  type="text"
                  onChange={this.handleChange}
                  value={this.state.form2_complement}
                />
              </div>

            </div>

          </div>

        );
      
      case 2:
        return (

          <div>

            <div style={styles.containerStep}>
              <Typography variant="h6" align="left">
                {'Localização'}
              </Typography>
            </div>

            <div style={styles.containerStep2}>

              <Typography variant="subtitle1" align="left">
                {'Latitude: ' + this.state.latitude}
              </Typography>

              <Typography variant="subtitle1" align="left">
                {'Longitude: ' + this.state.longitude}
              </Typography>

              <div style={styles.directionRow}>
                <div id="googlemap" ref={this.state.mapRef}>
                  <div style={{ height: 320, width: 310 }}>
                    <Map
                      key={'AIzaSyDC6df7s7nceeG2sHhFGu0-nzLppDiHpe0'}
                      google={this.props.google}
                      zoom={15}
                      style={styles.map}
                      mapType="hybrid"
                      initialCenter={{
                        lat: this.state.latitude,
                        lng: this.state.longitude
                      }}
                      center={{
                        lat: this.state.latitude,
                        lng: this.state.longitude
                      }}
                      onRightclick={(t, map, coord) => this.handlePolygon(coord)}
                    >

                      <Polygon
                        paths={this.state.polygonCoords}
                        strokeColor="#0000FF"
                        strokeOpacity={0.8}
                        strokeWeight={2}
                        fillColor="#0000FF"
                        fillOpacity={0.35}
                      />

                    </Map>

                    {/*<Typography variant="subtitle1" align="left" style={{ position: 'relative' }}>
                      Polígono da área: {this.state.area_name}
                    </Typography>*/}
                  </div>
                </div>
                <div style={{ marginLeft: '2%', marginTop: 5 }}>
                  <div style={styles.directionRow}>
                    <div>
                      <Typography variant="subtitle1" align="left">
                        {this.state.mapImageDescription}
                      </Typography>
                      <div>
                        <Button
                          variant="contained"
                          color="primary"
                          style={styles.button}
                          onClick={this.handleSaveMapAsImage}
                        >
                          <Save />Salvar como imagem *
                        </Button>

                        <input
                          accept="image/*"
                          style={{ display: 'none' }}
                          id="button-file"
                          multiple
                          type="file"
                          onChange={this.handleImportMapImage}
                        />
                        <label htmlFor="button-file">
                          <Button
                            component="span"
                            variant="contained"
                            color="primary"
                            style={{ textTransform: 'none', marginLeft: 10 }}
                          >
                            <Publish />Importar aqui
                          </Button>
                        </label>
                      </div>
                    </div>
                    {this.state.mapPreview && (
                      <img
                        id="mapPreview"
                        src={this.state.mapPreview}
                        style={styles.thumbnailStyles2}
                      />
                    )}
                  </div>
                </div>
              </div>

            </div>

          </div>

        );
      
      case 3:
        return (

          <div>

            <div style={styles.containerStep}>
              <Typography variant="h6" align="left">
                {'Justificativa'}
              </Typography>

              <Typography variant="subtitle1" align="left">
                {'Preencha os campos abaixo com os dados que serão utilizados para a construção do projeto urbanístico de sua área.'}
              </Typography>
            </div>

            <div style={styles.containerStep}>

              <TextField
                variant="outlined"
                margin="normal"
                maxRows={30}
                multiline
                fullWidth
                name="form4_justification"
                id="form4_justification"
                label="Justificativa"
                type="text"
                onChange={this.handleChange}
                value={this.state.form4_justification}
              />

            </div>

          </div>

        );

      case 4: 
        return (

          <div>

            <div style={styles.containerStep}>
              <Typography variant="h6" align="left">
                {'Infraestrutura'}
              </Typography>

              <Typography variant="subtitle1" align="left">
                {'Preencha os campos abaixo com os dados que serão utilizados para a construção do projeto urbanístico de sua área.'}
              </Typography>
            </div>

            <div style={styles.containerStep}>

              <TextField
                variant="outlined"
                margin="normal"
                maxRows={2}
                multiline
                fullWidth
                name="form5_description"
                id="form5_description"
                label="Descrição completa da infraestrutura"
                type="text"
                onChange={this.handleChange}
                value={this.state.form5_description}
              />

              <Dropzone onDrop={this.handleDrop} accept="image/*" multiple={false}>
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()} style={styles.dropzoneStyles}>
                    <input {...getInputProps()} />
                    <Typography variant="subtitle1" align="left">
                      Arraste e solte uma imagem aqui ou clique para selecionar.
                    </Typography>
                  </div>
                )}
              </Dropzone>
              {this.state.preview && (
                <div style={styles.imagePreviewStyles}>
                  <Typography variant="subtitle1" align="left">{`Consolidação e infraestrutura do ${this.state.area_name}`}</Typography>
                  <img
                    src={this.state.preview} 
                    alt={`Consolidação e infraestrutura do ${this.state.area_name}`}
                    style={styles.thumbnailStyles}
                  />
                </div>
              )}

            </div>

          </div>

        );
      
      case 5:
        return (

          <div>

            <div style={styles.containerStep}>
              <Typography variant="h6" align="left">
                {'Serviço de Transporte Coletivo'}
              </Typography>

              <Typography variant="subtitle1" align="left">
                {'Preencha os campos abaixo com os dados que serão utilizados para a construção do projeto urbanístico de sua área.'}
              </Typography>
            </div>

            <div style={styles.containerStep}>

              <TextField
                variant="outlined"
                margin="normal"
                maxRows={2}
                multiline
                fullWidth
                name="form6_description"
                id="form6_description"
                label="Descrição completa do serviço de transporte coletivo"
                type="text"
                onChange={this.handleChange}
                value={this.state.form6_description}
              />

              <Dropzone onDrop={this.handleDrop2} accept="image/*" multiple={false}>
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()} style={styles.dropzoneStyles}>
                    <input {...getInputProps()} />
                    <Typography variant="subtitle1" align="left">
                      Arraste e solte uma imagem aqui ou clique para selecionar.
                    </Typography>
                  </div>
                )}
              </Dropzone>
              {this.state.preview2 && (
                <div style={styles.imagePreviewStyles}>
                  <Typography variant="subtitle1" align="left">{`Consolidação e infraestrutura do ${this.state.area_name}`}</Typography>
                  <img
                    src={this.state.preview2}
                    alt={`Serviço de transporte coletivo do(a) ${this.state.area_name}`}
                    style={styles.thumbnailStyles}
                  />
                </div>
              )}

            </div>

          </div>

        );

      case 6:
        return (

          <div>

            <div style={styles.containerStep}>
              <Typography variant="h6" align="left">
                {'Rede de Água Potável'}
              </Typography>

              <Typography variant="subtitle1" align="left">
                {'Preencha os campos abaixo com os dados que serão utilizados para a construção do projeto urbanístico de sua área.'}
              </Typography>
            </div>

            <div style={styles.containerStep}>

              <TextField
                variant="outlined"
                margin="normal"
                maxRows={2}
                multiline
                fullWidth
                name="form7_description"
                id="form7_description"
                label="Descrição completa do serviço de Rede de Água Potável"
                type="text"
                onChange={this.handleChange}
                value={this.state.form7_description}
              />

              <Dropzone onDrop={this.handleDrop3} accept="image/*" multiple={false}>
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()} style={styles.dropzoneStyles}>
                    <input {...getInputProps()} />
                    <Typography variant="subtitle1" align="left">
                      Arraste e solte uma imagem aqui ou clique para selecionar.
                    </Typography>
                  </div>
                )}
              </Dropzone>
              {this.state.preview3 && (
                <div style={styles.imagePreviewStyles}>
                  <Typography variant="subtitle1" align="left">{`Rede de água potável do(a) ${this.state.area_name}`}</Typography>
                  <img
                    src={this.state.preview3}
                    alt={`Serviço de Rede de Água Potável do(a) ${this.state.area_name}`}
                    style={styles.thumbnailStyles}
                  />
                </div>
              )}

            </div>

          </div>

        );

      case 7:
        return (

          <div>

            <div style={styles.containerStep}>
              <Typography variant="h6" align="left">
                {'Rede de Drenagem de Águas Pluviais'}
              </Typography>

              <Typography variant="subtitle1" align="left">
                {'Preencha os campos abaixo com os dados que serão utilizados para a construção do projeto urbanístico de sua área.'}
              </Typography>
            </div>

            <div style={styles.containerStep}>

              <TextField
                variant="outlined"
                margin="normal"
                maxRows={2}
                multiline
                fullWidth
                name="form8_description"
                id="form8_description"
                label="Descrição completa do serviço de Rede de Drenagem de Águas Pluviais"
                type="text"
                onChange={this.handleChange}
                value={this.state.form8_description}
              />

              <Dropzone onDrop={this.handleDrop4} accept="image/*" multiple={false}>
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()} style={styles.dropzoneStyles}>
                    <input {...getInputProps()} />
                    <Typography variant="subtitle1" align="left">
                      Arraste e solte uma imagem aqui ou clique para selecionar.
                    </Typography>
                  </div>
                )}
              </Dropzone>
              {this.state.preview4 && (
                <div style={styles.imagePreviewStyles}>
                  <Typography variant="subtitle1" align="left">{`Drenagem de águas pluviais do(a) ${this.state.area_name}`}</Typography>
                  <img
                    src={this.state.preview4}
                    alt={`Serviço de drenagem de águas pluviais do(a) ${this.state.area_name}`}
                    style={styles.thumbnailStyles}
                  />
                </div>
              )}

            </div>

          </div>

        );

      case 8:
        return (

          <div>

            <div style={styles.containerStep}>
              <Typography variant="h6" align="left">
                {'Sistema de Coleta e Tratamento do Esgotamento Sanitário'}
              </Typography>

              <Typography variant="subtitle1" align="left">
                {'Preencha os campos abaixo com os dados que serão utilizados para a construção do projeto urbanístico de sua área.'}
              </Typography>
            </div>

            <div style={styles.containerStep}>

              <TextField
                variant="outlined"
                margin="normal"
                maxRows={2}
                multiline
                fullWidth
                name="form9_description"
                id="form9_description"
                label="Descrição completa do serviço de Sistema de Coleta e Tratamento do Esgotamento Sanitário"
                type="text"
                onChange={this.handleChange}
                value={this.state.form9_description}
              />

              <Dropzone onDrop={this.handleDrop5} accept="image/*" multiple={false}>
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()} style={styles.dropzoneStyles}>
                    <input {...getInputProps()} />
                    <Typography variant="subtitle1" align="left">
                      Arraste e solte uma imagem aqui ou clique para selecionar.
                    </Typography>
                  </div>
                )}
              </Dropzone>
              {this.state.preview5 && (
                <div style={styles.imagePreviewStyles}>
                  <Typography variant="subtitle1" align="left">{`Sistema de Coleta e Tratamento do Esgotamento Sanitário do(a) ${this.state.area_name}`}</Typography>
                  <img
                    src={this.state.preview5}
                    alt={`Serviço de Sistema de Coleta e Tratamento do Esgotamento Sanitário do(a) ${this.state.area_name}`}
                    style={styles.thumbnailStyles}
                  />
                </div>
              )}

            </div>

          </div>

        );

      case 9:
        return (

          <div>

            <div style={styles.containerStep}>
              <Typography variant="h6" align="left">
                {'Rede de Energia Elétrica'}
              </Typography>

              <Typography variant="subtitle1" align="left">
                {'Preencha os campos abaixo com os dados que serão utilizados para a construção do projeto urbanístico de sua área.'}
              </Typography>
            </div>

            <div style={styles.containerStep}>

              <TextField
                variant="outlined"
                margin="normal"
                maxRows={2}
                multiline
                fullWidth
                name="form10_description"
                id="form10_description"
                label="Descrição completa do serviço de Rede de Energia Elétrica"
                type="text"
                onChange={this.handleChange}
                value={this.state.form10_description}
              />

              <Dropzone onDrop={this.handleDrop6} accept="image/*" multiple={false}>
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()} style={styles.dropzoneStyles}>
                    <input {...getInputProps()} />
                    <Typography variant="subtitle1" align="left">
                      Arraste e solte uma imagem aqui ou clique para selecionar.
                    </Typography>
                  </div>
                )}
              </Dropzone>
              {this.state.preview6 && (
                <div style={styles.imagePreviewStyles}>
                  <Typography variant="subtitle1" align="left">{`Rede de Energia Elétrica do(a) ${this.state.area_name}`}</Typography>
                  <img
                    src={this.state.preview6}
                    alt={`Serviço de Rede de Energia Elétrica do(a) ${this.state.area_name}`}
                    style={styles.thumbnailStyles}
                  />
                </div>
              )}

            </div>

          </div>

        );

      case 10:
        return (

          <div>

            <div style={styles.containerStep}>
              <Typography variant="h6" align="left">
                {'Rede de Telecomunicações'}
              </Typography>

              <Typography variant="subtitle1" align="left">
                {'Preencha os campos abaixo com os dados que serão utilizados para a construção do projeto urbanístico de sua área.'}
              </Typography>
            </div>

            <div style={styles.containerStep}>

              <TextField
                variant="outlined"
                margin="normal"
                maxRows={2}
                multiline
                fullWidth
                name="form11_description"
                id="form11_description"
                label="Descrição completa do serviço de Rede de Telecomunicações"
                type="text"
                onChange={this.handleChange}
                value={this.state.form11_description}
              />

              <Dropzone onDrop={this.handleDrop7} accept="image/*" multiple={false}>
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()} style={styles.dropzoneStyles}>
                    <input {...getInputProps()} />
                    <Typography variant="subtitle1" align="left">
                      Arraste e solte uma imagem aqui ou clique para selecionar.
                    </Typography>
                  </div>
                )}
              </Dropzone>
              {this.state.preview7 && (
                <div style={styles.imagePreviewStyles}>
                  <Typography variant="subtitle1" align="left">{`Rede de Telecomunicações do(a) ${this.state.area_name}`}</Typography>
                  <img
                    src={this.state.preview7}
                    alt={`Serviço de Rede de Telecomunicações do(a) ${this.state.area_name}`}
                    style={styles.thumbnailStyles}
                  />
                </div>
              )}

            </div>

          </div>

        );

      case 11:
        return (

          <div>

            <div style={styles.containerStep}>
              <Typography variant="h6" align="left">
                {'Coleta de Resíduos Sólidos'}
              </Typography>

              <Typography variant="subtitle1" align="left">
                {'Preencha os campos abaixo com os dados que serão utilizados para a construção do projeto urbanístico de sua área.'}
              </Typography>
            </div>

            <div style={styles.containerStep}>

              <TextField
                variant="outlined"
                margin="normal"
                maxRows={2}
                multiline
                fullWidth
                name="form12_description"
                id="form12_description"
                label="Descrição completa do serviço de Coleta de Resíduos Sólidos"
                type="text"
                onChange={this.handleChange}
                value={this.state.form12_description}
              />

              <Dropzone onDrop={this.handleDrop8} accept="image/*" multiple={false}>
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()} style={styles.dropzoneStyles}>
                    <input {...getInputProps()} />
                    <Typography variant="subtitle1" align="left">
                      Arraste e solte uma imagem aqui ou clique para selecionar.
                    </Typography>
                  </div>
                )}
              </Dropzone>
              {this.state.preview8 && (
                <div style={styles.imagePreviewStyles}>
                  <Typography variant="subtitle1" align="left">{`Coleta de Resíduos Sólidos do(a) ${this.state.area_name}`}</Typography>
                  <img
                    src={this.state.preview8}
                    alt={`Serviço de Coleta de Resíduos Sólidos do(a) ${this.state.area_name}`}
                    style={styles.thumbnailStyles}
                  />
                </div>
              )}

            </div>

          </div>

        );

      case 12:
        return (

          <div>

            <div style={styles.containerStep}>
              <Typography variant="h6" align="left">
                {'Unidade de Saúde - ESF'}
              </Typography>

              <Typography variant="subtitle1" align="left">
                {'Preencha os campos abaixo com os dados que serão utilizados para a construção do projeto urbanístico de sua área.'}
              </Typography>
            </div>

            <div style={styles.containerStep}>

              <TextField
                variant="outlined"
                margin="normal"
                maxRows={2}
                multiline
                fullWidth
                name="form13_description"
                id="form13_description"
                label="Descrição completa do serviço de Unidade de Saúde - ESF"
                type="text"
                onChange={this.handleChange}
                value={this.state.form13_description}
              />

              <Dropzone onDrop={this.handleDrop9} accept="image/*" multiple={false}>
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()} style={styles.dropzoneStyles}>
                    <input {...getInputProps()} />
                    <Typography variant="subtitle1" align="left">
                      Arraste e solte uma imagem aqui ou clique para selecionar.
                    </Typography>
                  </div>
                )}
              </Dropzone>
              {this.state.preview9 && (
                <div style={styles.imagePreviewStyles}>
                  <Typography variant="subtitle1" align="left">{`Unidade de Saúde - ESF do(a) ${this.state.area_name}`}</Typography>
                  <img
                    src={this.state.preview9}
                    alt={`Serviço de Unidade de Saúde - ESF do(a) ${this.state.area_name}`}
                    style={styles.thumbnailStyles}
                  />
                </div>
              )}

            </div>

          </div>

        );

      case 13:
        return (

          <div>

            <div style={styles.containerStep}>
              <Typography variant="h6" align="left">
                {'Praça Pública'}
              </Typography>

              <Typography variant="subtitle1" align="left">
                {'Preencha os campos abaixo com os dados que serão utilizados para a construção do projeto urbanístico de sua área.'}
              </Typography>
            </div>

            <div style={styles.containerStep}>

              <TextField
                variant="outlined"
                margin="normal"
                maxRows={2}
                multiline
                fullWidth
                name="form14_description"
                id="form14_description"
                label="Descrição completa do serviço de Praça Pública"
                type="text"
                onChange={this.handleChange}
                value={this.state.form14_description}
              />

              <Dropzone onDrop={this.handleDrop10} accept="image/*" multiple={false}>
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()} style={styles.dropzoneStyles}>
                    <input {...getInputProps()} />
                    <Typography variant="subtitle1" align="left">
                      Arraste e solte uma imagem aqui ou clique para selecionar.
                    </Typography>
                  </div>
                )}
              </Dropzone>
              {this.state.preview10 && (
                <div style={styles.imagePreviewStyles}>
                  <Typography variant="subtitle1" align="left">{`Praça Pública do(a) ${this.state.area_name}`}</Typography>
                  <img
                    src={this.state.preview10}
                    alt={`Serviço de Praça Pública do(a) ${this.state.area_name}`}
                    style={styles.thumbnailStyles}
                  />
                </div>
              )}

            </div>

          </div>

        );

      case 14:
        return (

          <div>

            <div style={styles.containerStep}>
              <Typography variant="h6" align="left">
                {'Vias e Acessibilidade'}
              </Typography>

              <Typography variant="subtitle1" align="left">
                {'Preencha os campos abaixo com os dados que serão utilizados para a construção do projeto urbanístico de sua área.'}
              </Typography>
            </div>

            <div style={styles.containerStep}>

              <TextField
                variant="outlined"
                margin="normal"
                maxRows={2}
                multiline
                fullWidth
                name="form15_description"
                id="form15_description"
                label="Descrição completa do serviço de Vias e Acessibilidade"
                type="text"
                onChange={this.handleChange}
                value={this.state.form15_description}
              />

              <Dropzone onDrop={this.handleDrop11} accept="image/*" multiple={false}>
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()} style={styles.dropzoneStyles}>
                    <input {...getInputProps()} />
                    <Typography variant="subtitle1" align="left">
                      Arraste e solte uma imagem aqui ou clique para selecionar.
                    </Typography>
                  </div>
                )}
              </Dropzone>
              {this.state.preview11 && (
                <div style={styles.imagePreviewStyles}>
                  <Typography variant="subtitle1" align="left">{`Vias e Acessibilidade do(a) ${this.state.area_name}`}</Typography>
                  <img
                    src={this.state.preview11}
                    alt={`Serviço de Vias e Acessibilidade do(a) ${this.state.area_name}`}
                    style={styles.thumbnailStyles}
                  />
                </div>
              )}

            </div>

          </div>

        );
      default:
        return 'Unknown step';

    }

  }

  handlePDF = async () => {

    const Doc = () => (
      <UrbanProjectReport
        login={this.state.login}
        selected_city={this.state.selected_city}
        area_id={this.state.area_id}
        area_name={this.state.area_name}
        area={this.state.area}
        cep={this.state.cep}
        street={this.state.street}
        number={this.state.number}
        district={this.state.district}
        city={this.state.city}
        state={this.state.state}
        description={this.state.description}
        latitude={this.state.latitude}
        longitude={this.state.longitude}
        polygonCoords={this.state.polygonCoords}
        form1_title={this.state.form1_title}
        form1_description={this.state.form1_description}
        form1_informations={this.state.form1_informations}
        form1_complement={this.state.form1_complement}
        form2_area={this.state.form2_area}
        form2_blocks={this.state.form2_blocks}
        form2_plots={this.state.form2_plots}
        form2_complement={this.state.form2_complement}
        form4_justification={this.state.form4_justification}
        form5_description={this.state.form5_description}
        form6_description={this.state.form6_description}
        form7_description={this.state.form7_description}
        form8_description={this.state.form8_description}
        form9_description={this.state.form9_description}
        form10_description={this.state.form10_description}
        form11_description={this.state.form11_description}
        form12_description={this.state.form12_description}
        form13_description={this.state.form13_description}
        form14_description={this.state.form14_description}
        form15_description={this.state.form15_description}
        mapImageDescription={this.state.mapImageDescription}
        mapPreview={this.state.mapPreview}
        preview={this.state.preview}
        preview2={this.state.preview2}
        preview3={this.state.preview3}
        preview4={this.state.preview4}
        preview5={this.state.preview5}
        preview6={this.state.preview6}
        preview7={this.state.preview7}
        preview8={this.state.preview8}
        preview9={this.state.preview9}
        preview10={this.state.preview10}
        preview11={this.state.preview11}
      />
    );

    await this.setState({ open3: true });

    generatedPdf = ReactDOM.render(<Doc />, document.getElementById('nproot'));

  }

  handleSaveMapAsImage = async () => {

    await html2canvas(document.getElementById("googlemap"), {
      useCORS: true,
    }).then(canvas => {
      var myImage = canvas.toDataURL('image/png');
      //document.body.appendChild(canvas);

      //this.setState({ preview: canvas.toDataURL('image/jpeg') });
      /*if ((this.state.f3_image == null) || (this.state.f3_image == undefined) ||
        (this.state.f3_image == '')) { //setando uma imagem padrão

        let filename = "mypolygonmap.jpg"; //rever se precisa de um nome dinâmico
        let match = /\.(\w+)$/.exec(filename);
        let type = match ? `image/${match[1]}` : `image`;

        const f3_image = {
          url: this.state.preview,
          type: type,
          name: filename,
        };

        this.setState({ f3_image });
      }*/
      var link = document.createElement("a");
      link.download = "mapImage.jpg";
      link.href = "data:" + myImage;
      link.click();
    });

  }

  handleImportMapImage = async e => {

    await this.setState({ mapImage: e.target.files[0] });

    this.setState({ mapPreview: URL.createObjectURL(this.state.mapImage) });

  }

  handleNewPdf = async e => {
    await e.preventDefault();

    await this.setState({ file: e.target.files[0] });
    const fileSize = this.state.file.size;

    if (fileSize > 5000000) {
      alert('Tamanho máximo permitido: 5 MB');
    } else {

      const data = new FormData();
      data.append('area_id', this.state.area_id);
      data.append('selected_city', this.state.selected_city);
      data.append('document', this.state.file);

      try {
        let resp = await api.post('/storeareadocument', data);
        if (resp != null) {
          if (resp.data) {
            if (JSON.stringify(resp.data).includes('registred')) {
              alert('Documento salvo com sucesso!');
            } else {
              alert(resp.data);
            }
          }
        }
      } catch (err) {
        alert('Não foi possível salvar o PDF final. ' + err);
      }

    }
  }

  render() {

    return(

      <div style={styles.directionColumn}>

        <div style={styles.html}>

          <Header
            history={this.props.history}
            login={this.state.login}
            option={this.state.option}
            user_type={this.state.user_type}
            selected_area={this.state.selected_area}
            selected_city={this.state.selected_city}
            city={this.state.selected_city}
            {...this.props}
          />

          <ThemeProvider theme={theme}>
            <div>
              <Typography variant="h5" align="center" style={styles.text}>
                {'Construindo o Projeto Urbanístico de sua área'}
              </Typography>
              <Typography variant="subtitle1" align="center" style={styles.text}>
                {this.state.area_name}
              </Typography>
            </div>

            <div style={styles.container}>

              <Typography variant="h6" align="center" style={styles.text2}>
                {'Preenchimento do formulário'}
              </Typography>

              <div>
                <Stepper alternativeLabel nonLinear activeStep={this.state.activeStep}>
                  {this.state.steps.map((label, index) => (
                    <Step key={label}>
                      <StepButton onClick={this.handleStep(index)} completed={this.state.completed[index]}>
                        {label}
                      </StepButton>
                    </Step>
                  ))}
                </Stepper>
                <div>
                  {this.allStepsCompleted() ? (
                    <div>
                      <Typography variant="caption">
                        Todas as etapas foram concluídas, já pode enviar o formulário.
                      </Typography>
                      <Button onClick={this.handleReset}>Refazer</Button>
                    </div>
                  ) : (
                    <div>
                      {this.getStepContent(this.state.activeStep)}
                      <div>
                        <Button disabled={this.state.activeStep === 0} style={{ color: '#343434' }} onClick={this.handleBack}>
                          <ArrowBack />Voltar
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          style={styles.button}
                          onClick={this.handleNext}
                        >
                          <SkipNext />Próximo
                        </Button>
                        {this.state.activeStep !== this.state.steps.length &&
                          (this.state.completed[this.state.activeStep] ? (
                            <Typography variant="caption" style={{ marginLeft: 10 }}>
                              Etapa {this.state.activeStep + 1} já foi concluída
                            </Typography>
                          ) : (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={this.handleComplete}
                              style={styles.button2}
                            >
                              {this.completedSteps() === this.totalSteps() - 1 ? <div style={styles.directionRow}><DoneAll /><Typography>Finalizar</Typography></div> : 
                                <div style={styles.directionRow}><Done /><Typography>Completar etapa</Typography></div>}
                            </Button>
                          ))}
                      </div>
                    </div>
                  )}
                </div>
                <Button
                  variant="contained"
                  color="primary"
                  style={styles.button3}
                  onClick={this.handlePDF}
                >
                  <PictureAsPdf />Gerar relatório PDF
                </Button>
              </div>

            </div>

            <Dialog open={this.state.open3} onClose={() => this.setState({ open3: false })}
              aria-labelledby="form-dialog-title"
              fullWidth
              maxWidth="xl"
            >
              <DialogTitle id="form-dialog-title">Relatório</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Relatório a ser salvo e exportado.
                </DialogContentText>

                <div id="nproot" style={styles.pdf} />

              </DialogContent>
              <DialogActions>
                <Button onClick={() => this.setState({ open3: false })}>
                  Cancelar
                </Button>

                <input
                  accept="file_extension|audio/*|video/*|image/*|media_type"
                  style={{ display: 'none' }}
                  id="button-attach2"
                  multiple
                  type="file"
                  onChange={this.handleNewPdf}
                />
                <label htmlFor="button-attach2">
                  <Button
                    component="span"
                  >
                    Finalizar processo
                  </Button>
                </label>

              </DialogActions>
            </Dialog>
          </ThemeProvider>

        </div>

        <FooterHome />

      </div>

    );

  }

}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDC6df7s7nceeG2sHhFGu0-nzLppDiHpe0',
  language: 'pt-BR'
})(UrbanProject2);

let styles = {

  html: {
    backgroundColor: '#E5E6F0', //454545
    height: window.innerHeight + 500,
    width: window.innerWidth,
    margin: -10,
  },

  container: {
    padding: 20,
    backgroundColor: '#bfcadb',
    width: window.innerWidth - (window.innerWidth / 15),
    margin: 10, 
    alignSelf: 'center',
    marginLeft: '2%',
    borderRadius: 10,
  },

  directionRow: {
    display: 'flex',
    flexDirection: 'row'
  },

  directionColumn: {
    display: 'flex',
    flexDirection: 'column'
  },

  containerStep: {
    backgroundColor: '#E5E6F0',
    marginTop: 20,
    marginBottom: 20,
    padding: 20,
  },

  containerStep2: {
    backgroundColor: '#E5E6F0',
    height: 400,
    marginTop: 20,
    marginBottom: 20,
    padding: 20,
  },

  text: {
    marginTop: 20,
    color: '#343434',
  },

  text2: {
    marginBottom: 20,
    color: '#343434',
  },

  button: {
    textTransform: 'none',
  },

  button2: {
    textTransform: 'none',
    marginLeft: 10,
  },

  button3: {
    textTransform: 'none',
    marginLeft: window.innerWidth - (window.innerWidth / 6),
  },

  textField2: {
    marginRight: 10,
    color: '#343434',
  },

  map: {
    height: 300,
    width: window.innerWidth - (window.innerWidth / 1.2),
    marginTop: 10,
    alignSelf: 'center',
  },

  dropzoneStyles: {
    border: '2px dashed #ccc',
    borderRadius: 5,
    padding: 20,
    textAlign: 'center',
    cursor: 'pointer',
  },

  imagePreviewStyles: {
    marginTop: 20,
  },

  thumbnailStyles: {
    height: 480,
    width: 700,
  },

  thumbnailStyles2: {
    marginLeft: 10,
    height: 300,
    width: 300,
  },

  pdf: { 
    display: 'flex', 
    flexDirection: 'column',
    maxHeight: 900,
    height: 900,
    width: window.innerWidth - (window.innerWidth / 2.67),
    marginLeft: window.innerWidth - (window.innerWidth / 1.15),
  }

}