let polygonCoordsRio1 = [
  {
    lng: -43.4286498,
    lat: -23.0193338
  },
  {
    lng: -43.4264848,
    lat: -23.0189505
  },
  {
    lng: -43.4257342,
    lat: -23.0187384
  },
  {
    lng: -43.4233452,
    lat: -23.018231
  },
  {
    lng: -43.4188527,
    lat: -23.0173608
  },
  {
    lng: -43.4136197,
    lat: -23.0166521
  },
  {
    lng: -43.4131663,
    lat: -23.0165754
  },
  {
    lng: -43.4128769,
    lat: -23.0164624
  },
  {
    lng: -43.411682,
    lat: -23.0162405
  },
  {
    lng: -43.4087648,
    lat: -23.0158072
  },
  {
    lng: -43.4083136,
    lat: -23.0157922
  },
  {
    lng: -43.4060513,
    lat: -23.0154915
  },
  {
    lng: -43.40542,
    lat: -23.01534
  },
  {
    lng: -43.404742,
    lat: -23.0152452
  },
  {
    lng: -43.4039168,
    lat: -23.0150774
  },
  {
    lng: -43.4028642,
    lat: -23.0148804
  },
  {
    lng: -43.401921,
    lat: -23.0147178
  },
  {
    lng: -43.4007582,
    lat: -23.0145633
  },
  {
    lng: -43.3996685,
    lat: -23.0144087
  },
  {
    lng: -43.399156,
    lat: -23.0143096
  },
  {
    lng: -43.3978337,
    lat: -23.0141273
  },
  {
    lng: -43.3962976,
    lat: -23.0139885
  },
  {
    lng: -43.3957466,
    lat: -23.0139074
  },
  {
    lng: -43.3950817,
    lat: -23.0137745
  },
  {
    lng: -43.3942074,
    lat: -23.0136435
  },
  {
    lng: -43.3914122,
    lat: -23.0133384
  },
  {
    lng: -43.3882553,
    lat: -23.0130965
  },
  {
    lng: -43.3867736,
    lat: -23.0129618
  },
  {
    lng: -43.3833122,
    lat: -23.0127296
  },
  {
    lng: -43.3823198,
    lat: -23.012711
  },
  {
    lng: -43.3807021,
    lat: -23.0124558
  },
  {
    lng: -43.3790544,
    lat: -23.012319
  },
  {
    lng: -43.3773812,
    lat: -23.0121034
  },
  {
    lng: -43.3763033,
    lat: -23.0120833
  },
  {
    lng: -43.3753533,
    lat: -23.0119976
  },
  {
    lng: -43.3747289,
    lat: -23.0118882
  },
  {
    lng: -43.3739127,
    lat: -23.0118014
  },
  {
    lng: -43.3729797,
    lat: -23.0118158
  },
  {
    lng: -43.3712508,
    lat: -23.0117436
  },
  {
    lng: -43.3689611,
    lat: -23.0116019
  },
  {
    lng: -43.3666001,
    lat: -23.0114943
  },
  {
    lng: -43.3654991,
    lat: -23.0114551
  },
  {
    lng: -43.3615505,
    lat: -23.0113018
  },
  {
    lng: -43.3574954,
    lat: -23.011136
  },
  {
    lng: -43.3504968,
    lat: -23.0110119
  },
  {
    lng: -43.3477074,
    lat: -23.011024
  },
  {
    lng: -43.3426628,
    lat: -23.011108
  },
  {
    lng: -43.3391812,
    lat: -23.0112543
  },
  {
    lng: -43.3341675,
    lat: -23.0115138
  },
  {
    lng: -43.3303597,
    lat: -23.0118152
  },
  {
    lng: -43.3248951,
    lat: -23.0125469
  },
  {
    lng: -43.3217783,
    lat: -23.013059
  },
  {
    lng: -43.3187286,
    lat: -23.0137473
  },
  {
    lng: -43.3142744,
    lat: -23.0147184
  },
  {
    lng: -43.3124958,
    lat: -23.0152006
  },
  {
    lng: -43.3107355,
    lat: -23.0155762
  },
  {
    lng: -43.3086006,
    lat: -23.015942
  },
  {
    lng: -43.3074105,
    lat: -23.0160739
  },
  {
    lng: -43.3060713,
    lat: -23.0160483
  },
  {
    lng: -43.3039502,
    lat: -23.0159031
  },
  {
    lng: -43.3017564,
    lat: -23.0155606
  },
  {
    lng: -43.2993749,
    lat: -23.0153805
  },
  {
    lng: -43.2982941,
    lat: -23.0154078
  },
  {
    lng: -43.2977253,
    lat: -23.0154552
  },
  {
    lng: -43.2976911,
    lat: -23.0158114
  },
  {
    lng: -43.2977806,
    lat: -23.0159327
  },
  {
    lng: -43.297808,
    lat: -23.0160202
  },
  {
    lng: -43.2978019,
    lat: -23.016078
  },
  {
    lng: -43.2977598,
    lat: -23.0161511
  },
  {
    lng: -43.2977587,
    lat: -23.0161527
  },
  {
    lng: -43.2976892,
    lat: -23.0161841
  },
  {
    lng: -43.2976027,
    lat: -23.0162028
  },
  {
    lng: -43.2971384,
    lat: -23.0164167
  },
  {
    lng: -43.2965507,
    lat: -23.0166938
  },
  {
    lng: -43.2961486,
    lat: -23.0168787
  },
  {
    lng: -43.2960878,
    lat: -23.016883
  },
  {
    lng: -43.296039,
    lat: -23.0168848
  },
  {
    lng: -43.2959902,
    lat: -23.0168862
  },
  {
    lng: -43.2959415,
    lat: -23.0168881
  },
  {
    lng: -43.2958928,
    lat: -23.0168901
  },
  {
    lng: -43.295844,
    lat: -23.0168924
  },
  {
    lng: -43.2957957,
    lat: -23.016898
  },
  {
    lng: -43.2957681,
    lat: -23.0169351
  },
  {
    lng: -43.295725,
    lat: -23.0169565
  },
  {
    lng: -43.2956791,
    lat: -23.0169716
  },
  {
    lng: -43.2956328,
    lat: -23.0169858
  },
  {
    lng: -43.2955841,
    lat: -23.0169889
  },
  {
    lng: -43.2955353,
    lat: -23.0169891
  },
  {
    lng: -43.2954889,
    lat: -23.0169755
  },
  {
    lng: -43.2954407,
    lat: -23.0169831
  },
  {
    lng: -43.2954197,
    lat: -23.0169945
  },
  {
    lng: -43.2954359,
    lat: -23.0170281
  },
  {
    lng: -43.2953901,
    lat: -23.0170128
  },
  {
    lng: -43.2953499,
    lat: -23.0169873
  },
  {
    lng: -43.2953043,
    lat: -23.0169709
  },
  {
    lng: -43.2952572,
    lat: -23.0169592
  },
  {
    lng: -43.2951626,
    lat: -23.0169383
  },
  {
    lng: -43.2950177,
    lat: -23.0169556
  },
  {
    lng: -43.2947134,
    lat: -23.0168922
  },
  {
    lng: -43.2947018,
    lat: -23.016936
  },
  {
    lng: -43.2946049,
    lat: -23.0169452
  },
  {
    lng: -43.2944598,
    lat: -23.0169028
  },
  {
    lng: -43.2944329,
    lat: -23.0169882
  },
  {
    lng: -43.2943442,
    lat: -23.0170233
  },
  {
    lng: -43.2942956,
    lat: -23.0170191
  },
  {
    lng: -43.2942551,
    lat: -23.016994
  },
  {
    lng: -43.2942364,
    lat: -23.0169523
  },
  {
    lng: -43.2942307,
    lat: -23.0168174
  },
  {
    lng: -43.2942003,
    lat: -23.016782
  },
  {
    lng: -43.2940054,
    lat: -23.0167795
  },
  {
    lng: -43.2939603,
    lat: -23.0167969
  },
  {
    lng: -43.2939008,
    lat: -23.0168682
  },
  {
    lng: -43.293852,
    lat: -23.0168668
  },
  {
    lng: -43.2938143,
    lat: -23.0168381
  },
  {
    lng: -43.2937168,
    lat: -23.0168418
  },
  {
    lng: -43.2936366,
    lat: -23.0167906
  },
  {
    lng: -43.2935467,
    lat: -23.0167566
  },
  {
    lng: -43.2935124,
    lat: -23.0167244
  },
  {
    lng: -43.2933852,
    lat: -23.0164819
  },
  {
    lng: -43.2933613,
    lat: -23.0163953
  },
  {
    lng: -43.2932479,
    lat: -23.0162494
  },
  {
    lng: -43.2932041,
    lat: -23.016121
  },
  {
    lng: -43.2931711,
    lat: -23.0160879
  },
  {
    lng: -43.2930762,
    lat: -23.0160687
  },
  {
    lng: -43.2929926,
    lat: -23.016022
  },
  {
    lng: -43.2927454,
    lat: -23.0158043
  },
  {
    lng: -43.2924511,
    lat: -23.0153368
  },
  {
    lng: -43.2924347,
    lat: -23.015249
  },
  {
    lng: -43.2923042,
    lat: -23.0151151
  },
  {
    lng: -43.2922142,
    lat: -23.0151479
  },
  {
    lng: -43.2920218,
    lat: -23.0151765
  },
  {
    lng: -43.2919481,
    lat: -23.0152343
  },
  {
    lng: -43.2918663,
    lat: -23.0152374
  },
  {
    lng: -43.2917594,
    lat: -23.0152051
  },
  {
    lng: -43.2917106,
    lat: -23.0152041
  },
  {
    lng: -43.2916618,
    lat: -23.0152058
  },
  {
    lng: -43.2916132,
    lat: -23.015208
  },
  {
    lng: -43.2915643,
    lat: -23.0152088
  },
  {
    lng: -43.2915156,
    lat: -23.0152107
  },
  {
    lng: -43.2914669,
    lat: -23.015213
  },
  {
    lng: -43.2914183,
    lat: -23.0152152
  },
  {
    lng: -43.2913513,
    lat: -23.0152123
  },
  {
    lng: -43.2912836,
    lat: -23.0152096
  },
  {
    lng: -43.2912202,
    lat: -23.015205
  },
  {
    lng: -43.29118,
    lat: -23.0152045
  },
  {
    lng: -43.291113,
    lat: -23.0152028
  },
  {
    lng: -43.2910642,
    lat: -23.0152049
  },
  {
    lng: -43.2910154,
    lat: -23.0152071
  },
  {
    lng: -43.2909667,
    lat: -23.0152085
  },
  {
    lng: -43.290918,
    lat: -23.0152096
  },
  {
    lng: -43.2908691,
    lat: -23.015209
  },
  {
    lng: -43.2908209,
    lat: -23.0152033
  },
  {
    lng: -43.2907729,
    lat: -23.0151951
  },
  {
    lng: -43.2907248,
    lat: -23.0151869
  },
  {
    lng: -43.2906769,
    lat: -23.0151786
  },
  {
    lng: -43.290629,
    lat: -23.0151696
  },
  {
    lng: -43.2905815,
    lat: -23.0151593
  },
  {
    lng: -43.2905343,
    lat: -23.0151488
  },
  {
    lng: -43.2904868,
    lat: -23.0151382
  },
  {
    lng: -43.2904394,
    lat: -23.0151278
  },
  {
    lng: -43.2903919,
    lat: -23.0151173
  },
  {
    lng: -43.2903481,
    lat: -23.0150973
  },
  {
    lng: -43.2903071,
    lat: -23.0150729
  },
  {
    lng: -43.2902651,
    lat: -23.01505
  },
  {
    lng: -43.2902203,
    lat: -23.0150319
  },
  {
    lng: -43.2901748,
    lat: -23.0150157
  },
  {
    lng: -43.2901293,
    lat: -23.0149993
  },
  {
    lng: -43.2900838,
    lat: -23.0149833
  },
  {
    lng: -43.2900382,
    lat: -23.0149669
  },
  {
    lng: -43.289994,
    lat: -23.014948
  },
  {
    lng: -43.2899505,
    lat: -23.0149275
  },
  {
    lng: -43.2899068,
    lat: -23.0149075
  },
  {
    lng: -43.2898625,
    lat: -23.0148888
  },
  {
    lng: -43.2898178,
    lat: -23.0148705
  },
  {
    lng: -43.2897735,
    lat: -23.0148518
  },
  {
    lng: -43.2897377,
    lat: -23.014821
  },
  {
    lng: -43.2897088,
    lat: -23.0147846
  },
  {
    lng: -43.2896606,
    lat: -23.0147917
  },
  {
    lng: -43.2896246,
    lat: -23.0148056
  },
  {
    lng: -43.2895916,
    lat: -23.0147974
  },
  {
    lng: -43.2895713,
    lat: -23.0147732
  },
  {
    lng: -43.289538,
    lat: -23.0147404
  },
  {
    lng: -43.2894981,
    lat: -23.0147144
  },
  {
    lng: -43.2895066,
    lat: -23.0147588
  },
  {
    lng: -43.2894101,
    lat: -23.0147591
  },
  {
    lng: -43.2893711,
    lat: -23.0147863
  },
  {
    lng: -43.2893555,
    lat: -23.0148291
  },
  {
    lng: -43.2893649,
    lat: -23.0149189
  },
  {
    lng: -43.2893264,
    lat: -23.0149466
  },
  {
    lng: -43.289279,
    lat: -23.0149578
  },
  {
    lng: -43.2893022,
    lat: -23.0150455
  },
  {
    lng: -43.2891901,
    lat: -23.0151928
  },
  {
    lng: -43.2890169,
    lat: -23.0152749
  },
  {
    lng: -43.2889513,
    lat: -23.0153414
  },
  {
    lng: -43.2889031,
    lat: -23.0153489
  },
  {
    lng: -43.2887609,
    lat: -23.0153234
  },
  {
    lng: -43.2886165,
    lat: -23.0153358
  },
  {
    lng: -43.2884721,
    lat: -23.0153144
  },
  {
    lng: -43.2883763,
    lat: -23.0153244
  },
  {
    lng: -43.2882327,
    lat: -23.0153037
  },
  {
    lng: -43.2881591,
    lat: -23.0152454
  },
  {
    lng: -43.2880258,
    lat: -23.0151897
  },
  {
    lng: -43.2879618,
    lat: -23.0151225
  },
  {
    lng: -43.2878654,
    lat: -23.0151128
  },
  {
    lng: -43.2877342,
    lat: -23.0150541
  },
  {
    lng: -43.2876712,
    lat: -23.0149859
  },
  {
    lng: -43.2875825,
    lat: -23.0149516
  },
  {
    lng: -43.2873922,
    lat: -23.0148107
  },
  {
    lng: -43.287203,
    lat: -23.0148498
  },
  {
    lng: -43.2870843,
    lat: -23.0149284
  },
  {
    lng: -43.2870369,
    lat: -23.0150068
  },
  {
    lng: -43.2869388,
    lat: -23.0151062
  },
  {
    lng: -43.2869225,
    lat: -23.0151489
  },
  {
    lng: -43.2869277,
    lat: -23.0152843
  },
  {
    lng: -43.2869061,
    lat: -23.0154636
  },
  {
    lng: -43.2869613,
    lat: -23.015538
  },
  {
    lng: -43.2869695,
    lat: -23.0155825
  },
  {
    lng: -43.2869654,
    lat: -23.0156274
  },
  {
    lng: -43.2869313,
    lat: -23.0156597
  },
  {
    lng: -43.2869341,
    lat: -23.0157049
  },
  {
    lng: -43.2869724,
    lat: -23.0157878
  },
  {
    lng: -43.2869579,
    lat: -23.0158308
  },
  {
    lng: -43.2868787,
    lat: -23.0159448
  },
  {
    lng: -43.2868328,
    lat: -23.0159597
  },
  {
    lng: -43.2867996,
    lat: -23.0159928
  },
  {
    lng: -43.2867278,
    lat: -23.0161606
  },
  {
    lng: -43.2867104,
    lat: -23.0162494
  },
  {
    lng: -43.2866682,
    lat: -23.0163305
  },
  {
    lng: -43.2865476,
    lat: -23.0164021
  },
  {
    lng: -43.2865206,
    lat: -23.0164397
  },
  {
    lng: -43.2864731,
    lat: -23.0164504
  },
  {
    lng: -43.2863058,
    lat: -23.0162864
  },
  {
    lng: -43.2862856,
    lat: -23.0162454
  },
  {
    lng: -43.2862452,
    lat: -23.0162202
  },
  {
    lng: -43.2862216,
    lat: -23.0161333
  },
  {
    lng: -43.2861446,
    lat: -23.016079
  },
  {
    lng: -43.2859791,
    lat: -23.0159135
  },
  {
    lng: -43.2859069,
    lat: -23.0156988
  },
  {
    lng: -43.2858483,
    lat: -23.0156269
  },
  {
    lng: -43.2858016,
    lat: -23.0154527
  },
  {
    lng: -43.2856659,
    lat: -23.0152651
  },
  {
    lng: -43.2856043,
    lat: -23.0150471
  },
  {
    lng: -43.2856026,
    lat: -23.0149569
  },
  {
    lng: -43.2856235,
    lat: -23.0149161
  },
  {
    lng: -43.2857924,
    lat: -23.0147564
  },
  {
    lng: -43.2858332,
    lat: -23.0147318
  },
  {
    lng: -43.2858815,
    lat: -23.0147263
  },
  {
    lng: -43.2859087,
    lat: -23.0147638
  },
  {
    lng: -43.2859528,
    lat: -23.0147443
  },
  {
    lng: -43.2860537,
    lat: -23.0146482
  },
  {
    lng: -43.2861395,
    lat: -23.0146155
  },
  {
    lng: -43.286163,
    lat: -23.0145774
  },
  {
    lng: -43.2862083,
    lat: -23.0145602
  },
  {
    lng: -43.2863054,
    lat: -23.0145525
  },
  {
    lng: -43.2863969,
    lat: -23.0145829
  },
  {
    lng: -43.2864938,
    lat: -23.0145905
  },
  {
    lng: -43.2865246,
    lat: -23.0145314
  },
  {
    lng: -43.2865217,
    lat: -23.0142833
  },
  {
    lng: -43.2864768,
    lat: -23.013834
  },
  {
    lng: -43.2865481,
    lat: -23.0137732
  },
  {
    lng: -43.2865702,
    lat: -23.0137355
  },
  {
    lng: -43.2866494,
    lat: -23.0135711
  },
  {
    lng: -43.2866738,
    lat: -23.0134836
  },
  {
    lng: -43.286757,
    lat: -23.0134366
  },
  {
    lng: -43.2866926,
    lat: -23.0133163
  },
  {
    lng: -43.2866902,
    lat: -23.013181
  },
  {
    lng: -43.2867337,
    lat: -23.0131605
  },
  {
    lng: -43.2867783,
    lat: -23.0131786
  },
  {
    lng: -43.2868587,
    lat: -23.0130654
  },
  {
    lng: -43.286848,
    lat: -23.0130214
  },
  {
    lng: -43.2867895,
    lat: -23.0129492
  },
  {
    lng: -43.2867886,
    lat: -23.012904
  },
  {
    lng: -43.286882,
    lat: -23.012883
  },
  {
    lng: -43.2868388,
    lat: -23.0128022
  },
  {
    lng: -43.2868425,
    lat: -23.0127573
  },
  {
    lng: -43.2868679,
    lat: -23.0127187
  },
  {
    lng: -43.2869833,
    lat: -23.0126373
  },
  {
    lng: -43.2870163,
    lat: -23.0125525
  },
  {
    lng: -43.2870302,
    lat: -23.012463
  },
  {
    lng: -43.2871019,
    lat: -23.0122476
  },
  {
    lng: -43.2870781,
    lat: -23.0121604
  },
  {
    lng: -43.2871074,
    lat: -23.0120285
  },
  {
    lng: -43.2870881,
    lat: -23.0118953
  },
  {
    lng: -43.2871504,
    lat: -23.0118257
  },
  {
    lng: -43.2871621,
    lat: -23.0117819
  },
  {
    lng: -43.287188,
    lat: -23.0115131
  },
  {
    lng: -43.2872342,
    lat: -23.0114981
  },
  {
    lng: -43.2872648,
    lat: -23.0114629
  },
  {
    lng: -43.2873332,
    lat: -23.0112943
  },
  {
    lng: -43.2873615,
    lat: -23.0112576
  },
  {
    lng: -43.2873956,
    lat: -23.0112253
  },
  {
    lng: -43.2875299,
    lat: -23.0111718
  },
  {
    lng: -43.287548,
    lat: -23.0111299
  },
  {
    lng: -43.2875262,
    lat: -23.0110895
  },
  {
    lng: -43.2874197,
    lat: -23.0109965
  },
  {
    lng: -43.2873295,
    lat: -23.0109622
  },
  {
    lng: -43.2872257,
    lat: -23.0108682
  },
  {
    lng: -43.2872038,
    lat: -23.0107359
  },
  {
    lng: -43.2872439,
    lat: -23.0105134
  },
  {
    lng: -43.2872443,
    lat: -23.0104235
  },
  {
    lng: -43.2872175,
    lat: -23.0103368
  },
  {
    lng: -43.2871177,
    lat: -23.0101816
  },
  {
    lng: -43.2870204,
    lat: -23.0099755
  },
  {
    lng: -43.2867916,
    lat: -23.0098073
  },
  {
    lng: -43.2867454,
    lat: -23.0097933
  },
  {
    lng: -43.2866133,
    lat: -23.0098497
  },
  {
    lng: -43.2865672,
    lat: -23.0098348
  },
  {
    lng: -43.2864752,
    lat: -23.0097299
  },
  {
    lng: -43.286414,
    lat: -23.0095559
  },
  {
    lng: -43.2864114,
    lat: -23.0094601
  },
  {
    lng: -43.2863588,
    lat: -23.0093965
  },
  {
    lng: -43.286358,
    lat: -23.0093063
  },
  {
    lng: -43.2864066,
    lat: -23.0093098
  },
  {
    lng: -43.2864306,
    lat: -23.0092705
  },
  {
    lng: -43.2862643,
    lat: -23.0090539
  },
  {
    lng: -43.2860915,
    lat: -23.0088951
  },
  {
    lng: -43.2858011,
    lat: -23.008729
  },
  {
    lng: -43.285586,
    lat: -23.0086229
  },
  {
    lng: -43.2855389,
    lat: -23.008611
  },
  {
    lng: -43.2855283,
    lat: -23.0086552
  },
  {
    lng: -43.2854804,
    lat: -23.0086464
  },
  {
    lng: -43.285484,
    lat: -23.0085573
  },
  {
    lng: -43.2854512,
    lat: -23.0085238
  },
  {
    lng: -43.2854033,
    lat: -23.008516
  },
  {
    lng: -43.2853767,
    lat: -23.0086016
  },
  {
    lng: -43.2852843,
    lat: -23.0086286
  },
  {
    lng: -43.2852467,
    lat: -23.0085997
  },
  {
    lng: -43.2852765,
    lat: -23.0085138
  },
  {
    lng: -43.285253,
    lat: -23.0084742
  },
  {
    lng: -43.2851783,
    lat: -23.0084165
  },
  {
    lng: -43.285085,
    lat: -23.008313
  },
  {
    lng: -43.2848201,
    lat: -23.008198
  },
  {
    lng: -43.2846807,
    lat: -23.0081574
  },
  {
    lng: -43.2843405,
    lat: -23.0081413
  },
  {
    lng: -43.2842566,
    lat: -23.0080953
  },
  {
    lng: -43.2841649,
    lat: -23.0080686
  },
  {
    lng: -43.2841059,
    lat: -23.0079966
  },
  {
    lng: -43.2839944,
    lat: -23.007934
  },
  {
    lng: -43.2838391,
    lat: -23.0078573
  },
  {
    lng: -43.2837467,
    lat: -23.0078286
  },
  {
    lng: -43.2837002,
    lat: -23.0077492
  },
  {
    lng: -43.283534,
    lat: -23.0076558
  },
  {
    lng: -43.2834387,
    lat: -23.0076365
  },
  {
    lng: -43.2834096,
    lat: -23.0075503
  },
  {
    lng: -43.2832462,
    lat: -23.0074948
  },
  {
    lng: -43.2832781,
    lat: -23.0074446
  },
  {
    lng: -43.2832087,
    lat: -23.0073812
  },
  {
    lng: -43.2831056,
    lat: -23.0072283
  },
  {
    lng: -43.2830082,
    lat: -23.0072212
  },
  {
    lng: -43.2830157,
    lat: -23.0073113
  },
  {
    lng: -43.2829415,
    lat: -23.0073699
  },
  {
    lng: -43.2828458,
    lat: -23.0073526
  },
  {
    lng: -43.2828368,
    lat: -23.0073101
  },
  {
    lng: -43.2827398,
    lat: -23.0072993
  },
  {
    lng: -43.2826563,
    lat: -23.0072526
  },
  {
    lng: -43.282564,
    lat: -23.0072235
  },
  {
    lng: -43.2824716,
    lat: -23.0072524
  },
  {
    lng: -43.282375,
    lat: -23.0072396
  },
  {
    lng: -43.2823038,
    lat: -23.0071778
  },
  {
    lng: -43.282279,
    lat: -23.0070904
  },
  {
    lng: -43.2823023,
    lat: -23.0070026
  },
  {
    lng: -43.2822845,
    lat: -23.0069138
  },
  {
    lng: -43.2822207,
    lat: -23.0068457
  },
  {
    lng: -43.2821412,
    lat: -23.0067934
  },
  {
    lng: -43.2819529,
    lat: -23.0067489
  },
  {
    lng: -43.2818868,
    lat: -23.0066826
  },
  {
    lng: -43.2818627,
    lat: -23.006595
  },
  {
    lng: -43.2817968,
    lat: -23.0065286
  },
  {
    lng: -43.2817538,
    lat: -23.0064474
  },
  {
    lng: -43.2815556,
    lat: -23.0062485
  },
  {
    lng: -43.281216,
    lat: -23.0061061
  },
  {
    lng: -43.2811591,
    lat: -23.0060931
  },
  {
    lng: -43.2811014,
    lat: -23.0061012
  },
  {
    lng: -43.2810544,
    lat: -23.0061335
  },
  {
    lng: -43.281023,
    lat: -23.0061974
  },
  {
    lng: -43.2809951,
    lat: -23.0061965
  },
  {
    lng: -43.2809473,
    lat: -23.006165
  },
  {
    lng: -43.280881,
    lat: -23.0060152
  },
  {
    lng: -43.280827,
    lat: -23.0059947
  },
  {
    lng: -43.2807695,
    lat: -23.0060052
  },
  {
    lng: -43.2806207,
    lat: -23.006091
  },
  {
    lng: -43.2805383,
    lat: -23.0061663
  },
  {
    lng: -43.2804811,
    lat: -23.0061325
  },
  {
    lng: -43.2803127,
    lat: -23.0061425
  },
  {
    lng: -43.2802566,
    lat: -23.0061269
  },
  {
    lng: -43.2801662,
    lat: -23.0060525
  },
  {
    lng: -43.2800515,
    lat: -23.0060346
  },
  {
    lng: -43.2800052,
    lat: -23.0060014
  },
  {
    lng: -43.2799675,
    lat: -23.0059599
  },
  {
    lng: -43.2799049,
    lat: -23.0058083
  },
  {
    lng: -43.2798456,
    lat: -23.005715
  },
  {
    lng: -43.2798075,
    lat: -23.0056739
  },
  {
    lng: -43.2797086,
    lat: -23.0056162
  },
  {
    lng: -43.2795954,
    lat: -23.0055892
  },
  {
    lng: -43.2794794,
    lat: -23.0055883
  },
  {
    lng: -43.2790194,
    lat: -23.0055083
  },
  {
    lng: -43.2787273,
    lat: -23.0054968
  },
  {
    lng: -43.2786689,
    lat: -23.0055008
  },
  {
    lng: -43.278617,
    lat: -23.0055257
  },
  {
    lng: -43.2784253,
    lat: -23.0055433
  },
  {
    lng: -43.2783641,
    lat: -23.005613
  },
  {
    lng: -43.2782704,
    lat: -23.0056781
  },
  {
    lng: -43.2781671,
    lat: -23.0058718
  },
  {
    lng: -43.2780973,
    lat: -23.0058716
  },
  {
    lng: -43.2780156,
    lat: -23.0057938
  },
  {
    lng: -43.2779689,
    lat: -23.0056957
  },
  {
    lng: -43.2779311,
    lat: -23.0056737
  },
  {
    lng: -43.2778784,
    lat: -23.0058378
  },
  {
    lng: -43.2777923,
    lat: -23.0059099
  },
  {
    lng: -43.2777355,
    lat: -23.0059227
  },
  {
    lng: -43.2776782,
    lat: -23.0059118
  },
  {
    lng: -43.2774224,
    lat: -23.0057806
  },
  {
    lng: -43.2772002,
    lat: -23.0057139
  },
  {
    lng: -43.2771038,
    lat: -23.0056526
  },
  {
    lng: -43.276991,
    lat: -23.0055286
  },
  {
    lng: -43.2769662,
    lat: -23.0055345
  },
  {
    lng: -43.2769264,
    lat: -23.0054941
  },
  {
    lng: -43.2768258,
    lat: -23.005361
  },
  {
    lng: -43.2768311,
    lat: -23.0053211
  },
  {
    lng: -43.276816,
    lat: -23.0052764
  },
  {
    lng: -43.2766676,
    lat: -23.0050441
  },
  {
    lng: -43.2765829,
    lat: -23.0049697
  },
  {
    lng: -43.2764785,
    lat: -23.0049212
  },
  {
    lng: -43.2763756,
    lat: -23.0047268
  },
  {
    lng: -43.2763056,
    lat: -23.0046403
  },
  {
    lng: -43.2762869,
    lat: -23.0046637
  },
  {
    lng: -43.2762514,
    lat: -23.0046733
  },
  {
    lng: -43.2761864,
    lat: -23.0046595
  },
  {
    lng: -43.2760643,
    lat: -23.0047137
  },
  {
    lng: -43.2760223,
    lat: -23.0047092
  },
  {
    lng: -43.2760238,
    lat: -23.0046636
  },
  {
    lng: -43.2760833,
    lat: -23.0046126
  },
  {
    lng: -43.2760395,
    lat: -23.0046055
  },
  {
    lng: -43.2759862,
    lat: -23.0046279
  },
  {
    lng: -43.2759027,
    lat: -23.0045523
  },
  {
    lng: -43.2758567,
    lat: -23.0045967
  },
  {
    lng: -43.2758042,
    lat: -23.0046209
  },
  {
    lng: -43.2757224,
    lat: -23.0045757
  },
  {
    lng: -43.2757149,
    lat: -23.0045161
  },
  {
    lng: -43.2757381,
    lat: -23.004466
  },
  {
    lng: -43.2757238,
    lat: -23.004416
  },
  {
    lng: -43.2757332,
    lat: -23.0043349
  },
  {
    lng: -43.2756497,
    lat: -23.0038706
  },
  {
    lng: -43.275576,
    lat: -23.0035801
  },
  {
    lng: -43.2755828,
    lat: -23.003381
  },
  {
    lng: -43.2755729,
    lat: -23.0032188
  },
  {
    lng: -43.2754869,
    lat: -23.0030127
  },
  {
    lng: -43.2752217,
    lat: -23.0027656
  },
  {
    lng: -43.2745454,
    lat: -23.0023627
  },
  {
    lng: -43.2725829,
    lat: -23.0018214
  },
  {
    lng: -43.2688597,
    lat: -23.0009177
  },
  {
    lng: -43.266773,
    lat: -23.0005464
  },
  {
    lng: -43.2638053,
    lat: -23.0001446
  },
  {
    lng: -43.2576154,
    lat: -22.9994775
  },
  {
    lng: -43.2554748,
    lat: -22.9992445
  },
  {
    lng: -43.2548259,
    lat: -22.9989882
  },
  {
    lng: -43.2544462,
    lat: -22.9987109
  },
  {
    lng: -43.2540207,
    lat: -22.9988104
  },
  {
    lng: -43.2538683,
    lat: -22.99884
  },
  {
    lng: -43.2537,
    lat: -22.9989219
  },
  {
    lng: -43.2534782,
    lat: -22.9990118
  },
  {
    lng: -43.2533624,
    lat: -22.9990276
  },
  {
    lng: -43.2533046,
    lat: -22.9990184
  },
  {
    lng: -43.2532569,
    lat: -22.9989869
  },
  {
    lng: -43.2530834,
    lat: -22.9989691
  },
  {
    lng: -43.2530434,
    lat: -22.9989298
  },
  {
    lng: -43.2530617,
    lat: -22.9988999
  },
  {
    lng: -43.2530384,
    lat: -22.9988796
  },
  {
    lng: -43.2529585,
    lat: -22.998853
  },
  {
    lng: -43.2529432,
    lat: -22.9988256
  },
  {
    lng: -43.2528874,
    lat: -22.9988316
  },
  {
    lng: -43.2526739,
    lat: -22.9989191
  },
  {
    lng: -43.2520672,
    lat: -22.9991167
  },
  {
    lng: -43.2516688,
    lat: -22.999171
  },
  {
    lng: -43.250857,
    lat: -22.9993846
  },
  {
    lng: -43.2507061,
    lat: -22.999442
  },
  {
    lng: -43.250612,
    lat: -22.999514
  },
  {
    lng: -43.2504782,
    lat: -22.9995885
  },
  {
    lng: -43.250247,
    lat: -22.9996109
  },
  {
    lng: -43.250146,
    lat: -22.9995972
  },
  {
    lng: -43.2500922,
    lat: -22.9995515
  },
  {
    lng: -43.2501156,
    lat: -22.999438
  },
  {
    lng: -43.2500178,
    lat: -22.9994469
  },
  {
    lng: -43.2496799,
    lat: -22.9994344
  },
  {
    lng: -43.2493326,
    lat: -22.9994761
  },
  {
    lng: -43.2488292,
    lat: -22.9996049
  },
  {
    lng: -43.2485371,
    lat: -22.9996016
  },
  {
    lng: -43.2484831,
    lat: -22.9996224
  },
  {
    lng: -43.2483662,
    lat: -22.9996259
  },
  {
    lng: -43.2482011,
    lat: -22.9996811
  },
  {
    lng: -43.2480855,
    lat: -22.999696
  },
  {
    lng: -43.247869,
    lat: -22.9997759
  },
  {
    lng: -43.2477014,
    lat: -22.9998735
  },
  {
    lng: -43.2476826,
    lat: -22.9999481
  },
  {
    lng: -43.2474677,
    lat: -23.0001171
  },
  {
    lng: -43.2473888,
    lat: -23.0001969
  },
  {
    lng: -43.2473445,
    lat: -23.0002813
  },
  {
    lng: -43.2473435,
    lat: -23.0003624
  },
  {
    lng: -43.2473174,
    lat: -23.0004109
  },
  {
    lng: -43.2470379,
    lat: -23.0006876
  },
  {
    lng: -43.246943,
    lat: -23.0007511
  },
  {
    lng: -43.2468397,
    lat: -23.000802
  },
  {
    lng: -43.2465076,
    lat: -23.0009046
  },
  {
    lng: -43.2463504,
    lat: -23.0009746
  },
  {
    lng: -43.246251,
    lat: -23.0009929
  },
  {
    lng: -43.2459206,
    lat: -23.0011011
  },
  {
    lng: -43.2457449,
    lat: -23.0011016
  },
  {
    lng: -43.2455755,
    lat: -23.0011414
  },
  {
    lng: -43.2453499,
    lat: -23.0011666
  },
  {
    lng: -43.245173,
    lat: -23.0011658
  },
  {
    lng: -43.2450002,
    lat: -23.0011414
  },
  {
    lng: -43.2449883,
    lat: -23.0011408
  },
  {
    lng: -43.2447663,
    lat: -23.00113
  },
  {
    lng: -43.2441812,
    lat: -23.0011291
  },
  {
    lng: -43.2439539,
    lat: -23.0010782
  },
  {
    lng: -43.2436622,
    lat: -23.0010704
  },
  {
    lng: -43.2436066,
    lat: -23.0010535
  },
  {
    lng: -43.2434386,
    lat: -23.0009035
  },
  {
    lng: -43.2433871,
    lat: -23.0008778
  },
  {
    lng: -43.2433294,
    lat: -23.0008869
  },
  {
    lng: -43.2432725,
    lat: -23.0008748
  },
  {
    lng: -43.2431802,
    lat: -23.0008103
  },
  {
    lng: -43.2430703,
    lat: -23.0007732
  },
  {
    lng: -43.2429872,
    lat: -23.0007614
  },
  {
    lng: -43.2429389,
    lat: -23.0007138
  },
  {
    lng: -43.2429234,
    lat: -23.0006599
  },
  {
    lng: -43.2429119,
    lat: -23.0006522
  },
  {
    lng: -43.2428286,
    lat: -23.0005978
  },
  {
    lng: -43.2427751,
    lat: -23.0006196
  },
  {
    lng: -43.2427237,
    lat: -23.0006151
  },
  {
    lng: -43.2426786,
    lat: -23.0005875
  },
  {
    lng: -43.2427001,
    lat: -23.0005371
  },
  {
    lng: -43.2426425,
    lat: -23.000528
  },
  {
    lng: -43.2426197,
    lat: -23.0005779
  },
  {
    lng: -43.2425908,
    lat: -23.0005307
  },
  {
    lng: -43.2425344,
    lat: -23.0005161
  },
  {
    lng: -43.2423641,
    lat: -23.0004803
  },
  {
    lng: -43.2421322,
    lat: -23.0004551
  },
  {
    lng: -43.2420754,
    lat: -23.0004684
  },
  {
    lng: -43.2420811,
    lat: -23.0005222
  },
  {
    lng: -43.2420168,
    lat: -23.0005003
  },
  {
    lng: -43.2419159,
    lat: -23.0005261
  },
  {
    lng: -43.2419286,
    lat: -23.0005789
  },
  {
    lng: -43.2419018,
    lat: -23.0006277
  },
  {
    lng: -43.2418586,
    lat: -23.0006201
  },
  {
    lng: -43.2418274,
    lat: -23.0005743
  },
  {
    lng: -43.2418029,
    lat: -23.0005718
  },
  {
    lng: -43.2417512,
    lat: -23.0006219
  },
  {
    lng: -43.2417321,
    lat: -23.0006218
  },
  {
    lng: -43.2416519,
    lat: -23.0005854
  },
  {
    lng: -43.2416674,
    lat: -23.0005386
  },
  {
    lng: -43.2416114,
    lat: -23.0005233
  },
  {
    lng: -43.2414956,
    lat: -23.0005075
  },
  {
    lng: -43.2413201,
    lat: -23.0005107
  },
  {
    lng: -43.2410457,
    lat: -23.0004199
  },
  {
    lng: -43.2407566,
    lat: -23.0003835
  },
  {
    lng: -43.2407247,
    lat: -23.0003382
  },
  {
    lng: -43.2405687,
    lat: -23.0002655
  },
  {
    lng: -43.2404547,
    lat: -23.0000793
  },
  {
    lng: -43.2404041,
    lat: -23.0000521
  },
  {
    lng: -43.2403514,
    lat: -23.0000755
  },
  {
    lng: -43.2403215,
    lat: -23.0000291
  },
  {
    lng: -43.2402943,
    lat: -22.9999239
  },
  {
    lng: -43.2402407,
    lat: -22.9999021
  },
  {
    lng: -43.2402552,
    lat: -22.9998495
  },
  {
    lng: -43.2400821,
    lat: -22.9998259
  },
  {
    lng: -43.2401147,
    lat: -22.9997811
  },
  {
    lng: -43.2401726,
    lat: -22.9997725
  },
  {
    lng: -43.2401256,
    lat: -22.9997403
  },
  {
    lng: -43.2400203,
    lat: -22.9997875
  },
  {
    lng: -43.2399924,
    lat: -22.9998352
  },
  {
    lng: -43.2399514,
    lat: -22.9997965
  },
  {
    lng: -43.2399506,
    lat: -22.9997423
  },
  {
    lng: -43.2399206,
    lat: -22.9996957
  },
  {
    lng: -43.239918,
    lat: -22.999644
  },
  {
    lng: -43.2399283,
    lat: -22.9996018
  },
  {
    lng: -43.2400211,
    lat: -22.9994988
  },
  {
    lng: -43.2400148,
    lat: -22.9994594
  },
  {
    lng: -43.2399154,
    lat: -22.9994471
  },
  {
    lng: -43.2398757,
    lat: -22.9994071
  },
  {
    lng: -43.2398652,
    lat: -22.9993158
  },
  {
    lng: -43.2399316,
    lat: -22.9992403
  },
  {
    lng: -43.2399206,
    lat: -22.9992122
  },
  {
    lng: -43.2398105,
    lat: -22.9991827
  },
  {
    lng: -43.2398006,
    lat: -22.9991292
  },
  {
    lng: -43.2398324,
    lat: -22.9991014
  },
  {
    lng: -43.2397096,
    lat: -22.9988351
  },
  {
    lng: -43.2396205,
    lat: -22.9987922
  },
  {
    lng: -43.2395172,
    lat: -22.9988251
  },
  {
    lng: -43.2393721,
    lat: -22.9987292
  },
  {
    lng: -43.2393562,
    lat: -22.998651
  },
  {
    lng: -43.2392916,
    lat: -22.9985834
  },
  {
    lng: -43.2392392,
    lat: -22.9985343
  },
  {
    lng: -43.2391809,
    lat: -22.9985301
  },
  {
    lng: -43.2390889,
    lat: -22.9985968
  },
  {
    lng: -43.2390561,
    lat: -22.9986417
  },
  {
    lng: -43.2390035,
    lat: -22.9986654
  },
  {
    lng: -43.2388868,
    lat: -22.9986594
  },
  {
    lng: -43.2387752,
    lat: -22.9986271
  },
  {
    lng: -43.2387435,
    lat: -22.9985817
  },
  {
    lng: -43.2387681,
    lat: -22.9985326
  },
  {
    lng: -43.2388608,
    lat: -22.998467
  },
  {
    lng: -43.2388796,
    lat: -22.9984155
  },
  {
    lng: -43.2387633,
    lat: -22.9984231
  },
  {
    lng: -43.2387067,
    lat: -22.9984093
  },
  {
    lng: -43.2386007,
    lat: -22.9982802
  },
  {
    lng: -43.2382644,
    lat: -22.9981911
  },
  {
    lng: -43.2379169,
    lat: -22.9979937
  },
  {
    lng: -43.2378014,
    lat: -22.9979977
  },
  {
    lng: -43.2377029,
    lat: -22.9979397
  },
  {
    lng: -43.237713,
    lat: -22.9978863
  },
  {
    lng: -43.2377548,
    lat: -22.9978889
  },
  {
    lng: -43.2378127,
    lat: -22.9979195
  },
  {
    lng: -43.2378208,
    lat: -22.9978983
  },
  {
    lng: -43.2376166,
    lat: -22.9978199
  },
  {
    lng: -43.2373858,
    lat: -22.9976877
  },
  {
    lng: -43.2372993,
    lat: -22.9977089
  },
  {
    lng: -43.2372138,
    lat: -22.9978225
  },
  {
    lng: -43.2371664,
    lat: -22.9976878
  },
  {
    lng: -43.2370854,
    lat: -22.9976779
  },
  {
    lng: -43.2370845,
    lat: -22.997575
  },
  {
    lng: -43.2367281,
    lat: -22.9974295
  },
  {
    lng: -43.2365646,
    lat: -22.9973715
  },
  {
    lng: -43.2363612,
    lat: -22.9972533
  },
  {
    lng: -43.2361687,
    lat: -22.9971969
  },
  {
    lng: -43.2358935,
    lat: -22.9970284
  },
  {
    lng: -43.2354442,
    lat: -22.9968585
  },
  {
    lng: -43.235341,
    lat: -22.996821
  },
  {
    lng: -43.2351599,
    lat: -22.9966825
  },
  {
    lng: -43.2351268,
    lat: -22.9966378
  },
  {
    lng: -43.2350685,
    lat: -22.996633
  },
  {
    lng: -43.2350182,
    lat: -22.9966054
  },
  {
    lng: -43.2350179,
    lat: -22.9965511
  },
  {
    lng: -43.2350454,
    lat: -22.9965259
  },
  {
    lng: -43.2350127,
    lat: -22.9964985
  },
  {
    lng: -43.2349397,
    lat: -22.9965119
  },
  {
    lng: -43.2349109,
    lat: -22.9964338
  },
  {
    lng: -43.2349354,
    lat: -22.9963377
  },
  {
    lng: -43.234907,
    lat: -22.9963408
  },
  {
    lng: -43.2348967,
    lat: -22.9963648
  },
  {
    lng: -43.2348471,
    lat: -22.9963934
  },
  {
    lng: -43.2348568,
    lat: -22.9963399
  },
  {
    lng: -43.2347081,
    lat: -22.996334
  },
  {
    lng: -43.234676,
    lat: -22.9962887
  },
  {
    lng: -43.2346194,
    lat: -22.9963024
  },
  {
    lng: -43.2343866,
    lat: -22.9962911
  },
  {
    lng: -43.2342846,
    lat: -22.9962382
  },
  {
    lng: -43.2342273,
    lat: -22.9962273
  },
  {
    lng: -43.2341233,
    lat: -22.9961777
  },
  {
    lng: -43.2340651,
    lat: -22.9961713
  },
  {
    lng: -43.2340148,
    lat: -22.9961439
  },
  {
    lng: -43.2339601,
    lat: -22.9960482
  },
  {
    lng: -43.2339034,
    lat: -22.9960351
  },
  {
    lng: -43.2338761,
    lat: -22.9959961
  },
  {
    lng: -43.2338858,
    lat: -22.9959184
  },
  {
    lng: -43.2339259,
    lat: -22.9958444
  },
  {
    lng: -43.2340266,
    lat: -22.9957896
  },
  {
    lng: -43.2340489,
    lat: -22.9955751
  },
  {
    lng: -43.2340331,
    lat: -22.9954687
  },
  {
    lng: -43.2340724,
    lat: -22.9953668
  },
  {
    lng: -43.2341192,
    lat: -22.9953343
  },
  {
    lng: -43.2340608,
    lat: -22.9953363
  },
  {
    lng: -43.2340058,
    lat: -22.9953176
  },
  {
    lng: -43.2340196,
    lat: -22.9952651
  },
  {
    lng: -43.2339929,
    lat: -22.9952167
  },
  {
    lng: -43.2339391,
    lat: -22.9951953
  },
  {
    lng: -43.2339251,
    lat: -22.9951426
  },
  {
    lng: -43.2338665,
    lat: -22.995143
  },
  {
    lng: -43.2337634,
    lat: -22.9950925
  },
  {
    lng: -43.2335917,
    lat: -22.9951152
  },
  {
    lng: -43.2335482,
    lat: -22.9951514
  },
  {
    lng: -43.2334479,
    lat: -22.9950968
  },
  {
    lng: -43.2333972,
    lat: -22.9951238
  },
  {
    lng: -43.2333958,
    lat: -22.9951781
  },
  {
    lng: -43.2333591,
    lat: -22.9951887
  },
  {
    lng: -43.2333388,
    lat: -22.9951543
  },
  {
    lng: -43.2332254,
    lat: -22.9951809
  },
  {
    lng: -43.2331208,
    lat: -22.9952289
  },
  {
    lng: -43.2330897,
    lat: -22.9952747
  },
  {
    lng: -43.2329825,
    lat: -22.9953178
  },
  {
    lng: -43.2329505,
    lat: -22.995363
  },
  {
    lng: -43.232902,
    lat: -22.9953933
  },
  {
    lng: -43.2327851,
    lat: -22.9953998
  },
  {
    lng: -43.2327267,
    lat: -22.9953986
  },
  {
    lng: -43.2326182,
    lat: -22.9953578
  },
  {
    lng: -43.2324436,
    lat: -22.9953456
  },
  {
    lng: -43.2323389,
    lat: -22.9952972
  },
  {
    lng: -43.2322239,
    lat: -22.9952785
  },
  {
    lng: -43.231993,
    lat: -22.9952872
  },
  {
    lng: -43.2318916,
    lat: -22.9952343
  },
  {
    lng: -43.23187,
    lat: -22.9952372
  },
  {
    lng: -43.2318173,
    lat: -22.9951901
  },
  {
    lng: -43.2318633,
    lat: -22.9951567
  },
  {
    lng: -43.2317475,
    lat: -22.9951423
  },
  {
    lng: -43.2317573,
    lat: -22.9950887
  },
  {
    lng: -43.2318212,
    lat: -22.994999
  },
  {
    lng: -43.2318022,
    lat: -22.9949599
  },
  {
    lng: -43.2317971,
    lat: -22.9949495
  },
  {
    lng: -43.2317833,
    lat: -22.9948425
  },
  {
    lng: -43.2317943,
    lat: -22.9947893
  },
  {
    lng: -43.2318501,
    lat: -22.9947731
  },
  {
    lng: -43.2318302,
    lat: -22.9947221
  },
  {
    lng: -43.2318732,
    lat: -22.9946212
  },
  {
    lng: -43.2319616,
    lat: -22.9945415
  },
  {
    lng: -43.2320244,
    lat: -22.9945489
  },
  {
    lng: -43.2320543,
    lat: -22.9945352
  },
  {
    lng: -43.2322402,
    lat: -22.9942258
  },
  {
    lng: -43.2323643,
    lat: -22.9942129
  },
  {
    lng: -43.2324996,
    lat: -22.9942459
  },
  {
    lng: -43.2325154,
    lat: -22.9941479
  },
  {
    lng: -43.232694,
    lat: -22.9940546
  },
  {
    lng: -43.2327386,
    lat: -22.9940252
  },
  {
    lng: -43.2327993,
    lat: -22.9939486
  },
  {
    lng: -43.2328546,
    lat: -22.9939301
  },
  {
    lng: -43.23297,
    lat: -22.9939235
  },
  {
    lng: -43.2329726,
    lat: -22.9938784
  },
  {
    lng: -43.2329981,
    lat: -22.9937603
  },
  {
    lng: -43.2329604,
    lat: -22.9932905
  },
  {
    lng: -43.2328087,
    lat: -22.9929155
  },
  {
    lng: -43.2327715,
    lat: -22.9928737
  },
  {
    lng: -43.2327375,
    lat: -22.9928297
  },
  {
    lng: -43.2326988,
    lat: -22.9927889
  },
  {
    lng: -43.2326889,
    lat: -22.9926882
  },
  {
    lng: -43.2326104,
    lat: -22.9926087
  },
  {
    lng: -43.2325476,
    lat: -22.9926185
  },
  {
    lng: -43.2325142,
    lat: -22.9927184
  },
  {
    lng: -43.2324228,
    lat: -22.9926958
  },
  {
    lng: -43.2323862,
    lat: -22.992663
  },
  {
    lng: -43.2323914,
    lat: -22.9926046
  },
  {
    lng: -43.2323894,
    lat: -22.9925504
  },
  {
    lng: -43.2324,
    lat: -22.9924969
  },
  {
    lng: -43.2324772,
    lat: -22.9924804
  },
  {
    lng: -43.2325203,
    lat: -22.9925183
  },
  {
    lng: -43.2325285,
    lat: -22.9924743
  },
  {
    lng: -43.2325271,
    lat: -22.9924201
  },
  {
    lng: -43.2325394,
    lat: -22.9923779
  },
  {
    lng: -43.232528,
    lat: -22.9923246
  },
  {
    lng: -43.2324967,
    lat: -22.992279
  },
  {
    lng: -43.2324529,
    lat: -22.9922431
  },
  {
    lng: -43.2324155,
    lat: -22.9922013
  },
  {
    lng: -43.232365,
    lat: -22.9921489
  },
  {
    lng: -43.2320501,
    lat: -22.9918872
  },
  {
    lng: -43.2319642,
    lat: -22.9917679
  },
  {
    lng: -43.231484,
    lat: -22.9913822
  },
  {
    lng: -43.2313897,
    lat: -22.9913041
  },
  {
    lng: -43.2312846,
    lat: -22.991294
  },
  {
    lng: -43.2311955,
    lat: -22.991289
  },
  {
    lng: -43.2309989,
    lat: -22.9911757
  },
  {
    lng: -43.2308597,
    lat: -22.9910994
  },
  {
    lng: -43.2306509,
    lat: -22.9909567
  },
  {
    lng: -43.2305495,
    lat: -22.9910474
  },
  {
    lng: -43.2305185,
    lat: -22.9910644
  },
  {
    lng: -43.2304057,
    lat: -22.9910531
  },
  {
    lng: -43.2302238,
    lat: -22.9911176
  },
  {
    lng: -43.2301275,
    lat: -22.9911282
  },
  {
    lng: -43.2300385,
    lat: -22.9911648
  },
  {
    lng: -43.2298938,
    lat: -22.9911549
  },
  {
    lng: -43.2298469,
    lat: -22.9911672
  },
  {
    lng: -43.229735,
    lat: -22.991253
  },
  {
    lng: -43.2296863,
    lat: -22.9912513
  },
  {
    lng: -43.2295281,
    lat: -22.9912048
  },
  {
    lng: -43.2295792,
    lat: -22.9911518
  },
  {
    lng: -43.2295542,
    lat: -22.9911248
  },
  {
    lng: -43.2294744,
    lat: -22.9911193
  },
  {
    lng: -43.2294356,
    lat: -22.9911468
  },
  {
    lng: -43.2293976,
    lat: -22.9911488
  },
  {
    lng: -43.2294159,
    lat: -22.991072
  },
  {
    lng: -43.2293543,
    lat: -22.9911135
  },
  {
    lng: -43.2293213,
    lat: -22.9910843
  },
  {
    lng: -43.2293138,
    lat: -22.9911289
  },
  {
    lng: -43.2292798,
    lat: -22.9911612
  },
  {
    lng: -43.2291391,
    lat: -22.9911255
  },
  {
    lng: -43.2288957,
    lat: -22.9911306
  },
  {
    lng: -43.2288499,
    lat: -22.9911152
  },
  {
    lng: -43.2287753,
    lat: -22.991057
  },
  {
    lng: -43.2286815,
    lat: -22.9910335
  },
  {
    lng: -43.2286663,
    lat: -22.9909906
  },
  {
    lng: -43.2286852,
    lat: -22.9909489
  },
  {
    lng: -43.2288423,
    lat: -22.9908497
  },
  {
    lng: -43.2287274,
    lat: -22.9908407
  },
  {
    lng: -43.2287571,
    lat: -22.9907948
  },
  {
    lng: -43.2286814,
    lat: -22.9908026
  },
  {
    lng: -43.2287784,
    lat: -22.9907301
  },
  {
    lng: -43.2287147,
    lat: -22.9906547
  },
  {
    lng: -43.228443,
    lat: -22.9907721
  },
  {
    lng: -43.2283919,
    lat: -22.9907518
  },
  {
    lng: -43.2283659,
    lat: -22.9907138
  },
  {
    lng: -43.2283895,
    lat: -22.9906418
  },
  {
    lng: -43.2283704,
    lat: -22.9906284
  },
  {
    lng: -43.2282963,
    lat: -22.9906864
  },
  {
    lng: -43.228228,
    lat: -22.990806
  },
  {
    lng: -43.228156,
    lat: -22.9908198
  },
  {
    lng: -43.2280945,
    lat: -22.9908592
  },
  {
    lng: -43.2280567,
    lat: -22.9908305
  },
  {
    lng: -43.2280659,
    lat: -22.9907734
  },
  {
    lng: -43.2279652,
    lat: -22.9908064
  },
  {
    lng: -43.2279682,
    lat: -22.9907649
  },
  {
    lng: -43.2279923,
    lat: -22.9907256
  },
  {
    lng: -43.2281082,
    lat: -22.9906442
  },
  {
    lng: -43.2281613,
    lat: -22.9905683
  },
  {
    lng: -43.2282026,
    lat: -22.9905445
  },
  {
    lng: -43.2282414,
    lat: -22.9905169
  },
  {
    lng: -43.228237,
    lat: -22.9904719
  },
  {
    lng: -43.228197,
    lat: -22.9904461
  },
  {
    lng: -43.2280994,
    lat: -22.9904451
  },
  {
    lng: -43.2280047,
    lat: -22.9904653
  },
  {
    lng: -43.2279574,
    lat: -22.9904541
  },
  {
    lng: -43.2278763,
    lat: -22.9904048
  },
  {
    lng: -43.2278275,
    lat: -22.9904046
  },
  {
    lng: -43.2277838,
    lat: -22.9903849
  },
  {
    lng: -43.227639,
    lat: -22.9904026
  },
  {
    lng: -43.2274444,
    lat: -22.9903945
  },
  {
    lng: -43.2273985,
    lat: -22.9903791
  },
  {
    lng: -43.2273756,
    lat: -22.9903594
  },
  {
    lng: -43.2274003,
    lat: -22.990328
  },
  {
    lng: -43.2274506,
    lat: -22.9903002
  },
  {
    lng: -43.2274533,
    lat: -22.9902795
  },
  {
    lng: -43.2273643,
    lat: -22.990316
  },
  {
    lng: -43.2273156,
    lat: -22.9903177
  },
  {
    lng: -43.2272691,
    lat: -22.9903042
  },
  {
    lng: -43.2271012,
    lat: -22.9902127
  },
  {
    lng: -43.2270527,
    lat: -22.9902084
  },
  {
    lng: -43.2270054,
    lat: -22.9902196
  },
  {
    lng: -43.2269649,
    lat: -22.9901945
  },
  {
    lng: -43.2269574,
    lat: -22.9901498
  },
  {
    lng: -43.2269723,
    lat: -22.9901215
  },
  {
    lng: -43.2269248,
    lat: -22.990111
  },
  {
    lng: -43.2269062,
    lat: -22.9900955
  },
  {
    lng: -43.2268582,
    lat: -22.9900868
  },
  {
    lng: -43.226815,
    lat: -22.990066
  },
  {
    lng: -43.2268042,
    lat: -22.990022
  },
  {
    lng: -43.2268124,
    lat: -22.990005
  },
  {
    lng: -43.2268552,
    lat: -22.9899836
  },
  {
    lng: -43.2269073,
    lat: -22.9899867
  },
  {
    lng: -43.2269501,
    lat: -22.9900013
  },
  {
    lng: -43.2269866,
    lat: -22.9899756
  },
  {
    lng: -43.226989,
    lat: -22.9899305
  },
  {
    lng: -43.2269623,
    lat: -22.9898927
  },
  {
    lng: -43.2269453,
    lat: -22.9898505
  },
  {
    lng: -43.2269777,
    lat: -22.9898169
  },
  {
    lng: -43.2270918,
    lat: -22.9897325
  },
  {
    lng: -43.2271102,
    lat: -22.9897043
  },
  {
    lng: -43.2270344,
    lat: -22.9896476
  },
  {
    lng: -43.2269841,
    lat: -22.9896033
  },
  {
    lng: -43.2269413,
    lat: -22.9895526
  },
  {
    lng: -43.2269982,
    lat: -22.989488
  },
  {
    lng: -43.2269579,
    lat: -22.9892468
  },
  {
    lng: -43.2268506,
    lat: -22.9891136
  },
  {
    lng: -43.2262958,
    lat: -22.9887434
  },
  {
    lng: -43.2251018,
    lat: -22.9882789
  },
  {
    lng: -43.223868,
    lat: -22.987953
  },
  {
    lng: -43.2232457,
    lat: -22.9877505
  },
  {
    lng: -43.2223874,
    lat: -22.9875826
  },
  {
    lng: -43.2211697,
    lat: -22.9874813
  },
  {
    lng: -43.2197267,
    lat: -22.987311
  },
  {
    lng: -43.2182998,
    lat: -22.9871678
  },
  {
    lng: -43.2173637,
    lat: -22.9871282
  },
  {
    lng: -43.2169895,
    lat: -22.9871875
  },
  {
    lng: -43.2163189,
    lat: -22.9872258
  },
  {
    lng: -43.2154031,
    lat: -22.9872395
  },
  {
    lng: -43.2110391,
    lat: -22.9871431
  },
  {
    lng: -43.2101298,
    lat: -22.9871949
  },
  {
    lng: -43.2093225,
    lat: -22.9871677
  },
  {
    lng: -43.2089363,
    lat: -22.9870813
  },
  {
    lng: -43.2088343,
    lat: -22.9870863
  },
  {
    lng: -43.2083918,
    lat: -22.9871949
  },
  {
    lng: -43.2081987,
    lat: -22.9872097
  },
  {
    lng: -43.2074745,
    lat: -22.9871455
  },
  {
    lng: -43.2073724,
    lat: -22.9871505
  },
  {
    lng: -43.2070346,
    lat: -22.9871974
  },
  {
    lng: -43.2069166,
    lat: -22.9871949
  },
  {
    lng: -43.2064579,
    lat: -22.987106
  },
  {
    lng: -43.2063211,
    lat: -22.9871159
  },
  {
    lng: -43.2060046,
    lat: -22.9872147
  },
  {
    lng: -43.2057471,
    lat: -22.9872492
  },
  {
    lng: -43.2038508,
    lat: -22.9872937
  },
  {
    lng: -43.2033439,
    lat: -22.9872319
  },
  {
    lng: -43.2032097,
    lat: -22.9872344
  },
  {
    lng: -43.2021234,
    lat: -22.987511
  },
  {
    lng: -43.2020054,
    lat: -22.9875184
  },
  {
    lng: -43.2014744,
    lat: -22.9874295
  },
  {
    lng: -43.2013402,
    lat: -22.9874369
  },
  {
    lng: -43.2007877,
    lat: -22.9876023
  },
  {
    lng: -43.2006509,
    lat: -22.9876295
  },
  {
    lng: -43.1993634,
    lat: -22.9877184
  },
  {
    lng: -43.1986795,
    lat: -22.9877381
  },
  {
    lng: -43.1984515,
    lat: -22.9877381
  },
  {
    lng: -43.197958,
    lat: -22.9876468
  },
  {
    lng: -43.1977782,
    lat: -22.9876492
  },
  {
    lng: -43.1966544,
    lat: -22.9880196
  },
  {
    lng: -43.1965069,
    lat: -22.9880567
  },
  {
    lng: -43.1956674,
    lat: -22.9880888
  },
  {
    lng: -43.1955413,
    lat: -22.9881135
  },
  {
    lng: -43.1946431,
    lat: -22.9883779
  },
  {
    lng: -43.1933472,
    lat: -22.9887457
  },
  {
    lng: -43.1925458,
    lat: -22.9890668
  },
  {
    lng: -43.1918894,
    lat: -22.9893789
  },
  {
    lng: -43.1914133,
    lat: -22.9896962
  },
  {
    lng: -43.1913757,
    lat: -22.9897827
  },
  {
    lng: -43.1913685,
    lat: -22.9898846
  },
  {
    lng: -43.1914826,
    lat: -22.9899078
  },
  {
    lng: -43.1915232,
    lat: -22.9899217
  },
  {
    lng: -43.1915379,
    lat: -22.9899343
  },
  {
    lng: -43.1915322,
    lat: -22.989959
  },
  {
    lng: -43.191502,
    lat: -22.989972
  },
  {
    lng: -43.1915176,
    lat: -22.9900114
  },
  {
    lng: -43.1914974,
    lat: -22.9900319
  },
  {
    lng: -43.1915026,
    lat: -22.9900908
  },
  {
    lng: -43.1916358,
    lat: -22.9901961
  },
  {
    lng: -43.1916303,
    lat: -22.9903042
  },
  {
    lng: -43.1915726,
    lat: -22.9902951
  },
  {
    lng: -43.1915276,
    lat: -22.9903298
  },
  {
    lng: -43.1915718,
    lat: -22.9903654
  },
  {
    lng: -43.1915682,
    lat: -22.9904431
  },
  {
    lng: -43.1916399,
    lat: -22.9904617
  },
  {
    lng: -43.1917104,
    lat: -22.9904778
  },
  {
    lng: -43.1917922,
    lat: -22.9905141
  },
  {
    lng: -43.1918808,
    lat: -22.9905481
  },
  {
    lng: -43.1919564,
    lat: -22.990592
  },
  {
    lng: -43.1919933,
    lat: -22.9906161
  },
  {
    lng: -43.1920223,
    lat: -22.9906447
  },
  {
    lng: -43.1920356,
    lat: -22.9906667
  },
  {
    lng: -43.1920369,
    lat: -22.990692
  },
  {
    lng: -43.1920335,
    lat: -22.9907179
  },
  {
    lng: -43.1920262,
    lat: -22.9907364
  },
  {
    lng: -43.1920191,
    lat: -22.9907432
  },
  {
    lng: -43.1920044,
    lat: -22.9907476
  },
  {
    lng: -43.1919517,
    lat: -22.9907413
  },
  {
    lng: -43.1919183,
    lat: -22.9907334
  },
  {
    lng: -43.19187,
    lat: -22.9907191
  },
  {
    lng: -43.1918461,
    lat: -22.9907068
  },
  {
    lng: -43.191829,
    lat: -22.9906901
  },
  {
    lng: -43.1918176,
    lat: -22.9906781
  },
  {
    lng: -43.1918082,
    lat: -22.9906741
  },
  {
    lng: -43.1917999,
    lat: -22.9906735
  },
  {
    lng: -43.1917919,
    lat: -22.9906744
  },
  {
    lng: -43.1917846,
    lat: -22.9906777
  },
  {
    lng: -43.1917802,
    lat: -22.9906817
  },
  {
    lng: -43.1917774,
    lat: -22.9906877
  },
  {
    lng: -43.1917767,
    lat: -22.9906994
  },
  {
    lng: -43.1917784,
    lat: -22.9907142
  },
  {
    lng: -43.1917801,
    lat: -22.9907309
  },
  {
    lng: -43.1917834,
    lat: -22.990761
  },
  {
    lng: -43.1917885,
    lat: -22.990788
  },
  {
    lng: -43.1917972,
    lat: -22.9907991
  },
  {
    lng: -43.1918129,
    lat: -22.9908074
  },
  {
    lng: -43.1918324,
    lat: -22.9908099
  },
  {
    lng: -43.1918612,
    lat: -22.9908117
  },
  {
    lng: -43.1918767,
    lat: -22.9908191
  },
  {
    lng: -43.1918894,
    lat: -22.9908321
  },
  {
    lng: -43.1918934,
    lat: -22.9908451
  },
  {
    lng: -43.1918914,
    lat: -22.9908648
  },
  {
    lng: -43.1918724,
    lat: -22.990886
  },
  {
    lng: -43.1918585,
    lat: -22.9908994
  },
  {
    lng: -43.1918197,
    lat: -22.9909099
  },
  {
    lng: -43.1917861,
    lat: -22.9909092
  },
  {
    lng: -43.1917452,
    lat: -22.9908827
  },
  {
    lng: -43.1917259,
    lat: -22.9908561
  },
  {
    lng: -43.1917037,
    lat: -22.9908271
  },
  {
    lng: -43.1916822,
    lat: -22.9908167
  },
  {
    lng: -43.1916527,
    lat: -22.9908173
  },
  {
    lng: -43.1916253,
    lat: -22.9908198
  },
  {
    lng: -43.1915876,
    lat: -22.990834
  },
  {
    lng: -43.1915509,
    lat: -22.9908814
  },
  {
    lng: -43.1915353,
    lat: -22.9909136
  },
  {
    lng: -43.1915206,
    lat: -22.9909445
  },
  {
    lng: -43.1915086,
    lat: -22.9909672
  },
  {
    lng: -43.1914931,
    lat: -22.9909821
  },
  {
    lng: -43.1914743,
    lat: -22.990992
  },
  {
    lng: -43.1914126,
    lat: -22.9909987
  },
  {
    lng: -43.1913207,
    lat: -22.9910062
  },
  {
    lng: -43.1912652,
    lat: -22.9910098
  },
  {
    lng: -43.1912229,
    lat: -22.9910019
  },
  {
    lng: -43.1912047,
    lat: -22.9909889
  },
  {
    lng: -43.1911987,
    lat: -22.9909673
  },
  {
    lng: -43.1912047,
    lat: -22.9909259
  },
  {
    lng: -43.1912122,
    lat: -22.9909018
  },
  {
    lng: -43.1912383,
    lat: -22.990879
  },
  {
    lng: -43.1912689,
    lat: -22.9908611
  },
  {
    lng: -43.1912916,
    lat: -22.9908568
  },
  {
    lng: -43.1913124,
    lat: -22.990852
  },
  {
    lng: -43.1913238,
    lat: -22.9908451
  },
  {
    lng: -43.1913291,
    lat: -22.9908355
  },
  {
    lng: -43.1913305,
    lat: -22.9908267
  },
  {
    lng: -43.191329,
    lat: -22.9908188
  },
  {
    lng: -43.1913248,
    lat: -22.9908124
  },
  {
    lng: -43.19131,
    lat: -22.9908013
  },
  {
    lng: -43.1912906,
    lat: -22.9907969
  },
  {
    lng: -43.1912501,
    lat: -22.9907987
  },
  {
    lng: -43.1911304,
    lat: -22.9908
  },
  {
    lng: -43.191053,
    lat: -22.9908009
  },
  {
    lng: -43.191045,
    lat: -22.9908022
  },
  {
    lng: -43.1910395,
    lat: -22.990805
  },
  {
    lng: -43.1910348,
    lat: -22.9908111
  },
  {
    lng: -43.1910339,
    lat: -22.9908154
  },
  {
    lng: -43.1910348,
    lat: -22.9908204
  },
  {
    lng: -43.1910385,
    lat: -22.9908259
  },
  {
    lng: -43.1910435,
    lat: -22.99083
  },
  {
    lng: -43.1910513,
    lat: -22.9908313
  },
  {
    lng: -43.1910596,
    lat: -22.9908306
  }
];

export default polygonCoordsRio1;