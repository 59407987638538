let polygonCoords_Saquarema = [

  {
    lng: -42.6774534,
    lat: -22.8991416
  },
  {
    lng: -42.6772077,
    lat: -22.8995856
  },
  {
    lng: -42.6767636,
    lat: -22.8998094
  },
  {
    lng: -42.6761377,
    lat: -22.9001445
  },
  {
    lng: -42.6754971,
    lat: -22.9008768
  },
  {
    lng: -42.6751506,
    lat: -22.9016933
  },
  {
    lng: -42.6746728,
    lat: -22.9022682
  },
  {
    lng: -42.6740294,
    lat: -22.9031602
  },
  {
    lng: -42.6738856,
    lat: -22.9036485
  },
  {
    lng: -42.6739732,
    lat: -22.9039298
  },
  {
    lng: -42.6740987,
    lat: -22.9041864
  },
  {
    lng: -42.6744127,
    lat: -22.9043236
  },
  {
    lng: -42.6744996,
    lat: -22.9043703
  },
  {
    lng: -42.6748298,
    lat: -22.9045042
  },
  {
    lng: -42.6753489,
    lat: -22.9047649
  },
  {
    lng: -42.6758724,
    lat: -22.9051198
  },
  {
    lng: -42.6762072,
    lat: -22.9052361
  },
  {
    lng: -42.6768615,
    lat: -22.9055801
  },
  {
    lng: -42.6777582,
    lat: -22.9059842
  },
  {
    lng: -42.6780451,
    lat: -22.9063331
  },
  {
    lng: -42.6781863,
    lat: -22.9065168
  },
  {
    lng: -42.6782873,
    lat: -22.9066424
  },
  {
    lng: -42.6784424,
    lat: -22.9069097
  },
  {
    lng: -42.6791868,
    lat: -22.9083204
  },
  {
    lng: -42.6796303,
    lat: -22.908795
  },
  {
    lng: -42.6793533,
    lat: -22.9094454
  },
  {
    lng: -42.679315,
    lat: -22.9094699
  },
  {
    lng: -42.6787454,
    lat: -22.9098355
  },
  {
    lng: -42.6783371,
    lat: -22.9100206
  },
  {
    lng: -42.6778139,
    lat: -22.9101073
  },
  {
    lng: -42.677199,
    lat: -22.910275
  },
  {
    lng: -42.6768952,
    lat: -22.9104028
  },
  {
    lng: -42.6765713,
    lat: -22.9105818
  },
  {
    lng: -42.6762399,
    lat: -22.9106762
  },
  {
    lng: -42.6760021,
    lat: -22.9108055
  },
  {
    lng: -42.675654,
    lat: -22.9107394
  },
  {
    lng: -42.6753412,
    lat: -22.910533
  },
  {
    lng: -42.6752156,
    lat: -22.9104447
  },
  {
    lng: -42.6748739,
    lat: -22.9103241
  },
  {
    lng: -42.6746295,
    lat: -22.910256
  },
  {
    lng: -42.6743392,
    lat: -22.9101106
  },
  {
    lng: -42.6740103,
    lat: -22.9099429
  },
  {
    lng: -42.6739512,
    lat: -22.9099244
  },
  {
    lng: -42.6737877,
    lat: -22.9098216
  },
  {
    lng: -42.6734942,
    lat: -22.9097079
  },
  {
    lng: -42.6732966,
    lat: -22.909717
  },
  {
    lng: -42.6731471,
    lat: -22.9097451
  },
  {
    lng: -42.672994,
    lat: -22.9098536
  },
  {
    lng: -42.6727804,
    lat: -22.9101405
  },
  {
    lng: -42.6727469,
    lat: -22.9101513
  },
  {
    lng: -42.6725505,
    lat: -22.9102345
  },
  {
    lng: -42.6723515,
    lat: -22.9103246
  },
  {
    lng: -42.6719728,
    lat: -22.9103746
  },
  {
    lng: -42.6716101,
    lat: -22.9104368
  },
  {
    lng: -42.6715419,
    lat: -22.9104433
  },
  {
    lng: -42.6712841,
    lat: -22.9105358
  },
  {
    lng: -42.6710794,
    lat: -22.910648
  },
  {
    lng: -42.670851,
    lat: -22.910817
  },
  {
    lng: -42.6706198,
    lat: -22.9109274
  },
  {
    lng: -42.6705639,
    lat: -22.9109391
  },
  {
    lng: -42.6702348,
    lat: -22.9110325
  },
  {
    lng: -42.6701433,
    lat: -22.910968
  },
  {
    lng: -42.6699989,
    lat: -22.9108994
  },
  {
    lng: -42.6696977,
    lat: -22.910677
  },
  {
    lng: -42.6696935,
    lat: -22.9106755
  },
  {
    lng: -42.6694981,
    lat: -22.9106217
  },
  {
    lng: -42.6692058,
    lat: -22.9106218
  },
  {
    lng: -42.6688823,
    lat: -22.9106005
  },
  {
    lng: -42.6686969,
    lat: -22.9105236
  },
  {
    lng: -42.6684874,
    lat: -22.9104553
  },
  {
    lng: -42.6682585,
    lat: -22.910461
  },
  {
    lng: -42.6679658,
    lat: -22.9104903
  },
  {
    lng: -42.6678421,
    lat: -22.9104943
  },
  {
    lng: -42.6677148,
    lat: -22.9104593
  },
  {
    lng: -42.6676248,
    lat: -22.9104095
  },
  {
    lng: -42.6675177,
    lat: -22.9102875
  },
  {
    lng: -42.6673112,
    lat: -22.9101066
  },
  {
    lng: -42.667292,
    lat: -22.9100898
  },
  {
    lng: -42.6670177,
    lat: -22.9098323
  },
  {
    lng: -42.6669903,
    lat: -22.9098105
  },
  {
    lng: -42.6667515,
    lat: -22.9095254
  },
  {
    lng: -42.6667297,
    lat: -22.9094934
  },
  {
    lng: -42.666465,
    lat: -22.9092265
  },
  {
    lng: -42.6664015,
    lat: -22.9091448
  },
  {
    lng: -42.6661206,
    lat: -22.9088957
  },
  {
    lng: -42.6660969,
    lat: -22.908856
  },
  {
    lng: -42.6658709,
    lat: -22.9085956
  },
  {
    lng: -42.6657498,
    lat: -22.9083738
  },
  {
    lng: -42.6656917,
    lat: -22.9081058
  },
  {
    lng: -42.6655816,
    lat: -22.9077997
  },
  {
    lng: -42.6654282,
    lat: -22.9075394
  },
  {
    lng: -42.6653466,
    lat: -22.9074464
  },
  {
    lng: -42.6653427,
    lat: -22.9074476
  },
  {
    lng: -42.6652814,
    lat: -22.9074568
  },
  {
    lng: -42.6651869,
    lat: -22.9074304
  },
  {
    lng: -42.6650536,
    lat: -22.9074546
  },
  {
    lng: -42.6649289,
    lat: -22.9074976
  },
  {
    lng: -42.6647868,
    lat: -22.9076216
  },
  {
    lng: -42.66473,
    lat: -22.9077135
  },
  {
    lng: -42.6643704,
    lat: -22.9078529
  },
  {
    lng: -42.664355,
    lat: -22.9078533
  },
  {
    lng: -42.6642275,
    lat: -22.9078391
  },
  {
    lng: -42.6641764,
    lat: -22.9078541
  },
  {
    lng: -42.6640804,
    lat: -22.9079096
  },
  {
    lng: -42.6640104,
    lat: -22.9080062
  },
  {
    lng: -42.6639203,
    lat: -22.9081199
  },
  {
    lng: -42.6637512,
    lat: -22.908195
  },
  {
    lng: -42.66366,
    lat: -22.9082025
  },
  {
    lng: -42.6634839,
    lat: -22.9081765
  },
  {
    lng: -42.6632237,
    lat: -22.9081929
  },
  {
    lng: -42.6630977,
    lat: -22.9081571
  },
  {
    lng: -42.6629543,
    lat: -22.9080906
  },
  {
    lng: -42.6627839,
    lat: -22.9080123
  },
  {
    lng: -42.662726,
    lat: -22.9079857
  },
  {
    lng: -42.6625206,
    lat: -22.9077972
  },
  {
    lng: -42.6624179,
    lat: -22.9076656
  },
  {
    lng: -42.6623683,
    lat: -22.9076077
  },
  {
    lng: -42.6622618,
    lat: -22.9074944
  },
  {
    lng: -42.6620518,
    lat: -22.9073785
  },
  {
    lng: -42.6619154,
    lat: -22.9073032
  },
  {
    lng: -42.6615817,
    lat: -22.9071368
  },
  {
    lng: -42.6612141,
    lat: -22.906992
  },
  {
    lng: -42.6608993,
    lat: -22.9068927
  },
  {
    lng: -42.6608419,
    lat: -22.9068746
  },
  {
    lng: -42.6605665,
    lat: -22.9067957
  },
  {
    lng: -42.6602067,
    lat: -22.9066455
  },
  {
    lng: -42.6598393,
    lat: -22.9064805
  },
  {
    lng: -42.659499,
    lat: -22.9062914
  },
  {
    lng: -42.6592414,
    lat: -22.9060945
  },
  {
    lng: -42.659125,
    lat: -22.9059823
  },
  {
    lng: -42.6589285,
    lat: -22.9056703
  },
  {
    lng: -42.6588323,
    lat: -22.9055054
  },
  {
    lng: -42.6588122,
    lat: -22.9054825
  },
  {
    lng: -42.6585845,
    lat: -22.9052235
  },
  {
    lng: -42.6582614,
    lat: -22.9050294
  },
  {
    lng: -42.6582522,
    lat: -22.9050268
  },
  {
    lng: -42.6580813,
    lat: -22.9050016
  },
  {
    lng: -42.657689,
    lat: -22.9049897
  },
  {
    lng: -42.6576393,
    lat: -22.9049872
  },
  {
    lng: -42.6574862,
    lat: -22.9049443
  },
  {
    lng: -42.657271,
    lat: -22.904805
  },
  {
    lng: -42.6572454,
    lat: -22.9047884
  },
  {
    lng: -42.6572165,
    lat: -22.9047431
  },
  {
    lng: -42.6571111,
    lat: -22.9047243
  },
  {
    lng: -42.6569066,
    lat: -22.904623
  },
  {
    lng: -42.6567636,
    lat: -22.9045522
  },
  {
    lng: -42.6567233,
    lat: -22.9045287
  },
  {
    lng: -42.6564227,
    lat: -22.9043736
  },
  {
    lng: -42.6561066,
    lat: -22.9041549
  },
  {
    lng: -42.6559633,
    lat: -22.9040997
  },
  {
    lng: -42.6557293,
    lat: -22.9040409
  },
  {
    lng: -42.6555071,
    lat: -22.9039362
  },
  {
    lng: -42.6553319,
    lat: -22.9038586
  },
  {
    lng: -42.6552148,
    lat: -22.9038481
  },
  {
    lng: -42.6549704,
    lat: -22.9038661
  },
  {
    lng: -42.6547879,
    lat: -22.9038796
  },
  {
    lng: -42.6545536,
    lat: -22.9039945
  },
  {
    lng: -42.6541597,
    lat: -22.9041246
  },
  {
    lng: -42.6540702,
    lat: -22.9041381
  },
  {
    lng: -42.6536989,
    lat: -22.9041656
  },
  {
    lng: -42.6534329,
    lat: -22.9041931
  },
  {
    lng: -42.6532836,
    lat: -22.9042685
  },
  {
    lng: -42.6531166,
    lat: -22.9043387
  },
  {
    lng: -42.6530927,
    lat: -22.9043487
  },
  {
    lng: -42.6527021,
    lat: -22.9043293
  },
  {
    lng: -42.6524315,
    lat: -22.9043446
  },
  {
    lng: -42.6523499,
    lat: -22.9043889
  },
  {
    lng: -42.6521801,
    lat: -22.9044811
  },
  {
    lng: -42.6520353,
    lat: -22.9044852
  },
  {
    lng: -42.6518626,
    lat: -22.9046146
  },
  {
    lng: -42.6518414,
    lat: -22.9046306
  },
  {
    lng: -42.6515062,
    lat: -22.9048683
  },
  {
    lng: -42.6512408,
    lat: -22.9051374
  },
  {
    lng: -42.6510456,
    lat: -22.9054758
  },
  {
    lng: -42.6510799,
    lat: -22.9056718
  },
  {
    lng: -42.6510327,
    lat: -22.9056944
  },
  {
    lng: -42.6507086,
    lat: -22.9058493
  },
  {
    lng: -42.6504649,
    lat: -22.9058986
  },
  {
    lng: -42.6502812,
    lat: -22.9059061
  },
  {
    lng: -42.650112,
    lat: -22.9059358
  },
  {
    lng: -42.6499964,
    lat: -22.9059569
  },
  {
    lng: -42.6499352,
    lat: -22.9060111
  },
  {
    lng: -42.6499206,
    lat: -22.9060775
  },
  {
    lng: -42.649856,
    lat: -22.9061623
  },
  {
    lng: -42.6498062,
    lat: -22.906228
  },
  {
    lng: -42.6496822,
    lat: -22.9063095
  },
  {
    lng: -42.6495417,
    lat: -22.9064223
  },
  {
    lng: -42.6494232,
    lat: -22.9064747
  },
  {
    lng: -42.6493103,
    lat: -22.9064993
  },
  {
    lng: -42.6489552,
    lat: -22.9066556
  },
  {
    lng: -42.6486069,
    lat: -22.9068445
  },
  {
    lng: -42.6482521,
    lat: -22.9070151
  },
  {
    lng: -42.6480814,
    lat: -22.9071147
  },
  {
    lng: -42.6479484,
    lat: -22.9071548
  },
  {
    lng: -42.6478532,
    lat: -22.9072352
  },
  {
    lng: -42.6477785,
    lat: -22.9073017
  },
  {
    lng: -42.6476714,
    lat: -22.9073177
  },
  {
    lng: -42.64735,
    lat: -22.9075251
  },
  {
    lng: -42.6472469,
    lat: -22.9075669
  },
  {
    lng: -42.6469939,
    lat: -22.9076847
  },
  {
    lng: -42.6469201,
    lat: -22.9077225
  },
  {
    lng: -42.6467725,
    lat: -22.9077979
  },
  {
    lng: -42.6465855,
    lat: -22.9078653
  },
  {
    lng: -42.6465093,
    lat: -22.9079581
  },
  {
    lng: -42.6463418,
    lat: -22.9081493
  },
  {
    lng: -42.6462565,
    lat: -22.9082468
  },
  {
    lng: -42.6459568,
    lat: -22.9085324
  },
  {
    lng: -42.645695,
    lat: -22.9088003
  },
  {
    lng: -42.6454299,
    lat: -22.9090701
  },
  {
    lng: -42.6451911,
    lat: -22.9093659
  },
  {
    lng: -42.6448906,
    lat: -22.9096112
  },
  {
    lng: -42.6446869,
    lat: -22.9098927
  },
  {
    lng: -42.6446627,
    lat: -22.909926
  },
  {
    lng: -42.6443635,
    lat: -22.9101688
  },
  {
    lng: -42.6441501,
    lat: -22.9104857
  },
  {
    lng: -42.6439037,
    lat: -22.9107825
  },
  {
    lng: -42.6436384,
    lat: -22.9110565
  },
  {
    lng: -42.6434143,
    lat: -22.9113574
  },
  {
    lng: -42.6431793,
    lat: -22.9116545
  },
  {
    lng: -42.6429235,
    lat: -22.9119501
  },
  {
    lng: -42.6426791,
    lat: -22.9122461
  },
  {
    lng: -42.6426321,
    lat: -22.9122961
  },
  {
    lng: -42.6423616,
    lat: -22.9125937
  },
  {
    lng: -42.6420611,
    lat: -22.9128683
  },
  {
    lng: -42.6418182,
    lat: -22.9131596
  },
  {
    lng: -42.6415824,
    lat: -22.9134489
  },
  {
    lng: -42.6412999,
    lat: -22.9137154
  },
  {
    lng: -42.6410251,
    lat: -22.9139866
  },
  {
    lng: -42.6407772,
    lat: -22.914283
  },
  {
    lng: -42.6404891,
    lat: -22.9145505
  },
  {
    lng: -42.6402072,
    lat: -22.9148138
  },
  {
    lng: -42.6400803,
    lat: -22.9149323
  },
  {
    lng: -42.640029,
    lat: -22.9149802
  },
  {
    lng: -42.6398296,
    lat: -22.9152905
  },
  {
    lng: -42.639831,
    lat: -22.9153202
  },
  {
    lng: -42.6398966,
    lat: -22.9154803
  },
  {
    lng: -42.6398355,
    lat: -22.9155985
  },
  {
    lng: -42.6395692,
    lat: -22.9156962
  },
  {
    lng: -42.63943,
    lat: -22.9157637
  },
  {
    lng: -42.6393911,
    lat: -22.915816
  },
  {
    lng: -42.6393521,
    lat: -22.9158684
  },
  {
    lng: -42.639277,
    lat: -22.9159762
  },
  {
    lng: -42.6390839,
    lat: -22.9163149
  },
  {
    lng: -42.6389883,
    lat: -22.9165024
  },
  {
    lng: -42.638991,
    lat: -22.9166932
  },
  {
    lng: -42.6388099,
    lat: -22.9170136
  },
  {
    lng: -42.6387918,
    lat: -22.9170456
  },
  {
    lng: -42.6386978,
    lat: -22.9171299
  },
  {
    lng: -42.6386024,
    lat: -22.9171495
  },
  {
    lng: -42.6384894,
    lat: -22.9171595
  },
  {
    lng: -42.6384259,
    lat: -22.9172328
  },
  {
    lng: -42.6384091,
    lat: -22.9174024
  },
  {
    lng: -42.6382816,
    lat: -22.9176286
  },
  {
    lng: -42.6380687,
    lat: -22.9178698
  },
  {
    lng: -42.6377244,
    lat: -22.9180653
  },
  {
    lng: -42.6374979,
    lat: -22.9181542
  },
  {
    lng: -42.6371848,
    lat: -22.9182026
  },
  {
    lng: -42.6370465,
    lat: -22.9182866
  },
  {
    lng: -42.636809,
    lat: -22.9185831
  },
  {
    lng: -42.6367431,
    lat: -22.9186901
  },
  {
    lng: -42.6367209,
    lat: -22.9187174
  },
  {
    lng: -42.6365043,
    lat: -22.9187951
  },
  {
    lng: -42.6363952,
    lat: -22.9188152
  },
  {
    lng: -42.6360514,
    lat: -22.9189938
  },
  {
    lng: -42.6357167,
    lat: -22.9191793
  },
  {
    lng: -42.635707,
    lat: -22.9191831
  },
  {
    lng: -42.6356404,
    lat: -22.9192161
  },
  {
    lng: -42.6353586,
    lat: -22.9192745
  },
  {
    lng: -42.634973,
    lat: -22.9194304
  },
  {
    lng: -42.6347192,
    lat: -22.9195265
  },
  {
    lng: -42.6345924,
    lat: -22.9195745
  },
  {
    lng: -42.6341909,
    lat: -22.9197361
  },
  {
    lng: -42.6351615,
    lat: -22.9217895
  },
  {
    lng: -42.6355744,
    lat: -22.9226631
  },
  {
    lng: -42.6357762,
    lat: -22.92309
  },
  {
    lng: -42.6360069,
    lat: -22.923578
  },
  {
    lng: -42.6387293,
    lat: -22.9293379
  },
  {
    lng: -42.6404384,
    lat: -22.9329538
  },
  {
    lng: -42.6416462,
    lat: -22.9355092
  },
  {
    lng: -42.6415512,
    lat: -22.9359534
  },
  {
    lng: -42.6413587,
    lat: -22.9368535
  },
  {
    lng: -42.6412124,
    lat: -22.9375378
  },
  {
    lng: -42.6411985,
    lat: -22.9376052
  },
  {
    lng: -42.632408,
    lat: -22.9364163
  },
  {
    lng: -42.6282237,
    lat: -22.9360409
  },
  {
    lng: -42.625166,
    lat: -22.9357939
  },
  {
    lng: -42.623149,
    lat: -22.9355765
  },
  {
    lng: -42.6217864,
    lat: -22.935537
  },
  {
    lng: -42.6187395,
    lat: -22.9352702
  },
  {
    lng: -42.6131926,
    lat: -22.9347762
  },
  {
    lng: -42.6088582,
    lat: -22.934381
  },
  {
    lng: -42.607163,
    lat: -22.9342624
  },
  {
    lng: -42.6053499,
    lat: -22.9340944
  },
  {
    lng: -42.6030754,
    lat: -22.933966
  },
  {
    lng: -42.6013158,
    lat: -22.9338573
  },
  {
    lng: -42.5998674,
    lat: -22.9338178
  },
  {
    lng: -42.5980435,
    lat: -22.9337387
  },
  {
    lng: -42.5949644,
    lat: -22.9337486
  },
  {
    lng: -42.5927435,
    lat: -22.9335905
  },
  {
    lng: -42.5899753,
    lat: -22.9335016
  },
  {
    lng: -42.5884197,
    lat: -22.9334028
  },
  {
    lng: -42.5863061,
    lat: -22.9332446
  },
  {
    lng: -42.5837968,
    lat: -22.9330056
  },
  {
    lng: -42.5822613,
    lat: -22.9330273
  },
  {
    lng: -42.578914,
    lat: -22.9329977
  },
  {
    lng: -42.5747298,
    lat: -22.932968
  },
  {
    lng: -42.5720583,
    lat: -22.9329186
  },
  {
    lng: -42.5693224,
    lat: -22.9328791
  },
  {
    lng: -42.5681745,
    lat: -22.9328988
  },
  {
    lng: -42.5662969,
    lat: -22.9328791
  },
  {
    lng: -42.5635718,
    lat: -22.9329186
  },
  {
    lng: -42.5611149,
    lat: -22.9329384
  },
  {
    lng: -42.5568984,
    lat: -22.9329186
  },
  {
    lng: -42.5511584,
    lat: -22.9330145
  },
  {
    lng: -42.5478759,
    lat: -22.933005
  },
  {
    lng: -42.5444808,
    lat: -22.9329038
  },
  {
    lng: -42.5416579,
    lat: -22.9331465
  },
  {
    lng: -42.5373912,
    lat: -22.9331014
  },
  {
    lng: -42.5341343,
    lat: -22.9332581
  },
  {
    lng: -42.5280593,
    lat: -22.9333336
  },
  {
    lng: -42.5225769,
    lat: -22.9333731
  },
  {
    lng: -42.5198195,
    lat: -22.9333731
  },
  {
    lng: -42.5101786,
    lat: -22.9337859
  },
  {
    lng: -42.5078724,
    lat: -22.9338768
  },
  {
    lng: -42.4996745,
    lat: -22.9345893
  },
  {
    lng: -42.4965631,
    lat: -22.9350894
  },
  {
    lng: -42.4957011,
    lat: -22.9357248
  },
  {
    lng: -42.496045,
    lat: -22.9362748
  },
  {
    lng: -42.4960233,
    lat: -22.9366372
  },
  {
    lng: -42.4955962,
    lat: -22.9364405
  },
  {
    lng: -42.4954617,
    lat: -22.9365407
  },
  {
    lng: -42.4953471,
    lat: -22.9364387
  },
  {
    lng: -42.495092,
    lat: -22.936517
  },
  {
    lng: -42.4948489,
    lat: -22.9362912
  },
  {
    lng: -42.4946216,
    lat: -22.9362676
  },
  {
    lng: -42.4947481,
    lat: -22.9365115
  },
  {
    lng: -42.4945998,
    lat: -22.9364788
  },
  {
    lng: -42.4945127,
    lat: -22.9363094
  },
  {
    lng: -42.4943427,
    lat: -22.9362639
  },
  {
    lng: -42.4940917,
    lat: -22.936426
  },
  {
    lng: -42.4940363,
    lat: -22.936324
  },
  {
    lng: -42.4939256,
    lat: -22.9363494
  },
  {
    lng: -42.4938347,
    lat: -22.9364095
  },
  {
    lng: -42.4937536,
    lat: -22.9364732
  },
  {
    lng: -42.4938524,
    lat: -22.9365516
  },
  {
    lng: -42.4937674,
    lat: -22.9366955
  },
  {
    lng: -42.4937476,
    lat: -22.9368029
  },
  {
    lng: -42.4938861,
    lat: -22.936954
  },
  {
    lng: -42.4937793,
    lat: -22.9371215
  },
  {
    lng: -42.4937614,
    lat: -22.93732
  },
  {
    lng: -42.4934747,
    lat: -22.9374984
  },
  {
    lng: -42.4929883,
    lat: -22.9377442
  },
  {
    lng: -42.4929112,
    lat: -22.9379737
  },
  {
    lng: -42.4925969,
    lat: -22.9381212
  },
  {
    lng: -42.4921926,
    lat: -22.9381729
  },
  {
    lng: -42.4917205,
    lat: -22.9381808
  },
  {
    lng: -42.491403,
    lat: -22.9379753
  },
  {
    lng: -42.4912056,
    lat: -22.9379832
  },
  {
    lng: -42.4909756,
    lat: -22.9376532
  },
  {
    lng: -42.4907678,
    lat: -22.9377144
  },
  {
    lng: -42.4905723,
    lat: -22.9372981
  },
  {
    lng: -42.490509,
    lat: -22.9372417
  },
  {
    lng: -42.490691,
    lat: -22.936985
  },
  {
    lng: -42.4908174,
    lat: -22.9368211
  },
  {
    lng: -42.4910909,
    lat: -22.9366591
  },
  {
    lng: -42.4909824,
    lat: -22.9357225
  },
  {
    lng: -42.4903558,
    lat: -22.935849
  },
  {
    lng: -42.490064,
    lat: -22.9359675
  },
  {
    lng: -42.4896499,
    lat: -22.9364161
  },
  {
    lng: -42.4895404,
    lat: -22.9368054
  },
  {
    lng: -42.489523,
    lat: -22.9370363
  },
  {
    lng: -42.4896027,
    lat: -22.9370743
  },
  {
    lng: -42.4899055,
    lat: -22.9370482
  },
  {
    lng: -42.4900636,
    lat: -22.9371334
  },
  {
    lng: -42.4900407,
    lat: -22.9372405
  },
  {
    lng: -42.4898727,
    lat: -22.9373391
  },
  {
    lng: -42.4896526,
    lat: -22.9373751
  },
  {
    lng: -42.4894134,
    lat: -22.9373597
  },
  {
    lng: -42.4890507,
    lat: -22.937296
  },
  {
    lng: -42.488868,
    lat: -22.9371193
  },
  {
    lng: -42.4887206,
    lat: -22.936746
  },
  {
    lng: -42.4888312,
    lat: -22.9367686
  },
  {
    lng: -42.4889708,
    lat: -22.9369219
  },
  {
    lng: -42.4890792,
    lat: -22.9369279
  },
  {
    lng: -42.4891487,
    lat: -22.9367146
  },
  {
    lng: -42.4889289,
    lat: -22.9363061
  },
  {
    lng: -42.488637,
    lat: -22.936065
  },
  {
    lng: -42.4881565,
    lat: -22.9358578
  },
  {
    lng: -42.4869133,
    lat: -22.9358038
  },
  {
    lng: -42.48666,
    lat: -22.9358041
  },
  {
    lng: -42.4862722,
    lat: -22.9358249
  },
  {
    lng: -42.4856637,
    lat: -22.9358891
  },
  {
    lng: -42.4847934,
    lat: -22.9359718
  },
  {
    lng: -42.4827494,
    lat: -22.936196
  },
  {
    lng: -42.4814261,
    lat: -22.9364095
  },
  {
    lng: -42.4805882,
    lat: -22.9365329
  },
  {
    lng: -42.4797442,
    lat: -22.9366497
  },
  {
    lng: -42.4775864,
    lat: -22.9371025
  },
  {
    lng: -42.4769801,
    lat: -22.9372903
  },
  {
    lng: -42.4765189,
    lat: -22.9373989
  },
  {
    lng: -42.4754244,
    lat: -22.9376903
  },
  {
    lng: -42.4747497,
    lat: -22.9377867
  },
  {
    lng: -42.4733331,
    lat: -22.9376859
  },
  {
    lng: -42.4711076,
    lat: -22.9373409
  },
  {
    lng: -42.4684478,
    lat: -22.9371529
  },
  {
    lng: -42.4647328,
    lat: -22.935992
  },
  {
    lng: -42.4615864,
    lat: -22.9355189
  },
  {
    lng: -42.4589741,
    lat: -22.9353808
  },
  {
    lng: -42.4547599,
    lat: -22.9352069
  },
  {
    lng: -42.4518095,
    lat: -22.935122
  },
  {
    lng: -42.4278842,
    lat: -22.9347343
  },
  {
    lng: -42.4075502,
    lat: -22.9353854
  },
  {
    lng: -42.3909105,
    lat: -22.9360409
  },
  {
    lng: -42.3816365,
    lat: -22.935685
  },
  {
    lng: -42.3816366,
    lat: -22.9356836
  },
  {
    lng: -42.3813434,
    lat: -22.9334498
  },
  {
    lng: -42.380969,
    lat: -22.9305974
  },
  {
    lng: -42.3807134,
    lat: -22.9286503
  },
  {
    lng: -42.3804375,
    lat: -22.9265485
  },
  {
    lng: -42.380407,
    lat: -22.9263158
  },
  {
    lng: -42.3802349,
    lat: -22.925005
  },
  {
    lng: -42.380094,
    lat: -22.9239317
  },
  {
    lng: -42.3785963,
    lat: -22.9125212
  },
  {
    lng: -42.3784931,
    lat: -22.9117355
  },
  {
    lng: -42.378216,
    lat: -22.9096243
  },
  {
    lng: -42.378036,
    lat: -22.9082528
  },
  {
    lng: -42.377951,
    lat: -22.9077318
  },
  {
    lng: -42.3784002,
    lat: -22.9073956
  },
  {
    lng: -42.3791087,
    lat: -22.9068712
  },
  {
    lng: -42.3798212,
    lat: -22.9061435
  },
  {
    lng: -42.3801503,
    lat: -22.9056313
  },
  {
    lng: -42.3804239,
    lat: -22.9048963
  },
  {
    lng: -42.3807548,
    lat: -22.9042917
  },
  {
    lng: -42.3808563,
    lat: -22.9042009
  },
  {
    lng: -42.3811591,
    lat: -22.904021
  },
  {
    lng: -42.3817091,
    lat: -22.9034385
  },
  {
    lng: -42.3820229,
    lat: -22.9026857
  },
  {
    lng: -42.3823274,
    lat: -22.9024134
  },
  {
    lng: -42.3827769,
    lat: -22.9018662
  },
  {
    lng: -42.3831352,
    lat: -22.9008738
  },
  {
    lng: -42.3833496,
    lat: -22.9001009
  },
  {
    lng: -42.3838023,
    lat: -22.8993873
  },
  {
    lng: -42.3844748,
    lat: -22.8986589
  },
  {
    lng: -42.3849902,
    lat: -22.8977985
  },
  {
    lng: -42.3853217,
    lat: -22.8971569
  },
  {
    lng: -42.385794,
    lat: -22.8964622
  },
  {
    lng: -42.386283,
    lat: -22.8959341
  },
  {
    lng: -42.3866388,
    lat: -22.895071
  },
  {
    lng: -42.3867164,
    lat: -22.8941479
  },
  {
    lng: -42.3868398,
    lat: -22.8929113
  },
  {
    lng: -42.3867179,
    lat: -22.891985
  },
  {
    lng: -42.3866677,
    lat: -22.8917971
  },
  {
    lng: -42.3864945,
    lat: -22.8911494
  },
  {
    lng: -42.3862468,
    lat: -22.8905352
  },
  {
    lng: -42.3858001,
    lat: -22.8898993
  },
  {
    lng: -42.3855777,
    lat: -22.8890082
  },
  {
    lng: -42.3854569,
    lat: -22.8880264
  },
  {
    lng: -42.3853461,
    lat: -22.8875624
  },
  {
    lng: -42.385118,
    lat: -22.8869579
  },
  {
    lng: -42.3848824,
    lat: -22.8866832
  },
  {
    lng: -42.384594,
    lat: -22.8862005
  },
  {
    lng: -42.3841039,
    lat: -22.8857487
  },
  {
    lng: -42.3838075,
    lat: -22.8855959
  },
  {
    lng: -42.3836055,
    lat: -22.8854525
  },
  {
    lng: -42.3833548,
    lat: -22.8852742
  },
  {
    lng: -42.383104,
    lat: -22.8848264
  },
  {
    lng: -42.3828713,
    lat: -22.8844713
  },
  {
    lng: -42.3827182,
    lat: -22.884136
  },
  {
    lng: -42.3826347,
    lat: -22.883884
  },
  {
    lng: -42.3825986,
    lat: -22.883775
  },
  {
    lng: -42.3824911,
    lat: -22.8833643
  },
  {
    lng: -42.3823896,
    lat: -22.8829768
  },
  {
    lng: -42.3820396,
    lat: -22.8816397
  },
  {
    lng: -42.3813991,
    lat: -22.8791933
  },
  {
    lng: -42.3805518,
    lat: -22.8759566
  },
  {
    lng: -42.3804116,
    lat: -22.875421
  },
  {
    lng: -42.3804005,
    lat: -22.8753787
  },
  {
    lng: -42.3803308,
    lat: -22.8751126
  },
  {
    lng: -42.3803191,
    lat: -22.8750676
  },
  {
    lng: -42.3802676,
    lat: -22.8748711
  },
  {
    lng: -42.3801814,
    lat: -22.8745419
  },
  {
    lng: -42.3801674,
    lat: -22.8744881
  },
  {
    lng: -42.3798238,
    lat: -22.8731757
  },
  {
    lng: -42.3789077,
    lat: -22.8696766
  },
  {
    lng: -42.3782098,
    lat: -22.8670106
  },
  {
    lng: -42.3777947,
    lat: -22.8654251
  },
  {
    lng: -42.3771397,
    lat: -22.862923
  },
  {
    lng: -42.3762215,
    lat: -22.8594159
  },
  {
    lng: -42.3723307,
    lat: -22.8445536
  },
  {
    lng: -42.3721614,
    lat: -22.8439069
  },
  {
    lng: -42.3714913,
    lat: -22.8413474
  },
  {
    lng: -42.3699335,
    lat: -22.8353968
  },
  {
    lng: -42.3747423,
    lat: -22.8346058
  },
  {
    lng: -42.3808542,
    lat: -22.8336004
  },
  {
    lng: -42.3822383,
    lat: -22.8333727
  },
  {
    lng: -42.3846297,
    lat: -22.8329794
  },
  {
    lng: -42.3848963,
    lat: -22.8329355
  },
  {
    lng: -42.385418,
    lat: -22.8328497
  },
  {
    lng: -42.3858028,
    lat: -22.8327864
  },
  {
    lng: -42.3938671,
    lat: -22.8314599
  },
  {
    lng: -42.3942231,
    lat: -22.8314013
  },
  {
    lng: -42.3943218,
    lat: -22.8313851
  },
  {
    lng: -42.3945204,
    lat: -22.8313524
  },
  {
    lng: -42.3988741,
    lat: -22.8306363
  },
  {
    lng: -42.3995546,
    lat: -22.8305243
  },
  {
    lng: -42.4002351,
    lat: -22.8304124
  },
  {
    lng: -42.4007728,
    lat: -22.8303239
  },
  {
    lng: -42.4261761,
    lat: -22.8261453
  },
  {
    lng: -42.4263169,
    lat: -22.8261221
  },
  {
    lng: -42.4268907,
    lat: -22.8260277
  },
  {
    lng: -42.434778,
    lat: -22.8247303
  },
  {
    lng: -42.4348084,
    lat: -22.8247253
  },
  {
    lng: -42.435055,
    lat: -22.8246848
  },
  {
    lng: -42.4457997,
    lat: -22.8229173
  },
  {
    lng: -42.4458401,
    lat: -22.8229107
  },
  {
    lng: -42.4458833,
    lat: -22.8229036
  },
  {
    lng: -42.4468128,
    lat: -22.8227507
  },
  {
    lng: -42.4472214,
    lat: -22.8229778
  },
  {
    lng: -42.4477197,
    lat: -22.8230287
  },
  {
    lng: -42.448216,
    lat: -22.8229126
  },
  {
    lng: -42.4487515,
    lat: -22.8226768
  },
  {
    lng: -42.4490091,
    lat: -22.8225064
  },
  {
    lng: -42.4490549,
    lat: -22.8224761
  },
  {
    lng: -42.44907,
    lat: -22.8224662
  },
  {
    lng: -42.4492952,
    lat: -22.8220239
  },
  {
    lng: -42.4495384,
    lat: -22.8216934
  },
  {
    lng: -42.4498445,
    lat: -22.8213066
  },
  {
    lng: -42.4503883,
    lat: -22.8209035
  },
  {
    lng: -42.4506476,
    lat: -22.8207765
  },
  {
    lng: -42.4508691,
    lat: -22.8207212
  },
  {
    lng: -42.4512259,
    lat: -22.8207222
  },
  {
    lng: -42.4521141,
    lat: -22.8209082
  },
  {
    lng: -42.452679,
    lat: -22.8206017
  },
  {
    lng: -42.4527128,
    lat: -22.8205834
  },
  {
    lng: -42.4528619,
    lat: -22.8204218
  },
  {
    lng: -42.4528837,
    lat: -22.8203982
  },
  {
    lng: -42.4529054,
    lat: -22.8203747
  },
  {
    lng: -42.4529555,
    lat: -22.8203204
  },
  {
    lng: -42.4532124,
    lat: -22.820042
  },
  {
    lng: -42.4536705,
    lat: -22.8191929
  },
  {
    lng: -42.4542623,
    lat: -22.8190079
  },
  {
    lng: -42.455013,
    lat: -22.8191632
  },
  {
    lng: -42.4558189,
    lat: -22.8196139
  },
  {
    lng: -42.4565799,
    lat: -22.8197025
  },
  {
    lng: -42.4570506,
    lat: -22.819882
  },
  {
    lng: -42.4577882,
    lat: -22.8201231
  },
  {
    lng: -42.457836,
    lat: -22.8201443
  },
  {
    lng: -42.4582789,
    lat: -22.8203411
  },
  {
    lng: -42.458331,
    lat: -22.8203604
  },
  {
    lng: -42.4585909,
    lat: -22.8204563
  },
  {
    lng: -42.4590363,
    lat: -22.8206207
  },
  {
    lng: -42.4598351,
    lat: -22.820715
  },
  {
    lng: -42.4605481,
    lat: -22.8202436
  },
  {
    lng: -42.4608607,
    lat: -22.8197843
  },
  {
    lng: -42.4608845,
    lat: -22.8197493
  },
  {
    lng: -42.4609083,
    lat: -22.8197143
  },
  {
    lng: -42.4610834,
    lat: -22.8195488
  },
  {
    lng: -42.461286,
    lat: -22.8193572
  },
  {
    lng: -42.4617359,
    lat: -22.8190893
  },
  {
    lng: -42.4618495,
    lat: -22.8191461
  },
  {
    lng: -42.4620187,
    lat: -22.8192308
  },
  {
    lng: -42.4621879,
    lat: -22.8193154
  },
  {
    lng: -42.4623841,
    lat: -22.8194135
  },
  {
    lng: -42.4624695,
    lat: -22.8194562
  },
  {
    lng: -42.4625408,
    lat: -22.8194919
  },
  {
    lng: -42.4628797,
    lat: -22.8193908
  },
  {
    lng: -42.4631239,
    lat: -22.8193179
  },
  {
    lng: -42.463267,
    lat: -22.8192752
  },
  {
    lng: -42.4633436,
    lat: -22.8192524
  },
  {
    lng: -42.4634528,
    lat: -22.8192198
  },
  {
    lng: -42.4638051,
    lat: -22.8191203
  },
  {
    lng: -42.4641573,
    lat: -22.8190208
  },
  {
    lng: -42.4647089,
    lat: -22.8192805
  },
  {
    lng: -42.4648112,
    lat: -22.8193287
  },
  {
    lng: -42.4649134,
    lat: -22.8193768
  },
  {
    lng: -42.4657544,
    lat: -22.8196004
  },
  {
    lng: -42.4666789,
    lat: -22.819768
  },
  {
    lng: -42.4682095,
    lat: -22.8202255
  },
  {
    lng: -42.4690404,
    lat: -22.8203845
  },
  {
    lng: -42.469538,
    lat: -22.8206987
  },
  {
    lng: -42.4704784,
    lat: -22.8205162
  },
  {
    lng: -42.4709092,
    lat: -22.8202619
  },
  {
    lng: -42.4714134,
    lat: -22.8201107
  },
  {
    lng: -42.4720018,
    lat: -22.8201836
  },
  {
    lng: -42.4724083,
    lat: -22.8202546
  },
  {
    lng: -42.4728279,
    lat: -22.820446
  },
  {
    lng: -42.4731506,
    lat: -22.8206396
  },
  {
    lng: -42.4736798,
    lat: -22.8210628
  },
  {
    lng: -42.474111,
    lat: -22.8213764
  },
  {
    lng: -42.4747619,
    lat: -22.8218454
  },
  {
    lng: -42.4753319,
    lat: -22.8222366
  },
  {
    lng: -42.4756737,
    lat: -22.8224474
  },
  {
    lng: -42.4761268,
    lat: -22.8227269
  },
  {
    lng: -42.4766185,
    lat: -22.8228875
  },
  {
    lng: -42.4774072,
    lat: -22.8230132
  },
  {
    lng: -42.4775077,
    lat: -22.8236253
  },
  {
    lng: -42.4776525,
    lat: -22.8240086
  },
  {
    lng: -42.4777789,
    lat: -22.8244357
  },
  {
    lng: -42.4779793,
    lat: -22.8250922
  },
  {
    lng: -42.4779053,
    lat: -22.8260761
  },
  {
    lng: -42.4782626,
    lat: -22.8264137
  },
  {
    lng: -42.4785876,
    lat: -22.8266338
  },
  {
    lng: -42.4803153,
    lat: -22.8274064
  },
  {
    lng: -42.4808357,
    lat: -22.8276917
  },
  {
    lng: -42.4813122,
    lat: -22.8281729
  },
  {
    lng: -42.4816092,
    lat: -22.8284472
  },
  {
    lng: -42.4821681,
    lat: -22.8286586
  },
  {
    lng: -42.4825429,
    lat: -22.8287731
  },
  {
    lng: -42.4830192,
    lat: -22.8290295
  },
  {
    lng: -42.483339,
    lat: -22.8292834
  },
  {
    lng: -42.4835668,
    lat: -22.8296707
  },
  {
    lng: -42.4837301,
    lat: -22.8299118
  },
  {
    lng: -42.4839078,
    lat: -22.8295671
  },
  {
    lng: -42.4840778,
    lat: -22.8292536
  },
  {
    lng: -42.4843504,
    lat: -22.8290298
  },
  {
    lng: -42.4846365,
    lat: -22.8286817
  },
  {
    lng: -42.485304,
    lat: -22.8282775
  },
  {
    lng: -42.4858677,
    lat: -22.8280375
  },
  {
    lng: -42.4864218,
    lat: -22.8277144
  },
  {
    lng: -42.4868565,
    lat: -22.8275595
  },
  {
    lng: -42.4871966,
    lat: -22.8275607
  },
  {
    lng: -42.487652,
    lat: -22.8277392
  },
  {
    lng: -42.4883447,
    lat: -22.8277863
  },
  {
    lng: -42.4887579,
    lat: -22.8278522
  },
  {
    lng: -42.4892037,
    lat: -22.8279139
  },
  {
    lng: -42.489501,
    lat: -22.8280789
  },
  {
    lng: -42.4901171,
    lat: -22.8282081
  },
  {
    lng: -42.4907726,
    lat: -22.8283741
  },
  {
    lng: -42.4912938,
    lat: -22.8286804
  },
  {
    lng: -42.4916992,
    lat: -22.8292065
  },
  {
    lng: -42.492232,
    lat: -22.8295073
  },
  {
    lng: -42.4931422,
    lat: -22.8297077
  },
  {
    lng: -42.494203,
    lat: -22.8298177
  },
  {
    lng: -42.4950214,
    lat: -22.8299117
  },
  {
    lng: -42.4950403,
    lat: -22.8299138
  },
  {
    lng: -42.4951091,
    lat: -22.8299258
  },
  {
    lng: -42.4957651,
    lat: -22.8300393
  },
  {
    lng: -42.4963196,
    lat: -22.8303073
  },
  {
    lng: -42.4967751,
    lat: -22.8304804
  },
  {
    lng: -42.4971153,
    lat: -22.8308383
  },
  {
    lng: -42.4977181,
    lat: -22.83091
  },
  {
    lng: -42.4983648,
    lat: -22.8309175
  },
  {
    lng: -42.4988733,
    lat: -22.8307518
  },
  {
    lng: -42.4993921,
    lat: -22.8305215
  },
  {
    lng: -42.5001719,
    lat: -22.8299431
  },
  {
    lng: -42.5010138,
    lat: -22.8293233
  },
  {
    lng: -42.5017493,
    lat: -22.8288682
  },
  {
    lng: -42.5018853,
    lat: -22.828632
  },
  {
    lng: -42.5020306,
    lat: -22.8281675
  },
  {
    lng: -42.5023744,
    lat: -22.8277166
  },
  {
    lng: -42.5025156,
    lat: -22.8273351
  },
  {
    lng: -42.5025349,
    lat: -22.8268895
  },
  {
    lng: -42.5029082,
    lat: -22.8262356
  },
  {
    lng: -42.5035922,
    lat: -22.8257495
  },
  {
    lng: -42.504128,
    lat: -22.8257579
  },
  {
    lng: -42.504649,
    lat: -22.8258998
  },
  {
    lng: -42.5050453,
    lat: -22.8262431
  },
  {
    lng: -42.5052739,
    lat: -22.8266125
  },
  {
    lng: -42.5058194,
    lat: -22.8268191
  },
  {
    lng: -42.5063386,
    lat: -22.8267989
  },
  {
    lng: -42.5069536,
    lat: -22.8265398
  },
  {
    lng: -42.5073876,
    lat: -22.8262647
  },
  {
    lng: -42.5079503,
    lat: -22.8262725
  },
  {
    lng: -42.5084542,
    lat: -22.8262521
  },
  {
    lng: -42.509266,
    lat: -22.8258543
  },
  {
    lng: -42.5097872,
    lat: -22.8254891
  },
  {
    lng: -42.510373,
    lat: -22.8246479
  },
  {
    lng: -42.5111213,
    lat: -22.8242312
  },
  {
    lng: -42.5115238,
    lat: -22.8247282
  },
  {
    lng: -42.5122004,
    lat: -22.8250498
  },
  {
    lng: -42.5128963,
    lat: -22.8255377
  },
  {
    lng: -42.5133537,
    lat: -22.8256071
  },
  {
    lng: -42.5139771,
    lat: -22.8255196
  },
  {
    lng: -42.5145384,
    lat: -22.8256293
  },
  {
    lng: -42.5149879,
    lat: -22.8258465
  },
  {
    lng: -42.5154853,
    lat: -22.8257014
  },
  {
    lng: -42.5161294,
    lat: -22.8254249
  },
  {
    lng: -42.5171395,
    lat: -22.8247329
  },
  {
    lng: -42.5178514,
    lat: -22.8243395
  },
  {
    lng: -42.5182572,
    lat: -22.8241696
  },
  {
    lng: -42.5187063,
    lat: -22.823823
  },
  {
    lng: -42.5188267,
    lat: -22.8234867
  },
  {
    lng: -42.5192493,
    lat: -22.8223872
  },
  {
    lng: -42.5198126,
    lat: -22.8217599
  },
  {
    lng: -42.520386,
    lat: -22.8213955
  },
  {
    lng: -42.5208503,
    lat: -22.8204626
  },
  {
    lng: -42.5213782,
    lat: -22.820139
  },
  {
    lng: -42.521716,
    lat: -22.819605
  },
  {
    lng: -42.5220824,
    lat: -22.8191406
  },
  {
    lng: -42.5230591,
    lat: -22.818741
  },
  {
    lng: -42.5241147,
    lat: -22.8181075
  },
  {
    lng: -42.525408,
    lat: -22.8175193
  },
  {
    lng: -42.5260726,
    lat: -22.8170595
  },
  {
    lng: -42.5264466,
    lat: -22.8163545
  },
  {
    lng: -42.5265115,
    lat: -22.8166012
  },
  {
    lng: -42.5266339,
    lat: -22.8167188
  },
  {
    lng: -42.5267647,
    lat: -22.8168933
  },
  {
    lng: -42.5267712,
    lat: -22.8170231
  },
  {
    lng: -42.5266478,
    lat: -22.817497
  },
  {
    lng: -42.5265894,
    lat: -22.8177631
  },
  {
    lng: -42.5265959,
    lat: -22.8179903
  },
  {
    lng: -42.5266673,
    lat: -22.8180682
  },
  {
    lng: -42.526901,
    lat: -22.8181331
  },
  {
    lng: -42.5270503,
    lat: -22.8182175
  },
  {
    lng: -42.5272126,
    lat: -22.8182889
  },
  {
    lng: -42.5274883,
    lat: -22.8183358
  },
  {
    lng: -42.5283104,
    lat: -22.8165729
  },
  {
    lng: -42.5287859,
    lat: -22.8155532
  },
  {
    lng: -42.5319499,
    lat: -22.8087681
  },
  {
    lng: -42.5326212,
    lat: -22.8086841
  },
  {
    lng: -42.5331036,
    lat: -22.8089988
  },
  {
    lng: -42.5343413,
    lat: -22.8101735
  },
  {
    lng: -42.535495,
    lat: -22.8108448
  },
  {
    lng: -42.5366697,
    lat: -22.8114112
  },
  {
    lng: -42.5379703,
    lat: -22.8120824
  },
  {
    lng: -42.539166,
    lat: -22.8123971
  },
  {
    lng: -42.5408989,
    lat: -22.8126722
  },
  {
    lng: -42.5427951,
    lat: -22.8131103
  },
  {
    lng: -42.5445781,
    lat: -22.8136977
  },
  {
    lng: -42.5451865,
    lat: -22.8140543
  },
  {
    lng: -42.5456899,
    lat: -22.8141802
  },
  {
    lng: -42.5468017,
    lat: -22.8140333
  },
  {
    lng: -42.5482793,
    lat: -22.8139283
  },
  {
    lng: -42.5485037,
    lat: -22.8138954
  },
  {
    lng: -42.5496703,
    lat: -22.8139521
  },
  {
    lng: -42.5505356,
    lat: -22.8142011
  },
  {
    lng: -42.5512069,
    lat: -22.8146417
  },
  {
    lng: -42.552004,
    lat: -22.8148724
  },
  {
    lng: -42.5531578,
    lat: -22.8150612
  },
  {
    lng: -42.5536088,
    lat: -22.8149773
  },
  {
    lng: -42.5540388,
    lat: -22.8148095
  },
  {
    lng: -42.5543535,
    lat: -22.8143899
  },
  {
    lng: -42.5545842,
    lat: -22.8138131
  },
  {
    lng: -42.5555911,
    lat: -22.81203
  },
  {
    lng: -42.5561156,
    lat: -22.8117678
  },
  {
    lng: -42.5567239,
    lat: -22.811579
  },
  {
    lng: -42.5578357,
    lat: -22.8114531
  },
  {
    lng: -42.5585069,
    lat: -22.8118097
  },
  {
    lng: -42.55948,
    lat: -22.8118667
  },
  {
    lng: -42.5600322,
    lat: -22.8116912
  },
  {
    lng: -42.5604551,
    lat: -22.8114272
  },
  {
    lng: -42.5608115,
    lat: -22.8110249
  },
  {
    lng: -42.5616711,
    lat: -22.8090642
  },
  {
    lng: -42.5619244,
    lat: -22.8080322
  },
  {
    lng: -42.562567,
    lat: -22.8071345
  },
  {
    lng: -42.5630326,
    lat: -22.8067115
  },
  {
    lng: -42.5635353,
    lat: -22.8065431
  },
  {
    lng: -42.5645618,
    lat: -22.8064197
  },
  {
    lng: -42.5650743,
    lat: -22.8063929
  },
  {
    lng: -42.5655867,
    lat: -22.8063662
  },
  {
    lng: -42.5660902,
    lat: -22.8060935
  },
  {
    lng: -42.5663629,
    lat: -22.8056425
  },
  {
    lng: -42.5666775,
    lat: -22.8048663
  },
  {
    lng: -42.5668873,
    lat: -22.8045726
  },
  {
    lng: -42.56716,
    lat: -22.8043629
  },
  {
    lng: -42.5674747,
    lat: -22.804195
  },
  {
    lng: -42.5679859,
    lat: -22.8040514
  },
  {
    lng: -42.5685638,
    lat: -22.804021
  },
  {
    lng: -42.5695409,
    lat: -22.8040062
  },
  {
    lng: -42.5703381,
    lat: -22.8041846
  },
  {
    lng: -42.5713659,
    lat: -22.804646
  },
  {
    lng: -42.5723204,
    lat: -22.8049397
  },
  {
    lng: -42.5734532,
    lat: -22.805118
  },
  {
    lng: -42.5745754,
    lat: -22.8053173
  },
  {
    lng: -42.5764529,
    lat: -22.8054537
  },
  {
    lng: -42.5766965,
    lat: -22.805426
  },
  {
    lng: -42.5769144,
    lat: -22.8054012
  },
  {
    lng: -42.5773376,
    lat: -22.8054073
  },
  {
    lng: -42.578173,
    lat: -22.8057578
  },
  {
    lng: -42.5785191,
    lat: -22.8059257
  },
  {
    lng: -42.5788653,
    lat: -22.8059886
  },
  {
    lng: -42.5792743,
    lat: -22.8059257
  },
  {
    lng: -42.5797558,
    lat: -22.8058095
  },
  {
    lng: -42.5802078,
    lat: -22.8056215
  },
  {
    lng: -42.5807112,
    lat: -22.8056215
  },
  {
    lng: -42.5813511,
    lat: -22.8057369
  },
  {
    lng: -42.5819279,
    lat: -22.8059886
  },
  {
    lng: -42.5835956,
    lat: -22.8068382
  },
  {
    lng: -42.5844452,
    lat: -22.807027
  },
  {
    lng: -42.585043,
    lat: -22.8074885
  },
  {
    lng: -42.5856514,
    lat: -22.8077192
  },
  {
    lng: -42.5872456,
    lat: -22.8081492
  },
  {
    lng: -42.5879379,
    lat: -22.809303
  },
  {
    lng: -42.5890287,
    lat: -22.8101001
  },
  {
    lng: -42.5904473,
    lat: -22.8108563
  },
  {
    lng: -42.5909518,
    lat: -22.811257
  },
  {
    lng: -42.5919281,
    lat: -22.8117159
  },
  {
    lng: -42.5922217,
    lat: -22.8123522
  },
  {
    lng: -42.593019,
    lat: -22.8122899
  },
  {
    lng: -42.5934754,
    lat: -22.8123447
  },
  {
    lng: -42.5940566,
    lat: -22.812971
  },
  {
    lng: -42.5946844,
    lat: -22.8135301
  },
  {
    lng: -42.5951118,
    lat: -22.8144226
  },
  {
    lng: -42.5958846,
    lat: -22.8146164
  },
  {
    lng: -42.5963646,
    lat: -22.8146755
  },
  {
    lng: -42.5971364,
    lat: -22.8146768
  },
  {
    lng: -42.5976568,
    lat: -22.8149854
  },
  {
    lng: -42.5982348,
    lat: -22.8155134
  },
  {
    lng: -42.5990016,
    lat: -22.8154827
  },
  {
    lng: -42.5995879,
    lat: -22.8152219
  },
  {
    lng: -42.5999788,
    lat: -22.8152589
  },
  {
    lng: -42.600607,
    lat: -22.8157972
  },
  {
    lng: -42.6010313,
    lat: -22.8164777
  },
  {
    lng: -42.6017667,
    lat: -22.8166443
  },
  {
    lng: -42.6018845,
    lat: -22.8176901
  },
  {
    lng: -42.6014869,
    lat: -22.818251
  },
  {
    lng: -42.6014603,
    lat: -22.8189143
  },
  {
    lng: -42.6010085,
    lat: -22.8200137
  },
  {
    lng: -42.6022856,
    lat: -22.8203371
  },
  {
    lng: -42.6030764,
    lat: -22.8203213
  },
  {
    lng: -42.6035753,
    lat: -22.8204249
  },
  {
    lng: -42.6043429,
    lat: -22.8207351
  },
  {
    lng: -42.6047961,
    lat: -22.8209279
  },
  {
    lng: -42.6055379,
    lat: -22.8207769
  },
  {
    lng: -42.6065667,
    lat: -22.8208929
  },
  {
    lng: -42.6072221,
    lat: -22.8211247
  },
  {
    lng: -42.607735,
    lat: -22.8212638
  },
  {
    lng: -42.608799,
    lat: -22.821472
  },
  {
    lng: -42.6091446,
    lat: -22.8221824
  },
  {
    lng: -42.6098262,
    lat: -22.8224415
  },
  {
    lng: -42.6112171,
    lat: -22.8230846
  },
  {
    lng: -42.6114988,
    lat: -22.8231856
  },
  {
    lng: -42.611976,
    lat: -22.8231928
  },
  {
    lng: -42.6117467,
    lat: -22.8235074
  },
  {
    lng: -42.6123041,
    lat: -22.8247762
  },
  {
    lng: -42.6132035,
    lat: -22.8253708
  },
  {
    lng: -42.6136446,
    lat: -22.8259388
  },
  {
    lng: -42.6141197,
    lat: -22.82677
  },
  {
    lng: -42.6143835,
    lat: -22.8271561
  },
  {
    lng: -42.6153488,
    lat: -22.8279589
  },
  {
    lng: -42.6161245,
    lat: -22.8285438
  },
  {
    lng: -42.6171345,
    lat: -22.8290008
  },
  {
    lng: -42.6185417,
    lat: -22.8295832
  },
  {
    lng: -42.6200721,
    lat: -22.8297494
  },
  {
    lng: -42.6207833,
    lat: -22.8296714
  },
  {
    lng: -42.6219105,
    lat: -22.8300477
  },
  {
    lng: -42.6226954,
    lat: -22.8303774
  },
  {
    lng: -42.6232161,
    lat: -22.8313117
  },
  {
    lng: -42.6236572,
    lat: -22.8317975
  },
  {
    lng: -42.6242674,
    lat: -22.8322724
  },
  {
    lng: -42.6248808,
    lat: -22.8325682
  },
  {
    lng: -42.625405,
    lat: -22.832803
  },
  {
    lng: -42.6263741,
    lat: -22.8333907
  },
  {
    lng: -42.6271733,
    lat: -22.8326263
  },
  {
    lng: -42.6278947,
    lat: -22.832637
  },
  {
    lng: -42.6286251,
    lat: -22.8328748
  },
  {
    lng: -42.6291676,
    lat: -22.8331756
  },
  {
    lng: -42.6295154,
    lat: -22.8334574
  },
  {
    lng: -42.6296403,
    lat: -22.8337738
  },
  {
    lng: -42.6298136,
    lat: -22.8341824
  },
  {
    lng: -42.6300534,
    lat: -22.8344727
  },
  {
    lng: -42.6305254,
    lat: -22.8347696
  },
  {
    lng: -42.6307737,
    lat: -22.8350775
  },
  {
    lng: -42.6312498,
    lat: -22.8353265
  },
  {
    lng: -42.6317224,
    lat: -22.8354235
  },
  {
    lng: -42.6321071,
    lat: -22.8357592
  },
  {
    lng: -42.6329388,
    lat: -22.8362997
  },
  {
    lng: -42.6337307,
    lat: -22.8366148
  },
  {
    lng: -42.6342931,
    lat: -22.8368721
  },
  {
    lng: -42.6345884,
    lat: -22.8374529
  },
  {
    lng: -42.6345294,
    lat: -22.8378712
  },
  {
    lng: -42.6346755,
    lat: -22.8382397
  },
  {
    lng: -42.6350178,
    lat: -22.8387387
  },
  {
    lng: -42.6356003,
    lat: -22.8393326
  },
  {
    lng: -42.6359914,
    lat: -22.8398161
  },
  {
    lng: -42.6359675,
    lat: -22.8401956
  },
  {
    lng: -42.6355498,
    lat: -22.8410606
  },
  {
    lng: -42.6355702,
    lat: -22.8418231
  },
  {
    lng: -42.6348425,
    lat: -22.8426523
  },
  {
    lng: -42.6338797,
    lat: -22.8434936
  },
  {
    lng: -42.6331276,
    lat: -22.8441986
  },
  {
    lng: -42.6324717,
    lat: -22.8448415
  },
  {
    lng: -42.6320662,
    lat: -22.845481
  },
  {
    lng: -42.6323669,
    lat: -22.8458218
  },
  {
    lng: -42.6332572,
    lat: -22.8464866
  },
  {
    lng: -42.6339503,
    lat: -22.8468261
  },
  {
    lng: -42.6345668,
    lat: -22.8472627
  },
  {
    lng: -42.6352958,
    lat: -22.8479791
  },
  {
    lng: -42.6357854,
    lat: -22.8487875
  },
  {
    lng: -42.6364791,
    lat: -22.8494356
  },
  {
    lng: -42.6365154,
    lat: -22.8497628
  },
  {
    lng: -42.6366973,
    lat: -22.8503429
  },
  {
    lng: -42.6367241,
    lat: -22.8508004
  },
  {
    lng: -42.6370909,
    lat: -22.8511461
  },
  {
    lng: -42.6371476,
    lat: -22.8511995
  },
  {
    lng: -42.6372691,
    lat: -22.851314
  },
  {
    lng: -42.6376601,
    lat: -22.8519965
  },
  {
    lng: -42.6378557,
    lat: -22.8523339
  },
  {
    lng: -42.6378241,
    lat: -22.8529992
  },
  {
    lng: -42.6385284,
    lat: -22.8532605
  },
  {
    lng: -42.6392486,
    lat: -22.853319
  },
  {
    lng: -42.6397942,
    lat: -22.8533115
  },
  {
    lng: -42.6403714,
    lat: -22.8534212
  },
  {
    lng: -42.6406256,
    lat: -22.8534588
  },
  {
    lng: -42.6411648,
    lat: -22.8535743
  },
  {
    lng: -42.6419238,
    lat: -22.8536572
  },
  {
    lng: -42.6425655,
    lat: -22.8538693
  },
  {
    lng: -42.6429273,
    lat: -22.854128
  },
  {
    lng: -42.6433394,
    lat: -22.8545619
  },
  {
    lng: -42.6436918,
    lat: -22.8547046
  },
  {
    lng: -42.6437978,
    lat: -22.8547476
  },
  {
    lng: -42.644802,
    lat: -22.8547611
  },
  {
    lng: -42.6450655,
    lat: -22.8548695
  },
  {
    lng: -42.6450956,
    lat: -22.8548835
  },
  {
    lng: -42.6451257,
    lat: -22.8548975
  },
  {
    lng: -42.6452563,
    lat: -22.8549584
  },
  {
    lng: -42.6456949,
    lat: -22.8552254
  },
  {
    lng: -42.6458669,
    lat: -22.8553301
  },
  {
    lng: -42.6463889,
    lat: -22.8554474
  },
  {
    lng: -42.6465409,
    lat: -22.8554815
  },
  {
    lng: -42.6471534,
    lat: -22.8553962
  },
  {
    lng: -42.6473264,
    lat: -22.8554064
  },
  {
    lng: -42.6476764,
    lat: -22.8553272
  },
  {
    lng: -42.6478769,
    lat: -22.8553291
  },
  {
    lng: -42.6482557,
    lat: -22.8553125
  },
  {
    lng: -42.6486003,
    lat: -22.8552865
  },
  {
    lng: -42.6489191,
    lat: -22.8552912
  },
  {
    lng: -42.6493584,
    lat: -22.8552339
  },
  {
    lng: -42.6498643,
    lat: -22.8551936
  },
  {
    lng: -42.6502138,
    lat: -22.8551625
  },
  {
    lng: -42.6511084,
    lat: -22.8552847
  },
  {
    lng: -42.6516494,
    lat: -22.8554586
  },
  {
    lng: -42.6520263,
    lat: -22.8555318
  },
  {
    lng: -42.6525556,
    lat: -22.8556134
  },
  {
    lng: -42.6529915,
    lat: -22.8557244
  },
  {
    lng: -42.6535317,
    lat: -22.8559475
  },
  {
    lng: -42.6539963,
    lat: -22.8563171
  },
  {
    lng: -42.6546093,
    lat: -22.8569348
  },
  {
    lng: -42.6555349,
    lat: -22.8569851
  },
  {
    lng: -42.6560109,
    lat: -22.857247
  },
  {
    lng: -42.6564839,
    lat: -22.8575573
  },
  {
    lng: -42.65683,
    lat: -22.8578098
  },
  {
    lng: -42.6573835,
    lat: -22.858027
  },
  {
    lng: -42.6575473,
    lat: -22.8581952
  },
  {
    lng: -42.6577409,
    lat: -22.8582907
  },
  {
    lng: -42.6583758,
    lat: -22.8584613
  },
  {
    lng: -42.6590824,
    lat: -22.8587311
  },
  {
    lng: -42.6595112,
    lat: -22.8589068
  },
  {
    lng: -42.6599253,
    lat: -22.859008
  },
  {
    lng: -42.6599383,
    lat: -22.8590112
  },
  {
    lng: -42.6600629,
    lat: -22.8593056
  },
  {
    lng: -42.6599807,
    lat: -22.8596933
  },
  {
    lng: -42.6598663,
    lat: -22.8600378
  },
  {
    lng: -42.6598614,
    lat: -22.8603244
  },
  {
    lng: -42.6598851,
    lat: -22.8608663
  },
  {
    lng: -42.6599654,
    lat: -22.8610775
  },
  {
    lng: -42.6602557,
    lat: -22.8613813
  },
  {
    lng: -42.6604379,
    lat: -22.8615751
  },
  {
    lng: -42.6606837,
    lat: -22.8617725
  },
  {
    lng: -42.6609387,
    lat: -22.8620719
  },
  {
    lng: -42.661042,
    lat: -22.8624248
  },
  {
    lng: -42.661272,
    lat: -22.8627068
  },
  {
    lng: -42.6615853,
    lat: -22.8630381
  },
  {
    lng: -42.6618719,
    lat: -22.8634545
  },
  {
    lng: -42.6628915,
    lat: -22.8642006
  },
  {
    lng: -42.6631561,
    lat: -22.8644301
  },
  {
    lng: -42.6636407,
    lat: -22.8648135
  },
  {
    lng: -42.6640079,
    lat: -22.8651737
  },
  {
    lng: -42.6646152,
    lat: -22.8657069
  },
  {
    lng: -42.6650667,
    lat: -22.8660837
  },
  {
    lng: -42.6654919,
    lat: -22.8662533
  },
  {
    lng: -42.6658827,
    lat: -22.8663561
  },
  {
    lng: -42.6660491,
    lat: -22.8665493
  },
  {
    lng: -42.6662682,
    lat: -22.8667249
  },
  {
    lng: -42.6664619,
    lat: -22.8669281
  },
  {
    lng: -42.6665261,
    lat: -22.8671241
  },
  {
    lng: -42.6666292,
    lat: -22.8673226
  },
  {
    lng: -42.666729,
    lat: -22.8675151
  },
  {
    lng: -42.6669306,
    lat: -22.8677856
  },
  {
    lng: -42.6669731,
    lat: -22.8680305
  },
  {
    lng: -42.6676189,
    lat: -22.868541
  },
  {
    lng: -42.6685034,
    lat: -22.8686687
  },
  {
    lng: -42.6691828,
    lat: -22.8687359
  },
  {
    lng: -42.6697985,
    lat: -22.8696455
  },
  {
    lng: -42.6705588,
    lat: -22.8703411
  },
  {
    lng: -42.6705951,
    lat: -22.8706761
  },
  {
    lng: -42.6705992,
    lat: -22.870925
  },
  {
    lng: -42.6707453,
    lat: -22.8714342
  },
  {
    lng: -42.6712713,
    lat: -22.8720344
  },
  {
    lng: -42.6716306,
    lat: -22.8724646
  },
  {
    lng: -42.6718355,
    lat: -22.8727554
  },
  {
    lng: -42.6722256,
    lat: -22.87301
  },
  {
    lng: -42.672675,
    lat: -22.8731633
  },
  {
    lng: -42.6730894,
    lat: -22.8730538
  },
  {
    lng: -42.6737281,
    lat: -22.8730832
  },
  {
    lng: -42.6740946,
    lat: -22.8731774
  },
  {
    lng: -42.6743391,
    lat: -22.8736087
  },
  {
    lng: -42.6744757,
    lat: -22.8744585
  },
  {
    lng: -42.674789,
    lat: -22.8747898
  },
  {
    lng: -42.6749358,
    lat: -22.8750408
  },
  {
    lng: -42.6753582,
    lat: -22.8753737
  },
  {
    lng: -42.6761475,
    lat: -22.8763046
  },
  {
    lng: -42.6771901,
    lat: -22.8777261
  },
  {
    lng: -42.6772438,
    lat: -22.8780224
  },
  {
    lng: -42.677222,
    lat: -22.8783177
  },
  {
    lng: -42.6769815,
    lat: -22.8789868
  },
  {
    lng: -42.6769447,
    lat: -22.8792667
  },
  {
    lng: -42.6768837,
    lat: -22.880085
  },
  {
    lng: -42.6770072,
    lat: -22.880504
  },
  {
    lng: -42.6772294,
    lat: -22.8810806
  },
  {
    lng: -42.6774719,
    lat: -22.8819799
  },
  {
    lng: -42.6777626,
    lat: -22.8825568
  },
  {
    lng: -42.6778638,
    lat: -22.883025
  },
  {
    lng: -42.6775965,
    lat: -22.8837494
  },
  {
    lng: -42.6775262,
    lat: -22.8840947
  },
  {
    lng: -42.6772695,
    lat: -22.8847837
  },
  {
    lng: -42.676922,
    lat: -22.8855072
  },
  {
    lng: -42.6766968,
    lat: -22.8858585
  },
  {
    lng: -42.6766527,
    lat: -22.8861841
  },
  {
    lng: -42.6765202,
    lat: -22.8869955
  },
  {
    lng: -42.6763694,
    lat: -22.8874677
  },
  {
    lng: -42.6763892,
    lat: -22.8880315
  },
  {
    lng: -42.6764828,
    lat: -22.8886245
  },
  {
    lng: -42.6769939,
    lat: -22.8897403
  },
  {
    lng: -42.677264,
    lat: -22.8899832
  },
  {
    lng: -42.6775677,
    lat: -22.8903173
  },
  {
    lng: -42.6779465,
    lat: -22.8907815
  },
  {
    lng: -42.6781596,
    lat: -22.8920724
  },
  {
    lng: -42.678267,
    lat: -22.8929983
  },
  {
    lng: -42.6784324,
    lat: -22.8941303
  },
  {
    lng: -42.678791,
    lat: -22.8947746
  },
  {
    lng: -42.6788398,
    lat: -22.8954247
  },
  {
    lng: -42.6789303,
    lat: -22.8958718
  },
  {
    lng: -42.6789553,
    lat: -22.896136
  },
  {
    lng: -42.6787603,
    lat: -22.896577
  },
  {
    lng: -42.6785455,
    lat: -22.8968934
  },
  {
    lng: -42.6780075,
    lat: -22.8973876
  },
  {
    lng: -42.6777507,
    lat: -22.898
  },
  {
    lng: -42.6776189,
    lat: -22.8983908
  },
  {
    lng: -42.6775623,
    lat: -22.8989449
  },
  {
    lng: -42.6774534,
    lat: -22.8991416
  },

];

export default polygonCoords_Saquarema;