let polygonCoordsRio21 = [
  {
    lng: -43.1711914,
    lat: -22.9520157
  },
  {
    lng: -43.1710318,
    lat: -22.9520802
  },
  {
    lng: -43.1710238,
    lat: -22.9520882
  },
  {
    lng: -43.1710231,
    lat: -22.9521009
  },
  {
    lng: -43.1710387,
    lat: -22.9521293
  },
  {
    lng: -43.1710526,
    lat: -22.9521541
  },
  {
    lng: -43.1709065,
    lat: -22.9522108
  },
  {
    lng: -43.1708864,
    lat: -22.9522256
  },
  {
    lng: -43.170885,
    lat: -22.9522491
  },
  {
    lng: -43.1709494,
    lat: -22.9523794
  },
  {
    lng: -43.171009,
    lat: -22.9525022
  },
  {
    lng: -43.1710245,
    lat: -22.9525208
  },
  {
    lng: -43.1710479,
    lat: -22.9525201
  },
  {
    lng: -43.1716816,
    lat: -22.9522565
  },
  {
    lng: -43.1716987,
    lat: -22.9522432
  },
  {
    lng: -43.1717151,
    lat: -22.9522182
  },
  {
    lng: -43.1717212,
    lat: -22.952196
  },
  {
    lng: -43.1717289,
    lat: -22.9521845
  },
  {
    lng: -43.17174,
    lat: -22.9521787
  },
  {
    lng: -43.1722167,
    lat: -22.9519885
  },
  {
    lng: -43.1722318,
    lat: -22.9519857
  },
  {
    lng: -43.1722442,
    lat: -22.9519885
  },
  {
    lng: -43.1722573,
    lat: -22.9520042
  },
  {
    lng: -43.1722667,
    lat: -22.952007
  },
  {
    lng: -43.1722794,
    lat: -22.9520061
  },
  {
    lng: -43.1725543,
    lat: -22.9518851
  },
  {
    lng: -43.1725634,
    lat: -22.9518762
  },
  {
    lng: -43.172561,
    lat: -22.9518638
  },
  {
    lng: -43.1723824,
    lat: -22.951515
  },
  {
    lng: -43.1723612,
    lat: -22.9514707
  },
  {
    lng: -43.1723699,
    lat: -22.9514667
  },
  {
    lng: -43.1724947,
    lat: -22.9514143
  },
  {
    lng: -43.1725359,
    lat: -22.9513976
  },
  {
    lng: -43.1730765,
    lat: -22.9511704
  },
  {
    lng: -43.1731313,
    lat: -22.9511445
  },
  {
    lng: -43.1745885,
    lat: -22.9505326
  },
  {
    lng: -43.1746428,
    lat: -22.9505085
  },
  {
    lng: -43.1749727,
    lat: -22.9503689
  },
  {
    lng: -43.1764777,
    lat: -22.9497248
  },
  {
    lng: -43.1764891,
    lat: -22.9497177
  },
  {
    lng: -43.1764995,
    lat: -22.9497063
  },
  {
    lng: -43.1765119,
    lat: -22.9496736
  },
  {
    lng: -43.176521,
    lat: -22.9496603
  },
  {
    lng: -43.1765304,
    lat: -22.9496529
  },
  {
    lng: -43.176615,
    lat: -22.9496156
  },
  {
    lng: -43.1766209,
    lat: -22.9496106
  },
  {
    lng: -43.176623,
    lat: -22.9496038
  },
  {
    lng: -43.1766196,
    lat: -22.9495921
  },
  {
    lng: -43.1766031,
    lat: -22.9495675
  },
  {
    lng: -43.176589,
    lat: -22.9495464
  },
  {
    lng: -43.1765347,
    lat: -22.9494593
  },
  {
    lng: -43.1765305,
    lat: -22.9494446
  },
  {
    lng: -43.1765304,
    lat: -22.9494325
  },
  {
    lng: -43.1765398,
    lat: -22.9494233
  },
  {
    lng: -43.1765635,
    lat: -22.949413
  },
  {
    lng: -43.1766273,
    lat: -22.9493853
  },
  {
    lng: -43.1769564,
    lat: -22.949243
  },
  {
    lng: -43.1770058,
    lat: -22.9492216
  },
  {
    lng: -43.1770633,
    lat: -22.9491968
  },
  {
    lng: -43.1771165,
    lat: -22.9491738
  },
  {
    lng: -43.1771695,
    lat: -22.9491508
  },
  {
    lng: -43.1776998,
    lat: -22.9489216
  },
  {
    lng: -43.1777395,
    lat: -22.9489148
  },
  {
    lng: -43.1778743,
    lat: -22.9489172
  },
  {
    lng: -43.1779142,
    lat: -22.9489142
  },
  {
    lng: -43.1780825,
    lat: -22.9488568
  },
  {
    lng: -43.1781272,
    lat: -22.9488386
  },
  {
    lng: -43.1781556,
    lat: -22.9488993
  },
  {
    lng: -43.1781883,
    lat: -22.94889
  },
  {
    lng: -43.1782159,
    lat: -22.9488809
  },
  {
    lng: -43.1782672,
    lat: -22.9488648
  },
  {
    lng: -43.1783952,
    lat: -22.9488239
  },
  {
    lng: -43.1784344,
    lat: -22.9488113
  },
  {
    lng: -43.178514,
    lat: -22.9487858
  },
  {
    lng: -43.1785306,
    lat: -22.9487836
  },
  {
    lng: -43.17857,
    lat: -22.9487789
  },
  {
    lng: -43.1786158,
    lat: -22.9487665
  },
  {
    lng: -43.1787365,
    lat: -22.9487101
  },
  {
    lng: -43.1787459,
    lat: -22.9487106
  },
  {
    lng: -43.1787527,
    lat: -22.9487138
  },
  {
    lng: -43.1787794,
    lat: -22.9487419
  },
  {
    lng: -43.1787871,
    lat: -22.9487469
  },
  {
    lng: -43.178799,
    lat: -22.9487501
  },
  {
    lng: -43.1789414,
    lat: -22.9487487
  },
  {
    lng: -43.1789695,
    lat: -22.9487478
  },
  {
    lng: -43.1789902,
    lat: -22.9487472
  },
  {
    lng: -43.1789977,
    lat: -22.9487496
  },
  {
    lng: -43.1790052,
    lat: -22.9487577
  },
  {
    lng: -43.1790262,
    lat: -22.9488287
  },
  {
    lng: -43.1790366,
    lat: -22.9488388
  },
  {
    lng: -43.1790503,
    lat: -22.9488429
  },
  {
    lng: -43.1796122,
    lat: -22.948779
  },
  {
    lng: -43.1796727,
    lat: -22.9487632
  },
  {
    lng: -43.1796865,
    lat: -22.9487575
  },
  {
    lng: -43.1797645,
    lat: -22.9487238
  },
  {
    lng: -43.1799496,
    lat: -22.9486227
  },
  {
    lng: -43.1800461,
    lat: -22.9485394
  },
  {
    lng: -43.1800857,
    lat: -22.9485184
  },
  {
    lng: -43.1801708,
    lat: -22.9484906
  },
  {
    lng: -43.1802225,
    lat: -22.9484609
  },
  {
    lng: -43.1802789,
    lat: -22.9483979
  },
  {
    lng: -43.1803586,
    lat: -22.9482861
  },
  {
    lng: -43.1803914,
    lat: -22.9482405
  },
  {
    lng: -43.1804861,
    lat: -22.948128
  },
  {
    lng: -43.1805182,
    lat: -22.9480274
  },
  {
    lng: -43.1805155,
    lat: -22.9479546
  },
  {
    lng: -43.1804484,
    lat: -22.9472939
  },
  {
    lng: -43.180419,
    lat: -22.9464196
  },
  {
    lng: -43.1803519,
    lat: -22.9458205
  },
  {
    lng: -43.1802152,
    lat: -22.9452673
  },
  {
    lng: -43.1800622,
    lat: -22.9448128
  },
  {
    lng: -43.1799066,
    lat: -22.9444769
  },
  {
    lng: -43.1797712,
    lat: -22.9442224
  },
  {
    lng: -43.1795178,
    lat: -22.9438224
  },
  {
    lng: -43.1792307,
    lat: -22.9434839
  },
  {
    lng: -43.1787412,
    lat: -22.9430233
  },
  {
    lng: -43.1786635,
    lat: -22.9429775
  },
  {
    lng: -43.1785293,
    lat: -22.9429418
  },
  {
    lng: -43.1782879,
    lat: -22.9429115
  },
  {
    lng: -43.1782061,
    lat: -22.9429182
  },
  {
    lng: -43.1781432,
    lat: -22.9429307
  },
  {
    lng: -43.1780834,
    lat: -22.9429659
  },
  {
    lng: -43.1779429,
    lat: -22.9430903
  },
  {
    lng: -43.1779318,
    lat: -22.9431088
  },
  {
    lng: -43.1779376,
    lat: -22.9431322
  },
  {
    lng: -43.1779477,
    lat: -22.9431514
  },
  {
    lng: -43.177967,
    lat: -22.9431782
  },
  {
    lng: -43.1779731,
    lat: -22.9431931
  },
  {
    lng: -43.1779708,
    lat: -22.9432029
  },
  {
    lng: -43.1779544,
    lat: -22.9432089
  },
  {
    lng: -43.1778783,
    lat: -22.943203
  },
  {
    lng: -43.1777783,
    lat: -22.9431981
  },
  {
    lng: -43.1776389,
    lat: -22.943216
  },
  {
    lng: -43.1774659,
    lat: -22.9432714
  },
  {
    lng: -43.1773344,
    lat: -22.9433271
  },
  {
    lng: -43.1769267,
    lat: -22.9435457
  },
  {
    lng: -43.1767322,
    lat: -22.9436482
  },
  {
    lng: -43.1762722,
    lat: -22.943931
  },
  {
    lng: -43.176102,
    lat: -22.9440237
  },
  {
    lng: -43.1759584,
    lat: -22.9440965
  },
  {
    lng: -43.175776,
    lat: -22.9441632
  },
  {
    lng: -43.1756514,
    lat: -22.9442015
  },
  {
    lng: -43.1755441,
    lat: -22.9442237
  },
  {
    lng: -43.1753404,
    lat: -22.9442468
  },
  {
    lng: -43.1752571,
    lat: -22.9442558
  },
  {
    lng: -43.1751095,
    lat: -22.9442669
  },
  {
    lng: -43.1750022,
    lat: -22.9442719
  },
  {
    lng: -43.174598,
    lat: -22.9442649
  },
  {
    lng: -43.1742754,
    lat: -22.944204
  },
  {
    lng: -43.1741479,
    lat: -22.9441669
  },
  {
    lng: -43.1740728,
    lat: -22.9441273
  },
  {
    lng: -43.1739656,
    lat: -22.9440459
  },
  {
    lng: -43.1738141,
    lat: -22.9439285
  },
  {
    lng: -43.1735846,
    lat: -22.9437495
  },
  {
    lng: -43.1734533,
    lat: -22.943668
  },
  {
    lng: -43.1731395,
    lat: -22.9434901
  },
  {
    lng: -43.1728739,
    lat: -22.9433234
  },
  {
    lng: -43.1726244,
    lat: -22.9431382
  },
  {
    lng: -43.1725091,
    lat: -22.9430492
  },
  {
    lng: -43.1724139,
    lat: -22.9429677
  },
  {
    lng: -43.1722905,
    lat: -22.9428355
  },
  {
    lng: -43.1721939,
    lat: -22.9427578
  },
  {
    lng: -43.1720625,
    lat: -22.9426627
  },
  {
    lng: -43.1719217,
    lat: -22.9425601
  },
  {
    lng: -43.1717929,
    lat: -22.9424588
  },
  {
    lng: -43.1716857,
    lat: -22.9423762
  },
  {
    lng: -43.1714912,
    lat: -22.9422205
  },
  {
    lng: -43.1713598,
    lat: -22.9421045
  },
  {
    lng: -43.171223,
    lat: -22.9419958
  },
  {
    lng: -43.1711036,
    lat: -22.941892
  },
  {
    lng: -43.1710285,
    lat: -22.9418118
  },
  {
    lng: -43.1709494,
    lat: -22.9417215
  },
  {
    lng: -43.1708354,
    lat: -22.9415969
  },
  {
    lng: -43.1706369,
    lat: -22.9413918
  },
  {
    lng: -43.1704264,
    lat: -22.9411682
  },
  {
    lng: -43.1700228,
    lat: -22.9406705
  },
  {
    lng: -43.1696297,
    lat: -22.940179
  },
  {
    lng: -43.1694648,
    lat: -22.9399492
  },
  {
    lng: -43.16932,
    lat: -22.9397468
  },
  {
    lng: -43.169198,
    lat: -22.9395751
  },
  {
    lng: -43.1690826,
    lat: -22.9394034
  },
  {
    lng: -43.1689941,
    lat: -22.9392861
  },
  {
    lng: -43.1689538,
    lat: -22.9392341
  },
  {
    lng: -43.1688559,
    lat: -22.9390897
  },
  {
    lng: -43.1687191,
    lat: -22.9388934
  },
  {
    lng: -43.168679,
    lat: -22.9388304
  },
  {
    lng: -43.1686467,
    lat: -22.9387513
  },
  {
    lng: -43.1686252,
    lat: -22.9386859
  },
  {
    lng: -43.1686024,
    lat: -22.9386216
  },
  {
    lng: -43.1685717,
    lat: -22.938519
  },
  {
    lng: -43.1685502,
    lat: -22.9384412
  },
  {
    lng: -43.1685381,
    lat: -22.9383759
  },
  {
    lng: -43.1685273,
    lat: -22.9383067
  },
  {
    lng: -43.1685045,
    lat: -22.9382079
  },
  {
    lng: -43.1684858,
    lat: -22.9380991
  },
  {
    lng: -43.1684844,
    lat: -22.9380017
  },
  {
    lng: -43.1684885,
    lat: -22.9379336
  },
  {
    lng: -43.1684911,
    lat: -22.9378349
  },
  {
    lng: -43.1684925,
    lat: -22.9377397
  },
  {
    lng: -43.1684859,
    lat: -22.9376744
  },
  {
    lng: -43.1684951,
    lat: -22.9375866
  },
  {
    lng: -43.1685032,
    lat: -22.9374854
  },
  {
    lng: -43.168514,
    lat: -22.937352
  },
  {
    lng: -43.1685118,
    lat: -22.9372454
  },
  {
    lng: -43.1685154,
    lat: -22.9371741
  },
  {
    lng: -43.1685274,
    lat: -22.9370777
  },
  {
    lng: -43.1685461,
    lat: -22.9370062
  },
  {
    lng: -43.1685999,
    lat: -22.9368765
  },
  {
    lng: -43.1686629,
    lat: -22.9367615
  },
  {
    lng: -43.1687178,
    lat: -22.9366701
  },
  {
    lng: -43.1687635,
    lat: -22.9365948
  },
  {
    lng: -43.1688158,
    lat: -22.9365146
  },
  {
    lng: -43.1688579,
    lat: -22.9364578
  },
  {
    lng: -43.1688895,
    lat: -22.9364194
  },
  {
    lng: -43.1689432,
    lat: -22.9363602
  },
  {
    lng: -43.1690155,
    lat: -22.9362923
  },
  {
    lng: -43.1691054,
    lat: -22.9361959
  },
  {
    lng: -43.1692033,
    lat: -22.9360972
  },
  {
    lng: -43.1692919,
    lat: -22.9360292
  },
  {
    lng: -43.1693964,
    lat: -22.9359527
  },
  {
    lng: -43.1694823,
    lat: -22.9359144
  },
  {
    lng: -43.169611,
    lat: -22.9358896
  },
  {
    lng: -43.169833,
    lat: -22.9358557
  },
  {
    lng: -43.1698463,
    lat: -22.935844
  },
  {
    lng: -43.1698522,
    lat: -22.935824
  },
  {
    lng: -43.169843,
    lat: -22.9358026
  },
  {
    lng: -43.1698236,
    lat: -22.9357903
  },
  {
    lng: -43.1697981,
    lat: -22.9357841
  },
  {
    lng: -43.1695814,
    lat: -22.9358094
  },
  {
    lng: -43.1694743,
    lat: -22.9358304
  },
  {
    lng: -43.1694018,
    lat: -22.935834
  },
  {
    lng: -43.169367,
    lat: -22.935813
  },
  {
    lng: -43.1693536,
    lat: -22.9357902
  },
  {
    lng: -43.1693575,
    lat: -22.9357612
  },
  {
    lng: -43.1693816,
    lat: -22.9357273
  },
  {
    lng: -43.1694205,
    lat: -22.9357086
  },
  {
    lng: -43.169509,
    lat: -22.935697
  },
  {
    lng: -43.1697196,
    lat: -22.9356908
  },
  {
    lng: -43.1699154,
    lat: -22.9356847
  },
  {
    lng: -43.1701514,
    lat: -22.9356488
  },
  {
    lng: -43.1704304,
    lat: -22.935603
  },
  {
    lng: -43.1705095,
    lat: -22.9355834
  },
  {
    lng: -43.17072,
    lat: -22.9354932
  },
  {
    lng: -43.1709024,
    lat: -22.9354166
  },
  {
    lng: -43.1710365,
    lat: -22.9353437
  },
  {
    lng: -43.1711225,
    lat: -22.9353018
  },
  {
    lng: -43.1711532,
    lat: -22.9351622
  },
  {
    lng: -43.1711748,
    lat: -22.9350338
  },
  {
    lng: -43.1712284,
    lat: -22.9346756
  },
  {
    lng: -43.1713289,
    lat: -22.9333207
  },
  {
    lng: -43.1713665,
    lat: -22.9320782
  },
  {
    lng: -43.1712861,
    lat: -22.9311097
  },
  {
    lng: -43.1710848,
    lat: -22.9300821
  },
  {
    lng: -43.1709857,
    lat: -22.9296153
  },
  {
    lng: -43.1708139,
    lat: -22.9289706
  },
  {
    lng: -43.170578,
    lat: -22.9282492
  },
  {
    lng: -43.1704171,
    lat: -22.9278465
  },
  {
    lng: -43.1701461,
    lat: -22.9271943
  },
  {
    lng: -43.1697143,
    lat: -22.9261963
  },
  {
    lng: -43.1694541,
    lat: -22.9255986
  },
  {
    lng: -43.1692396,
    lat: -22.9251144
  },
  {
    lng: -43.1690304,
    lat: -22.9246351
  },
  {
    lng: -43.1687702,
    lat: -22.9240373
  },
  {
    lng: -43.1683813,
    lat: -22.9233282
  },
  {
    lng: -43.1677576,
    lat: -22.9224952
  },
  {
    lng: -43.1677436,
    lat: -22.9224834
  },
  {
    lng: -43.1677133,
    lat: -22.9224717
  },
  {
    lng: -43.1676623,
    lat: -22.9224735
  },
  {
    lng: -43.1673183,
    lat: -22.9225544
  },
  {
    lng: -43.1672728,
    lat: -22.9225588
  },
  {
    lng: -43.1672412,
    lat: -22.9225514
  },
  {
    lng: -43.1672332,
    lat: -22.9225359
  },
  {
    lng: -43.1672486,
    lat: -22.9225187
  },
  {
    lng: -43.1679802,
    lat: -22.9223118
  },
  {
    lng: -43.1680513,
    lat: -22.9222796
  },
  {
    lng: -43.1681062,
    lat: -22.9222241
  },
  {
    lng: -43.1681357,
    lat: -22.9221636
  },
  {
    lng: -43.1681573,
    lat: -22.9219806
  },
  {
    lng: -43.167987,
    lat: -22.9208654
  },
  {
    lng: -43.1679387,
    lat: -22.920722
  },
  {
    lng: -43.1678716,
    lat: -22.9204478
  },
  {
    lng: -43.1678259,
    lat: -22.9201168
  },
  {
    lng: -43.1677992,
    lat: -22.9199216
  },
  {
    lng: -43.1676999,
    lat: -22.9196127
  },
  {
    lng: -43.1675557,
    lat: -22.9192885
  },
  {
    lng: -43.1675343,
    lat: -22.9192404
  },
  {
    lng: -43.1675054,
    lat: -22.9191681
  },
  {
    lng: -43.1674799,
    lat: -22.9191001
  },
  {
    lng: -43.1674907,
    lat: -22.9190507
  },
  {
    lng: -43.1675309,
    lat: -22.9190285
  },
  {
    lng: -43.1675899,
    lat: -22.9190322
  },
  {
    lng: -43.1678998,
    lat: -22.9190891
  },
  {
    lng: -43.1679306,
    lat: -22.9190866
  },
  {
    lng: -43.1679567,
    lat: -22.9190711
  },
  {
    lng: -43.1679729,
    lat: -22.9190366
  },
  {
    lng: -43.1681814,
    lat: -22.9185493
  },
  {
    lng: -43.1684066,
    lat: -22.918144
  },
  {
    lng: -43.168805,
    lat: -22.917571
  },
  {
    lng: -43.169257,
    lat: -22.916957
  },
  {
    lng: -43.1693992,
    lat: -22.9168187
  },
  {
    lng: -43.1694823,
    lat: -22.9167877
  },
  {
    lng: -43.1695701,
    lat: -22.9167799
  },
  {
    lng: -43.1696634,
    lat: -22.916799
  },
  {
    lng: -43.1697445,
    lat: -22.9168755
  },
  {
    lng: -43.1697498,
    lat: -22.9169015
  },
  {
    lng: -43.1697638,
    lat: -22.9169472
  },
  {
    lng: -43.1697638,
    lat: -22.9170397
  },
  {
    lng: -43.1697142,
    lat: -22.9171694
  },
  {
    lng: -43.1696325,
    lat: -22.9173239
  },
  {
    lng: -43.1695801,
    lat: -22.9174333
  },
  {
    lng: -43.1694307,
    lat: -22.9177321
  },
  {
    lng: -43.1693226,
    lat: -22.9179416
  },
  {
    lng: -43.1692462,
    lat: -22.9181528
  },
  {
    lng: -43.1692087,
    lat: -22.9183343
  },
  {
    lng: -43.1692087,
    lat: -22.918388
  },
  {
    lng: -43.1692087,
    lat: -22.9185765
  },
  {
    lng: -43.1692683,
    lat: -22.9188075
  },
  {
    lng: -43.1692787,
    lat: -22.9188354
  },
  {
    lng: -43.1693327,
    lat: -22.9189804
  },
  {
    lng: -43.1694099,
    lat: -22.9191211
  },
  {
    lng: -43.1695198,
    lat: -22.9192694
  },
  {
    lng: -43.1696318,
    lat: -22.9193942
  },
  {
    lng: -43.1696787,
    lat: -22.9194356
  },
  {
    lng: -43.1697606,
    lat: -22.9195189
  },
  {
    lng: -43.1697914,
    lat: -22.9195461
  },
  {
    lng: -43.1700784,
    lat: -22.9197085
  },
  {
    lng: -43.1701032,
    lat: -22.9197204
  },
  {
    lng: -43.1702456,
    lat: -22.9197888
  },
  {
    lng: -43.1702588,
    lat: -22.9197943
  },
  {
    lng: -43.1702638,
    lat: -22.9198004
  },
  {
    lng: -43.1702638,
    lat: -22.9198069
  },
  {
    lng: -43.1702543,
    lat: -22.9198321
  },
  {
    lng: -43.1702558,
    lat: -22.9198389
  },
  {
    lng: -43.1702608,
    lat: -22.9198426
  },
  {
    lng: -43.170307,
    lat: -22.9198684
  },
  {
    lng: -43.1703741,
    lat: -22.9198994
  },
  {
    lng: -43.1704057,
    lat: -22.9199118
  },
  {
    lng: -43.1704204,
    lat: -22.9199121
  },
  {
    lng: -43.1704301,
    lat: -22.919904
  },
  {
    lng: -43.1704558,
    lat: -22.9198478
  },
  {
    lng: -43.1704623,
    lat: -22.9198438
  },
  {
    lng: -43.170468,
    lat: -22.9198426
  },
  {
    lng: -43.1704787,
    lat: -22.9198446
  },
  {
    lng: -43.1705377,
    lat: -22.9198599
  },
  {
    lng: -43.1705847,
    lat: -22.9198713
  },
  {
    lng: -43.1706065,
    lat: -22.9198753
  },
  {
    lng: -43.1706426,
    lat: -22.9198811
  },
  {
    lng: -43.1706755,
    lat: -22.9198874
  },
  {
    lng: -43.1706896,
    lat: -22.9198917
  },
  {
    lng: -43.1707044,
    lat: -22.919896
  },
  {
    lng: -43.1707347,
    lat: -22.9199022
  },
  {
    lng: -43.1707639,
    lat: -22.9199034
  },
  {
    lng: -43.1709395,
    lat: -22.9199111
  },
  {
    lng: -43.1709936,
    lat: -22.9199082
  },
  {
    lng: -43.1711151,
    lat: -22.9199019
  },
  {
    lng: -43.1713196,
    lat: -22.9198759
  },
  {
    lng: -43.1714805,
    lat: -22.9198314
  },
  {
    lng: -43.1716562,
    lat: -22.9197597
  },
  {
    lng: -43.1718185,
    lat: -22.919682
  },
  {
    lng: -43.1719927,
    lat: -22.919559
  },
  {
    lng: -43.1720981,
    lat: -22.9194677
  },
  {
    lng: -43.17219,
    lat: -22.919341
  },
  {
    lng: -43.1722442,
    lat: -22.9192534
  },
  {
    lng: -43.172258,
    lat: -22.9192246
  },
  {
    lng: -43.1722591,
    lat: -22.9192064
  },
  {
    lng: -43.172254,
    lat: -22.9191898
  },
  {
    lng: -43.1722326,
    lat: -22.91917
  },
  {
    lng: -43.1722271,
    lat: -22.9191518
  },
  {
    lng: -43.1722306,
    lat: -22.9191341
  },
  {
    lng: -43.1722449,
    lat: -22.9191169
  },
  {
    lng: -43.1722933,
    lat: -22.9190882
  },
  {
    lng: -43.1723307,
    lat: -22.9190483
  },
  {
    lng: -43.172615,
    lat: -22.9185271
  },
  {
    lng: -43.172733,
    lat: -22.9181713
  },
  {
    lng: -43.1727895,
    lat: -22.9178749
  },
  {
    lng: -43.172823,
    lat: -22.9175412
  },
  {
    lng: -43.1728122,
    lat: -22.9171312
  },
  {
    lng: -43.1727619,
    lat: -22.9167468
  },
  {
    lng: -43.1726862,
    lat: -22.9164752
  },
  {
    lng: -43.1726016,
    lat: -22.916232
  },
  {
    lng: -43.172548,
    lat: -22.9161257
  },
  {
    lng: -43.1724984,
    lat: -22.9160305
  },
  {
    lng: -43.1724254,
    lat: -22.9158978
  },
  {
    lng: -43.1723723,
    lat: -22.9158126
  },
  {
    lng: -43.1723311,
    lat: -22.915756
  },
  {
    lng: -43.1723157,
    lat: -22.9157331
  },
  {
    lng: -43.1722584,
    lat: -22.9156458
  },
  {
    lng: -43.1721792,
    lat: -22.9155401
  },
  {
    lng: -43.1721121,
    lat: -22.9154636
  },
  {
    lng: -43.1720586,
    lat: -22.9153993
  },
  {
    lng: -43.1720103,
    lat: -22.9153475
  },
  {
    lng: -43.1719815,
    lat: -22.9153166
  },
  {
    lng: -43.1719265,
    lat: -22.9152644
  },
  {
    lng: -43.1719067,
    lat: -22.9152484
  },
  {
    lng: -43.1718244,
    lat: -22.9151862
  },
  {
    lng: -43.171732,
    lat: -22.9151072
  },
  {
    lng: -43.1716712,
    lat: -22.9150567
  },
  {
    lng: -43.1715831,
    lat: -22.9149893
  },
  {
    lng: -43.1714041,
    lat: -22.9148719
  },
  {
    lng: -43.1712834,
    lat: -22.9148021
  },
  {
    lng: -43.1711996,
    lat: -22.914749
  },
  {
    lng: -43.1711163,
    lat: -22.9147051
  },
  {
    lng: -43.171009,
    lat: -22.9146521
  },
  {
    lng: -43.170891,
    lat: -22.9146002
  },
  {
    lng: -43.1707362,
    lat: -22.9145366
  },
  {
    lng: -43.1705115,
    lat: -22.9144421
  },
  {
    lng: -43.1704652,
    lat: -22.914416
  },
  {
    lng: -43.1704243,
    lat: -22.914388
  },
  {
    lng: -43.1703788,
    lat: -22.9143697
  },
  {
    lng: -43.170299,
    lat: -22.914355
  },
  {
    lng: -43.1701455,
    lat: -22.9143303
  },
  {
    lng: -43.169961,
    lat: -22.9143062
  },
  {
    lng: -43.1697901,
    lat: -22.9142931
  },
  {
    lng: -43.1695989,
    lat: -22.9142901
  },
  {
    lng: -43.1693992,
    lat: -22.9142976
  },
  {
    lng: -43.1692415,
    lat: -22.9143018
  },
  {
    lng: -43.1691429,
    lat: -22.9143241
  },
  {
    lng: -43.1690726,
    lat: -22.9143661
  },
  {
    lng: -43.1689659,
    lat: -22.9144562
  },
  {
    lng: -43.1689392,
    lat: -22.9144717
  },
  {
    lng: -43.1689043,
    lat: -22.914473
  },
  {
    lng: -43.1688721,
    lat: -22.9144563
  },
  {
    lng: -43.1687808,
    lat: -22.9143995
  },
  {
    lng: -43.1687165,
    lat: -22.9143611
  },
  {
    lng: -43.1686522,
    lat: -22.9143494
  },
  {
    lng: -43.1685012,
    lat: -22.9143555
  },
  {
    lng: -43.1684805,
    lat: -22.9143574
  },
  {
    lng: -43.1680929,
    lat: -22.9144253
  },
  {
    lng: -43.1679957,
    lat: -22.9144426
  },
  {
    lng: -43.1678893,
    lat: -22.9144641
  },
  {
    lng: -43.1678567,
    lat: -22.9144713
  },
  {
    lng: -43.1677536,
    lat: -22.9144994
  },
  {
    lng: -43.1676289,
    lat: -22.9145352
  },
  {
    lng: -43.1675028,
    lat: -22.9145749
  },
  {
    lng: -43.1673292,
    lat: -22.9146397
  },
  {
    lng: -43.1669074,
    lat: -22.9148657
  },
  {
    lng: -43.1668202,
    lat: -22.9149139
  },
  {
    lng: -43.1664286,
    lat: -22.9150991
  },
  {
    lng: -43.1663173,
    lat: -22.9151629
  },
  {
    lng: -43.1662242,
    lat: -22.9152363
  },
  {
    lng: -43.1660605,
    lat: -22.9154055
  },
  {
    lng: -43.1660169,
    lat: -22.9154785
  },
  {
    lng: -43.166,
    lat: -22.9155229
  },
  {
    lng: -43.1659981,
    lat: -22.9155846
  },
  {
    lng: -43.166043,
    lat: -22.91572
  },
  {
    lng: -43.166112,
    lat: -22.9158429
  },
  {
    lng: -43.166383,
    lat: -22.9162257
  },
  {
    lng: -43.1665505,
    lat: -22.9165149
  },
  {
    lng: -43.1668739,
    lat: -22.9169533
  },
  {
    lng: -43.1670173,
    lat: -22.9171732
  },
  {
    lng: -43.1670978,
    lat: -22.9173004
  },
  {
    lng: -43.1671956,
    lat: -22.9174413
  },
  {
    lng: -43.16724,
    lat: -22.9175153
  },
  {
    lng: -43.1672775,
    lat: -22.9175908
  },
  {
    lng: -43.1672742,
    lat: -22.9176155
  },
  {
    lng: -43.1672613,
    lat: -22.9176289
  },
  {
    lng: -43.1672416,
    lat: -22.9176371
  },
  {
    lng: -43.1671996,
    lat: -22.9176417
  },
  {
    lng: -43.1671675,
    lat: -22.9176318
  },
  {
    lng: -43.1671381,
    lat: -22.9176092
  },
  {
    lng: -43.167067,
    lat: -22.917524
  },
  {
    lng: -43.1669234,
    lat: -22.9173338
  },
  {
    lng: -43.1662489,
    lat: -22.9163567
  },
  {
    lng: -43.1658334,
    lat: -22.9157663
  },
  {
    lng: -43.1657654,
    lat: -22.9156959
  },
  {
    lng: -43.1657305,
    lat: -22.9156712
  },
  {
    lng: -43.165683,
    lat: -22.9156513
  },
  {
    lng: -43.1656427,
    lat: -22.9156501
  },
  {
    lng: -43.1655668,
    lat: -22.9156668
  },
  {
    lng: -43.165288,
    lat: -22.9157754
  },
  {
    lng: -43.1652195,
    lat: -22.9158021
  },
  {
    lng: -43.1651464,
    lat: -22.9158299
  },
  {
    lng: -43.1650968,
    lat: -22.9158509
  },
  {
    lng: -43.165068,
    lat: -22.9158688
  },
  {
    lng: -43.1650445,
    lat: -22.9158842
  },
  {
    lng: -43.165011,
    lat: -22.9159053
  },
  {
    lng: -43.1649499,
    lat: -22.9159503
  },
  {
    lng: -43.1649171,
    lat: -22.915975
  },
  {
    lng: -43.1648728,
    lat: -22.9160004
  },
  {
    lng: -43.1648205,
    lat: -22.9160257
  },
  {
    lng: -43.1647548,
    lat: -22.916059
  },
  {
    lng: -43.1646034,
    lat: -22.9161306
  },
  {
    lng: -43.1643565,
    lat: -22.9162405
  },
  {
    lng: -43.1638885,
    lat: -22.9164605
  },
  {
    lng: -43.1635532,
    lat: -22.9166075
  },
  {
    lng: -43.1633776,
    lat: -22.916684
  },
  {
    lng: -43.1631938,
    lat: -22.9167841
  },
  {
    lng: -43.1630738,
    lat: -22.9168588
  },
  {
    lng: -43.163002,
    lat: -22.9169052
  },
  {
    lng: -43.1629242,
    lat: -22.9169682
  },
  {
    lng: -43.1627721,
    lat: -22.9171145
  },
  {
    lng: -43.1626982,
    lat: -22.9171658
  },
  {
    lng: -43.1626493,
    lat: -22.9171892
  },
  {
    lng: -43.1625971,
    lat: -22.9172077
  },
  {
    lng: -43.1624066,
    lat: -22.9172374
  },
  {
    lng: -43.1623663,
    lat: -22.9172381
  },
  {
    lng: -43.1623207,
    lat: -22.9172313
  },
  {
    lng: -43.1622712,
    lat: -22.9172114
  },
  {
    lng: -43.1620847,
    lat: -22.9171189
  },
  {
    lng: -43.1619613,
    lat: -22.9170448
  },
  {
    lng: -43.1618325,
    lat: -22.916946
  },
  {
    lng: -43.1617548,
    lat: -22.9168842
  },
  {
    lng: -43.1616931,
    lat: -22.9168359
  },
  {
    lng: -43.1616569,
    lat: -22.9167928
  },
  {
    lng: -43.1616314,
    lat: -22.9167397
  },
  {
    lng: -43.1616206,
    lat: -22.916689
  },
  {
    lng: -43.1616207,
    lat: -22.9165926
  },
  {
    lng: -43.1616139,
    lat: -22.9164593
  },
  {
    lng: -43.1615979,
    lat: -22.9163036
  },
  {
    lng: -43.1615952,
    lat: -22.916232
  },
  {
    lng: -43.1615945,
    lat: -22.9161628
  },
  {
    lng: -43.1615986,
    lat: -22.9161281
  },
  {
    lng: -43.1616267,
    lat: -22.9160095
  },
  {
    lng: -43.1616408,
    lat: -22.9159899
  },
  {
    lng: -43.1616542,
    lat: -22.9159786
  },
  {
    lng: -43.161673,
    lat: -22.9159638
  },
  {
    lng: -43.1616823,
    lat: -22.9159539
  },
  {
    lng: -43.1616931,
    lat: -22.9159243
  },
  {
    lng: -43.1616971,
    lat: -22.9158985
  },
  {
    lng: -43.1617025,
    lat: -22.9158304
  },
  {
    lng: -43.1617025,
    lat: -22.9157045
  },
  {
    lng: -43.1617105,
    lat: -22.9155753
  },
  {
    lng: -43.1617118,
    lat: -22.9154314
  },
  {
    lng: -43.1617125,
    lat: -22.9154061
  },
  {
    lng: -43.1617192,
    lat: -22.9153833
  },
  {
    lng: -43.1617353,
    lat: -22.9153574
  },
  {
    lng: -43.161744,
    lat: -22.9153394
  },
  {
    lng: -43.1617507,
    lat: -22.9153092
  },
  {
    lng: -43.1617588,
    lat: -22.9152363
  },
  {
    lng: -43.1617777,
    lat: -22.9147991
  },
  {
    lng: -43.1617789,
    lat: -22.9146656
  },
  {
    lng: -43.1617722,
    lat: -22.9145644
  },
  {
    lng: -43.1617615,
    lat: -22.9144519
  },
  {
    lng: -43.1617455,
    lat: -22.9143456
  },
  {
    lng: -43.1617226,
    lat: -22.9142444
  },
  {
    lng: -43.1616957,
    lat: -22.9141628
  },
  {
    lng: -43.1616756,
    lat: -22.9141061
  },
  {
    lng: -43.1616569,
    lat: -22.9140616
  },
  {
    lng: -43.1616059,
    lat: -22.9139293
  },
  {
    lng: -43.1615523,
    lat: -22.9138133
  },
  {
    lng: -43.1614892,
    lat: -22.9136946
  },
  {
    lng: -43.1613082,
    lat: -22.9133501
  },
  {
    lng: -43.1611634,
    lat: -22.9130659
  },
  {
    lng: -43.1611038,
    lat: -22.9129023
  },
  {
    lng: -43.1610775,
    lat: -22.9127905
  },
  {
    lng: -43.1610682,
    lat: -22.9126236
  },
  {
    lng: -43.1610682,
    lat: -22.9124656
  },
  {
    lng: -43.1610869,
    lat: -22.9122766
  },
  {
    lng: -43.1611244,
    lat: -22.9117485
  },
  {
    lng: -43.1611252,
    lat: -22.9117321
  },
  {
    lng: -43.1611268,
    lat: -22.9117003
  },
  {
    lng: -43.1611278,
    lat: -22.9116799
  },
  {
    lng: -43.1612961,
    lat: -22.9090067
  },
  {
    lng: -43.1614168,
    lat: -22.9070351
  },
  {
    lng: -43.1615054,
    lat: -22.9055909
  },
  {
    lng: -43.1615308,
    lat: -22.90536
  },
  {
    lng: -43.1615496,
    lat: -22.9052698
  },
  {
    lng: -43.161575,
    lat: -22.9051933
  },
  {
    lng: -43.1616448,
    lat: -22.9050499
  },
  {
    lng: -43.1617098,
    lat: -22.9049381
  },
  {
    lng: -43.1617645,
    lat: -22.904861
  },
  {
    lng: -43.1618419,
    lat: -22.9047651
  },
  {
    lng: -43.1619084,
    lat: -22.9046966
  },
  {
    lng: -43.1619586,
    lat: -22.9046539
  },
  {
    lng: -43.1620659,
    lat: -22.9045829
  },
  {
    lng: -43.1621887,
    lat: -22.9045051
  },
  {
    lng: -43.1622711,
    lat: -22.9044519
  },
  {
    lng: -43.1623671,
    lat: -22.9043909
  },
  {
    lng: -43.1624749,
    lat: -22.9043349
  },
  {
    lng: -43.162526,
    lat: -22.9043142
  },
  {
    lng: -43.162607,
    lat: -22.9042913
  },
  {
    lng: -43.1626962,
    lat: -22.9042779
  },
  {
    lng: -43.1627921,
    lat: -22.9042716
  },
  {
    lng: -43.1628558,
    lat: -22.9042759
  },
  {
    lng: -43.1628946,
    lat: -22.9042886
  },
  {
    lng: -43.1629592,
    lat: -22.9043259
  },
  {
    lng: -43.1642198,
    lat: -22.9044025
  },
  {
    lng: -43.164433,
    lat: -22.9044062
  },
  {
    lng: -43.164484,
    lat: -22.9043667
  },
  {
    lng: -43.1655354,
    lat: -22.904413
  },
  {
    lng: -43.165573,
    lat: -22.904447
  },
  {
    lng: -43.1655998,
    lat: -22.9044303
  },
  {
    lng: -43.1656561,
    lat: -22.904439
  },
  {
    lng: -43.1656622,
    lat: -22.9045094
  },
  {
    lng: -43.1658352,
    lat: -22.9045174
  },
  {
    lng: -43.165858,
    lat: -22.9044353
  },
  {
    lng: -43.1658868,
    lat: -22.9044174
  },
  {
    lng: -43.1669818,
    lat: -22.9044563
  },
  {
    lng: -43.1670346,
    lat: -22.9044527
  },
  {
    lng: -43.1671697,
    lat: -22.9044667
  },
  {
    lng: -43.1671738,
    lat: -22.9044302
  },
  {
    lng: -43.1672989,
    lat: -22.9044374
  },
  {
    lng: -43.167434,
    lat: -22.9044452
  },
  {
    lng: -43.1680022,
    lat: -22.9045557
  },
  {
    lng: -43.168044,
    lat: -22.9045694
  },
  {
    lng: -43.1682544,
    lat: -22.9045872
  },
  {
    lng: -43.1683329,
    lat: -22.9045891
  },
  {
    lng: -43.1683403,
    lat: -22.9045891
  },
  {
    lng: -43.1686474,
    lat: -22.9046034
  },
  {
    lng: -43.1686776,
    lat: -22.9046039
  },
  {
    lng: -43.1688913,
    lat: -22.9046122
  },
  {
    lng: -43.1688978,
    lat: -22.9046136
  },
  {
    lng: -43.1689008,
    lat: -22.904615
  },
  {
    lng: -43.168902,
    lat: -22.904617
  },
  {
    lng: -43.168907,
    lat: -22.9046574
  },
  {
    lng: -43.1689089,
    lat: -22.9046593
  },
  {
    lng: -43.1689142,
    lat: -22.9046589
  },
  {
    lng: -43.1690283,
    lat: -22.9045915
  },
  {
    lng: -43.1690564,
    lat: -22.9045854
  },
  {
    lng: -43.1690846,
    lat: -22.9045867
  },
  {
    lng: -43.1691141,
    lat: -22.9045928
  },
  {
    lng: -43.1691603,
    lat: -22.9046096
  },
  {
    lng: -43.1692046,
    lat: -22.9046144
  },
  {
    lng: -43.1692294,
    lat: -22.9046101
  },
  {
    lng: -43.1692468,
    lat: -22.9046021
  },
  {
    lng: -43.1696,
    lat: -22.9043547
  },
  {
    lng: -43.1694971,
    lat: -22.9042399
  },
  {
    lng: -43.1695429,
    lat: -22.9042152
  },
  {
    lng: -43.1696432,
    lat: -22.9043322
  },
  {
    lng: -43.1707833,
    lat: -22.903541
  },
  {
    lng: -43.1709883,
    lat: -22.9033982
  },
  {
    lng: -43.1711598,
    lat: -22.9032822
  },
  {
    lng: -43.1711864,
    lat: -22.9032595
  },
  {
    lng: -43.1712038,
    lat: -22.9032007
  },
  {
    lng: -43.1712298,
    lat: -22.9030893
  },
  {
    lng: -43.171243,
    lat: -22.9030638
  },
  {
    lng: -43.1712528,
    lat: -22.903033
  },
  {
    lng: -43.1712816,
    lat: -22.9029297
  },
  {
    lng: -43.1716183,
    lat: -22.9029293
  },
  {
    lng: -43.171681,
    lat: -22.9029222
  },
  {
    lng: -43.1717287,
    lat: -22.9029105
  },
  {
    lng: -43.1717554,
    lat: -22.9028961
  },
  {
    lng: -43.1717864,
    lat: -22.9028502
  },
  {
    lng: -43.1718043,
    lat: -22.9028213
  },
  {
    lng: -43.17181,
    lat: -22.9028108
  },
  {
    lng: -43.1718293,
    lat: -22.902775
  },
  {
    lng: -43.1718425,
    lat: -22.9027473
  },
  {
    lng: -43.1718497,
    lat: -22.9027349
  },
  {
    lng: -43.1718729,
    lat: -22.9026986
  },
  {
    lng: -43.1718697,
    lat: -22.9026698
  },
  {
    lng: -43.1718988,
    lat: -22.9026332
  },
  {
    lng: -43.1719184,
    lat: -22.902597
  },
  {
    lng: -43.1718548,
    lat: -22.9025079
  },
  {
    lng: -43.1718853,
    lat: -22.9024787
  },
  {
    lng: -43.1719136,
    lat: -22.9024535
  },
  {
    lng: -43.1719264,
    lat: -22.9024422
  },
  {
    lng: -43.1719483,
    lat: -22.9024157
  },
  {
    lng: -43.1719693,
    lat: -22.9023867
  },
  {
    lng: -43.1719758,
    lat: -22.9023786
  },
  {
    lng: -43.1720036,
    lat: -22.9023492
  },
  {
    lng: -43.1720284,
    lat: -22.9023157
  },
  {
    lng: -43.1721337,
    lat: -22.902386
  },
  {
    lng: -43.1723249,
    lat: -22.902183
  },
  {
    lng: -43.1722491,
    lat: -22.9021291
  },
  {
    lng: -43.172361,
    lat: -22.9020034
  },
  {
    lng: -43.1725331,
    lat: -22.9021215
  },
  {
    lng: -43.1726126,
    lat: -22.902034
  },
  {
    lng: -43.1726662,
    lat: -22.9019716
  },
  {
    lng: -43.1727475,
    lat: -22.9018771
  },
  {
    lng: -43.1725945,
    lat: -22.9017536
  },
  {
    lng: -43.1726284,
    lat: -22.9017171
  },
  {
    lng: -43.1727747,
    lat: -22.901838
  },
  {
    lng: -43.1728331,
    lat: -22.9017756
  },
  {
    lng: -43.173167,
    lat: -22.9014207
  },
  {
    lng: -43.173193,
    lat: -22.901409
  },
  {
    lng: -43.1732199,
    lat: -22.9014214
  },
  {
    lng: -43.1732362,
    lat: -22.9014381
  },
  {
    lng: -43.1732408,
    lat: -22.9014603
  },
  {
    lng: -43.1732184,
    lat: -22.9014818
  },
  {
    lng: -43.1731599,
    lat: -22.9015044
  },
  {
    lng: -43.1728796,
    lat: -22.9018055
  },
  {
    lng: -43.1728788,
    lat: -22.9018348
  },
  {
    lng: -43.1729177,
    lat: -22.9018576
  },
  {
    lng: -43.1729064,
    lat: -22.9018707
  },
  {
    lng: -43.1729309,
    lat: -22.9018854
  },
  {
    lng: -43.1729428,
    lat: -22.9018756
  },
  {
    lng: -43.1733562,
    lat: -22.9021109
  },
  {
    lng: -43.1734022,
    lat: -22.9021023
  },
  {
    lng: -43.1734344,
    lat: -22.902054
  },
  {
    lng: -43.1738817,
    lat: -22.9013847
  },
  {
    lng: -43.1735084,
    lat: -22.9011767
  },
  {
    lng: -43.173354,
    lat: -22.9013539
  },
  {
    lng: -43.1733407,
    lat: -22.9013595
  },
  {
    lng: -43.1733017,
    lat: -22.9013367
  },
  {
    lng: -43.1732959,
    lat: -22.901325
  },
  {
    lng: -43.1732993,
    lat: -22.9013055
  },
  {
    lng: -43.1734596,
    lat: -22.9011286
  },
  {
    lng: -43.1734489,
    lat: -22.9010407
  },
  {
    lng: -43.1734135,
    lat: -22.9010177
  },
  {
    lng: -43.1730033,
    lat: -22.901054
  },
  {
    lng: -43.1729074,
    lat: -22.9007536
  },
  {
    lng: -43.1729825,
    lat: -22.9007334
  },
  {
    lng: -43.1730464,
    lat: -22.9009366
  },
  {
    lng: -43.1730702,
    lat: -22.9009561
  },
  {
    lng: -43.1732861,
    lat: -22.9009382
  },
  {
    lng: -43.1733046,
    lat: -22.9009126
  },
  {
    lng: -43.1732325,
    lat: -22.900679
  },
  {
    lng: -43.1733086,
    lat: -22.90066
  },
  {
    lng: -43.17338,
    lat: -22.9008937
  },
  {
    lng: -43.1733986,
    lat: -22.9009082
  },
  {
    lng: -43.173604,
    lat: -22.9008916
  },
  {
    lng: -43.1736496,
    lat: -22.9009083
  },
  {
    lng: -43.1741631,
    lat: -22.9002512
  },
  {
    lng: -43.175603,
    lat: -22.898409
  },
  {
    lng: -43.1757334,
    lat: -22.8984973
  },
  {
    lng: -43.1740689,
    lat: -22.9006332
  },
  {
    lng: -43.1740773,
    lat: -22.9006597
  },
  {
    lng: -43.1741033,
    lat: -22.9006871
  },
  {
    lng: -43.174356,
    lat: -22.9008229
  },
  {
    lng: -43.174839,
    lat: -22.9001334
  },
  {
    lng: -43.1751585,
    lat: -22.9003255
  },
  {
    lng: -43.1751709,
    lat: -22.9003281
  },
  {
    lng: -43.1751885,
    lat: -22.9003149
  },
  {
    lng: -43.1752833,
    lat: -22.9002114
  },
  {
    lng: -43.1755625,
    lat: -22.8997941
  },
  {
    lng: -43.1761048,
    lat: -22.8989719
  },
  {
    lng: -43.1761051,
    lat: -22.8989562
  },
  {
    lng: -43.1760913,
    lat: -22.8989423
  },
  {
    lng: -43.1758884,
    lat: -22.8988
  },
  {
    lng: -43.1759823,
    lat: -22.8986788
  },
  {
    lng: -43.1762718,
    lat: -22.8987651
  },
  {
    lng: -43.1763997,
    lat: -22.8986169
  },
  {
    lng: -43.1764145,
    lat: -22.8984952
  },
  {
    lng: -43.1764443,
    lat: -22.8983334
  },
  {
    lng: -43.1764035,
    lat: -22.8983234
  },
  {
    lng: -43.1765559,
    lat: -22.8973573
  },
  {
    lng: -43.1770084,
    lat: -22.8961898
  },
  {
    lng: -43.177182,
    lat: -22.8957392
  },
  {
    lng: -43.1772626,
    lat: -22.8956669
  },
  {
    lng: -43.1776649,
    lat: -22.8956737
  },
  {
    lng: -43.1776625,
    lat: -22.8957331
  },
  {
    lng: -43.1777206,
    lat: -22.8957351
  },
  {
    lng: -43.1778008,
    lat: -22.8957378
  },
  {
    lng: -43.1782058,
    lat: -22.8956921
  },
  {
    lng: -43.1784158,
    lat: -22.8955801
  },
  {
    lng: -43.1791066,
    lat: -22.8956293
  },
  {
    lng: -43.1792655,
    lat: -22.8956617
  },
  {
    lng: -43.1792776,
    lat: -22.8956058
  },
  {
    lng: -43.1794946,
    lat: -22.8956382
  },
  {
    lng: -43.1795311,
    lat: -22.8956308
  },
  {
    lng: -43.1795433,
    lat: -22.8956166
  },
  {
    lng: -43.1783307,
    lat: -22.8925791
  },
  {
    lng: -43.178334,
    lat: -22.8925518
  },
  {
    lng: -43.1783557,
    lat: -22.8925347
  },
  {
    lng: -43.1790404,
    lat: -22.8923023
  },
  {
    lng: -43.1790734,
    lat: -22.8923067
  },
  {
    lng: -43.1790975,
    lat: -22.8923297
  },
  {
    lng: -43.180175,
    lat: -22.895028
  },
  {
    lng: -43.1802119,
    lat: -22.8950999
  },
  {
    lng: -43.1802747,
    lat: -22.8951897
  },
  {
    lng: -43.1803512,
    lat: -22.8952614
  },
  {
    lng: -43.1804564,
    lat: -22.8953364
  },
  {
    lng: -43.1805951,
    lat: -22.8953993
  },
  {
    lng: -43.1807389,
    lat: -22.8954382
  },
  {
    lng: -43.1809057,
    lat: -22.8954458
  },
  {
    lng: -43.1809898,
    lat: -22.8954428
  },
  {
    lng: -43.1813158,
    lat: -22.8953619
  },
  {
    lng: -43.1838498,
    lat: -22.8944874
  },
  {
    lng: -43.1860878,
    lat: -22.89372
  },
  {
    lng: -43.1906945,
    lat: -22.8921649
  },
  {
    lng: -43.1909318,
    lat: -22.8921164
  },
  {
    lng: -43.1911382,
    lat: -22.8921051
  },
  {
    lng: -43.195484,
    lat: -22.8920081
  },
  {
    lng: -43.195715,
    lat: -22.8920195
  },
  {
    lng: -43.1957862,
    lat: -22.8920293
  },
  {
    lng: -43.1959831,
    lat: -22.8920762
  },
  {
    lng: -43.1960927,
    lat: -22.8921122
  },
  {
    lng: -43.2006812,
    lat: -22.8940033
  },
  {
    lng: -43.2095073,
    lat: -22.8975766
  },
  {
    lng: -43.2097135,
    lat: -22.8976282
  },
  {
    lng: -43.2099953,
    lat: -22.8976705
  },
  {
    lng: -43.2102763,
    lat: -22.8976655
  },
  {
    lng: -43.2103689,
    lat: -22.8976618
  },
  {
    lng: -43.2104654,
    lat: -22.897656
  },
  {
    lng: -43.2107973,
    lat: -22.8975985
  },
  {
    lng: -43.2108525,
    lat: -22.8975602
  },
  {
    lng: -43.2109358,
    lat: -22.8973862
  },
  {
    lng: -43.2160451,
    lat: -22.8867047
  },
  {
    lng: -43.2159716,
    lat: -22.8863189
  },
  {
    lng: -43.2158309,
    lat: -22.8861347
  },
  {
    lng: -43.2155042,
    lat: -22.885777
  },
  {
    lng: -43.2141703,
    lat: -22.8843387
  },
  {
    lng: -43.2123683,
    lat: -22.8824186
  },
  {
    lng: -43.2116571,
    lat: -22.8816465
  },
  {
    lng: -43.2101709,
    lat: -22.8800545
  },
  {
    lng: -43.2096962,
    lat: -22.8804024
  },
  {
    lng: -43.2087101,
    lat: -22.8793328
  },
  {
    lng: -43.2013849,
    lat: -22.8714682
  },
  {
    lng: -43.2018872,
    lat: -22.8710906
  },
  {
    lng: -43.2028356,
    lat: -22.8721325
  },
  {
    lng: -43.2039803,
    lat: -22.8712322
  },
  {
    lng: -43.2045278,
    lat: -22.8718399
  },
  {
    lng: -43.204648,
    lat: -22.8717122
  },
  {
    lng: -43.2047346,
    lat: -22.8714355
  },
  {
    lng: -43.2046028,
    lat: -22.871281
  },
  {
    lng: -43.2067964,
    lat: -22.8695409
  },
  {
    lng: -43.2068171,
    lat: -22.8695425
  },
  {
    lng: -43.206871,
    lat: -22.8695893
  },
  {
    lng: -43.2070517,
    lat: -22.8694483
  },
  {
    lng: -43.2070105,
    lat: -22.8693979
  },
  {
    lng: -43.2070086,
    lat: -22.8693734
  },
  {
    lng: -43.207329,
    lat: -22.869114
  },
  {
    lng: -43.2077923,
    lat: -22.8687461
  },
  {
    lng: -43.2097998,
    lat: -22.8671291
  },
  {
    lng: -43.2098598,
    lat: -22.8670808
  },
  {
    lng: -43.2116082,
    lat: -22.8688603
  },
  {
    lng: -43.2126372,
    lat: -22.8699075
  },
  {
    lng: -43.213112,
    lat: -22.8703908
  },
  {
    lng: -43.2138248,
    lat: -22.8711519
  },
  {
    lng: -43.2137919,
    lat: -22.8711782
  },
  {
    lng: -43.2137106,
    lat: -22.8712433
  },
  {
    lng: -43.2127166,
    lat: -22.87204
  },
  {
    lng: -43.2126685,
    lat: -22.8722138
  },
  {
    lng: -43.2125466,
    lat: -22.8723074
  },
  {
    lng: -43.2125624,
    lat: -22.8725461
  },
  {
    lng: -43.2126743,
    lat: -22.872571
  },
  {
    lng: -43.212643,
    lat: -22.8727044
  },
  {
    lng: -43.212725,
    lat: -22.8727368
  },
  {
    lng: -43.2126893,
    lat: -22.8728383
  },
  {
    lng: -43.212732,
    lat: -22.872866
  },
  {
    lng: -43.2129612,
    lat: -22.872936
  },
  {
    lng: -43.2131527,
    lat: -22.8729264
  },
  {
    lng: -43.2132054,
    lat: -22.8728858
  },
  {
    lng: -43.213229,
    lat: -22.8728802
  },
  {
    lng: -43.213323,
    lat: -22.8728722
  },
  {
    lng: -43.2134349,
    lat: -22.872927
  },
  {
    lng: -43.2135177,
    lat: -22.8730581
  },
  {
    lng: -43.2144959,
    lat: -22.8728988
  },
  {
    lng: -43.2145466,
    lat: -22.8728927
  },
  {
    lng: -43.2146463,
    lat: -22.8728961
  },
  {
    lng: -43.2148458,
    lat: -22.8729473
  },
  {
    lng: -43.2151359,
    lat: -22.8730376
  },
  {
    lng: -43.2157532,
    lat: -22.8732743
  },
  {
    lng: -43.215909,
    lat: -22.8733238
  },
  {
    lng: -43.2160006,
    lat: -22.8733684
  },
  {
    lng: -43.2162946,
    lat: -22.8734728
  },
  {
    lng: -43.2165229,
    lat: -22.8735837
  },
  {
    lng: -43.2165987,
    lat: -22.8736435
  },
  {
    lng: -43.216717,
    lat: -22.8737367
  },
  {
    lng: -43.216753,
    lat: -22.8737679
  },
  {
    lng: -43.2167635,
    lat: -22.873787
  },
  {
    lng: -43.2168434,
    lat: -22.8741037
  },
  {
    lng: -43.2169512,
    lat: -22.8744659
  },
  {
    lng: -43.21696,
    lat: -22.8745141
  },
  {
    lng: -43.2169469,
    lat: -22.8745444
  },
  {
    lng: -43.2168136,
    lat: -22.8747299
  },
  {
    lng: -43.2168822,
    lat: -22.8747899
  },
  {
    lng: -43.2169067,
    lat: -22.8748314
  },
  {
    lng: -43.2169114,
    lat: -22.8748781
  },
  {
    lng: -43.2169001,
    lat: -22.8749232
  },
  {
    lng: -43.2168755,
    lat: -22.8749635
  },
  {
    lng: -43.2168647,
    lat: -22.875058
  },
  {
    lng: -43.2168399,
    lat: -22.8751486
  },
  {
    lng: -43.2168393,
    lat: -22.8751968
  },
  {
    lng: -43.2168275,
    lat: -22.8752448
  },
  {
    lng: -43.2168635,
    lat: -22.8752626
  },
  {
    lng: -43.2168828,
    lat: -22.8753046
  },
  {
    lng: -43.2168828,
    lat: -22.8753499
  },
  {
    lng: -43.2168552,
    lat: -22.8754392
  },
  {
    lng: -43.216863,
    lat: -22.8755344
  },
  {
    lng: -43.2168898,
    lat: -22.8755741
  },
  {
    lng: -43.216957,
    lat: -22.8756448
  },
  {
    lng: -43.2169583,
    lat: -22.87569
  },
  {
    lng: -43.2169204,
    lat: -22.8757208
  },
  {
    lng: -43.2169131,
    lat: -22.875796
  },
  {
    lng: -43.2169226,
    lat: -22.8758415
  },
  {
    lng: -43.2169553,
    lat: -22.8758764
  },
  {
    lng: -43.2169811,
    lat: -22.8758579
  },
  {
    lng: -43.2170247,
    lat: -22.8758409
  },
  {
    lng: -43.2170963,
    lat: -22.8758298
  },
  {
    lng: -43.2179457,
    lat: -22.875432
  },
  {
    lng: -43.2179,
    lat: -22.8753508
  },
  {
    lng: -43.2180086,
    lat: -22.8752938
  },
  {
    lng: -43.217776,
    lat: -22.874896
  },
  {
    lng: -43.2178388,
    lat: -22.8748658
  },
  {
    lng: -43.2180425,
    lat: -22.8752176
  },
  {
    lng: -43.2180684,
    lat: -22.8752624
  },
  {
    lng: -43.2181726,
    lat: -22.875207
  },
  {
    lng: -43.2181926,
    lat: -22.8751684
  },
  {
    lng: -43.2182415,
    lat: -22.8751577
  },
  {
    lng: -43.2182779,
    lat: -22.8751251
  },
  {
    lng: -43.2184697,
    lat: -22.8750631
  },
  {
    lng: -43.2185078,
    lat: -22.8750336
  },
  {
    lng: -43.2185524,
    lat: -22.8750139
  },
  {
    lng: -43.2186247,
    lat: -22.8749534
  },
  {
    lng: -43.2186182,
    lat: -22.8749412
  },
  {
    lng: -43.2186261,
    lat: -22.8749389
  },
  {
    lng: -43.2186578,
    lat: -22.8749896
  },
  {
    lng: -43.2186884,
    lat: -22.8749713
  },
  {
    lng: -43.2187374,
    lat: -22.8749582
  },
  {
    lng: -43.2187816,
    lat: -22.8749369
  },
  {
    lng: -43.2188485,
    lat: -22.8748692
  },
  {
    lng: -43.2188743,
    lat: -22.874865
  },
  {
    lng: -43.2189235,
    lat: -22.8749434
  },
  {
    lng: -43.2190146,
    lat: -22.8750031
  },
  {
    lng: -43.2190679,
    lat: -22.8750257
  },
  {
    lng: -43.2191447,
    lat: -22.8750042
  },
  {
    lng: -43.2191612,
    lat: -22.8750087
  },
  {
    lng: -43.2192191,
    lat: -22.875153
  },
  {
    lng: -43.2193367,
    lat: -22.8753861
  },
  {
    lng: -43.2195432,
    lat: -22.8755967
  },
  {
    lng: -43.2195626,
    lat: -22.8755666
  },
  {
    lng: -43.2195918,
    lat: -22.8755779
  },
  {
    lng: -43.2196908,
    lat: -22.875494
  },
  {
    lng: -43.2197171,
    lat: -22.8754685
  },
  {
    lng: -43.2197419,
    lat: -22.8754197
  },
  {
    lng: -43.2197534,
    lat: -22.8753755
  },
  {
    lng: -43.219751,
    lat: -22.8753365
  },
  {
    lng: -43.2197253,
    lat: -22.875302
  },
  {
    lng: -43.2193862,
    lat: -22.8749572
  },
  {
    lng: -43.2192265,
    lat: -22.8747211
  },
  {
    lng: -43.2191356,
    lat: -22.8746066
  },
  {
    lng: -43.2190685,
    lat: -22.8745382
  },
  {
    lng: -43.2190104,
    lat: -22.8743553
  },
  {
    lng: -43.2183729,
    lat: -22.8737151
  },
  {
    lng: -43.2182523,
    lat: -22.8734711
  },
  {
    lng: -43.2182102,
    lat: -22.8733858
  },
  {
    lng: -43.2182713,
    lat: -22.8733114
  },
  {
    lng: -43.2182492,
    lat: -22.8732151
  },
  {
    lng: -43.2187891,
    lat: -22.8721478
  },
  {
    lng: -43.2188488,
    lat: -22.8721059
  },
  {
    lng: -43.2189592,
    lat: -22.8720983
  },
  {
    lng: -43.219127,
    lat: -22.8721502
  },
  {
    lng: -43.2193953,
    lat: -22.8722847
  },
  {
    lng: -43.2196361,
    lat: -22.8724827
  },
  {
    lng: -43.219874,
    lat: -22.8725322
  },
  {
    lng: -43.2200292,
    lat: -22.8725239
  },
  {
    lng: -43.2202086,
    lat: -22.8725389
  },
  {
    lng: -43.2211874,
    lat: -22.8728826
  },
  {
    lng: -43.2213647,
    lat: -22.8724817
  },
  {
    lng: -43.2214744,
    lat: -22.8725201
  },
  {
    lng: -43.2208135,
    lat: -22.8740581
  },
  {
    lng: -43.2214608,
    lat: -22.8743009
  },
  {
    lng: -43.2221571,
    lat: -22.8727671
  },
  {
    lng: -43.2222616,
    lat: -22.8728095
  },
  {
    lng: -43.2219024,
    lat: -22.8736357
  },
  {
    lng: -43.2222452,
    lat: -22.8737648
  },
  {
    lng: -43.2225452,
    lat: -22.8730927
  },
  {
    lng: -43.2226215,
    lat: -22.8730985
  },
  {
    lng: -43.2227661,
    lat: -22.873149
  },
  {
    lng: -43.2228752,
    lat: -22.8731989
  },
  {
    lng: -43.2228841,
    lat: -22.873178
  },
  {
    lng: -43.2231605,
    lat: -22.8732781
  },
  {
    lng: -43.2231521,
    lat: -22.8733
  },
  {
    lng: -43.2231734,
    lat: -22.8733119
  },
  {
    lng: -43.2233087,
    lat: -22.8733533
  },
  {
    lng: -43.2234453,
    lat: -22.8734142
  },
  {
    lng: -43.2235964,
    lat: -22.8734652
  },
  {
    lng: -43.223685,
    lat: -22.8735062
  },
  {
    lng: -43.2238943,
    lat: -22.8735689
  },
  {
    lng: -43.2240409,
    lat: -22.8736381
  },
  {
    lng: -43.2237073,
    lat: -22.8743789
  },
  {
    lng: -43.2237851,
    lat: -22.8745874
  },
  {
    lng: -43.2239746,
    lat: -22.8746733
  },
  {
    lng: -43.2239068,
    lat: -22.8748891
  },
  {
    lng: -43.2239469,
    lat: -22.8749465
  },
  {
    lng: -43.22399,
    lat: -22.8749712
  },
  {
    lng: -43.2240918,
    lat: -22.875
  },
  {
    lng: -43.2242971,
    lat: -22.8747206
  },
  {
    lng: -43.2243809,
    lat: -22.8747715
  },
  {
    lng: -43.2241756,
    lat: -22.8750519
  },
  {
    lng: -43.2246877,
    lat: -22.8753433
  },
  {
    lng: -43.2249376,
    lat: -22.8751203
  },
  {
    lng: -43.2250253,
    lat: -22.8749524
  },
  {
    lng: -43.2250373,
    lat: -22.8748248
  },
  {
    lng: -43.2259113,
    lat: -22.8740553
  },
  {
    lng: -43.2261637,
    lat: -22.8740708
  },
  {
    lng: -43.22653,
    lat: -22.874221
  },
  {
    lng: -43.2266964,
    lat: -22.8741742
  },
  {
    lng: -43.2267523,
    lat: -22.8740635
  },
  {
    lng: -43.2267296,
    lat: -22.8739514
  },
  {
    lng: -43.2262901,
    lat: -22.8737707
  },
  {
    lng: -43.2261694,
    lat: -22.8736768
  },
  {
    lng: -43.2260005,
    lat: -22.8734915
  },
  {
    lng: -43.2259454,
    lat: -22.8733518
  },
  {
    lng: -43.225916,
    lat: -22.8732209
  },
  {
    lng: -43.2259052,
    lat: -22.8731023
  },
  {
    lng: -43.2259132,
    lat: -22.8729885
  },
  {
    lng: -43.2259522,
    lat: -22.8727835
  },
  {
    lng: -43.2259589,
    lat: -22.8727068
  },
  {
    lng: -43.2259387,
    lat: -22.8725808
  },
  {
    lng: -43.2259467,
    lat: -22.8725351
  },
  {
    lng: -43.2259696,
    lat: -22.8725104
  },
  {
    lng: -43.2260032,
    lat: -22.872498
  },
  {
    lng: -43.2260984,
    lat: -22.8725091
  },
  {
    lng: -43.226148,
    lat: -22.8725054
  },
  {
    lng: -43.2261842,
    lat: -22.8724943
  },
  {
    lng: -43.2262043,
    lat: -22.8724573
  },
  {
    lng: -43.2262352,
    lat: -22.872346
  },
  {
    lng: -43.2262325,
    lat: -22.872304
  },
  {
    lng: -43.2262124,
    lat: -22.8722707
  },
  {
    lng: -43.2261654,
    lat: -22.8722398
  },
  {
    lng: -43.2260018,
    lat: -22.8721519
  },
  {
    lng: -43.2259643,
    lat: -22.8721039
  },
  {
    lng: -43.2259548,
    lat: -22.8720359
  },
  {
    lng: -43.2259683,
    lat: -22.8719827
  },
  {
    lng: -43.2259937,
    lat: -22.8718814
  },
  {
    lng: -43.2260098,
    lat: -22.8717887
  },
  {
    lng: -43.2260179,
    lat: -22.8716813
  },
  {
    lng: -43.2260191,
    lat: -22.8715886
  }
];

export default polygonCoordsRio21;