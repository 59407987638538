import React from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

export default function FooterHome() {
  return (
    <footer style={styles.footer}>
      <Typography variant="body2" style={{ color: '#E5E6F0', marginTop: 5 }} align="center">
        {'Copyright © '}
        <Link color="inherit" href="http://kintelligence.com.br">
          K-Intelligence
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    </footer>
  );
}

let styles = {
  footer: {
    backgroundColor: '#343434 ',
    height: 60,
    paddingTop: 40,
    paddingBotton: 40,
    margin: -10,
    marginTop: 10,
    width: window.innerWidth,
  }
};
