import React, { Component } from 'react';
import api from '../services/api';
import apiString from '../services/apiString';
import apiStringServer from '../services/apiStringServer';

import CitzenHeader from '../components/CitzenHeader';
import FooterHome from '../components/FooterHome';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Assignment from '@material-ui/icons/Assignment';
import Room from '@material-ui/icons/HouseTwoTone';
import MapIcon from '@material-ui/icons/Map';
import Send from '@material-ui/icons/Send';
import Person from '@material-ui/icons/Person';
import Email from '@material-ui/icons/Email';
import Home from '@material-ui/icons/Home';
import Fingerprint from '@material-ui/icons/Fingerprint';
import Phone from '@material-ui/icons/Phone';
import LocationOn from '@material-ui/icons/LocationOn';
import TextField from '@material-ui/core/TextField';
import AttachFile from '@material-ui/icons/AttachFile';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import Schooling from '@material-ui/icons/School';
import Working from '@material-ui/icons/Work';
import Marital from '@material-ui/icons/Favorite';
import Visibility from '@material-ui/icons/Visibility';
import Feed from '@material-ui/icons/Info';
import Child from '@material-ui/icons/ChildCare';

import ImageList from '../components/ImageList';

import theme from '../themes/theme';
import { ThemeProvider } from '@material-ui/core';

import { Map, GoogleApiWrapper, Marker, Polygon } from 'google-maps-react';

import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import Geocode from "react-geocode";
import { color } from '@mui/system';

import green_marker from '../assets/green_marker.png';

Geocode.setApiKey("AIzaSyDC6df7s7nceeG2sHhFGu0-nzLppDiHpe0");
Geocode.setLanguage("pt-BR");

export class CitzenHome extends Component {

  state = {
    login: '',
    option: '',
    selected_city: this.props.location.state.fields.selected_city,

    user: [],
    extrauser: [],
    sonsArray: [],
    user_id: '',
    documents: [],
    rooms: [],
    email: '',
    name: '',
    phone: '',
    cep: '',
    street: '',
    number: '',
    district: '',
    city: '',
    state: '',
    file: null,
    fileName: '',
    address: '',

    msg: '',

    map: null,

    zoom: 19,
    latitude: '',
    longitude: '',
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},

    polygonCoords: [],
    area: '',

    markers: [],

    formView: false,
    formView2: true,
    roomsView: false,
    map2View: false,

    identification: '',
    house: '',
    location: '',

    open_error: false,
    open: false,
  }

  async componentDidMount() {

    if (this.props.location.state) {
      await this.setState({ user_id: this.props.location.state.fields.user_id });
      await this.setState({ login: this.props.location.state.fields.login });
      await this.setState({ option: this.props.location.state.fields.option });
      await this.setState({ selected_city: this.props.location.state.fields.selected_city });
    }

    try {
      let resp = await api.get('/user', {
        params: {
          cpf_cnpj: this.state.login,
          selected_city: this.state.selected_city
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('name')) {

            this.setState({ user: [resp.data] });

          } else {
            let msg = JSON.stringify(resp.data);
            console.log(msg);
          }
        }
      }
    } catch (err) {
      console.log('Não foi possível buscar os dados do usuário. ' + err);
    }

    this.handleExtraUser();

    try {
      let resp = await api.get('/checkuserarea', {
        params: {
          cpf_cnpj: this.state.login,
          selected_city: this.state.selected_city
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('name')) {

            this.setState({ area: resp.data.area });
            let polygon = resp.data.polygon;
            polygon = polygon.split('"').join('');
            polygon = polygon.split('[').join('');
            polygon = polygon.split('latitude').join('"lat"');
            polygon = polygon.split('longitude').join('"lng"');
            polygon = `[${polygon}]`;
            let polygonJson = JSON.parse(polygon);

            this.setState({ latitude: polygonJson[0].lat });
            this.setState({ longitude: polygonJson[0].lng });

            await this.setState({ polygonCoords: polygonJson });

            //alert(JSON.stringify(this.state.polygonCoords)); 

          } else {
            let msg = JSON.stringify(resp.data);
            console.log(msg);
          }
        }
      }
    } catch (err) {
      console.log('Não foi possível buscar os dados da área. ' + err);
    }

    this.handleDocuments();
    await this.handleImages();
    this.setState({ formView: true });
    this.setState({ formView2: false });
    this.setState({ roomsView: false });
    await this.setState({ map2View: true });
    styles.container = {
      height: 600,
      width: window.innerWidth - (window.innerWidth / 1.8),
      backgroundColor: '#bfcadb', //343434
      margin: 10
    };
    await this.getMarkers();
    await this.handleMap2();
  }

  getMarkers = async () => {
    try {
      let resp = await api.get('/placesaround');
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('place')) {

            await this.setState({ markers: resp.data });

          } else {
            let msg = JSON.stringify(resp.data);
            console.log(msg);
          }
        }
      }
    } catch (err) {
      console.log('Não foi possível buscar os dados dos marcadores. ' + err);
    }
  }

  handleExtraUser = async () => {

    try {
      let resp = await api.get('/extrauser', {
        params: {
          cpf_cnpj: this.state.login,
          selected_city: this.state.selected_city
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('name')) {

            await this.setState({ extrauser: [resp.data] });
            let sons = '';
            sons = `[${[resp.data][0].sons_array}]`;
            await this.setState({ sonsArray: JSON.parse(sons) });

          } else {
            let msg = JSON.stringify(resp.data);
            console.log(msg);
          }
        }
      }
    } catch (err) {
      console.log('Não foi possível buscar os dados extra do usuário. ' + err);
    }

  }

  handleImages = async () => {

    try {
      let resp = await api.get('/images', {
        params: {
          user_id: this.state.user_id
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('rooms')) {

            await this.setState({ rooms: [resp.data] });

          } else {
            let msg = JSON.stringify(resp.data);
            //alert(msg);
          }
        }
      }
    } catch (err) {
      alert('Não foi possível buscar os cômodos. ' + err);
    }

  }

  onMapClicked = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      });
    }
  };

  onMarkerClick = (props, marker) => {

    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });

  }

  handleNewFile = async e => {
    await e.preventDefault();

    await this.setState({ file: e.target.files[0] });
    const fileSize = this.state.file.size;

    if (fileSize > 5000000) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      this.setState({ msg: 'Tamanho máximo permitido: 5 MB' });
      this.setState({ open_error: true });
      this.setState({ open: false });
    } else {
      this.setState({ fileName: this.state.file.name });

      const data = new FormData();
      data.append('user_id', this.state.user_id);
      data.append('selected_city', this.state.selected_city);
      data.append('document', this.state.file);

      try {
        let resp = await api.post('/storedocument', data);
        if (resp != null) {
          if (resp.data) {
            if (JSON.stringify(resp.data).includes('registred')) {
              //documento salvo
              this.handleDocuments();
            } else {
              this.setState({ msg: 'Não foi possível anexar o documento. ' });
              this.setState({ open: false });
              this.setState({ open_error: true });
            }
          }
        }
      } catch (err) {
        this.setState({ msg: 'Não foi possível anexar o documento. ' + err });
        this.setState({ open: false });
        this.setState({ open_error: true });
      }
    }
  }

  handleDocuments = async () => {

    try {
      let resp = await api.get('/documents', {
        params: {
          user_id: this.state.user_id
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('name')) {
            
            this.setState({ documents: resp.data });

          } else {
            let msg = JSON.stringify(resp.data);
            const finalmsg = msg.toString().split('"').join('');
            await this.setState({ msg: finalmsg });
            await this.setState({ open: false });
            if (this.state.msg == '[]') {
              this.setState({ open_error: false });
            } else {
              this.setState({ open_error: true });
            }
          }
        }
      }
    } catch (err) {
      console.error(err);
      await this.setState({ msg: 'Usuário incorreto. ' + err });
      await this.setState({ open: false });
      this.setState({ open_error: true });
    }

  }

  handleAddMarker = async (map) => {

    const myLatLng = { lat: this.state.latitude, lng: this.state.longitude + 0.00001 };
    new this.props.google.maps.Marker({
      map: map,
      position: myLatLng,
      title: 'Local',
      icon: {
        url: green_marker,
        scaledSize: new window.google.maps.Size(20, 30)
      }
    });

  }

  handleMap2 = async () => {

    var map = new this.props.google.maps.Map(document.getElementById("map"), {
      center: { lat: this.state.latitude, lng: this.state.longitude },
      zoom: this.state.zoom,
      mapTypeId: 'hybrid'
    });
    this.setState({ map });
    var request = {
      placeId: "ChIJN1t_tDeuEmsRUsoyG83frY4",
      fields: ["name", "formatted_address", "place_id", "geometry"],
    };
    var infowindow = new this.props.google.maps.InfoWindow();
    var service = new this.props.google.maps.places.PlacesService(map);

    const myLatLng = { lat: this.state.latitude, lng: this.state.longitude };
    new this.props.google.maps.Marker({
      map: map,
      position: myLatLng,
      title: 'Local'
    });

    for (let i = 0; i < this.state.markers.length; i++) {
      const marker = this.state.markers[i];
      new this.props.google.maps.Marker({
        map: map,
        position: { lat: parseFloat(marker.latitude), lng: parseFloat(marker.longitude) },
        title: `Nome: ${marker.place} \nTipo: ${marker.type} \nSobre: ${marker.description}`,
        icon: {
          url: green_marker,
          scaledSize: new window.google.maps.Size(20, 30)
        }
      });
    }

    const newPolygon = new this.props.google.maps.Polygon({
      paths: this.state.polygonCoords,
      strokeColor: "#001D5F",
      strokeOpacity: 0.5,
      strokeWeight: 2,
      fillColor: "#001D5F",
      fillOpacity: 0.7,
    });
  
    newPolygon.setMap(map);

    let props = this.props;
    service.getDetails(request, function (place, status) {
      if (status === props.google.maps.places.PlacesServiceStatus.OK &&
        place &&
        place.geometry &&
        place.geometry.location) {
        var marker_1 = new props.google.maps.Marker({
          map: map,
          position: place.geometry.location,
        });
        props.google.maps.event.addListener(marker_1, "click", function () {
          var content = document.createElement("div");
          var nameElement = document.createElement("h2");
          nameElement.textContent = place.name;
          content.appendChild(nameElement);
          var placeIdElement = document.createElement("p");
          placeIdElement.textContent = place.place_id;
          content.appendChild(placeIdElement);
          var placeAddressElement = document.createElement("p");
          placeAddressElement.textContent = place.formatted_address;
          content.appendChild(placeAddressElement);
          infowindow.setContent(content);
          infowindow.open(map, marker_1);
        });
      }
    });
  

  }

  handleLatLng = async (address) => {

    await Geocode.fromAddress(address).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        this.setState({ latitude: lat, longitude: lng });
        this.setState({ open_error: false});
      },
      (error) => {
        this.setState({ msg: 'Não foi possível encontrar este endereço.' });
        this.setState({ open_error: true});
      }
    );

    this.handleAddMarker(this.state.map);

    this.setState({ address: '' });

  }

  handleChange = async e => {
    await this.setState({ [e.target.name]: e.target.value });
  }

  render() {

    return(

      <div style={{ display: 'flex', flexDirection: 'column' }}>

        <div style={styles.html}>

          <CitzenHeader history={this.props.history} city={this.state.selected_city} {...this.props}/>

          <ThemeProvider theme={theme}>
            <div style={styles.directionRow}>

              <div style={styles.container}>

                {this.state.user.map((item) =>
                  <div style={{ padding: 10 }}>
                    <Typography variant="h6" color="textSecondary" align="left">
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={styles.icon}>
                          <Person />
                        </div>
                        {item.name}
                      </div>
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary" align="left">
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={styles.icon}>
                          <Email />
                        </div>
                        {item.email}
                      </div>
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary" align="left">
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={styles.icon}>
                          <Home />
                        </div>
                        {`${item.street}, ${item.number} - ${item.district}, ${item.city} - ${item.state}`}
                      </div>
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary" align="left">
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={styles.icon}>
                          <Fingerprint />
                        </div>
                        {item.cpf_cnpj}
                      </div>
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary" align="left">
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={styles.icon}>
                          <Phone />
                        </div>
                        {item.phone}
                      </div>
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary" align="left">
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={styles.icon}>
                          <LocationOn />
                        </div>
                        {`Área: ${this.state.area} m²`}
                      </div>
                    </Typography>
                    {this.state.area === '' ? (
                      <Typography variant="subtitle1" style={{ color: '#FF0000' }} align="left">
                        {`Acesse o aplicativo para finalizar seu cadastro.`}
                      </Typography>
                    ) : null}
                  </div>
                )}

                {this.state.extrauser.map((item) =>
                  <div style={{ paddingLeft: 10 }}>
                    <Typography variant="subtitle1" color="textSecondary" align="left">
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={styles.icon}>
                          <Schooling />
                        </div>
                        {`Escolaridade atual: ${item.schooling === 'fun' ? 'Ensino Fundamental' :
                          item.schooling === 'med' ? 'Ensino Médio' : item.schooling === 'sup' ? 'Ensino Superior em andamento' :
                            item.schooling === 'con' ? 'Ensino Superior completo' : 'Não informada'} (${item.course})`}
                      </div>
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary" align="left">
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={styles.icon}>
                          <Working />
                        </div>
                        {`Emprego atual: ${item.working === 'yes' ?
                          `${item.contract_type} - ${item.work} - ${item.description} (${item.experience} de experiência)` : 'Não informado'}`}
                      </div>
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary" align="left">
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={styles.icon}>
                          <Marital />
                        </div>
                        {`Estado civil: ${item.marital_status === 'sol' ? 'Solteiro' : item.marital_status === 'noi' ? 'Noivo' :
                          item.marital_status === 'uni' ? 'União Estável' : item.marital_status === 'cas' ? 'Casado' :
                            item.marital_status === 'div' ? 'Divorciado' : item.marital_status === 'viu' ? 'Viúvo' : 'Não informado'}`}
                      </div>
                    </Typography>
                    {item.marital_status !== 'uni' && item.marital_status !== 'cas' ? null : (
                      <Typography variant="subtitle1" color="textSecondary" align="left">
                        <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 10 }}>
                          <div style={styles.icon}>
                            <Feed />
                          </div>
                          {`Informações do cônjuje: Data de nascimento: ${item.spouse_born_date}`} <br />
                          {`Escolaridade atual: ${item.spouse_schooling === 'fun' ? 'Ensino Fundamental' :
                            item.spouse_schooling === 'med' ? 'Ensino Médio' : item.spouse_schooling === 'sup' ? 'Ensino Superior em andamento' :
                              item.spouse_schooling === 'con' ? 'Ensino Superior concluído' : 'Não informada'} (${item.spouse_course})`} <br />
                          {`Emprego atual: ${item.spouse_working === 'yes' ?
                            `${item.spouse_contract_type} - ${item.spouse_work} - ${item.spouse_description} (${item.spouse_experience} de experiência)` : 'Não informado'}`}
                        </div>
                      </Typography>
                    )}
                    {item.sons === 'yes' ? (
                      <div>
                        <Typography variant="subtitle1" color="textSecondary" align="left">
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={styles.icon}>
                              <Child />
                            </div>
                            {`Número de filhos: ${item.sons_number}`}
                          </div>
                        </Typography>
                        {this.state.sonsArray.map((sons) =>
                          <Typography variant="subtitle1" color="textSecondary" align="left">
                            <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 10 }}>
                              <div style={styles.icon}>
                                <Feed />
                              </div>
                              {`Informações do filho ${sons.number}: Data de nascimento: ${sons.bornDate}`} <br />
                              {`Gênero: ${sons.gender === 'mas' ? 'Masculino' : 'Feminino'}`} <br />
                              {`Escolaridade atual: ${sons.schooling === 'mat' ? 'Maternal' :
                                sons.schooling === 'jar' ? 'Jardim de infância' : sons.schooling === 'fun' ? 'Ensino Fundamental' :
                                  sons.schooling === 'med' ? 'Ensino Médio' : sons.schooling === 'sup' ? 'Ensino Superior' : 'Não informada'}`}
                            </div>
                          </Typography>
                        )}
                      </div>
                    ) : null}
                  </div>
                )}

                <Button
                  variant="contained"
                  color="primary"
                  style={styles.bt}
                  startIcon={<Assignment />}
                  onClick={() => {
                    this.setState({ formView: true });
                    this.setState({ formView2: true });
                    this.setState({ roomsView: false });
                    this.setState({ map2View: false });
                    styles.container = {
                      height: 600,
                      width: window.innerWidth - (window.innerWidth / 1.8),
                      backgroundColor: '#bfcadb', //343434
                      margin: 10
                    }
                  }}
                >
                  Requerimento do legitimado
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  style={styles.bt}
                  startIcon={<Room />}
                  onClick={() => {
                    this.setState({ formView: true });
                    this.setState({ formView2: false });
                    this.setState({ roomsView: true });
                    this.setState({ map2View: false });
                    styles.container = {
                      height: 600,
                      width: window.innerWidth - (window.innerWidth / 1.8),
                      backgroundColor: '#bfcadb', //343434
                      margin: 10
                    }
                  }}
                >
                  Cômodos
                </Button>

              </div>

              {this.state.formView ? (
                <div style={styles.container}>

                  {this.state.formView2 ? (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div>
                          <TextField
                            style={styles.text}
                            id="identification"
                            name="identification"
                            value={this.state.identification}
                            label="Identificação"
                            onChange={this.handleChange}
                            variant="outlined"
                          />

                          <TextField
                            style={styles.text}
                            id="house"
                            name="house"
                            value={this.state.house}
                            label="Imóvel"
                            onChange={this.handleChange}
                            variant="outlined"
                          />

                          <TextField
                            style={styles.text}
                            id="location"
                            name="location"
                            value={this.state.location}
                            label="Localização"
                            onChange={this.handleChange}
                            variant="outlined"
                          />

                          <Button
                            variant="contained"
                            color="primary"
                            style={styles.submit}
                            startIcon={<Send />}
                          >
                            Enviar
                          </Button>

                          <div style={{ marginLeft: 5 }}>
                            <Button
                              variant="contained"
                              color="primary"
                              style={styles.bt}
                              startIcon={<MapIcon />}
                              onClick={() => {
                                this.setState({ formView: false });
                                this.componentDidMount();
                                styles.container = {
                                  height: 600,
                                  width: window.innerWidth - (window.innerWidth / 1.8),
                                  backgroundColor: '#bfcadb', //343434
                                  margin: 10
                                }
                              }}
                            >
                              Voltar ao mapa
                            </Button>
                          </div>
                        </div>

                        <div style={{ flexDirection: 'column', marginTop: 10 }}>
                          <Typography variant="h6" color="textSecondary" align="left">
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                              <div style={styles.icon}>
                                <AttachFile />
                              </div>
                              Anexo de arquivos
                            </div>
                          </Typography>

                          <div style={styles.container2}>

                            {this.state.open ? (
                              <Alert
                                severity="success"
                                action={
                                  <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                      this.handleClose();
                                    }}
                                  >
                                    <CloseIcon fontSize="inherit" />
                                  </IconButton>
                                }
                              >
                                {this.state.msg}
                              </Alert>
                            ) : null}

                            {this.state.open_error ? (
                              <Alert
                                severity="error"
                                style={{ marginBottom: 10 }}
                                action={
                                  <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                      this.setState({ open_error: false });
                                    }}
                                  >
                                    <CloseIcon fontSize="inherit" />
                                  </IconButton>
                                }
                              >
                                {this.state.msg}
                              </Alert>
                            ) : null}

                            <div style={{ display: 'flex', flexDirection: 'column', margin: 10, marginTop: 20 }}>
                              {this.state.documents.map((item) =>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                  <a target="_blank" href={`${apiStringServer}/documents/${item.file}`} rel="noreferrer">
                                    <Typography variant="subtitle1" color="textSecondary" align="left">
                                      {item.file}
                                    </Typography>
                                  </a>

                                  <IconButton
                                    aria-label="close"
                                    color="#800000"
                                    style={{ marginTop: -15 }}
                                    onClick={async () => {
                                      await this.setState({ fileName: '' });
                                      //await this.setState({ document_id: item.document_id });
                                      //await this.setState({ document_name: item.file });
                                      //this.handleDeleteDocument();
                                    }}
                                  >
                                    <Close />
                                  </IconButton>
                                </div>
                              )}
                            </div>

                            <input
                              accept="file_extension|audio/*|video/*|image/*|media_type"
                              style={{ display: 'none' }}
                              id="button-attach"
                              multiple
                              type="file"
                              onChange={this.handleNewFile}
                            />
                            <label htmlFor="button-attach">
                              <Button
                                component="span"
                                variant="contained"
                                size="small"
                                color="primary"
                                style={styles.bt}
                                startIcon={<AttachFile />}
                              >
                                Anexar documento
                              </Button>
                            </label>

                          </div>
                        </div>
                      </div>

                    </div>
                  ) : (null
                    /*{<div style={styles.examples}>
                      <a
                        href={`${apiStringServer}/docs/plano_diretor_estrutura_e_conteudo.pdf`}
                        style={styles.href}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Plano Diretor - Estrutura e conteúdo
                      </a>
                      <a
                        href={`${apiStringServer}/docs/lei_complementar_2011_plano_diretor.pdf`}
                        style={styles.href}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Plano Diretor - Lei complementar
                      </a>
                      <a
                        href={`${apiStringServer}/docs/mapa1_macrozoneamento.pdf`}
                        style={styles.href}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Mapa 1 - Macrozoneamento
                      </a>
                      <a
                        href={`${apiStringServer}/docs/mapa2_hipsometria_hidrografia_e_subbacias_hidrograficas.pdf`}
                        style={styles.href}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Mapa 2 - Hipsometria, Hidrografia e Sub-bacias hidrográficas
                      </a>
                      <a
                        href={`${apiStringServer}/docs/mapa3_areas_protegidas.pdf`}
                        style={styles.href}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Mapa 3 - Áreas protegidas
                      </a>
                      <a
                        href={`${apiStringServer}/docs/mapa4_regioes_administrativas.pdf`}
                        style={styles.href}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Mapa 4 - Regiões administrativas
                      </a>
                      <a
                        href={`${apiStringServer}/docs/mapa5_regioes_de_planejamento.pdf`}
                        style={styles.href}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Mapa 5 - Regiões de planejamento
                      </a>
                      <a
                        href={`${apiStringServer}/docs/mapa6_uso_e_cobertura_do_solo.pdf`}
                        style={styles.href}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Mapa 6 - Uso e cobertura do solo
                      </a>
                      <a
                        href={`${apiStringServer}/docs/mapa7_rede_estrutural_viaria.pdf`}
                        style={styles.href}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Mapa 7 - Rede estrutural viária
                      </a>
                      <a
                        href={`${apiStringServer}/docs/mapa8_limites_administrativos_rarp.pdf`}
                        style={styles.href}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Mapa 8 - Limites administrativos RARP
                      </a>
                      <a
                        href={`${apiStringServer}/docs/mapa9_areas_de_intervencao_e_macrozona.pdf`}
                        style={styles.href}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Mapa 9 - Áreas de intervenção Macrozona
                      </a>
                      <a
                        href={`${apiStringServer}/docs/mapa10_areas_intervencao.pdf`}
                        style={styles.href}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Mapa 10 - Áreas de intervenção
                      </a>
                      <a
                        href={`${apiStringServer}/docs/mapa11_vetores_de_crescimento.pdf`}
                        style={styles.href}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Mapa 11 - Vetores de crescimento
                      </a>
                      <a
                        href={`${apiStringServer}/docs/mapa12_meio_ambiente.pdf`}
                        style={styles.href}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Mapa 12 - Meio ambiente
                      </a>
                      <a
                        href={`${apiStringServer}/docs/mapa13_meio_ambiente_sitios.pdf`}
                        style={styles.href}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Mapa 13 - Meio ambiente (sítios)
                      </a>
                      <a
                        href={`${apiStringServer}/docs/mapa14_saneamento_ambiental.pdf`}
                        style={styles.href}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Mapa 14 - Saneamento ambiental
                      </a>
                      <a
                        href={`${apiStringServer}/docs/mapa15_habitacao.pdf`}
                        style={styles.href}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Mapa 15 - Habitação
                      </a>
                      <a
                        href={`${apiStringServer}/docs/mapa16_transportes.pdf`}
                        style={styles.href}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Mapa 16 - Transportes
                      </a>
                      <a
                        href={`${apiStringServer}/docs/relatorio_2009_antecedentes_e_diagnostico.pdf`}
                        style={styles.href}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Relatório 2009 - Antecedentes e Diagnóstico
                      </a>
                      <a
                        href={`${apiStringServer}/docs/relatorio_2009_proposta_de_politica_urbana_parte3.pdf`}
                        style={styles.href}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Relatório 2009 - Proposta de política urbana (parte 3)
                      </a>
                      <a
                        href={`${apiStringServer}/docs/importancia_do_plano_diretor_municipal.docx`}
                        style={styles.href}
                        target="_blank"
                        rel="noreferrer"
                      >
                        A iportância do Plano Diretor Municipal
                      </a>
                      <a
                        href={`${apiStringServer}/docs/atos_e_procedimentos_da_regularizacao_fundiaria.docx`}
                        style={styles.href}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Atos e procedimentos da regularização fundiária
                      </a>
                  </div>}*/
                  )}

                  {this.state.roomsView ? (

                    <div style={styles.container3}>

                      <Typography variant="h6" color="textSecondary" align="center">
                        Cômodos
                      </Typography>

                      {this.state.rooms ? (
                        <ImageList rooms={this.state.rooms} {...this.props} />
                      ) : null}

                      <div style={{ marginLeft: 5, marginTop: 20 }}>
                        <Button
                          variant="contained"
                          color="primary"
                          style={styles.bt}
                          startIcon={<MapIcon />}
                          onClick={() => {
                            this.setState({ formView: false });
                            this.componentDidMount();
                            styles.container = {
                              height: 600,
                              width: window.innerWidth - (window.innerWidth / 1.8),
                              backgroundColor: '#bfcadb', //343434
                              margin: 10
                            }
                          }}
                        >
                          Voltar ao mapa
                        </Button>
                      </div>

                    </div>

                  ) : null}

                  {this.state.map2View ? (
                    <div>
                      <div id="map" style={styles.map2}>

                      </div>

                      <div style={{ marginTop: 10 }}>
                        <GooglePlacesAutocomplete
                          apiKey="AIzaSyCruPXiMhFujyljmN2-9AdUW4rHHPSfABQ"
                          apiOptions={{ language: 'pt-BR', region: 'br' }}
                          selectProps={{
                            value: this.state.address,
                            onChange: async (value) => { await this.setState({ address: value }); this.handleLatLng(this.state.address.label) },
                          }}
                        />
                      </div>

                      <script
                        src="https://maps.googleapis.com/maps/api/js?key=AIzaSyCruPXiMhFujyljmN2-9AdUW4rHHPSfABQ&callback=initMap&libraries=places&v=weekly"
                        defer
                      ></script>
                    </div>
                  ) : null}

                </div>
              ) : (
                <div>
                  <Map
                    key={'AIzaSyDC6df7s7nceeG2sHhFGu0-nzLppDiHpe0'}
                    google={this.props.google}
                    zoom={this.state.zoom}
                    style={styles.map}
                    mapType="hybrid"
                    center={{
                      lat: this.state.latitude,
                      lng: this.state.longitude
                    }}
                    onClick={this.onMapClicked}
                  >

                    <Polygon
                      paths={this.state.polygonCoords}
                      strokeColor="#001D5F"
                      strokeOpacity={0.5}
                      strokeWeight={2}
                      fillColor="#001D5F"
                      fillOpacity={0.7} />

                    <Marker
                      position={{
                        lat: this.state.latitude,
                        lng: this.state.longitude
                      }}
                      onClick={async (props, marker) => {
                        this.onMarkerClick(props, marker);
                      }}
                      name={`Local`}
                    />

                  </Map>
                </div>
              )}

            </div>
          </ThemeProvider>

        </div>

        <FooterHome />

      </div>

    );

  }

}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDC6df7s7nceeG2sHhFGu0-nzLppDiHpe0',
  language: 'pt-BR'
})(CitzenHome)

let styles = {

  html: {
    backgroundColor: '#E5E6F0', //454545
    height: window.innerHeight + 150,
    width: window.innerWidth,
    margin: -10,
    paddingBottom: 200,
  },

  container: {
    height: 600,
    width: window.innerWidth - (window.innerWidth / 1.8),
    backgroundColor: '#bfcadb', //343434
    margin: 10
  },

  container2: {
    height: 520,
    width: 300,
    backgroundColor: '#bfcadb', //454545
    margin: 10,
  },

  container3: {
    height: 550,
    width: 600,
    backgroundColor: '#bfcadb', //454545
    margin: 10,
  },

  directionRow: { 
    display: 'flex', 
    flexDirection: 'row' 
  },

  icon: {
    marginTop: 2.5,
    marginRight: 5,
  },

  map: {
    height: 600,
    width: window.innerWidth - (window.innerWidth / 2),
    marginLeft: 10,
    marginTop: 10,
    alignSelf: 'flex-start',
  },
  map2: {
    height: 600,
    width: window.innerWidth - (window.innerWidth / 2),
    alignSelf: 'flex-start',
  },

  bt: {
    margin: 10,
    marginBottom: 10,
    textTransform: 'none',
    backgroundColor: '#001D5F',
  },

  bt2: {
    margin: 10,
    width: '96.7%',
    textTransform: 'none',
    backgroundColor: '#001D5F',
  },

  examples: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#454545',
    margin: 10,
    marginTop: 30,
  },

  href: {
    margin: 10,
    color: '#fff'
  },

  text: {
    margin: 15,
    width: 335,
  },

  submit: {
    width: 200,
    margin: 15,
    textTransform: 'none',
    backgroundColor: '#001D5F',
  },

}