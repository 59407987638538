import React, { Component } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Person from '@material-ui/icons/Person';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import Home from '@material-ui/icons/Home';
import MapIcon from '@material-ui/icons/Map';
import LocationOn from '@material-ui/icons/LocationOn';
import NotificationsActive from '@material-ui/icons/NotificationsActive';
import Badge from '@material-ui/core/Badge';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Settings from '@material-ui/icons/Settings';
import PowerSettings from '@material-ui/icons/PowerSettingsNew';
import PriorityHigh from '@material-ui/icons/PriorityHigh';

import theme from '../themes/theme';
import { ThemeProvider } from '@material-ui/core';

import kreurb_logo from '../assets/kreurb_logo2.png';

export default class CitzenHeader extends Component {

  state = {
    anchorEl: null,
    anchorEl2: null,
    city: this.props.city,
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleClick2 = (event) => {
    this.setState({ anchorEl2: event.currentTarget });
  };

  handleClose2 = () => {
    this.setState({ anchorEl2: null });
  };

  handleHome = e => {
    e.preventDefault();

    this.props.history.push('/homecabo');
  }

  handleExit = () => {
    this.props.history.push('/');
  }

  render() {
    return (
      <header style={styles.header}>
        <ButtonGroup variant="text" color="primary" aria-label="text primary button group"
          style={styles.buttonGroup}
        >
          <Button
            style={styles.homeButton}
            startIcon={
              <div style={{ 
                marginTop: -20
              }}>
                <img src={kreurb_logo} style={{ width: 250, height: 80, borderWidth: 0, borderColor: '#fff' }} />
              </div>
            }
            onClick={this.handleHome}
          />
          <Button
            style={styles.button}
            startIcon={<LocationOn style={{ marginTop: -5 }} />}
          >
            Rio de Janeiro
          </Button>
          <Button
            style={styles.button}
            startIcon={<MapIcon style={{ marginTop: -5 }} />}
          >
            {this.state.city === 'cabo' ? 'Cabo Frio' : 
            this.state.city === 'arraial' ? 'Arraial do Cabo' : 
            this.state.city === 'buzios' ? 'Búzios' : 
            this.state.city === 'mendes' ? 'Mendes' : 
            this.state.city === 'ostras' ? 'Rio das Ostras' : 
            this.state.city === 'paulo' ? 'Paulo de Frontim' : 
            this.state.city === 'rio' ? 'Rio de Janeiro' : 
            this.state.city === 'tresrios' ? 'Três Rios' : ''}
          </Button>
        </ButtonGroup>

        <Button aria-controls="simple-menu2" aria-haspopup="true" onClick={this.handleClick2}
          style={styles.badge}
        >
          <Badge badgeContent={2} color="secondary">
            <NotificationsActive color="secondary" />
          </Badge>
        </Button>
        <ThemeProvider theme={theme}>
        <Menu
          style={styles.menu2}
          id="simple-menu2"
          anchorEl={this.state.anchorEl2}
          keepMounted
          open={Boolean(this.state.anchorEl2)}
          onClose={this.handleClose2}
        >
          <MenuItem onClick={this.handleClose2}><PriorityHigh />2 novos formulários</MenuItem>
          <MenuItem onClick={this.handleClose2}><PriorityHigh />Complete seu perfil</MenuItem>
        </Menu>
        </ThemeProvider>

        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={this.handleClick}>
          <Avatar style={styles.avatar}>
            <Person />
          </Avatar>
        </Button>
        <ThemeProvider theme={theme}>
          <Menu
            style={styles.menu}
            id="simple-menu"
            anchorEl={this.state.anchorEl}
            keepMounted
            open={Boolean(this.state.anchorEl)}
            onClose={this.handleClose}
          >
            <MenuItem onClick={this.handleClose}><Person />Perfil</MenuItem>
            <MenuItem onClick={this.handleClose}><Settings />Configurações</MenuItem>
            <MenuItem onClick={() => { this.handleExit(); this.handleClose(); }}>
              <PowerSettings />Sair
            </MenuItem>
          </Menu>
        </ThemeProvider>
      </header>
    );
  }

}

let styles = {
  header: {
    backgroundColor: '#001D5F',
    height: 80,
    flexDirection: 'row',
  },
  avatar: {
    backgroundColor: '#E5E6F0',
    marginLeft: window.innerWidth -80,
    marginTop: -165,
  },
  buttonGroup: {
    marginLeft: 10,
    marginTop: 15,
  },
  button: {
    marginTop: -10,
    marginBottom: 10,
    textTransform: 'none',
    color: '#E5E6F0',
    borderWidth: 0,
  },
  homeButton: {
    textTransform: 'none',
    color: '#E5E6F0',
    borderWidth: 0,
    borderColor: '#fff',
  },
  badge: {
    marginLeft: window.innerWidth -150,
    marginTop: -120,
  },
  menu: {
    marginLeft: window.innerWidth -200,
    width: 200,
  },
  menu2: {
    marginTop: 40,
    marginRight: 50,
    width: 1000,
  },
};
