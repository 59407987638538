import React, { Component } from 'react';
import api from '../services/api';
import apiString from '../services/apiString';

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

import InputMask from 'react-input-mask';

import backgroundVideo from '../assets/background3.mp4';
import logo from '../assets/kreurb_logoIcon3.png';

import Footer from '../components/Footer';
import theme from '../themes/theme';
import { ThemeProvider } from '@material-ui/core';

export default class Login extends Component {
  state = {
    option: 'cnpj',
    type: 'CNPJ',
    mask: '99.999.999/9999-99',
    login: '',
    password: '',
    city: 'select',

    mailView: false,

    open_error: false,
    msg: '',
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    if (this.state.city !== 'select') {

      const data = new FormData();

      data.append('login', this.state.login);

      try {
        let resp = await api.get('/checkuser', {
          params: {
            cpf_cnpj: this.state.login,
            city: this.state.city,
            password: this.state.password,
          }
        });
        if (resp != null) {
          if (resp.data) {
            if (JSON.stringify(resp.data).includes('name')) {
              if (this.state.option === 'cpf' && resp.data[0].type === 2) {
                this.props.history.push({
                  pathname: '/citzenhome',
                  state: {
                    fields: {
                      user_id: JSON.stringify(resp.data[0].id),
                      login: data.get('login'),
                      option: this.state.option,
                      selected_city: this.state.city
                    }
                  }
                });
              } else if ((this.state.option === 'cnpj') && (this.state.city === 'rio')) {
                this.props.history.push({
                  pathname: '/homerio',
                  state: {
                    fields: {
                      login: data.get('login'),
                      option: this.state.option,
                      user_type: resp.data[0].type,
                      selected_city: this.state.city
                    }
                  }
                });
              } else {
                if ((this.state.option === 'cnpj') && (this.state.city === 'mendes')) {
                  this.props.history.push({
                    pathname: '/home',
                    state: {
                      fields: {
                        login: data.get('login'),
                        option: this.state.option,
                        selected_city: this.state.city
                      }
                    }
                  });
                } else {
                  if ((this.state.option === 'cnpj') && (this.state.city === 'paulo')) {
                    this.props.history.push({
                      pathname: '/homepaulo',
                      state: {
                        fields: {
                          login: data.get('login'),
                          option: this.state.option,
                          selected_city: this.state.city
                        }
                      }
                    });
                  } else {
                    if ((this.state.option === 'cnpj') && (this.state.city === 'tresrios')) {
                      this.props.history.push({
                        pathname: '/hometresrios',
                        state: {
                          fields: {
                            login: data.get('login'),
                            option: this.state.option,
                            selected_city: this.state.city
                          }
                        }
                      });
                    } else {
                      if ((this.state.option === 'cnpj') && (this.state.city === 'arraial')) {
                        this.props.history.push({
                          pathname: '/homearraial',
                          state: {
                            fields: {
                              login: data.get('login'),
                              option: this.state.option,
                              selected_city: this.state.city
                            }
                          }
                        });
                      } else {
                        if (this.state.city === 'cabo' && resp.data[0].type !== 2) {
                          this.props.history.push({
                            pathname: '/homecabo',
                            state: {
                              fields: {
                                login: data.get('login'),
                                option: this.state.option,
                                user_type: resp.data[0].type,
                                selected_area: resp.data[0].selected_area,
                                selected_city: this.state.city
                              }
                            }
                          });
                        } else {
                          if ((this.state.option === 'cnpj') && (this.state.city === 'buzios')) {
                            this.props.history.push({
                              pathname: '/homebuzios',
                              state: {
                                fields: {
                                  login: data.get('login'),
                                  option: this.state.option,
                                  selected_city: this.state.city
                                }
                              }
                            });
                          } else {
                            if ((this.state.option === 'cnpj') && (this.state.city === 'ostras')) {
                              this.props.history.push({
                                pathname: '/homeostras',
                                state: {
                                  fields: {
                                    login: data.get('login'),
                                    option: this.state.option,
                                    selected_city: this.state.city
                                  }
                                }
                              });
                            } else {
                              if (this.state.city === 'saquarema' && resp.data[0].type !== 2) {
                                this.props.history.push({
                                  pathname: '/homesaquarema',
                                  state: {
                                    fields: {
                                      login: data.get('login'),
                                      option: this.state.option,
                                      user_type: resp.data[0].type,
                                      selected_area: resp.data[0].selected_area,
                                      selected_city: this.state.city
                                    }
                                  }
                                });
                              } else {

                                if (this.state.city === 'queimados' && resp.data[0].type !== 2) {
                                  this.props.history.push({
                                    pathname: '/homequeimados',
                                    state: {
                                      fields: {
                                        login: data.get('login'),
                                        option: this.state.option,
                                        user_type: resp.data[0].type,
                                        selected_area: resp.data[0].selected_area,
                                        selected_city: this.state.city
                                      }
                                    }
                                  });
                                } else {
                                  if (this.state.city === 'nova' && resp.data[0].type !== 2) {
                                    this.props.history.push({
                                      pathname: '/homenova',
                                      state: {
                                        fields: {
                                          login: data.get('login'),
                                          option: this.state.option,
                                          user_type: resp.data[0].type,
                                          selected_area: resp.data[0].selected_area,
                                          selected_city: this.state.city
                                        }
                                      }
                                    });
                                  } else {
                                    if (this.state.city === 'sjmeriti' && resp.data[0].type !== 2) {
                                      this.props.history.push({
                                        pathname: '/homesjmeriti',
                                        state: {
                                          fields: {
                                            login: data.get('login'),
                                            option: this.state.option,
                                            user_type: resp.data[0].type,
                                            selected_area: resp.data[0].selected_area,
                                            selected_city: this.state.city
                                          }
                                        }
                                      });
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }

            } else {
              let msg = JSON.stringify(resp.data);
              const finalmsg = msg.toString().split('"').join('');
              this.setState({ msg: finalmsg });
              this.setState({ open_error: true });
            }
          }
        }
      } catch (err) {
        console.error(err);
        this.setState({ msg: 'CNPJ/CPF incorreto. ' + err });
        this.setState({ open_error: true });
      }

    } else {

      this.setState({ msg: 'Selecione um município.' });
      this.setState({ open_error: true });

    }

  }

  handleMask = async e => {
    await this.setState({ [e.target.name]: e.target.value });

    if (this.state.option == "cnpj") {
      this.setState({ mask: '99.999.999/9999-99' });
      this.setState({ type: 'CNPJ' });
    }
    if (this.state.option == "cpf") {
      this.setState({ mask: '999.999.999-99' });
      this.setState({ type: 'CPF' });
    }
    if (this.state.option == "email") {
      this.setState({ mask: '' });
      this.setState({ type: 'E-mail' });
      this.setState({ mailView: true });
    }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    return (
      <html style={styles.html}>
        <video autoPlay muted loop id="myVideo" style={styles.video}>
          <source src={backgroundVideo} type="video/mp4" />
        </video>
        <ThemeProvider theme={theme}>
          <form style={styles.form} id="new_login" onSubmit={this.handleSubmit}>

            <Container component="main" maxWidth="xs">
              <CssBaseline />
              <div style={styles.paper}>
                <img src={logo} style={styles.avatar} />
                <Typography component="h1" variant="h5">
                  Login
                </Typography>

                {this.state.open_error ? (
                  <Alert
                    severity="error"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          this.setState({ open_error: false });
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    {this.state.msg}
                  </Alert>
                ) : null}

                <div style={styles.form}>

                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">Opção</FormLabel>
                      <RadioGroup
                        aria-label="option1"
                        name="option"
                        value={this.state.option}
                        onChange={this.handleMask}
                        style={{ flexDirection: 'row' }}
                      >
                        <FormControlLabel value="cnpj" control={<Radio />} label="CNPJ" />
                        <FormControlLabel value="cpf" control={<Radio />} label="CPF" />
                        <FormControlLabel value="email" control={<Radio />} label="E-mail" />
                      </RadioGroup>
                    </FormControl>

                    <FormControl variant="outlined" style={{ width: 162 }}>
                      <InputLabel htmlFor="outlined-city-native-simple">
                        Município *
                      </InputLabel>
                      <Select
                        native
                        required
                        value={this.state.city}
                        onChange={this.handleChange}
                        label="Município"
                        inputProps={{
                          name: 'city',
                          id: 'outlined-city-native-simple',
                        }}
                      >
                        <option value="select">Selecione</option>
                        <option value="arraial">Arraial do Cabo</option>
                        <option value="buzios">Búzios</option>
                        <option value="cabo">Cabo Frio</option>
                        <option value="mendes">Mendes</option>
                        <option value="nova">Nova Iguaçu</option>
                        <option value="queimados">Queimados</option>
                        <option value="ostras">Rio das Ostras</option>
                        <option value="rio">Rio de Janeiro</option>
                        <option value="paulo">Paulo de Frontin</option>
                        <option value="sjmeriti">São João de Meriti</option>
                        <option value="saquarema">Saquarema</option>
                        <option value="tresrios">Três Rios</option>
                      </Select>
                    </FormControl>
                  </div>

                  {this.state.mailView ? (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="login"
                      label={this.state.type}
                      type="text"
                      id="login"
                      autoComplete="email"
                      InputProps={{ style: styles.input }}
                      onChange={this.handleChange}
                      value={this.state.login}
                    />
                  ) : (
                    <InputMask
                      {...this.props.value}
                      mask={this.state.mask}
                      fullWidth
                      maskChar=" "
                      required
                      name="login"
                      id="login"
                      type="text"
                      onChange={this.handleChange}
                      value={this.state.login}
                    >
                      {(inputProps) =>
                        <TextField
                          {...inputProps}
                          variant="outlined"
                          margin="normal"
                          label={this.state.type}
                        />
                      }
                    </InputMask>
                  )}

                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Senha"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    InputProps={{ style: styles.input }}
                    onChange={this.handleChange}
                    value={this.state.password}
                  />
                  <FormControlLabel
                    control={<Checkbox value="remember" color="primary" />}
                    label="Lembrar de mim"
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    style={styles.submit}
                    startIcon={<ExitToAppIcon />}
                  >
                    Entrar
                  </Button>
                  <Grid container>
                    <Grid item xs>
                      <Link href="#" variant="body2" style={styles.link}>
                        Esqueceu a senha?
                      </Link>
                    </Grid>
                    {/*<Grid item>
                      <Link href={`${apiString}/register`} variant="body2" style={styles.link}>
                        {"Criar nova conta"}
                      </Link>
                    </Grid>*/}
                  </Grid>
                </div>
              </div>
            </Container>
            <Footer />
          </form>
        </ThemeProvider>
      </html>
    );
  }
}

let styles = {
  html: {
    backgroundColor: '#E5E6F0', //343434
    backgroundSize: "100% 100%",
    height: window.innerHeight,
    width: window.innerWidth,
  },
  video: {
    position: 'fixed',
    right: 0,
    bottom: 0,
    minWidth: '100%',
    maxHeight: '100%',
    objectFit: 'cover',
  },
  paper: {
    padding: 30,
    marginTop: -20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#E5E6F0', //343434
    position: 'relative',
  },
  avatar: {
    alignSelf: 'center',
    width: 100,
    height: 70,
    marginBottom: 10,
    marginTop: -10,
    marginRight: 30,
  },
  form: {
    width: '97%', // Fix IE 11 issue.
    paddingTop: 50,
  },
  input: {
    color: '#343434',
  },
  submit: {
    marginTop: 10,
    marginBottom: 10,
    textTransform: 'none',
    backgroundColor: '#001D5F',
  },
  link: {
    color: '#001D5F',
  },
};