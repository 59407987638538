let polygonCoordsRio3 = [
  {
    lng: -43.2260124,
    lat: -22.8714885
  },
  {
    lng: -43.225999,
    lat: -22.871423
  },
  {
    lng: -43.225971,
    lat: -22.8713686
  },
  {
    lng: -43.2259333,
    lat: -22.8713316
  },
  {
    lng: -43.2258817,
    lat: -22.8712963
  },
  {
    lng: -43.2258489,
    lat: -22.8712667
  },
  {
    lng: -43.2258233,
    lat: -22.87124
  },
  {
    lng: -43.225804,
    lat: -22.8712117
  },
  {
    lng: -43.2257859,
    lat: -22.871164
  },
  {
    lng: -43.2257698,
    lat: -22.871098
  },
  {
    lng: -43.2257604,
    lat: -22.8710565
  },
  {
    lng: -43.2257483,
    lat: -22.8709955
  },
  {
    lng: -43.2257416,
    lat: -22.8709485
  },
  {
    lng: -43.2257368,
    lat: -22.8709034
  },
  {
    lng: -43.2257321,
    lat: -22.8708305
  },
  {
    lng: -43.2257348,
    lat: -22.8707817
  },
  {
    lng: -43.225741,
    lat: -22.8707236
  },
  {
    lng: -43.2257383,
    lat: -22.8706722
  },
  {
    lng: -43.2257296,
    lat: -22.8706297
  },
  {
    lng: -43.2257088,
    lat: -22.870595
  },
  {
    lng: -43.225702,
    lat: -22.8705555
  },
  {
    lng: -43.2257027,
    lat: -22.8705216
  },
  {
    lng: -43.2257182,
    lat: -22.8704845
  },
  {
    lng: -43.2257442,
    lat: -22.8704561
  },
  {
    lng: -43.2257811,
    lat: -22.8704215
  },
  {
    lng: -43.2258086,
    lat: -22.8703985
  },
  {
    lng: -43.2258287,
    lat: -22.8703727
  },
  {
    lng: -43.2258502,
    lat: -22.8703188
  },
  {
    lng: -43.2258664,
    lat: -22.8702892
  },
  {
    lng: -43.2258865,
    lat: -22.8702695
  },
  {
    lng: -43.2259139,
    lat: -22.870251
  },
  {
    lng: -43.2259455,
    lat: -22.870228
  },
  {
    lng: -43.2259702,
    lat: -22.8702158
  },
  {
    lng: -43.2259923,
    lat: -22.8702015
  },
  {
    lng: -43.2260151,
    lat: -22.8701824
  },
  {
    lng: -43.2260426,
    lat: -22.8701484
  },
  {
    lng: -43.2260587,
    lat: -22.8701076
  },
  {
    lng: -43.2260627,
    lat: -22.8700736
  },
  {
    lng: -43.2260615,
    lat: -22.8700457
  },
  {
    lng: -43.2260594,
    lat: -22.8699951
  },
  {
    lng: -43.2260641,
    lat: -22.8699482
  },
  {
    lng: -43.2260728,
    lat: -22.8699025
  },
  {
    lng: -43.2260882,
    lat: -22.8698369
  },
  {
    lng: -43.2260997,
    lat: -22.8697746
  },
  {
    lng: -43.226113,
    lat: -22.8696819
  },
  {
    lng: -43.2261171,
    lat: -22.8695633
  },
  {
    lng: -43.2261118,
    lat: -22.8694496
  },
  {
    lng: -43.2261144,
    lat: -22.869326
  },
  {
    lng: -43.2261265,
    lat: -22.8692544
  },
  {
    lng: -43.2261574,
    lat: -22.8691803
  },
  {
    lng: -43.2262043,
    lat: -22.8691172
  },
  {
    lng: -43.2262766,
    lat: -22.8690579
  },
  {
    lng: -43.2263584,
    lat: -22.8690035
  },
  {
    lng: -43.2264818,
    lat: -22.868959
  },
  {
    lng: -43.2271847,
    lat: -22.8687921
  },
  {
    lng: -43.2274864,
    lat: -22.8687095
  },
  {
    lng: -43.2277089,
    lat: -22.8686476
  },
  {
    lng: -43.2279343,
    lat: -22.8685884
  },
  {
    lng: -43.2281596,
    lat: -22.8685229
  },
  {
    lng: -43.2283513,
    lat: -22.8684673
  },
  {
    lng: -43.2285244,
    lat: -22.868414
  },
  {
    lng: -43.228747,
    lat: -22.8683425
  },
  {
    lng: -43.229046,
    lat: -22.8682398
  },
  {
    lng: -43.22925,
    lat: -22.8681645
  },
  {
    lng: -43.2293573,
    lat: -22.868115
  },
  {
    lng: -43.2294612,
    lat: -22.868062
  },
  {
    lng: -43.2295838,
    lat: -22.8679952
  },
  {
    lng: -43.2298091,
    lat: -22.8679038
  },
  {
    lng: -43.2299767,
    lat: -22.8678704
  },
  {
    lng: -43.2302223,
    lat: -22.8678468
  },
  {
    lng: -43.2304166,
    lat: -22.8678482
  },
  {
    lng: -43.2306702,
    lat: -22.8678667
  },
  {
    lng: -43.2308498,
    lat: -22.8678964
  },
  {
    lng: -43.230988,
    lat: -22.8679347
  },
  {
    lng: -43.2312092,
    lat: -22.8680348
  },
  {
    lng: -43.231523,
    lat: -22.8681965
  },
  {
    lng: -43.2316531,
    lat: -22.8682546
  },
  {
    lng: -43.2317565,
    lat: -22.868288
  },
  {
    lng: -43.2317994,
    lat: -22.8683054
  },
  {
    lng: -43.2318355,
    lat: -22.8683338
  },
  {
    lng: -43.2318691,
    lat: -22.8683856
  },
  {
    lng: -43.2319039,
    lat: -22.8684339
  },
  {
    lng: -43.2319415,
    lat: -22.8684586
  },
  {
    lng: -43.2320046,
    lat: -22.8684722
  },
  {
    lng: -43.2323488,
    lat: -22.8681995
  },
  {
    lng: -43.2325772,
    lat: -22.8680372
  },
  {
    lng: -43.2326617,
    lat: -22.8679273
  },
  {
    lng: -43.2326738,
    lat: -22.8678852
  },
  {
    lng: -43.2326903,
    lat: -22.8677401
  },
  {
    lng: -43.2326911,
    lat: -22.8677219
  },
  {
    lng: -43.2326993,
    lat: -22.8676722
  },
  {
    lng: -43.2327022,
    lat: -22.8676523
  },
  {
    lng: -43.2327266,
    lat: -22.8674356
  },
  {
    lng: -43.2327288,
    lat: -22.8674163
  },
  {
    lng: -43.232765,
    lat: -22.8671203
  },
  {
    lng: -43.2328051,
    lat: -22.8668893
  },
  {
    lng: -43.2328629,
    lat: -22.8665915
  },
  {
    lng: -43.2328669,
    lat: -22.8665532
  },
  {
    lng: -43.2328629,
    lat: -22.8665036
  },
  {
    lng: -43.2328683,
    lat: -22.8664432
  },
  {
    lng: -43.2329192,
    lat: -22.8662603
  },
  {
    lng: -43.2329876,
    lat: -22.8660551
  },
  {
    lng: -43.2331298,
    lat: -22.8656857
  },
  {
    lng: -43.233304,
    lat: -22.8652049
  },
  {
    lng: -43.2334382,
    lat: -22.8648948
  },
  {
    lng: -43.2334543,
    lat: -22.8648528
  },
  {
    lng: -43.2334583,
    lat: -22.8648083
  },
  {
    lng: -43.2334423,
    lat: -22.8646131
  },
  {
    lng: -43.2334515,
    lat: -22.8643733
  },
  {
    lng: -43.2334758,
    lat: -22.8641732
  },
  {
    lng: -43.2334958,
    lat: -22.8640595
  },
  {
    lng: -43.2335361,
    lat: -22.8639569
  },
  {
    lng: -43.2335925,
    lat: -22.8638692
  },
  {
    lng: -43.2336555,
    lat: -22.8637851
  },
  {
    lng: -43.233709,
    lat: -22.8637172
  },
  {
    lng: -43.2337613,
    lat: -22.8636367
  },
  {
    lng: -43.2337962,
    lat: -22.8635688
  },
  {
    lng: -43.2338379,
    lat: -22.8634935
  },
  {
    lng: -43.233909,
    lat: -22.8634254
  },
  {
    lng: -43.2340726,
    lat: -22.8633551
  },
  {
    lng: -43.2345567,
    lat: -22.8632105
  },
  {
    lng: -43.2347189,
    lat: -22.8631809
  },
  {
    lng: -43.2350328,
    lat: -22.8631351
  },
  {
    lng: -43.2351333,
    lat: -22.8631117
  },
  {
    lng: -43.2352312,
    lat: -22.8630795
  },
  {
    lng: -43.2354105,
    lat: -22.8629905
  },
  {
    lng: -43.2355585,
    lat: -22.8628941
  },
  {
    lng: -43.2356631,
    lat: -22.8628114
  },
  {
    lng: -43.2357784,
    lat: -22.8627187
  },
  {
    lng: -43.2359273,
    lat: -22.8625925
  },
  {
    lng: -43.2360962,
    lat: -22.8624245
  },
  {
    lng: -43.2363068,
    lat: -22.8622022
  },
  {
    lng: -43.2363525,
    lat: -22.8621676
  },
  {
    lng: -43.2363926,
    lat: -22.8621533
  },
  {
    lng: -43.2364234,
    lat: -22.8621447
  },
  {
    lng: -43.2364437,
    lat: -22.8621311
  },
  {
    lng: -43.236464,
    lat: -22.8621105
  },
  {
    lng: -43.2365213,
    lat: -22.8620304
  },
  {
    lng: -43.2365335,
    lat: -22.8619697
  },
  {
    lng: -43.2365375,
    lat: -22.8618623
  },
  {
    lng: -43.2365402,
    lat: -22.8618129
  },
  {
    lng: -43.2365509,
    lat: -22.8617684
  },
  {
    lng: -43.2365965,
    lat: -22.8616966
  },
  {
    lng: -43.2366997,
    lat: -22.8615558
  },
  {
    lng: -43.2367949,
    lat: -22.8613926
  },
  {
    lng: -43.2369211,
    lat: -22.8611629
  },
  {
    lng: -43.2369425,
    lat: -22.8611085
  },
  {
    lng: -43.2369599,
    lat: -22.861043
  },
  {
    lng: -43.2369667,
    lat: -22.8609479
  },
  {
    lng: -43.2369746,
    lat: -22.8608711
  },
  {
    lng: -43.2369927,
    lat: -22.8605185
  },
  {
    lng: -43.2370431,
    lat: -22.8602533
  },
  {
    lng: -43.2370739,
    lat: -22.860042
  },
  {
    lng: -43.2371075,
    lat: -22.8598084
  },
  {
    lng: -43.2371275,
    lat: -22.8596169
  },
  {
    lng: -43.2371396,
    lat: -22.8594847
  },
  {
    lng: -43.2371598,
    lat: -22.8593562
  },
  {
    lng: -43.2371893,
    lat: -22.8591991
  },
  {
    lng: -43.2372429,
    lat: -22.858936
  },
  {
    lng: -43.2372817,
    lat: -22.8586566
  },
  {
    lng: -43.2373206,
    lat: -22.8583737
  },
  {
    lng: -43.2373488,
    lat: -22.8581105
  },
  {
    lng: -43.2374066,
    lat: -22.8577855
  },
  {
    lng: -43.2374372,
    lat: -22.8576298
  },
  {
    lng: -43.2374735,
    lat: -22.8574876
  },
  {
    lng: -43.2375138,
    lat: -22.8573159
  },
  {
    lng: -43.2375554,
    lat: -22.8571367
  },
  {
    lng: -43.2376143,
    lat: -22.8569167
  },
  {
    lng: -43.2376546,
    lat: -22.8567461
  },
  {
    lng: -43.2376922,
    lat: -22.8565941
  },
  {
    lng: -43.2377311,
    lat: -22.856415
  },
  {
    lng: -43.2377565,
    lat: -22.8563063
  },
  {
    lng: -43.2377995,
    lat: -22.8561344
  },
  {
    lng: -43.237841,
    lat: -22.8559947
  },
  {
    lng: -43.2378848,
    lat: -22.8558536
  },
  {
    lng: -43.2379241,
    lat: -22.8557279
  },
  {
    lng: -43.2379603,
    lat: -22.8556056
  },
  {
    lng: -43.2379952,
    lat: -22.8554684
  },
  {
    lng: -43.2380288,
    lat: -22.8553485
  },
  {
    lng: -43.2380676,
    lat: -22.8552101
  },
  {
    lng: -43.2381093,
    lat: -22.855058
  },
  {
    lng: -43.2381561,
    lat: -22.8549197
  },
  {
    lng: -43.2382245,
    lat: -22.8547305
  },
  {
    lng: -43.2383078,
    lat: -22.8545019
  },
  {
    lng: -43.238368,
    lat: -22.854314
  },
  {
    lng: -43.2384325,
    lat: -22.8541373
  },
  {
    lng: -43.2384968,
    lat: -22.8539656
  },
  {
    lng: -43.2385626,
    lat: -22.8538346
  },
  {
    lng: -43.2385988,
    lat: -22.8537932
  },
  {
    lng: -43.2386417,
    lat: -22.853779
  },
  {
    lng: -43.2387013,
    lat: -22.8537809
  },
  {
    lng: -43.2389797,
    lat: -22.853826
  },
  {
    lng: -43.2390018,
    lat: -22.8538235
  },
  {
    lng: -43.2390119,
    lat: -22.8538044
  },
  {
    lng: -43.2390038,
    lat: -22.8537846
  },
  {
    lng: -43.238983,
    lat: -22.8537685
  },
  {
    lng: -43.2387175,
    lat: -22.8536683
  },
  {
    lng: -43.2386913,
    lat: -22.8536368
  },
  {
    lng: -43.2386872,
    lat: -22.8535949
  },
  {
    lng: -43.2387128,
    lat: -22.8535047
  },
  {
    lng: -43.2388214,
    lat: -22.8531994
  },
  {
    lng: -43.2390118,
    lat: -22.8527705
  },
  {
    lng: -43.2391834,
    lat: -22.8523714
  },
  {
    lng: -43.2393578,
    lat: -22.8519611
  },
  {
    lng: -43.2395939,
    lat: -22.8513704
  },
  {
    lng: -43.239614,
    lat: -22.8513358
  },
  {
    lng: -43.2396395,
    lat: -22.8513246
  },
  {
    lng: -43.2396703,
    lat: -22.8513357
  },
  {
    lng: -43.2397722,
    lat: -22.8514025
  },
  {
    lng: -43.2397991,
    lat: -22.8514074
  },
  {
    lng: -43.2398285,
    lat: -22.8514006
  },
  {
    lng: -43.2399003,
    lat: -22.8512808
  },
  {
    lng: -43.2398989,
    lat: -22.8512579
  },
  {
    lng: -43.2398836,
    lat: -22.8512431
  },
  {
    lng: -43.2397158,
    lat: -22.8512103
  },
  {
    lng: -43.2396971,
    lat: -22.8511875
  },
  {
    lng: -43.2396985,
    lat: -22.8511584
  },
  {
    lng: -43.2397232,
    lat: -22.8511138
  },
  {
    lng: -43.2399265,
    lat: -22.8508711
  },
  {
    lng: -43.2399667,
    lat: -22.8508203
  },
  {
    lng: -43.2399882,
    lat: -22.8507697
  },
  {
    lng: -43.2399921,
    lat: -22.8506944
  },
  {
    lng: -43.2400003,
    lat: -22.8504806
  },
  {
    lng: -43.2400149,
    lat: -22.8503767
  },
  {
    lng: -43.2400526,
    lat: -22.8502605
  },
  {
    lng: -43.2401463,
    lat: -22.8500171
  },
  {
    lng: -43.2403582,
    lat: -22.8496006
  },
  {
    lng: -43.2405582,
    lat: -22.8492187
  },
  {
    lng: -43.2406385,
    lat: -22.8490445
  },
  {
    lng: -43.240715,
    lat: -22.8488715
  },
  {
    lng: -43.2407592,
    lat: -22.848775
  },
  {
    lng: -43.240825,
    lat: -22.8486637
  },
  {
    lng: -43.2409054,
    lat: -22.8485501
  },
  {
    lng: -43.2409752,
    lat: -22.8484401
  },
  {
    lng: -43.2410906,
    lat: -22.8482523
  },
  {
    lng: -43.2411845,
    lat: -22.8481064
  },
  {
    lng: -43.241277,
    lat: -22.8479408
  },
  {
    lng: -43.2413453,
    lat: -22.8478024
  },
  {
    lng: -43.2413936,
    lat: -22.847711
  },
  {
    lng: -43.2414339,
    lat: -22.847638
  },
  {
    lng: -43.2414822,
    lat: -22.8475688
  },
  {
    lng: -43.2415612,
    lat: -22.8474898
  },
  {
    lng: -43.2416431,
    lat: -22.8474317
  },
  {
    lng: -43.2417436,
    lat: -22.8473971
  },
  {
    lng: -43.2418214,
    lat: -22.8473859
  },
  {
    lng: -43.2419046,
    lat: -22.8473884
  },
  {
    lng: -43.242162,
    lat: -22.8474254
  },
  {
    lng: -43.2421915,
    lat: -22.8474156
  },
  {
    lng: -43.2422037,
    lat: -22.8473884
  },
  {
    lng: -43.2421969,
    lat: -22.847329
  },
  {
    lng: -43.2421903,
    lat: -22.8472611
  },
  {
    lng: -43.2421755,
    lat: -22.8470571
  },
  {
    lng: -43.2421621,
    lat: -22.8469348
  },
  {
    lng: -43.2421379,
    lat: -22.8468124
  },
  {
    lng: -43.2421325,
    lat: -22.8467345
  },
  {
    lng: -43.2421447,
    lat: -22.8466764
  },
  {
    lng: -43.2421741,
    lat: -22.8466036
  },
  {
    lng: -43.2423592,
    lat: -22.8463737
  },
  {
    lng: -43.2424344,
    lat: -22.8462786
  },
  {
    lng: -43.2424691,
    lat: -22.8462205
  },
  {
    lng: -43.2424852,
    lat: -22.8461635
  },
  {
    lng: -43.2424946,
    lat: -22.8460821
  },
  {
    lng: -43.2425067,
    lat: -22.8459783
  },
  {
    lng: -43.2425255,
    lat: -22.8458769
  },
  {
    lng: -43.2425443,
    lat: -22.8457879
  },
  {
    lng: -43.242567,
    lat: -22.8456852
  },
  {
    lng: -43.2426193,
    lat: -22.8455259
  },
  {
    lng: -43.242677,
    lat: -22.8453305
  },
  {
    lng: -43.2426998,
    lat: -22.845212
  },
  {
    lng: -43.2427079,
    lat: -22.8451329
  },
  {
    lng: -43.2426998,
    lat: -22.8448004
  },
  {
    lng: -43.2426985,
    lat: -22.8447237
  },
  {
    lng: -43.2427066,
    lat: -22.8446261
  },
  {
    lng: -43.2427199,
    lat: -22.8445656
  },
  {
    lng: -43.2427576,
    lat: -22.8444407
  },
  {
    lng: -43.2427965,
    lat: -22.8443135
  },
  {
    lng: -43.2428139,
    lat: -22.8442257
  },
  {
    lng: -43.2428796,
    lat: -22.8437325
  },
  {
    lng: -43.2429144,
    lat: -22.8435718
  },
  {
    lng: -43.2429734,
    lat: -22.843373
  },
  {
    lng: -43.2430513,
    lat: -22.8431702
  },
  {
    lng: -43.2431049,
    lat: -22.8430343
  },
  {
    lng: -43.2431638,
    lat: -22.8428242
  },
  {
    lng: -43.2431813,
    lat: -22.8427748
  },
  {
    lng: -43.2432067,
    lat: -22.8427302
  },
  {
    lng: -43.2432832,
    lat: -22.8426141
  },
  {
    lng: -43.2432979,
    lat: -22.8425696
  },
  {
    lng: -43.2432979,
    lat: -22.8425128
  },
  {
    lng: -43.2432872,
    lat: -22.8424323
  },
  {
    lng: -43.2432611,
    lat: -22.8422426
  },
  {
    lng: -43.2432665,
    lat: -22.8422038
  },
  {
    lng: -43.2432893,
    lat: -22.8421784
  },
  {
    lng: -43.2433228,
    lat: -22.8421618
  },
  {
    lng: -43.243471,
    lat: -22.8420776
  },
  {
    lng: -43.2435743,
    lat: -22.8419937
  },
  {
    lng: -43.2436588,
    lat: -22.8419059
  },
  {
    lng: -43.2437244,
    lat: -22.8418243
  },
  {
    lng: -43.2439612,
    lat: -22.8414869
  },
  {
    lng: -43.2440289,
    lat: -22.8414114
  },
  {
    lng: -43.244118,
    lat: -22.841341
  },
  {
    lng: -43.2442528,
    lat: -22.8412577
  },
  {
    lng: -43.2444386,
    lat: -22.8411588
  },
  {
    lng: -43.2445163,
    lat: -22.8411106
  },
  {
    lng: -43.2445861,
    lat: -22.8410562
  },
  {
    lng: -43.2446841,
    lat: -22.8409752
  },
  {
    lng: -43.2447899,
    lat: -22.84089
  },
  {
    lng: -43.244861,
    lat: -22.8408226
  },
  {
    lng: -43.2449416,
    lat: -22.8407429
  },
  {
    lng: -43.2450461,
    lat: -22.8406335
  },
  {
    lng: -43.2451421,
    lat: -22.8405358
  },
  {
    lng: -43.2452157,
    lat: -22.8404673
  },
  {
    lng: -43.2452795,
    lat: -22.8404364
  },
  {
    lng: -43.2453244,
    lat: -22.8404178
  },
  {
    lng: -43.2453754,
    lat: -22.8404104
  },
  {
    lng: -43.2454331,
    lat: -22.8404092
  },
  {
    lng: -43.2455162,
    lat: -22.8404154
  },
  {
    lng: -43.2455839,
    lat: -22.8404129
  },
  {
    lng: -43.2456034,
    lat: -22.8404073
  },
  {
    lng: -43.2456113,
    lat: -22.8403956
  },
  {
    lng: -43.2456107,
    lat: -22.8403621
  },
  {
    lng: -43.2456603,
    lat: -22.8403473
  },
  {
    lng: -43.2457287,
    lat: -22.8403318
  },
  {
    lng: -43.2458869,
    lat: -22.8403127
  },
  {
    lng: -43.2459373,
    lat: -22.8403153
  },
  {
    lng: -43.2460339,
    lat: -22.8403363
  },
  {
    lng: -43.2460875,
    lat: -22.8403449
  },
  {
    lng: -43.2461278,
    lat: -22.8403462
  },
  {
    lng: -43.2463162,
    lat: -22.8403245
  },
  {
    lng: -43.2463571,
    lat: -22.8403134
  },
  {
    lng: -43.2463812,
    lat: -22.8403016
  },
  {
    lng: -43.2464181,
    lat: -22.8402646
  },
  {
    lng: -43.2464449,
    lat: -22.8402263
  },
  {
    lng: -43.246459,
    lat: -22.8401781
  },
  {
    lng: -43.246461,
    lat: -22.8401422
  },
  {
    lng: -43.2464449,
    lat: -22.8400705
  },
  {
    lng: -43.2464449,
    lat: -22.8400458
  },
  {
    lng: -43.2464557,
    lat: -22.8400242
  },
  {
    lng: -43.2464999,
    lat: -22.8399958
  },
  {
    lng: -43.2465457,
    lat: -22.8399821
  },
  {
    lng: -43.2465688,
    lat: -22.8399809
  },
  {
    lng: -43.2466072,
    lat: -22.8399976
  },
  {
    lng: -43.2466213,
    lat: -22.8400106
  },
  {
    lng: -43.246626,
    lat: -22.8400335
  },
  {
    lng: -43.246622,
    lat: -22.8400928
  },
  {
    lng: -43.2466287,
    lat: -22.8401354
  },
  {
    lng: -43.2466595,
    lat: -22.840183
  },
  {
    lng: -43.2466964,
    lat: -22.8402151
  },
  {
    lng: -43.2467474,
    lat: -22.8402337
  },
  {
    lng: -43.2468023,
    lat: -22.8402392
  },
  {
    lng: -43.2468634,
    lat: -22.8402294
  },
  {
    lng: -43.2468713,
    lat: -22.8402226
  },
  {
    lng: -43.2468727,
    lat: -22.8402102
  },
  {
    lng: -43.2468386,
    lat: -22.8401218
  },
  {
    lng: -43.2468406,
    lat: -22.8401039
  },
  {
    lng: -43.2468525,
    lat: -22.840094
  },
  {
    lng: -43.2470196,
    lat: -22.8400291
  },
  {
    lng: -43.2470357,
    lat: -22.8400279
  },
  {
    lng: -43.2470457,
    lat: -22.840034
  },
  {
    lng: -43.2470686,
    lat: -22.8400946
  },
  {
    lng: -43.2470793,
    lat: -22.8401008
  },
  {
    lng: -43.247092,
    lat: -22.8400996
  },
  {
    lng: -43.2471175,
    lat: -22.8400916
  },
  {
    lng: -43.2471339,
    lat: -22.8400928
  },
  {
    lng: -43.2471443,
    lat: -22.8401008
  },
  {
    lng: -43.2471802,
    lat: -22.8401521
  },
  {
    lng: -43.2471953,
    lat: -22.8401598
  },
  {
    lng: -43.2472127,
    lat: -22.8401579
  },
  {
    lng: -43.247509,
    lat: -22.8400366
  },
  {
    lng: -43.2475296,
    lat: -22.8400289
  },
  {
    lng: -43.2477961,
    lat: -22.8399303
  },
  {
    lng: -43.2478067,
    lat: -22.8399184
  },
  {
    lng: -43.2478048,
    lat: -22.8398994
  },
  {
    lng: -43.2477518,
    lat: -22.8397962
  },
  {
    lng: -43.2477531,
    lat: -22.8397789
  },
  {
    lng: -43.2477592,
    lat: -22.8397708
  },
  {
    lng: -43.2479061,
    lat: -22.8397096
  },
  {
    lng: -43.2479175,
    lat: -22.8396972
  },
  {
    lng: -43.2479155,
    lat: -22.839675
  },
  {
    lng: -43.247778,
    lat: -22.8393321
  },
  {
    lng: -43.2477745,
    lat: -22.8393117
  },
  {
    lng: -43.2477894,
    lat: -22.8392987
  },
  {
    lng: -43.2478565,
    lat: -22.8392795
  },
  {
    lng: -43.2480697,
    lat: -22.8392363
  },
  {
    lng: -43.2481743,
    lat: -22.8392066
  },
  {
    lng: -43.2482762,
    lat: -22.8391745
  },
  {
    lng: -43.2483888,
    lat: -22.8391226
  },
  {
    lng: -43.2485042,
    lat: -22.8390768
  },
  {
    lng: -43.2486625,
    lat: -22.8390274
  },
  {
    lng: -43.2489473,
    lat: -22.838947
  },
  {
    lng: -43.2491116,
    lat: -22.838889
  },
  {
    lng: -43.2496576,
    lat: -22.8386727
  },
  {
    lng: -43.2503187,
    lat: -22.8383637
  },
  {
    lng: -43.2505345,
    lat: -22.8382611
  },
  {
    lng: -43.2510763,
    lat: -22.8380571
  },
  {
    lng: -43.2512562,
    lat: -22.8379941
  },
  {
    lng: -43.2513581,
    lat: -22.8379472
  },
  {
    lng: -43.2515001,
    lat: -22.837873
  },
  {
    lng: -43.251767,
    lat: -22.8377222
  },
  {
    lng: -43.251995,
    lat: -22.8375887
  },
  {
    lng: -43.2520394,
    lat: -22.8375436
  },
  {
    lng: -43.2520494,
    lat: -22.8375195
  },
  {
    lng: -43.2520534,
    lat: -22.8374565
  },
  {
    lng: -43.2520662,
    lat: -22.8374299
  },
  {
    lng: -43.2520957,
    lat: -22.8374126
  },
  {
    lng: -43.2521393,
    lat: -22.8374058
  },
  {
    lng: -43.2521849,
    lat: -22.8374175
  },
  {
    lng: -43.2522204,
    lat: -22.8374416
  },
  {
    lng: -43.2522699,
    lat: -22.8374985
  },
  {
    lng: -43.2523946,
    lat: -22.8376357
  },
  {
    lng: -43.252431,
    lat: -22.8376561
  },
  {
    lng: -43.2524819,
    lat: -22.8376623
  },
  {
    lng: -43.2525295,
    lat: -22.8376549
  },
  {
    lng: -43.2526816,
    lat: -22.8376011
  },
  {
    lng: -43.2529606,
    lat: -22.8374701
  },
  {
    lng: -43.2530868,
    lat: -22.8374157
  },
  {
    lng: -43.2531349,
    lat: -22.8373723
  },
  {
    lng: -43.2531565,
    lat: -22.8373266
  },
  {
    lng: -43.2531498,
    lat: -22.8371481
  },
  {
    lng: -43.2531518,
    lat: -22.8371129
  },
  {
    lng: -43.2531625,
    lat: -22.8370937
  },
  {
    lng: -43.253178,
    lat: -22.8370863
  },
  {
    lng: -43.253192,
    lat: -22.8370875
  },
  {
    lng: -43.2532095,
    lat: -22.8370999
  },
  {
    lng: -43.253237,
    lat: -22.8371388
  },
  {
    lng: -43.253351,
    lat: -22.8373663
  },
  {
    lng: -43.2537224,
    lat: -22.8380213
  },
  {
    lng: -43.2537466,
    lat: -22.8380522
  },
  {
    lng: -43.2537747,
    lat: -22.8380596
  },
  {
    lng: -43.2537922,
    lat: -22.8380572
  },
  {
    lng: -43.2538069,
    lat: -22.8380385
  },
  {
    lng: -43.2538123,
    lat: -22.8380065
  },
  {
    lng: -43.2535025,
    lat: -22.8374627
  },
  {
    lng: -43.2535011,
    lat: -22.8374415
  },
  {
    lng: -43.2535132,
    lat: -22.8374256
  },
  {
    lng: -43.25383,
    lat: -22.8372906
  },
  {
    lng: -43.253878,
    lat: -22.8372859
  },
  {
    lng: -43.2539089,
    lat: -22.8372946
  },
  {
    lng: -43.2539289,
    lat: -22.8373217
  },
  {
    lng: -43.2541275,
    lat: -22.8377618
  },
  {
    lng: -43.2541502,
    lat: -22.837805
  },
  {
    lng: -43.254177,
    lat: -22.8378248
  },
  {
    lng: -43.2542092,
    lat: -22.8378248
  },
  {
    lng: -43.2543031,
    lat: -22.8377791
  },
  {
    lng: -43.25433,
    lat: -22.837784
  },
  {
    lng: -43.2543486,
    lat: -22.8377939
  },
  {
    lng: -43.2543849,
    lat: -22.837852
  },
  {
    lng: -43.2544037,
    lat: -22.8378656
  },
  {
    lng: -43.2544292,
    lat: -22.8378643
  },
  {
    lng: -43.254531,
    lat: -22.8378075
  },
  {
    lng: -43.2545419,
    lat: -22.8377815
  },
  {
    lng: -43.2545311,
    lat: -22.8377494
  },
  {
    lng: -43.2544627,
    lat: -22.8376678
  },
  {
    lng: -43.2544064,
    lat: -22.8376331
  },
  {
    lng: -43.2542669,
    lat: -22.8376036
  },
  {
    lng: -43.2542321,
    lat: -22.8375863
  },
  {
    lng: -43.2542066,
    lat: -22.8375578
  },
  {
    lng: -43.2539853,
    lat: -22.837027
  },
  {
    lng: -43.2539818,
    lat: -22.8370084
  },
  {
    lng: -43.253992,
    lat: -22.8369911
  },
  {
    lng: -43.2540671,
    lat: -22.8369416
  },
  {
    lng: -43.2540943,
    lat: -22.8369222
  },
  {
    lng: -43.2541197,
    lat: -22.8369059
  },
  {
    lng: -43.2541862,
    lat: -22.8368583
  },
  {
    lng: -43.2546344,
    lat: -22.8365343
  },
  {
    lng: -43.2543528,
    lat: -22.8360475
  },
  {
    lng: -43.2543273,
    lat: -22.836024
  },
  {
    lng: -43.2543005,
    lat: -22.8360153
  },
  {
    lng: -43.2542226,
    lat: -22.8360326
  },
  {
    lng: -43.2540014,
    lat: -22.836134
  },
  {
    lng: -43.2539705,
    lat: -22.836066
  },
  {
    lng: -43.2540691,
    lat: -22.8360245
  },
  {
    lng: -43.2541462,
    lat: -22.8359949
  },
  {
    lng: -43.2541901,
    lat: -22.8359764
  },
  {
    lng: -43.2541523,
    lat: -22.8359356
  },
  {
    lng: -43.2541548,
    lat: -22.8359152
  },
  {
    lng: -43.2541663,
    lat: -22.8359016
  },
  {
    lng: -43.2541851,
    lat: -22.8358978
  },
  {
    lng: -43.2542119,
    lat: -22.8359066
  },
  {
    lng: -43.2542629,
    lat: -22.8359337
  },
  {
    lng: -43.2543085,
    lat: -22.8359498
  },
  {
    lng: -43.2543433,
    lat: -22.8359571
  },
  {
    lng: -43.2543689,
    lat: -22.8359708
  },
  {
    lng: -43.2547094,
    lat: -22.8365184
  },
  {
    lng: -43.2547611,
    lat: -22.8365982
  },
  {
    lng: -43.2548704,
    lat: -22.8365912
  },
  {
    lng: -43.2549655,
    lat: -22.8365851
  },
  {
    lng: -43.2549294,
    lat: -22.8361612
  },
  {
    lng: -43.2549402,
    lat: -22.835909
  },
  {
    lng: -43.2549737,
    lat: -22.835679
  },
  {
    lng: -43.2550179,
    lat: -22.8355049
  },
  {
    lng: -43.255085,
    lat: -22.8353442
  },
  {
    lng: -43.2552017,
    lat: -22.8351587
  },
  {
    lng: -43.2553599,
    lat: -22.8349536
  },
  {
    lng: -43.2557261,
    lat: -22.8345284
  },
  {
    lng: -43.2558889,
    lat: -22.8343616
  },
  {
    lng: -43.2560338,
    lat: -22.8342222
  },
  {
    lng: -43.2560412,
    lat: -22.8342083
  },
  {
    lng: -43.2560419,
    lat: -22.8341972
  },
  {
    lng: -43.2560362,
    lat: -22.8341848
  },
  {
    lng: -43.2560063,
    lat: -22.8341552
  },
  {
    lng: -43.2560526,
    lat: -22.8341107
  },
  {
    lng: -43.2565215,
    lat: -22.833682
  },
  {
    lng: -43.2565608,
    lat: -22.8336486
  },
  {
    lng: -43.2565958,
    lat: -22.8336706
  },
  {
    lng: -43.2566172,
    lat: -22.8336731
  },
  {
    lng: -43.2566352,
    lat: -22.8336657
  },
  {
    lng: -43.257039,
    lat: -22.8333196
  },
  {
    lng: -43.2577511,
    lat: -22.8326843
  },
  {
    lng: -43.2584377,
    lat: -22.8320922
  },
  {
    lng: -43.2586537,
    lat: -22.8318945
  },
  {
    lng: -43.2587328,
    lat: -22.831808
  },
  {
    lng: -43.2588923,
    lat: -22.8316633
  },
  {
    lng: -43.2601396,
    lat: -22.8306399
  },
  {
    lng: -43.2612769,
    lat: -22.829656
  },
  {
    lng: -43.2623873,
    lat: -22.8286993
  },
  {
    lng: -43.2632724,
    lat: -22.827933
  },
  {
    lng: -43.2633878,
    lat: -22.8278217
  },
  {
    lng: -43.2634629,
    lat: -22.8276981
  },
  {
    lng: -43.2636024,
    lat: -22.8273594
  },
  {
    lng: -43.2637283,
    lat: -22.8271146
  },
  {
    lng: -43.2638947,
    lat: -22.8268699
  },
  {
    lng: -43.2640986,
    lat: -22.8266079
  },
  {
    lng: -43.2642594,
    lat: -22.8264447
  },
  {
    lng: -43.2643614,
    lat: -22.8263902
  },
  {
    lng: -43.2644848,
    lat: -22.826378
  },
  {
    lng: -43.2646001,
    lat: -22.8264052
  },
  {
    lng: -43.264804,
    lat: -22.8264917
  },
  {
    lng: -43.264855,
    lat: -22.8264892
  },
  {
    lng: -43.2648898,
    lat: -22.8264732
  },
  {
    lng: -43.2653807,
    lat: -22.8259032
  },
  {
    lng: -43.2656461,
    lat: -22.8255053
  },
  {
    lng: -43.265968,
    lat: -22.8250702
  },
  {
    lng: -43.2662819,
    lat: -22.824635
  },
  {
    lng: -43.266644,
    lat: -22.8240146
  },
  {
    lng: -43.26688,
    lat: -22.8235857
  },
  {
    lng: -43.2670087,
    lat: -22.823321
  },
  {
    lng: -43.2671173,
    lat: -22.8231048
  },
  {
    lng: -43.2674057,
    lat: -22.8225152
  },
  {
    lng: -43.267419,
    lat: -22.8224644
  },
  {
    lng: -43.2674191,
    lat: -22.8224213
  },
  {
    lng: -43.2674044,
    lat: -22.8223298
  },
  {
    lng: -43.2674128,
    lat: -22.8222942
  },
  {
    lng: -43.2674225,
    lat: -22.8222787
  },
  {
    lng: -43.2674728,
    lat: -22.8222618
  },
  {
    lng: -43.2675211,
    lat: -22.8222321
  },
  {
    lng: -43.267572,
    lat: -22.8221728
  },
  {
    lng: -43.2677759,
    lat: -22.8217327
  },
  {
    lng: -43.2682748,
    lat: -22.8206202
  },
  {
    lng: -43.2688433,
    lat: -22.8193914
  },
  {
    lng: -43.2691277,
    lat: -22.8188155
  },
  {
    lng: -43.2694657,
    lat: -22.8180664
  },
  {
    lng: -43.2698733,
    lat: -22.8172282
  },
  {
    lng: -43.2700343,
    lat: -22.8169044
  },
  {
    lng: -43.2701791,
    lat: -22.8166818
  },
  {
    lng: -43.2703614,
    lat: -22.816405
  },
  {
    lng: -43.2705064,
    lat: -22.816175
  },
  {
    lng: -43.2705278,
    lat: -22.8161009
  },
  {
    lng: -43.2705198,
    lat: -22.8160366
  },
  {
    lng: -43.270466,
    lat: -22.8159253
  },
  {
    lng: -43.270466,
    lat: -22.8158387
  },
  {
    lng: -43.2704983,
    lat: -22.8157745
  },
  {
    lng: -43.2706002,
    lat: -22.8156558
  },
  {
    lng: -43.2706619,
    lat: -22.8155766
  },
  {
    lng: -43.2708416,
    lat: -22.8152108
  },
  {
    lng: -43.271126,
    lat: -22.8146644
  },
  {
    lng: -43.2712757,
    lat: -22.8143636
  },
  {
    lng: -43.2712818,
    lat: -22.8143516
  },
  {
    lng: -43.2712951,
    lat: -22.8143238
  },
  {
    lng: -43.2713027,
    lat: -22.8143092
  },
  {
    lng: -43.2713527,
    lat: -22.8141959
  },
  {
    lng: -43.2713583,
    lat: -22.8141851
  },
  {
    lng: -43.2713706,
    lat: -22.8141546
  },
  {
    lng: -43.2713781,
    lat: -22.8141402
  },
  {
    lng: -43.2714997,
    lat: -22.8138614
  },
  {
    lng: -43.2715178,
    lat: -22.8138206
  },
  {
    lng: -43.2715684,
    lat: -22.8136804
  },
  {
    lng: -43.2715927,
    lat: -22.8135519
  },
  {
    lng: -43.2715791,
    lat: -22.813453
  },
  {
    lng: -43.2714933,
    lat: -22.8131958
  },
  {
    lng: -43.2715095,
    lat: -22.8131217
  },
  {
    lng: -43.271555,
    lat: -22.8130599
  },
  {
    lng: -43.2717535,
    lat: -22.8129016
  },
  {
    lng: -43.2718019,
    lat: -22.8128199
  },
  {
    lng: -43.2718046,
    lat: -22.8127434
  },
  {
    lng: -43.2717723,
    lat: -22.8126593
  },
  {
    lng: -43.2717669,
    lat: -22.8125728
  },
  {
    lng: -43.2717991,
    lat: -22.8124665
  },
  {
    lng: -43.2718661,
    lat: -22.8123898
  },
  {
    lng: -43.2719922,
    lat: -22.8123181
  },
  {
    lng: -43.2720995,
    lat: -22.8122267
  },
  {
    lng: -43.2722015,
    lat: -22.8120807
  },
  {
    lng: -43.2725273,
    lat: -22.8114182
  },
  {
    lng: -43.2725742,
    lat: -22.8113539
  },
  {
    lng: -43.2726333,
    lat: -22.8113044
  },
  {
    lng: -43.2726882,
    lat: -22.8112402
  },
  {
    lng: -43.2728934,
    lat: -22.8109261
  },
  {
    lng: -43.2730168,
    lat: -22.810748
  },
  {
    lng: -43.2728431,
    lat: -22.8103332
  },
  {
    lng: -43.2726749,
    lat: -22.80979
  },
  {
    lng: -43.2726118,
    lat: -22.8096801
  },
  {
    lng: -43.2725488,
    lat: -22.8095836
  },
  {
    lng: -43.2725046,
    lat: -22.8095168
  },
  {
    lng: -43.2724656,
    lat: -22.8094476
  },
  {
    lng: -43.2724388,
    lat: -22.8093784
  },
  {
    lng: -43.2724173,
    lat: -22.8092931
  },
  {
    lng: -43.2724133,
    lat: -22.8092375
  },
  {
    lng: -43.272412,
    lat: -22.8091596
  },
  {
    lng: -43.272416,
    lat: -22.8090595
  },
  {
    lng: -43.2724294,
    lat: -22.8089544
  },
  {
    lng: -43.2724348,
    lat: -22.808879
  },
  {
    lng: -43.2724361,
    lat: -22.8088122
  },
  {
    lng: -43.2724375,
    lat: -22.808764
  },
  {
    lng: -43.2724348,
    lat: -22.8086973
  },
  {
    lng: -43.2724294,
    lat: -22.8086231
  },
  {
    lng: -43.2724227,
    lat: -22.8085501
  },
  {
    lng: -43.2724133,
    lat: -22.8084327
  },
  {
    lng: -43.272408,
    lat: -22.8083214
  },
  {
    lng: -43.2723919,
    lat: -22.8081731
  },
  {
    lng: -43.2723838,
    lat: -22.8080297
  },
  {
    lng: -43.2723771,
    lat: -22.8078714
  },
  {
    lng: -43.2722828,
    lat: -22.8060642
  },
  {
    lng: -43.2722408,
    lat: -22.8049713
  },
  {
    lng: -43.2722827,
    lat: -22.8042591
  },
  {
    lng: -43.272432,
    lat: -22.8035624
  },
  {
    lng: -43.2725046,
    lat: -22.8034146
  },
  {
    lng: -43.272571,
    lat: -22.8033154
  },
  {
    lng: -43.2728431,
    lat: -22.8027882
  },
  {
    lng: -43.274265,
    lat: -22.8031315
  },
  {
    lng: -43.2752585,
    lat: -22.8032647
  },
  {
    lng: -43.2768567,
    lat: -22.8032258
  },
  {
    lng: -43.2768718,
    lat: -22.8037561
  },
  {
    lng: -43.2768838,
    lat: -22.8038684
  },
  {
    lng: -43.2769788,
    lat: -22.8042
  },
  {
    lng: -43.2769884,
    lat: -22.8042336
  },
  {
    lng: -43.2770826,
    lat: -22.8044931
  },
  {
    lng: -43.2772215,
    lat: -22.8048067
  },
  {
    lng: -43.2773565,
    lat: -22.8050602
  },
  {
    lng: -43.2774454,
    lat: -22.8052101
  },
  {
    lng: -43.2776022,
    lat: -22.8054594
  },
  {
    lng: -43.277767,
    lat: -22.8056944
  },
  {
    lng: -43.2779589,
    lat: -22.8059351
  },
  {
    lng: -43.2781983,
    lat: -22.8062005
  },
  {
    lng: -43.278616,
    lat: -22.8066373
  },
  {
    lng: -43.2787513,
    lat: -22.8068471
  },
  {
    lng: -43.278934,
    lat: -22.8070436
  },
  {
    lng: -43.278979,
    lat: -22.8071241
  },
  {
    lng: -43.278979,
    lat: -22.8072171
  },
  {
    lng: -43.2789788,
    lat: -22.8075458
  },
  {
    lng: -43.2790058,
    lat: -22.8076085
  },
  {
    lng: -43.2790489,
    lat: -22.8076573
  },
  {
    lng: -43.2791794,
    lat: -22.807687
  },
  {
    lng: -43.2794639,
    lat: -22.8076964
  },
  {
    lng: -43.2796305,
    lat: -22.8077194
  },
  {
    lng: -43.2798389,
    lat: -22.8077574
  },
  {
    lng: -43.2799991,
    lat: -22.8077758
  },
  {
    lng: -43.2801026,
    lat: -22.8077812
  },
  {
    lng: -43.280208,
    lat: -22.8077781
  },
  {
    lng: -43.2803183,
    lat: -22.8077627
  },
  {
    lng: -43.2804454,
    lat: -22.8077342
  },
  {
    lng: -43.2805394,
    lat: -22.8077458
  },
  {
    lng: -43.280645,
    lat: -22.8077367
  },
  {
    lng: -43.2807557,
    lat: -22.8076933
  },
  {
    lng: -43.2813422,
    lat: -22.8077271
  },
  {
    lng: -43.2820722,
    lat: -22.8077708
  },
  {
    lng: -43.283129,
    lat: -22.807837
  },
  {
    lng: -43.2838252,
    lat: -22.8078677
  },
  {
    lng: -43.2843837,
    lat: -22.8079179
  },
  {
    lng: -43.2850204,
    lat: -22.8079627
  },
  {
    lng: -43.2855338,
    lat: -22.8080019
  },
  {
    lng: -43.2859617,
    lat: -22.8080572
  },
  {
    lng: -43.2863571,
    lat: -22.8080972
  },
  {
    lng: -43.2867601,
    lat: -22.8081863
  },
  {
    lng: -43.2869731,
    lat: -22.8082746
  },
  {
    lng: -43.2872478,
    lat: -22.8084214
  },
  {
    lng: -43.2874608,
    lat: -22.808583
  },
  {
    lng: -43.2876102,
    lat: -22.8087354
  },
  {
    lng: -43.2877146,
    lat: -22.8088794
  },
  {
    lng: -43.2880167,
    lat: -22.8091839
  },
  {
    lng: -43.288086,
    lat: -22.8092437
  },
  {
    lng: -43.2886545,
    lat: -22.8097331
  },
  {
    lng: -43.2890426,
    lat: -22.8100671
  },
  {
    lng: -43.2893994,
    lat: -22.8103539
  },
  {
    lng: -43.2895751,
    lat: -22.8104402
  },
  {
    lng: -43.2897376,
    lat: -22.8104523
  },
  {
    lng: -43.2899484,
    lat: -22.810456
  },
  {
    lng: -43.2901198,
    lat: -22.8104451
  },
  {
    lng: -43.2903992,
    lat: -22.8104032
  },
  {
    lng: -43.2907481,
    lat: -22.810324
  },
  {
    lng: -43.2910164,
    lat: -22.8102168
  },
  {
    lng: -43.2911965,
    lat: -22.81009
  },
  {
    lng: -43.2913672,
    lat: -22.809981
  },
  {
    lng: -43.2914875,
    lat: -22.8098903
  },
  {
    lng: -43.2916384,
    lat: -22.8097627
  },
  {
    lng: -43.2918002,
    lat: -22.8096253
  },
  {
    lng: -43.2920725,
    lat: -22.8094336
  },
  {
    lng: -43.2928317,
    lat: -22.8089194
  },
  {
    lng: -43.293187,
    lat: -22.808698
  },
  {
    lng: -43.2936369,
    lat: -22.8082815
  },
  {
    lng: -43.2938889,
    lat: -22.8080348
  },
  {
    lng: -43.2942206,
    lat: -22.8077252
  },
  {
    lng: -43.2942594,
    lat: -22.8076466
  },
  {
    lng: -43.2944461,
    lat: -22.8074192
  },
  {
    lng: -43.2944948,
    lat: -22.8073489
  },
  {
    lng: -43.2946323,
    lat: -22.8071876
  },
  {
    lng: -43.2950574,
    lat: -22.8067841
  },
  {
    lng: -43.2954413,
    lat: -22.8065189
  },
  {
    lng: -43.2956257,
    lat: -22.8064265
  },
  {
    lng: -43.29579,
    lat: -22.8064283
  },
  {
    lng: -43.2959177,
    lat: -22.8064571
  },
  {
    lng: -43.2960648,
    lat: -22.8064224
  },
  {
    lng: -43.296261,
    lat: -22.8063768
  },
  {
    lng: -43.2963519,
    lat: -22.806444
  },
  {
    lng: -43.2964591,
    lat: -22.8064541
  },
  {
    lng: -43.2965682,
    lat: -22.8065112
  },
  {
    lng: -43.2967333,
    lat: -22.8064803
  },
  {
    lng: -43.2968279,
    lat: -22.8064833
  },
  {
    lng: -43.2969114,
    lat: -22.806486
  },
  {
    lng: -43.2970773,
    lat: -22.8065163
  },
  {
    lng: -43.2972019,
    lat: -22.8065537
  },
  {
    lng: -43.2975431,
    lat: -22.8066705
  },
  {
    lng: -43.2977254,
    lat: -22.8067347
  },
  {
    lng: -43.2978722,
    lat: -22.8067887
  },
  {
    lng: -43.2981487,
    lat: -22.8068477
  },
  {
    lng: -43.298384,
    lat: -22.8068521
  },
  {
    lng: -43.2987519,
    lat: -22.8068017
  },
  {
    lng: -43.298856,
    lat: -22.8067622
  },
  {
    lng: -43.2989808,
    lat: -22.8066981
  },
  {
    lng: -43.2991279,
    lat: -22.8066113
  },
  {
    lng: -43.2992654,
    lat: -22.8065309
  },
  {
    lng: -43.2995336,
    lat: -22.8062346
  },
  {
    lng: -43.299815,
    lat: -22.8056787
  },
  {
    lng: -43.2998813,
    lat: -22.8055479
  },
  {
    lng: -43.2999083,
    lat: -22.8054153
  },
  {
    lng: -43.2999275,
    lat: -22.805246
  },
  {
    lng: -43.2999602,
    lat: -22.8051378
  },
  {
    lng: -43.2999723,
    lat: -22.8050607
  },
  {
    lng: -43.2999649,
    lat: -22.8049776
  },
  {
    lng: -43.2999544,
    lat: -22.8048918
  },
  {
    lng: -43.2999674,
    lat: -22.8047917
  },
  {
    lng: -43.3000183,
    lat: -22.8046453
  },
  {
    lng: -43.3001034,
    lat: -22.8044673
  },
  {
    lng: -43.3001765,
    lat: -22.8043123
  },
  {
    lng: -43.300253,
    lat: -22.8041535
  },
  {
    lng: -43.3003237,
    lat: -22.8040577
  },
  {
    lng: -43.3004548,
    lat: -22.803923
  },
  {
    lng: -43.3006031,
    lat: -22.8038099
  },
  {
    lng: -43.3008092,
    lat: -22.8037045
  },
  {
    lng: -43.3009252,
    lat: -22.8036457
  },
  {
    lng: -43.3009693,
    lat: -22.8036058
  },
  {
    lng: -43.3010129,
    lat: -22.8035919
  },
  {
    lng: -43.3010714,
    lat: -22.8035817
  },
  {
    lng: -43.3011404,
    lat: -22.8035744
  },
  {
    lng: -43.3011938,
    lat: -22.8035699
  },
  {
    lng: -43.3012494,
    lat: -22.8035577
  },
  {
    lng: -43.3013009,
    lat: -22.8035397
  },
  {
    lng: -43.3013729,
    lat: -22.8035244
  },
  {
    lng: -43.3015173,
    lat: -22.8034887
  },
  {
    lng: -43.301691,
    lat: -22.8034905
  },
  {
    lng: -43.3018496,
    lat: -22.8035136
  },
  {
    lng: -43.3020328,
    lat: -22.8035769
  },
  {
    lng: -43.3021468,
    lat: -22.8036258
  },
  {
    lng: -43.3022823,
    lat: -22.8036878
  },
  {
    lng: -43.3024621,
    lat: -22.8037472
  },
  {
    lng: -43.3026419,
    lat: -22.8037753
  },
  {
    lng: -43.3027535,
    lat: -22.8037739
  },
  {
    lng: -43.302849,
    lat: -22.8037548
  },
  {
    lng: -43.3029261,
    lat: -22.8036923
  },
  {
    lng: -43.3030244,
    lat: -22.8035736
  },
  {
    lng: -43.3030994,
    lat: -22.8034386
  },
  {
    lng: -43.3031457,
    lat: -22.8033271
  },
  {
    lng: -43.3031759,
    lat: -22.8031679
  },
  {
    lng: -43.3031854,
    lat: -22.8030839
  },
  {
    lng: -43.3031925,
    lat: -22.8029827
  },
  {
    lng: -43.3032373,
    lat: -22.8028341
  },
  {
    lng: -43.3032551,
    lat: -22.802774
  },
  {
    lng: -43.3032913,
    lat: -22.8025994
  },
  {
    lng: -43.3033158,
    lat: -22.8024545
  },
  {
    lng: -43.3033373,
    lat: -22.802328
  },
  {
    lng: -43.3033529,
    lat: -22.8022375
  },
  {
    lng: -43.3033739,
    lat: -22.8021711
  },
  {
    lng: -43.3033919,
    lat: -22.8021119
  },
  {
    lng: -43.3034452,
    lat: -22.8020187
  },
  {
    lng: -43.303553,
    lat: -22.8018463
  },
  {
    lng: -43.3036777,
    lat: -22.8016733
  },
  {
    lng: -43.3037649,
    lat: -22.8015342
  },
  {
    lng: -43.3039438,
    lat: -22.8012908
  },
  {
    lng: -43.3040432,
    lat: -22.8011876
  },
  {
    lng: -43.3042489,
    lat: -22.8010275
  },
  {
    lng: -43.3043998,
    lat: -22.8009457
  },
  {
    lng: -43.3044802,
    lat: -22.800902
  },
  {
    lng: -43.3046822,
    lat: -22.8008297
  },
  {
    lng: -43.3048979,
    lat: -22.8007735
  },
  {
    lng: -43.3051642,
    lat: -22.8007366
  },
  {
    lng: -43.3053977,
    lat: -22.8007075
  },
  {
    lng: -43.305544,
    lat: -22.8006752
  },
  {
    lng: -43.3056513,
    lat: -22.8006217
  },
  {
    lng: -43.3057288,
    lat: -22.8005801
  },
  {
    lng: -43.3061345,
    lat: -22.800385
  },
  {
    lng: -43.3065736,
    lat: -22.8001596
  },
  {
    lng: -43.3068702,
    lat: -22.8000379
  },
  {
    lng: -43.3071286,
    lat: -22.7999824
  },
  {
    lng: -43.3075759,
    lat: -22.7998945
  },
  {
    lng: -43.3078424,
    lat: -22.7998496
  },
  {
    lng: -43.3081625,
    lat: -22.7998344
  },
  {
    lng: -43.3084368,
    lat: -22.7998215
  },
  {
    lng: -43.3090203,
    lat: -22.7998435
  },
  {
    lng: -43.3093222,
    lat: -22.7998758
  },
  {
    lng: -43.3096317,
    lat: -22.7998976
  },
  {
    lng: -43.3099417,
    lat: -22.7998957
  },
  {
    lng: -43.3101178,
    lat: -22.7998922
  },
  {
    lng: -43.3102941,
    lat: -22.7998756
  },
  {
    lng: -43.3105528,
    lat: -22.7998519
  },
  {
    lng: -43.3107107,
    lat: -22.7998483
  },
  {
    lng: -43.3109199,
    lat: -22.7998637
  },
  {
    lng: -43.3111341,
    lat: -22.7999005
  },
  {
    lng: -43.31141,
    lat: -22.7999616
  },
  {
    lng: -43.3115631,
    lat: -22.7999974
  },
  {
    lng: -43.3117135,
    lat: -22.8000203
  },
  {
    lng: -43.3119934,
    lat: -22.8000706
  },
  {
    lng: -43.3124764,
    lat: -22.8001339
  },
  {
    lng: -43.312918,
    lat: -22.8001792
  },
  {
    lng: -43.3134401,
    lat: -22.8002766
  },
  {
    lng: -43.3143972,
    lat: -22.8005391
  },
  {
    lng: -43.3148808,
    lat: -22.8006464
  },
  {
    lng: -43.3155119,
    lat: -22.8007486
  },
  {
    lng: -43.3162976,
    lat: -22.8008865
  },
  {
    lng: -43.3170386,
    lat: -22.8010227
  },
  {
    lng: -43.3177433,
    lat: -22.8011908
  },
  {
    lng: -43.3185924,
    lat: -22.8013184
  },
  {
    lng: -43.3189001,
    lat: -22.8013977
  },
  {
    lng: -43.3194571,
    lat: -22.8015369
  },
  {
    lng: -43.3203948,
    lat: -22.8015927
  },
  {
    lng: -43.3208842,
    lat: -22.8016045
  },
  {
    lng: -43.320925,
    lat: -22.8016055
  },
  {
    lng: -43.3220515,
    lat: -22.8015175
  },
  {
    lng: -43.3228218,
    lat: -22.801414
  },
  {
    lng: -43.3233647,
    lat: -22.8013324
  },
  {
    lng: -43.3240937,
    lat: -22.8012526
  },
  {
    lng: -43.3249307,
    lat: -22.8011853
  },
  {
    lng: -43.3249493,
    lat: -22.8011838
  },
  {
    lng: -43.3258018,
    lat: -22.8012658
  },
  {
    lng: -43.3265705,
    lat: -22.8015344
  },
  {
    lng: -43.3272739,
    lat: -22.8019064
  },
  {
    lng: -43.3277082,
    lat: -22.8022097
  },
  {
    lng: -43.3281773,
    lat: -22.8024556
  },
  {
    lng: -43.3285668,
    lat: -22.8026006
  },
  {
    lng: -43.3295007,
    lat: -22.8026582
  },
  {
    lng: -43.3307962,
    lat: -22.80272
  },
  {
    lng: -43.3310882,
    lat: -22.8027119
  },
  {
    lng: -43.3315487,
    lat: -22.8027204
  },
  {
    lng: -43.3331889,
    lat: -22.8028237
  },
  {
    lng: -43.3339743,
    lat: -22.8028786
  },
  {
    lng: -43.334335,
    lat: -22.8029385
  },
  {
    lng: -43.3349253,
    lat: -22.8029557
  },
  {
    lng: -43.3353053,
    lat: -22.8029885
  },
  {
    lng: -43.3356691,
    lat: -22.8030198
  },
  {
    lng: -43.3365229,
    lat: -22.8030484
  },
  {
    lng: -43.3366181,
    lat: -22.803048
  },
  {
    lng: -43.3374313,
    lat: -22.8030438
  },
  {
    lng: -43.3381315,
    lat: -22.803082
  },
  {
    lng: -43.3388307,
    lat: -22.8030949
  },
  {
    lng: -43.3395577,
    lat: -22.8031531
  },
  {
    lng: -43.3414375,
    lat: -22.8033059
  },
  {
    lng: -43.3468243,
    lat: -22.8036472
  },
  {
    lng: -43.347805,
    lat: -22.8037093
  },
  {
    lng: -43.3483367,
    lat: -22.803741
  },
  {
    lng: -43.3485945,
    lat: -22.8035314
  },
  {
    lng: -43.3487835,
    lat: -22.8034354
  },
  {
    lng: -43.349005,
    lat: -22.8033234
  },
  {
    lng: -43.349232,
    lat: -22.8032387
  },
  {
    lng: -43.3494679,
    lat: -22.8031943
  },
  {
    lng: -43.3500086,
    lat: -22.8031157
  },
  {
    lng: -43.350297,
    lat: -22.8030771
  },
  {
    lng: -43.3509318,
    lat: -22.8029882
  },
  {
    lng: -43.3515043,
    lat: -22.8029081
  },
  {
    lng: -43.3519299,
    lat: -22.8028414
  },
  {
    lng: -43.3523146,
    lat: -22.8027763
  },
  {
    lng: -43.3526805,
    lat: -22.8026896
  },
  {
    lng: -43.3529646,
    lat: -22.8026194
  },
  {
    lng: -43.3531532,
    lat: -22.8025683
  },
  {
    lng: -43.3532025,
    lat: -22.8025593
  },
  {
    lng: -43.3534857,
    lat: -22.8024862
  },
  {
    lng: -43.3536819,
    lat: -22.8024411
  },
  {
    lng: -43.3539124,
    lat: -22.8023972
  },
  {
    lng: -43.3540328,
    lat: -22.8023976
  },
  {
    lng: -43.3541034,
    lat: -22.8023964
  },
  {
    lng: -43.3542174,
    lat: -22.802413
  },
  {
    lng: -43.3543484,
    lat: -22.8024297
  },
  {
    lng: -43.3546997,
    lat: -22.8024814
  },
  {
    lng: -43.3548462,
    lat: -22.8025022
  },
  {
    lng: -43.3550244,
    lat: -22.80253
  },
  {
    lng: -43.3553678,
    lat: -22.8025875
  },
  {
    lng: -43.3555138,
    lat: -22.802612
  },
  {
    lng: -43.3556905,
    lat: -22.8026351
  },
  {
    lng: -43.3558584,
    lat: -22.802657
  },
  {
    lng: -43.356018,
    lat: -22.8026788
  },
  {
    lng: -43.3561781,
    lat: -22.8026959
  },
  {
    lng: -43.3563161,
    lat: -22.8027069
  },
  {
    lng: -43.3564619,
    lat: -22.8027123
  },
  {
    lng: -43.3565813,
    lat: -22.8027019
  },
  {
    lng: -43.3567153,
    lat: -22.802688
  },
  {
    lng: -43.3569892,
    lat: -22.8026551
  },
  {
    lng: -43.3571982,
    lat: -22.8026275
  },
  {
    lng: -43.3573962,
    lat: -22.8026055
  },
  {
    lng: -43.3574325,
    lat: -22.8026039
  },
  {
    lng: -43.3574595,
    lat: -22.8026052
  },
  {
    lng: -43.3575735,
    lat: -22.8026189
  },
  {
    lng: -43.3578008,
    lat: -22.8026587
  },
  {
    lng: -43.3580566,
    lat: -22.8027066
  },
  {
    lng: -43.358317,
    lat: -22.8027593
  },
  {
    lng: -43.3586107,
    lat: -22.8028142
  },
  {
    lng: -43.3591299,
    lat: -22.8029178
  },
  {
    lng: -43.3593452,
    lat: -22.8029633
  },
  {
    lng: -43.3594585,
    lat: -22.8029914
  },
  {
    lng: -43.3595061,
    lat: -22.8030054
  },
  {
    lng: -43.3595496,
    lat: -22.8030202
  },
  {
    lng: -43.3595898,
    lat: -22.803037
  },
  {
    lng: -43.3596218,
    lat: -22.8030537
  },
  {
    lng: -43.3596548,
    lat: -22.8030714
  },
  {
    lng: -43.3597161,
    lat: -22.8031086
  },
  {
    lng: -43.3598139,
    lat: -22.803176
  },
  {
    lng: -43.3599076,
    lat: -22.8032404
  },
  {
    lng: -43.3600322,
    lat: -22.8033216
  },
  {
    lng: -43.3605023,
    lat: -22.8036738
  },
  {
    lng: -43.3606062,
    lat: -22.8037509
  },
  {
    lng: -43.3611378,
    lat: -22.8041278
  },
  {
    lng: -43.3612069,
    lat: -22.8041718
  },
  {
    lng: -43.3614958,
    lat: -22.8044346
  },
  {
    lng: -43.3615414,
    lat: -22.8044488
  },
  {
    lng: -43.3621123,
    lat: -22.8044033
  },
  {
    lng: -43.3641722,
    lat: -22.8041343
  },
  {
    lng: -43.36467,
    lat: -22.8040868
  },
  {
    lng: -43.3652108,
    lat: -22.803976
  },
  {
    lng: -43.3656227,
    lat: -22.8040551
  },
  {
    lng: -43.3665,
    lat: -22.8044596
  },
  {
    lng: -43.3668069,
    lat: -22.8046413
  },
  {
    lng: -43.3677071,
    lat: -22.8049454
  },
  {
    lng: -43.3681051,
    lat: -22.8050395
  },
  {
    lng: -43.3698122,
    lat: -22.8051825
  },
  {
    lng: -43.370081,
    lat: -22.8052686
  },
  {
    lng: -43.3702919,
    lat: -22.8053923
  },
  {
    lng: -43.3722832,
    lat: -22.8071488
  },
  {
    lng: -43.3724205,
    lat: -22.80722
  },
  {
    lng: -43.3738453,
    lat: -22.8074494
  },
  {
    lng: -43.3741457,
    lat: -22.8074573
  },
  {
    lng: -43.374429,
    lat: -22.8075998
  },
  {
    lng: -43.3752959,
    lat: -22.8082169
  },
  {
    lng: -43.3758709,
    lat: -22.8086125
  },
  {
    lng: -43.3763258,
    lat: -22.80919
  },
  {
    lng: -43.3763399,
    lat: -22.8093737
  },
  {
    lng: -43.3763747,
    lat: -22.8096284
  },
  {
    lng: -43.3764388,
    lat: -22.8100985
  },
  {
    lng: -43.3764937,
    lat: -22.8104938
  },
  {
    lng: -43.3767133,
    lat: -22.8107464
  },
  {
    lng: -43.3770427,
    lat: -22.8108892
  },
  {
    lng: -43.377493,
    lat: -22.8108672
  },
  {
    lng: -43.3783934,
    lat: -22.8107903
  },
  {
    lng: -43.3788656,
    lat: -22.8107244
  },
  {
    lng: -43.3794806,
    lat: -22.8106256
  },
  {
    lng: -43.3796782,
    lat: -22.8106366
  },
  {
    lng: -43.3799967,
    lat: -22.810966
  },
  {
    lng: -43.3802932,
    lat: -22.8110758
  },
  {
    lng: -43.3807654,
    lat: -22.8111417
  },
  {
    lng: -43.3811168,
    lat: -22.8111857
  },
  {
    lng: -43.3815121,
    lat: -22.8114382
  },
  {
    lng: -43.3820268,
    lat: -22.8117244
  },
  {
    lng: -43.3823701,
    lat: -22.8117719
  },
  {
    lng: -43.3826963,
    lat: -22.8118352
  },
  {
    lng: -43.3829538,
    lat: -22.8119222
  },
  {
    lng: -43.3832027,
    lat: -22.8120646
  },
  {
    lng: -43.3834687,
    lat: -22.8122703
  },
  {
    lng: -43.3838292,
    lat: -22.8125393
  },
  {
    lng: -43.3840266,
    lat: -22.8128004
  },
  {
    lng: -43.384224,
    lat: -22.8131723
  },
  {
    lng: -43.384327,
    lat: -22.8134413
  },
  {
    lng: -43.3844129,
    lat: -22.8136311
  },
  {
    lng: -43.3845674,
    lat: -22.8138368
  },
  {
    lng: -43.3847905,
    lat: -22.8139872
  },
  {
    lng: -43.3850824,
    lat: -22.81409
  },
  {
    lng: -43.385666,
    lat: -22.814185
  },
  {
    lng: -43.3862497,
    lat: -22.8142403
  },
  {
    lng: -43.38649,
    lat: -22.8143748
  },
  {
    lng: -43.3866874,
    lat: -22.8144065
  },
  {
    lng: -43.3873225,
    lat: -22.8147071
  },
  {
    lng: -43.3876058,
    lat: -22.8148021
  },
  {
    lng: -43.387786,
    lat: -22.8148021
  },
  {
    lng: -43.3880349,
    lat: -22.8146834
  },
  {
    lng: -43.3883096,
    lat: -22.8145093
  },
  {
    lng: -43.3888414,
    lat: -22.81417
  },
  {
    lng: -43.3891306,
    lat: -22.8139877
  },
  {
    lng: -43.3893354,
    lat: -22.8138676
  },
  {
    lng: -43.3895713,
    lat: -22.8137736
  },
  {
    lng: -43.3899671,
    lat: -22.8136655
  },
  {
    lng: -43.390033,
    lat: -22.813655
  },
  {
    lng: -43.3900976,
    lat: -22.8136561
  },
  {
    lng: -43.3901673,
    lat: -22.8136677
  },
  {
    lng: -43.3902216,
    lat: -22.8136848
  },
  {
    lng: -43.3902574,
    lat: -22.8136971
  },
  {
    lng: -43.3903072,
    lat: -22.8137188
  },
  {
    lng: -43.39043,
    lat: -22.8137815
  },
  {
    lng: -43.3904797,
    lat: -22.8138081
  },
  {
    lng: -43.3905943,
    lat: -22.8138646
  },
  {
    lng: -43.3908818,
    lat: -22.8140235
  },
  {
    lng: -43.3912474,
    lat: -22.8141939
  },
  {
    lng: -43.3915282,
    lat: -22.814272
  },
  {
    lng: -43.3917771,
    lat: -22.8142957
  },
  {
    lng: -43.3919746,
    lat: -22.8143907
  },
  {
    lng: -43.3927642,
    lat: -22.8147071
  },
  {
    lng: -43.3929788,
    lat: -22.8147704
  },
  {
    lng: -43.3932363,
    lat: -22.8148179
  },
  {
    lng: -43.3936397,
    lat: -22.8148812
  },
  {
    lng: -43.394498,
    lat: -22.8151027
  },
  {
    lng: -43.3956653,
    lat: -22.8154666
  },
  {
    lng: -43.3962747,
    lat: -22.8157119
  },
  {
    lng: -43.3969012,
    lat: -22.8159413
  },
  {
    lng: -43.397645,
    lat: -22.8162904
  },
  {
    lng: -43.3985602,
    lat: -22.8166276
  },
  {
    lng: -43.398944,
    lat: -22.8167404
  },
  {
    lng: -43.3993732,
    lat: -22.8168986
  },
  {
    lng: -43.3998967,
    lat: -22.8170806
  },
  {
    lng: -43.4003602,
    lat: -22.8172625
  },
  {
    lng: -43.4009267,
    lat: -22.8175236
  },
  {
    lng: -43.4014245,
    lat: -22.8177609
  },
  {
    lng: -43.4022399,
    lat: -22.8181882
  },
  {
    lng: -43.4026519,
    lat: -22.818378
  },
  {
    lng: -43.4029866,
    lat: -22.8185204
  },
  {
    lng: -43.4032785,
    lat: -22.8186549
  },
  {
    lng: -43.4035703,
    lat: -22.8188527
  },
  {
    lng: -43.4037934,
    lat: -22.8190426
  },
  {
    lng: -43.4040252,
    lat: -22.8192008
  },
  {
    lng: -43.4042741,
    lat: -22.8193116
  },
  {
    lng: -43.4044458,
    lat: -22.8194619
  },
  {
    lng: -43.4047204,
    lat: -22.8196122
  },
  {
    lng: -43.405038,
    lat: -22.8197071
  },
  {
    lng: -43.4054328,
    lat: -22.819984
  },
  {
    lng: -43.4057761,
    lat: -22.8202135
  },
  {
    lng: -43.4063169,
    lat: -22.8204429
  },
  {
    lng: -43.4066858,
    lat: -22.8206145
  },
  {
    lng: -43.4068631,
    lat: -22.820681
  },
  {
    lng: -43.4072037,
    lat: -22.820832
  },
  {
    lng: -43.4074327,
    lat: -22.821052
  },
  {
    lng: -43.4076301,
    lat: -22.8211707
  },
  {
    lng: -43.4078275,
    lat: -22.8212103
  },
  {
    lng: -43.4080592,
    lat: -22.8211628
  },
  {
    lng: -43.4084712,
    lat: -22.8209334
  },
  {
    lng: -43.4092351,
    lat: -22.8205616
  },
  {
    lng: -43.4094669,
    lat: -22.8204191
  },
  {
    lng: -43.4098531,
    lat: -22.8202926
  },
  {
    lng: -43.4105827,
    lat: -22.8202293
  },
  {
    lng: -43.4108316,
    lat: -22.8202055
  },
  {
    lng: -43.4110461,
    lat: -22.8202609
  },
  {
    lng: -43.4116212,
    lat: -22.8205141
  },
  {
    lng: -43.4118959,
    lat: -22.820609
  },
  {
    lng: -43.412119,
    lat: -22.8206723
  },
  {
    lng: -43.4123422,
    lat: -22.820704
  },
  {
    lng: -43.4127284,
    lat: -22.8207752
  },
  {
    lng: -43.4129602,
    lat: -22.8208464
  },
  {
    lng: -43.413149,
    lat: -22.8209571
  },
  {
    lng: -43.4134236,
    lat: -22.8210046
  },
  {
    lng: -43.4137326,
    lat: -22.8209888
  },
  {
    lng: -43.4139329,
    lat: -22.8209937
  },
  {
    lng: -43.4140674,
    lat: -22.8210046
  },
  {
    lng: -43.4142991,
    lat: -22.8210362
  },
  {
    lng: -43.4145394,
    lat: -22.8211312
  },
  {
    lng: -43.4147197,
    lat: -22.8212103
  },
  {
    lng: -43.4150716,
    lat: -22.8214001
  },
  {
    lng: -43.415269,
    lat: -22.8215267
  },
  {
    lng: -43.4154149,
    lat: -22.8217245
  },
  {
    lng: -43.4155608,
    lat: -22.8219856
  },
  {
    lng: -43.4156982,
    lat: -22.8222783
  },
  {
    lng: -43.4158698,
    lat: -22.8226897
  },
  {
    lng: -43.4159986,
    lat: -22.8228716
  },
  {
    lng: -43.4161531,
    lat: -22.8230773
  },
  {
    lng: -43.4164191,
    lat: -22.8233779
  },
  {
    lng: -43.4167539,
    lat: -22.8236785
  },
  {
    lng: -43.4169685,
    lat: -22.8239475
  },
  {
    lng: -43.41708,
    lat: -22.8241611
  },
  {
    lng: -43.4171315,
    lat: -22.8243826
  },
  {
    lng: -43.4171315,
    lat: -22.824612
  },
  {
    lng: -43.4170972,
    lat: -22.8248968
  },
  {
    lng: -43.417389,
    lat: -22.8255772
  },
  {
    lng: -43.4174663,
    lat: -22.8257987
  },
  {
    lng: -43.4177936,
    lat: -22.8261555
  },
  {
    lng: -43.4181787,
    lat: -22.8266135
  },
  {
    lng: -43.4187623,
    lat: -22.827365
  },
  {
    lng: -43.4190713,
    lat: -22.8278001
  },
  {
    lng: -43.4196292,
    lat: -22.8283301
  },
  {
    lng: -43.4197688,
    lat: -22.8285119
  },
  {
    lng: -43.4201699,
    lat: -22.8290342
  },
  {
    lng: -43.4204789,
    lat: -22.829406
  },
  {
    lng: -43.4206163,
    lat: -22.8296117
  },
  {
    lng: -43.4207364,
    lat: -22.8300389
  },
  {
    lng: -43.4208308,
    lat: -22.8300942
  },
  {
    lng: -43.4208051,
    lat: -22.8301971
  },
  {
    lng: -43.4209252,
    lat: -22.8308299
  },
  {
    lng: -43.421088,
    lat: -22.8310959
  },
  {
    lng: -43.4212382,
    lat: -22.8313926
  },
  {
    lng: -43.4213026,
    lat: -22.8315409
  },
  {
    lng: -43.421367,
    lat: -22.8316299
  },
  {
    lng: -43.4215816,
    lat: -22.8320254
  },
  {
    lng: -43.4216888,
    lat: -22.8322133
  },
  {
    lng: -43.4216781,
    lat: -22.8323418
  },
  {
    lng: -43.4217103,
    lat: -22.8324704
  },
  {
    lng: -43.4218605,
    lat: -22.8325594
  },
  {
    lng: -43.4220536,
    lat: -22.832589
  },
  {
    lng: -43.4224291,
    lat: -22.832589
  },
  {
    lng: -43.4225793,
    lat: -22.8325099
  },
  {
    lng: -43.4229441,
    lat: -22.8324506
  },
  {
    lng: -43.4234484,
    lat: -22.8323319
  },
  {
    lng: -43.4237273,
    lat: -22.8322924
  },
  {
    lng: -43.4239204,
    lat: -22.8323616
  },
  {
    lng: -43.4240706,
    lat: -22.8324901
  },
  {
    lng: -43.4241887,
    lat: -22.8326484
  },
  {
    lng: -43.4242638,
    lat: -22.8327176
  },
  {
    lng: -43.424253,
    lat: -22.8328362
  },
  {
    lng: -43.4252735,
    lat: -22.8339106
  },
  {
    lng: -43.4252853,
    lat: -22.8339277
  },
  {
    lng: -43.4253283,
    lat: -22.833986
  },
  {
    lng: -43.4253213,
    lat: -22.8340423
  },
  {
    lng: -43.4252465,
    lat: -22.834159
  },
  {
    lng: -43.4252402,
    lat: -22.8342516
  },
  {
    lng: -43.4252881,
    lat: -22.8343075
  },
  {
    lng: -43.4253289,
    lat: -22.8343533
  },
  {
    lng: -43.4253464,
    lat: -22.8344903
  },
  {
    lng: -43.425314,
    lat: -22.8345374
  },
  {
    lng: -43.4252782,
    lat: -22.8345652
  },
  {
    lng: -43.4252582,
    lat: -22.834609
  },
  {
    lng: -43.4252709,
    lat: -22.8346555
  },
  {
    lng: -43.4253253,
    lat: -22.8346867
  },
  {
    lng: -43.4253862,
    lat: -22.8346931
  },
  {
    lng: -43.4254154,
    lat: -22.8346845
  },
  {
    lng: -43.425467,
    lat: -22.8346523
  },
  {
    lng: -43.4255306,
    lat: -22.8346418
  },
  {
    lng: -43.4255961,
    lat: -22.8346664
  },
  {
    lng: -43.4256298,
    lat: -22.8346974
  },
  {
    lng: -43.4256649,
    lat: -22.834795
  },
  {
    lng: -43.4256494,
    lat: -22.8348514
  },
  {
    lng: -43.42561,
    lat: -22.834878
  },
  {
    lng: -43.4255673,
    lat: -22.8348831
  },
  {
    lng: -43.4255417,
    lat: -22.8348782
  },
  {
    lng: -43.4254826,
    lat: -22.834839
  },
  {
    lng: -43.4254365,
    lat: -22.8348215
  },
  {
    lng: -43.4254242,
    lat: -22.8348326
  },
  {
    lng: -43.4254249,
    lat: -22.83487
  },
  {
    lng: -43.4254854,
    lat: -22.8349781
  },
  {
    lng: -43.4254908,
    lat: -22.8351138
  },
  {
    lng: -43.4255547,
    lat: -22.8352367
  },
  {
    lng: -43.4256322,
    lat: -22.8353541
  },
  {
    lng: -43.4256817,
    lat: -22.8353943
  },
  {
    lng: -43.4257285,
    lat: -22.8354367
  },
  {
    lng: -43.4257559,
    lat: -22.8354766
  },
  {
    lng: -43.4257566,
    lat: -22.8355218
  },
  {
    lng: -43.4257292,
    lat: -22.8355509
  },
  {
    lng: -43.4256386,
    lat: -22.8355882
  },
  {
    lng: -43.4255376,
    lat: -22.8356614
  },
  {
    lng: -43.4256039,
    lat: -22.8357991
  },
  {
    lng: -43.4257005,
    lat: -22.8359325
  },
  {
    lng: -43.4257618,
    lat: -22.8359051
  },
  {
    lng: -43.425801,
    lat: -22.8358942
  },
  {
    lng: -43.4258423,
    lat: -22.8359027
  },
  {
    lng: -43.4258705,
    lat: -22.8358974
  },
  {
    lng: -43.4258806,
    lat: -22.8358704
  },
  {
    lng: -43.4259075,
    lat: -22.8357769
  },
  {
    lng: -43.4259634,
    lat: -22.8357844
  },
  {
    lng: -43.4259813,
    lat: -22.8358129
  },
  {
    lng: -43.4259756,
    lat: -22.835866
  },
  {
    lng: -43.4259846,
    lat: -22.8359204
  },
  {
    lng: -43.4260077,
    lat: -22.8359274
  },
  {
    lng: -43.4260592,
    lat: -22.8359032
  },
  {
    lng: -43.4261845,
    lat: -22.8358313
  },
  {
    lng: -43.4262406,
    lat: -22.8358275
  },
  {
    lng: -43.4262891,
    lat: -22.835844
  },
  {
    lng: -43.4263047,
    lat: -22.8358668
  },
  {
    lng: -43.4262654,
    lat: -22.8359635
  },
  {
    lng: -43.4262439,
    lat: -22.8360265
  },
  {
    lng: -43.4262247,
    lat: -22.8360862
  },
  {
    lng: -43.4262325,
    lat: -22.8361315
  },
  {
    lng: -43.4262773,
    lat: -22.8361546
  },
  {
    lng: -43.4263333,
    lat: -22.8361565
  },
  {
    lng: -43.4263641,
    lat: -22.8361365
  },
  {
    lng: -43.4263827,
    lat: -22.8361119
  },
  {
    lng: -43.4263823,
    lat: -22.8360644
  },
  {
    lng: -43.4263734,
    lat: -22.8359942
  },
  {
    lng: -43.4264259,
    lat: -22.8359881
  },
  {
    lng: -43.4265729,
    lat: -22.8360194
  },
  {
    lng: -43.426631,
    lat: -22.8360461
  },
  {
    lng: -43.426643,
    lat: -22.8360688
  },
  {
    lng: -43.426624,
    lat: -22.8361048
  },
  {
    lng: -43.4266026,
    lat: -22.8361542
  },
  {
    lng: -43.4266095,
    lat: -22.8361837
  },
  {
    lng: -43.4267314,
    lat: -22.8361819
  },
  {
    lng: -43.4268817,
    lat: -22.8361555
  },
  {
    lng: -43.4270313,
    lat: -22.8361744
  },
  {
    lng: -43.4270965,
    lat: -22.8362193
  },
  {
    lng: -43.4271395,
    lat: -22.836273
  },
  {
    lng: -43.4271268,
    lat: -22.8363067
  },
  {
    lng: -43.4270899,
    lat: -22.8363311
  },
  {
    lng: -43.4270113,
    lat: -22.8363685
  },
  {
    lng: -43.4269793,
    lat: -22.8363941
  },
  {
    lng: -43.4269791,
    lat: -22.8364111
  },
  {
    lng: -43.4270322,
    lat: -22.8364389
  },
  {
    lng: -43.4271715,
    lat: -22.8364972
  },
  {
    lng: -43.4273147,
    lat: -22.8365454
  },
  {
    lng: -43.4274591,
    lat: -22.8365822
  },
  {
    lng: -43.4274922,
    lat: -22.8365736
  },
  {
    lng: -43.4275547,
    lat: -22.8365438
  },
  {
    lng: -43.4276098,
    lat: -22.8365299
  },
  {
    lng: -43.4276536,
    lat: -22.8365361
  },
  {
    lng: -43.4276643,
    lat: -22.8365543
  },
  {
    lng: -43.4276492,
    lat: -22.8365835
  },
  {
    lng: -43.4276245,
    lat: -22.8366047
  },
  {
    lng: -43.4276081,
    lat: -22.8366406
  },
  {
    lng: -43.4276308,
    lat: -22.8366726
  },
  {
    lng: -43.4276858,
    lat: -22.8366608
  },
  {
    lng: -43.4277447,
    lat: -22.8366299
  },
  {
    lng: -43.4277872,
    lat: -22.8366429
  },
  {
    lng: -43.4277741,
    lat: -22.8367071
  },
  {
    lng: -43.4277501,
    lat: -22.8367645
  },
  {
    lng: -43.4277343,
    lat: -22.8368254
  },
  {
    lng: -43.4277795,
    lat: -22.8368383
  },
  {
    lng: -43.4278125,
    lat: -22.8368354
  },
  {
    lng: -43.4278495,
    lat: -22.8367974
  },
  {
    lng: -43.4278902,
    lat: -22.836764
  },
  {
    lng: -43.4280379,
    lat: -22.8367501
  },
  {
    lng: -43.4281719,
    lat: -22.8368321
  },
  {
    lng: -43.4282654,
    lat: -22.8369395
  },
  {
    lng: -43.4284024,
    lat: -22.8369887
  },
  {
    lng: -43.4284557,
    lat: -22.8370052
  },
  {
    lng: -43.4285053,
    lat: -22.8370318
  },
  {
    lng: -43.4285145,
    lat: -22.8370692
  },
  {
    lng: -43.428464,
    lat: -22.8371082
  },
  {
    lng: -43.4284322,
    lat: -22.837119
  },
  {
    lng: -43.4283954,
    lat: -22.8371367
  },
  {
    lng: -43.4283361,
    lat: -22.8371856
  },
  {
    lng: -43.4283088,
    lat: -22.8372238
  },
  {
    lng: -43.4283058,
    lat: -22.8372542
  },
  {
    lng: -43.428326,
    lat: -22.8372963
  },
  {
    lng: -43.428361,
    lat: -22.8373216
  },
  {
    lng: -43.4284108,
    lat: -22.8373256
  },
  {
    lng: -43.4285469,
    lat: -22.8372697
  },
  {
    lng: -43.4286955,
    lat: -22.8372783
  },
  {
    lng: -43.42886,
    lat: -22.8372781
  },
  {
    lng: -43.4288808,
    lat: -22.8372912
  },
  {
    lng: -43.428988,
    lat: -22.8373578
  },
  {
    lng: -43.4290814,
    lat: -22.8374653
  },
  {
    lng: -43.4291598,
    lat: -22.8375183
  },
  {
    lng: -43.4292339,
    lat: -22.8375197
  },
  {
    lng: -43.4292599,
    lat: -22.8375116
  },
  {
    lng: -43.4294007,
    lat: -22.8374704
  }
];

export default polygonCoordsRio3;