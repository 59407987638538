import React, { Component } from 'react';
import api from '../services/api';

import { Redirect } from 'react-router-dom';
import { Box, Typography, IconButton, List, ListItem, ListItemAvatar, Avatar, ListItemText, Divider } from '@mui/material';
import { Refresh } from '@mui/icons-material';
import moment from 'moment';

export default class Log extends Component {

  state = {
    redirect: false
  }

  async componentDidMount() {
    this.getLogs();
  }

  async getLogs() {
    /*const response = await api.get('/logs');
    console.log(response.data);*/
  }

  render() {
    const { redirect } = this.state;
    if (redirect) {
      return <Redirect to='/' />
    }
    return (
      <div style={{ marginLeft: 30 }}>
        
        <Box>
          <Box sx={styles.directionRow}>
            <Typography color="textSecondary" variant="body1">
              Logs de Usuários
            </Typography>
            <IconButton
              color={'primary'}
              size="small"
              aria-label="refresh"
              onClick={() => { this.getLogs(); }}
              sx={{ marginTop: -0.5 }}
            >
              <Refresh fontSize="small" />
            </IconButton>
          </Box>

          <List sx={{ width: '100%', maxWidth: '100%', bgcolor: 'background.paper' }}>
              <Box>
                <ListItem alignItems="flex-start">
                  <ListItemAvatar>
                    <Avatar alt={'Teste Rio'} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={`Teste Rio (rioteste@gmail.com)`}
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: 'inline' }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          Última atualização: 11/03/2024 - 17:14
                        </Typography>
                        {` Validou as informações de Lucas Cordeiro dos Santos. `}
                        <Typography
                          sx={{ display: 'inline' }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          Criado em: 04/12/2023 - 18:07
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem alignItems="flex-start">
                  <ListItemAvatar>
                    <Avatar alt={'Teste Rio'} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={`Teste Rio (rioteste@gmail.com)`}
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: 'inline' }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          Última atualização: 12/03/2024 - 16:08
                        </Typography>
                        {` Validou os cômodos de Guilherme Pedrosa. `}
                        <Typography
                          sx={{ display: 'inline' }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          Criado em: 24/01/2024 - 13:18
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </Box>
          </List>

        </Box>

      </div>
    );
  }
}

const styles = {

  directionRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  }

}