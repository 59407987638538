let polygonCoords_Ostras = [
  {
    lng: -42.0635168,
    lat: -22.4227724
  },
  {
    lng: -42.0635012,
    lat: -22.4227265
  },
  {
    lng: -42.0634857,
    lat: -22.4224183
  },
  {
    lng: -42.063498,
    lat: -22.4221757
  },
  {
    lng: -42.0634334,
    lat: -22.4218406
  },
  {
    lng: -42.0634369,
    lat: -22.4216754
  },
  {
    lng: -42.0633933,
    lat: -22.4213992
  },
  {
    lng: -42.0633999,
    lat: -22.4210873
  },
  {
    lng: -42.0634319,
    lat: -22.4208228
  },
  {
    lng: -42.0632244,
    lat: -22.4203011
  },
  {
    lng: -42.0632109,
    lat: -22.4199048
  },
  {
    lng: -42.0631317,
    lat: -22.4195174
  },
  {
    lng: -42.0627229,
    lat: -22.4192154
  },
  {
    lng: -42.0624319,
    lat: -22.4190273
  },
  {
    lng: -42.062116,
    lat: -22.4187599
  },
  {
    lng: -42.0616006,
    lat: -22.4184272
  },
  {
    lng: -42.0612477,
    lat: -22.4182667
  },
  {
    lng: -42.061143,
    lat: -22.4180812
  },
  {
    lng: -42.0611145,
    lat: -22.4177846
  },
  {
    lng: -42.0610216,
    lat: -22.417529
  },
  {
    lng: -42.0609104,
    lat: -22.4172547
  },
  {
    lng: -42.060682,
    lat: -22.4170557
  },
  {
    lng: -42.0602474,
    lat: -22.4169462
  },
  {
    lng: -42.0599862,
    lat: -22.4169008
  },
  {
    lng: -42.0597798,
    lat: -22.4168463
  },
  {
    lng: -42.0594232,
    lat: -22.4167648
  },
  {
    lng: -42.0590966,
    lat: -22.4166104
  },
  {
    lng: -42.0588292,
    lat: -22.4163415
  },
  {
    lng: -42.0584937,
    lat: -22.4161932
  },
  {
    lng: -42.0581798,
    lat: -22.4160554
  },
  {
    lng: -42.0578221,
    lat: -22.4159095
  },
  {
    lng: -42.0574819,
    lat: -22.4156555
  },
  {
    lng: -42.0570525,
    lat: -22.4154181
  },
  {
    lng: -42.0566742,
    lat: -22.4150992
  },
  {
    lng: -42.0563557,
    lat: -22.4147537
  },
  {
    lng: -42.0561144,
    lat: -22.4145452
  },
  {
    lng: -42.0557477,
    lat: -22.4143252
  },
  {
    lng: -42.0555871,
    lat: -22.41417
  },
  {
    lng: -42.055255,
    lat: -22.4138592
  },
  {
    lng: -42.054944,
    lat: -22.4135895
  },
  {
    lng: -42.0545235,
    lat: -22.4133279
  },
  {
    lng: -42.0541458,
    lat: -22.4131078
  },
  {
    lng: -42.0536199,
    lat: -22.4129322
  },
  {
    lng: -42.0533321,
    lat: -22.4128492
  },
  {
    lng: -42.0529415,
    lat: -22.4128005
  },
  {
    lng: -42.0524851,
    lat: -22.4126103
  },
  {
    lng: -42.0522732,
    lat: -22.4123017
  },
  {
    lng: -42.051974,
    lat: -22.4118649
  },
  {
    lng: -42.0516946,
    lat: -22.4115605
  },
  {
    lng: -42.0513509,
    lat: -22.4112414
  },
  {
    lng: -42.0508805,
    lat: -22.4109461
  },
  {
    lng: -42.0505003,
    lat: -22.4103657
  },
  {
    lng: -42.0501803,
    lat: -22.4095908
  },
  {
    lng: -42.0499509,
    lat: -22.4091696
  },
  {
    lng: -42.0496726,
    lat: -22.4087301
  },
  {
    lng: -42.0496946,
    lat: -22.4082024
  },
  {
    lng: -42.0494094,
    lat: -22.4075957
  },
  {
    lng: -42.049263,
    lat: -22.4072281
  },
  {
    lng: -42.0490434,
    lat: -22.4069718
  },
  {
    lng: -42.0485494,
    lat: -22.4065768
  },
  {
    lng: -42.0481635,
    lat: -22.4061914
  },
  {
    lng: -42.0476355,
    lat: -22.4058899
  },
  {
    lng: -42.0472106,
    lat: -22.4053235
  },
  {
    lng: -42.0468765,
    lat: -22.4047472
  },
  {
    lng: -42.0466435,
    lat: -22.4044953
  },
  {
    lng: -42.0463702,
    lat: -22.4041645
  },
  {
    lng: -42.0460907,
    lat: -22.4038999
  },
  {
    lng: -42.045795,
    lat: -22.4035711
  },
  {
    lng: -42.0455163,
    lat: -22.4033223
  },
  {
    lng: -42.0451159,
    lat: -22.4031423
  },
  {
    lng: -42.0448462,
    lat: -22.402985
  },
  {
    lng: -42.044576,
    lat: -22.4028414
  },
  {
    lng: -42.0442455,
    lat: -22.4025616
  },
  {
    lng: -42.0439786,
    lat: -22.402177
  },
  {
    lng: -42.0436139,
    lat: -22.4018656
  },
  {
    lng: -42.043071,
    lat: -22.4017135
  },
  {
    lng: -42.0423906,
    lat: -22.4014823
  },
  {
    lng: -42.0419856,
    lat: -22.4014932
  },
  {
    lng: -42.0416179,
    lat: -22.4015491
  },
  {
    lng: -42.0410984,
    lat: -22.4015917
  },
  {
    lng: -42.0408593,
    lat: -22.4016264
  },
  {
    lng: -42.0405931,
    lat: -22.4016478
  },
  {
    lng: -42.0402542,
    lat: -22.4016619
  },
  {
    lng: -42.0398751,
    lat: -22.4015128
  },
  {
    lng: -42.0394943,
    lat: -22.4014449
  },
  {
    lng: -42.0392017,
    lat: -22.401338
  },
  {
    lng: -42.0386449,
    lat: -22.4013278
  },
  {
    lng: -42.038218,
    lat: -22.4013707
  },
  {
    lng: -42.0380577,
    lat: -22.4012053
  },
  {
    lng: -42.0378271,
    lat: -22.4005282
  },
  {
    lng: -42.0376318,
    lat: -22.4001717
  },
  {
    lng: -42.0372799,
    lat: -22.3997692
  },
  {
    lng: -42.0370738,
    lat: -22.3995395
  },
  {
    lng: -42.0369138,
    lat: -22.3993123
  },
  {
    lng: -42.0366385,
    lat: -22.3990727
  },
  {
    lng: -42.0364185,
    lat: -22.3988732
  },
  {
    lng: -42.0360584,
    lat: -22.398671
  },
  {
    lng: -42.0355688,
    lat: -22.398623
  },
  {
    lng: -42.0350208,
    lat: -22.3986614
  },
  {
    lng: -42.0344524,
    lat: -22.3986814
  },
  {
    lng: -42.0341714,
    lat: -22.3984279
  },
  {
    lng: -42.0341044,
    lat: -22.3980961
  },
  {
    lng: -42.0339637,
    lat: -22.3975248
  },
  {
    lng: -42.0339734,
    lat: -22.3971422
  },
  {
    lng: -42.033901,
    lat: -22.3968737
  },
  {
    lng: -42.0336476,
    lat: -22.3964628
  },
  {
    lng: -42.0332667,
    lat: -22.3958871
  },
  {
    lng: -42.0327351,
    lat: -22.395207
  },
  {
    lng: -42.0323902,
    lat: -22.3949874
  },
  {
    lng: -42.0320598,
    lat: -22.394748
  },
  {
    lng: -42.0319496,
    lat: -22.3943604
  },
  {
    lng: -42.0319576,
    lat: -22.3939842
  },
  {
    lng: -42.0317975,
    lat: -22.3936233
  },
  {
    lng: -42.0312519,
    lat: -22.3932828
  },
  {
    lng: -42.0307157,
    lat: -22.3930982
  },
  {
    lng: -42.030378,
    lat: -22.3930615
  },
  {
    lng: -42.0300414,
    lat: -22.3931137
  },
  {
    lng: -42.0297595,
    lat: -22.3930032
  },
  {
    lng: -42.0294432,
    lat: -22.3928513
  },
  {
    lng: -42.0292397,
    lat: -22.3926648
  },
  {
    lng: -42.0289798,
    lat: -22.3925585
  },
  {
    lng: -42.0286859,
    lat: -22.3925124
  },
  {
    lng: -42.0283702,
    lat: -22.392466
  },
  {
    lng: -42.0280123,
    lat: -22.3923477
  },
  {
    lng: -42.0277749,
    lat: -22.3920805
  },
  {
    lng: -42.0274165,
    lat: -22.3916055
  },
  {
    lng: -42.0268718,
    lat: -22.3915447
  },
  {
    lng: -42.0262492,
    lat: -22.3910356
  },
  {
    lng: -42.0259376,
    lat: -22.3907963
  },
  {
    lng: -42.0255418,
    lat: -22.3904031
  },
  {
    lng: -42.0252392,
    lat: -22.3900972
  },
  {
    lng: -42.0250815,
    lat: -22.3899681
  },
  {
    lng: -42.0242789,
    lat: -22.3886838
  },
  {
    lng: -42.0239155,
    lat: -22.3883115
  },
  {
    lng: -42.0237888,
    lat: -22.3879439
  },
  {
    lng: -42.0172791,
    lat: -22.3908349
  },
  {
    lng: -42.0072464,
    lat: -22.3952905
  },
  {
    lng: -41.998521,
    lat: -22.3991654
  },
  {
    lng: -41.9984536,
    lat: -22.3992556
  },
  {
    lng: -41.9982396,
    lat: -22.3995563
  },
  {
    lng: -41.9981892,
    lat: -22.3998702
  },
  {
    lng: -41.9978843,
    lat: -22.4001664
  },
  {
    lng: -41.9973625,
    lat: -22.4002958
  },
  {
    lng: -41.9967058,
    lat: -22.4001527
  },
  {
    lng: -41.9961371,
    lat: -22.4000112
  },
  {
    lng: -41.9956222,
    lat: -22.3998217
  },
  {
    lng: -41.9950958,
    lat: -22.3993457
  },
  {
    lng: -41.9949218,
    lat: -22.3989743
  },
  {
    lng: -41.9947811,
    lat: -22.3985774
  },
  {
    lng: -41.9946675,
    lat: -22.3979034
  },
  {
    lng: -41.9942942,
    lat: -22.3974493
  },
  {
    lng: -41.9939572,
    lat: -22.3971482
  },
  {
    lng: -41.9934227,
    lat: -22.3968815
  },
  {
    lng: -41.9929873,
    lat: -22.396613
  },
  {
    lng: -41.9926193,
    lat: -22.3964047
  },
  {
    lng: -41.9919929,
    lat: -22.3964777
  },
  {
    lng: -41.9912409,
    lat: -22.3968605
  },
  {
    lng: -41.9907506,
    lat: -22.3971154
  },
  {
    lng: -41.9904645,
    lat: -22.3976432
  },
  {
    lng: -41.9898777,
    lat: -22.3980228
  },
  {
    lng: -41.9893134,
    lat: -22.3979103
  },
  {
    lng: -41.9888463,
    lat: -22.3977039
  },
  {
    lng: -41.9883455,
    lat: -22.3974672
  },
  {
    lng: -41.9876808,
    lat: -22.3972951
  },
  {
    lng: -41.9872771,
    lat: -22.3969645
  },
  {
    lng: -41.9869441,
    lat: -22.3968478
  },
  {
    lng: -41.9863033,
    lat: -22.3967034
  },
  {
    lng: -41.9857521,
    lat: -22.3967469
  },
  {
    lng: -41.9851231,
    lat: -22.396697
  },
  {
    lng: -41.984461,
    lat: -22.3966478
  },
  {
    lng: -41.9835671,
    lat: -22.3965721
  },
  {
    lng: -41.9823771,
    lat: -22.3964338
  },
  {
    lng: -41.9817577,
    lat: -22.3964035
  },
  {
    lng: -41.9810264,
    lat: -22.3962531
  },
  {
    lng: -41.9804677,
    lat: -22.3960546
  },
  {
    lng: -41.9798642,
    lat: -22.395896
  },
  {
    lng: -41.9791444,
    lat: -22.3956772
  },
  {
    lng: -41.9785356,
    lat: -22.3950246
  },
  {
    lng: -41.9779378,
    lat: -22.394265
  },
  {
    lng: -41.9777692,
    lat: -22.3934898
  },
  {
    lng: -41.9776404,
    lat: -22.3930311
  },
  {
    lng: -41.9773686,
    lat: -22.3923723
  },
  {
    lng: -41.9769583,
    lat: -22.3919727
  },
  {
    lng: -41.9754227,
    lat: -22.3913202
  },
  {
    lng: -41.9745953,
    lat: -22.3912055
  },
  {
    lng: -41.973826,
    lat: -22.3908332
  },
  {
    lng: -41.9731252,
    lat: -22.3906576
  },
  {
    lng: -41.9723153,
    lat: -22.3902825
  },
  {
    lng: -41.9716085,
    lat: -22.3900239
  },
  {
    lng: -41.9713113,
    lat: -22.3899365
  },
  {
    lng: -41.9709508,
    lat: -22.3899297
  },
  {
    lng: -41.9705704,
    lat: -22.3899887
  },
  {
    lng: -41.9701215,
    lat: -22.3900451
  },
  {
    lng: -41.9697141,
    lat: -22.3901274
  },
  {
    lng: -41.9689549,
    lat: -22.3901721
  },
  {
    lng: -41.9682929,
    lat: -22.3901227
  },
  {
    lng: -41.9678419,
    lat: -22.3901086
  },
  {
    lng: -41.9674615,
    lat: -22.389936
  },
  {
    lng: -41.9670609,
    lat: -22.3896196
  },
  {
    lng: -41.9666873,
    lat: -22.3894081
  },
  {
    lng: -41.9657376,
    lat: -22.3890641
  },
  {
    lng: -41.9652881,
    lat: -22.3890301
  },
  {
    lng: -41.9641343,
    lat: -22.3882637
  },
  {
    lng: -41.9636203,
    lat: -22.3880662
  },
  {
    lng: -41.9632469,
    lat: -22.3882474
  },
  {
    lng: -41.9629745,
    lat: -22.3882341
  },
  {
    lng: -41.9624634,
    lat: -22.3886825
  },
  {
    lng: -41.9620029,
    lat: -22.3887453
  },
  {
    lng: -41.9616481,
    lat: -22.388844
  },
  {
    lng: -41.9613294,
    lat: -22.3887761
  },
  {
    lng: -41.9608377,
    lat: -22.388354
  },
  {
    lng: -41.9601076,
    lat: -22.3882137
  },
  {
    lng: -41.9593718,
    lat: -22.3880682
  },
  {
    lng: -41.9586736,
    lat: -22.3878178
  },
  {
    lng: -41.9580585,
    lat: -22.3877899
  },
  {
    lng: -41.9576107,
    lat: -22.3877569
  },
  {
    lng: -41.9570586,
    lat: -22.3878085
  },
  {
    lng: -41.9567302,
    lat: -22.3879067
  },
  {
    lng: -41.9564043,
    lat: -22.3881278
  },
  {
    lng: -41.9559501,
    lat: -22.3884782
  },
  {
    lng: -41.9556302,
    lat: -22.3886276
  },
  {
    lng: -41.9552838,
    lat: -22.3887846
  },
  {
    lng: -41.9550177,
    lat: -22.3888859
  },
  {
    lng: -41.9547886,
    lat: -22.3889143
  },
  {
    lng: -41.954586,
    lat: -22.3888505
  },
  {
    lng: -41.9541366,
    lat: -22.3886974
  },
  {
    lng: -41.9536153,
    lat: -22.3886608
  },
  {
    lng: -41.9533705,
    lat: -22.3888036
  },
  {
    lng: -41.9529822,
    lat: -22.3890521
  },
  {
    lng: -41.9526566,
    lat: -22.3890623
  },
  {
    lng: -41.9523551,
    lat: -22.3888452
  },
  {
    lng: -41.9521248,
    lat: -22.3885036
  },
  {
    lng: -41.9520171,
    lat: -22.3881341
  },
  {
    lng: -41.9515845,
    lat: -22.3875684
  },
  {
    lng: -41.9512718,
    lat: -22.3872203
  },
  {
    lng: -41.9508471,
    lat: -22.3869342
  },
  {
    lng: -41.9504044,
    lat: -22.3868524
  },
  {
    lng: -41.9495367,
    lat: -22.3868452
  },
  {
    lng: -41.9488736,
    lat: -22.3871339
  },
  {
    lng: -41.9485878,
    lat: -22.3877174
  },
  {
    lng: -41.9484266,
    lat: -22.3882966
  },
  {
    lng: -41.9480208,
    lat: -22.3888812
  },
  {
    lng: -41.9477437,
    lat: -22.3892804
  },
  {
    lng: -41.947299,
    lat: -22.3895092
  },
  {
    lng: -41.9467906,
    lat: -22.389426
  },
  {
    lng: -41.9462769,
    lat: -22.3891873
  },
  {
    lng: -41.9459759,
    lat: -22.3888707
  },
  {
    lng: -41.9455555,
    lat: -22.3883884
  },
  {
    lng: -41.9452657,
    lat: -22.3879657
  },
  {
    lng: -41.9452141,
    lat: -22.3875067
  },
  {
    lng: -41.9453617,
    lat: -22.3867897
  },
  {
    lng: -41.9452433,
    lat: -22.386174
  },
  {
    lng: -41.9448512,
    lat: -22.3856022
  },
  {
    lng: -41.9444404,
    lat: -22.3850791
  },
  {
    lng: -41.9443785,
    lat: -22.3846935
  },
  {
    lng: -41.9443615,
    lat: -22.3843654
  },
  {
    lng: -41.9441591,
    lat: -22.3841662
  },
  {
    lng: -41.9436767,
    lat: -22.3840266
  },
  {
    lng: -41.9430995,
    lat: -22.3836914
  },
  {
    lng: -41.9426973,
    lat: -22.3834221
  },
  {
    lng: -41.9422708,
    lat: -22.3831763
  },
  {
    lng: -41.9417952,
    lat: -22.3829544
  },
  {
    lng: -41.941458,
    lat: -22.3826997
  },
  {
    lng: -41.9413161,
    lat: -22.3823535
  },
  {
    lng: -41.9412584,
    lat: -22.3817716
  },
  {
    lng: -41.9411099,
    lat: -22.3813271
  },
  {
    lng: -41.9413066,
    lat: -22.3807746
  },
  {
    lng: -41.9413148,
    lat: -22.380151
  },
  {
    lng: -41.9411555,
    lat: -22.3796468
  },
  {
    lng: -41.9409442,
    lat: -22.3790283
  },
  {
    lng: -41.9407043,
    lat: -22.3782258
  },
  {
    lng: -41.9405731,
    lat: -22.3778825
  },
  {
    lng: -41.9403453,
    lat: -22.3776561
  },
  {
    lng: -41.9400399,
    lat: -22.3775688
  },
  {
    lng: -41.9398198,
    lat: -22.377581
  },
  {
    lng: -41.9395019,
    lat: -22.3776253
  },
  {
    lng: -41.9390822,
    lat: -22.3777021
  },
  {
    lng: -41.9386953,
    lat: -22.3776548
  },
  {
    lng: -41.9384697,
    lat: -22.3777844
  },
  {
    lng: -41.9382913,
    lat: -22.3778087
  },
  {
    lng: -41.9379906,
    lat: -22.3776528
  },
  {
    lng: -41.9376627,
    lat: -22.3773822
  },
  {
    lng: -41.9373834,
    lat: -22.3770645
  },
  {
    lng: -41.9370148,
    lat: -22.3767508
  },
  {
    lng: -41.9368556,
    lat: -22.3767887
  },
  {
    lng: -41.9368128,
    lat: -22.3769196
  },
  {
    lng: -41.9368703,
    lat: -22.3771277
  },
  {
    lng: -41.9369304,
    lat: -22.3773042
  },
  {
    lng: -41.9369402,
    lat: -22.3773244
  },
  {
    lng: -41.9369647,
    lat: -22.3773892
  },
  {
    lng: -41.9370161,
    lat: -22.3774989
  },
  {
    lng: -41.9370792,
    lat: -22.3776259
  },
  {
    lng: -41.9371214,
    lat: -22.377732
  },
  {
    lng: -41.93712,
    lat: -22.3777589
  },
  {
    lng: -41.937121,
    lat: -22.3778746
  },
  {
    lng: -41.9371477,
    lat: -22.3780079
  },
  {
    lng: -41.9371591,
    lat: -22.3781432
  },
  {
    lng: -41.9371548,
    lat: -22.3781873
  },
  {
    lng: -41.9371489,
    lat: -22.3782895
  },
  {
    lng: -41.9371344,
    lat: -22.3784847
  },
  {
    lng: -41.9371257,
    lat: -22.3785165
  },
  {
    lng: -41.9370988,
    lat: -22.3786349
  },
  {
    lng: -41.937081,
    lat: -22.378761
  },
  {
    lng: -41.9370409,
    lat: -22.3789303
  },
  {
    lng: -41.9370194,
    lat: -22.3790675
  },
  {
    lng: -41.9369828,
    lat: -22.3792336
  },
  {
    lng: -41.9369175,
    lat: -22.3794175
  },
  {
    lng: -41.9368713,
    lat: -22.3795932
  },
  {
    lng: -41.936864,
    lat: -22.3797314
  },
  {
    lng: -41.9368897,
    lat: -22.3798779
  },
  {
    lng: -41.9369432,
    lat: -22.3800392
  },
  {
    lng: -41.9369931,
    lat: -22.3801347
  },
  {
    lng: -41.937055,
    lat: -22.380246
  },
  {
    lng: -41.9371614,
    lat: -22.3803938
  },
  {
    lng: -41.9372343,
    lat: -22.3805487
  },
  {
    lng: -41.9373593,
    lat: -22.380759
  },
  {
    lng: -41.9374821,
    lat: -22.380949
  },
  {
    lng: -41.937512,
    lat: -22.3810794
  },
  {
    lng: -41.9375158,
    lat: -22.3812195
  },
  {
    lng: -41.9374989,
    lat: -22.3814151
  },
  {
    lng: -41.9374424,
    lat: -22.3815088
  },
  {
    lng: -41.9374481,
    lat: -22.3816156
  },
  {
    lng: -41.9374561,
    lat: -22.3818023
  },
  {
    lng: -41.9374405,
    lat: -22.3819768
  },
  {
    lng: -41.937437,
    lat: -22.3821632
  },
  {
    lng: -41.9374653,
    lat: -22.382334
  },
  {
    lng: -41.9375234,
    lat: -22.3825149
  },
  {
    lng: -41.9375938,
    lat: -22.3826612
  },
  {
    lng: -41.9376906,
    lat: -22.3828309
  },
  {
    lng: -41.9377773,
    lat: -22.3829946
  },
  {
    lng: -41.9378831,
    lat: -22.3831741
  },
  {
    lng: -41.937981,
    lat: -22.3832821
  },
  {
    lng: -41.9380829,
    lat: -22.3833455
  },
  {
    lng: -41.9382267,
    lat: -22.383376
  },
  {
    lng: -41.9384243,
    lat: -22.3834267
  },
  {
    lng: -41.9386146,
    lat: -22.3834831
  },
  {
    lng: -41.9388156,
    lat: -22.3835419
  },
  {
    lng: -41.9389324,
    lat: -22.3835633
  },
  {
    lng: -41.9390238,
    lat: -22.3837712
  },
  {
    lng: -41.9391148,
    lat: -22.3839264
  },
  {
    lng: -41.9392384,
    lat: -22.3841124
  },
  {
    lng: -41.9393529,
    lat: -22.3842825
  },
  {
    lng: -41.9394592,
    lat: -22.384466
  },
  {
    lng: -41.9395503,
    lat: -22.3846471
  },
  {
    lng: -41.9396434,
    lat: -22.3848419
  },
  {
    lng: -41.9397201,
    lat: -22.3850522
  },
  {
    lng: -41.9397525,
    lat: -22.3852515
  },
  {
    lng: -41.9396909,
    lat: -22.3853487
  },
  {
    lng: -41.9396015,
    lat: -22.3854175
  },
  {
    lng: -41.9394887,
    lat: -22.3854443
  },
  {
    lng: -41.9393018,
    lat: -22.3855047
  },
  {
    lng: -41.9392172,
    lat: -22.3855807
  },
  {
    lng: -41.9391879,
    lat: -22.3856864
  },
  {
    lng: -41.9391901,
    lat: -22.3858245
  },
  {
    lng: -41.939194,
    lat: -22.3860297
  },
  {
    lng: -41.9391664,
    lat: -22.3861893
  },
  {
    lng: -41.9391803,
    lat: -22.3863415
  },
  {
    lng: -41.9392238,
    lat: -22.3864586
  },
  {
    lng: -41.9392917,
    lat: -22.3865389
  },
  {
    lng: -41.9394268,
    lat: -22.3866863
  },
  {
    lng: -41.9395837,
    lat: -22.3868182
  },
  {
    lng: -41.9397381,
    lat: -22.3869206
  },
  {
    lng: -41.9398007,
    lat: -22.3869571
  },
  {
    lng: -41.9398226,
    lat: -22.3869477
  },
  {
    lng: -41.9398478,
    lat: -22.3869528
  },
  {
    lng: -41.9399295,
    lat: -22.3870414
  },
  {
    lng: -41.9399944,
    lat: -22.3871564
  },
  {
    lng: -41.9399975,
    lat: -22.387248
  },
  {
    lng: -41.9399631,
    lat: -22.3873427
  },
  {
    lng: -41.9399529,
    lat: -22.3873985
  },
  {
    lng: -41.9399699,
    lat: -22.3873828
  },
  {
    lng: -41.9399634,
    lat: -22.3874126
  },
  {
    lng: -41.9400618,
    lat: -22.3874741
  },
  {
    lng: -41.9400844,
    lat: -22.3874854
  },
  {
    lng: -41.9401848,
    lat: -22.3874623
  },
  {
    lng: -41.9402261,
    lat: -22.3874729
  },
  {
    lng: -41.9402879,
    lat: -22.3875474
  },
  {
    lng: -41.9404027,
    lat: -22.3877149
  },
  {
    lng: -41.9405157,
    lat: -22.3878851
  },
  {
    lng: -41.9406107,
    lat: -22.3880636
  },
  {
    lng: -41.9406956,
    lat: -22.3882524
  },
  {
    lng: -41.9407006,
    lat: -22.3882635
  },
  {
    lng: -41.9407694,
    lat: -22.3884378
  },
  {
    lng: -41.9408237,
    lat: -22.3886417
  },
  {
    lng: -41.9408857,
    lat: -22.3888314
  },
  {
    lng: -41.9408976,
    lat: -22.3888735
  },
  {
    lng: -41.9408095,
    lat: -22.3889603
  },
  {
    lng: -41.9407144,
    lat: -22.3890898
  },
  {
    lng: -41.9407175,
    lat: -22.3891846
  },
  {
    lng: -41.9407341,
    lat: -22.389393
  },
  {
    lng: -41.9407264,
    lat: -22.3895694
  },
  {
    lng: -41.9407665,
    lat: -22.3896908
  },
  {
    lng: -41.9408309,
    lat: -22.3898796
  },
  {
    lng: -41.9409132,
    lat: -22.3900234
  },
  {
    lng: -41.9410232,
    lat: -22.3901703
  },
  {
    lng: -41.9410987,
    lat: -22.3903214
  },
  {
    lng: -41.9411184,
    lat: -22.3904553
  },
  {
    lng: -41.9411402,
    lat: -22.3906432
  },
  {
    lng: -41.9412084,
    lat: -22.3907541
  },
  {
    lng: -41.9413022,
    lat: -22.3908473
  },
  {
    lng: -41.9413888,
    lat: -22.3909877
  },
  {
    lng: -41.9413981,
    lat: -22.3910997
  },
  {
    lng: -41.9414457,
    lat: -22.3912153
  },
  {
    lng: -41.9415235,
    lat: -22.3913139
  },
  {
    lng: -41.9415311,
    lat: -22.391338
  },
  {
    lng: -41.9414651,
    lat: -22.3914356
  },
  {
    lng: -41.9413517,
    lat: -22.3915413
  },
  {
    lng: -41.9412132,
    lat: -22.3916687
  },
  {
    lng: -41.941056,
    lat: -22.3918102
  },
  {
    lng: -41.940891,
    lat: -22.3919621
  },
  {
    lng: -41.9407504,
    lat: -22.3921033
  },
  {
    lng: -41.9406291,
    lat: -22.3922536
  },
  {
    lng: -41.9404757,
    lat: -22.392407
  },
  {
    lng: -41.9403174,
    lat: -22.3925597
  },
  {
    lng: -41.9401689,
    lat: -22.3926875
  },
  {
    lng: -41.9399919,
    lat: -22.3928247
  },
  {
    lng: -41.9398287,
    lat: -22.3929519
  },
  {
    lng: -41.939694,
    lat: -22.3930241
  },
  {
    lng: -41.9395575,
    lat: -22.3931232
  },
  {
    lng: -41.9393759,
    lat: -22.3932263
  },
  {
    lng: -41.9392075,
    lat: -22.3932936
  },
  {
    lng: -41.9390776,
    lat: -22.3933745
  },
  {
    lng: -41.9389206,
    lat: -22.3934852
  },
  {
    lng: -41.9387357,
    lat: -22.3936121
  },
  {
    lng: -41.9386247,
    lat: -22.3937211
  },
  {
    lng: -41.9385048,
    lat: -22.3938781
  },
  {
    lng: -41.9383937,
    lat: -22.3940326
  },
  {
    lng: -41.9382847,
    lat: -22.3941429
  },
  {
    lng: -41.9381673,
    lat: -22.3942437
  },
  {
    lng: -41.9381072,
    lat: -22.3943655
  },
  {
    lng: -41.9379858,
    lat: -22.3945435
  },
  {
    lng: -41.9378845,
    lat: -22.3946379
  },
  {
    lng: -41.9377565,
    lat: -22.3947036
  },
  {
    lng: -41.9376181,
    lat: -22.394745
  },
  {
    lng: -41.9375909,
    lat: -22.3947545
  },
  {
    lng: -41.9374981,
    lat: -22.3947369
  },
  {
    lng: -41.9373006,
    lat: -22.3947197
  },
  {
    lng: -41.9370998,
    lat: -22.3947028
  },
  {
    lng: -41.9369173,
    lat: -22.3946615
  },
  {
    lng: -41.9366897,
    lat: -22.3946086
  },
  {
    lng: -41.9364785,
    lat: -22.3945589
  },
  {
    lng: -41.9362726,
    lat: -22.3944995
  },
  {
    lng: -41.9360681,
    lat: -22.3944592
  },
  {
    lng: -41.9358553,
    lat: -22.3944097
  },
  {
    lng: -41.9357357,
    lat: -22.3944143
  },
  {
    lng: -41.9357195,
    lat: -22.3944149
  },
  {
    lng: -41.9355844,
    lat: -22.394451
  },
  {
    lng: -41.9353978,
    lat: -22.3945048
  },
  {
    lng: -41.9351917,
    lat: -22.3945558
  },
  {
    lng: -41.9350046,
    lat: -22.3946119
  },
  {
    lng: -41.9348045,
    lat: -22.3946795
  },
  {
    lng: -41.9345948,
    lat: -22.3947317
  },
  {
    lng: -41.9344109,
    lat: -22.394799
  },
  {
    lng: -41.9342335,
    lat: -22.3948728
  },
  {
    lng: -41.9340975,
    lat: -22.3948984
  },
  {
    lng: -41.9338978,
    lat: -22.3949409
  },
  {
    lng: -41.9337499,
    lat: -22.3950006
  },
  {
    lng: -41.9335717,
    lat: -22.3951015
  },
  {
    lng: -41.9334083,
    lat: -22.3952127
  },
  {
    lng: -41.9332649,
    lat: -22.3952739
  },
  {
    lng: -41.9331119,
    lat: -22.3953098
  },
  {
    lng: -41.9328981,
    lat: -22.3953595
  },
  {
    lng: -41.9327432,
    lat: -22.3954204
  },
  {
    lng: -41.9326014,
    lat: -22.3955046
  },
  {
    lng: -41.932561,
    lat: -22.3955967
  },
  {
    lng: -41.9325812,
    lat: -22.3956839
  },
  {
    lng: -41.9326494,
    lat: -22.3958408
  },
  {
    lng: -41.9327317,
    lat: -22.3959537
  },
  {
    lng: -41.9328495,
    lat: -22.3961171
  },
  {
    lng: -41.9329226,
    lat: -22.3962595
  },
  {
    lng: -41.9329255,
    lat: -22.396355
  },
  {
    lng: -41.9328479,
    lat: -22.3964399
  },
  {
    lng: -41.9328192,
    lat: -22.396446
  },
  {
    lng: -41.9327378,
    lat: -22.3964633
  },
  {
    lng: -41.9325402,
    lat: -22.396506
  },
  {
    lng: -41.932338,
    lat: -22.3965715
  },
  {
    lng: -41.9322879,
    lat: -22.3966178
  },
  {
    lng: -41.9291827,
    lat: -22.3973982
  },
  {
    lng: -41.928802,
    lat: -22.3974418
  },
  {
    lng: -41.9282501,
    lat: -22.3974281
  },
  {
    lng: -41.9276748,
    lat: -22.3972935
  },
  {
    lng: -41.9275933,
    lat: -22.3972744
  },
  {
    lng: -41.9238429,
    lat: -22.3964441
  },
  {
    lng: -41.9223028,
    lat: -22.3961031
  },
  {
    lng: -41.9204308,
    lat: -22.3956083
  },
  {
    lng: -41.9200582,
    lat: -22.3955098
  },
  {
    lng: -41.9199592,
    lat: -22.3954475
  },
  {
    lng: -41.9179638,
    lat: -22.3961523
  },
  {
    lng: -41.916256,
    lat: -22.3967554
  },
  {
    lng: -41.9135779,
    lat: -22.3977012
  },
  {
    lng: -41.9125416,
    lat: -22.3980671
  },
  {
    lng: -41.9093206,
    lat: -22.3991853
  },
  {
    lng: -41.9080311,
    lat: -22.3996329
  },
  {
    lng: -41.9069097,
    lat: -22.4000169
  },
  {
    lng: -41.9065962,
    lat: -22.4002307
  },
  {
    lng: -41.9042517,
    lat: -22.4039085
  },
  {
    lng: -41.9034338,
    lat: -22.4051283
  },
  {
    lng: -41.9032939,
    lat: -22.4052344
  },
  {
    lng: -41.9031137,
    lat: -22.405371
  },
  {
    lng: -41.9024638,
    lat: -22.4055961
  },
  {
    lng: -41.9002588,
    lat: -22.4061435
  },
  {
    lng: -41.8987297,
    lat: -22.4065231
  },
  {
    lng: -41.8983533,
    lat: -22.4068164
  },
  {
    lng: -41.8973915,
    lat: -22.4078135
  },
  {
    lng: -41.8939809,
    lat: -22.4113489
  },
  {
    lng: -41.8938598,
    lat: -22.4114745
  },
  {
    lng: -41.8933307,
    lat: -22.4118348
  },
  {
    lng: -41.8928787,
    lat: -22.4120973
  },
  {
    lng: -41.8917009,
    lat: -22.4127598
  },
  {
    lng: -41.8911619,
    lat: -22.4130629
  },
  {
    lng: -41.8894121,
    lat: -22.414047
  },
  {
    lng: -41.888384,
    lat: -22.4144977
  },
  {
    lng: -41.887809,
    lat: -22.4145955
  },
  {
    lng: -41.8877307,
    lat: -22.4146106
  },
  {
    lng: -41.8875945,
    lat: -22.4146005
  },
  {
    lng: -41.887464,
    lat: -22.4146199
  },
  {
    lng: -41.8870658,
    lat: -22.4146267
  },
  {
    lng: -41.8869919,
    lat: -22.4146268
  },
  {
    lng: -41.8868041,
    lat: -22.4146228
  },
  {
    lng: -41.8866713,
    lat: -22.4146139
  },
  {
    lng: -41.8863282,
    lat: -22.4146212
  },
  {
    lng: -41.8859844,
    lat: -22.4146326
  },
  {
    lng: -41.8858058,
    lat: -22.4146325
  },
  {
    lng: -41.8855642,
    lat: -22.4146321
  },
  {
    lng: -41.8854106,
    lat: -22.4146293
  },
  {
    lng: -41.8852744,
    lat: -22.4146296
  },
  {
    lng: -41.8851408,
    lat: -22.4146195
  },
  {
    lng: -41.8850016,
    lat: -22.4146048
  },
  {
    lng: -41.8848922,
    lat: -22.4145991
  },
  {
    lng: -41.8847525,
    lat: -22.4146077
  },
  {
    lng: -41.8844124,
    lat: -22.4146085
  },
  {
    lng: -41.8842379,
    lat: -22.41461
  },
  {
    lng: -41.8840714,
    lat: -22.4145935
  },
  {
    lng: -41.8836584,
    lat: -22.4146134
  },
  {
    lng: -41.8832603,
    lat: -22.4146395
  },
  {
    lng: -41.8831405,
    lat: -22.4146494
  },
  {
    lng: -41.8829239,
    lat: -22.4146655
  },
  {
    lng: -41.8827031,
    lat: -22.4147171
  },
  {
    lng: -41.8825266,
    lat: -22.4147631
  },
  {
    lng: -41.8823507,
    lat: -22.4148125
  },
  {
    lng: -41.8822061,
    lat: -22.4148629
  },
  {
    lng: -41.8820682,
    lat: -22.4149391
  },
  {
    lng: -41.8818444,
    lat: -22.4150723
  },
  {
    lng: -41.8817222,
    lat: -22.4151899
  },
  {
    lng: -41.8815925,
    lat: -22.4152743
  },
  {
    lng: -41.8815039,
    lat: -22.415385
  },
  {
    lng: -41.8814081,
    lat: -22.415492
  },
  {
    lng: -41.8813185,
    lat: -22.4156003
  },
  {
    lng: -41.8812088,
    lat: -22.415793
  },
  {
    lng: -41.8811198,
    lat: -22.4159242
  },
  {
    lng: -41.8810396,
    lat: -22.4160341
  },
  {
    lng: -41.8809385,
    lat: -22.4161542
  },
  {
    lng: -41.8808921,
    lat: -22.4162793
  },
  {
    lng: -41.8806822,
    lat: -22.4165837
  },
  {
    lng: -41.8806315,
    lat: -22.4166677
  },
  {
    lng: -41.880394,
    lat: -22.4169582
  },
  {
    lng: -41.8803065,
    lat: -22.4170986
  },
  {
    lng: -41.8802135,
    lat: -22.4171848
  },
  {
    lng: -41.879929,
    lat: -22.4174379
  },
  {
    lng: -41.8797784,
    lat: -22.4175573
  },
  {
    lng: -41.8797762,
    lat: -22.417559
  },
  {
    lng: -41.8796391,
    lat: -22.4176804
  },
  {
    lng: -41.8794028,
    lat: -22.4177923
  },
  {
    lng: -41.879287,
    lat: -22.4178745
  },
  {
    lng: -41.8791475,
    lat: -22.4179201
  },
  {
    lng: -41.8790423,
    lat: -22.4179452
  },
  {
    lng: -41.8786678,
    lat: -22.4180709
  },
  {
    lng: -41.8760626,
    lat: -22.4184548
  },
  {
    lng: -41.8745471,
    lat: -22.4187075
  },
  {
    lng: -41.8727751,
    lat: -22.4189737
  },
  {
    lng: -41.8710615,
    lat: -22.4192403
  },
  {
    lng: -41.870674,
    lat: -22.4193006
  },
  {
    lng: -41.8703556,
    lat: -22.4193701
  },
  {
    lng: -41.8698466,
    lat: -22.4194091
  },
  {
    lng: -41.8693142,
    lat: -22.4194291
  },
  {
    lng: -41.8677469,
    lat: -22.4192925
  },
  {
    lng: -41.8663603,
    lat: -22.4191743
  },
  {
    lng: -41.8642345,
    lat: -22.4189837
  },
  {
    lng: -41.8638739,
    lat: -22.4189514
  },
  {
    lng: -41.8616668,
    lat: -22.4187663
  },
  {
    lng: -41.8607578,
    lat: -22.4186822
  },
  {
    lng: -41.8605051,
    lat: -22.4186589
  },
  {
    lng: -41.860271,
    lat: -22.4186272
  },
  {
    lng: -41.8601376,
    lat: -22.4186002
  },
  {
    lng: -41.8600259,
    lat: -22.4185497
  },
  {
    lng: -41.859863,
    lat: -22.4184675
  },
  {
    lng: -41.8587036,
    lat: -22.4175908
  },
  {
    lng: -41.8580154,
    lat: -22.4170732
  },
  {
    lng: -41.8576741,
    lat: -22.4168913
  },
  {
    lng: -41.857394,
    lat: -22.4166002
  },
  {
    lng: -41.857154,
    lat: -22.4163668
  },
  {
    lng: -41.8571119,
    lat: -22.4163259
  },
  {
    lng: -41.8570206,
    lat: -22.4162625
  },
  {
    lng: -41.8569355,
    lat: -22.4161414
  },
  {
    lng: -41.8569764,
    lat: -22.4160559
  },
  {
    lng: -41.8571122,
    lat: -22.4159962
  },
  {
    lng: -41.8573804,
    lat: -22.4159256
  },
  {
    lng: -41.85759,
    lat: -22.4158266
  },
  {
    lng: -41.8577215,
    lat: -22.4156819
  },
  {
    lng: -41.857706,
    lat: -22.4155544
  },
  {
    lng: -41.8576389,
    lat: -22.4154565
  },
  {
    lng: -41.8574499,
    lat: -22.4153221
  },
  {
    lng: -41.8573143,
    lat: -22.4152545
  },
  {
    lng: -41.8571598,
    lat: -22.4151181
  },
  {
    lng: -41.8571074,
    lat: -22.414975
  },
  {
    lng: -41.8571097,
    lat: -22.4148176
  },
  {
    lng: -41.85713,
    lat: -22.414621
  },
  {
    lng: -41.8571674,
    lat: -22.4143986
  },
  {
    lng: -41.8572555,
    lat: -22.4141436
  },
  {
    lng: -41.8572423,
    lat: -22.4139199
  },
  {
    lng: -41.8571458,
    lat: -22.4138131
  },
  {
    lng: -41.8570129,
    lat: -22.4138076
  },
  {
    lng: -41.8568518,
    lat: -22.4139135
  },
  {
    lng: -41.8567188,
    lat: -22.4140556
  },
  {
    lng: -41.8564448,
    lat: -22.4141226
  },
  {
    lng: -41.8561097,
    lat: -22.4139231
  },
  {
    lng: -41.8560173,
    lat: -22.4138126
  },
  {
    lng: -41.855802,
    lat: -22.4134667
  },
  {
    lng: -41.8557905,
    lat: -22.4134365
  },
  {
    lng: -41.8556566,
    lat: -22.4131401
  },
  {
    lng: -41.8555975,
    lat: -22.4129439
  },
  {
    lng: -41.8554968,
    lat: -22.4127507
  },
  {
    lng: -41.8552668,
    lat: -22.412457
  },
  {
    lng: -41.8552274,
    lat: -22.4123962
  },
  {
    lng: -41.8551453,
    lat: -22.4122555
  },
  {
    lng: -41.8550917,
    lat: -22.411979
  },
  {
    lng: -41.8551375,
    lat: -22.4116792
  },
  {
    lng: -41.8551926,
    lat: -22.4115088
  },
  {
    lng: -41.8552554,
    lat: -22.4113617
  },
  {
    lng: -41.8553358,
    lat: -22.4112015
  },
  {
    lng: -41.8553129,
    lat: -22.4110329
  },
  {
    lng: -41.8552494,
    lat: -22.4109025
  },
  {
    lng: -41.8551527,
    lat: -22.4108423
  },
  {
    lng: -41.8550127,
    lat: -22.4108269
  },
  {
    lng: -41.8549036,
    lat: -22.4108448
  },
  {
    lng: -41.8547287,
    lat: -22.410908
  },
  {
    lng: -41.8545805,
    lat: -22.4109979
  },
  {
    lng: -41.8544487,
    lat: -22.4111044
  },
  {
    lng: -41.8543413,
    lat: -22.4111469
  },
  {
    lng: -41.8541278,
    lat: -22.4112049
  },
  {
    lng: -41.8539399,
    lat: -22.4113129
  },
  {
    lng: -41.8536651,
    lat: -22.4113605
  },
  {
    lng: -41.8534504,
    lat: -22.4114142
  },
  {
    lng: -41.8533106,
    lat: -22.4114628
  },
  {
    lng: -41.8532941,
    lat: -22.4114685
  },
  {
    lng: -41.853243,
    lat: -22.4114815
  },
  {
    lng: -41.8527408,
    lat: -22.411322
  },
  {
    lng: -41.8523244,
    lat: -22.4111846
  },
  {
    lng: -41.8516914,
    lat: -22.4111715
  },
  {
    lng: -41.8513999,
    lat: -22.4111392
  },
  {
    lng: -41.8508609,
    lat: -22.4110794
  },
  {
    lng: -41.850118,
    lat: -22.4110066
  },
  {
    lng: -41.8492878,
    lat: -22.4109348
  },
  {
    lng: -41.84835,
    lat: -22.410986
  },
  {
    lng: -41.8475955,
    lat: -22.4110081
  },
  {
    lng: -41.8472377,
    lat: -22.4109861
  },
  {
    lng: -41.8448048,
    lat: -22.4111627
  },
  {
    lng: -41.8420464,
    lat: -22.411363
  },
  {
    lng: -41.8417422,
    lat: -22.411325
  },
  {
    lng: -41.8412619,
    lat: -22.4113101
  },
  {
    lng: -41.8408912,
    lat: -22.4113347
  },
  {
    lng: -41.8403675,
    lat: -22.4113405
  },
  {
    lng: -41.8397935,
    lat: -22.4114127
  },
  {
    lng: -41.8396503,
    lat: -22.4113957
  },
  {
    lng: -41.838775,
    lat: -22.4113788
  },
  {
    lng: -41.8383159,
    lat: -22.4113231
  },
  {
    lng: -41.8379671,
    lat: -22.4113474
  },
  {
    lng: -41.8377495,
    lat: -22.4113904
  },
  {
    lng: -41.8376085,
    lat: -22.4113945
  },
  {
    lng: -41.8374318,
    lat: -22.411324
  },
  {
    lng: -41.8373553,
    lat: -22.4112934
  },
  {
    lng: -41.8371222,
    lat: -22.4111712
  },
  {
    lng: -41.836728,
    lat: -22.4110561
  },
  {
    lng: -41.8337297,
    lat: -22.4102924
  },
  {
    lng: -41.833317,
    lat: -22.4103079
  },
  {
    lng: -41.8332947,
    lat: -22.4103067
  },
  {
    lng: -41.8330647,
    lat: -22.4102846
  },
  {
    lng: -41.832841,
    lat: -22.4102789
  },
  {
    lng: -41.8326118,
    lat: -22.4102616
  },
  {
    lng: -41.8313535,
    lat: -22.4094905
  },
  {
    lng: -41.8311003,
    lat: -22.4093168
  },
  {
    lng: -41.8298185,
    lat: -22.4089103
  },
  {
    lng: -41.8280634,
    lat: -22.4082691
  },
  {
    lng: -41.8275297,
    lat: -22.4083546
  },
  {
    lng: -41.8271965,
    lat: -22.4085476
  },
  {
    lng: -41.826693,
    lat: -22.4089504
  },
  {
    lng: -41.8265049,
    lat: -22.4090481
  },
  {
    lng: -41.8262047,
    lat: -22.409204
  },
  {
    lng: -41.825866,
    lat: -22.4094131
  },
  {
    lng: -41.8247688,
    lat: -22.4104007
  },
  {
    lng: -41.8239387,
    lat: -22.4112022
  },
  {
    lng: -41.8237432,
    lat: -22.411377
  },
  {
    lng: -41.8224226,
    lat: -22.4125578
  },
  {
    lng: -41.8198787,
    lat: -22.4149826
  },
  {
    lng: -41.8186409,
    lat: -22.4165179
  },
  {
    lng: -41.8182237,
    lat: -22.4169096
  },
  {
    lng: -41.8179718,
    lat: -22.4170554
  },
  {
    lng: -41.8177902,
    lat: -22.417031
  },
  {
    lng: -41.8182015,
    lat: -22.4172307
  },
  {
    lng: -41.819846,
    lat: -22.4182573
  },
  {
    lng: -41.8212713,
    lat: -22.4192407
  },
  {
    lng: -41.8226472,
    lat: -22.4201774
  },
  {
    lng: -41.823553,
    lat: -22.4208246
  },
  {
    lng: -41.8242347,
    lat: -22.4212713
  },
  {
    lng: -41.8253779,
    lat: -22.4220697
  },
  {
    lng: -41.8265479,
    lat: -22.4230238
  },
  {
    lng: -41.827452,
    lat: -22.4237964
  },
  {
    lng: -41.8280084,
    lat: -22.4244293
  },
  {
    lng: -41.8285595,
    lat: -22.4251175
  },
  {
    lng: -41.828539,
    lat: -22.425279
  },
  {
    lng: -41.8284294,
    lat: -22.4254313
  },
  {
    lng: -41.8285157,
    lat: -22.4255351
  },
  {
    lng: -41.8286171,
    lat: -22.4256381
  },
  {
    lng: -41.8285739,
    lat: -22.4257026
  },
  {
    lng: -41.8286766,
    lat: -22.425744
  },
  {
    lng: -41.8287442,
    lat: -22.4256999
  },
  {
    lng: -41.8288193,
    lat: -22.4256987
  },
  {
    lng: -41.8288955,
    lat: -22.4257427
  },
  {
    lng: -41.8289658,
    lat: -22.4257221
  },
  {
    lng: -41.8290109,
    lat: -22.4256407
  },
  {
    lng: -41.828982,
    lat: -22.4255449
  },
  {
    lng: -41.828797,
    lat: -22.425417
  },
  {
    lng: -41.8289727,
    lat: -22.4252001
  },
  {
    lng: -41.8292854,
    lat: -22.4250828
  },
  {
    lng: -41.8296179,
    lat: -22.4250908
  },
  {
    lng: -41.8300297,
    lat: -22.4252373
  },
  {
    lng: -41.8306099,
    lat: -22.4255925
  },
  {
    lng: -41.8321386,
    lat: -22.4267491
  },
  {
    lng: -41.8333275,
    lat: -22.4276691
  },
  {
    lng: -41.8349462,
    lat: -22.4288618
  },
  {
    lng: -41.8362343,
    lat: -22.4300805
  },
  {
    lng: -41.8375438,
    lat: -22.431157
  },
  {
    lng: -41.8383057,
    lat: -22.4319674
  },
  {
    lng: -41.8393182,
    lat: -22.4327807
  },
  {
    lng: -41.840172,
    lat: -22.4336554
  },
  {
    lng: -41.8408183,
    lat: -22.4342302
  },
  {
    lng: -41.8417249,
    lat: -22.4354322
  },
  {
    lng: -41.84248,
    lat: -22.4363097
  },
  {
    lng: -41.8438262,
    lat: -22.4376887
  },
  {
    lng: -41.8449158,
    lat: -22.4386608
  },
  {
    lng: -41.8458342,
    lat: -22.439537
  },
  {
    lng: -41.8468629,
    lat: -22.440562
  },
  {
    lng: -41.8477775,
    lat: -22.4415349
  },
  {
    lng: -41.8483618,
    lat: -22.4419867
  },
  {
    lng: -41.8492887,
    lat: -22.4429814
  },
  {
    lng: -41.8499318,
    lat: -22.4435076
  },
  {
    lng: -41.8509222,
    lat: -22.4445103
  },
  {
    lng: -41.8518307,
    lat: -22.4454777
  },
  {
    lng: -41.8525584,
    lat: -22.4463524
  },
  {
    lng: -41.8532345,
    lat: -22.4471268
  },
  {
    lng: -41.8537989,
    lat: -22.4478465
  },
  {
    lng: -41.854259,
    lat: -22.4485851
  },
  {
    lng: -41.854595,
    lat: -22.4493728
  },
  {
    lng: -41.855007,
    lat: -22.4505733
  },
  {
    lng: -41.8552145,
    lat: -22.4518307
  },
  {
    lng: -41.8552876,
    lat: -22.4530591
  },
  {
    lng: -41.8551828,
    lat: -22.453478
  },
  {
    lng: -41.8549223,
    lat: -22.4538405
  },
  {
    lng: -41.8548798,
    lat: -22.4539946
  },
  {
    lng: -41.8547271,
    lat: -22.4541051
  },
  {
    lng: -41.8546404,
    lat: -22.454118
  },
  {
    lng: -41.8545458,
    lat: -22.4541238
  },
  {
    lng: -41.8544949,
    lat: -22.4541177
  },
  {
    lng: -41.8544993,
    lat: -22.4540751
  },
  {
    lng: -41.8544757,
    lat: -22.4540325
  },
  {
    lng: -41.8544242,
    lat: -22.4540271
  },
  {
    lng: -41.8544121,
    lat: -22.4540681
  },
  {
    lng: -41.8544176,
    lat: -22.4541022
  },
  {
    lng: -41.8544034,
    lat: -22.4541222
  },
  {
    lng: -41.8543586,
    lat: -22.454099
  },
  {
    lng: -41.8543046,
    lat: -22.4541588
  },
  {
    lng: -41.8543455,
    lat: -22.4542095
  },
  {
    lng: -41.8543121,
    lat: -22.4542877
  },
  {
    lng: -41.854436,
    lat: -22.4543185
  },
  {
    lng: -41.8544645,
    lat: -22.4543553
  },
  {
    lng: -41.8544156,
    lat: -22.4543984
  },
  {
    lng: -41.8544417,
    lat: -22.4544823
  },
  {
    lng: -41.8545342,
    lat: -22.4545235
  },
  {
    lng: -41.8546066,
    lat: -22.4545015
  },
  {
    lng: -41.8546605,
    lat: -22.4545883
  },
  {
    lng: -41.8546538,
    lat: -22.454682
  },
  {
    lng: -41.8547062,
    lat: -22.4546976
  },
  {
    lng: -41.8547533,
    lat: -22.4546604
  },
  {
    lng: -41.8548843,
    lat: -22.4547042
  },
  {
    lng: -41.8549246,
    lat: -22.4547738
  },
  {
    lng: -41.8550567,
    lat: -22.45468
  },
  {
    lng: -41.8551545,
    lat: -22.4547017
  },
  {
    lng: -41.8552966,
    lat: -22.4548155
  },
  {
    lng: -41.8554204,
    lat: -22.4549388
  },
  {
    lng: -41.855521,
    lat: -22.4550871
  },
  {
    lng: -41.8555959,
    lat: -22.455261
  },
  {
    lng: -41.8556295,
    lat: -22.4554062
  },
  {
    lng: -41.8555108,
    lat: -22.4554889
  },
  {
    lng: -41.8554851,
    lat: -22.4555862
  },
  {
    lng: -41.8553705,
    lat: -22.4555235
  },
  {
    lng: -41.8552984,
    lat: -22.4556533
  },
  {
    lng: -41.8553406,
    lat: -22.4557662
  },
  {
    lng: -41.855187,
    lat: -22.4558748
  },
  {
    lng: -41.8550828,
    lat: -22.4558575
  },
  {
    lng: -41.8550103,
    lat: -22.455871
  },
  {
    lng: -41.8549924,
    lat: -22.455955
  },
  {
    lng: -41.8550441,
    lat: -22.4560596
  },
  {
    lng: -41.8551057,
    lat: -22.4562733
  },
  {
    lng: -41.8552209,
    lat: -22.4563204
  },
  {
    lng: -41.8554046,
    lat: -22.4563241
  },
  {
    lng: -41.8556129,
    lat: -22.4563915
  },
  {
    lng: -41.8557213,
    lat: -22.4562987
  },
  {
    lng: -41.8558855,
    lat: -22.4563078
  },
  {
    lng: -41.8562104,
    lat: -22.4565293
  },
  {
    lng: -41.8562233,
    lat: -22.4566404
  },
  {
    lng: -41.8563052,
    lat: -22.4567259
  },
  {
    lng: -41.85643,
    lat: -22.4566108
  },
  {
    lng: -41.8566414,
    lat: -22.4567835
  },
  {
    lng: -41.8565921,
    lat: -22.4568548
  },
  {
    lng: -41.8566415,
    lat: -22.4569315
  },
  {
    lng: -41.8567485,
    lat: -22.4569193
  },
  {
    lng: -41.8567915,
    lat: -22.4569481
  },
  {
    lng: -41.8568511,
    lat: -22.4569347
  },
  {
    lng: -41.8569115,
    lat: -22.4569739
  },
  {
    lng: -41.8569722,
    lat: -22.457106
  },
  {
    lng: -41.8570686,
    lat: -22.4571295
  },
  {
    lng: -41.8571067,
    lat: -22.4571928
  },
  {
    lng: -41.8572308,
    lat: -22.4572724
  },
  {
    lng: -41.8573221,
    lat: -22.4572822
  },
  {
    lng: -41.8574799,
    lat: -22.4573985
  },
  {
    lng: -41.8574374,
    lat: -22.4574704
  },
  {
    lng: -41.8574811,
    lat: -22.4575416
  },
  {
    lng: -41.8576424,
    lat: -22.4575445
  },
  {
    lng: -41.8576798,
    lat: -22.4574952
  },
  {
    lng: -41.8579925,
    lat: -22.4579339
  },
  {
    lng: -41.8581179,
    lat: -22.4580233
  },
  {
    lng: -41.8584811,
    lat: -22.4589088
  },
  {
    lng: -41.8596639,
    lat: -22.4614293
  },
  {
    lng: -41.8597571,
    lat: -22.4616443
  },
  {
    lng: -41.8599667,
    lat: -22.4621276
  },
  {
    lng: -41.8601793,
    lat: -22.4628704
  },
  {
    lng: -41.8604277,
    lat: -22.4638119
  },
  {
    lng: -41.8606225,
    lat: -22.4646644
  },
  {
    lng: -41.8606418,
    lat: -22.4648284
  },
  {
    lng: -41.8606677,
    lat: -22.4650773
  },
  {
    lng: -41.8606863,
    lat: -22.4653001
  },
  {
    lng: -41.8606626,
    lat: -22.465517
  },
  {
    lng: -41.8606085,
    lat: -22.4656677
  },
  {
    lng: -41.860495,
    lat: -22.4658568
  },
  {
    lng: -41.8603632,
    lat: -22.4659047
  },
  {
    lng: -41.8603405,
    lat: -22.4658387
  },
  {
    lng: -41.8602885,
    lat: -22.4658139
  },
  {
    lng: -41.860216,
    lat: -22.4657486
  },
  {
    lng: -41.8601402,
    lat: -22.4658378
  },
  {
    lng: -41.8600558,
    lat: -22.4658472
  },
  {
    lng: -41.8599592,
    lat: -22.465873
  },
  {
    lng: -41.8599332,
    lat: -22.4659639
  },
  {
    lng: -41.8599513,
    lat: -22.4660837
  },
  {
    lng: -41.8600601,
    lat: -22.4661473
  },
  {
    lng: -41.8601354,
    lat: -22.46605
  },
  {
    lng: -41.8602144,
    lat: -22.4659974
  },
  {
    lng: -41.8603565,
    lat: -22.4661048
  },
  {
    lng: -41.8604847,
    lat: -22.4661874
  },
  {
    lng: -41.8605156,
    lat: -22.4660782
  },
  {
    lng: -41.8604663,
    lat: -22.4660319
  },
  {
    lng: -41.8605334,
    lat: -22.4660084
  },
  {
    lng: -41.8606044,
    lat: -22.4660177
  },
  {
    lng: -41.8607599,
    lat: -22.4661027
  },
  {
    lng: -41.8609039,
    lat: -22.4662229
  },
  {
    lng: -41.8610079,
    lat: -22.4663964
  },
  {
    lng: -41.861018,
    lat: -22.4665529
  },
  {
    lng: -41.8610293,
    lat: -22.4666517
  },
  {
    lng: -41.8609954,
    lat: -22.4667138
  },
  {
    lng: -41.861025,
    lat: -22.466768
  },
  {
    lng: -41.8609686,
    lat: -22.4668366
  },
  {
    lng: -41.8609591,
    lat: -22.4668966
  },
  {
    lng: -41.8608849,
    lat: -22.4669709
  },
  {
    lng: -41.8608404,
    lat: -22.4670864
  },
  {
    lng: -41.8608656,
    lat: -22.4672697
  },
  {
    lng: -41.8609559,
    lat: -22.4674114
  },
  {
    lng: -41.8610561,
    lat: -22.4674829
  },
  {
    lng: -41.8610863,
    lat: -22.4675498
  },
  {
    lng: -41.8612081,
    lat: -22.4676438
  },
  {
    lng: -41.8614451,
    lat: -22.4678189
  },
  {
    lng: -41.8615787,
    lat: -22.4679119
  },
  {
    lng: -41.8617282,
    lat: -22.4679737
  },
  {
    lng: -41.8618045,
    lat: -22.4680829
  },
  {
    lng: -41.8618398,
    lat: -22.4682327
  },
  {
    lng: -41.861791,
    lat: -22.4685041
  },
  {
    lng: -41.8616682,
    lat: -22.468669
  },
  {
    lng: -41.8616513,
    lat: -22.4687793
  },
  {
    lng: -41.8616251,
    lat: -22.4688416
  },
  {
    lng: -41.8615582,
    lat: -22.46888
  },
  {
    lng: -41.8614774,
    lat: -22.4688736
  },
  {
    lng: -41.861303,
    lat: -22.468799
  },
  {
    lng: -41.8612276,
    lat: -22.4688368
  },
  {
    lng: -41.8611251,
    lat: -22.4687652
  },
  {
    lng: -41.8610902,
    lat: -22.4688131
  },
  {
    lng: -41.8611359,
    lat: -22.4689146
  },
  {
    lng: -41.8611645,
    lat: -22.4689899
  },
  {
    lng: -41.8612603,
    lat: -22.4690756
  },
  {
    lng: -41.8612157,
    lat: -22.4692279
  },
  {
    lng: -41.8611576,
    lat: -22.4693454
  },
  {
    lng: -41.8610258,
    lat: -22.4694642
  },
  {
    lng: -41.8608395,
    lat: -22.4694237
  },
  {
    lng: -41.8607832,
    lat: -22.4694965
  },
  {
    lng: -41.8606615,
    lat: -22.4695052
  },
  {
    lng: -41.8606221,
    lat: -22.469564
  },
  {
    lng: -41.8606368,
    lat: -22.4696353
  },
  {
    lng: -41.8607315,
    lat: -22.469655
  },
  {
    lng: -41.8607977,
    lat: -22.4696698
  },
  {
    lng: -41.8608168,
    lat: -22.4697372
  },
  {
    lng: -41.8607773,
    lat: -22.4697965
  },
  {
    lng: -41.8606974,
    lat: -22.4699197
  },
  {
    lng: -41.8605461,
    lat: -22.4700154
  },
  {
    lng: -41.8604229,
    lat: -22.4700265
  },
  {
    lng: -41.8603965,
    lat: -22.4701142
  },
  {
    lng: -41.860343,
    lat: -22.4701224
  },
  {
    lng: -41.860332,
    lat: -22.4700097
  },
  {
    lng: -41.8603242,
    lat: -22.4699827
  },
  {
    lng: -41.8602686,
    lat: -22.4699967
  },
  {
    lng: -41.8602716,
    lat: -22.4700974
  },
  {
    lng: -41.8602219,
    lat: -22.4702002
  },
  {
    lng: -41.8601545,
    lat: -22.4702171
  },
  {
    lng: -41.8601599,
    lat: -22.4702957
  },
  {
    lng: -41.86004,
    lat: -22.4703233
  },
  {
    lng: -41.8599501,
    lat: -22.4702881
  },
  {
    lng: -41.8599195,
    lat: -22.4701872
  },
  {
    lng: -41.8598183,
    lat: -22.4701598
  },
  {
    lng: -41.8597184,
    lat: -22.4701954
  },
  {
    lng: -41.8596465,
    lat: -22.4701357
  },
  {
    lng: -41.8595596,
    lat: -22.4701388
  },
  {
    lng: -41.8595498,
    lat: -22.4701985
  },
  {
    lng: -41.8594887,
    lat: -22.4702354
  },
  {
    lng: -41.8594973,
    lat: -22.4703015
  },
  {
    lng: -41.859538,
    lat: -22.4703751
  },
  {
    lng: -41.859486,
    lat: -22.4704103
  },
  {
    lng: -41.8594785,
    lat: -22.4705007
  },
  {
    lng: -41.8595867,
    lat: -22.4705445
  },
  {
    lng: -41.8596797,
    lat: -22.4705215
  },
  {
    lng: -41.8597481,
    lat: -22.470562
  },
  {
    lng: -41.8597826,
    lat: -22.4706198
  },
  {
    lng: -41.8598547,
    lat: -22.4705697
  },
  {
    lng: -41.8598861,
    lat: -22.4704721
  },
  {
    lng: -41.860099,
    lat: -22.4705604
  },
  {
    lng: -41.8600657,
    lat: -22.4706644
  },
  {
    lng: -41.86011,
    lat: -22.4707664
  },
  {
    lng: -41.8601579,
    lat: -22.4708026
  },
  {
    lng: -41.8601008,
    lat: -22.4708974
  },
  {
    lng: -41.8599853,
    lat: -22.4709558
  },
  {
    lng: -41.8599232,
    lat: -22.4710445
  },
  {
    lng: -41.8598264,
    lat: -22.4711075
  },
  {
    lng: -41.8598761,
    lat: -22.4711785
  },
  {
    lng: -41.8600087,
    lat: -22.4712198
  },
  {
    lng: -41.8601334,
    lat: -22.4709883
  },
  {
    lng: -41.860169,
    lat: -22.4710485
  },
  {
    lng: -41.8601107,
    lat: -22.4712172
  },
  {
    lng: -41.860116,
    lat: -22.4712509
  },
  {
    lng: -41.8601619,
    lat: -22.4712192
  },
  {
    lng: -41.8602418,
    lat: -22.4710812
  },
  {
    lng: -41.8602736,
    lat: -22.4709866
  },
  {
    lng: -41.8603041,
    lat: -22.4708851
  },
  {
    lng: -41.860374,
    lat: -22.4708403
  },
  {
    lng: -41.8605163,
    lat: -22.4708457
  },
  {
    lng: -41.8606832,
    lat: -22.4709486
  },
  {
    lng: -41.8609422,
    lat: -22.4711796
  },
  {
    lng: -41.8611094,
    lat: -22.4714261
  },
  {
    lng: -41.8610948,
    lat: -22.4715779
  },
  {
    lng: -41.8611789,
    lat: -22.4716118
  },
  {
    lng: -41.8612035,
    lat: -22.4716855
  },
  {
    lng: -41.8611294,
    lat: -22.4716857
  },
  {
    lng: -41.8610737,
    lat: -22.4717368
  },
  {
    lng: -41.8609893,
    lat: -22.471809
  },
  {
    lng: -41.8610024,
    lat: -22.4719035
  },
  {
    lng: -41.8611168,
    lat: -22.471906
  },
  {
    lng: -41.8612315,
    lat: -22.4718446
  },
  {
    lng: -41.8614124,
    lat: -22.4718594
  },
  {
    lng: -41.8614862,
    lat: -22.472067
  },
  {
    lng: -41.8616052,
    lat: -22.4719732
  },
  {
    lng: -41.8617571,
    lat: -22.472086
  },
  {
    lng: -41.8620315,
    lat: -22.4724239
  },
  {
    lng: -41.8622139,
    lat: -22.4727285
  },
  {
    lng: -41.8624295,
    lat: -22.4733945
  },
  {
    lng: -41.8625517,
    lat: -22.4738691
  },
  {
    lng: -41.8625805,
    lat: -22.474211
  },
  {
    lng: -41.8625422,
    lat: -22.4745124
  },
  {
    lng: -41.8625295,
    lat: -22.4749845
  },
  {
    lng: -41.8626198,
    lat: -22.4753032
  },
  {
    lng: -41.8626963,
    lat: -22.4756972
  },
  {
    lng: -41.8626275,
    lat: -22.4759531
  },
  {
    lng: -41.8625328,
    lat: -22.4760124
  },
  {
    lng: -41.8624177,
    lat: -22.4759694
  },
  {
    lng: -41.8622882,
    lat: -22.476019
  },
  {
    lng: -41.8620649,
    lat: -22.476125
  },
  {
    lng: -41.8617774,
    lat: -22.4762214
  },
  {
    lng: -41.861561,
    lat: -22.476208
  },
  {
    lng: -41.8614411,
    lat: -22.4762619
  },
  {
    lng: -41.8612239,
    lat: -22.4763353
  },
  {
    lng: -41.8612638,
    lat: -22.4765152
  },
  {
    lng: -41.8612571,
    lat: -22.4766736
  },
  {
    lng: -41.8614193,
    lat: -22.4768658
  },
  {
    lng: -41.8613223,
    lat: -22.4770018
  },
  {
    lng: -41.8616157,
    lat: -22.4771469
  },
  {
    lng: -41.8618571,
    lat: -22.4770199
  },
  {
    lng: -41.861953,
    lat: -22.4769094
  },
  {
    lng: -41.8621461,
    lat: -22.4767886
  },
  {
    lng: -41.8622614,
    lat: -22.4766165
  },
  {
    lng: -41.862206,
    lat: -22.4764732
  },
  {
    lng: -41.8622852,
    lat: -22.4763672
  },
  {
    lng: -41.8625018,
    lat: -22.4763268
  },
  {
    lng: -41.8626276,
    lat: -22.4763125
  },
  {
    lng: -41.8626081,
    lat: -22.4765129
  },
  {
    lng: -41.862654,
    lat: -22.4766125
  },
  {
    lng: -41.8627765,
    lat: -22.476617
  },
  {
    lng: -41.8628245,
    lat: -22.4765015
  },
  {
    lng: -41.8629401,
    lat: -22.4764966
  },
  {
    lng: -41.8629957,
    lat: -22.4763744
  },
  {
    lng: -41.8629117,
    lat: -22.4762802
  },
  {
    lng: -41.8629439,
    lat: -22.4761861
  },
  {
    lng: -41.8631317,
    lat: -22.4761613
  },
  {
    lng: -41.8634186,
    lat: -22.4762525
  },
  {
    lng: -41.8636464,
    lat: -22.4764159
  },
  {
    lng: -41.8637405,
    lat: -22.4765932
  },
  {
    lng: -41.8636355,
    lat: -22.4767028
  },
  {
    lng: -41.863565,
    lat: -22.4766909
  },
  {
    lng: -41.8634285,
    lat: -22.4767955
  },
  {
    lng: -41.8635054,
    lat: -22.4768911
  },
  {
    lng: -41.8636149,
    lat: -22.4768742
  },
  {
    lng: -41.8636913,
    lat: -22.4768739
  },
  {
    lng: -41.8637425,
    lat: -22.4768041
  },
  {
    lng: -41.8638933,
    lat: -22.4767119
  },
  {
    lng: -41.8640634,
    lat: -22.4767555
  },
  {
    lng: -41.8641883,
    lat: -22.4768559
  },
  {
    lng: -41.8643813,
    lat: -22.4770774
  },
  {
    lng: -41.8644752,
    lat: -22.4772489
  },
  {
    lng: -41.864398,
    lat: -22.4774021
  },
  {
    lng: -41.8643872,
    lat: -22.4773898
  },
  {
    lng: -41.8642274,
    lat: -22.4775075
  },
  {
    lng: -41.8643355,
    lat: -22.477718
  },
  {
    lng: -41.8643372,
    lat: -22.477845
  },
  {
    lng: -41.8641517,
    lat: -22.4778657
  },
  {
    lng: -41.8640532,
    lat: -22.4780626
  },
  {
    lng: -41.8639175,
    lat: -22.4781392
  },
  {
    lng: -41.8639626,
    lat: -22.4782453
  },
  {
    lng: -41.8640992,
    lat: -22.4783132
  },
  {
    lng: -41.8641373,
    lat: -22.4784501
  },
  {
    lng: -41.8642907,
    lat: -22.4784912
  },
  {
    lng: -41.8642623,
    lat: -22.4783993
  },
  {
    lng: -41.8642928,
    lat: -22.4783046
  },
  {
    lng: -41.8643778,
    lat: -22.4781547
  },
  {
    lng: -41.8645026,
    lat: -22.4779423
  },
  {
    lng: -41.8646149,
    lat: -22.4778634
  },
  {
    lng: -41.8647047,
    lat: -22.4779134
  },
  {
    lng: -41.8648781,
    lat: -22.478015
  },
  {
    lng: -41.8650851,
    lat: -22.478028
  },
  {
    lng: -41.8651474,
    lat: -22.4778753
  },
  {
    lng: -41.8654337,
    lat: -22.4777583
  },
  {
    lng: -41.8656189,
    lat: -22.4778011
  },
  {
    lng: -41.8657567,
    lat: -22.4776473
  },
  {
    lng: -41.8661145,
    lat: -22.477564
  },
  {
    lng: -41.8665563,
    lat: -22.4776241
  },
  {
    lng: -41.8670189,
    lat: -22.4777338
  },
  {
    lng: -41.8676461,
    lat: -22.4780196
  },
  {
    lng: -41.8681987,
    lat: -22.4783285
  },
  {
    lng: -41.8686937,
    lat: -22.478565
  },
  {
    lng: -41.8690495,
    lat: -22.4788721
  },
  {
    lng: -41.8694549,
    lat: -22.4791688
  },
  {
    lng: -41.8694326,
    lat: -22.4792261
  },
  {
    lng: -41.869489,
    lat: -22.4792621
  },
  {
    lng: -41.8695145,
    lat: -22.479285
  },
  {
    lng: -41.8695625,
    lat: -22.4793119
  },
  {
    lng: -41.8696124,
    lat: -22.4793812
  },
  {
    lng: -41.8697031,
    lat: -22.479424
  },
  {
    lng: -41.8698573,
    lat: -22.4796442
  },
  {
    lng: -41.8700689,
    lat: -22.4796856
  },
  {
    lng: -41.8703374,
    lat: -22.4798687
  },
  {
    lng: -41.8705764,
    lat: -22.4801304
  },
  {
    lng: -41.8706108,
    lat: -22.480337
  },
  {
    lng: -41.870415,
    lat: -22.4803898
  },
  {
    lng: -41.8705098,
    lat: -22.4804779
  },
  {
    lng: -41.8705192,
    lat: -22.4805326
  },
  {
    lng: -41.8705997,
    lat: -22.4806304
  },
  {
    lng: -41.8707197,
    lat: -22.4806288
  },
  {
    lng: -41.8708771,
    lat: -22.480637
  },
  {
    lng: -41.8710196,
    lat: -22.4806899
  },
  {
    lng: -41.8711817,
    lat: -22.480696
  },
  {
    lng: -41.8712346,
    lat: -22.4807612
  },
  {
    lng: -41.871261,
    lat: -22.4808572
  },
  {
    lng: -41.8711576,
    lat: -22.480986
  },
  {
    lng: -41.8709953,
    lat: -22.4811021
  },
  {
    lng: -41.8709364,
    lat: -22.4811869
  },
  {
    lng: -41.8709512,
    lat: -22.4813244
  },
  {
    lng: -41.8710826,
    lat: -22.4813542
  },
  {
    lng: -41.8712984,
    lat: -22.4813243
  },
  {
    lng: -41.8714727,
    lat: -22.4812549
  },
  {
    lng: -41.8718426,
    lat: -22.4812389
  },
  {
    lng: -41.8722236,
    lat: -22.4810306
  },
  {
    lng: -41.872354,
    lat: -22.480955
  },
  {
    lng: -41.8725646,
    lat: -22.4810039
  },
  {
    lng: -41.8729253,
    lat: -22.4810561
  },
  {
    lng: -41.8731739,
    lat: -22.4812024
  },
  {
    lng: -41.8734435,
    lat: -22.4815494
  },
  {
    lng: -41.8736522,
    lat: -22.4820446
  },
  {
    lng: -41.8737672,
    lat: -22.4824972
  },
  {
    lng: -41.8738397,
    lat: -22.482766
  },
  {
    lng: -41.8738527,
    lat: -22.4830209
  },
  {
    lng: -41.8737843,
    lat: -22.4833039
  },
  {
    lng: -41.8735004,
    lat: -22.4834244
  },
  {
    lng: -41.873246,
    lat: -22.4836161
  },
  {
    lng: -41.8730138,
    lat: -22.4837498
  },
  {
    lng: -41.872891,
    lat: -22.4838927
  },
  {
    lng: -41.8729811,
    lat: -22.484047
  },
  {
    lng: -41.8731353,
    lat: -22.4840417
  },
  {
    lng: -41.8731927,
    lat: -22.4841546
  },
  {
    lng: -41.8731352,
    lat: -22.4843037
  },
  {
    lng: -41.8730403,
    lat: -22.4844545
  },
  {
    lng: -41.8730745,
    lat: -22.4846083
  },
  {
    lng: -41.8731865,
    lat: -22.4846001
  },
  {
    lng: -41.873289,
    lat: -22.484494
  },
  {
    lng: -41.8733607,
    lat: -22.4845218
  },
  {
    lng: -41.8733827,
    lat: -22.4846934
  },
  {
    lng: -41.8735378,
    lat: -22.4845757
  },
  {
    lng: -41.873482,
    lat: -22.4843723
  },
  {
    lng: -41.8738049,
    lat: -22.4840846
  },
  {
    lng: -41.8739582,
    lat: -22.4839018
  },
  {
    lng: -41.8744215,
    lat: -22.4838385
  },
  {
    lng: -41.8753303,
    lat: -22.4838145
  },
  {
    lng: -41.8759419,
    lat: -22.484112
  },
  {
    lng: -41.8768914,
    lat: -22.4848753
  },
  {
    lng: -41.8775459,
    lat: -22.4858469
  },
  {
    lng: -41.8777175,
    lat: -22.4863127
  },
  {
    lng: -41.8776784,
    lat: -22.4866608
  },
  {
    lng: -41.8775029,
    lat: -22.4865605
  },
  {
    lng: -41.8774574,
    lat: -22.4866638
  },
  {
    lng: -41.8773821,
    lat: -22.4866934
  },
  {
    lng: -41.877238,
    lat: -22.4866795
  },
  {
    lng: -41.8771806,
    lat: -22.4867553
  },
  {
    lng: -41.8771996,
    lat: -22.4868945
  },
  {
    lng: -41.8770851,
    lat: -22.4869706
  },
  {
    lng: -41.8770577,
    lat: -22.4871059
  },
  {
    lng: -41.8768476,
    lat: -22.4871413
  },
  {
    lng: -41.8767034,
    lat: -22.4873416
  },
  {
    lng: -41.8767035,
    lat: -22.4874151
  },
  {
    lng: -41.8765749,
    lat: -22.4874676
  },
  {
    lng: -41.8765066,
    lat: -22.487573
  },
  {
    lng: -41.8765554,
    lat: -22.4876411
  },
  {
    lng: -41.8765373,
    lat: -22.4877253
  },
  {
    lng: -41.8767336,
    lat: -22.4879243
  },
  {
    lng: -41.8768911,
    lat: -22.4879799
  },
  {
    lng: -41.8769553,
    lat: -22.4879029
  },
  {
    lng: -41.8770126,
    lat: -22.4879272
  },
  {
    lng: -41.8769854,
    lat: -22.4879828
  },
  {
    lng: -41.8770338,
    lat: -22.4880154
  },
  {
    lng: -41.877566,
    lat: -22.4880573
  },
  {
    lng: -41.8778145,
    lat: -22.4881395
  },
  {
    lng: -41.8780652,
    lat: -22.4881931
  },
  {
    lng: -41.8785945,
    lat: -22.4882658
  },
  {
    lng: -41.8788287,
    lat: -22.4882816
  },
  {
    lng: -41.8789086,
    lat: -22.4881047
  },
  {
    lng: -41.878937,
    lat: -22.4878249
  },
  {
    lng: -41.879119,
    lat: -22.4878624
  },
  {
    lng: -41.879306,
    lat: -22.4876174
  },
  {
    lng: -41.8792538,
    lat: -22.4874396
  },
  {
    lng: -41.879255,
    lat: -22.4873814
  },
  {
    lng: -41.8793871,
    lat: -22.4872647
  },
  {
    lng: -41.8795973,
    lat: -22.4872241
  },
  {
    lng: -41.8797729,
    lat: -22.4871656
  },
  {
    lng: -41.8799471,
    lat: -22.4870954
  },
  {
    lng: -41.8803498,
    lat: -22.4874114
  },
  {
    lng: -41.8804379,
    lat: -22.4875127
  },
  {
    lng: -41.8804207,
    lat: -22.4876023
  },
  {
    lng: -41.8803739,
    lat: -22.4876609
  },
  {
    lng: -41.880227,
    lat: -22.4877389
  },
  {
    lng: -41.8805447,
    lat: -22.4879453
  },
  {
    lng: -41.8807114,
    lat: -22.4880087
  },
  {
    lng: -41.8809486,
    lat: -22.4880407
  },
  {
    lng: -41.8809661,
    lat: -22.4878964
  },
  {
    lng: -41.8811412,
    lat: -22.4879659
  },
  {
    lng: -41.8812329,
    lat: -22.4880634
  },
  {
    lng: -41.8816443,
    lat: -22.4881674
  },
  {
    lng: -41.8818297,
    lat: -22.4881413
  },
  {
    lng: -41.8822347,
    lat: -22.4883021
  },
  {
    lng: -41.8823751,
    lat: -22.4884206
  },
  {
    lng: -41.8822409,
    lat: -22.4885434
  },
  {
    lng: -41.8821203,
    lat: -22.4887361
  },
  {
    lng: -41.8823163,
    lat: -22.4887652
  },
  {
    lng: -41.8823705,
    lat: -22.4886698
  },
  {
    lng: -41.8826436,
    lat: -22.4886456
  },
  {
    lng: -41.8827392,
    lat: -22.4885919
  },
  {
    lng: -41.8826224,
    lat: -22.48847
  },
  {
    lng: -41.8827021,
    lat: -22.4883924
  },
  {
    lng: -41.8830001,
    lat: -22.4883832
  },
  {
    lng: -41.883178,
    lat: -22.4884637
  },
  {
    lng: -41.8831963,
    lat: -22.4883179
  },
  {
    lng: -41.8831053,
    lat: -22.4882391
  },
  {
    lng: -41.8830887,
    lat: -22.488073
  },
  {
    lng: -41.8832124,
    lat: -22.4881162
  },
  {
    lng: -41.8832638,
    lat: -22.4880468
  },
  {
    lng: -41.8831911,
    lat: -22.4879119
  },
  {
    lng: -41.8831997,
    lat: -22.4877479
  },
  {
    lng: -41.8833578,
    lat: -22.4875406
  },
  {
    lng: -41.8835262,
    lat: -22.487477
  },
  {
    lng: -41.8837538,
    lat: -22.4874186
  },
  {
    lng: -41.8837375,
    lat: -22.4874853
  },
  {
    lng: -41.8838035,
    lat: -22.4875175
  },
  {
    lng: -41.8840347,
    lat: -22.4874356
  },
  {
    lng: -41.8842658,
    lat: -22.4874194
  },
  {
    lng: -41.8845479,
    lat: -22.4873418
  },
  {
    lng: -41.8847506,
    lat: -22.4872116
  },
  {
    lng: -41.8844356,
    lat: -22.4871209
  },
  {
    lng: -41.8844672,
    lat: -22.4870519
  },
  {
    lng: -41.8848499,
    lat: -22.4871533
  },
  {
    lng: -41.8850186,
    lat: -22.4871643
  },
  {
    lng: -41.8852896,
    lat: -22.487184
  },
  {
    lng: -41.8854197,
    lat: -22.4872226
  },
  {
    lng: -41.8854687,
    lat: -22.4871902
  },
  {
    lng: -41.8854172,
    lat: -22.4871149
  },
  {
    lng: -41.8853448,
    lat: -22.4870742
  },
  {
    lng: -41.8853426,
    lat: -22.4870119
  },
  {
    lng: -41.88542,
    lat: -22.4868911
  },
  {
    lng: -41.8853372,
    lat: -22.4868795
  },
  {
    lng: -41.8852641,
    lat: -22.4869102
  },
  {
    lng: -41.8851236,
    lat: -22.4868738
  },
  {
    lng: -41.8849358,
    lat: -22.4867547
  },
  {
    lng: -41.8849936,
    lat: -22.4864499
  },
  {
    lng: -41.8854736,
    lat: -22.4861165
  },
  {
    lng: -41.8858503,
    lat: -22.485933
  },
  {
    lng: -41.8864628,
    lat: -22.4858307
  },
  {
    lng: -41.8872797,
    lat: -22.4858081
  },
  {
    lng: -41.8885519,
    lat: -22.4859743
  },
  {
    lng: -41.8897897,
    lat: -22.4863515
  },
  {
    lng: -41.8905726,
    lat: -22.4867519
  },
  {
    lng: -41.8912853,
    lat: -22.4871581
  },
  {
    lng: -41.8920746,
    lat: -22.4875853
  },
  {
    lng: -41.8929007,
    lat: -22.4882311
  },
  {
    lng: -41.893757,
    lat: -22.488879
  },
  {
    lng: -41.8947364,
    lat: -22.4897804
  },
  {
    lng: -41.8954658,
    lat: -22.490479
  },
  {
    lng: -41.8960236,
    lat: -22.4908722
  },
  {
    lng: -41.8967814,
    lat: -22.4916847
  },
  {
    lng: -41.8970657,
    lat: -22.4920321
  },
  {
    lng: -41.8974315,
    lat: -22.4924792
  },
  {
    lng: -41.8984219,
    lat: -22.4935099
  },
  {
    lng: -41.8990992,
    lat: -22.4942393
  },
  {
    lng: -41.9009805,
    lat: -22.4965868
  },
  {
    lng: -41.9021898,
    lat: -22.4980957
  },
  {
    lng: -41.902972,
    lat: -22.4990401
  },
  {
    lng: -41.9043283,
    lat: -22.5006775
  },
  {
    lng: -41.9061534,
    lat: -22.5029954
  },
  {
    lng: -41.9077359,
    lat: -22.50503
  },
  {
    lng: -41.9089631,
    lat: -22.5065389
  },
  {
    lng: -41.9098467,
    lat: -22.5076684
  },
  {
    lng: -41.9102716,
    lat: -22.5082116
  },
  {
    lng: -41.9107095,
    lat: -22.5087714
  },
  {
    lng: -41.9114609,
    lat: -22.509732
  },
  {
    lng: -41.9122093,
    lat: -22.5107947
  },
  {
    lng: -41.9128586,
    lat: -22.5117445
  },
  {
    lng: -41.9135878,
    lat: -22.512689
  },
  {
    lng: -41.9142592,
    lat: -22.5136618
  },
  {
    lng: -41.9152494,
    lat: -22.5149284
  },
  {
    lng: -41.9157977,
    lat: -22.5158353
  },
  {
    lng: -41.9160604,
    lat: -22.5161338
  },
  {
    lng: -41.9162393,
    lat: -22.5166048
  },
  {
    lng: -41.9168493,
    lat: -22.5175326
  },
  {
    lng: -41.9173226,
    lat: -22.518459
  },
  {
    lng: -41.917396,
    lat: -22.5186155
  },
  {
    lng: -41.9174919,
    lat: -22.5188264
  },
  {
    lng: -41.9179644,
    lat: -22.5196071
  },
  {
    lng: -41.9182466,
    lat: -22.5203634
  },
  {
    lng: -41.9183151,
    lat: -22.5207256
  },
  {
    lng: -41.9183916,
    lat: -22.5211149
  },
  {
    lng: -41.9183033,
    lat: -22.5211413
  },
  {
    lng: -41.9183571,
    lat: -22.521249
  },
  {
    lng: -41.9185086,
    lat: -22.5212949
  },
  {
    lng: -41.9185573,
    lat: -22.5213607
  },
  {
    lng: -41.9186966,
    lat: -22.5213589
  },
  {
    lng: -41.9188321,
    lat: -22.5214891
  },
  {
    lng: -41.9189044,
    lat: -22.5215556
  },
  {
    lng: -41.919042,
    lat: -22.5215763
  },
  {
    lng: -41.9190685,
    lat: -22.5214824
  },
  {
    lng: -41.9191328,
    lat: -22.5214943
  },
  {
    lng: -41.9193585,
    lat: -22.5216933
  },
  {
    lng: -41.9194791,
    lat: -22.5219271
  },
  {
    lng: -41.9196777,
    lat: -22.5224228
  },
  {
    lng: -41.9201319,
    lat: -22.5235071
  },
  {
    lng: -41.9202222,
    lat: -22.5238543
  },
  {
    lng: -41.9203291,
    lat: -22.5244776
  },
  {
    lng: -41.9204073,
    lat: -22.524722
  },
  {
    lng: -41.9204987,
    lat: -22.5250078
  },
  {
    lng: -41.9205029,
    lat: -22.5253233
  },
  {
    lng: -41.9205835,
    lat: -22.5255125
  },
  {
    lng: -41.9205845,
    lat: -22.5256416
  },
  {
    lng: -41.9204485,
    lat: -22.525768
  },
  {
    lng: -41.9203482,
    lat: -22.5257306
  },
  {
    lng: -41.9203376,
    lat: -22.5257899
  },
  {
    lng: -41.9203789,
    lat: -22.525853
  },
  {
    lng: -41.9203179,
    lat: -22.5259133
  },
  {
    lng: -41.9203137,
    lat: -22.5259707
  },
  {
    lng: -41.9202062,
    lat: -22.5260212
  },
  {
    lng: -41.9200955,
    lat: -22.5262038
  },
  {
    lng: -41.920009,
    lat: -22.526185
  },
  {
    lng: -41.9200106,
    lat: -22.5262115
  },
  {
    lng: -41.9201055,
    lat: -22.5262725
  },
  {
    lng: -41.9200851,
    lat: -22.5263109
  },
  {
    lng: -41.9200187,
    lat: -22.5263095
  },
  {
    lng: -41.9200957,
    lat: -22.5263579
  },
  {
    lng: -41.9202004,
    lat: -22.5263761
  },
  {
    lng: -41.9202624,
    lat: -22.5262696
  },
  {
    lng: -41.9203327,
    lat: -22.5262471
  },
  {
    lng: -41.9203371,
    lat: -22.5261829
  },
  {
    lng: -41.9204044,
    lat: -22.5261867
  },
  {
    lng: -41.9204544,
    lat: -22.52625
  },
  {
    lng: -41.9205404,
    lat: -22.5262822
  },
  {
    lng: -41.9205927,
    lat: -22.5263347
  },
  {
    lng: -41.9206993,
    lat: -22.5263044
  },
  {
    lng: -41.9207156,
    lat: -22.526237
  },
  {
    lng: -41.9206756,
    lat: -22.5261278
  },
  {
    lng: -41.9206026,
    lat: -22.5260664
  },
  {
    lng: -41.9206597,
    lat: -22.5260013
  },
  {
    lng: -41.9207512,
    lat: -22.5259647
  },
  {
    lng: -41.9209322,
    lat: -22.5259666
  },
  {
    lng: -41.9210362,
    lat: -22.5259966
  },
  {
    lng: -41.9211502,
    lat: -22.5260568
  },
  {
    lng: -41.9212252,
    lat: -22.5261898
  },
  {
    lng: -41.9211364,
    lat: -22.5262463
  },
  {
    lng: -41.9211744,
    lat: -22.5263903
  },
  {
    lng: -41.9211738,
    lat: -22.526499
  },
  {
    lng: -41.9212384,
    lat: -22.526504
  },
  {
    lng: -41.9212793,
    lat: -22.5264407
  },
  {
    lng: -41.9212816,
    lat: -22.5263677
  },
  {
    lng: -41.921339,
    lat: -22.5262635
  },
  {
    lng: -41.9214436,
    lat: -22.5262487
  },
  {
    lng: -41.9215751,
    lat: -22.5262487
  },
  {
    lng: -41.9216985,
    lat: -22.5263032
  },
  {
    lng: -41.9216394,
    lat: -22.5263911
  },
  {
    lng: -41.9215783,
    lat: -22.5263802
  },
  {
    lng: -41.9215527,
    lat: -22.5264522
  },
  {
    lng: -41.9216127,
    lat: -22.5264999
  },
  {
    lng: -41.9216756,
    lat: -22.5265143
  },
  {
    lng: -41.9216949,
    lat: -22.5265808
  },
  {
    lng: -41.9217582,
    lat: -22.5265992
  },
  {
    lng: -41.9218548,
    lat: -22.5265709
  },
  {
    lng: -41.9219343,
    lat: -22.52666
  },
  {
    lng: -41.9220533,
    lat: -22.526604
  },
  {
    lng: -41.9221973,
    lat: -22.5267219
  },
  {
    lng: -41.9223425,
    lat: -22.526864
  },
  {
    lng: -41.9225548,
    lat: -22.5271615
  },
  {
    lng: -41.9226115,
    lat: -22.5273761
  },
  {
    lng: -41.9225905,
    lat: -22.5275267
  },
  {
    lng: -41.9225343,
    lat: -22.5275721
  },
  {
    lng: -41.9224696,
    lat: -22.527601
  },
  {
    lng: -41.9224571,
    lat: -22.5276479
  },
  {
    lng: -41.9224308,
    lat: -22.5276744
  },
  {
    lng: -41.9224383,
    lat: -22.5276897
  },
  {
    lng: -41.9224124,
    lat: -22.5277133
  },
  {
    lng: -41.922428,
    lat: -22.5277256
  },
  {
    lng: -41.9224312,
    lat: -22.5277371
  },
  {
    lng: -41.9224268,
    lat: -22.5277492
  },
  {
    lng: -41.9223875,
    lat: -22.5277502
  },
  {
    lng: -41.9223665,
    lat: -22.5277862
  },
  {
    lng: -41.9223464,
    lat: -22.5277926
  },
  {
    lng: -41.9222581,
    lat: -22.5278041
  },
  {
    lng: -41.9222071,
    lat: -22.5278255
  },
  {
    lng: -41.9222027,
    lat: -22.5278444
  },
  {
    lng: -41.9221866,
    lat: -22.5278488
  },
  {
    lng: -41.9221758,
    lat: -22.5278443
  },
  {
    lng: -41.9221643,
    lat: -22.527849
  },
  {
    lng: -41.9221493,
    lat: -22.52785
  },
  {
    lng: -41.9221388,
    lat: -22.5278608
  },
  {
    lng: -41.922125,
    lat: -22.5278599
  },
  {
    lng: -41.9221179,
    lat: -22.5278731
  },
  {
    lng: -41.9220835,
    lat: -22.5278769
  },
  {
    lng: -41.9220476,
    lat: -22.5278783
  },
  {
    lng: -41.9220324,
    lat: -22.5278849
  },
  {
    lng: -41.9220049,
    lat: -22.5279042
  },
  {
    lng: -41.9219876,
    lat: -22.5279221
  },
  {
    lng: -41.9219881,
    lat: -22.5279302
  },
  {
    lng: -41.9219922,
    lat: -22.5279378
  },
  {
    lng: -41.9219896,
    lat: -22.5279456
  },
  {
    lng: -41.9219915,
    lat: -22.5279539
  },
  {
    lng: -41.9220301,
    lat: -22.5279328
  },
  {
    lng: -41.9220458,
    lat: -22.5279272
  },
  {
    lng: -41.9220348,
    lat: -22.5279379
  },
  {
    lng: -41.9219909,
    lat: -22.5279752
  },
  {
    lng: -41.9220002,
    lat: -22.5279807
  },
  {
    lng: -41.9220105,
    lat: -22.5279768
  },
  {
    lng: -41.922085,
    lat: -22.5279508
  },
  {
    lng: -41.9220916,
    lat: -22.5279524
  },
  {
    lng: -41.9220878,
    lat: -22.5279568
  },
  {
    lng: -41.922019,
    lat: -22.5279944
  },
  {
    lng: -41.9220128,
    lat: -22.5279978
  },
  {
    lng: -41.9220066,
    lat: -22.5279984
  },
  {
    lng: -41.9220038,
    lat: -22.5280041
  },
  {
    lng: -41.9220051,
    lat: -22.5280097
  },
  {
    lng: -41.9220147,
    lat: -22.5280133
  },
  {
    lng: -41.9220193,
    lat: -22.5280168
  },
  {
    lng: -41.9220225,
    lat: -22.5280224
  },
  {
    lng: -41.9220381,
    lat: -22.5280166
  },
  {
    lng: -41.9220576,
    lat: -22.5279936
  },
  {
    lng: -41.9220774,
    lat: -22.5280123
  },
  {
    lng: -41.9221186,
    lat: -22.5279901
  },
  {
    lng: -41.9221756,
    lat: -22.5279897
  },
  {
    lng: -41.9221911,
    lat: -22.5279924
  },
  {
    lng: -41.9221989,
    lat: -22.5280036
  },
  {
    lng: -41.9222021,
    lat: -22.5280395
  },
  {
    lng: -41.9222309,
    lat: -22.5280666
  },
  {
    lng: -41.9222849,
    lat: -22.5280678
  },
  {
    lng: -41.9222919,
    lat: -22.5280727
  },
  {
    lng: -41.9223032,
    lat: -22.5280659
  },
  {
    lng: -41.9223391,
    lat: -22.5280347
  },
  {
    lng: -41.9223503,
    lat: -22.5280301
  },
  {
    lng: -41.9223535,
    lat: -22.528035
  },
  {
    lng: -41.9223529,
    lat: -22.5280455
  },
  {
    lng: -41.9223399,
    lat: -22.528073
  },
  {
    lng: -41.9223666,
    lat: -22.528074
  },
  {
    lng: -41.9223768,
    lat: -22.5280717
  },
  {
    lng: -41.9223838,
    lat: -22.5280641
  },
  {
    lng: -41.9224448,
    lat: -22.5280625
  },
  {
    lng: -41.9224713,
    lat: -22.528088
  },
  {
    lng: -41.9224717,
    lat: -22.5281024
  },
  {
    lng: -41.9225068,
    lat: -22.5280945
  },
  {
    lng: -41.9225394,
    lat: -22.5280983
  },
  {
    lng: -41.9225363,
    lat: -22.5281153
  },
  {
    lng: -41.9225099,
    lat: -22.5281222
  },
  {
    lng: -41.9225082,
    lat: -22.5281363
  },
  {
    lng: -41.9225145,
    lat: -22.5281503
  },
  {
    lng: -41.9225695,
    lat: -22.5281947
  },
  {
    lng: -41.9226173,
    lat: -22.5281977
  },
  {
    lng: -41.9226444,
    lat: -22.5282135
  },
  {
    lng: -41.9226172,
    lat: -22.5282357
  },
  {
    lng: -41.9226429,
    lat: -22.528268
  },
  {
    lng: -41.9226669,
    lat: -22.5282804
  },
  {
    lng: -41.922743,
    lat: -22.5282723
  },
  {
    lng: -41.922732,
    lat: -22.5282994
  },
  {
    lng: -41.9227413,
    lat: -22.528322
  },
  {
    lng: -41.9227587,
    lat: -22.5283332
  },
  {
    lng: -41.9227966,
    lat: -22.5283324
  },
  {
    lng: -41.9227923,
    lat: -22.5283612
  },
  {
    lng: -41.9227694,
    lat: -22.5283818
  },
  {
    lng: -41.9226844,
    lat: -22.5283791
  },
  {
    lng: -41.922643,
    lat: -22.5283925
  },
  {
    lng: -41.9224418,
    lat: -22.5286301
  },
  {
    lng: -41.9224177,
    lat: -22.5288268
  },
  {
    lng: -41.9224541,
    lat: -22.5289068
  },
  {
    lng: -41.9225872,
    lat: -22.5289876
  },
  {
    lng: -41.9227538,
    lat: -22.5289671
  },
  {
    lng: -41.9228027,
    lat: -22.5288013
  },
  {
    lng: -41.9228935,
    lat: -22.5286936
  },
  {
    lng: -41.9229347,
    lat: -22.5287772
  },
  {
    lng: -41.9230245,
    lat: -22.5289753
  },
  {
    lng: -41.9231597,
    lat: -22.529016
  },
  {
    lng: -41.9232029,
    lat: -22.5290724
  },
  {
    lng: -41.9232419,
    lat: -22.5292108
  },
  {
    lng: -41.923177,
    lat: -22.5292711
  },
  {
    lng: -41.9232353,
    lat: -22.529387
  },
  {
    lng: -41.9233789,
    lat: -22.5293384
  },
  {
    lng: -41.923493,
    lat: -22.5295646
  },
  {
    lng: -41.9237964,
    lat: -22.5300208
  },
  {
    lng: -41.9239828,
    lat: -22.5301226
  },
  {
    lng: -41.9240685,
    lat: -22.5300979
  },
  {
    lng: -41.9241265,
    lat: -22.530159
  },
  {
    lng: -41.9241442,
    lat: -22.5302795
  },
  {
    lng: -41.924254,
    lat: -22.5302789
  },
  {
    lng: -41.9245038,
    lat: -22.5303777
  },
  {
    lng: -41.9244729,
    lat: -22.5304383
  },
  {
    lng: -41.9243514,
    lat: -22.5304592
  },
  {
    lng: -41.924313,
    lat: -22.5305194
  },
  {
    lng: -41.9244138,
    lat: -22.5305818
  },
  {
    lng: -41.9245167,
    lat: -22.5305603
  },
  {
    lng: -41.9245341,
    lat: -22.5306123
  },
  {
    lng: -41.9246548,
    lat: -22.5306767
  },
  {
    lng: -41.9247342,
    lat: -22.5306257
  },
  {
    lng: -41.9248759,
    lat: -22.5305948
  },
  {
    lng: -41.9249362,
    lat: -22.5305098
  },
  {
    lng: -41.9250227,
    lat: -22.5302975
  },
  {
    lng: -41.9252579,
    lat: -22.5302041
  },
  {
    lng: -41.9252566,
    lat: -22.5300431
  },
  {
    lng: -41.9254291,
    lat: -22.5301543
  },
  {
    lng: -41.9255438,
    lat: -22.5301045
  },
  {
    lng: -41.9256243,
    lat: -22.5300613
  },
  {
    lng: -41.9256332,
    lat: -22.5299241
  },
  {
    lng: -41.9256893,
    lat: -22.5298612
  },
  {
    lng: -41.9257883,
    lat: -22.529878
  },
  {
    lng: -41.9258507,
    lat: -22.5297386
  },
  {
    lng: -41.9258504,
    lat: -22.5296892
  },
  {
    lng: -41.9259119,
    lat: -22.5296545
  },
  {
    lng: -41.9260317,
    lat: -22.5296609
  },
  {
    lng: -41.9261003,
    lat: -22.5296914
  },
  {
    lng: -41.9261516,
    lat: -22.5297509
  },
  {
    lng: -41.9260978,
    lat: -22.5297942
  },
  {
    lng: -41.926054,
    lat: -22.5298635
  },
  {
    lng: -41.9260758,
    lat: -22.5299375
  },
  {
    lng: -41.926126,
    lat: -22.5300011
  },
  {
    lng: -41.9261353,
    lat: -22.5300768
  },
  {
    lng: -41.9261877,
    lat: -22.5301846
  },
  {
    lng: -41.9261958,
    lat: -22.5302052
  },
  {
    lng: -41.9262293,
    lat: -22.530222
  },
  {
    lng: -41.9262759,
    lat: -22.5302109
  },
  {
    lng: -41.9263232,
    lat: -22.5301166
  },
  {
    lng: -41.92638,
    lat: -22.530248
  },
  {
    lng: -41.9264156,
    lat: -22.5302647
  },
  {
    lng: -41.9264626,
    lat: -22.5302609
  },
  {
    lng: -41.9265323,
    lat: -22.5302003
  },
  {
    lng: -41.9265604,
    lat: -22.5302288
  },
  {
    lng: -41.9265538,
    lat: -22.5302767
  },
  {
    lng: -41.9266256,
    lat: -22.5303228
  },
  {
    lng: -41.9267121,
    lat: -22.5303825
  },
  {
    lng: -41.9267538,
    lat: -22.5304702
  },
  {
    lng: -41.9267379,
    lat: -22.5305066
  },
  {
    lng: -41.9266702,
    lat: -22.5305045
  },
  {
    lng: -41.9266212,
    lat: -22.5305408
  },
  {
    lng: -41.9266564,
    lat: -22.5306914
  },
  {
    lng: -41.9268867,
    lat: -22.5306933
  },
  {
    lng: -41.9271181,
    lat: -22.5307964
  },
  {
    lng: -41.927312,
    lat: -22.5309171
  },
  {
    lng: -41.9273956,
    lat: -22.5310275
  },
  {
    lng: -41.9276396,
    lat: -22.5310808
  },
  {
    lng: -41.9277456,
    lat: -22.5310662
  },
  {
    lng: -41.9280443,
    lat: -22.5313584
  },
  {
    lng: -41.9283056,
    lat: -22.5313397
  },
  {
    lng: -41.9285724,
    lat: -22.5318563
  },
  {
    lng: -41.9289469,
    lat: -22.5322061
  },
  {
    lng: -41.9298975,
    lat: -22.5335102
  },
  {
    lng: -41.9302958,
    lat: -22.5339983
  },
  {
    lng: -41.9306324,
    lat: -22.5344805
  },
  {
    lng: -41.9311032,
    lat: -22.5350015
  },
  {
    lng: -41.931589,
    lat: -22.5357451
  },
  {
    lng: -41.9318371,
    lat: -22.5361788
  },
  {
    lng: -41.9323074,
    lat: -22.5370845
  },
  {
    lng: -41.9323007,
    lat: -22.5372656
  },
  {
    lng: -41.9324375,
    lat: -22.5372865
  },
  {
    lng: -41.9324921,
    lat: -22.5373219
  },
  {
    lng: -41.9325146,
    lat: -22.5374476
  },
  {
    lng: -41.9325718,
    lat: -22.5374528
  },
  {
    lng: -41.9326576,
    lat: -22.537495
  },
  {
    lng: -41.9327593,
    lat: -22.5374625
  },
  {
    lng: -41.9328862,
    lat: -22.5374353
  },
  {
    lng: -41.9331552,
    lat: -22.5372405
  },
  {
    lng: -41.9333357,
    lat: -22.5372555
  },
  {
    lng: -41.9334842,
    lat: -22.537345
  },
  {
    lng: -41.9337372,
    lat: -22.537309
  },
  {
    lng: -41.9339585,
    lat: -22.5373529
  },
  {
    lng: -41.9340989,
    lat: -22.5373083
  },
  {
    lng: -41.9342775,
    lat: -22.5372057
  },
  {
    lng: -41.9346976,
    lat: -22.5372424
  },
  {
    lng: -41.9353814,
    lat: -22.5370178
  },
  {
    lng: -41.9354564,
    lat: -22.5369071
  },
  {
    lng: -41.9356329,
    lat: -22.5368962
  },
  {
    lng: -41.9357889,
    lat: -22.536703
  },
  {
    lng: -41.9358563,
    lat: -22.5364104
  },
  {
    lng: -41.9359862,
    lat: -22.5362162
  },
  {
    lng: -41.9362438,
    lat: -22.536149
  },
  {
    lng: -41.9365773,
    lat: -22.5361559
  },
  {
    lng: -41.9368711,
    lat: -22.53622
  },
  {
    lng: -41.9371345,
    lat: -22.5363211
  },
  {
    lng: -41.9372961,
    lat: -22.5363207
  },
  {
    lng: -41.9374163,
    lat: -22.5363645
  },
  {
    lng: -41.9374905,
    lat: -22.5364224
  },
  {
    lng: -41.9376291,
    lat: -22.5364273
  },
  {
    lng: -41.9377316,
    lat: -22.5363131
  },
  {
    lng: -41.9378199,
    lat: -22.536104
  },
  {
    lng: -41.9377555,
    lat: -22.535869
  },
  {
    lng: -41.9376931,
    lat: -22.5357601
  },
  {
    lng: -41.9374741,
    lat: -22.5353782
  },
  {
    lng: -41.9373795,
    lat: -22.5348367
  },
  {
    lng: -41.9372916,
    lat: -22.5346428
  },
  {
    lng: -41.9370961,
    lat: -22.5345912
  },
  {
    lng: -41.9369423,
    lat: -22.5343412
  },
  {
    lng: -41.9368091,
    lat: -22.5342418
  },
  {
    lng: -41.9367921,
    lat: -22.5339091
  },
  {
    lng: -41.9367372,
    lat: -22.5338332
  },
  {
    lng: -41.936605,
    lat: -22.5337756
  },
  {
    lng: -41.9363506,
    lat: -22.5337622
  },
  {
    lng: -41.936366,
    lat: -22.5335908
  },
  {
    lng: -41.936482,
    lat: -22.5334484
  },
  {
    lng: -41.9365872,
    lat: -22.5335433
  },
  {
    lng: -41.9366333,
    lat: -22.5336178
  },
  {
    lng: -41.9366793,
    lat: -22.5336459
  },
  {
    lng: -41.9367311,
    lat: -22.5336454
  },
  {
    lng: -41.9368236,
    lat: -22.5335757
  },
  {
    lng: -41.937025,
    lat: -22.5334809
  },
  {
    lng: -41.9372315,
    lat: -22.5333011
  },
  {
    lng: -41.9375154,
    lat: -22.5330099
  },
  {
    lng: -41.9376421,
    lat: -22.5327771
  },
  {
    lng: -41.9378686,
    lat: -22.5325834
  },
  {
    lng: -41.9383693,
    lat: -22.5323281
  },
  {
    lng: -41.9390873,
    lat: -22.5320981
  },
  {
    lng: -41.9394915,
    lat: -22.5320153
  },
  {
    lng: -41.940305,
    lat: -22.531838
  },
  {
    lng: -41.9409603,
    lat: -22.5317679
  },
  {
    lng: -41.9413,
    lat: -22.5316795
  },
  {
    lng: -41.9414618,
    lat: -22.5316525
  },
  {
    lng: -41.9414984,
    lat: -22.5315868
  },
  {
    lng: -41.9416566,
    lat: -22.5315899
  },
  {
    lng: -41.9416848,
    lat: -22.531499
  },
  {
    lng: -41.9416646,
    lat: -22.5314374
  },
  {
    lng: -41.9416276,
    lat: -22.5314165
  },
  {
    lng: -41.9416577,
    lat: -22.5313653
  },
  {
    lng: -41.9418731,
    lat: -22.5313041
  },
  {
    lng: -41.941908,
    lat: -22.5312289
  },
  {
    lng: -41.9417073,
    lat: -22.5312801
  },
  {
    lng: -41.9416815,
    lat: -22.5311748
  },
  {
    lng: -41.9416424,
    lat: -22.5307305
  },
  {
    lng: -41.9419279,
    lat: -22.5304593
  },
  {
    lng: -41.941318,
    lat: -22.5298489
  },
  {
    lng: -41.941126,
    lat: -22.5300266
  },
  {
    lng: -41.9409874,
    lat: -22.5298925
  },
  {
    lng: -41.9410624,
    lat: -22.5294342
  },
  {
    lng: -41.9412226,
    lat: -22.5290813
  },
  {
    lng: -41.9414691,
    lat: -22.5287854
  },
  {
    lng: -41.9418565,
    lat: -22.5285219
  },
  {
    lng: -41.9422672,
    lat: -22.5283203
  },
  {
    lng: -41.9430519,
    lat: -22.5281352
  },
  {
    lng: -41.9435938,
    lat: -22.5280472
  },
  {
    lng: -41.9445378,
    lat: -22.5280626
  },
  {
    lng: -41.9459477,
    lat: -22.5282554
  },
  {
    lng: -41.9468755,
    lat: -22.5285224
  },
  {
    lng: -41.9483905,
    lat: -22.5290005
  },
  {
    lng: -41.9502325,
    lat: -22.5295268
  },
  {
    lng: -41.9511994,
    lat: -22.5301139
  },
  {
    lng: -41.9515329,
    lat: -22.5302409
  },
  {
    lng: -41.9522499,
    lat: -22.5304808
  },
  {
    lng: -41.9531197,
    lat: -22.5309731
  },
  {
    lng: -41.9540392,
    lat: -22.5315138
  },
  {
    lng: -41.9548584,
    lat: -22.5320182
  },
  {
    lng: -41.9548798,
    lat: -22.532059
  },
  {
    lng: -41.9549559,
    lat: -22.5320852
  },
  {
    lng: -41.955004,
    lat: -22.532044
  },
  {
    lng: -41.955052,
    lat: -22.5320029
  },
  {
    lng: -41.9551653,
    lat: -22.5320552
  },
  {
    lng: -41.9552395,
    lat: -22.5321232
  },
  {
    lng: -41.9551938,
    lat: -22.532199
  },
  {
    lng: -41.9552203,
    lat: -22.5322296
  },
  {
    lng: -41.9552619,
    lat: -22.5322358
  },
  {
    lng: -41.9552816,
    lat: -22.5322465
  },
  {
    lng: -41.9553543,
    lat: -22.5322724
  },
  {
    lng: -41.9554217,
    lat: -22.5323009
  },
  {
    lng: -41.9554755,
    lat: -22.5322773
  },
  {
    lng: -41.9555541,
    lat: -22.5322408
  },
  {
    lng: -41.9555888,
    lat: -22.5322435
  },
  {
    lng: -41.9555631,
    lat: -22.5323116
  },
  {
    lng: -41.9555793,
    lat: -22.5323771
  },
  {
    lng: -41.9556294,
    lat: -22.5324388
  },
  {
    lng: -41.9556837,
    lat: -22.5324208
  },
  {
    lng: -41.9557379,
    lat: -22.5324073
  },
  {
    lng: -41.9558226,
    lat: -22.5324193
  },
  {
    lng: -41.9559451,
    lat: -22.5325215
  },
  {
    lng: -41.9560198,
    lat: -22.5325502
  },
  {
    lng: -41.9560434,
    lat: -22.532651
  },
  {
    lng: -41.9560718,
    lat: -22.5326691
  },
  {
    lng: -41.9562066,
    lat: -22.5325542
  },
  {
    lng: -41.9562937,
    lat: -22.5325282
  },
  {
    lng: -41.9563177,
    lat: -22.532469
  },
  {
    lng: -41.9563225,
    lat: -22.5324169
  },
  {
    lng: -41.9563181,
    lat: -22.5323134
  },
  {
    lng: -41.9562357,
    lat: -22.5321537
  },
  {
    lng: -41.9560649,
    lat: -22.5320271
  },
  {
    lng: -41.9562563,
    lat: -22.5317859
  },
  {
    lng: -41.9565515,
    lat: -22.5316116
  },
  {
    lng: -41.9567892,
    lat: -22.5315356
  },
  {
    lng: -41.957114,
    lat: -22.5315009
  },
  {
    lng: -41.957332,
    lat: -22.5315197
  },
  {
    lng: -41.9576644,
    lat: -22.531595
  },
  {
    lng: -41.9578493,
    lat: -22.5316538
  },
  {
    lng: -41.9584269,
    lat: -22.5319668
  },
  {
    lng: -41.9593466,
    lat: -22.5325505
  },
  {
    lng: -41.9602256,
    lat: -22.533242
  },
  {
    lng: -41.9611266,
    lat: -22.5340447
  },
  {
    lng: -41.9627929,
    lat: -22.5354413
  },
  {
    lng: -41.9653765,
    lat: -22.5381874
  },
  {
    lng: -41.9677835,
    lat: -22.5410932
  },
  {
    lng: -41.969283,
    lat: -22.5431057
  },
  {
    lng: -41.97078,
    lat: -22.5453531
  },
  {
    lng: -41.9723027,
    lat: -22.547918
  },
  {
    lng: -41.9746333,
    lat: -22.5521372
  },
  {
    lng: -41.9759634,
    lat: -22.5547817
  },
  {
    lng: -41.9774491,
    lat: -22.5581729
  },
  {
    lng: -41.9791559,
    lat: -22.5624592
  },
  {
    lng: -41.9796285,
    lat: -22.5623336
  },
  {
    lng: -41.9797439,
    lat: -22.562303
  },
  {
    lng: -41.9799035,
    lat: -22.5622607
  },
  {
    lng: -41.981623,
    lat: -22.5618045
  },
  {
    lng: -41.9819136,
    lat: -22.5615218
  },
  {
    lng: -41.9820214,
    lat: -22.5610356
  },
  {
    lng: -41.9821006,
    lat: -22.5607649
  },
  {
    lng: -41.9821279,
    lat: -22.5606714
  },
  {
    lng: -41.9821745,
    lat: -22.5603977
  },
  {
    lng: -41.9822106,
    lat: -22.5601286
  },
  {
    lng: -41.9822179,
    lat: -22.5600316
  },
  {
    lng: -41.9822161,
    lat: -22.5599478
  },
  {
    lng: -41.9822099,
    lat: -22.5596584
  },
  {
    lng: -41.9822048,
    lat: -22.5594233
  },
  {
    lng: -41.982212,
    lat: -22.5590636
  },
  {
    lng: -41.9822194,
    lat: -22.5587177
  },
  {
    lng: -41.9822137,
    lat: -22.5584551
  },
  {
    lng: -41.9822296,
    lat: -22.5582931
  },
  {
    lng: -41.9822519,
    lat: -22.5581501
  },
  {
    lng: -41.9823583,
    lat: -22.5579572
  },
  {
    lng: -41.9824537,
    lat: -22.5578421
  },
  {
    lng: -41.9825463,
    lat: -22.5576882
  },
  {
    lng: -41.9827008,
    lat: -22.5575609
  },
  {
    lng: -41.9828868,
    lat: -22.5573845
  },
  {
    lng: -41.9830077,
    lat: -22.5572925
  },
  {
    lng: -41.9831541,
    lat: -22.5571791
  },
  {
    lng: -41.9832859,
    lat: -22.557005
  },
  {
    lng: -41.9833134,
    lat: -22.5569687
  },
  {
    lng: -41.9834435,
    lat: -22.5567865
  },
  {
    lng: -41.9835123,
    lat: -22.5565225
  },
  {
    lng: -41.9836403,
    lat: -22.5562435
  },
  {
    lng: -41.9836956,
    lat: -22.5560488
  },
  {
    lng: -41.9837361,
    lat: -22.5558545
  },
  {
    lng: -41.9837766,
    lat: -22.5556601
  },
  {
    lng: -41.9838019,
    lat: -22.5554522
  },
  {
    lng: -41.9838418,
    lat: -22.5552302
  },
  {
    lng: -41.9838367,
    lat: -22.5549951
  },
  {
    lng: -41.9838409,
    lat: -22.5548943
  },
  {
    lng: -41.9838466,
    lat: -22.5547599
  },
  {
    lng: -41.9838412,
    lat: -22.554511
  },
  {
    lng: -41.9838218,
    lat: -22.5543039
  },
  {
    lng: -41.9837729,
    lat: -22.5540371
  },
  {
    lng: -41.9837712,
    lat: -22.5540283
  },
  {
    lng: -41.9837653,
    lat: -22.5537518
  },
  {
    lng: -41.9837733,
    lat: -22.5534336
  },
  {
    lng: -41.9837989,
    lat: -22.5532395
  },
  {
    lng: -41.9838531,
    lat: -22.5529895
  },
  {
    lng: -41.9839228,
    lat: -22.5527669
  },
  {
    lng: -41.9839915,
    lat: -22.5525029
  },
  {
    lng: -41.984046,
    lat: -22.5522668
  },
  {
    lng: -41.9840764,
    lat: -22.5521266
  },
  {
    lng: -41.9841002,
    lat: -22.5520168
  },
  {
    lng: -41.9841538,
    lat: -22.5517392
  },
  {
    lng: -41.9841931,
    lat: -22.5514896
  },
  {
    lng: -41.9842764,
    lat: -22.5512114
  },
  {
    lng: -41.9843108,
    lat: -22.5509927
  },
  {
    lng: -41.984341,
    lat: -22.5507539
  },
  {
    lng: -41.9843208,
    lat: -22.5505053
  },
  {
    lng: -41.9843577,
    lat: -22.5502704
  },
  {
    lng: -41.9843601,
    lat: -22.5502556
  },
  {
    lng: -41.9843696,
    lat: -22.5500065
  },
  {
    lng: -41.9844238,
    lat: -22.5497566
  },
  {
    lng: -41.9844768,
    lat: -22.5494513
  },
  {
    lng: -41.9845324,
    lat: -22.5492705
  },
  {
    lng: -41.9846009,
    lat: -22.5489926
  },
  {
    lng: -41.9846417,
    lat: -22.5488121
  },
  {
    lng: -41.9846518,
    lat: -22.5485906
  },
  {
    lng: -41.9847521,
    lat: -22.548409
  },
  {
    lng: -41.9849116,
    lat: -22.5482124
  },
  {
    lng: -41.9850912,
    lat: -22.5479896
  },
  {
    lng: -41.9852738,
    lat: -22.547782
  },
  {
    lng: -41.9861888,
    lat: -22.5467414
  },
  {
    lng: -41.9864374,
    lat: -22.5463751
  },
  {
    lng: -41.9865356,
    lat: -22.5462304
  },
  {
    lng: -41.9866891,
    lat: -22.545871
  },
  {
    lng: -41.9867122,
    lat: -22.5458636
  },
  {
    lng: -41.9867443,
    lat: -22.5456816
  },
  {
    lng: -41.9867617,
    lat: -22.5454915
  },
  {
    lng: -41.9867624,
    lat: -22.5454842
  },
  {
    lng: -41.9867593,
    lat: -22.5453391
  },
  {
    lng: -41.9867627,
    lat: -22.5452398
  },
  {
    lng: -41.9867662,
    lat: -22.5451419
  },
  {
    lng: -41.9867727,
    lat: -22.5449239
  },
  {
    lng: -41.9867796,
    lat: -22.5447267
  },
  {
    lng: -41.9867968,
    lat: -22.5444878
  },
  {
    lng: -41.9868133,
    lat: -22.5442178
  },
  {
    lng: -41.9868294,
    lat: -22.5439271
  },
  {
    lng: -41.9868334,
    lat: -22.5435951
  },
  {
    lng: -41.986875,
    lat: -22.5433349
  },
  {
    lng: -41.9868797,
    lat: -22.5431784
  },
  {
    lng: -41.98688,
    lat: -22.543169
  },
  {
    lng: -41.9869097,
    lat: -22.5429921
  },
  {
    lng: -41.9869278,
    lat: -22.5427947
  },
  {
    lng: -41.9869555,
    lat: -22.5425245
  },
  {
    lng: -41.9869945,
    lat: -22.5422645
  },
  {
    lng: -41.987012,
    lat: -22.5420359
  },
  {
    lng: -41.9870508,
    lat: -22.5417655
  },
  {
    lng: -41.9870671,
    lat: -22.5414852
  },
  {
    lng: -41.9870959,
    lat: -22.5412668
  },
  {
    lng: -41.9871238,
    lat: -22.541007
  },
  {
    lng: -41.9871554,
    lat: -22.5406712
  },
  {
    lng: -41.9872196,
    lat: -22.5402688
  },
  {
    lng: -41.9872466,
    lat: -22.5399675
  },
  {
    lng: -41.9872642,
    lat: -22.5397493
  },
  {
    lng: -41.9873216,
    lat: -22.5393023
  },
  {
    lng: -41.9873586,
    lat: -22.5389489
  },
  {
    lng: -41.9873714,
    lat: -22.5387958
  },
  {
    lng: -41.9873941,
    lat: -22.538523
  },
  {
    lng: -41.9874595,
    lat: -22.5379305
  },
  {
    lng: -41.9874702,
    lat: -22.5378121
  },
  {
    lng: -41.9874712,
    lat: -22.5378004
  },
  {
    lng: -41.9874858,
    lat: -22.5376374
  },
  {
    lng: -41.9874972,
    lat: -22.5375107
  },
  {
    lng: -41.987595,
    lat: -22.5369634
  },
  {
    lng: -41.9876318,
    lat: -22.5365997
  },
  {
    lng: -41.9877113,
    lat: -22.5361418
  },
  {
    lng: -41.9877618,
    lat: -22.5358919
  },
  {
    lng: -41.9878448,
    lat: -22.5356
  },
  {
    lng: -41.9879189,
    lat: -22.5354119
  },
  {
    lng: -41.9879477,
    lat: -22.5351935
  },
  {
    lng: -41.9879635,
    lat: -22.5348924
  },
  {
    lng: -41.9879733,
    lat: -22.5347994
  },
  {
    lng: -41.9880006,
    lat: -22.5345391
  },
  {
    lng: -41.9880597,
    lat: -22.534175
  },
  {
    lng: -41.9881008,
    lat: -22.5340082
  },
  {
    lng: -41.988262,
    lat: -22.5337148
  },
  {
    lng: -41.9883702,
    lat: -22.5335572
  },
  {
    lng: -41.988479,
    lat: -22.5334204
  },
  {
    lng: -41.9885406,
    lat: -22.5333055
  },
  {
    lng: -41.988597,
    lat: -22.5332003
  },
  {
    lng: -41.9887383,
    lat: -22.5330214
  },
  {
    lng: -41.9890089,
    lat: -22.5326222
  },
  {
    lng: -41.9891912,
    lat: -22.5322765
  },
  {
    lng: -41.9892964,
    lat: -22.5319738
  },
  {
    lng: -41.9894037,
    lat: -22.5317747
  },
  {
    lng: -41.9896553,
    lat: -22.5310129
  },
  {
    lng: -41.9896686,
    lat: -22.5309869
  },
  {
    lng: -41.9897898,
    lat: -22.5307502
  },
  {
    lng: -41.9899231,
    lat: -22.5304893
  },
  {
    lng: -41.990067,
    lat: -22.5301592
  },
  {
    lng: -41.9901285,
    lat: -22.5299902
  },
  {
    lng: -41.9902037,
    lat: -22.5295185
  },
  {
    lng: -41.9902135,
    lat: -22.529457
  },
  {
    lng: -41.9902318,
    lat: -22.5290154
  },
  {
    lng: -41.9902356,
    lat: -22.5289276
  },
  {
    lng: -41.9902142,
    lat: -22.5284509
  },
  {
    lng: -41.9899265,
    lat: -22.5280517
  },
  {
    lng: -41.9898436,
    lat: -22.5278354
  },
  {
    lng: -41.9897834,
    lat: -22.5276291
  },
  {
    lng: -41.9897557,
    lat: -22.5273807
  },
  {
    lng: -41.9897838,
    lat: -22.5271312
  },
  {
    lng: -41.9898916,
    lat: -22.5269529
  },
  {
    lng: -41.9899517,
    lat: -22.5266302
  },
  {
    lng: -41.9899912,
    lat: -22.5263909
  },
  {
    lng: -41.9900537,
    lat: -22.5261823
  },
  {
    lng: -41.9901711,
    lat: -22.5259312
  },
  {
    lng: -41.9902548,
    lat: -22.5256703
  },
  {
    lng: -41.9903175,
    lat: -22.5254721
  },
  {
    lng: -41.9903235,
    lat: -22.5252334
  },
  {
    lng: -41.9902527,
    lat: -22.5250584
  },
  {
    lng: -41.9901378,
    lat: -22.5249049
  },
  {
    lng: -41.9899557,
    lat: -22.5247424
  },
  {
    lng: -41.9897776,
    lat: -22.5245819
  },
  {
    lng: -41.9893696,
    lat: -22.5242196
  },
  {
    lng: -41.9893047,
    lat: -22.524162
  },
  {
    lng: -41.989186,
    lat: -22.5240565
  },
  {
    lng: -41.9883352,
    lat: -22.5234127
  },
  {
    lng: -41.9881894,
    lat: -22.5233023
  },
  {
    lng: -41.9878676,
    lat: -22.5230588
  },
  {
    lng: -41.9875893,
    lat: -22.5228305
  },
  {
    lng: -41.9874206,
    lat: -22.5226933
  },
  {
    lng: -41.9871442,
    lat: -22.5224326
  },
  {
    lng: -41.9871239,
    lat: -22.5224031
  },
  {
    lng: -41.9869869,
    lat: -22.5222036
  },
  {
    lng: -41.9868668,
    lat: -22.5219959
  },
  {
    lng: -41.9868187,
    lat: -22.5219127
  },
  {
    lng: -41.9867379,
    lat: -22.5216518
  },
  {
    lng: -41.9869405,
    lat: -22.5199604
  },
  {
    lng: -41.9869764,
    lat: -22.5197627
  },
  {
    lng: -41.9870759,
    lat: -22.5193356
  },
  {
    lng: -41.987272,
    lat: -22.5187649
  },
  {
    lng: -41.9875426,
    lat: -22.5180786
  },
  {
    lng: -41.9876503,
    lat: -22.5179223
  },
  {
    lng: -41.9878262,
    lat: -22.5176846
  },
  {
    lng: -41.9879355,
    lat: -22.517466
  },
  {
    lng: -41.9881161,
    lat: -22.5170952
  },
  {
    lng: -41.9882507,
    lat: -22.5167948
  },
  {
    lng: -41.9883205,
    lat: -22.5166388
  },
  {
    lng: -41.9885288,
    lat: -22.5161451
  },
  {
    lng: -41.9885772,
    lat: -22.5160304
  },
  {
    lng: -41.9887246,
    lat: -22.5156976
  },
  {
    lng: -41.9888244,
    lat: -22.5155217
  },
  {
    lng: -41.9889688,
    lat: -22.5153501
  },
  {
    lng: -41.9890003,
    lat: -22.5153126
  },
  {
    lng: -41.9892475,
    lat: -22.5150416
  },
  {
    lng: -41.9896278,
    lat: -22.51459
  },
  {
    lng: -41.9897751,
    lat: -22.5143191
  },
  {
    lng: -41.989856,
    lat: -22.5141052
  },
  {
    lng: -41.9898658,
    lat: -22.5140722
  },
  {
    lng: -41.9899082,
    lat: -22.5139293
  },
  {
    lng: -41.9899082,
    lat: -22.5137296
  },
  {
    lng: -41.9898512,
    lat: -22.5134682
  },
  {
    lng: -41.9897133,
    lat: -22.5132115
  },
  {
    lng: -41.9897178,
    lat: -22.5131746
  },
  {
    lng: -41.9898109,
    lat: -22.5128222
  },
  {
    lng: -41.989938,
    lat: -22.5123282
  },
  {
    lng: -41.9908428,
    lat: -22.5110783
  },
  {
    lng: -41.9911145,
    lat: -22.5105616
  },
  {
    lng: -41.9912112,
    lat: -22.510214
  },
  {
    lng: -41.9912029,
    lat: -22.509827
  },
  {
    lng: -41.9910771,
    lat: -22.5095158
  },
  {
    lng: -41.990777,
    lat: -22.5089472
  },
  {
    lng: -41.9903064,
    lat: -22.5082617
  },
  {
    lng: -41.9901809,
    lat: -22.507987
  },
  {
    lng: -41.9901692,
    lat: -22.507946
  },
  {
    lng: -41.9900722,
    lat: -22.5076052
  },
  {
    lng: -41.99002,
    lat: -22.5072535
  },
  {
    lng: -41.9899852,
    lat: -22.5067333
  },
  {
    lng: -41.9901483,
    lat: -22.5060111
  },
  {
    lng: -41.9903691,
    lat: -22.5048986
  },
  {
    lng: -41.9904344,
    lat: -22.504549
  },
  {
    lng: -41.9905132,
    lat: -22.5043998
  },
  {
    lng: -41.9905181,
    lat: -22.5043905
  },
  {
    lng: -41.9905215,
    lat: -22.5043841
  },
  {
    lng: -41.9906355,
    lat: -22.5041559
  },
  {
    lng: -41.990637,
    lat: -22.5041539
  },
  {
    lng: -41.9906384,
    lat: -22.5041519
  },
  {
    lng: -41.9906398,
    lat: -22.5041499
  },
  {
    lng: -41.9906413,
    lat: -22.5041478
  },
  {
    lng: -41.9906427,
    lat: -22.5041458
  },
  {
    lng: -41.9906442,
    lat: -22.5041438
  },
  {
    lng: -41.9906456,
    lat: -22.5041418
  },
  {
    lng: -41.9906471,
    lat: -22.5041398
  },
  {
    lng: -41.9906486,
    lat: -22.5041379
  },
  {
    lng: -41.99065,
    lat: -22.5041359
  },
  {
    lng: -41.9906515,
    lat: -22.5041339
  },
  {
    lng: -41.990653,
    lat: -22.5041319
  },
  {
    lng: -41.9906545,
    lat: -22.5041299
  },
  {
    lng: -41.990656,
    lat: -22.504128
  },
  {
    lng: -41.9906575,
    lat: -22.504126
  },
  {
    lng: -41.990659,
    lat: -22.504124
  },
  {
    lng: -41.9906605,
    lat: -22.5041221
  },
  {
    lng: -41.990662,
    lat: -22.5041201
  },
  {
    lng: -41.9906636,
    lat: -22.5041182
  },
  {
    lng: -41.9906651,
    lat: -22.5041162
  },
  {
    lng: -41.9906666,
    lat: -22.5041143
  },
  {
    lng: -41.9906682,
    lat: -22.5041123
  },
  {
    lng: -41.9906697,
    lat: -22.5041104
  },
  {
    lng: -41.9906713,
    lat: -22.5041085
  },
  {
    lng: -41.9906728,
    lat: -22.5041066
  },
  {
    lng: -41.9906744,
    lat: -22.5041046
  },
  {
    lng: -41.9906759,
    lat: -22.5041027
  },
  {
    lng: -41.9906775,
    lat: -22.5041008
  },
  {
    lng: -41.9906791,
    lat: -22.5040989
  },
  {
    lng: -41.9906807,
    lat: -22.504097
  },
  {
    lng: -41.9906823,
    lat: -22.5040951
  },
  {
    lng: -41.9906838,
    lat: -22.5040932
  },
  {
    lng: -41.9906854,
    lat: -22.5040913
  },
  {
    lng: -41.990687,
    lat: -22.5040894
  },
  {
    lng: -41.9906886,
    lat: -22.5040876
  },
  {
    lng: -41.9906903,
    lat: -22.5040857
  },
  {
    lng: -41.9906919,
    lat: -22.5040838
  },
  {
    lng: -41.9906935,
    lat: -22.5040819
  },
  {
    lng: -41.9906951,
    lat: -22.5040801
  },
  {
    lng: -41.9906968,
    lat: -22.5040782
  },
  {
    lng: -41.9906984,
    lat: -22.5040764
  },
  {
    lng: -41.9907,
    lat: -22.5040745
  },
  {
    lng: -41.9907017,
    lat: -22.5040727
  },
  {
    lng: -41.9907033,
    lat: -22.5040708
  },
  {
    lng: -41.990705,
    lat: -22.504069
  },
  {
    lng: -41.9907067,
    lat: -22.5040672
  },
  {
    lng: -41.9907083,
    lat: -22.5040653
  },
  {
    lng: -41.99071,
    lat: -22.5040635
  },
  {
    lng: -41.9907117,
    lat: -22.5040617
  },
  {
    lng: -41.9907134,
    lat: -22.5040599
  },
  {
    lng: -41.9907151,
    lat: -22.5040581
  },
  {
    lng: -41.9907167,
    lat: -22.5040563
  },
  {
    lng: -41.9907184,
    lat: -22.5040545
  },
  {
    lng: -41.9907201,
    lat: -22.5040527
  },
  {
    lng: -41.9907219,
    lat: -22.5040509
  },
  {
    lng: -41.9907236,
    lat: -22.5040491
  },
  {
    lng: -41.9907253,
    lat: -22.5040473
  },
  {
    lng: -41.990727,
    lat: -22.5040455
  },
  {
    lng: -41.9907287,
    lat: -22.5040438
  },
  {
    lng: -41.9907305,
    lat: -22.504042
  },
  {
    lng: -41.9907322,
    lat: -22.5040402
  },
  {
    lng: -41.9907339,
    lat: -22.5040385
  },
  {
    lng: -41.9907357,
    lat: -22.5040367
  },
  {
    lng: -41.9907374,
    lat: -22.504035
  },
  {
    lng: -41.9907392,
    lat: -22.5040332
  },
  {
    lng: -41.990741,
    lat: -22.5040315
  },
  {
    lng: -41.9907427,
    lat: -22.5040297
  },
  {
    lng: -41.9907445,
    lat: -22.504028
  },
  {
    lng: -41.9907463,
    lat: -22.5040263
  },
  {
    lng: -41.990748,
    lat: -22.5040246
  },
  {
    lng: -41.9907498,
    lat: -22.5040229
  },
  {
    lng: -41.9907516,
    lat: -22.5040211
  },
  {
    lng: -41.9907534,
    lat: -22.5040194
  },
  {
    lng: -41.9907552,
    lat: -22.5040177
  },
  {
    lng: -41.990757,
    lat: -22.504016
  },
  {
    lng: -41.9907588,
    lat: -22.5040144
  },
  {
    lng: -41.9907606,
    lat: -22.5040127
  },
  {
    lng: -41.9907624,
    lat: -22.504011
  },
  {
    lng: -41.9907643,
    lat: -22.5040093
  },
  {
    lng: -41.9907661,
    lat: -22.5040076
  },
  {
    lng: -41.9907679,
    lat: -22.504006
  },
  {
    lng: -41.9907698,
    lat: -22.5040043
  },
  {
    lng: -41.9907716,
    lat: -22.5040027
  },
  {
    lng: -41.9907734,
    lat: -22.504001
  },
  {
    lng: -41.9907753,
    lat: -22.5039994
  },
  {
    lng: -41.9907771,
    lat: -22.5039977
  },
  {
    lng: -41.990779,
    lat: -22.5039961
  },
  {
    lng: -41.9907809,
    lat: -22.5039945
  },
  {
    lng: -41.9907827,
    lat: -22.5039928
  },
  {
    lng: -41.9907846,
    lat: -22.5039912
  },
  {
    lng: -41.9907865,
    lat: -22.5039896
  },
  {
    lng: -41.9907883,
    lat: -22.503988
  },
  {
    lng: -41.9907902,
    lat: -22.5039864
  },
  {
    lng: -41.9907921,
    lat: -22.5039848
  },
  {
    lng: -41.990794,
    lat: -22.5039832
  },
  {
    lng: -41.9907959,
    lat: -22.5039816
  },
  {
    lng: -41.9907978,
    lat: -22.50398
  },
  {
    lng: -41.9907997,
    lat: -22.5039785
  },
  {
    lng: -41.9908016,
    lat: -22.5039769
  },
  {
    lng: -41.9908035,
    lat: -22.5039753
  },
  {
    lng: -41.9908055,
    lat: -22.5039737
  },
  {
    lng: -41.9908074,
    lat: -22.5039722
  },
  {
    lng: -41.9908093,
    lat: -22.5039706
  },
  {
    lng: -41.9908112,
    lat: -22.5039691
  },
  {
    lng: -41.9908132,
    lat: -22.5039676
  },
  {
    lng: -41.9908151,
    lat: -22.503966
  },
  {
    lng: -41.9908171,
    lat: -22.5039645
  },
  {
    lng: -41.990819,
    lat: -22.503963
  },
  {
    lng: -41.990821,
    lat: -22.5039614
  },
  {
    lng: -41.9908229,
    lat: -22.5039599
  },
  {
    lng: -41.9908249,
    lat: -22.5039584
  },
  {
    lng: -41.9908268,
    lat: -22.5039569
  },
  {
    lng: -41.9908288,
    lat: -22.5039554
  },
  {
    lng: -41.9908308,
    lat: -22.5039539
  },
  {
    lng: -41.9908328,
    lat: -22.5039524
  },
  {
    lng: -41.9908347,
    lat: -22.5039509
  },
  {
    lng: -41.9908367,
    lat: -22.5039495
  },
  {
    lng: -41.9908387,
    lat: -22.503948
  },
  {
    lng: -41.9908407,
    lat: -22.5039465
  },
  {
    lng: -41.9908427,
    lat: -22.5039451
  },
  {
    lng: -41.9908447,
    lat: -22.5039436
  },
  {
    lng: -41.9908467,
    lat: -22.5039422
  },
  {
    lng: -41.9908487,
    lat: -22.5039407
  },
  {
    lng: -41.9908507,
    lat: -22.5039393
  },
  {
    lng: -41.9908527,
    lat: -22.5039378
  },
  {
    lng: -41.9908548,
    lat: -22.5039364
  },
  {
    lng: -41.9908568,
    lat: -22.503935
  },
  {
    lng: -41.9908588,
    lat: -22.5039336
  },
  {
    lng: -41.9908608,
    lat: -22.5039322
  },
  {
    lng: -41.9908629,
    lat: -22.5039308
  },
  {
    lng: -41.9908649,
    lat: -22.5039294
  },
  {
    lng: -41.990867,
    lat: -22.503928
  },
  {
    lng: -41.990869,
    lat: -22.5039266
  },
  {
    lng: -41.9908711,
    lat: -22.5039252
  },
  {
    lng: -41.9908731,
    lat: -22.5039238
  },
  {
    lng: -41.9908752,
    lat: -22.5039224
  },
  {
    lng: -41.9908772,
    lat: -22.5039211
  },
  {
    lng: -41.9908793,
    lat: -22.5039197
  },
  {
    lng: -41.9908814,
    lat: -22.5039184
  },
  {
    lng: -41.9908834,
    lat: -22.503917
  },
  {
    lng: -41.9908855,
    lat: -22.5039157
  },
  {
    lng: -41.9908876,
    lat: -22.5039143
  },
  {
    lng: -41.9908897,
    lat: -22.503913
  },
  {
    lng: -41.9908918,
    lat: -22.5039117
  },
  {
    lng: -41.9908939,
    lat: -22.5039103
  },
  {
    lng: -41.990896,
    lat: -22.503909
  },
  {
    lng: -41.9908981,
    lat: -22.5039077
  },
  {
    lng: -41.9909002,
    lat: -22.5039064
  },
  {
    lng: -41.9909023,
    lat: -22.5039051
  },
  {
    lng: -41.9909044,
    lat: -22.5039038
  },
  {
    lng: -41.9909065,
    lat: -22.5039025
  },
  {
    lng: -41.9909086,
    lat: -22.5039013
  },
  {
    lng: -41.9909107,
    lat: -22.5039
  },
  {
    lng: -41.9909129,
    lat: -22.5038987
  },
  {
    lng: -41.990915,
    lat: -22.5038974
  },
  {
    lng: -41.9909171,
    lat: -22.5038962
  },
  {
    lng: -41.9909192,
    lat: -22.5038949
  },
  {
    lng: -41.9909214,
    lat: -22.5038937
  },
  {
    lng: -41.9909235,
    lat: -22.5038924
  },
  {
    lng: -41.9909257,
    lat: -22.5038912
  },
  {
    lng: -41.9909278,
    lat: -22.50389
  },
  {
    lng: -41.99093,
    lat: -22.5038888
  },
  {
    lng: -41.9909321,
    lat: -22.5038875
  },
  {
    lng: -41.9909343,
    lat: -22.5038863
  },
  {
    lng: -41.9909364,
    lat: -22.5038851
  },
  {
    lng: -41.9909386,
    lat: -22.5038839
  },
  {
    lng: -41.9909408,
    lat: -22.5038827
  },
  {
    lng: -41.9909429,
    lat: -22.5038815
  },
  {
    lng: -41.9909451,
    lat: -22.5038804
  },
  {
    lng: -41.9909473,
    lat: -22.5038792
  },
  {
    lng: -41.9909495,
    lat: -22.503878
  },
  {
    lng: -41.9909517,
    lat: -22.5038769
  },
  {
    lng: -41.9909538,
    lat: -22.5038757
  },
  {
    lng: -41.990956,
    lat: -22.5038745
  },
  {
    lng: -41.9909582,
    lat: -22.5038734
  },
  {
    lng: -41.9909604,
    lat: -22.5038723
  },
  {
    lng: -41.9909626,
    lat: -22.5038711
  },
  {
    lng: -41.9909648,
    lat: -22.50387
  },
  {
    lng: -41.990967,
    lat: -22.5038689
  },
  {
    lng: -41.9909692,
    lat: -22.5038678
  },
  {
    lng: -41.9909715,
    lat: -22.5038667
  },
  {
    lng: -41.9909737,
    lat: -22.5038655
  },
  {
    lng: -41.9909759,
    lat: -22.5038644
  },
  {
    lng: -41.9909781,
    lat: -22.5038634
  },
  {
    lng: -41.9909803,
    lat: -22.5038623
  },
  {
    lng: -41.9909826,
    lat: -22.5038612
  },
  {
    lng: -41.9909847,
    lat: -22.5038601
  },
  {
    lng: -41.9909869,
    lat: -22.5038589
  },
  {
    lng: -41.9909891,
    lat: -22.5038578
  },
  {
    lng: -41.9909912,
    lat: -22.5038566
  },
  {
    lng: -41.9909934,
    lat: -22.5038555
  },
  {
    lng: -41.9909956,
    lat: -22.5038543
  },
  {
    lng: -41.9909977,
    lat: -22.5038532
  },
  {
    lng: -41.9909999,
    lat: -22.503852
  },
  {
    lng: -41.991002,
    lat: -22.5038508
  },
  {
    lng: -41.9910042,
    lat: -22.5038497
  },
  {
    lng: -41.9910063,
    lat: -22.5038485
  },
  {
    lng: -41.9910085,
    lat: -22.5038473
  },
  {
    lng: -41.9910106,
    lat: -22.5038461
  },
  {
    lng: -41.9910128,
    lat: -22.5038449
  },
  {
    lng: -41.9910149,
    lat: -22.5038437
  },
  {
    lng: -41.991017,
    lat: -22.5038424
  },
  {
    lng: -41.9910191,
    lat: -22.5038412
  },
  {
    lng: -41.9910213,
    lat: -22.50384
  },
  {
    lng: -41.9910234,
    lat: -22.5038388
  },
  {
    lng: -41.9910255,
    lat: -22.5038375
  },
  {
    lng: -41.9910276,
    lat: -22.5038363
  },
  {
    lng: -41.9910297,
    lat: -22.503835
  },
  {
    lng: -41.9910318,
    lat: -22.5038338
  },
  {
    lng: -41.9910339,
    lat: -22.5038325
  },
  {
    lng: -41.991036,
    lat: -22.5038312
  },
  {
    lng: -41.9910381,
    lat: -22.50383
  },
  {
    lng: -41.9910402,
    lat: -22.5038287
  },
  {
    lng: -41.9910423,
    lat: -22.5038274
  },
  {
    lng: -41.9910444,
    lat: -22.5038261
  },
  {
    lng: -41.9910465,
    lat: -22.5038248
  },
  {
    lng: -41.9910485,
    lat: -22.5038235
  },
  {
    lng: -41.9910506,
    lat: -22.5038222
  },
  {
    lng: -41.9910527,
    lat: -22.5038209
  },
  {
    lng: -41.9910547,
    lat: -22.5038195
  },
  {
    lng: -41.9910568,
    lat: -22.5038182
  },
  {
    lng: -41.9910589,
    lat: -22.5038169
  },
  {
    lng: -41.9910609,
    lat: -22.5038156
  },
  {
    lng: -41.991063,
    lat: -22.5038142
  },
  {
    lng: -41.991065,
    lat: -22.5038129
  },
  {
    lng: -41.9910671,
    lat: -22.5038115
  },
  {
    lng: -41.9910691,
    lat: -22.5038101
  },
  {
    lng: -41.9910711,
    lat: -22.5038088
  },
  {
    lng: -41.9910732,
    lat: -22.5038074
  },
  {
    lng: -41.9910752,
    lat: -22.503806
  },
  {
    lng: -41.9910772,
    lat: -22.5038046
  },
  {
    lng: -41.9910792,
    lat: -22.5038033
  },
  {
    lng: -41.9910813,
    lat: -22.5038019
  },
  {
    lng: -41.9910833,
    lat: -22.5038005
  },
  {
    lng: -41.9910853,
    lat: -22.5037991
  },
  {
    lng: -41.9910873,
    lat: -22.5037976
  },
  {
    lng: -41.9910893,
    lat: -22.5037962
  },
  {
    lng: -41.9910913,
    lat: -22.5037948
  },
  {
    lng: -41.9910933,
    lat: -22.5037934
  },
  {
    lng: -41.9910953,
    lat: -22.5037919
  },
  {
    lng: -41.9910973,
    lat: -22.5037905
  },
  {
    lng: -41.9910992,
    lat: -22.5037891
  },
  {
    lng: -41.9911012,
    lat: -22.5037876
  },
  {
    lng: -41.9911032,
    lat: -22.5037862
  },
  {
    lng: -41.9911052,
    lat: -22.5037847
  },
  {
    lng: -41.9911071,
    lat: -22.5037832
  },
  {
    lng: -41.9911091,
    lat: -22.5037818
  },
  {
    lng: -41.991111,
    lat: -22.5037803
  },
  {
    lng: -41.991113,
    lat: -22.5037788
  },
  {
    lng: -41.9911149,
    lat: -22.5037773
  },
  {
    lng: -41.9911169,
    lat: -22.5037758
  },
  {
    lng: -41.9911188,
    lat: -22.5037743
  },
  {
    lng: -41.9911208,
    lat: -22.5037728
  },
  {
    lng: -41.9911227,
    lat: -22.5037713
  },
  {
    lng: -41.9911246,
    lat: -22.5037698
  },
  {
    lng: -41.9911265,
    lat: -22.5037683
  },
  {
    lng: -41.9911285,
    lat: -22.5037667
  },
  {
    lng: -41.9911304,
    lat: -22.5037652
  },
  {
    lng: -41.9911323,
    lat: -22.5037637
  },
  {
    lng: -41.9911342,
    lat: -22.5037621
  },
  {
    lng: -41.9911361,
    lat: -22.5037606
  },
  {
    lng: -41.991138,
    lat: -22.503759
  },
  {
    lng: -41.9911399,
    lat: -22.5037575
  },
  {
    lng: -41.9911418,
    lat: -22.5037559
  },
  {
    lng: -41.9911437,
    lat: -22.5037543
  },
  {
    lng: -41.9911456,
    lat: -22.5037528
  },
  {
    lng: -41.9911474,
    lat: -22.5037512
  },
  {
    lng: -41.9911493,
    lat: -22.5037496
  },
  {
    lng: -41.9911512,
    lat: -22.503748
  },
  {
    lng: -41.991153,
    lat: -22.5037464
  },
  {
    lng: -41.9911549,
    lat: -22.5037448
  },
  {
    lng: -41.9911567,
    lat: -22.5037432
  },
  {
    lng: -41.9911586,
    lat: -22.5037416
  },
  {
    lng: -41.9911604,
    lat: -22.50374
  },
  {
    lng: -41.9911623,
    lat: -22.5037384
  },
  {
    lng: -41.9911641,
    lat: -22.5037368
  },
  {
    lng: -41.991166,
    lat: -22.5037351
  },
  {
    lng: -41.9911678,
    lat: -22.5037335
  },
  {
    lng: -41.9911696,
    lat: -22.5037319
  },
  {
    lng: -41.9911714,
    lat: -22.5037302
  },
  {
    lng: -41.9911732,
    lat: -22.5037286
  },
  {
    lng: -41.991175,
    lat: -22.5037269
  },
  {
    lng: -41.9911769,
    lat: -22.5037253
  },
  {
    lng: -41.9911787,
    lat: -22.5037236
  },
  {
    lng: -41.9911804,
    lat: -22.5037219
  },
  {
    lng: -41.9911822,
    lat: -22.5037202
  },
  {
    lng: -41.991184,
    lat: -22.5037186
  },
  {
    lng: -41.9911858,
    lat: -22.5037169
  },
  {
    lng: -41.9911876,
    lat: -22.5037152
  },
  {
    lng: -41.9911894,
    lat: -22.5037135
  },
  {
    lng: -41.9911911,
    lat: -22.5037118
  },
  {
    lng: -41.9911929,
    lat: -22.5037101
  },
  {
    lng: -41.9911946,
    lat: -22.5037084
  },
  {
    lng: -41.9911964,
    lat: -22.5037067
  },
  {
    lng: -41.9911981,
    lat: -22.503705
  },
  {
    lng: -41.9911999,
    lat: -22.5037032
  },
  {
    lng: -41.9912016,
    lat: -22.5037015
  },
  {
    lng: -41.9912034,
    lat: -22.5036998
  },
  {
    lng: -41.9912051,
    lat: -22.503698
  },
  {
    lng: -41.9912068,
    lat: -22.5036963
  },
  {
    lng: -41.9912085,
    lat: -22.5036945
  },
  {
    lng: -41.9912103,
    lat: -22.5036928
  },
  {
    lng: -41.991212,
    lat: -22.503691
  },
  {
    lng: -41.9912137,
    lat: -22.5036893
  },
  {
    lng: -41.9912154,
    lat: -22.5036875
  },
  {
    lng: -41.9912171,
    lat: -22.5036857
  },
  {
    lng: -41.9912188,
    lat: -22.503684
  },
  {
    lng: -41.9912204,
    lat: -22.5036822
  },
  {
    lng: -41.9912221,
    lat: -22.5036804
  },
  {
    lng: -41.9912238,
    lat: -22.5036786
  },
  {
    lng: -41.9912255,
    lat: -22.5036768
  },
  {
    lng: -41.9912271,
    lat: -22.503675
  },
  {
    lng: -41.9912288,
    lat: -22.5036732
  },
  {
    lng: -41.9912305,
    lat: -22.5036714
  },
  {
    lng: -41.9912321,
    lat: -22.5036696
  },
  {
    lng: -41.9912338,
    lat: -22.5036678
  },
  {
    lng: -41.9912354,
    lat: -22.5036659
  },
  {
    lng: -41.991237,
    lat: -22.5036641
  },
  {
    lng: -41.9912387,
    lat: -22.5036623
  },
  {
    lng: -41.9912403,
    lat: -22.5036604
  },
  {
    lng: -41.9912419,
    lat: -22.5036586
  },
  {
    lng: -41.9912435,
    lat: -22.5036568
  },
  {
    lng: -41.9912451,
    lat: -22.5036549
  },
  {
    lng: -41.9912467,
    lat: -22.5036531
  },
  {
    lng: -41.9912483,
    lat: -22.5036512
  },
  {
    lng: -41.9912499,
    lat: -22.5036493
  },
  {
    lng: -41.9912515,
    lat: -22.5036475
  },
  {
    lng: -41.9912531,
    lat: -22.5036456
  },
  {
    lng: -41.9912547,
    lat: -22.5036437
  },
  {
    lng: -41.9912563,
    lat: -22.5036418
  },
  {
    lng: -41.9912578,
    lat: -22.50364
  },
  {
    lng: -41.9912594,
    lat: -22.5036381
  },
  {
    lng: -41.991261,
    lat: -22.5036362
  },
  {
    lng: -41.9912625,
    lat: -22.5036343
  },
  {
    lng: -41.9912641,
    lat: -22.5036324
  },
  {
    lng: -41.9912656,
    lat: -22.5036305
  },
  {
    lng: -41.9912671,
    lat: -22.5036286
  },
  {
    lng: -41.9912687,
    lat: -22.5036267
  },
  {
    lng: -41.9912702,
    lat: -22.5036247
  },
  {
    lng: -41.9912717,
    lat: -22.5036228
  },
  {
    lng: -41.9912732,
    lat: -22.5036209
  },
  {
    lng: -41.9912747,
    lat: -22.503619
  },
  {
    lng: -41.9912762,
    lat: -22.503617
  },
  {
    lng: -41.9912777,
    lat: -22.5036151
  },
  {
    lng: -41.9912792,
    lat: -22.5036131
  },
  {
    lng: -41.9912807,
    lat: -22.5036112
  },
  {
    lng: -41.9912822,
    lat: -22.5036092
  },
  {
    lng: -41.9912837,
    lat: -22.5036073
  },
  {
    lng: -41.9912852,
    lat: -22.5036053
  },
  {
    lng: -41.9912866,
    lat: -22.5036034
  },
  {
    lng: -41.9912881,
    lat: -22.5036014
  },
  {
    lng: -41.9912896,
    lat: -22.5035994
  },
  {
    lng: -41.991291,
    lat: -22.5035974
  },
  {
    lng: -41.9912925,
    lat: -22.5035955
  },
  {
    lng: -41.9912939,
    lat: -22.5035935
  },
  {
    lng: -41.9912953,
    lat: -22.5035915
  },
  {
    lng: -41.9912968,
    lat: -22.5035895
  },
  {
    lng: -41.9912982,
    lat: -22.5035875
  },
  {
    lng: -41.9912996,
    lat: -22.5035855
  },
  {
    lng: -41.991301,
    lat: -22.5035835
  },
  {
    lng: -41.9913024,
    lat: -22.5035815
  },
  {
    lng: -41.9913038,
    lat: -22.5035795
  },
  {
    lng: -41.9913052,
    lat: -22.5035775
  },
  {
    lng: -41.9913066,
    lat: -22.5035754
  },
  {
    lng: -41.991308,
    lat: -22.5035734
  },
  {
    lng: -41.9913094,
    lat: -22.5035714
  },
  {
    lng: -41.9913107,
    lat: -22.5035694
  },
  {
    lng: -41.9913121,
    lat: -22.5035673
  },
  {
    lng: -41.9913135,
    lat: -22.5035653
  },
  {
    lng: -41.9913148,
    lat: -22.5035632
  },
  {
    lng: -41.9913162,
    lat: -22.5035612
  },
  {
    lng: -41.9913175,
    lat: -22.5035591
  },
  {
    lng: -41.9913189,
    lat: -22.5035571
  },
  {
    lng: -41.9913202,
    lat: -22.503555
  },
  {
    lng: -41.9913215,
    lat: -22.503553
  },
  {
    lng: -41.9913229,
    lat: -22.5035509
  },
  {
    lng: -41.9913242,
    lat: -22.5035488
  },
  {
    lng: -41.9913255,
    lat: -22.5035468
  },
  {
    lng: -41.9913268,
    lat: -22.5035447
  },
  {
    lng: -41.9913281,
    lat: -22.5035426
  },
  {
    lng: -41.9913294,
    lat: -22.5035405
  },
  {
    lng: -41.9913307,
    lat: -22.5035384
  },
  {
    lng: -41.991332,
    lat: -22.5035363
  },
  {
    lng: -41.9913332,
    lat: -22.5035343
  },
  {
    lng: -41.9913345,
    lat: -22.5035322
  },
  {
    lng: -41.9913358,
    lat: -22.5035301
  },
  {
    lng: -41.991337,
    lat: -22.503528
  },
  {
    lng: -41.9913383,
    lat: -22.5035258
  },
  {
    lng: -41.9913395,
    lat: -22.5035237
  },
  {
    lng: -41.9913408,
    lat: -22.5035216
  },
  {
    lng: -41.991342,
    lat: -22.5035195
  },
  {
    lng: -41.9913432,
    lat: -22.5035174
  },
  {
    lng: -41.9913445,
    lat: -22.5035153
  },
  {
    lng: -41.9913457,
    lat: -22.5035131
  },
  {
    lng: -41.9913469,
    lat: -22.503511
  },
  {
    lng: -41.9913481,
    lat: -22.5035089
  },
  {
    lng: -41.9913493,
    lat: -22.5035067
  },
  {
    lng: -41.9913505,
    lat: -22.5035046
  },
  {
    lng: -41.9913517,
    lat: -22.5035024
  },
  {
    lng: -41.9913529,
    lat: -22.5035003
  },
  {
    lng: -41.991354,
    lat: -22.5034981
  },
  {
    lng: -41.9913552,
    lat: -22.503496
  },
  {
    lng: -41.9913564,
    lat: -22.5034938
  },
  {
    lng: -41.9913575,
    lat: -22.5034917
  },
  {
    lng: -41.9913587,
    lat: -22.5034895
  },
  {
    lng: -41.9913598,
    lat: -22.5034873
  },
  {
    lng: -41.991361,
    lat: -22.5034852
  },
  {
    lng: -41.9913621,
    lat: -22.503483
  },
  {
    lng: -41.9913632,
    lat: -22.5034808
  },
  {
    lng: -41.9913644,
    lat: -22.5034786
  },
  {
    lng: -41.9913655,
    lat: -22.5034765
  },
  {
    lng: -41.9913666,
    lat: -22.5034743
  },
  {
    lng: -41.9913677,
    lat: -22.5034721
  },
  {
    lng: -41.9913688,
    lat: -22.5034699
  },
  {
    lng: -41.9913699,
    lat: -22.5034677
  },
  {
    lng: -41.991371,
    lat: -22.5034655
  },
  {
    lng: -41.991372,
    lat: -22.5034633
  },
  {
    lng: -41.9913731,
    lat: -22.5034611
  },
  {
    lng: -41.9913742,
    lat: -22.5034589
  },
  {
    lng: -41.9913752,
    lat: -22.5034567
  },
  {
    lng: -41.9913763,
    lat: -22.5034545
  },
  {
    lng: -41.9913774,
    lat: -22.5034522
  },
  {
    lng: -41.9913784,
    lat: -22.50345
  },
  {
    lng: -41.9913794,
    lat: -22.5034478
  },
  {
    lng: -41.9913805,
    lat: -22.5034456
  },
  {
    lng: -41.9913815,
    lat: -22.5034433
  },
  {
    lng: -41.9913825,
    lat: -22.5034411
  },
  {
    lng: -41.9913835,
    lat: -22.5034389
  },
  {
    lng: -41.9913845,
    lat: -22.5034366
  },
  {
    lng: -41.9913855,
    lat: -22.5034344
  },
  {
    lng: -41.9913865,
    lat: -22.5034322
  },
  {
    lng: -41.9913875,
    lat: -22.5034299
  },
  {
    lng: -41.9913885,
    lat: -22.5034277
  },
  {
    lng: -41.9913895,
    lat: -22.5034254
  },
  {
    lng: -41.9913904,
    lat: -22.5034232
  },
  {
    lng: -41.9913914,
    lat: -22.5034209
  },
  {
    lng: -41.9913924,
    lat: -22.5034187
  },
  {
    lng: -41.9913933,
    lat: -22.5034164
  },
  {
    lng: -41.9913942,
    lat: -22.5034141
  },
  {
    lng: -41.9913952,
    lat: -22.5034119
  },
  {
    lng: -41.9913961,
    lat: -22.5034096
  },
  {
    lng: -41.9913967,
    lat: -22.5034073
  },
  {
    lng: -41.9913972,
    lat: -22.5034051
  },
  {
    lng: -41.9913978,
    lat: -22.5034028
  },
  {
    lng: -41.9913983,
    lat: -22.5034006
  },
  {
    lng: -41.9913989,
    lat: -22.5033983
  },
  {
    lng: -41.9913994,
    lat: -22.5033961
  },
  {
    lng: -41.9913999,
    lat: -22.5033938
  },
  {
    lng: -41.9914005,
    lat: -22.5033915
  },
  {
    lng: -41.991401,
    lat: -22.5033893
  },
  {
    lng: -41.9914015,
    lat: -22.503387
  },
  {
    lng: -41.991402,
    lat: -22.5033847
  },
  {
    lng: -41.9914025,
    lat: -22.5033824
  },
  {
    lng: -41.991403,
    lat: -22.5033802
  },
  {
    lng: -41.9914034,
    lat: -22.5033779
  },
  {
    lng: -41.9914039,
    lat: -22.5033756
  },
  {
    lng: -41.9914044,
    lat: -22.5033733
  },
  {
    lng: -41.9914048,
    lat: -22.5033711
  },
  {
    lng: -41.9914053,
    lat: -22.5033688
  },
  {
    lng: -41.9914057,
    lat: -22.5033665
  },
  {
    lng: -41.9914062,
    lat: -22.5033642
  },
  {
    lng: -41.9914066,
    lat: -22.5033619
  },
  {
    lng: -41.991407,
    lat: -22.5033597
  },
  {
    lng: -41.9914075,
    lat: -22.5033574
  },
  {
    lng: -41.9914079,
    lat: -22.5033551
  },
  {
    lng: -41.9914083,
    lat: -22.5033528
  },
  {
    lng: -41.9914087,
    lat: -22.5033505
  },
  {
    lng: -41.9914091,
    lat: -22.5033482
  },
  {
    lng: -41.9914095,
    lat: -22.5033459
  },
  {
    lng: -41.9914098,
    lat: -22.5033436
  },
  {
    lng: -41.9914102,
    lat: -22.5033413
  },
  {
    lng: -41.9914106,
    lat: -22.503339
  },
  {
    lng: -41.9914109,
    lat: -22.5033367
  },
  {
    lng: -41.9914113,
    lat: -22.5033344
  },
  {
    lng: -41.9914116,
    lat: -22.5033321
  },
  {
    lng: -41.991412,
    lat: -22.5033298
  },
  {
    lng: -41.9914123,
    lat: -22.5033275
  },
  {
    lng: -41.9914126,
    lat: -22.5033252
  },
  {
    lng: -41.991413,
    lat: -22.5033229
  },
  {
    lng: -41.9914133,
    lat: -22.5033206
  },
  {
    lng: -41.9914136,
    lat: -22.5033183
  },
  {
    lng: -41.9914139,
    lat: -22.503316
  },
  {
    lng: -41.9914142,
    lat: -22.5033137
  },
  {
    lng: -41.9914145,
    lat: -22.5033114
  },
  {
    lng: -41.9914147,
    lat: -22.5033091
  },
  {
    lng: -41.991415,
    lat: -22.5033068
  },
  {
    lng: -41.9914153,
    lat: -22.5033045
  },
  {
    lng: -41.9914155,
    lat: -22.5033022
  },
  {
    lng: -41.9914158,
    lat: -22.5032999
  },
  {
    lng: -41.991416,
    lat: -22.5032976
  },
  {
    lng: -41.9914163,
    lat: -22.5032953
  },
  {
    lng: -41.9914165,
    lat: -22.5032929
  },
  {
    lng: -41.9914167,
    lat: -22.5032906
  },
  {
    lng: -41.991417,
    lat: -22.5032883
  },
  {
    lng: -41.9914172,
    lat: -22.503286
  },
  {
    lng: -41.9914174,
    lat: -22.5032837
  },
  {
    lng: -41.9914176,
    lat: -22.5032814
  },
  {
    lng: -41.9914178,
    lat: -22.5032791
  },
  {
    lng: -41.9914179,
    lat: -22.5032767
  },
  {
    lng: -41.9914181,
    lat: -22.5032744
  },
  {
    lng: -41.9914183,
    lat: -22.5032721
  },
  {
    lng: -41.9914185,
    lat: -22.5032698
  },
  {
    lng: -41.9914186,
    lat: -22.5032675
  },
  {
    lng: -41.9914188,
    lat: -22.5032651
  },
  {
    lng: -41.9914189,
    lat: -22.5032628
  },
  {
    lng: -41.9914191,
    lat: -22.5032605
  },
  {
    lng: -41.9914192,
    lat: -22.5032582
  },
  {
    lng: -41.9914193,
    lat: -22.5032559
  },
  {
    lng: -41.9914194,
    lat: -22.5032535
  },
  {
    lng: -41.9914195,
    lat: -22.5032512
  },
  {
    lng: -41.9914196,
    lat: -22.5032489
  },
  {
    lng: -41.9914197,
    lat: -22.5032466
  },
  {
    lng: -41.9914198,
    lat: -22.5032443
  },
  {
    lng: -41.9914199,
    lat: -22.5032419
  },
  {
    lng: -41.99142,
    lat: -22.5032396
  },
  {
    lng: -41.9914201,
    lat: -22.5032373
  },
  {
    lng: -41.9914201,
    lat: -22.503235
  },
  {
    lng: -41.9914202,
    lat: -22.5032326
  },
  {
    lng: -41.9914202,
    lat: -22.5032303
  },
  {
    lng: -41.9914203,
    lat: -22.503228
  },
  {
    lng: -41.9914203,
    lat: -22.5032257
  },
  {
    lng: -41.9914203,
    lat: -22.5032233
  },
  {
    lng: -41.9914204,
    lat: -22.503221
  },
  {
    lng: -41.9914204,
    lat: -22.5032187
  },
  {
    lng: -41.9914204,
    lat: -22.5032164
  },
  {
    lng: -41.9914204,
    lat: -22.503214
  },
  {
    lng: -41.9914204,
    lat: -22.5032117
  },
  {
    lng: -41.9914204,
    lat: -22.5032094
  },
  {
    lng: -41.9914204,
    lat: -22.5032071
  },
  {
    lng: -41.9914203,
    lat: -22.5032047
  },
  {
    lng: -41.9914203,
    lat: -22.5032024
  },
  {
    lng: -41.9914203,
    lat: -22.5032001
  },
  {
    lng: -41.9914202,
    lat: -22.5031978
  },
  {
    lng: -41.9914202,
    lat: -22.5031954
  },
  {
    lng: -41.9914201,
    lat: -22.5031931
  },
  {
    lng: -41.99142,
    lat: -22.5031908
  },
  {
    lng: -41.99142,
    lat: -22.5031885
  },
  {
    lng: -41.9914199,
    lat: -22.5031862
  },
  {
    lng: -41.9914198,
    lat: -22.5031838
  },
  {
    lng: -41.9914197,
    lat: -22.5031815
  },
  {
    lng: -41.9914196,
    lat: -22.5031792
  },
  {
    lng: -41.9914195,
    lat: -22.5031769
  },
  {
    lng: -41.9914194,
    lat: -22.5031745
  },
  {
    lng: -41.9914193,
    lat: -22.5031722
  },
  {
    lng: -41.9914191,
    lat: -22.5031699
  },
  {
    lng: -41.991419,
    lat: -22.5031676
  },
  {
    lng: -41.9914189,
    lat: -22.5031653
  },
  {
    lng: -41.9914187,
    lat: -22.5031629
  },
  {
    lng: -41.9914186,
    lat: -22.5031606
  },
  {
    lng: -41.9914184,
    lat: -22.5031583
  },
  {
    lng: -41.9914182,
    lat: -22.503156
  },
  {
    lng: -41.9914181,
    lat: -22.5031537
  },
  {
    lng: -41.9914179,
    lat: -22.5031514
  },
  {
    lng: -41.9914177,
    lat: -22.503149
  },
  {
    lng: -41.9914175,
    lat: -22.5031467
  },
  {
    lng: -41.9914173,
    lat: -22.5031444
  },
  {
    lng: -41.9914171,
    lat: -22.5031421
  },
  {
    lng: -41.9914169,
    lat: -22.5031398
  },
  {
    lng: -41.9914167,
    lat: -22.5031375
  },
  {
    lng: -41.9914164,
    lat: -22.5031351
  },
  {
    lng: -41.9914162,
    lat: -22.5031328
  },
  {
    lng: -41.991416,
    lat: -22.5031305
  },
  {
    lng: -41.9914157,
    lat: -22.5031282
  },
  {
    lng: -41.9914155,
    lat: -22.5031259
  },
  {
    lng: -41.9914152,
    lat: -22.5031236
  },
  {
    lng: -41.9914149,
    lat: -22.5031213
  },
  {
    lng: -41.9914146,
    lat: -22.503119
  },
  {
    lng: -41.9914144,
    lat: -22.5031167
  },
  {
    lng: -41.9914141,
    lat: -22.5031144
  },
  {
    lng: -41.9914138,
    lat: -22.5031121
  },
  {
    lng: -41.9914135,
    lat: -22.5031098
  },
  {
    lng: -41.9914132,
    lat: -22.5031074
  },
  {
    lng: -41.9914129,
    lat: -22.5031051
  },
  {
    lng: -41.9914125,
    lat: -22.5031028
  },
  {
    lng: -41.9914122,
    lat: -22.5031005
  },
  {
    lng: -41.9914119,
    lat: -22.5030982
  },
  {
    lng: -41.9914115,
    lat: -22.5030959
  },
  {
    lng: -41.9914112,
    lat: -22.5030936
  },
  {
    lng: -41.9914108,
    lat: -22.5030913
  },
  {
    lng: -41.9914105,
    lat: -22.5030891
  },
  {
    lng: -41.9914101,
    lat: -22.5030868
  },
  {
    lng: -41.9914097,
    lat: -22.5030845
  },
  {
    lng: -41.9914093,
    lat: -22.5030822
  },
  {
    lng: -41.9914089,
    lat: -22.5030799
  },
  {
    lng: -41.9914086,
    lat: -22.5030776
  },
  {
    lng: -41.9914081,
    lat: -22.5030753
  },
  {
    lng: -41.9914077,
    lat: -22.503073
  },
  {
    lng: -41.9914073,
    lat: -22.5030707
  },
  {
    lng: -41.9914069,
    lat: -22.5030684
  },
  {
    lng: -41.9914065,
    lat: -22.5030662
  },
  {
    lng: -41.991406,
    lat: -22.5030639
  },
  {
    lng: -41.9914056,
    lat: -22.5030616
  },
  {
    lng: -41.9914051,
    lat: -22.5030593
  },
  {
    lng: -41.9914047,
    lat: -22.503057
  },
  {
    lng: -41.9914042,
    lat: -22.5030548
  },
  {
    lng: -41.9914038,
    lat: -22.5030525
  },
  {
    lng: -41.9914033,
    lat: -22.5030502
  },
  {
    lng: -41.9914028,
    lat: -22.5030479
  },
  {
    lng: -41.9914023,
    lat: -22.5030457
  },
  {
    lng: -41.9914018,
    lat: -22.5030434
  },
  {
    lng: -41.9914013,
    lat: -22.5030411
  },
  {
    lng: -41.9914008,
    lat: -22.5030388
  },
  {
    lng: -41.9914003,
    lat: -22.5030366
  },
  {
    lng: -41.9913998,
    lat: -22.5030343
  },
  {
    lng: -41.9913992,
    lat: -22.503032
  },
  {
    lng: -41.9913987,
    lat: -22.5030298
  },
  {
    lng: -41.9913982,
    lat: -22.5030275
  },
  {
    lng: -41.9913976,
    lat: -22.5030253
  },
  {
    lng: -41.9913971,
    lat: -22.503023
  },
  {
    lng: -41.9913965,
    lat: -22.5030208
  },
  {
    lng: -41.9913959,
    lat: -22.5030185
  },
  {
    lng: -41.9913954,
    lat: -22.5030163
  },
  {
    lng: -41.9913948,
    lat: -22.503014
  },
  {
    lng: -41.9913942,
    lat: -22.5030118
  },
  {
    lng: -41.9913936,
    lat: -22.5030095
  },
  {
    lng: -41.991393,
    lat: -22.5030073
  },
  {
    lng: -41.9913924,
    lat: -22.503005
  },
  {
    lng: -41.9913918,
    lat: -22.5030028
  },
  {
    lng: -41.9913912,
    lat: -22.5030005
  },
  {
    lng: -41.9913905,
    lat: -22.5029983
  },
  {
    lng: -41.9913899,
    lat: -22.5029961
  },
  {
    lng: -41.9913893,
    lat: -22.5029938
  },
  {
    lng: -41.9913886,
    lat: -22.5029916
  },
  {
    lng: -41.991388,
    lat: -22.5029894
  },
  {
    lng: -41.9913873,
    lat: -22.5029871
  },
  {
    lng: -41.9913866,
    lat: -22.5029849
  },
  {
    lng: -41.991386,
    lat: -22.5029827
  },
  {
    lng: -41.9913853,
    lat: -22.5029805
  },
  {
    lng: -41.9913846,
    lat: -22.5029782
  },
  {
    lng: -41.9913839,
    lat: -22.502976
  },
  {
    lng: -41.9913832,
    lat: -22.5029738
  },
  {
    lng: -41.9913825,
    lat: -22.5029716
  },
  {
    lng: -41.9913818,
    lat: -22.5029694
  },
  {
    lng: -41.9913811,
    lat: -22.5029672
  },
  {
    lng: -41.9913803,
    lat: -22.502965
  },
  {
    lng: -41.9913796,
    lat: -22.5029627
  },
  {
    lng: -41.9913789,
    lat: -22.5029605
  },
  {
    lng: -41.9913781,
    lat: -22.5029583
  },
  {
    lng: -41.9913774,
    lat: -22.5029561
  },
  {
    lng: -41.9913766,
    lat: -22.5029539
  },
  {
    lng: -41.9913759,
    lat: -22.5029517
  },
  {
    lng: -41.9913751,
    lat: -22.5029496
  },
  {
    lng: -41.9913743,
    lat: -22.5029474
  },
  {
    lng: -41.9913735,
    lat: -22.5029452
  },
  {
    lng: -41.9913727,
    lat: -22.502943
  },
  {
    lng: -41.991372,
    lat: -22.5029408
  },
  {
    lng: -41.9913712,
    lat: -22.5029386
  },
  {
    lng: -41.9913703,
    lat: -22.5029364
  },
  {
    lng: -41.9913695,
    lat: -22.5029343
  },
  {
    lng: -41.9913687,
    lat: -22.5029321
  },
  {
    lng: -41.9913679,
    lat: -22.5029299
  },
  {
    lng: -41.9913671,
    lat: -22.5029277
  },
  {
    lng: -41.9913662,
    lat: -22.5029256
  },
  {
    lng: -41.9913654,
    lat: -22.5029234
  },
  {
    lng: -41.9913645,
    lat: -22.5029213
  },
  {
    lng: -41.9913637,
    lat: -22.5029191
  },
  {
    lng: -41.9913628,
    lat: -22.5029169
  },
  {
    lng: -41.9913619,
    lat: -22.5029148
  },
  {
    lng: -41.991361,
    lat: -22.5029126
  },
  {
    lng: -41.9913602,
    lat: -22.5029105
  },
  {
    lng: -41.9913593,
    lat: -22.5029083
  },
  {
    lng: -41.9913584,
    lat: -22.5029062
  },
  {
    lng: -41.9913575,
    lat: -22.502904
  },
  {
    lng: -41.9913566,
    lat: -22.5029019
  },
  {
    lng: -41.9913557,
    lat: -22.5028998
  },
  {
    lng: -41.9913547,
    lat: -22.5028976
  },
  {
    lng: -41.9913538,
    lat: -22.5028955
  },
  {
    lng: -41.9913529,
    lat: -22.5028934
  },
  {
    lng: -41.9913519,
    lat: -22.5028912
  },
  {
    lng: -41.991351,
    lat: -22.5028891
  },
  {
    lng: -41.99135,
    lat: -22.502887
  },
  {
    lng: -41.9913491,
    lat: -22.5028849
  },
  {
    lng: -41.9913481,
    lat: -22.5028828
  },
  {
    lng: -41.9913471,
    lat: -22.5028807
  },
  {
    lng: -41.9913462,
    lat: -22.5028785
  },
  {
    lng: -41.9913452,
    lat: -22.5028764
  },
  {
    lng: -41.9913442,
    lat: -22.5028743
  },
  {
    lng: -41.9913432,
    lat: -22.5028722
  },
  {
    lng: -41.9913422,
    lat: -22.5028701
  },
  {
    lng: -41.9913412,
    lat: -22.502868
  },
  {
    lng: -41.9913402,
    lat: -22.502866
  },
  {
    lng: -41.9913392,
    lat: -22.5028639
  },
  {
    lng: -41.9913381,
    lat: -22.5028618
  },
  {
    lng: -41.9913371,
    lat: -22.5028597
  },
  {
    lng: -41.9913361,
    lat: -22.5028576
  },
  {
    lng: -41.991335,
    lat: -22.5028555
  },
  {
    lng: -41.991334,
    lat: -22.5028535
  },
  {
    lng: -41.9913329,
    lat: -22.5028514
  },
  {
    lng: -41.9913319,
    lat: -22.5028493
  },
  {
    lng: -41.9913308,
    lat: -22.5028473
  },
  {
    lng: -41.9913297,
    lat: -22.5028452
  },
  {
    lng: -41.9913286,
    lat: -22.5028431
  },
  {
    lng: -41.9913275,
    lat: -22.5028411
  },
  {
    lng: -41.9913265,
    lat: -22.502839
  },
  {
    lng: -41.9913254,
    lat: -22.502837
  },
  {
    lng: -41.9913243,
    lat: -22.5028349
  },
  {
    lng: -41.9913231,
    lat: -22.5028329
  },
  {
    lng: -41.991322,
    lat: -22.5028309
  },
  {
    lng: -41.9913209,
    lat: -22.5028288
  },
  {
    lng: -41.9913198,
    lat: -22.5028268
  },
  {
    lng: -41.9913186,
    lat: -22.5028248
  },
  {
    lng: -41.9913175,
    lat: -22.5028227
  },
  {
    lng: -41.9913164,
    lat: -22.5028207
  },
  {
    lng: -41.9913152,
    lat: -22.5028187
  },
  {
    lng: -41.9913141,
    lat: -22.5028167
  },
  {
    lng: -41.9913129,
    lat: -22.5028147
  },
  {
    lng: -41.9913117,
    lat: -22.5028127
  },
  {
    lng: -41.9913106,
    lat: -22.5028107
  },
  {
    lng: -41.991263,
    lat: -22.5025349
  },
  {
    lng: -41.9912583,
    lat: -22.5023115
  },
  {
    lng: -41.9913106,
    lat: -22.5021594
  },
  {
    lng: -41.9914056,
    lat: -22.5020406
  },
  {
    lng: -41.9915815,
    lat: -22.501917
  },
  {
    lng: -41.9918097,
    lat: -22.5017981
  },
  {
    lng: -41.9922803,
    lat: -22.5015652
  },
  {
    lng: -41.9927081,
    lat: -22.5013228
  },
  {
    lng: -41.9930694,
    lat: -22.5010566
  },
  {
    lng: -41.9933498,
    lat: -22.5008094
  },
  {
    lng: -41.9938109,
    lat: -22.5003958
  },
  {
    lng: -41.9942015,
    lat: -22.5
  },
  {
    lng: -41.9943678,
    lat: -22.4998272
  },
  {
    lng: -41.9946673,
    lat: -22.4995324
  },
  {
    lng: -41.9949478,
    lat: -22.499286
  },
  {
    lng: -41.9950224,
    lat: -22.4992202
  },
  {
    lng: -41.9950611,
    lat: -22.4991859
  },
  {
    lng: -41.9952663,
    lat: -22.4989905
  },
  {
    lng: -41.9958272,
    lat: -22.4985009
  },
  {
    lng: -41.9959508,
    lat: -22.4983393
  },
  {
    lng: -41.9959936,
    lat: -22.4981872
  },
  {
    lng: -41.9960173,
    lat: -22.4978212
  },
  {
    lng: -41.9960268,
    lat: -22.4973458
  },
  {
    lng: -41.9960417,
    lat: -22.4970039
  },
  {
    lng: -41.9963609,
    lat: -22.4967005
  },
  {
    lng: -41.9969789,
    lat: -22.496092
  },
  {
    lng: -41.9979349,
    lat: -22.4951694
  },
  {
    lng: -41.9984769,
    lat: -22.4946169
  },
  {
    lng: -41.9993333,
    lat: -22.4937817
  },
  {
    lng: -41.9994505,
    lat: -22.4936673
  },
  {
    lng: -42.0008365,
    lat: -22.4923498
  },
  {
    lng: -42.0015459,
    lat: -22.4916467
  },
  {
    lng: -42.0017786,
    lat: -22.4914241
  },
  {
    lng: -42.0028059,
    lat: -22.4904412
  },
  {
    lng: -42.0030854,
    lat: -22.4902002
  },
  {
    lng: -42.0033093,
    lat: -22.4900263
  },
  {
    lng: -42.0035393,
    lat: -22.4898645
  },
  {
    lng: -42.0074044,
    lat: -22.487335
  },
  {
    lng: -42.0083047,
    lat: -22.4867421
  },
  {
    lng: -42.0092075,
    lat: -22.4861448
  },
  {
    lng: -42.0093757,
    lat: -22.4860339
  },
  {
    lng: -42.0104643,
    lat: -22.4853164
  },
  {
    lng: -42.0109597,
    lat: -22.4849983
  },
  {
    lng: -42.0112633,
    lat: -22.4848032
  },
  {
    lng: -42.0122421,
    lat: -22.4841553
  },
  {
    lng: -42.0128536,
    lat: -22.4837543
  },
  {
    lng: -42.0142427,
    lat: -22.4828451
  },
  {
    lng: -42.0147758,
    lat: -22.4824941
  },
  {
    lng: -42.0154975,
    lat: -22.4820121
  },
  {
    lng: -42.0155095,
    lat: -22.4820044
  },
  {
    lng: -42.0158261,
    lat: -22.4817995
  },
  {
    lng: -42.0161426,
    lat: -22.4815945
  },
  {
    lng: -42.0167152,
    lat: -22.4812237
  },
  {
    lng: -42.0173397,
    lat: -22.480817
  },
  {
    lng: -42.017494,
    lat: -22.4807159
  },
  {
    lng: -42.0182795,
    lat: -22.4802013
  },
  {
    lng: -42.0188107,
    lat: -22.4798284
  },
  {
    lng: -42.0189219,
    lat: -22.4797673
  },
  {
    lng: -42.0189203,
    lat: -22.4797623
  },
  {
    lng: -42.0188713,
    lat: -22.4796172
  },
  {
    lng: -42.0188069,
    lat: -22.4794337
  },
  {
    lng: -42.01878,
    lat: -22.4793647
  },
  {
    lng: -42.0186978,
    lat: -22.4791542
  },
  {
    lng: -42.018551,
    lat: -22.4786403
  },
  {
    lng: -42.0184962,
    lat: -22.4783975
  },
  {
    lng: -42.018481,
    lat: -22.4783299
  },
  {
    lng: -42.0183978,
    lat: -22.4780311
  },
  {
    lng: -42.0182842,
    lat: -22.4777595
  },
  {
    lng: -42.0180888,
    lat: -22.4773279
  },
  {
    lng: -42.0179827,
    lat: -22.477104
  },
  {
    lng: -42.0179601,
    lat: -22.4769689
  },
  {
    lng: -42.017971,
    lat: -22.4768542
  },
  {
    lng: -42.0180243,
    lat: -22.4767561
  },
  {
    lng: -42.0181464,
    lat: -22.4766315
  },
  {
    lng: -42.0185162,
    lat: -22.4764225
  },
  {
    lng: -42.0188804,
    lat: -22.4762052
  },
  {
    lng: -42.0192919,
    lat: -22.4759077
  },
  {
    lng: -42.0195611,
    lat: -22.4757468
  },
  {
    lng: -42.0195942,
    lat: -22.475727
  },
  {
    lng: -42.0199962,
    lat: -22.4754768
  },
  {
    lng: -42.0202945,
    lat: -22.4752803
  },
  {
    lng: -42.0204418,
    lat: -22.475172
  },
  {
    lng: -42.0205551,
    lat: -22.4750632
  },
  {
    lng: -42.020682,
    lat: -22.474907
  },
  {
    lng: -42.020775,
    lat: -22.4747462
  },
  {
    lng: -42.0209406,
    lat: -22.4743847
  },
  {
    lng: -42.0211962,
    lat: -22.4737989
  },
  {
    lng: -42.0214071,
    lat: -22.4733114
  },
  {
    lng: -42.0215471,
    lat: -22.4729821
  },
  {
    lng: -42.0215911,
    lat: -22.4728789
  },
  {
    lng: -42.0217425,
    lat: -22.4725805
  },
  {
    lng: -42.0218262,
    lat: -22.4724552
  },
  {
    lng: -42.0219842,
    lat: -22.4722442
  },
  {
    lng: -42.0221906,
    lat: -22.4719794
  },
  {
    lng: -42.0222034,
    lat: -22.4719629
  },
  {
    lng: -42.0224368,
    lat: -22.4716145
  },
  {
    lng: -42.0225834,
    lat: -22.4713398
  },
  {
    lng: -42.0226088,
    lat: -22.4711501
  },
  {
    lng: -42.0227352,
    lat: -22.4710217
  },
  {
    lng: -42.0228754,
    lat: -22.4708459
  },
  {
    lng: -42.0230716,
    lat: -22.4706434
  },
  {
    lng: -42.0232497,
    lat: -22.4704922
  },
  {
    lng: -42.0232735,
    lat: -22.4704752
  },
  {
    lng: -42.0234012,
    lat: -22.4703841
  },
  {
    lng: -42.0238589,
    lat: -22.470119
  },
  {
    lng: -42.024325,
    lat: -22.4698581
  },
  {
    lng: -42.0248253,
    lat: -22.4695899
  },
  {
    lng: -42.025067,
    lat: -22.4694558
  },
  {
    lng: -42.0255574,
    lat: -22.4690566
  },
  {
    lng: -42.025869,
    lat: -22.4688365
  },
  {
    lng: -42.0261495,
    lat: -22.4686792
  },
  {
    lng: -42.0265017,
    lat: -22.4685628
  },
  {
    lng: -42.0269523,
    lat: -22.4684285
  },
  {
    lng: -42.02679,
    lat: -22.4680411
  },
  {
    lng: -42.0266398,
    lat: -22.4676856
  },
  {
    lng: -42.0264266,
    lat: -22.4672854
  },
  {
    lng: -42.0262361,
    lat: -22.4670243
  },
  {
    lng: -42.0260469,
    lat: -22.4666958
  },
  {
    lng: -42.025944,
    lat: -22.4663959
  },
  {
    lng: -42.0259305,
    lat: -22.4663568
  },
  {
    lng: -42.0258488,
    lat: -22.4659907
  },
  {
    lng: -42.0258131,
    lat: -22.4656691
  },
  {
    lng: -42.0258011,
    lat: -22.4654271
  },
  {
    lng: -42.025802,
    lat: -22.4651893
  },
  {
    lng: -42.0258064,
    lat: -22.4649834
  },
  {
    lng: -42.0258174,
    lat: -22.4648646
  },
  {
    lng: -42.0259603,
    lat: -22.4645621
  },
  {
    lng: -42.0260273,
    lat: -22.4644207
  },
  {
    lng: -42.0260522,
    lat: -22.4642548
  },
  {
    lng: -42.026025,
    lat: -22.4639293
  },
  {
    lng: -42.0260238,
    lat: -22.4635884
  },
  {
    lng: -42.0261491,
    lat: -22.463389
  },
  {
    lng: -42.0261518,
    lat: -22.4633848
  },
  {
    lng: -42.0262772,
    lat: -22.4633038
  },
  {
    lng: -42.0264883,
    lat: -22.4632047
  },
  {
    lng: -42.0267166,
    lat: -22.463094
  },
  {
    lng: -42.0267735,
    lat: -22.4630237
  },
  {
    lng: -42.0268559,
    lat: -22.4627596
  },
  {
    lng: -42.0269127,
    lat: -22.4624952
  },
  {
    lng: -42.0269887,
    lat: -22.4623262
  },
  {
    lng: -42.0270915,
    lat: -22.4621061
  },
  {
    lng: -42.0271644,
    lat: -22.4618895
  },
  {
    lng: -42.0271978,
    lat: -22.4617237
  },
  {
    lng: -42.0271457,
    lat: -22.4615643
  },
  {
    lng: -42.027019,
    lat: -22.4613083
  },
  {
    lng: -42.0268669,
    lat: -22.4610479
  },
  {
    lng: -42.0267294,
    lat: -22.4608987
  },
  {
    lng: -42.0266602,
    lat: -22.4607429
  },
  {
    lng: -42.0266291,
    lat: -22.4605997
  },
  {
    lng: -42.0264301,
    lat: -22.4601323
  },
  {
    lng: -42.0263085,
    lat: -22.4598408
  },
  {
    lng: -42.0262955,
    lat: -22.4597106
  },
  {
    lng: -42.0262769,
    lat: -22.4595231
  },
  {
    lng: -42.0262993,
    lat: -22.4592699
  },
  {
    lng: -42.0263486,
    lat: -22.4591599
  },
  {
    lng: -42.0264462,
    lat: -22.4589794
  },
  {
    lng: -42.026529,
    lat: -22.4589017
  },
  {
    lng: -42.0267101,
    lat: -22.4588059
  },
  {
    lng: -42.0268101,
    lat: -22.4587126
  },
  {
    lng: -42.0269652,
    lat: -22.4584381
  },
  {
    lng: -42.027169,
    lat: -22.4580773
  },
  {
    lng: -42.027443,
    lat: -22.4576265
  },
  {
    lng: -42.0275289,
    lat: -22.4573983
  },
  {
    lng: -42.0275558,
    lat: -22.4571373
  },
  {
    lng: -42.0275637,
    lat: -22.4567688
  },
  {
    lng: -42.027558,
    lat: -22.4564319
  },
  {
    lng: -42.0275881,
    lat: -22.4562185
  },
  {
    lng: -42.0276607,
    lat: -22.4560177
  },
  {
    lng: -42.0276853,
    lat: -22.4558596
  },
  {
    lng: -42.0276677,
    lat: -22.4556889
  },
  {
    lng: -42.0276091,
    lat: -22.4554342
  },
  {
    lng: -42.0275284,
    lat: -22.4552227
  },
  {
    lng: -42.0274206,
    lat: -22.455078
  },
  {
    lng: -42.0273241,
    lat: -22.4550089
  },
  {
    lng: -42.02716,
    lat: -22.4549028
  },
  {
    lng: -42.0270009,
    lat: -22.4547691
  },
  {
    lng: -42.026922,
    lat: -22.4546526
  },
  {
    lng: -42.0268975,
    lat: -22.4546165
  },
  {
    lng: -42.0268289,
    lat: -22.4544279
  },
  {
    lng: -42.026825,
    lat: -22.4544171
  },
  {
    lng: -42.0267471,
    lat: -22.454273
  },
  {
    lng: -42.0266604,
    lat: -22.4541406
  },
  {
    lng: -42.0264428,
    lat: -22.4539503
  },
  {
    lng: -42.0262165,
    lat: -22.4537639
  },
  {
    lng: -42.0260999,
    lat: -22.4536349
  },
  {
    lng: -42.025887,
    lat: -22.4534209
  },
  {
    lng: -42.0256344,
    lat: -22.4532198
  },
  {
    lng: -42.0256271,
    lat: -22.453214
  },
  {
    lng: -42.025292,
    lat: -22.4529344
  },
  {
    lng: -42.0251495,
    lat: -22.4528208
  },
  {
    lng: -42.0247902,
    lat: -22.4526755
  },
  {
    lng: -42.0242017,
    lat: -22.4524783
  },
  {
    lng: -42.0235582,
    lat: -22.4522683
  },
  {
    lng: -42.0232317,
    lat: -22.4521831
  },
  {
    lng: -42.0229435,
    lat: -22.4521024
  },
  {
    lng: -42.0227261,
    lat: -22.4520297
  },
  {
    lng: -42.0226049,
    lat: -22.4519892
  },
  {
    lng: -42.0223259,
    lat: -22.451877
  },
  {
    lng: -42.0221574,
    lat: -22.4517788
  },
  {
    lng: -42.0219263,
    lat: -22.45162
  },
  {
    lng: -42.021776,
    lat: -22.4514785
  },
  {
    lng: -42.0215324,
    lat: -22.4511015
  },
  {
    lng: -42.0212951,
    lat: -22.4508356
  },
  {
    lng: -42.0210953,
    lat: -22.4506099
  },
  {
    lng: -42.0210156,
    lat: -22.450545
  },
  {
    lng: -42.020884,
    lat: -22.4505228
  },
  {
    lng: -42.0206667,
    lat: -22.4505188
  },
  {
    lng: -42.0201781,
    lat: -22.4506366
  },
  {
    lng: -42.0193648,
    lat: -22.450784
  },
  {
    lng: -42.0190836,
    lat: -22.4507788
  },
  {
    lng: -42.0188247,
    lat: -22.4507265
  },
  {
    lng: -42.0185418,
    lat: -22.4505944
  },
  {
    lng: -42.0181787,
    lat: -22.4504332
  },
  {
    lng: -42.018063,
    lat: -22.4503288
  },
  {
    lng: -42.0179904,
    lat: -22.4502633
  },
  {
    lng: -42.0178183,
    lat: -22.4499391
  },
  {
    lng: -42.0176655,
    lat: -22.4497064
  },
  {
    lng: -42.0175328,
    lat: -22.4495335
  },
  {
    lng: -42.0174336,
    lat: -22.4493851
  },
  {
    lng: -42.0173653,
    lat: -22.4491936
  },
  {
    lng: -42.017329,
    lat: -22.4490673
  },
  {
    lng: -42.0172542,
    lat: -22.4488072
  },
  {
    lng: -42.0171073,
    lat: -22.4483011
  },
  {
    lng: -42.0170443,
    lat: -22.4480582
  },
  {
    lng: -42.0169936,
    lat: -22.4478354
  },
  {
    lng: -42.0170109,
    lat: -22.4476257
  },
  {
    lng: -42.0170393,
    lat: -22.4474954
  },
  {
    lng: -42.0171401,
    lat: -22.4471683
  },
  {
    lng: -42.017272,
    lat: -22.4467824
  },
  {
    lng: -42.0174815,
    lat: -22.4461562
  },
  {
    lng: -42.0176409,
    lat: -22.4456796
  },
  {
    lng: -42.0177532,
    lat: -22.4454082
  },
  {
    lng: -42.017852,
    lat: -22.4451762
  },
  {
    lng: -42.0179402,
    lat: -22.4450432
  },
  {
    lng: -42.0180621,
    lat: -22.4449225
  },
  {
    lng: -42.0182827,
    lat: -22.4447721
  },
  {
    lng: -42.0183916,
    lat: -22.4446671
  },
  {
    lng: -42.018632,
    lat: -22.4443902
  },
  {
    lng: -42.0188021,
    lat: -22.444207
  },
  {
    lng: -42.0188595,
    lat: -22.444113
  },
  {
    lng: -42.0189276,
    lat: -22.4439241
  },
  {
    lng: -42.0189732,
    lat: -22.4438176
  },
  {
    lng: -42.0189949,
    lat: -22.4437667
  },
  {
    lng: -42.019075,
    lat: -22.4436058
  },
  {
    lng: -42.0191547,
    lat: -22.4434614
  },
  {
    lng: -42.0191682,
    lat: -22.4434371
  },
  {
    lng: -42.0192955,
    lat: -22.4432611
  },
  {
    lng: -42.0194526,
    lat: -22.4430936
  },
  {
    lng: -42.0196914,
    lat: -22.442892
  },
  {
    lng: -42.0199433,
    lat: -22.4426747
  },
  {
    lng: -42.0202289,
    lat: -22.4424778
  },
  {
    lng: -42.0204681,
    lat: -22.4422564
  },
  {
    lng: -42.020658,
    lat: -22.4419468
  },
  {
    lng: -42.0207215,
    lat: -22.4417736
  },
  {
    lng: -42.0208098,
    lat: -22.4416286
  },
  {
    lng: -42.0208595,
    lat: -22.4414987
  },
  {
    lng: -42.0208636,
    lat: -22.4413047
  },
  {
    lng: -42.0208345,
    lat: -22.4410742
  },
  {
    lng: -42.0208149,
    lat: -22.4403923
  },
  {
    lng: -42.0208195,
    lat: -22.4399762
  },
  {
    lng: -42.0207909,
    lat: -22.4393178
  },
  {
    lng: -42.0207673,
    lat: -22.43883
  },
  {
    lng: -42.0207326,
    lat: -22.4386589
  },
  {
    lng: -42.0206586,
    lat: -22.4385267
  },
  {
    lng: -42.0205758,
    lat: -22.4384143
  },
  {
    lng: -42.0205049,
    lat: -22.4383417
  },
  {
    lng: -42.0203163,
    lat: -22.4381915
  },
  {
    lng: -42.020106,
    lat: -22.4380529
  },
  {
    lng: -42.0198652,
    lat: -22.4379533
  },
  {
    lng: -42.0196488,
    lat: -22.4379057
  },
  {
    lng: -42.0194923,
    lat: -22.4378513
  },
  {
    lng: -42.019371,
    lat: -22.4377382
  },
  {
    lng: -42.019259,
    lat: -22.4375934
  },
  {
    lng: -42.0191309,
    lat: -22.4372066
  },
  {
    lng: -42.0190302,
    lat: -22.4369501
  },
  {
    lng: -42.0189287,
    lat: -22.4366917
  },
  {
    lng: -42.0188169,
    lat: -22.4363409
  },
  {
    lng: -42.0187962,
    lat: -22.4361106
  },
  {
    lng: -42.0188149,
    lat: -22.4358336
  },
  {
    lng: -42.0189094,
    lat: -22.4352013
  },
  {
    lng: -42.0190247,
    lat: -22.4345971
  },
  {
    lng: -42.0190755,
    lat: -22.4342136
  },
  {
    lng: -42.0190851,
    lat: -22.4340625
  },
  {
    lng: -42.0190948,
    lat: -22.4339088
  },
  {
    lng: -42.0190804,
    lat: -22.4335836
  },
  {
    lng: -42.0190085,
    lat: -22.4331582
  },
  {
    lng: -42.0189349,
    lat: -22.432704
  },
  {
    lng: -42.0189004,
    lat: -22.4324907
  },
  {
    lng: -42.018856,
    lat: -22.432215
  },
  {
    lng: -42.0188273,
    lat: -22.4317323
  },
  {
    lng: -42.0188017,
    lat: -22.4315605
  },
  {
    lng: -42.0187786,
    lat: -22.4314047
  },
  {
    lng: -42.0187065,
    lat: -22.4311181
  },
  {
    lng: -42.0186596,
    lat: -22.4309669
  },
  {
    lng: -42.0186237,
    lat: -22.430821
  },
  {
    lng: -42.0186414,
    lat: -22.4305155
  },
  {
    lng: -42.0186257,
    lat: -22.430204
  },
  {
    lng: -42.0185909,
    lat: -22.4300063
  },
  {
    lng: -42.0185294,
    lat: -22.4298022
  },
  {
    lng: -42.0185076,
    lat: -22.42973
  },
  {
    lng: -42.0183843,
    lat: -22.4294944
  },
  {
    lng: -42.0182667,
    lat: -22.4292537
  },
  {
    lng: -42.0181435,
    lat: -22.429018
  },
  {
    lng: -42.017968,
    lat: -22.4286155
  },
  {
    lng: -42.0178559,
    lat: -22.4283801
  },
  {
    lng: -42.0177474,
    lat: -22.4282381
  },
  {
    lng: -42.0174165,
    lat: -22.4278016
  },
  {
    lng: -42.0172915,
    lat: -22.4276489
  },
  {
    lng: -42.0171274,
    lat: -22.4274955
  },
  {
    lng: -42.0169627,
    lat: -22.4273732
  },
  {
    lng: -42.0167204,
    lat: -22.4272287
  },
  {
    lng: -42.0161966,
    lat: -22.4269442
  },
  {
    lng: -42.0157436,
    lat: -22.4267387
  },
  {
    lng: -42.0155225,
    lat: -22.4266465
  },
  {
    lng: -42.0153728,
    lat: -22.4266075
  },
  {
    lng: -42.0151949,
    lat: -22.4265782
  },
  {
    lng: -42.0150673,
    lat: -22.4265448
  },
  {
    lng: -42.0147678,
    lat: -22.4264666
  },
  {
    lng: -42.0144461,
    lat: -22.4263828
  },
  {
    lng: -42.0144135,
    lat: -22.4263574
  },
  {
    lng: -42.014397,
    lat: -22.4263301
  },
  {
    lng: -42.0143946,
    lat: -22.4262738
  },
  {
    lng: -42.0143935,
    lat: -22.4262315
  },
  {
    lng: -42.0144194,
    lat: -22.4260661
  },
  {
    lng: -42.0144559,
    lat: -22.4258772
  },
  {
    lng: -42.0147384,
    lat: -22.4259631
  },
  {
    lng: -42.0151926,
    lat: -22.426133
  },
  {
    lng: -42.015687,
    lat: -22.4263285
  },
  {
    lng: -42.01658,
    lat: -22.4266581
  },
  {
    lng: -42.0176913,
    lat: -22.4270603
  },
  {
    lng: -42.0177422,
    lat: -22.4270787
  },
  {
    lng: -42.018706,
    lat: -22.4274357
  },
  {
    lng: -42.0197251,
    lat: -22.427821
  },
  {
    lng: -42.0201481,
    lat: -22.4277669
  },
  {
    lng: -42.0202847,
    lat: -22.4275066
  },
  {
    lng: -42.0203972,
    lat: -22.4272499
  },
  {
    lng: -42.0205474,
    lat: -22.4269075
  },
  {
    lng: -42.0208408,
    lat: -22.4262027
  },
  {
    lng: -42.020928,
    lat: -22.4257994
  },
  {
    lng: -42.0210178,
    lat: -22.425602
  },
  {
    lng: -42.0210822,
    lat: -22.425516
  },
  {
    lng: -42.0210914,
    lat: -22.4255038
  },
  {
    lng: -42.0211928,
    lat: -22.4254327
  },
  {
    lng: -42.021308,
    lat: -22.4253883
  },
  {
    lng: -42.0214379,
    lat: -22.4253243
  },
  {
    lng: -42.0218206,
    lat: -22.425119
  },
  {
    lng: -42.022261,
    lat: -22.4248882
  },
  {
    lng: -42.0228506,
    lat: -22.4246933
  },
  {
    lng: -42.0232884,
    lat: -22.4245886
  },
  {
    lng: -42.0235459,
    lat: -22.4245601
  },
  {
    lng: -42.0242063,
    lat: -22.424393
  },
  {
    lng: -42.0245008,
    lat: -22.4243122
  },
  {
    lng: -42.0246078,
    lat: -22.4243141
  },
  {
    lng: -42.0248632,
    lat: -22.4243919
  },
  {
    lng: -42.0252316,
    lat: -22.4245248
  },
  {
    lng: -42.0255081,
    lat: -22.4246162
  },
  {
    lng: -42.0256919,
    lat: -22.4246992
  },
  {
    lng: -42.0260308,
    lat: -22.424878
  },
  {
    lng: -42.0262722,
    lat: -22.4249422
  },
  {
    lng: -42.026677,
    lat: -22.4250426
  },
  {
    lng: -42.0269611,
    lat: -22.4251075
  },
  {
    lng: -42.0271393,
    lat: -22.4251242
  },
  {
    lng: -42.0273541,
    lat: -22.4250949
  },
  {
    lng: -42.0275546,
    lat: -22.4250654
  },
  {
    lng: -42.0278126,
    lat: -22.4250236
  },
  {
    lng: -42.0280266,
    lat: -22.4250276
  },
  {
    lng: -42.0283686,
    lat: -22.4250604
  },
  {
    lng: -42.0285262,
    lat: -22.4250368
  },
  {
    lng: -42.0285645,
    lat: -22.4250251
  },
  {
    lng: -42.0286699,
    lat: -22.424993
  },
  {
    lng: -42.0288432,
    lat: -22.4249033
  },
  {
    lng: -42.0290447,
    lat: -22.4248273
  },
  {
    lng: -42.0292174,
    lat: -22.4247641
  },
  {
    lng: -42.0294676,
    lat: -22.4247173
  },
  {
    lng: -42.0295399,
    lat: -22.4247037
  },
  {
    lng: -42.0298864,
    lat: -22.4245242
  },
  {
    lng: -42.0304551,
    lat: -22.4243024
  },
  {
    lng: -42.0307912,
    lat: -22.4241881
  },
  {
    lng: -42.0311584,
    lat: -22.4243026
  },
  {
    lng: -42.0312729,
    lat: -22.4243383
  },
  {
    lng: -42.0318155,
    lat: -22.4245251
  },
  {
    lng: -42.032376,
    lat: -22.4247652
  },
  {
    lng: -42.0326532,
    lat: -22.4249116
  },
  {
    lng: -42.0329447,
    lat: -22.4250673
  },
  {
    lng: -42.0333248,
    lat: -22.4252952
  },
  {
    lng: -42.0336657,
    lat: -22.4255799
  },
  {
    lng: -42.0338993,
    lat: -22.4257742
  },
  {
    lng: -42.0339628,
    lat: -22.4259123
  },
  {
    lng: -42.0340018,
    lat: -22.4260942
  },
  {
    lng: -42.0340703,
    lat: -22.4262236
  },
  {
    lng: -42.0341907,
    lat: -22.4263716
  },
  {
    lng: -42.034405,
    lat: -22.4265744
  },
  {
    lng: -42.034475,
    lat: -22.4266376
  },
  {
    lng: -42.0345638,
    lat: -22.4267099
  },
  {
    lng: -42.0346327,
    lat: -22.4267475
  },
  {
    lng: -42.0346812,
    lat: -22.4267739
  },
  {
    lng: -42.0348555,
    lat: -22.4268522
  },
  {
    lng: -42.0349868,
    lat: -22.4269386
  },
  {
    lng: -42.0350892,
    lat: -22.4270378
  },
  {
    lng: -42.0351769,
    lat: -22.4271587
  },
  {
    lng: -42.0352308,
    lat: -22.4273054
  },
  {
    lng: -42.0352851,
    lat: -22.4274346
  },
  {
    lng: -42.03533,
    lat: -22.4274934
  },
  {
    lng: -42.0353637,
    lat: -22.4275377
  },
  {
    lng: -42.0354527,
    lat: -22.4275968
  },
  {
    lng: -42.0355379,
    lat: -22.4276204
  },
  {
    lng: -42.0356517,
    lat: -22.4276313
  },
  {
    lng: -42.0358415,
    lat: -22.4276436
  },
  {
    lng: -42.0360062,
    lat: -22.4277263
  },
  {
    lng: -42.0360893,
    lat: -22.4278383
  },
  {
    lng: -42.0360978,
    lat: -22.427887
  },
  {
    lng: -42.0361658,
    lat: -22.4279455
  },
  {
    lng: -42.0362108,
    lat: -22.4279836
  },
  {
    lng: -42.0362624,
    lat: -22.4279793
  },
  {
    lng: -42.036488,
    lat: -22.4278717
  },
  {
    lng: -42.0365031,
    lat: -22.4278373
  },
  {
    lng: -42.0366713,
    lat: -22.4277517
  },
  {
    lng: -42.036764,
    lat: -22.427772
  },
  {
    lng: -42.0370624,
    lat: -22.4278083
  },
  {
    lng: -42.0372547,
    lat: -22.4278304
  },
  {
    lng: -42.037318,
    lat: -22.4278285
  },
  {
    lng: -42.0375734,
    lat: -22.4278548
  },
  {
    lng: -42.0376184,
    lat: -22.427851
  },
  {
    lng: -42.0376933,
    lat: -22.4278446
  },
  {
    lng: -42.0377942,
    lat: -22.4277909
  },
  {
    lng: -42.0378428,
    lat: -22.4276928
  },
  {
    lng: -42.037865,
    lat: -22.4275882
  },
  {
    lng: -42.0378875,
    lat: -22.427465
  },
  {
    lng: -42.0379438,
    lat: -22.4273176
  },
  {
    lng: -42.0382238,
    lat: -22.4269704
  },
  {
    lng: -42.0386655,
    lat: -22.4265211
  },
  {
    lng: -42.03874,
    lat: -22.4264595
  },
  {
    lng: -42.0392764,
    lat: -22.4264446
  },
  {
    lng: -42.0393464,
    lat: -22.4264459
  },
  {
    lng: -42.0394609,
    lat: -22.4264539
  },
  {
    lng: -42.0395813,
    lat: -22.4264739
  },
  {
    lng: -42.0396449,
    lat: -22.4264809
  },
  {
    lng: -42.0397331,
    lat: -22.4265241
  },
  {
    lng: -42.0399166,
    lat: -22.4265748
  },
  {
    lng: -42.0400237,
    lat: -22.4266242
  },
  {
    lng: -42.0401825,
    lat: -22.4266389
  },
  {
    lng: -42.0403158,
    lat: -22.4266591
  },
  {
    lng: -42.04046,
    lat: -22.4267625
  },
  {
    lng: -42.0406255,
    lat: -22.4267655
  },
  {
    lng: -42.0407634,
    lat: -22.4268688
  },
  {
    lng: -42.0408698,
    lat: -22.4269477
  },
  {
    lng: -42.0409638,
    lat: -22.4270206
  },
  {
    lng: -42.0410505,
    lat: -22.4271347
  },
  {
    lng: -42.0411301,
    lat: -22.4272843
  },
  {
    lng: -42.0412399,
    lat: -22.4274343
  },
  {
    lng: -42.041397,
    lat: -22.427617
  },
  {
    lng: -42.0417453,
    lat: -22.427893
  },
  {
    lng: -42.0420981,
    lat: -22.4279594
  },
  {
    lng: -42.0423025,
    lat: -22.4279064
  },
  {
    lng: -42.0424219,
    lat: -22.4278755
  },
  {
    lng: -42.0427179,
    lat: -22.4275812
  },
  {
    lng: -42.0432802,
    lat: -22.4268754
  },
  {
    lng: -42.043481,
    lat: -22.4265194
  },
  {
    lng: -42.0438098,
    lat: -22.4261958
  },
  {
    lng: -42.0440055,
    lat: -22.4260794
  },
  {
    lng: -42.0444032,
    lat: -22.4255473
  },
  {
    lng: -42.0448986,
    lat: -22.424957
  },
  {
    lng: -42.0453222,
    lat: -22.424725
  },
  {
    lng: -42.0456188,
    lat: -22.4244008
  },
  {
    lng: -42.0463023,
    lat: -22.4240536
  },
  {
    lng: -42.0466261,
    lat: -22.4239697
  },
  {
    lng: -42.0470779,
    lat: -22.423918
  },
  {
    lng: -42.047432,
    lat: -22.4239245
  },
  {
    lng: -42.0476573,
    lat: -22.4239286
  },
  {
    lng: -42.0479798,
    lat: -22.4239045
  },
  {
    lng: -42.0481729,
    lat: -22.4239081
  },
  {
    lng: -42.0489069,
    lat: -22.4240713
  },
  {
    lng: -42.049353,
    lat: -22.4242893
  },
  {
    lng: -42.0497998,
    lat: -22.4244773
  },
  {
    lng: -42.0506095,
    lat: -22.4242523
  },
  {
    lng: -42.0510697,
    lat: -22.4238112
  },
  {
    lng: -42.05156,
    lat: -22.4234605
  },
  {
    lng: -42.0520144,
    lat: -22.423289
  },
  {
    lng: -42.0525326,
    lat: -22.4231486
  },
  {
    lng: -42.0529529,
    lat: -22.4230664
  },
  {
    lng: -42.0534679,
    lat: -22.4230758
  },
  {
    lng: -42.0541992,
    lat: -22.4230592
  },
  {
    lng: -42.0546435,
    lat: -22.423367
  },
  {
    lng: -42.0548991,
    lat: -22.4234615
  },
  {
    lng: -42.0552481,
    lat: -22.4237077
  },
  {
    lng: -42.0555043,
    lat: -22.4237723
  },
  {
    lng: -42.056076,
    lat: -22.4241423
  },
  {
    lng: -42.0563638,
    lat: -22.4242374
  },
  {
    lng: -42.0566536,
    lat: -22.4242427
  },
  {
    lng: -42.056942,
    lat: -22.424308
  },
  {
    lng: -42.0572966,
    lat: -22.4242844
  },
  {
    lng: -42.057568,
    lat: -22.4242464
  },
  {
    lng: -42.0581148,
    lat: -22.4242192
  },
  {
    lng: -42.0634756,
    lat: -22.4227835
  },
  {
    lng: -42.0635168,
    lat: -22.4227724
  }
];

export default polygonCoords_Ostras;