let polygonCoords_ArraialDoCabo = [
  {
    lng: -42.2910825,
    lat: -22.9387315
  },
  {
    lng: -42.2901293,
    lat: -22.938798
  },
  {
    lng: -42.2837074,
    lat: -22.939242
  },
  {
    lng: -42.2736909,
    lat: -22.9397682
  },
  {
    lng: -42.2640564,
    lat: -22.940156
  },
  {
    lng: -42.2464118,
    lat: -22.9410425
  },
  {
    lng: -42.2374305,
    lat: -22.9413748
  },
  {
    lng: -42.2283718,
    lat: -22.9419131
  },
  {
    lng: -42.2219087,
    lat: -22.9423009
  },
  {
    lng: -42.2171382,
    lat: -22.9425514
  },
  {
    lng: -42.2134291,
    lat: -22.942773
  },
  {
    lng: -42.2108733,
    lat: -22.9430053
  },
  {
    lng: -42.2077535,
    lat: -22.9432269
  },
  {
    lng: -42.2036043,
    lat: -22.9434249
  },
  {
    lng: -42.1977576,
    lat: -22.9438812
  },
  {
    lng: -42.1928537,
    lat: -22.9441547
  },
  {
    lng: -42.1911022,
    lat: -22.9443193
  },
  {
    lng: -42.1881419,
    lat: -22.9444631
  },
  {
    lng: -42.1860038,
    lat: -22.9446371
  },
  {
    lng: -42.1839889,
    lat: -22.9447204
  },
  {
    lng: -42.1820422,
    lat: -22.9448265
  },
  {
    lng: -42.1801075,
    lat: -22.944967
  },
  {
    lng: -42.1789864,
    lat: -22.9450628
  },
  {
    lng: -42.1769284,
    lat: -22.94536
  },
  {
    lng: -42.1749196,
    lat: -22.9454529
  },
  {
    lng: -42.1734613,
    lat: -22.9455462
  },
  {
    lng: -42.1714067,
    lat: -22.9456678
  },
  {
    lng: -42.1699057,
    lat: -22.9458012
  },
  {
    lng: -42.1693587,
    lat: -22.9459221
  },
  {
    lng: -42.1685809,
    lat: -22.9459816
  },
  {
    lng: -42.1682001,
    lat: -22.9459747
  },
  {
    lng: -42.1678537,
    lat: -22.9459972
  },
  {
    lng: -42.1674117,
    lat: -22.9460365
  },
  {
    lng: -42.1669163,
    lat: -22.9461227
  },
  {
    lng: -42.1651928,
    lat: -22.9461942
  },
  {
    lng: -42.1545581,
    lat: -22.94719
  },
  {
    lng: -42.1501675,
    lat: -22.9475153
  },
  {
    lng: -42.1452954,
    lat: -22.9480739
  },
  {
    lng: -42.1426239,
    lat: -22.9483047
  },
  {
    lng: -42.1375224,
    lat: -22.9486163
  },
  {
    lng: -42.132494,
    lat: -22.9492603
  },
  {
    lng: -42.1282301,
    lat: -22.9496285
  },
  {
    lng: -42.1220563,
    lat: -22.9502095
  },
  {
    lng: -42.1186178,
    lat: -22.9506176
  },
  {
    lng: -42.1148834,
    lat: -22.9510371
  },
  {
    lng: -42.1118854,
    lat: -22.951229
  },
  {
    lng: -42.1092454,
    lat: -22.9514209
  },
  {
    lng: -42.105031,
    lat: -22.9518867
  },
  {
    lng: -42.1022252,
    lat: -22.9522418
  },
  {
    lng: -42.0995894,
    lat: -22.9525316
  },
  {
    lng: -42.0968545,
    lat: -22.9529043
  },
  {
    lng: -42.0941057,
    lat: -22.9531596
  },
  {
    lng: -42.0870029,
    lat: -22.9541106
  },
  {
    lng: -42.0817346,
    lat: -22.9545595
  },
  {
    lng: -42.0769969,
    lat: -22.9553643
  },
  {
    lng: -42.0746256,
    lat: -22.9558809
  },
  {
    lng: -42.0727651,
    lat: -22.956169
  },
  {
    lng: -42.0698059,
    lat: -22.9567393
  },
  {
    lng: -42.0677912,
    lat: -22.9570049
  },
  {
    lng: -42.0647217,
    lat: -22.957595
  },
  {
    lng: -42.0626582,
    lat: -22.9579197
  },
  {
    lng: -42.0574327,
    lat: -22.9592469
  },
  {
    lng: -42.0539868,
    lat: -22.960348
  },
  {
    lng: -42.0502171,
    lat: -22.9613192
  },
  {
    lng: -42.0456737,
    lat: -22.963031
  },
  {
    lng: -42.0417421,
    lat: -22.9649291
  },
  {
    lng: -42.040165,
    lat: -22.9658413
  },
  {
    lng: -42.0381913,
    lat: -22.9668285
  },
  {
    lng: -42.0376525,
    lat: -22.9672389
  },
  {
    lng: -42.0367026,
    lat: -22.9678752
  },
  {
    lng: -42.0350957,
    lat: -22.9693325
  },
  {
    lng: -42.0332504,
    lat: -22.9714822
  },
  {
    lng: -42.0324697,
    lat: -22.9730452
  },
  {
    lng: -42.032299,
    lat: -22.973837
  },
  {
    lng: -42.0322193,
    lat: -22.9750376
  },
  {
    lng: -42.0320894,
    lat: -22.9760311
  },
  {
    lng: -42.0325923,
    lat: -22.9765852
  },
  {
    lng: -42.0334084,
    lat: -22.9768923
  },
  {
    lng: -42.0339741,
    lat: -22.9766726
  },
  {
    lng: -42.0339917,
    lat: -22.9764651
  },
  {
    lng: -42.0342303,
    lat: -22.9763878
  },
  {
    lng: -42.0343805,
    lat: -22.9766197
  },
  {
    lng: -42.0347692,
    lat: -22.9767174
  },
  {
    lng: -42.0349858,
    lat: -22.9765139
  },
  {
    lng: -42.0351934,
    lat: -22.9767254
  },
  {
    lng: -42.0348312,
    lat: -22.9768353
  },
  {
    lng: -42.0345484,
    lat: -22.9770305
  },
  {
    lng: -42.0348488,
    lat: -22.9772339
  },
  {
    lng: -42.0345042,
    lat: -22.9774007
  },
  {
    lng: -42.0345701,
    lat: -22.9774636
  },
  {
    lng: -42.0347849,
    lat: -22.9779107
  },
  {
    lng: -42.0351282,
    lat: -22.9780889
  },
  {
    lng: -42.0351902,
    lat: -22.9785018
  },
  {
    lng: -42.0349485,
    lat: -22.9788285
  },
  {
    lng: -42.0345219,
    lat: -22.9789405
  },
  {
    lng: -42.0342175,
    lat: -22.9789274
  },
  {
    lng: -42.0339692,
    lat: -22.9787749
  },
  {
    lng: -42.0335552,
    lat: -22.9787622
  },
  {
    lng: -42.0330944,
    lat: -22.9787806
  },
  {
    lng: -42.0328238,
    lat: -22.9790798
  },
  {
    lng: -42.0326896,
    lat: -22.9793958
  },
  {
    lng: -42.0326306,
    lat: -22.9796515
  },
  {
    lng: -42.0323932,
    lat: -22.9800566
  },
  {
    lng: -42.0320719,
    lat: -22.9804371
  },
  {
    lng: -42.0313679,
    lat: -22.980411
  },
  {
    lng: -42.0316181,
    lat: -22.9807861
  },
  {
    lng: -42.0315681,
    lat: -22.9809727
  },
  {
    lng: -42.031193,
    lat: -22.9813369
  },
  {
    lng: -42.0309805,
    lat: -22.9813657
  },
  {
    lng: -42.0307915,
    lat: -22.9815583
  },
  {
    lng: -42.0306435,
    lat: -22.9815571
  },
  {
    lng: -42.0303952,
    lat: -22.9817604
  },
  {
    lng: -42.0304228,
    lat: -22.9821923
  },
  {
    lng: -42.0303262,
    lat: -22.9826242
  },
  {
    lng: -42.0300364,
    lat: -22.9828021
  },
  {
    lng: -42.029765,
    lat: -22.9828283
  },
  {
    lng: -42.0292084,
    lat: -22.9828275
  },
  {
    lng: -42.0283943,
    lat: -22.9827386
  },
  {
    lng: -42.0281321,
    lat: -22.9828021
  },
  {
    lng: -42.0273041,
    lat: -22.9825607
  },
  {
    lng: -42.0272075,
    lat: -22.9823575
  },
  {
    lng: -42.0266418,
    lat: -22.9821796
  },
  {
    lng: -42.0259932,
    lat: -22.9819636
  },
  {
    lng: -42.025179,
    lat: -22.981862
  },
  {
    lng: -42.0246512,
    lat: -22.9819924
  },
  {
    lng: -42.0242683,
    lat: -22.9821415
  },
  {
    lng: -42.0240802,
    lat: -22.9824157
  },
  {
    lng: -42.023646,
    lat: -22.9824794
  },
  {
    lng: -42.0231137,
    lat: -22.9825911
  },
  {
    lng: -42.0228461,
    lat: -22.9825225
  },
  {
    lng: -42.0223193,
    lat: -22.982665
  },
  {
    lng: -42.0220351,
    lat: -22.9827029
  },
  {
    lng: -42.0213784,
    lat: -22.9830305
  },
  {
    lng: -42.0208751,
    lat: -22.9834359
  },
  {
    lng: -42.0205071,
    lat: -22.9835022
  },
  {
    lng: -42.020338,
    lat: -22.9834502
  },
  {
    lng: -42.020194,
    lat: -22.9835313
  },
  {
    lng: -42.0202774,
    lat: -22.9839268
  },
  {
    lng: -42.0197145,
    lat: -22.9838312
  },
  {
    lng: -42.0194936,
    lat: -22.9841107
  },
  {
    lng: -42.0192315,
    lat: -22.9842758
  },
  {
    lng: -42.0189831,
    lat: -22.9845553
  },
  {
    lng: -42.0190384,
    lat: -22.9847713
  },
  {
    lng: -42.0191764,
    lat: -22.9848348
  },
  {
    lng: -42.0192146,
    lat: -22.9851179
  },
  {
    lng: -42.0192622,
    lat: -22.9855938
  },
  {
    lng: -42.0191969,
    lat: -22.9856796
  },
  {
    lng: -42.0192549,
    lat: -22.9857483
  },
  {
    lng: -42.0192785,
    lat: -22.9859877
  },
  {
    lng: -42.019167,
    lat: -22.9860722
  },
  {
    lng: -42.019062,
    lat: -22.9862137
  },
  {
    lng: -42.0190742,
    lat: -22.986304
  },
  {
    lng: -42.0189144,
    lat: -22.9864059
  },
  {
    lng: -42.0187302,
    lat: -22.9863856
  },
  {
    lng: -42.0184282,
    lat: -22.9860968
  },
  {
    lng: -42.0178676,
    lat: -22.986198
  },
  {
    lng: -42.0177631,
    lat: -22.9861912
  },
  {
    lng: -42.0176232,
    lat: -22.9862454
  },
  {
    lng: -42.0174733,
    lat: -22.9862518
  },
  {
    lng: -42.0174037,
    lat: -22.9862864
  },
  {
    lng: -42.0175021,
    lat: -22.9864289
  },
  {
    lng: -42.0175021,
    lat: -22.9865201
  },
  {
    lng: -42.0174297,
    lat: -22.9866011
  },
  {
    lng: -42.0173112,
    lat: -22.9866225
  },
  {
    lng: -42.0174807,
    lat: -22.9868523
  },
  {
    lng: -42.0173886,
    lat: -22.9872667
  },
  {
    lng: -42.017261,
    lat: -22.9874552
  },
  {
    lng: -42.017173,
    lat: -22.9874902
  },
  {
    lng: -42.0172023,
    lat: -22.9875818
  },
  {
    lng: -42.0173223,
    lat: -22.9875893
  },
  {
    lng: -42.0174455,
    lat: -22.98778
  },
  {
    lng: -42.017446,
    lat: -22.9881423
  },
  {
    lng: -42.017261,
    lat: -22.9883413
  },
  {
    lng: -42.0170855,
    lat: -22.9885317
  },
  {
    lng: -42.0173231,
    lat: -22.988687
  },
  {
    lng: -42.0172915,
    lat: -22.98887
  },
  {
    lng: -42.017371,
    lat: -22.9891493
  },
  {
    lng: -42.0175046,
    lat: -22.9893796
  },
  {
    lng: -42.0174724,
    lat: -22.9894627
  },
  {
    lng: -42.0174493,
    lat: -22.9896421
  },
  {
    lng: -42.0176066,
    lat: -22.9897116
  },
  {
    lng: -42.0176808,
    lat: -22.9900491
  },
  {
    lng: -42.017765,
    lat: -22.9901972
  },
  {
    lng: -42.0179146,
    lat: -22.9904061
  },
  {
    lng: -42.0179747,
    lat: -22.9906491
  },
  {
    lng: -42.0176851,
    lat: -22.9909524
  },
  {
    lng: -42.0173803,
    lat: -22.99108
  },
  {
    lng: -42.0172228,
    lat: -22.9909754
  },
  {
    lng: -42.0168866,
    lat: -22.991024
  },
  {
    lng: -42.0164133,
    lat: -22.9911905
  },
  {
    lng: -42.01599,
    lat: -22.9914241
  },
  {
    lng: -42.0158206,
    lat: -22.9914179
  },
  {
    lng: -42.0151125,
    lat: -22.9911858
  },
  {
    lng: -42.0152026,
    lat: -22.9914099
  },
  {
    lng: -42.0154103,
    lat: -22.9916389
  },
  {
    lng: -42.0153592,
    lat: -22.9920913
  },
  {
    lng: -42.0155898,
    lat: -22.9923094
  },
  {
    lng: -42.0155885,
    lat: -22.9926747
  },
  {
    lng: -42.0155086,
    lat: -22.9927227
  },
  {
    lng: -42.0154842,
    lat: -22.9929806
  },
  {
    lng: -42.0156387,
    lat: -22.9930965
  },
  {
    lng: -42.0158593,
    lat: -22.9934681
  },
  {
    lng: -42.0158092,
    lat: -22.9938286
  },
  {
    lng: -42.0159748,
    lat: -22.9940192
  },
  {
    lng: -42.0160999,
    lat: -22.99439
  },
  {
    lng: -42.0161342,
    lat: -22.9946475
  },
  {
    lng: -42.015999,
    lat: -22.9947236
  },
  {
    lng: -42.0159625,
    lat: -22.9948734
  },
  {
    lng: -42.0159325,
    lat: -22.9950993
  },
  {
    lng: -42.0158233,
    lat: -22.9951937
  },
  {
    lng: -42.0157026,
    lat: -22.9951823
  },
  {
    lng: -42.0156447,
    lat: -22.9954205
  },
  {
    lng: -42.0153882,
    lat: -22.9956596
  },
  {
    lng: -42.0154232,
    lat: -22.9957507
  },
  {
    lng: -42.0152549,
    lat: -22.9959438
  },
  {
    lng: -42.0150591,
    lat: -22.9959642
  },
  {
    lng: -42.014841,
    lat: -22.9959233
  },
  {
    lng: -42.0147924,
    lat: -22.9959901
  },
  {
    lng: -42.0148906,
    lat: -22.9961908
  },
  {
    lng: -42.014924,
    lat: -22.996638
  },
  {
    lng: -42.0145787,
    lat: -22.99705
  },
  {
    lng: -42.0143558,
    lat: -22.9971576
  },
  {
    lng: -42.0141581,
    lat: -22.9972028
  },
  {
    lng: -42.0140405,
    lat: -22.997299
  },
  {
    lng: -42.0138861,
    lat: -22.997269
  },
  {
    lng: -42.0135068,
    lat: -22.9974373
  },
  {
    lng: -42.0131007,
    lat: -22.9975569
  },
  {
    lng: -42.0129051,
    lat: -22.9975352
  },
  {
    lng: -42.0127464,
    lat: -22.9974737
  },
  {
    lng: -42.0125843,
    lat: -22.9970839
  },
  {
    lng: -42.0124594,
    lat: -22.9969471
  },
  {
    lng: -42.0125253,
    lat: -22.9967628
  },
  {
    lng: -42.0123313,
    lat: -22.9964753
  },
  {
    lng: -42.0123275,
    lat: -22.996204
  },
  {
    lng: -42.0124735,
    lat: -22.9957884
  },
  {
    lng: -42.0126115,
    lat: -22.9955853
  },
  {
    lng: -42.0129241,
    lat: -22.9952519
  },
  {
    lng: -42.0130214,
    lat: -22.994912
  },
  {
    lng: -42.0130522,
    lat: -22.9948171
  },
  {
    lng: -42.0131756,
    lat: -22.9947636
  },
  {
    lng: -42.0133607,
    lat: -22.9941994
  },
  {
    lng: -42.0133883,
    lat: -22.9939115
  },
  {
    lng: -42.0135241,
    lat: -22.993785
  },
  {
    lng: -42.0135492,
    lat: -22.9934322
  },
  {
    lng: -42.0134635,
    lat: -22.9933346
  },
  {
    lng: -42.0131598,
    lat: -22.9929649
  },
  {
    lng: -42.0130782,
    lat: -22.992867
  },
  {
    lng: -42.0129666,
    lat: -22.9926728
  },
  {
    lng: -42.0128688,
    lat: -22.9924513
  },
  {
    lng: -42.0128316,
    lat: -22.992279
  },
  {
    lng: -42.0127182,
    lat: -22.9920757
  },
  {
    lng: -42.0127676,
    lat: -22.9919507
  },
  {
    lng: -42.0127368,
    lat: -22.9917859
  },
  {
    lng: -42.0126078,
    lat: -22.9917073
  },
  {
    lng: -42.0125147,
    lat: -22.9916134
  },
  {
    lng: -42.0124034,
    lat: -22.9913797
  },
  {
    lng: -42.0124399,
    lat: -22.9912297
  },
  {
    lng: -42.0128778,
    lat: -22.9907559
  },
  {
    lng: -42.0129365,
    lat: -22.9906124
  },
  {
    lng: -42.0129633,
    lat: -22.9900148
  },
  {
    lng: -42.0129633,
    lat: -22.9895086
  },
  {
    lng: -42.0129579,
    lat: -22.9887542
  },
  {
    lng: -42.0129231,
    lat: -22.9884962
  },
  {
    lng: -42.0128226,
    lat: -22.9880542
  },
  {
    lng: -42.0126523,
    lat: -22.98759
  },
  {
    lng: -42.0124404,
    lat: -22.9873259
  },
  {
    lng: -42.0120461,
    lat: -22.9870764
  },
  {
    lng: -42.010729,
    lat: -22.9865332
  },
  {
    lng: -42.0103361,
    lat: -22.9863221
  },
  {
    lng: -42.0101473,
    lat: -22.9864162
  },
  {
    lng: -42.0100924,
    lat: -22.9864884
  },
  {
    lng: -42.0100401,
    lat: -22.986503
  },
  {
    lng: -42.0099757,
    lat: -22.9864213
  },
  {
    lng: -42.0098592,
    lat: -22.9863971
  },
  {
    lng: -42.0096209,
    lat: -22.9863728
  },
  {
    lng: -42.0095775,
    lat: -22.9864826
  },
  {
    lng: -42.0094855,
    lat: -22.9865247
  },
  {
    lng: -42.0092669,
    lat: -22.9863445
  },
  {
    lng: -42.0092832,
    lat: -22.9862508
  },
  {
    lng: -42.0087186,
    lat: -22.9858704
  },
  {
    lng: -42.0085361,
    lat: -22.9856216
  },
  {
    lng: -42.0083536,
    lat: -22.9851674
  },
  {
    lng: -42.008151,
    lat: -22.9845326
  },
  {
    lng: -42.0080168,
    lat: -22.9840226
  },
  {
    lng: -42.0079182,
    lat: -22.9832492
  },
  {
    lng: -42.0081614,
    lat: -22.9829746
  },
  {
    lng: -42.0083218,
    lat: -22.9827253
  },
  {
    lng: -42.0084622,
    lat: -22.9823487
  },
  {
    lng: -42.0085069,
    lat: -22.982135
  },
  {
    lng: -42.0086118,
    lat: -22.9818917
  },
  {
    lng: -42.0087459,
    lat: -22.9816037
  },
  {
    lng: -42.0087562,
    lat: -22.9814448
  },
  {
    lng: -42.0090484,
    lat: -22.981453
  },
  {
    lng: -42.0096603,
    lat: -22.9815354
  },
  {
    lng: -42.0102645,
    lat: -22.981083
  },
  {
    lng: -42.0104542,
    lat: -22.9805375
  },
  {
    lng: -42.0103448,
    lat: -22.9800707
  },
  {
    lng: -42.0101378,
    lat: -22.979715
  },
  {
    lng: -42.0096273,
    lat: -22.9795118
  },
  {
    lng: -42.0096833,
    lat: -22.9793216
  },
  {
    lng: -42.0103298,
    lat: -22.9789986
  },
  {
    lng: -42.0103985,
    lat: -22.9786069
  },
  {
    lng: -42.0104764,
    lat: -22.9786025
  },
  {
    lng: -42.0110255,
    lat: -22.9787946
  },
  {
    lng: -42.0117488,
    lat: -22.9789392
  },
  {
    lng: -42.0118528,
    lat: -22.9785577
  },
  {
    lng: -42.0121472,
    lat: -22.9784981
  },
  {
    lng: -42.0124204,
    lat: -22.9785476
  },
  {
    lng: -42.0126404,
    lat: -22.9783825
  },
  {
    lng: -42.0126307,
    lat: -22.9782804
  },
  {
    lng: -42.0127344,
    lat: -22.9781021
  },
  {
    lng: -42.0129057,
    lat: -22.9781994
  },
  {
    lng: -42.0130885,
    lat: -22.9781331
  },
  {
    lng: -42.0131885,
    lat: -22.9778927
  },
  {
    lng: -42.0133469,
    lat: -22.9779376
  },
  {
    lng: -42.0134091,
    lat: -22.978078
  },
  {
    lng: -42.0136198,
    lat: -22.9781008
  },
  {
    lng: -42.014016,
    lat: -22.9784537
  },
  {
    lng: -42.0142703,
    lat: -22.9785036
  },
  {
    lng: -42.0146703,
    lat: -22.9787544
  },
  {
    lng: -42.0150162,
    lat: -22.9789954
  },
  {
    lng: -42.0151748,
    lat: -22.9789787
  },
  {
    lng: -42.0154466,
    lat: -22.9790491
  },
  {
    lng: -42.0162991,
    lat: -22.9792866
  },
  {
    lng: -42.017022,
    lat: -22.9792744
  },
  {
    lng: -42.0171383,
    lat: -22.9790292
  },
  {
    lng: -42.0172458,
    lat: -22.9790088
  },
  {
    lng: -42.0173611,
    lat: -22.9787346
  },
  {
    lng: -42.0174281,
    lat: -22.9788035
  },
  {
    lng: -42.0178572,
    lat: -22.9789578
  },
  {
    lng: -42.0181253,
    lat: -22.97908
  },
  {
    lng: -42.0184993,
    lat: -22.9791389
  },
  {
    lng: -42.0188116,
    lat: -22.9792473
  },
  {
    lng: -42.0195478,
    lat: -22.9792643
  },
  {
    lng: -42.0197617,
    lat: -22.9787664
  },
  {
    lng: -42.0204298,
    lat: -22.9776074
  },
  {
    lng: -42.0208823,
    lat: -22.9761609
  },
  {
    lng: -42.0208903,
    lat: -22.9749736
  },
  {
    lng: -42.0205016,
    lat: -22.9734247
  },
  {
    lng: -42.0200415,
    lat: -22.971684
  },
  {
    lng: -42.0195847,
    lat: -22.9708383
  },
  {
    lng: -42.0194017,
    lat: -22.9705326
  },
  {
    lng: -42.0191815,
    lat: -22.9702949
  },
  {
    lng: -42.018816,
    lat: -22.9699878
  },
  {
    lng: -42.0183587,
    lat: -22.9697619
  },
  {
    lng: -42.0180329,
    lat: -22.9700532
  },
  {
    lng: -42.0180094,
    lat: -22.9700751
  },
  {
    lng: -42.0176513,
    lat: -22.9703936
  },
  {
    lng: -42.0177813,
    lat: -22.9704843
  },
  {
    lng: -42.017766,
    lat: -22.9705048
  },
  {
    lng: -42.0175167,
    lat: -22.9708961
  },
  {
    lng: -42.0174993,
    lat: -22.9709208
  },
  {
    lng: -42.0174758,
    lat: -22.9709541
  },
  {
    lng: -42.0173895,
    lat: -22.9709999
  },
  {
    lng: -42.0171966,
    lat: -22.9708798
  },
  {
    lng: -42.0169522,
    lat: -22.9708827
  },
  {
    lng: -42.0168319,
    lat: -22.9708528
  },
  {
    lng: -42.0161184,
    lat: -22.9709591
  },
  {
    lng: -42.0161409,
    lat: -22.9710915
  },
  {
    lng: -42.0142241,
    lat: -22.9713527
  },
  {
    lng: -42.0142048,
    lat: -22.9712368
  },
  {
    lng: -42.0137002,
    lat: -22.9713478
  },
  {
    lng: -42.0136022,
    lat: -22.9731497
  },
  {
    lng: -42.0136737,
    lat: -22.9732529
  },
  {
    lng: -42.013669,
    lat: -22.9735386
  },
  {
    lng: -42.0134118,
    lat: -22.9737126
  },
  {
    lng: -42.013228,
    lat: -22.9735846
  },
  {
    lng: -42.0133669,
    lat: -22.9712156
  },
  {
    lng: -42.0134723,
    lat: -22.9709655
  },
  {
    lng: -42.0133117,
    lat: -22.9708345
  },
  {
    lng: -42.013416,
    lat: -22.9706394
  },
  {
    lng: -42.0131153,
    lat: -22.9706848
  },
  {
    lng: -42.0128467,
    lat: -22.9706908
  },
  {
    lng: -42.0122477,
    lat: -22.9707597
  },
  {
    lng: -42.0119513,
    lat: -22.9707582
  },
  {
    lng: -42.0118677,
    lat: -22.970525
  },
  {
    lng: -42.011994,
    lat: -22.9703376
  },
  {
    lng: -42.0118954,
    lat: -22.9701236
  },
  {
    lng: -42.0119675,
    lat: -22.9699096
  },
  {
    lng: -42.0122471,
    lat: -22.9698772
  },
  {
    lng: -42.0126808,
    lat: -22.9698662
  },
  {
    lng: -42.0128184,
    lat: -22.9699037
  },
  {
    lng: -42.0129674,
    lat: -22.969718
  },
  {
    lng: -42.013347,
    lat: -22.9692783
  },
  {
    lng: -42.0136835,
    lat: -22.9690215
  },
  {
    lng: -42.0139075,
    lat: -22.9689153
  },
  {
    lng: -42.0143085,
    lat: -22.968793
  },
  {
    lng: -42.0144144,
    lat: -22.9687696
  },
  {
    lng: -42.0145632,
    lat: -22.968777
  },
  {
    lng: -42.0146947,
    lat: -22.9687831
  },
  {
    lng: -42.0148288,
    lat: -22.9687659
  },
  {
    lng: -42.0149348,
    lat: -22.9687399
  },
  {
    lng: -42.015077,
    lat: -22.9686078
  },
  {
    lng: -42.0152124,
    lat: -22.9684991
  },
  {
    lng: -42.0153746,
    lat: -22.9684201
  },
  {
    lng: -42.0155087,
    lat: -22.9683843
  },
  {
    lng: -42.0157327,
    lat: -22.9683139
  },
  {
    lng: -42.0159702,
    lat: -22.9681744
  },
  {
    lng: -42.016005,
    lat: -22.9679657
  },
  {
    lng: -42.0159876,
    lat: -22.9677472
  },
  {
    lng: -42.0159338,
    lat: -22.9674582
  },
  {
    lng: -42.0158587,
    lat: -22.9672026
  },
  {
    lng: -42.0156456,
    lat: -22.9668025
  },
  {
    lng: -42.0151748,
    lat: -22.9659802
  },
  {
    lng: -42.014959,
    lat: -22.965606
  },
  {
    lng: -42.0147483,
    lat: -22.9653146
  },
  {
    lng: -42.0145015,
    lat: -22.9650405
  },
  {
    lng: -42.0141328,
    lat: -22.9647009
  },
  {
    lng: -42.0137988,
    lat: -22.9644416
  },
  {
    lng: -42.0135091,
    lat: -22.9642267
  },
  {
    lng: -42.0131578,
    lat: -22.9640353
  },
  {
    lng: -42.0129607,
    lat: -22.9639798
  },
  {
    lng: -42.0128734,
    lat: -22.9639761
  },
  {
    lng: -42.0128065,
    lat: -22.9639786
  },
  {
    lng: -42.0127232,
    lat: -22.9639899
  },
  {
    lng: -42.0126793,
    lat: -22.9640425
  },
  {
    lng: -42.0124404,
    lat: -22.9642218
  },
  {
    lng: -42.0121185,
    lat: -22.9645824
  },
  {
    lng: -42.011743,
    lat: -22.9647947
  },
  {
    lng: -42.0114479,
    lat: -22.9649207
  },
  {
    lng: -42.0111769,
    lat: -22.9650985
  },
  {
    lng: -42.0108954,
    lat: -22.9652245
  },
  {
    lng: -42.0106996,
    lat: -22.9652911
  },
  {
    lng: -42.010359,
    lat: -22.9654369
  },
  {
    lng: -42.0099996,
    lat: -22.9655455
  },
  {
    lng: -42.0097394,
    lat: -22.9656369
  },
  {
    lng: -42.009506,
    lat: -22.9657036
  },
  {
    lng: -42.0091412,
    lat: -22.9657209
  },
  {
    lng: -42.008814,
    lat: -22.9656591
  },
  {
    lng: -42.0084466,
    lat: -22.9655974
  },
  {
    lng: -42.008173,
    lat: -22.9655282
  },
  {
    lng: -42.0077814,
    lat: -22.9655579
  },
  {
    lng: -42.007607,
    lat: -22.9656073
  },
  {
    lng: -42.0074568,
    lat: -22.9656739
  },
  {
    lng: -42.0073013,
    lat: -22.9657875
  },
  {
    lng: -42.0071966,
    lat: -22.9658863
  },
  {
    lng: -42.0070606,
    lat: -22.9660827
  },
  {
    lng: -42.0069277,
    lat: -22.96624
  },
  {
    lng: -42.0066613,
    lat: -22.9663266
  },
  {
    lng: -42.0065809,
    lat: -22.9665994
  },
  {
    lng: -42.0063017,
    lat: -22.966718
  },
  {
    lng: -42.0063293,
    lat: -22.9668324
  },
  {
    lng: -42.0061637,
    lat: -22.9669594
  },
  {
    lng: -42.0061702,
    lat: -22.9671709
  },
  {
    lng: -42.0058877,
    lat: -22.9674549
  },
  {
    lng: -42.0056117,
    lat: -22.9676074
  },
  {
    lng: -42.0053812,
    lat: -22.9680066
  },
  {
    lng: -42.0050045,
    lat: -22.9682935
  },
  {
    lng: -42.0048665,
    lat: -22.9685984
  },
  {
    lng: -42.0048803,
    lat: -22.9688525
  },
  {
    lng: -42.0047561,
    lat: -22.9692082
  },
  {
    lng: -42.0043008,
    lat: -22.9692972
  },
  {
    lng: -42.0039144,
    lat: -22.9692718
  },
  {
    lng: -42.0036734,
    lat: -22.969366
  },
  {
    lng: -42.0032066,
    lat: -22.9690291
  },
  {
    lng: -42.0028273,
    lat: -22.9688808
  },
  {
    lng: -42.0027276,
    lat: -22.9685603
  },
  {
    lng: -42.0027704,
    lat: -22.9684421
  },
  {
    lng: -42.0028104,
    lat: -22.9683316
  },
  {
    lng: -42.0027508,
    lat: -22.9680372
  },
  {
    lng: -42.0028303,
    lat: -22.9678613
  },
  {
    lng: -42.0027552,
    lat: -22.9675566
  },
  {
    lng: -42.0027828,
    lat: -22.9673025
  },
  {
    lng: -42.0026173,
    lat: -22.9670992
  },
  {
    lng: -42.0026035,
    lat: -22.9667434
  },
  {
    lng: -42.0024517,
    lat: -22.9665274
  },
  {
    lng: -42.0025759,
    lat: -22.9661463
  },
  {
    lng: -42.0026035,
    lat: -22.9658413
  },
  {
    lng: -42.0026725,
    lat: -22.9655618
  },
  {
    lng: -42.0025897,
    lat: -22.9651552
  },
  {
    lng: -42.0023275,
    lat: -22.9650028
  },
  {
    lng: -42.0020653,
    lat: -22.9648757
  },
  {
    lng: -42.0018721,
    lat: -22.9648376
  },
  {
    lng: -42.0016396,
    lat: -22.9646073
  },
  {
    lng: -42.0014305,
    lat: -22.9646851
  },
  {
    lng: -42.0010165,
    lat: -22.9646978
  },
  {
    lng: -42.0005902,
    lat: -22.9646134
  },
  {
    lng: -42.0002714,
    lat: -22.9647105
  },
  {
    lng: -41.9999816,
    lat: -22.964863
  },
  {
    lng: -41.9998712,
    lat: -22.9649901
  },
  {
    lng: -41.9997332,
    lat: -22.9649011
  },
  {
    lng: -41.9995033,
    lat: -22.9648943
  },
  {
    lng: -41.9993882,
    lat: -22.9645835
  },
  {
    lng: -41.9987673,
    lat: -22.9645708
  },
  {
    lng: -41.9984913,
    lat: -22.9646851
  },
  {
    lng: -41.9980497,
    lat: -22.9647105
  },
  {
    lng: -41.9979393,
    lat: -22.9646089
  },
  {
    lng: -41.9975805,
    lat: -22.9643294
  },
  {
    lng: -41.9971803,
    lat: -22.9641388
  },
  {
    lng: -41.9969167,
    lat: -22.9641108
  },
  {
    lng: -41.9967112,
    lat: -22.9639228
  },
  {
    lng: -41.9962972,
    lat: -22.9637195
  },
  {
    lng: -41.9962696,
    lat: -22.9636051
  },
  {
    lng: -41.9961065,
    lat: -22.9635001
  },
  {
    lng: -41.9960508,
    lat: -22.9633221
  },
  {
    lng: -41.9959108,
    lat: -22.9632748
  },
  {
    lng: -41.9958694,
    lat: -22.9631096
  },
  {
    lng: -41.9956486,
    lat: -22.9629317
  },
  {
    lng: -41.995552,
    lat: -22.9627666
  },
  {
    lng: -41.995345,
    lat: -22.9627157
  },
  {
    lng: -41.9952878,
    lat: -22.9623901
  },
  {
    lng: -41.9951051,
    lat: -22.9622735
  },
  {
    lng: -41.9949863,
    lat: -22.9619915
  },
  {
    lng: -41.9947365,
    lat: -22.9616815
  },
  {
    lng: -41.9947379,
    lat: -22.9613816
  },
  {
    lng: -41.9949035,
    lat: -22.9612292
  },
  {
    lng: -41.995447,
    lat: -22.9611778
  },
  {
    lng: -41.9955658,
    lat: -22.9605812
  },
  {
    lng: -41.9959246,
    lat: -22.9604668
  },
  {
    lng: -41.9959522,
    lat: -22.9601237
  },
  {
    lng: -41.9961326,
    lat: -22.9598717
  },
  {
    lng: -41.996242,
    lat: -22.9595901
  },
  {
    lng: -41.9965456,
    lat: -22.9595393
  },
  {
    lng: -41.9965318,
    lat: -22.9593614
  },
  {
    lng: -41.996794,
    lat: -22.9592597
  },
  {
    lng: -41.9969872,
    lat: -22.9595266
  },
  {
    lng: -41.9972493,
    lat: -22.9596409
  },
  {
    lng: -41.9973183,
    lat: -22.9593995
  },
  {
    lng: -41.9973122,
    lat: -22.9591578
  },
  {
    lng: -41.9975668,
    lat: -22.9590983
  },
  {
    lng: -41.9977567,
    lat: -22.9589244
  },
  {
    lng: -41.9979807,
    lat: -22.9589294
  },
  {
    lng: -41.9982843,
    lat: -22.9588277
  },
  {
    lng: -41.9986017,
    lat: -22.9587515
  },
  {
    lng: -41.9986845,
    lat: -22.9586244
  },
  {
    lng: -41.9987949,
    lat: -22.958383
  },
  {
    lng: -41.9989053,
    lat: -22.9583322
  },
  {
    lng: -41.9989467,
    lat: -22.9580908
  },
  {
    lng: -41.9993606,
    lat: -22.9583195
  },
  {
    lng: -41.9996642,
    lat: -22.9583068
  },
  {
    lng: -42.0000001,
    lat: -22.958385
  },
  {
    lng: -42.0006992,
    lat: -22.9587007
  },
  {
    lng: -42.0012097,
    lat: -22.9586879
  },
  {
    lng: -42.0014857,
    lat: -22.9588404
  },
  {
    lng: -42.0016789,
    lat: -22.9587642
  },
  {
    lng: -42.0019549,
    lat: -22.9589802
  },
  {
    lng: -42.0024379,
    lat: -22.9588785
  },
  {
    lng: -42.0026587,
    lat: -22.959031
  },
  {
    lng: -42.0031554,
    lat: -22.9591073
  },
  {
    lng: -42.0032106,
    lat: -22.9587896
  },
  {
    lng: -42.0034038,
    lat: -22.9588785
  },
  {
    lng: -42.0037902,
    lat: -22.9589167
  },
  {
    lng: -42.0037902,
    lat: -22.9590818
  },
  {
    lng: -42.0040837,
    lat: -22.9592332
  },
  {
    lng: -42.0042298,
    lat: -22.9592614
  },
  {
    lng: -42.0042318,
    lat: -22.9594757
  },
  {
    lng: -42.0042456,
    lat: -22.9596917
  },
  {
    lng: -42.0039931,
    lat: -22.959918
  },
  {
    lng: -42.0039696,
    lat: -22.9602254
  },
  {
    lng: -42.0041766,
    lat: -22.9605684
  },
  {
    lng: -42.0041214,
    lat: -22.960759
  },
  {
    lng: -42.0043482,
    lat: -22.9609756
  },
  {
    lng: -42.0045905,
    lat: -22.9611275
  },
  {
    lng: -42.0050891,
    lat: -22.9611642
  },
  {
    lng: -42.0056222,
    lat: -22.9612331
  },
  {
    lng: -42.0059153,
    lat: -22.961407
  },
  {
    lng: -42.0062051,
    lat: -22.9616739
  },
  {
    lng: -42.0065638,
    lat: -22.9619407
  },
  {
    lng: -42.0069605,
    lat: -22.9617514
  },
  {
    lng: -42.0071848,
    lat: -22.9614579
  },
  {
    lng: -42.0075574,
    lat: -22.9613181
  },
  {
    lng: -42.0076264,
    lat: -22.9610767
  },
  {
    lng: -42.0078886,
    lat: -22.9611275
  },
  {
    lng: -42.0082887,
    lat: -22.9607209
  },
  {
    lng: -42.0084819,
    lat: -22.9604287
  },
  {
    lng: -42.0087303,
    lat: -22.9604033
  },
  {
    lng: -42.0088821,
    lat: -22.9600221
  },
  {
    lng: -42.0091367,
    lat: -22.9599418
  },
  {
    lng: -42.0093099,
    lat: -22.959463
  },
  {
    lng: -42.0091443,
    lat: -22.9591835
  },
  {
    lng: -42.0090753,
    lat: -22.9588404
  },
  {
    lng: -42.0087993,
    lat: -22.9584974
  },
  {
    lng: -42.0086751,
    lat: -22.9581924
  },
  {
    lng: -42.0084543,
    lat: -22.9581162
  },
  {
    lng: -42.0082474,
    lat: -22.9578112
  },
  {
    lng: -42.0079852,
    lat: -22.9574809
  },
  {
    lng: -42.0077698,
    lat: -22.9574207
  },
  {
    lng: -42.0076213,
    lat: -22.9572173
  },
  {
    lng: -42.007792,
    lat: -22.956998
  },
  {
    lng: -42.007861,
    lat: -22.9566168
  },
  {
    lng: -42.0080298,
    lat: -22.9565294
  },
  {
    lng: -42.00793,
    lat: -22.9556511
  },
  {
    lng: -42.0079852,
    lat: -22.9552445
  },
  {
    lng: -42.0080128,
    lat: -22.95466
  },
  {
    lng: -42.0080128,
    lat: -22.9543043
  },
  {
    lng: -42.0078472,
    lat: -22.9539993
  },
  {
    lng: -42.0072814,
    lat: -22.9536816
  },
  {
    lng: -42.0068879,
    lat: -22.9537503
  },
  {
    lng: -42.006826,
    lat: -22.9535546
  },
  {
    lng: -42.0063983,
    lat: -22.9534275
  },
  {
    lng: -42.0062741,
    lat: -22.9533004
  },
  {
    lng: -42.0059153,
    lat: -22.9533004
  },
  {
    lng: -42.0055427,
    lat: -22.9531988
  },
  {
    lng: -42.0051701,
    lat: -22.9532369
  },
  {
    lng: -42.0050873,
    lat: -22.9531352
  },
  {
    lng: -42.0048389,
    lat: -22.9531988
  },
  {
    lng: -42.0045974,
    lat: -22.9529881
  },
  {
    lng: -42.0045905,
    lat: -22.9527413
  },
  {
    lng: -42.0044526,
    lat: -22.9526143
  },
  {
    lng: -42.0042732,
    lat: -22.9523983
  },
  {
    lng: -42.0039037,
    lat: -22.9522247
  },
  {
    lng: -42.0036798,
    lat: -22.952106
  },
  {
    lng: -42.0035142,
    lat: -22.951801
  },
  {
    lng: -42.0035694,
    lat: -22.9515216
  },
  {
    lng: -42.003459,
    lat: -22.951242
  },
  {
    lng: -42.003252,
    lat: -22.9510768
  },
  {
    lng: -42.0028518,
    lat: -22.9511277
  },
  {
    lng: -42.0026587,
    lat: -22.9510387
  },
  {
    lng: -42.0023137,
    lat: -22.9510768
  },
  {
    lng: -42.00201,
    lat: -22.95096
  },
  {
    lng: -42.0018307,
    lat: -22.951026
  },
  {
    lng: -42.0015409,
    lat: -22.9508353
  },
  {
    lng: -42.0015271,
    lat: -22.9506574
  },
  {
    lng: -42.0013142,
    lat: -22.9503104
  },
  {
    lng: -42.0013621,
    lat: -22.949797
  },
  {
    lng: -42.0015409,
    lat: -22.9496917
  },
  {
    lng: -42.0016375,
    lat: -22.9493359
  },
  {
    lng: -42.0018031,
    lat: -22.9492342
  },
  {
    lng: -42.0019549,
    lat: -22.9494757
  },
  {
    lng: -42.0022999,
    lat: -22.9494121
  },
  {
    lng: -42.0025207,
    lat: -22.9495646
  },
  {
    lng: -42.002769,
    lat: -22.9496027
  },
  {
    lng: -42.0028932,
    lat: -22.9497552
  },
  {
    lng: -42.0036246,
    lat: -22.949806
  },
  {
    lng: -42.0035694,
    lat: -22.9494757
  },
  {
    lng: -42.003942,
    lat: -22.9494884
  },
  {
    lng: -42.0040386,
    lat: -22.9497044
  },
  {
    lng: -42.004221,
    lat: -22.9500597
  },
  {
    lng: -42.0046181,
    lat: -22.9503652
  },
  {
    lng: -42.0049079,
    lat: -22.9506828
  },
  {
    lng: -42.0052805,
    lat: -22.9508226
  },
  {
    lng: -42.0059429,
    lat: -22.9513183
  },
  {
    lng: -42.0061692,
    lat: -22.9513488
  },
  {
    lng: -42.0062189,
    lat: -22.9515216
  },
  {
    lng: -42.0069916,
    lat: -22.9516232
  },
  {
    lng: -42.0075436,
    lat: -22.9515343
  },
  {
    lng: -42.0082887,
    lat: -22.9516105
  },
  {
    lng: -42.0087441,
    lat: -22.9518392
  },
  {
    lng: -42.0095169,
    lat: -22.9519154
  },
  {
    lng: -42.0105104,
    lat: -22.9517757
  },
  {
    lng: -42.0106898,
    lat: -22.9520298
  },
  {
    lng: -42.0109658,
    lat: -22.9521568
  },
  {
    lng: -42.011159,
    lat: -22.9524745
  },
  {
    lng: -42.0117524,
    lat: -22.9525634
  },
  {
    lng: -42.0120973,
    lat: -22.9527286
  },
  {
    lng: -42.0123595,
    lat: -22.9527032
  },
  {
    lng: -42.0125383,
    lat: -22.9528687
  },
  {
    lng: -42.0124561,
    lat: -22.9530209
  },
  {
    lng: -42.0127321,
    lat: -22.9532623
  },
  {
    lng: -42.0129115,
    lat: -22.9535673
  },
  {
    lng: -42.0132289,
    lat: -22.95358
  },
  {
    lng: -42.0133357,
    lat: -22.9538432
  },
  {
    lng: -42.0136072,
    lat: -22.9540212
  },
  {
    lng: -42.013974,
    lat: -22.9541009
  },
  {
    lng: -42.0144018,
    lat: -22.9544948
  },
  {
    lng: -42.0146088,
    lat: -22.9545076
  },
  {
    lng: -42.0152436,
    lat: -22.9550158
  },
  {
    lng: -42.0155453,
    lat: -22.9554393
  },
  {
    lng: -42.0156713,
    lat: -22.9557274
  },
  {
    lng: -42.0163613,
    lat: -22.9562229
  },
  {
    lng: -42.0163387,
    lat: -22.9565683
  },
  {
    lng: -42.016571,
    lat: -22.9569209
  },
  {
    lng: -42.0169823,
    lat: -22.9575063
  },
  {
    lng: -42.0173825,
    lat: -22.9584211
  },
  {
    lng: -42.0174239,
    lat: -22.9588404
  },
  {
    lng: -42.0173549,
    lat: -22.9592597
  },
  {
    lng: -42.0175268,
    lat: -22.9593931
  },
  {
    lng: -42.0177274,
    lat: -22.9598442
  },
  {
    lng: -42.0182819,
    lat: -22.9596152
  },
  {
    lng: -42.0189275,
    lat: -22.9597257
  },
  {
    lng: -42.0194356,
    lat: -22.9603988
  },
  {
    lng: -42.0205393,
    lat: -22.9615935
  },
  {
    lng: -42.0205876,
    lat: -22.9616078
  },
  {
    lng: -42.022232,
    lat: -22.9609967
  },
  {
    lng: -42.0240936,
    lat: -22.9596247
  },
  {
    lng: -42.0254192,
    lat: -22.9583838
  },
  {
    lng: -42.0262564,
    lat: -22.9573713
  },
  {
    lng: -42.0269268,
    lat: -22.9562402
  },
  {
    lng: -42.0258821,
    lat: -22.9553195
  },
  {
    lng: -42.0253127,
    lat: -22.9546714
  },
  {
    lng: -42.0251071,
    lat: -22.9545439
  },
  {
    lng: -42.0248382,
    lat: -22.9545694
  },
  {
    lng: -42.0241501,
    lat: -22.9545804
  },
  {
    lng: -42.0238812,
    lat: -22.9544676
  },
  {
    lng: -42.0239445,
    lat: -22.9542963
  },
  {
    lng: -42.024241,
    lat: -22.9540707
  },
  {
    lng: -42.0246009,
    lat: -22.9539469
  },
  {
    lng: -42.0246878,
    lat: -22.9537611
  },
  {
    lng: -42.0247076,
    lat: -22.9535609
  },
  {
    lng: -42.0247375,
    lat: -22.9532242
  },
  {
    lng: -42.0247237,
    lat: -22.9528684
  },
  {
    lng: -42.0248893,
    lat: -22.9527286
  },
  {
    lng: -42.0248658,
    lat: -22.9524138
  },
  {
    lng: -42.0247393,
    lat: -22.9521662
  },
  {
    lng: -42.0244902,
    lat: -22.9519551
  },
  {
    lng: -42.0243162,
    lat: -22.9517694
  },
  {
    lng: -42.0243004,
    lat: -22.951358
  },
  {
    lng: -42.0238733,
    lat: -22.9510667
  },
  {
    lng: -42.0235372,
    lat: -22.9507972
  },
  {
    lng: -42.023035,
    lat: -22.9505824
  },
  {
    lng: -42.022952,
    lat: -22.9504148
  },
  {
    lng: -42.023023,
    lat: -22.9502983
  },
  {
    lng: -42.0232169,
    lat: -22.9502218
  },
  {
    lng: -42.0234542,
    lat: -22.9500252
  },
  {
    lng: -42.023806,
    lat: -22.9498904
  },
  {
    lng: -42.024336,
    lat: -22.9498941
  },
  {
    lng: -42.0247037,
    lat: -22.9497229
  },
  {
    lng: -42.0248738,
    lat: -22.9495955
  },
  {
    lng: -42.0247907,
    lat: -22.9491767
  },
  {
    lng: -42.0243675,
    lat: -22.9489183
  },
  {
    lng: -42.0241817,
    lat: -22.9487398
  },
  {
    lng: -42.0241106,
    lat: -22.9485213
  },
  {
    lng: -42.0240394,
    lat: -22.94815
  },
  {
    lng: -42.0238773,
    lat: -22.9479278
  },
  {
    lng: -42.0237349,
    lat: -22.9477239
  },
  {
    lng: -42.02347,
    lat: -22.9474981
  },
  {
    lng: -42.0231497,
    lat: -22.9472395
  },
  {
    lng: -42.0231734,
    lat: -22.947123
  },
  {
    lng: -42.0232965,
    lat: -22.9469966
  },
  {
    lng: -42.0230762,
    lat: -22.946851
  },
  {
    lng: -42.0230953,
    lat: -22.9465657
  },
  {
    lng: -42.0228255,
    lat: -22.9463621
  },
  {
    lng: -42.0225737,
    lat: -22.9463518
  },
  {
    lng: -42.0225575,
    lat: -22.9461805
  },
  {
    lng: -42.0226262,
    lat: -22.9459558
  },
  {
    lng: -42.022709,
    lat: -22.9458668
  },
  {
    lng: -42.0233437,
    lat: -22.9459049
  },
  {
    lng: -42.0236611,
    lat: -22.9459939
  },
  {
    lng: -42.0239923,
    lat: -22.9459431
  },
  {
    lng: -42.0241579,
    lat: -22.9460701
  },
  {
    lng: -42.0243649,
    lat: -22.9459431
  },
  {
    lng: -42.0247375,
    lat: -22.946032
  },
  {
    lng: -42.0251238,
    lat: -22.9460574
  },
  {
    lng: -42.0255516,
    lat: -22.9461591
  },
  {
    lng: -42.0256206,
    lat: -22.9463497
  },
  {
    lng: -42.0260622,
    lat: -22.9464132
  },
  {
    lng: -42.0268901,
    lat: -22.946642
  },
  {
    lng: -42.0273869,
    lat: -22.9463117
  },
  {
    lng: -42.0276767,
    lat: -22.94621
  },
  {
    lng: -42.0278147,
    lat: -22.9462608
  },
  {
    lng: -42.0281484,
    lat: -22.946098
  },
  {
    lng: -42.0281192,
    lat: -22.9455128
  },
  {
    lng: -42.0277776,
    lat: -22.9451995
  },
  {
    lng: -42.02768,
    lat: -22.944876
  },
  {
    lng: -42.0277687,
    lat: -22.9446129
  },
  {
    lng: -42.0282655,
    lat: -22.9450737
  },
  {
    lng: -42.0294169,
    lat: -22.9453611
  },
  {
    lng: -42.0306966,
    lat: -22.9458407
  },
  {
    lng: -42.0322812,
    lat: -22.9467528
  },
  {
    lng: -42.03267,
    lat: -22.9464023
  },
  {
    lng: -42.0334481,
    lat: -22.9456146
  },
  {
    lng: -42.0338467,
    lat: -22.9445478
  },
  {
    lng: -42.033963,
    lat: -22.9430018
  },
  {
    lng: -42.0342431,
    lat: -22.94189
  },
  {
    lng: -42.0348589,
    lat: -22.9410966
  },
  {
    lng: -42.0359037,
    lat: -22.9393734
  },
  {
    lng: -42.0361181,
    lat: -22.9388269
  },
  {
    lng: -42.0365904,
    lat: -22.9376227
  },
  {
    lng: -42.0370668,
    lat: -22.9349556
  },
  {
    lng: -42.0373205,
    lat: -22.9332842
  },
  {
    lng: -42.0375448,
    lat: -22.9332805
  },
  {
    lng: -42.0405828,
    lat: -22.9332304
  },
  {
    lng: -42.0408121,
    lat: -22.9332267
  },
  {
    lng: -42.0588773,
    lat: -22.9329296
  },
  {
    lng: -42.0631015,
    lat: -22.9328602
  },
  {
    lng: -42.0638512,
    lat: -22.9328479
  },
  {
    lng: -42.0656179,
    lat: -22.9328188
  },
  {
    lng: -42.0805275,
    lat: -22.9325737
  },
  {
    lng: -42.0827543,
    lat: -22.9325371
  },
  {
    lng: -42.0829181,
    lat: -22.9325344
  },
  {
    lng: -42.084771,
    lat: -22.932504
  },
  {
    lng: -42.0904848,
    lat: -22.9324101
  },
  {
    lng: -42.1005315,
    lat: -22.9252068
  },
  {
    lng: -42.1123959,
    lat: -22.9127101
  },
  {
    lng: -42.1231676,
    lat: -22.9070176
  },
  {
    lng: -42.1346198,
    lat: -22.902352
  },
  {
    lng: -42.1483943,
    lat: -22.8942704
  },
  {
    lng: -42.1530395,
    lat: -22.8902082
  },
  {
    lng: -42.1591198,
    lat: -22.8806354
  },
  {
    lng: -42.1642857,
    lat: -22.875577
  },
  {
    lng: -42.1690568,
    lat: -22.873017
  },
  {
    lng: -42.172507,
    lat: -22.8726912
  },
  {
    lng: -42.1771748,
    lat: -22.8753015
  },
  {
    lng: -42.1798932,
    lat: -22.875529
  },
  {
    lng: -42.1875301,
    lat: -22.8737276
  },
  {
    lng: -42.1896482,
    lat: -22.8742126
  },
  {
    lng: -42.1957257,
    lat: -22.8779301
  },
  {
    lng: -42.1988984,
    lat: -22.8787659
  },
  {
    lng: -42.2060643,
    lat: -22.879104
  },
  {
    lng: -42.2106695,
    lat: -22.8777584
  },
  {
    lng: -42.214254,
    lat: -22.871754
  },
  {
    lng: -42.2157876,
    lat: -22.8703265
  },
  {
    lng: -42.2203162,
    lat: -22.868512
  },
  {
    lng: -42.226507,
    lat: -22.8685904
  },
  {
    lng: -42.234034,
    lat: -22.8738545
  },
  {
    lng: -42.237669,
    lat: -22.8636726
  },
  {
    lng: -42.2837865,
    lat: -22.9001023
  },
  {
    lng: -42.2807018,
    lat: -22.901952
  },
  {
    lng: -42.2772595,
    lat: -22.9064462
  },
  {
    lng: -42.2751998,
    lat: -22.914032
  },
  {
    lng: -42.2754279,
    lat: -22.9167976
  },
  {
    lng: -42.278257,
    lat: -22.9220315
  },
  {
    lng: -42.2825065,
    lat: -22.9261223
  },
  {
    lng: -42.2836734,
    lat: -22.9263989
  },
  {
    lng: -42.2893682,
    lat: -22.9271637
  },
  {
    lng: -42.2909532,
    lat: -22.9280629
  },
  {
    lng: -42.2910578,
    lat: -22.938079
  },
  {
    lng: -42.2910825,
    lat: -22.9387315
  },
];

export default polygonCoords_ArraialDoCabo;