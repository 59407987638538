import React, { Component } from 'react';
import api from '../services/api';
import apiString from '../services/apiString';
import apiStringServer from '../services/apiStringServer';

import Header from '../components/Header';
import FooterHome from '../components/FooterHome';
import Table from '../components/Table';

import Accordion from '@material-ui/core/Accordion';
import Divider from '@material-ui/core/Divider';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import House from '@material-ui/icons/House';
import Check from '@material-ui/icons/Check';
import Report from '@material-ui/icons/Report';
import LocationOn from '@material-ui/icons/LocationOn';
import Close from '@material-ui/icons/Close';
import DoNotDisturb from '@material-ui/icons/NotInterestedOutlined';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Add from '@material-ui/icons/Add';
import Apartment from '@material-ui/icons/Apartment';
import Button from '@material-ui/core/Button';
import MapIcon from '@material-ui/icons/Map';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Assignment from '@material-ui/icons/Assignment';
import AssignmentTurnedIn from '@material-ui/icons/AssignmentTurnedIn';

import { ThemeProvider } from '@material-ui/core';
import theme from '../themes/theme';
import themeDark from '../themes/themeDark';

import { Map, GoogleApiWrapper, Marker, InfoWindow, Polygon } from 'google-maps-react';

import yellow_marker from '../assets/yellow_marker.png';
import green_marker from '../assets/green_marker.png';
import red_marker from '../assets/red_marker.png';

import polygonCoords_CaboFrio from './utils/polygonCaboFrio';

let polygonCoords_Mendes_Vila_Mariana = [
  { lat: -22.52066804820888, lng: -43.719215762900575 }, //-22.52066804820888, -43.719215762900575
  { lat: -22.520740262185132, lng: -43.715306985340206 }, //-22.520740262185132, -43.715306985340206
  { lat: -22.52000367784743, lng: -43.71506724695532 }, //-22.52000367784743, -43.71506724695532
  { lat: -22.521712740046603, lng: -43.71432197336714 }, //-22.521712740046603, -43.71432197336714
  { lat: -22.521712740059108, lng: -43.71327963265148 }, //-22.521712740059108, -43.71327963265148

  { lat: -22.521828281536926, lng: -43.71292523680763 }, //-22.521828281536926, -43.71292523680763
  { lat: -22.522637069132568, lng: -43.71079365008844 }, //-22.522637069132568, -43.71079365008844
  { lat: -22.522853707860946, lng: -43.7092301390643 }, //-22.522853707860946, -43.7092301390643
  { lat: -22.522661140128736, lng: -43.70908421132953 }, //-22.522661140128736, -43.70908421132953
  { lat: -22.52265632593176, lng: -43.70875587401446 }, //-22.52265632593176, -43.70875587401446
  { lat: -22.522949991640786, lng: -43.708953918744186 }, //-22.522949991640786, -43.708953918744186

  { lat: -22.523055903710574, lng: -43.708959130447596 }, //-22.523055903710574, -43.708959130447596
  { lat: -22.523176258236777, lng: -43.70891743682029 }, //-22.523176258236777, -43.70891743682029
  { lat: -22.523238842548963, lng: -43.70891743682029 }, //-22.523238842548963, -43.70891743682029
  { lat: -22.523729887727654, lng: -43.70924577414035 }, //-22.523729887727654, -43.70924577414035
  { lat: -22.524004294555805, lng: -43.70951678271787 }, //-22.524004294555805, -43.70951678271787
  { lat: -22.52443756738537, lng: -43.70921971562328 }, //-22.52443756738537, -43.70921971562328

  { lat: -22.524389426026968, lng: -43.7099024487705 }, //-22.524389426026968, -43.7099024487705
  { lat: -22.52454347832499, lng: -43.71072589792764 }, //-22.52454347832499, -43.71072589792764
  { lat: -22.525159685758624, lng: -43.71091873095394 }, //-22.525159685758624, -43.71091873095394
  { lat: -22.524933422410907, lng: -43.71114283420074 }, //-22.524933422410907, -43.71114283420074
  { lat: -22.52458680549933, lng: -43.7131024347172 }, //-22.52458680549933, -43.7131024347172
  { lat: -22.524610876146237, lng: -43.71329526774351 }, //-22.524610876146237, -43.71329526774351

  { lat: -22.524394240171922, lng: -43.714530441474935 }, //-22.524394240171922, -43.714530441474935
  { lat: -22.52456273484709, lng: -43.71485356708659 }, //-22.52456273484709, -43.71485356708659
  { lat: -22.52497674947465, lng: -43.71487962561078 }, //-22.52497674947465, -43.71487962561078
  { lat: -22.525169313988993, lng: -43.71502555330636 }, //-22.525169313988993, -43.71502555330636
  { lat: -22.525491858949206, lng: -43.71563532260578 }, //-22.525491858949206, -43.71563532260578
  { lat: -22.52567479456213, lng: -43.715739556674045 }, //-22.52567479456213, -43.715739556674045

  { lat: -22.525718121382347, lng: -43.71592717799695 }, //-22.525718121382347, -43.71592717799695
  { lat: -22.52553999991723, lng: -43.71608352913202 }, //-22.52553999991723, -43.71608352913202
  { lat: -22.523455480344282, lng: -43.71867374576091 }, //-22.523455480344282, -43.71867374576091
  { lat: -22.52276705241361, lng: -43.71917928099891 }, //-22.52276705241361, -43.71917928099891
  { lat: -22.522531156744968, lng: -43.71922097462622 }, //-22.522531156744968, -43.71922097462622
  { lat: -22.522107506349684, lng: -43.71906462351483 }, //-22.522107506349684, -43.71906462351483

  { lat: -22.521876423779357, lng: -43.71909068203191 }, //-22.521876423779357, -43.71909068203191
  { lat: -22.52095208963347, lng: -43.718762344716836 }, //-22.52095208963347, -43.718762344716836
  { lat: -22.52077396201678, lng: -43.71890827240952 }, //-22.52077396201678, -43.71890827240952
  { lat: -22.520802847589657, lng: -43.71907504691875 } //-22.520802847589657, -43.71907504691875
];

let polygonCoords_Mendes_Tupinamba = [
  { lat: -22.521075366327636, lng: -43.729608981298995 }, //-22.521075366327636, -43.729608981298995
  { lat: -22.523338046312038, lng: -43.72483506097194 }, //-22.523338046312038, -43.72483506097194
  { lat: -22.52247149239808, lng: -43.72321943291366 }, //-22.52247149239808, -43.72321943291366
  { lat: -22.52309733745907, lng: -43.72248979438264 }, //-22.52309733745907, -43.72248979438264
  { lat: -22.523318789639863, lng: -43.72242725394167 }, //-22.523318789639863, -43.72242725394167

  { lat: -22.523598011448843, lng: -43.722208362398284 }, //-22.523598011448843, -43.722208362398284
  { lat: -22.52376169293658, lng: -43.72241683053485 }, //-22.52376169293658, -43.72241683053485
  { lat: -22.523954259144496, lng: -43.722521064603114 }, //-22.523954259144496, -43.722521064603114
  { lat: -22.524252736236342, lng: -43.72250021778947 }, //-22.524252736236342, -43.72250021778947
  { lat: -22.524560840945096, lng: -43.72279207318064 }, //-22.524560840945096, -43.72279207318064
  { lat: -22.525735483878332, lng: -43.7220415878713 }, //-22.525735483878332, -43.7220415878713

  { lat: -22.52595693183048, lng: -43.72201031765082 }, //-22.52595693183048, -43.72201031765082
  { lat: -22.52639982666981, lng: -43.72165592181869 }, //-22.52639982666981, -43.72165592181869
  { lat: -22.526775323356123, lng: -43.72162465159819 }, //-22.526775323356123, -43.72162465159819
  { lat: -22.52680420768594, lng: -43.72202074106521 }, //-22.52680420768594, -43.72202074106521
  { lat: -22.526226520174262, lng: -43.722958847679685 }, //-22.526226520174262, -43.722958847679685
  { lat: -22.524994112075422, lng: -43.72566893345488 }, //-22.524994112075422, -43.72566893345488

  { lat: -22.526553876727473, lng: -43.724293043753626 }, //-22.526553876727473, -43.724293043753626
  { lat: -22.52701602583494, lng: -43.724209656499 }, //-22.52701602583494, -43.724209656499
  { lat: -22.527901807302595, lng: -43.723063081747966 }, //-22.527901807302595, -43.723063081747966
  { lat: -22.528652791488945, lng: -43.72319858603673 }, //-22.528652791488945, -43.72319858603673
  { lat: -22.528335067918462, lng: -43.72464743963465 }, //-22.528335067918462, -43.72464743963465
  { lat: -22.528527627751497, lng: -43.72555427602867 }, //-22.528527627751497, -43.72555427602867

  { lat: -22.528286927918256, lng: -43.72662788693191 }, //-22.528286927918256, -43.72662788693191
  { lat: -22.527179703283146, lng: -43.727701497835156 }, //-22.527179703283146, -43.727701497835156
  { lat: -22.526794579590618, lng: -43.72787869575122 }, //-22.526794579590618, -43.72787869575122
  { lat: -22.525523663744647, lng: -43.727472182886785 }, //-22.525523663744647, -43.727472182886785
  { lat: -22.524599353998692, lng: -43.7281184341101 }, //-22.524599353998692, -43.7281184341101
  { lat: -22.524098683637604, lng: -43.728910613029 }, //-22.524098683637604, -43.728910613029

  { lat: -22.52328027621524, lng: -43.728764685348125 }, //-22.52328027621524, -43.728764685348125
  { lat: -22.522615918422883, lng: -43.72935881953729 }, //-22.522615918422883, -43.72935881953729
  { lat: -22.522327066212434, lng: -43.72937966635095 }, //-22.522327066212434, -43.72937966635095
  { lat: -22.521990071203692, lng: -43.72927543228268 }, //-22.521990071203692, -43.72927543228268
  { lat: -22.521701217684907, lng: -43.72943178338509 } //-22.521701217684907, -43.72943178338509
];

let polygonCoords_Vila_Modesto = [
  { lat: -22.532298882017457, lng: -43.7053020117844 }, //-22.532298882017457, -43.7053020117844
  { lat: -22.531347534506278, lng: -43.70536638479972 }, //-22.531347534506278, -43.70536638479972
  { lat: -22.530970957639383, lng: -43.704894316020685 }, //-22.530970957639383, -43.704894316020685
  { lat: -22.530396180441887, lng: -43.704540647503045 }, //-22.530396180441887, -43.704540647503045
  { lat: -22.529067530103774, lng: -43.70448853046891 }, //-22.529067530103774, -43.70448853046891

  { lat: -22.52793142764924, lng: -43.70542663708339 }, //-22.52793142764924, -43.70542663708339
  { lat: -22.52866315572492, lng: -43.706468977766164 }, //-22.52866315572492, -43.706468977766164
  { lat: -22.528056591936995, lng: -43.70722988646458 }, //-22.528056591936995, -43.70722988646458
  { lat: -22.52766184264426, lng: -43.70748004822843 }, //-22.52766184264426, -43.70748004822843
  { lat: -22.526516100460455, lng: -43.70909567630722 }, //-22.526516100460455, -43.70909567630722
  { lat: -22.525389605225737, lng: -43.710638340517704 }, //-22.525389605225737, -43.710638340517704

  { lat: -22.525081502344708, lng: -43.710909349117976 }, //-22.525081502344708, -43.710909349117976
  { lat: -22.52516815634368, lng: -43.710909349117976 }, //-22.52516815634368, -43.710909349117976
  { lat: -22.524937078892314, lng: -43.71109697044087 }, //-22.524937078892314, -43.71109697044087
  { lat: -22.524590461990574, lng: -43.713275462467855 }, //-22.524590461990574, -43.713275462467855
  { lat: -22.524388268392144, lng: -43.71454711816108 }, //-22.524388268392144, -43.71454711816108
  { lat: -22.524532692418262, lng: -43.71484939695908 }, //-22.524532692418262, -43.71484939695908

  { lat: -22.52485042474425, lng: -43.71484939695908 }, //-22.52485042474425, -43.71484939695908
  { lat: -22.525158528120098, lng: -43.71499532465467 }, //-22.525158528120098, -43.71499532465467
  { lat: -22.525466630808822, lng: -43.71562072906433 }, //-22.525466630808822, -43.71562072906433
  { lat: -22.525649566455144, lng: -43.7157249631326 }, //-22.525649566455144, -43.7157249631326
  { lat: -22.525726591917966, lng: -43.715912584455495 }, //-22.525726591917966, -43.715912584455495
  { lat: -22.527161183314213, lng: -43.716225286660325 }, //-22.527161183314213, -43.716225286660325

  { lat: -22.527228579859052, lng: -43.71645460161054 }, //-22.527228579859052, -43.71645460161054
  { lat: -22.527623330389915, lng: -43.716225286660325 }, //-22.527623330389915, -43.716225286660325
  { lat: -22.52746928155825, lng: -43.71478685648627 }, //-22.52746928155825, -43.71478685648627
  { lat: -22.527450025439496, lng: -43.71286894962998 }, //-22.527450025439496, -43.71286894962998
  { lat: -22.527777379120973, lng: -43.71212888772592 }, //-22.527777379120973, -43.71212888772592
  { lat: -22.527747910872296, lng: -43.71190788283176 }, //-22.527747910872296, -43.71190788283176

  { lat: -22.52816113852526, lng: -43.71194740392239 }, //-22.52816113852526, -43.71194740392239
  { lat: -22.528922292513997, lng: -43.711010997925975 }, //-22.528922292513997, -43.711010997925975
  { lat: -22.52893382511792, lng: -43.71098602709939 }, //-22.52893382511792, -43.71098602709939
  { lat: -22.52956279614979, lng: -43.70811378676631 } //-22.52956279614979, -43.70811378676631
];

let polygonCoords_Cinco_Lagos = [
  { lat: -22.509430777956254, lng: -43.71155456791006 }, //-22.509430777956254, -43.71155456791006
  { lat: -22.49614270582512, lng: -43.71714803283095 }, //-22.49614270582512, -43.71714803283095
  { lat: -22.48866760410613, lng: -43.69252679865715 }, //-22.48866760410613, -43.69252679865715
  { lat: -22.48294564844685, lng: -43.691627848900005 }, //-22.48294564844685, -43.691627848900005
  { lat: -22.47533138917616, lng: -43.70411326181583 }, //-22.47533138917616, -43.70411326181583

  { lat: -22.468962778238776, lng: -43.72359050590924 }, //-22.468962778238776, -43.72359050590924
  { lat: -22.483822414912567, lng: -43.73592609398652 }, //-22.483822414912567, -43.73592609398652
  { lat: -22.48183698602537, lng: -43.73947571124458 }, //-22.48183698602537, -43.73947571124458
  { lat: -22.482330141932202, lng: -43.74110112821455 }, //-22.482330141932202, -43.74110112821455
  { lat: -22.480200592476987, lng: -43.741416507626646 }, //-22.480200592476987, -43.741416507626646
  { lat: -22.47320647327839, lng: -43.74546792010615 }, //-22.47320647327839, -43.74546792010615

  { lat: -22.473408231499846, lng: -43.74670517789866 }, //-22.473408231499846, -43.74670517789866
  { lat: -22.46594297555066, lng: -43.7515814288086 }, //-22.46594297555066, -43.7515814288086
  { lat: -22.46527041021689, lng: -43.74988323197429 }, //-22.46527041021689, -43.74988323197429
  { lat: -22.463678659147877, lng: -43.74973767225616 }, //-22.463678659147877, -43.74973767225616
  { lat: -22.463364790602814, lng: -43.74888857383901 }, //-22.463364790602814, -43.74888857383901
  { lat: -22.46235592260733, lng: -43.75015009148735 }, //-22.46235592260733, -43.75015009148735

  { lat: -22.46112285174987, lng: -43.75296424624134 }, //-22.46112285174987, -43.75296424624134
  { lat: -22.460046708217934, lng: -43.753934644432384 }, //-22.460046708217934, -43.753934644432384
  { lat: -22.46000186872278, lng: -43.75441984352789 }, //-22.46000186872278, -43.75441984352789
  { lat: -22.462350476908203, lng: -43.75332846861483 }, //-22.462350476908203, -43.75332846861483
  { lat: -22.466172606594643, lng: -43.754330701605575 }, //-22.466172606594643, -43.754330701605575
  { lat: -22.46708572782226, lng: -43.75260855477533 }, //-22.46708572782226, -43.75260855477533

  { lat: -22.474108569413698, lng: -43.74750740775737 }, //-22.474108569413698, -43.74750740775737
  { lat: -22.477435903022055, lng: -43.74957346898818 }, //-22.477435903022055, -43.74957346898818
  { lat: -22.4784504785763, lng: -43.749615398641176 }, //-22.4784504785763, -43.749615398641176
  { lat: -22.48413001360927, lng: -43.74862300073401 }, //-22.48413001360927, -43.74862300073401
  { lat: -22.490492558562114, lng: -43.75129644236136 }, //-22.490492558562114, -43.75129644236136
  { lat: -22.490660974951204, lng: -43.75042554840469 }, //-22.490660974951204, -43.75042554840469

  { lat: -22.488415406976824, lng: -43.743600170945804 }, //-22.488415406976824, -43.743600170945804
  { lat: -22.48888323663609, lng: -43.74218243676242 }, //-22.48888323663609, -43.74218243676242
  { lat: -22.495657232922024, lng: -43.73950899515948 }, //-22.495657232922024, -43.73950899515948
  { lat: -22.495339124517702, lng: -43.7388001280076 }, //-22.495339124517702, -43.7388001280076

  { lat: -22.495844355201676, lng: -43.73754442058803 }, //-22.495844355201676, -43.73754442058803
  { lat: -22.495563671716127, lng: -43.73645073993228 }, //-22.495563671716127, -43.73645073993228
  { lat: -22.49498359070821, lng: -43.736430486586805 } //-22.49498359070821, -43.736430486586805
];

let polygonCoords_Martins_Costa = [
  { lat: -22.538028246691034, lng: -43.739321404913184 }, //-22.538028246691034, -43.739321404913184
  { lat: -22.533580363804354, lng: -43.74029180311569 }, //-22.533580363804354, -43.74029180311569
  { lat: -22.53107066387474, lng: -43.75112387330002 }, //-22.53107066387474, -43.75112387330002
  { lat: -22.529658937615608, lng: -43.75464156687462 }, //-22.529658937615608, -43.75464156687462
  { lat: -22.529770979906637, lng: -43.75481138656154 }, //-22.529770979906637, -43.75481138656154

  { lat: -22.53163086869133, lng: -43.76222280297175 }, //-22.53163086869133, -43.76222280297175
  { lat: -22.531798929694286, lng: -43.762368362703405 }, //-22.531798929694286, -43.762368362703405
  { lat: -22.531933378349404, lng: -43.762307712815215 }, //-22.531933378349404, -43.762307712815215
  { lat: -22.531989398583757, lng: -43.762186413038826 }, //-22.531989398583757, -43.762186413038826
  { lat: -22.53196699049275, lng: -43.76167695397801 }, //-22.53196699049275, -43.76167695397801
  { lat: -22.532023010713452, lng: -43.76156778417927 }, //-22.532023010713452, -43.76156778417927

  { lat: -22.532213479293873, lng: -43.76054886605764 }, //-22.532213479293873, -43.76054886605764
  { lat: -22.53240394761161, lng: -43.76052460610235 }, //-22.53240394761161, -43.76052460610235
  { lat: -22.533501936321226, lng: -43.76145861438052 }, //-22.533501936321226, -43.76145861438052
  { lat: -22.533804441914715, lng: -43.7610461951262 }, //-22.533804441914715, -43.7610461951262
  { lat: -22.53390527695398, lng: -43.760560996020665 }, //-22.53390527695398, -43.760560996020665
  { lat: -22.53371802050911, lng: -43.75887458381207 }, //-22.53371802050911, -43.75887458381207

  { lat: -22.544277354571683, lng: -43.77677921187888 }, //-22.544277354571683, -43.77677921187888
  { lat: -22.551597584773553, lng: -43.77152807598189 }, //-22.551597584773553, -43.77152807598189
  { lat: -22.55641171415796, lng: -43.76948125415054 }, //-22.55641171415796, -43.76948125415054
  { lat: -22.55504298773838, lng: -43.76639861949981 }, //-22.55504298773838, -43.76639861949981
  { lat: -22.550434597118794, lng: -43.758317234270685 } //-22.550434597118794, -43.758317234270685
];

let polygonCoords_Morsing = [
  { lat: -22.542803201225286, lng: -43.793166105290226 }, //-22.542803201225286, -43.793166105290226
  { lat: -22.545013207345903, lng: -43.78084290893676 }, //-22.545013207345903, -43.78084290893676
  { lat: -22.544350209225254, lng: -43.77677505771329 }, //-22.544350209225254, -43.77677505771329
  { lat: -22.53374809813133, lng: -43.75906365226653 }, //-22.53374809813133, -43.75906365226653
  { lat: -22.533962822925464, lng: -43.760690965944775 }, //-22.533962822925464, -43.760690965944775

  { lat: -22.533479691669157, lng: -43.76156274112955 }, //-22.533479691669157, -43.76156274112955
  { lat: -22.532298697038073, lng: -43.76051661090782 }, //-22.532298697038073, -43.76051661090782
  { lat: -22.53194871314101, lng: -43.762296419028914 }, //-22.53194871314101, -43.762296419028914
  { lat: -22.51580247684904, lng: -43.76069828609534 }, //-22.51580247684904, -43.76069828609534
  { lat: -22.511955857683294, lng: -43.74986401194859 }, //-22.511955857683294, -43.74986401194859
  { lat: -22.510669634656544, lng: -43.750072858699546 }, //-22.510669634656544, -43.750072858699546

  { lat: -22.509061838882747, lng: -43.74944631835724 }, //-22.509061838882747, -43.74944631835724
  { lat: -22.50475285445081, lng: -43.74986401185916 }, //-22.50475285445081, -43.74986401185916
  { lat: -22.503273619675536, lng: -43.749446318308046 }, //-22.503273619675536, -43.749446318308046
  { lat: -22.49922172222791, lng: -43.74972478064265 }, //-22.49922172222791, -43.74972478064265
  { lat: -22.495234024636027, lng: -43.74930708712955 }, //-22.495234024636027, -43.74930708712955
  { lat: -22.4904100434115, lng: -43.75097786113721 }, //-22.4904100434115, -43.75097786113721

  { lat: -22.48397780659315, lng: -43.74826285331663 }, //-22.48397780659315, -43.74826285331663
  { lat: -22.477609597802108, lng: -43.74930708709824 }, //-22.477609597802108, -43.74930708709824
  { lat: -22.47426456283731, lng: -43.74735785075597 }, //-22.47426456283731, -43.74735785075597

  { lat: -22.466916947505226, lng: -43.75256346798234 }, //-22.466916947505226, -43.75256346798234
  { lat: -22.4660162995947, lng: -43.75430385757365 }, //-22.4660162995947, -43.75430385757365
  { lat: -22.46247798327044, lng: -43.75339885498617 }, //-22.46247798327044, -43.75339885498617

  { lat: -22.459982927872016, lng: -43.754528255958256 }, //-22.459982927872016, -43.754528255958256
  { lat: -22.45893495183852, lng: -43.76583428001838 }, //-22.45893495183852, -43.76583428001838

  { lat: -22.517764519612943, lng: -43.76991348442389 }, //-22.517764519612943, -43.76991348442389
  { lat: -22.531139968005913, lng: -43.77771043018318 }, //-22.531139968005913, -43.77771043018318
  { lat: -22.53210449340115, lng: -43.783697370409314 }, //-22.53210449340115, -43.783697370409314
  { lat: -22.534355026793126, lng: -43.789127386096666 } //-22.534355026793126, -43.789127386096666
];

let polygonCoords_Regiao_Da_Gaudencia = [
  { lat: -22.52248686517238, lng: -43.72321251191306 }, //-22.52248686517238, -43.72321251191306
  { lat: -22.52268818609963, lng: -43.72290920678567 }, //-22.52268818609963, -43.72290920678567
  { lat: -22.522876085360767, lng: -43.72268399819163 }, //-22.522876085360767, -43.72268399819163
  { lat: -22.523045530018045, lng: -43.72253143752791 }, //-22.523045530018045, -43.72253143752791
  { lat: -22.52311599208867, lng: -43.72248240017275 }, //-22.52311599208867, -43.72248240017275

  { lat: -22.52332570041962, lng: -43.72241338463587 }, //-22.52332570041962, -43.72241338463587
  { lat: -22.52360419259812, lng: -43.72219362463338 }, //-22.52360419259812, -43.72219362463338
  { lat: -22.523446492276317, lng: -43.72194117305732 }, //-22.523446492276317, -43.72194117305732
  { lat: -22.523391129356273, lng: -43.72163241933515 }, //-22.523391129356273, -43.72163241933515
  { lat: -22.523404550674645, lng: -43.721240120484495 }, //-22.523404550674645, -43.721240120484495
  { lat: -22.523441459286893, lng: -43.72078607089972 }, //-22.523441459286893, -43.72078607089972

  { lat: -22.523475012563967, lng: -43.72068073139035 }, //-22.523475012563967, -43.72068073139035
  { lat: -22.523629357528375, lng: -43.72033383750336 }, //-22.523629357528375, -43.72033383750336
  { lat: -22.52364445648273, lng: -43.72026663816481 }, //-22.52364445648273, -43.72026663816481
  { lat: -22.523642778821213, lng: -43.720143136677756 }, //-22.523642778821213, -43.720143136677756
  { lat: -22.52371995123029, lng: -43.719638233528016 }, //-22.52371995123029, -43.719638233528016
  { lat: -22.523691430997385, lng: -43.71946932708247 }, //-22.523691430997385, -43.71946932708247

  { lat: -22.523641101156596, lng: -43.719458429891525 }, //-22.523641101156596, -43.719458429891525
  { lat: -22.523527020124465, lng: -43.7195365264201 }, //-22.523527020124465, -43.7195365264201
  { lat: -22.52346494658211, lng: -43.719609174353664 }, //-22.52346494658211, -43.719609174353664

  { lat: -22.522996877612616, lng: -43.72013950427659 }, //-22.522996877612616, -43.72013950427659
  { lat: -22.522746904648344, lng: -43.72030114592954 }, //-22.522746904648344, -43.72030114592954
  { lat: -22.522726772578043, lng: -43.720292064937844 }, //-22.522726772578043, -43.720292064937844

  { lat: -22.52259591404142, lng: -43.72006140774538 }, //-22.52259591404142, -43.72006140774538
  { lat: -22.522402981369634, lng: -43.719892501299846 }, //-22.522402981369634, -43.719892501299846

  { lat: -22.52221843681542, lng: -43.720088650722865 }, //-22.52221843681542, -43.720088650722865
  { lat: -22.52212616444957, lng: -43.720088650722865 }, //-22.52212616444957, -43.720088650722865
  { lat: -22.522203337705225, lng: -43.71975991882348 }, //-22.522203337705225, -43.71975991882348
  { lat: -22.522454989330626, lng: -43.71929860443576 }, //-22.522454989330626, -43.71929860443576

  { lat: -22.522537195427557, lng: -43.7192168755105 }, //-22.522537195427557, -43.7192168755105
  { lat: -22.522161395720385, lng: -43.71906249864753 }, //-22.522161395720385, -43.71906249864753
  { lat: -22.521884578319067, lng: -43.71908247682935 }, //-22.521884578319067, -43.71908247682935
  { lat: -22.520948428094243, lng: -43.71875737732338 }, //-22.520948428094243, -43.71875737732338
  { lat: -22.520776243453994, lng: -43.718910339324026 }, //-22.520776243453994, -43.718910339324026
  { lat: -22.520801408896652, lng: -43.7190756133729 }, //-22.520801408896652, -43.7190756133729

  { lat: -22.520668870847217, lng: -43.719210012049984 } //-22.520668870847217, -43.719210012049984
];

export class HomeCabo extends Component {

  state = {
    login: this.props.location.state.fields.login,
    option: this.props.location.state.fields.option,
    user_type: this.props.location.state.fields.user_type,
    selected_area: this.props.location.state.fields.selected_area,
    selected_city: this.props.location.state.fields.selected_city,

    zoom: 11,
    latitude: '',
    longitude: '',
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},

    places: [],

    cpf_cnpj: '',
    name: '',
    email: '',
    street: '',
    number: '',
    district: '',
    city: '',
    state: '',

    new_lat: '',
    new_lng: '',

    expanded: false,
    openDialog: false,

    renderTable: false,

    url: null,

    search: '',
  }

  async componentDidMount() {

    if (this.props.location.state) {
      await this.setState({ login: this.props.location.state.fields.login });
      await this.setState({ option: this.props.location.state.fields.option });
      await this.setState({ user_type: this.props.location.state.fields.user_type });
      await this.setState({ selected_area: this.props.location.state.fields.selected_area });
      await this.setState({ selected_city: this.props.location.state.fields.selected_city });
    }

    /*navigator.geolocation.getCurrentPosition(async (position) => {
      this.setState({ status: null });
      await this.setState({ latitude: position.coords.latitude });
      await this.setState({ longitude: position.coords.longitude });
    }, () => {
      this.setState({ status: 'Impossível retornar a localização.' });
    });*/

    await this.setState({ latitude: -22.755854 });
    await this.setState({ longitude: -42.012410 });

    if (this.state.user_type !== 4) {

      try {
        let resp = await api.get('/locations', {
          params: {
            selected_city: this.state.selected_city
          }
        });
        if (resp != null) {
          if (resp.data) {
            if (JSON.stringify(resp.data).includes('name')) {
  
              this.setState({ places: resp.data });
  
            } else {
              let msg = JSON.stringify(resp.data);
              console.log(msg)
            }
          }
        }
      } catch (err) {
        console.error(err);
      }

    } else {

      try {
        let resp = await api.get('/arealocations', {
          params: {
            selected_city: this.state.selected_city,
            selected_area: this.state.selected_area
          }
        });
        if (resp != null) {
          if (resp.data) {
            if (JSON.stringify(resp.data).includes('name')) {
  
              this.setState({ places: resp.data });
  
            } else {
              let msg = JSON.stringify(resp.data);
              console.log(msg)
            }
          }
        }
      } catch (err) {
        console.error(err);
      }

    }

  }

  handleLocations = async () => {
    
    if (this.state.user_type !== 4) {

      try {
        let resp = await api.get('/locations', {
          params: {
            selected_city: this.state.selected_city
          }
        });
        if (resp != null) {
          if (resp.data) {
            if (JSON.stringify(resp.data).includes('name')) {

              this.setState({ places: resp.data });

            } else {
              let msg = JSON.stringify(resp.data);
              console.log(msg)
            }
          }
        }
      } catch (err) {
        console.error(err);
      }

    } else {

      try {
        let resp = await api.get('/arealocations', {
          params: {
            selected_city: this.state.selected_city,
            selected_area: this.state.selected_area
          }
        });
        if (resp != null) {
          if (resp.data) {
            if (JSON.stringify(resp.data).includes('name')) {

              this.setState({ places: resp.data });

            } else {
              let msg = JSON.stringify(resp.data);
              console.log(msg)
            }
          }
        }
      } catch (err) {
        console.error(err);
      }

    }

  }

  handleRegular = async () => {

    if (this.state.user_type !== 4) {

      try {
        let resp = await api.get('/regularlocations', {
          params: {
            selected_city: this.state.selected_city
          }
        });
        if (resp != null) {
          if (resp.data) {
            if (JSON.stringify(resp.data).includes('name')) {
  
              for (let i = 0; i < resp.data.length; i++) {
  
                await this.state.places.pop();
  
              }
  
              await this.setState({ places: [] });
  
              await this.setState({ places: resp.data });
  
            } else {
              await this.setState({ places: resp.data });
            }
          }
        }
      } catch (err) {
        console.error(err);
      }

    } else {

      try {
        let resp = await api.get('/regulararealocations', {
          params: {
            selected_city: this.state.selected_city,
            selected_area: this.state.selected_area
          }
        });
        if (resp != null) {
          if (resp.data) {
            if (JSON.stringify(resp.data).includes('name')) {
  
              for (let i = 0; i < resp.data.length; i++) {
  
                await this.state.places.pop();
  
              }
  
              await this.setState({ places: [] });
  
              await this.setState({ places: resp.data });
  
            } else {
              await this.setState({ places: resp.data });
            }
          }
        }
      } catch (err) {
        console.error(err);
      }

    }

  }

  handleNegattive = async () => {
    
    if (this.state.user_type !== 4) {

      try {
        let resp = await api.get('/negattivelocations', {
          params: {
            selected_city: this.state.selected_city
          }
        });
        if (resp != null) {
          if (resp.data) {
            if (JSON.stringify(resp.data).includes('name')) {
  
              for (let i = 0; i < resp.data.length; i++) {
  
                await this.state.places.pop();
  
              }
  
              await this.setState({ places: [] });
  
              await this.setState({ places: resp.data });
  
            } else {
              await this.setState({ places: resp.data });
            }
          }
        }
      } catch (err) {
        console.error(err);
      }

    } else {

      try {
        let resp = await api.get('/negattivearealocations', {
          params: {
            selected_city: this.state.selected_city,
            selected_area: this.state.selected_area
          }
        });
        if (resp != null) {
          if (resp.data) {
            if (JSON.stringify(resp.data).includes('name')) {
  
              for (let i = 0; i < resp.data.length; i++) {
  
                await this.state.places.pop();
  
              }
  
              await this.setState({ places: [] });
  
              await this.setState({ places: resp.data });
  
            } else {
              await this.setState({ places: resp.data });
            }
          }
        }
      } catch (err) {
        console.error(err);
      }

    }

  }

  handlePendence = async () => {
    
    if (this.state.user_type !== 4) {

      try {
        let resp = await api.get('/pendencelocations', {
          params: {
            selected_city: this.state.selected_city
          }
        });
        if (resp != null) {
          if (resp.data) {
            if (JSON.stringify(resp.data).includes('name')) {
  
              for (let i = 0; i < resp.data.length; i++) {
  
                await this.state.places.pop();
  
              }
  
              await this.setState({ places: [] });
  
              await this.setState({ places: resp.data });
  
            } else {
              await this.setState({ places: resp.data });
            }
          }
        }
      } catch (err) {
        console.error(err);
      }

    } else {

      try {
        let resp = await api.get('/pendencearealocations', {
          params: {
            selected_city: this.state.selected_city,
            selected_area: this.state.selected_area
          }
        });
        if (resp != null) {
          if (resp.data) {
            if (JSON.stringify(resp.data).includes('name')) {
  
              for (let i = 0; i < resp.data.length; i++) {
  
                await this.state.places.pop();
  
              }
  
              await this.setState({ places: [] });
  
              await this.setState({ places: resp.data });
  
            } else {
              await this.setState({ places: resp.data });
            }
          }
        }
      } catch (err) {
        console.error(err);
      }

    }

  }

  handleChangePanel = (panel) => (event, isExpanded) => {
    this.setState({ expanded: isExpanded ? panel : false });
  };

  onMapClicked = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      });
    }
  };

  onMarkerClick = (props, marker) => {
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });

  }

  handleChange = async e => {
    await this.setState({ [e.target.name]: e.target.value });

    if (this.state.search.toUpperCase() === 'vila mariana'.toUpperCase()) {
      this.setState({ latitude: -22.52306584531408 }); //-22.52306584531408, -43.71470413469226
      this.setState({ longitude: -43.71470413469226 });
      this.setState({ zoom: 16 });
    } else {
      if (this.state.search.toUpperCase() === 'tupinambá'.toUpperCase()) {
        this.setState({ latitude: -22.524974794477203 }); //-22.524974794477203, -43.72572445671319
        this.setState({ longitude: -43.72572445671319 });
        this.setState({ zoom: 16 });
      } else {
        if (this.state.search.toUpperCase() === 'vila modesto'.toUpperCase()) {
          this.setState({ latitude: -22.527392093911057 }); //-22.527392093911057, -43.710106941552674
          this.setState({ longitude: -43.710106941552674 });
          this.setState({ zoom: 16 });
        } else {
          if (this.state.search.toUpperCase() === 'cinco lagos'.toUpperCase()) {
            this.setState({ latitude: -22.486415335580375 }); //-22.486415335580375, -43.72546676055614
            this.setState({ longitude: -43.72546676055614 });
            this.setState({ zoom: 14 });
          } else {
            if (this.state.search.toUpperCase() === 'martins costa'.toUpperCase()) {
              this.setState({ latitude: -22.541772643035266 }); //-22.541772643035266, -43.75860225254421
              this.setState({ longitude: -43.75860225254421 });
              this.setState({ zoom: 15 });
            } else {
              if (this.state.search.toUpperCase() === 'morsing'.toUpperCase()) {
                this.setState({ latitude: -22.500119184975564 }); //-22.500119184975564, -43.76264006382194
                this.setState({ longitude: -43.76264006382194 });
                this.setState({ zoom: 13 });
              } else {
                if (this.state.search.toUpperCase() === 'região da gaudência'.toUpperCase()) {
                  this.setState({ latitude: -22.52220120899949 }); //-22.52220120899949, -43.72077557058365
                  this.setState({ longitude: -43.72077557058365 });
                  this.setState({ zoom: 17 });
                } else {
                  if (this.state.search.toUpperCase() === 'vila wesley'.toUpperCase()) {
                    this.setState({ latitude: -22.526245 });
                    this.setState({ longitude: -43.719507 });
                    this.setState({ zoom: 17 });
                  } else {
                    this.setState({ latitude: -22.5242815 });
                    this.setState({ longitude: -43.7298658 });
                    this.setState({ zoom: 13 });
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  render() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={styles.html}>
          <Header
            history={this.props.history}
            login={this.state.login}
            option={this.state.option}
            user_type={this.state.user_type}
            selected_area={this.state.selected_area}
            selected_city={this.state.selected_city}
            city={this.state.selected_city}
            {...this.props}
          />

          <ThemeProvider theme={theme}>
            <div style={styles.directionRow}>

              <div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div>
                    <TextField
                      style={styles.text}
                      id="search"
                      name="search"
                      value={this.state.search}
                      placeholder='Pesquisar localidade'
                      onChange={this.handleChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        ),
                      }}
                      variant="outlined"
                    />
                  </div>

                  <div style={styles.accordionDiv}>
                    
                    <Accordion
                      TransitionProps={{ unmountOnExit: true }}
                      expanded={this.state.expanded === 'panel1'}
                      onChange={this.handleChangePanel('panel1')}
                      style={styles.accordion}
                      onClick={this.handleLocations}
                      theme={themeDark}
                    >
                      <ThemeProvider theme={themeDark}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <House />
                          <Typography>
                            Painel geral
                          </Typography>
                        </AccordionSummary>
                      </ThemeProvider>
                      <AccordionDetails>
                        <div style={{ display: 'flex', flexDirection: 'column', color: '#343434' }}>
                          <List
                            component="div"
                            disablePadding
                            style={styles.list}
                            onClick={() => {
                              this.setState({ renderTable: false });
                              this.componentDidMount();
                            }}
                          >
                            <ListItem button>
                              <ListItemIcon>
                                <MapIcon />
                              </ListItemIcon>
                              <ListItemText primary={`Mapa`} />
                            </ListItem>
                          </List>
                          <Divider />
                          {this.state.user_type !== 3 && this.state.user_type !== 4 ? (
                            <List
                              component="div"
                              disablePadding
                              style={styles.list}
                              onClick={() => {
                                this.props.history.push({
                                  pathname: '/addarea',
                                  state: {
                                    fields: {
                                      login: this.state.login,
                                      option: this.state.option,
                                      user_type: this.state.user_type,
                                      selected_area: this.state.selected_area,
                                      selected_city: this.state.selected_city,
                                      city: this.state.selected_city
                                    }
                                  }
                                });
                              }}
                            >
                              <ListItem button>
                                <ListItemIcon>
                                  <Add />
                                </ListItemIcon>
                                <ListItemText primary={`Cadastrar áreas`} />
                              </ListItem>
                            </List>
                          ) : null}
                          <Divider />
                          <List
                            component="div"
                            disablePadding
                            style={styles.list}
                            onClick={() => {
                              this.props.history.push({
                                pathname: '/areas',
                                state: {
                                  fields: {
                                    login: this.state.login,
                                    selected_city: this.state.selected_city
                                  }
                                }
                              });
                            }}
                          >
                            <ListItem button>
                              <ListItemIcon>
                                <ViewColumn />
                              </ListItemIcon>
                              <ListItemText primary={`Tabela de áreas`} />
                            </ListItem>
                          </List>
                          <Divider />
                          <List
                            component="div"
                            disablePadding
                            style={styles.list}
                            onClick={() => {
                              this.setState({ renderTable: true });
                              this.componentDidMount();
                            }}
                          >
                            <ListItem button>
                              <ListItemIcon>
                                <Apartment />
                              </ListItemIcon>
                              <ListItemText primary={`RGI (Registro Geral de Imóveis)`} />
                            </ListItem>
                          </List>
                          <Divider />
                          <List
                            component="div"
                            disablePadding
                            style={styles.list}
                            onClick={() => {
                              this.setState({ renderTable: true });
                              this.componentDidMount();
                            }}
                          >
                            <ListItem button>
                              <ListItemIcon>
                                <Add />
                              </ListItemIcon>
                              <ListItemText primary={`Cadastro imobiliário`} />
                            </ListItem>
                          </List>
                          <Divider />
                          <List
                            component="div"
                            disablePadding
                            style={styles.list}
                            onClick={() => {
                              this.props.history.push({
                                pathname: '/urbanproject',
                                state: {
                                  fields: {
                                    login: this.state.login,
                                    option: this.state.option,
                                    user_type: this.state.user_type,
                                    selected_area: this.state.selected_area,
                                    selected_city: this.state.selected_city
                                  }
                                }
                              });
                            }}
                          >
                            <ListItem button>
                              <ListItemIcon>
                                <Apartment />
                              </ListItemIcon>
                              <ListItemText primary={`Projeto Urbanístico REURB-S`} />
                            </ListItem>
                          </List>
                          <Divider />
                          <List
                            component="div"
                            disablePadding
                            style={styles.list}
                            onClick={() => {
                              this.setState({ renderTable: true });
                              this.componentDidMount();
                            }}
                          >
                            <ListItem button>
                              <ListItemIcon>
                                <ViewColumn />
                              </ListItemIcon>
                              <ListItemText primary={`Tabela de registros`} />
                            </ListItem>
                          </List>
                        </div>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion
                      expanded={this.state.expanded === 'panel2'}
                      onChange={this.handleChangePanel('panel2')}
                      style={styles.accordion}
                    >
                      <ThemeProvider theme={themeDark}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          <LocationOn />
                          <Typography>
                            Filtros
                          </Typography>
                        </AccordionSummary>
                      </ThemeProvider>
                      <AccordionDetails>
                        <div style={{ display: 'flex', flexDirection: 'column', color: '#343434' }}>
                          <List
                            component="div"
                            disablePadding
                            style={styles.list}
                            onClick={() => { this.handleRegular(); }}
                          >
                            <ListItem button>
                              <ListItemIcon>
                                <Check />
                              </ListItemIcon>
                              <ListItemText primary={`Imóveis regularizados`} />
                            </ListItem>
                          </List>
                          <Divider />
                          <List
                            component="div"
                            disablePadding
                            style={styles.list}
                            onClick={() => { this.handlePendence(); }}
                          >
                            <ListItem button>
                              <ListItemIcon>
                                <Report />
                              </ListItemIcon>
                              <ListItemText primary={`Regularizações em andamento`} />
                            </ListItem>
                          </List>
                          <Divider />
                          <List
                            component="div"
                            disablePadding
                            style={styles.list}
                            onClick={() => { this.handleNegattive(); }}
                          >
                            <ListItem button>
                              <ListItemIcon>
                                <Close />
                              </ListItemIcon>
                              <ListItemText primary={`Regularizações negadas`} />
                            </ListItem>
                          </List>
                        </div>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion
                      expanded={this.state.expanded === 'panel5'}
                      onChange={this.handleChangePanel('panel5')}
                      style={styles.accordion}
                    >
                      <ThemeProvider theme={themeDark}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel5a-content"
                          id="panel5a-header"
                        >
                          <Assignment />
                          <Typography>
                            Formulários
                          </Typography>
                        </AccordionSummary>
                      </ThemeProvider>
                      <AccordionDetails>

                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <Button
                            color="secondary"
                            style={styles.bt2}
                            startIcon={<Assignment />}
                          >
                            Decisão instauradora da REURB
                          </Button>

                          <Button
                            color="secondary"
                            style={styles.bt2}
                            startIcon={<Assignment />}
                          >
                            Decisão denegatória da instauração da REURB
                          </Button>

                          <Button
                            color="secondary"
                            style={styles.bt2}
                            startIcon={<Assignment />}
                          >
                            Autuação do procedimento
                          </Button>

                          <Button
                            color="secondary"
                            style={styles.bt2}
                            startIcon={<Assignment />}
                          >
                            Certificações comuns do procedimento administrativo
                          </Button>

                          <Button
                            color="secondary"
                            style={styles.bt2}
                            startIcon={<Assignment />}
                          >
                            Anuência expressa do notificado
                          </Button>

                          <Button
                            color="secondary"
                            style={styles.bt2}
                            startIcon={<Assignment />}
                          >
                            Impugnação expressa do notificado
                          </Button>
                        </div>

                      </AccordionDetails>
                    </Accordion>

                    <Accordion
                      expanded={this.state.expanded === 'panel6'}
                      onChange={this.handleChangePanel('panel6')}
                      style={styles.accordion}
                    >
                      <ThemeProvider theme={themeDark}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel6a-content"
                          id="panel6a-header"
                        >
                          <AssignmentTurnedIn />
                          <Typography>
                            Modelos de documentação
                          </Typography>
                        </AccordionSummary>
                      </ThemeProvider>
                      <AccordionDetails>

                        <div style={styles.examples}>
                          <a
                            href={`${apiStringServer}/docs/plano_diretor_estrutura_e_conteudo.pdf`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Plano Diretor - Estrutura e conteúdo
                          </a>
                          <a
                            href={`${apiStringServer}/docs/lei_complementar_2011_plano_diretor.pdf`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Plano Diretor - Lei complementar
                          </a>
                          <a
                            href={`${apiStringServer}/docs/mapa1_macrozoneamento.pdf`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Mapa 1 - Macrozoneamento
                          </a>
                          <a
                            href={`${apiStringServer}/docs/mapa2_hipsometria_hidrografia_e_subbacias_hidrograficas.pdf`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Mapa 2 - Hipsometria, Hidrografia e Sub-bacias hidrográficas
                          </a>
                          <a
                            href={`${apiStringServer}/docs/mapa3_areas_protegidas.pdf`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Mapa 3 - Áreas protegidas
                          </a>
                          <a
                            href={`${apiStringServer}/docs/mapa4_regioes_administrativas.pdf`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Mapa 4 - Regiões administrativas
                          </a>
                          <a
                            href={`${apiStringServer}/docs/mapa5_regioes_de_planejamento.pdf`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Mapa 5 - Regiões de planejamento
                          </a>
                          <a
                            href={`${apiStringServer}/docs/mapa6_uso_e_cobertura_do_solo.pdf`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Mapa 6 - Uso e cobertura do solo
                          </a>
                          <a
                            href={`${apiStringServer}/docs/mapa7_rede_estrutural_viaria.pdf`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Mapa 7 - Rede estrutural viária
                          </a>
                          <a
                            href={`${apiStringServer}/docs/mapa8_limites_administrativos_rarp.pdf`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Mapa 8 - Limites administrativos RARP
                          </a>
                          <a
                            href={`${apiStringServer}/docs/mapa9_areas_de_intervencao_e_macrozona.pdf`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Mapa 9 - Áreas de intervenção Macrozona
                          </a>
                          <a
                            href={`${apiStringServer}/docs/mapa10_areas_intervencao.pdf`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Mapa 10 - Áreas de intervenção
                          </a>
                          <a
                            href={`${apiStringServer}/docs/mapa11_vetores_de_crescimento.pdf`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Mapa 11 - Vetores de crescimento
                          </a>
                          <a
                            href={`${apiStringServer}/docs/mapa12_meio_ambiente.pdf`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Mapa 12 - Meio ambiente
                          </a>
                          <a
                            href={`${apiStringServer}/docs/mapa13_meio_ambiente_sitios.pdf`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Mapa 13 - Meio ambiente (sítios)
                          </a>
                          <a
                            href={`${apiStringServer}/docs/mapa14_saneamento_ambiental.pdf`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Mapa 14 - Saneamento ambiental
                          </a>
                          <a
                            href={`${apiStringServer}/docs/mapa15_habitacao.pdf`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Mapa 15 - Habitação
                          </a>
                          <a
                            href={`${apiStringServer}/docs/mapa16_transportes.pdf`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Mapa 16 - Transportes
                          </a>
                          <a
                            href={`${apiStringServer}/docs/relatorio_2009_antecedentes_e_diagnostico.pdf`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Relatório 2009 - Antecedentes e Diagnóstico
                          </a>
                          <a
                            href={`${apiStringServer}/docs/relatorio_2009_proposta_de_politica_urbana_parte3.pdf`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Relatório 2009 - Proposta de política urbana (parte 3)
                          </a>
                          <a
                            href={`${apiStringServer}/docs/importancia_do_plano_diretor_municipal.docx`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            A iportância do Plano Diretor Municipal
                          </a>
                          <a
                            href={`${apiStringServer}/docs/atos_e_procedimentos_da_regularizacao_fundiaria.docx`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Atos e procedimentos da regularização fundiária
                          </a>
                        </div>

                      </AccordionDetails>
                    </Accordion>
                  </div>
              </div>
              </div>

              {this.state.renderTable ? (
                <div>
                  <Table
                    history={this.props.history}
                    vars={this.state}
                    {...this.props}
                  />
                </div>
              ) : (
                <div style={{
                  height: '100%',
                  width: '100%',
                  position: 'absolute',
                  zIndex: 0
                }}>
                  <Map
                    key={'AIzaSyDC6df7s7nceeG2sHhFGu0-nzLppDiHpe0'}
                    google={this.props.google}
                    zoom={this.state.zoom}
                    style={styles.map}
                    mapType="map"
                    center={{
                      lat: this.state.latitude,
                      lng: this.state.longitude
                    }}
                    onClick={this.onMapClicked}
                  >

                    <Polygon
                      paths={polygonCoords_CaboFrio}
                      strokeColor="#001D5F"
                      strokeOpacity={0.5}
                      strokeWeight={2}
                      fillColor="#001D5F"
                      fillOpacity={0.2}
                    />

                    <Polygon
                      paths={polygonCoords_Mendes_Vila_Mariana}
                      strokeColor="#800000"
                      strokeOpacity={0.5}
                      strokeWeight={2}
                      fillColor="#fff"
                      fillOpacity={0.2}
                    />

                    <Polygon
                      paths={polygonCoords_Mendes_Tupinamba}
                      strokeColor="#800000"
                      strokeOpacity={0.5}
                      strokeWeight={2}
                      fillColor="#fff"
                      fillOpacity={0.2}
                    />

                    <Polygon
                      paths={polygonCoords_Vila_Modesto}
                      strokeColor="#800000"
                      strokeOpacity={0.5}
                      strokeWeight={2}
                      fillColor="#fff"
                      fillOpacity={0.2}
                    />

                    <Polygon
                      paths={polygonCoords_Cinco_Lagos}
                      strokeColor="#800000"
                      strokeOpacity={0.5}
                      strokeWeight={2}
                      fillColor="#fff"
                      fillOpacity={0.2}
                    />

                    <Polygon
                      paths={polygonCoords_Martins_Costa}
                      strokeColor="#800000"
                      strokeOpacity={0.5}
                      strokeWeight={2}
                      fillColor="#fff"
                      fillOpacity={0.2}
                    />

                    <Polygon
                      paths={polygonCoords_Morsing}
                      strokeColor="#800000"
                      strokeOpacity={0.5}
                      strokeWeight={2}
                      fillColor="#fff"
                      fillOpacity={0.2}
                    />

                    <Polygon
                      paths={polygonCoords_Regiao_Da_Gaudencia}
                      strokeColor="#800000"
                      strokeOpacity={0.5}
                      strokeWeight={2}
                      fillColor="#fff"
                      fillOpacity={0.2}
                    />

                    {this.state.places.map((item) =>
                      <Marker
                        icon={{
                          url: (item.active == 1) ? yellow_marker : (item.active == 2) ? green_marker : red_marker,
                          scaledSize: new window.google.maps.Size(32, 40)
                        }}
                        position={{
                          lat: item.latitude,
                          lng: item.longitude
                        }}
                        onClick={async (props, marker) => {
                          this.setState({ cpf_cnpj: item.cpf_cnpj });
                          this.setState({ name: item.name });
                          this.setState({ email: item.email });
                          this.setState({ street: item.street });
                          this.setState({ number: item.number });
                          this.setState({ district: item.district });
                          this.setState({ city: item.city });
                          this.setState({ state: item.state });
                          this.setState({ new_lat: item.latitude });
                          this.setState({ new_lng: item.longitude });
                          this.onMarkerClick(props, marker);
                        }}
                        name={item.name}
                      />
                    )}

                    <InfoWindow
                      marker={this.state.activeMarker}
                      visible={this.state.showingInfoWindow}
                    >

                      <a
                        href={`${apiString}/details?cpf_cnpj=${this.state.cpf_cnpj}&lat=${this.state.new_lat}&lng=${this.state.new_lng}&selected_city=${this.state.selected_city}&login=${this.state.login}`}
                      >
                        <Button
                          fullWidth
                          variant="text"
                          color="primary"
                          style={{ textTransform: 'none' }}
                        >
                          <Typography variant="h6" color="primary" align="left" style={{ maxWidth: 250 }}>
                            {`${this.state.name}`}
                          </Typography>
                        </Button>
                      </a>
                      <Typography variant="subtitle1" color="textSecondary" align="left" style={{ maxWidth: 250 }}>
                        {`${this.state.email}`}
                      </Typography>
                      <Typography variant="subtitle1" color="textSecondary" align="left" style={{ maxWidth: 250 }}>
                        {`${this.state.street}, ${this.state.number} - ${this.state.district}, ${this.state.city} - ${this.state.state}`}
                      </Typography>

                    </InfoWindow>

                  </Map>
                </div>
              )}

            </div>
          </ThemeProvider>

        </div>
        <FooterHome />
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDC6df7s7nceeG2sHhFGu0-nzLppDiHpe0',
  language: 'pt-BR'
})(HomeCabo)

let styles = {
  html: {
    backgroundColor: '#E5E6F0', //454545
    height: window.innerHeight,
    width: window.innerWidth,
    margin: -10,
    paddingBottom: 50,
    zIndex: 0,
  },

  directionRow: {
    display: 'flex',
    flexDirection: 'row'
  },

  directionColumn: {
    display: 'flex',
    flexDirection: 'column'
  },

  text: {
    margin: 10,
    marginTop: '30%',
    width: 335,
    backgroundColor: '#E5E6F0', //343434
    borderRadius: 5,
    zIndex: 1,
  },

  accordionDiv: {
    marginTop: 10,
    zIndex: 1,
  },

  accordion: {
    maxWidth: 350,
    backgroundColor: '#001D5F',
    marginLeft: 10,
    color: '#E5E6F0',
    borderRadius: 5,
    marginBottom: 2,
  },

  list: {
    backgroundColor: '#E5E6F0', //343434
    width: 315,
  },

  map: {
    height: window.innerHeight - 30,
    width: window.innerWidth,
    alignSelf: 'flex-start',
  },

  bt2: {
    margin: 10,
    width: '96.7%',
    textTransform: 'none',
    backgroundColor: '#E5E6F0', //343434
    color: '#343434'
  },

  examples: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#E5E6F0', //454545
    margin: 10,
    marginTop: 30,
    maxHeight: 370,
    overflowY: 'scroll',
  },

  href: {
    margin: 10,
    color: '#343434'
  },

};