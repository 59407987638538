import * as React from 'react';
import apiStringServer from '../services/apiStringServer';

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';

let roomsArray = []

export default function TitlebarImageList({ rooms }) {
  roomsArray = rooms[0];

  //alert(JSON.stringify(roomsArray[0].rooms.split(',')[1]));

  let itemData = [];

  if (roomsArray) {
    roomsArray.map((item) => {
      itemData.push(
        {
          img: `${apiStringServer}/files/${item.rooms.split(',')[1]}`,
          title: `${item.rooms.split(',')[0]}`,
          author: `${item.user_name}`,
          rows: 2,
          cols: 2,
          featured: true,
        },
      )
    })
  } 
  return (
    <ImageList sx={{ width: 500, height: 450, marginLeft: '8%' }}>
      {itemData.map((item) => (
        <ImageListItem key={item.img}>
          <img
            src={`${item.img}?w=248&fit=crop&auto=format`}
            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
            alt={item.title}
            loading="lazy"
          />
          <ImageListItemBar
            title={item.title}
            subtitle={item.author}
            actionIcon={
              <a href={`${item.img}`} target="_blank">
                <IconButton
                  sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                  aria-label={`info about ${item.title}`}
                >
                  <InfoIcon />
                </IconButton>
              </a>
            }
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}
