import React from 'react';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';

import apiStringServer from '../../services/apiStringServer';

const date = new Date();
const day = date.getDate();
const month = date.getMonth() + 1;
const year = date.getFullYear();

const monthNames = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
  "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
];

const monthName = monthNames[date.getMonth()];

const data1 = [];
const data2 = [];

const Report = ({
  login, selected_city, area_id, area_name, area, cep, street, number, district, city, state, description, latitude, longitude, 
  polygonCoords, form1_title, form1_description, form1_informations, form1_complement, form2_area, form2_blocks, form2_plots, 
  form2_complement, form4_justification, form5_description, form6_description, form7_description, form8_description, 
  form9_description, form10_description, form11_description, form12_description, form13_description, form14_description, 
  form15_description, mapImageDescription, mapPreview, preview, preview2, preview3, preview4, preview5, 
  preview6, preview7, preview8, preview9, preview10, preview11
}) => (
  <PDFViewer style={{ height: 1000 }}>
    <Document>

      <Page size="A4" style={styles.page}>
        <View>
          <Image src={`${apiStringServer}/files/kreurb_logo.jpg`} style={styles.avatar2} />
          <Text style={styles.title}>PROJETO URBANÍSTICO</Text>
          <Text style={styles.title2}>{area_name}</Text>
          <Text style={styles.title2}>{form1_title}</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.title2}>{day} de {monthName}</Text>
          <Text style={styles.title2}>Elaborado por: K-Reurb Cidadania</Text>
          <Text style={styles.title2}>Ano base: {year}</Text>
        </View>
      </Page>

      <Page size="A4">
        <View style={styles.topView}>
          <Image src={`${apiStringServer}/files/kreurb_logo.jpg`} style={styles.avatar} />
          <Text style={styles.title3}>OBJETO</Text>
        </View>
        <View style={styles.section2}>
          <Text style={styles.title2}>1 - Objeto</Text>
        </View>
        <View style={styles.view}>
          <Text style={styles.text}>{form1_description}</Text>
          <Text style={styles.text}>{form1_informations}</Text>
          <Text style={styles.text}>{form1_complement}</Text>
        </View>
        <View style={styles.pageNumber}>
          <Text>
            Página 2 de 16
          </Text>
        </View>
      </Page>

      <Page size="A4">
        <View style={styles.topView}>
          <Image src={`${apiStringServer}/files/kreurb_logo.jpg`} style={styles.avatar} />
          <Text style={styles.title3}>SITUAÇÃO</Text>
        </View>
        <View style={styles.section2}>
          <Text style={styles.title2}>2 - Situação</Text>
        </View>
        <View style={styles.view}>
          <Text style={styles.text}>Área: {form2_area}</Text>
          <Text style={styles.text}>Quadras: {form2_blocks}</Text>
          <Text style={styles.text}>Lotes: {form2_plots}</Text>
          <Text style={styles.text2}>{form2_complement}</Text>
        </View>
        <View style={styles.pageNumber}>
          <Text>
            Página 3 de 16
          </Text>
        </View>
      </Page>

      <Page size="A4">
        <View style={styles.topView}>
          <Image src={`${apiStringServer}/files/kreurb_logo.jpg`} style={styles.avatar} />
          <Text style={styles.title3}>LOCALIZAÇÃO</Text>
        </View>
        <View style={styles.section2}>
          <Text style={styles.title2}>3 - Localização</Text>
        </View>
        <View style={styles.view}>
          <Text style={styles.text}>Latitude: {latitude}</Text>
          <Text style={styles.text}>Longitude: {longitude}</Text>
          <Image
            src={mapPreview}
            alt={mapImageDescription}
            style={styles.thumbnailStyles}
          />
          <Text style={styles.text2}>{mapImageDescription}</Text>
        </View>
        <View style={styles.pageNumber}>
          <Text>
            Página 4 de 16
          </Text>
        </View>
      </Page>

      <Page size="A4">
        <View style={styles.topView}>
          <Image src={`${apiStringServer}/files/kreurb_logo.jpg`} style={styles.avatar} />
          <Text style={styles.title3}>JUSTIFICATIVA</Text>
        </View>
        <View style={styles.section2}>
          <Text style={styles.title2}>4 - Justificativa</Text>
        </View>
        <View style={styles.view}>
          <Text style={styles.text}>{form4_justification}</Text>
        </View>
        <View style={styles.pageNumber}>
          <Text>
            Página 5 de 16
          </Text>
        </View>
      </Page>

      <Page size="A4">
        <View style={styles.topView}>
          <Image src={`${apiStringServer}/files/kreurb_logo.jpg`} style={styles.avatar} />
          <Text style={styles.title3}>INFRAESTRUTURA</Text>
        </View>
        <View style={styles.section2}>
          <Text style={styles.title2}>5 - Infraestrutura</Text>
        </View>
        <View style={styles.view}>
          <Text style={styles.text}>{form5_description}</Text>
          <Image
            src={preview}
            alt={'Infraestrutura'}
            style={styles.thumbnailStyles2}
          />
        </View>
        <View style={styles.pageNumber}>
          <Text>
            Página 6 de 16
          </Text>
        </View>
      </Page>

      <Page size="A4">
        <View style={styles.topView}>
          <Image src={`${apiStringServer}/files/kreurb_logo.jpg`} style={styles.avatar} />
          <Text style={styles.title3}>SERVIÇO DE TRANSPORTE COLETIVO</Text>
        </View>
        <View style={styles.section2}>
          <Text style={styles.title2}>6 - Serviço de Transporte Coletivo</Text>
        </View>
        <View style={styles.view}>
          <Text style={styles.text}>{form6_description}</Text>
          <Image
            src={preview2}
            alt={'Serviço de Transporte Coletivo'}
            style={styles.thumbnailStyles2}
          />
        </View>
        <View style={styles.pageNumber}>
          <Text>
            Página 7 de 16
          </Text>
        </View>
      </Page>

      <Page size="A4">
        <View style={styles.topView}>
          <Image src={`${apiStringServer}/files/kreurb_logo.jpg`} style={styles.avatar} />
          <Text style={styles.title3}>REDE DE ÁGUA POTÁVEL</Text>
        </View>
        <View style={styles.section2}>
          <Text style={styles.title2}>7 - Rede de Água Potável</Text>
        </View>
        <View style={styles.view}>
          <Text style={styles.text}>{form7_description}</Text>
          <Image
            src={preview3}
            alt={'Rede de Água Potável'}
            style={styles.thumbnailStyles2}
          />
        </View>
        <View style={styles.pageNumber}>
          <Text>
            Página 8 de 16
          </Text>
        </View>
      </Page>

      <Page size="A4">
        <View style={styles.topView}>
          <Image src={`${apiStringServer}/files/kreurb_logo.jpg`} style={styles.avatar} />
          <Text style={styles.title3}>REDE DE DENAGEM DE ÁGUAS PLUVIAIS</Text>
        </View>
        <View style={styles.section2}>
          <Text style={styles.title2}>8 - Rede de Drenagem de Águas Pluviais</Text>
        </View>
        <View style={styles.view}>
          <Text style={styles.text}>{form8_description}</Text>
          <Image
            src={preview4}
            alt={'Rede de Drenagem de Águas Pluviais'}
            style={styles.thumbnailStyles2}
          />
        </View>
        <View style={styles.pageNumber}>
          <Text>
            Página 9 de 16
          </Text>
        </View>
      </Page>

      <Page size="A4">
        <View style={styles.topView}>
          <Image src={`${apiStringServer}/files/kreurb_logo.jpg`} style={styles.avatar} />
          <Text style={styles.title3}>SISTEMA DE COLETA E TRATAMENTO DO ESGOTAMENTO SANITÁRIO</Text>
        </View>
        <View style={styles.section2}>
          <Text style={styles.title2}>9 - Sistema de Coleta e Tratamento do Esgotamento Sanitário</Text>
        </View>
        <View style={styles.view}>
          <Text style={styles.text}>{form9_description}</Text>
          <Image
            src={preview5}
            alt={'Sistema de Coleta e Tratamento do Esgotamento Sanitário'}
            style={styles.thumbnailStyles2}
          />
        </View>
        <View style={styles.pageNumber}>
          <Text>
            Página 10 de 16
          </Text>
        </View>
      </Page>

      <Page size="A4">
        <View style={styles.topView}>
          <Image src={`${apiStringServer}/files/kreurb_logo.jpg`} style={styles.avatar} />
          <Text style={styles.title3}>REDE DE ENERGIA ELÉTRICA</Text>
        </View>
        <View style={styles.section2}>
          <Text style={styles.title2}>10 - Rede de Energia Elétrica</Text>
        </View>
        <View style={styles.view}>
          <Text style={styles.text}>{form10_description}</Text>
          <Image
            src={preview6}
            alt={'Rede de Energia Elétrica'}
            style={styles.thumbnailStyles2}
          />
        </View>
        <View style={styles.pageNumber}>
          <Text>
            Página 11 de 16
          </Text>
        </View>
      </Page>

      <Page size="A4">
        <View style={styles.topView}>
          <Image src={`${apiStringServer}/files/kreurb_logo.jpg`} style={styles.avatar} />
          <Text style={styles.title3}>REDE DE TELECOMUNICAÇÕES</Text>
        </View>
        <View style={styles.section2}>
          <Text style={styles.title2}>11 - Rede de Telecomunicações</Text>
        </View>
        <View style={styles.view}>
          <Text style={styles.text}>{form11_description}</Text>
          <Image
            src={preview7}
            alt={'Rede de Telecomunicações'}
            style={styles.thumbnailStyles2}
          />
        </View>
        <View style={styles.pageNumber}>
          <Text>
            Página 12 de 16
          </Text>
        </View>
      </Page>

      <Page size="A4">
        <View style={styles.topView}>
          <Image src={`${apiStringServer}/files/kreurb_logo.jpg`} style={styles.avatar} />
          <Text style={styles.title3}>COLETA DE RESÍDUOS SÓLIDOS</Text>
        </View>
        <View style={styles.section2}>
          <Text style={styles.title2}>12 - Coleta de Resíduos Sólidos</Text>
        </View>
        <View style={styles.view}>
          <Text style={styles.text}>{form12_description}</Text>
          <Image
            src={preview8}
            alt={'Coleta de Resíduos Sólidos'}
            style={styles.thumbnailStyles2}
          />
        </View>
        <View style={styles.pageNumber}>
          <Text>
            Página 13 de 16
          </Text>
        </View>
      </Page>

      <Page size="A4">
        <View style={styles.topView}>
          <Image src={`${apiStringServer}/files/kreurb_logo.jpg`} style={styles.avatar} />
          <Text style={styles.title3}>UNIDADE DE SAÚDE</Text>
        </View>
        <View style={styles.section2}>
          <Text style={styles.title2}>13 - Unidade de Saúde</Text>
        </View>
        <View style={styles.view}>
          <Text style={styles.text}>{form13_description}</Text>
          <Image
            src={preview9}
            alt={'Unidade de Saúde'}
            style={styles.thumbnailStyles2}
          />
        </View>
        <View style={styles.pageNumber}>
          <Text>
            Página 14 de 16
          </Text>
        </View>
      </Page>

      <Page size="A4">
        <View style={styles.topView}>
          <Image src={`${apiStringServer}/files/kreurb_logo.jpg`} style={styles.avatar} />
          <Text style={styles.title3}>PRAÇA PÚBLICA</Text>
        </View>
        <View style={styles.section2}>
          <Text style={styles.title2}>14 - Praça Púbica</Text>
        </View>
        <View style={styles.view}>
          <Text style={styles.text}>{form14_description}</Text>
          <Image
            src={preview10}
            alt={'Praça Púbica'}
            style={styles.thumbnailStyles2}
          />
        </View>
        <View style={styles.pageNumber}>
          <Text>
            Página 15 de 16
          </Text>
        </View>
      </Page>

      <Page size="A4">
        <View style={styles.topView}>
          <Image src={`${apiStringServer}/files/kreurb_logo.jpg`} style={styles.avatar} />
          <Text style={styles.title3}>VIAS E ACESSIBILIDADE</Text>
        </View>
        <View style={styles.section2}>
          <Text style={styles.title2}>15 - Vias e Acessibilidade</Text>
        </View>
        <View style={styles.view}>
          <Text style={styles.text}>{form15_description}</Text>
          <Image
            src={preview11}
            alt={'Vias e Acessibilidade'}
            style={styles.thumbnailStyles2}
          />
        </View>
        <View style={styles.pageNumber}>
          <Text>
            Página 16 de 16
          </Text>
        </View>
      </Page>

    </Document>
  </PDFViewer>
);

// Create styles
let styles = {
  page: {
    backgroundColor: '#E4E4E4',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  section: {
    marginTop: 80,
    flexGrow: 1,
  },

  section2: {
    marginTop: 10,
    alignItems: 'center',
  },

  button: {
    textTransform: 'none',
    margin: 20,
  },

  title: {
    width: 450,
    color: '#343434',
    fontSize: 30,
    textDecoration: 'underline',
    marginTop: 50,
  },

  title2: {
    width: 450,
    color: '#343434',
    fontSize: 20,
    marginTop: 20,
  },

  title3: {
    width: 230,
    color: '#E4E4E4',
    fontSize: 20,
    margin: 25,
    marginLeft: 80,
  },

  topView: {
    backgroundColor: '#484848',
    height: 80,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  },

  avatar: {
    width: 100,
    height: 80,
    marginLeft: 5,
  },

  avatar2: {
    width: 100,
    height: 100,
    marginLeft: 160,
    marginTop: 20
  },

  text: {
    width: 450,
    color: '#343434',
    fontSize: 17,
    margin: 10,
    width: 500,
  },

  text2: {
    color: '#343434',
    fontSize: 14,
    margin: 10,
    width: 500,
  },

  text3: {
    color: '#484848',
    fontSize: 15,
    margin: 10,
    width: 560,
  },

  text4: {
    color: '#343434',
    fontSize: 16,
  },

  view: {
    marginTop: 10,
  },

  view2: {
    display: 'flex',
    alignItems: 'center',
    width: window.innerWidth - (window.innerWidth / 1.6),
  },

  view3: {
    display: 'flex',
    alignItems: 'center',
    width: window.innerWidth - (window.innerWidth / 1.6),
    marginTop: 20,
  },

  tableCell: {
    backgroundColor: '#484848',
    color: '#fff'
  },

  pageNumber: {
    alignSelf: 'flex-end', 
    marginTop: 190,
    marginRight: 10,
  },

  pageNumber2: {
    alignSelf: 'flex-end', 
    marginTop: 120,
    marginRight: 10,
  },

  pageNumber3: {
    alignSelf: 'flex-end', 
    marginTop: 15,
    marginRight: 10,
  },

  image: {
    width: 250,
    height: 150,
    marginLeft: 150
  },

  thumbnailStyles: {
    height: 350,
    width: 350,
    marginLeft: 10,
  },

  thumbnailStyles2: {
    height: 350,
    width: 570,
  },

}

export default Report;