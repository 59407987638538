let polygonCaboFrio = [
  {
    lng: -42.1821965,
    lat: -22.5521695
  },
  {
    lng: -42.1322598,
    lat: -22.6523637
  },
  {
    lng: -42.1318057,
    lat: -22.6528917
  },
  {
    lng: -42.0884059,
    lat: -22.7033939
  },
  {
    lng: -42.0754679,
    lat: -22.7184367
  },
  {
    lng: -42.0638033,
    lat: -22.7319897
  },
  {
    lng: -42.0571232,
    lat: -22.7397507
  },
  {
    lng: -42.0569607,
    lat: -22.7399395
  },
  {
    lng: -42.05642,
    lat: -22.7405677
  },
  {
    lng: -42.0561728,
    lat: -22.7408548
  },
  {
    lng: -42.0551429,
    lat: -22.7420513
  },
  {
    lng: -42.0548051,
    lat: -22.7424439
  },
  {
    lng: -42.054752,
    lat: -22.7425058
  },
  {
    lng: -42.0546641,
    lat: -22.7426102
  },
  {
    lng: -42.0545813,
    lat: -22.7427087
  },
  {
    lng: -42.0418973,
    lat: -22.7577923
  },
  {
    lng: -42.0418276,
    lat: -22.7578753
  },
  {
    lng: -42.0378298,
    lat: -22.7656181
  },
  {
    lng: -42.0245363,
    lat: -22.7925715
  },
  {
    lng: -42.0242384,
    lat: -22.7931756
  },
  {
    lng: -42.0238132,
    lat: -22.7940372
  },
  {
    lng: -42.0230354,
    lat: -22.7956153
  },
  {
    lng: -42.0242568,
    lat: -22.8004083
  },
  {
    lng: -42.0249986,
    lat: -22.803318
  },
  {
    lng: -42.0250707,
    lat: -22.8036011
  },
  {
    lng: -42.0253204,
    lat: -22.8045803
  },
  {
    lng: -42.025446,
    lat: -22.8050729
  },
  {
    lng: -42.0257929,
    lat: -22.8064334
  },
  {
    lng: -42.0262697,
    lat: -22.8083036
  },
  {
    lng: -42.0267453,
    lat: -22.810169
  },
  {
    lng: -42.0272181,
    lat: -22.8120232
  },
  {
    lng: -42.0299907,
    lat: -22.8228967
  },
  {
    lng: -42.0300129,
    lat: -22.8229838
  },
  {
    lng: -42.0316504,
    lat: -22.8294054
  },
  {
    lng: -42.0326239,
    lat: -22.8332227
  },
  {
    lng: -42.0327348,
    lat: -22.8336577
  },
  {
    lng: -42.0328617,
    lat: -22.8341552
  },
  {
    lng: -42.0331082,
    lat: -22.8351217
  },
  {
    lng: -42.0333011,
    lat: -22.8358783
  },
  {
    lng: -42.0333947,
    lat: -22.8362454
  },
  {
    lng: -42.0335242,
    lat: -22.8367532
  },
  {
    lng: -42.0337484,
    lat: -22.8376395
  },
  {
    lng: -42.0338972,
    lat: -22.838222
  },
  {
    lng: -42.0340504,
    lat: -22.8388219
  },
  {
    lng: -42.0341653,
    lat: -22.8392722
  },
  {
    lng: -42.0343242,
    lat: -22.8398944
  },
  {
    lng: -42.034579,
    lat: -22.840892
  },
  {
    lng: -42.0351612,
    lat: -22.843172
  },
  {
    lng: -42.0351973,
    lat: -22.8433131
  },
  {
    lng: -42.0352647,
    lat: -22.8435778
  },
  {
    lng: -42.0356398,
    lat: -22.8450491
  },
  {
    lng: -42.0357504,
    lat: -22.8454832
  },
  {
    lng: -42.0358626,
    lat: -22.8459232
  },
  {
    lng: -42.0360485,
    lat: -22.8466524
  },
  {
    lng: -42.0363389,
    lat: -22.8477917
  },
  {
    lng: -42.0364526,
    lat: -22.8482377
  },
  {
    lng: -42.0366049,
    lat: -22.8488352
  },
  {
    lng: -42.0367401,
    lat: -22.8493655
  },
  {
    lng: -42.0368765,
    lat: -22.8499005
  },
  {
    lng: -42.0370763,
    lat: -22.8506842
  },
  {
    lng: -42.0372313,
    lat: -22.8512924
  },
  {
    lng: -42.0375431,
    lat: -22.8525153
  },
  {
    lng: -42.0375972,
    lat: -22.8527272
  },
  {
    lng: -42.0378825,
    lat: -22.8538464
  },
  {
    lng: -42.0380316,
    lat: -22.8544311
  },
  {
    lng: -42.0381063,
    lat: -22.8547241
  },
  {
    lng: -42.0383594,
    lat: -22.8557171
  },
  {
    lng: -42.0385113,
    lat: -22.8563128
  },
  {
    lng: -42.0407862,
    lat: -22.8652353
  },
  {
    lng: -42.0458407,
    lat: -22.8670844
  },
  {
    lng: -42.0499344,
    lat: -22.8650911
  },
  {
    lng: -42.0500361,
    lat: -22.8650416
  },
  {
    lng: -42.050528,
    lat: -22.8648021
  },
  {
    lng: -42.0528068,
    lat: -22.8620769
  },
  {
    lng: -42.0546937,
    lat: -22.8628458
  },
  {
    lng: -42.0579468,
    lat: -22.8661822
  },
  {
    lng: -42.061144,
    lat: -22.8670606
  },
  {
    lng: -42.068092,
    lat: -22.8648864
  },
  {
    lng: -42.0735593,
    lat: -22.8606633
  },
  {
    lng: -42.0758382,
    lat: -22.8601011
  },
  {
    lng: -42.077842,
    lat: -22.8603122
  },
  {
    lng: -42.082892,
    lat: -22.8633528
  },
  {
    lng: -42.0870857,
    lat: -22.8644622
  },
  {
    lng: -42.0941704,
    lat: -22.8616297
  },
  {
    lng: -42.098032,
    lat: -22.8612833
  },
  {
    lng: -42.1047876,
    lat: -22.8655083
  },
  {
    lng: -42.1075093,
    lat: -22.8676558
  },
  {
    lng: -42.1086698,
    lat: -22.8708769
  },
  {
    lng: -42.105397,
    lat: -22.8787186
  },
  {
    lng: -42.1044184,
    lat: -22.8844917
  },
  {
    lng: -42.1060057,
    lat: -22.8952814
  },
  {
    lng: -42.1080282,
    lat: -22.9010631
  },
  {
    lng: -42.1141387,
    lat: -22.9082658
  },
  {
    lng: -42.1170593,
    lat: -22.9085253
  },
  {
    lng: -42.1231676,
    lat: -22.9070176
  },
  {
    lng: -42.1123959,
    lat: -22.9127101
  },
  {
    lng: -42.1005315,
    lat: -22.9252068
  },
  {
    lng: -42.0904848,
    lat: -22.9324101
  },
  {
    lng: -42.084771,
    lat: -22.932504
  },
  {
    lng: -42.0829181,
    lat: -22.9325344
  },
  {
    lng: -42.0827543,
    lat: -22.9325371
  },
  {
    lng: -42.0805275,
    lat: -22.9325737
  },
  {
    lng: -42.0656179,
    lat: -22.9328188
  },
  {
    lng: -42.0638512,
    lat: -22.9328479
  },
  {
    lng: -42.0631015,
    lat: -22.9328602
  },
  {
    lng: -42.0588773,
    lat: -22.9329296
  },
  {
    lng: -42.0408121,
    lat: -22.9332267
  },
  {
    lng: -42.0405828,
    lat: -22.9332304
  },
  {
    lng: -42.0375448,
    lat: -22.9332805
  },
  {
    lng: -42.0373205,
    lat: -22.9332842
  },
  {
    lng: -42.0373962,
    lat: -22.9247131
  },
  {
    lng: -42.036954,
    lat: -22.9202074
  },
  {
    lng: -42.0359056,
    lat: -22.9152343
  },
  {
    lng: -42.0348601,
    lat: -22.9117434
  },
  {
    lng: -42.0334087,
    lat: -22.907888
  },
  {
    lng: -42.0323112,
    lat: -22.9051821
  },
  {
    lng: -42.030849,
    lat: -22.9024804
  },
  {
    lng: -42.028893,
    lat: -22.8990331
  },
  {
    lng: -42.0270055,
    lat: -22.8965484
  },
  {
    lng: -42.026512,
    lat: -22.8960106
  },
  {
    lng: -42.0253076,
    lat: -22.8943986
  },
  {
    lng: -42.0243598,
    lat: -22.8932457
  },
  {
    lng: -42.0236887,
    lat: -22.8925311
  },
  {
    lng: -42.0228126,
    lat: -22.8913847
  },
  {
    lng: -42.0225666,
    lat: -22.8911356
  },
  {
    lng: -42.0214138,
    lat: -22.8899656
  },
  {
    lng: -42.021201,
    lat: -22.8897152
  },
  {
    lng: -42.0207683,
    lat: -22.8892882
  },
  {
    lng: -42.0205329,
    lat: -22.889069
  },
  {
    lng: -42.0202492,
    lat: -22.8887146
  },
  {
    lng: -42.0197546,
    lat: -22.8882894
  },
  {
    lng: -42.0191639,
    lat: -22.8877872
  },
  {
    lng: -42.0179287,
    lat: -22.8866235
  },
  {
    lng: -42.0176957,
    lat: -22.8863935
  },
  {
    lng: -42.0175386,
    lat: -22.886224
  },
  {
    lng: -42.0173342,
    lat: -22.886129
  },
  {
    lng: -42.0157149,
    lat: -22.884917
  },
  {
    lng: -42.0149184,
    lat: -22.8842301
  },
  {
    lng: -42.0139566,
    lat: -22.8836138
  },
  {
    lng: -42.0130242,
    lat: -22.8831415
  },
  {
    lng: -42.0126418,
    lat: -22.8829894
  },
  {
    lng: -42.0112948,
    lat: -22.8826604
  },
  {
    lng: -42.0104885,
    lat: -22.8825622
  },
  {
    lng: -42.0100159,
    lat: -22.8826026
  },
  {
    lng: -42.009647,
    lat: -22.8827067
  },
  {
    lng: -42.0088858,
    lat: -22.882884
  },
  {
    lng: -42.0083041,
    lat: -22.8831551
  },
  {
    lng: -42.0079348,
    lat: -22.8833653
  },
  {
    lng: -42.0075258,
    lat: -22.8838136
  },
  {
    lng: -42.007274,
    lat: -22.8841553
  },
  {
    lng: -42.0071295,
    lat: -22.884578
  },
  {
    lng: -42.0071472,
    lat: -22.8850304
  },
  {
    lng: -42.0072721,
    lat: -22.8852215
  },
  {
    lng: -42.0073909,
    lat: -22.8852951
  },
  {
    lng: -42.0073498,
    lat: -22.8854011
  },
  {
    lng: -42.0072427,
    lat: -22.8854652
  },
  {
    lng: -42.007156,
    lat: -22.8855431
  },
  {
    lng: -42.0071031,
    lat: -22.8855377
  },
  {
    lng: -42.0070783,
    lat: -22.8856272
  },
  {
    lng: -42.0069316,
    lat: -22.8857446
  },
  {
    lng: -42.0068665,
    lat: -22.8858548
  },
  {
    lng: -42.0065888,
    lat: -22.8856243
  },
  {
    lng: -42.0065286,
    lat: -22.8854987
  },
  {
    lng: -42.0066401,
    lat: -22.8852443
  },
  {
    lng: -42.0065941,
    lat: -22.8852003
  },
  {
    lng: -42.0065286,
    lat: -22.8852068
  },
  {
    lng: -42.0064117,
    lat: -22.8854172
  },
  {
    lng: -42.0063515,
    lat: -22.8854156
  },
  {
    lng: -42.0063091,
    lat: -22.8854531
  },
  {
    lng: -42.0062046,
    lat: -22.8855998
  },
  {
    lng: -42.0062914,
    lat: -22.8852215
  },
  {
    lng: -42.0060435,
    lat: -22.8856211
  },
  {
    lng: -42.0058686,
    lat: -22.8856713
  },
  {
    lng: -42.0057656,
    lat: -22.8855395
  },
  {
    lng: -42.0055992,
    lat: -22.8854531
  },
  {
    lng: -42.0054165,
    lat: -22.885505
  },
  {
    lng: -42.005743,
    lat: -22.8862071
  },
  {
    lng: -42.0058122,
    lat: -22.8863167
  },
  {
    lng: -42.0058512,
    lat: -22.886499
  },
  {
    lng: -42.0057346,
    lat: -22.8867134
  },
  {
    lng: -42.0056069,
    lat: -22.8868541
  },
  {
    lng: -42.0054442,
    lat: -22.8869925
  },
  {
    lng: -42.0051889,
    lat: -22.8870963
  },
  {
    lng: -42.0049711,
    lat: -22.8873015
  },
  {
    lng: -42.0050003,
    lat: -22.8873914
  },
  {
    lng: -42.0049686,
    lat: -22.8875022
  },
  {
    lng: -42.0051214,
    lat: -22.8875299
  },
  {
    lng: -42.0050813,
    lat: -22.8875852
  },
  {
    lng: -42.0049111,
    lat: -22.8876244
  },
  {
    lng: -42.0048109,
    lat: -22.8876175
  },
  {
    lng: -42.0047383,
    lat: -22.8876752
  },
  {
    lng: -42.0046457,
    lat: -22.8876291
  },
  {
    lng: -42.0046407,
    lat: -22.8877213
  },
  {
    lng: -42.0044955,
    lat: -22.8878274
  },
  {
    lng: -42.0046968,
    lat: -22.8882371
  },
  {
    lng: -42.0046682,
    lat: -22.8884709
  },
  {
    lng: -42.0045906,
    lat: -22.8885309
  },
  {
    lng: -42.0046131,
    lat: -22.888893
  },
  {
    lng: -42.0045396,
    lat: -22.8891665
  },
  {
    lng: -42.0042251,
    lat: -22.8896149
  },
  {
    lng: -42.0039597,
    lat: -22.8897163
  },
  {
    lng: -42.0037469,
    lat: -22.8896771
  },
  {
    lng: -42.003449,
    lat: -22.8897855
  },
  {
    lng: -42.003136,
    lat: -22.8899651
  },
  {
    lng: -42.0021722,
    lat: -22.8900577
  },
  {
    lng: -42.0010113,
    lat: -22.8896773
  },
  {
    lng: -42.0011156,
    lat: -22.8876715
  },
  {
    lng: -42.0013211,
    lat: -22.8872454
  },
  {
    lng: -42.0010344,
    lat: -22.8874865
  },
  {
    lng: -42.0009301,
    lat: -22.8874113
  },
  {
    lng: -42.0006083,
    lat: -22.8871017
  },
  {
    lng: -42.0006919,
    lat: -22.8869387
  },
  {
    lng: -42.000571,
    lat: -22.8868932
  },
  {
    lng: -42.0004341,
    lat: -22.8869365
  },
  {
    lng: -42.0001913,
    lat: -22.8866558
  },
  {
    lng: -42.0001784,
    lat: -22.886307
  },
  {
    lng: -42.0000705,
    lat: -22.8861223
  },
  {
    lng: -41.9999576,
    lat: -22.8860041
  },
  {
    lng: -41.9997433,
    lat: -22.886011
  },
  {
    lng: -41.9996024,
    lat: -22.8858296
  },
  {
    lng: -41.9996587,
    lat: -22.8856759
  },
  {
    lng: -41.9994161,
    lat: -22.8856738
  },
  {
    lng: -41.9993249,
    lat: -22.885569
  },
  {
    lng: -41.9992144,
    lat: -22.8850912
  },
  {
    lng: -41.9989099,
    lat: -22.8849046
  },
  {
    lng: -41.9985183,
    lat: -22.8846402
  },
  {
    lng: -41.9983212,
    lat: -22.8845118
  },
  {
    lng: -41.9982448,
    lat: -22.8844821
  },
  {
    lng: -41.998167,
    lat: -22.8844661
  },
  {
    lng: -41.9980476,
    lat: -22.8844414
  },
  {
    lng: -41.9979846,
    lat: -22.8844055
  },
  {
    lng: -41.9978679,
    lat: -22.8843005
  },
  {
    lng: -41.9977995,
    lat: -22.8842498
  },
  {
    lng: -41.9975594,
    lat: -22.8841447
  },
  {
    lng: -41.9972913,
    lat: -22.8840905
  },
  {
    lng: -41.9970338,
    lat: -22.8840855
  },
  {
    lng: -41.996791,
    lat: -22.8840225
  },
  {
    lng: -41.9965456,
    lat: -22.8838977
  },
  {
    lng: -41.9962183,
    lat: -22.883653
  },
  {
    lng: -41.9952839,
    lat: -22.8838963
  },
  {
    lng: -41.9949675,
    lat: -22.8837823
  },
  {
    lng: -41.9945824,
    lat: -22.8834895
  },
  {
    lng: -41.9945448,
    lat: -22.8833065
  },
  {
    lng: -41.9942503,
    lat: -22.8830813
  },
  {
    lng: -41.9939633,
    lat: -22.8829804
  },
  {
    lng: -41.9937205,
    lat: -22.8828888
  },
  {
    lng: -41.9929344,
    lat: -22.8826624
  },
  {
    lng: -41.9920648,
    lat: -22.882009
  },
  {
    lng: -41.991798,
    lat: -22.8818536
  },
  {
    lng: -41.9915784,
    lat: -22.8818788
  },
  {
    lng: -41.9910114,
    lat: -22.8820704
  },
  {
    lng: -41.9901322,
    lat: -22.882641
  },
  {
    lng: -41.9898285,
    lat: -22.8826837
  },
  {
    lng: -41.9896692,
    lat: -22.8827571
  },
  {
    lng: -41.989606,
    lat: -22.8824199
  },
  {
    lng: -41.9893685,
    lat: -22.882403
  },
  {
    lng: -41.9889826,
    lat: -22.8819591
  },
  {
    lng: -41.9888996,
    lat: -22.8811076
  },
  {
    lng: -41.9878687,
    lat: -22.880125
  },
  {
    lng: -41.9876435,
    lat: -22.8786729
  },
  {
    lng: -41.9862073,
    lat: -22.8772463
  },
  {
    lng: -41.9861403,
    lat: -22.8774753
  },
  {
    lng: -41.9854913,
    lat: -22.8772173
  },
  {
    lng: -41.9853547,
    lat: -22.8769656
  },
  {
    lng: -41.9850747,
    lat: -22.8769844
  },
  {
    lng: -41.985033,
    lat: -22.8768074
  },
  {
    lng: -41.9855187,
    lat: -22.8764306
  },
  {
    lng: -41.9854572,
    lat: -22.8762166
  },
  {
    lng: -41.9851635,
    lat: -22.8760404
  },
  {
    lng: -41.984979,
    lat: -22.8752726
  },
  {
    lng: -41.9847176,
    lat: -22.8752486
  },
  {
    lng: -41.9844257,
    lat: -22.8749139
  },
  {
    lng: -41.9839271,
    lat: -22.8746684
  },
  {
    lng: -41.9837973,
    lat: -22.874316
  },
  {
    lng: -41.9837427,
    lat: -22.8739825
  },
  {
    lng: -41.9838861,
    lat: -22.8733657
  },
  {
    lng: -41.9835446,
    lat: -22.8733216
  },
  {
    lng: -41.9830786,
    lat: -22.8735594
  },
  {
    lng: -41.9827772,
    lat: -22.8735315
  },
  {
    lng: -41.9826371,
    lat: -22.8733936
  },
  {
    lng: -41.9822411,
    lat: -22.8732512
  },
  {
    lng: -41.9820092,
    lat: -22.8728614
  },
  {
    lng: -41.9816711,
    lat: -22.8734043
  },
  {
    lng: -41.9814393,
    lat: -22.8732708
  },
  {
    lng: -41.9812944,
    lat: -22.8735111
  },
  {
    lng: -41.980821,
    lat: -22.8735378
  },
  {
    lng: -41.9806761,
    lat: -22.8737603
  },
  {
    lng: -41.9799454,
    lat: -22.873847
  },
  {
    lng: -41.9796328,
    lat: -22.8734755
  },
  {
    lng: -41.9796715,
    lat: -22.8729415
  },
  {
    lng: -41.9799516,
    lat: -22.8725944
  },
  {
    lng: -41.9795492,
    lat: -22.8722232
  },
  {
    lng: -41.9797584,
    lat: -22.8718734
  },
  {
    lng: -41.9796135,
    lat: -22.8716064
  },
  {
    lng: -41.9795556,
    lat: -22.8712148
  },
  {
    lng: -41.9796585,
    lat: -22.8708134
  },
  {
    lng: -41.9803091,
    lat: -22.8708054
  },
  {
    lng: -41.9807514,
    lat: -22.8710778
  },
  {
    lng: -41.9808744,
    lat: -22.8715058
  },
  {
    lng: -41.9815847,
    lat: -22.8714932
  },
  {
    lng: -41.9821449,
    lat: -22.871254
  },
  {
    lng: -41.9826793,
    lat: -22.8704603
  },
  {
    lng: -41.982911,
    lat: -22.8695251
  },
  {
    lng: -41.9828195,
    lat: -22.8686178
  },
  {
    lng: -41.9825794,
    lat: -22.8680935
  },
  {
    lng: -41.9821095,
    lat: -22.8676084
  },
  {
    lng: -41.9816567,
    lat: -22.8674564
  },
  {
    lng: -41.9815063,
    lat: -22.8674761
  },
  {
    lng: -41.9812998,
    lat: -22.8675206
  },
  {
    lng: -41.9810532,
    lat: -22.8676145
  },
  {
    lng: -41.9809297,
    lat: -22.867748
  },
  {
    lng: -41.9807687,
    lat: -22.8680717
  },
  {
    lng: -41.9806668,
    lat: -22.8682299
  },
  {
    lng: -41.9803907,
    lat: -22.868482
  },
  {
    lng: -41.9801814,
    lat: -22.8685413
  },
  {
    lng: -41.9799694,
    lat: -22.8685116
  },
  {
    lng: -41.979822,
    lat: -22.86844
  },
  {
    lng: -41.9796878,
    lat: -22.8683263
  },
  {
    lng: -41.9794787,
    lat: -22.8682175
  },
  {
    lng: -41.9792507,
    lat: -22.8680791
  },
  {
    lng: -41.9790066,
    lat: -22.8679655
  },
  {
    lng: -41.9788618,
    lat: -22.8678802
  },
  {
    lng: -41.978776,
    lat: -22.8678431
  },
  {
    lng: -41.978674,
    lat: -22.8678159
  },
  {
    lng: -41.978493,
    lat: -22.8678073
  },
  {
    lng: -41.9783428,
    lat: -22.8678122
  },
  {
    lng: -41.9782368,
    lat: -22.8678357
  },
  {
    lng: -41.9781778,
    lat: -22.8678221
  },
  {
    lng: -41.9781483,
    lat: -22.86779
  },
  {
    lng: -41.978143,
    lat: -22.8677554
  },
  {
    lng: -41.9781671,
    lat: -22.8676862
  },
  {
    lng: -41.9782435,
    lat: -22.8675725
  },
  {
    lng: -41.9782957,
    lat: -22.8675269
  },
  {
    lng: -41.9783937,
    lat: -22.8674724
  },
  {
    lng: -41.9785131,
    lat: -22.8674638
  },
  {
    lng: -41.9786388,
    lat: -22.8675021
  },
  {
    lng: -41.978725,
    lat: -22.8675589
  },
  {
    lng: -41.9788041,
    lat: -22.8676046
  },
  {
    lng: -41.978894,
    lat: -22.8676108
  },
  {
    lng: -41.9789463,
    lat: -22.8675849
  },
  {
    lng: -41.9789704,
    lat: -22.8675379
  },
  {
    lng: -41.9789811,
    lat: -22.8674477
  },
  {
    lng: -41.9790107,
    lat: -22.8673847
  },
  {
    lng: -41.9790804,
    lat: -22.8673451
  },
  {
    lng: -41.9791796,
    lat: -22.8673377
  },
  {
    lng: -41.9792078,
    lat: -22.8672945
  },
  {
    lng: -41.9791957,
    lat: -22.8672512
  },
  {
    lng: -41.9791487,
    lat: -22.8672166
  },
  {
    lng: -41.9789911,
    lat: -22.8671833
  },
  {
    lng: -41.9789537,
    lat: -22.8671703
  },
  {
    lng: -41.9789349,
    lat: -22.8671505
  },
  {
    lng: -41.9789221,
    lat: -22.8671202
  },
  {
    lng: -41.9788993,
    lat: -22.8670968
  },
  {
    lng: -41.9788725,
    lat: -22.8670869
  },
  {
    lng: -41.9788229,
    lat: -22.8670931
  },
  {
    lng: -41.9786485,
    lat: -22.8671252
  },
  {
    lng: -41.9785131,
    lat: -22.8670881
  },
  {
    lng: -41.978458,
    lat: -22.8669757
  },
  {
    lng: -41.9784367,
    lat: -22.8668509
  },
  {
    lng: -41.9784231,
    lat: -22.8667569
  },
  {
    lng: -41.9783816,
    lat: -22.8666396
  },
  {
    lng: -41.978316,
    lat: -22.8663541
  },
  {
    lng: -41.9782649,
    lat: -22.8662775
  },
  {
    lng: -41.9781738,
    lat: -22.8661613
  },
  {
    lng: -41.978057,
    lat: -22.8660872
  },
  {
    lng: -41.9779859,
    lat: -22.8660217
  },
  {
    lng: -41.9779525,
    lat: -22.865934
  },
  {
    lng: -41.9778399,
    lat: -22.8658252
  },
  {
    lng: -41.9777245,
    lat: -22.8657288
  },
  {
    lng: -41.9776521,
    lat: -22.8656201
  },
  {
    lng: -41.9776347,
    lat: -22.8655595
  },
  {
    lng: -41.9776427,
    lat: -22.8655039
  },
  {
    lng: -41.9777084,
    lat: -22.8653791
  },
  {
    lng: -41.9777311,
    lat: -22.865305
  },
  {
    lng: -41.9777378,
    lat: -22.8652395
  },
  {
    lng: -41.9777581,
    lat: -22.865195
  },
  {
    lng: -41.9777849,
    lat: -22.8651579
  },
  {
    lng: -41.9778452,
    lat: -22.8650924
  },
  {
    lng: -41.9778962,
    lat: -22.8650566
  },
  {
    lng: -41.9779445,
    lat: -22.8649998
  },
  {
    lng: -41.9780397,
    lat: -22.8648404
  },
  {
    lng: -41.9781255,
    lat: -22.8647749
  },
  {
    lng: -41.9781925,
    lat: -22.8647378
  },
  {
    lng: -41.9782516,
    lat: -22.8647477
  },
  {
    lng: -41.9783293,
    lat: -22.864786
  },
  {
    lng: -41.9784112,
    lat: -22.8648972
  },
  {
    lng: -41.9784781,
    lat: -22.865048
  },
  {
    lng: -41.9785278,
    lat: -22.8652296
  },
  {
    lng: -41.9785693,
    lat: -22.865326
  },
  {
    lng: -41.9787009,
    lat: -22.8654211
  },
  {
    lng: -41.9788846,
    lat: -22.8655113
  },
  {
    lng: -41.9790543,
    lat: -22.8656329
  },
  {
    lng: -41.9791353,
    lat: -22.8657362
  },
  {
    lng: -41.9792279,
    lat: -22.8658141
  },
  {
    lng: -41.9793352,
    lat: -22.8658462
  },
  {
    lng: -41.9794572,
    lat: -22.8658746
  },
  {
    lng: -41.9795833,
    lat: -22.8659809
  },
  {
    lng: -41.9797067,
    lat: -22.8661193
  },
  {
    lng: -41.9798174,
    lat: -22.866276
  },
  {
    lng: -41.9799309,
    lat: -22.8665252
  },
  {
    lng: -41.980077,
    lat: -22.8665958
  },
  {
    lng: -41.9802673,
    lat: -22.8666457
  },
  {
    lng: -41.9806776,
    lat: -22.8666853
  },
  {
    lng: -41.9810558,
    lat: -22.8667026
  },
  {
    lng: -41.9813241,
    lat: -22.8668088
  },
  {
    lng: -41.9815467,
    lat: -22.8669299
  },
  {
    lng: -41.9817059,
    lat: -22.8669522
  },
  {
    lng: -41.9828538,
    lat: -22.8661778
  },
  {
    lng: -41.9837281,
    lat: -22.86524
  },
  {
    lng: -41.9850942,
    lat: -22.863119
  },
  {
    lng: -41.9860369,
    lat: -22.8607901
  },
  {
    lng: -41.9864668,
    lat: -22.8595279
  },
  {
    lng: -41.9866511,
    lat: -22.8587403
  },
  {
    lng: -41.9867149,
    lat: -22.8559359
  },
  {
    lng: -41.9864494,
    lat: -22.8532706
  },
  {
    lng: -41.9857669,
    lat: -22.8506071
  },
  {
    lng: -41.9849474,
    lat: -22.8481769
  },
  {
    lng: -41.982799,
    lat: -22.8426476
  },
  {
    lng: -41.9814341,
    lat: -22.8394262
  },
  {
    lng: -41.9794135,
    lat: -22.8352773
  },
  {
    lng: -41.9779503,
    lat: -22.8327952
  },
  {
    lng: -41.975872,
    lat: -22.8295528
  },
  {
    lng: -41.9735769,
    lat: -22.8265649
  },
  {
    lng: -41.9699791,
    lat: -22.8229153
  },
  {
    lng: -41.9690088,
    lat: -22.8229386
  },
  {
    lng: -41.9688949,
    lat: -22.8229072
  },
  {
    lng: -41.968779,
    lat: -22.8228874
  },
  {
    lng: -41.9686678,
    lat: -22.8228598
  },
  {
    lng: -41.9686088,
    lat: -22.8228772
  },
  {
    lng: -41.9685179,
    lat: -22.8228788
  },
  {
    lng: -41.9684051,
    lat: -22.822801
  },
  {
    lng: -41.9683293,
    lat: -22.8227245
  },
  {
    lng: -41.96823,
    lat: -22.822619
  },
  {
    lng: -41.9680978,
    lat: -22.8225164
  },
  {
    lng: -41.967963,
    lat: -22.8224021
  },
  {
    lng: -41.9678344,
    lat: -22.8222891
  },
  {
    lng: -41.9677167,
    lat: -22.8221927
  },
  {
    lng: -41.9676011,
    lat: -22.8220675
  },
  {
    lng: -42.0018726,
    lat: -22.7952601
  },
  {
    lng: -42.0084193,
    lat: -22.7811768
  },
  {
    lng: -42.008117,
    lat: -22.7762098
  },
  {
    lng: -42.0007226,
    lat: -22.7662812
  },
  {
    lng: -41.9999201,
    lat: -22.7656254
  },
  {
    lng: -41.9994184,
    lat: -22.7661048
  },
  {
    lng: -41.9880674,
    lat: -22.7511966
  },
  {
    lng: -41.987037,
    lat: -22.7498432
  },
  {
    lng: -41.9865976,
    lat: -22.7491465
  },
  {
    lng: -41.9860318,
    lat: -22.7482481
  },
  {
    lng: -41.9853235,
    lat: -22.747125
  },
  {
    lng: -41.9847615,
    lat: -22.7462338
  },
  {
    lng: -41.9839945,
    lat: -22.7450174
  },
  {
    lng: -41.9834917,
    lat: -22.74422
  },
  {
    lng: -41.9826789,
    lat: -22.7429311
  },
  {
    lng: -41.9824725,
    lat: -22.7426038
  },
  {
    lng: -41.9814644,
    lat: -22.7410052
  },
  {
    lng: -41.9804964,
    lat: -22.7394433
  },
  {
    lng: -41.9803809,
    lat: -22.7392607
  },
  {
    lng: -41.9799955,
    lat: -22.7386511
  },
  {
    lng: -41.9786083,
    lat: -22.7364574
  },
  {
    lng: -41.977681,
    lat: -22.7349908
  },
  {
    lng: -41.9752619,
    lat: -22.7311649
  },
  {
    lng: -41.9747909,
    lat: -22.73042
  },
  {
    lng: -41.97442,
    lat: -22.7298334
  },
  {
    lng: -41.9760078,
    lat: -22.7287944
  },
  {
    lng: -41.9776386,
    lat: -22.7273446
  },
  {
    lng: -41.9808734,
    lat: -22.7239553
  },
  {
    lng: -41.9818926,
    lat: -22.7224165
  },
  {
    lng: -41.9827187,
    lat: -22.7208133
  },
  {
    lng: -41.9834644,
    lat: -22.7194674
  },
  {
    lng: -41.9848663,
    lat: -22.7172264
  },
  {
    lng: -41.9860768,
    lat: -22.7148754
  },
  {
    lng: -41.9868271,
    lat: -22.713165
  },
  {
    lng: -41.987007,
    lat: -22.7127956
  },
  {
    lng: -41.9870791,
    lat: -22.7126393
  },
  {
    lng: -41.9872725,
    lat: -22.7121037
  },
  {
    lng: -41.987459,
    lat: -22.711587
  },
  {
    lng: -41.9901468,
    lat: -22.7041406
  },
  {
    lng: -41.9941607,
    lat: -22.6885754
  },
  {
    lng: -41.9948793,
    lat: -22.683849
  },
  {
    lng: -41.9953242,
    lat: -22.6812532
  },
  {
    lng: -41.9960439,
    lat: -22.6767085
  },
  {
    lng: -41.9967583,
    lat: -22.671186
  },
  {
    lng: -41.997208,
    lat: -22.6677096
  },
  {
    lng: -41.9979161,
    lat: -22.6612298
  },
  {
    lng: -41.9986564,
    lat: -22.6550963
  },
  {
    lng: -41.9989622,
    lat: -22.6498585
  },
  {
    lng: -41.9989193,
    lat: -22.6496803
  },
  {
    lng: -41.9989568,
    lat: -22.6493536
  },
  {
    lng: -41.9990534,
    lat: -22.6490813
  },
  {
    lng: -41.9992948,
    lat: -22.6446899
  },
  {
    lng: -41.9993752,
    lat: -22.641655
  },
  {
    lng: -41.9994876,
    lat: -22.6369058
  },
  {
    lng: -41.9993538,
    lat: -22.6312922
  },
  {
    lng: -41.9991177,
    lat: -22.6260635
  },
  {
    lng: -41.9989568,
    lat: -22.623038
  },
  {
    lng: -41.9985974,
    lat: -22.6185419
  },
  {
    lng: -41.9983077,
    lat: -22.6149171
  },
  {
    lng: -41.9979966,
    lat: -22.6118023
  },
  {
    lng: -41.9976801,
    lat: -22.6093757
  },
  {
    lng: -41.9975325,
    lat: -22.6086188
  },
  {
    lng: -41.9973207,
    lat: -22.6074295
  },
  {
    lng: -41.9968218,
    lat: -22.6058349
  },
  {
    lng: -41.9964677,
    lat: -22.6042254
  },
  {
    lng: -41.9960761,
    lat: -22.6022445
  },
  {
    lng: -41.9957221,
    lat: -22.6009073
  },
  {
    lng: -41.9953358,
    lat: -22.5999366
  },
  {
    lng: -41.9949477,
    lat: -22.5993817
  },
  {
    lng: -41.9944292,
    lat: -22.5987579
  },
  {
    lng: -41.9940087,
    lat: -22.5984561
  },
  {
    lng: -41.9932642,
    lat: -22.598167
  },
  {
    lng: -41.9927661,
    lat: -22.5984804
  },
  {
    lng: -41.9916847,
    lat: -22.5970715
  },
  {
    lng: -41.9957861,
    lat: -22.5947831
  },
  {
    lng: -41.9956914,
    lat: -22.59128
  },
  {
    lng: -41.9909387,
    lat: -22.5804299
  },
  {
    lng: -41.9910412,
    lat: -22.579582
  },
  {
    lng: -41.9917933,
    lat: -22.5782164
  },
  {
    lng: -41.9936912,
    lat: -22.5765083
  },
  {
    lng: -41.9949508,
    lat: -22.5751133
  },
  {
    lng: -41.9960527,
    lat: -22.5744398
  },
  {
    lng: -41.9976733,
    lat: -22.5749939
  },
  {
    lng: -41.9993538,
    lat: -22.5785138
  },
  {
    lng: -42.0015171,
    lat: -22.5806515
  },
  {
    lng: -42.0021221,
    lat: -22.581542
  },
  {
    lng: -42.0024373,
    lat: -22.5836699
  },
  {
    lng: -42.0027867,
    lat: -22.5843062
  },
  {
    lng: -42.0029625,
    lat: -22.5846265
  },
  {
    lng: -42.003833,
    lat: -22.5849762
  },
  {
    lng: -42.0045397,
    lat: -22.5847614
  },
  {
    lng: -42.0051973,
    lat: -22.5842772
  },
  {
    lng: -42.0060045,
    lat: -22.5829055
  },
  {
    lng: -42.006365,
    lat: -22.5825494
  },
  {
    lng: -42.008048,
    lat: -22.5785745
  },
  {
    lng: -42.0086045,
    lat: -22.5769048
  },
  {
    lng: -42.0090621,
    lat: -22.5738464
  },
  {
    lng: -42.0093399,
    lat: -22.5733347
  },
  {
    lng: -42.0101532,
    lat: -22.5725149
  },
  {
    lng: -42.0110631,
    lat: -22.5716553
  },
  {
    lng: -42.0120277,
    lat: -22.5712569
  },
  {
    lng: -42.012873,
    lat: -22.5713155
  },
  {
    lng: -42.0132633,
    lat: -22.5715463
  },
  {
    lng: -42.0136825,
    lat: -22.572329
  },
  {
    lng: -42.0139031,
    lat: -22.5741364
  },
  {
    lng: -42.0141339,
    lat: -22.574692
  },
  {
    lng: -42.0150689,
    lat: -22.5761484
  },
  {
    lng: -42.0165914,
    lat: -22.5775359
  },
  {
    lng: -42.0174634,
    lat: -22.5780299
  },
  {
    lng: -42.0177691,
    lat: -22.5782032
  },
  {
    lng: -42.0184687,
    lat: -22.5783936
  },
  {
    lng: -42.019236,
    lat: -22.5783272
  },
  {
    lng: -42.0200507,
    lat: -22.5779828
  },
  {
    lng: -42.0208474,
    lat: -22.5775701
  },
  {
    lng: -42.0213426,
    lat: -22.5770106
  },
  {
    lng: -42.0215969,
    lat: -22.5764296
  },
  {
    lng: -42.0216886,
    lat: -22.5757545
  },
  {
    lng: -42.0215581,
    lat: -22.5749517
  },
  {
    lng: -42.0205625,
    lat: -22.5699494
  },
  {
    lng: -42.0205903,
    lat: -22.5691785
  },
  {
    lng: -42.0208413,
    lat: -22.5686982
  },
  {
    lng: -42.02115,
    lat: -22.5685883
  },
  {
    lng: -42.02176,
    lat: -22.5684545
  },
  {
    lng: -42.0223149,
    lat: -22.5685847
  },
  {
    lng: -42.0276989,
    lat: -22.5709972
  },
  {
    lng: -42.0283235,
    lat: -22.5712242
  },
  {
    lng: -42.029033,
    lat: -22.5711915
  },
  {
    lng: -42.0297385,
    lat: -22.5709461
  },
  {
    lng: -42.0302319,
    lat: -22.5704457
  },
  {
    lng: -42.0305788,
    lat: -22.5696595
  },
  {
    lng: -42.0306983,
    lat: -22.5676024
  },
  {
    lng: -42.0307345,
    lat: -22.5668362
  },
  {
    lng: -42.0314244,
    lat: -22.5644404
  },
  {
    lng: -42.0321586,
    lat: -22.5622672
  },
  {
    lng: -42.0324935,
    lat: -22.5613518
  },
  {
    lng: -42.0329778,
    lat: -22.5607294
  },
  {
    lng: -42.0336473,
    lat: -22.5601473
  },
  {
    lng: -42.0346255,
    lat: -22.5598517
  },
  {
    lng: -42.0353293,
    lat: -22.5597413
  },
  {
    lng: -42.0362578,
    lat: -22.5599203
  },
  {
    lng: -42.0368379,
    lat: -22.5601165
  },
  {
    lng: -42.0374533,
    lat: -22.5607762
  },
  {
    lng: -42.0383119,
    lat: -22.5660365
  },
  {
    lng: -42.038755,
    lat: -22.5672931
  },
  {
    lng: -42.0395302,
    lat: -22.5681969
  },
  {
    lng: -42.040007,
    lat: -22.5685071
  },
  {
    lng: -42.040608,
    lat: -22.5687645
  },
  {
    lng: -42.0410816,
    lat: -22.5688985
  },
  {
    lng: -42.0416182,
    lat: -22.5689018
  },
  {
    lng: -42.0425985,
    lat: -22.5686628
  },
  {
    lng: -42.0434593,
    lat: -22.5676699
  },
  {
    lng: -42.044055,
    lat: -22.5665531
  },
  {
    lng: -42.0483798,
    lat: -22.5618629
  },
  {
    lng: -42.0491603,
    lat: -22.5613279
  },
  {
    lng: -42.0498747,
    lat: -22.5612407
  },
  {
    lng: -42.0507116,
    lat: -22.5616278
  },
  {
    lng: -42.0523474,
    lat: -22.5626185
  },
  {
    lng: -42.0538,
    lat: -22.5630494
  },
  {
    lng: -42.0547362,
    lat: -22.5630814
  },
  {
    lng: -42.0559116,
    lat: -22.5629292
  },
  {
    lng: -42.0571101,
    lat: -22.5624012
  },
  {
    lng: -42.0594057,
    lat: -22.561141
  },
  {
    lng: -42.06115,
    lat: -22.5600275
  },
  {
    lng: -42.0631034,
    lat: -22.5610215
  },
  {
    lng: -42.0630243,
    lat: -22.5615944
  },
  {
    lng: -42.0627341,
    lat: -22.5620455
  },
  {
    lng: -42.0611602,
    lat: -22.5637508
  },
  {
    lng: -42.0606941,
    lat: -22.5646737
  },
  {
    lng: -42.0607853,
    lat: -22.5698875
  },
  {
    lng: -42.0613928,
    lat: -22.5719775
  },
  {
    lng: -42.0627881,
    lat: -22.5729786
  },
  {
    lng: -42.0651742,
    lat: -22.5689008
  },
  {
    lng: -42.0664825,
    lat: -22.568205
  },
  {
    lng: -42.0700597,
    lat: -22.5685728
  },
  {
    lng: -42.0727506,
    lat: -22.5676942
  },
  {
    lng: -42.0751714,
    lat: -22.5712494
  },
  {
    lng: -42.0793353,
    lat: -22.5748333
  },
  {
    lng: -42.0817033,
    lat: -22.5747589
  },
  {
    lng: -42.0840025,
    lat: -22.5695361
  },
  {
    lng: -42.0856475,
    lat: -22.5700217
  },
  {
    lng: -42.0875683,
    lat: -22.5721708
  },
  {
    lng: -42.0918364,
    lat: -22.5727675
  },
  {
    lng: -42.09381,
    lat: -22.5708303
  },
  {
    lng: -42.0917006,
    lat: -22.5654742
  },
  {
    lng: -42.0918742,
    lat: -22.5629414
  },
  {
    lng: -42.0928886,
    lat: -22.5619125
  },
  {
    lng: -42.0959836,
    lat: -22.5609906
  },
  {
    lng: -42.0974528,
    lat: -22.5612578
  },
  {
    lng: -42.1004822,
    lat: -22.5642178
  },
  {
    lng: -42.105205,
    lat: -22.5601589
  },
  {
    lng: -42.1074219,
    lat: -22.5647231
  },
  {
    lng: -42.1098133,
    lat: -22.5667214
  },
  {
    lng: -42.1135653,
    lat: -22.5658639
  },
  {
    lng: -42.1182886,
    lat: -22.5662864
  },
  {
    lng: -42.1243181,
    lat: -22.5641364
  },
  {
    lng: -42.1244217,
    lat: -22.56338
  },
  {
    lng: -42.1211175,
    lat: -22.5605586
  },
  {
    lng: -42.1205092,
    lat: -22.5580167
  },
  {
    lng: -42.1214269,
    lat: -22.5543119
  },
  {
    lng: -42.1225314,
    lat: -22.5538231
  },
  {
    lng: -42.1247036,
    lat: -22.5565506
  },
  {
    lng: -42.1274535,
    lat: -22.5573125
  },
  {
    lng: -42.13309,
    lat: -22.5548925
  },
  {
    lng: -42.1336781,
    lat: -22.5541614
  },
  {
    lng: -42.1330889,
    lat: -22.5534336
  },
  {
    lng: -42.1307267,
    lat: -22.5521058
  },
  {
    lng: -42.1315653,
    lat: -22.5493994
  },
  {
    lng: -42.1315022,
    lat: -22.5459783
  },
  {
    lng: -42.1329844,
    lat: -22.5447428
  },
  {
    lng: -42.1336769,
    lat: -22.5428289
  },
  {
    lng: -42.1385922,
    lat: -22.5399025
  },
  {
    lng: -42.1404483,
    lat: -22.541665
  },
  {
    lng: -42.1438714,
    lat: -22.5405167
  },
  {
    lng: -42.1461744,
    lat: -22.540069
  },
  {
    lng: -42.1475097,
    lat: -22.5404157
  },
  {
    lng: -42.1478917,
    lat: -22.5412884
  },
  {
    lng: -42.1470328,
    lat: -22.5425329
  },
  {
    lng: -42.1439028,
    lat: -22.5429918
  },
  {
    lng: -42.1430459,
    lat: -22.5444319
  },
  {
    lng: -42.1413458,
    lat: -22.5472887
  },
  {
    lng: -42.1412614,
    lat: -22.5485909
  },
  {
    lng: -42.1437847,
    lat: -22.5480801
  },
  {
    lng: -42.1443108,
    lat: -22.551214
  },
  {
    lng: -42.1465886,
    lat: -22.551387
  },
  {
    lng: -42.1488944,
    lat: -22.5551676
  },
  {
    lng: -42.1513656,
    lat: -22.5561384
  },
  {
    lng: -42.1523708,
    lat: -22.5554551
  },
  {
    lng: -42.1536497,
    lat: -22.5506101
  },
  {
    lng: -42.1517283,
    lat: -22.5510401
  },
  {
    lng: -42.1511781,
    lat: -22.5480559
  },
  {
    lng: -42.1558139,
    lat: -22.545652
  },
  {
    lng: -42.15667,
    lat: -22.5471284
  },
  {
    lng: -42.1600389,
    lat: -22.5448382
  },
  {
    lng: -42.1615075,
    lat: -22.5445432
  },
  {
    lng: -42.1634225,
    lat: -22.5461932
  },
  {
    lng: -42.1650306,
    lat: -22.5449673
  },
  {
    lng: -42.1657281,
    lat: -22.5474107
  },
  {
    lng: -42.1669811,
    lat: -22.5476715
  },
  {
    lng: -42.1694492,
    lat: -22.5466482
  },
  {
    lng: -42.1704525,
    lat: -22.547904
  },
  {
    lng: -42.171505,
    lat: -22.5480254
  },
  {
    lng: -42.1764283,
    lat: -22.5452318
  },
  {
    lng: -42.1785861,
    lat: -22.5482157
  },
  {
    lng: -42.1797014,
    lat: -22.5496673
  },
  {
    lng: -42.1794231,
    lat: -22.550857
  },
  {
    lng: -42.1821965,
    lat: -22.5521695
  },
];

export default polygonCaboFrio;