let polygonCoords_Rio = [
  {
  lng: -43.7956815,
  lat: -22.9167565
  },
  {
  lng: -43.7953052,
  lat: -22.9171141
  },
  {
  lng: -43.7956182,
  lat: -22.9180706
  },
  {
  lng: -43.7962372,
  lat: -22.9189477
  },
  {
  lng: -43.796252,
  lat: -22.9191968
  },
  {
  lng: -43.7961283,
  lat: -22.9193538
  },
  {
  lng: -43.7957828,
  lat: -22.9193882
  },
  {
  lng: -43.7954246,
  lat: -22.9196288
  },
  {
  lng: -43.7950965,
  lat: -22.9201859
  },
  {
  lng: -43.7947277,
  lat: -22.9201866
  },
  {
  lng: -43.7944957,
  lat: -22.9202649
  },
  {
  lng: -43.7938656,
  lat: -22.9212836
  },
  {
  lng: -43.7931283,
  lat: -22.9219195
  },
  {
  lng: -43.7919619,
  lat: -22.9222529
  },
  {
  lng: -43.7909698,
  lat: -22.923321
  },
  {
  lng: -43.7898693,
  lat: -22.9254798
  },
  {
  lng: -43.7897017,
  lat: -22.9256157
  },
  {
  lng: -43.7893945,
  lat: -22.9256421
  },
  {
  lng: -43.7892007,
  lat: -22.9256952
  },
  {
  lng: -43.7869086,
  lat: -22.9269006
  },
  {
  lng: -43.7867988,
  lat: -22.9270122
  },
  {
  lng: -43.7866928,
  lat: -22.9271285
  },
  {
  lng: -43.7866215,
  lat: -22.9272578
  },
  {
  lng: -43.7866061,
  lat: -22.9273963
  },
  {
  lng: -43.7866015,
  lat: -22.9275448
  },
  {
  lng: -43.7865965,
  lat: -22.9276999
  },
  {
  lng: -43.7865913,
  lat: -22.9278644
  },
  {
  lng: -43.7865811,
  lat: -22.9280128
  },
  {
  lng: -43.7865473,
  lat: -22.9281502
  },
  {
  lng: -43.7865072,
  lat: -22.9282818
  },
  {
  lng: -43.7864353,
  lat: -22.9284273
  },
  {
  lng: -43.7863536,
  lat: -22.9285562
  },
  {
  lng: -43.7862721,
  lat: -22.9286837
  },
  {
  lng: -43.7862069,
  lat: -22.9288107
  },
  {
  lng: -43.7860939,
  lat: -22.9289073
  },
  {
  lng: -43.7859582,
  lat: -22.9289698
  },
  {
  lng: -43.7858073,
  lat: -22.9290001
  },
  {
  lng: -43.785646,
  lat: -22.9290073
  },
  {
  lng: -43.7854943,
  lat: -22.9290011
  },
  {
  lng: -43.7853497,
  lat: -22.928974
  },
  {
  lng: -43.7851889,
  lat: -22.9288426
  },
  {
  lng: -43.7851685,
  lat: -22.9288304
  },
  {
  lng: -43.7850449,
  lat: -22.9287461
  },
  {
  lng: -43.7849137,
  lat: -22.9286603
  },
  {
  lng: -43.7847901,
  lat: -22.9285768
  },
  {
  lng: -43.7846905,
  lat: -22.9284741
  },
  {
  lng: -43.7845608,
  lat: -22.9283827
  },
  {
  lng: -43.784432,
  lat: -22.9283124
  },
  {
  lng: -43.7842808,
  lat: -22.9282309
  },
  {
  lng: -43.7841427,
  lat: -22.928163
  },
  {
  lng: -43.7839489,
  lat: -22.9281213
  },
  {
  lng: -43.7834636,
  lat: -22.9281365
  },
  {
  lng: -43.7807044,
  lat: -22.9283862
  },
  {
  lng: -43.7802009,
  lat: -22.9286533
  },
  {
  lng: -43.7778645,
  lat: -22.9309939
  },
  {
  lng: -43.7772454,
  lat: -22.9310314
  },
  {
  lng: -43.7771902,
  lat: -22.9310767
  },
  {
  lng: -43.7771368,
  lat: -22.9311294
  },
  {
  lng: -43.7748958,
  lat: -22.9324768
  },
  {
  lng: -43.7739139,
  lat: -22.9328364
  },
  {
  lng: -43.7731271,
  lat: -22.932966
  },
  {
  lng: -43.7725502,
  lat: -22.9328827
  },
  {
  lng: -43.7715741,
  lat: -22.9325841
  },
  {
  lng: -43.769653,
  lat: -22.9324678
  },
  {
  lng: -43.7689694,
  lat: -22.9325708
  },
  {
  lng: -43.7682814,
  lat: -22.9325671
  },
  {
  lng: -43.7680965,
  lat: -22.9326779
  },
  {
  lng: -43.7680415,
  lat: -22.9329044
  },
  {
  lng: -43.7680652,
  lat: -22.9332043
  },
  {
  lng: -43.7681588,
  lat: -22.9335962
  },
  {
  lng: -43.7681335,
  lat: -22.9339898
  },
  {
  lng: -43.7677368,
  lat: -22.9344736
  },
  {
  lng: -43.7671532,
  lat: -22.9350175
  },
  {
  lng: -43.766392,
  lat: -22.9354808
  },
  {
  lng: -43.7646216,
  lat: -22.9363262
  },
  {
  lng: -43.7612963,
  lat: -22.9372846
  },
  {
  lng: -43.7583484,
  lat: -22.938499
  },
  {
  lng: -43.755363,
  lat: -22.9400078
  },
  {
  lng: -43.75354,
  lat: -22.9410886
  },
  {
  lng: -43.7528791,
  lat: -22.9417367
  },
  {
  lng: -43.7523212,
  lat: -22.9423691
  },
  {
  lng: -43.7516603,
  lat: -22.9429855
  },
  {
  lng: -43.7511366,
  lat: -22.9431831
  },
  {
  lng: -43.7504242,
  lat: -22.9433176
  },
  {
  lng: -43.750081,
  lat: -22.9437365
  },
  {
  lng: -43.7496089,
  lat: -22.9442185
  },
  {
  lng: -43.7490339,
  lat: -22.9448508
  },
  {
  lng: -43.748476,
  lat: -22.9452777
  },
  {
  lng: -43.7477377,
  lat: -22.9456809
  },
  {
  lng: -43.7470617,
  lat: -22.9459034
  },
  {
  lng: -43.7461817,
  lat: -22.9458853
  },
  {
  lng: -43.7457814,
  lat: -22.946025
  },
  {
  lng: -43.7453808,
  lat: -22.9461309
  },
  {
  lng: -43.744751,
  lat: -22.9471547
  },
  {
  lng: -43.742385,
  lat: -22.948997
  },
  {
  lng: -43.740414,
  lat: -22.9500706
  },
  {
  lng: -43.7374695,
  lat: -22.9524652
  },
  {
  lng: -43.7309611,
  lat: -22.95621
  },
  {
  lng: -43.7253479,
  lat: -22.9603407
  },
  {
  lng: -43.7224184,
  lat: -22.9625366
  },
  {
  lng: -43.7209556,
  lat: -22.9638968
  },
  {
  lng: -43.7196165,
  lat: -22.9657042
  },
  {
  lng: -43.7188502,
  lat: -22.9664939
  },
  {
  lng: -43.7184654,
  lat: -22.9672017
  },
  {
  lng: -43.7180582,
  lat: -22.9681902
  },
  {
  lng: -43.7179793,
  lat: -22.9690291
  },
  {
  lng: -43.7180489,
  lat: -22.9696672
  },
  {
  lng: -43.7182549,
  lat: -22.9700386
  },
  {
  lng: -43.7186584,
  lat: -22.9703784
  },
  {
  lng: -43.7190188,
  lat: -22.9704732
  },
  {
  lng: -43.719063,
  lat: -22.9706158
  },
  {
  lng: -43.7191851,
  lat: -22.9706914
  },
  {
  lng: -43.7193621,
  lat: -22.9707656
  },
  {
  lng: -43.7194016,
  lat: -22.9706503
  },
  {
  lng: -43.7195607,
  lat: -22.9706885
  },
  {
  lng: -43.7197841,
  lat: -22.970672
  },
  {
  lng: -43.7198217,
  lat: -22.970584
  },
  {
  lng: -43.7200367,
  lat: -22.9706339
  },
  {
  lng: -43.7202376,
  lat: -22.9709948
  },
  {
  lng: -43.7201346,
  lat: -22.971382
  },
  {
  lng: -43.7198956,
  lat: -22.971369
  },
  {
  lng: -43.7196338,
  lat: -22.9712189
  },
  {
  lng: -43.7194565,
  lat: -22.9711845
  },
  {
  lng: -43.7193285,
  lat: -22.9712803
  },
  {
  lng: -43.7192911,
  lat: -22.9714081
  },
  {
  lng: -43.7193279,
  lat: -22.9714847
  },
  {
  lng: -43.7195547,
  lat: -22.9717869
  },
  {
  lng: -43.7195604,
  lat: -22.9718685
  },
  {
  lng: -43.7195085,
  lat: -22.9718828
  },
  {
  lng: -43.7194136,
  lat: -22.9718559
  },
  {
  lng: -43.7193798,
  lat: -22.971952
  },
  {
  lng: -43.7192549,
  lat: -22.971916
  },
  {
  lng: -43.7191299,
  lat: -22.9718468
  },
  {
  lng: -43.7190497,
  lat: -22.9717617
  },
  {
  lng: -43.7189695,
  lat: -22.9717335
  },
  {
  lng: -43.7188214,
  lat: -22.9716744
  },
  {
  lng: -43.718521,
  lat: -22.9718483
  },
  {
  lng: -43.7183322,
  lat: -22.9720695
  },
  {
  lng: -43.7180571,
  lat: -22.9719114
  },
  {
  lng: -43.7179668,
  lat: -22.9719114
  },
  {
  lng: -43.7179297,
  lat: -22.9718922
  },
  {
  lng: -43.717962,
  lat: -22.9716021
  },
  {
  lng: -43.7178772,
  lat: -22.9714871
  },
  {
  lng: -43.7179754,
  lat: -22.9711995
  },
  {
  lng: -43.7179202,
  lat: -22.9709158
  },
  {
  lng: -43.7168709,
  lat: -22.9713148
  },
  {
  lng: -43.7162947,
  lat: -22.971438
  },
  {
  lng: -43.7145946,
  lat: -22.9718547
  },
  {
  lng: -43.7131602,
  lat: -22.9722838
  },
  {
  lng: -43.7110067,
  lat: -22.9733867
  },
  {
  lng: -43.7089012,
  lat: -22.9752016
  },
  {
  lng: -43.7080722,
  lat: -22.9759727
  },
  {
  lng: -43.7064616,
  lat: -22.9773727
  },
  {
  lng: -43.7054835,
  lat: -22.9783573
  },
  {
  lng: -43.7048042,
  lat: -22.978461
  },
  {
  lng: -43.7037119,
  lat: -22.9798689
  },
  {
  lng: -43.7033337,
  lat: -22.9803019
  },
  {
  lng: -43.7030205,
  lat: -22.980439
  },
  {
  lng: -43.7026248,
  lat: -22.9810974
  },
  {
  lng: -43.701496,
  lat: -22.9833918
  },
  {
  lng: -43.7012589,
  lat: -22.9838838
  },
  {
  lng: -43.7009333,
  lat: -22.9843204
  },
  {
  lng: -43.7006714,
  lat: -22.9846461
  },
  {
  lng: -43.7004058,
  lat: -22.9849573
  },
  {
  lng: -43.7003462,
  lat: -22.9850689
  },
  {
  lng: -43.7003054,
  lat: -22.9853596
  },
  {
  lng: -43.7004978,
  lat: -22.9854662
  },
  {
  lng: -43.7006006,
  lat: -22.9855934
  },
  {
  lng: -43.7005392,
  lat: -22.9857021
  },
  {
  lng: -43.7005529,
  lat: -22.9857789
  },
  {
  lng: -43.7004921,
  lat: -22.9858153
  },
  {
  lng: -43.7004201,
  lat: -22.9857859
  },
  {
  lng: -43.7003878,
  lat: -22.985695
  },
  {
  lng: -43.7002477,
  lat: -22.9857589
  },
  {
  lng: -43.7002273,
  lat: -22.985788
  },
  {
  lng: -43.700262,
  lat: -22.9858067
  },
  {
  lng: -43.70025,
  lat: -22.9858837
  },
  {
  lng: -43.7002017,
  lat: -22.9859766
  },
  {
  lng: -43.7001473,
  lat: -22.985964
  },
  {
  lng: -43.700107,
  lat: -22.9860243
  },
  {
  lng: -43.7001288,
  lat: -22.9860734
  },
  {
  lng: -43.7000775,
  lat: -22.9861039
  },
  {
  lng: -43.7000192,
  lat: -22.9860472
  },
  {
  lng: -43.699981,
  lat: -22.9860926
  },
  {
  lng: -43.7000013,
  lat: -22.9861676
  },
  {
  lng: -43.7000447,
  lat: -22.9862532
  },
  {
  lng: -43.7000925,
  lat: -22.9862824
  },
  {
  lng: -43.7001224,
  lat: -22.9862104
  },
  {
  lng: -43.7001774,
  lat: -22.9862183
  },
  {
  lng: -43.700272,
  lat: -22.9863118
  },
  {
  lng: -43.7001299,
  lat: -22.9863404
  },
  {
  lng: -43.7000105,
  lat: -22.9863218
  },
  {
  lng: -43.6999399,
  lat: -22.9862574
  },
  {
  lng: -43.6999233,
  lat: -22.9861709
  },
  {
  lng: -43.6999232,
  lat: -22.9861634
  },
  {
  lng: -43.6999542,
  lat: -22.9861599
  },
  {
  lng: -43.6999502,
  lat: -22.9861151
  },
  {
  lng: -43.6999313,
  lat: -22.9860901
  },
  {
  lng: -43.6999124,
  lat: -22.9860803
  },
  {
  lng: -43.6998584,
  lat: -22.9860536
  },
  {
  lng: -43.6997799,
  lat: -22.9860369
  },
  {
  lng: -43.6996935,
  lat: -22.9860333
  },
  {
  lng: -43.6997082,
  lat: -22.9860853
  },
  {
  lng: -43.6996511,
  lat: -22.9861515
  },
  {
  lng: -43.6996278,
  lat: -22.9861649
  },
  {
  lng: -43.6994935,
  lat: -22.9861274
  },
  {
  lng: -43.6994588,
  lat: -22.9862297
  },
  {
  lng: -43.6993276,
  lat: -22.9861917
  },
  {
  lng: -43.6990128,
  lat: -22.9865414
  },
  {
  lng: -43.6989725,
  lat: -22.9865079
  },
  {
  lng: -43.6992131,
  lat: -22.9862198
  },
  {
  lng: -43.6993193,
  lat: -22.9859755
  },
  {
  lng: -43.6991549,
  lat: -22.9858079
  },
  {
  lng: -43.6990066,
  lat: -22.985718
  },
  {
  lng: -43.6989081,
  lat: -22.985855
  },
  {
  lng: -43.6986474,
  lat: -22.9858189
  },
  {
  lng: -43.6986122,
  lat: -22.9858663
  },
  {
  lng: -43.6983548,
  lat: -22.9858314
  },
  {
  lng: -43.6983097,
  lat: -22.9858253
  },
  {
  lng: -43.698283,
  lat: -22.9859026
  },
  {
  lng: -43.6976905,
  lat: -22.985704
  },
  {
  lng: -43.6973927,
  lat: -22.9855948
  },
  {
  lng: -43.6974638,
  lat: -22.9854406
  },
  {
  lng: -43.6970507,
  lat: -22.9852652
  },
  {
  lng: -43.696633,
  lat: -22.9851186
  },
  {
  lng: -43.6961836,
  lat: -22.984783
  },
  {
  lng: -43.695819,
  lat: -22.9846754
  },
  {
  lng: -43.6955544,
  lat: -22.9848943
  },
  {
  lng: -43.6952386,
  lat: -22.9852131
  },
  {
  lng: -43.6946015,
  lat: -22.9856171
  },
  {
  lng: -43.6938032,
  lat: -22.98617
  },
  {
  lng: -43.6932713,
  lat: -22.9867109
  },
  {
  lng: -43.6927293,
  lat: -22.9872951
  },
  {
  lng: -43.6914539,
  lat: -22.9886237
  },
  {
  lng: -43.691002,
  lat: -22.9892596
  },
  {
  lng: -43.690627,
  lat: -22.9898104
  },
  {
  lng: -43.6896896,
  lat: -22.9917011
  },
  {
  lng: -43.6898484,
  lat: -22.9919338
  },
  {
  lng: -43.6898368,
  lat: -22.9919951
  },
  {
  lng: -43.689674,
  lat: -22.9920354
  },
  {
  lng: -43.6895467,
  lat: -22.99206
  },
  {
  lng: -43.6893964,
  lat: -22.9920363
  },
  {
  lng: -43.6892253,
  lat: -22.991964
  },
  {
  lng: -43.68904,
  lat: -22.991866
  },
  {
  lng: -43.6891482,
  lat: -22.991552
  },
  {
  lng: -43.6890855,
  lat: -22.9911342
  },
  {
  lng: -43.6887974,
  lat: -22.9904189
  },
  {
  lng: -43.6887266,
  lat: -22.9901257
  },
  {
  lng: -43.6888115,
  lat: -22.9895555
  },
  {
  lng: -43.6889531,
  lat: -22.9892265
  },
  {
  lng: -43.6889779,
  lat: -22.988728
  },
  {
  lng: -43.6889849,
  lat: -22.9883696
  },
  {
  lng: -43.6887819,
  lat: -22.9879304
  },
  {
  lng: -43.6883728,
  lat: -22.9873465
  },
  {
  lng: -43.6877463,
  lat: -22.9863855
  },
  {
  lng: -43.6875608,
  lat: -22.9861387
  },
  {
  lng: -43.6875234,
  lat: -22.9860889
  },
  {
  lng: -43.6871337,
  lat: -22.9858574
  },
  {
  lng: -43.6863164,
  lat: -22.9852841
  },
  {
  lng: -43.6855342,
  lat: -22.9848508
  },
  {
  lng: -43.6848087,
  lat: -22.9846064
  },
  {
  lng: -43.6844548,
  lat: -22.9845445
  },
  {
  lng: -43.6839239,
  lat: -22.9845478
  },
  {
  lng: -43.6832975,
  lat: -22.9840721
  },
  {
  lng: -43.6827418,
  lat: -22.9835606
  },
  {
  lng: -43.6821118,
  lat: -22.9827459
  },
  {
  lng: -43.6816376,
  lat: -22.982368
  },
  {
  lng: -43.6814252,
  lat: -22.9820585
  },
  {
  lng: -43.6814985,
  lat: -22.9818734
  },
  {
  lng: -43.6813536,
  lat: -22.9817091
  },
  {
  lng: -43.6812327,
  lat: -22.9818592
  },
  {
  lng: -43.680987,
  lat: -22.981918
  },
  {
  lng: -43.6801125,
  lat: -22.9816524
  },
  {
  lng: -43.6792581,
  lat: -22.9813509
  },
  {
  lng: -43.67881,
  lat: -22.981148
  },
  {
  lng: -43.6783436,
  lat: -22.9813364
  },
  {
  lng: -43.6780464,
  lat: -22.9811268
  },
  {
  lng: -43.6774361,
  lat: -22.9807349
  },
  {
  lng: -43.6767633,
  lat: -22.9805617
  },
  {
  lng: -43.676161,
  lat: -22.9805595
  },
  {
  lng: -43.6755838,
  lat: -22.9807151
  },
  {
  lng: -43.6748493,
  lat: -22.9805381
  },
  {
  lng: -43.6743995,
  lat: -22.9804813
  },
  {
  lng: -43.6742661,
  lat: -22.9805254
  },
  {
  lng: -43.6701562,
  lat: -22.982975
  },
  {
  lng: -43.6681479,
  lat: -22.9842303
  },
  {
  lng: -43.6668908,
  lat: -22.9851017
  },
  {
  lng: -43.6660077,
  lat: -22.9856764
  },
  {
  lng: -43.6656404,
  lat: -22.9856565
  },
  {
  lng: -43.6650322,
  lat: -22.9860981
  },
  {
  lng: -43.663324,
  lat: -22.9875848
  },
  {
  lng: -43.6624429,
  lat: -22.9885111
  },
  {
  lng: -43.6610749,
  lat: -22.9899875
  },
  {
  lng: -43.6600563,
  lat: -22.9910149
  },
  {
  lng: -43.6597376,
  lat: -22.9913301
  },
  {
  lng: -43.6589147,
  lat: -22.9921437
  },
  {
  lng: -43.6571211,
  lat: -22.9942109
  },
  {
  lng: -43.6562415,
  lat: -22.9956023
  },
  {
  lng: -43.6553619,
  lat: -22.9971035
  },
  {
  lng: -43.6549005,
  lat: -22.9978147
  },
  {
  lng: -43.6551691,
  lat: -22.9981741
  },
  {
  lng: -43.6553543,
  lat: -22.9982943
  },
  {
  lng: -43.6546234,
  lat: -22.9993504
  },
  {
  lng: -43.6541924,
  lat: -23.0000516
  },
  {
  lng: -43.6540344,
  lat: -23.0000697
  },
  {
  lng: -43.6537285,
  lat: -23.0000577
  },
  {
  lng: -43.6535106,
  lat: -23.0000581
  },
  {
  lng: -43.6532242,
  lat: -23.0000853
  },
  {
  lng: -43.6530391,
  lat: -23.0001093
  },
  {
  lng: -43.6529372,
  lat: -23.0001587
  },
  {
  lng: -43.6528722,
  lat: -23.0002028
  },
  {
  lng: -43.652636,
  lat: -23.0006346
  },
  {
  lng: -43.6524658,
  lat: -23.0009447
  },
  {
  lng: -43.6523537,
  lat: -23.0011761
  },
  {
  lng: -43.6522558,
  lat: -23.0014707
  },
  {
  lng: -43.6524531,
  lat: -23.0016399
  },
  {
  lng: -43.6526378,
  lat: -23.0019247
  },
  {
  lng: -43.6528023,
  lat: -23.002036
  },
  {
  lng: -43.6528968,
  lat: -23.002081
  },
  {
  lng: -43.6530148,
  lat: -23.0021545
  },
  {
  lng: -43.6530826,
  lat: -23.0021983
  },
  {
  lng: -43.6531469,
  lat: -23.0022471
  },
  {
  lng: -43.6532502,
  lat: -23.0023069
  },
  {
  lng: -43.653275,
  lat: -23.0023342
  },
  {
  lng: -43.6532844,
  lat: -23.0023644
  },
  {
  lng: -43.6532712,
  lat: -23.0023955
  },
  {
  lng: -43.6532429,
  lat: -23.0025162
  },
  {
  lng: -43.6532207,
  lat: -23.0026286
  },
  {
  lng: -43.6531912,
  lat: -23.002697
  },
  {
  lng: -43.6531516,
  lat: -23.0027803
  },
  {
  lng: -43.6531114,
  lat: -23.0027977
  },
  {
  lng: -43.6530296,
  lat: -23.002807
  },
  {
  lng: -43.6528685,
  lat: -23.0028061
  },
  {
  lng: -43.6522439,
  lat: -23.0024958
  },
  {
  lng: -43.6519414,
  lat: -23.0023417
  },
  {
  lng: -43.6512998,
  lat: -23.0022291
  },
  {
  lng: -43.6508492,
  lat: -23.0021402
  },
  {
  lng: -43.650379,
  lat: -23.0020898
  },
  {
  lng: -43.6498769,
  lat: -23.0019304
  },
  {
  lng: -43.6498203,
  lat: -23.0018372
  },
  {
  lng: -43.6496588,
  lat: -23.0018158
  },
  {
  lng: -43.6494115,
  lat: -23.0017365
  },
  {
  lng: -43.6489287,
  lat: -23.0013897
  },
  {
  lng: -43.6488019,
  lat: -23.0011406
  },
  {
  lng: -43.6487501,
  lat: -23.0010891
  },
  {
  lng: -43.6486596,
  lat: -23.0010459
  },
  {
  lng: -43.6485248,
  lat: -23.0010126
  },
  {
  lng: -43.6483303,
  lat: -23.001015
  },
  {
  lng: -43.6483035,
  lat: -23.0010385
  },
  {
  lng: -43.6483035,
  lat: -23.0010743
  },
  {
  lng: -43.6482767,
  lat: -23.0010842
  },
  {
  lng: -43.64816,
  lat: -23.0010619
  },
  {
  lng: -43.6481412,
  lat: -23.0010385
  },
  {
  lng: -43.6481372,
  lat: -23.000936
  },
  {
  lng: -43.6481211,
  lat: -23.0009015
  },
  {
  lng: -43.6480758,
  lat: -23.0008811
  },
  {
  lng: -43.6478,
  lat: -23.0008892
  },
  {
  lng: -43.6475394,
  lat: -23.0009897
  },
  {
  lng: -43.6471487,
  lat: -23.0011787
  },
  {
  lng: -43.6464858,
  lat: -23.0017319
  },
  {
  lng: -43.6462143,
  lat: -23.0018563
  },
  {
  lng: -43.6459461,
  lat: -23.0018662
  },
  {
  lng: -43.6456242,
  lat: -23.0017946
  },
  {
  lng: -43.6453592,
  lat: -23.0017953
  },
  {
  lng: -43.6451307,
  lat: -23.0018835
  },
  {
  lng: -43.644863,
  lat: -23.001541
  },
  {
  lng: -43.6445997,
  lat: -23.0012761
  },
  {
  lng: -43.6444226,
  lat: -23.0011625
  },
  {
  lng: -43.6442112,
  lat: -23.001183
  },
  {
  lng: -43.6439881,
  lat: -23.0012712
  },
  {
  lng: -43.6437467,
  lat: -23.0013032
  },
  {
  lng: -43.643487,
  lat: -23.0012496
  },
  {
  lng: -43.6433068,
  lat: -23.0011724
  },
  {
  lng: -43.6428026,
  lat: -23.0014489
  },
  {
  lng: -43.6422929,
  lat: -23.0018192
  },
  {
  lng: -43.6407963,
  lat: -23.0033426
  },
  {
  lng: -43.6408175,
  lat: -23.0034612
  },
  {
  lng: -43.6403186,
  lat: -23.0040661
  },
  {
  lng: -43.6401316,
  lat: -23.0040996
  },
  {
  lng: -43.6395275,
  lat: -23.0049997
  },
  {
  lng: -43.6385507,
  lat: -23.0062569
  },
  {
  lng: -43.6382691,
  lat: -23.0062317
  },
  {
  lng: -43.6376033,
  lat: -23.0057123
  },
  {
  lng: -43.6370626,
  lat: -23.0053992
  },
  {
  lng: -43.6367837,
  lat: -23.0053795
  },
  {
  lng: -43.6366228,
  lat: -23.0055572
  },
  {
  lng: -43.6361614,
  lat: -23.0056066
  },
  {
  lng: -43.6356464,
  lat: -23.005735
  },
  {
  lng: -43.6351851,
  lat: -23.0059226
  },
  {
  lng: -43.6347881,
  lat: -23.0063176
  },
  {
  lng: -43.6344984,
  lat: -23.0064065
  },
  {
  lng: -43.6341122,
  lat: -23.0062288
  },
  {
  lng: -43.6337689,
  lat: -23.0060411
  },
  {
  lng: -43.633329,
  lat: -23.0061498
  },
  {
  lng: -43.6328998,
  lat: -23.0057646
  },
  {
  lng: -43.632637,
  lat: -23.0056807
  },
  {
  lng: -43.6321327,
  lat: -23.0055918
  },
  {
  lng: -43.6321038,
  lat: -23.0057636
  },
  {
  lng: -43.6315971,
  lat: -23.0059066
  },
  {
  lng: -43.6312179,
  lat: -23.0059639
  },
  {
  lng: -43.6308585,
  lat: -23.0060217
  },
  {
  lng: -43.6300994,
  lat: -23.006156
  },
  {
  lng: -43.629152,
  lat: -23.0064256
  },
  {
  lng: -43.6288076,
  lat: -23.0066244
  },
  {
  lng: -43.6278656,
  lat: -23.0069351
  },
  {
  lng: -43.6245864,
  lat: -23.0084323
  },
  {
  lng: -43.6242275,
  lat: -23.0084649
  },
  {
  lng: -43.6238144,
  lat: -23.0083364
  },
  {
  lng: -43.6231236,
  lat: -23.008018
  },
  {
  lng: -43.6229595,
  lat: -23.0081877
  },
  {
  lng: -43.6229092,
  lat: -23.0082342
  },
  {
  lng: -43.6228042,
  lat: -23.0083325
  },
  {
  lng: -43.622454,
  lat: -23.0086771
  },
  {
  lng: -43.6218382,
  lat: -23.0102184
  },
  {
  lng: -43.6210872,
  lat: -23.0115121
  },
  {
  lng: -43.6204755,
  lat: -23.0125786
  },
  {
  lng: -43.6191238,
  lat: -23.0138031
  },
  {
  lng: -43.6177076,
  lat: -23.0147313
  },
  {
  lng: -43.6165918,
  lat: -23.0155805
  },
  {
  lng: -43.6148408,
  lat: -23.0169534
  },
  {
  lng: -43.6127863,
  lat: -23.0183437
  },
  {
  lng: -43.6108175,
  lat: -23.0194556
  },
  {
  lng: -43.6083274,
  lat: -23.0205665
  },
  {
  lng: -43.6066762,
  lat: -23.021002
  },
  {
  lng: -43.6060673,
  lat: -23.0211239
  },
  {
  lng: -43.6059155,
  lat: -23.0212276
  },
  {
  lng: -43.6058324,
  lat: -23.0213461
  },
  {
  lng: -43.6057685,
  lat: -23.0217773
  },
  {
  lng: -43.60568,
  lat: -23.0219326
  },
  {
  lng: -43.6055451,
  lat: -23.022038
  },
  {
  lng: -43.6053598,
  lat: -23.0221049
  },
  {
  lng: -43.6045694,
  lat: -23.0221302
  },
  {
  lng: -43.6036797,
  lat: -23.0220526
  },
  {
  lng: -43.6030099,
  lat: -23.0220589
  },
  {
  lng: -43.6021442,
  lat: -23.0222026
  },
  {
  lng: -43.6010141,
  lat: -23.0224759
  },
  {
  lng: -43.6004571,
  lat: -23.0226525
  },
  {
  lng: -43.5993291,
  lat: -23.0228742
  },
  {
  lng: -43.5991103,
  lat: -23.0228528
  },
  {
  lng: -43.5990431,
  lat: -23.0231778
  },
  {
  lng: -43.5989729,
  lat: -23.0235185
  },
  {
  lng: -43.6145633,
  lat: -23.0310231
  },
  {
  lng: -43.6147731,
  lat: -23.0311245
  },
  {
  lng: -43.6152471,
  lat: -23.0315716
  },
  {
  lng: -43.6163669,
  lat: -23.0318952
  },
  {
  lng: -43.6178864,
  lat: -23.0323318
  },
  {
  lng: -43.6195707,
  lat: -23.0328942
  },
  {
  lng: -43.6216926,
  lat: -23.0331795
  },
  {
  lng: -43.6243192,
  lat: -23.0334613
  },
  {
  lng: -43.6258004,
  lat: -23.0335681
  },
  {
  lng: -43.6292206,
  lat: -23.0335228
  },
  {
  lng: -43.6320244,
  lat: -23.0340954
  },
  {
  lng: -43.6338544,
  lat: -23.0343611
  },
  {
  lng: -43.6361289,
  lat: -23.0343216
  },
  {
  lng: -43.637795,
  lat: -23.0350671
  },
  {
  lng: -43.6397975,
  lat: -23.0356387
  },
  {
  lng: -43.6421048,
  lat: -23.0357365
  },
  {
  lng: -43.643437,
  lat: -23.0360422
  },
  {
  lng: -43.6450887,
  lat: -23.0367108
  },
  {
  lng: -43.6459883,
  lat: -23.0368754
  },
  {
  lng: -43.6476956,
  lat: -23.0370841
  },
  {
  lng: -43.6490609,
  lat: -23.037582
  },
  {
  lng: -43.649511,
  lat: -23.0374936
  },
  {
  lng: -43.6533439,
  lat: -23.0385499
  },
  {
  lng: -43.6539997,
  lat: -23.0388315
  },
  {
  lng: -43.6544614,
  lat: -23.0386849
  },
  {
  lng: -43.656358,
  lat: -23.0391351
  },
  {
  lng: -43.6588144,
  lat: -23.0402143
  },
  {
  lng: -43.659087,
  lat: -23.0402116
  },
  {
  lng: -43.6593472,
  lat: -23.0404052
  },
  {
  lng: -43.6604618,
  lat: -23.0408605
  },
  {
  lng: -43.6624722,
  lat: -23.0412644
  },
  {
  lng: -43.6635127,
  lat: -23.0410172
  },
  {
  lng: -43.6638309,
  lat: -23.0410534
  },
  {
  lng: -43.6640848,
  lat: -23.0412113
  },
  {
  lng: -43.6642521,
  lat: -23.0413873
  },
  {
  lng: -43.6641989,
  lat: -23.0528651
  },
  {
  lng: -43.6639238,
  lat: -23.052858
  },
  {
  lng: -43.6635997,
  lat: -23.0528495
  },
  {
  lng: -43.6628282,
  lat: -23.0527084
  },
  {
  lng: -43.6618067,
  lat: -23.0528309
  },
  {
  lng: -43.6611222,
  lat: -23.0528089
  },
  {
  lng: -43.6602885,
  lat: -23.0527091
  },
  {
  lng: -43.6596036,
  lat: -23.0525041
  },
  {
  lng: -43.657465,
  lat: -23.052451
  },
  {
  lng: -43.6561119,
  lat: -23.052268
  },
  {
  lng: -43.6551378,
  lat: -23.0522195
  },
  {
  lng: -43.6544596,
  lat: -23.0520868
  },
  {
  lng: -43.6528494,
  lat: -23.0520411
  },
  {
  lng: -43.6521034,
  lat: -23.0519691
  },
  {
  lng: -43.6504829,
  lat: -23.0519354
  },
  {
  lng: -43.6498991,
  lat: -23.0518387
  },
  {
  lng: -43.6488328,
  lat: -23.0518059
  },
  {
  lng: -43.6481598,
  lat: -23.0516972
  },
  {
  lng: -43.6449039,
  lat: -23.0515531
  },
  {
  lng: -43.6442332,
  lat: -23.051462
  },
  {
  lng: -43.6432093,
  lat: -23.0514402
  },
  {
  lng: -43.642056,
  lat: -23.0512574
  },
  {
  lng: -43.6402349,
  lat: -23.0512351
  },
  {
  lng: -43.6389098,
  lat: -23.0509913
  },
  {
  lng: -43.6379649,
  lat: -23.0508915
  },
  {
  lng: -43.6365557,
  lat: -23.0508694
  },
  {
  lng: -43.6342971,
  lat: -23.0509397
  },
  {
  lng: -43.6333307,
  lat: -23.050757
  },
  {
  lng: -43.6323411,
  lat: -23.0506375
  },
  {
  lng: -43.6302311,
  lat: -23.0506669
  },
  {
  lng: -43.6291978,
  lat: -23.0505289
  },
  {
  lng: -43.6279575,
  lat: -23.0505271
  },
  {
  lng: -43.6268888,
  lat: -23.0506588
  },
  {
  lng: -43.6256409,
  lat: -23.0505131
  },
  {
  lng: -43.6238419,
  lat: -23.0504207
  },
  {
  lng: -43.6230859,
  lat: -23.0504779
  },
  {
  lng: -43.6226101,
  lat: -23.0506072
  },
  {
  lng: -43.6221234,
  lat: -23.0506588
  },
  {
  lng: -43.6212659,
  lat: -23.0505316
  },
  {
  lng: -43.6190624,
  lat: -23.0505908
  },
  {
  lng: -43.6176591,
  lat: -23.0504712
  },
  {
  lng: -43.6168567,
  lat: -23.050464
  },
  {
  lng: -43.615612,
  lat: -23.0506471
  },
  {
  lng: -43.613045,
  lat: -23.05066
  },
  {
  lng: -43.6122273,
  lat: -23.050792
  },
  {
  lng: -43.6100407,
  lat: -23.0507884
  },
  {
  lng: -43.6039852,
  lat: -23.0512007
  },
  {
  lng: -43.6030513,
  lat: -23.0513285
  },
  {
  lng: -43.601457,
  lat: -23.051436
  },
  {
  lng: -43.5977941,
  lat: -23.0517802
  },
  {
  lng: -43.5938037,
  lat: -23.0524868
  },
  {
  lng: -43.5927277,
  lat: -23.0527686
  },
  {
  lng: -43.5916913,
  lat: -23.0529026
  },
  {
  lng: -43.5902597,
  lat: -23.0532296
  },
  {
  lng: -43.5889917,
  lat: -23.0533907
  },
  {
  lng: -43.5860195,
  lat: -23.0541448
  },
  {
  lng: -43.5835953,
  lat: -23.0545679
  },
  {
  lng: -43.5829532,
  lat: -23.0547257
  },
  {
  lng: -43.5825676,
  lat: -23.0548821
  },
  {
  lng: -43.5805855,
  lat: -23.0553496
  },
  {
  lng: -43.5786167,
  lat: -23.0559943
  },
  {
  lng: -43.576101,
  lat: -23.0569413
  },
  {
  lng: -43.5751357,
  lat: -23.0574576
  },
  {
  lng: -43.5743774,
  lat: -23.0579937
  },
  {
  lng: -43.5741204,
  lat: -23.0581318
  },
  {
  lng: -43.5733225,
  lat: -23.0584343
  },
  {
  lng: -43.5728742,
  lat: -23.0586966
  },
  {
  lng: -43.571882,
  lat: -23.0595776
  },
  {
  lng: -43.5704818,
  lat: -23.0605626
  },
  {
  lng: -43.5700756,
  lat: -23.0608994
  },
  {
  lng: -43.5693828,
  lat: -23.0616139
  },
  {
  lng: -43.5688171,
  lat: -23.062115
  },
  {
  lng: -43.5684413,
  lat: -23.0625754
  },
  {
  lng: -43.5680636,
  lat: -23.0625663
  },
  {
  lng: -43.5676612,
  lat: -23.0627969
  },
  {
  lng: -43.567462,
  lat: -23.0628347
  },
  {
  lng: -43.5670778,
  lat: -23.0627412
  },
  {
  lng: -43.5667967,
  lat: -23.0625743
  },
  {
  lng: -43.5664656,
  lat: -23.0628095
  },
  {
  lng: -43.5662329,
  lat: -23.0629615
  },
  {
  lng: -43.5662505,
  lat: -23.0629756
  },
  {
  lng: -43.5662607,
  lat: -23.0629983
  },
  {
  lng: -43.5662608,
  lat: -23.0630405
  },
  {
  lng: -43.566259,
  lat: -23.0630957
  },
  {
  lng: -43.5662593,
  lat: -23.0631501
  },
  {
  lng: -43.5662611,
  lat: -23.0632067
  },
  {
  lng: -43.5663011,
  lat: -23.0632269
  },
  {
  lng: -43.5663163,
  lat: -23.0632789
  },
  {
  lng: -43.5663566,
  lat: -23.0633197
  },
  {
  lng: -43.5663231,
  lat: -23.0633387
  },
  {
  lng: -43.5662734,
  lat: -23.0633707
  },
  {
  lng: -43.5662515,
  lat: -23.0634227
  },
  {
  lng: -43.5662569,
  lat: -23.0634393
  },
  {
  lng: -43.5662624,
  lat: -23.063456
  },
  {
  lng: -43.5663055,
  lat: -23.0634826
  },
  {
  lng: -43.5663865,
  lat: -23.0635062
  },
  {
  lng: -43.5666763,
  lat: -23.0636186
  },
  {
  lng: -43.5667184,
  lat: -23.0635972
  },
  {
  lng: -43.5667802,
  lat: -23.0636041
  },
  {
  lng: -43.5670393,
  lat: -23.0637193
  },
  {
  lng: -43.5672454,
  lat: -23.0638145
  },
  {
  lng: -43.5674882,
  lat: -23.0639592
  },
  {
  lng: -43.5677145,
  lat: -23.0641382
  },
  {
  lng: -43.5678824,
  lat: -23.0643362
  },
  {
  lng: -43.5679767,
  lat: -23.0644599
  },
  {
  lng: -43.5680389,
  lat: -23.0646029
  },
  {
  lng: -43.5680766,
  lat: -23.0647722
  },
  {
  lng: -43.5680819,
  lat: -23.0648296
  },
  {
  lng: -43.5680852,
  lat: -23.0648869
  },
  {
  lng: -43.5680887,
  lat: -23.0649465
  },
  {
  lng: -43.5680903,
  lat: -23.0650061
  },
  {
  lng: -43.5680864,
  lat: -23.065064
  },
  {
  lng: -43.5680748,
  lat: -23.0651214
  },
  {
  lng: -43.5680746,
  lat: -23.0651758
  },
  {
  lng: -43.5681343,
  lat: -23.0651685
  },
  {
  lng: -43.5681939,
  lat: -23.0651691
  },
  {
  lng: -43.5682493,
  lat: -23.0652006
  },
  {
  lng: -43.568258,
  lat: -23.065255
  },
  {
  lng: -43.5682954,
  lat: -23.0652896
  },
  {
  lng: -43.5683515,
  lat: -23.0653146
  },
  {
  lng: -43.5684063,
  lat: -23.0653435
  },
  {
  lng: -43.5684618,
  lat: -23.0653621
  },
  {
  lng: -43.5685081,
  lat: -23.0653992
  },
  {
  lng: -43.5685521,
  lat: -23.0654406
  },
  {
  lng: -43.5685985,
  lat: -23.065477
  },
  {
  lng: -43.5686442,
  lat: -23.0655116
  },
  {
  lng: -43.5686616,
  lat: -23.0655655
  },
  {
  lng: -43.5686477,
  lat: -23.0656186
  },
  {
  lng: -43.5686021,
  lat: -23.0656578
  },
  {
  lng: -43.5685507,
  lat: -23.0656843
  },
  {
  lng: -43.5685435,
  lat: -23.0657017
  },
  {
  lng: -43.5685426,
  lat: -23.0657559
  },
  {
  lng: -43.5685449,
  lat: -23.0658108
  },
  {
  lng: -43.5685634,
  lat: -23.0658352
  },
  {
  lng: -43.5686075,
  lat: -23.0658735
  },
  {
  lng: -43.5686596,
  lat: -23.0659055
  },
  {
  lng: -43.5687064,
  lat: -23.0659398
  },
  {
  lng: -43.5687442,
  lat: -23.0659821
  },
  {
  lng: -43.5687809,
  lat: -23.0660256
  },
  {
  lng: -43.5688131,
  lat: -23.0660747
  },
  {
  lng: -43.5688385,
  lat: -23.0661265
  },
  {
  lng: -43.5688351,
  lat: -23.0661574
  },
  {
  lng: -43.5688564,
  lat: -23.0661852
  },
  {
  lng: -43.5688625,
  lat: -23.0662409
  },
  {
  lng: -43.5688382,
  lat: -23.0662908
  },
  {
  lng: -43.5688394,
  lat: -23.0663453
  },
  {
  lng: -43.568827,
  lat: -23.0663985
  },
  {
  lng: -43.568787,
  lat: -23.0664397
  },
  {
  lng: -43.5687334,
  lat: -23.0664684
  },
  {
  lng: -43.5686951,
  lat: -23.0664864
  },
  {
  lng: -43.5682422,
  lat: -23.0672278
  },
  {
  lng: -43.5674938,
  lat: -23.0691436
  },
  {
  lng: -43.567576,
  lat: -23.0692884
  },
  {
  lng: -43.5675702,
  lat: -23.0693284
  },
  {
  lng: -43.5675419,
  lat: -23.0693811
  },
  {
  lng: -43.5675099,
  lat: -23.0694279
  },
  {
  lng: -43.5675372,
  lat: -23.0694277
  },
  {
  lng: -43.5675437,
  lat: -23.0694456
  },
  {
  lng: -43.5675014,
  lat: -23.0694638
  },
  {
  lng: -43.567466,
  lat: -23.0695082
  },
  {
  lng: -43.5674325,
  lat: -23.069555
  },
  {
  lng: -43.5674051,
  lat: -23.0696032
  },
  {
  lng: -43.5674206,
  lat: -23.0696577
  },
  {
  lng: -43.5674426,
  lat: -23.069711
  },
  {
  lng: -43.5674613,
  lat: -23.0697626
  },
  {
  lng: -43.5674918,
  lat: -23.0697769
  },
  {
  lng: -43.5674833,
  lat: -23.069833
  },
  {
  lng: -43.567492,
  lat: -23.0698636
  },
  {
  lng: -43.5674812,
  lat: -23.0699179
  },
  {
  lng: -43.56743,
  lat: -23.069946
  },
  {
  lng: -43.5674356,
  lat: -23.0699642
  },
  {
  lng: -43.5674638,
  lat: -23.0700123
  },
  {
  lng: -43.5674605,
  lat: -23.0700674
  },
  {
  lng: -43.56744,
  lat: -23.0701191
  },
  {
  lng: -43.5674325,
  lat: -23.0701747
  },
  {
  lng: -43.5674314,
  lat: -23.0702304
  },
  {
  lng: -43.5674377,
  lat: -23.0702846
  },
  {
  lng: -43.5674522,
  lat: -23.0702962
  },
  {
  lng: -43.5674991,
  lat: -23.0703051
  },
  {
  lng: -43.5674478,
  lat: -23.0703347
  },
  {
  lng: -43.5674524,
  lat: -23.0703566
  },
  {
  lng: -43.567469,
  lat: -23.0704104
  },
  {
  lng: -43.567493,
  lat: -23.0704601
  },
  {
  lng: -43.5675317,
  lat: -23.0705019
  },
  {
  lng: -43.5675713,
  lat: -23.0705449
  },
  {
  lng: -43.5675995,
  lat: -23.0705948
  },
  {
  lng: -43.5676134,
  lat: -23.0706494
  },
  {
  lng: -43.5676023,
  lat: -23.0707027
  },
  {
  lng: -43.5675855,
  lat: -23.0707554
  },
  {
  lng: -43.5675843,
  lat: -23.0708097
  },
  {
  lng: -43.5675734,
  lat: -23.070866
  },
  {
  lng: -43.5675377,
  lat: -23.0709115
  },
  {
  lng: -43.5675517,
  lat: -23.0709642
  },
  {
  lng: -43.5675549,
  lat: -23.0710186
  },
  {
  lng: -43.5675547,
  lat: -23.071074
  },
  {
  lng: -43.5675636,
  lat: -23.0711305
  },
  {
  lng: -43.5675765,
  lat: -23.0711866
  },
  {
  lng: -43.5676008,
  lat: -23.071239
  },
  {
  lng: -43.5676039,
  lat: -23.0712955
  },
  {
  lng: -43.5676033,
  lat: -23.0713503
  },
  {
  lng: -43.5676032,
  lat: -23.0714106
  },
  {
  lng: -43.5676177,
  lat: -23.0714668
  },
  {
  lng: -43.567642,
  lat: -23.0715182
  },
  {
  lng: -43.5676604,
  lat: -23.0715707
  },
  {
  lng: -43.5676651,
  lat: -23.0716264
  },
  {
  lng: -43.5676632,
  lat: -23.0716836
  },
  {
  lng: -43.5677114,
  lat: -23.0717168
  },
  {
  lng: -43.5677629,
  lat: -23.0717438
  },
  {
  lng: -43.5678062,
  lat: -23.0717816
  },
  {
  lng: -43.567847,
  lat: -23.071771
  },
  {
  lng: -43.5679038,
  lat: -23.0717504
  },
  {
  lng: -43.5679646,
  lat: -23.0717494
  },
  {
  lng: -43.5680264,
  lat: -23.0717493
  },
  {
  lng: -43.5680858,
  lat: -23.0717564
  },
  {
  lng: -43.5681426,
  lat: -23.0717751
  },
  {
  lng: -43.5681996,
  lat: -23.0717961
  },
  {
  lng: -43.5682571,
  lat: -23.071807
  },
  {
  lng: -43.5683151,
  lat: -23.0718216
  },
  {
  lng: -43.5683717,
  lat: -23.0718382
  },
  {
  lng: -43.5684301,
  lat: -23.0718463
  },
  {
  lng: -43.5684874,
  lat: -23.0718608
  },
  {
  lng: -43.5685475,
  lat: -23.0718696
  },
  {
  lng: -43.5686096,
  lat: -23.0718762
  },
  {
  lng: -43.5686668,
  lat: -23.0718896
  },
  {
  lng: -43.568727,
  lat: -23.0719036
  },
  {
  lng: -43.5687839,
  lat: -23.0719248
  },
  {
  lng: -43.5687893,
  lat: -23.0719419
  },
  {
  lng: -43.5688387,
  lat: -23.0719646
  },
  {
  lng: -43.5688948,
  lat: -23.0719901
  },
  {
  lng: -43.5689511,
  lat: -23.072009
  },
  {
  lng: -43.5690082,
  lat: -23.0720288
  },
  {
  lng: -43.5690345,
  lat: -23.072013
  },
  {
  lng: -43.5690934,
  lat: -23.0720061
  },
  {
  lng: -43.5690982,
  lat: -23.0720436
  },
  {
  lng: -43.5691559,
  lat: -23.0720579
  },
  {
  lng: -43.5692128,
  lat: -23.0720797
  },
  {
  lng: -43.5692747,
  lat: -23.0720875
  },
  {
  lng: -43.5693343,
  lat: -23.0720915
  },
  {
  lng: -43.569394,
  lat: -23.0721096
  },
  {
  lng: -43.5694525,
  lat: -23.0721216
  },
  {
  lng: -43.5695112,
  lat: -23.0721339
  },
  {
  lng: -43.5695603,
  lat: -23.0721669
  },
  {
  lng: -43.5696086,
  lat: -23.0722012
  },
  {
  lng: -43.5696664,
  lat: -23.0722144
  },
  {
  lng: -43.569699,
  lat: -23.072192
  },
  {
  lng: -43.5697444,
  lat: -23.0721561
  },
  {
  lng: -43.5698009,
  lat: -23.0721411
  },
  {
  lng: -43.5698616,
  lat: -23.0721295
  },
  {
  lng: -43.5699246,
  lat: -23.0721287
  },
  {
  lng: -43.5699878,
  lat: -23.0721254
  },
  {
  lng: -43.5700497,
  lat: -23.0721225
  },
  {
  lng: -43.5701125,
  lat: -23.0721199
  },
  {
  lng: -43.5701751,
  lat: -23.0721192
  },
  {
  lng: -43.5702377,
  lat: -23.0721185
  },
  {
  lng: -43.5702967,
  lat: -23.0721178
  },
  {
  lng: -43.5703597,
  lat: -23.0721172
  },
  {
  lng: -43.570422,
  lat: -23.0721166
  },
  {
  lng: -43.5704823,
  lat: -23.0721177
  },
  {
  lng: -43.5705428,
  lat: -23.0721182
  },
  {
  lng: -43.5706028,
  lat: -23.0721224
  },
  {
  lng: -43.5706645,
  lat: -23.0721288
  },
  {
  lng: -43.5707261,
  lat: -23.0721373
  },
  {
  lng: -43.5707839,
  lat: -23.0721562
  },
  {
  lng: -43.5708341,
  lat: -23.0721846
  },
  {
  lng: -43.5708891,
  lat: -23.0722121
  },
  {
  lng: -43.5708858,
  lat: -23.0722327
  },
  {
  lng: -43.5709218,
  lat: -23.0722357
  },
  {
  lng: -43.5711195,
  lat: -23.0722617
  },
  {
  lng: -43.5711784,
  lat: -23.072281
  },
  {
  lng: -43.5711785,
  lat: -23.0722959
  },
  {
  lng: -43.5711856,
  lat: -23.0722997
  },
  {
  lng: -43.5712095,
  lat: -23.0723501
  },
  {
  lng: -43.5712583,
  lat: -23.0723733
  },
  {
  lng: -43.5712882,
  lat: -23.0723609
  },
  {
  lng: -43.5712745,
  lat: -23.0723305
  },
  {
  lng: -43.5713034,
  lat: -23.072295
  },
  {
  lng: -43.5713459,
  lat: -23.0722763
  },
  {
  lng: -43.5713487,
  lat: -23.0722992
  },
  {
  lng: -43.5713665,
  lat: -23.0723265
  },
  {
  lng: -43.5713201,
  lat: -23.0723529
  },
  {
  lng: -43.57134,
  lat: -23.0723865
  },
  {
  lng: -43.5713517,
  lat: -23.0723901
  },
  {
  lng: -43.5714055,
  lat: -23.0723934
  },
  {
  lng: -43.5714299,
  lat: -23.0724189
  },
  {
  lng: -43.5714382,
  lat: -23.072399
  },
  {
  lng: -43.5714552,
  lat: -23.0723929
  },
  {
  lng: -43.5714885,
  lat: -23.072438
  },
  {
  lng: -43.57151,
  lat: -23.0724499
  },
  {
  lng: -43.5715412,
  lat: -23.0724902
  },
  {
  lng: -43.5716017,
  lat: -23.0724866
  },
  {
  lng: -43.571662,
  lat: -23.0724915
  },
  {
  lng: -43.571725,
  lat: -23.0724898
  },
  {
  lng: -43.5717703,
  lat: -23.072453
  },
  {
  lng: -43.5717851,
  lat: -23.0724529
  },
  {
  lng: -43.5718227,
  lat: -23.0724945
  },
  {
  lng: -43.5718715,
  lat: -23.0725268
  },
  {
  lng: -43.5719118,
  lat: -23.0725705
  },
  {
  lng: -43.571952,
  lat: -23.0726134
  },
  {
  lng: -43.5719992,
  lat: -23.0726457
  },
  {
  lng: -43.5720515,
  lat: -23.0726774
  },
  {
  lng: -43.5720995,
  lat: -23.0727108
  },
  {
  lng: -43.5721333,
  lat: -23.0727554
  },
  {
  lng: -43.5721754,
  lat: -23.0727983
  },
  {
  lng: -43.5722014,
  lat: -23.072851
  },
  {
  lng: -43.5722348,
  lat: -23.0728977
  },
  {
  lng: -43.5722624,
  lat: -23.0729454
  },
  {
  lng: -43.5722565,
  lat: -23.0729889
  },
  {
  lng: -43.5722864,
  lat: -23.0729875
  },
  {
  lng: -43.5723049,
  lat: -23.0729936
  },
  {
  lng: -43.5723457,
  lat: -23.073033
  },
  {
  lng: -43.5723584,
  lat: -23.0730903
  },
  {
  lng: -43.5724151,
  lat: -23.0731116
  },
  {
  lng: -43.572467,
  lat: -23.0731427
  },
  {
  lng: -43.5724812,
  lat: -23.0731952
  },
  {
  lng: -43.5724423,
  lat: -23.0732377
  },
  {
  lng: -43.572384,
  lat: -23.0732447
  },
  {
  lng: -43.5723555,
  lat: -23.0732734
  },
  {
  lng: -43.5722963,
  lat: -23.0732667
  },
  {
  lng: -43.5722402,
  lat: -23.0732425
  },
  {
  lng: -43.5721792,
  lat: -23.073245
  },
  {
  lng: -43.5721266,
  lat: -23.0732205
  },
  {
  lng: -43.5720676,
  lat: -23.0732132
  },
  {
  lng: -43.572011,
  lat: -23.073194
  },
  {
  lng: -43.5719616,
  lat: -23.0731635
  },
  {
  lng: -43.5719246,
  lat: -23.0731198
  },
  {
  lng: -43.5718997,
  lat: -23.0730697
  },
  {
  lng: -43.5718832,
  lat: -23.0730181
  },
  {
  lng: -43.57187,
  lat: -23.0729858
  },
  {
  lng: -43.571851,
  lat: -23.0729701
  },
  {
  lng: -43.5718385,
  lat: -23.0729778
  },
  {
  lng: -43.5718431,
  lat: -23.0729951
  },
  {
  lng: -43.5718594,
  lat: -23.0730181
  },
  {
  lng: -43.5718713,
  lat: -23.0730418
  },
  {
  lng: -43.5718838,
  lat: -23.0730703
  },
  {
  lng: -43.5719061,
  lat: -23.0731207
  },
  {
  lng: -43.5719284,
  lat: -23.0731629
  },
  {
  lng: -43.5719278,
  lat: -23.073216
  },
  {
  lng: -43.57191,
  lat: -23.0732498
  },
  {
  lng: -43.5718497,
  lat: -23.0732601
  },
  {
  lng: -43.5717933,
  lat: -23.0732186
  },
  {
  lng: -43.5717797,
  lat: -23.073194
  },
  {
  lng: -43.5717611,
  lat: -23.0731715
  },
  {
  lng: -43.5717559,
  lat: -23.0731943
  },
  {
  lng: -43.5717499,
  lat: -23.0732185
  },
  {
  lng: -43.5717379,
  lat: -23.0732466
  },
  {
  lng: -43.5717017,
  lat: -23.0732914
  },
  {
  lng: -43.5716424,
  lat: -23.0732954
  },
  {
  lng: -43.5716025,
  lat: -23.0733369
  },
  {
  lng: -43.5715511,
  lat: -23.0733672
  },
  {
  lng: -43.5715201,
  lat: -23.0734166
  },
  {
  lng: -43.5714502,
  lat: -23.0735183
  },
  {
  lng: -43.5713587,
  lat: -23.0735644
  },
  {
  lng: -43.5712482,
  lat: -23.073539
  },
  {
  lng: -43.5711301,
  lat: -23.0734922
  },
  {
  lng: -43.5710216,
  lat: -23.073494
  },
  {
  lng: -43.5709488,
  lat: -23.0735939
  },
  {
  lng: -43.5709228,
  lat: -23.0736201
  },
  {
  lng: -43.5708006,
  lat: -23.0736707
  },
  {
  lng: -43.5706769,
  lat: -23.0737227
  },
  {
  lng: -43.5705636,
  lat: -23.07378
  },
  {
  lng: -43.5704163,
  lat: -23.0738203
  },
  {
  lng: -43.5702785,
  lat: -23.0738239
  },
  {
  lng: -43.5701261,
  lat: -23.0738275
  },
  {
  lng: -43.569991,
  lat: -23.0738162
  },
  {
  lng: -43.5698827,
  lat: -23.0737529
  },
  {
  lng: -43.5697987,
  lat: -23.0736405
  },
  {
  lng: -43.5697453,
  lat: -23.0735303
  },
  {
  lng: -43.5697108,
  lat: -23.0734862
  },
  {
  lng: -43.5697134,
  lat: -23.0736269
  },
  {
  lng: -43.5697143,
  lat: -23.0737528
  },
  {
  lng: -43.5696781,
  lat: -23.0738658
  },
  {
  lng: -43.5695707,
  lat: -23.0739312
  },
  {
  lng: -43.5694608,
  lat: -23.074006
  },
  {
  lng: -43.5693869,
  lat: -23.0741304
  },
  {
  lng: -43.5693464,
  lat: -23.0742461
  },
  {
  lng: -43.5692135,
  lat: -23.0742712
  },
  {
  lng: -43.5690755,
  lat: -23.0742599
  },
  {
  lng: -43.568949,
  lat: -23.0742348
  },
  {
  lng: -43.5688221,
  lat: -23.0742029
  },
  {
  lng: -43.5686782,
  lat: -23.0741808
  },
  {
  lng: -43.5685455,
  lat: -23.0741437
  },
  {
  lng: -43.5684084,
  lat: -23.0741039
  },
  {
  lng: -43.5682757,
  lat: -23.0740572
  },
  {
  lng: -43.5681513,
  lat: -23.0739901
  },
  {
  lng: -43.5680343,
  lat: -23.073927
  },
  {
  lng: -43.5679185,
  lat: -23.0738557
  },
  {
  lng: -43.5678217,
  lat: -23.0737651
  },
  {
  lng: -43.5677061,
  lat: -23.0736992
  },
  {
  lng: -43.5675937,
  lat: -23.0736428
  },
  {
  lng: -43.5674868,
  lat: -23.07357
  },
  {
  lng: -43.5674318,
  lat: -23.0734572
  },
  {
  lng: -43.5674183,
  lat: -23.0734203
  },
  {
  lng: -43.5673892,
  lat: -23.0733739
  },
  {
  lng: -43.5673455,
  lat: -23.0733973
  },
  {
  lng: -43.567326,
  lat: -23.0734177
  },
  {
  lng: -43.5671968,
  lat: -23.0735069
  },
  {
  lng: -43.5670681,
  lat: -23.0735265
  },
  {
  lng: -43.5669229,
  lat: -23.0735302
  },
  {
  lng: -43.5667885,
  lat: -23.0735486
  },
  {
  lng: -43.5667553,
  lat: -23.0735774
  },
  {
  lng: -43.5666749,
  lat: -23.0736708
  },
  {
  lng: -43.5666064,
  lat: -23.0737748
  },
  {
  lng: -43.5665497,
  lat: -23.07388
  },
  {
  lng: -43.5665373,
  lat: -23.0740061
  },
  {
  lng: -43.5665278,
  lat: -23.0741254
  },
  {
  lng: -43.5664845,
  lat: -23.0742426
  },
  {
  lng: -43.5664252,
  lat: -23.0743667
  },
  {
  lng: -43.5663912,
  lat: -23.0744935
  },
  {
  lng: -43.5663608,
  lat: -23.0746155
  },
  {
  lng: -43.5663688,
  lat: -23.0747332
  },
  {
  lng: -43.5664077,
  lat: -23.074853
  },
  {
  lng: -43.5664113,
  lat: -23.0749749
  },
  {
  lng: -43.5664237,
  lat: -23.0750952
  },
  {
  lng: -43.5663524,
  lat: -23.0751953
  },
  {
  lng: -43.5662364,
  lat: -23.0752647
  },
  {
  lng: -43.5661125,
  lat: -23.0753046
  },
  {
  lng: -43.5659886,
  lat: -23.0752632
  },
  {
  lng: -43.5659529,
  lat: -23.0752394
  },
  {
  lng: -43.5659025,
  lat: -23.0751278
  },
  {
  lng: -43.5658713,
  lat: -23.0751107
  },
  {
  lng: -43.56577,
  lat: -23.0750988
  },
  {
  lng: -43.5656976,
  lat: -23.0750688
  },
  {
  lng: -43.565637,
  lat: -23.0751279
  },
  {
  lng: -43.5656216,
  lat: -23.0752447
  },
  {
  lng: -43.5655827,
  lat: -23.0753618
  },
  {
  lng: -43.565454,
  lat: -23.0753718
  },
  {
  lng: -43.5653326,
  lat: -23.0753116
  },
  {
  lng: -43.5652488,
  lat: -23.0752193
  },
  {
  lng: -43.5651973,
  lat: -23.0752053
  },
  {
  lng: -43.5651127,
  lat: -23.0752337
  },
  {
  lng: -43.5650249,
  lat: -23.0751606
  },
  {
  lng: -43.5649942,
  lat: -23.0750879
  },
  {
  lng: -43.5649873,
  lat: -23.0749554
  },
  {
  lng: -43.5649546,
  lat: -23.0748408
  },
  {
  lng: -43.5649337,
  lat: -23.0748249
  },
  {
  lng: -43.5648296,
  lat: -23.074905
  },
  {
  lng: -43.5647449,
  lat: -23.0750052
  },
  {
  lng: -43.5647015,
  lat: -23.0751169
  },
  {
  lng: -43.5646996,
  lat: -23.0752537
  },
  {
  lng: -43.5647082,
  lat: -23.07532
  },
  {
  lng: -43.5647094,
  lat: -23.0753863
  },
  {
  lng: -43.5646924,
  lat: -23.0754231
  },
  {
  lng: -43.564645,
  lat: -23.0753927
  },
  {
  lng: -43.5646212,
  lat: -23.0754567
  },
  {
  lng: -43.564564,
  lat: -23.0755334
  },
  {
  lng: -43.5644338,
  lat: -23.0755504
  },
  {
  lng: -43.5643046,
  lat: -23.0755444
  },
  {
  lng: -43.5641679,
  lat: -23.0755221
  },
  {
  lng: -43.5640312,
  lat: -23.0755026
  },
  {
  lng: -43.563906,
  lat: -23.0754735
  },
  {
  lng: -43.5637694,
  lat: -23.0754593
  },
  {
  lng: -43.5636403,
  lat: -23.0754519
  },
  {
  lng: -43.5635026,
  lat: -23.0754608
  },
  {
  lng: -43.5633675,
  lat: -23.0754535
  },
  {
  lng: -43.5632482,
  lat: -23.075496
  },
  {
  lng: -43.5632365,
  lat: -23.0755788
  },
  {
  lng: -43.5631473,
  lat: -23.0756695
  },
  {
  lng: -43.563022,
  lat: -23.0757163
  },
  {
  lng: -43.562919,
  lat: -23.0756907
  },
  {
  lng: -43.5628983,
  lat: -23.0756856
  },
  {
  lng: -43.5627813,
  lat: -23.0756224
  },
  {
  lng: -43.5626571,
  lat: -23.0755689
  },
  {
  lng: -43.5625363,
  lat: -23.0755329
  },
  {
  lng: -43.5624121,
  lat: -23.0754834
  },
  {
  lng: -43.562288,
  lat: -23.0754258
  },
  {
  lng: -43.5621666,
  lat: -23.0753627
  },
  {
  lng: -43.5620567,
  lat: -23.0752887
  },
  {
  lng: -43.5619468,
  lat: -23.0752104
  },
  {
  lng: -43.5618746,
  lat: -23.0751088
  },
  {
  lng: -43.561801,
  lat: -23.0750096
  },
  {
  lng: -43.5616955,
  lat: -23.0749355
  },
  {
  lng: -43.5615988,
  lat: -23.0748558
  },
  {
  lng: -43.5614948,
  lat: -23.0747816
  },
  {
  lng: -43.5614872,
  lat: -23.0747726
  },
  {
  lng: -43.5614377,
  lat: -23.0747367
  },
  {
  lng: -43.5613998,
  lat: -23.0746867
  },
  {
  lng: -43.5613585,
  lat: -23.0746084
  },
  {
  lng: -43.5612938,
  lat: -23.0745477
  },
  {
  lng: -43.5611817,
  lat: -23.0744881
  },
  {
  lng: -43.5610652,
  lat: -23.0744176
  },
  {
  lng: -43.5609383,
  lat: -23.074354
  },
  {
  lng: -43.5608145,
  lat: -23.074293
  },
  {
  lng: -43.5606849,
  lat: -23.0742348
  },
  {
  lng: -43.5605481,
  lat: -23.0741944
  },
  {
  lng: -43.5604205,
  lat: -23.0741727
  },
  {
  lng: -43.5602637,
  lat: -23.0741608
  },
  {
  lng: -43.5601348,
  lat: -23.0741596
  },
  {
  lng: -43.5599914,
  lat: -23.074157
  },
  {
  lng: -43.5598212,
  lat: -23.0741399
  },
  {
  lng: -43.5596748,
  lat: -23.0741266
  },
  {
  lng: -43.5595342,
  lat: -23.0741308
  },
  {
  lng: -43.5593806,
  lat: -23.0741337
  },
  {
  lng: -43.5592584,
  lat: -23.0740837
  },
  {
  lng: -43.5591472,
  lat: -23.073986
  },
  {
  lng: -43.559016,
  lat: -23.0739225
  },
  {
  lng: -43.5588852,
  lat: -23.0738806
  },
  {
  lng: -43.558759,
  lat: -23.0738575
  },
  {
  lng: -43.5586195,
  lat: -23.0738401
  },
  {
  lng: -43.5584922,
  lat: -23.0738292
  },
  {
  lng: -43.5583619,
  lat: -23.073828
  },
  {
  lng: -43.5582167,
  lat: -23.0738159
  },
  {
  lng: -43.5580835,
  lat: -23.0738146
  },
  {
  lng: -43.5579328,
  lat: -23.0738189
  },
  {
  lng: -43.5577996,
  lat: -23.0738258
  },
  {
  lng: -43.5576679,
  lat: -23.0738299
  },
  {
  lng: -43.5575303,
  lat: -23.0738259
  },
  {
  lng: -43.5574012,
  lat: -23.0738125
  },
  {
  lng: -43.5572689,
  lat: -23.0737759
  },
  {
  lng: -43.5571357,
  lat: -23.0737665
  },
  {
  lng: -43.5569876,
  lat: -23.0737532
  },
  {
  lng: -43.5568603,
  lat: -23.0737654
  },
  {
  lng: -43.5567309,
  lat: -23.0738128
  },
  {
  lng: -43.5566039,
  lat: -23.0738453
  },
  {
  lng: -43.5564749,
  lat: -23.0738277
  },
  {
  lng: -43.5563466,
  lat: -23.0737627
  },
  {
  lng: -43.5562272,
  lat: -23.0737045
  },
  {
  lng: -43.5561123,
  lat: -23.0736543
  },
  {
  lng: -43.5559872,
  lat: -23.0736028
  },
  {
  lng: -43.5558573,
  lat: -23.0735257
  },
  {
  lng: -43.5557521,
  lat: -23.0734334
  },
  {
  lng: -43.5556528,
  lat: -23.073356
  },
  {
  lng: -43.5555274,
  lat: -23.0732829
  },
  {
  lng: -43.5554182,
  lat: -23.0732191
  },
  {
  lng: -43.5552886,
  lat: -23.073161
  },
  {
  lng: -43.5551722,
  lat: -23.0731135
  },
  {
  lng: -43.5550384,
  lat: -23.0730663
  },
  {
  lng: -43.554874,
  lat: -23.0730327
  },
  {
  lng: -43.5547056,
  lat: -23.0729881
  },
  {
  lng: -43.554528,
  lat: -23.0730081
  },
  {
  lng: -43.5543814,
  lat: -23.0731265
  },
  {
  lng: -43.5543277,
  lat: -23.0732219
  },
  {
  lng: -43.554325,
  lat: -23.0733403
  },
  {
  lng: -43.5543913,
  lat: -23.0734525
  },
  {
  lng: -43.5543641,
  lat: -23.0736846
  },
  {
  lng: -43.5542306,
  lat: -23.0737519
  },
  {
  lng: -43.554337,
  lat: -23.0739993
  },
  {
  lng: -43.5542632,
  lat: -23.0741097
  },
  {
  lng: -43.5541005,
  lat: -23.0740945
  },
  {
  lng: -43.5540676,
  lat: -23.0741804
  },
  {
  lng: -43.5539289,
  lat: -23.0742157
  },
  {
  lng: -43.553836,
  lat: -23.0741627
  },
  {
  lng: -43.5537808,
  lat: -23.0742715
  },
  {
  lng: -43.5537022,
  lat: -23.0744186
  },
  {
  lng: -43.5535464,
  lat: -23.0744838
  },
  {
  lng: -43.5536896,
  lat: -23.0745686
  },
  {
  lng: -43.553758,
  lat: -23.0746874
  },
  {
  lng: -43.5535947,
  lat: -23.0747049
  },
  {
  lng: -43.5534128,
  lat: -23.0746613
  },
  {
  lng: -43.5535964,
  lat: -23.0748524
  },
  {
  lng: -43.5536473,
  lat: -23.075393
  },
  {
  lng: -43.5537579,
  lat: -23.0756928
  },
  {
  lng: -43.5537969,
  lat: -23.075976
  },
  {
  lng: -43.5535956,
  lat: -23.0762766
  },
  {
  lng: -43.553388,
  lat: -23.0763959
  },
  {
  lng: -43.5532497,
  lat: -23.0764257
  },
  {
  lng: -43.5522545,
  lat: -23.0758669
  },
  {
  lng: -43.552086,
  lat: -23.0757723
  },
  {
  lng: -43.5520492,
  lat: -23.0757515
  },
  {
  lng: -43.5517014,
  lat: -23.0753849
  },
  {
  lng: -43.5513228,
  lat: -23.0747783
  },
  {
  lng: -43.5511577,
  lat: -23.0747185
  },
  {
  lng: -43.5510078,
  lat: -23.0744598
  },
  {
  lng: -43.5506649,
  lat: -23.0741817
  },
  {
  lng: -43.5494423,
  lat: -23.0734166
  },
  {
  lng: -43.5493925,
  lat: -23.0734089
  },
  {
  lng: -43.5492955,
  lat: -23.0734857
  },
  {
  lng: -43.5490292,
  lat: -23.0734994
  },
  {
  lng: -43.548926,
  lat: -23.0734504
  },
  {
  lng: -43.5489406,
  lat: -23.0733338
  },
  {
  lng: -43.5490495,
  lat: -23.0731662
  },
  {
  lng: -43.5490813,
  lat: -23.0729236
  },
  {
  lng: -43.5492077,
  lat: -23.0728546
  },
  {
  lng: -43.549863,
  lat: -23.0727069
  },
  {
  lng: -43.5500748,
  lat: -23.0725694
  },
  {
  lng: -43.5501794,
  lat: -23.0726278
  },
  {
  lng: -43.5505796,
  lat: -23.0725586
  },
  {
  lng: -43.5513627,
  lat: -23.0722323
  },
  {
  lng: -43.5517586,
  lat: -23.0722312
  },
  {
  lng: -43.5520332,
  lat: -23.0719303
  },
  {
  lng: -43.5523159,
  lat: -23.071365
  },
  {
  lng: -43.5524385,
  lat: -23.0708577
  },
  {
  lng: -43.5524585,
  lat: -23.070713
  },
  {
  lng: -43.5524821,
  lat: -23.0700876
  },
  {
  lng: -43.5522326,
  lat: -23.0696152
  },
  {
  lng: -43.5519167,
  lat: -23.0692139
  },
  {
  lng: -43.5518857,
  lat: -23.0690938
  },
  {
  lng: -43.5516302,
  lat: -23.0689776
  },
  {
  lng: -43.5515576,
  lat: -23.0688629
  },
  {
  lng: -43.5513295,
  lat: -23.068859
  },
  {
  lng: -43.5513295,
  lat: -23.0687827
  },
  {
  lng: -43.5514207,
  lat: -23.0685613
  },
  {
  lng: -43.5515536,
  lat: -23.0686392
  },
  {
  lng: -43.5516649,
  lat: -23.068479
  },
  {
  lng: -43.5517569,
  lat: -23.0684044
  },
  {
  lng: -43.5518077,
  lat: -23.0682802
  },
  {
  lng: -43.5517491,
  lat: -23.0679389
  },
  {
  lng: -43.5516187,
  lat: -23.0677451
  },
  {
  lng: -43.55165,
  lat: -23.0674812
  },
  {
  lng: -43.5509394,
  lat: -23.0665651
  },
  {
  lng: -43.5503711,
  lat: -23.0659709
  },
  {
  lng: -43.5500347,
  lat: -23.0656307
  },
  {
  lng: -43.5496818,
  lat: -23.0654577
  },
  {
  lng: -43.5492705,
  lat: -23.0653482
  },
  {
  lng: -43.548999,
  lat: -23.0653786
  },
  {
  lng: -43.548485,
  lat: -23.0652688
  },
  {
  lng: -43.5480623,
  lat: -23.065076
  },
  {
  lng: -43.5479943,
  lat: -23.0649689
  },
  {
  lng: -43.5479161,
  lat: -23.0648264
  },
  {
  lng: -43.5477549,
  lat: -23.0647199
  },
  {
  lng: -43.5477425,
  lat: -23.0646384
  },
  {
  lng: -43.5477719,
  lat: -23.0645924
  },
  {
  lng: -43.5480041,
  lat: -23.0646788
  },
  {
  lng: -43.5481609,
  lat: -23.0646069
  },
  {
  lng: -43.5481268,
  lat: -23.0645568
  },
  {
  lng: -43.5481914,
  lat: -23.0644554
  },
  {
  lng: -43.5482484,
  lat: -23.0644432
  },
  {
  lng: -43.5483218,
  lat: -23.0643709
  },
  {
  lng: -43.5483339,
  lat: -23.0643331
  },
  {
  lng: -43.5482769,
  lat: -23.0642736
  },
  {
  lng: -43.5482579,
  lat: -23.0642299
  },
  {
  lng: -43.5482256,
  lat: -23.0640744
  },
  {
  lng: -43.5482313,
  lat: -23.0639992
  },
  {
  lng: -43.5483091,
  lat: -23.0638856
  },
  {
  lng: -43.5483909,
  lat: -23.0638245
  },
  {
  lng: -43.5484892,
  lat: -23.0637909
  },
  {
  lng: -43.5485606,
  lat: -23.0637907
  },
  {
  lng: -43.5485561,
  lat: -23.0637004
  },
  {
  lng: -43.5485979,
  lat: -23.0636605
  },
  {
  lng: -43.548751,
  lat: -23.0635864
  },
  {
  lng: -43.5489536,
  lat: -23.0635558
  },
  {
  lng: -43.5493289,
  lat: -23.0633884
  },
  {
  lng: -43.5496338,
  lat: -23.0633061
  },
  {
  lng: -43.5494196,
  lat: -23.0626419
  },
  {
  lng: -43.549129,
  lat: -23.0621033
  },
  {
  lng: -43.5486996,
  lat: -23.0615382
  },
  {
  lng: -43.5482332,
  lat: -23.0610261
  },
  {
  lng: -43.548055,
  lat: -23.061017
  },
  {
  lng: -43.5478455,
  lat: -23.0609845
  },
  {
  lng: -43.547674,
  lat: -23.0609018
  },
  {
  lng: -43.547623,
  lat: -23.060803
  },
  {
  lng: -43.5474946,
  lat: -23.0607942
  },
  {
  lng: -43.547358,
  lat: -23.0606322
  },
  {
  lng: -43.547386,
  lat: -23.0604329
  },
  {
  lng: -43.5472696,
  lat: -23.0603732
  },
  {
  lng: -43.547252,
  lat: -23.0602637
  },
  {
  lng: -43.5470405,
  lat: -23.0602105
  },
  {
  lng: -43.5466586,
  lat: -23.0600142
  },
  {
  lng: -43.5465515,
  lat: -23.0599372
  },
  {
  lng: -43.5447782,
  lat: -23.0591083
  },
  {
  lng: -43.5444372,
  lat: -23.0592723
  },
  {
  lng: -43.5440356,
  lat: -23.0592679
  },
  {
  lng: -43.5437082,
  lat: -23.0594161
  },
  {
  lng: -43.5433245,
  lat: -23.0595019
  },
  {
  lng: -43.5425884,
  lat: -23.059303
  },
  {
  lng: -43.5421742,
  lat: -23.0591888
  },
  {
  lng: -43.5419822,
  lat: -23.0590418
  },
  {
  lng: -43.5418451,
  lat: -23.0588155
  },
  {
  lng: -43.5418587,
  lat: -23.0586188
  },
  {
  lng: -43.5419267,
  lat: -23.0585135
  },
  {
  lng: -43.5420012,
  lat: -23.0584368
  },
  {
  lng: -43.541972,
  lat: -23.0583081
  },
  {
  lng: -43.5417637,
  lat: -23.058258
  },
  {
  lng: -43.5416072,
  lat: -23.0583232
  },
  {
  lng: -43.5414351,
  lat: -23.0582546
  },
  {
  lng: -43.5414076,
  lat: -23.0581805
  },
  {
  lng: -43.541483,
  lat: -23.0580079
  },
  {
  lng: -43.5414175,
  lat: -23.0579287
  },
  {
  lng: -43.5410909,
  lat: -23.0577102
  },
  {
  lng: -43.5407262,
  lat: -23.0572625
  },
  {
  lng: -43.5402188,
  lat: -23.0569207
  },
  {
  lng: -43.5398437,
  lat: -23.0563954
  },
  {
  lng: -43.5397043,
  lat: -23.0565098
  },
  {
  lng: -43.5389414,
  lat: -23.0563505
  },
  {
  lng: -43.5389379,
  lat: -23.0562615
  },
  {
  lng: -43.5384497,
  lat: -23.056204
  },
  {
  lng: -43.5385043,
  lat: -23.056341
  },
  {
  lng: -43.5384112,
  lat: -23.0563408
  },
  {
  lng: -43.5383106,
  lat: -23.0562547
  },
  {
  lng: -43.5381225,
  lat: -23.0562209
  },
  {
  lng: -43.5380878,
  lat: -23.0563527
  },
  {
  lng: -43.5378026,
  lat: -23.0563695
  },
  {
  lng: -43.5374909,
  lat: -23.0562898
  },
  {
  lng: -43.5373265,
  lat: -23.0562669
  },
  {
  lng: -43.5371612,
  lat: -23.0562156
  },
  {
  lng: -43.5369453,
  lat: -23.0561596
  },
  {
  lng: -43.5367771,
  lat: -23.0560086
  },
  {
  lng: -43.5365992,
  lat: -23.0558214
  },
  {
  lng: -43.5365371,
  lat: -23.0556914
  },
  {
  lng: -43.5363863,
  lat: -23.0557012
  },
  {
  lng: -43.5362704,
  lat: -23.0556552
  },
  {
  lng: -43.5361395,
  lat: -23.055487
  },
  {
  lng: -43.5360462,
  lat: -23.0554349
  },
  {
  lng: -43.5358753,
  lat: -23.0552462
  },
  {
  lng: -43.5357593,
  lat: -23.0550906
  },
  {
  lng: -43.5358313,
  lat: -23.0549699
  },
  {
  lng: -43.5356906,
  lat: -23.054703
  },
  {
  lng: -43.5354626,
  lat: -23.0545208
  },
  {
  lng: -43.5352902,
  lat: -23.0542185
  },
  {
  lng: -43.5351795,
  lat: -23.0541396
  },
  {
  lng: -43.5349868,
  lat: -23.0537205
  },
  {
  lng: -43.5350311,
  lat: -23.0536122
  },
  {
  lng: -43.5349776,
  lat: -23.053413
  },
  {
  lng: -43.535075,
  lat: -23.0531311
  },
  {
  lng: -43.5352113,
  lat: -23.052875
  },
  {
  lng: -43.5352838,
  lat: -23.0528714
  },
  {
  lng: -43.5354025,
  lat: -23.0527033
  },
  {
  lng: -43.5354398,
  lat: -23.0525558
  },
  {
  lng: -43.5355099,
  lat: -23.0524875
  },
  {
  lng: -43.5356974,
  lat: -23.052408
  },
  {
  lng: -43.535766,
  lat: -23.0524166
  },
  {
  lng: -43.5360205,
  lat: -23.0526025
  },
  {
  lng: -43.5361617,
  lat: -23.0525978
  },
  {
  lng: -43.5362315,
  lat: -23.0524435
  },
  {
  lng: -43.536255,
  lat: -23.0521911
  },
  {
  lng: -43.5362205,
  lat: -23.0519487
  },
  {
  lng: -43.5360995,
  lat: -23.051697
  },
  {
  lng: -43.5357946,
  lat: -23.051316
  },
  {
  lng: -43.5353674,
  lat: -23.0509414
  },
  {
  lng: -43.5347764,
  lat: -23.0505451
  },
  {
  lng: -43.5339718,
  lat: -23.0501812
  },
  {
  lng: -43.5328204,
  lat: -23.0498065
  },
  {
  lng: -43.5316185,
  lat: -23.0495772
  },
  {
  lng: -43.5239505,
  lat: -23.0485808
  },
  {
  lng: -43.5219673,
  lat: -23.0484047
  },
  {
  lng: -43.5203062,
  lat: -23.0483315
  },
  {
  lng: -43.51612,
  lat: -23.0481465
  },
  {
  lng: -43.5151285,
  lat: -23.0480525
  },
  {
  lng: -43.5149318,
  lat: -23.0481399
  },
  {
  lng: -43.5147149,
  lat: -23.0481399
  },
  {
  lng: -43.5145752,
  lat: -23.0482279
  },
  {
  lng: -43.5144538,
  lat: -23.0483497
  },
  {
  lng: -43.5143252,
  lat: -23.0483869
  },
  {
  lng: -43.5138022,
  lat: -23.0484026
  },
  {
  lng: -43.5121405,
  lat: -23.0480729
  },
  {
  lng: -43.5111549,
  lat: -23.0478359
  },
  {
  lng: -43.5107769,
  lat: -23.048002
  },
  {
  lng: -43.5104685,
  lat: -23.0481007
  },
  {
  lng: -43.51023,
  lat: -23.0480934
  },
  {
  lng: -43.5100419,
  lat: -23.0480081
  },
  {
  lng: -43.5098607,
  lat: -23.0479535
  },
  {
  lng: -43.5097397,
  lat: -23.0479167
  },
  {
  lng: -43.5096282,
  lat: -23.0478189
  },
  {
  lng: -43.509533,
  lat: -23.0477906
  },
  {
  lng: -43.5093919,
  lat: -23.047736
  },
  {
  lng: -43.5093663,
  lat: -23.0476874
  },
  {
  lng: -43.5092844,
  lat: -23.0477136
  },
  {
  lng: -43.5092692,
  lat: -23.04766
  },
  {
  lng: -43.5091285,
  lat: -23.0476073
  },
  {
  lng: -43.5090707,
  lat: -23.04769
  },
  {
  lng: -43.5089344,
  lat: -23.0477223
  },
  {
  lng: -43.5090357,
  lat: -23.0476408
  },
  {
  lng: -43.5090348,
  lat: -23.0475876
  },
  {
  lng: -43.5087409,
  lat: -23.0475104
  },
  {
  lng: -43.5086657,
  lat: -23.0475575
  },
  {
  lng: -43.5085557,
  lat: -23.0475015
  },
  {
  lng: -43.5083409,
  lat: -23.0475016
  },
  {
  lng: -43.5083252,
  lat: -23.047384
  },
  {
  lng: -43.508203,
  lat: -23.04738
  },
  {
  lng: -43.5081656,
  lat: -23.0473047
  },
  {
  lng: -43.5080472,
  lat: -23.0473051
  },
  {
  lng: -43.5080214,
  lat: -23.0471422
  },
  {
  lng: -43.5077981,
  lat: -23.0470368
  },
  {
  lng: -43.5075172,
  lat: -23.0467501
  },
  {
  lng: -43.5074589,
  lat: -23.0468102
  },
  {
  lng: -43.5074026,
  lat: -23.0467938
  },
  {
  lng: -43.5073897,
  lat: -23.0467091
  },
  {
  lng: -43.5074656,
  lat: -23.0466695
  },
  {
  lng: -43.5074527,
  lat: -23.0466412
  },
  {
  lng: -43.5072418,
  lat: -23.0463966
  },
  {
  lng: -43.5071491,
  lat: -23.0462181
  },
  {
  lng: -43.5070745,
  lat: -23.046184
  },
  {
  lng: -43.5070608,
  lat: -23.0459621
  },
  {
  lng: -43.506955,
  lat: -23.0458973
  },
  {
  lng: -43.5070133,
  lat: -23.0458161
  },
  {
  lng: -43.5069268,
  lat: -23.0457905
  },
  {
  lng: -43.5069312,
  lat: -23.045737
  },
  {
  lng: -43.506858,
  lat: -23.0456885
  },
  {
  lng: -43.5069588,
  lat: -23.0454914
  },
  {
  lng: -43.5068823,
  lat: -23.0455394
  },
  {
  lng: -43.5068776,
  lat: -23.0452882
  },
  {
  lng: -43.5067666,
  lat: -23.0451982
  },
  {
  lng: -43.5068726,
  lat: -23.0451131
  },
  {
  lng: -43.5068415,
  lat: -23.0450646
  },
  {
  lng: -43.5069061,
  lat: -23.0447993
  },
  {
  lng: -43.5068648,
  lat: -23.0446705
  },
  {
  lng: -43.506922,
  lat: -23.044549
  },
  {
  lng: -43.5069989,
  lat: -23.0445077
  },
  {
  lng: -43.5070344,
  lat: -23.0443807
  },
  {
  lng: -43.5071078,
  lat: -23.0440456
  },
  {
  lng: -43.5070842,
  lat: -23.0438302
  },
  {
  lng: -43.5071288,
  lat: -23.0437915
  },
  {
  lng: -43.5071632,
  lat: -23.0437386
  },
  {
  lng: -43.5072704,
  lat: -23.0435519
  },
  {
  lng: -43.5073025,
  lat: -23.0432433
  },
  {
  lng: -43.5076304,
  lat: -23.042865
  },
  {
  lng: -43.5074625,
  lat: -23.0425733
  },
  {
  lng: -43.5070706,
  lat: -23.0421874
  },
  {
  lng: -43.5061819,
  lat: -23.0415497
  },
  {
  lng: -43.5032932,
  lat: -23.0400544
  },
  {
  lng: -43.5030569,
  lat: -23.0400406
  },
  {
  lng: -43.5025155,
  lat: -23.0402783
  },
  {
  lng: -43.5016396,
  lat: -23.0404141
  },
  {
  lng: -43.5012353,
  lat: -23.0406147
  },
  {
  lng: -43.5010689,
  lat: -23.0408104
  },
  {
  lng: -43.5009875,
  lat: -23.0408478
  },
  {
  lng: -43.5010958,
  lat: -23.04096
  },
  {
  lng: -43.5010153,
  lat: -23.0410433
  },
  {
  lng: -43.5007466,
  lat: -23.040983
  },
  {
  lng: -43.5006477,
  lat: -23.0410393
  },
  {
  lng: -43.5005703,
  lat: -23.0409889
  },
  {
  lng: -43.5004183,
  lat: -23.0411119
  },
  {
  lng: -43.5003669,
  lat: -23.0410966
  },
  {
  lng: -43.500059,
  lat: -23.0408443
  },
  {
  lng: -43.5000488,
  lat: -23.0407392
  },
  {
  lng: -43.5001225,
  lat: -23.0406889
  },
  {
  lng: -43.4999001,
  lat: -23.0404292
  },
  {
  lng: -43.4998877,
  lat: -23.0403822
  },
  {
  lng: -43.4999294,
  lat: -23.0403634
  },
  {
  lng: -43.4994486,
  lat: -23.039845
  },
  {
  lng: -43.4993845,
  lat: -23.0395762
  },
  {
  lng: -43.4994424,
  lat: -23.0394012
  },
  {
  lng: -43.4993485,
  lat: -23.0391336
  },
  {
  lng: -43.4989605,
  lat: -23.0386178
  },
  {
  lng: -43.4989605,
  lat: -23.0383696
  },
  {
  lng: -43.499065,
  lat: -23.0381766
  },
  {
  lng: -43.4988458,
  lat: -23.0380166
  },
  {
  lng: -43.4987512,
  lat: -23.0376005
  },
  {
  lng: -43.4984172,
  lat: -23.0371999
  },
  {
  lng: -43.4980424,
  lat: -23.0370457
  },
  {
  lng: -43.4979825,
  lat: -23.0372832
  },
  {
  lng: -43.4978482,
  lat: -23.0373312
  },
  {
  lng: -43.4979777,
  lat: -23.0375893
  },
  {
  lng: -43.4979329,
  lat: -23.0378113
  },
  {
  lng: -43.4977981,
  lat: -23.037825
  },
  {
  lng: -43.4976651,
  lat: -23.0377277
  },
  {
  lng: -43.4975896,
  lat: -23.037845
  },
  {
  lng: -43.4973888,
  lat: -23.037811
  },
  {
  lng: -43.4973197,
  lat: -23.0377913
  },
  {
  lng: -43.4973594,
  lat: -23.0377242
  },
  {
  lng: -43.4969667,
  lat: -23.0376148
  },
  {
  lng: -43.4967958,
  lat: -23.0374482
  },
  {
  lng: -43.496697,
  lat: -23.0374387
  },
  {
  lng: -43.4962169,
  lat: -23.0370959
  },
  {
  lng: -43.4958316,
  lat: -23.0369716
  },
  {
  lng: -43.4957604,
  lat: -23.0370085
  },
  {
  lng: -43.4958865,
  lat: -23.0371712
  },
  {
  lng: -43.4958286,
  lat: -23.0372073
  },
  {
  lng: -43.4957411,
  lat: -23.0371477
  },
  {
  lng: -43.4951926,
  lat: -23.036668
  },
  {
  lng: -43.4951815,
  lat: -23.0366139
  },
  {
  lng: -43.4952047,
  lat: -23.0365371
  },
  {
  lng: -43.4952111,
  lat: -23.0364829
  },
  {
  lng: -43.495196,
  lat: -23.0364189
  },
  {
  lng: -43.4951154,
  lat: -23.0364456
  },
  {
  lng: -43.495066,
  lat: -23.0364371
  },
  {
  lng: -43.4948596,
  lat: -23.0362358
  },
  {
  lng: -43.4948186,
  lat: -23.0361572
  },
  {
  lng: -43.4947666,
  lat: -23.0361878
  },
  {
  lng: -43.4945939,
  lat: -23.0361453
  },
  {
  lng: -43.4942852,
  lat: -23.0361319
  },
  {
  lng: -43.4942334,
  lat: -23.0361453
  },
  {
  lng: -43.4942139,
  lat: -23.0361654
  },
  {
  lng: -43.4942055,
  lat: -23.0361977
  },
  {
  lng: -43.4943601,
  lat: -23.036321
  },
  {
  lng: -43.4943539,
  lat: -23.0363532
  },
  {
  lng: -43.4943105,
  lat: -23.0363751
  },
  {
  lng: -43.4941937,
  lat: -23.036293
  },
  {
  lng: -43.4941395,
  lat: -23.0363475
  },
  {
  lng: -43.4940553,
  lat: -23.0362451
  },
  {
  lng: -43.4941121,
  lat: -23.0361788
  },
  {
  lng: -43.4940414,
  lat: -23.0360861
  },
  {
  lng: -43.4940648,
  lat: -23.0360128
  },
  {
  lng: -43.4939536,
  lat: -23.0360225
  },
  {
  lng: -43.4938753,
  lat: -23.0359945
  },
  {
  lng: -43.4937209,
  lat: -23.0359687
  },
  {
  lng: -43.493531,
  lat: -23.0359446
  },
  {
  lng: -43.4932854,
  lat: -23.0358159
  },
  {
  lng: -43.4932108,
  lat: -23.0357365
  },
  {
  lng: -43.4930898,
  lat: -23.0356708
  },
  {
  lng: -43.4892522,
  lat: -23.0346692
  },
  {
  lng: -43.4855325,
  lat: -23.0336363
  },
  {
  lng: -43.4811321,
  lat: -23.0324549
  },
  {
  lng: -43.4811348,
  lat: -23.0325303
  },
  {
  lng: -43.4811552,
  lat: -23.0325769
  },
  {
  lng: -43.4811693,
  lat: -23.0326424
  },
  {
  lng: -43.4811872,
  lat: -23.0326932
  },
  {
  lng: -43.4811723,
  lat: -23.0327187
  },
  {
  lng: -43.4811428,
  lat: -23.0327422
  },
  {
  lng: -43.4811175,
  lat: -23.0327498
  },
  {
  lng: -43.4810649,
  lat: -23.0327357
  },
  {
  lng: -43.4809806,
  lat: -23.0327132
  },
  {
  lng: -43.4809321,
  lat: -23.0326832
  },
  {
  lng: -43.4808951,
  lat: -23.0326665
  },
  {
  lng: -43.4808761,
  lat: -23.0326247
  },
  {
  lng: -43.4808537,
  lat: -23.0326035
  },
  {
  lng: -43.4807989,
  lat: -23.0326053
  },
  {
  lng: -43.4807611,
  lat: -23.0325948
  },
  {
  lng: -43.4807555,
  lat: -23.0325563
  },
  {
  lng: -43.4807146,
  lat: -23.0324873
  },
  {
  lng: -43.4806729,
  lat: -23.0324505
  },
  {
  lng: -43.4806337,
  lat: -23.0323902
  },
  {
  lng: -43.4806184,
  lat: -23.0323252
  },
  {
  lng: -43.4806096,
  lat: -23.0322498
  },
  {
  lng: -43.4805804,
  lat: -23.0322689
  },
  {
  lng: -43.4805575,
  lat: -23.0322576
  },
  {
  lng: -43.4805337,
  lat: -23.0322189
  },
  {
  lng: -43.4801119,
  lat: -23.0321745
  },
  {
  lng: -43.4795359,
  lat: -23.0320695
  },
  {
  lng: -43.4776461,
  lat: -23.0317768
  },
  {
  lng: -43.4763466,
  lat: -23.0315877
  },
  {
  lng: -43.4743625,
  lat: -23.031659
  },
  {
  lng: -43.4736384,
  lat: -23.0317742
  },
  {
  lng: -43.4725732,
  lat: -23.032195
  },
  {
  lng: -43.4715451,
  lat: -23.0328008
  },
  {
  lng: -43.4708257,
  lat: -23.0334346
  },
  {
  lng: -43.4705916,
  lat: -23.0338691
  },
  {
  lng: -43.4708439,
  lat: -23.0339857
  },
  {
  lng: -43.4709645,
  lat: -23.0340892
  },
  {
  lng: -43.4710486,
  lat: -23.0342661
  },
  {
  lng: -43.4710346,
  lat: -23.034412
  },
  {
  lng: -43.4710223,
  lat: -23.0345254
  },
  {
  lng: -43.4710921,
  lat: -23.0344464
  },
  {
  lng: -43.4711538,
  lat: -23.0344043
  },
  {
  lng: -43.4712047,
  lat: -23.0344068
  },
  {
  lng: -43.4712852,
  lat: -23.0345277
  },
  {
  lng: -43.4712852,
  lat: -23.0345697
  },
  {
  lng: -43.4712262,
  lat: -23.0346783
  },
  {
  lng: -43.4711162,
  lat: -23.034703
  },
  {
  lng: -43.4710742,
  lat: -23.0347218
  },
  {
  lng: -43.4710358,
  lat: -23.0347252
  },
  {
  lng: -43.4710004,
  lat: -23.0348293
  },
  {
  lng: -43.4713537,
  lat: -23.0350087
  },
  {
  lng: -43.4716407,
  lat: -23.0352518
  },
  {
  lng: -43.4718927,
  lat: -23.0354652
  },
  {
  lng: -43.4721573,
  lat: -23.0358025
  },
  {
  lng: -43.472239,
  lat: -23.0362486
  },
  {
  lng: -43.4722176,
  lat: -23.0364386
  },
  {
  lng: -43.472099,
  lat: -23.0365901
  },
  {
  lng: -43.471866,
  lat: -23.0367509
  },
  {
  lng: -43.4717025,
  lat: -23.0367879
  },
  {
  lng: -43.471466,
  lat: -23.0369383
  },
  {
  lng: -43.4709729,
  lat: -23.0369913
  },
  {
  lng: -43.470592,
  lat: -23.0369733
  },
  {
  lng: -43.4705722,
  lat: -23.0370057
  },
  {
  lng: -43.4706479,
  lat: -23.0370925
  },
  {
  lng: -43.4709101,
  lat: -23.0372094
  },
  {
  lng: -43.4710683,
  lat: -23.0374731
  },
  {
  lng: -43.4709972,
  lat: -23.0377294
  },
  {
  lng: -43.4707155,
  lat: -23.0378804
  },
  {
  lng: -43.4701164,
  lat: -23.0378253
  },
  {
  lng: -43.469869,
  lat: -23.0379575
  },
  {
  lng: -43.4692381,
  lat: -23.0380062
  },
  {
  lng: -43.468405,
  lat: -23.0379434
  },
  {
  lng: -43.4681476,
  lat: -23.0377116
  },
  {
  lng: -43.4680013,
  lat: -23.0376478
  },
  {
  lng: -43.4677342,
  lat: -23.0373376
  },
  {
  lng: -43.4676172,
  lat: -23.037132
  },
  {
  lng: -43.4675896,
  lat: -23.0368344
  },
  {
  lng: -43.4677095,
  lat: -23.0366859
  },
  {
  lng: -43.4678164,
  lat: -23.0366254
  },
  {
  lng: -43.468229,
  lat: -23.0366163
  },
  {
  lng: -43.4680429,
  lat: -23.0365318
  },
  {
  lng: -43.4679505,
  lat: -23.036409
  },
  {
  lng: -43.4679399,
  lat: -23.0359832
  },
  {
  lng: -43.4682344,
  lat: -23.0355696
  },
  {
  lng: -43.4685572,
  lat: -23.0353945
  },
  {
  lng: -43.4684776,
  lat: -23.0352337
  },
  {
  lng: -43.4686372,
  lat: -23.0349805
  },
  {
  lng: -43.4686809,
  lat: -23.0347493
  },
  {
  lng: -43.4687442,
  lat: -23.0347054
  },
  {
  lng: -43.4690155,
  lat: -23.0342252
  },
  {
  lng: -43.4695317,
  lat: -23.033847
  },
  {
  lng: -43.4698717,
  lat: -23.0338002
  },
  {
  lng: -43.4704116,
  lat: -23.0338477
  },
  {
  lng: -43.4704655,
  lat: -23.0333679
  },
  {
  lng: -43.470226,
  lat: -23.0327824
  },
  {
  lng: -43.4694828,
  lat: -23.0317664
  },
  {
  lng: -43.4678124,
  lat: -23.0303039
  },
  {
  lng: -43.4650208,
  lat: -23.0288127
  },
  {
  lng: -43.4645799,
  lat: -23.0286337
  },
  {
  lng: -43.4635747,
  lat: -23.0283428
  },
  {
  lng: -43.4633795,
  lat: -23.0282043
  },
  {
  lng: -43.4627919,
  lat: -23.027948
  },
  {
  lng: -43.4620556,
  lat: -23.0277965
  },
  {
  lng: -43.4613138,
  lat: -23.0274172
  },
  {
  lng: -43.459581,
  lat: -23.0269144
  },
  {
  lng: -43.459427,
  lat: -23.0267609
  },
  {
  lng: -43.4591822,
  lat: -23.0266598
  },
  {
  lng: -43.4582657,
  lat: -23.0264914
  },
  {
  lng: -43.4577773,
  lat: -23.0261677
  },
  {
  lng: -43.4572706,
  lat: -23.0260185
  },
  {
  lng: -43.4569759,
  lat: -23.0259852
  },
  {
  lng: -43.456396,
  lat: -23.025782
  },
  {
  lng: -43.4558801,
  lat: -23.0257087
  },
  {
  lng: -43.4556515,
  lat: -23.0256307
  },
  {
  lng: -43.4554077,
  lat: -23.0254308
  },
  {
  lng: -43.4552364,
  lat: -23.0253606
  },
  {
  lng: -43.4545907,
  lat: -23.0251722
  },
  {
  lng: -43.4535109,
  lat: -23.0249489
  },
  {
  lng: -43.4530384,
  lat: -23.0246785
  },
  {
  lng: -43.4525561,
  lat: -23.0245523
  },
  {
  lng: -43.4514268,
  lat: -23.0243515
  },
  {
  lng: -43.4507901,
  lat: -23.0241022
  },
  {
  lng: -43.4471793,
  lat: -23.0231371
  },
  {
  lng: -43.4459517,
  lat: -23.0228003
  },
  {
  lng: -43.4448143,
  lat: -23.0225096
  },
  {
  lng: -43.4421238,
  lat: -23.0218832
  },
  {
  lng: -43.4412885,
  lat: -23.021799
  },
  {
  lng: -43.4406355,
  lat: -23.0215496
  },
  {
  lng: -43.4398581,
  lat: -23.0214128
  },
  {
  lng: -43.4389754,
  lat: -23.0211687
  },
  {
  lng: -43.4384754,
  lat: -23.0211486
  },
  {
  lng: -43.4379011,
  lat: -23.0209384
  },
  {
  lng: -43.437099,
  lat: -23.020741
  },
  {
  lng: -43.4366146,
  lat: -23.0206723
  },
  {
  lng: -43.4362015,
  lat: -23.0205485
  },
  {
  lng: -43.4360604,
  lat: -23.0205556
  },
  {
  lng: -43.4351021,
  lat: -23.0203425
  },
  {
  lng: -43.4347707,
  lat: -23.0203994
  },
  {
  lng: -43.4340084,
  lat: -23.0203053
  },
  {
  lng: -43.4326727,
  lat: -23.0200122
  },
  {
  lng: -43.4306332,
  lat: -23.0196634
  },
  {
  lng: -43.4294847,
  lat: -23.0195256
  }
];

export default polygonCoords_Rio;