let polygonCoords_PauloDeFrontin = [
  {
    lng: -43.7167643,
    lat: -22.4956984
  },
  {
    lng: -43.6972398,
    lat: -22.5408482
  },
  {
    lng: -43.697544,
    lat: -22.5414843
  },
  {
    lng: -43.7022345,
    lat: -22.5435298
  },
  {
    lng: -43.7045395,
    lat: -22.5464351
  },
  {
    lng: -43.7077082,
    lat: -22.5468051
  },
  {
    lng: -43.7102398,
    lat: -22.5486526
  },
  {
    lng: -43.7115095,
    lat: -22.5495376
  },
  {
    lng: -43.7114895,
    lat: -22.5498151
  },
  {
    lng: -43.7115073,
    lat: -22.5498718
  },
  {
    lng: -43.7115616,
    lat: -22.5499752
  },
  {
    lng: -43.7115977,
    lat: -22.5500551
  },
  {
    lng: -43.7116178,
    lat: -22.5500898
  },
  {
    lng: -43.7116267,
    lat: -22.5501196
  },
  {
    lng: -43.7116746,
    lat: -22.5502257
  },
  {
    lng: -43.711687,
    lat: -22.5502555
  },
  {
    lng: -43.7116987,
    lat: -22.550283
  },
  {
    lng: -43.7117104,
    lat: -22.5503105
  },
  {
    lng: -43.711745,
    lat: -22.5503618
  },
  {
    lng: -43.7117626,
    lat: -22.5503919
  },
  {
    lng: -43.7117802,
    lat: -22.5504221
  },
  {
    lng: -43.7118138,
    lat: -22.5504815
  },
  {
    lng: -43.7118522,
    lat: -22.5505376
  },
  {
    lng: -43.711893,
    lat: -22.5505862
  },
  {
    lng: -43.711913,
    lat: -22.5506131
  },
  {
    lng: -43.7119329,
    lat: -22.5506401
  },
  {
    lng: -43.7119643,
    lat: -22.5506967
  },
  {
    lng: -43.7119946,
    lat: -22.5507518
  },
  {
    lng: -43.7120129,
    lat: -22.5508117
  },
  {
    lng: -43.7120278,
    lat: -22.5508735
  },
  {
    lng: -43.7120319,
    lat: -22.5509352
  },
  {
    lng: -43.7120297,
    lat: -22.551002
  },
  {
    lng: -43.7120202,
    lat: -22.5510701
  },
  {
    lng: -43.7120016,
    lat: -22.5511355
  },
  {
    lng: -43.7119772,
    lat: -22.5512018
  },
  {
    lng: -43.7119633,
    lat: -22.5512333
  },
  {
    lng: -43.7119495,
    lat: -22.5512648
  },
  {
    lng: -43.7119127,
    lat: -22.5513227
  },
  {
    lng: -43.7118665,
    lat: -22.5513757
  },
  {
    lng: -43.7118204,
    lat: -22.551423
  },
  {
    lng: -43.7117704,
    lat: -22.5514741
  },
  {
    lng: -43.7117243,
    lat: -22.5515224
  },
  {
    lng: -43.7117057,
    lat: -22.5515504
  },
  {
    lng: -43.7116871,
    lat: -22.5515786
  },
  {
    lng: -43.7116495,
    lat: -22.5516385
  },
  {
    lng: -43.7116091,
    lat: -22.5516891
  },
  {
    lng: -43.71158,
    lat: -22.5517425
  },
  {
    lng: -43.7115628,
    lat: -22.5517997
  },
  {
    lng: -43.7115308,
    lat: -22.5518541
  },
  {
    lng: -43.7115035,
    lat: -22.5519118
  },
  {
    lng: -43.7114737,
    lat: -22.5519695
  },
  {
    lng: -43.7114456,
    lat: -22.5520286
  },
  {
    lng: -43.7114248,
    lat: -22.5520925
  },
  {
    lng: -43.7114012,
    lat: -22.5521567
  },
  {
    lng: -43.7113714,
    lat: -22.5522211
  },
  {
    lng: -43.7113387,
    lat: -22.5522855
  },
  {
    lng: -43.7113073,
    lat: -22.5523417
  },
  {
    lng: -43.7112676,
    lat: -22.5523952
  },
  {
    lng: -43.7112196,
    lat: -22.5524446
  },
  {
    lng: -43.7111712,
    lat: -22.5524947
  },
  {
    lng: -43.7111265,
    lat: -22.5525459
  },
  {
    lng: -43.7110778,
    lat: -22.552601
  },
  {
    lng: -43.7110378,
    lat: -22.5526527
  },
  {
    lng: -43.7110107,
    lat: -22.5527194
  },
  {
    lng: -43.7109875,
    lat: -22.5527885
  },
  {
    lng: -43.7109661,
    lat: -22.5528528
  },
  {
    lng: -43.7109452,
    lat: -22.552921
  },
  {
    lng: -43.7109304,
    lat: -22.5529872
  },
  {
    lng: -43.7109148,
    lat: -22.5530555
  },
  {
    lng: -43.7108969,
    lat: -22.5531212
  },
  {
    lng: -43.7108853,
    lat: -22.5531836
  },
  {
    lng: -43.7108652,
    lat: -22.5532475
  },
  {
    lng: -43.7108494,
    lat: -22.553309
  },
  {
    lng: -43.7108324,
    lat: -22.553373
  },
  {
    lng: -43.7108116,
    lat: -22.5534319
  },
  {
    lng: -43.7107839,
    lat: -22.5534968
  },
  {
    lng: -43.7107585,
    lat: -22.5535581
  },
  {
    lng: -43.7107303,
    lat: -22.5536182
  },
  {
    lng: -43.7107068,
    lat: -22.5536861
  },
  {
    lng: -43.7106902,
    lat: -22.5537475
  },
  {
    lng: -43.7106722,
    lat: -22.5538172
  },
  {
    lng: -43.7106555,
    lat: -22.5538805
  },
  {
    lng: -43.7106428,
    lat: -22.5539435
  },
  {
    lng: -43.7106411,
    lat: -22.5540068
  },
  {
    lng: -43.7106403,
    lat: -22.5540769
  },
  {
    lng: -43.7106405,
    lat: -22.5541405
  },
  {
    lng: -43.7106487,
    lat: -22.5542073
  },
  {
    lng: -43.7106553,
    lat: -22.5542732
  },
  {
    lng: -43.7106448,
    lat: -22.5543388
  },
  {
    lng: -43.7106179,
    lat: -22.554399
  },
  {
    lng: -43.7105778,
    lat: -22.5544518
  },
  {
    lng: -43.7105255,
    lat: -22.5545057
  },
  {
    lng: -43.7104745,
    lat: -22.554549
  },
  {
    lng: -43.7104151,
    lat: -22.5545935
  },
  {
    lng: -43.7103538,
    lat: -22.5546323
  },
  {
    lng: -43.710292,
    lat: -22.554665
  },
  {
    lng: -43.7102332,
    lat: -22.5547018
  },
  {
    lng: -43.7101351,
    lat: -22.554781
  },
  {
    lng: -43.7100164,
    lat: -22.5548985
  },
  {
    lng: -43.7099361,
    lat: -22.5550696
  },
  {
    lng: -43.7098747,
    lat: -22.5552298
  },
  {
    lng: -43.7097853,
    lat: -22.5554419
  },
  {
    lng: -43.7096299,
    lat: -22.5556308
  },
  {
    lng: -43.7094863,
    lat: -22.5557531
  },
  {
    lng: -43.7093497,
    lat: -22.5559313
  },
  {
    lng: -43.7092714,
    lat: -22.5560903
  },
  {
    lng: -43.7092549,
    lat: -22.5562866
  },
  {
    lng: -43.7092615,
    lat: -22.5564646
  },
  {
    lng: -43.7093195,
    lat: -22.556617
  },
  {
    lng: -43.70941,
    lat: -22.556739
  },
  {
    lng: -43.7095773,
    lat: -22.5569101
  },
  {
    lng: -43.7097321,
    lat: -22.5570322
  },
  {
    lng: -43.7098126,
    lat: -22.5571044
  },
  {
    lng: -43.7098511,
    lat: -22.5571613
  },
  {
    lng: -43.709863,
    lat: -22.5572193
  },
  {
    lng: -43.7098592,
    lat: -22.5572925
  },
  {
    lng: -43.7098351,
    lat: -22.5573767
  },
  {
    lng: -43.7098329,
    lat: -22.5574234
  },
  {
    lng: -43.7098406,
    lat: -22.5574645
  },
  {
    lng: -43.7098603,
    lat: -22.5575164
  },
  {
    lng: -43.7099189,
    lat: -22.5575898
  },
  {
    lng: -43.7099621,
    lat: -22.557634
  },
  {
    lng: -43.710051,
    lat: -22.5577875
  },
  {
    lng: -43.7100538,
    lat: -22.5578371
  },
  {
    lng: -43.7100566,
    lat: -22.5579146
  },
  {
    lng: -43.7100689,
    lat: -22.5579764
  },
  {
    lng: -43.7100816,
    lat: -22.5580114
  },
  {
    lng: -43.7101687,
    lat: -22.5581469
  },
  {
    lng: -43.7102662,
    lat: -22.558254
  },
  {
    lng: -43.7104068,
    lat: -22.5583724
  },
  {
    lng: -43.7105612,
    lat: -22.5585088
  },
  {
    lng: -43.7106635,
    lat: -22.55869
  },
  {
    lng: -43.7106847,
    lat: -22.5588948
  },
  {
    lng: -43.7106881,
    lat: -22.5591241
  },
  {
    lng: -43.7106937,
    lat: -22.5593697
  },
  {
    lng: -43.7106624,
    lat: -22.5596166
  },
  {
    lng: -43.7106491,
    lat: -22.5598169
  },
  {
    lng: -43.7106815,
    lat: -22.5599637
  },
  {
    lng: -43.7107659,
    lat: -22.5601584
  },
  {
    lng: -43.7109112,
    lat: -22.5603429
  },
  {
    lng: -43.711085,
    lat: -22.5604645
  },
  {
    lng: -43.7112883,
    lat: -22.5605602
  },
  {
    lng: -43.7114227,
    lat: -22.5606565
  },
  {
    lng: -43.7114866,
    lat: -22.5607498
  },
  {
    lng: -43.7115932,
    lat: -22.5608778
  },
  {
    lng: -43.7116934,
    lat: -22.5609455
  },
  {
    lng: -43.7118394,
    lat: -22.5609754
  },
  {
    lng: -43.7119517,
    lat: -22.5609544
  },
  {
    lng: -43.7120275,
    lat: -22.5609283
  },
  {
    lng: -43.7120714,
    lat: -22.5609296
  },
  {
    lng: -43.7121196,
    lat: -22.5609361
  },
  {
    lng: -43.7121618,
    lat: -22.5609486
  },
  {
    lng: -43.712204,
    lat: -22.5609774
  },
  {
    lng: -43.7122449,
    lat: -22.5610308
  },
  {
    lng: -43.7122569,
    lat: -22.5610731
  },
  {
    lng: -43.71226,
    lat: -22.5611421
  },
  {
    lng: -43.7122477,
    lat: -22.5612065
  },
  {
    lng: -43.7122266,
    lat: -22.5612403
  },
  {
    lng: -43.7121859,
    lat: -22.5612839
  },
  {
    lng: -43.7121474,
    lat: -22.5613295
  },
  {
    lng: -43.7121158,
    lat: -22.5613961
  },
  {
    lng: -43.7120874,
    lat: -22.5615032
  },
  {
    lng: -43.7120791,
    lat: -22.5615637
  },
  {
    lng: -43.712091,
    lat: -22.5617063
  },
  {
    lng: -43.7121622,
    lat: -22.5618836
  },
  {
    lng: -43.7123492,
    lat: -22.5620975
  },
  {
    lng: -43.7123269,
    lat: -22.5621133
  },
  {
    lng: -43.7123061,
    lat: -22.5621576
  },
  {
    lng: -43.7123083,
    lat: -22.5622162
  },
  {
    lng: -43.7123201,
    lat: -22.5622671
  },
  {
    lng: -43.712333,
    lat: -22.5623211
  },
  {
    lng: -43.7123378,
    lat: -22.5623708
  },
  {
    lng: -43.7123384,
    lat: -22.562441
  },
  {
    lng: -43.7123394,
    lat: -22.5624952
  },
  {
    lng: -43.7123357,
    lat: -22.5625424
  },
  {
    lng: -43.7123326,
    lat: -22.5625861
  },
  {
    lng: -43.7123266,
    lat: -22.5626268
  },
  {
    lng: -43.7123176,
    lat: -22.5626729
  },
  {
    lng: -43.7122954,
    lat: -22.5627438
  },
  {
    lng: -43.7122497,
    lat: -22.5627629
  },
  {
    lng: -43.7122074,
    lat: -22.562782
  },
  {
    lng: -43.7121694,
    lat: -22.562809
  },
  {
    lng: -43.7120711,
    lat: -22.5628409
  },
  {
    lng: -43.7120436,
    lat: -22.562844
  },
  {
    lng: -43.7120252,
    lat: -22.5628468
  },
  {
    lng: -43.7120145,
    lat: -22.562849
  },
  {
    lng: -43.7120021,
    lat: -22.5628602
  },
  {
    lng: -43.7119791,
    lat: -22.562902
  },
  {
    lng: -43.7119549,
    lat: -22.5629336
  },
  {
    lng: -43.7119224,
    lat: -22.5629534
  },
  {
    lng: -43.7119049,
    lat: -22.5629674
  },
  {
    lng: -43.7119001,
    lat: -22.5629925
  },
  {
    lng: -43.7118995,
    lat: -22.5630441
  },
  {
    lng: -43.7118985,
    lat: -22.5634053
  },
  {
    lng: -43.7117326,
    lat: -22.5633989
  },
  {
    lng: -43.7115972,
    lat: -22.5633585
  },
  {
    lng: -43.7115224,
    lat: -22.5633299
  },
  {
    lng: -43.7114661,
    lat: -22.563314
  },
  {
    lng: -43.7114183,
    lat: -22.5633035
  },
  {
    lng: -43.7113743,
    lat: -22.5633026
  },
  {
    lng: -43.7113297,
    lat: -22.563301
  },
  {
    lng: -43.7112881,
    lat: -22.5632996
  },
  {
    lng: -43.7112123,
    lat: -22.5633235
  },
  {
    lng: -43.7111853,
    lat: -22.5633335
  },
  {
    lng: -43.7111442,
    lat: -22.5633355
  },
  {
    lng: -43.7111197,
    lat: -22.5633424
  },
  {
    lng: -43.7110493,
    lat: -22.5633886
  },
  {
    lng: -43.710964,
    lat: -22.5634541
  },
  {
    lng: -43.7109211,
    lat: -22.5634741
  },
  {
    lng: -43.7108739,
    lat: -22.5634894
  },
  {
    lng: -43.7108283,
    lat: -22.5635038
  },
  {
    lng: -43.7106894,
    lat: -22.5635506
  },
  {
    lng: -43.7105874,
    lat: -22.5635939
  },
  {
    lng: -43.7105401,
    lat: -22.5636094
  },
  {
    lng: -43.7104981,
    lat: -22.5636161
  },
  {
    lng: -43.7104302,
    lat: -22.5636143
  },
  {
    lng: -43.7103852,
    lat: -22.5636095
  },
  {
    lng: -43.7103318,
    lat: -22.5635853
  },
  {
    lng: -43.7102847,
    lat: -22.5635503
  },
  {
    lng: -43.7102529,
    lat: -22.5635332
  },
  {
    lng: -43.7102207,
    lat: -22.5635326
  },
  {
    lng: -43.7101887,
    lat: -22.5635378
  },
  {
    lng: -43.7099937,
    lat: -22.5636001
  },
  {
    lng: -43.7098616,
    lat: -22.563662
  },
  {
    lng: -43.7097959,
    lat: -22.5637074
  },
  {
    lng: -43.7097357,
    lat: -22.5637355
  },
  {
    lng: -43.7096818,
    lat: -22.5637532
  },
  {
    lng: -43.7095749,
    lat: -22.5637581
  },
  {
    lng: -43.7095178,
    lat: -22.5637558
  },
  {
    lng: -43.7094646,
    lat: -22.5637402
  },
  {
    lng: -43.709418,
    lat: -22.5637226
  },
  {
    lng: -43.7093847,
    lat: -22.5637098
  },
  {
    lng: -43.7093395,
    lat: -22.5637023
  },
  {
    lng: -43.7092042,
    lat: -22.5636484
  },
  {
    lng: -43.70916,
    lat: -22.5636193
  },
  {
    lng: -43.7091367,
    lat: -22.5635852
  },
  {
    lng: -43.7090528,
    lat: -22.5634551
  },
  {
    lng: -43.7089737,
    lat: -22.5634086
  },
  {
    lng: -43.7089473,
    lat: -22.5633575
  },
  {
    lng: -43.7089455,
    lat: -22.5633191
  },
  {
    lng: -43.7089462,
    lat: -22.5632662
  },
  {
    lng: -43.7089526,
    lat: -22.5632443
  },
  {
    lng: -43.7089541,
    lat: -22.5632263
  },
  {
    lng: -43.708943,
    lat: -22.5632068
  },
  {
    lng: -43.7088955,
    lat: -22.5631801
  },
  {
    lng: -43.7088694,
    lat: -22.5631742
  },
  {
    lng: -43.7088414,
    lat: -22.5631724
  },
  {
    lng: -43.7087969,
    lat: -22.5632018
  },
  {
    lng: -43.7087576,
    lat: -22.5632792
  },
  {
    lng: -43.7087331,
    lat: -22.5633397
  },
  {
    lng: -43.7087146,
    lat: -22.563375
  },
  {
    lng: -43.7086716,
    lat: -22.5634119
  },
  {
    lng: -43.7086243,
    lat: -22.5634302
  },
  {
    lng: -43.7085911,
    lat: -22.56344
  },
  {
    lng: -43.7085515,
    lat: -22.5634633
  },
  {
    lng: -43.7085037,
    lat: -22.5634925
  },
  {
    lng: -43.7084676,
    lat: -22.5635275
  },
  {
    lng: -43.7083214,
    lat: -22.5636438
  },
  {
    lng: -43.7082936,
    lat: -22.5637285
  },
  {
    lng: -43.7082802,
    lat: -22.5638216
  },
  {
    lng: -43.7082472,
    lat: -22.5639291
  },
  {
    lng: -43.7081944,
    lat: -22.5640271
  },
  {
    lng: -43.7081688,
    lat: -22.5640552
  },
  {
    lng: -43.7081053,
    lat: -22.5641598
  },
  {
    lng: -43.7080633,
    lat: -22.5643159
  },
  {
    lng: -43.7080335,
    lat: -22.5644592
  },
  {
    lng: -43.7080142,
    lat: -22.5645318
  },
  {
    lng: -43.70799,
    lat: -22.5646471
  },
  {
    lng: -43.7079082,
    lat: -22.5647325
  },
  {
    lng: -43.7078105,
    lat: -22.5648034
  },
  {
    lng: -43.7077271,
    lat: -22.5648332
  },
  {
    lng: -43.7076327,
    lat: -22.5648406
  },
  {
    lng: -43.7074155,
    lat: -22.5648277
  },
  {
    lng: -43.7072336,
    lat: -22.5647879
  },
  {
    lng: -43.7070536,
    lat: -22.5647186
  },
  {
    lng: -43.7068826,
    lat: -22.5646608
  },
  {
    lng: -43.706814,
    lat: -22.5646147
  },
  {
    lng: -43.7067239,
    lat: -22.5645282
  },
  {
    lng: -43.7066085,
    lat: -22.5643968
  },
  {
    lng: -43.7064886,
    lat: -22.5642464
  },
  {
    lng: -43.7064155,
    lat: -22.5641813
  },
  {
    lng: -43.7062827,
    lat: -22.5641213
  },
  {
    lng: -43.7061506,
    lat: -22.564092
  },
  {
    lng: -43.706084,
    lat: -22.5640898
  },
  {
    lng: -43.7059901,
    lat: -22.5640926
  },
  {
    lng: -43.7059624,
    lat: -22.564105
  },
  {
    lng: -43.7059265,
    lat: -22.5641198
  },
  {
    lng: -43.7057548,
    lat: -22.5641548
  },
  {
    lng: -43.7055869,
    lat: -22.5641511
  },
  {
    lng: -43.7054431,
    lat: -22.5641087
  },
  {
    lng: -43.7052432,
    lat: -22.5640748
  },
  {
    lng: -43.7051383,
    lat: -22.564056
  },
  {
    lng: -43.7051036,
    lat: -22.5640331
  },
  {
    lng: -43.7050858,
    lat: -22.5640027
  },
  {
    lng: -43.7050533,
    lat: -22.5639738
  },
  {
    lng: -43.7050259,
    lat: -22.5639786
  },
  {
    lng: -43.7049955,
    lat: -22.563986
  },
  {
    lng: -43.7049373,
    lat: -22.5639893
  },
  {
    lng: -43.7048819,
    lat: -22.5639899
  },
  {
    lng: -43.7047879,
    lat: -22.5640009
  },
  {
    lng: -43.7047355,
    lat: -22.5639903
  },
  {
    lng: -43.7046317,
    lat: -22.5639127
  },
  {
    lng: -43.7045651,
    lat: -22.5638368
  },
  {
    lng: -43.7045768,
    lat: -22.5638115
  },
  {
    lng: -43.7046321,
    lat: -22.5637241
  },
  {
    lng: -43.7046541,
    lat: -22.5636345
  },
  {
    lng: -43.7046291,
    lat: -22.5634857
  },
  {
    lng: -43.7046178,
    lat: -22.563466
  },
  {
    lng: -43.7045785,
    lat: -22.5634545
  },
  {
    lng: -43.7045341,
    lat: -22.5634537
  },
  {
    lng: -43.7044882,
    lat: -22.5634461
  },
  {
    lng: -43.7044671,
    lat: -22.5634314
  },
  {
    lng: -43.7043108,
    lat: -22.5632919
  },
  {
    lng: -43.7042374,
    lat: -22.5632352
  },
  {
    lng: -43.7041568,
    lat: -22.5631434
  },
  {
    lng: -43.7040948,
    lat: -22.5630799
  },
  {
    lng: -43.7040467,
    lat: -22.563002
  },
  {
    lng: -43.7040256,
    lat: -22.5629574
  },
  {
    lng: -43.7039805,
    lat: -22.5628638
  },
  {
    lng: -43.7039359,
    lat: -22.5627865
  },
  {
    lng: -43.7038912,
    lat: -22.5627093
  },
  {
    lng: -43.7038303,
    lat: -22.5626304
  },
  {
    lng: -43.7037305,
    lat: -22.5625116
  },
  {
    lng: -43.7036418,
    lat: -22.5624199
  },
  {
    lng: -43.7035529,
    lat: -22.5623283
  },
  {
    lng: -43.7034691,
    lat: -22.5622064
  },
  {
    lng: -43.7033643,
    lat: -22.5620726
  },
  {
    lng: -43.7032382,
    lat: -22.5619414
  },
  {
    lng: -43.7031325,
    lat: -22.5618533
  },
  {
    lng: -43.7029976,
    lat: -22.561791
  },
  {
    lng: -43.7029186,
    lat: -22.5617523
  },
  {
    lng: -43.7028064,
    lat: -22.5617266
  },
  {
    lng: -43.7026643,
    lat: -22.5617082
  },
  {
    lng: -43.7025214,
    lat: -22.5617039
  },
  {
    lng: -43.7023484,
    lat: -22.5616862
  },
  {
    lng: -43.7022254,
    lat: -22.5616441
  },
  {
    lng: -43.7021026,
    lat: -22.561602
  },
  {
    lng: -43.7018244,
    lat: -22.5614476
  },
  {
    lng: -43.7017354,
    lat: -22.5613981
  },
  {
    lng: -43.7014995,
    lat: -22.5612629
  },
  {
    lng: -43.701444,
    lat: -22.5612371
  },
  {
    lng: -43.7013111,
    lat: -22.5611755
  },
  {
    lng: -43.7012597,
    lat: -22.5611565
  },
  {
    lng: -43.7012082,
    lat: -22.5611376
  },
  {
    lng: -43.701184,
    lat: -22.5611332
  },
  {
    lng: -43.7011485,
    lat: -22.5611267
  },
  {
    lng: -43.701128,
    lat: -22.5611231
  },
  {
    lng: -43.7010457,
    lat: -22.5611221
  },
  {
    lng: -43.7009972,
    lat: -22.5611219
  },
  {
    lng: -43.700986,
    lat: -22.5611219
  },
  {
    lng: -43.7009414,
    lat: -22.5611231
  },
  {
    lng: -43.70091,
    lat: -22.5611259
  },
  {
    lng: -43.7008149,
    lat: -22.5611494
  },
  {
    lng: -43.7007585,
    lat: -22.5611634
  },
  {
    lng: -43.7007024,
    lat: -22.5611773
  },
  {
    lng: -43.7006289,
    lat: -22.5611985
  },
  {
    lng: -43.7005786,
    lat: -22.5612129
  },
  {
    lng: -43.7004897,
    lat: -22.5612412
  },
  {
    lng: -43.7004201,
    lat: -22.5612561
  },
  {
    lng: -43.7003116,
    lat: -22.5612534
  },
  {
    lng: -43.7002377,
    lat: -22.5612465
  },
  {
    lng: -43.7001663,
    lat: -22.5612291
  },
  {
    lng: -43.700079,
    lat: -22.5611876
  },
  {
    lng: -43.6999996,
    lat: -22.5611302
  },
  {
    lng: -43.6997927,
    lat: -22.5609636
  },
  {
    lng: -43.6995302,
    lat: -22.5607285
  },
  {
    lng: -43.6994816,
    lat: -22.5606902
  },
  {
    lng: -43.6992437,
    lat: -22.5605024
  },
  {
    lng: -43.6989508,
    lat: -22.5602871
  },
  {
    lng: -43.698901,
    lat: -22.5602565
  },
  {
    lng: -43.6988577,
    lat: -22.5602067
  },
  {
    lng: -43.6988527,
    lat: -22.5601705
  },
  {
    lng: -43.69885,
    lat: -22.560147
  },
  {
    lng: -43.6988329,
    lat: -22.5601159
  },
  {
    lng: -43.6988089,
    lat: -22.5600871
  },
  {
    lng: -43.6987762,
    lat: -22.560054
  },
  {
    lng: -43.6987172,
    lat: -22.5600229
  },
  {
    lng: -43.6985483,
    lat: -22.5599257
  },
  {
    lng: -43.6984992,
    lat: -22.5598574
  },
  {
    lng: -43.6984664,
    lat: -22.5598253
  },
  {
    lng: -43.6984319,
    lat: -22.5598051
  },
  {
    lng: -43.6983956,
    lat: -22.559804
  },
  {
    lng: -43.6983668,
    lat: -22.5597995
  },
  {
    lng: -43.6983339,
    lat: -22.5597891
  },
  {
    lng: -43.6983111,
    lat: -22.5597797
  },
  {
    lng: -43.6982618,
    lat: -22.5597582
  },
  {
    lng: -43.6982115,
    lat: -22.5597197
  },
  {
    lng: -43.6979586,
    lat: -22.5594907
  },
  {
    lng: -43.6977829,
    lat: -22.5593466
  },
  {
    lng: -43.6977455,
    lat: -22.5593146
  },
  {
    lng: -43.6977138,
    lat: -22.5592892
  },
  {
    lng: -43.6976659,
    lat: -22.5592771
  },
  {
    lng: -43.697638,
    lat: -22.5592707
  },
  {
    lng: -43.6975799,
    lat: -22.5592674
  },
  {
    lng: -43.6973283,
    lat: -22.5592305
  },
  {
    lng: -43.6970934,
    lat: -22.5592035
  },
  {
    lng: -43.6970269,
    lat: -22.5591932
  },
  {
    lng: -43.6969685,
    lat: -22.5591765
  },
  {
    lng: -43.6969418,
    lat: -22.5591556
  },
  {
    lng: -43.6969279,
    lat: -22.5591345
  },
  {
    lng: -43.6969259,
    lat: -22.5591067
  },
  {
    lng: -43.696927,
    lat: -22.5590783
  },
  {
    lng: -43.6969265,
    lat: -22.5590412
  },
  {
    lng: -43.69691,
    lat: -22.5590031
  },
  {
    lng: -43.6968766,
    lat: -22.5589522
  },
  {
    lng: -43.6968484,
    lat: -22.5589093
  },
  {
    lng: -43.6967537,
    lat: -22.5588664
  },
  {
    lng: -43.6966551,
    lat: -22.5588641
  },
  {
    lng: -43.6965836,
    lat: -22.5588781
  },
  {
    lng: -43.6965423,
    lat: -22.5588938
  },
  {
    lng: -43.6964619,
    lat: -22.5589287
  },
  {
    lng: -43.6964198,
    lat: -22.5589335
  },
  {
    lng: -43.6962591,
    lat: -22.558935
  },
  {
    lng: -43.696142,
    lat: -22.5589064
  },
  {
    lng: -43.696094,
    lat: -22.5588885
  },
  {
    lng: -43.6960462,
    lat: -22.5588705
  },
  {
    lng: -43.6959578,
    lat: -22.5588311
  },
  {
    lng: -43.6959197,
    lat: -22.5587907
  },
  {
    lng: -43.6958913,
    lat: -22.5587468
  },
  {
    lng: -43.6958742,
    lat: -22.5587247
  },
  {
    lng: -43.6958428,
    lat: -22.5586832
  },
  {
    lng: -43.6958112,
    lat: -22.5586501
  },
  {
    lng: -43.6957695,
    lat: -22.5586246
  },
  {
    lng: -43.6956988,
    lat: -22.5585999
  },
  {
    lng: -43.6955952,
    lat: -22.5585676
  },
  {
    lng: -43.6954629,
    lat: -22.5585444
  },
  {
    lng: -43.6953836,
    lat: -22.5585248
  },
  {
    lng: -43.6952491,
    lat: -22.5584722
  },
  {
    lng: -43.6951631,
    lat: -22.5583843
  },
  {
    lng: -43.695133,
    lat: -22.5582841
  },
  {
    lng: -43.6950882,
    lat: -22.5581372
  },
  {
    lng: -43.6950843,
    lat: -22.5580719
  },
  {
    lng: -43.6950905,
    lat: -22.5579907
  },
  {
    lng: -43.6951572,
    lat: -22.5578512
  },
  {
    lng: -43.6952792,
    lat: -22.5576527
  },
  {
    lng: -43.6954023,
    lat: -22.5574627
  },
  {
    lng: -43.6955135,
    lat: -22.5573006
  },
  {
    lng: -43.6955651,
    lat: -22.5571995
  },
  {
    lng: -43.6955878,
    lat: -22.5571106
  },
  {
    lng: -43.695595,
    lat: -22.5570454
  },
  {
    lng: -43.6955861,
    lat: -22.55698
  },
  {
    lng: -43.6955572,
    lat: -22.5569212
  },
  {
    lng: -43.6954967,
    lat: -22.5568377
  },
  {
    lng: -43.6954128,
    lat: -22.5567628
  },
  {
    lng: -43.6953101,
    lat: -22.5566683
  },
  {
    lng: -43.6950401,
    lat: -22.5564085
  },
  {
    lng: -43.6947675,
    lat: -22.5561625
  },
  {
    lng: -43.6943822,
    lat: -22.5558057
  },
  {
    lng: -43.6939677,
    lat: -22.5554912
  },
  {
    lng: -43.6938529,
    lat: -22.5553845
  },
  {
    lng: -43.6938118,
    lat: -22.5553087
  },
  {
    lng: -43.6937983,
    lat: -22.5552523
  },
  {
    lng: -43.6937867,
    lat: -22.5552134
  },
  {
    lng: -43.6937754,
    lat: -22.5551737
  },
  {
    lng: -43.6937642,
    lat: -22.555134
  },
  {
    lng: -43.6937391,
    lat: -22.5550744
  },
  {
    lng: -43.6937064,
    lat: -22.555025
  },
  {
    lng: -43.6936631,
    lat: -22.5549809
  },
  {
    lng: -43.6936506,
    lat: -22.5549682
  },
  {
    lng: -43.693638,
    lat: -22.5549554
  },
  {
    lng: -43.6936131,
    lat: -22.5549299
  },
  {
    lng: -43.693576,
    lat: -22.5548996
  },
  {
    lng: -43.6935394,
    lat: -22.5548819
  },
  {
    lng: -43.6935029,
    lat: -22.5548643
  },
  {
    lng: -43.6934749,
    lat: -22.5548591
  },
  {
    lng: -43.6934626,
    lat: -22.5548567
  },
  {
    lng: -43.6933943,
    lat: -22.5548482
  },
  {
    lng: -43.6932796,
    lat: -22.5548478
  },
  {
    lng: -43.6932226,
    lat: -22.554857
  },
  {
    lng: -43.6931657,
    lat: -22.5548662
  },
  {
    lng: -43.6931416,
    lat: -22.5548803
  },
  {
    lng: -43.6931048,
    lat: -22.5549019
  },
  {
    lng: -43.6930396,
    lat: -22.5549355
  },
  {
    lng: -43.6929837,
    lat: -22.5549756
  },
  {
    lng: -43.6929285,
    lat: -22.5550091
  },
  {
    lng: -43.6928629,
    lat: -22.5550358
  },
  {
    lng: -43.6928,
    lat: -22.555049
  },
  {
    lng: -43.6927548,
    lat: -22.5550535
  },
  {
    lng: -43.6927096,
    lat: -22.5550568
  },
  {
    lng: -43.6926449,
    lat: -22.5550533
  },
  {
    lng: -43.6925998,
    lat: -22.5550388
  },
  {
    lng: -43.6925546,
    lat: -22.5550083
  },
  {
    lng: -43.6925105,
    lat: -22.5549855
  },
  {
    lng: -43.692475,
    lat: -22.5549646
  },
  {
    lng: -43.6924307,
    lat: -22.5549493
  },
  {
    lng: -43.6923536,
    lat: -22.5549338
  },
  {
    lng: -43.692241,
    lat: -22.5548667
  },
  {
    lng: -43.6922037,
    lat: -22.5548151
  },
  {
    lng: -43.6921982,
    lat: -22.5548075
  },
  {
    lng: -43.6921795,
    lat: -22.5547817
  },
  {
    lng: -43.6921511,
    lat: -22.5547434
  },
  {
    lng: -43.6921259,
    lat: -22.5547186
  },
  {
    lng: -43.6921011,
    lat: -22.5546953
  },
  {
    lng: -43.6920785,
    lat: -22.5546725
  },
  {
    lng: -43.6920569,
    lat: -22.5546517
  },
  {
    lng: -43.6920369,
    lat: -22.5546347
  },
  {
    lng: -43.6920096,
    lat: -22.5546258
  },
  {
    lng: -43.6919909,
    lat: -22.5546187
  },
  {
    lng: -43.6919628,
    lat: -22.5546188
  },
  {
    lng: -43.6919143,
    lat: -22.5546219
  },
  {
    lng: -43.6918607,
    lat: -22.5546349
  },
  {
    lng: -43.6918104,
    lat: -22.5546412
  },
  {
    lng: -43.6917643,
    lat: -22.5546432
  },
  {
    lng: -43.6917133,
    lat: -22.5546417
  },
  {
    lng: -43.6916679,
    lat: -22.5546347
  },
  {
    lng: -43.691606,
    lat: -22.5546147
  },
  {
    lng: -43.6915364,
    lat: -22.554589
  },
  {
    lng: -43.6914684,
    lat: -22.5545489
  },
  {
    lng: -43.6914222,
    lat: -22.5545242
  },
  {
    lng: -43.6913862,
    lat: -22.5545044
  },
  {
    lng: -43.6913502,
    lat: -22.5544846
  },
  {
    lng: -43.6913179,
    lat: -22.5544511
  },
  {
    lng: -43.6913078,
    lat: -22.5544277
  },
  {
    lng: -43.6913013,
    lat: -22.5544097
  },
  {
    lng: -43.6912868,
    lat: -22.5543843
  },
  {
    lng: -43.6912744,
    lat: -22.5543549
  },
  {
    lng: -43.6912539,
    lat: -22.554315
  },
  {
    lng: -43.6912329,
    lat: -22.5542855
  },
  {
    lng: -43.6912023,
    lat: -22.5542501
  },
  {
    lng: -43.6911662,
    lat: -22.5542153
  },
  {
    lng: -43.6911349,
    lat: -22.5541811
  },
  {
    lng: -43.6911051,
    lat: -22.5541417
  },
  {
    lng: -43.6910872,
    lat: -22.5541012
  },
  {
    lng: -43.691086,
    lat: -22.5540984
  },
  {
    lng: -43.6910698,
    lat: -22.5540522
  },
  {
    lng: -43.691059,
    lat: -22.5540072
  },
  {
    lng: -43.6910543,
    lat: -22.5539618
  },
  {
    lng: -43.6910503,
    lat: -22.5539165
  },
  {
    lng: -43.691044,
    lat: -22.5538705
  },
  {
    lng: -43.6910375,
    lat: -22.5538233
  },
  {
    lng: -43.6910311,
    lat: -22.5537738
  },
  {
    lng: -43.6910283,
    lat: -22.5537267
  },
  {
    lng: -43.6910245,
    lat: -22.5536796
  },
  {
    lng: -43.6910188,
    lat: -22.5536342
  },
  {
    lng: -43.6910101,
    lat: -22.5535874
  },
  {
    lng: -43.6910043,
    lat: -22.5535655
  },
  {
    lng: -43.6909983,
    lat: -22.5535436
  },
  {
    lng: -43.6910364,
    lat: -22.5535147
  },
  {
    lng: -43.6910822,
    lat: -22.5534932
  },
  {
    lng: -43.6911238,
    lat: -22.5534623
  },
  {
    lng: -43.6911645,
    lat: -22.5534315
  },
  {
    lng: -43.6912054,
    lat: -22.5534018
  },
  {
    lng: -43.6912378,
    lat: -22.553365
  },
  {
    lng: -43.6912705,
    lat: -22.5533307
  },
  {
    lng: -43.6913023,
    lat: -22.5532955
  },
  {
    lng: -43.6913335,
    lat: -22.5532601
  },
  {
    lng: -43.6913453,
    lat: -22.5532153
  },
  {
    lng: -43.6913458,
    lat: -22.5531702
  },
  {
    lng: -43.6912687,
    lat: -22.5530364
  },
  {
    lng: -43.6910873,
    lat: -22.5528698
  },
  {
    lng: -43.6909262,
    lat: -22.5527128
  },
  {
    lng: -43.6907957,
    lat: -22.5525373
  },
  {
    lng: -43.6907256,
    lat: -22.5524328
  },
  {
    lng: -43.6907047,
    lat: -22.552392
  },
  {
    lng: -43.6907127,
    lat: -22.5523432
  },
  {
    lng: -43.6907256,
    lat: -22.5522992
  },
  {
    lng: -43.6907685,
    lat: -22.5522439
  },
  {
    lng: -43.6907979,
    lat: -22.5522078
  },
  {
    lng: -43.6908183,
    lat: -22.5521907
  },
  {
    lng: -43.6908812,
    lat: -22.5521217
  },
  {
    lng: -43.6909444,
    lat: -22.5520528
  },
  {
    lng: -43.6909798,
    lat: -22.5519749
  },
  {
    lng: -43.6910245,
    lat: -22.5519156
  },
  {
    lng: -43.6910565,
    lat: -22.5518617
  },
  {
    lng: -43.6910804,
    lat: -22.5518022
  },
  {
    lng: -43.6910922,
    lat: -22.5517409
  },
  {
    lng: -43.6910896,
    lat: -22.551694
  },
  {
    lng: -43.6910594,
    lat: -22.5516419
  },
  {
    lng: -43.690989,
    lat: -22.5515891
  },
  {
    lng: -43.6909369,
    lat: -22.551561
  },
  {
    lng: -43.6908717,
    lat: -22.5515373
  },
  {
    lng: -43.6908031,
    lat: -22.5515325
  },
  {
    lng: -43.6907194,
    lat: -22.5515334
  },
  {
    lng: -43.6906399,
    lat: -22.5515463
  },
  {
    lng: -43.6905765,
    lat: -22.5515609
  },
  {
    lng: -43.6903947,
    lat: -22.5516194
  },
  {
    lng: -43.6902833,
    lat: -22.5516643
  },
  {
    lng: -43.690172,
    lat: -22.551698
  },
  {
    lng: -43.6900975,
    lat: -22.5516989
  },
  {
    lng: -43.6899991,
    lat: -22.5517058
  },
  {
    lng: -43.6899613,
    lat: -22.5517116
  },
  {
    lng: -43.6899234,
    lat: -22.5517174
  },
  {
    lng: -43.6898922,
    lat: -22.5517297
  },
  {
    lng: -43.6898613,
    lat: -22.5517421
  },
  {
    lng: -43.6898115,
    lat: -22.5517518
  },
  {
    lng: -43.6897672,
    lat: -22.5517572
  },
  {
    lng: -43.6896441,
    lat: -22.5517622
  },
  {
    lng: -43.6895153,
    lat: -22.5517655
  },
  {
    lng: -43.6893872,
    lat: -22.5517496
  },
  {
    lng: -43.6892976,
    lat: -22.5517219
  },
  {
    lng: -43.6892491,
    lat: -22.5517005
  },
  {
    lng: -43.6891946,
    lat: -22.5516869
  },
  {
    lng: -43.6891539,
    lat: -22.5516729
  },
  {
    lng: -43.6891261,
    lat: -22.5516663
  },
  {
    lng: -43.6891093,
    lat: -22.5516652
  },
  {
    lng: -43.6890656,
    lat: -22.5516576
  },
  {
    lng: -43.6890026,
    lat: -22.5516534
  },
  {
    lng: -43.688918,
    lat: -22.5516616
  },
  {
    lng: -43.6888597,
    lat: -22.5516676
  },
  {
    lng: -43.6887947,
    lat: -22.5516596
  },
  {
    lng: -43.6887526,
    lat: -22.5516445
  },
  {
    lng: -43.6887105,
    lat: -22.5516214
  },
  {
    lng: -43.6886792,
    lat: -22.5516065
  },
  {
    lng: -43.68865,
    lat: -22.5515911
  },
  {
    lng: -43.6885912,
    lat: -22.551575
  },
  {
    lng: -43.6885405,
    lat: -22.5515693
  },
  {
    lng: -43.6884938,
    lat: -22.5515783
  },
  {
    lng: -43.6883736,
    lat: -22.5515991
  },
  {
    lng: -43.6883317,
    lat: -22.5516159
  },
  {
    lng: -43.6882881,
    lat: -22.5516291
  },
  {
    lng: -43.6882412,
    lat: -22.5516354
  },
  {
    lng: -43.6881979,
    lat: -22.551635
  },
  {
    lng: -43.6881529,
    lat: -22.5516345
  },
  {
    lng: -43.6880731,
    lat: -22.5516474
  },
  {
    lng: -43.6880064,
    lat: -22.5516535
  },
  {
    lng: -43.6879468,
    lat: -22.5516563
  },
  {
    lng: -43.6879153,
    lat: -22.551656
  },
  {
    lng: -43.6878838,
    lat: -22.5516557
  },
  {
    lng: -43.6878279,
    lat: -22.5516516
  },
  {
    lng: -43.6877325,
    lat: -22.5516506
  },
  {
    lng: -43.6876706,
    lat: -22.5516414
  },
  {
    lng: -43.6876086,
    lat: -22.5516322
  },
  {
    lng: -43.6874766,
    lat: -22.5515897
  },
  {
    lng: -43.6874374,
    lat: -22.5515754
  },
  {
    lng: -43.6874018,
    lat: -22.5515613
  },
  {
    lng: -43.6873714,
    lat: -22.5515473
  },
  {
    lng: -43.687341,
    lat: -22.5515435
  },
  {
    lng: -43.6872994,
    lat: -22.5515499
  },
  {
    lng: -43.6872823,
    lat: -22.5515738
  },
  {
    lng: -43.6872616,
    lat: -22.551601
  },
  {
    lng: -43.6872336,
    lat: -22.551635
  },
  {
    lng: -43.6872111,
    lat: -22.5516623
  },
  {
    lng: -43.6871684,
    lat: -22.5517064
  },
  {
    lng: -43.6871493,
    lat: -22.5517371
  },
  {
    lng: -43.6871224,
    lat: -22.5517952
  },
  {
    lng: -43.6871043,
    lat: -22.5518534
  },
  {
    lng: -43.6870915,
    lat: -22.5519186
  },
  {
    lng: -43.6870761,
    lat: -22.5520078
  },
  {
    lng: -43.6870716,
    lat: -22.5520936
  },
  {
    lng: -43.6870532,
    lat: -22.5522207
  },
  {
    lng: -43.6870482,
    lat: -22.5522688
  },
  {
    lng: -43.6870575,
    lat: -22.552317
  },
  {
    lng: -43.68707,
    lat: -22.552379
  },
  {
    lng: -43.6870834,
    lat: -22.5524136
  },
  {
    lng: -43.6871007,
    lat: -22.5524413
  },
  {
    lng: -43.6871545,
    lat: -22.5525107
  },
  {
    lng: -43.6871662,
    lat: -22.5525417
  },
  {
    lng: -43.6871725,
    lat: -22.5525693
  },
  {
    lng: -43.6871792,
    lat: -22.5525866
  },
  {
    lng: -43.6871854,
    lat: -22.5525936
  },
  {
    lng: -43.6871914,
    lat: -22.5526005
  },
  {
    lng: -43.6872092,
    lat: -22.552611
  },
  {
    lng: -43.6872285,
    lat: -22.552625
  },
  {
    lng: -43.6872391,
    lat: -22.5526354
  },
  {
    lng: -43.6872502,
    lat: -22.5526872
  },
  {
    lng: -43.6872501,
    lat: -22.5526906
  },
  {
    lng: -43.6872499,
    lat: -22.5526941
  },
  {
    lng: -43.6872497,
    lat: -22.5527009
  },
  {
    lng: -43.6872479,
    lat: -22.5527045
  },
  {
    lng: -43.6872477,
    lat: -22.5527078
  },
  {
    lng: -43.6872457,
    lat: -22.5527147
  },
  {
    lng: -43.6872455,
    lat: -22.5527181
  },
  {
    lng: -43.6872437,
    lat: -22.5527215
  },
  {
    lng: -43.6872417,
    lat: -22.5527284
  },
  {
    lng: -43.6872416,
    lat: -22.5527301
  },
  {
    lng: -43.6872416,
    lat: -22.5527319
  },
  {
    lng: -43.6872396,
    lat: -22.5527353
  },
  {
    lng: -43.6872378,
    lat: -22.5527387
  },
  {
    lng: -43.6872357,
    lat: -22.5527459
  },
  {
    lng: -43.6872357,
    lat: -22.552749
  },
  {
    lng: -43.6872335,
    lat: -22.5527559
  },
  {
    lng: -43.6872299,
    lat: -22.5527593
  },
  {
    lng: -43.6872279,
    lat: -22.5527661
  },
  {
    lng: -43.6872259,
    lat: -22.5527696
  },
  {
    lng: -43.687224,
    lat: -22.552773
  },
  {
    lng: -43.6871777,
    lat: -22.5528371
  },
  {
    lng: -43.6871424,
    lat: -22.5528677
  },
  {
    lng: -43.6870893,
    lat: -22.5528947
  },
  {
    lng: -43.6870269,
    lat: -22.5529252
  },
  {
    lng: -43.6869412,
    lat: -22.5529519
  },
  {
    lng: -43.6868788,
    lat: -22.5529821
  },
  {
    lng: -43.6868324,
    lat: -22.5530196
  },
  {
    lng: -43.6867896,
    lat: -22.5530605
  },
  {
    lng: -43.6866568,
    lat: -22.553204
  },
  {
    lng: -43.686574,
    lat: -22.5533134
  },
  {
    lng: -43.6864915,
    lat: -22.5534489
  },
  {
    lng: -43.6864735,
    lat: -22.5534812
  },
  {
    lng: -43.6864301,
    lat: -22.5535588
  },
  {
    lng: -43.68634,
    lat: -22.5537127
  },
  {
    lng: -43.6863306,
    lat: -22.5537304
  },
  {
    lng: -43.6862779,
    lat: -22.5538389
  },
  {
    lng: -43.6862453,
    lat: -22.5538939
  },
  {
    lng: -43.6862129,
    lat: -22.5539488
  },
  {
    lng: -43.6861412,
    lat: -22.554038
  },
  {
    lng: -43.6860738,
    lat: -22.5541065
  },
  {
    lng: -43.6859144,
    lat: -22.5542225
  },
  {
    lng: -43.6857837,
    lat: -22.5542627
  },
  {
    lng: -43.6857505,
    lat: -22.5542615
  },
  {
    lng: -43.6857056,
    lat: -22.5542598
  },
  {
    lng: -43.6856725,
    lat: -22.5542586
  },
  {
    lng: -43.6856363,
    lat: -22.5542629
  },
  {
    lng: -43.6855941,
    lat: -22.5542614
  },
  {
    lng: -43.6855403,
    lat: -22.5542538
  },
  {
    lng: -43.685489,
    lat: -22.5542576
  },
  {
    lng: -43.6854349,
    lat: -22.5542556
  },
  {
    lng: -43.6853807,
    lat: -22.5542537
  },
  {
    lng: -43.6853239,
    lat: -22.554246
  },
  {
    lng: -43.6852846,
    lat: -22.5542502
  },
  {
    lng: -43.6852423,
    lat: -22.5542599
  },
  {
    lng: -43.6851598,
    lat: -22.5543019
  },
  {
    lng: -43.6850985,
    lat: -22.5543447
  },
  {
    lng: -43.6849985,
    lat: -22.5544255
  },
  {
    lng: -43.6849507,
    lat: -22.5544632
  },
  {
    lng: -43.6849002,
    lat: -22.5544951
  },
  {
    lng: -43.6848114,
    lat: -22.5545482
  },
  {
    lng: -43.6846681,
    lat: -22.5546189
  },
  {
    lng: -43.6845973,
    lat: -22.5546501
  },
  {
    lng: -43.6845737,
    lat: -22.5546605
  },
  {
    lng: -43.6844983,
    lat: -22.5546888
  },
  {
    lng: -43.6844232,
    lat: -22.554717
  },
  {
    lng: -43.6841439,
    lat: -22.5548314
  },
  {
    lng: -43.6840916,
    lat: -22.5548457
  },
  {
    lng: -43.6838914,
    lat: -22.5548385
  },
  {
    lng: -43.6838325,
    lat: -22.5548504
  },
  {
    lng: -43.68368,
    lat: -22.5549181
  },
  {
    lng: -43.6836158,
    lat: -22.554958
  },
  {
    lng: -43.6834428,
    lat: -22.5550643
  },
  {
    lng: -43.6834104,
    lat: -22.5550762
  },
  {
    lng: -43.6833479,
    lat: -22.5550992
  },
  {
    lng: -43.6833224,
    lat: -22.5551087
  },
  {
    lng: -43.6832969,
    lat: -22.5551181
  },
  {
    lng: -43.6832796,
    lat: -22.5551252
  },
  {
    lng: -43.6831157,
    lat: -22.5551931
  },
  {
    lng: -43.68302,
    lat: -22.5552235
  },
  {
    lng: -43.6829443,
    lat: -22.5552376
  },
  {
    lng: -43.6828006,
    lat: -22.5552606
  },
  {
    lng: -43.6825845,
    lat: -22.55527
  },
  {
    lng: -43.682275,
    lat: -22.5552982
  },
  {
    lng: -43.6820408,
    lat: -22.5553208
  },
  {
    lng: -43.6819124,
    lat: -22.5553331
  },
  {
    lng: -43.6818429,
    lat: -22.555339
  },
  {
    lng: -43.6817973,
    lat: -22.5553514
  },
  {
    lng: -43.6817547,
    lat: -22.5553668
  },
  {
    lng: -43.6817275,
    lat: -22.5554014
  },
  {
    lng: -43.6816799,
    lat: -22.555638
  },
  {
    lng: -43.6816929,
    lat: -22.5557026
  },
  {
    lng: -43.6817068,
    lat: -22.5557537
  },
  {
    lng: -43.6817173,
    lat: -22.5558104
  },
  {
    lng: -43.6817403,
    lat: -22.5558562
  },
  {
    lng: -43.6817515,
    lat: -22.5558848
  },
  {
    lng: -43.681766,
    lat: -22.5559134
  },
  {
    lng: -43.681774,
    lat: -22.5559475
  },
  {
    lng: -43.6817735,
    lat: -22.5559672
  },
  {
    lng: -43.681773,
    lat: -22.5559868
  },
  {
    lng: -43.6817601,
    lat: -22.5560258
  },
  {
    lng: -43.681629,
    lat: -22.556308
  },
  {
    lng: -43.6816032,
    lat: -22.5563803
  },
  {
    lng: -43.6815681,
    lat: -22.5564521
  },
  {
    lng: -43.6815425,
    lat: -22.5565131
  },
  {
    lng: -43.6815263,
    lat: -22.5565519
  },
  {
    lng: -43.6815192,
    lat: -22.5565966
  },
  {
    lng: -43.6815084,
    lat: -22.5566637
  },
  {
    lng: -43.6814978,
    lat: -22.5567196
  },
  {
    lng: -43.6814933,
    lat: -22.5567813
  },
  {
    lng: -43.6814915,
    lat: -22.5568487
  },
  {
    lng: -43.68149,
    lat: -22.5569105
  },
  {
    lng: -43.6814857,
    lat: -22.556961
  },
  {
    lng: -43.6814781,
    lat: -22.5570225
  },
  {
    lng: -43.681483,
    lat: -22.5570621
  },
  {
    lng: -43.6814939,
    lat: -22.5571074
  },
  {
    lng: -43.6815113,
    lat: -22.5571361
  },
  {
    lng: -43.6815347,
    lat: -22.5571594
  },
  {
    lng: -43.6815967,
    lat: -22.5572122
  },
  {
    lng: -43.681665,
    lat: -22.5572539
  },
  {
    lng: -43.6817555,
    lat: -22.557277
  },
  {
    lng: -43.6818655,
    lat: -22.5573236
  },
  {
    lng: -43.6819009,
    lat: -22.5573529
  },
  {
    lng: -43.6819892,
    lat: -22.5574937
  },
  {
    lng: -43.6820731,
    lat: -22.5576231
  },
  {
    lng: -43.6821059,
    lat: -22.5576692
  },
  {
    lng: -43.6821384,
    lat: -22.5577153
  },
  {
    lng: -43.6822248,
    lat: -22.557811
  },
  {
    lng: -43.6822502,
    lat: -22.5578231
  },
  {
    lng: -43.6823326,
    lat: -22.55784
  },
  {
    lng: -43.6824243,
    lat: -22.557846
  },
  {
    lng: -43.6824871,
    lat: -22.5578623
  },
  {
    lng: -43.6825419,
    lat: -22.5578979
  },
  {
    lng: -43.6825621,
    lat: -22.5579267
  },
  {
    lng: -43.6825862,
    lat: -22.5580427
  },
  {
    lng: -43.6826104,
    lat: -22.558153
  },
  {
    lng: -43.6826343,
    lat: -22.5582745
  },
  {
    lng: -43.6826536,
    lat: -22.5583958
  },
  {
    lng: -43.6826702,
    lat: -22.5585115
  },
  {
    lng: -43.6826922,
    lat: -22.558703
  },
  {
    lng: -43.6827103,
    lat: -22.5588158
  },
  {
    lng: -43.6827221,
    lat: -22.5589341
  },
  {
    lng: -43.6827131,
    lat: -22.5590487
  },
  {
    lng: -43.6827263,
    lat: -22.5591782
  },
  {
    lng: -43.6827514,
    lat: -22.5591959
  },
  {
    lng: -43.6828334,
    lat: -22.5592239
  },
  {
    lng: -43.68295,
    lat: -22.5592476
  },
  {
    lng: -43.6830802,
    lat: -22.5592745
  },
  {
    lng: -43.6831985,
    lat: -22.5592898
  },
  {
    lng: -43.6833189,
    lat: -22.5592855
  },
  {
    lng: -43.6835026,
    lat: -22.559275
  },
  {
    lng: -43.6836645,
    lat: -22.5592415
  },
  {
    lng: -43.683878,
    lat: -22.5591843
  },
  {
    lng: -43.6840233,
    lat: -22.5591472
  },
  {
    lng: -43.6842337,
    lat: -22.5590872
  },
  {
    lng: -43.6842697,
    lat: -22.5590913
  },
  {
    lng: -43.684283,
    lat: -22.5591029
  },
  {
    lng: -43.684348,
    lat: -22.5591949
  },
  {
    lng: -43.6844355,
    lat: -22.5593465
  },
  {
    lng: -43.6845747,
    lat: -22.5595893
  },
  {
    lng: -43.6847097,
    lat: -22.5598152
  },
  {
    lng: -43.6847955,
    lat: -22.5599693
  },
  {
    lng: -43.6848829,
    lat: -22.5601234
  },
  {
    lng: -43.6849938,
    lat: -22.5602924
  },
  {
    lng: -43.6850229,
    lat: -22.5603521
  },
  {
    lng: -43.685238,
    lat: -22.5607931
  },
  {
    lng: -43.6853101,
    lat: -22.560912
  },
  {
    lng: -43.6853729,
    lat: -22.5610157
  },
  {
    lng: -43.6854585,
    lat: -22.5611695
  },
  {
    lng: -43.6854994,
    lat: -22.5612101
  },
  {
    lng: -43.685555,
    lat: -22.5612622
  },
  {
    lng: -43.6856348,
    lat: -22.5613041
  },
  {
    lng: -43.6858315,
    lat: -22.5614488
  },
  {
    lng: -43.6859818,
    lat: -22.5615526
  },
  {
    lng: -43.6860586,
    lat: -22.5616473
  },
  {
    lng: -43.6861756,
    lat: -22.5618049
  },
  {
    lng: -43.6862721,
    lat: -22.561892
  },
  {
    lng: -43.6863899,
    lat: -22.5619629
  },
  {
    lng: -43.6864934,
    lat: -22.5620167
  },
  {
    lng: -43.6865682,
    lat: -22.5620399
  },
  {
    lng: -43.6867069,
    lat: -22.5620908
  },
  {
    lng: -43.686837,
    lat: -22.562151
  },
  {
    lng: -43.6869921,
    lat: -22.5621785
  },
  {
    lng: -43.6871904,
    lat: -22.5621572
  },
  {
    lng: -43.6872503,
    lat: -22.5621592
  },
  {
    lng: -43.6873587,
    lat: -22.5621349
  },
  {
    lng: -43.6874549,
    lat: -22.562127
  },
  {
    lng: -43.6875408,
    lat: -22.5621342
  },
  {
    lng: -43.6875653,
    lat: -22.5621363
  },
  {
    lng: -43.6877214,
    lat: -22.5622024
  },
  {
    lng: -43.6877368,
    lat: -22.5622091
  },
  {
    lng: -43.6877899,
    lat: -22.5622359
  },
  {
    lng: -43.6878431,
    lat: -22.5622628
  },
  {
    lng: -43.6879379,
    lat: -22.5622994
  },
  {
    lng: -43.6879965,
    lat: -22.5623152
  },
  {
    lng: -43.6880329,
    lat: -22.5623249
  },
  {
    lng: -43.6880956,
    lat: -22.562327
  },
  {
    lng: -43.6882507,
    lat: -22.5623489
  },
  {
    lng: -43.688307,
    lat: -22.5623675
  },
  {
    lng: -43.6883798,
    lat: -22.5624002
  },
  {
    lng: -43.6884142,
    lat: -22.5624436
  },
  {
    lng: -43.6884269,
    lat: -22.5624836
  },
  {
    lng: -43.6884203,
    lat: -22.5625124
  },
  {
    lng: -43.6883822,
    lat: -22.562583
  },
  {
    lng: -43.6883101,
    lat: -22.5626656
  },
  {
    lng: -43.6882924,
    lat: -22.5626861
  },
  {
    lng: -43.6882095,
    lat: -22.5627673
  },
  {
    lng: -43.688145,
    lat: -22.5628295
  },
  {
    lng: -43.6881098,
    lat: -22.5628745
  },
  {
    lng: -43.6880945,
    lat: -22.5629053
  },
  {
    lng: -43.6880668,
    lat: -22.5630385
  },
  {
    lng: -43.6880661,
    lat: -22.5631494
  },
  {
    lng: -43.688096,
    lat: -22.5632595
  },
  {
    lng: -43.6881015,
    lat: -22.5632764
  },
  {
    lng: -43.6881362,
    lat: -22.5633311
  },
  {
    lng: -43.6881792,
    lat: -22.5633792
  },
  {
    lng: -43.6882167,
    lat: -22.5634105
  },
  {
    lng: -43.6883451,
    lat: -22.563538
  },
  {
    lng: -43.688385,
    lat: -22.5635889
  },
  {
    lng: -43.6883957,
    lat: -22.5636486
  },
  {
    lng: -43.6883477,
    lat: -22.5637527
  },
  {
    lng: -43.6883133,
    lat: -22.5637726
  },
  {
    lng: -43.6882983,
    lat: -22.5637864
  },
  {
    lng: -43.6882793,
    lat: -22.5638161
  },
  {
    lng: -43.6882497,
    lat: -22.563926
  },
  {
    lng: -43.6882474,
    lat: -22.5639793
  },
  {
    lng: -43.6882296,
    lat: -22.5640985
  },
  {
    lng: -43.6882174,
    lat: -22.564146
  },
  {
    lng: -43.6882137,
    lat: -22.564208
  },
  {
    lng: -43.688217,
    lat: -22.5642336
  },
  {
    lng: -43.6882153,
    lat: -22.5642543
  },
  {
    lng: -43.6882134,
    lat: -22.5642868
  },
  {
    lng: -43.6882465,
    lat: -22.5644018
  },
  {
    lng: -43.6883598,
    lat: -22.5646733
  },
  {
    lng: -43.6884263,
    lat: -22.564768
  },
  {
    lng: -43.6884756,
    lat: -22.5648137
  },
  {
    lng: -43.6885167,
    lat: -22.5648343
  },
  {
    lng: -43.6885736,
    lat: -22.5648562
  },
  {
    lng: -43.6886172,
    lat: -22.5648673
  },
  {
    lng: -43.6888279,
    lat: -22.5649086
  },
  {
    lng: -43.6888802,
    lat: -22.564925
  },
  {
    lng: -43.6889216,
    lat: -22.5649459
  },
  {
    lng: -43.689017,
    lat: -22.5650283
  },
  {
    lng: -43.6891884,
    lat: -22.5652122
  },
  {
    lng: -43.6893373,
    lat: -22.5653156
  },
  {
    lng: -43.6894719,
    lat: -22.5654138
  },
  {
    lng: -43.6896564,
    lat: -22.5655291
  },
  {
    lng: -43.6898174,
    lat: -22.5656533
  },
  {
    lng: -43.6901101,
    lat: -22.5657758
  },
  {
    lng: -43.6902172,
    lat: -22.5658103
  },
  {
    lng: -43.6903062,
    lat: -22.565838
  },
  {
    lng: -43.6905488,
    lat: -22.5659417
  },
  {
    lng: -43.6906687,
    lat: -22.5660365
  },
  {
    lng: -43.6908022,
    lat: -22.5661796
  },
  {
    lng: -43.6911052,
    lat: -22.5665147
  },
  {
    lng: -43.6911917,
    lat: -22.5666233
  },
  {
    lng: -43.6912342,
    lat: -22.5667056
  },
  {
    lng: -43.6912469,
    lat: -22.5668074
  },
  {
    lng: -43.6912668,
    lat: -22.5671656
  },
  {
    lng: -43.6912651,
    lat: -22.5672247
  },
  {
    lng: -43.6912498,
    lat: -22.5675766
  },
  {
    lng: -43.6912582,
    lat: -22.5677581
  },
  {
    lng: -43.6912692,
    lat: -22.5679731
  },
  {
    lng: -43.6911218,
    lat: -22.5681435
  },
  {
    lng: -43.690988,
    lat: -22.5683346
  },
  {
    lng: -43.6908705,
    lat: -22.5684373
  },
  {
    lng: -43.6907787,
    lat: -22.5685324
  },
  {
    lng: -43.6906766,
    lat: -22.5686273
  },
  {
    lng: -43.6906289,
    lat: -22.5686721
  },
  {
    lng: -43.6906097,
    lat: -22.5687207
  },
  {
    lng: -43.6905961,
    lat: -22.5687605
  },
  {
    lng: -43.6905645,
    lat: -22.5688722
  },
  {
    lng: -43.6905436,
    lat: -22.5689388
  },
  {
    lng: -43.6905354,
    lat: -22.5689983
  },
  {
    lng: -43.6905316,
    lat: -22.5690755
  },
  {
    lng: -43.6905062,
    lat: -22.5692162
  },
  {
    lng: -43.6904932,
    lat: -22.5692446
  },
  {
    lng: -43.6904616,
    lat: -22.5692721
  },
  {
    lng: -43.690417,
    lat: -22.5692881
  },
  {
    lng: -43.6903613,
    lat: -22.5692924
  },
  {
    lng: -43.6903162,
    lat: -22.5692794
  },
  {
    lng: -43.690281,
    lat: -22.5692606
  },
  {
    lng: -43.6902621,
    lat: -22.56926
  },
  {
    lng: -43.6902462,
    lat: -22.5692739
  },
  {
    lng: -43.6902555,
    lat: -22.5693117
  },
  {
    lng: -43.6902795,
    lat: -22.5693671
  },
  {
    lng: -43.6902815,
    lat: -22.5694107
  },
  {
    lng: -43.6902315,
    lat: -22.5694522
  },
  {
    lng: -43.6901977,
    lat: -22.5694831
  },
  {
    lng: -43.6901689,
    lat: -22.5695284
  },
  {
    lng: -43.6901561,
    lat: -22.5695684
  },
  {
    lng: -43.69021,
    lat: -22.5696709
  },
  {
    lng: -43.6903348,
    lat: -22.569833
  },
  {
    lng: -43.6903646,
    lat: -22.5698794
  },
  {
    lng: -43.6903639,
    lat: -22.5698989
  },
  {
    lng: -43.6903632,
    lat: -22.5699185
  },
  {
    lng: -43.6903405,
    lat: -22.5699601
  },
  {
    lng: -43.6902537,
    lat: -22.5700272
  },
  {
    lng: -43.6900665,
    lat: -22.5702096
  },
  {
    lng: -43.6900286,
    lat: -22.5702502
  },
  {
    lng: -43.6899707,
    lat: -22.5702654
  },
  {
    lng: -43.6898976,
    lat: -22.5702685
  },
  {
    lng: -43.6896013,
    lat: -22.5701641
  },
  {
    lng: -43.6894046,
    lat: -22.5700569
  },
  {
    lng: -43.6891298,
    lat: -22.5699476
  },
  {
    lng: -43.6887212,
    lat: -22.5698336
  },
  {
    lng: -43.688619,
    lat: -22.5698253
  },
  {
    lng: -43.6883642,
    lat: -22.5698318
  },
  {
    lng: -43.6879466,
    lat: -22.5698521
  },
  {
    lng: -43.6877441,
    lat: -22.5698864
  },
  {
    lng: -43.6875143,
    lat: -22.5699717
  },
  {
    lng: -43.6875584,
    lat: -22.5700469
  },
  {
    lng: -43.6876242,
    lat: -22.5702432
  },
  {
    lng: -43.6878435,
    lat: -22.5706262
  },
  {
    lng: -43.6881793,
    lat: -22.5711659
  },
  {
    lng: -43.688358,
    lat: -22.5714697
  },
  {
    lng: -43.6885203,
    lat: -22.5717223
  },
  {
    lng: -43.6887061,
    lat: -22.5719923
  },
  {
    lng: -43.6888754,
    lat: -22.5722226
  },
  {
    lng: -43.6889616,
    lat: -22.5723828
  },
  {
    lng: -43.6891037,
    lat: -22.5726566
  },
  {
    lng: -43.6891812,
    lat: -22.5727766
  },
  {
    lng: -43.6891918,
    lat: -22.5727928
  },
  {
    lng: -43.6891931,
    lat: -22.5727949
  },
  {
    lng: -43.6891993,
    lat: -22.5729069
  },
  {
    lng: -43.689209,
    lat: -22.5731884
  },
  {
    lng: -43.6892001,
    lat: -22.5734691
  },
  {
    lng: -43.6891907,
    lat: -22.5735927
  },
  {
    lng: -43.6892025,
    lat: -22.5737545
  },
  {
    lng: -43.689187,
    lat: -22.5738693
  },
  {
    lng: -43.689173,
    lat: -22.5739278
  },
  {
    lng: -43.6891837,
    lat: -22.5739674
  },
  {
    lng: -43.689216,
    lat: -22.5740134
  },
  {
    lng: -43.6892596,
    lat: -22.5740651
  },
  {
    lng: -43.6892889,
    lat: -22.5741082
  },
  {
    lng: -43.6893321,
    lat: -22.5741684
  },
  {
    lng: -43.6893651,
    lat: -22.5741975
  },
  {
    lng: -43.6894157,
    lat: -22.5742186
  },
  {
    lng: -43.6894457,
    lat: -22.5742222
  },
  {
    lng: -43.6895066,
    lat: -22.5742212
  },
  {
    lng: -43.6895684,
    lat: -22.5741752
  },
  {
    lng: -43.6896034,
    lat: -22.5741257
  },
  {
    lng: -43.6896355,
    lat: -22.5740649
  },
  {
    lng: -43.6896497,
    lat: -22.5739951
  },
  {
    lng: -43.6896565,
    lat: -22.573883
  },
  {
    lng: -43.6896584,
    lat: -22.5738099
  },
  {
    lng: -43.6896843,
    lat: -22.5737601
  },
  {
    lng: -43.6897361,
    lat: -22.5737504
  },
  {
    lng: -43.6897688,
    lat: -22.5737682
  },
  {
    lng: -43.6898006,
    lat: -22.5738056
  },
  {
    lng: -43.6898934,
    lat: -22.5739572
  },
  {
    lng: -43.6899778,
    lat: -22.574159
  },
  {
    lng: -43.6899992,
    lat: -22.5742609
  },
  {
    lng: -43.6900142,
    lat: -22.5743651
  },
  {
    lng: -43.6900189,
    lat: -22.5744157
  },
  {
    lng: -43.6900358,
    lat: -22.5744499
  },
  {
    lng: -43.6900708,
    lat: -22.5744846
  },
  {
    lng: -43.6902321,
    lat: -22.5745481
  },
  {
    lng: -43.6904485,
    lat: -22.5745993
  },
  {
    lng: -43.6905626,
    lat: -22.5746307
  },
  {
    lng: -43.6906044,
    lat: -22.5746459
  },
  {
    lng: -43.6906488,
    lat: -22.5746655
  },
  {
    lng: -43.6906517,
    lat: -22.5746669
  },
  {
    lng: -43.6907349,
    lat: -22.5747198
  },
  {
    lng: -43.6908459,
    lat: -22.5748465
  },
  {
    lng: -43.690933,
    lat: -22.5749583
  },
  {
    lng: -43.6910205,
    lat: -22.5750787
  },
  {
    lng: -43.691072,
    lat: -22.5751643
  },
  {
    lng: -43.6910832,
    lat: -22.5752066
  },
  {
    lng: -43.6910985,
    lat: -22.5753949
  },
  {
    lng: -43.691128,
    lat: -22.5755051
  },
  {
    lng: -43.6911798,
    lat: -22.5755935
  },
  {
    lng: -43.6912231,
    lat: -22.575662
  },
  {
    lng: -43.6912787,
    lat: -22.5757141
  },
  {
    lng: -43.6913415,
    lat: -22.575741
  },
  {
    lng: -43.6915071,
    lat: -22.5757846
  },
  {
    lng: -43.6916916,
    lat: -22.5757891
  },
  {
    lng: -43.691132,
    lat: -22.5760081
  },
  {
    lng: -43.6888485,
    lat: -22.5769018
  },
  {
    lng: -43.6861864,
    lat: -22.5779695
  },
  {
    lng: -43.684713,
    lat: -22.5785203
  },
  {
    lng: -43.6712757,
    lat: -22.5837791
  },
  {
    lng: -43.6706161,
    lat: -22.5840373
  },
  {
    lng: -43.6688326,
    lat: -22.5847353
  },
  {
    lng: -43.6684154,
    lat: -22.5852961
  },
  {
    lng: -43.6673332,
    lat: -22.5867509
  },
  {
    lng: -43.666893,
    lat: -22.5864965
  },
  {
    lng: -43.6664056,
    lat: -22.5860861
  },
  {
    lng: -43.6661647,
    lat: -22.5858096
  },
  {
    lng: -43.6660901,
    lat: -22.585355
  },
  {
    lng: -43.666052,
    lat: -22.5851224
  },
  {
    lng: -43.6659456,
    lat: -22.584929
  },
  {
    lng: -43.6657574,
    lat: -22.5848186
  },
  {
    lng: -43.6653729,
    lat: -22.5847928
  },
  {
    lng: -43.664873,
    lat: -22.5846823
  },
  {
    lng: -43.6645566,
    lat: -22.5845536
  },
  {
    lng: -43.6638147,
    lat: -22.5845938
  },
  {
    lng: -43.6633355,
    lat: -22.5843819
  },
  {
    lng: -43.6627384,
    lat: -22.5842742
  },
  {
    lng: -43.6625161,
    lat: -22.5841842
  },
  {
    lng: -43.6621333,
    lat: -22.5837978
  },
  {
    lng: -43.6617582,
    lat: -22.5833951
  },
  {
    lng: -43.6615976,
    lat: -22.5829537
  },
  {
    lng: -43.6615867,
    lat: -22.5825948
  },
  {
    lng: -43.6618217,
    lat: -22.5820774
  },
  {
    lng: -43.6615486,
    lat: -22.5821026
  },
  {
    lng: -43.6611768,
    lat: -22.5820729
  },
  {
    lng: -43.6607051,
    lat: -22.5819893
  },
  {
    lng: -43.6603901,
    lat: -22.5820132
  },
  {
    lng: -43.6599731,
    lat: -22.5821958
  },
  {
    lng: -43.6596361,
    lat: -22.5819304
  },
  {
    lng: -43.6593922,
    lat: -22.5816618
  },
  {
    lng: -43.6593402,
    lat: -22.5816314
  },
  {
    lng: -43.6593376,
    lat: -22.5815958
  },
  {
    lng: -43.6592606,
    lat: -22.5815027
  },
  {
    lng: -43.659202,
    lat: -22.5812395
  },
  {
    lng: -43.6591865,
    lat: -22.5810864
  },
  {
    lng: -43.6591712,
    lat: -22.5809332
  },
  {
    lng: -43.6591347,
    lat: -22.5808155
  },
  {
    lng: -43.659081,
    lat: -22.5806421
  },
  {
    lng: -43.6590749,
    lat: -22.5804067
  },
  {
    lng: -43.6590854,
    lat: -22.5801949
  },
  {
    lng: -43.6592566,
    lat: -22.5799531
  },
  {
    lng: -43.6597497,
    lat: -22.5796512
  },
  {
    lng: -43.6599719,
    lat: -22.5793863
  },
  {
    lng: -43.6602177,
    lat: -22.5791141
  },
  {
    lng: -43.6604503,
    lat: -22.5787439
  },
  {
    lng: -43.6605543,
    lat: -22.5783593
  },
  {
    lng: -43.660656,
    lat: -22.5781873
  },
  {
    lng: -43.6609321,
    lat: -22.5777643
  },
  {
    lng: -43.6613634,
    lat: -22.5775685
  },
  {
    lng: -43.6615648,
    lat: -22.5774639
  },
  {
    lng: -43.6617671,
    lat: -22.5772529
  },
  {
    lng: -43.6616861,
    lat: -22.5767471
  },
  {
    lng: -43.661722,
    lat: -22.5766145
  },
  {
    lng: -43.66179,
    lat: -22.5763625
  },
  {
    lng: -43.6618494,
    lat: -22.5761503
  },
  {
    lng: -43.6619428,
    lat: -22.575569
  },
  {
    lng: -43.6616002,
    lat: -22.5752843
  },
  {
    lng: -43.6615223,
    lat: -22.5751972
  },
  {
    lng: -43.6614443,
    lat: -22.5751102
  },
  {
    lng: -43.6614258,
    lat: -22.5750404
  },
  {
    lng: -43.6614073,
    lat: -22.5749707
  },
  {
    lng: -43.6614055,
    lat: -22.5749702
  },
  {
    lng: -43.6614069,
    lat: -22.5749688
  },
  {
    lng: -43.6613703,
    lat: -22.5748312
  },
  {
    lng: -43.6613922,
    lat: -22.5745914
  },
  {
    lng: -43.6614522,
    lat: -22.5742994
  },
  {
    lng: -43.6614691,
    lat: -22.5740337
  },
  {
    lng: -43.6616869,
    lat: -22.5737165
  },
  {
    lng: -43.6619378,
    lat: -22.5731241
  },
  {
    lng: -43.6624128,
    lat: -22.5726459
  },
  {
    lng: -43.6628351,
    lat: -22.5723365
  },
  {
    lng: -43.6630105,
    lat: -22.5722063
  },
  {
    lng: -43.6633668,
    lat: -22.571942
  },
  {
    lng: -43.663931,
    lat: -22.5718865
  },
  {
    lng: -43.6642634,
    lat: -22.5721961
  },
  {
    lng: -43.6646213,
    lat: -22.5721992
  },
  {
    lng: -43.6651213,
    lat: -22.5719711
  },
  {
    lng: -43.6655391,
    lat: -22.571629
  },
  {
    lng: -43.6657372,
    lat: -22.5714669
  },
  {
    lng: -43.6658039,
    lat: -22.5714155
  },
  {
    lng: -43.6661475,
    lat: -22.5711502
  },
  {
    lng: -43.6664431,
    lat: -22.5707002
  },
  {
    lng: -43.6666645,
    lat: -22.5701746
  },
  {
    lng: -43.6669536,
    lat: -22.57011
  },
  {
    lng: -43.6672422,
    lat: -22.5701124
  },
  {
    lng: -43.6675544,
    lat: -22.5702453
  },
  {
    lng: -43.6677633,
    lat: -22.570317
  },
  {
    lng: -43.6680324,
    lat: -22.5699489
  },
  {
    lng: -43.6682757,
    lat: -22.5697856
  },
  {
    lng: -43.6687186,
    lat: -22.5693527
  },
  {
    lng: -43.6687897,
    lat: -22.5690946
  },
  {
    lng: -43.6691904,
    lat: -22.5690979
  },
  {
    lng: -43.6696177,
    lat: -22.5691796
  },
  {
    lng: -43.6698261,
    lat: -22.5694795
  },
  {
    lng: -43.6701382,
    lat: -22.5697615
  },
  {
    lng: -43.6709156,
    lat: -22.5703987
  },
  {
    lng: -43.6714131,
    lat: -22.5701442
  },
  {
    lng: -43.6718751,
    lat: -22.5697227
  },
  {
    lng: -43.6722016,
    lat: -22.569495
  },
  {
    lng: -43.6725264,
    lat: -22.5694623
  },
  {
    lng: -43.6727397,
    lat: -22.5695977
  },
  {
    lng: -43.6729986,
    lat: -22.5698407
  },
  {
    lng: -43.6736268,
    lat: -22.570127
  },
  {
    lng: -43.674295,
    lat: -22.5704187
  },
  {
    lng: -43.6745784,
    lat: -22.5704007
  },
  {
    lng: -43.674849,
    lat: -22.5700485
  },
  {
    lng: -43.6751188,
    lat: -22.5697672
  },
  {
    lng: -43.6753883,
    lat: -22.5695213
  },
  {
    lng: -43.6753717,
    lat: -22.5692553
  },
  {
    lng: -43.6751646,
    lat: -22.56897
  },
  {
    lng: -43.6750939,
    lat: -22.5683846
  },
  {
    lng: -43.6748901,
    lat: -22.5677272
  },
  {
    lng: -43.67478,
    lat: -22.5672832
  },
  {
    lng: -43.6742658,
    lat: -22.5671726
  },
  {
    lng: -43.673867,
    lat: -22.5669566
  },
  {
    lng: -43.673545,
    lat: -22.5667236
  },
  {
    lng: -43.673196,
    lat: -22.5666225
  },
  {
    lng: -43.6728423,
    lat: -22.5665597
  },
  {
    lng: -43.672587,
    lat: -22.5663284
  },
  {
    lng: -43.6721724,
    lat: -22.5659062
  },
  {
    lng: -43.6721681,
    lat: -22.5658163
  },
  {
    lng: -43.6721396,
    lat: -22.5655105
  },
  {
    lng: -43.6720642,
    lat: -22.5652188
  },
  {
    lng: -43.6720194,
    lat: -22.5649856
  },
  {
    lng: -43.6718949,
    lat: -22.5646279
  },
  {
    lng: -43.6719499,
    lat: -22.5644738
  },
  {
    lng: -43.672005,
    lat: -22.5643197
  },
  {
    lng: -43.6721358,
    lat: -22.5641214
  },
  {
    lng: -43.6722954,
    lat: -22.5639694
  },
  {
    lng: -43.6721402,
    lat: -22.5638226
  },
  {
    lng: -43.6716452,
    lat: -22.5637884
  },
  {
    lng: -43.6715066,
    lat: -22.5636976
  },
  {
    lng: -43.6710244,
    lat: -22.5636038
  },
  {
    lng: -43.6705911,
    lat: -22.5636206
  },
  {
    lng: -43.6701194,
    lat: -22.5636203
  },
  {
    lng: -43.6693401,
    lat: -22.5635102
  },
  {
    lng: -43.6690907,
    lat: -22.5632299
  },
  {
    lng: -43.6688627,
    lat: -22.562938
  },
  {
    lng: -43.6681015,
    lat: -22.5624568
  },
  {
    lng: -43.6676888,
    lat: -22.5622305
  },
  {
    lng: -43.6674341,
    lat: -22.5619192
  },
  {
    lng: -43.66721,
    lat: -22.5612528
  },
  {
    lng: -43.6670901,
    lat: -22.5611604
  },
  {
    lng: -43.66697,
    lat: -22.561068
  },
  {
    lng: -43.6667575,
    lat: -22.5608569
  },
  {
    lng: -43.6667428,
    lat: -22.560269
  },
  {
    lng: -43.6666668,
    lat: -22.5600832
  },
  {
    lng: -43.6671752,
    lat: -22.5596022
  },
  {
    lng: -43.6674054,
    lat: -22.5593271
  },
  {
    lng: -43.6673527,
    lat: -22.559232
  },
  {
    lng: -43.6672999,
    lat: -22.5591368
  },
  {
    lng: -43.6671215,
    lat: -22.5590289
  },
  {
    lng: -43.666882,
    lat: -22.5590225
  },
  {
    lng: -43.6666178,
    lat: -22.5590154
  },
  {
    lng: -43.6664103,
    lat: -22.5590098
  },
  {
    lng: -43.6660357,
    lat: -22.558897
  },
  {
    lng: -43.6658218,
    lat: -22.5588055
  },
  {
    lng: -43.6656785,
    lat: -22.5586398
  },
  {
    lng: -43.6655353,
    lat: -22.5584742
  },
  {
    lng: -43.6653978,
    lat: -22.5582537
  },
  {
    lng: -43.6651461,
    lat: -22.5580025
  },
  {
    lng: -43.6646158,
    lat: -22.5577508
  },
  {
    lng: -43.6643367,
    lat: -22.557604
  },
  {
    lng: -43.6642079,
    lat: -22.5571067
  },
  {
    lng: -43.6644013,
    lat: -22.5568425
  },
  {
    lng: -43.6645182,
    lat: -22.5565776
  },
  {
    lng: -43.6647312,
    lat: -22.5562604
  },
  {
    lng: -43.6647714,
    lat: -22.5560482
  },
  {
    lng: -43.6650793,
    lat: -22.5557848
  },
  {
    lng: -43.6649674,
    lat: -22.555518
  },
  {
    lng: -43.6646073,
    lat: -22.5552669
  },
  {
    lng: -43.6643121,
    lat: -22.5551137
  },
  {
    lng: -43.6639627,
    lat: -22.5548362
  },
  {
    lng: -43.6642513,
    lat: -22.5545905
  },
  {
    lng: -43.6648084,
    lat: -22.5544744
  },
  {
    lng: -43.6649419,
    lat: -22.5542063
  },
  {
    lng: -43.6650406,
    lat: -22.5540369
  },
  {
    lng: -43.6653454,
    lat: -22.5539369
  },
  {
    lng: -43.6659749,
    lat: -22.5539313
  },
  {
    lng: -43.6661306,
    lat: -22.5536136
  },
  {
    lng: -43.6662105,
    lat: -22.5532244
  },
  {
    lng: -43.6662135,
    lat: -22.5529231
  },
  {
    lng: -43.6659347,
    lat: -22.5526456
  },
  {
    lng: -43.6657403,
    lat: -22.5525548
  },
  {
    lng: -43.6655457,
    lat: -22.5524639
  },
  {
    lng: -43.6654301,
    lat: -22.5521962
  },
  {
    lng: -43.6654984,
    lat: -22.5518893
  },
  {
    lng: -43.6658507,
    lat: -22.5513489
  },
  {
    lng: -43.6662729,
    lat: -22.5507083
  },
  {
    lng: -43.6663861,
    lat: -22.5503565
  },
  {
    lng: -43.6665615,
    lat: -22.5499791
  },
  {
    lng: -43.6674477,
    lat: -22.5495838
  },
  {
    lng: -43.6677001,
    lat: -22.5491251
  },
  {
    lng: -43.6686011,
    lat: -22.5486895
  },
  {
    lng: -43.6686061,
    lat: -22.5482914
  },
  {
    lng: -43.6685337,
    lat: -22.5481917
  },
  {
    lng: -43.6681717,
    lat: -22.5476934
  },
  {
    lng: -43.6680196,
    lat: -22.547639
  },
  {
    lng: -43.6678674,
    lat: -22.5475846
  },
  {
    lng: -43.6676006,
    lat: -22.5475646
  },
  {
    lng: -43.667248,
    lat: -22.5477862
  },
  {
    lng: -43.6670439,
    lat: -22.5479145
  },
  {
    lng: -43.6665246,
    lat: -22.5481721
  },
  {
    lng: -43.6658774,
    lat: -22.5481884
  },
  {
    lng: -43.6653822,
    lat: -22.5480957
  },
  {
    lng: -43.6649974,
    lat: -22.5484627
  },
  {
    lng: -43.6649586,
    lat: -22.5484998
  },
  {
    lng: -43.6647483,
    lat: -22.5485335
  },
  {
    lng: -43.6646916,
    lat: -22.5484895
  },
  {
    lng: -43.6645898,
    lat: -22.5483992
  },
  {
    lng: -43.6643317,
    lat: -22.548211
  },
  {
    lng: -43.6639331,
    lat: -22.547995
  },
  {
    lng: -43.66358,
    lat: -22.5480438
  },
  {
    lng: -43.6635702,
    lat: -22.5480452
  },
  {
    lng: -43.663323,
    lat: -22.5479545
  },
  {
    lng: -43.6628686,
    lat: -22.5475785
  },
  {
    lng: -43.6624752,
    lat: -22.5474629
  },
  {
    lng: -43.6622181,
    lat: -22.5474109
  },
  {
    lng: -43.6621132,
    lat: -22.5471608
  },
  {
    lng: -43.6620093,
    lat: -22.5467911
  },
  {
    lng: -43.6616228,
    lat: -22.5468178
  },
  {
    lng: -43.6613481,
    lat: -22.546981
  },
  {
    lng: -43.6609631,
    lat: -22.5473323
  },
  {
    lng: -43.6606745,
    lat: -22.547578
  },
  {
    lng: -43.6604422,
    lat: -22.5479128
  },
  {
    lng: -43.6604961,
    lat: -22.5482677
  },
  {
    lng: -43.6600759,
    lat: -22.5480809
  },
  {
    lng: -43.659526,
    lat: -22.5479583
  },
  {
    lng: -43.659029,
    lat: -22.5480606
  },
  {
    lng: -43.6585094,
    lat: -22.548028
  },
  {
    lng: -43.6582635,
    lat: -22.5479783
  },
  {
    lng: -43.6579237,
    lat: -22.5479095
  },
  {
    lng: -43.6575845,
    lat: -22.5474814
  },
  {
    lng: -43.6572232,
    lat: -22.5473544
  },
  {
    lng: -43.6564404,
    lat: -22.5474187
  },
  {
    lng: -43.6562685,
    lat: -22.5474172
  },
  {
    lng: -43.6561764,
    lat: -22.5470797
  },
  {
    lng: -43.6559514,
    lat: -22.5466702
  },
  {
    lng: -43.6554566,
    lat: -22.5465421
  },
  {
    lng: -43.6551475,
    lat: -22.5460959
  },
  {
    lng: -43.6547183,
    lat: -22.5460823
  },
  {
    lng: -43.6543509,
    lat: -22.5464619
  },
  {
    lng: -43.6541189,
    lat: -22.5467612
  },
  {
    lng: -43.6539781,
    lat: -22.5475183
  },
  {
    lng: -43.6536779,
    lat: -22.5471
  },
  {
    lng: -43.6534105,
    lat: -22.5468152
  },
  {
    lng: -43.6526681,
    lat: -22.5467149
  },
  {
    lng: -43.6523935,
    lat: -22.5462732
  },
  {
    lng: -43.6519711,
    lat: -22.545928
  },
  {
    lng: -43.6512773,
    lat: -22.5455184
  },
  {
    lng: -43.6512355,
    lat: -22.5450534
  },
  {
    lng: -43.6511814,
    lat: -22.5447162
  },
  {
    lng: -43.6508368,
    lat: -22.5448551
  },
  {
    lng: -43.6504554,
    lat: -22.5449231
  },
  {
    lng: -43.6497454,
    lat: -22.5449563
  },
  {
    lng: -43.6492557,
    lat: -22.5449286
  },
  {
    lng: -43.6489119,
    lat: -22.5446263
  },
  {
    lng: -43.6482222,
    lat: -22.5443707
  },
  {
    lng: -43.6476584,
    lat: -22.5440486
  },
  {
    lng: -43.6470295,
    lat: -22.5439725
  },
  {
    lng: -43.646803,
    lat: -22.5437047
  },
  {
    lng: -43.6465014,
    lat: -22.5433477
  },
  {
    lng: -43.6461029,
    lat: -22.543114
  },
  {
    lng: -43.6456838,
    lat: -22.5430573
  },
  {
    lng: -43.6452645,
    lat: -22.5430006
  },
  {
    lng: -43.6448049,
    lat: -22.5431739
  },
  {
    lng: -43.64463,
    lat: -22.5435092
  },
  {
    lng: -43.6444349,
    lat: -22.5439506
  },
  {
    lng: -43.6440851,
    lat: -22.5441486
  },
  {
    lng: -43.643689,
    lat: -22.5439378
  },
  {
    lng: -43.6432586,
    lat: -22.5437124
  },
  {
    lng: -43.6430422,
    lat: -22.5434559
  },
  {
    lng: -43.6426805,
    lat: -22.5433473
  },
  {
    lng: -43.6424422,
    lat: -22.5433037
  },
  {
    lng: -43.6422794,
    lat: -22.5431638
  },
  {
    lng: -43.6422519,
    lat: -22.5429419
  },
  {
    lng: -43.642394,
    lat: -22.5424755
  },
  {
    lng: -43.642303,
    lat: -22.5421692
  },
  {
    lng: -43.6422913,
    lat: -22.5419446
  },
  {
    lng: -43.6421624,
    lat: -22.5414171
  },
  {
    lng: -43.6418516,
    lat: -22.541165
  },
  {
    lng: -43.6414848,
    lat: -22.5405384
  },
  {
    lng: -43.6409483,
    lat: -22.5404784
  },
  {
    lng: -43.6403944,
    lat: -22.5406954
  },
  {
    lng: -43.6401692,
    lat: -22.5408459
  },
  {
    lng: -43.6400069,
    lat: -22.5406506
  },
  {
    lng: -43.6397214,
    lat: -22.5402558
  },
  {
    lng: -43.6393183,
    lat: -22.5398304
  },
  {
    lng: -43.6387334,
    lat: -22.5397253
  },
  {
    lng: -43.638421,
    lat: -22.5396396
  },
  {
    lng: -43.637818,
    lat: -22.539483
  },
  {
    lng: -43.6375684,
    lat: -22.5398679
  },
  {
    lng: -43.6373054,
    lat: -22.5400541
  },
  {
    lng: -43.6371897,
    lat: -22.5405728
  },
  {
    lng: -43.6370084,
    lat: -22.5407992
  },
  {
    lng: -43.6370481,
    lat: -22.5412075
  },
  {
    lng: -43.6370891,
    lat: -22.5415959
  },
  {
    lng: -43.6371798,
    lat: -22.5419863
  },
  {
    lng: -43.6365816,
    lat: -22.5416331
  },
  {
    lng: -43.636335,
    lat: -22.541611
  },
  {
    lng: -43.6359719,
    lat: -22.5414284
  },
  {
    lng: -43.6356545,
    lat: -22.5409672
  },
  {
    lng: -43.635588,
    lat: -22.5406973
  },
  {
    lng: -43.6353653,
    lat: -22.540408
  },
  {
    lng: -43.6351499,
    lat: -22.5398962
  },
  {
    lng: -43.6347942,
    lat: -22.5397613
  },
  {
    lng: -43.6345391,
    lat: -22.5400223
  },
  {
    lng: -43.6337024,
    lat: -22.5401676
  },
  {
    lng: -43.6333292,
    lat: -22.5401411
  },
  {
    lng: -43.6329411,
    lat: -22.5400986
  },
  {
    lng: -43.6327961,
    lat: -22.5397998
  },
  {
    lng: -43.6328267,
    lat: -22.5393282
  },
  {
    lng: -43.6327221,
    lat: -22.5390482
  },
  {
    lng: -43.6324671,
    lat: -22.5388411
  },
  {
    lng: -43.6321849,
    lat: -22.5388823
  },
  {
    lng: -43.6318863,
    lat: -22.5389816
  },
  {
    lng: -43.6314006,
    lat: -22.5392064
  },
  {
    lng: -43.6310114,
    lat: -22.5395022
  },
  {
    lng: -43.6308575,
    lat: -22.5400496
  },
  {
    lng: -43.6307921,
    lat: -22.5403369
  },
  {
    lng: -43.6306344,
    lat: -22.5404407
  },
  {
    lng: -43.6304034,
    lat: -22.5404306
  },
  {
    lng: -43.6301039,
    lat: -22.5405389
  },
  {
    lng: -43.6297684,
    lat: -22.540613
  },
  {
    lng: -43.6293217,
    lat: -22.540515
  },
  {
    lng: -43.6291361,
    lat: -22.5403782
  },
  {
    lng: -43.6288548,
    lat: -22.5401541
  },
  {
    lng: -43.6287392,
    lat: -22.5397791
  },
  {
    lng: -43.6283726,
    lat: -22.5394755
  },
  {
    lng: -43.6280267,
    lat: -22.5396035
  },
  {
    lng: -43.627712,
    lat: -22.5398372
  },
  {
    lng: -43.6273515,
    lat: -22.5398626
  },
  {
    lng: -43.6270544,
    lat: -22.5399609
  },
  {
    lng: -43.6267303,
    lat: -22.5405494
  },
  {
    lng: -43.6265748,
    lat: -22.5405789
  },
  {
    lng: -43.6264191,
    lat: -22.5406084
  },
  {
    lng: -43.6261202,
    lat: -22.5405269
  },
  {
    lng: -43.6257018,
    lat: -22.5402821
  },
  {
    lng: -43.625421,
    lat: -22.5401633
  },
  {
    lng: -43.6252416,
    lat: -22.5400963
  },
  {
    lng: -43.6250621,
    lat: -22.5400293
  },
  {
    lng: -43.6247522,
    lat: -22.5399703
  },
  {
    lng: -43.6245528,
    lat: -22.5398603
  },
  {
    lng: -43.6244536,
    lat: -22.5398143
  },
  {
    lng: -43.6241111,
    lat: -22.5395701
  },
  {
    lng: -43.6238467,
    lat: -22.5393787
  },
  {
    lng: -43.6236401,
    lat: -22.5393366
  },
  {
    lng: -43.6235218,
    lat: -22.5394665
  },
  {
    lng: -43.6234034,
    lat: -22.5395963
  },
  {
    lng: -43.6231666,
    lat: -22.539856
  },
  {
    lng: -43.6229639,
    lat: -22.5400786
  },
  {
    lng: -43.6228601,
    lat: -22.5401924
  },
  {
    lng: -43.6228285,
    lat: -22.540227
  },
  {
    lng: -43.6226931,
    lat: -22.5403755
  },
  {
    lng: -43.6213626,
    lat: -22.5418353
  },
  {
    lng: -43.6212565,
    lat: -22.5419516
  },
  {
    lng: -43.6208533,
    lat: -22.542394
  },
  {
    lng: -43.5985824,
    lat: -22.5668271
  },
  {
    lng: -43.5974463,
    lat: -22.5680738
  },
  {
    lng: -43.5850343,
    lat: -22.5438112
  },
  {
    lng: -43.5725143,
    lat: -22.5235104
  },
  {
    lng: -43.5605387,
    lat: -22.5040859
  },
  {
    lng: -43.5621443,
    lat: -22.5000557
  },
  {
    lng: -43.5727615,
    lat: -22.4605148
  },
  {
    lng: -43.5838195,
    lat: -22.456079
  },
  {
    lng: -43.6345468,
    lat: -22.4712084
  },
  {
    lng: -43.6509904,
    lat: -22.4761095
  },
  {
    lng: -43.657182,
    lat: -22.4779543
  },
  {
    lng: -43.7166357,
    lat: -22.4956598
  },
  {
    lng: -43.7167643,
    lat: -22.4956984
  }
];

export default polygonCoords_PauloDeFrontin;