import React, { Component } from 'react';
import api from '../services/api';
import apiString from '../services/apiString';
import apiStringServer from '../services/apiStringServer';

import { DataGrid, ptBR } from '@mui/x-data-grid';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Description from '@material-ui/icons/Description';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';

import Box from '@material-ui/core/Box';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import StepButton from '@material-ui/core/StepButton';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import theme from '../themes/theme';
import { ThemeProvider } from '@material-ui/core';

import pdfFile from '../components/examples/kreurbcert_queimados_lcds09710q144-2024.pdf';

let columns = [
  { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'name',
    headerName: 'Nome',
    width: 150,
    editable: false,
    renderHeader: () => (
      <strong>
        {'Nome '}
        <span role="img" aria-label="enjoy">
          
        </span>
      </strong>
    ),
  },
  {
    field: 'email',
    headerName: 'E-mail',
    width: 200,
    editable: false,
    renderHeader: () => (
      <strong>
        {'E-mail '}
        <span role="img" aria-label="enjoy">
          📧
        </span>
      </strong>
    ),
  },
  {
    field: 'cpf_cnpj',
    headerName: 'Documento',
    width: 150,
    editable: false,
    renderHeader: () => (
      <strong>
        {'Documento '}
        <span role="img" aria-label="enjoy">
          📇
        </span>
      </strong>
    ),
  },
  {
    field: 'address',
    headerName: 'Endereço',
    width: 330,
    editable: false,
    renderHeader: () => (
      <strong>
        {'Endereço '}
        <span role="img" aria-label="enjoy">
          🏠
        </span>
      </strong>
    ),
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 130,
    editable: false,
    renderHeader: () => (
      <strong>
        {'Status '}
        <span role="img" aria-label="enjoy">
          ✔️
        </span>
      </strong>
    ),
  },
  {
    field: 'actions',
    headerName: 'Ações',
    renderCell: () => (
      <Actions />
    ),
    sortable: false,
    width: 100,
    headerAlign: 'center',
    filterable: false,
    align: 'center',
    disableColumnMenu: true,
    disableReorder: true,
  },
];

let rows = [];

let rowsArray = [];

let cpf_cnpj = '';

let selected_city = '';

let user_step = '';

const steps = [
  {
    label: 'Cadastro', 
    description: `Este usuário fez o cadastro através do aplicativo, mas ainda não finalizou os detalhes da área.`,
  },
  {
    label: 'Finalização do cadastro',
    description: 'O usuário finalizou o cadastro.',
  },
  {
    label: 'Aprovação do cadastro',
    description: <div>Análise dos dados do usuário e da área delimitada. <br /> <a href="http://localhost:3000/details?cpf_cnpj=097.033.024-10&lat=-22.7176626&lng=-43.5411099&selected_city=queimados&login=12.345.678/0001-90" target="_blank" rel="noreferrer" style={{ color: '#001D5F' }}>Clique aqui</a> para analisar os dados.</div>,
  },
  {
    label: 'Validação periódica de permanência',
    description: 'A validação periódica no aplicativo do usuário terminou.',
  },
  {
    label: 'Aprovação da área delimitada',
    description: <div>Análise da área delimitada. <br /> <a href="http://localhost:3000/details?cpf_cnpj=097.033.024-10&lat=-22.7176626&lng=-43.5411099&selected_city=queimados&login=12.345.678/0001-90" target="_blank" rel="noreferrer" style={{ color: '#001D5F' }}>Clique aqui</a> para analisar os dados.</div>,
  },
  {
    label: 'Aprovação dos documentos',
    description: <div>O usuário enviou os documentos necessários para a regularização. <br /> <a href="http://localhost:3000/details?cpf_cnpj=097.033.024-10&lat=-22.7176626&lng=-43.5411099&selected_city=queimados&login=12.345.678/0001-90" target="_blank" rel="noreferrer" style={{ color: '#001D5F' }}>Clique aqui</a> para visualizar os documentos.</div>,
  },
  {
    label: 'Aprovação das imagens dos cômodos',
    description: 'As imagens dos cômodos foram validadas.',
  },
  {
    label: 'Aprovação da planta baixa',
    description: <div>O usuário enviou a planta baixa para a regularização. <br /> <a href="http://localhost:3000/details?cpf_cnpj=097.033.024-10&lat=-22.7176626&lng=-43.5411099&selected_city=queimados&login=12.345.678/0001-90" target="_blank" rel="noreferrer" style={{ color: '#001D5F' }}>Clique aqui</a> para visualizar a planta baixa junto aos documentos.</div>,
  },
  {
    label: 'Homologação geral',
    description: 'Todas as etapas foram concluídas, é possível gerar o ducumento de aprovação.',
  },
];

export class Actions extends Component {

  state = {
    openDialog: false,
    openDialog2: false,
    openDialog3: false,
    status: '1',

    userStep: '0',
    activeStep: 0,
    completed: {},
    disabled: true,

    page_rows: [],
  }

  async componentDidMount() {
    await this.setState({ userStep: user_step });
    //alert(this.state.userStep);
    const completed = {};
    for (let i = 0; i < this.state.userStep.length; i++) {
      completed[this.state.userStep.charAt(i)] = true;
    }
    this.setState({ completed });

    if (this.state.userStep.length === 7) { //o correto é 9 mas por enquanto é 7 (validação periódica e homologação geral não estão disponíveis ainda)
      this.setState({ disabled: false });
    }
  }

  handleStep = (step) => () => {
    this.setState({ activeStep: step });
  }

  handleEdit = async () => {
    setTimeout(async () => {
      await this.setState({ openDialog: true });
      await this.componentDidMount();
    }, 1200);
    //let 
    //alert(JSON.stringify(rowsArray[0]));
  }

  handleDelete = async () => {
    if (selected_city.toLowerCase().includes('três rios')) {
      selected_city = 'rios';
    } else {
      if (selected_city.toLowerCase().includes('mendes')) {
        selected_city = 'mendes';
      } else {
        if (selected_city.toLowerCase().includes('paulo de frontin')) {
          selected_city = 'paulo';
        } else {
          selected_city = 'rio de janeiro';
        }
      }
    }

    try {
      let resp = await api.get('/deleteuser', {
        params: {
          cpf_cnpj,
          selected_city: selected_city
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('deleted')) {

            window.location.reload();

          } else {
            let msg = JSON.stringify(resp.data);
            alert(msg);
          }
        }
      }
    } catch (err) {
      alert('Não foi possível buscar os dados do usuário. ' + err);
    }
  }

  onStatusChange = async () => {
    if (selected_city.toLowerCase().includes('três rios')) {
      selected_city = 'rios';
    } else {
      if (selected_city.toLowerCase().includes('mendes')) {
        selected_city = 'mendes';
      } else {
        if (selected_city.toLowerCase().includes('paulo de frontin')) {
          selected_city = 'paulo';
        } else {
          if (selected_city.toLowerCase().includes('cabo')) {
            selected_city = 'cabo';
          } else {
            if (selected_city.toLowerCase().includes('arraial')) {
              selected_city = 'arraial';
            } else {
              if (selected_city.toLowerCase().includes('buzios')) {
                selected_city = 'buzios';
              } else {
                if (selected_city.toLowerCase().includes('ostras')) {
                  selected_city = 'ostras';
                } else {
                  selected_city = 'rio de janeiro';
                }
              }
            }
          }
        }
      }
    }

    this.setState({ openDialog3: true });

    /*try {
      let resp = await api.get('/updateuser', {
        params: {
          cpf_cnpj,
          status: this.state.status,
          selected_city: selected_city
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('edited')) {

            window.location.reload();

          } else {
            let msg = JSON.stringify(resp.data);
            console.log(msg);
          }
        }
      }
    } catch (err) {
      alert('Não foi possível buscar os dados do usuário. ' + err);
    }*/
  }

  generateDocument = async () => {

    let taga = document.getElementById('pdfdoc');
    taga.href = pdfFile;
    taga.click();

  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    return(
      <div>
        <IconButton
          color="inherit"
          size="small"
          aria-label="edit"
          onClick={this.handleEdit}
        >
          <Edit fontSize="small" />
        </IconButton>
        <IconButton
          color="inherit"
          size="small"
          aria-label="delete"
          onClick={() => this.setState({ openDialog2: true })}
        >
          <Delete fontSize="small" />
        </IconButton>

        <Dialog open={this.state.openDialog} onClose={() => this.setState({ openDialog: false })}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Alteração</DialogTitle>
          <DialogContent>
            <DialogContentText color="#fff">
              Selecione ou preencha os campos que deseja alterar.
            </DialogContentText>

            <FormControl variant="outlined" fullWidth style={{ marginBottom: 20 }}>
              <InputLabel htmlFor="outlined-theme-native-simple">
                Status
              </InputLabel>
              <Select
                autoFocus
                native
                value={this.state.status}
                onChange={this.handleChange}
                label="Status"
                inputProps={{
                  name: 'status',
                  id: 'outlined-status-native-simple',
                }}
              >
                <option value="1">Pendente</option>
                <option value="2">Regularizado</option>
                <option value="3">Negado</option>
              </Select>
            </FormControl>

          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ openDialog: false })} style={{ color: '#001D5F' }}>
              Cancelar
            </Button>
            <Button onClick={async () => {
              await this.setState({ openDialog: false });
              this.onStatusChange();
            }} style={{ color: '#001D5F' }}>
              Alterar
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={this.state.openDialog2} onClose={() => this.setState({ openDialog2: false })}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Exclusão</DialogTitle>
          <DialogContent>
            <DialogContentText color="#fff">
              Deseja excluir este usuário e todos os dados relacionados a ele?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ openDialog2: false })} style={{ color: '#001D5F' }}>
              Cancelar
            </Button>
            <Button onClick={async () => {
              this.setState({ openDialog2: false });
              this.handleDelete();
            }} style={{ color: '#001D5F' }}>
              Excluir
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={this.state.openDialog3} onClose={() => this.setState({ openDialog3: false })}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Conclusão de etapas</DialogTitle>
          <DialogContent>
            <DialogContentText color="#fff">
              Certifique-se de já ter concluído todas as etapas necessárias para a alteração do status do usuário.
            </DialogContentText>

            <Box sx={{ maxWidth: 400 }}>
              <Stepper nonLinear orientation="vertical" activeStep={this.state.activeStep}>
                {steps.map((step, index) => (
                  <Step key={step.label}>
                    <StepButton onClick={this.handleStep(index)} completed={this.state.completed[index]}>
                      {step.label}
                    </StepButton>
                    <StepContent>
                      <Typography>{step.description}</Typography>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
              {this.state.activeStep === steps.length && (
                <Paper square elevation={0} sx={{ p: 3 }}>
                  <Typography>Todas as etapas foram concluídas.</Typography>
                </Paper>
              )}
            </Box>

          </DialogContent>
          <DialogActions>
            <ThemeProvider theme={theme}>
              <Box>
                <Button onClick={() => this.setState({ disabled: !this.state.disabled })} style={{ color: '#001D5F' }}>
                  Teste temporário
                </Button>
                <Button onClick={() => this.setState({ openDialog3: false })} style={{ color: '#001D5F' }}>
                  Cancelar
                </Button>
                <Button
                  disabled={this.state.disabled}
                  startIcon={<Description style={{ marginTop: -5, marginRight: -5 }} />}
                  onClick={async () => {
                    await this.generateDocument();
                    this.setState({ openDialog3: false });
                  }}
                  style={{ color: this.state.disabled === false ? '#001D5F' : null }} 
                >
                  Gerar documento
                </Button>
                <a id="pdfdoc" href="" target="_blank" rel="noreferrer"/>
              </Box>
            </ThemeProvider>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

let users = [];

export default class Table extends Component {

  state = {
    page_rows: []
  }

  async componentDidMount() {
    
    try {
      let resp = await api.get('/users', {
        params: {
          selected_city: this.props.vars.selected_city
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('name')) {

            users = resp.data;

            for (let i = 0; i < resp.data.length; i++) {
              let status = '';
              if (resp.data[i].active == 1) {
                status = '🟡 Pendente';
              } else {
                if (resp.data[i].active == 2) {
                  status = '🟢 Regularizado';
                } else {
                  if (resp.data[i].active == 3) {
                    status = '🔴 Negado';
                  }
                }
              }
              let aux = {
                id: `${resp.data[i].id}`, 
                name: `${resp.data[i].name}`, 
                email: `${resp.data[i].email}`, 
                cpf_cnpj: `${resp.data[i].cpf_cnpj}`, 
                address: `${resp.data[i].street}, ${resp.data[i].number} - ${resp.data[i].district}, ${resp.data[i].city} - ${resp.data[i].state}`,
                status: status,
                user_step: `${resp.data[i].user_step}`
              };
              rows = Object.assign([], rows);
              rows.push(aux);
            }

            await this.setState({ page_rows: rows });

          } else {
            let msg = JSON.stringify(resp.data);
            console.log(msg)
          }
        }
      }
    } catch (err) {
      console.error(err);
    }
    await this.setState({ page_rows: rows });
  }

  render() {

    return(

      <div style={styles.dataGrid}>
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={7}
              rowsPerPageOptions={[7]}
              disableSelectionOnClick
              localeText={ptBR.props.MuiDataGrid.localeText}
              onCellClick={async (row) => {
                await rowsArray.push(row);
                cpf_cnpj = rowsArray[0].row.cpf_cnpj;
                selected_city = rowsArray[0].row.address;
                user_step = rowsArray[0].row.user_step;
                rowsArray.pop();
              }}
            />
          </div>

    );

  }

}

let styles = {
  dataGrid: { 
    height: 480, 
    width: '480%',
    padding: 20,
  }
}