let polygonCoords_NovaIguacu = [

  {
    lng: -43.6734805,
    lat: -22.8640462
  },
  {
    lng: -43.6733625,
    lat: -22.8639264
  },
  {
    lng: -43.6729838,
    lat: -22.8638338
  },
  {
    lng: -43.6725973,
    lat: -22.8639085
  },
  {
    lng: -43.6712746,
    lat: -22.8646904
  },
  {
    lng: -43.6706426,
    lat: -22.8649968
  },
  {
    lng: -43.6705295,
    lat: -22.8650688
  },
  {
    lng: -43.67044,
    lat: -22.8651038
  },
  {
    lng: -43.6702747,
    lat: -22.8651686
  },
  {
    lng: -43.6699969,
    lat: -22.865255
  },
  {
    lng: -43.6694364,
    lat: -22.8652766
  },
  {
    lng: -43.6690279,
    lat: -22.8651965
  },
  {
    lng: -43.6689276,
    lat: -22.8651769
  },
  {
    lng: -43.6684896,
    lat: -22.8650593
  },
  {
    lng: -43.6682618,
    lat: -22.865074
  },
  {
    lng: -43.6678684,
    lat: -22.865083
  },
  {
    lng: -43.6674748,
    lat: -22.8650747
  },
  {
    lng: -43.6667598,
    lat: -22.8651084
  },
  {
    lng: -43.6663571,
    lat: -22.8651205
  },
  {
    lng: -43.666301,
    lat: -22.8651222
  },
  {
    lng: -43.6662701,
    lat: -22.8651274
  },
  {
    lng: -43.6659167,
    lat: -22.8651868
  },
  {
    lng: -43.6656558,
    lat: -22.8651854
  },
  {
    lng: -43.6655242,
    lat: -22.8651848
  },
  {
    lng: -43.6654647,
    lat: -22.8651802
  },
  {
    lng: -43.6651349,
    lat: -22.8651549
  },
  {
    lng: -43.6647558,
    lat: -22.8650572
  },
  {
    lng: -43.6643689,
    lat: -22.8649583
  },
  {
    lng: -43.6642858,
    lat: -22.8649376
  },
  {
    lng: -43.6639863,
    lat: -22.8648629
  },
  {
    lng: -43.663606,
    lat: -22.8647816
  },
  {
    lng: -43.66323,
    lat: -22.8646852
  },
  {
    lng: -43.6628476,
    lat: -22.8646024
  },
  {
    lng: -43.6624743,
    lat: -22.8644882
  },
  {
    lng: -43.662098,
    lat: -22.8643727
  },
  {
    lng: -43.6617532,
    lat: -22.8642021
  },
  {
    lng: -43.6613895,
    lat: -22.8640608
  },
  {
    lng: -43.6610096,
    lat: -22.8639189
  },
  {
    lng: -43.6606324,
    lat: -22.8638134
  },
  {
    lng: -43.6602592,
    lat: -22.8637077
  },
  {
    lng: -43.6598829,
    lat: -22.8635942
  },
  {
    lng: -43.6594991,
    lat: -22.8635244
  },
  {
    lng: -43.6592086,
    lat: -22.863492
  },
  {
    lng: -43.6591026,
    lat: -22.8634802
  },
  {
    lng: -43.6587191,
    lat: -22.8634012
  },
  {
    lng: -43.6583526,
    lat: -22.8632685
  },
  {
    lng: -43.6581003,
    lat: -22.8630816
  },
  {
    lng: -43.6580393,
    lat: -22.8630441
  },
  {
    lng: -43.6577158,
    lat: -22.8628451
  },
  {
    lng: -43.6574122,
    lat: -22.8626104
  },
  {
    lng: -43.6571109,
    lat: -22.8623717
  },
  {
    lng: -43.6568032,
    lat: -22.8621402
  },
  {
    lng: -43.6564895,
    lat: -22.8619249
  },
  {
    lng: -43.6561908,
    lat: -22.8616895
  },
  {
    lng: -43.6559059,
    lat: -22.8614426
  },
  {
    lng: -43.6555997,
    lat: -22.8612185
  },
  {
    lng: -43.6552738,
    lat: -22.8610105
  },
  {
    lng: -43.6549533,
    lat: -22.8607973
  },
  {
    lng: -43.6546454,
    lat: -22.8605702
  },
  {
    lng: -43.6543322,
    lat: -22.8603516
  },
  {
    lng: -43.6540143,
    lat: -22.8601337
  },
  {
    lng: -43.6536836,
    lat: -22.8599322
  },
  {
    lng: -43.6533741,
    lat: -22.8597037
  },
  {
    lng: -43.6530675,
    lat: -22.8594765
  },
  {
    lng: -43.6527546,
    lat: -22.8592542
  },
  {
    lng: -43.6524528,
    lat: -22.8590202
  },
  {
    lng: -43.6521574,
    lat: -22.8587791
  },
  {
    lng: -43.6518486,
    lat: -22.8585559
  },
  {
    lng: -43.6515364,
    lat: -22.8583378
  },
  {
    lng: -43.6515276,
    lat: -22.8583316
  },
  {
    lng: -43.6512134,
    lat: -22.8581125
  },
  {
    lng: -43.6508989,
    lat: -22.8578903
  },
  {
    lng: -43.6505686,
    lat: -22.8576922
  },
  {
    lng: -43.6502311,
    lat: -22.8575073
  },
  {
    lng: -43.6498753,
    lat: -22.8573372
  },
  {
    lng: -43.6495532,
    lat: -22.8571327
  },
  {
    lng: -43.6494311,
    lat: -22.8571102
  },
  {
    lng: -43.6490394,
    lat: -22.8570436
  },
  {
    lng: -43.6486614,
    lat: -22.8569438
  },
  {
    lng: -43.6483335,
    lat: -22.856848
  },
  {
    lng: -43.6482802,
    lat: -22.8568324
  },
  {
    lng: -43.6478754,
    lat: -22.8567405
  },
  {
    lng: -43.647482,
    lat: -22.856675
  },
  {
    lng: -43.6470923,
    lat: -22.8566212
  },
  {
    lng: -43.6467055,
    lat: -22.8565394
  },
  {
    lng: -43.6463564,
    lat: -22.8563756
  },
  {
    lng: -43.6460314,
    lat: -22.8561739
  },
  {
    lng: -43.6456577,
    lat: -22.8560689
  },
  {
    lng: -43.6452743,
    lat: -22.8559936
  },
  {
    lng: -43.644907,
    lat: -22.8558612
  },
  {
    lng: -43.6445189,
    lat: -22.8557845
  },
  {
    lng: -43.6442489,
    lat: -22.855749
  },
  {
    lng: -43.644239,
    lat: -22.8557477
  },
  {
    lng: -43.6441309,
    lat: -22.8557334
  },
  {
    lng: -43.6437409,
    lat: -22.855785
  },
  {
    lng: -43.6433579,
    lat: -22.8558636
  },
  {
    lng: -43.6429786,
    lat: -22.8559563
  },
  {
    lng: -43.6428668,
    lat: -22.8560046
  },
  {
    lng: -43.6426252,
    lat: -22.8561091
  },
  {
    lng: -43.64227,
    lat: -22.8562721
  },
  {
    lng: -43.6419049,
    lat: -22.8564024
  },
  {
    lng: -43.6415422,
    lat: -22.856536
  },
  {
    lng: -43.6411583,
    lat: -22.8566316
  },
  {
    lng: -43.6407778,
    lat: -22.8567423
  },
  {
    lng: -43.6404224,
    lat: -22.8568931
  },
  {
    lng: -43.6400443,
    lat: -22.8570172
  },
  {
    lng: -43.6396743,
    lat: -22.8571638
  },
  {
    lng: -43.6393108,
    lat: -22.8573079
  },
  {
    lng: -43.638949,
    lat: -22.8574426
  },
  {
    lng: -43.6385641,
    lat: -22.8575548
  },
  {
    lng: -43.6381977,
    lat: -22.8576822
  },
  {
    lng: -43.6378233,
    lat: -22.8577998
  },
  {
    lng: -43.6374635,
    lat: -22.8579634
  },
  {
    lng: -43.6370889,
    lat: -22.8580906
  },
  {
    lng: -43.63672,
    lat: -22.8582135
  },
  {
    lng: -43.6363466,
    lat: -22.8583346
  },
  {
    lng: -43.635971,
    lat: -22.8584566
  },
  {
    lng: -43.6356017,
    lat: -22.8585926
  },
  {
    lng: -43.6352288,
    lat: -22.8587142
  },
  {
    lng: -43.6348652,
    lat: -22.8588444
  },
  {
    lng: -43.6346868,
    lat: -22.8589208
  },
  {
    lng: -43.6345084,
    lat: -22.8589972
  },
  {
    lng: -43.6341542,
    lat: -22.8591585
  },
  {
    lng: -43.6337885,
    lat: -22.8593026
  },
  {
    lng: -43.6334214,
    lat: -22.8594328
  },
  {
    lng: -43.6330601,
    lat: -22.859569
  },
  {
    lng: -43.6326862,
    lat: -22.8596926
  },
  {
    lng: -43.6323221,
    lat: -22.8598333
  },
  {
    lng: -43.6321469,
    lat: -22.8599136
  },
  {
    lng: -43.6319717,
    lat: -22.859994
  },
  {
    lng: -43.6316147,
    lat: -22.8601517
  },
  {
    lng: -43.6312296,
    lat: -22.8602995
  },
  {
    lng: -43.6310734,
    lat: -22.8603537
  },
  {
    lng: -43.6310617,
    lat: -22.8603578
  },
  {
    lng: -43.630654,
    lat: -22.8604992
  },
  {
    lng: -43.6305585,
    lat: -22.860518
  },
  {
    lng: -43.6301418,
    lat: -22.8606228
  },
  {
    lng: -43.6297438,
    lat: -22.860725
  },
  {
    lng: -43.629372,
    lat: -22.8608505
  },
  {
    lng: -43.6289083,
    lat: -22.8610013
  },
  {
    lng: -43.6283534,
    lat: -22.8612093
  },
  {
    lng: -43.6276723,
    lat: -22.8614519
  },
  {
    lng: -43.6271377,
    lat: -22.8616113
  },
  {
    lng: -43.6267771,
    lat: -22.8617506
  },
  {
    lng: -43.6262766,
    lat: -22.8619159
  },
  {
    lng: -43.6258948,
    lat: -22.8620489
  },
  {
    lng: -43.6256723,
    lat: -22.8621366
  },
  {
    lng: -43.6256041,
    lat: -22.8621635
  },
  {
    lng: -43.6253424,
    lat: -22.8622667
  },
  {
    lng: -43.6249623,
    lat: -22.8624037
  },
  {
    lng: -43.6249133,
    lat: -22.8624213
  },
  {
    lng: -43.6247764,
    lat: -22.8624706
  },
  {
    lng: -43.6244104,
    lat: -22.8625986
  },
  {
    lng: -43.6239801,
    lat: -22.8627363
  },
  {
    lng: -43.6234806,
    lat: -22.8628141
  },
  {
    lng: -43.6230895,
    lat: -22.8628121
  },
  {
    lng: -43.6230649,
    lat: -22.8628092
  },
  {
    lng: -43.6226994,
    lat: -22.8627666
  },
  {
    lng: -43.6223199,
    lat: -22.8626797
  },
  {
    lng: -43.6218731,
    lat: -22.8625963
  },
  {
    lng: -43.6213169,
    lat: -22.8624943
  },
  {
    lng: -43.6210591,
    lat: -22.8624495
  },
  {
    lng: -43.6209284,
    lat: -22.8624268
  },
  {
    lng: -43.6205361,
    lat: -22.8623614
  },
  {
    lng: -43.6200241,
    lat: -22.8623181
  },
  {
    lng: -43.6196266,
    lat: -22.862279
  },
  {
    lng: -43.6191847,
    lat: -22.862208
  },
  {
    lng: -43.6187205,
    lat: -22.8621323
  },
  {
    lng: -43.6183377,
    lat: -22.8620499
  },
  {
    lng: -43.6179539,
    lat: -22.8619794
  },
  {
    lng: -43.6175568,
    lat: -22.8619173
  },
  {
    lng: -43.6173697,
    lat: -22.8618808
  },
  {
    lng: -43.6173399,
    lat: -22.861875
  },
  {
    lng: -43.6172787,
    lat: -22.861863
  },
  {
    lng: -43.6169677,
    lat: -22.8618024
  },
  {
    lng: -43.6165039,
    lat: -22.8617051
  },
  {
    lng: -43.6158816,
    lat: -22.8615951
  },
  {
    lng: -43.6154927,
    lat: -22.861559
  },
  {
    lng: -43.6149864,
    lat: -22.8614522
  },
  {
    lng: -43.6145553,
    lat: -22.8613233
  },
  {
    lng: -43.6140955,
    lat: -22.8612837
  },
  {
    lng: -43.6132075,
    lat: -22.8611182
  },
  {
    lng: -43.6131598,
    lat: -22.8611082
  },
  {
    lng: -43.6112675,
    lat: -22.8607098
  },
  {
    lng: -43.6112598,
    lat: -22.8607082
  },
  {
    lng: -43.6108481,
    lat: -22.8606198
  },
  {
    lng: -43.6096358,
    lat: -22.8604476
  },
  {
    lng: -43.6091492,
    lat: -22.860312
  },
  {
    lng: -43.608676,
    lat: -22.8602285
  },
  {
    lng: -43.606783,
    lat: -22.8598263
  },
  {
    lng: -43.6066194,
    lat: -22.8598025
  },
  {
    lng: -43.6062265,
    lat: -22.8597061
  },
  {
    lng: -43.6058074,
    lat: -22.8596215
  },
  {
    lng: -43.6057524,
    lat: -22.8596139
  },
  {
    lng: -43.6057235,
    lat: -22.85961
  },
  {
    lng: -43.6054038,
    lat: -22.8595661
  },
  {
    lng: -43.6050079,
    lat: -22.8594929
  },
  {
    lng: -43.6046109,
    lat: -22.8593809
  },
  {
    lng: -43.6042134,
    lat: -22.8593137
  },
  {
    lng: -43.6038187,
    lat: -22.8592447
  },
  {
    lng: -43.6035132,
    lat: -22.8591774
  },
  {
    lng: -43.6034272,
    lat: -22.8591584
  },
  {
    lng: -43.6033381,
    lat: -22.8591502
  },
  {
    lng: -43.6030138,
    lat: -22.8591202
  },
  {
    lng: -43.6027703,
    lat: -22.859062
  },
  {
    lng: -43.6026258,
    lat: -22.8590275
  },
  {
    lng: -43.6025204,
    lat: -22.859004
  },
  {
    lng: -43.6022959,
    lat: -22.858956
  },
  {
    lng: -43.6020768,
    lat: -22.8589029
  },
  {
    lng: -43.601986,
    lat: -22.8589037
  },
  {
    lng: -43.6019107,
    lat: -22.8589158
  },
  {
    lng: -43.6017838,
    lat: -22.8589089
  },
  {
    lng: -43.6017281,
    lat: -22.8589058
  },
  {
    lng: -43.601666,
    lat: -22.8589024
  },
  {
    lng: -43.6015912,
    lat: -22.8588789
  },
  {
    lng: -43.6014637,
    lat: -22.858839
  },
  {
    lng: -43.6012585,
    lat: -22.8587989
  },
  {
    lng: -43.6008706,
    lat: -22.8586912
  },
  {
    lng: -43.6007173,
    lat: -22.8586699
  },
  {
    lng: -43.60062,
    lat: -22.8586564
  },
  {
    lng: -43.6005957,
    lat: -22.8586502
  },
  {
    lng: -43.6001929,
    lat: -22.8585745
  },
  {
    lng: -43.6001655,
    lat: -22.8585663
  },
  {
    lng: -43.5998787,
    lat: -22.8585251
  },
  {
    lng: -43.59963,
    lat: -22.8584412
  },
  {
    lng: -43.5994646,
    lat: -22.858396
  },
  {
    lng: -43.599434,
    lat: -22.8583877
  },
  {
    lng: -43.5992381,
    lat: -22.8583343
  },
  {
    lng: -43.598757,
    lat: -22.858205
  },
  {
    lng: -43.5983711,
    lat: -22.8581346
  },
  {
    lng: -43.5982222,
    lat: -22.8581094
  },
  {
    lng: -43.5981417,
    lat: -22.8580957
  },
  {
    lng: -43.5977457,
    lat: -22.8579928
  },
  {
    lng: -43.5975765,
    lat: -22.8579419
  },
  {
    lng: -43.5973533,
    lat: -22.8578748
  },
  {
    lng: -43.5972448,
    lat: -22.8578422
  },
  {
    lng: -43.5968342,
    lat: -22.857783
  },
  {
    lng: -43.596656,
    lat: -22.8577659
  },
  {
    lng: -43.5965621,
    lat: -22.857757
  },
  {
    lng: -43.5964483,
    lat: -22.8577695
  },
  {
    lng: -43.5963351,
    lat: -22.857766
  },
  {
    lng: -43.5960004,
    lat: -22.8578733
  },
  {
    lng: -43.595794,
    lat: -22.8579584
  },
  {
    lng: -43.595564,
    lat: -22.8580667
  },
  {
    lng: -43.5952536,
    lat: -22.8582748
  },
  {
    lng: -43.595042,
    lat: -22.8584219
  },
  {
    lng: -43.5948251,
    lat: -22.8586314
  },
  {
    lng: -43.594598,
    lat: -22.8589282
  },
  {
    lng: -43.5944472,
    lat: -22.8590905
  },
  {
    lng: -43.5943232,
    lat: -22.8592241
  },
  {
    lng: -43.5940447,
    lat: -22.8595058
  },
  {
    lng: -43.5940163,
    lat: -22.8595332
  },
  {
    lng: -43.5937083,
    lat: -22.8598163
  },
  {
    lng: -43.5934619,
    lat: -22.8600447
  },
  {
    lng: -43.5934269,
    lat: -22.8600772
  },
  {
    lng: -43.5932622,
    lat: -22.8602302
  },
  {
    lng: -43.5930885,
    lat: -22.8603329
  },
  {
    lng: -43.5930381,
    lat: -22.860375
  },
  {
    lng: -43.5929877,
    lat: -22.8604171
  },
  {
    lng: -43.5929439,
    lat: -22.8604993
  },
  {
    lng: -43.5927958,
    lat: -22.8602472
  },
  {
    lng: -43.5927539,
    lat: -22.8601759
  },
  {
    lng: -43.5927297,
    lat: -22.8601675
  },
  {
    lng: -43.5926957,
    lat: -22.8601501
  },
  {
    lng: -43.5926769,
    lat: -22.8600319
  },
  {
    lng: -43.5922946,
    lat: -22.857633
  },
  {
    lng: -43.5915741,
    lat: -22.8540687
  },
  {
    lng: -43.5910023,
    lat: -22.8512739
  },
  {
    lng: -43.5908839,
    lat: -22.8506866
  },
  {
    lng: -43.5887404,
    lat: -22.8400543
  },
  {
    lng: -43.588466,
    lat: -22.8386931
  },
  {
    lng: -43.5881433,
    lat: -22.8385842
  },
  {
    lng: -43.5690056,
    lat: -22.8321254
  },
  {
    lng: -43.56789,
    lat: -22.8317528
  },
  {
    lng: -43.5678182,
    lat: -22.8317286
  },
  {
    lng: -43.5668657,
    lat: -22.8314073
  },
  {
    lng: -43.5667948,
    lat: -22.8313834
  },
  {
    lng: -43.5593869,
    lat: -22.8288849
  },
  {
    lng: -43.5356638,
    lat: -22.8248847
  },
  {
    lng: -43.5222694,
    lat: -22.8226262
  },
  {
    lng: -43.5218047,
    lat: -22.8225479
  },
  {
    lng: -43.5152864,
    lat: -22.8211038
  },
  {
    lng: -43.4900623,
    lat: -22.815515
  },
  {
    lng: -43.4910186,
    lat: -22.8148963
  },
  {
    lng: -43.4914972,
    lat: -22.8146213
  },
  {
    lng: -43.4918452,
    lat: -22.8146246
  },
  {
    lng: -43.4923549,
    lat: -22.8146831
  },
  {
    lng: -43.4930162,
    lat: -22.8146679
  },
  {
    lng: -43.4935505,
    lat: -22.8146084
  },
  {
    lng: -43.4941064,
    lat: -22.8146889
  },
  {
    lng: -43.4944377,
    lat: -22.8146222
  },
  {
    lng: -43.494769,
    lat: -22.8145554
  },
  {
    lng: -43.494782,
    lat: -22.8145506
  },
  {
    lng: -43.4954794,
    lat: -22.8142933
  },
  {
    lng: -43.4960733,
    lat: -22.8140838
  },
  {
    lng: -43.4963196,
    lat: -22.8138388
  },
  {
    lng: -43.4963607,
    lat: -22.8132586
  },
  {
    lng: -43.4964121,
    lat: -22.8128074
  },
  {
    lng: -43.4962767,
    lat: -22.812462
  },
  {
    lng: -43.4961412,
    lat: -22.8121274
  },
  {
    lng: -43.4959588,
    lat: -22.8118354
  },
  {
    lng: -43.4957411,
    lat: -22.811586
  },
  {
    lng: -43.4953155,
    lat: -22.8112701
  },
  {
    lng: -43.4949817,
    lat: -22.8110304
  },
  {
    lng: -43.4945929,
    lat: -22.8108755
  },
  {
    lng: -43.494208,
    lat: -22.810722
  },
  {
    lng: -43.4941196,
    lat: -22.8103233
  },
  {
    lng: -43.4940874,
    lat: -22.8100864
  },
  {
    lng: -43.4940119,
    lat: -22.8096304
  },
  {
    lng: -43.4939786,
    lat: -22.8094295
  },
  {
    lng: -43.4940552,
    lat: -22.8087958
  },
  {
    lng: -43.494003,
    lat: -22.8082684
  },
  {
    lng: -43.4939163,
    lat: -22.8077084
  },
  {
    lng: -43.4936335,
    lat: -22.8070605
  },
  {
    lng: -43.4932797,
    lat: -22.8065303
  },
  {
    lng: -43.4932255,
    lat: -22.8061857
  },
  {
    lng: -43.4929876,
    lat: -22.805678
  },
  {
    lng: -43.4928264,
    lat: -22.8053325
  },
  {
    lng: -43.4927609,
    lat: -22.805192
  },
  {
    lng: -43.4925661,
    lat: -22.8049751
  },
  {
    lng: -43.4923508,
    lat: -22.8045214
  },
  {
    lng: -43.4921046,
    lat: -22.8037019
  },
  {
    lng: -43.4918443,
    lat: -22.8031187
  },
  {
    lng: -43.4917462,
    lat: -22.8026835
  },
  {
    lng: -43.4916452,
    lat: -22.8022352
  },
  {
    lng: -43.4918533,
    lat: -22.8018218
  },
  {
    lng: -43.4919724,
    lat: -22.801582
  },
  {
    lng: -43.4923226,
    lat: -22.8015188
  },
  {
    lng: -43.492347,
    lat: -22.8015185
  },
  {
    lng: -43.4927527,
    lat: -22.8015146
  },
  {
    lng: -43.4933351,
    lat: -22.8015201
  },
  {
    lng: -43.494042,
    lat: -22.8015482
  },
  {
    lng: -43.4944503,
    lat: -22.8011485
  },
  {
    lng: -43.4948929,
    lat: -22.8008286
  },
  {
    lng: -43.4952731,
    lat: -22.800475
  },
  {
    lng: -43.4958689,
    lat: -22.8000735
  },
  {
    lng: -43.4962748,
    lat: -22.7998364
  },
  {
    lng: -43.4973482,
    lat: -22.7991903
  },
  {
    lng: -43.4979013,
    lat: -22.7985973
  },
  {
    lng: -43.498581,
    lat: -22.7978975
  },
  {
    lng: -43.4988781,
    lat: -22.7977674
  },
  {
    lng: -43.4992941,
    lat: -22.7974224
  },
  {
    lng: -43.4994769,
    lat: -22.7970918
  },
  {
    lng: -43.4995267,
    lat: -22.7966354
  },
  {
    lng: -43.4997172,
    lat: -22.7964212
  },
  {
    lng: -43.499909,
    lat: -22.7960906
  },
  {
    lng: -43.500098,
    lat: -22.7960093
  },
  {
    lng: -43.5003323,
    lat: -22.7958952
  },
  {
    lng: -43.5006296,
    lat: -22.7957402
  },
  {
    lng: -43.5012312,
    lat: -22.7956295
  },
  {
    lng: -43.5019482,
    lat: -22.7956113
  },
  {
    lng: -43.502259,
    lat: -22.7950493
  },
  {
    lng: -43.5021901,
    lat: -22.7947995
  },
  {
    lng: -43.5022276,
    lat: -22.794642
  },
  {
    lng: -43.502223,
    lat: -22.7942431
  },
  {
    lng: -43.5022981,
    lat: -22.7939282
  },
  {
    lng: -43.5023945,
    lat: -22.793349
  },
  {
    lng: -43.5020601,
    lat: -22.7933728
  },
  {
    lng: -43.5019723,
    lat: -22.7933791
  },
  {
    lng: -43.5014904,
    lat: -22.7933671
  },
  {
    lng: -43.5012119,
    lat: -22.7932325
  },
  {
    lng: -43.5010047,
    lat: -22.7931669
  },
  {
    lng: -43.5006014,
    lat: -22.7931423
  },
  {
    lng: -43.5003849,
    lat: -22.7931108
  },
  {
    lng: -43.4999861,
    lat: -22.7930755
  },
  {
    lng: -43.4996721,
    lat: -22.7931032
  },
  {
    lng: -43.4994167,
    lat: -22.7930056
  },
  {
    lng: -43.4990422,
    lat: -22.7928528
  },
  {
    lng: -43.4986296,
    lat: -22.7927374
  },
  {
    lng: -43.49825,
    lat: -22.7926554
  },
  {
    lng: -43.497852,
    lat: -22.7926309
  },
  {
    lng: -43.4975018,
    lat: -22.7925767
  },
  {
    lng: -43.4971267,
    lat: -22.7925082
  },
  {
    lng: -43.4967538,
    lat: -22.7925905
  },
  {
    lng: -43.4962145,
    lat: -22.792575
  },
  {
    lng: -43.4958945,
    lat: -22.7924651
  },
  {
    lng: -43.4955286,
    lat: -22.7923633
  },
  {
    lng: -43.4951476,
    lat: -22.7922331
  },
  {
    lng: -43.4948107,
    lat: -22.7921082
  },
  {
    lng: -43.4940794,
    lat: -22.7919371
  },
  {
    lng: -43.4937178,
    lat: -22.7918586
  },
  {
    lng: -43.4933217,
    lat: -22.7918183
  },
  {
    lng: -43.4929236,
    lat: -22.7917971
  },
  {
    lng: -43.4925262,
    lat: -22.7918034
  },
  {
    lng: -43.492165,
    lat: -22.7916641
  },
  {
    lng: -43.4917805,
    lat: -22.7915388
  },
  {
    lng: -43.4914168,
    lat: -22.7913862
  },
  {
    lng: -43.4910394,
    lat: -22.7913602
  },
  {
    lng: -43.4906475,
    lat: -22.7913465
  },
  {
    lng: -43.4899628,
    lat: -22.7913141
  },
  {
    lng: -43.4895487,
    lat: -22.7912744
  },
  {
    lng: -43.4891944,
    lat: -22.7911877
  },
  {
    lng: -43.4888058,
    lat: -22.7911082
  },
  {
    lng: -43.4884506,
    lat: -22.7910165
  },
  {
    lng: -43.4880363,
    lat: -22.7908918
  },
  {
    lng: -43.4876633,
    lat: -22.7907857
  },
  {
    lng: -43.4872676,
    lat: -22.7906928
  },
  {
    lng: -43.4869194,
    lat: -22.7906129
  },
  {
    lng: -43.4865406,
    lat: -22.7905509
  },
  {
    lng: -43.4864902,
    lat: -22.7905539
  },
  {
    lng: -43.4860719,
    lat: -22.790586
  },
  {
    lng: -43.4856649,
    lat: -22.7905647
  },
  {
    lng: -43.4852524,
    lat: -22.790655
  },
  {
    lng: -43.4848542,
    lat: -22.7906563
  },
  {
    lng: -43.4844473,
    lat: -22.7906084
  },
  {
    lng: -43.4837522,
    lat: -22.7905291
  },
  {
    lng: -43.48337,
    lat: -22.7905514
  },
  {
    lng: -43.4830106,
    lat: -22.7905254
  },
  {
    lng: -43.4826132,
    lat: -22.7905426
  },
  {
    lng: -43.4822006,
    lat: -22.7906322
  },
  {
    lng: -43.4819608,
    lat: -22.7906121
  },
  {
    lng: -43.4815534,
    lat: -22.7904157
  },
  {
    lng: -43.4812676,
    lat: -22.7902612
  },
  {
    lng: -43.4812267,
    lat: -22.7902391
  },
  {
    lng: -43.4808859,
    lat: -22.7900483
  },
  {
    lng: -43.4805114,
    lat: -22.7898989
  },
  {
    lng: -43.4801167,
    lat: -22.7898044
  },
  {
    lng: -43.4797095,
    lat: -22.7896922
  },
  {
    lng: -43.4789782,
    lat: -22.7893168
  },
  {
    lng: -43.4784953,
    lat: -22.7890249
  },
  {
    lng: -43.4781057,
    lat: -22.7889529
  },
  {
    lng: -43.4776825,
    lat: -22.7889274
  },
  {
    lng: -43.4772841,
    lat: -22.7888445
  },
  {
    lng: -43.4768615,
    lat: -22.788758
  },
  {
    lng: -43.4765133,
    lat: -22.7886796
  },
  {
    lng: -43.4754477,
    lat: -22.7884018
  },
  {
    lng: -43.4750426,
    lat: -22.7883697
  },
  {
    lng: -43.4746848,
    lat: -22.7883755
  },
  {
    lng: -43.4742929,
    lat: -22.7884734
  },
  {
    lng: -43.4739324,
    lat: -22.7884725
  },
  {
    lng: -43.4735351,
    lat: -22.7884721
  },
  {
    lng: -43.4731373,
    lat: -22.7884193
  },
  {
    lng: -43.4728104,
    lat: -22.788376
  },
  {
    lng: -43.4724882,
    lat: -22.7883127
  },
  {
    lng: -43.4721037,
    lat: -22.7881941
  },
  {
    lng: -43.4717235,
    lat: -22.7880696
  },
  {
    lng: -43.4713665,
    lat: -22.7878811
  },
  {
    lng: -43.471129,
    lat: -22.787581
  },
  {
    lng: -43.4711151,
    lat: -22.7873833
  },
  {
    lng: -43.4711033,
    lat: -22.7872149
  },
  {
    lng: -43.470995,
    lat: -22.7870266
  },
  {
    lng: -43.4709084,
    lat: -22.786876
  },
  {
    lng: -43.4707826,
    lat: -22.7865574
  },
  {
    lng: -43.4706455,
    lat: -22.7861922
  },
  {
    lng: -43.4706017,
    lat: -22.7860527
  },
  {
    lng: -43.4706255,
    lat: -22.7856853
  },
  {
    lng: -43.4705291,
    lat: -22.7856062
  },
  {
    lng: -43.4702771,
    lat: -22.7853243
  },
  {
    lng: -43.4701201,
    lat: -22.7851814
  },
  {
    lng: -43.4702271,
    lat: -22.7848655
  },
  {
    lng: -43.4703204,
    lat: -22.7846795
  },
  {
    lng: -43.4703181,
    lat: -22.784627
  },
  {
    lng: -43.4699289,
    lat: -22.7845116
  },
  {
    lng: -43.4698401,
    lat: -22.7844813
  },
  {
    lng: -43.4695596,
    lat: -22.7843855
  },
  {
    lng: -43.4694727,
    lat: -22.784254
  },
  {
    lng: -43.469382,
    lat: -22.7839182
  },
  {
    lng: -43.4695051,
    lat: -22.7836058
  },
  {
    lng: -43.4694905,
    lat: -22.7833661
  },
  {
    lng: -43.4691728,
    lat: -22.7833491
  },
  {
    lng: -43.4689733,
    lat: -22.7833047
  },
  {
    lng: -43.4687904,
    lat: -22.783264
  },
  {
    lng: -43.4686673,
    lat: -22.7832104
  },
  {
    lng: -43.4685987,
    lat: -22.7831361
  },
  {
    lng: -43.4685021,
    lat: -22.7830316
  },
  {
    lng: -43.4684975,
    lat: -22.7826869
  },
  {
    lng: -43.4685477,
    lat: -22.7825864
  },
  {
    lng: -43.4686506,
    lat: -22.7824749
  },
  {
    lng: -43.4682685,
    lat: -22.7824033
  },
  {
    lng: -43.4678789,
    lat: -22.7824517
  },
  {
    lng: -43.4674739,
    lat: -22.782461
  },
  {
    lng: -43.4670722,
    lat: -22.7824706
  },
  {
    lng: -43.466864,
    lat: -22.7824566
  },
  {
    lng: -43.4658817,
    lat: -22.7822229
  },
  {
    lng: -43.4654915,
    lat: -22.7822886
  },
  {
    lng: -43.4653704,
    lat: -22.7822978
  },
  {
    lng: -43.4653017,
    lat: -22.782374
  },
  {
    lng: -43.4651178,
    lat: -22.7825782
  },
  {
    lng: -43.4650747,
    lat: -22.7829382
  },
  {
    lng: -43.4649872,
    lat: -22.7833008
  },
  {
    lng: -43.4648316,
    lat: -22.7836381
  },
  {
    lng: -43.4645845,
    lat: -22.783949
  },
  {
    lng: -43.4642218,
    lat: -22.7840903
  },
  {
    lng: -43.4638298,
    lat: -22.7841002
  },
  {
    lng: -43.4638146,
    lat: -22.7841005
  },
  {
    lng: -43.4634367,
    lat: -22.7840054
  },
  {
    lng: -43.4633419,
    lat: -22.78393
  },
  {
    lng: -43.4629697,
    lat: -22.7838113
  },
  {
    lng: -43.4626072,
    lat: -22.7836829
  },
  {
    lng: -43.4625726,
    lat: -22.78366
  },
  {
    lng: -43.4622906,
    lat: -22.7834732
  },
  {
    lng: -43.4621243,
    lat: -22.7831376
  },
  {
    lng: -43.4621415,
    lat: -22.7827726
  },
  {
    lng: -43.4622779,
    lat: -22.7824337
  },
  {
    lng: -43.4622393,
    lat: -22.782074
  },
  {
    lng: -43.4619425,
    lat: -22.7818349
  },
  {
    lng: -43.4616779,
    lat: -22.7817831
  },
  {
    lng: -43.4615581,
    lat: -22.7817596
  },
  {
    lng: -43.4615011,
    lat: -22.7817567
  },
  {
    lng: -43.4611654,
    lat: -22.7817396
  },
  {
    lng: -43.460829,
    lat: -22.7815596
  },
  {
    lng: -43.460798,
    lat: -22.7815402
  },
  {
    lng: -43.4607611,
    lat: -22.7815172
  },
  {
    lng: -43.460372,
    lat: -22.7815664
  },
  {
    lng: -43.4599934,
    lat: -22.7816736
  },
  {
    lng: -43.4596781,
    lat: -22.7817538
  },
  {
    lng: -43.4592837,
    lat: -22.7817714
  },
  {
    lng: -43.4591578,
    lat: -22.7817337
  },
  {
    lng: -43.4590681,
    lat: -22.7816722
  },
  {
    lng: -43.4588431,
    lat: -22.781518
  },
  {
    lng: -43.4586826,
    lat: -22.7813513
  },
  {
    lng: -43.4586346,
    lat: -22.7813014
  },
  {
    lng: -43.4586193,
    lat: -22.7812903
  },
  {
    lng: -43.4583257,
    lat: -22.7810764
  },
  {
    lng: -43.4581326,
    lat: -22.7809086
  },
  {
    lng: -43.4580398,
    lat: -22.780828
  },
  {
    lng: -43.4579695,
    lat: -22.7807764
  },
  {
    lng: -43.4578673,
    lat: -22.7807651
  },
  {
    lng: -43.4577709,
    lat: -22.7807543
  },
  {
    lng: -43.4576736,
    lat: -22.7807999
  },
  {
    lng: -43.4575235,
    lat: -22.7807175
  },
  {
    lng: -43.4574439,
    lat: -22.7806167
  },
  {
    lng: -43.4572923,
    lat: -22.7804248
  },
  {
    lng: -43.4572838,
    lat: -22.7804143
  },
  {
    lng: -43.457077,
    lat: -22.78011
  },
  {
    lng: -43.4568467,
    lat: -22.7798192
  },
  {
    lng: -43.4566316,
    lat: -22.7795083
  },
  {
    lng: -43.4564305,
    lat: -22.7791848
  },
  {
    lng: -43.456354,
    lat: -22.779101
  },
  {
    lng: -43.4559905,
    lat: -22.7790124
  },
  {
    lng: -43.4555943,
    lat: -22.7790386
  },
  {
    lng: -43.455195,
    lat: -22.7790127
  },
  {
    lng: -43.4548141,
    lat: -22.7789365
  },
  {
    lng: -43.45472,
    lat: -22.7788824
  },
  {
    lng: -43.4546231,
    lat: -22.7787828
  },
  {
    lng: -43.4544799,
    lat: -22.7785068
  },
  {
    lng: -43.4543472,
    lat: -22.7783748
  },
  {
    lng: -43.4542247,
    lat: -22.7783241
  },
  {
    lng: -43.4540589,
    lat: -22.7782016
  },
  {
    lng: -43.4540439,
    lat: -22.7781906
  },
  {
    lng: -43.4540167,
    lat: -22.7781899
  },
  {
    lng: -43.4536536,
    lat: -22.7781807
  },
  {
    lng: -43.4532621,
    lat: -22.7781737
  },
  {
    lng: -43.4528871,
    lat: -22.7780704
  },
  {
    lng: -43.4528058,
    lat: -22.7780498
  },
  {
    lng: -43.4527312,
    lat: -22.7780506
  },
  {
    lng: -43.4526038,
    lat: -22.7780521
  },
  {
    lng: -43.4524504,
    lat: -22.7780539
  },
  {
    lng: -43.452427,
    lat: -22.7780541
  },
  {
    lng: -43.4520231,
    lat: -22.7780651
  },
  {
    lng: -43.4519252,
    lat: -22.7780954
  },
  {
    lng: -43.4517235,
    lat: -22.7781578
  },
  {
    lng: -43.4516507,
    lat: -22.7781803
  },
  {
    lng: -43.451542,
    lat: -22.7781782
  },
  {
    lng: -43.45142,
    lat: -22.7781088
  },
  {
    lng: -43.4512142,
    lat: -22.7779918
  },
  {
    lng: -43.4509419,
    lat: -22.7778357
  },
  {
    lng: -43.4508803,
    lat: -22.7778004
  },
  {
    lng: -43.4504876,
    lat: -22.7777125
  },
  {
    lng: -43.4500899,
    lat: -22.7776057
  },
  {
    lng: -43.4497454,
    lat: -22.777433
  },
  {
    lng: -43.4494216,
    lat: -22.7772271
  },
  {
    lng: -43.4490711,
    lat: -22.777057
  },
  {
    lng: -43.4486877,
    lat: -22.776986
  },
  {
    lng: -43.448322,
    lat: -22.7768612
  },
  {
    lng: -43.4480031,
    lat: -22.7766538
  },
  {
    lng: -43.4479938,
    lat: -22.7766412
  },
  {
    lng: -43.4478749,
    lat: -22.7764725
  },
  {
    lng: -43.4478706,
    lat: -22.7764604
  },
  {
    lng: -43.4478086,
    lat: -22.7762875
  },
  {
    lng: -43.4478456,
    lat: -22.7759497
  },
  {
    lng: -43.4478395,
    lat: -22.7755837
  },
  {
    lng: -43.4477122,
    lat: -22.7752196
  },
  {
    lng: -43.4475772,
    lat: -22.7749689
  },
  {
    lng: -43.4474285,
    lat: -22.7748893
  },
  {
    lng: -43.4474161,
    lat: -22.774892
  },
  {
    lng: -43.4471729,
    lat: -22.7749448
  },
  {
    lng: -43.4469185,
    lat: -22.7750346
  },
  {
    lng: -43.4467437,
    lat: -22.7749421
  },
  {
    lng: -43.4464846,
    lat: -22.7747656
  },
  {
    lng: -43.4462734,
    lat: -22.7747778
  },
  {
    lng: -43.4458754,
    lat: -22.7747301
  },
  {
    lng: -43.4458242,
    lat: -22.7747116
  },
  {
    lng: -43.445433,
    lat: -22.7746789
  },
  {
    lng: -43.4451992,
    lat: -22.7746464
  },
  {
    lng: -43.4448366,
    lat: -22.774503
  },
  {
    lng: -43.4448293,
    lat: -22.7744987
  },
  {
    lng: -43.4447074,
    lat: -22.7744091
  },
  {
    lng: -43.4446587,
    lat: -22.7743732
  },
  {
    lng: -43.4446518,
    lat: -22.7743676
  },
  {
    lng: -43.4446212,
    lat: -22.7743423
  },
  {
    lng: -43.4443062,
    lat: -22.7740829
  },
  {
    lng: -43.4434852,
    lat: -22.7739093
  },
  {
    lng: -43.4428265,
    lat: -22.7737699
  },
  {
    lng: -43.4423121,
    lat: -22.7736469
  },
  {
    lng: -43.4421496,
    lat: -22.7728682
  },
  {
    lng: -43.4420122,
    lat: -22.7728597
  },
  {
    lng: -43.4417074,
    lat: -22.7727677
  },
  {
    lng: -43.4413444,
    lat: -22.7725199
  },
  {
    lng: -43.4413428,
    lat: -22.7724589
  },
  {
    lng: -43.4412649,
    lat: -22.7724064
  },
  {
    lng: -43.4412281,
    lat: -22.7723817
  },
  {
    lng: -43.4393737,
    lat: -22.7711327
  },
  {
    lng: -43.4384454,
    lat: -22.7705075
  },
  {
    lng: -43.4381351,
    lat: -22.7702984
  },
  {
    lng: -43.4364696,
    lat: -22.7691767
  },
  {
    lng: -43.4364668,
    lat: -22.7691756
  },
  {
    lng: -43.4350557,
    lat: -22.768605
  },
  {
    lng: -43.4350707,
    lat: -22.7685786
  },
  {
    lng: -43.435428,
    lat: -22.7679502
  },
  {
    lng: -43.4354469,
    lat: -22.7679169
  },
  {
    lng: -43.4354448,
    lat: -22.7679157
  },
  {
    lng: -43.4330185,
    lat: -22.7664376
  },
  {
    lng: -43.4322613,
    lat: -22.7659762
  },
  {
    lng: -43.4322241,
    lat: -22.7660313
  },
  {
    lng: -43.4321111,
    lat: -22.766199
  },
  {
    lng: -43.4318445,
    lat: -22.7664626
  },
  {
    lng: -43.4317977,
    lat: -22.7666229
  },
  {
    lng: -43.4317619,
    lat: -22.7666423
  },
  {
    lng: -43.4316097,
    lat: -22.7667246
  },
  {
    lng: -43.4313304,
    lat: -22.7668483
  },
  {
    lng: -43.4311585,
    lat: -22.7668811
  },
  {
    lng: -43.4307693,
    lat: -22.7669418
  },
  {
    lng: -43.4303797,
    lat: -22.7669729
  },
  {
    lng: -43.4303633,
    lat: -22.7669792
  },
  {
    lng: -43.429999,
    lat: -22.7671132
  },
  {
    lng: -43.429614,
    lat: -22.7671742
  },
  {
    lng: -43.4292282,
    lat: -22.7672279
  },
  {
    lng: -43.4288409,
    lat: -22.76729
  },
  {
    lng: -43.4287611,
    lat: -22.7672974
  },
  {
    lng: -43.4284496,
    lat: -22.7673264
  },
  {
    lng: -43.4280596,
    lat: -22.7674004
  },
  {
    lng: -43.4276618,
    lat: -22.7673968
  },
  {
    lng: -43.4275907,
    lat: -22.7673654
  },
  {
    lng: -43.4273065,
    lat: -22.7672404
  },
  {
    lng: -43.4270043,
    lat: -22.7670082
  },
  {
    lng: -43.4269591,
    lat: -22.7669744
  },
  {
    lng: -43.426699,
    lat: -22.76678
  },
  {
    lng: -43.4264004,
    lat: -22.7665442
  },
  {
    lng: -43.4260746,
    lat: -22.766334
  },
  {
    lng: -43.426003,
    lat: -22.7662848
  },
  {
    lng: -43.4257939,
    lat: -22.7661171
  },
  {
    lng: -43.4257058,
    lat: -22.7660465
  },
  {
    lng: -43.4255043,
    lat: -22.7658973
  },
  {
    lng: -43.4253963,
    lat: -22.7658173
  },
  {
    lng: -43.425086,
    lat: -22.7655972
  },
  {
    lng: -43.4249072,
    lat: -22.7654866
  },
  {
    lng: -43.4247599,
    lat: -22.7653954
  },
  {
    lng: -43.4245958,
    lat: -22.7652567
  },
  {
    lng: -43.4244706,
    lat: -22.7651509
  },
  {
    lng: -43.4241453,
    lat: -22.7649436
  },
  {
    lng: -43.4238533,
    lat: -22.7646839
  },
  {
    lng: -43.4235566,
    lat: -22.7644386
  },
  {
    lng: -43.4234542,
    lat: -22.7644039
  },
  {
    lng: -43.4234159,
    lat: -22.7643909
  },
  {
    lng: -43.4233985,
    lat: -22.764385
  },
  {
    lng: -43.4232667,
    lat: -22.7643404
  },
  {
    lng: -43.4229377,
    lat: -22.7643409
  },
  {
    lng: -43.4227419,
    lat: -22.76438
  },
  {
    lng: -43.4227298,
    lat: -22.7643824
  },
  {
    lng: -43.4225161,
    lat: -22.7644888
  },
  {
    lng: -43.4222279,
    lat: -22.7647326
  },
  {
    lng: -43.422182,
    lat: -22.7647712
  },
  {
    lng: -43.4219751,
    lat: -22.7650818
  },
  {
    lng: -43.421945,
    lat: -22.7651438
  },
  {
    lng: -43.4217784,
    lat: -22.7654731
  },
  {
    lng: -43.4216261,
    lat: -22.7655985
  },
  {
    lng: -43.4215811,
    lat: -22.7656312
  },
  {
    lng: -43.4213153,
    lat: -22.7658973
  },
  {
    lng: -43.421103,
    lat: -22.7662073
  },
  {
    lng: -43.420886,
    lat: -22.7664315
  },
  {
    lng: -43.4208425,
    lat: -22.7664764
  },
  {
    lng: -43.4206335,
    lat: -22.7667914
  },
  {
    lng: -43.4203891,
    lat: -22.7670795
  },
  {
    lng: -43.420149,
    lat: -22.767367
  },
  {
    lng: -43.4198844,
    lat: -22.7676447
  },
  {
    lng: -43.4196692,
    lat: -22.7679506
  },
  {
    lng: -43.4194222,
    lat: -22.7682367
  },
  {
    lng: -43.4194155,
    lat: -22.7682385
  },
  {
    lng: -43.4190359,
    lat: -22.7683243
  },
  {
    lng: -43.4190078,
    lat: -22.7683627
  },
  {
    lng: -43.4189737,
    lat: -22.7684221
  },
  {
    lng: -43.4186551,
    lat: -22.7684498
  },
  {
    lng: -43.4182729,
    lat: -22.7684354
  },
  {
    lng: -43.4182643,
    lat: -22.7684351
  },
  {
    lng: -43.4178709,
    lat: -22.7684295
  },
  {
    lng: -43.4174777,
    lat: -22.7684281
  },
  {
    lng: -43.4171157,
    lat: -22.7684241
  },
  {
    lng: -43.4170869,
    lat: -22.7684238
  },
  {
    lng: -43.4167358,
    lat: -22.7684408
  },
  {
    lng: -43.4166959,
    lat: -22.7684427
  },
  {
    lng: -43.41666,
    lat: -22.7684439
  },
  {
    lng: -43.4160271,
    lat: -22.7684687
  },
  {
    lng: -43.4160189,
    lat: -22.7684688
  },
  {
    lng: -43.415537,
    lat: -22.768477
  },
  {
    lng: -43.4151824,
    lat: -22.7684411
  },
  {
    lng: -43.4150298,
    lat: -22.7683919
  },
  {
    lng: -43.4149053,
    lat: -22.7683517
  },
  {
    lng: -43.4146504,
    lat: -22.7682162
  },
  {
    lng: -43.4146405,
    lat: -22.7682128
  },
  {
    lng: -43.4144533,
    lat: -22.7681488
  },
  {
    lng: -43.414426,
    lat: -22.7681395
  },
  {
    lng: -43.4142086,
    lat: -22.7680263
  },
  {
    lng: -43.4138872,
    lat: -22.7678014
  },
  {
    lng: -43.4138097,
    lat: -22.7677758
  },
  {
    lng: -43.4132139,
    lat: -22.7676572
  },
  {
    lng: -43.4130754,
    lat: -22.7676403
  },
  {
    lng: -43.4127567,
    lat: -22.7676541
  },
  {
    lng: -43.4123502,
    lat: -22.7676688
  },
  {
    lng: -43.4119068,
    lat: -22.7677633
  },
  {
    lng: -43.4117824,
    lat: -22.7678029
  },
  {
    lng: -43.4117795,
    lat: -22.7678038
  },
  {
    lng: -43.4114226,
    lat: -22.7679175
  },
  {
    lng: -43.4113607,
    lat: -22.7679372
  },
  {
    lng: -43.4111805,
    lat: -22.7680656
  },
  {
    lng: -43.4111013,
    lat: -22.7681199
  },
  {
    lng: -43.4108878,
    lat: -22.7681436
  },
  {
    lng: -43.4107447,
    lat: -22.7680692
  },
  {
    lng: -43.4107555,
    lat: -22.7680614
  },
  {
    lng: -43.4107986,
    lat: -22.768033
  },
  {
    lng: -43.411371,
    lat: -22.7676556
  },
  {
    lng: -43.411403,
    lat: -22.7676345
  },
  {
    lng: -43.4114378,
    lat: -22.7676116
  },
  {
    lng: -43.4117631,
    lat: -22.7673915
  },
  {
    lng: -43.4120142,
    lat: -22.7672216
  },
  {
    lng: -43.4120317,
    lat: -22.7672098
  },
  {
    lng: -43.4123878,
    lat: -22.7669832
  },
  {
    lng: -43.4125321,
    lat: -22.7668913
  },
  {
    lng: -43.4127331,
    lat: -22.7667678
  },
  {
    lng: -43.4133711,
    lat: -22.7663758
  },
  {
    lng: -43.4137476,
    lat: -22.766161
  },
  {
    lng: -43.4141242,
    lat: -22.7659462
  },
  {
    lng: -43.4143711,
    lat: -22.7658143
  },
  {
    lng: -43.4145689,
    lat: -22.7657087
  },
  {
    lng: -43.4148339,
    lat: -22.765565
  },
  {
    lng: -43.4149884,
    lat: -22.7654812
  },
  {
    lng: -43.4157008,
    lat: -22.765134
  },
  {
    lng: -43.4159942,
    lat: -22.764991
  },
  {
    lng: -43.415996,
    lat: -22.7647388
  },
  {
    lng: -43.415969,
    lat: -22.7643036
  },
  {
    lng: -43.4159065,
    lat: -22.7639158
  },
  {
    lng: -43.4157644,
    lat: -22.7633672
  },
  {
    lng: -43.4153858,
    lat: -22.7620067
  },
  {
    lng: -43.415053,
    lat: -22.7608108
  },
  {
    lng: -43.4150507,
    lat: -22.7608026
  },
  {
    lng: -43.4149722,
    lat: -22.7605208
  },
  {
    lng: -43.4148268,
    lat: -22.759998
  },
  {
    lng: -43.4146609,
    lat: -22.759402
  },
  {
    lng: -43.414637,
    lat: -22.759316
  },
  {
    lng: -43.4146124,
    lat: -22.7592276
  },
  {
    lng: -43.4145687,
    lat: -22.7590706
  },
  {
    lng: -43.4144929,
    lat: -22.7587982
  },
  {
    lng: -43.4144865,
    lat: -22.7587754
  },
  {
    lng: -43.4144738,
    lat: -22.7587297
  },
  {
    lng: -43.4143437,
    lat: -22.7582623
  },
  {
    lng: -43.4142586,
    lat: -22.7579562
  },
  {
    lng: -43.4140736,
    lat: -22.7572915
  },
  {
    lng: -43.414035,
    lat: -22.7571528
  },
  {
    lng: -43.4140306,
    lat: -22.7571369
  },
  {
    lng: -43.4140296,
    lat: -22.757133
  },
  {
    lng: -43.4139226,
    lat: -22.7566935
  },
  {
    lng: -43.4139028,
    lat: -22.7565949
  },
  {
    lng: -43.4138909,
    lat: -22.7565351
  },
  {
    lng: -43.4138827,
    lat: -22.7564943
  },
  {
    lng: -43.4138722,
    lat: -22.7564419
  },
  {
    lng: -43.4138714,
    lat: -22.7564377
  },
  {
    lng: -43.4138711,
    lat: -22.7564358
  },
  {
    lng: -43.4138572,
    lat: -22.756351
  },
  {
    lng: -43.413843,
    lat: -22.7562643
  },
  {
    lng: -43.4138429,
    lat: -22.7562612
  },
  {
    lng: -43.4138373,
    lat: -22.7560739
  },
  {
    lng: -43.4138376,
    lat: -22.7560691
  },
  {
    lng: -43.4138458,
    lat: -22.7559531
  },
  {
    lng: -43.4138543,
    lat: -22.7558323
  },
  {
    lng: -43.4139128,
    lat: -22.7556318
  },
  {
    lng: -43.413914,
    lat: -22.7556276
  },
  {
    lng: -43.4140681,
    lat: -22.7553066
  },
  {
    lng: -43.4140846,
    lat: -22.7552724
  },
  {
    lng: -43.4141559,
    lat: -22.7551234
  },
  {
    lng: -43.4141769,
    lat: -22.7550794
  },
  {
    lng: -43.4142684,
    lat: -22.7548882
  },
  {
    lng: -43.4143063,
    lat: -22.7548091
  },
  {
    lng: -43.4143303,
    lat: -22.7547617
  },
  {
    lng: -43.414365,
    lat: -22.7546934
  },
  {
    lng: -43.4144366,
    lat: -22.7545525
  },
  {
    lng: -43.4144389,
    lat: -22.7545479
  },
  {
    lng: -43.4144826,
    lat: -22.754462
  },
  {
    lng: -43.4144882,
    lat: -22.7544509
  },
  {
    lng: -43.414601,
    lat: -22.754249
  },
  {
    lng: -43.4146104,
    lat: -22.7542321
  },
  {
    lng: -43.4147152,
    lat: -22.7540551
  },
  {
    lng: -43.4148305,
    lat: -22.7539325
  },
  {
    lng: -43.4149859,
    lat: -22.7537918
  },
  {
    lng: -43.4150295,
    lat: -22.7537594
  },
  {
    lng: -43.4151504,
    lat: -22.7536693
  },
  {
    lng: -43.4151643,
    lat: -22.7536601
  },
  {
    lng: -43.4153458,
    lat: -22.7535391
  },
  {
    lng: -43.4154318,
    lat: -22.7534817
  },
  {
    lng: -43.4156042,
    lat: -22.7533918
  },
  {
    lng: -43.4156965,
    lat: -22.7533437
  },
  {
    lng: -43.415748,
    lat: -22.7533223
  },
  {
    lng: -43.4159451,
    lat: -22.7532401
  },
  {
    lng: -43.415952,
    lat: -22.7532373
  },
  {
    lng: -43.4162504,
    lat: -22.7531293
  },
  {
    lng: -43.4163072,
    lat: -22.7531079
  },
  {
    lng: -43.4164089,
    lat: -22.7530697
  },
  {
    lng: -43.4166468,
    lat: -22.7529803
  },
  {
    lng: -43.4167194,
    lat: -22.752953
  },
  {
    lng: -43.4168901,
    lat: -22.7528935
  },
  {
    lng: -43.4171376,
    lat: -22.7528072
  },
  {
    lng: -43.4171713,
    lat: -22.7527954
  },
  {
    lng: -43.4172082,
    lat: -22.7527825
  },
  {
    lng: -43.4174844,
    lat: -22.7526782
  },
  {
    lng: -43.4176436,
    lat: -22.752618
  },
  {
    lng: -43.4177199,
    lat: -22.7525892
  },
  {
    lng: -43.4182983,
    lat: -22.7523569
  },
  {
    lng: -43.4183037,
    lat: -22.7523548
  },
  {
    lng: -43.4184031,
    lat: -22.7523181
  },
  {
    lng: -43.4193125,
    lat: -22.7519827
  },
  {
    lng: -43.4199309,
    lat: -22.7517429
  },
  {
    lng: -43.4203666,
    lat: -22.751574
  },
  {
    lng: -43.4207282,
    lat: -22.7514023
  },
  {
    lng: -43.4208119,
    lat: -22.7513626
  },
  {
    lng: -43.4208265,
    lat: -22.7513557
  },
  {
    lng: -43.42125,
    lat: -22.7510942
  },
  {
    lng: -43.4214745,
    lat: -22.7509071
  },
  {
    lng: -43.4217696,
    lat: -22.7506116
  },
  {
    lng: -43.4218213,
    lat: -22.7505598
  },
  {
    lng: -43.4220316,
    lat: -22.7503381
  },
  {
    lng: -43.4221913,
    lat: -22.7501507
  },
  {
    lng: -43.4223511,
    lat: -22.7499632
  },
  {
    lng: -43.4224399,
    lat: -22.7498366
  },
  {
    lng: -43.4224529,
    lat: -22.7498181
  },
  {
    lng: -43.4225148,
    lat: -22.7497299
  },
  {
    lng: -43.4226257,
    lat: -22.7495451
  },
  {
    lng: -43.4227366,
    lat: -22.7493254
  },
  {
    lng: -43.4227735,
    lat: -22.7492523
  },
  {
    lng: -43.4227866,
    lat: -22.7492261
  },
  {
    lng: -43.4229141,
    lat: -22.748971
  },
  {
    lng: -43.422998,
    lat: -22.7488033
  },
  {
    lng: -43.4229989,
    lat: -22.7488013
  },
  {
    lng: -43.4230313,
    lat: -22.7487299
  },
  {
    lng: -43.423153,
    lat: -22.7484614
  },
  {
    lng: -43.4231617,
    lat: -22.7484421
  },
  {
    lng: -43.4231627,
    lat: -22.7484403
  },
  {
    lng: -43.4232905,
    lat: -22.7482076
  },
  {
    lng: -43.4233306,
    lat: -22.7481347
  },
  {
    lng: -43.4235127,
    lat: -22.7478032
  },
  {
    lng: -43.4235623,
    lat: -22.7477129
  },
  {
    lng: -43.4235902,
    lat: -22.7476621
  },
  {
    lng: -43.4236401,
    lat: -22.7475713
  },
  {
    lng: -43.423883,
    lat: -22.7471292
  },
  {
    lng: -43.4239019,
    lat: -22.7470947
  },
  {
    lng: -43.4239189,
    lat: -22.7470638
  },
  {
    lng: -43.4239419,
    lat: -22.7470213
  },
  {
    lng: -43.4239537,
    lat: -22.7469996
  },
  {
    lng: -43.4240963,
    lat: -22.7467362
  },
  {
    lng: -43.424297,
    lat: -22.7463655
  },
  {
    lng: -43.4243845,
    lat: -22.7462039
  },
  {
    lng: -43.4244021,
    lat: -22.7461713
  },
  {
    lng: -43.4245693,
    lat: -22.7458506
  },
  {
    lng: -43.4246119,
    lat: -22.7457688
  },
  {
    lng: -43.4247634,
    lat: -22.7454781
  },
  {
    lng: -43.4248009,
    lat: -22.7454063
  },
  {
    lng: -43.4248674,
    lat: -22.7452786
  },
  {
    lng: -43.4250113,
    lat: -22.7450026
  },
  {
    lng: -43.4250944,
    lat: -22.7448713
  },
  {
    lng: -43.4254399,
    lat: -22.744325
  },
  {
    lng: -43.4254964,
    lat: -22.7442357
  },
  {
    lng: -43.4258177,
    lat: -22.7436389
  },
  {
    lng: -43.4258719,
    lat: -22.7435383
  },
  {
    lng: -43.4259672,
    lat: -22.7433614
  },
  {
    lng: -43.4259815,
    lat: -22.7433287
  },
  {
    lng: -43.4260024,
    lat: -22.7432813
  },
  {
    lng: -43.4262103,
    lat: -22.742809
  },
  {
    lng: -43.4262232,
    lat: -22.7427796
  },
  {
    lng: -43.4262532,
    lat: -22.7427113
  },
  {
    lng: -43.4265033,
    lat: -22.7421431
  },
  {
    lng: -43.426602,
    lat: -22.7419189
  },
  {
    lng: -43.4266106,
    lat: -22.7418994
  },
  {
    lng: -43.4266372,
    lat: -22.741831
  },
  {
    lng: -43.4267536,
    lat: -22.7415327
  },
  {
    lng: -43.4267856,
    lat: -22.7414509
  },
  {
    lng: -43.4270198,
    lat: -22.7408506
  },
  {
    lng: -43.4270429,
    lat: -22.7407905
  },
  {
    lng: -43.4271014,
    lat: -22.7406381
  },
  {
    lng: -43.4272778,
    lat: -22.7401783
  },
  {
    lng: -43.427287,
    lat: -22.7401542
  },
  {
    lng: -43.4273451,
    lat: -22.7400007
  },
  {
    lng: -43.4273559,
    lat: -22.7399721
  },
  {
    lng: -43.4276111,
    lat: -22.7392972
  },
  {
    lng: -43.4278435,
    lat: -22.7386826
  },
  {
    lng: -43.4278477,
    lat: -22.7386716
  },
  {
    lng: -43.4280144,
    lat: -22.7382308
  },
  {
    lng: -43.4280225,
    lat: -22.7382093
  },
  {
    lng: -43.428134,
    lat: -22.7379145
  },
  {
    lng: -43.4282383,
    lat: -22.7376536
  },
  {
    lng: -43.4284246,
    lat: -22.7371871
  },
  {
    lng: -43.4285677,
    lat: -22.7368289
  },
  {
    lng: -43.4286729,
    lat: -22.7365655
  },
  {
    lng: -43.4287466,
    lat: -22.7363809
  },
  {
    lng: -43.4289867,
    lat: -22.7357799
  },
  {
    lng: -43.4291048,
    lat: -22.7354723
  },
  {
    lng: -43.4291498,
    lat: -22.7353553
  },
  {
    lng: -43.4293989,
    lat: -22.7347066
  },
  {
    lng: -43.4296357,
    lat: -22.7340901
  },
  {
    lng: -43.4297184,
    lat: -22.7338748
  },
  {
    lng: -43.4297191,
    lat: -22.7338728
  },
  {
    lng: -43.4297198,
    lat: -22.733871
  },
  {
    lng: -43.4297246,
    lat: -22.7338587
  },
  {
    lng: -43.4297257,
    lat: -22.7338557
  },
  {
    lng: -43.4297273,
    lat: -22.7338514
  },
  {
    lng: -43.4297423,
    lat: -22.7338125
  },
  {
    lng: -43.4297616,
    lat: -22.7337625
  },
  {
    lng: -43.42977,
    lat: -22.7337407
  },
  {
    lng: -43.4298087,
    lat: -22.7336402
  },
  {
    lng: -43.430114,
    lat: -22.732847
  },
  {
    lng: -43.4301237,
    lat: -22.732822
  },
  {
    lng: -43.4301717,
    lat: -22.7326971
  },
  {
    lng: -43.4303521,
    lat: -22.7322286
  },
  {
    lng: -43.4303841,
    lat: -22.7321509
  },
  {
    lng: -43.430664,
    lat: -22.7314722
  },
  {
    lng: -43.4310538,
    lat: -22.7305271
  },
  {
    lng: -43.4310792,
    lat: -22.7304656
  },
  {
    lng: -43.4311437,
    lat: -22.7303092
  },
  {
    lng: -43.4313118,
    lat: -22.7299014
  },
  {
    lng: -43.4313386,
    lat: -22.7298355
  },
  {
    lng: -43.4314266,
    lat: -22.7296185
  },
  {
    lng: -43.4315174,
    lat: -22.7293945
  },
  {
    lng: -43.4316576,
    lat: -22.7290487
  },
  {
    lng: -43.4316704,
    lat: -22.729017
  },
  {
    lng: -43.4316791,
    lat: -22.7289956
  },
  {
    lng: -43.4316913,
    lat: -22.7289655
  },
  {
    lng: -43.4317047,
    lat: -22.7289323
  },
  {
    lng: -43.431739,
    lat: -22.7288479
  },
  {
    lng: -43.4318343,
    lat: -22.7286129
  },
  {
    lng: -43.431889,
    lat: -22.7284779
  },
  {
    lng: -43.4319143,
    lat: -22.7284155
  },
  {
    lng: -43.4319576,
    lat: -22.7283154
  },
  {
    lng: -43.4319662,
    lat: -22.7282956
  },
  {
    lng: -43.4321701,
    lat: -22.7278243
  },
  {
    lng: -43.4322964,
    lat: -22.7275777
  },
  {
    lng: -43.4325114,
    lat: -22.7271579
  },
  {
    lng: -43.432531,
    lat: -22.7271195
  },
  {
    lng: -43.4325768,
    lat: -22.7270396
  },
  {
    lng: -43.4327904,
    lat: -22.7266671
  },
  {
    lng: -43.433006,
    lat: -22.7262912
  },
  {
    lng: -43.4330474,
    lat: -22.7262188
  },
  {
    lng: -43.4330499,
    lat: -22.7262146
  },
  {
    lng: -43.4330523,
    lat: -22.7262107
  },
  {
    lng: -43.4333034,
    lat: -22.7258048
  },
  {
    lng: -43.4339847,
    lat: -22.7247033
  },
  {
    lng: -43.4340841,
    lat: -22.7245425
  },
  {
    lng: -43.4337586,
    lat: -22.7243515
  },
  {
    lng: -43.4331995,
    lat: -22.7240236
  },
  {
    lng: -43.4328079,
    lat: -22.723794
  },
  {
    lng: -43.432683,
    lat: -22.7237207
  },
  {
    lng: -43.4326699,
    lat: -22.723713
  },
  {
    lng: -43.4326494,
    lat: -22.723701
  },
  {
    lng: -43.4323703,
    lat: -22.7235373
  },
  {
    lng: -43.4322383,
    lat: -22.7234557
  },
  {
    lng: -43.4321189,
    lat: -22.7233477
  },
  {
    lng: -43.4321141,
    lat: -22.7233408
  },
  {
    lng: -43.4320422,
    lat: -22.7232369
  },
  {
    lng: -43.4319612,
    lat: -22.7230919
  },
  {
    lng: -43.4317324,
    lat: -22.7226912
  },
  {
    lng: -43.4316722,
    lat: -22.7225865
  },
  {
    lng: -43.4316141,
    lat: -22.7224854
  },
  {
    lng: -43.4315843,
    lat: -22.7224336
  },
  {
    lng: -43.4313882,
    lat: -22.7220925
  },
  {
    lng: -43.4313021,
    lat: -22.721958
  },
  {
    lng: -43.4312975,
    lat: -22.7219509
  },
  {
    lng: -43.4311179,
    lat: -22.7217826
  },
  {
    lng: -43.4310929,
    lat: -22.7217592
  },
  {
    lng: -43.4310816,
    lat: -22.7217486
  },
  {
    lng: -43.4308657,
    lat: -22.7215463
  },
  {
    lng: -43.4305145,
    lat: -22.7212699
  },
  {
    lng: -43.4302528,
    lat: -22.7210639
  },
  {
    lng: -43.430091,
    lat: -22.7209366
  },
  {
    lng: -43.4300192,
    lat: -22.7208801
  },
  {
    lng: -43.4299474,
    lat: -22.7208236
  },
  {
    lng: -43.4299409,
    lat: -22.7208165
  },
  {
    lng: -43.4298279,
    lat: -22.7206936
  },
  {
    lng: -43.4296535,
    lat: -22.7204525
  },
  {
    lng: -43.4296498,
    lat: -22.7204475
  },
  {
    lng: -43.4293505,
    lat: -22.7199251
  },
  {
    lng: -43.4293369,
    lat: -22.7199013
  },
  {
    lng: -43.4293146,
    lat: -22.7198625
  },
  {
    lng: -43.4293085,
    lat: -22.7198518
  },
  {
    lng: -43.4293069,
    lat: -22.719849
  },
  {
    lng: -43.4293058,
    lat: -22.719847
  },
  {
    lng: -43.429299,
    lat: -22.7198349
  },
  {
    lng: -43.4292643,
    lat: -22.7197734
  },
  {
    lng: -43.4291387,
    lat: -22.7195503
  },
  {
    lng: -43.4289036,
    lat: -22.7191328
  },
  {
    lng: -43.4288733,
    lat: -22.719079
  },
  {
    lng: -43.4287885,
    lat: -22.7189283
  },
  {
    lng: -43.4285246,
    lat: -22.7184598
  },
  {
    lng: -43.42798,
    lat: -22.7175158
  },
  {
    lng: -43.4278882,
    lat: -22.7173568
  },
  {
    lng: -43.4278779,
    lat: -22.7173389
  },
  {
    lng: -43.4278431,
    lat: -22.7172787
  },
  {
    lng: -43.4278178,
    lat: -22.7172348
  },
  {
    lng: -43.4277235,
    lat: -22.7170807
  },
  {
    lng: -43.427612,
    lat: -22.7168985
  },
  {
    lng: -43.4275899,
    lat: -22.7168625
  },
  {
    lng: -43.4275882,
    lat: -22.7168597
  },
  {
    lng: -43.4275876,
    lat: -22.7168586
  },
  {
    lng: -43.4274833,
    lat: -22.7166598
  },
  {
    lng: -43.427314,
    lat: -22.7163372
  },
  {
    lng: -43.4272563,
    lat: -22.7161192
  },
  {
    lng: -43.4272269,
    lat: -22.7159006
  },
  {
    lng: -43.4272167,
    lat: -22.715775
  },
  {
    lng: -43.4272033,
    lat: -22.7156079
  },
  {
    lng: -43.4272031,
    lat: -22.7156059
  },
  {
    lng: -43.4272036,
    lat: -22.7155951
  },
  {
    lng: -43.4272144,
    lat: -22.7153542
  },
  {
    lng: -43.4272333,
    lat: -22.7152357
  },
  {
    lng: -43.4272907,
    lat: -22.7150705
  },
  {
    lng: -43.4273225,
    lat: -22.7149786
  },
  {
    lng: -43.4273709,
    lat: -22.7148706
  },
  {
    lng: -43.4274866,
    lat: -22.7146648
  },
  {
    lng: -43.4267661,
    lat: -22.7144969
  },
  {
    lng: -43.424875,
    lat: -22.7140357
  },
  {
    lng: -43.4249076,
    lat: -22.713403
  },
  {
    lng: -43.4249427,
    lat: -22.712716
  },
  {
    lng: -43.4245659,
    lat: -22.7126382
  },
  {
    lng: -43.4236436,
    lat: -22.7124169
  },
  {
    lng: -43.4226801,
    lat: -22.712225
  },
  {
    lng: -43.422398,
    lat: -22.7121256
  },
  {
    lng: -43.4218576,
    lat: -22.7118791
  },
  {
    lng: -43.4221602,
    lat: -22.7113023
  },
  {
    lng: -43.4211589,
    lat: -22.710755
  },
  {
    lng: -43.421166,
    lat: -22.7103071
  },
  {
    lng: -43.4212181,
    lat: -22.71009
  },
  {
    lng: -43.4214338,
    lat: -22.7096225
  },
  {
    lng: -43.4225238,
    lat: -22.7076843
  },
  {
    lng: -43.4222172,
    lat: -22.7073444
  },
  {
    lng: -43.4216611,
    lat: -22.706434
  },
  {
    lng: -43.421641,
    lat: -22.7064013
  },
  {
    lng: -43.4205992,
    lat: -22.7054056
  },
  {
    lng: -43.4203972,
    lat: -22.7051914
  },
  {
    lng: -43.4198066,
    lat: -22.7046654
  },
  {
    lng: -43.4190738,
    lat: -22.7039718
  },
  {
    lng: -43.4189488,
    lat: -22.703919
  },
  {
    lng: -43.417907,
    lat: -22.7036839
  },
  {
    lng: -43.4174884,
    lat: -22.7031821
  },
  {
    lng: -43.417243,
    lat: -22.7030207
  },
  {
    lng: -43.4168319,
    lat: -22.7028767
  },
  {
    lng: -43.4166643,
    lat: -22.7027251
  },
  {
    lng: -43.4165374,
    lat: -22.7025044
  },
  {
    lng: -43.4164839,
    lat: -22.7022419
  },
  {
    lng: -43.4164726,
    lat: -22.7019591
  },
  {
    lng: -43.4164162,
    lat: -22.7017003
  },
  {
    lng: -43.4162008,
    lat: -22.7014678
  },
  {
    lng: -43.4159775,
    lat: -22.7013311
  },
  {
    lng: -43.4155777,
    lat: -22.7011447
  },
  {
    lng: -43.4152504,
    lat: -22.7010782
  },
  {
    lng: -43.4132867,
    lat: -22.701616
  },
  {
    lng: -43.412274,
    lat: -22.701566
  },
  {
    lng: -43.4120838,
    lat: -22.7015146
  },
  {
    lng: -43.4116941,
    lat: -22.7013735
  },
  {
    lng: -43.411432,
    lat: -22.70123
  },
  {
    lng: -43.4102465,
    lat: -22.6997759
  },
  {
    lng: -43.4101528,
    lat: -22.6996731
  },
  {
    lng: -43.4100884,
    lat: -22.6996054
  },
  {
    lng: -43.4100637,
    lat: -22.6995368
  },
  {
    lng: -43.4100606,
    lat: -22.6995105
  },
  {
    lng: -43.4100524,
    lat: -22.6994418
  },
  {
    lng: -43.4100753,
    lat: -22.69926
  },
  {
    lng: -43.4100774,
    lat: -22.6992429
  },
  {
    lng: -43.4101185,
    lat: -22.6990619
  },
  {
    lng: -43.4102051,
    lat: -22.698681
  },
  {
    lng: -43.4102368,
    lat: -22.6985123
  },
  {
    lng: -43.410238,
    lat: -22.6982482
  },
  {
    lng: -43.410195,
    lat: -22.69787
  },
  {
    lng: -43.4101904,
    lat: -22.6977036
  },
  {
    lng: -43.4101736,
    lat: -22.6975345
  },
  {
    lng: -43.4101322,
    lat: -22.6974324
  },
  {
    lng: -43.4100884,
    lat: -22.6973587
  },
  {
    lng: -43.4099923,
    lat: -22.6971967
  },
  {
    lng: -43.4098742,
    lat: -22.6970319
  },
  {
    lng: -43.4098111,
    lat: -22.6969621
  },
  {
    lng: -43.4097558,
    lat: -22.6969009
  },
  {
    lng: -43.4097527,
    lat: -22.6968975
  },
  {
    lng: -43.4097371,
    lat: -22.6968801
  },
  {
    lng: -43.4097358,
    lat: -22.6968789
  },
  {
    lng: -43.4097324,
    lat: -22.6968752
  },
  {
    lng: -43.409691,
    lat: -22.6968315
  },
  {
    lng: -43.4096079,
    lat: -22.6967436
  },
  {
    lng: -43.4095497,
    lat: -22.696682
  },
  {
    lng: -43.4095415,
    lat: -22.6966733
  },
  {
    lng: -43.4095369,
    lat: -22.6966682
  },
  {
    lng: -43.4095123,
    lat: -22.6966408
  },
  {
    lng: -43.4092919,
    lat: -22.6963947
  },
  {
    lng: -43.4092314,
    lat: -22.6963017
  },
  {
    lng: -43.4091212,
    lat: -22.6961326
  },
  {
    lng: -43.4090907,
    lat: -22.6960857
  },
  {
    lng: -43.4090897,
    lat: -22.6960836
  },
  {
    lng: -43.4090675,
    lat: -22.6960354
  },
  {
    lng: -43.4090534,
    lat: -22.6960048
  },
  {
    lng: -43.4090396,
    lat: -22.695975
  },
  {
    lng: -43.4089761,
    lat: -22.6958371
  },
  {
    lng: -43.4089631,
    lat: -22.6958123
  },
  {
    lng: -43.4089417,
    lat: -22.6957712
  },
  {
    lng: -43.4088422,
    lat: -22.6956469
  },
  {
    lng: -43.4086547,
    lat: -22.695546
  },
  {
    lng: -43.4085641,
    lat: -22.6954883
  },
  {
    lng: -43.4085533,
    lat: -22.6954717
  },
  {
    lng: -43.4085196,
    lat: -22.6954195
  },
  {
    lng: -43.4084638,
    lat: -22.6952798
  },
  {
    lng: -43.4084176,
    lat: -22.6951204
  },
  {
    lng: -43.408402,
    lat: -22.694983
  },
  {
    lng: -43.4083746,
    lat: -22.6948731
  },
  {
    lng: -43.4083615,
    lat: -22.6948515
  },
  {
    lng: -43.4082673,
    lat: -22.6946955
  },
  {
    lng: -43.4082349,
    lat: -22.6946419
  },
  {
    lng: -43.4082228,
    lat: -22.6946218
  },
  {
    lng: -43.4082153,
    lat: -22.6946094
  },
  {
    lng: -43.4081465,
    lat: -22.6945052
  },
  {
    lng: -43.4080718,
    lat: -22.6944462
  },
  {
    lng: -43.4080374,
    lat: -22.6944245
  },
  {
    lng: -43.4079888,
    lat: -22.6943938
  },
  {
    lng: -43.4079771,
    lat: -22.6943864
  },
  {
    lng: -43.4078376,
    lat: -22.6943515
  },
  {
    lng: -43.4076943,
    lat: -22.694331
  },
  {
    lng: -43.4075257,
    lat: -22.6943089
  },
  {
    lng: -43.4073776,
    lat: -22.6942933
  },
  {
    lng: -43.4072183,
    lat: -22.6942936
  },
  {
    lng: -43.4071537,
    lat: -22.6943032
  },
  {
    lng: -43.4070892,
    lat: -22.6943128
  },
  {
    lng: -43.406853,
    lat: -22.6943705
  },
  {
    lng: -43.4065061,
    lat: -22.6944749
  },
  {
    lng: -43.406191,
    lat: -22.6945215
  },
  {
    lng: -43.4059944,
    lat: -22.6945914
  },
  {
    lng: -43.4059823,
    lat: -22.6945957
  },
  {
    lng: -43.4059051,
    lat: -22.694616
  },
  {
    lng: -43.405765,
    lat: -22.6946528
  },
  {
    lng: -43.4056612,
    lat: -22.6946801
  },
  {
    lng: -43.4056439,
    lat: -22.6946846
  },
  {
    lng: -43.4056312,
    lat: -22.694688
  },
  {
    lng: -43.4055832,
    lat: -22.6947006
  },
  {
    lng: -43.4055703,
    lat: -22.694704
  },
  {
    lng: -43.4055648,
    lat: -22.6947054
  },
  {
    lng: -43.4055618,
    lat: -22.6947062
  },
  {
    lng: -43.4055604,
    lat: -22.6947066
  },
  {
    lng: -43.405559,
    lat: -22.694707
  },
  {
    lng: -43.4055556,
    lat: -22.6947078
  },
  {
    lng: -43.4055524,
    lat: -22.6947087
  },
  {
    lng: -43.4055343,
    lat: -22.6947113
  },
  {
    lng: -43.4054108,
    lat: -22.6947291
  },
  {
    lng: -43.4053451,
    lat: -22.6947385
  },
  {
    lng: -43.4051006,
    lat: -22.6947715
  },
  {
    lng: -43.4050736,
    lat: -22.694778
  },
  {
    lng: -43.4048727,
    lat: -22.6948264
  },
  {
    lng: -43.4047546,
    lat: -22.6948539
  },
  {
    lng: -43.4046009,
    lat: -22.6949107
  },
  {
    lng: -43.4045129,
    lat: -22.6949771
  },
  {
    lng: -43.404425,
    lat: -22.6950434
  },
  {
    lng: -43.4043016,
    lat: -22.6951211
  },
  {
    lng: -43.4041762,
    lat: -22.6951933
  },
  {
    lng: -43.4041503,
    lat: -22.6952082
  },
  {
    lng: -43.4039828,
    lat: -22.6952713
  },
  {
    lng: -43.4038564,
    lat: -22.6953125
  },
  {
    lng: -43.4036779,
    lat: -22.6953482
  },
  {
    lng: -43.403601,
    lat: -22.695351
  },
  {
    lng: -43.4035133,
    lat: -22.6953556
  },
  {
    lng: -43.4034966,
    lat: -22.6953565
  },
  {
    lng: -43.4033044,
    lat: -22.6953373
  },
  {
    lng: -43.4031863,
    lat: -22.6953263
  },
  {
    lng: -43.4030078,
    lat: -22.6953428
  },
  {
    lng: -43.4028951,
    lat: -22.695362
  },
  {
    lng: -43.4025133,
    lat: -22.6954777
  },
  {
    lng: -43.4024472,
    lat: -22.695483
  },
  {
    lng: -43.4023811,
    lat: -22.6954883
  },
  {
    lng: -43.4023559,
    lat: -22.695481
  },
  {
    lng: -43.4022405,
    lat: -22.6954478
  },
  {
    lng: -43.4017007,
    lat: -22.6952308
  },
  {
    lng: -43.4016231,
    lat: -22.6951996
  },
  {
    lng: -43.4014861,
    lat: -22.6951445
  },
  {
    lng: -43.4013597,
    lat: -22.6950937
  },
  {
    lng: -43.4011456,
    lat: -22.6950077
  },
  {
    lng: -43.4010441,
    lat: -22.6949704
  },
  {
    lng: -43.4009192,
    lat: -22.6949245
  },
  {
    lng: -43.4008656,
    lat: -22.6949048
  },
  {
    lng: -43.4007887,
    lat: -22.6948766
  },
  {
    lng: -43.4007325,
    lat: -22.6948559
  },
  {
    lng: -43.4001211,
    lat: -22.6946314
  },
  {
    lng: -43.4001126,
    lat: -22.6946287
  },
  {
    lng: -43.3995114,
    lat: -22.6944391
  },
  {
    lng: -43.3994529,
    lat: -22.6944269
  },
  {
    lng: -43.3992574,
    lat: -22.6943859
  },
  {
    lng: -43.3991049,
    lat: -22.694354
  },
  {
    lng: -43.3990627,
    lat: -22.6943479
  },
  {
    lng: -43.3987409,
    lat: -22.6943018
  },
  {
    lng: -43.3985442,
    lat: -22.6942736
  },
  {
    lng: -43.398377,
    lat: -22.6942496
  },
  {
    lng: -43.3975793,
    lat: -22.6941472
  },
  {
    lng: -43.3975125,
    lat: -22.6941387
  },
  {
    lng: -43.3974474,
    lat: -22.6941303
  },
  {
    lng: -43.3973401,
    lat: -22.6940896
  },
  {
    lng: -43.3971136,
    lat: -22.6939612
  },
  {
    lng: -43.3968582,
    lat: -22.6938266
  },
  {
    lng: -43.3966494,
    lat: -22.693758
  },
  {
    lng: -43.3962539,
    lat: -22.6936152
  },
  {
    lng: -43.3959055,
    lat: -22.6935086
  },
  {
    lng: -43.3957812,
    lat: -22.6934556
  },
  {
    lng: -43.3956224,
    lat: -22.6933493
  },
  {
    lng: -43.3954844,
    lat: -22.693247
  },
  {
    lng: -43.3953481,
    lat: -22.6931121
  },
  {
    lng: -43.3952322,
    lat: -22.6929944
  },
  {
    lng: -43.3951428,
    lat: -22.6928552
  },
  {
    lng: -43.395119,
    lat: -22.6927838
  },
  {
    lng: -43.3950991,
    lat: -22.6926471
  },
  {
    lng: -43.3950925,
    lat: -22.692602
  },
  {
    lng: -43.3951058,
    lat: -22.6923462
  },
  {
    lng: -43.3950846,
    lat: -22.6922683
  },
  {
    lng: -43.3950225,
    lat: -22.6921633
  },
  {
    lng: -43.3949734,
    lat: -22.6920804
  },
  {
    lng: -43.3947405,
    lat: -22.6917997
  },
  {
    lng: -43.3946314,
    lat: -22.6916753
  },
  {
    lng: -43.3944851,
    lat: -22.691547
  },
  {
    lng: -43.3943862,
    lat: -22.6914756
  },
  {
    lng: -43.3942242,
    lat: -22.6913932
  },
  {
    lng: -43.3940759,
    lat: -22.6913492
  },
  {
    lng: -43.3939193,
    lat: -22.6913382
  },
  {
    lng: -43.3937754,
    lat: -22.6913738
  },
  {
    lng: -43.3935619,
    lat: -22.6914435
  },
  {
    lng: -43.3932474,
    lat: -22.6915346
  },
  {
    lng: -43.3928322,
    lat: -22.691655
  },
  {
    lng: -43.3923648,
    lat: -22.6918134
  },
  {
    lng: -43.3922805,
    lat: -22.6918372
  },
  {
    lng: -43.3921533,
    lat: -22.6918409
  },
  {
    lng: -43.3920297,
    lat: -22.6918711
  },
  {
    lng: -43.3918979,
    lat: -22.6918892
  },
  {
    lng: -43.3918896,
    lat: -22.6918903
  },
  {
    lng: -43.3917387,
    lat: -22.6918925
  },
  {
    lng: -43.3915051,
    lat: -22.6918958
  },
  {
    lng: -43.391496,
    lat: -22.6918948
  },
  {
    lng: -43.3911782,
    lat: -22.6918601
  },
  {
    lng: -43.3910125,
    lat: -22.6918406
  },
  {
    lng: -43.3908658,
    lat: -22.6918029
  },
  {
    lng: -43.3908279,
    lat: -22.6917932
  },
  {
    lng: -43.3907895,
    lat: -22.6917827
  },
  {
    lng: -43.3907443,
    lat: -22.6917703
  },
  {
    lng: -43.3907048,
    lat: -22.6917594
  },
  {
    lng: -43.3906885,
    lat: -22.691755
  },
  {
    lng: -43.3906717,
    lat: -22.6917503
  },
  {
    lng: -43.3904812,
    lat: -22.6916981
  },
  {
    lng: -43.3904655,
    lat: -22.6916938
  },
  {
    lng: -43.3904593,
    lat: -22.691692
  },
  {
    lng: -43.3903049,
    lat: -22.6916497
  },
  {
    lng: -43.3902546,
    lat: -22.6916362
  },
  {
    lng: -43.390178,
    lat: -22.6916158
  },
  {
    lng: -43.3900733,
    lat: -22.6915878
  },
  {
    lng: -43.3894536,
    lat: -22.6914223
  },
  {
    lng: -43.389443,
    lat: -22.6914191
  },
  {
    lng: -43.3891787,
    lat: -22.6913404
  },
  {
    lng: -43.3891649,
    lat: -22.6913349
  },
  {
    lng: -43.3891271,
    lat: -22.69132
  },
  {
    lng: -43.3889782,
    lat: -22.6912613
  },
  {
    lng: -43.3887915,
    lat: -22.6912064
  },
  {
    lng: -43.3886235,
    lat: -22.6911791
  },
  {
    lng: -43.3885214,
    lat: -22.691171
  },
  {
    lng: -43.3883662,
    lat: -22.6911587
  },
  {
    lng: -43.3882904,
    lat: -22.6911527
  },
  {
    lng: -43.3882217,
    lat: -22.6911183
  },
  {
    lng: -43.3880607,
    lat: -22.6910047
  },
  {
    lng: -43.3873742,
    lat: -22.690333
  },
  {
    lng: -43.3872451,
    lat: -22.6902094
  },
  {
    lng: -43.3871188,
    lat: -22.6900885
  },
  {
    lng: -43.3869979,
    lat: -22.6899869
  },
  {
    lng: -43.3869198,
    lat: -22.6899618
  },
  {
    lng: -43.3868279,
    lat: -22.6899571
  },
  {
    lng: -43.3867286,
    lat: -22.6899744
  },
  {
    lng: -43.3866793,
    lat: -22.6899897
  },
  {
    lng: -43.3866566,
    lat: -22.6900529
  },
  {
    lng: -43.3866698,
    lat: -22.6901401
  },
  {
    lng: -43.3866903,
    lat: -22.6902671
  },
  {
    lng: -43.3867233,
    lat: -22.6904017
  },
  {
    lng: -43.386737,
    lat: -22.6905362
  },
  {
    lng: -43.3867095,
    lat: -22.6907032
  },
  {
    lng: -43.3866519,
    lat: -22.6909263
  },
  {
    lng: -43.3865997,
    lat: -22.6910581
  },
  {
    lng: -43.3865365,
    lat: -22.6911817
  },
  {
    lng: -43.3864569,
    lat: -22.6912998
  },
  {
    lng: -43.3863827,
    lat: -22.6913602
  },
  {
    lng: -43.3862426,
    lat: -22.6914783
  },
  {
    lng: -43.3859075,
    lat: -22.6916925
  },
  {
    lng: -43.3855642,
    lat: -22.6918958
  },
  {
    lng: -43.3853413,
    lat: -22.6919997
  },
  {
    lng: -43.3851497,
    lat: -22.6920938
  },
  {
    lng: -43.3849118,
    lat: -22.692152
  },
  {
    lng: -43.3846898,
    lat: -22.6921731
  },
  {
    lng: -43.3845288,
    lat: -22.6921731
  },
  {
    lng: -43.384309,
    lat: -22.6921293
  },
  {
    lng: -43.3841415,
    lat: -22.6920606
  },
  {
    lng: -43.3840097,
    lat: -22.6919727
  },
  {
    lng: -43.3839178,
    lat: -22.6918823
  },
  {
    lng: -43.3838541,
    lat: -22.6917894
  },
  {
    lng: -43.383664,
    lat: -22.6915122
  },
  {
    lng: -43.3835583,
    lat: -22.6913219
  },
  {
    lng: -43.3834367,
    lat: -22.6911844
  },
  {
    lng: -43.3833435,
    lat: -22.6911163
  },
  {
    lng: -43.3831617,
    lat: -22.6909835
  },
  {
    lng: -43.3829661,
    lat: -22.6908777
  },
  {
    lng: -43.382826,
    lat: -22.690772
  },
  {
    lng: -43.3826859,
    lat: -22.6906662
  },
  {
    lng: -43.3826144,
    lat: -22.6906049
  },
  {
    lng: -43.3825238,
    lat: -22.6904731
  },
  {
    lng: -43.3821889,
    lat: -22.689852
  },
  {
    lng: -43.3821001,
    lat: -22.6897023
  },
  {
    lng: -43.3818838,
    lat: -22.6893378
  },
  {
    lng: -43.3818187,
    lat: -22.6892281
  },
  {
    lng: -43.38175,
    lat: -22.6891541
  },
  {
    lng: -43.3816813,
    lat: -22.68908
  },
  {
    lng: -43.3815597,
    lat: -22.6889954
  },
  {
    lng: -43.3814645,
    lat: -22.6889531
  },
  {
    lng: -43.3814143,
    lat: -22.6889399
  },
  {
    lng: -43.381364,
    lat: -22.6889267
  },
  {
    lng: -43.3811896,
    lat: -22.688932
  },
  {
    lng: -43.3809992,
    lat: -22.6889584
  },
  {
    lng: -43.3806925,
    lat: -22.6890325
  },
  {
    lng: -43.3805339,
    lat: -22.68908
  },
  {
    lng: -43.3804863,
    lat: -22.6891118
  },
  {
    lng: -43.3804388,
    lat: -22.6891435
  },
  {
    lng: -43.3803912,
    lat: -22.6892334
  },
  {
    lng: -43.3803965,
    lat: -22.6892942
  },
  {
    lng: -43.3804017,
    lat: -22.689355
  },
  {
    lng: -43.3804599,
    lat: -22.689466
  },
  {
    lng: -43.3805392,
    lat: -22.6895585
  },
  {
    lng: -43.3806185,
    lat: -22.6896511
  },
  {
    lng: -43.3808032,
    lat: -22.6898062
  },
  {
    lng: -43.3808829,
    lat: -22.6898731
  },
  {
    lng: -43.3810106,
    lat: -22.6899668
  },
  {
    lng: -43.3810415,
    lat: -22.6899895
  },
  {
    lng: -43.3810838,
    lat: -22.6901111
  },
  {
    lng: -43.3811393,
    lat: -22.6901824
  },
  {
    lng: -43.3811948,
    lat: -22.6902538
  },
  {
    lng: -43.3812477,
    lat: -22.6903913
  },
  {
    lng: -43.3812424,
    lat: -22.6904653
  },
  {
    lng: -43.3812371,
    lat: -22.6905393
  },
  {
    lng: -43.3812107,
    lat: -22.6906609
  },
  {
    lng: -43.3811552,
    lat: -22.6907085
  },
  {
    lng: -43.3810997,
    lat: -22.6907561
  },
  {
    lng: -43.3808565,
    lat: -22.690846
  },
  {
    lng: -43.3805422,
    lat: -22.6908991
  },
  {
    lng: -43.3804811,
    lat: -22.6909095
  },
  {
    lng: -43.3803926,
    lat: -22.6909024
  },
  {
    lng: -43.380148,
    lat: -22.690883
  },
  {
    lng: -43.3798335,
    lat: -22.6907821
  },
  {
    lng: -43.3796742,
    lat: -22.6907271
  },
  {
    lng: -43.3795204,
    lat: -22.6906887
  },
  {
    lng: -43.3793528,
    lat: -22.6906612
  },
  {
    lng: -43.3790452,
    lat: -22.6906722
  },
  {
    lng: -43.3787733,
    lat: -22.6906722
  },
  {
    lng: -43.3786118,
    lat: -22.690678
  },
  {
    lng: -43.3785313,
    lat: -22.6906809
  },
  {
    lng: -43.3783523,
    lat: -22.6907085
  },
  {
    lng: -43.3782339,
    lat: -22.6907528
  },
  {
    lng: -43.3780119,
    lat: -22.6908777
  },
  {
    lng: -43.3777983,
    lat: -22.6910842
  },
  {
    lng: -43.3777529,
    lat: -22.6911515
  },
  {
    lng: -43.3777076,
    lat: -22.6912188
  },
  {
    lng: -43.3772572,
    lat: -22.6921279
  },
  {
    lng: -43.3771226,
    lat: -22.6923641
  },
  {
    lng: -43.3769413,
    lat: -22.692636
  },
  {
    lng: -43.3767353,
    lat: -22.692842
  },
  {
    lng: -43.3765568,
    lat: -22.693004
  },
  {
    lng: -43.37637,
    lat: -22.6931194
  },
  {
    lng: -43.376175,
    lat: -22.69321
  },
  {
    lng: -43.3759855,
    lat: -22.6932595
  },
  {
    lng: -43.3757988,
    lat: -22.6933062
  },
  {
    lng: -43.3755351,
    lat: -22.6933089
  },
  {
    lng: -43.3752302,
    lat: -22.6932897
  },
  {
    lng: -43.3749803,
    lat: -22.6932705
  },
  {
    lng: -43.3748484,
    lat: -22.693276
  },
  {
    lng: -43.374554,
    lat: -22.693331
  },
  {
    lng: -43.3740712,
    lat: -22.6933913
  },
  {
    lng: -43.3739858,
    lat: -22.6934218
  },
  {
    lng: -43.3738789,
    lat: -22.69346
  },
  {
    lng: -43.3737965,
    lat: -22.6935231
  },
  {
    lng: -43.3737141,
    lat: -22.6935863
  },
  {
    lng: -43.3735768,
    lat: -22.6936934
  },
  {
    lng: -43.373211,
    lat: -22.6940448
  },
  {
    lng: -43.3731,
    lat: -22.6941453
  },
  {
    lng: -43.3729281,
    lat: -22.694259
  },
  {
    lng: -43.3727563,
    lat: -22.6943726
  },
  {
    lng: -43.3724708,
    lat: -22.6944361
  },
  {
    lng: -43.3723406,
    lat: -22.6944407
  },
  {
    lng: -43.3720266,
    lat: -22.6944519
  },
  {
    lng: -43.3716771,
    lat: -22.6944395
  },
  {
    lng: -43.3714309,
    lat: -22.6943928
  },
  {
    lng: -43.3712938,
    lat: -22.6943572
  },
  {
    lng: -43.3711924,
    lat: -22.6943496
  },
  {
    lng: -43.371014,
    lat: -22.6943544
  },
  {
    lng: -43.3706601,
    lat: -22.6943243
  },
  {
    lng: -43.3704462,
    lat: -22.6942941
  },
  {
    lng: -43.370303,
    lat: -22.6942563
  },
  {
    lng: -43.3700862,
    lat: -22.6940318
  },
  {
    lng: -43.3698564,
    lat: -22.693751
  },
  {
    lng: -43.3696949,
    lat: -22.6934421
  },
  {
    lng: -43.369473,
    lat: -22.6929686
  },
  {
    lng: -43.3693627,
    lat: -22.6927333
  },
  {
    lng: -43.3689512,
    lat: -22.6919433
  },
  {
    lng: -43.3687839,
    lat: -22.6916114
  },
  {
    lng: -43.3686056,
    lat: -22.6911888
  },
  {
    lng: -43.3685068,
    lat: -22.6909146
  },
  {
    lng: -43.3684517,
    lat: -22.6908258
  },
  {
    lng: -43.36826,
    lat: -22.6905169
  },
  {
    lng: -43.3681475,
    lat: -22.6902673
  },
  {
    lng: -43.3680899,
    lat: -22.6900808
  },
  {
    lng: -43.368035,
    lat: -22.6898449
  },
  {
    lng: -43.3679939,
    lat: -22.6895898
  },
  {
    lng: -43.3679727,
    lat: -22.6893957
  },
  {
    lng: -43.3679582,
    lat: -22.6892633
  },
  {
    lng: -43.3678814,
    lat: -22.6886791
  },
  {
    lng: -43.367832,
    lat: -22.6882127
  },
  {
    lng: -43.3678074,
    lat: -22.6879384
  },
  {
    lng: -43.3677497,
    lat: -22.6875873
  },
  {
    lng: -43.367728,
    lat: -22.6874852
  },
  {
    lng: -43.3676976,
    lat: -22.6873432
  },
  {
    lng: -43.3676727,
    lat: -22.6872837
  },
  {
    lng: -43.3675989,
    lat: -22.6871073
  },
  {
    lng: -43.3675509,
    lat: -22.687025
  },
  {
    lng: -43.3675029,
    lat: -22.6869427
  },
  {
    lng: -43.3673438,
    lat: -22.686737
  },
  {
    lng: -43.367149,
    lat: -22.6865395
  },
  {
    lng: -43.3664311,
    lat: -22.6859712
  },
  {
    lng: -43.3661752,
    lat: -22.6857687
  },
  {
    lng: -43.3657602,
    lat: -22.6854013
  },
  {
    lng: -43.3656019,
    lat: -22.6852612
  },
  {
    lng: -43.3655579,
    lat: -22.6852595
  },
  {
    lng: -43.3655306,
    lat: -22.6852585
  },
  {
    lng: -43.3654483,
    lat: -22.685231
  },
  {
    lng: -43.3653703,
    lat: -22.6851339
  },
  {
    lng: -43.3654776,
    lat: -22.6850501
  },
  {
    lng: -43.3654839,
    lat: -22.6850328
  },
  {
    lng: -43.3655258,
    lat: -22.6849266
  },
  {
    lng: -43.3655338,
    lat: -22.6849061
  },
  {
    lng: -43.3655696,
    lat: -22.6848145
  },
  {
    lng: -43.3655485,
    lat: -22.6845738
  },
  {
    lng: -43.3654799,
    lat: -22.6843454
  },
  {
    lng: -43.3654615,
    lat: -22.6842527
  },
  {
    lng: -43.3653768,
    lat: -22.68389
  },
  {
    lng: -43.3652802,
    lat: -22.6835248
  },
  {
    lng: -43.3652082,
    lat: -22.6832806
  },
  {
    lng: -43.3651523,
    lat: -22.6830522
  },
  {
    lng: -43.3650386,
    lat: -22.6827785
  },
  {
    lng: -43.36493,
    lat: -22.6825154
  },
  {
    lng: -43.36492,
    lat: -22.6824912
  },
  {
    lng: -43.36479,
    lat: -22.682141
  },
  {
    lng: -43.3646649,
    lat: -22.6818939
  },
  {
    lng: -43.3645185,
    lat: -22.6815957
  },
  {
    lng: -43.3644403,
    lat: -22.6813453
  },
  {
    lng: -43.3643969,
    lat: -22.6810986
  },
  {
    lng: -43.3643972,
    lat: -22.6809079
  },
  {
    lng: -43.3644728,
    lat: -22.6806656
  },
  {
    lng: -43.3645698,
    lat: -22.6804923
  },
  {
    lng: -43.3646504,
    lat: -22.6803553
  },
  {
    lng: -43.3646552,
    lat: -22.6803472
  },
  {
    lng: -43.3647856,
    lat: -22.6801717
  },
  {
    lng: -43.3649437,
    lat: -22.6799685
  },
  {
    lng: -43.3651583,
    lat: -22.6797043
  },
  {
    lng: -43.3653213,
    lat: -22.6795246
  },
  {
    lng: -43.3655841,
    lat: -22.6792535
  },
  {
    lng: -43.3656979,
    lat: -22.6791354
  },
  {
    lng: -43.3657118,
    lat: -22.6791211
  },
  {
    lng: -43.3658725,
    lat: -22.6789547
  },
  {
    lng: -43.3659694,
    lat: -22.6788543
  },
  {
    lng: -43.3659795,
    lat: -22.6788442
  },
  {
    lng: -43.3662349,
    lat: -22.6785679
  },
  {
    lng: -43.3664935,
    lat: -22.6782795
  },
  {
    lng: -43.3665535,
    lat: -22.6782245
  },
  {
    lng: -43.366819,
    lat: -22.6779465
  },
  {
    lng: -43.3669912,
    lat: -22.6777605
  },
  {
    lng: -43.3671944,
    lat: -22.6775412
  },
  {
    lng: -43.3674014,
    lat: -22.6773141
  },
  {
    lng: -43.3675333,
    lat: -22.6771769
  },
  {
    lng: -43.3676651,
    lat: -22.6770396
  },
  {
    lng: -43.3678293,
    lat: -22.6768464
  },
  {
    lng: -43.3680173,
    lat: -22.6766297
  },
  {
    lng: -43.3682228,
    lat: -22.676413
  },
  {
    lng: -43.368421,
    lat: -22.6762189
  },
  {
    lng: -43.3686587,
    lat: -22.6759896
  },
  {
    lng: -43.3687791,
    lat: -22.6758593
  },
  {
    lng: -43.3689256,
    lat: -22.675697
  },
  {
    lng: -43.3690786,
    lat: -22.6755525
  },
  {
    lng: -43.3692301,
    lat: -22.6753967
  },
  {
    lng: -43.3694386,
    lat: -22.6751783
  },
  {
    lng: -43.3696875,
    lat: -22.6748961
  },
  {
    lng: -43.3696998,
    lat: -22.6748837
  },
  {
    lng: -43.3699737,
    lat: -22.6746201
  },
  {
    lng: -43.3700523,
    lat: -22.6745295
  },
  {
    lng: -43.3701357,
    lat: -22.6744334
  },
  {
    lng: -43.3702897,
    lat: -22.6742432
  },
  {
    lng: -43.3704413,
    lat: -22.6740436
  },
  {
    lng: -43.3705851,
    lat: -22.67382
  },
  {
    lng: -43.3707418,
    lat: -22.6735766
  },
  {
    lng: -43.370875,
    lat: -22.6734262
  },
  {
    lng: -43.3709871,
    lat: -22.6733486
  },
  {
    lng: -43.3711245,
    lat: -22.6732711
  },
  {
    lng: -43.3713571,
    lat: -22.6731408
  },
  {
    lng: -43.3714036,
    lat: -22.6731191
  },
  {
    lng: -43.3714803,
    lat: -22.6730833
  },
  {
    lng: -43.3716604,
    lat: -22.6729924
  },
  {
    lng: -43.371762,
    lat: -22.6729537
  },
  {
    lng: -43.3720232,
    lat: -22.6728791
  },
  {
    lng: -43.3722672,
    lat: -22.672811
  },
  {
    lng: -43.3724242,
    lat: -22.6727542
  },
  {
    lng: -43.372716,
    lat: -22.6726617
  },
  {
    lng: -43.3728915,
    lat: -22.6726116
  },
  {
    lng: -43.3732401,
    lat: -22.6725062
  },
  {
    lng: -43.3735126,
    lat: -22.6724162
  },
  {
    lng: -43.3736015,
    lat: -22.6723757
  },
  {
    lng: -43.3736274,
    lat: -22.672368
  },
  {
    lng: -43.3739362,
    lat: -22.6722582
  },
  {
    lng: -43.3740279,
    lat: -22.6722208
  },
  {
    lng: -43.3740938,
    lat: -22.6722011
  },
  {
    lng: -43.3741162,
    lat: -22.6721943
  },
  {
    lng: -43.3743024,
    lat: -22.6721392
  },
  {
    lng: -43.3745303,
    lat: -22.6720755
  },
  {
    lng: -43.3746657,
    lat: -22.6720471
  },
  {
    lng: -43.3750009,
    lat: -22.6719459
  },
  {
    lng: -43.3750669,
    lat: -22.6719042
  },
  {
    lng: -43.3751404,
    lat: -22.6718862
  },
  {
    lng: -43.3753431,
    lat: -22.671798
  },
  {
    lng: -43.3756887,
    lat: -22.6717008
  },
  {
    lng: -43.3761288,
    lat: -22.6715831
  },
  {
    lng: -43.3765336,
    lat: -22.671458
  },
  {
    lng: -43.376893,
    lat: -22.6713313
  },
  {
    lng: -43.3772755,
    lat: -22.6711998
  },
  {
    lng: -43.3775923,
    lat: -22.6710992
  },
  {
    lng: -43.3780656,
    lat: -22.6709689
  },
  {
    lng: -43.3784319,
    lat: -22.6708549
  },
  {
    lng: -43.3786973,
    lat: -22.6707505
  },
  {
    lng: -43.3790699,
    lat: -22.6706298
  },
  {
    lng: -43.3794633,
    lat: -22.6704775
  },
  {
    lng: -43.3799117,
    lat: -22.6703288
  },
  {
    lng: -43.3802355,
    lat: -22.6702556
  },
  {
    lng: -43.3806211,
    lat: -22.6701469
  },
  {
    lng: -43.3809523,
    lat: -22.670041
  },
  {
    lng: -43.3813587,
    lat: -22.6699095
  },
  {
    lng: -43.3816574,
    lat: -22.6697904
  },
  {
    lng: -43.3820013,
    lat: -22.669678
  },
  {
    lng: -43.382273,
    lat: -22.669612
  },
  {
    lng: -43.382716,
    lat: -22.6694935
  },
  {
    lng: -43.3829613,
    lat: -22.6694145
  },
  {
    lng: -43.3833101,
    lat: -22.6692849
  },
  {
    lng: -43.3837003,
    lat: -22.6691856
  },
  {
    lng: -43.3841357,
    lat: -22.6690899
  },
  {
    lng: -43.3843641,
    lat: -22.6690624
  },
  {
    lng: -43.3843681,
    lat: -22.6690619
  },
  {
    lng: -43.3846343,
    lat: -22.6689779
  },
  {
    lng: -43.3848309,
    lat: -22.668916
  },
  {
    lng: -43.3849819,
    lat: -22.6688713
  },
  {
    lng: -43.3851367,
    lat: -22.668848
  },
  {
    lng: -43.3853561,
    lat: -22.6687835
  },
  {
    lng: -43.3856,
    lat: -22.6686901
  },
  {
    lng: -43.3858012,
    lat: -22.6685776
  },
  {
    lng: -43.3861352,
    lat: -22.6683754
  },
  {
    lng: -43.3863465,
    lat: -22.6682422
  },
  {
    lng: -43.3865016,
    lat: -22.6681677
  },
  {
    lng: -43.3866955,
    lat: -22.6680908
  },
  {
    lng: -43.3870504,
    lat: -22.6678728
  },
  {
    lng: -43.387327,
    lat: -22.6676922
  },
  {
    lng: -43.3877531,
    lat: -22.6673677
  },
  {
    lng: -43.3878839,
    lat: -22.6672461
  },
  {
    lng: -43.3881611,
    lat: -22.6670268
  },
  {
    lng: -43.3884672,
    lat: -22.6668241
  },
  {
    lng: -43.3888665,
    lat: -22.6665693
  },
  {
    lng: -43.3891413,
    lat: -22.6663526
  },
  {
    lng: -43.3893973,
    lat: -22.6661657
  },
  {
    lng: -43.3895741,
    lat: -22.6659895
  },
  {
    lng: -43.3897567,
    lat: -22.665761
  },
  {
    lng: -43.3898837,
    lat: -22.6655349
  },
  {
    lng: -43.3899112,
    lat: -22.665433
  },
  {
    lng: -43.389907,
    lat: -22.6653591
  },
  {
    lng: -43.3898867,
    lat: -22.6652695
  },
  {
    lng: -43.3898903,
    lat: -22.6652056
  },
  {
    lng: -43.3899229,
    lat: -22.6651581
  },
  {
    lng: -43.3899789,
    lat: -22.6651062
  },
  {
    lng: -43.3900454,
    lat: -22.6650658
  },
  {
    lng: -43.390071,
    lat: -22.6650541
  },
  {
    lng: -43.3901867,
    lat: -22.6650014
  },
  {
    lng: -43.3904543,
    lat: -22.6649399
  },
  {
    lng: -43.3906823,
    lat: -22.6649222
  },
  {
    lng: -43.3909018,
    lat: -22.6649236
  },
  {
    lng: -43.3911296,
    lat: -22.6649376
  },
  {
    lng: -43.3914749,
    lat: -22.6649783
  },
  {
    lng: -43.3916649,
    lat: -22.6649896
  },
  {
    lng: -43.3918609,
    lat: -22.6649819
  },
  {
    lng: -43.3919507,
    lat: -22.664977
  },
  {
    lng: -43.3920643,
    lat: -22.6649709
  },
  {
    lng: -43.3922687,
    lat: -22.6649234
  },
  {
    lng: -43.3925734,
    lat: -22.6648779
  },
  {
    lng: -43.3929811,
    lat: -22.6648195
  },
  {
    lng: -43.3931372,
    lat: -22.6648117
  },
  {
    lng: -43.3933014,
    lat: -22.664799
  },
  {
    lng: -43.3934685,
    lat: -22.6647589
  },
  {
    lng: -43.3936387,
    lat: -22.6646767
  },
  {
    lng: -43.3938408,
    lat: -22.6645896
  },
  {
    lng: -43.3942077,
    lat: -22.6644353
  },
  {
    lng: -43.3945506,
    lat: -22.6642884
  },
  {
    lng: -43.3948145,
    lat: -22.6641544
  },
  {
    lng: -43.3949562,
    lat: -22.6640877
  },
  {
    lng: -43.395272,
    lat: -22.6639781
  },
  {
    lng: -43.3954449,
    lat: -22.6638961
  },
  {
    lng: -43.3955736,
    lat: -22.6638045
  },
  {
    lng: -43.3956568,
    lat: -22.6637453
  },
  {
    lng: -43.3959723,
    lat: -22.6635298
  },
  {
    lng: -43.3963102,
    lat: -22.6633485
  },
  {
    lng: -43.3966567,
    lat: -22.6630915
  },
  {
    lng: -43.3969516,
    lat: -22.662819
  },
  {
    lng: -43.3973205,
    lat: -22.6624827
  },
  {
    lng: -43.3974872,
    lat: -22.6623542
  },
  {
    lng: -43.3976217,
    lat: -22.662243
  },
  {
    lng: -43.3978484,
    lat: -22.6620081
  },
  {
    lng: -43.398141,
    lat: -22.6616848
  },
  {
    lng: -43.3982185,
    lat: -22.6615903
  },
  {
    lng: -43.3983625,
    lat: -22.6614239
  },
  {
    lng: -43.3984694,
    lat: -22.6612973
  },
  {
    lng: -43.3985426,
    lat: -22.6611848
  },
  {
    lng: -43.3986853,
    lat: -22.6609133
  },
  {
    lng: -43.3989372,
    lat: -22.6604571
  },
  {
    lng: -43.3991014,
    lat: -22.660105
  },
  {
    lng: -43.3993984,
    lat: -22.65958
  },
  {
    lng: -43.399638,
    lat: -22.6591983
  },
  {
    lng: -43.3998339,
    lat: -22.6588969
  },
  {
    lng: -43.3999732,
    lat: -22.6586449
  },
  {
    lng: -43.4000618,
    lat: -22.6584572
  },
  {
    lng: -43.4001608,
    lat: -22.6582369
  },
  {
    lng: -43.4002331,
    lat: -22.6580487
  },
  {
    lng: -43.4003127,
    lat: -22.6579144
  },
  {
    lng: -43.400426,
    lat: -22.657781
  },
  {
    lng: -43.4004324,
    lat: -22.6577736
  },
  {
    lng: -43.4005473,
    lat: -22.6576384
  },
  {
    lng: -43.4007285,
    lat: -22.6574722
  },
  {
    lng: -43.4009095,
    lat: -22.6573086
  },
  {
    lng: -43.4010223,
    lat: -22.6572041
  },
  {
    lng: -43.4011041,
    lat: -22.6570752
  },
  {
    lng: -43.4011779,
    lat: -22.6569436
  },
  {
    lng: -43.4012349,
    lat: -22.6568188
  },
  {
    lng: -43.4012699,
    lat: -22.6566983
  },
  {
    lng: -43.4012756,
    lat: -22.6566095
  },
  {
    lng: -43.4012974,
    lat: -22.6564235
  },
  {
    lng: -43.4013143,
    lat: -22.6562791
  },
  {
    lng: -43.40133,
    lat: -22.6560791
  },
  {
    lng: -43.4013613,
    lat: -22.6558901
  },
  {
    lng: -43.401386,
    lat: -22.6558023
  },
  {
    lng: -43.4014071,
    lat: -22.6557001
  },
  {
    lng: -43.401419,
    lat: -22.6555585
  },
  {
    lng: -43.4014333,
    lat: -22.6553791
  },
  {
    lng: -43.40144,
    lat: -22.6552905
  },
  {
    lng: -43.401461,
    lat: -22.6550086
  },
  {
    lng: -43.4014815,
    lat: -22.6548333
  },
  {
    lng: -43.4015193,
    lat: -22.6546678
  },
  {
    lng: -43.401571,
    lat: -22.6545384
  },
  {
    lng: -43.4016355,
    lat: -22.6543793
  },
  {
    lng: -43.4016939,
    lat: -22.6542301
  },
  {
    lng: -43.401748,
    lat: -22.6540364
  },
  {
    lng: -43.4017841,
    lat: -22.6538534
  },
  {
    lng: -43.4017992,
    lat: -22.6536758
  },
  {
    lng: -43.4018051,
    lat: -22.6534589
  },
  {
    lng: -43.4017877,
    lat: -22.6530466
  },
  {
    lng: -43.4017848,
    lat: -22.6528824
  },
  {
    lng: -43.401758,
    lat: -22.6527262
  },
  {
    lng: -43.4017088,
    lat: -22.6525676
  },
  {
    lng: -43.4016572,
    lat: -22.6524608
  },
  {
    lng: -43.4015651,
    lat: -22.6522715
  },
  {
    lng: -43.401488,
    lat: -22.6521434
  },
  {
    lng: -43.401295,
    lat: -22.6519033
  },
  {
    lng: -43.4011274,
    lat: -22.6517324
  },
  {
    lng: -43.4009926,
    lat: -22.6515818
  },
  {
    lng: -43.4008699,
    lat: -22.65142
  },
  {
    lng: -43.4007472,
    lat: -22.6512583
  },
  {
    lng: -43.400653,
    lat: -22.6511499
  },
  {
    lng: -43.400564,
    lat: -22.651068
  },
  {
    lng: -43.400381,
    lat: -22.6508919
  },
  {
    lng: -43.3999847,
    lat: -22.6504358
  },
  {
    lng: -43.3997109,
    lat: -22.6501121
  },
  {
    lng: -43.399431,
    lat: -22.6498035
  },
  {
    lng: -43.3993188,
    lat: -22.6496498
  },
  {
    lng: -43.3992301,
    lat: -22.6495473
  },
  {
    lng: -43.3991301,
    lat: -22.6494726
  },
  {
    lng: -43.3990428,
    lat: -22.6493902
  },
  {
    lng: -43.3987906,
    lat: -22.6490812
  },
  {
    lng: -43.3985394,
    lat: -22.648793
  },
  {
    lng: -43.3982883,
    lat: -22.6485047
  },
  {
    lng: -43.3979911,
    lat: -22.6481596
  },
  {
    lng: -43.3977341,
    lat: -22.6478771
  },
  {
    lng: -43.3975382,
    lat: -22.6476849
  },
  {
    lng: -43.3974171,
    lat: -22.6475566
  },
  {
    lng: -43.3973536,
    lat: -22.6474485
  },
  {
    lng: -43.3972142,
    lat: -22.6472591
  },
  {
    lng: -43.3970553,
    lat: -22.6470969
  },
  {
    lng: -43.3969136,
    lat: -22.6469053
  },
  {
    lng: -43.3967208,
    lat: -22.6466944
  },
  {
    lng: -43.3964902,
    lat: -22.6464586
  },
  {
    lng: -43.3962125,
    lat: -22.6461493
  },
  {
    lng: -43.3959276,
    lat: -22.6458172
  },
  {
    lng: -43.3956858,
    lat: -22.6455246
  },
  {
    lng: -43.3955306,
    lat: -22.6453609
  },
  {
    lng: -43.3953548,
    lat: -22.6451737
  },
  {
    lng: -43.3950775,
    lat: -22.6448628
  },
  {
    lng: -43.3948945,
    lat: -22.6446437
  },
  {
    lng: -43.394405,
    lat: -22.6440759
  },
  {
    lng: -43.394238,
    lat: -22.6439269
  },
  {
    lng: -43.3938345,
    lat: -22.6434878
  },
  {
    lng: -43.3936505,
    lat: -22.6432842
  },
  {
    lng: -43.3934473,
    lat: -22.6430182
  },
  {
    lng: -43.3932529,
    lat: -22.6428144
  },
  {
    lng: -43.3930976,
    lat: -22.6426347
  },
  {
    lng: -43.3929894,
    lat: -22.6425041
  },
  {
    lng: -43.3928901,
    lat: -22.6423617
  },
  {
    lng: -43.3927853,
    lat: -22.6422216
  },
  {
    lng: -43.3926818,
    lat: -22.6421006
  },
  {
    lng: -43.3925137,
    lat: -22.6419041
  },
  {
    lng: -43.3924142,
    lat: -22.6417669
  },
  {
    lng: -43.3922893,
    lat: -22.6416377
  },
  {
    lng: -43.3921871,
    lat: -22.6415464
  },
  {
    lng: -43.3921349,
    lat: -22.6415034
  },
  {
    lng: -43.3921192,
    lat: -22.6414905
  },
  {
    lng: -43.3919602,
    lat: -22.6413261
  },
  {
    lng: -43.3918183,
    lat: -22.641183
  },
  {
    lng: -43.3917817,
    lat: -22.6411461
  },
  {
    lng: -43.3917198,
    lat: -22.6410694
  },
  {
    lng: -43.3916399,
    lat: -22.6409706
  },
  {
    lng: -43.3915104,
    lat: -22.6408127
  },
  {
    lng: -43.3913048,
    lat: -22.6405341
  },
  {
    lng: -43.3911824,
    lat: -22.6404256
  },
  {
    lng: -43.3910602,
    lat: -22.640349
  },
  {
    lng: -43.390947,
    lat: -22.6402217
  },
  {
    lng: -43.3906644,
    lat: -22.6398551
  },
  {
    lng: -43.3903801,
    lat: -22.6394925
  },
  {
    lng: -43.3902283,
    lat: -22.6393271
  },
  {
    lng: -43.3899973,
    lat: -22.6390753
  },
  {
    lng: -43.389874,
    lat: -22.6389187
  },
  {
    lng: -43.389769,
    lat: -22.6387995
  },
  {
    lng: -43.3896157,
    lat: -22.6386175
  },
  {
    lng: -43.389465,
    lat: -22.6384743
  },
  {
    lng: -43.389306,
    lat: -22.6383336
  },
  {
    lng: -43.3892065,
    lat: -22.6382149
  },
  {
    lng: -43.3890411,
    lat: -22.6379914
  },
  {
    lng: -43.3889245,
    lat: -22.6378235
  },
  {
    lng: -43.3888063,
    lat: -22.6376749
  },
  {
    lng: -43.3886659,
    lat: -22.6375404
  },
  {
    lng: -43.3885315,
    lat: -22.6373984
  },
  {
    lng: -43.3883449,
    lat: -22.6372133
  },
  {
    lng: -43.3881664,
    lat: -22.6369966
  },
  {
    lng: -43.3880413,
    lat: -22.6368729
  },
  {
    lng: -43.3879252,
    lat: -22.6367055
  },
  {
    lng: -43.3878125,
    lat: -22.6365626
  },
  {
    lng: -43.3876095,
    lat: -22.6363578
  },
  {
    lng: -43.3874395,
    lat: -22.6361742
  },
  {
    lng: -43.3872385,
    lat: -22.6359818
  },
  {
    lng: -43.3871558,
    lat: -22.6358931
  },
  {
    lng: -43.3870718,
    lat: -22.6357906
  },
  {
    lng: -43.3870384,
    lat: -22.6357173
  },
  {
    lng: -43.3869662,
    lat: -22.635601
  },
  {
    lng: -43.3867794,
    lat: -22.6353993
  },
  {
    lng: -43.3866253,
    lat: -22.6352219
  },
  {
    lng: -43.3864527,
    lat: -22.6350773
  },
  {
    lng: -43.3863609,
    lat: -22.6350052
  },
  {
    lng: -43.3863167,
    lat: -22.6349364
  },
  {
    lng: -43.3862984,
    lat: -22.6348391
  },
  {
    lng: -43.3862787,
    lat: -22.6347307
  },
  {
    lng: -43.3862353,
    lat: -22.6346178
  },
  {
    lng: -43.3861644,
    lat: -22.6345525
  },
  {
    lng: -43.3861009,
    lat: -22.6345227
  },
  {
    lng: -43.3860164,
    lat: -22.6344928
  },
  {
    lng: -43.3859165,
    lat: -22.6344597
  },
  {
    lng: -43.3858443,
    lat: -22.6344112
  },
  {
    lng: -43.3857765,
    lat: -22.634338
  },
  {
    lng: -43.3857489,
    lat: -22.634263
  },
  {
    lng: -43.3857229,
    lat: -22.6341943
  },
  {
    lng: -43.3857015,
    lat: -22.6341356
  },
  {
    lng: -43.3856563,
    lat: -22.6340813
  },
  {
    lng: -43.3855841,
    lat: -22.6339983
  },
  {
    lng: -43.3854798,
    lat: -22.633893
  },
  {
    lng: -43.3853555,
    lat: -22.6337805
  },
  {
    lng: -43.3852088,
    lat: -22.6336479
  },
  {
    lng: -43.3850621,
    lat: -22.6335154
  },
  {
    lng: -43.3849471,
    lat: -22.6333579
  },
  {
    lng: -43.3848349,
    lat: -22.6332261
  },
  {
    lng: -43.3845836,
    lat: -22.6329684
  },
  {
    lng: -43.3844549,
    lat: -22.6328454
  },
  {
    lng: -43.384316,
    lat: -22.6327254
  },
  {
    lng: -43.384183,
    lat: -22.6325927
  },
  {
    lng: -43.3840994,
    lat: -22.6324986
  },
  {
    lng: -43.3840206,
    lat: -22.6323808
  },
  {
    lng: -43.3839589,
    lat: -22.6322724
  },
  {
    lng: -43.383915,
    lat: -22.632147
  },
  {
    lng: -43.3838341,
    lat: -22.6320245
  },
  {
    lng: -43.3837592,
    lat: -22.6319238
  },
  {
    lng: -43.3836614,
    lat: -22.6318434
  },
  {
    lng: -43.3834879,
    lat: -22.631666
  },
  {
    lng: -43.3833322,
    lat: -22.6314742
  },
  {
    lng: -43.3832324,
    lat: -22.6313765
  },
  {
    lng: -43.3830158,
    lat: -22.6311789
  },
  {
    lng: -43.3827987,
    lat: -22.630976
  },
  {
    lng: -43.3826625,
    lat: -22.6308247
  },
  {
    lng: -43.382574,
    lat: -22.6306941
  },
  {
    lng: -43.382526,
    lat: -22.6305439
  },
  {
    lng: -43.3825097,
    lat: -22.6304341
  },
  {
    lng: -43.3824782,
    lat: -22.6303463
  },
  {
    lng: -43.3824607,
    lat: -22.630323
  },
  {
    lng: -43.3824162,
    lat: -22.6302636
  },
  {
    lng: -43.3822958,
    lat: -22.6302138
  },
  {
    lng: -43.382139,
    lat: -22.6301827
  },
  {
    lng: -43.3820269,
    lat: -22.6301556
  },
  {
    lng: -43.3819728,
    lat: -22.6301154
  },
  {
    lng: -43.3819429,
    lat: -22.6300322
  },
  {
    lng: -43.3819412,
    lat: -22.6300275
  },
  {
    lng: -43.3819241,
    lat: -22.6298326
  },
  {
    lng: -43.3819425,
    lat: -22.6296918
  },
  {
    lng: -43.3819818,
    lat: -22.6295374
  },
  {
    lng: -43.3820998,
    lat: -22.6293048
  },
  {
    lng: -43.3821301,
    lat: -22.6291473
  },
  {
    lng: -43.3821249,
    lat: -22.6289579
  },
  {
    lng: -43.3821322,
    lat: -22.6287574
  },
  {
    lng: -43.3821643,
    lat: -22.6285598
  },
  {
    lng: -43.3822369,
    lat: -22.6284339
  },
  {
    lng: -43.3822981,
    lat: -22.6282793
  },
  {
    lng: -43.3823441,
    lat: -22.6281075
  },
  {
    lng: -43.3823925,
    lat: -22.6279782
  },
  {
    lng: -43.3824646,
    lat: -22.6278719
  },
  {
    lng: -43.3825332,
    lat: -22.6278052
  },
  {
    lng: -43.382738,
    lat: -22.6276164
  },
  {
    lng: -43.382847,
    lat: -22.6274909
  },
  {
    lng: -43.3828926,
    lat: -22.6273558
  },
  {
    lng: -43.3828861,
    lat: -22.6272196
  },
  {
    lng: -43.3828657,
    lat: -22.6270377
  },
  {
    lng: -43.3828509,
    lat: -22.62687
  },
  {
    lng: -43.382792,
    lat: -22.6265141
  },
  {
    lng: -43.3827047,
    lat: -22.6261799
  },
  {
    lng: -43.3825622,
    lat: -22.6257278
  },
  {
    lng: -43.3824712,
    lat: -22.6254098
  },
  {
    lng: -43.3824284,
    lat: -22.6251499
  },
  {
    lng: -43.3824026,
    lat: -22.6250027
  },
  {
    lng: -43.3823847,
    lat: -22.624901
  },
  {
    lng: -43.3823518,
    lat: -22.6248128
  },
  {
    lng: -43.38229,
    lat: -22.6246359
  },
  {
    lng: -43.3822201,
    lat: -22.6244226
  },
  {
    lng: -43.3821559,
    lat: -22.6241492
  },
  {
    lng: -43.3821286,
    lat: -22.6238721
  },
  {
    lng: -43.3821259,
    lat: -22.6237281
  },
  {
    lng: -43.3821479,
    lat: -22.6236413
  },
  {
    lng: -43.382114,
    lat: -22.6235111
  },
  {
    lng: -43.3820465,
    lat: -22.623394
  },
  {
    lng: -43.3819276,
    lat: -22.6232443
  },
  {
    lng: -43.38185,
    lat: -22.6231532
  },
  {
    lng: -43.3818212,
    lat: -22.6230337
  },
  {
    lng: -43.3817906,
    lat: -22.6228777
  },
  {
    lng: -43.3817847,
    lat: -22.6226601
  },
  {
    lng: -43.3817995,
    lat: -22.6225136
  },
  {
    lng: -43.3818525,
    lat: -22.6223526
  },
  {
    lng: -43.3818688,
    lat: -22.6222426
  },
  {
    lng: -43.3818782,
    lat: -22.6221208
  },
  {
    lng: -43.3818815,
    lat: -22.6219993
  },
  {
    lng: -43.3818984,
    lat: -22.6219014
  },
  {
    lng: -43.3819211,
    lat: -22.621791
  },
  {
    lng: -43.3819437,
    lat: -22.6216682
  },
  {
    lng: -43.3819337,
    lat: -22.6216079
  },
  {
    lng: -43.3819199,
    lat: -22.6215239
  },
  {
    lng: -43.3819109,
    lat: -22.6214249
  },
  {
    lng: -43.3819056,
    lat: -22.6213669
  },
  {
    lng: -43.3819023,
    lat: -22.621331
  },
  {
    lng: -43.3819446,
    lat: -22.6212674
  },
  {
    lng: -43.3819861,
    lat: -22.6211795
  },
  {
    lng: -43.3820346,
    lat: -22.6210674
  },
  {
    lng: -43.3820956,
    lat: -22.6209906
  },
  {
    lng: -43.3822013,
    lat: -22.6208989
  },
  {
    lng: -43.3822875,
    lat: -22.6208084
  },
  {
    lng: -43.3823422,
    lat: -22.6206836
  },
  {
    lng: -43.382465,
    lat: -22.620506
  },
  {
    lng: -43.3825494,
    lat: -22.6203668
  },
  {
    lng: -43.3826607,
    lat: -22.6202626
  },
  {
    lng: -43.3828188,
    lat: -22.6201922
  },
  {
    lng: -43.3829878,
    lat: -22.6201207
  },
  {
    lng: -43.3831686,
    lat: -22.6199879
  },
  {
    lng: -43.3832872,
    lat: -22.6199439
  },
  {
    lng: -43.3833953,
    lat: -22.6199614
  },
  {
    lng: -43.3834677,
    lat: -22.6200419
  },
  {
    lng: -43.3835096,
    lat: -22.6201608
  },
  {
    lng: -43.3836012,
    lat: -22.6202401
  },
  {
    lng: -43.3837613,
    lat: -22.620315
  },
  {
    lng: -43.383952,
    lat: -22.6203513
  },
  {
    lng: -43.3840659,
    lat: -22.6203562
  },
  {
    lng: -43.3841865,
    lat: -22.6203124
  },
  {
    lng: -43.3842842,
    lat: -22.620197
  },
  {
    lng: -43.3843687,
    lat: -22.6200702
  },
  {
    lng: -43.3844693,
    lat: -22.6199672
  },
  {
    lng: -43.3845364,
    lat: -22.6199384
  },
  {
    lng: -43.3846257,
    lat: -22.6199692
  },
  {
    lng: -43.384704,
    lat: -22.6200371
  },
  {
    lng: -43.3847821,
    lat: -22.620105
  },
  {
    lng: -43.3848671,
    lat: -22.6201846
  },
  {
    lng: -43.3849697,
    lat: -22.6202267
  },
  {
    lng: -43.3850314,
    lat: -22.6202226
  },
  {
    lng: -43.3851196,
    lat: -22.6201808
  },
  {
    lng: -43.3851973,
    lat: -22.6201027
  },
  {
    lng: -43.3852492,
    lat: -22.6200031
  },
  {
    lng: -43.3853298,
    lat: -22.619852
  },
  {
    lng: -43.3854316,
    lat: -22.619579
  },
  {
    lng: -43.3855148,
    lat: -22.6193798
  },
  {
    lng: -43.3855935,
    lat: -22.6192173
  },
  {
    lng: -43.3857827,
    lat: -22.6189387
  },
  {
    lng: -43.3858726,
    lat: -22.6187997
  },
  {
    lng: -43.3860384,
    lat: -22.6185711
  },
  {
    lng: -43.3861674,
    lat: -22.6183811
  },
  {
    lng: -43.3862515,
    lat: -22.6181939
  },
  {
    lng: -43.386319,
    lat: -22.6179956
  },
  {
    lng: -43.3863687,
    lat: -22.6178954
  },
  {
    lng: -43.386386,
    lat: -22.6177971
  },
  {
    lng: -43.3864706,
    lat: -22.6176828
  },
  {
    lng: -43.3865894,
    lat: -22.6176026
  },
  {
    lng: -43.386688,
    lat: -22.6175116
  },
  {
    lng: -43.3867145,
    lat: -22.6174702
  },
  {
    lng: -43.3867687,
    lat: -22.6173852
  },
  {
    lng: -43.3868844,
    lat: -22.6171838
  },
  {
    lng: -43.387005,
    lat: -22.6170187
  },
  {
    lng: -43.3870693,
    lat: -22.6169423
  },
  {
    lng: -43.3872646,
    lat: -22.6167235
  },
  {
    lng: -43.387429,
    lat: -22.6165315
  },
  {
    lng: -43.3875595,
    lat: -22.6163654
  },
  {
    lng: -43.387706,
    lat: -22.6161988
  },
  {
    lng: -43.3877376,
    lat: -22.6161597
  },
  {
    lng: -43.3878397,
    lat: -22.6160331
  },
  {
    lng: -43.3880001,
    lat: -22.6158898
  },
  {
    lng: -43.3881871,
    lat: -22.615757
  },
  {
    lng: -43.3883986,
    lat: -22.6155985
  },
  {
    lng: -43.3886101,
    lat: -22.6154399
  },
  {
    lng: -43.3888259,
    lat: -22.615306
  },
  {
    lng: -43.3889677,
    lat: -22.6151762
  },
  {
    lng: -43.3891783,
    lat: -22.6150056
  },
  {
    lng: -43.3893729,
    lat: -22.6148966
  },
  {
    lng: -43.3895982,
    lat: -22.6148226
  },
  {
    lng: -43.3898034,
    lat: -22.6147378
  },
  {
    lng: -43.3899785,
    lat: -22.61463
  },
  {
    lng: -43.3901133,
    lat: -22.6145619
  },
  {
    lng: -43.3903119,
    lat: -22.6144777
  },
  {
    lng: -43.3905302,
    lat: -22.6144042
  },
  {
    lng: -43.3906338,
    lat: -22.6143805
  },
  {
    lng: -43.3907396,
    lat: -22.6143564
  },
  {
    lng: -43.3909337,
    lat: -22.6142827
  },
  {
    lng: -43.3911378,
    lat: -22.6141861
  },
  {
    lng: -43.3913063,
    lat: -22.6140788
  },
  {
    lng: -43.3914924,
    lat: -22.6140076
  },
  {
    lng: -43.3917536,
    lat: -22.6139686
  },
  {
    lng: -43.3918842,
    lat: -22.6139516
  },
  {
    lng: -43.392092,
    lat: -22.6139247
  },
  {
    lng: -43.3922747,
    lat: -22.6139139
  },
  {
    lng: -43.392411,
    lat: -22.613882
  },
  {
    lng: -43.3925106,
    lat: -22.6138154
  },
  {
    lng: -43.3925736,
    lat: -22.6137872
  },
  {
    lng: -43.3926803,
    lat: -22.6137704
  },
  {
    lng: -43.3929001,
    lat: -22.6137331
  },
  {
    lng: -43.3931935,
    lat: -22.6136566
  },
  {
    lng: -43.3934201,
    lat: -22.6136042
  },
  {
    lng: -43.3936931,
    lat: -22.6135851
  },
  {
    lng: -43.393875,
    lat: -22.6135414
  },
  {
    lng: -43.3939856,
    lat: -22.6134981
  },
  {
    lng: -43.394097,
    lat: -22.6134205
  },
  {
    lng: -43.3942425,
    lat: -22.6133647
  },
  {
    lng: -43.3946018,
    lat: -22.6132791
  },
  {
    lng: -43.3948284,
    lat: -22.6132267
  },
  {
    lng: -43.3950032,
    lat: -22.6132172
  },
  {
    lng: -43.3951428,
    lat: -22.6132428
  },
  {
    lng: -43.3952512,
    lat: -22.6131977
  },
  {
    lng: -43.3953318,
    lat: -22.613131
  },
  {
    lng: -43.3954068,
    lat: -22.6130414
  },
  {
    lng: -43.3954935,
    lat: -22.6129748
  },
  {
    lng: -43.3956042,
    lat: -22.6129315
  },
  {
    lng: -43.3958277,
    lat: -22.6129116
  },
  {
    lng: -43.3960782,
    lat: -22.6128825
  },
  {
    lng: -43.3963134,
    lat: -22.6128857
  },
  {
    lng: -43.3965182,
    lat: -22.6128769
  },
  {
    lng: -43.3966656,
    lat: -22.6128229
  },
  {
    lng: -43.3968381,
    lat: -22.6127353
  },
  {
    lng: -43.3970313,
    lat: -22.6126921
  },
  {
    lng: -43.3972238,
    lat: -22.612683
  },
  {
    lng: -43.3974322,
    lat: -22.6126303
  },
  {
    lng: -43.3976384,
    lat: -22.6125419
  },
  {
    lng: -43.3977982,
    lat: -22.6124768
  },
  {
    lng: -43.397945,
    lat: -22.612457
  },
  {
    lng: -43.3980772,
    lat: -22.6124013
  },
  {
    lng: -43.3982256,
    lat: -22.6123018
  },
  {
    lng: -43.3983309,
    lat: -22.6122242
  },
  {
    lng: -43.3984357,
    lat: -22.6121694
  },
  {
    lng: -43.3985488,
    lat: -22.6121475
  },
  {
    lng: -43.3987392,
    lat: -22.612083
  },
  {
    lng: -43.3987696,
    lat: -22.612066
  },
  {
    lng: -43.3988379,
    lat: -22.612028
  },
  {
    lng: -43.3989312,
    lat: -22.6119388
  },
  {
    lng: -43.3990128,
    lat: -22.6118265
  },
  {
    lng: -43.399134,
    lat: -22.6117023
  },
  {
    lng: -43.3992611,
    lat: -22.6116024
  },
  {
    lng: -43.3993882,
    lat: -22.6115024
  },
  {
    lng: -43.3996976,
    lat: -22.6112921
  },
  {
    lng: -43.39976,
    lat: -22.611225
  },
  {
    lng: -43.399847,
    lat: -22.6111469
  },
  {
    lng: -43.4000152,
    lat: -22.6111035
  },
  {
    lng: -43.4001389,
    lat: -22.6110262
  },
  {
    lng: -43.4001582,
    lat: -22.6110108
  },
  {
    lng: -43.4003064,
    lat: -22.6108928
  },
  {
    lng: -43.4004511,
    lat: -22.6108034
  },
  {
    lng: -43.4005625,
    lat: -22.6107258
  },
  {
    lng: -43.400662,
    lat: -22.6106366
  },
  {
    lng: -43.4007254,
    lat: -22.6105239
  },
  {
    lng: -43.400741,
    lat: -22.6104985
  },
  {
    lng: -43.4007735,
    lat: -22.610446
  },
  {
    lng: -43.4009432,
    lat: -22.610323
  },
  {
    lng: -43.4011106,
    lat: -22.6102008
  },
  {
    lng: -43.4012658,
    lat: -22.6100784
  },
  {
    lng: -43.4014391,
    lat: -22.6099677
  },
  {
    lng: -43.4015837,
    lat: -22.6098785
  },
  {
    lng: -43.4017131,
    lat: -22.6098239
  },
  {
    lng: -43.4018933,
    lat: -22.609781
  },
  {
    lng: -43.4020417,
    lat: -22.6097944
  },
  {
    lng: -43.4021209,
    lat: -22.6098073
  },
  {
    lng: -43.4022683,
    lat: -22.609776
  },
  {
    lng: -43.4024594,
    lat: -22.6097902
  },
  {
    lng: -43.4025772,
    lat: -22.6098031
  },
  {
    lng: -43.4026696,
    lat: -22.6098611
  },
  {
    lng: -43.4026965,
    lat: -22.6098876
  },
  {
    lng: -43.4027151,
    lat: -22.6099059
  },
  {
    lng: -43.4027406,
    lat: -22.6099764
  },
  {
    lng: -43.4027474,
    lat: -22.6100326
  },
  {
    lng: -43.4027637,
    lat: -22.6101355
  },
  {
    lng: -43.402825,
    lat: -22.6102155
  },
  {
    lng: -43.4028913,
    lat: -22.6102623
  },
  {
    lng: -43.402927,
    lat: -22.6103086
  },
  {
    lng: -43.4029333,
    lat: -22.6103875
  },
  {
    lng: -43.4029197,
    lat: -22.6104555
  },
  {
    lng: -43.4028815,
    lat: -22.6105345
  },
  {
    lng: -43.4028435,
    lat: -22.6106021
  },
  {
    lng: -43.4028236,
    lat: -22.6106814
  },
  {
    lng: -43.4028301,
    lat: -22.6107488
  },
  {
    lng: -43.4028469,
    lat: -22.6108288
  },
  {
    lng: -43.4029009,
    lat: -22.6108753
  },
  {
    lng: -43.4030605,
    lat: -22.610923
  },
  {
    lng: -43.4032625,
    lat: -22.6109828
  },
  {
    lng: -43.4033185,
    lat: -22.611017
  },
  {
    lng: -43.4033725,
    lat: -22.6110635
  },
  {
    lng: -43.4034157,
    lat: -22.6111202
  },
  {
    lng: -43.4034144,
    lat: -22.6111884
  },
  {
    lng: -43.4034178,
    lat: -22.6113249
  },
  {
    lng: -43.4034673,
    lat: -22.6113702
  },
  {
    lng: -43.4035157,
    lat: -22.6113939
  },
  {
    lng: -43.4035952,
    lat: -22.6113842
  },
  {
    lng: -43.403671,
    lat: -22.611339
  },
  {
    lng: -43.4037519,
    lat: -22.6112611
  },
  {
    lng: -43.4038082,
    lat: -22.611194
  },
  {
    lng: -43.4038326,
    lat: -22.611116
  },
  {
    lng: -43.4038711,
    lat: -22.6110258
  },
  {
    lng: -43.4039828,
    lat: -22.6109371
  },
  {
    lng: -43.4040822,
    lat: -22.6109266
  },
  {
    lng: -43.4041736,
    lat: -22.6109398
  },
  {
    lng: -43.4042892,
    lat: -22.611032
  },
  {
    lng: -43.4043677,
    lat: -22.6111461
  },
  {
    lng: -43.4043863,
    lat: -22.6112693
  },
  {
    lng: -43.4043659,
    lat: -22.6113711
  },
  {
    lng: -43.4042851,
    lat: -22.6115159
  },
  {
    lng: -43.4042767,
    lat: -22.6116293
  },
  {
    lng: -43.4042934,
    lat: -22.6117092
  },
  {
    lng: -43.4043723,
    lat: -22.6118003
  },
  {
    lng: -43.4044694,
    lat: -22.611825
  },
  {
    lng: -43.4045753,
    lat: -22.6117805
  },
  {
    lng: -43.4046989,
    lat: -22.6117036
  },
  {
    lng: -43.4047474,
    lat: -22.6116642
  },
  {
    lng: -43.4048354,
    lat: -22.6115928
  },
  {
    lng: -43.4049716,
    lat: -22.6114934
  },
  {
    lng: -43.4051457,
    lat: -22.6113936
  },
  {
    lng: -43.4052929,
    lat: -22.6113625
  },
  {
    lng: -43.4053609,
    lat: -22.611374
  },
  {
    lng: -43.4055303,
    lat: -22.6115012
  },
  {
    lng: -43.4056451,
    lat: -22.6116158
  },
  {
    lng: -43.4057275,
    lat: -22.6117437
  },
  {
    lng: -43.4058299,
    lat: -22.6118242
  },
  {
    lng: -43.4059581,
    lat: -22.6118481
  },
  {
    lng: -43.4060622,
    lat: -22.6118491
  },
  {
    lng: -43.4061239,
    lat: -22.6118269
  },
  {
    lng: -43.4061871,
    lat: -22.6117364
  },
  {
    lng: -43.4062554,
    lat: -22.6116474
  },
  {
    lng: -43.406393,
    lat: -22.6115121
  },
  {
    lng: -43.4065785,
    lat: -22.6113899
  },
  {
    lng: -43.4066963,
    lat: -22.6113227
  },
  {
    lng: -43.4068379,
    lat: -22.6112898
  },
  {
    lng: -43.4069682,
    lat: -22.6112443
  },
  {
    lng: -43.4071156,
    lat: -22.6112229
  },
  {
    lng: -43.407251,
    lat: -22.61119
  },
  {
    lng: -43.4073438,
    lat: -22.6111453
  },
  {
    lng: -43.4073878,
    lat: -22.6110889
  },
  {
    lng: -43.4074326,
    lat: -22.6109983
  },
  {
    lng: -43.4074642,
    lat: -22.6109201
  },
  {
    lng: -43.4075208,
    lat: -22.6108523
  },
  {
    lng: -43.4076199,
    lat: -22.6107964
  },
  {
    lng: -43.4077386,
    lat: -22.6107642
  },
  {
    lng: -43.4078165,
    lat: -22.6107641
  },
  {
    lng: -43.4079019,
    lat: -22.6107762
  },
  {
    lng: -43.4080603,
    lat: -22.6108118
  },
  {
    lng: -43.4081943,
    lat: -22.6108472
  },
  {
    lng: -43.4082983,
    lat: -22.6108481
  },
  {
    lng: -43.4084341,
    lat: -22.6107925
  },
  {
    lng: -43.408484,
    lat: -22.6107475
  },
  {
    lng: -43.4085986,
    lat: -22.6105324
  },
  {
    lng: -43.4086554,
    lat: -22.6104429
  },
  {
    lng: -43.4087738,
    lat: -22.6103416
  },
  {
    lng: -43.4088799,
    lat: -22.6102298
  },
  {
    lng: -43.408979,
    lat: -22.6101738
  },
  {
    lng: -43.4090708,
    lat: -22.6101747
  },
  {
    lng: -43.4092293,
    lat: -22.6102094
  },
  {
    lng: -43.4093931,
    lat: -22.6102791
  },
  {
    lng: -43.4096114,
    lat: -22.6103712
  },
  {
    lng: -43.4098416,
    lat: -22.6104757
  },
  {
    lng: -43.4099444,
    lat: -22.6105335
  },
  {
    lng: -43.4100282,
    lat: -22.6106129
  },
  {
    lng: -43.410087,
    lat: -22.6107272
  },
  {
    lng: -43.4101829,
    lat: -22.6108066
  },
  {
    lng: -43.4102744,
    lat: -22.6108064
  },
  {
    lng: -43.4103786,
    lat: -22.610796
  },
  {
    lng: -43.4104775,
    lat: -22.6107525
  },
  {
    lng: -43.4105707,
    lat: -22.6106738
  },
  {
    lng: -43.4106198,
    lat: -22.6106505
  },
  {
    lng: -43.4106924,
    lat: -22.6106977
  },
  {
    lng: -43.4108502,
    lat: -22.6107322
  },
  {
    lng: -43.4110273,
    lat: -22.6107339
  },
  {
    lng: -43.4111431,
    lat: -22.6107464
  },
  {
    lng: -43.4112544,
    lat: -22.6106792
  },
  {
    lng: -43.4112931,
    lat: -22.610601
  },
  {
    lng: -43.4113262,
    lat: -22.6104763
  },
  {
    lng: -43.4113473,
    lat: -22.6103638
  },
  {
    lng: -43.4114081,
    lat: -22.6101037
  },
  {
    lng: -43.4114488,
    lat: -22.6099344
  },
  {
    lng: -43.411519,
    lat: -22.6097872
  },
  {
    lng: -43.4116536,
    lat: -22.6095163
  },
  {
    lng: -43.4117686,
    lat: -22.609302
  },
  {
    lng: -43.4118334,
    lat: -22.6091554
  },
  {
    lng: -43.4118805,
    lat: -22.6089745
  },
  {
    lng: -43.4119266,
    lat: -22.6088156
  },
  {
    lng: -43.4120107,
    lat: -22.6086236
  },
  {
    lng: -43.4120696,
    lat: -22.6084653
  },
  {
    lng: -43.4121792,
    lat: -22.6081933
  },
  {
    lng: -43.4122375,
    lat: -22.6080347
  },
  {
    lng: -43.4122469,
    lat: -22.6079099
  },
  {
    lng: -43.4122699,
    lat: -22.6076827
  },
  {
    lng: -43.4123099,
    lat: -22.6075582
  },
  {
    lng: -43.4123673,
    lat: -22.607445
  },
  {
    lng: -43.4124431,
    lat: -22.6073662
  },
  {
    lng: -43.4125612,
    lat: -22.6072763
  },
  {
    lng: -43.4126241,
    lat: -22.6071973
  },
  {
    lng: -43.4126503,
    lat: -22.6071521
  },
  {
    lng: -43.4126948,
    lat: -22.6070729
  },
  {
    lng: -43.4127422,
    lat: -22.6068914
  },
  {
    lng: -43.4127885,
    lat: -22.6067212
  },
  {
    lng: -43.4128551,
    lat: -22.6065056
  },
  {
    lng: -43.4128968,
    lat: -22.606432
  },
  {
    lng: -43.4129386,
    lat: -22.6063584
  },
  {
    lng: -43.412977,
    lat: -22.6062791
  },
  {
    lng: -43.413017,
    lat: -22.6061656
  },
  {
    lng: -43.4130195,
    lat: -22.6060404
  },
  {
    lng: -43.4130225,
    lat: -22.6059378
  },
  {
    lng: -43.4130736,
    lat: -22.6058359
  },
  {
    lng: -43.4132551,
    lat: -22.6057007
  },
  {
    lng: -43.4133797,
    lat: -22.6056224
  },
  {
    lng: -43.4133979,
    lat: -22.605611
  },
  {
    lng: -43.4134371,
    lat: -22.6055428
  },
  {
    lng: -43.413438,
    lat: -22.6054973
  },
  {
    lng: -43.4133811,
    lat: -22.6053256
  },
  {
    lng: -43.4133044,
    lat: -22.605177
  },
  {
    lng: -43.4132421,
    lat: -22.6049711
  },
  {
    lng: -43.4132322,
    lat: -22.6047486
  },
  {
    lng: -43.4131946,
    lat: -22.604685
  },
  {
    lng: -43.4130496,
    lat: -22.6045699
  },
  {
    lng: -43.4128559,
    lat: -22.6044764
  },
  {
    lng: -43.412709,
    lat: -22.6044523
  },
  {
    lng: -43.4124956,
    lat: -22.6043372
  },
  {
    lng: -43.4124121,
    lat: -22.6042561
  },
  {
    lng: -43.4123769,
    lat: -22.6041761
  },
  {
    lng: -43.4123778,
    lat: -22.6041306
  },
  {
    lng: -43.4124096,
    lat: -22.604074
  },
  {
    lng: -43.4124423,
    lat: -22.6040166
  },
  {
    lng: -43.4124838,
    lat: -22.6039684
  },
  {
    lng: -43.4125492,
    lat: -22.6038924
  },
  {
    lng: -43.4125813,
    lat: -22.6038244
  },
  {
    lng: -43.4125833,
    lat: -22.6037667
  },
  {
    lng: -43.4125719,
    lat: -22.603721
  },
  {
    lng: -43.4125549,
    lat: -22.6036526
  },
  {
    lng: -43.4125573,
    lat: -22.6035721
  },
  {
    lng: -43.4125958,
    lat: -22.6034927
  },
  {
    lng: -43.4127109,
    lat: -22.6033107
  },
  {
    lng: -43.4128445,
    lat: -22.6031288
  },
  {
    lng: -43.4129591,
    lat: -22.6029364
  },
  {
    lng: -43.4130932,
    lat: -22.6027316
  },
  {
    lng: -43.4132147,
    lat: -22.6025494
  },
  {
    lng: -43.4133241,
    lat: -22.6023557
  },
  {
    lng: -43.4134198,
    lat: -22.6021858
  },
  {
    lng: -43.4136284,
    lat: -22.6019282
  },
  {
    lng: -43.4137043,
    lat: -22.601857
  },
  {
    lng: -43.4138181,
    lat: -22.6016988
  },
  {
    lng: -43.4138548,
    lat: -22.6016671
  },
  {
    lng: -43.4139034,
    lat: -22.6016251
  },
  {
    lng: -43.4139632,
    lat: -22.6015735
  },
  {
    lng: -43.4140543,
    lat: -22.6014776
  },
  {
    lng: -43.4141454,
    lat: -22.6013817
  },
  {
    lng: -43.414181,
    lat: -22.6013244
  },
  {
    lng: -43.4142166,
    lat: -22.601267
  },
  {
    lng: -43.414223,
    lat: -22.6012594
  },
  {
    lng: -43.4142576,
    lat: -22.601218
  },
  {
    lng: -43.4142922,
    lat: -22.6011767
  },
  {
    lng: -43.4142952,
    lat: -22.601173
  },
  {
    lng: -43.4143658,
    lat: -22.6010854
  },
  {
    lng: -43.4143742,
    lat: -22.601075
  },
  {
    lng: -43.414386,
    lat: -22.6010681
  },
  {
    lng: -43.4144207,
    lat: -22.6010479
  },
  {
    lng: -43.4144812,
    lat: -22.6010126
  },
  {
    lng: -43.4145684,
    lat: -22.6009614
  },
  {
    lng: -43.4146379,
    lat: -22.600871
  },
  {
    lng: -43.4147841,
    lat: -22.6007
  },
  {
    lng: -43.4149035,
    lat: -22.6005759
  },
  {
    lng: -43.4150931,
    lat: -22.6003938
  },
  {
    lng: -43.415116,
    lat: -22.6003424
  },
  {
    lng: -43.4151848,
    lat: -22.600188
  },
  {
    lng: -43.4152881,
    lat: -22.6000164
  },
  {
    lng: -43.4153895,
    lat: -22.5998694
  },
  {
    lng: -43.4154222,
    lat: -22.5998376
  },
  {
    lng: -43.4154648,
    lat: -22.5997961
  },
  {
    lng: -43.4155401,
    lat: -22.5997229
  },
  {
    lng: -43.4156285,
    lat: -22.5996487
  },
  {
    lng: -43.415717,
    lat: -22.5995746
  },
  {
    lng: -43.4157267,
    lat: -22.5995659
  },
  {
    lng: -43.4157479,
    lat: -22.5995468
  },
  {
    lng: -43.4158423,
    lat: -22.599462
  },
  {
    lng: -43.415899,
    lat: -22.5993943
  },
  {
    lng: -43.4159473,
    lat: -22.5992106
  },
  {
    lng: -43.4159461,
    lat: -22.5990492
  },
  {
    lng: -43.4159192,
    lat: -22.5989648
  },
  {
    lng: -43.4158961,
    lat: -22.598853
  },
  {
    lng: -43.4159172,
    lat: -22.5987167
  },
  {
    lng: -43.4159279,
    lat: -22.5986142
  },
  {
    lng: -43.415941,
    lat: -22.5983027
  },
  {
    lng: -43.4159516,
    lat: -22.5981639
  },
  {
    lng: -43.4160047,
    lat: -22.5979709
  },
  {
    lng: -43.4160216,
    lat: -22.5978207
  },
  {
    lng: -43.4160689,
    lat: -22.5976611
  },
  {
    lng: -43.4161021,
    lat: -22.5975486
  },
  {
    lng: -43.4161507,
    lat: -22.5973844
  },
  {
    lng: -43.4162146,
    lat: -22.5972712
  },
  {
    lng: -43.4162781,
    lat: -22.5971098
  },
  {
    lng: -43.4163171,
    lat: -22.5969368
  },
  {
    lng: -43.4163325,
    lat: -22.5967833
  },
  {
    lng: -43.4163478,
    lat: -22.5966298
  },
  {
    lng: -43.4163448,
    lat: -22.5965587
  },
  {
    lng: -43.4163719,
    lat: -22.5964338
  },
  {
    lng: -43.416413,
    lat: -22.5963175
  },
  {
    lng: -43.4164735,
    lat: -22.5961558
  },
  {
    lng: -43.4165323,
    lat: -22.5959857
  },
  {
    lng: -43.4166111,
    lat: -22.5958355
  },
  {
    lng: -43.4166838,
    lat: -22.5956852
  },
  {
    lng: -43.4167246,
    lat: -22.5954922
  },
  {
    lng: -43.4167257,
    lat: -22.5954353
  },
  {
    lng: -43.4167271,
    lat: -22.5953556
  },
  {
    lng: -43.4167119,
    lat: -22.595284
  },
  {
    lng: -43.4166951,
    lat: -22.5951928
  },
  {
    lng: -43.4166844,
    lat: -22.5951657
  },
  {
    lng: -43.4166263,
    lat: -22.5950183
  },
  {
    lng: -43.4165601,
    lat: -22.5949266
  },
  {
    lng: -43.4164529,
    lat: -22.5948198
  },
  {
    lng: -43.4163371,
    lat: -22.594739
  },
  {
    lng: -43.4162042,
    lat: -22.5946774
  },
  {
    lng: -43.41604,
    lat: -22.5945392
  },
  {
    lng: -43.4159016,
    lat: -22.5944319
  },
  {
    lng: -43.4158356,
    lat: -22.5943288
  },
  {
    lng: -43.4158091,
    lat: -22.5941885
  },
  {
    lng: -43.4158076,
    lat: -22.5940369
  },
  {
    lng: -43.4158275,
    lat: -22.5938814
  },
  {
    lng: -43.4158708,
    lat: -22.5936506
  },
  {
    lng: -43.415891,
    lat: -22.5935527
  },
  {
    lng: -43.4157733,
    lat: -22.5934571
  },
  {
    lng: -43.4156709,
    lat: -22.5933574
  },
  {
    lng: -43.4155824,
    lat: -22.5932996
  },
  {
    lng: -43.4155415,
    lat: -22.5932687
  },
  {
    lng: -43.4155291,
    lat: -22.5932385
  },
  {
    lng: -43.4155228,
    lat: -22.5932231
  },
  {
    lng: -43.4154905,
    lat: -22.5931753
  },
  {
    lng: -43.4154271,
    lat: -22.593095
  },
  {
    lng: -43.4153522,
    lat: -22.5930069
  },
  {
    lng: -43.4152863,
    lat: -22.592936
  },
  {
    lng: -43.4152225,
    lat: -22.5928784
  },
  {
    lng: -43.4151438,
    lat: -22.5928244
  },
  {
    lng: -43.4150435,
    lat: -22.5927644
  },
  {
    lng: -43.4149485,
    lat: -22.5927178
  },
  {
    lng: -43.414859,
    lat: -22.5926998
  },
  {
    lng: -43.4147262,
    lat: -22.5926812
  },
  {
    lng: -43.4146178,
    lat: -22.59268
  },
  {
    lng: -43.4145095,
    lat: -22.5926788
  },
  {
    lng: -43.4143093,
    lat: -22.5926518
  },
  {
    lng: -43.4141364,
    lat: -22.5926214
  },
  {
    lng: -43.4140283,
    lat: -22.5926031
  },
  {
    lng: -43.4139206,
    lat: -22.5925677
  },
  {
    lng: -43.4138474,
    lat: -22.5925364
  },
  {
    lng: -43.413777,
    lat: -22.5924901
  },
  {
    lng: -43.413722,
    lat: -22.5924496
  },
  {
    lng: -43.413671,
    lat: -22.5923957
  },
  {
    lng: -43.4136163,
    lat: -22.5923381
  },
  {
    lng: -43.4135684,
    lat: -22.5922786
  },
  {
    lng: -43.4134991,
    lat: -22.5922017
  },
  {
    lng: -43.4134447,
    lat: -22.5921327
  },
  {
    lng: -43.4133997,
    lat: -22.5920846
  },
  {
    lng: -43.4133324,
    lat: -22.5920382
  },
  {
    lng: -43.4132966,
    lat: -22.5919902
  },
  {
    lng: -43.4132787,
    lat: -22.59195
  },
  {
    lng: -43.4132737,
    lat: -22.591893
  },
  {
    lng: -43.4132782,
    lat: -22.5918454
  },
  {
    lng: -43.4133009,
    lat: -22.5918001
  },
  {
    lng: -43.4133211,
    lat: -22.5917413
  },
  {
    lng: -43.4133158,
    lat: -22.5916956
  },
  {
    lng: -43.4133072,
    lat: -22.5916613
  },
  {
    lng: -43.4132774,
    lat: -22.5916247
  },
  {
    lng: -43.4132256,
    lat: -22.5915785
  },
  {
    lng: -43.4131672,
    lat: -22.5915493
  },
  {
    lng: -43.4130816,
    lat: -22.5915062
  },
  {
    lng: -43.4130417,
    lat: -22.5914829
  },
  {
    lng: -43.4129217,
    lat: -22.5914302
  },
  {
    lng: -43.4128235,
    lat: -22.5913927
  },
  {
    lng: -43.4126786,
    lat: -22.5913396
  },
  {
    lng: -43.412589,
    lat: -22.5913157
  },
  {
    lng: -43.4124998,
    lat: -22.5912897
  },
  {
    lng: -43.4124225,
    lat: -22.5912716
  },
  {
    lng: -43.4123205,
    lat: -22.5912475
  },
  {
    lng: -43.4122305,
    lat: -22.5912407
  },
  {
    lng: -43.4122114,
    lat: -22.5912364
  },
  {
    lng: -43.4121501,
    lat: -22.5912226
  },
  {
    lng: -43.412042,
    lat: -22.591187
  },
  {
    lng: -43.4119525,
    lat: -22.5911574
  },
  {
    lng: -43.411885,
    lat: -22.5911258
  },
  {
    lng: -43.4117551,
    lat: -22.5910957
  },
  {
    lng: -43.4116687,
    lat: -22.5910604
  },
  {
    lng: -43.4115883,
    lat: -22.5910365
  },
  {
    lng: -43.4115142,
    lat: -22.5910128
  },
  {
    lng: -43.4114495,
    lat: -22.5909926
  },
  {
    lng: -43.4113969,
    lat: -22.5909805
  },
  {
    lng: -43.4113258,
    lat: -22.5909613
  },
  {
    lng: -43.4112422,
    lat: -22.5909386
  },
  {
    lng: -43.4111772,
    lat: -22.5909206
  },
  {
    lng: -43.4110935,
    lat: -22.5909081
  },
  {
    lng: -43.4109977,
    lat: -22.5908784
  },
  {
    lng: -43.4108992,
    lat: -22.5908292
  },
  {
    lng: -43.4108312,
    lat: -22.5908055
  },
  {
    lng: -43.4107692,
    lat: -22.5907933
  },
  {
    lng: -43.4106944,
    lat: -22.590798
  },
  {
    lng: -43.4106316,
    lat: -22.5908222
  },
  {
    lng: -43.4105723,
    lat: -22.5908271
  },
  {
    lng: -43.4104861,
    lat: -22.5907838
  },
  {
    lng: -43.4104308,
    lat: -22.5907489
  },
  {
    lng: -43.4104068,
    lat: -22.5907029
  },
  {
    lng: -43.4103863,
    lat: -22.5906433
  },
  {
    lng: -43.410356,
    lat: -22.5906029
  },
  {
    lng: -43.4103159,
    lat: -22.5905853
  },
  {
    lng: -43.4102598,
    lat: -22.5905839
  },
  {
    lng: -43.4102258,
    lat: -22.5905727
  },
  {
    lng: -43.4101947,
    lat: -22.5905722
  },
  {
    lng: -43.41007,
    lat: -22.5905762
  },
  {
    lng: -43.4099887,
    lat: -22.5905865
  },
  {
    lng: -43.4099358,
    lat: -22.5905858
  },
  {
    lng: -43.4099111,
    lat: -22.5905741
  },
  {
    lng: -43.409884,
    lat: -22.590528
  },
  {
    lng: -43.409837,
    lat: -22.5904932
  },
  {
    lng: -43.4097883,
    lat: -22.5904556
  },
  {
    lng: -43.40973,
    lat: -22.5904294
  },
  {
    lng: -43.4096958,
    lat: -22.5904292
  },
  {
    lng: -43.409639,
    lat: -22.5904519
  },
  {
    lng: -43.4095815,
    lat: -22.5904975
  },
  {
    lng: -43.409534,
    lat: -22.590523
  },
  {
    lng: -43.4094962,
    lat: -22.5905343
  },
  {
    lng: -43.4094501,
    lat: -22.5905168
  },
  {
    lng: -43.4094108,
    lat: -22.5904792
  },
  {
    lng: -43.4093906,
    lat: -22.5904302
  },
  {
    lng: -43.4093643,
    lat: -22.5903784
  },
  {
    lng: -43.4093252,
    lat: -22.5903351
  },
  {
    lng: -43.409264,
    lat: -22.5903031
  },
  {
    lng: -43.4091742,
    lat: -22.5902882
  },
  {
    lng: -43.4091193,
    lat: -22.5902535
  },
  {
    lng: -43.4090312,
    lat: -22.5901898
  },
  {
    lng: -43.4089702,
    lat: -22.5901521
  },
  {
    lng: -43.4089123,
    lat: -22.5901116
  },
  {
    lng: -43.4088551,
    lat: -22.590051
  },
  {
    lng: -43.4088107,
    lat: -22.5899846
  },
  {
    lng: -43.4087563,
    lat: -22.5899355
  },
  {
    lng: -43.4086962,
    lat: -22.5898692
  },
  {
    lng: -43.4086275,
    lat: -22.5897885
  },
  {
    lng: -43.4085555,
    lat: -22.5897048
  },
  {
    lng: -43.408493,
    lat: -22.5896212
  },
  {
    lng: -43.4084266,
    lat: -22.5895606
  },
  {
    lng: -43.4083223,
    lat: -22.5894224
  },
  {
    lng: -43.4082632,
    lat: -22.5893361
  },
  {
    lng: -43.4082366,
    lat: -22.58929
  },
  {
    lng: -43.4082294,
    lat: -22.5892298
  },
  {
    lng: -43.408234,
    lat: -22.589184
  },
  {
    lng: -43.4082458,
    lat: -22.5891125
  },
  {
    lng: -43.4082602,
    lat: -22.5890495
  },
  {
    lng: -43.4082745,
    lat: -22.5889923
  },
  {
    lng: -43.408273,
    lat: -22.5889494
  },
  {
    lng: -43.408232,
    lat: -22.5888806
  },
  {
    lng: -43.4081843,
    lat: -22.5888202
  },
  {
    lng: -43.4081449,
    lat: -22.5887943
  },
  {
    lng: -43.4080926,
    lat: -22.5887767
  },
  {
    lng: -43.408072,
    lat: -22.5887451
  },
  {
    lng: -43.4080663,
    lat: -22.5887279
  },
  {
    lng: -43.4080702,
    lat: -22.588705
  },
  {
    lng: -43.4080806,
    lat: -22.5886707
  },
  {
    lng: -43.4080843,
    lat: -22.5886535
  },
  {
    lng: -43.4080857,
    lat: -22.5886165
  },
  {
    lng: -43.4080714,
    lat: -22.5885763
  },
  {
    lng: -43.4080272,
    lat: -22.5885045
  },
  {
    lng: -43.4079763,
    lat: -22.5884498
  },
  {
    lng: -43.4079248,
    lat: -22.5884123
  },
  {
    lng: -43.4078637,
    lat: -22.5883862
  },
  {
    lng: -43.4077738,
    lat: -22.588374
  },
  {
    lng: -43.4076512,
    lat: -22.5883333
  },
  {
    lng: -43.4075434,
    lat: -22.5883068
  },
  {
    lng: -43.407474,
    lat: -22.588267
  },
  {
    lng: -43.4073542,
    lat: -22.5882082
  },
  {
    lng: -43.4072748,
    lat: -22.5881705
  },
  {
    lng: -43.4071985,
    lat: -22.5881329
  },
  {
    lng: -43.4071512,
    lat: -22.5880668
  },
  {
    lng: -43.4070967,
    lat: -22.5880293
  },
  {
    lng: -43.407048,
    lat: -22.5880138
  },
  {
    lng: -43.4070412,
    lat: -22.5880116
  },
  {
    lng: -43.4069917,
    lat: -22.5880143
  },
  {
    lng: -43.4069308,
    lat: -22.588071
  },
  {
    lng: -43.406905,
    lat: -22.5880994
  },
  {
    lng: -43.4068576,
    lat: -22.5881219
  },
  {
    lng: -43.4068013,
    lat: -22.5881271
  },
  {
    lng: -43.4067429,
    lat: -22.5881182
  },
  {
    lng: -43.4066806,
    lat: -22.5881176
  },
  {
    lng: -43.4065388,
    lat: -22.5880996
  },
  {
    lng: -43.4063656,
    lat: -22.5880782
  },
  {
    lng: -43.406217,
    lat: -22.5880627
  },
  {
    lng: -43.4061214,
    lat: -22.5880477
  },
  {
    lng: -43.4060534,
    lat: -22.5880502
  },
  {
    lng: -43.4058968,
    lat: -22.5880294
  },
  {
    lng: -43.4057666,
    lat: -22.5880255
  },
  {
    lng: -43.4056207,
    lat: -22.5880272
  },
  {
    lng: -43.4055095,
    lat: -22.5880177
  },
  {
    lng: -43.4052776,
    lat: -22.5879987
  },
  {
    lng: -43.405157,
    lat: -22.5879892
  },
  {
    lng: -43.4050792,
    lat: -22.5879884
  },
  {
    lng: -43.4049986,
    lat: -22.5879964
  },
  {
    lng: -43.4049,
    lat: -22.5879813
  },
  {
    lng: -43.4048005,
    lat: -22.5879745
  },
  {
    lng: -43.4047693,
    lat: -22.5879656
  },
  {
    lng: -43.4047085,
    lat: -22.5879481
  },
  {
    lng: -43.4046129,
    lat: -22.5879387
  },
  {
    lng: -43.4044456,
    lat: -22.5879286
  },
  {
    lng: -43.4043742,
    lat: -22.5879424
  },
  {
    lng: -43.4042902,
    lat: -22.5879358
  },
  {
    lng: -43.4042045,
    lat: -22.5879094
  },
  {
    lng: -43.4041495,
    lat: -22.5879005
  },
  {
    lng: -43.4040901,
    lat: -22.5879055
  },
  {
    lng: -43.404029,
    lat: -22.5878908
  },
  {
    lng: -43.4039704,
    lat: -22.5878673
  },
  {
    lng: -43.403869,
    lat: -22.5878464
  },
  {
    lng: -43.4038145,
    lat: -22.5878203
  },
  {
    lng: -43.4037316,
    lat: -22.5878054
  },
  {
    lng: -43.4036447,
    lat: -22.5877929
  },
  {
    lng: -43.4035554,
    lat: -22.5877836
  },
  {
    lng: -43.4034667,
    lat: -22.5877572
  },
  {
    lng: -43.4033628,
    lat: -22.5877452
  },
  {
    lng: -43.4032455,
    lat: -22.5877355
  },
  {
    lng: -43.4031693,
    lat: -22.5877091
  },
  {
    lng: -43.4031359,
    lat: -22.5876801
  },
  {
    lng: -43.4031068,
    lat: -22.5876429
  },
  {
    lng: -43.4030519,
    lat: -22.587531
  },
  {
    lng: -43.4030145,
    lat: -22.5874594
  },
  {
    lng: -43.4029821,
    lat: -22.5874279
  },
  {
    lng: -43.4029279,
    lat: -22.587399
  },
  {
    lng: -43.402797,
    lat: -22.5873974
  },
  {
    lng: -43.4026942,
    lat: -22.5873568
  },
  {
    lng: -43.4026361,
    lat: -22.5872825
  },
  {
    lng: -43.4025945,
    lat: -22.5872134
  },
  {
    lng: -43.402597,
    lat: -22.5871333
  },
  {
    lng: -43.4026013,
    lat: -22.5870598
  },
  {
    lng: -43.4026023,
    lat: -22.5870255
  },
  {
    lng: -43.4025788,
    lat: -22.5869794
  },
  {
    lng: -43.4025142,
    lat: -22.5869166
  },
  {
    lng: -43.4024342,
    lat: -22.5868812
  },
  {
    lng: -43.4023674,
    lat: -22.5868232
  },
  {
    lng: -43.4023149,
    lat: -22.5867719
  },
  {
    lng: -43.4022393,
    lat: -22.5866632
  },
  {
    lng: -43.402186,
    lat: -22.5865711
  },
  {
    lng: -43.4021056,
    lat: -22.5864166
  },
  {
    lng: -43.4020055,
    lat: -22.5862962
  },
  {
    lng: -43.4019224,
    lat: -22.5862333
  },
  {
    lng: -43.4018822,
    lat: -22.5861184
  },
  {
    lng: -43.401813,
    lat: -22.5860099
  },
  {
    lng: -43.4017372,
    lat: -22.5859129
  },
  {
    lng: -43.4016517,
    lat: -22.5858545
  },
  {
    lng: -43.4016123,
    lat: -22.5857923
  },
  {
    lng: -43.4015627,
    lat: -22.585709
  },
  {
    lng: -43.4015068,
    lat: -22.5856491
  },
  {
    lng: -43.401465,
    lat: -22.5855914
  },
  {
    lng: -43.4014421,
    lat: -22.5855225
  },
  {
    lng: -43.4014369,
    lat: -22.5854882
  },
  {
    lng: -43.4014162,
    lat: -22.5854263
  },
  {
    lng: -43.401374,
    lat: -22.58538
  },
  {
    lng: -43.4012721,
    lat: -22.5853284
  },
  {
    lng: -43.4012116,
    lat: -22.5852704
  },
  {
    lng: -43.4011986,
    lat: -22.5851629
  },
  {
    lng: -43.4011689,
    lat: -22.5851168
  },
  {
    lng: -43.4010864,
    lat: -22.5850427
  },
  {
    lng: -43.4010393,
    lat: -22.5849745
  },
  {
    lng: -43.4010148,
    lat: -22.5849388
  },
  {
    lng: -43.400915,
    lat: -22.5848188
  },
  {
    lng: -43.4008215,
    lat: -22.584699
  },
  {
    lng: -43.4006523,
    lat: -22.5844938
  },
  {
    lng: -43.4005589,
    lat: -22.5843741
  },
  {
    lng: -43.4004535,
    lat: -22.5842427
  },
  {
    lng: -43.4003301,
    lat: -22.5840883
  },
  {
    lng: -43.4002496,
    lat: -22.5839574
  },
  {
    lng: -43.4002026,
    lat: -22.5838654
  },
  {
    lng: -43.4001584,
    lat: -22.5837694
  },
  {
    lng: -43.4001045,
    lat: -22.5837001
  },
  {
    lng: -43.4000528,
    lat: -22.5836496
  },
  {
    lng: -43.3999927,
    lat: -22.5835802
  },
  {
    lng: -43.3999286,
    lat: -22.5835296
  },
  {
    lng: -43.3998446,
    lat: -22.5834255
  },
  {
    lng: -43.3997727,
    lat: -22.583333
  },
  {
    lng: -43.3997214,
    lat: -22.5832713
  },
  {
    lng: -43.3996892,
    lat: -22.5831984
  },
  {
    lng: -43.3996169,
    lat: -22.5831174
  },
  {
    lng: -43.3995288,
    lat: -22.5830437
  },
  {
    lng: -43.3994683,
    lat: -22.5829856
  },
  {
    lng: -43.3993936,
    lat: -22.5828779
  },
  {
    lng: -43.3993324,
    lat: -22.5827364
  },
  {
    lng: -43.3992238,
    lat: -22.5825142
  },
  {
    lng: -43.3991496,
    lat: -22.5823953
  },
  {
    lng: -43.3990267,
    lat: -22.5822414
  },
  {
    lng: -43.3988908,
    lat: -22.58211
  },
  {
    lng: -43.3988026,
    lat: -22.5820479
  },
  {
    lng: -43.3987732,
    lat: -22.5819904
  },
  {
    lng: -43.3987298,
    lat: -22.5818836
  },
  {
    lng: -43.3987128,
    lat: -22.5818263
  },
  {
    lng: -43.3986805,
    lat: -22.5817654
  },
  {
    lng: -43.3986325,
    lat: -22.5817075
  },
  {
    lng: -43.3985627,
    lat: -22.5816573
  },
  {
    lng: -43.398515,
    lat: -22.5815881
  },
  {
    lng: -43.39846,
    lat: -22.5814585
  },
  {
    lng: -43.3983349,
    lat: -22.5812901
  },
  {
    lng: -43.3982572,
    lat: -22.5811862
  },
  {
    lng: -43.3981833,
    lat: -22.5810677
  },
  {
    lng: -43.3981395,
    lat: -22.580984
  },
  {
    lng: -43.3980305,
    lat: -22.5808908
  },
  {
    lng: -43.3979736,
    lat: -22.5808296
  },
  {
    lng: -43.3978114,
    lat: -22.5807664
  },
  {
    lng: -43.3976205,
    lat: -22.580623
  },
  {
    lng: -43.3973466,
    lat: -22.5804749
  },
  {
    lng: -43.3971998,
    lat: -22.5804037
  },
  {
    lng: -43.3970633,
    lat: -22.5803183
  },
  {
    lng: -43.3968776,
    lat: -22.5802206
  },
  {
    lng: -43.3967915,
    lat: -22.5801961
  },
  {
    lng: -43.3966466,
    lat: -22.5801902
  },
  {
    lng: -43.3965977,
    lat: -22.5801664
  },
  {
    lng: -43.3965673,
    lat: -22.580143
  },
  {
    lng: -43.3965435,
    lat: -22.5801084
  },
  {
    lng: -43.3965245,
    lat: -22.5800369
  },
  {
    lng: -43.3964469,
    lat: -22.579984
  },
  {
    lng: -43.3963807,
    lat: -22.5799145
  },
  {
    lng: -43.3962797,
    lat: -22.5798981
  },
  {
    lng: -43.39618,
    lat: -22.5799188
  },
  {
    lng: -43.3961303,
    lat: -22.579901
  },
  {
    lng: -43.3961125,
    lat: -22.5798946
  },
  {
    lng: -43.3960949,
    lat: -22.5798602
  },
  {
    lng: -43.3961074,
    lat: -22.579778
  },
  {
    lng: -43.3961217,
    lat: -22.5797102
  },
  {
    lng: -43.3961165,
    lat: -22.579676
  },
  {
    lng: -43.3960803,
    lat: -22.5796411
  },
  {
    lng: -43.3960234,
    lat: -22.579603
  },
  {
    lng: -43.395912,
    lat: -22.5796007
  },
  {
    lng: -43.3958683,
    lat: -22.5796111
  },
  {
    lng: -43.3957986,
    lat: -22.5796664
  },
  {
    lng: -43.3957487,
    lat: -22.5796767
  },
  {
    lng: -43.3957057,
    lat: -22.5796645
  },
  {
    lng: -43.3956754,
    lat: -22.5796411
  },
  {
    lng: -43.3956622,
    lat: -22.5795926
  },
  {
    lng: -43.3956697,
    lat: -22.5795473
  },
  {
    lng: -43.3957016,
    lat: -22.5795139
  },
  {
    lng: -43.3957343,
    lat: -22.5794578
  },
  {
    lng: -43.3957455,
    lat: -22.5794212
  },
  {
    lng: -43.3957403,
    lat: -22.579387
  },
  {
    lng: -43.3956803,
    lat: -22.5793176
  },
  {
    lng: -43.3956001,
    lat: -22.5792337
  },
  {
    lng: -43.395507,
    lat: -22.579198
  },
  {
    lng: -43.3953976,
    lat: -22.5791275
  },
  {
    lng: -43.3953136,
    lat: -22.5790348
  },
  {
    lng: -43.3952029,
    lat: -22.5790097
  },
  {
    lng: -43.3950506,
    lat: -22.5790012
  },
  {
    lng: -43.3949387,
    lat: -22.5790214
  },
  {
    lng: -43.3948378,
    lat: -22.5790873
  },
  {
    lng: -43.3947598,
    lat: -22.5792218
  },
  {
    lng: -43.3946843,
    lat: -22.5792656
  },
  {
    lng: -43.3945971,
    lat: -22.5792864
  },
  {
    lng: -43.3945352,
    lat: -22.579285
  },
  {
    lng: -43.3944252,
    lat: -22.5792372
  },
  {
    lng: -43.3942533,
    lat: -22.579188
  },
  {
    lng: -43.3941036,
    lat: -22.5792301
  },
  {
    lng: -43.3939884,
    lat: -22.5793639
  },
  {
    lng: -43.3939246,
    lat: -22.5794306
  },
  {
    lng: -43.393838,
    lat: -22.5794287
  },
  {
    lng: -43.3937527,
    lat: -22.5793814
  },
  {
    lng: -43.3937149,
    lat: -22.5792843
  },
  {
    lng: -43.3937422,
    lat: -22.5791941
  },
  {
    lng: -43.3937942,
    lat: -22.5791044
  },
  {
    lng: -43.3938092,
    lat: -22.579014
  },
  {
    lng: -43.3937733,
    lat: -22.5789678
  },
  {
    lng: -43.3936874,
    lat: -22.5789432
  },
  {
    lng: -43.3935236,
    lat: -22.5789343
  },
  {
    lng: -43.3932731,
    lat: -22.5790422
  },
  {
    lng: -43.3931486,
    lat: -22.5791143
  },
  {
    lng: -43.3930845,
    lat: -22.5791514
  },
  {
    lng: -43.3929478,
    lat: -22.5791711
  },
  {
    lng: -43.3928495,
    lat: -22.5791462
  },
  {
    lng: -43.3928234,
    lat: -22.579121
  },
  {
    lng: -43.3928013,
    lat: -22.5790997
  },
  {
    lng: -43.3928032,
    lat: -22.5790316
  },
  {
    lng: -43.3928903,
    lat: -22.5789149
  },
  {
    lng: -43.393043,
    lat: -22.5787595
  },
  {
    lng: -43.3930832,
    lat: -22.578647
  },
  {
    lng: -43.3930734,
    lat: -22.578556
  },
  {
    lng: -43.3930252,
    lat: -22.5785095
  },
  {
    lng: -43.3929022,
    lat: -22.578484
  },
  {
    lng: -43.3926654,
    lat: -22.578451
  },
  {
    lng: -43.3925926,
    lat: -22.5784039
  },
  {
    lng: -43.3925846,
    lat: -22.5782449
  },
  {
    lng: -43.392647,
    lat: -22.5781281
  },
  {
    lng: -43.3927472,
    lat: -22.578085
  },
  {
    lng: -43.3928487,
    lat: -22.5780921
  },
  {
    lng: -43.3930093,
    lat: -22.5780958
  },
  {
    lng: -43.393073,
    lat: -22.5780292
  },
  {
    lng: -43.3930742,
    lat: -22.5779838
  },
  {
    lng: -43.3930857,
    lat: -22.5779114
  },
  {
    lng: -43.3929882,
    lat: -22.5778638
  },
  {
    lng: -43.39289,
    lat: -22.5778389
  },
  {
    lng: -43.3926689,
    lat: -22.5777884
  },
  {
    lng: -43.3925955,
    lat: -22.577764
  },
  {
    lng: -43.3925319,
    lat: -22.5777353
  },
  {
    lng: -43.3924837,
    lat: -22.5776888
  },
  {
    lng: -43.3924362,
    lat: -22.5776197
  },
  {
    lng: -43.3923634,
    lat: -22.5775726
  },
  {
    lng: -43.3921655,
    lat: -22.5774956
  },
  {
    lng: -43.3918711,
    lat: -22.5774206
  },
  {
    lng: -43.3916379,
    lat: -22.5773698
  },
  {
    lng: -43.3913676,
    lat: -22.5772457
  },
  {
    lng: -43.3911199,
    lat: -22.5771407
  },
  {
    lng: -43.3909042,
    lat: -22.576909
  },
  {
    lng: -43.3907726,
    lat: -22.576698
  },
  {
    lng: -43.3906646,
    lat: -22.576533
  },
  {
    lng: -43.3905205,
    lat: -22.5763937
  },
  {
    lng: -43.3903154,
    lat: -22.5761812
  },
  {
    lng: -43.3901139,
    lat: -22.5760739
  },
  {
    lng: -43.3900483,
    lat: -22.5760389
  },
  {
    lng: -43.3898593,
    lat: -22.5759354
  },
  {
    lng: -43.3896952,
    lat: -22.5758455
  },
  {
    lng: -43.3894509,
    lat: -22.5757716
  },
  {
    lng: -43.3891321,
    lat: -22.5756923
  },
  {
    lng: -43.3887616,
    lat: -22.5756987
  },
  {
    lng: -43.3884667,
    lat: -22.5756913
  },
  {
    lng: -43.3882946,
    lat: -22.575687
  },
  {
    lng: -43.3880255,
    lat: -22.575635
  },
  {
    lng: -43.3877699,
    lat: -22.5755348
  },
  {
    lng: -43.3875758,
    lat: -22.5754395
  },
  {
    lng: -43.3875198,
    lat: -22.5753747
  },
  {
    lng: -43.387433,
    lat: -22.5752744
  },
  {
    lng: -43.3873524,
    lat: -22.575069
  },
  {
    lng: -43.3873459,
    lat: -22.5748625
  },
  {
    lng: -43.3873759,
    lat: -22.57466
  },
  {
    lng: -43.3874815,
    lat: -22.574389
  },
  {
    lng: -43.3875635,
    lat: -22.5740837
  },
  {
    lng: -43.3875784,
    lat: -22.5739826
  },
  {
    lng: -43.3875581,
    lat: -22.5738241
  },
  {
    lng: -43.3875189,
    lat: -22.5737067
  },
  {
    lng: -43.3874797,
    lat: -22.5735893
  },
  {
    lng: -43.3873384,
    lat: -22.57338
  },
  {
    lng: -43.3872923,
    lat: -22.5732654
  },
  {
    lng: -43.3872463,
    lat: -22.5731508
  },
  {
    lng: -43.3871547,
    lat: -22.5729199
  },
  {
    lng: -43.3870381,
    lat: -22.5727115
  },
  {
    lng: -43.3868236,
    lat: -22.5725012
  },
  {
    lng: -43.3866349,
    lat: -22.5722462
  },
  {
    lng: -43.3864653,
    lat: -22.5721195
  },
  {
    lng: -43.3864852,
    lat: -22.5718056
  },
  {
    lng: -43.3865036,
    lat: -22.5715157
  },
  {
    lng: -43.3865387,
    lat: -22.5711117
  },
  {
    lng: -43.3865765,
    lat: -22.5706613
  },
  {
    lng: -43.3867146,
    lat: -22.5701686
  },
  {
    lng: -43.3868726,
    lat: -22.5697686
  },
  {
    lng: -43.3870475,
    lat: -22.5695941
  },
  {
    lng: -43.3872428,
    lat: -22.5695992
  },
  {
    lng: -43.3875379,
    lat: -22.569473
  },
  {
    lng: -43.3878608,
    lat: -22.5692132
  },
  {
    lng: -43.3881374,
    lat: -22.5688631
  },
  {
    lng: -43.3883368,
    lat: -22.5686465
  },
  {
    lng: -43.3883888,
    lat: -22.5685141
  },
  {
    lng: -43.3884444,
    lat: -22.5682478
  },
  {
    lng: -43.3884499,
    lat: -22.5680261
  },
  {
    lng: -43.3884583,
    lat: -22.5677141
  },
  {
    lng: -43.3883972,
    lat: -22.5672686
  },
  {
    lng: -43.3883777,
    lat: -22.5670526
  },
  {
    lng: -43.3881728,
    lat: -22.5666024
  },
  {
    lng: -43.3880341,
    lat: -22.5663762
  },
  {
    lng: -43.3880412,
    lat: -22.5661131
  },
  {
    lng: -43.3880459,
    lat: -22.5659356
  },
  {
    lng: -43.3881263,
    lat: -22.5656309
  },
  {
    lng: -43.3882522,
    lat: -22.5654169
  },
  {
    lng: -43.3882365,
    lat: -22.5651061
  },
  {
    lng: -43.3882205,
    lat: -22.5648001
  },
  {
    lng: -43.3881605,
    lat: -22.5643608
  },
  {
    lng: -43.3882164,
    lat: -22.5640582
  },
  {
    lng: -43.3883441,
    lat: -22.5637584
  },
  {
    lng: -43.3885911,
    lat: -22.5634627
  },
  {
    lng: -43.3888125,
    lat: -22.563211
  },
  {
    lng: -43.3890193,
    lat: -22.5628596
  },
  {
    lng: -43.3891281,
    lat: -22.5625763
  },
  {
    lng: -43.38946,
    lat: -22.5619704
  },
  {
    lng: -43.3897113,
    lat: -22.5616834
  },
  {
    lng: -43.3899608,
    lat: -22.5613729
  },
  {
    lng: -43.3901264,
    lat: -22.5611071
  },
  {
    lng: -43.3902052,
    lat: -22.5607955
  },
  {
    lng: -43.3901981,
    lat: -22.5604926
  },
  {
    lng: -43.3901446,
    lat: -22.5601683
  },
  {
    lng: -43.3900614,
    lat: -22.5597288
  },
  {
    lng: -43.390038,
    lat: -22.5593063
  },
  {
    lng: -43.3900832,
    lat: -22.5590522
  },
  {
    lng: -43.3901975,
    lat: -22.5587887
  },
  {
    lng: -43.3904169,
    lat: -22.5585128
  },
  {
    lng: -43.3907549,
    lat: -22.5582835
  },
  {
    lng: -43.391128,
    lat: -22.5581027
  },
  {
    lng: -43.3915501,
    lat: -22.5578954
  },
  {
    lng: -43.3919081,
    lat: -22.5574889
  },
  {
    lng: -43.3921534,
    lat: -22.5570373
  },
  {
    lng: -43.3924296,
    lat: -22.5565667
  },
  {
    lng: -43.3926943,
    lat: -22.556146
  },
  {
    lng: -43.3929385,
    lat: -22.5559108
  },
  {
    lng: -43.3931746,
    lat: -22.5558193
  },
  {
    lng: -43.3933685,
    lat: -22.5558118
  },
  {
    lng: -43.3936184,
    lat: -22.5558721
  },
  {
    lng: -43.3939332,
    lat: -22.5558911
  },
  {
    lng: -43.3941422,
    lat: -22.5558499
  },
  {
    lng: -43.3943066,
    lat: -22.5556554
  },
  {
    lng: -43.3944005,
    lat: -22.5554244
  },
  {
    lng: -43.3944359,
    lat: -22.55512
  },
  {
    lng: -43.3944856,
    lat: -22.5546409
  },
  {
    lng: -43.3946502,
    lat: -22.5543415
  },
  {
    lng: -43.3949635,
    lat: -22.5540238
  },
  {
    lng: -43.3954334,
    lat: -22.5537354
  },
  {
    lng: -43.3958668,
    lat: -22.5535333
  },
  {
    lng: -43.3963084,
    lat: -22.5533287
  },
  {
    lng: -43.3967387,
    lat: -22.5530926
  },
  {
    lng: -43.3971003,
    lat: -22.5528022
  },
  {
    lng: -43.3973712,
    lat: -22.5525628
  },
  {
    lng: -43.397623,
    lat: -22.5524399
  },
  {
    lng: -43.3979429,
    lat: -22.5523441
  },
  {
    lng: -43.398197,
    lat: -22.5521745
  },
  {
    lng: -43.3984195,
    lat: -22.5517972
  },
  {
    lng: -43.3984377,
    lat: -22.5517076
  },
  {
    lng: -43.3984536,
    lat: -22.5516011
  },
  {
    lng: -43.3985675,
    lat: -22.5513475
  },
  {
    lng: -43.3986239,
    lat: -22.5512969
  },
  {
    lng: -43.3987284,
    lat: -22.5512487
  },
  {
    lng: -43.3988919,
    lat: -22.5512254
  },
  {
    lng: -43.3991355,
    lat: -22.5512398
  },
  {
    lng: -43.3994359,
    lat: -22.5513513
  },
  {
    lng: -43.3998606,
    lat: -22.5513571
  },
  {
    lng: -43.4002486,
    lat: -22.5512559
  },
  {
    lng: -43.4005177,
    lat: -22.551108
  },
  {
    lng: -43.4007967,
    lat: -22.5508945
  },
  {
    lng: -43.4010199,
    lat: -22.5506846
  },
  {
    lng: -43.4012659,
    lat: -22.5504584
  },
  {
    lng: -43.4017026,
    lat: -22.5501369
  },
  {
    lng: -43.4021233,
    lat: -22.5499282
  },
  {
    lng: -43.4024788,
    lat: -22.5497763
  },
  {
    lng: -43.4028858,
    lat: -22.5495673
  },
  {
    lng: -43.4032747,
    lat: -22.5493782
  },
  {
    lng: -43.4035758,
    lat: -22.5492104
  },
  {
    lng: -43.4038302,
    lat: -22.5489994
  },
  {
    lng: -43.4041215,
    lat: -22.5484712
  },
  {
    lng: -43.4044368,
    lat: -22.5481232
  },
  {
    lng: -43.404443,
    lat: -22.5481195
  },
  {
    lng: -43.4046043,
    lat: -22.548025
  },
  {
    lng: -43.4047921,
    lat: -22.5479668
  },
  {
    lng: -43.4050013,
    lat: -22.547899
  },
  {
    lng: -43.4051814,
    lat: -22.54775
  },
  {
    lng: -43.405473,
    lat: -22.5475161
  },
  {
    lng: -43.4058783,
    lat: -22.5472691
  },
  {
    lng: -43.4061911,
    lat: -22.5470468
  },
  {
    lng: -43.4064903,
    lat: -22.5469091
  },
  {
    lng: -43.4067032,
    lat: -22.5468315
  },
  {
    lng: -43.40692,
    lat: -22.5467926
  },
  {
    lng: -43.4071037,
    lat: -22.5467371
  },
  {
    lng: -43.4072548,
    lat: -22.5466145
  },
  {
    lng: -43.4073785,
    lat: -22.5464588
  },
  {
    lng: -43.4075098,
    lat: -22.5463422
  },
  {
    lng: -43.4078803,
    lat: -22.546164
  },
  {
    lng: -43.408126,
    lat: -22.5460874
  },
  {
    lng: -43.4085885,
    lat: -22.545978
  },
  {
    lng: -43.4089292,
    lat: -22.5458993
  },
  {
    lng: -43.4091077,
    lat: -22.5458613
  },
  {
    lng: -43.4092415,
    lat: -22.5458291
  },
  {
    lng: -43.4094816,
    lat: -22.5457386
  },
  {
    lng: -43.4095938,
    lat: -22.5456899
  },
  {
    lng: -43.4096974,
    lat: -22.5456415
  },
  {
    lng: -43.4097884,
    lat: -22.5455831
  },
  {
    lng: -43.4098644,
    lat: -22.5455111
  },
  {
    lng: -43.4099365,
    lat: -22.5453933
  },
  {
    lng: -43.4099692,
    lat: -22.5453261
  },
  {
    lng: -43.4099967,
    lat: -22.5451438
  },
  {
    lng: -43.4100036,
    lat: -22.545063
  },
  {
    lng: -43.410003,
    lat: -22.5449702
  },
  {
    lng: -43.4100019,
    lat: -22.5448396
  },
  {
    lng: -43.4099945,
    lat: -22.5447068
  },
  {
    lng: -43.4099839,
    lat: -22.5446094
  },
  {
    lng: -43.4099707,
    lat: -22.5445188
  },
  {
    lng: -43.4099588,
    lat: -22.5444369
  },
  {
    lng: -43.409936,
    lat: -22.5443356
  },
  {
    lng: -43.4098882,
    lat: -22.5442087
  },
  {
    lng: -43.409853,
    lat: -22.5441215
  },
  {
    lng: -43.4098044,
    lat: -22.5440354
  },
  {
    lng: -43.4097758,
    lat: -22.5439979
  },
  {
    lng: -43.4097259,
    lat: -22.5439369
  },
  {
    lng: -43.4095351,
    lat: -22.5437986
  },
  {
    lng: -43.4094072,
    lat: -22.5437425
  },
  {
    lng: -43.4092827,
    lat: -22.5437095
  },
  {
    lng: -43.4091483,
    lat: -22.5436742
  },
  {
    lng: -43.4088801,
    lat: -22.5436153
  },
  {
    lng: -43.4086332,
    lat: -22.5435773
  },
  {
    lng: -43.4083308,
    lat: -22.5435102
  },
  {
    lng: -43.4081044,
    lat: -22.5434147
  },
  {
    lng: -43.4079699,
    lat: -22.5432962
  },
  {
    lng: -43.4077789,
    lat: -22.5431088
  },
  {
    lng: -43.4077375,
    lat: -22.5430624
  },
  {
    lng: -43.4076501,
    lat: -22.5430023
  },
  {
    lng: -43.407494,
    lat: -22.5429338
  },
  {
    lng: -43.4071832,
    lat: -22.5428408
  },
  {
    lng: -43.4071032,
    lat: -22.5428131
  },
  {
    lng: -43.4070261,
    lat: -22.5427726
  },
  {
    lng: -43.4069734,
    lat: -22.5427171
  },
  {
    lng: -43.4068268,
    lat: -22.5425624
  },
  {
    lng: -43.406721,
    lat: -22.5424538
  },
  {
    lng: -43.4066216,
    lat: -22.5423859
  },
  {
    lng: -43.4063088,
    lat: -22.5422719
  },
  {
    lng: -43.406032,
    lat: -22.5422561
  },
  {
    lng: -43.405906,
    lat: -22.5422604
  },
  {
    lng: -43.4057501,
    lat: -22.5422592
  },
  {
    lng: -43.4056151,
    lat: -22.5422406
  },
  {
    lng: -43.4053611,
    lat: -22.5421777
  },
  {
    lng: -43.405106,
    lat: -22.5420973
  },
  {
    lng: -43.4049119,
    lat: -22.5420243
  },
  {
    lng: -43.4048123,
    lat: -22.5419797
  },
  {
    lng: -43.4045432,
    lat: -22.5418919
  },
  {
    lng: -43.4044088,
    lat: -22.5418367
  },
  {
    lng: -43.404255,
    lat: -22.541803
  },
  {
    lng: -43.4040753,
    lat: -22.5417637
  },
  {
    lng: -43.4038337,
    lat: -22.5417562
  },
  {
    lng: -43.4035932,
    lat: -22.5417868
  },
  {
    lng: -43.403388,
    lat: -22.5418431
  },
  {
    lng: -43.4031908,
    lat: -22.5418968
  },
  {
    lng: -43.4029,
    lat: -22.5419535
  },
  {
    lng: -43.4025766,
    lat: -22.5420258
  },
  {
    lng: -43.4022638,
    lat: -22.5421182
  },
  {
    lng: -43.4020574,
    lat: -22.5421427
  },
  {
    lng: -43.4018975,
    lat: -22.5420729
  },
  {
    lng: -43.4017426,
    lat: -22.5420105
  },
  {
    lng: -43.4015151,
    lat: -22.5419658
  },
  {
    lng: -43.4013074,
    lat: -22.5419712
  },
  {
    lng: -43.4014238,
    lat: -22.5334362
  },
  {
    lng: -43.4014288,
    lat: -22.5334055
  },
  {
    lng: -43.4014277,
    lat: -22.5331519
  },
  {
    lng: -43.4014326,
    lat: -22.5327921
  },
  {
    lng: -43.4015485,
    lat: -22.5328756
  },
  {
    lng: -43.4019424,
    lat: -22.5329783
  },
  {
    lng: -43.4020928,
    lat: -22.5332432
  },
  {
    lng: -43.402314,
    lat: -22.5336877
  },
  {
    lng: -43.4025398,
    lat: -22.5338734
  },
  {
    lng: -43.4030039,
    lat: -22.5341438
  },
  {
    lng: -43.4033262,
    lat: -22.5341918
  },
  {
    lng: -43.4036711,
    lat: -22.5342402
  },
  {
    lng: -43.4040317,
    lat: -22.5343263
  },
  {
    lng: -43.404207,
    lat: -22.5343758
  },
  {
    lng: -43.4044219,
    lat: -22.5344146
  },
  {
    lng: -43.4046883,
    lat: -22.5344855
  },
  {
    lng: -43.4049409,
    lat: -22.5345764
  },
  {
    lng: -43.4053844,
    lat: -22.5346103
  },
  {
    lng: -43.4056382,
    lat: -22.534603
  },
  {
    lng: -43.406068,
    lat: -22.5345132
  },
  {
    lng: -43.4063066,
    lat: -22.5343739
  },
  {
    lng: -43.4065015,
    lat: -22.5341696
  },
  {
    lng: -43.4068194,
    lat: -22.533898
  },
  {
    lng: -43.4071485,
    lat: -22.5337638
  },
  {
    lng: -43.4074891,
    lat: -22.533617
  },
  {
    lng: -43.4075891,
    lat: -22.5334915
  },
  {
    lng: -43.407631,
    lat: -22.5333248
  },
  {
    lng: -43.4076767,
    lat: -22.5331881
  },
  {
    lng: -43.4078233,
    lat: -22.5330414
  },
  {
    lng: -43.4080563,
    lat: -22.5329387
  },
  {
    lng: -43.4083761,
    lat: -22.532853
  },
  {
    lng: -43.4085225,
    lat: -22.5327226
  },
  {
    lng: -43.4087851,
    lat: -22.5323213
  },
  {
    lng: -43.4095226,
    lat: -22.5319489
  },
  {
    lng: -43.4098837,
    lat: -22.5318017
  },
  {
    lng: -43.4104426,
    lat: -22.5323538
  },
  {
    lng: -43.410545,
    lat: -22.5325164
  },
  {
    lng: -43.4106561,
    lat: -22.5326898
  },
  {
    lng: -43.4110765,
    lat: -22.5329282
  },
  {
    lng: -43.411255,
    lat: -22.5330323
  },
  {
    lng: -43.4113788,
    lat: -22.5330725
  },
  {
    lng: -43.4114728,
    lat: -22.5337133
  },
  {
    lng: -43.4117128,
    lat: -22.5339791
  },
  {
    lng: -43.4120563,
    lat: -22.5340578
  },
  {
    lng: -43.4125676,
    lat: -22.5340818
  },
  {
    lng: -43.4126494,
    lat: -22.5342765
  },
  {
    lng: -43.4127778,
    lat: -22.5347424
  },
  {
    lng: -43.4129608,
    lat: -22.5349231
  },
  {
    lng: -43.413306,
    lat: -22.5348996
  },
  {
    lng: -43.413492,
    lat: -22.5348583
  },
  {
    lng: -43.4137699,
    lat: -22.5347898
  },
  {
    lng: -43.4141884,
    lat: -22.5345795
  },
  {
    lng: -43.4145371,
    lat: -22.5345829
  },
  {
    lng: -43.4149671,
    lat: -22.5346134
  },
  {
    lng: -43.4153287,
    lat: -22.5348805
  },
  {
    lng: -43.4157589,
    lat: -22.5349484
  },
  {
    lng: -43.4162751,
    lat: -22.5353791
  },
  {
    lng: -43.4163861,
    lat: -22.5355534
  },
  {
    lng: -43.4166779,
    lat: -22.53559
  },
  {
    lng: -43.4172578,
    lat: -22.5362544
  },
  {
    lng: -43.417497,
    lat: -22.5365955
  },
  {
    lng: -43.417734,
    lat: -22.5369117
  },
  {
    lng: -43.4182578,
    lat: -22.5373194
  },
  {
    lng: -43.4185821,
    lat: -22.5376073
  },
  {
    lng: -43.4188461,
    lat: -22.5377392
  },
  {
    lng: -43.4191434,
    lat: -22.5378324
  },
  {
    lng: -43.4193971,
    lat: -22.5379876
  },
  {
    lng: -43.4193983,
    lat: -22.5379884
  },
  {
    lng: -43.4195032,
    lat: -22.5380526
  },
  {
    lng: -43.4195088,
    lat: -22.53805
  },
  {
    lng: -43.4199136,
    lat: -22.5378613
  },
  {
    lng: -43.4201571,
    lat: -22.537826
  },
  {
    lng: -43.4204626,
    lat: -22.5376785
  },
  {
    lng: -43.4206652,
    lat: -22.5376805
  },
  {
    lng: -43.42091,
    lat: -22.5375323
  },
  {
    lng: -43.4212133,
    lat: -22.5373464
  },
  {
    lng: -43.4214922,
    lat: -22.5372824
  },
  {
    lng: -43.4217992,
    lat: -22.5372364
  },
  {
    lng: -43.4220218,
    lat: -22.5371796
  },
  {
    lng: -43.4222242,
    lat: -22.5370442
  },
  {
    lng: -43.4225444,
    lat: -22.5367626
  },
  {
    lng: -43.4229807,
    lat: -22.5365018
  },
  {
    lng: -43.4232253,
    lat: -22.5363667
  },
  {
    lng: -43.4232389,
    lat: -22.5361018
  },
  {
    lng: -43.4231687,
    lat: -22.5357673
  },
  {
    lng: -43.423204,
    lat: -22.5354535
  },
  {
    lng: -43.4232629,
    lat: -22.5349239
  },
  {
    lng: -43.4233422,
    lat: -22.5344534
  },
  {
    lng: -43.4236534,
    lat: -22.5340343
  },
  {
    lng: -43.423836,
    lat: -22.5337808
  },
  {
    lng: -43.4242479,
    lat: -22.5337217
  },
  {
    lng: -43.4245949,
    lat: -22.5337205
  },
  {
    lng: -43.425103,
    lat: -22.5338247
  },
  {
    lng: -43.4254297,
    lat: -22.5337752
  },
  {
    lng: -43.425915,
    lat: -22.5338553
  },
  {
    lng: -43.4263673,
    lat: -22.5339723
  },
  {
    lng: -43.4266662,
    lat: -22.5339656
  },
  {
    lng: -43.4274785,
    lat: -22.5341012
  },
  {
    lng: -43.4279449,
    lat: -22.5339781
  },
  {
    lng: -43.4288341,
    lat: -22.5338494
  },
  {
    lng: -43.4292196,
    lat: -22.5336241
  },
  {
    lng: -43.4299934,
    lat: -22.5332029
  },
  {
    lng: -43.4305747,
    lat: -22.5331987
  },
  {
    lng: -43.4311135,
    lat: -22.5332138
  },
  {
    lng: -43.4317201,
    lat: -22.5328466
  },
  {
    lng: -43.4323364,
    lat: -22.5325483
  },
  {
    lng: -43.4328051,
    lat: -22.5322191
  },
  {
    lng: -43.4331951,
    lat: -22.5320998
  },
  {
    lng: -43.4334448,
    lat: -22.5317246
  },
  {
    lng: -43.433545,
    lat: -22.5312739
  },
  {
    lng: -43.433869,
    lat: -22.5306586
  },
  {
    lng: -43.4344671,
    lat: -22.5301048
  },
  {
    lng: -43.4349131,
    lat: -22.5299128
  },
  {
    lng: -43.4351998,
    lat: -22.5297977
  },
  {
    lng: -43.4356,
    lat: -22.5299194
  },
  {
    lng: -43.4360076,
    lat: -22.5300535
  },
  {
    lng: -43.4361895,
    lat: -22.5301215
  },
  {
    lng: -43.4364411,
    lat: -22.5303007
  },
  {
    lng: -43.4368643,
    lat: -22.5302655
  },
  {
    lng: -43.4371721,
    lat: -22.5301549
  },
  {
    lng: -43.4373751,
    lat: -22.5299465
  },
  {
    lng: -43.4377029,
    lat: -22.52993
  },
  {
    lng: -43.4380823,
    lat: -22.5300319
  },
  {
    lng: -43.4384623,
    lat: -22.530065
  },
  {
    lng: -43.4389488,
    lat: -22.5300403
  },
  {
    lng: -43.4394556,
    lat: -22.5300747
  },
  {
    lng: -43.4398978,
    lat: -22.5302262
  },
  {
    lng: -43.4403454,
    lat: -22.5307225
  },
  {
    lng: -43.4406797,
    lat: -22.5306669
  },
  {
    lng: -43.4408671,
    lat: -22.5304909
  },
  {
    lng: -43.4411857,
    lat: -22.5303565
  },
  {
    lng: -43.4416531,
    lat: -22.5301451
  },
  {
    lng: -43.4419826,
    lat: -22.5299715
  },
  {
    lng: -43.4423121,
    lat: -22.5298078
  },
  {
    lng: -43.4426652,
    lat: -22.5294185
  },
  {
    lng: -43.4432297,
    lat: -22.5290313
  },
  {
    lng: -43.4436407,
    lat: -22.5286987
  },
  {
    lng: -43.4440726,
    lat: -22.5283129
  },
  {
    lng: -43.4445229,
    lat: -22.527738
  },
  {
    lng: -43.4448002,
    lat: -22.5275149
  },
  {
    lng: -43.4448769,
    lat: -22.5272702
  },
  {
    lng: -43.4449854,
    lat: -22.527016
  },
  {
    lng: -43.4452866,
    lat: -22.5265476
  },
  {
    lng: -43.4457892,
    lat: -22.5260125
  },
  {
    lng: -43.4462195,
    lat: -22.5262817
  },
  {
    lng: -43.4463324,
    lat: -22.5265872
  },
  {
    lng: -43.4464973,
    lat: -22.5269618
  },
  {
    lng: -43.4468973,
    lat: -22.5271031
  },
  {
    lng: -43.4473507,
    lat: -22.5271959
  },
  {
    lng: -43.4476423,
    lat: -22.5272099
  },
  {
    lng: -43.4479504,
    lat: -22.5274275
  },
  {
    lng: -43.4484964,
    lat: -22.5277469
  },
  {
    lng: -43.449083,
    lat: -22.528214
  },
  {
    lng: -43.4496086,
    lat: -22.5284645
  },
  {
    lng: -43.4503051,
    lat: -22.5285596
  },
  {
    lng: -43.4506636,
    lat: -22.5286318
  },
  {
    lng: -43.4510311,
    lat: -22.5288415
  },
  {
    lng: -43.4514727,
    lat: -22.5290519
  },
  {
    lng: -43.452072,
    lat: -22.5293227
  },
  {
    lng: -43.4526294,
    lat: -22.5295736
  },
  {
    lng: -43.4531444,
    lat: -22.529824
  },
  {
    lng: -43.4536387,
    lat: -22.5300447
  },
  {
    lng: -43.4541014,
    lat: -22.5302455
  },
  {
    lng: -43.454543,
    lat: -22.5304559
  },
  {
    lng: -43.4549296,
    lat: -22.5308523
  },
  {
    lng: -43.455477,
    lat: -22.5310441
  },
  {
    lng: -43.4561288,
    lat: -22.5313547
  },
  {
    lng: -43.4568238,
    lat: -22.5315774
  },
  {
    lng: -43.4572014,
    lat: -22.5318363
  },
  {
    lng: -43.4574427,
    lat: -22.5319957
  },
  {
    lng: -43.4576191,
    lat: -22.5322919
  },
  {
    lng: -43.4577846,
    lat: -22.5326175
  },
  {
    lng: -43.4579798,
    lat: -22.5331201
  },
  {
    lng: -43.4582375,
    lat: -22.5337116
  },
  {
    lng: -43.4585898,
    lat: -22.5343404
  },
  {
    lng: -43.458813,
    lat: -22.5346631
  },
  {
    lng: -43.4591357,
    lat: -22.5349958
  },
  {
    lng: -43.4595074,
    lat: -22.5352929
  },
  {
    lng: -43.4597803,
    lat: -22.5355213
  },
  {
    lng: -43.4601276,
    lat: -22.5358316
  },
  {
    lng: -43.460338,
    lat: -22.5360413
  },
  {
    lng: -43.4606482,
    lat: -22.5364056
  },
  {
    lng: -43.4611195,
    lat: -22.5367171
  },
  {
    lng: -43.4613399,
    lat: -22.5370009
  },
  {
    lng: -43.4615178,
    lat: -22.5371597
  },
  {
    lng: -43.4617464,
    lat: -22.5375153
  },
  {
    lng: -43.462038,
    lat: -22.537901
  },
  {
    lng: -43.4624713,
    lat: -22.5382201
  },
  {
    lng: -43.4629313,
    lat: -22.5383611
  },
  {
    lng: -43.4633124,
    lat: -22.5383059
  },
  {
    lng: -43.4635366,
    lat: -22.5381018
  },
  {
    lng: -43.4637815,
    lat: -22.5379373
  },
  {
    lng: -43.4640367,
    lat: -22.5378022
  },
  {
    lng: -43.4652537,
    lat: -22.5374681
  },
  {
    lng: -43.4654587,
    lat: -22.5370813
  },
  {
    lng: -43.4659582,
    lat: -22.5368049
  },
  {
    lng: -43.4662796,
    lat: -22.5367457
  },
  {
    lng: -43.4666878,
    lat: -22.5367857
  },
  {
    lng: -43.4670975,
    lat: -22.5366812
  },
  {
    lng: -43.467413,
    lat: -22.5363046
  },
  {
    lng: -43.4679027,
    lat: -22.536002
  },
  {
    lng: -43.4684278,
    lat: -22.5360251
  },
  {
    lng: -43.4688546,
    lat: -22.5361376
  },
  {
    lng: -43.4692435,
    lat: -22.5361594
  },
  {
    lng: -43.4694555,
    lat: -22.5363755
  },
  {
    lng: -43.4695875,
    lat: -22.536723
  },
  {
    lng: -43.4697207,
    lat: -22.5369954
  },
  {
    lng: -43.4698061,
    lat: -22.5370713
  },
  {
    lng: -43.470029,
    lat: -22.5372694
  },
  {
    lng: -43.4705126,
    lat: -22.537453
  },
  {
    lng: -43.4707828,
    lat: -22.5377284
  },
  {
    lng: -43.4711701,
    lat: -22.5378948
  },
  {
    lng: -43.4714379,
    lat: -22.538313
  },
  {
    lng: -43.4718834,
    lat: -22.538498
  },
  {
    lng: -43.4723088,
    lat: -22.538737
  },
  {
    lng: -43.4727521,
    lat: -22.5391208
  },
  {
    lng: -43.4730783,
    lat: -22.5395396
  },
  {
    lng: -43.4735548,
    lat: -22.5400193
  },
  {
    lng: -43.4738794,
    lat: -22.5404093
  },
  {
    lng: -43.4740772,
    lat: -22.5406861
  },
  {
    lng: -43.4742119,
    lat: -22.5409328
  },
  {
    lng: -43.4748329,
    lat: -22.5411645
  },
  {
    lng: -43.47556,
    lat: -22.5413678
  },
  {
    lng: -43.476129,
    lat: -22.5415204
  },
  {
    lng: -43.4765821,
    lat: -22.5416524
  },
  {
    lng: -43.4772236,
    lat: -22.5419431
  },
  {
    lng: -43.4776662,
    lat: -22.5420652
  },
  {
    lng: -43.4780042,
    lat: -22.5421718
  },
  {
    lng: -43.478348,
    lat: -22.5423665
  },
  {
    lng: -43.478518,
    lat: -22.5424561
  },
  {
    lng: -43.4788204,
    lat: -22.5428836
  },
  {
    lng: -43.47915,
    lat: -22.5429951
  },
  {
    lng: -43.479533,
    lat: -22.5428914
  },
  {
    lng: -43.4802551,
    lat: -22.5430812
  },
  {
    lng: -43.4805706,
    lat: -22.5432315
  },
  {
    lng: -43.4806958,
    lat: -22.5433799
  },
  {
    lng: -43.4808908,
    lat: -22.5440808
  },
  {
    lng: -43.4810561,
    lat: -22.5442571
  },
  {
    lng: -43.4815703,
    lat: -22.5445958
  },
  {
    lng: -43.4822033,
    lat: -22.5447
  },
  {
    lng: -43.4825521,
    lat: -22.5447033
  },
  {
    lng: -43.4829116,
    lat: -22.544687
  },
  {
    lng: -43.4830359,
    lat: -22.5449238
  },
  {
    lng: -43.4831908,
    lat: -22.5452591
  },
  {
    lng: -43.4834801,
    lat: -22.5456026
  },
  {
    lng: -43.4840423,
    lat: -22.5456795
  },
  {
    lng: -43.484485,
    lat: -22.5457916
  },
  {
    lng: -43.4845887,
    lat: -22.5459792
  },
  {
    lng: -43.4845759,
    lat: -22.5461754
  },
  {
    lng: -43.4845304,
    lat: -22.5464695
  },
  {
    lng: -43.4844948,
    lat: -22.5468324
  },
  {
    lng: -43.4846076,
    lat: -22.5471477
  },
  {
    lng: -43.484806,
    lat: -22.5473655
  },
  {
    lng: -43.4851322,
    lat: -22.5475521
  },
  {
    lng: -43.4853611,
    lat: -22.5478322
  },
  {
    lng: -43.4856121,
    lat: -22.54808
  },
  {
    lng: -43.4859377,
    lat: -22.5482696
  },
  {
    lng: -43.4863998,
    lat: -22.5485391
  },
  {
    lng: -43.4865751,
    lat: -22.5490659
  },
  {
    lng: -43.4868929,
    lat: -22.5484724
  },
  {
    lng: -43.4872826,
    lat: -22.5484219
  },
  {
    lng: -43.4875377,
    lat: -22.5482254
  },
  {
    lng: -43.4878681,
    lat: -22.5482647
  },
  {
    lng: -43.4882702,
    lat: -22.5488649
  },
  {
    lng: -43.4883795,
    lat: -22.5491124
  },
  {
    lng: -43.488781,
    lat: -22.5491947
  },
  {
    lng: -43.4890287,
    lat: -22.5491609
  },
  {
    lng: -43.4892393,
    lat: -22.5491944
  },
  {
    lng: -43.4893825,
    lat: -22.5493329
  },
  {
    lng: -43.4897798,
    lat: -22.5496202
  },
  {
    lng: -43.4900549,
    lat: -22.5493697
  },
  {
    lng: -43.490527,
    lat: -22.5489042
  },
  {
    lng: -43.4907626,
    lat: -22.5487076
  },
  {
    lng: -43.4909784,
    lat: -22.5485469
  },
  {
    lng: -43.4912514,
    lat: -22.5484953
  },
  {
    lng: -43.4914436,
    lat: -22.548714
  },
  {
    lng: -43.4915763,
    lat: -22.5490406
  },
  {
    lng: -43.4917685,
    lat: -22.5492593
  },
  {
    lng: -43.4920772,
    lat: -22.5494971
  },
  {
    lng: -43.4920719,
    lat: -22.5499851
  },
  {
    lng: -43.4920283,
    lat: -22.5504185
  },
  {
    lng: -43.4919041,
    lat: -22.5511041
  },
  {
    lng: -43.4919351,
    lat: -22.5517623
  },
  {
    lng: -43.4922657,
    lat: -22.5518486
  },
  {
    lng: -43.492635,
    lat: -22.5518882
  },
  {
    lng: -43.4930516,
    lat: -22.5521262
  },
  {
    lng: -43.493464,
    lat: -22.5521104
  },
  {
    lng: -43.4937417,
    lat: -22.5521262
  },
  {
    lng: -43.4940339,
    lat: -22.5521943
  },
  {
    lng: -43.4943477,
    lat: -22.5525016
  },
  {
    lng: -43.494449,
    lat: -22.5527107
  },
  {
    lng: -43.4946969,
    lat: -22.5528937
  },
  {
    lng: -43.4949133,
    lat: -22.5529782
  },
  {
    lng: -43.4951868,
    lat: -22.5530985
  },
  {
    lng: -43.4954507,
    lat: -22.5531305
  },
  {
    lng: -43.4958833,
    lat: -22.5532032
  },
  {
    lng: -43.496147,
    lat: -22.5532548
  },
  {
    lng: -43.4966234,
    lat: -22.5531905
  },
  {
    lng: -43.4968643,
    lat: -22.5533332
  },
  {
    lng: -43.4970276,
    lat: -22.5534298
  },
  {
    lng: -43.4972642,
    lat: -22.5535598
  },
  {
    lng: -43.497484,
    lat: -22.5537582
  },
  {
    lng: -43.4979359,
    lat: -22.553998
  },
  {
    lng: -43.4981028,
    lat: -22.5542058
  },
  {
    lng: -43.4983181,
    lat: -22.5543946
  },
  {
    lng: -43.4981088,
    lat: -22.554628
  },
  {
    lng: -43.4979679,
    lat: -22.5549507
  },
  {
    lng: -43.497795,
    lat: -22.5552927
  },
  {
    lng: -43.4977152,
    lat: -22.5558319
  },
  {
    lng: -43.4977323,
    lat: -22.5562052
  },
  {
    lng: -43.4974946,
    lat: -22.556684
  },
  {
    lng: -43.4977757,
    lat: -22.5570794
  },
  {
    lng: -43.4981227,
    lat: -22.557473
  },
  {
    lng: -43.4979144,
    lat: -22.5579349
  },
  {
    lng: -43.4977507,
    lat: -22.5584144
  },
  {
    lng: -43.4977475,
    lat: -22.5587089
  },
  {
    lng: -43.4977646,
    lat: -22.5589782
  },
  {
    lng: -43.4978019,
    lat: -22.5591863
  },
  {
    lng: -43.4978463,
    lat: -22.559348
  },
  {
    lng: -43.4979618,
    lat: -22.5594178
  },
  {
    lng: -43.4982464,
    lat: -22.5594892
  },
  {
    lng: -43.4985411,
    lat: -22.5596098
  },
  {
    lng: -43.498835,
    lat: -22.559799
  },
  {
    lng: -43.4991078,
    lat: -22.5599881
  },
  {
    lng: -43.4994017,
    lat: -22.5601774
  },
  {
    lng: -43.4997606,
    lat: -22.5605583
  },
  {
    lng: -43.499991,
    lat: -22.5608496
  },
  {
    lng: -43.500456,
    lat: -22.5610347
  },
  {
    lng: -43.5006294,
    lat: -22.561199
  },
  {
    lng: -43.5008228,
    lat: -22.5613093
  },
  {
    lng: -43.5010981,
    lat: -22.5615558
  },
  {
    lng: -43.5010869,
    lat: -22.5617248
  },
  {
    lng: -43.5011162,
    lat: -22.5619508
  },
  {
    lng: -43.50093,
    lat: -22.5621959
  },
  {
    lng: -43.500887,
    lat: -22.5625751
  },
  {
    lng: -43.5008112,
    lat: -22.5627825
  },
  {
    lng: -43.5006861,
    lat: -22.5631516
  },
  {
    lng: -43.5006239,
    lat: -22.5635125
  },
  {
    lng: -43.5007469,
    lat: -22.5638423
  },
  {
    lng: -43.5010543,
    lat: -22.5641478
  },
  {
    lng: -43.5012189,
    lat: -22.5641805
  },
  {
    lng: -43.5013884,
    lat: -22.5641526
  },
  {
    lng: -43.5017897,
    lat: -22.5641858
  },
  {
    lng: -43.502409,
    lat: -22.5639991
  },
  {
    lng: -43.5034581,
    lat: -22.5643584
  },
  {
    lng: -43.503826,
    lat: -22.5645484
  },
  {
    lng: -43.5042319,
    lat: -22.564809
  },
  {
    lng: -43.5053995,
    lat: -22.5651628
  },
  {
    lng: -43.5058465,
    lat: -22.5653114
  },
  {
    lng: -43.5060725,
    lat: -22.5653526
  },
  {
    lng: -43.5064405,
    lat: -22.5655187
  },
  {
    lng: -43.5069439,
    lat: -22.5657584
  },
  {
    lng: -43.507251,
    lat: -22.5661589
  },
  {
    lng: -43.5072679,
    lat: -22.566394
  },
  {
    lng: -43.5072263,
    lat: -22.5666466
  },
  {
    lng: -43.5071247,
    lat: -22.5670433
  },
  {
    lng: -43.5071997,
    lat: -22.5673877
  },
  {
    lng: -43.5074873,
    lat: -22.5677787
  },
  {
    lng: -43.5077874,
    lat: -22.5680885
  },
  {
    lng: -43.5082491,
    lat: -22.5685217
  },
  {
    lng: -43.5086223,
    lat: -22.5687656
  },
  {
    lng: -43.5088081,
    lat: -22.5691797
  },
  {
    lng: -43.5087293,
    lat: -22.5696208
  },
  {
    lng: -43.5086086,
    lat: -22.570032
  },
  {
    lng: -43.5087609,
    lat: -22.5706225
  },
  {
    lng: -43.509157,
    lat: -22.5711367
  },
  {
    lng: -43.5095347,
    lat: -22.5714053
  },
  {
    lng: -43.5100482,
    lat: -22.5718323
  },
  {
    lng: -43.5104499,
    lat: -22.5718262
  },
  {
    lng: -43.5107719,
    lat: -22.5717404
  },
  {
    lng: -43.5113748,
    lat: -22.5717821
  },
  {
    lng: -43.5118445,
    lat: -22.5715335
  },
  {
    lng: -43.5121187,
    lat: -22.5713733
  },
  {
    lng: -43.5129584,
    lat: -22.57111
  },
  {
    lng: -43.5135665,
    lat: -22.5706637
  },
  {
    lng: -43.5139972,
    lat: -22.5704327
  },
  {
    lng: -43.5147429,
    lat: -22.5698613
  },
  {
    lng: -43.5152307,
    lat: -22.5697392
  },
  {
    lng: -43.5156014,
    lat: -22.5696523
  },
  {
    lng: -43.5158914,
    lat: -22.5698357
  },
  {
    lng: -43.5162388,
    lat: -22.57011
  },
  {
    lng: -43.5165867,
    lat: -22.5703301
  },
  {
    lng: -43.5170728,
    lat: -22.5703708
  },
  {
    lng: -43.5174426,
    lat: -22.5703742
  },
  {
    lng: -43.5177554,
    lat: -22.5708039
  },
  {
    lng: -43.5179753,
    lat: -22.5710023
  },
  {
    lng: -43.5182384,
    lat: -22.5711127
  },
  {
    lng: -43.5191347,
    lat: -22.5707472
  },
  {
    lng: -43.5194418,
    lat: -22.5703887
  },
  {
    lng: -43.5197128,
    lat: -22.5701111
  },
  {
    lng: -43.5199951,
    lat: -22.5697884
  },
  {
    lng: -43.52013,
    lat: -22.5695684
  },
  {
    lng: -43.5202144,
    lat: -22.5692259
  },
  {
    lng: -43.5202619,
    lat: -22.5689237
  },
  {
    lng: -43.5204701,
    lat: -22.5686004
  },
  {
    lng: -43.5206301,
    lat: -22.5684754
  },
  {
    lng: -43.5209637,
    lat: -22.5683397
  },
  {
    lng: -43.5211652,
    lat: -22.5682826
  },
  {
    lng: -43.5212523,
    lat: -22.5680478
  },
  {
    lng: -43.5213392,
    lat: -22.5678326
  },
  {
    lng: -43.5214152,
    lat: -22.567637
  },
  {
    lng: -43.5215159,
    lat: -22.5675259
  },
  {
    lng: -43.5215865,
    lat: -22.5674422
  },
  {
    lng: -43.5217256,
    lat: -22.5672864
  },
  {
    lng: -43.5219071,
    lat: -22.5671113
  },
  {
    lng: -43.5221912,
    lat: -22.5672415
  },
  {
    lng: -43.5225698,
    lat: -22.5674218
  },
  {
    lng: -43.5229373,
    lat: -22.5676608
  },
  {
    lng: -43.5231345,
    lat: -22.5680062
  },
  {
    lng: -43.5232787,
    lat: -22.568361
  },
  {
    lng: -43.5235202,
    lat: -22.5685105
  },
  {
    lng: -43.5237936,
    lat: -22.5686504
  },
  {
    lng: -43.5239829,
    lat: -22.5687405
  },
  {
    lng: -43.5241949,
    lat: -22.5686934
  },
  {
    lng: -43.5244703,
    lat: -22.568637
  },
  {
    lng: -43.5246925,
    lat: -22.5686194
  },
  {
    lng: -43.5249779,
    lat: -22.568622
  },
  {
    lng: -43.5252416,
    lat: -22.5686736
  },
  {
    lng: -43.5254538,
    lat: -22.5688222
  },
  {
    lng: -43.5257352,
    lat: -22.5688772
  },
  {
    lng: -43.5260161,
    lat: -22.568967
  },
  {
    lng: -43.5263149,
    lat: -22.5691443
  },
  {
    lng: -43.5265096,
    lat: -22.5687586
  },
  {
    lng: -43.5267673,
    lat: -22.5683091
  },
  {
    lng: -43.5271995,
    lat: -22.5679335
  },
  {
    lng: -43.5277087,
    lat: -22.5676309
  },
  {
    lng: -43.5281771,
    lat: -22.5675086
  },
  {
    lng: -43.5283761,
    lat: -22.5670947
  },
  {
    lng: -43.5285537,
    lat: -22.5668614
  },
  {
    lng: -43.5288466,
    lat: -22.5667737
  },
  {
    lng: -43.5292371,
    lat: -22.5666507
  },
  {
    lng: -43.5295303,
    lat: -22.5665269
  },
  {
    lng: -43.5300037,
    lat: -22.5659348
  },
  {
    lng: -43.5307227,
    lat: -22.5660317
  },
  {
    lng: -43.5312222,
    lat: -22.5661223
  },
  {
    lng: -43.531532,
    lat: -22.5660844
  },
  {
    lng: -43.5318913,
    lat: -22.5660831
  },
  {
    lng: -43.5323254,
    lat: -22.5661276
  },
  {
    lng: -43.5325689,
    lat: -22.5660074
  },
  {
    lng: -43.5328149,
    lat: -22.5658525
  },
  {
    lng: -43.5331163,
    lat: -22.5657855
  },
  {
    lng: -43.5335872,
    lat: -22.565685
  },
  {
    lng: -43.5338575,
    lat: -22.5658977
  },
  {
    lng: -43.5342184,
    lat: -22.5664239
  },
  {
    lng: -43.5342739,
    lat: -22.567004
  },
  {
    lng: -43.5346391,
    lat: -22.5674411
  },
  {
    lng: -43.5349233,
    lat: -22.5681667
  },
  {
    lng: -43.5350056,
    lat: -22.5684034
  },
  {
    lng: -43.5352684,
    lat: -22.5686579
  },
  {
    lng: -43.5357916,
    lat: -22.5688796
  },
  {
    lng: -43.5360228,
    lat: -22.5690986
  },
  {
    lng: -43.5362927,
    lat: -22.569336
  },
  {
    lng: -43.5365667,
    lat: -22.5697649
  },
  {
    lng: -43.5369467,
    lat: -22.5696778
  },
  {
    lng: -43.5373815,
    lat: -22.5694374
  },
  {
    lng: -43.5380467,
    lat: -22.5688387
  },
  {
    lng: -43.5383861,
    lat: -22.5686262
  },
  {
    lng: -43.5386523,
    lat: -22.5683319
  },
  {
    lng: -43.5389582,
    lat: -22.5678459
  },
  {
    lng: -43.5393955,
    lat: -22.5673612
  },
  {
    lng: -43.5401533,
    lat: -22.5667921
  },
  {
    lng: -43.5404693,
    lat: -22.5663736
  },
  {
    lng: -43.5407244,
    lat: -22.5661771
  },
  {
    lng: -43.5410004,
    lat: -22.5658361
  },
  {
    lng: -43.5411397,
    lat: -22.5655482
  },
  {
    lng: -43.541494,
    lat: -22.5651719
  },
  {
    lng: -43.5416647,
    lat: -22.5655891
  },
  {
    lng: -43.5415226,
    lat: -22.5661482
  },
  {
    lng: -43.5416159,
    lat: -22.5665286
  },
  {
    lng: -43.5418439,
    lat: -22.5670548
  },
  {
    lng: -43.5420893,
    lat: -22.56778
  },
  {
    lng: -43.5422606,
    lat: -22.5681431
  },
  {
    lng: -43.5424446,
    lat: -22.5684847
  },
  {
    lng: -43.5427598,
    lat: -22.5687983
  },
  {
    lng: -43.5428916,
    lat: -22.5692152
  },
  {
    lng: -43.5432392,
    lat: -22.5694714
  },
  {
    lng: -43.5437223,
    lat: -22.5698011
  },
  {
    lng: -43.544342,
    lat: -22.5700959
  },
  {
    lng: -43.5446303,
    lat: -22.570442
  },
  {
    lng: -43.5449585,
    lat: -22.570698
  },
  {
    lng: -43.5453053,
    lat: -22.5710265
  },
  {
    lng: -43.5457098,
    lat: -22.5714278
  },
  {
    lng: -43.5460785,
    lat: -22.5715396
  },
  {
    lng: -43.5465434,
    lat: -22.5717426
  },
  {
    lng: -43.5471223,
    lat: -22.5722178
  },
  {
    lng: -43.5474152,
    lat: -22.57213
  },
  {
    lng: -43.5478618,
    lat: -22.5722245
  },
  {
    lng: -43.5484061,
    lat: -22.5722836
  },
  {
    lng: -43.5489299,
    lat: -22.572451
  },
  {
    lng: -43.549587,
    lat: -22.5728907
  },
  {
    lng: -43.5500666,
    lat: -22.5733123
  },
  {
    lng: -43.5505349,
    lat: -22.5734415
  },
  {
    lng: -43.5510609,
    lat: -22.573392
  },
  {
    lng: -43.5513175,
    lat: -22.5730509
  },
  {
    lng: -43.5514773,
    lat: -22.5726547
  },
  {
    lng: -43.5516782,
    lat: -22.5720601
  },
  {
    lng: -43.5518374,
    lat: -22.5717181
  },
  {
    lng: -43.5519486,
    lat: -22.5714049
  },
  {
    lng: -43.5520833,
    lat: -22.5711983
  },
  {
    lng: -43.5523132,
    lat: -22.5708909
  },
  {
    lng: -43.5527838,
    lat: -22.5705518
  },
  {
    lng: -43.5529172,
    lat: -22.5701017
  },
  {
    lng: -43.5531079,
    lat: -22.5698416
  },
  {
    lng: -43.5535049,
    lat: -22.5696182
  },
  {
    lng: -43.5539583,
    lat: -22.5693954
  },
  {
    lng: -43.5545373,
    lat: -22.5695262
  },
  {
    lng: -43.5548466,
    lat: -22.5694702
  },
  {
    lng: -43.5551797,
    lat: -22.5692925
  },
  {
    lng: -43.5557427,
    lat: -22.5686263
  },
  {
    lng: -43.5558656,
    lat: -22.5680309
  },
  {
    lng: -43.5561779,
    lat: -22.5674081
  },
  {
    lng: -43.5568007,
    lat: -22.5671519
  },
  {
    lng: -43.5571392,
    lat: -22.5672109
  },
  {
    lng: -43.5576854,
    lat: -22.5670893
  },
  {
    lng: -43.5580151,
    lat: -22.5672007
  },
  {
    lng: -43.558365,
    lat: -22.56724
  },
  {
    lng: -43.5588695,
    lat: -22.5671344
  },
  {
    lng: -43.5591875,
    lat: -22.5671558
  },
  {
    lng: -43.5595926,
    lat: -22.5670883
  },
  {
    lng: -43.5599813,
    lat: -22.567146
  },
  {
    lng: -43.5604068,
    lat: -22.5674029
  },
  {
    lng: -43.5607037,
    lat: -22.5677454
  },
  {
    lng: -43.5607733,
    lat: -22.5682871
  },
  {
    lng: -43.5608813,
    lat: -22.5685639
  },
  {
    lng: -43.5607651,
    lat: -22.5690725
  },
  {
    lng: -43.5607233,
    lat: -22.5694736
  },
  {
    lng: -43.5606438,
    lat: -22.5698918
  },
  {
    lng: -43.5606221,
    lat: -22.5701709
  },
  {
    lng: -43.5607504,
    lat: -22.5704862
  },
  {
    lng: -43.5608975,
    lat: -22.5708017
  },
  {
    lng: -43.5605928,
    lat: -22.571183
  },
  {
    lng: -43.5603263,
    lat: -22.5715122
  },
  {
    lng: -43.5599484,
    lat: -22.5717008
  },
  {
    lng: -43.5593748,
    lat: -22.571619
  },
  {
    lng: -43.5590787,
    lat: -22.5717474
  },
  {
    lng: -43.558957,
    lat: -22.5720713
  },
  {
    lng: -43.5588765,
    lat: -22.5723236
  },
  {
    lng: -43.5588353,
    lat: -22.5725401
  },
  {
    lng: -43.558794,
    lat: -22.5727748
  },
  {
    lng: -43.5589474,
    lat: -22.572993
  },
  {
    lng: -43.5591029,
    lat: -22.5733148
  },
  {
    lng: -43.5593532,
    lat: -22.5736241
  },
  {
    lng: -43.5596199,
    lat: -22.5738305
  },
  {
    lng: -43.5594593,
    lat: -22.574299
  },
  {
    lng: -43.5591844,
    lat: -22.5745315
  },
  {
    lng: -43.5589683,
    lat: -22.5747284
  },
  {
    lng: -43.5589265,
    lat: -22.5749991
  },
  {
    lng: -43.5589331,
    lat: -22.5753728
  },
  {
    lng: -43.5589341,
    lat: -22.5755715
  },
  {
    lng: -43.5591476,
    lat: -22.5759166
  },
  {
    lng: -43.5596898,
    lat: -22.5764701
  },
  {
    lng: -43.5593939,
    lat: -22.576847
  },
  {
    lng: -43.5594684,
    lat: -22.577315
  },
  {
    lng: -43.5595072,
    lat: -22.5775005
  },
  {
    lng: -43.5595717,
    lat: -22.5778578
  },
  {
    lng: -43.559861,
    lat: -22.5783978
  },
  {
    lng: -43.5600742,
    lat: -22.5787203
  },
  {
    lng: -43.560287,
    lat: -22.578957
  },
  {
    lng: -43.560517,
    lat: -22.5792791
  },
  {
    lng: -43.5608461,
    lat: -22.5794447
  },
  {
    lng: -43.5614092,
    lat: -22.5795763
  },
  {
    lng: -43.5616807,
    lat: -22.5796691
  },
  {
    lng: -43.5622028,
    lat: -22.5799991
  },
  {
    lng: -43.5624725,
    lat: -22.5802238
  },
  {
    lng: -43.5629239,
    lat: -22.5801929
  },
  {
    lng: -43.5634068,
    lat: -22.5802629
  },
  {
    lng: -43.5637157,
    lat: -22.5799556
  },
  {
    lng: -43.5638249,
    lat: -22.5796293
  },
  {
    lng: -43.5640093,
    lat: -22.5794367
  },
  {
    lng: -43.5641186,
    lat: -22.5791486
  },
  {
    lng: -43.5640409,
    lat: -22.5787098
  },
  {
    lng: -43.5640706,
    lat: -22.5781902
  },
  {
    lng: -43.563762,
    lat: -22.5779163
  },
  {
    lng: -43.5636365,
    lat: -22.5777082
  },
  {
    lng: -43.5637093,
    lat: -22.5774785
  },
  {
    lng: -43.5638287,
    lat: -22.5771216
  },
  {
    lng: -43.5640442,
    lat: -22.576979
  },
  {
    lng: -43.5642735,
    lat: -22.5767517
  },
  {
    lng: -43.5645341,
    lat: -22.576658
  },
  {
    lng: -43.564923,
    lat: -22.5766976
  },
  {
    lng: -43.56503,
    lat: -22.5767485
  },
  {
    lng: -43.5653491,
    lat: -22.5769003
  },
  {
    lng: -43.5657193,
    lat: -22.5770586
  },
  {
    lng: -43.5662262,
    lat: -22.5771155
  },
  {
    lng: -43.5665162,
    lat: -22.5769649
  },
  {
    lng: -43.5670401,
    lat: -22.5769926
  },
  {
    lng: -43.5672017,
    lat: -22.5770573
  },
  {
    lng: -43.5677493,
    lat: -22.5773104
  },
  {
    lng: -43.5679483,
    lat: -22.5774115
  },
  {
    lng: -43.5680231,
    lat: -22.5774709
  },
  {
    lng: -43.5687364,
    lat: -22.5779234
  },
  {
    lng: -43.5690756,
    lat: -22.5778391
  },
  {
    lng: -43.5693022,
    lat: -22.5777364
  },
  {
    lng: -43.5698013,
    lat: -22.5775945
  },
  {
    lng: -43.5701328,
    lat: -22.5773044
  },
  {
    lng: -43.5704848,
    lat: -22.5771449
  },
  {
    lng: -43.5707712,
    lat: -22.5774088
  },
  {
    lng: -43.5709605,
    lat: -22.5776615
  },
  {
    lng: -43.5712639,
    lat: -22.5778343
  },
  {
    lng: -43.5716281,
    lat: -22.57804
  },
  {
    lng: -43.5722175,
    lat: -22.5783854
  },
  {
    lng: -43.572496,
    lat: -22.5784446
  },
  {
    lng: -43.5727307,
    lat: -22.5785195
  },
  {
    lng: -43.5729401,
    lat: -22.5785052
  },
  {
    lng: -43.5732451,
    lat: -22.5785322
  },
  {
    lng: -43.5735727,
    lat: -22.5785578
  },
  {
    lng: -43.5738753,
    lat: -22.5786571
  },
  {
    lng: -43.5740142,
    lat: -22.5783689
  },
  {
    lng: -43.5741749,
    lat: -22.5780059
  },
  {
    lng: -43.5747016,
    lat: -22.5777969
  },
  {
    lng: -43.5754032,
    lat: -22.5776276
  },
  {
    lng: -43.5761734,
    lat: -22.5776253
  },
  {
    lng: -43.5765757,
    lat: -22.5776198
  },
  {
    lng: -43.5767826,
    lat: -22.5775305
  },
  {
    lng: -43.5771322,
    lat: -22.5774106
  },
  {
    lng: -43.5776463,
    lat: -22.5775062
  },
  {
    lng: -43.5780419,
    lat: -22.5776737
  },
  {
    lng: -43.5783471,
    lat: -22.57805
  },
  {
    lng: -43.5786134,
    lat: -22.5783849
  },
  {
    lng: -43.5787633,
    lat: -22.5786003
  },
  {
    lng: -43.5788836,
    lat: -22.5788292
  },
  {
    lng: -43.5790924,
    lat: -22.579036
  },
  {
    lng: -43.5795329,
    lat: -22.5791265
  },
  {
    lng: -43.5799111,
    lat: -22.5790888
  },
  {
    lng: -43.5802207,
    lat: -22.5790323
  },
  {
    lng: -43.5805793,
    lat: -22.5789763
  },
  {
    lng: -43.5805412,
    lat: -22.5793449
  },
  {
    lng: -43.5805561,
    lat: -22.5798097
  },
  {
    lng: -43.5805864,
    lat: -22.5802063
  },
  {
    lng: -43.5806613,
    lat: -22.5805486
  },
  {
    lng: -43.5807876,
    lat: -22.5806728
  },
  {
    lng: -43.5810171,
    lat: -22.5807796
  },
  {
    lng: -43.5811835,
    lat: -22.5808129
  },
  {
    lng: -43.5813648,
    lat: -22.5808373
  },
  {
    lng: -43.5816939,
    lat: -22.5807992
  },
  {
    lng: -43.5822684,
    lat: -22.5807359
  },
  {
    lng: -43.5825331,
    lat: -22.5807611
  },
  {
    lng: -43.5827389,
    lat: -22.5807811
  },
  {
    lng: -43.5830724,
    lat: -22.5807886
  },
  {
    lng: -43.5832742,
    lat: -22.580886
  },
  {
    lng: -43.5835762,
    lat: -22.5810983
  },
  {
    lng: -43.5839021,
    lat: -22.5813654
  },
  {
    lng: -43.5843213,
    lat: -22.5817804
  },
  {
    lng: -43.5848086,
    lat: -22.5822272
  },
  {
    lng: -43.5852966,
    lat: -22.5826805
  },
  {
    lng: -43.5853437,
    lat: -22.5827616
  },
  {
    lng: -43.5855521,
    lat: -22.5831201
  },
  {
    lng: -43.5853087,
    lat: -22.5835662
  },
  {
    lng: -43.5851107,
    lat: -22.5838898
  },
  {
    lng: -43.5846751,
    lat: -22.5846089
  },
  {
    lng: -43.5842443,
    lat: -22.5851124
  },
  {
    lng: -43.5837854,
    lat: -22.5858764
  },
  {
    lng: -43.5836459,
    lat: -22.5862694
  },
  {
    lng: -43.5835873,
    lat: -22.5867244
  },
  {
    lng: -43.5837252,
    lat: -22.5871538
  },
  {
    lng: -43.583807,
    lat: -22.5873094
  },
  {
    lng: -43.583893,
    lat: -22.5875334
  },
  {
    lng: -43.5841454,
    lat: -22.5877999
  },
  {
    lng: -43.5845016,
    lat: -22.5882585
  },
  {
    lng: -43.5848306,
    lat: -22.5884422
  },
  {
    lng: -43.5851404,
    lat: -22.5886076
  },
  {
    lng: -43.5854486,
    lat: -22.5889176
  },
  {
    lng: -43.5856575,
    lat: -22.5891297
  },
  {
    lng: -43.5861028,
    lat: -22.5892384
  },
  {
    lng: -43.586562,
    lat: -22.5894292
  },
  {
    lng: -43.5867531,
    lat: -22.5897093
  },
  {
    lng: -43.5869754,
    lat: -22.5900499
  },
  {
    lng: -43.5871476,
    lat: -22.5902882
  },
  {
    lng: -43.5873301,
    lat: -22.5905001
  },
  {
    lng: -43.5875567,
    lat: -22.5907748
  },
  {
    lng: -43.5877744,
    lat: -22.5910819
  },
  {
    lng: -43.5879553,
    lat: -22.5912532
  },
  {
    lng: -43.5882072,
    lat: -22.5914502
  },
  {
    lng: -43.588418,
    lat: -22.5916911
  },
  {
    lng: -43.5886031,
    lat: -22.5920823
  },
  {
    lng: -43.5888131,
    lat: -22.5926581
  },
  {
    lng: -43.588998,
    lat: -22.5929216
  },
  {
    lng: -43.5892606,
    lat: -22.5931723
  },
  {
    lng: -43.5899228,
    lat: -22.5937651
  },
  {
    lng: -43.5903881,
    lat: -22.5940796
  },
  {
    lng: -43.5909983,
    lat: -22.5944431
  },
  {
    lng: -43.5912879,
    lat: -22.5946806
  },
  {
    lng: -43.5921425,
    lat: -22.5948688
  },
  {
    lng: -43.5926509,
    lat: -22.595351
  },
  {
    lng: -43.5930705,
    lat: -22.5954915
  },
  {
    lng: -43.5934048,
    lat: -22.595561
  },
  {
    lng: -43.5937901,
    lat: -22.5955521
  },
  {
    lng: -43.5940802,
    lat: -22.5956891
  },
  {
    lng: -43.5944744,
    lat: -22.5957449
  },
  {
    lng: -43.5946998,
    lat: -22.5957643
  },
  {
    lng: -43.5948682,
    lat: -22.5958356
  },
  {
    lng: -43.5950854,
    lat: -22.5958912
  },
  {
    lng: -43.5952652,
    lat: -22.5959807
  },
  {
    lng: -43.5956121,
    lat: -22.5963272
  },
  {
    lng: -43.5960746,
    lat: -22.5967831
  },
  {
    lng: -43.5963629,
    lat: -22.5971471
  },
  {
    lng: -43.5968298,
    lat: -22.5974667
  },
  {
    lng: -43.5970941,
    lat: -22.5976476
  },
  {
    lng: -43.5977899,
    lat: -22.5977403
  },
  {
    lng: -43.5981192,
    lat: -22.5978015
  },
  {
    lng: -43.5984939,
    lat: -22.597927
  },
  {
    lng: -43.5989235,
    lat: -22.5981925
  },
  {
    lng: -43.5992417,
    lat: -22.5983349
  },
  {
    lng: -43.5994084,
    lat: -22.5985808
  },
  {
    lng: -43.6000059,
    lat: -22.598661
  },
  {
    lng: -43.6003658,
    lat: -22.5987182
  },
  {
    lng: -43.6010634,
    lat: -22.5990674
  },
  {
    lng: -43.6012876,
    lat: -22.5992048
  },
  {
    lng: -43.6017312,
    lat: -22.5993865
  },
  {
    lng: -43.6021787,
    lat: -22.5995295
  },
  {
    lng: -43.6023892,
    lat: -22.5998928
  },
  {
    lng: -43.6023899,
    lat: -22.600569
  },
  {
    lng: -43.6024937,
    lat: -22.6011435
  },
  {
    lng: -43.6025476,
    lat: -22.6015571
  },
  {
    lng: -43.6022267,
    lat: -22.6024454
  },
  {
    lng: -43.6025719,
    lat: -22.6030771
  },
  {
    lng: -43.6027997,
    lat: -22.6035837
  },
  {
    lng: -43.6029652,
    lat: -22.6037783
  },
  {
    lng: -43.6030979,
    lat: -22.6040737
  },
  {
    lng: -43.6031819,
    lat: -22.6043902
  },
  {
    lng: -43.6032918,
    lat: -22.6047394
  },
  {
    lng: -43.6034468,
    lat: -22.6049432
  },
  {
    lng: -43.6039406,
    lat: -22.6051165
  },
  {
    lng: -43.6041774,
    lat: -22.6051521
  },
  {
    lng: -43.6045442,
    lat: -22.6050986
  },
  {
    lng: -43.604911,
    lat: -22.6050613
  },
  {
    lng: -43.6051676,
    lat: -22.6050547
  },
  {
    lng: -43.6054536,
    lat: -22.6051564
  },
  {
    lng: -43.6055661,
    lat: -22.6052703
  },
  {
    lng: -43.6060479,
    lat: -22.6056728
  },
  {
    lng: -43.6067385,
    lat: -22.6061675
  },
  {
    lng: -43.6073268,
    lat: -22.6067801
  },
  {
    lng: -43.608273,
    lat: -22.6071559
  },
  {
    lng: -43.6088755,
    lat: -22.6072515
  },
  {
    lng: -43.6094396,
    lat: -22.6072925
  },
  {
    lng: -43.6099417,
    lat: -22.6073124
  },
  {
    lng: -43.6105071,
    lat: -22.6074302
  },
  {
    lng: -43.6114805,
    lat: -22.6075875
  },
  {
    lng: -43.611818,
    lat: -22.6076777
  },
  {
    lng: -43.6121729,
    lat: -22.6079077
  },
  {
    lng: -43.6128348,
    lat: -22.6084606
  },
  {
    lng: -43.6134609,
    lat: -22.6088265
  },
  {
    lng: -43.6138147,
    lat: -22.6091612
  },
  {
    lng: -43.6142194,
    lat: -22.6094577
  },
  {
    lng: -43.6147186,
    lat: -22.6098322
  },
  {
    lng: -43.6151806,
    lat: -22.6101522
  },
  {
    lng: -43.6156793,
    lat: -22.6105177
  },
  {
    lng: -43.6159422,
    lat: -22.6108045
  },
  {
    lng: -43.6165582,
    lat: -22.6111747
  },
  {
    lng: -43.6168757,
    lat: -22.6113869
  },
  {
    lng: -43.6171157,
    lat: -22.6118253
  },
  {
    lng: -43.617332,
    lat: -22.6123291
  },
  {
    lng: -43.6174825,
    lat: -22.6125471
  },
  {
    lng: -43.6175835,
    lat: -22.6127874
  },
  {
    lng: -43.6177611,
    lat: -22.6132811
  },
  {
    lng: -43.6179,
    lat: -22.6135668
  },
  {
    lng: -43.6180641,
    lat: -22.6138888
  },
  {
    lng: -43.61834,
    lat: -22.6142208
  },
  {
    lng: -43.6188001,
    lat: -22.6144279
  },
  {
    lng: -43.6192963,
    lat: -22.6144992
  },
  {
    lng: -43.6198785,
    lat: -22.614685
  },
  {
    lng: -43.6202265,
    lat: -22.6149229
  },
  {
    lng: -43.6203794,
    lat: -22.6152135
  },
  {
    lng: -43.6204718,
    lat: -22.6155439
  },
  {
    lng: -43.620822,
    lat: -22.6159217
  },
  {
    lng: -43.6211344,
    lat: -22.6161863
  },
  {
    lng: -43.6212966,
    lat: -22.6163231
  },
  {
    lng: -43.6217284,
    lat: -22.6165988
  },
  {
    lng: -43.6218622,
    lat: -22.616853
  },
  {
    lng: -43.6219368,
    lat: -22.617179
  },
  {
    lng: -43.6219035,
    lat: -22.6176424
  },
  {
    lng: -43.6220812,
    lat: -22.6181226
  },
  {
    lng: -43.6223732,
    lat: -22.6183214
  },
  {
    lng: -43.622702,
    lat: -22.6185412
  },
  {
    lng: -43.6230295,
    lat: -22.6188874
  },
  {
    lng: -43.6233774,
    lat: -22.6191434
  },
  {
    lng: -43.6238989,
    lat: -22.6195636
  },
  {
    lng: -43.6241496,
    lat: -22.6198008
  },
  {
    lng: -43.6244211,
    lat: -22.6201791
  },
  {
    lng: -43.6248018,
    lat: -22.6207824
  },
  {
    lng: -43.6256132,
    lat: -22.621422
  },
  {
    lng: -43.6259213,
    lat: -22.6217681
  },
  {
    lng: -43.6261904,
    lat: -22.6221138
  },
  {
    lng: -43.6266215,
    lat: -22.6226091
  },
  {
    lng: -43.6269036,
    lat: -22.6228429
  },
  {
    lng: -43.6275452,
    lat: -22.6236619
  },
  {
    lng: -43.6278596,
    lat: -22.6241883
  },
  {
    lng: -43.6279377,
    lat: -22.624587
  },
  {
    lng: -43.6285054,
    lat: -22.6253165
  },
  {
    lng: -43.6288252,
    lat: -22.6257241
  },
  {
    lng: -43.6242932,
    lat: -22.6379908
  },
  {
    lng: -43.6230191,
    lat: -22.6414393
  },
  {
    lng: -43.6227298,
    lat: -22.6422221
  },
  {
    lng: -43.6222164,
    lat: -22.6415237
  },
  {
    lng: -43.6219801,
    lat: -22.6413018
  },
  {
    lng: -43.6217662,
    lat: -22.6410709
  },
  {
    lng: -43.621624,
    lat: -22.6408418
  },
  {
    lng: -43.6213582,
    lat: -22.6405049
  },
  {
    lng: -43.6211611,
    lat: -22.6403938
  },
  {
    lng: -43.6210666,
    lat: -22.6401947
  },
  {
    lng: -43.6193946,
    lat: -22.6380624
  },
  {
    lng: -43.6187733,
    lat: -22.6372701
  },
  {
    lng: -43.6184521,
    lat: -22.6369175
  },
  {
    lng: -43.6180646,
    lat: -22.6366452
  },
  {
    lng: -43.6172898,
    lat: -22.6363635
  },
  {
    lng: -43.6164604,
    lat: -22.636166
  },
  {
    lng: -43.6148932,
    lat: -22.6357074
  },
  {
    lng: -43.6146534,
    lat: -22.635697
  },
  {
    lng: -43.6144806,
    lat: -22.6356625
  },
  {
    lng: -43.614298,
    lat: -22.6355785
  },
  {
    lng: -43.6142387,
    lat: -22.6354946
  },
  {
    lng: -43.6138653,
    lat: -22.635357
  },
  {
    lng: -43.6132365,
    lat: -22.6352625
  },
  {
    lng: -43.6129569,
    lat: -22.6351963
  },
  {
    lng: -43.6116791,
    lat: -22.6347669
  },
  {
    lng: -43.6113265,
    lat: -22.6347215
  },
  {
    lng: -43.6109424,
    lat: -22.634498
  },
  {
    lng: -43.6106038,
    lat: -22.6343933
  },
  {
    lng: -43.6103734,
    lat: -22.6340442
  },
  {
    lng: -43.6101988,
    lat: -22.6338452
  },
  {
    lng: -43.6099786,
    lat: -22.6337773
  },
  {
    lng: -43.6095922,
    lat: -22.6335046
  },
  {
    lng: -43.6095126,
    lat: -22.6333227
  },
  {
    lng: -43.6093592,
    lat: -22.6332886
  },
  {
    lng: -43.608523,
    lat: -22.6324522
  },
  {
    lng: -43.6079609,
    lat: -22.6320926
  },
  {
    lng: -43.6079504,
    lat: -22.6319494
  },
  {
    lng: -43.6077165,
    lat: -22.6319005
  },
  {
    lng: -43.6076467,
    lat: -22.6318482
  },
  {
    lng: -43.6076118,
    lat: -22.6316492
  },
  {
    lng: -43.6073988,
    lat: -22.6316876
  },
  {
    lng: -43.6071789,
    lat: -22.6316282
  },
  {
    lng: -43.6071614,
    lat: -22.631307
  },
  {
    lng: -43.6070732,
    lat: -22.6312312
  },
  {
    lng: -43.6069275,
    lat: -22.6311953
  },
  {
    lng: -43.6068311,
    lat: -22.6311123
  },
  {
    lng: -43.6040612,
    lat: -22.628727
  },
  {
    lng: -43.6034432,
    lat: -22.6282976
  },
  {
    lng: -43.6012013,
    lat: -22.6263413
  },
  {
    lng: -43.6007229,
    lat: -22.6259707
  },
  {
    lng: -43.6003859,
    lat: -22.6255798
  },
  {
    lng: -43.5999031,
    lat: -22.6253439
  },
  {
    lng: -43.5997389,
    lat: -22.6250878
  },
  {
    lng: -43.599506,
    lat: -22.6249503
  },
  {
    lng: -43.598517,
    lat: -22.6242058
  },
  {
    lng: -43.5982698,
    lat: -22.6239826
  },
  {
    lng: -43.598135,
    lat: -22.6237063
  },
  {
    lng: -43.5979598,
    lat: -22.6237063
  },
  {
    lng: -43.5977779,
    lat: -22.6236591
  },
  {
    lng: -43.5977037,
    lat: -22.6235783
  },
  {
    lng: -43.5977172,
    lat: -22.6234907
  },
  {
    lng: -43.5975884,
    lat: -22.6233714
  },
  {
    lng: -43.5958951,
    lat: -22.6220063
  },
  {
    lng: -43.5958148,
    lat: -22.6218422
  },
  {
    lng: -43.595546,
    lat: -22.6217514
  },
  {
    lng: -43.5953869,
    lat: -22.62149
  },
  {
    lng: -43.5948826,
    lat: -22.6212662
  },
  {
    lng: -43.594836,
    lat: -22.6210968
  },
  {
    lng: -43.594516,
    lat: -22.6209504
  },
  {
    lng: -43.5944359,
    lat: -22.6208647
  },
  {
    lng: -43.5939749,
    lat: -22.6203718
  },
  {
    lng: -43.5937536,
    lat: -22.620331
  },
  {
    lng: -43.5935937,
    lat: -22.6201574
  },
  {
    lng: -43.5934439,
    lat: -22.6200315
  },
  {
    lng: -43.5932193,
    lat: -22.6200008
  },
  {
    lng: -43.593175,
    lat: -22.6199123
  },
  {
    lng: -43.5929742,
    lat: -22.6198749
  },
  {
    lng: -43.5927291,
    lat: -22.6198817
  },
  {
    lng: -43.5926611,
    lat: -22.619766
  },
  {
    lng: -43.5925484,
    lat: -22.6197476
  },
  {
    lng: -43.5924603,
    lat: -22.6198238
  },
  {
    lng: -43.5923616,
    lat: -22.6199259
  },
  {
    lng: -43.5922526,
    lat: -22.6199328
  },
  {
    lng: -43.592124,
    lat: -22.6198094
  },
  {
    lng: -43.592028,
    lat: -22.6196979
  },
  {
    lng: -43.5918711,
    lat: -22.6196583
  },
  {
    lng: -43.591838,
    lat: -22.6196797
  },
  {
    lng: -43.5917625,
    lat: -22.6197285
  },
  {
    lng: -43.5916195,
    lat: -22.6197329
  },
  {
    lng: -43.591499,
    lat: -22.6196671
  },
  {
    lng: -43.5913892,
    lat: -22.6196601
  },
  {
    lng: -43.5912237,
    lat: -22.6197089
  },
  {
    lng: -43.591086,
    lat: -22.6197629
  },
  {
    lng: -43.5909311,
    lat: -22.619761
  },
  {
    lng: -43.5908002,
    lat: -22.6197594
  },
  {
    lng: -43.5906712,
    lat: -22.619735
  },
  {
    lng: -43.5904825,
    lat: -22.6197398
  },
  {
    lng: -43.590194,
    lat: -22.6197017
  },
  {
    lng: -43.5900719,
    lat: -22.619754
  },
  {
    lng: -43.5899086,
    lat: -22.6197509
  },
  {
    lng: -43.5897849,
    lat: -22.6197208
  },
  {
    lng: -43.5896755,
    lat: -22.6197239
  },
  {
    lng: -43.5894551,
    lat: -22.6196557
  },
  {
    lng: -43.5893093,
    lat: -22.6197097
  },
  {
    lng: -43.589138,
    lat: -22.6197334
  },
  {
    lng: -43.5888431,
    lat: -22.6196748
  },
  {
    lng: -43.5888173,
    lat: -22.6196775
  },
  {
    lng: -43.5885434,
    lat: -22.6197065
  },
  {
    lng: -43.5879917,
    lat: -22.6196018
  },
  {
    lng: -43.587627,
    lat: -22.619494
  },
  {
    lng: -43.5873131,
    lat: -22.6194465
  },
  {
    lng: -43.5869475,
    lat: -22.6193229
  },
  {
    lng: -43.5868599,
    lat: -22.6191981
  },
  {
    lng: -43.5855897,
    lat: -22.6186826
  },
  {
    lng: -43.5855765,
    lat: -22.6186805
  },
  {
    lng: -43.5855632,
    lat: -22.6186784
  },
  {
    lng: -43.5855019,
    lat: -22.6186687
  },
  {
    lng: -43.585433,
    lat: -22.6186578
  },
  {
    lng: -43.5853407,
    lat: -22.6186097
  },
  {
    lng: -43.5852433,
    lat: -22.6185506
  },
  {
    lng: -43.5850453,
    lat: -22.6185423
  },
  {
    lng: -43.5847773,
    lat: -22.6183444
  },
  {
    lng: -43.5843814,
    lat: -22.6182495
  },
  {
    lng: -43.5842948,
    lat: -22.6181753
  },
  {
    lng: -43.5842576,
    lat: -22.6181498
  },
  {
    lng: -43.5840721,
    lat: -22.6180227
  },
  {
    lng: -43.5838617,
    lat: -22.6179691
  },
  {
    lng: -43.5836202,
    lat: -22.6179394
  },
  {
    lng: -43.5831083,
    lat: -22.6177086
  },
  {
    lng: -43.5825005,
    lat: -22.6175374
  },
  {
    lng: -43.5819721,
    lat: -22.6173118
  },
  {
    lng: -43.5817502,
    lat: -22.6172354
  },
  {
    lng: -43.5815955,
    lat: -22.6171822
  },
  {
    lng: -43.581579,
    lat: -22.6171765
  },
  {
    lng: -43.5815219,
    lat: -22.6171446
  },
  {
    lng: -43.5810965,
    lat: -22.6169068
  },
  {
    lng: -43.5809218,
    lat: -22.6168092
  },
  {
    lng: -43.5801226,
    lat: -22.6165194
  },
  {
    lng: -43.5798479,
    lat: -22.6164198
  },
  {
    lng: -43.5795107,
    lat: -22.6162975
  },
  {
    lng: -43.5794865,
    lat: -22.6162887
  },
  {
    lng: -43.579313,
    lat: -22.6162017
  },
  {
    lng: -43.5790534,
    lat: -22.6160714
  },
  {
    lng: -43.5788399,
    lat: -22.6160945
  },
  {
    lng: -43.5786339,
    lat: -22.616044
  },
  {
    lng: -43.5784007,
    lat: -22.6159118
  },
  {
    lng: -43.5782141,
    lat: -22.6157019
  },
  {
    lng: -43.5779443,
    lat: -22.6155084
  },
  {
    lng: -43.5777904,
    lat: -22.6151577
  },
  {
    lng: -43.5776582,
    lat: -22.6149983
  },
  {
    lng: -43.5773317,
    lat: -22.6144308
  },
  {
    lng: -43.5772306,
    lat: -22.6143569
  },
  {
    lng: -43.5770984,
    lat: -22.6143375
  },
  {
    lng: -43.5764687,
    lat: -22.6139876
  },
  {
    lng: -43.575816,
    lat: -22.6135034
  },
  {
    lng: -43.5757172,
    lat: -22.6135169
  },
  {
    lng: -43.5755912,
    lat: -22.6133765
  },
  {
    lng: -43.575395,
    lat: -22.613358
  },
  {
    lng: -43.5751287,
    lat: -22.6131518
  },
  {
    lng: -43.5749526,
    lat: -22.6129843
  },
  {
    lng: -43.5747249,
    lat: -22.612937
  },
  {
    lng: -43.574596,
    lat: -22.6127695
  },
  {
    lng: -43.5743812,
    lat: -22.6127566
  },
  {
    lng: -43.5742394,
    lat: -22.6126449
  },
  {
    lng: -43.5740934,
    lat: -22.6125332
  },
  {
    lng: -43.5740504,
    lat: -22.6123699
  },
  {
    lng: -43.5740246,
    lat: -22.6122926
  },
  {
    lng: -43.5739473,
    lat: -22.6122625
  },
  {
    lng: -43.5737884,
    lat: -22.6122282
  },
  {
    lng: -43.5736681,
    lat: -22.6120778
  },
  {
    lng: -43.5734876,
    lat: -22.6120563
  },
  {
    lng: -43.5733115,
    lat: -22.611906
  },
  {
    lng: -43.5730967,
    lat: -22.6118689
  },
  {
    lng: -43.5724781,
    lat: -22.6113991
  },
  {
    lng: -43.5722032,
    lat: -22.6113217
  },
  {
    lng: -43.5719798,
    lat: -22.6111069
  },
  {
    lng: -43.5717339,
    lat: -22.6110837
  },
  {
    lng: -43.5714814,
    lat: -22.6109437
  },
  {
    lng: -43.5713024,
    lat: -22.6107438
  },
  {
    lng: -43.5711034,
    lat: -22.6107117
  },
  {
    lng: -43.5708199,
    lat: -22.6104539
  },
  {
    lng: -43.5702356,
    lat: -22.6101103
  },
  {
    lng: -43.5699349,
    lat: -22.6098869
  },
  {
    lng: -43.5697717,
    lat: -22.6097924
  },
  {
    lng: -43.5697803,
    lat: -22.6095432
  },
  {
    lng: -43.5697115,
    lat: -22.60938
  },
  {
    lng: -43.5696915,
    lat: -22.6093777
  },
  {
    lng: -43.5694795,
    lat: -22.6093542
  },
  {
    lng: -43.5692257,
    lat: -22.609392
  },
  {
    lng: -43.5690757,
    lat: -22.6094143
  },
  {
    lng: -43.5690454,
    lat: -22.6097189
  },
  {
    lng: -43.5690767,
    lat: -22.6101691
  },
  {
    lng: -43.5691067,
    lat: -22.6128809
  },
  {
    lng: -43.5691108,
    lat: -22.6132548
  },
  {
    lng: -43.5691112,
    lat: -22.6132843
  },
  {
    lng: -43.5691115,
    lat: -22.6133139
  },
  {
    lng: -43.5691273,
    lat: -22.6147413
  },
  {
    lng: -43.5691382,
    lat: -22.6162151
  },
  {
    lng: -43.5691498,
    lat: -22.6187114
  },
  {
    lng: -43.5691348,
    lat: -22.6195209
  },
  {
    lng: -43.569133,
    lat: -22.6196164
  },
  {
    lng: -43.569119,
    lat: -22.6197541
  },
  {
    lng: -43.5690559,
    lat: -22.6203723
  },
  {
    lng: -43.5690454,
    lat: -22.6204762
  },
  {
    lng: -43.56856,
    lat: -22.6216343
  },
  {
    lng: -43.5684653,
    lat: -22.6218604
  },
  {
    lng: -43.5680236,
    lat: -22.6229144
  },
  {
    lng: -43.5679395,
    lat: -22.623222
  },
  {
    lng: -43.5679497,
    lat: -22.6234661
  },
  {
    lng: -43.5680412,
    lat: -22.6237102
  },
  {
    lng: -43.5682752,
    lat: -22.6239441
  },
  {
    lng: -43.5688346,
    lat: -22.6241577
  },
  {
    lng: -43.5696401,
    lat: -22.6243041
  },
  {
    lng: -43.5701161,
    lat: -22.6243814
  },
  {
    lng: -43.5705732,
    lat: -22.6245861
  },
  {
    lng: -43.5713127,
    lat: -22.6250107
  },
  {
    lng: -43.5720689,
    lat: -22.6255918
  },
  {
    lng: -43.5723741,
    lat: -22.626019
  },
  {
    lng: -43.5724656,
    lat: -22.6264156
  },
  {
    lng: -43.5724656,
    lat: -22.6269038
  },
  {
    lng: -43.5723435,
    lat: -22.6276056
  },
  {
    lng: -43.5723232,
    lat: -22.6278497
  },
  {
    lng: -43.5725164,
    lat: -22.6292838
  },
  {
    lng: -43.5725681,
    lat: -22.6295427
  },
  {
    lng: -43.5726613,
    lat: -22.6297969
  },
  {
    lng: -43.5732035,
    lat: -22.6305
  },
  {
    lng: -43.5733559,
    lat: -22.6307202
  },
  {
    lng: -43.573483,
    lat: -22.6309659
  },
  {
    lng: -43.5735254,
    lat: -22.6311946
  },
  {
    lng: -43.5734563,
    lat: -22.6314947
  },
  {
    lng: -43.5732797,
    lat: -22.6317537
  },
  {
    lng: -43.5729493,
    lat: -22.6322366
  },
  {
    lng: -43.5728392,
    lat: -22.6324399
  },
  {
    lng: -43.5727968,
    lat: -22.6326093
  },
  {
    lng: -43.572763,
    lat: -22.6331684
  },
  {
    lng: -43.5726528,
    lat: -22.6334649
  },
  {
    lng: -43.572526,
    lat: -22.633578
  },
  {
    lng: -43.5723992,
    lat: -22.6336911
  },
  {
    lng: -43.5722398,
    lat: -22.6338737
  },
  {
    lng: -43.57218,
    lat: -22.6339422
  },
  {
    lng: -43.572153,
    lat: -22.6339732
  },
  {
    lng: -43.5717803,
    lat: -22.6341934
  },
  {
    lng: -43.5713737,
    lat: -22.6343544
  },
  {
    lng: -43.5708886,
    lat: -22.6342966
  },
  {
    lng: -43.5699081,
    lat: -22.6342051
  },
  {
    lng: -43.568341,
    lat: -22.6341765
  },
  {
    lng: -43.5678901,
    lat: -22.6342254
  },
  {
    lng: -43.5646282,
    lat: -22.6363468
  },
  {
    lng: -43.5615914,
    lat: -22.6360707
  },
  {
    lng: -43.5614193,
    lat: -22.6360651
  },
  {
    lng: -43.5609231,
    lat: -22.6360489
  },
  {
    lng: -43.5600671,
    lat: -22.6361904
  },
  {
    lng: -43.5592112,
    lat: -22.6363319
  },
  {
    lng: -43.558684,
    lat: -22.6365837
  },
  {
    lng: -43.5581568,
    lat: -22.6370007
  },
  {
    lng: -43.5577556,
    lat: -22.6374098
  },
  {
    lng: -43.5577525,
    lat: -22.6374866
  },
  {
    lng: -43.5577005,
    lat: -22.638771
  },
  {
    lng: -43.5576612,
    lat: -22.6389677
  },
  {
    lng: -43.5570081,
    lat: -22.6396365
  },
  {
    lng: -43.5566264,
    lat: -22.6403094
  },
  {
    lng: -43.5532082,
    lat: -22.6455109
  },
  {
    lng: -43.5529665,
    lat: -22.6458657
  },
  {
    lng: -43.5524995,
    lat: -22.6465512
  },
  {
    lng: -43.5522822,
    lat: -22.6469857
  },
  {
    lng: -43.5522822,
    lat: -22.6473172
  },
  {
    lng: -43.5523223,
    lat: -22.6475937
  },
  {
    lng: -43.5524322,
    lat: -22.6478227
  },
  {
    lng: -43.5525512,
    lat: -22.6480424
  },
  {
    lng: -43.5532855,
    lat: -22.6488233
  },
  {
    lng: -43.5534901,
    lat: -22.6492453
  },
  {
    lng: -43.5536591,
    lat: -22.6499287
  },
  {
    lng: -43.5536958,
    lat: -22.6501484
  },
  {
    lng: -43.5537174,
    lat: -22.6502997
  },
  {
    lng: -43.5536774,
    lat: -22.6504414
  },
  {
    lng: -43.5536133,
    lat: -22.6506063
  },
  {
    lng: -43.5535309,
    lat: -22.6507619
  },
  {
    lng: -43.5533478,
    lat: -22.6509267
  },
  {
    lng: -43.5531372,
    lat: -22.6510824
  },
  {
    lng: -43.5528534,
    lat: -22.6512289
  },
  {
    lng: -43.5521963,
    lat: -22.6513962
  },
  {
    lng: -43.5511001,
    lat: -22.6516752
  },
  {
    lng: -43.5508389,
    lat: -22.6517417
  },
  {
    lng: -43.5496943,
    lat: -22.6521812
  },
  {
    lng: -43.5490625,
    lat: -22.6524559
  },
  {
    lng: -43.5488428,
    lat: -22.6526115
  },
  {
    lng: -43.5486596,
    lat: -22.652813
  },
  {
    lng: -43.5484165,
    lat: -22.6532134
  },
  {
    lng: -43.5479231,
    lat: -22.6539891
  },
  {
    lng: -43.5475557,
    lat: -22.6546994
  },
  {
    lng: -43.547425,
    lat: -22.655026
  },
  {
    lng: -43.5473271,
    lat: -22.6553199
  },
  {
    lng: -43.5472536,
    lat: -22.6554996
  },
  {
    lng: -43.5471801,
    lat: -22.6556628
  },
  {
    lng: -43.5470005,
    lat: -22.6557772
  },
  {
    lng: -43.546829,
    lat: -22.655818
  },
  {
    lng: -43.546633,
    lat: -22.6558425
  },
  {
    lng: -43.5462885,
    lat: -22.655778
  },
  {
    lng: -43.5446271,
    lat: -22.6551144
  },
  {
    lng: -43.541775,
    lat: -22.6544136
  },
  {
    lng: -43.5409259,
    lat: -22.6540136
  },
  {
    lng: -43.5405295,
    lat: -22.6537492
  },
  {
    lng: -43.5401175,
    lat: -22.6536135
  },
  {
    lng: -43.5397827,
    lat: -22.6535444
  },
  {
    lng: -43.5395637,
    lat: -22.6535247
  },
  {
    lng: -43.538973,
    lat: -22.6536028
  },
  {
    lng: -43.5375288,
    lat: -22.6539347
  },
  {
    lng: -43.5373587,
    lat: -22.6539667
  },
  {
    lng: -43.5350439,
    lat: -22.6544019
  },
  {
    lng: -43.5344246,
    lat: -22.6545724
  },
  {
    lng: -43.5342762,
    lat: -22.6546133
  },
  {
    lng: -43.5341256,
    lat: -22.6547289
  },
  {
    lng: -43.5339869,
    lat: -22.6548354
  },
  {
    lng: -43.5341589,
    lat: -22.6550981
  },
  {
    lng: -43.5343048,
    lat: -22.6553534
  },
  {
    lng: -43.5348818,
    lat: -22.6558591
  },
  {
    lng: -43.534954,
    lat: -22.6559223
  },
  {
    lng: -43.5352887,
    lat: -22.6561403
  },
  {
    lng: -43.5355128,
    lat: -22.6562861
  },
  {
    lng: -43.5361181,
    lat: -22.6567807
  },
  {
    lng: -43.5367688,
    lat: -22.657451
  },
  {
    lng: -43.5371942,
    lat: -22.6580182
  },
  {
    lng: -43.5376474,
    lat: -22.6586224
  },
  {
    lng: -43.5379337,
    lat: -22.65913
  },
  {
    lng: -43.5382201,
    lat: -22.6594554
  },
  {
    lng: -43.5385585,
    lat: -22.6597739
  },
  {
    lng: -43.5388292,
    lat: -22.6599905
  },
  {
    lng: -43.5391601,
    lat: -22.6601289
  },
  {
    lng: -43.539473,
    lat: -22.660183
  },
  {
    lng: -43.53984,
    lat: -22.6604718
  },
  {
    lng: -43.5403875,
    lat: -22.6607847
  },
  {
    lng: -43.5404881,
    lat: -22.6608893
  },
  {
    lng: -43.5405921,
    lat: -22.6610133
  },
  {
    lng: -43.5407244,
    lat: -22.6614224
  },
  {
    lng: -43.5407485,
    lat: -22.6616812
  },
  {
    lng: -43.5407966,
    lat: -22.66197
  },
  {
    lng: -43.5408989,
    lat: -22.6622828
  },
  {
    lng: -43.5411877,
    lat: -22.6629507
  },
  {
    lng: -43.5414016,
    lat: -22.6633534
  },
  {
    lng: -43.5416858,
    lat: -22.6636067
  },
  {
    lng: -43.5418217,
    lat: -22.6639466
  },
  {
    lng: -43.5424149,
    lat: -22.6666837
  },
  {
    lng: -43.5424829,
    lat: -22.6670915
  },
  {
    lng: -43.5426373,
    lat: -22.6676044
  },
  {
    lng: -43.5427238,
    lat: -22.6680122
  },
  {
    lng: -43.5430204,
    lat: -22.6683582
  },
  {
    lng: -43.5436111,
    lat: -22.6690332
  },
  {
    lng: -43.5436259,
    lat: -22.6690502
  },
  {
    lng: -43.5436644,
    lat: -22.6690745
  },
  {
    lng: -43.5436763,
    lat: -22.669082
  },
  {
    lng: -43.5438607,
    lat: -22.6691985
  },
  {
    lng: -43.5442932,
    lat: -22.6692232
  },
  {
    lng: -43.5452077,
    lat: -22.66863
  },
  {
    lng: -43.5454425,
    lat: -22.6686671
  },
  {
    lng: -43.5456526,
    lat: -22.6687289
  },
  {
    lng: -43.5457514,
    lat: -22.6688896
  },
  {
    lng: -43.5459862,
    lat: -22.6691367
  },
  {
    lng: -43.5462334,
    lat: -22.6693344
  },
  {
    lng: -43.5464929,
    lat: -22.6694704
  },
  {
    lng: -43.5466498,
    lat: -22.6695023
  },
  {
    lng: -43.5467895,
    lat: -22.6696681
  },
  {
    lng: -43.5470613,
    lat: -22.6698658
  },
  {
    lng: -43.5473332,
    lat: -22.6700882
  },
  {
    lng: -43.5475309,
    lat: -22.6702056
  },
  {
    lng: -43.5480252,
    lat: -22.6707061
  },
  {
    lng: -43.5482229,
    lat: -22.6707926
  },
  {
    lng: -43.5482609,
    lat: -22.6707874
  },
  {
    lng: -43.548288,
    lat: -22.6707837
  },
  {
    lng: -43.5484948,
    lat: -22.6707555
  },
  {
    lng: -43.5490275,
    lat: -22.6703458
  },
  {
    lng: -43.5494958,
    lat: -22.6701377
  },
  {
    lng: -43.5497129,
    lat: -22.6701079
  },
  {
    lng: -43.5499119,
    lat: -22.6702296
  },
  {
    lng: -43.5500798,
    lat: -22.6704317
  },
  {
    lng: -43.5502525,
    lat: -22.6706799
  },
  {
    lng: -43.5504036,
    lat: -22.6709713
  },
  {
    lng: -43.5506842,
    lat: -22.6712519
  },
  {
    lng: -43.5508892,
    lat: -22.6713814
  },
  {
    lng: -43.5510727,
    lat: -22.6715757
  },
  {
    lng: -43.5514828,
    lat: -22.6717807
  },
  {
    lng: -43.5516554,
    lat: -22.6718455
  },
  {
    lng: -43.5517634,
    lat: -22.6721692
  },
  {
    lng: -43.5519792,
    lat: -22.6726764
  },
  {
    lng: -43.5522922,
    lat: -22.6733779
  },
  {
    lng: -43.5524864,
    lat: -22.6736909
  },
  {
    lng: -43.5527131,
    lat: -22.6739607
  },
  {
    lng: -43.5528965,
    lat: -22.6741118
  },
  {
    lng: -43.5532367,
    lat: -22.6741032
  },
  {
    lng: -43.5537059,
    lat: -22.6741657
  },
  {
    lng: -43.5539685,
    lat: -22.6742642
  },
  {
    lng: -43.5542132,
    lat: -22.6743924
  },
  {
    lng: -43.5544614,
    lat: -22.6745758
  },
  {
    lng: -43.5545585,
    lat: -22.6748241
  },
  {
    lng: -43.5545261,
    lat: -22.6751154
  },
  {
    lng: -43.5543966,
    lat: -22.6753529
  },
  {
    lng: -43.5542132,
    lat: -22.6756335
  },
  {
    lng: -43.5541268,
    lat: -22.6758493
  },
  {
    lng: -43.5541808,
    lat: -22.6760975
  },
  {
    lng: -43.5542132,
    lat: -22.6763026
  },
  {
    lng: -43.5544506,
    lat: -22.6764644
  },
  {
    lng: -43.5547312,
    lat: -22.6764536
  },
  {
    lng: -43.5549503,
    lat: -22.6764293
  },
  {
    lng: -43.5550696,
    lat: -22.6765015
  },
  {
    lng: -43.5551884,
    lat: -22.676593
  },
  {
    lng: -43.5552492,
    lat: -22.676745
  },
  {
    lng: -43.5552265,
    lat: -22.6769413
  },
  {
    lng: -43.5552886,
    lat: -22.6771226
  },
  {
    lng: -43.5553887,
    lat: -22.6772418
  },
  {
    lng: -43.5555796,
    lat: -22.6773468
  },
  {
    lng: -43.5557561,
    lat: -22.6773516
  },
  {
    lng: -43.5558849,
    lat: -22.6773134
  },
  {
    lng: -43.5561425,
    lat: -22.677113
  },
  {
    lng: -43.556319,
    lat: -22.6770749
  },
  {
    lng: -43.5564537,
    lat: -22.6770817
  },
  {
    lng: -43.5566148,
    lat: -22.6771512
  },
  {
    lng: -43.5567674,
    lat: -22.6772657
  },
  {
    lng: -43.5568676,
    lat: -22.6774136
  },
  {
    lng: -43.5568867,
    lat: -22.6776044
  },
  {
    lng: -43.5568533,
    lat: -22.6777427
  },
  {
    lng: -43.556808,
    lat: -22.6778358
  },
  {
    lng: -43.5565528,
    lat: -22.678153
  },
  {
    lng: -43.5565051,
    lat: -22.6782389
  },
  {
    lng: -43.5564335,
    lat: -22.6784059
  },
  {
    lng: -43.5563906,
    lat: -22.6785824
  },
  {
    lng: -43.5564117,
    lat: -22.6788105
  },
  {
    lng: -43.5563439,
    lat: -22.6789604
  },
  {
    lng: -43.5559867,
    lat: -22.6791607
  },
  {
    lng: -43.5557719,
    lat: -22.6792778
  },
  {
    lng: -43.5556502,
    lat: -22.6793616
  },
  {
    lng: -43.5555569,
    lat: -22.679475
  },
  {
    lng: -43.555466,
    lat: -22.6795856
  },
  {
    lng: -43.555438,
    lat: -22.6797405
  },
  {
    lng: -43.5550272,
    lat: -22.6798521
  },
  {
    lng: -43.5547305,
    lat: -22.6799031
  },
  {
    lng: -43.5544338,
    lat: -22.6799542
  },
  {
    lng: -43.554066,
    lat: -22.679948
  },
  {
    lng: -43.5531044,
    lat: -22.6796495
  },
  {
    lng: -43.5521981,
    lat: -22.6795039
  },
  {
    lng: -43.551523,
    lat: -22.6794261
  },
  {
    lng: -43.5510269,
    lat: -22.6794575
  },
  {
    lng: -43.5505549,
    lat: -22.6796563
  },
  {
    lng: -43.5501266,
    lat: -22.679981
  },
  {
    lng: -43.5496838,
    lat: -22.6804549
  },
  {
    lng: -43.5492936,
    lat: -22.6808278
  },
  {
    lng: -43.548955,
    lat: -22.6811951
  },
  {
    lng: -43.5486368,
    lat: -22.6814551
  },
  {
    lng: -43.548269,
    lat: -22.6815294
  },
  {
    lng: -43.5477609,
    lat: -22.681483
  },
  {
    lng: -43.5471391,
    lat: -22.6812324
  },
  {
    lng: -43.5467002,
    lat: -22.6809727
  },
  {
    lng: -43.5456812,
    lat: -22.6804091
  },
  {
    lng: -43.5447848,
    lat: -22.6799469
  },
  {
    lng: -43.5441726,
    lat: -22.679884
  },
  {
    lng: -43.5437496,
    lat: -22.6799422
  },
  {
    lng: -43.5430314,
    lat: -22.6801603
  },
  {
    lng: -43.5423588,
    lat: -22.6804505
  },
  {
    lng: -43.5419692,
    lat: -22.6807624
  },
  {
    lng: -43.5414653,
    lat: -22.6811736
  },
  {
    lng: -43.5411884,
    lat: -22.6815487
  },
  {
    lng: -43.5406434,
    lat: -22.6824326
  },
  {
    lng: -43.5405184,
    lat: -22.6825605
  },
  {
    lng: -43.5403142,
    lat: -22.6828789
  },
  {
    lng: -43.5401687,
    lat: -22.6829971
  },
  {
    lng: -43.5398186,
    lat: -22.6834718
  },
  {
    lng: -43.5388844,
    lat: -22.6846342
  },
  {
    lng: -43.5377742,
    lat: -22.6859656
  },
  {
    lng: -43.5372191,
    lat: -22.6865842
  },
  {
    lng: -43.5361669,
    lat: -22.6874492
  },
  {
    lng: -43.5359709,
    lat: -22.6876368
  },
  {
    lng: -43.5357142,
    lat: -22.6878824
  },
  {
    lng: -43.5356722,
    lat: -22.6879888
  },
  {
    lng: -43.535583,
    lat: -22.6882144
  },
  {
    lng: -43.5352355,
    lat: -22.6883984
  },
  {
    lng: -43.5351139,
    lat: -22.6884628
  },
  {
    lng: -43.5348752,
    lat: -22.6885584
  },
  {
    lng: -43.5346303,
    lat: -22.688672
  },
  {
    lng: -43.534548,
    lat: -22.6887102
  },
  {
    lng: -43.5344428,
    lat: -22.6887435
  },
  {
    lng: -43.5342478,
    lat: -22.6888052
  },
  {
    lng: -43.533824,
    lat: -22.6889397
  },
  {
    lng: -43.5332595,
    lat: -22.6890647
  },
  {
    lng: -43.53281,
    lat: -22.6891337
  },
  {
    lng: -43.5324227,
    lat: -22.6891543
  },
  {
    lng: -43.5317713,
    lat: -22.6891886
  },
  {
    lng: -43.5310937,
    lat: -22.6892063
  },
  {
    lng: -43.5306185,
    lat: -22.689226
  },
  {
    lng: -43.5301961,
    lat: -22.6892381
  },
  {
    lng: -43.5296503,
    lat: -22.6892653
  },
  {
    lng: -43.5291748,
    lat: -22.6893014
  },
  {
    lng: -43.52869,
    lat: -22.6893863
  },
  {
    lng: -43.528081,
    lat: -22.6895598
  },
  {
    lng: -43.5274544,
    lat: -22.6897331
  },
  {
    lng: -43.5270394,
    lat: -22.6898596
  },
  {
    lng: -43.5266865,
    lat: -22.6899458
  },
  {
    lng: -43.5260416,
    lat: -22.6901842
  },
  {
    lng: -43.5256344,
    lat: -22.6904005
  },
  {
    lng: -43.5249975,
    lat: -22.6906962
  },
  {
    lng: -43.524502,
    lat: -22.6909443
  },
  {
    lng: -43.5239976,
    lat: -22.6911922
  },
  {
    lng: -43.5234847,
    lat: -22.6914238
  },
  {
    lng: -43.5230076,
    lat: -22.6915986
  },
  {
    lng: -43.5224871,
    lat: -22.691724
  },
  {
    lng: -43.5224596,
    lat: -22.6917262
  },
  {
    lng: -43.5219587,
    lat: -22.6917676
  },
  {
    lng: -43.5212283,
    lat: -22.6917929
  },
  {
    lng: -43.5202697,
    lat: -22.691767
  },
  {
    lng: -43.5195222,
    lat: -22.6917431
  },
  {
    lng: -43.5188099,
    lat: -22.6917114
  },
  {
    lng: -43.5183351,
    lat: -22.6916903
  },
  {
    lng: -43.5177636,
    lat: -22.6916682
  },
  {
    lng: -43.5173767,
    lat: -22.691648
  },
  {
    lng: -43.5168759,
    lat: -22.691594
  },
  {
    lng: -43.5162612,
    lat: -22.691498
  },
  {
    lng: -43.515594,
    lat: -22.6913851
  },
  {
    lng: -43.5148911,
    lat: -22.6913046
  },
  {
    lng: -43.5142227,
    lat: -22.6912896
  },
  {
    lng: -43.5139366,
    lat: -22.6913007
  },
  {
    lng: -43.5139332,
    lat: -22.6913009
  },
  {
    lng: -43.5135538,
    lat: -22.6913155
  },
  {
    lng: -43.5132718,
    lat: -22.6913535
  },
  {
    lng: -43.5122146,
    lat: -22.6914897
  },
  {
    lng: -43.512186,
    lat: -22.6916858
  },
  {
    lng: -43.5130582,
    lat: -22.6917512
  },
  {
    lng: -43.5136135,
    lat: -22.6917727
  },
  {
    lng: -43.513732,
    lat: -22.6919168
  },
  {
    lng: -43.5139244,
    lat: -22.6926023
  },
  {
    lng: -43.5140337,
    lat: -22.692893
  },
  {
    lng: -43.5140427,
    lat: -22.6932175
  },
  {
    lng: -43.5138593,
    lat: -22.6935095
  },
  {
    lng: -43.5138859,
    lat: -22.6938534
  },
  {
    lng: -43.514046,
    lat: -22.6940635
  },
  {
    lng: -43.5142325,
    lat: -22.6941463
  },
  {
    lng: -43.5143088,
    lat: -22.6941511
  },
  {
    lng: -43.5143114,
    lat: -22.6941513
  },
  {
    lng: -43.5146693,
    lat: -22.6941736
  },
  {
    lng: -43.5150694,
    lat: -22.6941506
  },
  {
    lng: -43.5152684,
    lat: -22.6942066
  },
  {
    lng: -43.5155663,
    lat: -22.6943789
  },
  {
    lng: -43.5160398,
    lat: -22.694476
  },
  {
    lng: -43.5164148,
    lat: -22.6944447
  },
  {
    lng: -43.5167404,
    lat: -22.6943666
  },
  {
    lng: -43.5171567,
    lat: -22.6944584
  },
  {
    lng: -43.5173438,
    lat: -22.6945414
  },
  {
    lng: -43.5176181,
    lat: -22.6947527
  },
  {
    lng: -43.5176848,
    lat: -22.6948041
  },
  {
    lng: -43.5182327,
    lat: -22.6949598
  },
  {
    lng: -43.5188564,
    lat: -22.6950236
  },
  {
    lng: -43.5192191,
    lat: -22.6949806
  },
  {
    lng: -43.5196328,
    lat: -22.6948337
  },
  {
    lng: -43.5198217,
    lat: -22.6946848
  },
  {
    lng: -43.5199752,
    lat: -22.6943502
  },
  {
    lng: -43.5201765,
    lat: -22.694213
  },
  {
    lng: -43.5203447,
    lat: -22.6941097
  },
  {
    lng: -43.5206672,
    lat: -22.694108
  },
  {
    lng: -43.5210907,
    lat: -22.6942789
  },
  {
    lng: -43.5214831,
    lat: -22.6946538
  },
  {
    lng: -43.5215906,
    lat: -22.6947847
  },
  {
    lng: -43.5218283,
    lat: -22.6950742
  },
  {
    lng: -43.5220249,
    lat: -22.6953773
  },
  {
    lng: -43.5220701,
    lat: -22.6954754
  },
  {
    lng: -43.5221591,
    lat: -22.6956683
  },
  {
    lng: -43.5223442,
    lat: -22.6958786
  },
  {
    lng: -43.5227171,
    lat: -22.6960442
  },
  {
    lng: -43.5231269,
    lat: -22.6962682
  },
  {
    lng: -43.5233625,
    lat: -22.696421
  },
  {
    lng: -43.5235839,
    lat: -22.6967359
  },
  {
    lng: -43.5237685,
    lat: -22.6969926
  },
  {
    lng: -43.5240269,
    lat: -22.6973542
  },
  {
    lng: -43.5242372,
    lat: -22.6976554
  },
  {
    lng: -43.5243752,
    lat: -22.6979431
  },
  {
    lng: -43.524391,
    lat: -22.6979613
  },
  {
    lng: -43.5245924,
    lat: -22.6981938
  },
  {
    lng: -43.5248023,
    lat: -22.6984052
  },
  {
    lng: -43.5248572,
    lat: -22.6984261
  },
  {
    lng: -43.5249254,
    lat: -22.6984522
  },
  {
    lng: -43.5250219,
    lat: -22.698489
  },
  {
    lng: -43.5254098,
    lat: -22.6985008
  },
  {
    lng: -43.5256828,
    lat: -22.6985278
  },
  {
    lng: -43.5257659,
    lat: -22.6985696
  },
  {
    lng: -43.5258245,
    lat: -22.6985991
  },
  {
    lng: -43.526047,
    lat: -22.6988213
  },
  {
    lng: -43.5262427,
    lat: -22.6992056
  },
  {
    lng: -43.5262993,
    lat: -22.6992623
  },
  {
    lng: -43.5264528,
    lat: -22.6994161
  },
  {
    lng: -43.5268252,
    lat: -22.6996281
  },
  {
    lng: -43.5268733,
    lat: -22.6996323
  },
  {
    lng: -43.5271247,
    lat: -22.699654
  },
  {
    lng: -43.5275926,
    lat: -22.699699
  },
  {
    lng: -43.5280231,
    lat: -22.6997318
  },
  {
    lng: -43.5283345,
    lat: -22.6998042
  },
  {
    lng: -43.5285692,
    lat: -22.7000497
  },
  {
    lng: -43.5288311,
    lat: -22.7003051
  },
  {
    lng: -43.5289618,
    lat: -22.7003405
  },
  {
    lng: -43.5292163,
    lat: -22.7004094
  },
  {
    lng: -43.5296013,
    lat: -22.7004764
  },
  {
    lng: -43.5299621,
    lat: -22.7006072
  },
  {
    lng: -43.5300286,
    lat: -22.7007063
  },
  {
    lng: -43.5301342,
    lat: -22.7008637
  },
  {
    lng: -43.5302932,
    lat: -22.701178
  },
  {
    lng: -43.5305029,
    lat: -22.7014233
  },
  {
    lng: -43.5307123,
    lat: -22.7014388
  },
  {
    lng: -43.5310396,
    lat: -22.701463
  },
  {
    lng: -43.5313717,
    lat: -22.7014199
  },
  {
    lng: -43.5315398,
    lat: -22.7013981
  },
  {
    lng: -43.531801,
    lat: -22.7015048
  },
  {
    lng: -43.5321389,
    lat: -22.7017126
  },
  {
    lng: -43.5323754,
    lat: -22.701862
  },
  {
    lng: -43.5328309,
    lat: -22.70214
  },
  {
    lng: -43.5331401,
    lat: -22.7024209
  },
  {
    lng: -43.5333752,
    lat: -22.7026317
  },
  {
    lng: -43.5334844,
    lat: -22.7028394
  },
  {
    lng: -43.5335466,
    lat: -22.7029577
  },
  {
    lng: -43.5335714,
    lat: -22.7029646
  },
  {
    lng: -43.5336301,
    lat: -22.7029808
  },
  {
    lng: -43.5338514,
    lat: -22.703201
  },
  {
    lng: -43.5340174,
    lat: -22.7033416
  },
  {
    lng: -43.5345397,
    lat: -22.703523
  },
  {
    lng: -43.5347766,
    lat: -22.70356
  },
  {
    lng: -43.5352134,
    lat: -22.7036553
  },
  {
    lng: -43.5355477,
    lat: -22.7037238
  },
  {
    lng: -43.5357939,
    lat: -22.7037915
  },
  {
    lng: -43.536046,
    lat: -22.7039461
  },
  {
    lng: -43.5358114,
    lat: -22.7040976
  },
  {
    lng: -43.535618,
    lat: -22.7043093
  },
  {
    lng: -43.535564,
    lat: -22.7046912
  },
  {
    lng: -43.5355227,
    lat: -22.7050501
  },
  {
    lng: -43.5355086,
    lat: -22.7051581
  },
  {
    lng: -43.5354819,
    lat: -22.7053626
  },
  {
    lng: -43.535449,
    lat: -22.7054357
  },
  {
    lng: -43.535426,
    lat: -22.7059183
  },
  {
    lng: -43.5354347,
    lat: -22.7059672
  },
  {
    lng: -43.5354605,
    lat: -22.7061156
  },
  {
    lng: -43.5354849,
    lat: -22.7062549
  },
  {
    lng: -43.5355366,
    lat: -22.7064236
  },
  {
    lng: -43.5356373,
    lat: -22.7069072
  },
  {
    lng: -43.5356937,
    lat: -22.7074068
  },
  {
    lng: -43.5357148,
    lat: -22.7081227
  },
  {
    lng: -43.5356184,
    lat: -22.708466
  },
  {
    lng: -43.535598,
    lat: -22.7085388
  },
  {
    lng: -43.5355323,
    lat: -22.7088395
  },
  {
    lng: -43.5355932,
    lat: -22.7089907
  },
  {
    lng: -43.5359034,
    lat: -22.709179
  },
  {
    lng: -43.5362985,
    lat: -22.7094169
  },
  {
    lng: -43.5367097,
    lat: -22.7097078
  },
  {
    lng: -43.5370943,
    lat: -22.7099547
  },
  {
    lng: -43.5375328,
    lat: -22.7102368
  },
  {
    lng: -43.5379501,
    lat: -22.7105188
  },
  {
    lng: -43.5382356,
    lat: -22.7106404
  },
  {
    lng: -43.5384226,
    lat: -22.7107201
  },
  {
    lng: -43.5389688,
    lat: -22.711038
  },
  {
    lng: -43.5396615,
    lat: -22.7116816
  },
  {
    lng: -43.539855,
    lat: -22.7118718
  },
  {
    lng: -43.5399829,
    lat: -22.7119974
  },
  {
    lng: -43.5404149,
    lat: -22.7124881
  },
  {
    lng: -43.5406868,
    lat: -22.7127571
  },
  {
    lng: -43.5409584,
    lat: -22.7130609
  },
  {
    lng: -43.5410753,
    lat: -22.7132101
  },
  {
    lng: -43.5412418,
    lat: -22.7134227
  },
  {
    lng: -43.5416868,
    lat: -22.7138671
  },
  {
    lng: -43.5419597,
    lat: -22.714182
  },
  {
    lng: -43.5420817,
    lat: -22.7143226
  },
  {
    lng: -43.5423038,
    lat: -22.7145796
  },
  {
    lng: -43.542464,
    lat: -22.7147897
  },
  {
    lng: -43.5425283,
    lat: -22.7148818
  },
  {
    lng: -43.5423471,
    lat: -22.7149493
  },
  {
    lng: -43.5419486,
    lat: -22.7150978
  },
  {
    lng: -43.5416175,
    lat: -22.7152547
  },
  {
    lng: -43.5415051,
    lat: -22.715308
  },
  {
    lng: -43.5413058,
    lat: -22.7154025
  },
  {
    lng: -43.5411969,
    lat: -22.7154582
  },
  {
    lng: -43.5408562,
    lat: -22.7156325
  },
  {
    lng: -43.5403535,
    lat: -22.7159176
  },
  {
    lng: -43.5400017,
    lat: -22.7161114
  },
  {
    lng: -43.5397087,
    lat: -22.7163124
  },
  {
    lng: -43.5392719,
    lat: -22.7166146
  },
  {
    lng: -43.5390075,
    lat: -22.7168092
  },
  {
    lng: -43.5389607,
    lat: -22.716843
  },
  {
    lng: -43.5385166,
    lat: -22.717164
  },
  {
    lng: -43.5381697,
    lat: -22.7173915
  },
  {
    lng: -43.5379121,
    lat: -22.717622
  },
  {
    lng: -43.5381657,
    lat: -22.7179428
  },
  {
    lng: -43.5382594,
    lat: -22.7180741
  },
  {
    lng: -43.5384854,
    lat: -22.7183905
  },
  {
    lng: -43.540058,
    lat: -22.7205926
  },
  {
    lng: -43.5405893,
    lat: -22.7213365
  },
  {
    lng: -43.5410477,
    lat: -22.7219783
  },
  {
    lng: -43.5410793,
    lat: -22.7220385
  },
  {
    lng: -43.5410855,
    lat: -22.7220502
  },
  {
    lng: -43.5412103,
    lat: -22.7222874
  },
  {
    lng: -43.5412086,
    lat: -22.7224511
  },
  {
    lng: -43.5412041,
    lat: -22.7228783
  },
  {
    lng: -43.5411126,
    lat: -22.7232599
  },
  {
    lng: -43.5409674,
    lat: -22.7235893
  },
  {
    lng: -43.5408195,
    lat: -22.7238019
  },
  {
    lng: -43.5405549,
    lat: -22.7240196
  },
  {
    lng: -43.5404757,
    lat: -22.7240512
  },
  {
    lng: -43.5402161,
    lat: -22.7241556
  },
  {
    lng: -43.5399646,
    lat: -22.7243155
  },
  {
    lng: -43.5397504,
    lat: -22.7244874
  },
  {
    lng: -43.5395492,
    lat: -22.724613
  },
  {
    lng: -43.5394483,
    lat: -22.7247048
  },
  {
    lng: -43.5393474,
    lat: -22.7247966
  },
  {
    lng: -43.5391711,
    lat: -22.724934
  },
  {
    lng: -43.5390683,
    lat: -22.7251996
  },
  {
    lng: -43.539045,
    lat: -22.7253921
  },
  {
    lng: -43.5390135,
    lat: -22.7256511
  },
  {
    lng: -43.5390343,
    lat: -22.7260453
  },
  {
    lng: -43.5388186,
    lat: -22.7264647
  },
  {
    lng: -43.5386132,
    lat: -22.7268873
  },
  {
    lng: -43.5385225,
    lat: -22.7271878
  },
  {
    lng: -43.5383075,
    lat: -22.7274408
  },
  {
    lng: -43.5384799,
    lat: -22.7276741
  },
  {
    lng: -43.5389776,
    lat: -22.7280956
  },
  {
    lng: -43.5392707,
    lat: -22.7283642
  },
  {
    lng: -43.5395687,
    lat: -22.7286575
  },
  {
    lng: -43.5400032,
    lat: -22.7289164
  },
  {
    lng: -43.5404057,
    lat: -22.729141
  },
  {
    lng: -43.5406982,
    lat: -22.7293515
  },
  {
    lng: -43.5407559,
    lat: -22.7294263
  },
  {
    lng: -43.5409342,
    lat: -22.7296461
  },
  {
    lng: -43.540988,
    lat: -22.729729
  },
  {
    lng: -43.5411527,
    lat: -22.7300741
  },
  {
    lng: -43.5412476,
    lat: -22.7305501
  },
  {
    lng: -43.5412659,
    lat: -22.7306535
  },
  {
    lng: -43.5413147,
    lat: -22.7310722
  },
  {
    lng: -43.5413061,
    lat: -22.7314292
  },
  {
    lng: -43.5413009,
    lat: -22.7315352
  },
  {
    lng: -43.5412049,
    lat: -22.7322811
  },
  {
    lng: -43.5412104,
    lat: -22.7324342
  },
  {
    lng: -43.541223,
    lat: -22.7327814
  },
  {
    lng: -43.5412278,
    lat: -22.7329614
  },
  {
    lng: -43.5412362,
    lat: -22.7331707
  },
  {
    lng: -43.5412334,
    lat: -22.7334049
  },
  {
    lng: -43.541244,
    lat: -22.7336196
  },
  {
    lng: -43.5413187,
    lat: -22.7339183
  },
  {
    lng: -43.5414975,
    lat: -22.7343367
  },
  {
    lng: -43.5416943,
    lat: -22.7347835
  },
  {
    lng: -43.5417042,
    lat: -22.73481
  },
  {
    lng: -43.5419115,
    lat: -22.7353445
  },
  {
    lng: -43.541919,
    lat: -22.7353647
  },
  {
    lng: -43.5422047,
    lat: -22.7361306
  },
  {
    lng: -43.5418612,
    lat: -22.7361782
  },
  {
    lng: -43.5409878,
    lat: -22.7363821
  },
  {
    lng: -43.539609,
    lat: -22.7367473
  },
  {
    lng: -43.5384067,
    lat: -22.7370561
  },
  {
    lng: -43.5374108,
    lat: -22.7373164
  },
  {
    lng: -43.5364331,
    lat: -22.7375811
  },
  {
    lng: -43.5352551,
    lat: -22.7378987
  },
  {
    lng: -43.5346108,
    lat: -22.7380677
  },
  {
    lng: -43.5338863,
    lat: -22.7382561
  },
  {
    lng: -43.5328934,
    lat: -22.7385229
  },
  {
    lng: -43.5321958,
    lat: -22.7387189
  },
  {
    lng: -43.5323433,
    lat: -22.7389724
  },
  {
    lng: -43.5328084,
    lat: -22.7406772
  },
  {
    lng: -43.5329876,
    lat: -22.7414269
  },
  {
    lng: -43.5331586,
    lat: -22.7418319
  },
  {
    lng: -43.5334554,
    lat: -22.7423222
  },
  {
    lng: -43.5336857,
    lat: -22.7428146
  },
  {
    lng: -43.5341133,
    lat: -22.7440709
  },
  {
    lng: -43.5343943,
    lat: -22.744914
  },
  {
    lng: -43.5344491,
    lat: -22.745444
  },
  {
    lng: -43.5343476,
    lat: -22.745897
  },
  {
    lng: -43.5342336,
    lat: -22.7463835
  },
  {
    lng: -43.5341948,
    lat: -22.746624
  },
  {
    lng: -43.5342162,
    lat: -22.7468847
  },
  {
    lng: -43.5342202,
    lat: -22.7470698
  },
  {
    lng: -43.5342513,
    lat: -22.7475631
  },
  {
    lng: -43.5343033,
    lat: -22.7477821
  },
  {
    lng: -43.5343853,
    lat: -22.7479901
  },
  {
    lng: -43.5346343,
    lat: -22.7482953
  },
  {
    lng: -43.5346968,
    lat: -22.7483719
  },
  {
    lng: -43.5348693,
    lat: -22.7485937
  },
  {
    lng: -43.5349247,
    lat: -22.7486411
  },
  {
    lng: -43.5349703,
    lat: -22.7486801
  },
  {
    lng: -43.5350239,
    lat: -22.7487259
  },
  {
    lng: -43.5351293,
    lat: -22.7488162
  },
  {
    lng: -43.5353806,
    lat: -22.7491246
  },
  {
    lng: -43.5353974,
    lat: -22.749456
  },
  {
    lng: -43.5353677,
    lat: -22.7498961
  },
  {
    lng: -43.5353134,
    lat: -22.7503012
  },
  {
    lng: -43.5352695,
    lat: -22.7506847
  },
  {
    lng: -43.5352185,
    lat: -22.7509956
  },
  {
    lng: -43.5352161,
    lat: -22.7512273
  },
  {
    lng: -43.535202,
    lat: -22.7513779
  },
  {
    lng: -43.5352197,
    lat: -22.7514255
  },
  {
    lng: -43.5352627,
    lat: -22.7515406
  },
  {
    lng: -43.5354181,
    lat: -22.7517528
  },
  {
    lng: -43.5356286,
    lat: -22.7518812
  },
  {
    lng: -43.5359007,
    lat: -22.7519378
  },
  {
    lng: -43.5360453,
    lat: -22.751965
  },
  {
    lng: -43.5364075,
    lat: -22.7519799
  },
  {
    lng: -43.5367189,
    lat: -22.7520639
  },
  {
    lng: -43.5371639,
    lat: -22.7522085
  },
  {
    lng: -43.5373769,
    lat: -22.7522967
  },
  {
    lng: -43.5375612,
    lat: -22.7524623
  },
  {
    lng: -43.5377143,
    lat: -22.7525263
  },
  {
    lng: -43.5378176,
    lat: -22.7525695
  },
  {
    lng: -43.538721,
    lat: -22.7527893
  },
  {
    lng: -43.5396599,
    lat: -22.7529389
  },
  {
    lng: -43.5399074,
    lat: -22.7530246
  },
  {
    lng: -43.5401168,
    lat: -22.753272
  },
  {
    lng: -43.5402881,
    lat: -22.7534053
  },
  {
    lng: -43.5406498,
    lat: -22.7535195
  },
  {
    lng: -43.5409126,
    lat: -22.7535877
  },
  {
    lng: -43.541347,
    lat: -22.7537003
  },
  {
    lng: -43.5415635,
    lat: -22.7538812
  },
  {
    lng: -43.5418098,
    lat: -22.7541461
  },
  {
    lng: -43.5419537,
    lat: -22.7542714
  },
  {
    lng: -43.5421917,
    lat: -22.754319
  },
  {
    lng: -43.5430336,
    lat: -22.7540619
  },
  {
    lng: -43.5438097,
    lat: -22.7540335
  },
  {
    lng: -43.5441167,
    lat: -22.754062
  },
  {
    lng: -43.5443641,
    lat: -22.7541191
  },
  {
    lng: -43.5447258,
    lat: -22.7543428
  },
  {
    lng: -43.5448672,
    lat: -22.7544537
  },
  {
    lng: -43.5450826,
    lat: -22.7546257
  },
  {
    lng: -43.5451313,
    lat: -22.754661
  },
  {
    lng: -43.5453574,
    lat: -22.7548249
  },
  {
    lng: -43.5455039,
    lat: -22.7549281
  },
  {
    lng: -43.5459679,
    lat: -22.7550923
  },
  {
    lng: -43.5461963,
    lat: -22.7552065
  },
  {
    lng: -43.5464604,
    lat: -22.7553707
  },
  {
    lng: -43.5467388,
    lat: -22.7556563
  },
  {
    lng: -43.547003,
    lat: -22.7561206
  },
  {
    lng: -43.5471195,
    lat: -22.7568745
  },
  {
    lng: -43.5472355,
    lat: -22.7573386
  },
  {
    lng: -43.5474646,
    lat: -22.7582549
  },
  {
    lng: -43.5474955,
    lat: -22.7585473
  },
  {
    lng: -43.5474938,
    lat: -22.7585811
  },
  {
    lng: -43.5474841,
    lat: -22.7587779
  },
  {
    lng: -43.5475297,
    lat: -22.7588679
  },
  {
    lng: -43.5477349,
    lat: -22.7588229
  },
  {
    lng: -43.547739,
    lat: -22.7584346
  },
  {
    lng: -43.5477845,
    lat: -22.7578209
  },
  {
    lng: -43.5478177,
    lat: -22.7574418
  },
  {
    lng: -43.5477326,
    lat: -22.757047
  },
  {
    lng: -43.5476034,
    lat: -22.7567841
  },
  {
    lng: -43.5475353,
    lat: -22.7565043
  },
  {
    lng: -43.547586,
    lat: -22.7562814
  },
  {
    lng: -43.5477996,
    lat: -22.7557025
  },
  {
    lng: -43.5481448,
    lat: -22.7547268
  },
  {
    lng: -43.5481838,
    lat: -22.7547422
  },
  {
    lng: -43.5482659,
    lat: -22.7547917
  },
  {
    lng: -43.5486034,
    lat: -22.7549445
  },
  {
    lng: -43.548845,
    lat: -22.7550529
  },
  {
    lng: -43.5491638,
    lat: -22.7551286
  },
  {
    lng: -43.5492628,
    lat: -22.7551323
  },
  {
    lng: -43.549428,
    lat: -22.7550833
  },
  {
    lng: -43.5494967,
    lat: -22.7550425
  },
  {
    lng: -43.5495946,
    lat: -22.7549534
  },
  {
    lng: -43.5498825,
    lat: -22.7547035
  },
  {
    lng: -43.5501539,
    lat: -22.7545139
  },
  {
    lng: -43.5502302,
    lat: -22.7545009
  },
  {
    lng: -43.5503026,
    lat: -22.7544919
  },
  {
    lng: -43.550399,
    lat: -22.7545075
  },
  {
    lng: -43.5508009,
    lat: -22.754586
  },
  {
    lng: -43.5511785,
    lat: -22.7546751
  },
  {
    lng: -43.5515732,
    lat: -22.7547811
  },
  {
    lng: -43.5519691,
    lat: -22.7548492
  },
  {
    lng: -43.5521004,
    lat: -22.7548776
  },
  {
    lng: -43.5522257,
    lat: -22.7548968
  },
  {
    lng: -43.5523238,
    lat: -22.7548979
  },
  {
    lng: -43.5527188,
    lat: -22.7549393
  },
  {
    lng: -43.5531195,
    lat: -22.7549525
  },
  {
    lng: -43.5535327,
    lat: -22.7549576
  },
  {
    lng: -43.553934,
    lat: -22.754947
  },
  {
    lng: -43.5539568,
    lat: -22.754945
  },
  {
    lng: -43.5540291,
    lat: -22.7549513
  },
  {
    lng: -43.5540852,
    lat: -22.7549589
  },
  {
    lng: -43.5542097,
    lat: -22.754943
  },
  {
    lng: -43.5542982,
    lat: -22.7549619
  },
  {
    lng: -43.5543982,
    lat: -22.7549607
  },
  {
    lng: -43.5544952,
    lat: -22.7549781
  },
  {
    lng: -43.554637,
    lat: -22.7549713
  },
  {
    lng: -43.5547467,
    lat: -22.754958
  },
  {
    lng: -43.5547804,
    lat: -22.7549584
  },
  {
    lng: -43.5548398,
    lat: -22.7549591
  },
  {
    lng: -43.554864,
    lat: -22.754962
  },
  {
    lng: -43.5549748,
    lat: -22.7549751
  },
  {
    lng: -43.5552428,
    lat: -22.7549817
  },
  {
    lng: -43.5553667,
    lat: -22.7549836
  },
  {
    lng: -43.5554573,
    lat: -22.7549866
  },
  {
    lng: -43.5555613,
    lat: -22.7549591
  },
  {
    lng: -43.5556581,
    lat: -22.7549787
  },
  {
    lng: -43.5557762,
    lat: -22.7550342
  },
  {
    lng: -43.5559295,
    lat: -22.7550845
  },
  {
    lng: -43.555989,
    lat: -22.7551303
  },
  {
    lng: -43.5560012,
    lat: -22.7551993
  },
  {
    lng: -43.5560026,
    lat: -22.7552147
  },
  {
    lng: -43.5560084,
    lat: -22.7552794
  },
  {
    lng: -43.5560771,
    lat: -22.7553271
  },
  {
    lng: -43.5561936,
    lat: -22.7553875
  },
  {
    lng: -43.5563805,
    lat: -22.7555137
  },
  {
    lng: -43.556459,
    lat: -22.7555638
  },
  {
    lng: -43.5566157,
    lat: -22.7556639
  },
  {
    lng: -43.5567607,
    lat: -22.7557795
  },
  {
    lng: -43.557037,
    lat: -22.7559886
  },
  {
    lng: -43.5573323,
    lat: -22.7562342
  },
  {
    lng: -43.5576245,
    lat: -22.7565052
  },
  {
    lng: -43.5579268,
    lat: -22.756768
  },
  {
    lng: -43.5582282,
    lat: -22.7570236
  },
  {
    lng: -43.5585254,
    lat: -22.7572931
  },
  {
    lng: -43.558822,
    lat: -22.7575558
  },
  {
    lng: -43.5588439,
    lat: -22.7575737
  },
  {
    lng: -43.5589579,
    lat: -22.7576445
  },
  {
    lng: -43.5590719,
    lat: -22.757705
  },
  {
    lng: -43.5594186,
    lat: -22.7578751
  },
  {
    lng: -43.5594848,
    lat: -22.757884
  },
  {
    lng: -43.5596201,
    lat: -22.7579051
  },
  {
    lng: -43.5597123,
    lat: -22.7579018
  },
  {
    lng: -43.5600988,
    lat: -22.7578339
  },
  {
    lng: -43.5602871,
    lat: -22.7578216
  },
  {
    lng: -43.5606662,
    lat: -22.7577225
  },
  {
    lng: -43.5610438,
    lat: -22.7576214
  },
  {
    lng: -43.5614113,
    lat: -22.7574933
  },
  {
    lng: -43.5617941,
    lat: -22.7573918
  },
  {
    lng: -43.5621738,
    lat: -22.757298
  },
  {
    lng: -43.5624894,
    lat: -22.7572157
  },
  {
    lng: -43.5625703,
    lat: -22.7571946
  },
  {
    lng: -43.562935,
    lat: -22.7570674
  },
  {
    lng: -43.5632566,
    lat: -22.7569995
  },
  {
    lng: -43.5633793,
    lat: -22.7569903
  },
  {
    lng: -43.5637163,
    lat: -22.7568801
  },
  {
    lng: -43.5637694,
    lat: -22.7568586
  },
  {
    lng: -43.563991,
    lat: -22.7568054
  },
  {
    lng: -43.5640848,
    lat: -22.7567928
  },
  {
    lng: -43.5642267,
    lat: -22.75674
  },
  {
    lng: -43.5645964,
    lat: -22.7566232
  },
  {
    lng: -43.5649813,
    lat: -22.756539
  },
  {
    lng: -43.5650652,
    lat: -22.7565239
  },
  {
    lng: -43.5651618,
    lat: -22.7564835
  },
  {
    lng: -43.5651773,
    lat: -22.756477
  },
  {
    lng: -43.5655266,
    lat: -22.756311
  },
  {
    lng: -43.5655822,
    lat: -22.7562869
  },
  {
    lng: -43.5656602,
    lat: -22.7562694
  },
  {
    lng: -43.5658551,
    lat: -22.7561774
  },
  {
    lng: -43.5660116,
    lat: -22.7561035
  },
  {
    lng: -43.566352,
    lat: -22.7559115
  },
  {
    lng: -43.5663911,
    lat: -22.7558906
  },
  {
    lng: -43.5667119,
    lat: -22.7557196
  },
  {
    lng: -43.5670494,
    lat: -22.7555055
  },
  {
    lng: -43.5673865,
    lat: -22.7553101
  },
  {
    lng: -43.5677029,
    lat: -22.7550877
  },
  {
    lng: -43.5680111,
    lat: -22.7549203
  },
  {
    lng: -43.5680502,
    lat: -22.7548991
  },
  {
    lng: -43.5682561,
    lat: -22.7547452
  },
  {
    lng: -43.5683212,
    lat: -22.7546903
  },
  {
    lng: -43.5684793,
    lat: -22.7545388
  },
  {
    lng: -43.5685264,
    lat: -22.7544799
  },
  {
    lng: -43.5686561,
    lat: -22.7541171
  },
  {
    lng: -43.5687637,
    lat: -22.7537507
  },
  {
    lng: -43.5688705,
    lat: -22.7533916
  },
  {
    lng: -43.5690164,
    lat: -22.7531142
  },
  {
    lng: -43.5690762,
    lat: -22.7530581
  },
  {
    lng: -43.5691437,
    lat: -22.7530148
  },
  {
    lng: -43.5693132,
    lat: -22.7528773
  },
  {
    lng: -43.5693649,
    lat: -22.7528511
  },
  {
    lng: -43.5694642,
    lat: -22.7528076
  },
  {
    lng: -43.5697049,
    lat: -22.7527489
  },
  {
    lng: -43.5697836,
    lat: -22.7527354
  },
  {
    lng: -43.5698685,
    lat: -22.7527461
  },
  {
    lng: -43.5699568,
    lat: -22.7527478
  },
  {
    lng: -43.5700596,
    lat: -22.7527668
  },
  {
    lng: -43.570086,
    lat: -22.7527718
  },
  {
    lng: -43.5701623,
    lat: -22.752794
  },
  {
    lng: -43.570236,
    lat: -22.7528177
  },
  {
    lng: -43.5703331,
    lat: -22.7528722
  },
  {
    lng: -43.5704316,
    lat: -22.7529209
  },
  {
    lng: -43.5704761,
    lat: -22.7529429
  },
  {
    lng: -43.5708117,
    lat: -22.7531386
  },
  {
    lng: -43.5711459,
    lat: -22.7533529
  },
  {
    lng: -43.5713174,
    lat: -22.7534288
  },
  {
    lng: -43.5713976,
    lat: -22.7534518
  },
  {
    lng: -43.5715503,
    lat: -22.7535562
  },
  {
    lng: -43.5716143,
    lat: -22.7535998
  },
  {
    lng: -43.5717242,
    lat: -22.7536706
  },
  {
    lng: -43.5718408,
    lat: -22.7537444
  },
  {
    lng: -43.5721904,
    lat: -22.7539132
  },
  {
    lng: -43.5722337,
    lat: -22.7539392
  },
  {
    lng: -43.5723244,
    lat: -22.7539966
  },
  {
    lng: -43.5726842,
    lat: -22.7541717
  },
  {
    lng: -43.5728121,
    lat: -22.754235
  },
  {
    lng: -43.5730482,
    lat: -22.7543748
  },
  {
    lng: -43.5731058,
    lat: -22.7543976
  },
  {
    lng: -43.573107,
    lat: -22.7543981
  },
  {
    lng: -43.5732013,
    lat: -22.7544294
  },
  {
    lng: -43.5735769,
    lat: -22.7545347
  },
  {
    lng: -43.5739616,
    lat: -22.7546062
  },
  {
    lng: -43.5740612,
    lat: -22.7546268
  },
  {
    lng: -43.5740688,
    lat: -22.7546284
  },
  {
    lng: -43.5741642,
    lat: -22.7546511
  },
  {
    lng: -43.5745542,
    lat: -22.7547421
  },
  {
    lng: -43.5749522,
    lat: -22.7548303
  },
  {
    lng: -43.5753371,
    lat: -22.754917
  },
  {
    lng: -43.5757321,
    lat: -22.7549757
  },
  {
    lng: -43.5759134,
    lat: -22.7549791
  },
  {
    lng: -43.5759963,
    lat: -22.7549684
  },
  {
    lng: -43.5760481,
    lat: -22.7549658
  },
  {
    lng: -43.576087,
    lat: -22.7549637
  },
  {
    lng: -43.5761346,
    lat: -22.7549489
  },
  {
    lng: -43.5761562,
    lat: -22.7549422
  },
  {
    lng: -43.5764178,
    lat: -22.7548817
  },
  {
    lng: -43.5765464,
    lat: -22.7548519
  },
  {
    lng: -43.5769326,
    lat: -22.75477
  },
  {
    lng: -43.5773197,
    lat: -22.754663
  },
  {
    lng: -43.5777137,
    lat: -22.7546091
  },
  {
    lng: -43.5780939,
    lat: -22.7545075
  },
  {
    lng: -43.5784897,
    lat: -22.7544189
  },
  {
    lng: -43.5788728,
    lat: -22.754315
  },
  {
    lng: -43.5792486,
    lat: -22.7541901
  },
  {
    lng: -43.5794097,
    lat: -22.7541686
  },
  {
    lng: -43.579562,
    lat: -22.754151
  },
  {
    lng: -43.5796154,
    lat: -22.7541436
  },
  {
    lng: -43.5798583,
    lat: -22.7540884
  },
  {
    lng: -43.5799676,
    lat: -22.7540696
  },
  {
    lng: -43.5801579,
    lat: -22.7540385
  },
  {
    lng: -43.5801556,
    lat: -22.7540421
  },
  {
    lng: -43.5803634,
    lat: -22.7540201
  },
  {
    lng: -43.5808785,
    lat: -22.7540344
  },
  {
    lng: -43.5810097,
    lat: -22.7540575
  },
  {
    lng: -43.5811241,
    lat: -22.7540608
  },
  {
    lng: -43.5812064,
    lat: -22.7540684
  },
  {
    lng: -43.5815977,
    lat: -22.7540901
  },
  {
    lng: -43.5819898,
    lat: -22.7541289
  },
  {
    lng: -43.5823866,
    lat: -22.7541679
  },
  {
    lng: -43.5827766,
    lat: -22.7542127
  },
  {
    lng: -43.5831633,
    lat: -22.7542727
  },
  {
    lng: -43.583553,
    lat: -22.7542899
  },
  {
    lng: -43.5836209,
    lat: -22.7542915
  },
  {
    lng: -43.5839579,
    lat: -22.7542997
  },
  {
    lng: -43.5841306,
    lat: -22.7543037
  },
  {
    lng: -43.5841655,
    lat: -22.7542896
  },
  {
    lng: -43.5842042,
    lat: -22.7542688
  },
  {
    lng: -43.5842816,
    lat: -22.7542543
  },
  {
    lng: -43.5843397,
    lat: -22.7542473
  },
  {
    lng: -43.5847385,
    lat: -22.7541935
  },
  {
    lng: -43.5851332,
    lat: -22.7541462
  },
  {
    lng: -43.5852593,
    lat: -22.754129
  },
  {
    lng: -43.5853792,
    lat: -22.7541188
  },
  {
    lng: -43.5855301,
    lat: -22.7540992
  },
  {
    lng: -43.5856197,
    lat: -22.7540912
  },
  {
    lng: -43.5856626,
    lat: -22.754092
  },
  {
    lng: -43.5857247,
    lat: -22.7540932
  },
  {
    lng: -43.5857728,
    lat: -22.7540828
  },
  {
    lng: -43.5858661,
    lat: -22.7540975
  },
  {
    lng: -43.586265,
    lat: -22.7541395
  },
  {
    lng: -43.5866537,
    lat: -22.7542025
  },
  {
    lng: -43.5870618,
    lat: -22.754266
  },
  {
    lng: -43.5874226,
    lat: -22.7543057
  },
  {
    lng: -43.5875143,
    lat: -22.7543141
  },
  {
    lng: -43.5878607,
    lat: -22.7543697
  },
  {
    lng: -43.5881517,
    lat: -22.7543983
  },
  {
    lng: -43.5882534,
    lat: -22.7544083
  },
  {
    lng: -43.5886623,
    lat: -22.7544208
  },
  {
    lng: -43.5890557,
    lat: -22.7544139
  },
  {
    lng: -43.5890592,
    lat: -22.7544136
  },
  {
    lng: -43.5894442,
    lat: -22.7543774
  },
  {
    lng: -43.5898516,
    lat: -22.7543601
  },
  {
    lng: -43.5902478,
    lat: -22.7543523
  },
  {
    lng: -43.5906443,
    lat: -22.754365
  },
  {
    lng: -43.5907986,
    lat: -22.7543901
  },
  {
    lng: -43.5908427,
    lat: -22.7543822
  },
  {
    lng: -43.5909223,
    lat: -22.7543921
  },
  {
    lng: -43.5910706,
    lat: -22.7544105
  },
  {
    lng: -43.5911507,
    lat: -22.7544299
  },
  {
    lng: -43.5912224,
    lat: -22.7544423
  },
  {
    lng: -43.5913548,
    lat: -22.7544516
  },
  {
    lng: -43.5914369,
    lat: -22.7544658
  },
  {
    lng: -43.5915646,
    lat: -22.7544927
  },
  {
    lng: -43.5916854,
    lat: -22.7545292
  },
  {
    lng: -43.5918462,
    lat: -22.7545358
  },
  {
    lng: -43.5922509,
    lat: -22.7545287
  },
  {
    lng: -43.59243,
    lat: -22.7544759
  },
  {
    lng: -43.592478,
    lat: -22.7544678
  },
  {
    lng: -43.5928632,
    lat: -22.7544045
  },
  {
    lng: -43.5932418,
    lat: -22.7543072
  },
  {
    lng: -43.5936307,
    lat: -22.7542338
  },
  {
    lng: -43.5940138,
    lat: -22.7541339
  },
  {
    lng: -43.5944069,
    lat: -22.7540401
  },
  {
    lng: -43.5947848,
    lat: -22.7539511
  },
  {
    lng: -43.5951632,
    lat: -22.7538532
  },
  {
    lng: -43.5955471,
    lat: -22.7537661
  },
  {
    lng: -43.5956276,
    lat: -22.7537574
  },
  {
    lng: -43.5957077,
    lat: -22.7537507
  },
  {
    lng: -43.5960836,
    lat: -22.7536399
  },
  {
    lng: -43.5963142,
    lat: -22.7535791
  },
  {
    lng: -43.5963641,
    lat: -22.7535747
  },
  {
    lng: -43.5965261,
    lat: -22.7534855
  },
  {
    lng: -43.5965705,
    lat: -22.7534562
  },
  {
    lng: -43.5968408,
    lat: -22.7533246
  },
  {
    lng: -43.5968896,
    lat: -22.753287
  },
  {
    lng: -43.5971061,
    lat: -22.7532031
  },
  {
    lng: -43.597154,
    lat: -22.7531871
  },
  {
    lng: -43.5972232,
    lat: -22.7531592
  },
  {
    lng: -43.59757,
    lat: -22.752993
  },
  {
    lng: -43.5979009,
    lat: -22.7528015
  },
  {
    lng: -43.5979402,
    lat: -22.7527875
  },
  {
    lng: -43.5981053,
    lat: -22.7526855
  },
  {
    lng: -43.5981772,
    lat: -22.7526664
  },
  {
    lng: -43.5982353,
    lat: -22.7526354
  },
  {
    lng: -43.5982939,
    lat: -22.7526014
  },
  {
    lng: -43.5984778,
    lat: -22.7524892
  },
  {
    lng: -43.598506,
    lat: -22.7524651
  },
  {
    lng: -43.5986294,
    lat: -22.7523594
  },
  {
    lng: -43.5986356,
    lat: -22.7523541
  },
  {
    lng: -43.5986835,
    lat: -22.752313
  },
  {
    lng: -43.5987238,
    lat: -22.752276
  },
  {
    lng: -43.5988918,
    lat: -22.7520916
  },
  {
    lng: -43.5989422,
    lat: -22.7520248
  },
  {
    lng: -43.5990719,
    lat: -22.7518221
  },
  {
    lng: -43.5991088,
    lat: -22.7517525
  },
  {
    lng: -43.5992412,
    lat: -22.7514254
  },
  {
    lng: -43.5992671,
    lat: -22.7513827
  },
  {
    lng: -43.5993992,
    lat: -22.7511386
  },
  {
    lng: -43.5994345,
    lat: -22.7510696
  },
  {
    lng: -43.5995886,
    lat: -22.7507222
  },
  {
    lng: -43.5996974,
    lat: -22.7504544
  },
  {
    lng: -43.5997305,
    lat: -22.750373
  },
  {
    lng: -43.5998058,
    lat: -22.7501933
  },
  {
    lng: -43.5999096,
    lat: -22.7500079
  },
  {
    lng: -43.6000042,
    lat: -22.7497501
  },
  {
    lng: -43.600174,
    lat: -22.7493205
  },
  {
    lng: -43.6003051,
    lat: -22.7490342
  },
  {
    lng: -43.6004023,
    lat: -22.7488702
  },
  {
    lng: -43.6004897,
    lat: -22.7487361
  },
  {
    lng: -43.6006097,
    lat: -22.7485991
  },
  {
    lng: -43.6007557,
    lat: -22.7484712
  },
  {
    lng: -43.6009001,
    lat: -22.7483821
  },
  {
    lng: -43.6010737,
    lat: -22.7482962
  },
  {
    lng: -43.6012876,
    lat: -22.748233
  },
  {
    lng: -43.6012927,
    lat: -22.7482314
  },
  {
    lng: -43.6012966,
    lat: -22.7482308
  },
  {
    lng: -43.601541,
    lat: -22.7481967
  },
  {
    lng: -43.6018475,
    lat: -22.7481204
  },
  {
    lng: -43.6023048,
    lat: -22.7480328
  },
  {
    lng: -43.6027619,
    lat: -22.7479602
  },
  {
    lng: -43.6031004,
    lat: -22.7478898
  },
  {
    lng: -43.6033386,
    lat: -22.7478551
  },
  {
    lng: -43.6035508,
    lat: -22.7478352
  },
  {
    lng: -43.6039573,
    lat: -22.7477774
  },
  {
    lng: -43.6040048,
    lat: -22.7477664
  },
  {
    lng: -43.6043264,
    lat: -22.7476925
  },
  {
    lng: -43.6047148,
    lat: -22.7476316
  },
  {
    lng: -43.6052794,
    lat: -22.7475328
  },
  {
    lng: -43.6059116,
    lat: -22.7474165
  },
  {
    lng: -43.6061751,
    lat: -22.747385
  },
  {
    lng: -43.6063431,
    lat: -22.747356
  },
  {
    lng: -43.6064886,
    lat: -22.7473119
  },
  {
    lng: -43.6066388,
    lat: -22.7472858
  },
  {
    lng: -43.6069328,
    lat: -22.7472425
  },
  {
    lng: -43.6072299,
    lat: -22.7471933
  },
  {
    lng: -43.6075754,
    lat: -22.7471473
  },
  {
    lng: -43.6077868,
    lat: -22.7470827
  },
  {
    lng: -43.607974,
    lat: -22.7470717
  },
  {
    lng: -43.6082208,
    lat: -22.747073
  },
  {
    lng: -43.608474,
    lat: -22.7470742
  },
  {
    lng: -43.6086449,
    lat: -22.7470811
  },
  {
    lng: -43.6089398,
    lat: -22.7471451
  },
  {
    lng: -43.6092684,
    lat: -22.7472272
  },
  {
    lng: -43.6097594,
    lat: -22.7473756
  },
  {
    lng: -43.6102421,
    lat: -22.7474762
  },
  {
    lng: -43.6106731,
    lat: -22.7476093
  },
  {
    lng: -43.6108804,
    lat: -22.7476847
  },
  {
    lng: -43.6112546,
    lat: -22.7478739
  },
  {
    lng: -43.6115339,
    lat: -22.7480537
  },
  {
    lng: -43.6118596,
    lat: -22.7482187
  },
  {
    lng: -43.6121804,
    lat: -22.7483985
  },
  {
    lng: -43.6129512,
    lat: -22.7488179
  },
  {
    lng: -43.6132972,
    lat: -22.7489886
  },
  {
    lng: -43.6134156,
    lat: -22.7490663
  },
  {
    lng: -43.6134764,
    lat: -22.749114
  },
  {
    lng: -43.61355,
    lat: -22.7491559
  },
  {
    lng: -43.6136029,
    lat: -22.7491561
  },
  {
    lng: -43.6136685,
    lat: -22.7491801
  },
  {
    lng: -43.6137085,
    lat: -22.7492011
  },
  {
    lng: -43.6137501,
    lat: -22.7492398
  },
  {
    lng: -43.6137805,
    lat: -22.7492667
  },
  {
    lng: -43.6138717,
    lat: -22.7493145
  },
  {
    lng: -43.6139469,
    lat: -22.7493267
  },
  {
    lng: -43.6140712,
    lat: -22.7493196
  },
  {
    lng: -43.6143462,
    lat: -22.7496962
  },
  {
    lng: -43.6144887,
    lat: -22.7498996
  },
  {
    lng: -43.6145531,
    lat: -22.7500037
  },
  {
    lng: -43.6146365,
    lat: -22.7501232
  },
  {
    lng: -43.6148083,
    lat: -22.7504574
  },
  {
    lng: -43.6149319,
    lat: -22.7508093
  },
  {
    lng: -43.6149769,
    lat: -22.7511714
  },
  {
    lng: -43.6149233,
    lat: -22.7515379
  },
  {
    lng: -43.6149602,
    lat: -22.7519023
  },
  {
    lng: -43.6149798,
    lat: -22.7522847
  },
  {
    lng: -43.6149847,
    lat: -22.7526643
  },
  {
    lng: -43.6150046,
    lat: -22.7530382
  },
  {
    lng: -43.6150413,
    lat: -22.7534016
  },
  {
    lng: -43.6150594,
    lat: -22.7537725
  },
  {
    lng: -43.6150793,
    lat: -22.7541498
  },
  {
    lng: -43.6151206,
    lat: -22.7545309
  },
  {
    lng: -43.6151609,
    lat: -22.7548923
  },
  {
    lng: -43.6151753,
    lat: -22.7552607
  },
  {
    lng: -43.6151601,
    lat: -22.7556257
  },
  {
    lng: -43.6151725,
    lat: -22.7557397
  },
  {
    lng: -43.6152013,
    lat: -22.7560048
  },
  {
    lng: -43.6152275,
    lat: -22.7563702
  },
  {
    lng: -43.615232,
    lat: -22.7567402
  },
  {
    lng: -43.6152901,
    lat: -22.7571114
  },
  {
    lng: -43.6152962,
    lat: -22.7574766
  },
  {
    lng: -43.6152989,
    lat: -22.7575427
  },
  {
    lng: -43.615311,
    lat: -22.7578381
  },
  {
    lng: -43.6153743,
    lat: -22.7582
  },
  {
    lng: -43.6154179,
    lat: -22.7585688
  },
  {
    lng: -43.6154482,
    lat: -22.7589381
  },
  {
    lng: -43.6154645,
    lat: -22.7590516
  },
  {
    lng: -43.6155031,
    lat: -22.7593197
  },
  {
    lng: -43.6155771,
    lat: -22.7596954
  },
  {
    lng: -43.615662,
    lat: -22.760055
  },
  {
    lng: -43.6156739,
    lat: -22.7601431
  },
  {
    lng: -43.6156831,
    lat: -22.7602111
  },
  {
    lng: -43.6157106,
    lat: -22.7604151
  },
  {
    lng: -43.6157552,
    lat: -22.760781
  },
  {
    lng: -43.6157348,
    lat: -22.7611467
  },
  {
    lng: -43.6157717,
    lat: -22.7613861
  },
  {
    lng: -43.6157914,
    lat: -22.7615138
  },
  {
    lng: -43.6159663,
    lat: -22.7618398
  },
  {
    lng: -43.6161824,
    lat: -22.7621409
  },
  {
    lng: -43.6163648,
    lat: -22.7624646
  },
  {
    lng: -43.6165854,
    lat: -22.7627891
  },
  {
    lng: -43.6167858,
    lat: -22.7631079
  },
  {
    lng: -43.6169869,
    lat: -22.7634354
  },
  {
    lng: -43.6172171,
    lat: -22.7637398
  },
  {
    lng: -43.6174669,
    lat: -22.7640265
  },
  {
    lng: -43.6177441,
    lat: -22.7642821
  },
  {
    lng: -43.6180339,
    lat: -22.764528
  },
  {
    lng: -43.618329,
    lat: -22.7647664
  },
  {
    lng: -43.6186155,
    lat: -22.7650269
  },
  {
    lng: -43.6189009,
    lat: -22.7652737
  },
  {
    lng: -43.6191673,
    lat: -22.7655451
  },
  {
    lng: -43.6194621,
    lat: -22.7658028
  },
  {
    lng: -43.619752,
    lat: -22.7660595
  },
  {
    lng: -43.6200121,
    lat: -22.7663318
  },
  {
    lng: -43.6202816,
    lat: -22.7665956
  },
  {
    lng: -43.6205649,
    lat: -22.7668533
  },
  {
    lng: -43.6208444,
    lat: -22.7671151
  },
  {
    lng: -43.6211293,
    lat: -22.7673759
  },
  {
    lng: -43.6213865,
    lat: -22.767658
  },
  {
    lng: -43.6216696,
    lat: -22.7679231
  },
  {
    lng: -43.6219476,
    lat: -22.7681857
  },
  {
    lng: -43.6222351,
    lat: -22.7684408
  },
  {
    lng: -43.6224989,
    lat: -22.7687236
  },
  {
    lng: -43.6227641,
    lat: -22.7689936
  },
  {
    lng: -43.6230295,
    lat: -22.7692761
  },
  {
    lng: -43.6232161,
    lat: -22.7694439
  },
  {
    lng: -43.6233138,
    lat: -22.7695316
  },
  {
    lng: -43.6235801,
    lat: -22.7697961
  },
  {
    lng: -43.623855,
    lat: -22.7700746
  },
  {
    lng: -43.6241281,
    lat: -22.7703467
  },
  {
    lng: -43.6244203,
    lat: -22.7705933
  },
  {
    lng: -43.624757,
    lat: -22.7707759
  },
  {
    lng: -43.625142,
    lat: -22.7709154
  },
  {
    lng: -43.6255026,
    lat: -22.7710698
  },
  {
    lng: -43.6258518,
    lat: -22.7712354
  },
  {
    lng: -43.6261854,
    lat: -22.7714393
  },
  {
    lng: -43.6264838,
    lat: -22.7716748
  },
  {
    lng: -43.6267672,
    lat: -22.7719303
  },
  {
    lng: -43.6270423,
    lat: -22.7721886
  },
  {
    lng: -43.6272477,
    lat: -22.7724984
  },
  {
    lng: -43.6273303,
    lat: -22.7728537
  },
  {
    lng: -43.6272327,
    lat: -22.7732066
  },
  {
    lng: -43.6271398,
    lat: -22.7735595
  },
  {
    lng: -43.6270424,
    lat: -22.7739174
  },
  {
    lng: -43.626869,
    lat: -22.7742645
  },
  {
    lng: -43.6268455,
    lat: -22.7743475
  },
  {
    lng: -43.626769,
    lat: -22.7746185
  },
  {
    lng: -43.6266274,
    lat: -22.7749578
  },
  {
    lng: -43.6264462,
    lat: -22.7752866
  },
  {
    lng: -43.6262899,
    lat: -22.7756227
  },
  {
    lng: -43.6260995,
    lat: -22.7759519
  },
  {
    lng: -43.6259659,
    lat: -22.7763046
  },
  {
    lng: -43.6258264,
    lat: -22.7766718
  },
  {
    lng: -43.625645,
    lat: -22.7769954
  },
  {
    lng: -43.6255142,
    lat: -22.7773446
  },
  {
    lng: -43.6253746,
    lat: -22.7776853
  },
  {
    lng: -43.625208,
    lat: -22.7780161
  },
  {
    lng: -43.6250277,
    lat: -22.7783586
  },
  {
    lng: -43.6248774,
    lat: -22.7786973
  },
  {
    lng: -43.6247084,
    lat: -22.7790485
  },
  {
    lng: -43.6245503,
    lat: -22.7793921
  },
  {
    lng: -43.62441,
    lat: -22.7797392
  },
  {
    lng: -43.6242509,
    lat: -22.7800822
  },
  {
    lng: -43.6241233,
    lat: -22.7804371
  },
  {
    lng: -43.6240166,
    lat: -22.7807882
  },
  {
    lng: -43.6239881,
    lat: -22.7810161
  },
  {
    lng: -43.6239578,
    lat: -22.7812582
  },
  {
    lng: -43.6238933,
    lat: -22.7816279
  },
  {
    lng: -43.6238451,
    lat: -22.7820091
  },
  {
    lng: -43.6237975,
    lat: -22.7823989
  },
  {
    lng: -43.6237834,
    lat: -22.7824711
  },
  {
    lng: -43.6240471,
    lat: -22.7824962
  },
  {
    lng: -43.6273681,
    lat: -22.7828172
  },
  {
    lng: -43.6294847,
    lat: -22.7829759
  },
  {
    lng: -43.632909,
    lat: -22.7831659
  },
  {
    lng: -43.634188,
    lat: -22.7832643
  },
  {
    lng: -43.6346266,
    lat: -22.783298
  },
  {
    lng: -43.634798,
    lat: -22.7833112
  },
  {
    lng: -43.6347309,
    lat: -22.784859
  },
  {
    lng: -43.6347108,
    lat: -22.7853219
  },
  {
    lng: -43.6346845,
    lat: -22.7862537
  },
  {
    lng: -43.6346543,
    lat: -22.7865433
  },
  {
    lng: -43.6330299,
    lat: -22.7915757
  },
  {
    lng: -43.6317916,
    lat: -22.7955799
  },
  {
    lng: -43.6312726,
    lat: -22.7972581
  },
  {
    lng: -43.6310581,
    lat: -22.7979768
  },
  {
    lng: -43.6312995,
    lat: -22.799567
  },
  {
    lng: -43.6312709,
    lat: -22.799943
  },
  {
    lng: -43.6311744,
    lat: -22.8012122
  },
  {
    lng: -43.6304353,
    lat: -22.8026505
  },
  {
    lng: -43.6303353,
    lat: -22.8035904
  },
  {
    lng: -43.6298585,
    lat: -22.8041998
  },
  {
    lng: -43.6288936,
    lat: -22.8053563
  },
  {
    lng: -43.628628,
    lat: -22.8056746
  },
  {
    lng: -43.627707,
    lat: -22.8061703
  },
  {
    lng: -43.6276169,
    lat: -22.8062716
  },
  {
    lng: -43.6269517,
    lat: -22.8070198
  },
  {
    lng: -43.6269527,
    lat: -22.8072991
  },
  {
    lng: -43.626954,
    lat: -22.8076567
  },
  {
    lng: -43.6269553,
    lat: -22.8080143
  },
  {
    lng: -43.6276048,
    lat: -22.8087965
  },
  {
    lng: -43.6276373,
    lat: -22.8088356
  },
  {
    lng: -43.6276396,
    lat: -22.8088384
  },
  {
    lng: -43.6289877,
    lat: -22.8098067
  },
  {
    lng: -43.6292407,
    lat: -22.8103125
  },
  {
    lng: -43.629177,
    lat: -22.810703
  },
  {
    lng: -43.629154,
    lat: -22.8107267
  },
  {
    lng: -43.6287742,
    lat: -22.8111195
  },
  {
    lng: -43.6286507,
    lat: -22.8115702
  },
  {
    lng: -43.62863,
    lat: -22.8116457
  },
  {
    lng: -43.6286142,
    lat: -22.8117032
  },
  {
    lng: -43.6286043,
    lat: -22.8117394
  },
  {
    lng: -43.6283792,
    lat: -22.8125607
  },
  {
    lng: -43.6275352,
    lat: -22.8136118
  },
  {
    lng: -43.6257188,
    lat: -22.8158471
  },
  {
    lng: -43.6251292,
    lat: -22.8168163
  },
  {
    lng: -43.624752,
    lat: -22.8183727
  },
  {
    lng: -43.6246944,
    lat: -22.81861
  },
  {
    lng: -43.6241629,
    lat: -22.8192102
  },
  {
    lng: -43.6228134,
    lat: -22.8200552
  },
  {
    lng: -43.6223542,
    lat: -22.8206728
  },
  {
    lng: -43.6220152,
    lat: -22.8219465
  },
  {
    lng: -43.6220834,
    lat: -22.8226446
  },
  {
    lng: -43.6222147,
    lat: -22.8229785
  },
  {
    lng: -43.6224383,
    lat: -22.8232426
  },
  {
    lng: -43.6224714,
    lat: -22.8234287
  },
  {
    lng: -43.6224727,
    lat: -22.8240865
  },
  {
    lng: -43.6224751,
    lat: -22.8253376
  },
  {
    lng: -43.6225822,
    lat: -22.8257347
  },
  {
    lng: -43.6226625,
    lat: -22.8260324
  },
  {
    lng: -43.6231103,
    lat: -22.8265756
  },
  {
    lng: -43.6246782,
    lat: -22.8274458
  },
  {
    lng: -43.6258103,
    lat: -22.8283963
  },
  {
    lng: -43.626513,
    lat: -22.8288055
  },
  {
    lng: -43.6273123,
    lat: -22.8286109
  },
  {
    lng: -43.6278365,
    lat: -22.8287498
  },
  {
    lng: -43.628701,
    lat: -22.829278
  },
  {
    lng: -43.6289411,
    lat: -22.8294317
  },
  {
    lng: -43.6298484,
    lat: -22.8299128
  },
  {
    lng: -43.630646,
    lat: -22.8317895
  },
  {
    lng: -43.6313029,
    lat: -22.833244
  },
  {
    lng: -43.6322647,
    lat: -22.8339947
  },
  {
    lng: -43.6325697,
    lat: -22.8343466
  },
  {
    lng: -43.6346575,
    lat: -22.8351676
  },
  {
    lng: -43.6358378,
    lat: -22.8361119
  },
  {
    lng: -43.6365343,
    lat: -22.836669
  },
  {
    lng: -43.6376838,
    lat: -22.837232
  },
  {
    lng: -43.6379653,
    lat: -22.8375136
  },
  {
    lng: -43.6382468,
    lat: -22.8379827
  },
  {
    lng: -43.6385987,
    lat: -22.8383581
  },
  {
    lng: -43.6404849,
    lat: -22.8397598
  },
  {
    lng: -43.6411557,
    lat: -22.8402583
  },
  {
    lng: -43.6430414,
    lat: -22.8415304
  },
  {
    lng: -43.6440907,
    lat: -22.8419501
  },
  {
    lng: -43.6445513,
    lat: -22.8421343
  },
  {
    lng: -43.6453494,
    lat: -22.8426089
  },
  {
    lng: -43.646622,
    lat: -22.8437736
  },
  {
    lng: -43.6480025,
    lat: -22.8448306
  },
  {
    lng: -43.6506125,
    lat: -22.8460601
  },
  {
    lng: -43.6516588,
    lat: -22.846697
  },
  {
    lng: -43.653093,
    lat: -22.8475699
  },
  {
    lng: -43.6547115,
    lat: -22.8484028
  },
  {
    lng: -43.6553147,
    lat: -22.8487132
  },
  {
    lng: -43.6567815,
    lat: -22.849274
  },
  {
    lng: -43.6586149,
    lat: -22.8493171
  },
  {
    lng: -43.660319,
    lat: -22.8497054
  },
  {
    lng: -43.6612368,
    lat: -22.8499313
  },
  {
    lng: -43.6631231,
    lat: -22.8503956
  },
  {
    lng: -43.6672214,
    lat: -22.8512584
  },
  {
    lng: -43.6686234,
    lat: -22.8517114
  },
  {
    lng: -43.670198,
    lat: -22.8523585
  },
  {
    lng: -43.6702582,
    lat: -22.8523859
  },
  {
    lng: -43.6702282,
    lat: -22.8528219
  },
  {
    lng: -43.6702553,
    lat: -22.8528916
  },
  {
    lng: -43.6702596,
    lat: -22.8529538
  },
  {
    lng: -43.6703223,
    lat: -22.853083
  },
  {
    lng: -43.6704492,
    lat: -22.8532558
  },
  {
    lng: -43.6704583,
    lat: -22.8532968
  },
  {
    lng: -43.6704783,
    lat: -22.8533859
  },
  {
    lng: -43.6704846,
    lat: -22.8534188
  },
  {
    lng: -43.6705016,
    lat: -22.8535068
  },
  {
    lng: -43.6706141,
    lat: -22.8538543
  },
  {
    lng: -43.6707617,
    lat: -22.854195
  },
  {
    lng: -43.6709318,
    lat: -22.8545328
  },
  {
    lng: -43.6710279,
    lat: -22.8548831
  },
  {
    lng: -43.6711426,
    lat: -22.855232
  },
  {
    lng: -43.6713011,
    lat: -22.855567
  },
  {
    lng: -43.6714407,
    lat: -22.8559107
  },
  {
    lng: -43.6715337,
    lat: -22.8562648
  },
  {
    lng: -43.6716222,
    lat: -22.8566175
  },
  {
    lng: -43.6717842,
    lat: -22.8569588
  },
  {
    lng: -43.6718548,
    lat: -22.8570549
  },
  {
    lng: -43.6718359,
    lat: -22.8571989
  },
  {
    lng: -43.671873,
    lat: -22.8575631
  },
  {
    lng: -43.6718972,
    lat: -22.8576486
  },
  {
    lng: -43.6720007,
    lat: -22.857872
  },
  {
    lng: -43.6720518,
    lat: -22.8579823
  },
  {
    lng: -43.6722506,
    lat: -22.8583029
  },
  {
    lng: -43.6724112,
    lat: -22.8586345
  },
  {
    lng: -43.6724853,
    lat: -22.8588227
  },
  {
    lng: -43.6725461,
    lat: -22.8589772
  },
  {
    lng: -43.6727016,
    lat: -22.8593087
  },
  {
    lng: -43.6728263,
    lat: -22.85966
  },
  {
    lng: -43.6729215,
    lat: -22.8600199
  },
  {
    lng: -43.6730087,
    lat: -22.8603727
  },
  {
    lng: -43.6730984,
    lat: -22.8607263
  },
  {
    lng: -43.6731124,
    lat: -22.8608083
  },
  {
    lng: -43.67316,
    lat: -22.8610881
  },
  {
    lng: -43.6732155,
    lat: -22.8614578
  },
  {
    lng: -43.6733109,
    lat: -22.8618165
  },
  {
    lng: -43.6734197,
    lat: -22.8621827
  },
  {
    lng: -43.6735011,
    lat: -22.8624792
  },
  {
    lng: -43.6736089,
    lat: -22.8628396
  },
  {
    lng: -43.6737059,
    lat: -22.8631798
  },
  {
    lng: -43.6737373,
    lat: -22.8633154
  },
  {
    lng: -43.6737687,
    lat: -22.863451
  },
  {
    lng: -43.6737671,
    lat: -22.8636086
  },
  {
    lng: -43.6737268,
    lat: -22.8637254
  },
  {
    lng: -43.6736662,
    lat: -22.863861
  },
  {
    lng: -43.6734805,
    lat: -22.8640462
  },

];

export default polygonCoords_NovaIguacu;