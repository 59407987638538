import React, { Component } from 'react';
import api from '../services/api';
import apiString from '../services/apiString';
import apiStringServer from '../services/apiStringServer';

import Header from '../components/Header';
import FooterHome from '../components/FooterHome';
import Table from '../components/Table';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import House from '@material-ui/icons/House';
import Check from '@material-ui/icons/Check';
import Report from '@material-ui/icons/Report';
import DoNotDisturb from '@material-ui/icons/NotInterestedOutlined';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Button from '@material-ui/core/Button';
import MapIcon from '@material-ui/icons/Map';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Assignment from '@material-ui/icons/Assignment';
import AssignmentTurnedIn from '@material-ui/icons/AssignmentTurnedIn';

import { Map, GoogleApiWrapper, Marker, InfoWindow, Polygon } from 'google-maps-react';

import yellow_marker from '../assets/yellow_marker.png';
import green_marker from '../assets/green_marker.png';
import red_marker from '../assets/red_marker.png';

//import map_Rio from '../assets/citities/map_Rio.png';

import polygonCoords_TresRios from './utils/polygonTresRios';

export class HomeTresRios extends Component {
  
  state = {
    login: '',
    option: '',
    selected_city: this.props.location.state.fields.selected_city,

    zoom: 12,
    latitude: '',
    longitude: '',
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},

    places: [],

    cpf_cnpj: '',
    name: '',
    email: '',
    street: '',
    number: '',
    district: '',
    city: '',
    state: '',

    new_lat: '',
    new_lng: '',

    expanded: false,
    openDialog: false,

    renderTable: false,

    url: null,

    search: '',

    polygonCoords_RioGeral: [],
  }

  async componentDidMount() {

    if (this.props.location.state) {
      await this.setState({ login: this.props.location.state.fields.login });
      await this.setState({ option: this.props.location.state.fields.option });
      await this.setState({ selected_city: this.props.location.state.fields.selected_city });
    }

    /*navigator.geolocation.getCurrentPosition(async (position) => {
      this.setState({ status: null });
      await this.setState({ latitude: position.coords.latitude });
      await this.setState({ longitude: position.coords.longitude });
    }, () => {
      this.setState({ status: 'Impossível retornar a localização.' });
    });*/

    await this.setState({ latitude: -22.124758 });
    await this.setState({ longitude: -43.106627 });

    try {
      let resp = await api.get('/locations', {
        params: {
          selected_city: this.state.selected_city
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('name')) {
            
            this.setState({ places: resp.data });

          } else {
            let msg = JSON.stringify(resp.data);
            console.log(msg)
          }
        }
      }
    } catch (err) {
      console.error(err);
    }

  }

  handleLocations = async () => {
    try {
      let resp = await api.get('/locations', {
        params: {
          selected_city: this.state.selected_city
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('name')) {

            await this.setState({ places: resp.data });

          } else {
            await this.setState({ places: resp.data });
          }
        }
      }
    } catch (err) {
      console.error(err);
    }

  }

  handleRegular = async () => {
    try {
      let resp = await api.get('/regularlocations', {
        params: {
          selected_city: this.state.selected_city
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('name')) {

            await this.setState({ places: resp.data });

          } else {
            await this.setState({ places: resp.data });
          }
        }
      }
    } catch (err) {
      console.error(err);
    }

  }

  handleNegattive = async () => {
    try {
      let resp = await api.get('/negattivelocations', {
        params: {
          selected_city: this.state.selected_city
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('name')) {

            await this.setState({ places: resp.data });

          } else {
            await this.setState({ places: resp.data });
          }
        }
      }
    } catch (err) {
      console.error(err);
    }

  }

  handlePendence = async () => {
    try {
      let resp = await api.get('/pendencelocations', {
        params: {
          selected_city: this.state.selected_city
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('name')) {

            await this.setState({ places: resp.data });

          } else {
            await this.setState({ places: resp.data });
          }
        }
      }
    } catch (err) {
      console.error(err);
    }

  }

  handleChangePanel = (panel) => (event, isExpanded) => {
    this.setState({ expanded: isExpanded ? panel : false });
  };

  onMapClicked = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      });
    }
  };

  onMarkerClick = (props, marker) => {
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });

  }

  handleChange = async e => {
    await this.setState({ [e.target.name]: e.target.value });

    if (this.state.search.toUpperCase() === 'barra da tijuca'.toUpperCase()) {
      this.setState({ latitude: -22.99561048068859 }); //-22.99561048068859, -43.37167231281911
      this.setState({ longitude: -43.37167231281911 });
      this.setState({ zoom: 13 });
    } else {
      if (this.state.search.toUpperCase() === 'recreio'.toUpperCase()) {
        this.setState({ latitude: -23.013325217831405 }); //-23.013325217831405, -43.47353862101815
        this.setState({ longitude: -43.47353862101815 });
        this.setState({ zoom: 13 });
      } else {
        if (this.state.search.toUpperCase() === 'taquara'.toUpperCase()) {
          this.setState({ latitude: -22.92411592052944 }); //-22.92411592052944, -43.383641882969314
          this.setState({ longitude: -43.383641882969314 });
          this.setState({ zoom: 14 });
        } else {
          if (this.state.search.toUpperCase() === 'campo grande'.toUpperCase()) {
            this.setState({ latitude: -22.895650292382275 }); //-22.895650292382275, -43.5580595839595
            this.setState({ longitude: -43.5580595839595 });
            this.setState({ zoom: 12 });
          } else {
            if (this.state.search.toUpperCase() === 'méier'.toUpperCase()) {
              this.setState({ latitude: -22.902734628806304 }); //-22.902734628806304, -43.28060405942685
              this.setState({ longitude: -43.28060405942685 });
              this.setState({ zoom: 15 });
            } else {
              if (this.state.search.toUpperCase() === 'santa cruz'.toUpperCase()) {
                this.setState({ latitude: -22.915834986913296 }); //-22.915834986913296, -43.70211683723845
                this.setState({ longitude: -43.70211683723845 });
                this.setState({ zoom: 13 });
              } else {
                if (this.state.search.toUpperCase() === 'madureira'.toUpperCase()) {
                  this.setState({ latitude: -22.872239178670586 }); //-22.872239178670586, -43.33736988480521
                  this.setState({ longitude: -43.33736988480521 });
                  this.setState({ zoom: 15 });
                } else {
                  this.setState({ latitude: -22.931987594567158 });
                  this.setState({ longitude: -43.44911393720455 });
                  this.setState({ zoom: 11 });
                }
              }
            }
          }
        }
      }
    }
  }

  render() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={styles.html}>
          <Header history={this.props.history} city={this.state.selected_city} {...this.props}/>

          <div style={styles.directionRow}>

            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div>
                <TextField
                  style={styles.text}
                  id="search"
                  name="search"
                  value={this.state.search}
                  label="Pesquisar localidade"
                  onChange={this.handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                />
              </div>

              <div style={styles.accordionDiv}>
                <Accordion
                  expanded={this.state.expanded === 'panel1'}
                  onChange={this.handleChangePanel('panel1')}
                  style={styles.accordion}
                  onClick={this.handleLocations}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <House />
                    <Typography>
                      Painel geral
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div style={styles.directionColumn}>
                      <List
                        component="div"
                        disablePadding
                        style={styles.list}
                        onClick={() => {
                          this.setState({ renderTable: false });
                          this.componentDidMount();
                        }}
                      >
                        <ListItem button>
                          <ListItemIcon>
                            <MapIcon />
                          </ListItemIcon>
                          <ListItemText primary={`Mapa`} />
                        </ListItem>
                      </List>
                      <List
                        component="div"
                        disablePadding
                        style={styles.list}
                        onClick={() => {
                          this.setState({ renderTable: true });
                          this.componentDidMount();
                        }}
                      >
                        <ListItem button>
                          <ListItemIcon>
                            <ViewColumn />
                          </ListItemIcon>
                          <ListItemText primary={`Tabela de registros`} />
                        </ListItem>
                      </List>
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={this.state.expanded === 'panel2'}
                  onChange={this.handleChangePanel('panel2')}
                  style={styles.accordion}
                  onClick={() => { this.handleRegular(); }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Check />
                    <Typography>
                      Imóveis regularizados
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Aqui estarão todos os imóveis que já foram regularizados pelo sistema.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={this.state.expanded === 'panel3'}
                  onChange={this.handleChangePanel('panel3')}
                  style={styles.accordion}
                  onClick={() => { this.handlePendence(); }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                  >
                    <Report />
                    <Typography>
                      Regularizações com pendências
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Aqui estarão todos os imóveis que ainda estão em processo de validação ou pendentes.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={this.state.expanded === 'panel4'}
                  onChange={this.handleChangePanel('panel4')}
                  style={styles.accordion}
                  onClick={() => { this.handleNegattive(); }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4a-content"
                    id="panel4a-header"
                  >
                    <DoNotDisturb />
                    <Typography>
                      Regularizações negadas
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Aqui estarão todos os imóveis que tiveram o processo de regularização negado.
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  expanded={this.state.expanded === 'panel5'}
                  onChange={this.handleChangePanel('panel5')}
                  style={styles.accordion}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel5a-content"
                    id="panel5a-header"
                  >
                    <Assignment />
                    <Typography>
                      Formulários
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <Button
                        color="secondary"
                        style={styles.bt2}
                        startIcon={<Assignment />}
                      >
                        Decisão instauradora da REURB
                      </Button>

                      <Button
                        color="secondary"
                        style={styles.bt2}
                        startIcon={<Assignment />}
                      >
                        Decisão denegatória da instauração da REURB
                      </Button>

                      <Button
                        color="secondary"
                        style={styles.bt2}
                        startIcon={<Assignment />}
                      >
                        Autuação do procedimento
                      </Button>

                      <Button
                        color="secondary"
                        style={styles.bt2}
                        startIcon={<Assignment />}
                      >
                        Certificações comuns do procedimento administrativo
                      </Button>

                      <Button
                        color="secondary"
                        style={styles.bt2}
                        startIcon={<Assignment />}
                      >
                        Anuência expressa do notificado
                      </Button>

                      <Button
                        color="secondary"
                        style={styles.bt2}
                        startIcon={<Assignment />}
                      >
                        Impugnação expressa do notificado
                      </Button>
                    </div>

                  </AccordionDetails>
                </Accordion>

                <Accordion
                  expanded={this.state.expanded === 'panel6'}
                  onChange={this.handleChangePanel('panel6')}
                  style={styles.accordion}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel6a-content"
                    id="panel6a-header"
                  >
                    <AssignmentTurnedIn />
                    <Typography>
                      Modelos de documentação
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    
                    <div style={styles.examples}>
                      <a
                        href={`${apiStringServer}/docs/plano_diretor_estrutura_e_conteudo.pdf`}
                        style={styles.href}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Plano Diretor - Estrutura e conteúdo
                      </a>
                      <a
                        href={`${apiStringServer}/docs/lei_complementar_2011_plano_diretor.pdf`}
                        style={styles.href}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Plano Diretor - Lei complementar
                      </a>
                      <a
                        href={`${apiStringServer}/docs/mapa1_macrozoneamento.pdf`}
                        style={styles.href}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Mapa 1 - Macrozoneamento
                      </a>
                      <a
                        href={`${apiStringServer}/docs/mapa2_hipsometria_hidrografia_e_subbacias_hidrograficas.pdf`}
                        style={styles.href}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Mapa 2 - Hipsometria, Hidrografia e Sub-bacias hidrográficas
                      </a>
                      <a
                        href={`${apiStringServer}/docs/mapa3_areas_protegidas.pdf`}
                        style={styles.href}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Mapa 3 - Áreas protegidas
                      </a>
                      <a
                        href={`${apiStringServer}/docs/mapa4_regioes_administrativas.pdf`}
                        style={styles.href}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Mapa 4 - Regiões administrativas
                      </a>
                      <a
                        href={`${apiStringServer}/docs/mapa5_regioes_de_planejamento.pdf`}
                        style={styles.href}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Mapa 5 - Regiões de planejamento
                      </a>
                      <a
                        href={`${apiStringServer}/docs/mapa6_uso_e_cobertura_do_solo.pdf`}
                        style={styles.href}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Mapa 6 - Uso e cobertura do solo
                      </a>
                      <a
                        href={`${apiStringServer}/docs/mapa7_rede_estrutural_viaria.pdf`}
                        style={styles.href}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Mapa 7 - Rede estrutural viária
                      </a>
                      <a
                        href={`${apiStringServer}/docs/mapa8_limites_administrativos_rarp.pdf`}
                        style={styles.href}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Mapa 8 - Limites administrativos RARP
                      </a>
                      <a
                        href={`${apiStringServer}/docs/mapa9_areas_de_intervencao_e_macrozona.pdf`}
                        style={styles.href}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Mapa 9 - Áreas de intervenção Macrozona
                      </a>
                      <a
                        href={`${apiStringServer}/docs/mapa10_areas_intervencao.pdf`}
                        style={styles.href}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Mapa 10 - Áreas de intervenção
                      </a>
                      <a
                        href={`${apiStringServer}/docs/mapa11_vetores_de_crescimento.pdf`}
                        style={styles.href}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Mapa 11 - Vetores de crescimento
                      </a>
                      <a
                        href={`${apiStringServer}/docs/mapa12_meio_ambiente.pdf`}
                        style={styles.href}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Mapa 12 - Meio ambiente
                      </a>
                      <a
                        href={`${apiStringServer}/docs/mapa13_meio_ambiente_sitios.pdf`}
                        style={styles.href}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Mapa 13 - Meio ambiente (sítios)
                      </a>
                      <a
                        href={`${apiStringServer}/docs/mapa14_saneamento_ambiental.pdf`}
                        style={styles.href}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Mapa 14 - Saneamento ambiental
                      </a>
                      <a
                        href={`${apiStringServer}/docs/mapa15_habitacao.pdf`}
                        style={styles.href}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Mapa 15 - Habitação
                      </a>
                      <a
                        href={`${apiStringServer}/docs/mapa16_transportes.pdf`}
                        style={styles.href}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Mapa 16 - Transportes
                      </a>
                      <a
                        href={`${apiStringServer}/docs/relatorio_2009_antecedentes_e_diagnostico.pdf`}
                        style={styles.href}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Relatório 2009 - Antecedentes e Diagnóstico
                      </a>
                      <a
                        href={`${apiStringServer}/docs/relatorio_2009_proposta_de_politica_urbana_parte3.pdf`}
                        style={styles.href}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Relatório 2009 - Proposta de política urbana (parte 3)
                      </a>
                      <a
                        href={`${apiStringServer}/docs/importancia_do_plano_diretor_municipal.docx`}
                        style={styles.href}
                        target="_blank"
                        rel="noreferrer"
                      >
                        A iportância do Plano Diretor Municipal
                      </a>
                      <a
                        href={`${apiStringServer}/docs/atos_e_procedimentos_da_regularizacao_fundiaria.docx`}
                        style={styles.href}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Atos e procedimentos da regularização fundiária
                      </a>
                    </div>

                  </AccordionDetails>
                </Accordion>
              </div>
            </div>

            {this.state.renderTable ? (
              <div>
                <Table
                  history={this.props.history}
                  vars={this.state}
                  selected_city={this.state.selected_city}
                />
              </div>
            ) : (
                <div>
                  <Map
                    key={'AIzaSyDC6df7s7nceeG2sHhFGu0-nzLppDiHpe0'}
                    google={this.props.google}
                    zoom={this.state.zoom}
                    style={styles.map}
                    mapType="hybrid"
                    center={{
                      lat: this.state.latitude,
                      lng: this.state.longitude
                    }}
                    onClick={this.onMapClicked}
                  >


                    {<Polygon
                      paths={polygonCoords_TresRios}
                      strokeColor="#001D5F"
                      strokeOpacity={0.5}
                      strokeWeight={2}
                      fillColor="#001D5F"
                      fillOpacity={0.2} 
                    />}

                    {/*<Polygon
                      paths={polygonCoords_Barra_da_Tijuca}
                      strokeColor="#800000"
                      strokeOpacity={0.5}
                      strokeWeight={2}
                      fillColor="#fff"
                      fillOpacity={0.2}
                    />*/}

                    {this.state.places.map((item) =>
                      <Marker
                        icon={{
                          url: (item.active == 1) ? yellow_marker : (item.active == 2) ? green_marker : red_marker,
                          scaledSize: new window.google.maps.Size(32, 40)
                        }}
                        position={{
                          lat: item.latitude,
                          lng: item.longitude
                        }}
                        onClick={async (props, marker) => {
                          this.setState({ cpf_cnpj: item.cpf_cnpj });
                          this.setState({ name: item.name });
                          this.setState({ email: item.email });
                          this.setState({ street: item.street });
                          this.setState({ number: item.number });
                          this.setState({ district: item.district });
                          this.setState({ city: item.city });
                          this.setState({ state: item.state });
                          this.setState({ new_lat: item.latitude });
                          this.setState({ new_lng: item.longitude });
                          this.onMarkerClick(props, marker);
                        }}
                        name={item.name}
                      />
                    )}

                    <InfoWindow
                      marker={this.state.activeMarker}
                      visible={this.state.showingInfoWindow}
                    >

                      <a href={`${apiString}/details?cpf_cnpj=${this.state.cpf_cnpj}&lat=${this.state.new_lat}&lng=${this.state.new_lng}&selected_city=${this.state.selected_city}`}>
                        <Button
                          fullWidth
                          variant="text"
                          color="primary"
                          style={{ textTransform: 'none' }}
                        >
                          <Typography variant="h6" color="primary" align="left" style={{ maxWidth: 250 }}>
                            {`${this.state.name}`}
                          </Typography>
                        </Button>
                      </a>
                      <Typography variant="subtitle1" color="textSecondary" align="left" style={{ maxWidth: 250 }}>
                        {`${this.state.email}`}
                      </Typography>
                      <Typography variant="subtitle1" color="textSecondary" align="left" style={{ maxWidth: 250 }}>
                        {`${this.state.street}, ${this.state.number} - ${this.state.district}, ${this.state.city} - ${this.state.state}`}
                      </Typography>

                    </InfoWindow>

                  </Map>
                </div>
            ) }
            
          </div>

        </div>
        <FooterHome />
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDC6df7s7nceeG2sHhFGu0-nzLppDiHpe0',
  language: 'pt-BR'
})(HomeTresRios)

let styles = {
  html: {
    backgroundColor: '#454545',
    height: window.innerHeight + 700,
    width: window.innerWidth,
    margin: -10,
    paddingBottom: 50,
  },

  directionRow: { 
    display: 'flex', 
    flexDirection: 'row' 
  },

  directionColumn: { 
    display: 'flex', 
    flexDirection: 'column' 
  },

  text: {
    margin: 15,
    width: 335,
  },

  accordionDiv: {
    marginTop: 10,
  },

  accordion: {
    width: 350,
    backgroundColor: '#001D5F',
  },

  list: {
    backgroundColor: '#343434',
    width: 315,
  },

  map: {
    height: 600,
    width: window.innerWidth - (window.innerWidth / 3.5),
    marginLeft: 10,
    marginTop: 10,
    alignSelf: 'flex-start',
  },

  bt2: {
    margin: 10,
    width: '96.7%',
    textTransform: 'none',
    backgroundColor: '#343434',
    color: '#fff'
  },

  examples: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#454545',
    margin: 10,
    marginTop: 30,
  },

  href: {
    margin: 10,
    color: '#fff'
  },

};