import React, { Component } from 'react';
import api from '../services/api';
import apiString from '../services/apiString';
import apiCEP from '../services/apiCEP';

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import PersonAdd from '@material-ui/icons/PersonAdd';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';

import InputMask from 'react-input-mask';

import theme from '../themes/theme';
import { ThemeProvider } from '@material-ui/core';

import backgroundVideo from '../assets/background3.mp4';
import logo from '../assets/kreurb_logoIcon3.png';
import Footer from '../components/Footer';

let emailValidator = require('react-email-validator');

export default class Register extends Component {

  state = {
    name: '',
    email: '',
    option: 'cnpj',
    type: 'CNPJ',
    mask: '99.999.999/9999-99',
    login: '',
    typology: '',
    cep: '',
    cepMask: '99999-999',
    street: '',
    number: '',
    district: '',
    city: '',
    state: '',
    password: '',
    password2: '',
    phone: '',
    phoneMask: '(99) 99999-9999',
    city_selected: 'mendes',

    city_areas: [],
    selected_area: '',

    mailView: true,

    disabled: true,
    open: false,
    open_error: false,
    open_error2: false,
    msg: '',
  }

  async componentDidMount() {

    this.handleAdmCityAreas();

  }

  handleClose = async () => {
    window.location.reload();
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    const data = new FormData();

    if (!(20 >= this.state.password.length) || !(this.state.password.length >= 6)) {

      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      this.setState({ msg: 'A senha precisa ter de 6 a 20 caracteres.' });
      this.setState({ open_error: true });
      this.setState({ open: false });

    } else {

      if (!emailValidator.validate(this.state.email)) {

        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        this.setState({ msg: 'E-mail inválido.' });
        this.setState({ open_error: true });
        this.setState({ open: false });

      } else {

        data.append('name', this.state.name);
        data.append('email', this.state.email);
        data.append('option', this.state.option);
        data.append('login', this.state.login);
        data.append('password', this.state.password);
        data.append('typology', this.state.typology);
        data.append('cep', this.state.cep);
        data.append('street', this.state.street);
        data.append('number', this.state.number);
        data.append('district', this.state.district);
        data.append('city', this.state.city);
        data.append('state', this.state.state);
        data.append('phone', this.state.phone);

        data.append('city_selected', this.state.city_selected);
        data.append('selected_area', this.state.selected_area);

        try {
          let resp = await api.post('/user', data);
          if (resp != null) {
            if (resp.data) {
              if (JSON.stringify(resp.data).includes('registred')) {

                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
                this.setState({ msg: 'Cadastro realizado com sucesso.' });
                this.setState({ open_error: false });
                this.setState({ open: true });

              } else {
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
                this.setState({ msg: resp.data });
                this.setState({ open_error: true });
                this.setState({ open: false });
              }
            }
          }
        } catch (err) {
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
          this.setState({ msg: 'Não foi possível incluir este usuário ' + err });
          this.setState({ open_error: true });
          this.setState({ open: false });
        }

      }

    }

  }

  handleMask = async e => {
    await this.setState({ [e.target.name]: e.target.value });

    if (this.state.option == "cnpj") {
      this.setState({ mailView: true });
      this.setState({ mask: '99.999.999/9999-99' });
      this.setState({ type: 'CNPJ' });
    }
    if (this.state.option == "cpf") {
      this.setState({ mailView: true });
      this.setState({ mask: '999.999.999-99' });
      this.setState({ type: 'CPF' });
    }
    if (this.state.option == "email") {
      this.setState({ mailView: false });
    }
  }

  handleCEP = async () => {

    try{
      let resp = await apiCEP.get(`/ws/${this.state.cep}/json/`);
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('cep')) {
            this.setState({ street: resp.data.logradouro });
            this.setState({ district: resp.data.bairro });
            this.setState({ city: resp.data.localidade });
            this.setState({ state: resp.data.uf });
            this.setState({ open_error2: false });
          } else {
            this.setState({ msg: 'CEP não encontrado.' });
            this.setState({ open_error2: true });
            this.setState({ open: false });
          }
        }
      }
    } catch(err) {
      this.setState({ street: '' });
      this.setState({ number: '' });
      this.setState({ district: '' });
      this.setState({ city: '' });
      this.setState({ state: '' });
      this.setState({ msg: 'CEP não encontrado.' });
      this.setState({ open_error2: true });
      this.setState({ open: false });
    }

  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleChangeCity = async e => {
    await this.setState({ [e.target.name]: e.target.value });

    this.handleAdmCityAreas();
  }

  handleAdmCityAreas = async () => {

    if(this.state.option === 'email') {
      try {
        let resp = await api.get('/admcityareas', {
          params: {
            selected_city: this.state.city_selected
          }
        });
        if (resp != null) {
          if (resp.data) {
            if (JSON.stringify(resp.data).includes('name')) {
  
              this.setState({ city_areas: resp.data });
  
            } else {
              let msg = JSON.stringify(resp.data);
              alert(msg); 
            }
          }
        }
      } catch (err) {
        alert('Não foi possível buscar os dados das áreas para este município. ' + err);
      }
    }
  }

  render() {
    return (

      <div style={styles.html}>
        <video autoPlay muted loop id="myVideo" style={styles.video}>
          <source src={backgroundVideo} type="video/mp4" />
        </video>
        <ThemeProvider theme={theme}>
          <form style={styles.form} id="new_register" onSubmit={this.handleSubmit}>

            <Container component="main" maxWidth="xs">
              <CssBaseline />
              <div style={styles.paper}>
                <img src={logo} style={styles.avatar} />
                <Typography component="h1" variant="h5">
                  Cadastro
                </Typography>

                {this.state.open ? (
                  <Alert
                    severity="success"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          this.handleClose();
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    {this.state.msg}
                  </Alert>
                ) : null}

                {this.state.open_error ? (
                  <Alert
                    severity="error"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          this.setState({ open_error: false });
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    {this.state.msg}
                  </Alert>
                ) : null}

                <div style={styles.form}>
                  <Grid container spacing={2}>
                    <Typography variant="h6" style={{ marginLeft: 10 }}>
                      Dados cadastrais
                    </Typography>
                    <Grid item xs={12}>
                      <TextField
                        autoComplete="fname"
                        name="name"
                        variant="outlined"
                        required
                        fullWidth
                        id="name"
                        label="Nome"
                        autoFocus
                        InputProps={{ style: styles.input }}
                        onChange={this.handleChange}
                        value={this.state.name}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="email"
                        label="E-mail"
                        name="email"
                        autoComplete="email"
                        InputProps={{ style: styles.input }}
                        onChange={this.handleChange}
                        value={this.state.email}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl component="fieldset">
                        <FormLabel component="legend">Opção</FormLabel>
                        <RadioGroup
                          aria-label="option1"
                          name="option"
                          value={this.state.option}
                          onChange={this.handleMask}
                          style={{ flexDirection: 'row' }}
                        >
                          <FormControlLabel value="cnpj" control={<Radio />} label="Órgão/Analista" />
                          {/*<FormControlLabel value="cpf" control={<Radio />} label="Cidadão" />*/}
                          <FormControlLabel value="cpf" control={<Radio />} label="Analista" />
                          <FormControlLabel value="email" control={<Radio />} label="Sub-Analista" />
                        </RadioGroup>
                      </FormControl>

                      {this.state.mailView ? (
                        <InputMask
                          {...this.props.value}
                          mask={this.state.mask}
                          fullWidth
                          maskChar=" "
                          required
                          name="login"
                          id="login"
                          type="text"
                          onChange={this.handleChange}
                          value={this.state.login}
                        >
                          {(inputProps) =>
                            <TextField
                              {...inputProps}
                              variant="outlined"
                              margin="normal"
                              label={this.state.type}
                            />
                          }
                        </InputMask>
                      ) : null}
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        name="password"
                        label="Senha"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        InputProps={{ style: styles.input }}
                        onChange={this.handleChange}
                        value={this.state.password}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        name="password2"
                        label="Confirmação de senha"
                        type="password"
                        id="password2"
                        autoComplete="current-password"
                        InputProps={{ style: styles.input }}
                        onChange={this.handleChange}
                        value={this.state.password2}
                      />
                    </Grid>

                    <Typography variant="h6" style={{ marginLeft: 10 }}>
                      Informações
                    </Typography>

                    {/*{this.state.option == 'cpf' ? (

                      <Grid item xs={12}>

                        {this.state.open_error2 ? (
                          <Alert
                            severity="error"
                            style={{ marginBottom: 5 }}
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  this.setState({ open_error2: false });
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            {this.state.msg}
                          </Alert>
                        ) : null}

                        <InputMask
                          {...this.props.value}
                          mask={this.state.cepMask}
                          fullWidth
                          maskChar=" "
                          required
                          name="cep"
                          id="cep"
                          type="text"
                          onChange={this.handleChange}
                          onBlur={this.handleCEP}
                          value={this.state.cep}
                        >
                          {(inputProps) =>
                            <TextField
                              {...inputProps}
                              variant="outlined"
                              margin="normal"
                              label="CEP"
                              style={{ marginBottom: 15, marginTop: -2 }}
                            />
                          }
                        </InputMask>

                        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 15 }}>
                          <TextField
                            name="street"
                            variant="outlined"
                            required
                            fullWidth
                            id="street"
                            label="Rua"
                            InputProps={{ style: styles.input }}
                            onChange={this.handleChange}
                            value={this.state.street}
                          />
                          <Typography component="h1" variant="h5" style={{ marginTop: 10 }}>
                            -
                          </Typography>
                          <TextField
                            name="number"
                            variant="outlined"
                            required
                            id="number"
                            label="Número"
                            InputProps={{ style: styles.input }}
                            onChange={this.handleChange}
                            value={this.state.number}
                            style={{ width: 150 }}
                          />
                        </div>

                        <TextField
                          name="district"
                          variant="outlined"
                          required
                          fullWidth
                          id="district"
                          label="Bairro"
                          InputProps={{ style: styles.input }}
                          onChange={this.handleChange}
                          value={this.state.district}
                          style={{ marginBottom: 15 }}
                        />

                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <TextField
                            name="city"
                            variant="outlined"
                            required
                            fullWidth
                            id="city"
                            label="Cidade"
                            InputProps={{ style: styles.input }}
                            onChange={this.handleChange}
                            value={this.state.city}
                          />
                          <Typography component="h1" variant="h5" style={{ marginTop: 10 }}>
                            -
                          </Typography>
                          <TextField
                            name="state"
                            variant="outlined"
                            required
                            id="state"
                            label="Estado"
                            InputProps={{ style: styles.input }}
                            onChange={this.handleChange}
                            value={this.state.state}
                            style={{ width: 150 }}
                          />
                        </div>
                      </Grid>*/}

                    {/*) : (*/}
                      <Grid item xs={12}>

                        <FormControl variant="outlined" fullWidth style={{ marginBottom: 15 }}>
                          <InputLabel htmlFor="outlined-city_selected-native-simple">
                            Município *
                          </InputLabel>
                          <Select
                            native
                            required
                            value={this.state.city_selected}
                            onChange={this.handleChangeCity}
                            label="Município"
                            inputProps={{
                              name: 'city_selected',
                              id: 'outlined-city_selected-native-simple',
                            }}
                          >
                            <option value="arraial">Arraial do Cabo</option>
                            <option value="buzios">Búzios</option>
                            <option value="cabo">Cabo Frio</option>
                            <option value="mendes">Mendes</option>
                            <option value="nova">Nova Iguaçu</option>
                            <option value="queimados">Queimados</option>
                            <option value="ostras">Rio das Ostras</option>
                            <option value="rio">Rio de Janeiro</option>
                            <option value="paulo">Paulo de Frontin</option>
                            <option value="sjmeriti">São João de Meriti</option>
                            <option value="saquarema">Saquarema</option>
                            <option value="tresrios">Três Rios</option>
                          </Select>
                        </FormControl>

                      {this.state.option === 'email' ? (
                        <FormControl variant="outlined" fullWidth style={{ marginBottom: 15 }}>
                          <InputLabel htmlFor="outlined-city_selected-native-simple">
                            Área *
                          </InputLabel>
                          <Select
                            native
                            required
                            value={this.state.selected_area}
                            onChange={this.handleChange}
                            label="Área"
                            inputProps={{
                              name: 'selected_area',
                              id: 'outlined-selected_area-native-simple',
                            }}
                          >
                            {this.state.city_areas.map((area) => (
                              <option value={area.area_name}>{area.area_name}</option>
                            ))}
                          </Select>
                        </FormControl>
                      ) : null}

                        <TextField
                          autoComplete="fname"
                          name="typology"
                          variant="outlined"
                          required
                          fullWidth
                          id="typology"
                          label="Categoria"
                          InputProps={{ style: styles.input }}
                          onChange={this.handleChange}
                          value={this.state.typology}
                        />

                      </Grid>
                    {/*)}*/}

                    <Grid item xs={12}>
                      <InputMask
                        {...this.props.value}
                        mask={this.state.phoneMask}
                        fullWidth
                        maskChar=" "
                        required
                        name="phone"
                        id="phone"
                        type="text"
                        onChange={this.handleChange}
                        value={this.state.phone}
                      >
                        {(inputProps) =>
                          <TextField
                            {...inputProps}
                            variant="outlined"
                            margin="normal"
                            label="Telefone de contato"
                            style={{ marginTop: 0 }}
                          />
                        }
                      </InputMask>
                    </Grid>

                    <Grid item xs={12}>
                      <FormControlLabel onChange={() => this.setState({ disabled: !this.state.disabled })}
                        control={<Checkbox value="allowExtraEmails" color="primary" />}
                        label="Estou de acordo com os termos de uso desta aplicação web."
                        aria-label={{ style: { color: '#fff' } }}
                      />
                    </Grid>
                  </Grid>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    style={styles.submit}
                    disabled={this.state.disabled}
                    startIcon={<PersonAdd />}
                  >
                    Cadastrar
                  </Button>
                  <Grid container justifyContent="flex-end">
                    <Grid item>
                      <Link href={`${apiString}/`} variant="body2">
                        Já possui uma conta? Entre
                      </Link>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Container>
            <Footer />
          </form>
        </ThemeProvider>

      </div>
    );
  }

}

let styles = {
  html: {
    backgroundColor: '#E5E6F0', //343434
    backgroundSize: "100% 100%",
    height: window.innerHeight + 40,
  },
  video: {
    position: 'fixed',
    right: 0,
    bottom: 0,
    minWidth: '100%',
    maxHeight: '100%',
    objectFit: 'cover',
  },
  paper: {
    padding: 30,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#E5E6F0', //343434
    position: 'relative',
  },
  avatar: {
    alignSelf: 'center',
    width: 100,
    height: 70,
    marginBottom: 10,
    marginRight: 30,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    paddingTop: 50,
  },
  input: {
    color: '#343434', //fff
  },
  submit: {
    marginTop: 10,
    marginBottom: 10,
    textTransform: 'none',
    backgroundColor: '#001D5F',
  },
  link: {
    color: '#001D5F',
  },
}