import React, { Component } from 'react';
import api from '../services/api';
import apiString from '../services/apiString';
import apiStringServer from '../services/apiStringServer';

import Header from '../components/Header';
import FooterHome from '../components/FooterHome';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Person from '@material-ui/icons/Person';
import Email from '@material-ui/icons/Email';
import Home from '@material-ui/icons/Home';
import Fingerprint from '@material-ui/icons/Fingerprint';
import Phone from '@material-ui/icons/Phone';
import LocationOn from '@material-ui/icons/LocationOn';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import Schooling from '@material-ui/icons/School';
import Working from '@material-ui/icons/Work';
import Marital from '@material-ui/icons/Favorite';
import Visibility from '@material-ui/icons/Visibility';
import Feed from '@material-ui/icons/Info';
import Child from '@material-ui/icons/ChildCare';
import Send from '@material-ui/icons/Send';
import Check from '@material-ui/icons/Check';
import ViewQuilt from '@material-ui/icons/ViewQuilt';
import AttachFile from '@material-ui/icons/AttachFile';
import HealthIcon from '@material-ui/icons/LocalHospital';
import SchoolIcon from '@material-ui/icons/School';
import PoliceIcon from '@material-ui/icons/Security';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import theme from '../themes/theme';
import { ThemeProvider } from '@material-ui/core';

import ImageList from '../components/ImageList';

import { Map, GoogleApiWrapper, Marker, Polygon } from 'google-maps-react';
import Info from '@material-ui/icons/Info';

import green_marker from '../assets/green_marker.png';
import hospital_marker from '../assets/map_icons/healthcare_icon.png';
import school_marker from '../assets/map_icons/school_icon.png';
import police_marker from '../assets/map_icons/police_icon.png';

export class Details extends Component {
  
  state = {
    cpf_cnpj: '',
    login: '',
    user_id: '',
    option: '',
    selected_city: '',
    documents: [],
    document_id: '',
    document_name: '',
    rooms: [],

    zoom: 19,
    latitude: '',
    longitude: '',
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},

    user: [],
    extrauser: [],
    polygonCoords: [],
    area: '',

    adm_areas: [],
    hospitals: [],
    listHospitals: false,
    schools: [],
    listSchools: false,
    policeStations: [],
    listPoliceStations: false,

    sonsArray: [],

    markers: [],

    expanded: false,
    openDialog: false,

    file: null,
    fileName: '',

    validateArea: false,
    validateUserInfo: false,
    validateDocuments: false,
    validatePlan: false,
    validateRooms: false,

    msg: '',
    open: false,
    open_error: false,

    msg2: '',
    open2: false,
    open_error2: false,
  }

  async componentDidMount() {

    if (this.props.location.state) {

      await this.setState({ cpf_cnpj: this.props.location.state.fields.cpf_cnpj });
      await this.setState({ selected_city: this.props.location.state.fields.selected_city });

    } else {

      const idx = document.URL.indexOf('?');

      var params = new Array();
      if (idx != -1) {
        var pairs = document.URL.substring(idx + 1, document.URL.length).split('&');
        //alert(pairs);
        for (var i = 0; i < pairs.length; i++) {
          let nameVal = pairs[i].split(',');
          nameVal = pairs[i].replace('cpf_cnpj=', '');
          nameVal = nameVal.replace('lat=', '');
          nameVal = nameVal.replace('lng=', '');
          nameVal = nameVal.replace('selected_city=', '');
          nameVal = nameVal.replace('login=', '');
          params.push(nameVal);
        }
      }

      await this.setState({ cpf_cnpj: params[0] });
      await this.setState({ latitude: params[1] });
      await this.setState({ longitude: params[2] });
      await this.setState({ selected_city: params[3] });
      await this.setState({ login: params[4] });

    }

    try {
      let resp = await api.get('/user', {
        params: {
          cpf_cnpj: this.state.cpf_cnpj,
          selected_city: this.state.selected_city
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('name')) {

            this.setState({ user: [resp.data] });

            await this.setState({ user_id: JSON.stringify(resp.data.id) });

          } else {
            let msg = JSON.stringify(resp.data);
            console.log(msg);
          }
        }
      }
    } catch (err) {
      console.log('Não foi possível buscar os dados do usuário. ' + err);
    }

    try {
      let resp = await api.get('/checkuserarea', {
        params: {
          cpf_cnpj: this.state.cpf_cnpj,
          selected_city: this.state.selected_city
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('name')) {

            this.setState({ area: resp.data.area });
            let polygon = resp.data.polygon;
            polygon = polygon.split('"').join('');
            polygon = polygon.split('[').join('');
            polygon = polygon.split('latitude').join('"lat"');
            polygon = polygon.split('longitude').join('"lng"');
            polygon = `[${polygon}]`;
            let polygonJson = JSON.parse(polygon);

            this.setState({ latitude: polygonJson[0].lat });
            this.setState({ longitude: polygonJson[0].lng });

            await this.setState({ polygonCoords: polygonJson });

            //alert(JSON.stringify(this.state.polygonCoords)); 

          } else {
            let msg = JSON.stringify(resp.data);
            console.log(msg);
          }
        }
      }
    } catch (err) {
      console.log('Não foi possível buscar os dados da área. ' + err);
    }

    await this.getMarkers();
    this.handleDocuments();
    this.handleExtraUser();
    this.handleAdmAreas();
    await this.handleImages();
    this.getValidation();

  }

  getHospitals = async () => {

    try {
      let resp = await api.get('/hospitals', {
        params: {
          selected_city: `municipios_${this.state.selected_city}`
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('municipio')) {

            await this.setState({ hospitals: resp.data });

            //let latitude1 = this.state.hospitals.map((item) => item[0].latitude);

            //alert(JSON.stringify(latitude1));

          } else {
            let msg = JSON.stringify(resp.data);
            console.log(msg);
          }
        }
      }
    } catch (err) {
      console.log('Não foi possível buscar os dados dos hospitais nas proximidades. ' + err);
    }

  }

  getSchools = async () => {

    try {
      let resp = await api.get('/schools', {
        params: {
          selected_city: `municipios_${this.state.selected_city}`
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('municipio')) {

            await this.setState({ schools: resp.data });

          } else {
            let msg = JSON.stringify(resp.data);
            console.log(msg);
          }
        }
      }
    } catch (err) {
      console.log('Não foi possível buscar os dados das escolas nas proximidades. ' + err);
    }

  }

  getPoliceStations = async () => {

    try {
      let resp = await api.get('/police_stations', {
        params: {
          selected_city: `municipios_${this.state.selected_city}`
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('municipio')) {

            await this.setState({ policeStations: resp.data });

          } else {
            let msg = JSON.stringify(resp.data);
            console.log(msg);
          }
        }
      }
    } catch (err) {
      console.log('Não foi possível buscar os dados das delegacias nas proximidades. ' + err);
    }

  }

  getValidation = async () => {

    try {
      let resp = await api.get('/validation', {
        params: {
          user_id: this.state.user_id,
          selected_city: this.state.selected_city
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('user_step')) {

            if (resp.data.user_step.includes('2')) { //ver como fica melhor isso aqui
              this.setState({ validateUserInfo: true });
            }
            if (resp.data.user_step.includes('4')) {
              this.setState({ validateArea: true });
            }
            if (resp.data.user_step.includes('5')) {
              this.setState({ validateDocuments: true });
            }
            if (resp.data.user_step.includes('6')) {
              this.setState({ validateRooms: true });
            }
            if (resp.data.user_step.includes('7')) {
              this.setState({ validatePlan: true });
            }
            

          } else {
            let msg = JSON.stringify(resp.data);
            console.log(msg);
          }
        }
      }
    } catch (err) {
      console.log('Não foi possível buscar os dados do usuário. ' + err);
    }

  }

  getMarkers = async () => {
    try {
      let resp = await api.get('/placesaround');
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('place')) {

            await this.setState({ markers: resp.data });

          } else {
            let msg = JSON.stringify(resp.data);
            console.log(msg);
          }
        }
      }
    } catch (err) {
      console.log('Não foi possível buscar os dados dos marcadores. ' + err);
    }
  }

  handleImages = async () => {

    try {
      let resp = await api.get('/images', {
        params: {
          user_id: this.state.user_id,
          selected_city: this.state.selected_city
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('rooms')) {

            await this.setState({ rooms: [resp.data] });

          } else {
            let msg = JSON.stringify(resp.data);
            //alert(msg);
          }
        }
      }
    } catch (err) {
      alert('Não foi possível buscar os cômodos. ' + err);
    }

  }

  handleAdmAreas = async () => {

    try {
      let resp = await api.get('/admareas', {
        params: {
          cpf_cnpj: this.state.login,
          selected_city: this.state.selected_city
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('name')) {

            let polygonStr = '';
          
            for(let i = 0; i < resp.data.length; i++) {
              let polygon = resp.data[i].polygon;
              let area = resp.data[i].area;
              let area_name = resp.data[i].area_name;
              let description = resp.data[i].description;
              let latitude = resp.data[i].latitude;
              let longitude = resp.data[i].longitude;
              polygon = polygon.split('"').join('');
              polygon = polygon.split('[').join('');
              polygon = polygon.split('lat').join('"lat"');
              polygon = polygon.split('lng').join('"lng"');
              polygonStr = polygonStr + `{"polygon":[${polygon}],
              "area":${area},
              "area_name":"${area_name}",
              "description":"${description}",
              "latitude":${latitude},
              "longitude":${longitude}},`;
            }

            polygonStr = `[${polygonStr}]`;
            polygonStr = polygonStr.split(',]').join(']');

            let polygonJson = JSON.parse(polygonStr)

            await this.setState({ adm_areas: polygonJson });

          } else {
            let msg = JSON.stringify(resp.data);
            //alert(msg); 
          }
        }
      }
    } catch (err) {
      alert('Não foi possível buscar os dados do usuário. ' + err);
    }

  }

  handleExtraUser = async () => {

    try {
      let resp = await api.get('/extrauser', {
        params: {
          cpf_cnpj: this.state.cpf_cnpj,
          selected_city: this.state.selected_city
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('name')) {

            await this.setState({ extrauser: [resp.data] });
            let sons = '';
            sons = `[${[resp.data][0].sons_array}]`;
            await this.setState({ sonsArray: JSON.parse(sons) });

          } else {
            let msg = JSON.stringify(resp.data);
            console.log(msg);
          }
        }
      }
    } catch (err) {
      console.log('Não foi possível buscar os dados extra do usuário. ' + err);
    }

  }

  handleDocuments = async () => {

    try {
      let resp = await api.get('/documents', {
        params: {
          user_id: this.state.user_id,
          selected_city: this.state.selected_city
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('name')) {
            
            this.setState({ documents: resp.data });

          } else {
            let msg = JSON.stringify(resp.data);
            const finalmsg = msg.toString().split('"').join('');
            await this.setState({ msg: finalmsg });
            await this.setState({ open: false });
            if (this.state.msg == '[]') {
              this.setState({ open_error: false });
            } else {
              this.setState({ open_error: true });
            }
          }
        }
      }
    } catch (err) {
      console.error(err);
      await this.setState({ msg: 'Usuário incorreto. ' + err });
      await this.setState({ open: false });
      this.setState({ open_error: true });
    }

  }

  onMapClicked = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      });
    }
  };

  onMarkerClick = (props, marker) => {

    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });

  }

  handleAdjust = async (polygon, area) => {

    this.setState({ polygonCoords: polygon });
    this.setState({ area });

  }

  handleSend = async () => {

    this.setState({ msg: 'Alterações enviadas com sucesso.' });
    this.setState({ open: true });

  }

  handleNewFile = async e => {
    await e.preventDefault();

    await this.setState({ file: e.target.files[0] });
    const fileSize = this.state.file.size;

    if (fileSize > 5000000) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      this.setState({ msg2: 'Tamanho máximo permitido: 5 MB' });
      this.setState({ open_error2: true });
      this.setState({ open2: false });
    } else {
      this.setState({ fileName: this.state.file.name });

      const data = new FormData();
      data.append('user_id', this.state.user_id);
      data.append('selected_city', this.state.selected_city);
      data.append('document', this.state.file);

      try {
        let resp = await api.post('/storedocument', data);
        if (resp != null) {
          if (resp.data) {
            if (JSON.stringify(resp.data).includes('registred')) {
              //documento salvo
              this.handleDocuments();
            } else {
              this.setState({ msg2: 'Não foi possível anexar o documento. ' });
              this.setState({ open2: false });
              this.setState({ open_error2: true });
            }
          }
        }
      } catch (err) {
        this.setState({ msg2: 'Não foi possível anexar o documento. ' + err });
        this.setState({ open2: false });
        this.setState({ open_error2: true });
      }
    }
  }

  handleDeleteDoc = async () => {

    try {
      let resp = await api.get('/deletedoc', {
        params: {
          document_id: this.state.document_id,
          document_name: this.state.document_name,
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('deleted')) {

            this.handleDocuments();

          } else {
            let msg = JSON.stringify(resp.data);
            alert(msg);
          }
        }
      }
    } catch (err) {
      alert('Não foi possível buscar os dados da área. ' + err);
    }
  }

  handleValidateUserInfo = async () => {
      
      try {
        let resp = await api.get('/validateuserinfo', {
          params: {
            user_id: this.state.user_id,
            selected_city: this.state.selected_city
          }
        });
        if (resp != null) {
          if (resp.data) {
            if (JSON.stringify(resp.data).includes('validated')) {
  
              this.setState({ validateUserInfo: true });
  
            } else {
              let msg = JSON.stringify(resp.data);
              console.log(msg);
            }
          }
        }
      } catch (err) {
        console.log('Não foi possível validar as informações do usuário. ' + err);
      }

  }

  handleValidateArea = async () => {

    try {
      let resp = await api.get('/validateuserarea', {
        params: {
          user_id: this.state.user_id,
          selected_city: this.state.selected_city
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('validated')) {

            this.setState({ validateArea: true });

          } else {
            let msg = JSON.stringify(resp.data);
            console.log(msg);
          }
        }
      }
    } catch (err) {
      console.log('Não foi possível validar a área. ' + err);
    }

  }

  handleValidateDocuments = async () => {
      
      try {
        let resp = await api.get('/validatedocuments', {
          params: {
            user_id: this.state.user_id,
            selected_city: this.state.selected_city
          }
        });
        if (resp != null) {
          if (resp.data) {
            if (JSON.stringify(resp.data).includes('validated')) {
  
              this.setState({ validateDocuments: true });
  
            } else {
              let msg = JSON.stringify(resp.data);
              console.log(msg);
            }
          }
        }
      } catch (err) {
        console.log('Não foi possível validar os documentos. ' + err);
      }

  }

  handleValidateRooms = async () => {

    try {
      let resp = await api.get('/validaterooms', {
        params: {
          user_id: this.state.user_id,
          selected_city: this.state.selected_city
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('validated')) {

            this.setState({ validateRooms: true });

          } else {
            let msg = JSON.stringify(resp.data);
            console.log(msg);
          }
        }
      }
    } catch (err) {
      console.log('Não foi possível validar os cômodos. ' + err);
    }

  }

  handleValidatePlan = async () => {

    try {
      let resp = await api.get('/validateplan', {
        params: {
          user_id: this.state.user_id,
          selected_city: this.state.selected_city
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('validated')) {

            this.setState({ validatePlan: true });

          } else {
            let msg = JSON.stringify(resp.data);
            console.log(msg);
          }
        }
      }
    } catch (err) {
      console.log('Não foi possível validar a planta. ' + err);
    }

  }


  handlePlan = async () => {



  }

  render() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={styles.html}>
          <Header history={this.props.history} {...this.props}/>

          <ThemeProvider theme={theme}>
            <div style={styles.directionRow}>

              <div style={styles.container}>

                <div style={styles.container4}>

                  {this.state.user.map((item) =>
                    <div style={{ padding: 10 }}>
                      <Typography variant="h6" color="textSecondary" align="left">
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <div style={styles.icon}>
                            <Person />
                          </div>
                          {item.name}
                        </div>
                      </Typography>
                      <Divider />
                      <Typography variant="subtitle1" color="textSecondary" align="left">
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <div style={styles.icon}>
                            <Email />
                          </div>
                          {item.email}
                        </div>
                      </Typography>
                      <Typography variant="subtitle1" color="textSecondary" align="left">
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <div style={styles.icon}>
                            <Home />
                          </div>
                          {`${item.street}, ${item.number} - ${item.district}, ${item.city} - ${item.state}`}
                        </div>
                      </Typography>
                      <Typography variant="subtitle1" color="textSecondary" align="left">
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <div style={styles.icon}>
                            <Fingerprint />
                          </div>
                          {item.cpf_cnpj}
                        </div>
                      </Typography>
                      <Typography variant="subtitle1" color="textSecondary" align="left">
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <div style={styles.icon}>
                            <Phone />
                          </div>
                          {item.phone}
                        </div>
                      </Typography>
                      <Typography variant="subtitle1" color="textSecondary" align="left">
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <div style={styles.icon}>
                            <LocationOn />
                          </div>
                          {`Área: ${this.state.area} m²`}
                        </div>
                      </Typography>
                      <Divider />
                    </div>
                  )}

                  {this.state.extrauser.map((item) =>
                    <div style={{ paddingLeft: 10 }}>
                      <Typography variant="subtitle1" color="textSecondary" align="left">
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <div style={styles.icon}>
                            <Schooling />
                          </div>
                          {`Escolaridade atual: ${item.schooling === 'fun' ? 'Ensino Fundamental' :
                            item.schooling === 'med' ? 'Ensino Médio' : item.schooling === 'sup' ? 'Ensino Superior em andamento' :
                              item.schooling === 'con' ? 'Ensino Superior completo' : 'Não informada'} (${item.course})`}
                        </div>
                      </Typography>
                      <Typography variant="subtitle1" color="textSecondary" align="left">
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <div style={styles.icon}>
                            <Working />
                          </div>
                          {`Emprego atual: ${item.working === 'yes' ?
                            `${item.contract_type} - ${item.work} - ${item.description} (${item.experience} de experiência)` : 'Não informado'}`}
                        </div>
                      </Typography>
                      <Typography variant="subtitle1" color="textSecondary" align="left">
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <div style={styles.icon}>
                            <Marital />
                          </div>
                          {`Estado civil: ${item.marital_status === 'sol' ? 'Solteiro' : item.marital_status === 'noi' ? 'Noivo' :
                            item.marital_status === 'uni' ? 'União Estável' : item.marital_status === 'cas' ? 'Casado' :
                              item.marital_status === 'div' ? 'Divorciado' : item.marital_status === 'viu' ? 'Viúvo' : 'Não informado'}`}
                        </div>
                      </Typography>
                      {item.marital_status !== 'uni' && item.marital_status !== 'cas' ? null : (
                        <Typography variant="subtitle1" color="textSecondary" align="left">
                          <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 10 }}>
                            <div style={styles.icon}>
                              <Feed />
                            </div>
                            {`Informações do cônjuje: Data de nascimento: ${item.spouse_born_date}`} <br />
                            {`Escolaridade atual: ${item.spouse_schooling === 'fun' ? 'Ensino Fundamental' :
                              item.spouse_schooling === 'med' ? 'Ensino Médio' : item.spouse_schooling === 'sup' ? 'Ensino Superior em andamento' :
                                item.spouse_schooling === 'con' ? 'Ensino Superior concluído' : 'Não informada'} (${item.spouse_course})`} <br />
                            {`Emprego atual: ${item.spouse_working === 'yes' ?
                              `${item.spouse_contract_type} - ${item.spouse_work} - ${item.spouse_description} (${item.spouse_experience} de experiência)` : 'Não informado'}`}
                          </div>
                        </Typography>
                      )}
                      {item.sons === 'yes' ? (
                        <div>
                          <Typography variant="subtitle1" color="textSecondary" align="left">
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                              <div style={styles.icon}>
                                <Child />
                              </div>
                              {`Número de filhos: ${item.sons_number}`}
                            </div>
                          </Typography>
                          {this.state.sonsArray.map((sons) =>
                            <Typography variant="subtitle1" color="textSecondary" align="left">
                              <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 10 }}>
                                <div style={styles.icon}>
                                  <Feed />
                                </div>
                                {`Informações do filho ${sons.number}: Data de nascimento: ${sons.bornDate}`} <br />
                                {`Gênero: ${sons.gender === 'mas' ? 'Masculino' : 'Feminino'}`} <br />
                                {`Escolaridade atual: ${sons.schooling === 'mat' ? 'Maternal' :
                                  sons.schooling === 'jar' ? 'Jardim de infância' : sons.schooling === 'fun' ? 'Ensino Fundamental' :
                                    sons.schooling === 'med' ? 'Ensino Médio' : sons.schooling === 'sup' ? 'Ensino Superior' : 'Não informada'}`}
                              </div>
                            </Typography>
                          )}
                        </div>
                      ) : null}
                    </div>
                  )}

                  <Divider />

                  <div style={styles.directionRow}>
                    <Button
                      variant="contained"
                      disabled={this.state.validateUserInfo}
                      color="primary"
                      style={styles.button}
                      onClick={this.handleValidateUserInfo}
                      startIcon={<Check />}
                    >
                      Validar informações do usuário
                    </Button>

                    {this.state.validateUserInfo ? (
                      <Typography
                        variant="subtitle1"
                        color="textSecondary"
                        align="left"
                        style={styles.validate}
                      >
                        <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 10 }}>
                          <div style={styles.icon2}>
                            <Check />
                          </div>
                          Estas informações já foram validadas.
                        </div>
                      </Typography>
                    ) : null}
                  </div>

                  <div style={styles.directionRow}>
                    <a
                      href={`${apiString}/plan?cpf_cnpj=${this.state.cpf_cnpj}&login=${this.state.login}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        style={styles.button}
                        //onClick={this.handlePlan}
                        startIcon={<ViewQuilt />}
                      >
                        1 - Criar planta da área
                      </Button>
                    </a>
                    <div>
                      <input
                        accept="file_extension|audio/*|video/*|image/*|media_type"
                        style={{ display: 'none' }}
                        id="button-attach"
                        multiple
                        type="file"
                        onChange={this.handleNewFile}
                      />
                      <label htmlFor="button-attach">
                        <Button
                          component="span"
                          variant="contained"
                          size="small"
                          color="primary"
                          startIcon={<AttachFile />}
                          style={styles.button2}
                        >
                          2 - Anexar planta aos documentos
                        </Button>
                      </label>
                    </div>
                  </div>

                </div>

                <div>
                  {this.state.open2 ? (
                    <Alert
                      severity="success"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            this.setState({ open2: false });
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      {this.state.msg2}
                    </Alert>
                  ) : null}

                  {this.state.open_error2 ? (
                    <Alert
                      severity="error"
                      style={{ marginBottom: 10 }}
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            this.setState({ open_error2: false });
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      {this.state.msg2}
                    </Alert>
                  ) : null}
                </div>

                <div style={styles.container2}>

                  <Typography variant="h6" color="textSecondary" align="center">
                    Documentos
                  </Typography>

                  <Divider />

                  <div style={{ display: 'flex', flexDirection: 'column', margin: 10, marginTop: 20 }}>
                    {this.state.documents ? this.state.documents.map((item) =>
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <a target="_blank" href={`${apiStringServer}/documents/${item.file}`} rel="noreferrer">
                          <Typography variant="subtitle1" color="textSecondary" align="left">
                            {item.file}
                          </Typography>
                        </a>

                        <a target="_blank" href={`${apiStringServer}/documents/${item.file}`} rel="noreferrer">
                          <IconButton
                            aria-label="close"
                            color="#800000"
                            style={{ marginTop: -15 }}
                          >
                            <Visibility />
                          </IconButton>
                        </a>

                        <IconButton
                          aria-label="close"
                          color="#800000"
                          style={{ marginTop: -15 }}
                          onClick={async () => {
                            await this.setState({ fileName: '' });
                            await this.setState({ document_id: item.document_id });
                            await this.setState({ document_name: item.file });
                            this.handleDeleteDoc();
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </div>
                    ) : (
                        <Typography variant="subtitle1" color="textSecondary" align="left">
                          Nenhum documento anexado.
                        </Typography>
                    )}
                  </div>

                  <Divider />

                  <div style={styles.directionRow}>
                    <Button
                      variant="contained"
                      disabled={this.state.validateDocuments}
                      color="primary"
                      style={styles.button}
                      onClick={this.handleValidateDocuments}
                      startIcon={<Check />}
                    >
                      Validar documentação
                    </Button>

                    {this.state.validateDocuments ? (
                      <Typography
                        variant="subtitle1"
                        color="textSecondary"
                        align="left"
                        style={styles.validate}
                      >
                        <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 10 }}>
                          <div style={styles.icon2}>
                            <Check />
                          </div>
                          Estes documentos já foram validados.
                        </div>
                      </Typography>
                    ) : null}
                  </div>

                  <div style={styles.directionRow}>
                    <Button
                      variant="contained"
                      disabled={this.state.validatePlan}
                      color="primary"
                      style={styles.button}
                      onClick={this.handleValidatePlan}
                      startIcon={<Check />}
                    >
                      Validar planta
                    </Button>

                    {this.state.validatePlan ? (
                      <Typography
                        variant="subtitle1"
                        color="textSecondary"
                        align="left"
                        style={styles.validate}
                      >
                        <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 10 }}>
                          <div style={styles.icon2}>
                            <Check />
                          </div>
                          Esta planta já foi validada.
                        </div>
                      </Typography>
                    ) : null}
                  </div>

                </div>

                <div style={styles.container3}>

                  <Typography variant="h6" color="textSecondary" align="center">
                    Cômodos
                  </Typography>

                  {this.state.rooms ? (
                    <ImageList rooms={this.state.rooms} {...this.props} />
                  ) : null}

                  <div style={styles.directionRow}>
                    <Button
                      variant="contained"
                      disabled={this.state.validateRooms}
                      color="primary"
                      style={styles.button}
                      onClick={this.handleValidateRooms}
                      startIcon={<Check />}
                    >
                      Validar cômodos
                    </Button>

                    {this.state.validateRooms ? (
                      <Typography
                        variant="subtitle1"
                        color="textSecondary"
                        align="left"
                        style={styles.validate}
                      >
                        <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 10 }}>
                          <div style={styles.icon2}>
                            <Check />
                          </div>
                          Estes cômodos já foram validados.
                        </div>
                      </Typography>
                    ) : null}
                  </div>

                </div>

              </div>

              <div>
                <Map
                  key={'AIzaSyDC6df7s7nceeG2sHhFGu0-nzLppDiHpe0'}
                  google={this.props.google}
                  zoom={this.state.zoom}
                  style={styles.map}
                  center={{
                    lat: this.state.latitude,
                    lng: this.state.longitude
                  }}
                  onClick={this.onMapClicked}
                >

                  <Polygon
                    paths={this.state.polygonCoords}
                    strokeColor="#001D5F"
                    strokeOpacity={0.9}
                    strokeWeight={2}
                    fillColor="#001D5F"
                    fillOpacity={0.7}
                  />

                  {this.state.adm_areas.map((item) =>
                    <Polygon
                      paths={item.polygon}
                      strokeColor="#fdfd96"
                      strokeOpacity={0.8}
                      strokeWeight={2}
                      fillColor="#fdfd96"
                      fillOpacity={0.2}
                    />
                  )}

                  {this.state.hospitals.map((item) =>
                    <Marker
                      icon={{
                        url: hospital_marker,
                        scaledSize: new window.google.maps.Size(32, 32)
                      }}
                      position={{
                        lat: item.latitude,
                        lng: item.longitude
                      }}
                      name={`${item.unidade}`}
                      onClick={this.onMarkerClick}
                      title={`${item.unidade} - ${item.endereco}, ${item.bairro}`}
                    />
                  )}

                  {this.state.schools.map((item) =>
                    <Marker
                      icon={{
                        url: school_marker,
                        scaledSize: new window.google.maps.Size(32, 40)
                      }}
                      position={{
                        lat: item.latitude,
                        lng: item.longitude
                      }}
                      name={`${item.unidade}`}
                      onClick={this.onMarkerClick}
                      title={`${item.unidade} - ${item.endereco}, ${item.bairro}`}
                    />
                  )}

                  {this.state.policeStations.map((item) =>
                    <Marker
                      icon={{
                        url: police_marker,
                        scaledSize: new window.google.maps.Size(32, 32)
                      }}
                      position={{
                        lat: item.latitude,
                        lng: item.longitude
                      }}
                      name={`${item.unidade}`}
                      onClick={this.onMarkerClick}
                      title={`${item.unidade} - ${item.endereco}`}
                    />
                  )}

                  {this.state.adm_areas.map((item) =>
                    <Marker
                      position={{
                        lat: item.latitude,
                        lng: item.longitude
                      }}
                      name={`${item.area_name}`}
                      onClick={() => this.handleAdjust(item.polygon, item.area)}
                      title={`${item.area_name}: ${item.area} m² / ${item.description} / Diferença de ${(item.area - this.state.area).toFixed(2)} m²`}
                    />
                  )}

                  {this.state.markers.map((item) =>
                    <Marker
                      icon={{
                        url: green_marker,
                        scaledSize: new window.google.maps.Size(20, 30)
                      }}
                      position={{
                        lat: item.latitude,
                        lng: item.longitude
                      }}
                      name={`${item.place}`}
                      title={`Nome: ${item.place} \nTipo: ${item.type} \nSobre: ${item.description}`}
                    />
                  )}

                </Map>

                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '92%', marginLeft: 10 }}>

                  <FormControlLabel
                    control={
                      <Checkbox
                        value={this.state.listHospitals}
                        color="primary"
                        onChange={async () => {
                          await this.setState({ listHospitals: !this.state.listHospitals });
                          if (this.state.listHospitals) {
                            this.getHospitals();
                          } else {
                            this.setState({ hospitals: [] });
                          }
                        }}
                      />
                    }
                    label="Listar Saúde"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        value={this.state.listSchools}
                        color="primary"
                        onChange={async () => {
                          await this.setState({ listSchools: !this.state.listSchools });
                          if (this.state.listSchools) {
                            this.getSchools();
                          } else {
                            this.setState({ schools: [] });
                          }
                        }}
                      />
                    }
                    label="Listar Educação"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        value={this.state.listPoliceStations}
                        color="primary"
                        onChange={async () => {
                          await this.setState({ listPoliceStations: !this.state.listPoliceStations });
                          if (this.state.listPoliceStations) {
                            this.getPoliceStations();
                          } else {
                            this.setState({ policeStations: [] });
                          }
                        }}
                      />
                    }
                    label="Listar Segurança"
                  />

                </div>

                <Typography variant="subtitle1" color="textSecondary" align="left">
                  <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 10 }}>
                    <div style={styles.icon}>
                      <Info />
                    </div>
                    Passe o cursor no marcador para visualizar as informações do lote.
                  </div>
                </Typography>

                <Typography variant="subtitle1" color="textSecondary" align="left">
                  <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 10 }}>
                    <div style={styles.icon}>
                      <Info />
                    </div>
                    Clique no marcador do lote para ajustar a área do usuário ao lote correto.
                  </div>
                </Typography>

                <div style={styles.directionRow}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={styles.button}
                    onClick={this.handleSend}
                    startIcon={<Send />}
                  >
                    Enviar alterações de área
                  </Button>

                  <Button
                    variant="contained"
                    disabled={this.state.validateArea}
                    color="primary"
                    style={styles.button}
                    onClick={this.handleValidateArea}
                    startIcon={<Check />}
                  >
                    Validar informações de área
                  </Button>

                  {this.state.validateArea ? (
                    <Typography
                      variant="subtitle1"
                      color="textSecondary"
                      align="left"
                      style={styles.validate}
                    >
                      <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 10 }}>
                        <div style={styles.icon2}>
                          <Check />
                        </div>
                        Esta área já foi validada.
                      </div>
                    </Typography>
                  ) : null }
                </div>

                {this.state.open ? (
                  <Alert
                    style={{ marginBottom: 10 }}
                    severity="success"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          this.setState({ open: false });
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    {this.state.msg}
                  </Alert>
                ) : null}

                <Typography variant="subtitle1" color="textSecondary" align="left">
                  <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 10 }}>
                    As alterações serão enviadas ao usuário e o mesmo poderá aprovar as novas informações.
                  </div>
                </Typography>
              </div>
            </div>
          </ThemeProvider>

        </div>
        <FooterHome />
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDC6df7s7nceeG2sHhFGu0-nzLppDiHpe0',
  language: 'pt-BR'
})(Details)

let styles = {
  html: {
    backgroundColor: '#E5E6F0', //454545
    height: window.innerHeight + 1000,
    width: window.innerWidth,
    margin: -10,
    paddingBottom: 50,
  },

  container: {
    height: 1700,
    width: window.innerWidth - (window.innerWidth / 1.6),
    backgroundColor: '#bfcadb', //343434
    marginLeft: 10,
    marginTop: 10,
  },

  container2: {
    height: 340,
    width: 600,
    backgroundColor: '#fff', //454545
    margin: 10,
    borderRadius: 5,
  },

  container3: {
    height: 600,
    width: 600,
    backgroundColor: '#fff', //454545
    margin: 10,
    borderRadius: 5,
  },

  container4: {
    width: 600,
    backgroundColor: '#fff', //454545
    margin: 10,
    borderRadius: 5,
  },

  directionRow: { 
    display: 'flex', 
    flexDirection: 'row' 
  },

  icon: {
    marginTop: 2.5,
    marginRight: 5,
  },

  icon2: {
    marginTop: 2.5,
    marginRight: 5,
    color: '#fff'
  },

  directionColumn: { 
    display: 'flex', 
    flexDirection: 'column' 
  },

  map: {
    height: 600,
    width: window.innerWidth - (window.innerWidth / 2),
    marginLeft: 10,
    marginTop: 10,
    alignSelf: 'flex-start',
  },

  button: {
    textTransform: 'none',
    margin: 10
  },

  button2: {
    textTransform: 'none',
    margin: 10,
    height: 37,
  },

  validate: {
    color: '#fff', 
    marginTop: 11, 
    backgroundColor: '#32cd32', 
    borderRadius: 5,
    padding: 5,
    height: 25,
  }
};