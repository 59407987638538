let polygonCoordsRio2 = [
  {
    lng: -43.191083,
    lat: -22.9908267
  },
  {
    lng: -43.1911089,
    lat: -22.9908275
  },
  {
    lng: -43.1911327,
    lat: -22.9908405
  },
  {
    lng: -43.1911397,
    lat: -22.9908574
  },
  {
    lng: -43.1911384,
    lat: -22.9908815
  },
  {
    lng: -43.1911353,
    lat: -22.9908935
  },
  {
    lng: -43.1911162,
    lat: -22.9909087
  },
  {
    lng: -43.191072,
    lat: -22.990921
  },
  {
    lng: -43.1910063,
    lat: -22.9909241
  },
  {
    lng: -43.1908889,
    lat: -22.990934
  },
  {
    lng: -43.1908072,
    lat: -22.9909543
  },
  {
    lng: -43.1907179,
    lat: -22.9909709
  },
  {
    lng: -43.1906649,
    lat: -22.9909729
  },
  {
    lng: -43.1905993,
    lat: -22.9909543
  },
  {
    lng: -43.1905068,
    lat: -22.9909
  },
  {
    lng: -43.1904531,
    lat: -22.9908604
  },
  {
    lng: -43.1903982,
    lat: -22.9908147
  },
  {
    lng: -43.190311,
    lat: -22.9907678
  },
  {
    lng: -43.1901902,
    lat: -22.9907321
  },
  {
    lng: -43.1900911,
    lat: -22.9907271
  },
  {
    lng: -43.1900502,
    lat: -22.9907229
  },
  {
    lng: -43.1900356,
    lat: -22.990717
  },
  {
    lng: -43.1900236,
    lat: -22.9907086
  },
  {
    lng: -43.1900038,
    lat: -22.9906938
  },
  {
    lng: -43.1899763,
    lat: -22.9906753
  },
  {
    lng: -43.1899367,
    lat: -22.9906605
  },
  {
    lng: -43.1899106,
    lat: -22.990658
  },
  {
    lng: -43.1898898,
    lat: -22.9906617
  },
  {
    lng: -43.1898777,
    lat: -22.9906692
  },
  {
    lng: -43.1898422,
    lat: -22.9907037
  },
  {
    lng: -43.1898184,
    lat: -22.9907197
  },
  {
    lng: -43.1897553,
    lat: -22.9907309
  },
  {
    lng: -43.1897283,
    lat: -22.9907295
  },
  {
    lng: -43.1897101,
    lat: -22.9907238
  },
  {
    lng: -43.1897044,
    lat: -22.9907127
  },
  {
    lng: -43.1897,
    lat: -22.9906898
  },
  {
    lng: -43.189703,
    lat: -22.9906775
  },
  {
    lng: -43.1897092,
    lat: -22.9906613
  },
  {
    lng: -43.1897927,
    lat: -22.9905593
  },
  {
    lng: -43.1898731,
    lat: -22.9904692
  },
  {
    lng: -43.189924,
    lat: -22.9904284
  },
  {
    lng: -43.1899803,
    lat: -22.9904154
  },
  {
    lng: -43.1900702,
    lat: -22.9904067
  },
  {
    lng: -43.1901667,
    lat: -22.9903698
  },
  {
    lng: -43.190213,
    lat: -22.9903599
  },
  {
    lng: -43.190287,
    lat: -22.9903476
  },
  {
    lng: -43.1904806,
    lat: -22.9903167
  },
  {
    lng: -43.1906597,
    lat: -22.9902722
  },
  {
    lng: -43.1905174,
    lat: -22.9901985
  },
  {
    lng: -43.1904915,
    lat: -22.9901671
  },
  {
    lng: -43.1904895,
    lat: -22.9901511
  },
  {
    lng: -43.1904933,
    lat: -22.9901387
  },
  {
    lng: -43.1905268,
    lat: -22.9901092
  },
  {
    lng: -43.1906327,
    lat: -22.9899798
  },
  {
    lng: -43.1906042,
    lat: -22.9899588
  },
  {
    lng: -43.190527,
    lat: -22.9899343
  },
  {
    lng: -43.1904101,
    lat: -22.9899289
  },
  {
    lng: -43.1904026,
    lat: -22.9899016
  },
  {
    lng: -43.1904102,
    lat: -22.9898748
  },
  {
    lng: -43.1904448,
    lat: -22.9898311
  },
  {
    lng: -43.1904954,
    lat: -22.989804
  },
  {
    lng: -43.1905537,
    lat: -22.9897984
  },
  {
    lng: -43.1906119,
    lat: -22.989804
  },
  {
    lng: -43.1906414,
    lat: -22.9897573
  },
  {
    lng: -43.1906137,
    lat: -22.9897096
  },
  {
    lng: -43.190589,
    lat: -22.9896604
  },
  {
    lng: -43.1905644,
    lat: -22.9896113
  },
  {
    lng: -43.19054,
    lat: -22.9895621
  },
  {
    lng: -43.1905119,
    lat: -22.9895145
  },
  {
    lng: -43.1904863,
    lat: -22.9894659
  },
  {
    lng: -43.1904615,
    lat: -22.9894169
  },
  {
    lng: -43.190443,
    lat: -22.9893654
  },
  {
    lng: -43.1904241,
    lat: -22.9893141
  },
  {
    lng: -43.1903884,
    lat: -22.9892711
  },
  {
    lng: -43.1903368,
    lat: -22.9892455
  },
  {
    lng: -43.1902783,
    lat: -22.989249
  },
  {
    lng: -43.1902221,
    lat: -22.9892339
  },
  {
    lng: -43.1901374,
    lat: -22.9891851
  },
  {
    lng: -43.1900529,
    lat: -22.989089
  },
  {
    lng: -43.1899946,
    lat: -22.9890851
  },
  {
    lng: -43.1899415,
    lat: -22.9890621
  },
  {
    lng: -43.1899079,
    lat: -22.9890179
  },
  {
    lng: -43.1899419,
    lat: -22.9889739
  },
  {
    lng: -43.1901071,
    lat: -22.9889195
  },
  {
    lng: -43.1901302,
    lat: -22.9888697
  },
  {
    lng: -43.190081,
    lat: -22.9888405
  },
  {
    lng: -43.1899016,
    lat: -22.9887014
  },
  {
    lng: -43.1897514,
    lat: -22.9886176
  },
  {
    lng: -43.1895899,
    lat: -22.9884984
  },
  {
    lng: -43.1895378,
    lat: -22.9884737
  },
  {
    lng: -43.189444,
    lat: -22.988409
  },
  {
    lng: -43.1893475,
    lat: -22.98836
  },
  {
    lng: -43.1890083,
    lat: -22.9881366
  },
  {
    lng: -43.1888549,
    lat: -22.9880589
  },
  {
    lng: -43.1886481,
    lat: -22.9879781
  },
  {
    lng: -43.1885951,
    lat: -22.987939
  },
  {
    lng: -43.1884433,
    lat: -22.9878618
  },
  {
    lng: -43.1884015,
    lat: -22.9878488
  },
  {
    lng: -43.1883319,
    lat: -22.9878288
  },
  {
    lng: -43.1882117,
    lat: -22.9878518
  },
  {
    lng: -43.1881575,
    lat: -22.987835
  },
  {
    lng: -43.1880409,
    lat: -22.9878435
  },
  {
    lng: -43.187983,
    lat: -22.9878355
  },
  {
    lng: -43.1878712,
    lat: -22.9878037
  },
  {
    lng: -43.1877684,
    lat: -22.9877518
  },
  {
    lng: -43.187727,
    lat: -22.9877135
  },
  {
    lng: -43.1876858,
    lat: -22.9876387
  },
  {
    lng: -43.187638,
    lat: -22.9876075
  },
  {
    lng: -43.18757,
    lat: -22.9875609
  },
  {
    lng: -43.1875319,
    lat: -22.9875005
  },
  {
    lng: -43.1875163,
    lat: -22.9873048
  },
  {
    lng: -43.1874522,
    lat: -22.9872494
  },
  {
    lng: -43.1873902,
    lat: -22.987203
  },
  {
    lng: -43.1872919,
    lat: -22.9871303
  },
  {
    lng: -43.1872149,
    lat: -22.9870723
  },
  {
    lng: -43.1871404,
    lat: -22.9870054
  },
  {
    lng: -43.1870891,
    lat: -22.9869543
  },
  {
    lng: -43.1870513,
    lat: -22.986926
  },
  {
    lng: -43.1870194,
    lat: -22.9869113
  },
  {
    lng: -43.1869855,
    lat: -22.9868964
  },
  {
    lng: -43.1868601,
    lat: -22.986928
  },
  {
    lng: -43.1867281,
    lat: -22.9869667
  },
  {
    lng: -43.1866697,
    lat: -22.9869661
  },
  {
    lng: -43.1866122,
    lat: -22.9869387
  },
  {
    lng: -43.1865803,
    lat: -22.9868934
  },
  {
    lng: -43.1865573,
    lat: -22.9867871
  },
  {
    lng: -43.1865742,
    lat: -22.9867354
  },
  {
    lng: -43.1866186,
    lat: -22.9866953
  },
  {
    lng: -43.1865662,
    lat: -22.9866711
  },
  {
    lng: -43.1863929,
    lat: -22.9866457
  },
  {
    lng: -43.1862238,
    lat: -22.9866021
  },
  {
    lng: -43.1860611,
    lat: -22.9865414
  },
  {
    lng: -43.1858052,
    lat: -22.9864105
  },
  {
    lng: -43.1857576,
    lat: -22.986379
  },
  {
    lng: -43.1857041,
    lat: -22.9863569
  },
  {
    lng: -43.1855403,
    lat: -22.9862988
  },
  {
    lng: -43.1854253,
    lat: -22.9862781
  },
  {
    lng: -43.185368,
    lat: -22.9862891
  },
  {
    lng: -43.1851464,
    lat: -22.9863583
  },
  {
    lng: -43.1850883,
    lat: -22.9863651
  },
  {
    lng: -43.1849745,
    lat: -22.9863406
  },
  {
    lng: -43.1848586,
    lat: -22.9863522
  },
  {
    lng: -43.184625,
    lat: -22.9863387
  },
  {
    lng: -43.1845101,
    lat: -22.9863184
  },
  {
    lng: -43.1844558,
    lat: -22.9862981
  },
  {
    lng: -43.1843399,
    lat: -22.986312
  },
  {
    lng: -43.1842812,
    lat: -22.9863111
  },
  {
    lng: -43.1840478,
    lat: -22.9862967
  },
  {
    lng: -43.1839337,
    lat: -22.9862725
  },
  {
    lng: -43.1838799,
    lat: -22.9862513
  },
  {
    lng: -43.183832,
    lat: -22.9862203
  },
  {
    lng: -43.1837456,
    lat: -22.9861471
  },
  {
    lng: -43.1837062,
    lat: -22.986107
  },
  {
    lng: -43.1836986,
    lat: -22.9860532
  },
  {
    lng: -43.1836591,
    lat: -22.9860131
  },
  {
    lng: -43.1836086,
    lat: -22.9859859
  },
  {
    lng: -43.1835533,
    lat: -22.9859266
  },
  {
    lng: -43.1835215,
    lat: -22.9858478
  },
  {
    lng: -43.1835286,
    lat: -22.9857942
  },
  {
    lng: -43.1835267,
    lat: -22.9857164
  },
  {
    lng: -43.183621,
    lat: -22.9857365
  },
  {
    lng: -43.1838532,
    lat: -22.9857578
  },
  {
    lng: -43.1838937,
    lat: -22.9857419
  },
  {
    lng: -43.1838828,
    lat: -22.9856887
  },
  {
    lng: -43.1838948,
    lat: -22.9856357
  },
  {
    lng: -43.1839346,
    lat: -22.985596
  },
  {
    lng: -43.1839835,
    lat: -22.9855659
  },
  {
    lng: -43.1840905,
    lat: -22.9855228
  },
  {
    lng: -43.1842045,
    lat: -22.9854999
  },
  {
    lng: -43.1844384,
    lat: -22.985505
  },
  {
    lng: -43.1844969,
    lat: -22.9855005
  },
  {
    lng: -43.1846116,
    lat: -22.9854795
  },
  {
    lng: -43.1847225,
    lat: -22.9854448
  },
  {
    lng: -43.1848977,
    lat: -22.9854427
  },
  {
    lng: -43.1849504,
    lat: -22.9854663
  },
  {
    lng: -43.1850211,
    lat: -22.9854427
  },
  {
    lng: -43.1851383,
    lat: -22.9854434
  },
  {
    lng: -43.1853117,
    lat: -22.9854193
  },
  {
    lng: -43.1854284,
    lat: -22.9854116
  },
  {
    lng: -43.1856355,
    lat: -22.9853111
  },
  {
    lng: -43.1856933,
    lat: -22.985302
  },
  {
    lng: -43.1858432,
    lat: -22.9853863
  },
  {
    lng: -43.1858989,
    lat: -22.9854031
  },
  {
    lng: -43.1860697,
    lat: -22.98544
  },
  {
    lng: -43.1864159,
    lat: -22.9854916
  },
  {
    lng: -43.1865808,
    lat: -22.9855472
  },
  {
    lng: -43.1867513,
    lat: -22.9855858
  },
  {
    lng: -43.1869262,
    lat: -22.9855973
  },
  {
    lng: -43.1870398,
    lat: -22.9856227
  },
  {
    lng: -43.1871509,
    lat: -22.9856571
  },
  {
    lng: -43.1873805,
    lat: -22.9857812
  },
  {
    lng: -43.1874624,
    lat: -22.9858008
  },
  {
    lng: -43.1875791,
    lat: -22.9858017
  },
  {
    lng: -43.1877817,
    lat: -22.9857079
  },
  {
    lng: -43.1878083,
    lat: -22.9857058
  },
  {
    lng: -43.1878292,
    lat: -22.9857042
  },
  {
    lng: -43.1878393,
    lat: -22.985709
  },
  {
    lng: -43.1878708,
    lat: -22.9857573
  },
  {
    lng: -43.1879324,
    lat: -22.9858536
  },
  {
    lng: -43.1879607,
    lat: -22.9858653
  },
  {
    lng: -43.1880544,
    lat: -22.9858765
  },
  {
    lng: -43.1881773,
    lat: -22.9858264
  },
  {
    lng: -43.1883128,
    lat: -22.9857326
  },
  {
    lng: -43.1884313,
    lat: -22.9855961
  },
  {
    lng: -43.188593,
    lat: -22.9853121
  },
  {
    lng: -43.1886869,
    lat: -22.9851221
  },
  {
    lng: -43.188774,
    lat: -22.9849529
  },
  {
    lng: -43.1888197,
    lat: -22.9848159
  },
  {
    lng: -43.188884,
    lat: -22.9845369
  },
  {
    lng: -43.1889389,
    lat: -22.9843369
  },
  {
    lng: -43.1889675,
    lat: -22.9842237
  },
  {
    lng: -43.1890355,
    lat: -22.983912
  },
  {
    lng: -43.1890584,
    lat: -22.9838307
  },
  {
    lng: -43.1890729,
    lat: -22.983748
  },
  {
    lng: -43.1890906,
    lat: -22.983559
  },
  {
    lng: -43.1890906,
    lat: -22.9835084
  },
  {
    lng: -43.1890933,
    lat: -22.9834281
  },
  {
    lng: -43.1890986,
    lat: -22.9833416
  },
  {
    lng: -43.1891039,
    lat: -22.9832677
  },
  {
    lng: -43.1891134,
    lat: -22.983149
  },
  {
    lng: -43.1891094,
    lat: -22.9829749
  },
  {
    lng: -43.1891227,
    lat: -22.9828886
  },
  {
    lng: -43.1891187,
    lat: -22.98278
  },
  {
    lng: -43.1891193,
    lat: -22.9826964
  },
  {
    lng: -43.189112,
    lat: -22.9825416
  },
  {
    lng: -43.1891134,
    lat: -22.9824539
  },
  {
    lng: -43.1891013,
    lat: -22.982365
  },
  {
    lng: -43.1890999,
    lat: -22.9823194
  },
  {
    lng: -43.1890972,
    lat: -22.9822441
  },
  {
    lng: -43.1890932,
    lat: -22.982165
  },
  {
    lng: -43.1890866,
    lat: -22.9821057
  },
  {
    lng: -43.1890839,
    lat: -22.982028
  },
  {
    lng: -43.1890705,
    lat: -22.9819651
  },
  {
    lng: -43.189061,
    lat: -22.9819157
  },
  {
    lng: -43.189045,
    lat: -22.9818034
  },
  {
    lng: -43.1890368,
    lat: -22.9817453
  },
  {
    lng: -43.1890275,
    lat: -22.981649
  },
  {
    lng: -43.18901,
    lat: -22.9815069
  },
  {
    lng: -43.1890033,
    lat: -22.9814181
  },
  {
    lng: -43.1889912,
    lat: -22.9813046
  },
  {
    lng: -43.1889739,
    lat: -22.9811996
  },
  {
    lng: -43.1889658,
    lat: -22.9811291
  },
  {
    lng: -43.1889578,
    lat: -22.9810539
  },
  {
    lng: -43.1889417,
    lat: -22.9809453
  },
  {
    lng: -43.1889216,
    lat: -22.9808107
  },
  {
    lng: -43.1889216,
    lat: -22.9807045
  },
  {
    lng: -43.1889349,
    lat: -22.9806241
  },
  {
    lng: -43.1889416,
    lat: -22.9804957
  },
  {
    lng: -43.1889001,
    lat: -22.980181
  },
  {
    lng: -43.1887969,
    lat: -22.9799438
  },
  {
    lng: -43.188778,
    lat: -22.9798909
  },
  {
    lng: -43.1887445,
    lat: -22.9798118
  },
  {
    lng: -43.1887271,
    lat: -22.9797587
  },
  {
    lng: -43.1886037,
    lat: -22.9794537
  },
  {
    lng: -43.188546,
    lat: -22.9792019
  },
  {
    lng: -43.1885149,
    lat: -22.9790195
  },
  {
    lng: -43.1884917,
    lat: -22.9789019
  },
  {
    lng: -43.1884562,
    lat: -22.978771
  },
  {
    lng: -43.188353,
    lat: -22.9785696
  },
  {
    lng: -43.1881289,
    lat: -22.978287
  },
  {
    lng: -43.1879613,
    lat: -22.9780623
  },
  {
    lng: -43.1878553,
    lat: -22.9778981
  },
  {
    lng: -43.1877628,
    lat: -22.9777771
  },
  {
    lng: -43.1877119,
    lat: -22.9777141
  },
  {
    lng: -43.1876689,
    lat: -22.977661
  },
  {
    lng: -43.1875992,
    lat: -22.9775683
  },
  {
    lng: -43.1875,
    lat: -22.9774375
  },
  {
    lng: -43.1874035,
    lat: -22.9772968
  },
  {
    lng: -43.1872532,
    lat: -22.9771239
  },
  {
    lng: -43.1870882,
    lat: -22.9769819
  },
  {
    lng: -43.1869474,
    lat: -22.9768608
  },
  {
    lng: -43.1868549,
    lat: -22.9767708
  },
  {
    lng: -43.1867785,
    lat: -22.9767065
  },
  {
    lng: -43.186702,
    lat: -22.9766448
  },
  {
    lng: -43.1865854,
    lat: -22.976546
  },
  {
    lng: -43.1864674,
    lat: -22.9764139
  },
  {
    lng: -43.1863544,
    lat: -22.9762894
  },
  {
    lng: -43.1862246,
    lat: -22.97612
  },
  {
    lng: -43.186112,
    lat: -22.9759744
  },
  {
    lng: -43.1860489,
    lat: -22.9758867
  },
  {
    lng: -43.1859329,
    lat: -22.9757472
  },
  {
    lng: -43.1857968,
    lat: -22.9755521
  },
  {
    lng: -43.1857109,
    lat: -22.9754261
  },
  {
    lng: -43.1856386,
    lat: -22.9753126
  },
  {
    lng: -43.1855581,
    lat: -22.9751964
  },
  {
    lng: -43.1853837,
    lat: -22.9749434
  },
  {
    lng: -43.1852187,
    lat: -22.9746915
  },
  {
    lng: -43.1851652,
    lat: -22.9746088
  },
  {
    lng: -43.185078,
    lat: -22.9745014
  },
  {
    lng: -43.1849439,
    lat: -22.9743754
  },
  {
    lng: -43.184795,
    lat: -22.9741889
  },
  {
    lng: -43.1846824,
    lat: -22.9740371
  },
  {
    lng: -43.1845884,
    lat: -22.9739334
  },
  {
    lng: -43.1845227,
    lat: -22.9738753
  },
  {
    lng: -43.1843994,
    lat: -22.9737692
  },
  {
    lng: -43.1842036,
    lat: -22.9735579
  },
  {
    lng: -43.184052,
    lat: -22.9733839
  },
  {
    lng: -43.1839447,
    lat: -22.9732765
  },
  {
    lng: -43.1838174,
    lat: -22.9731568
  },
  {
    lng: -43.183749,
    lat: -22.9730827
  },
  {
    lng: -43.1836283,
    lat: -22.9729542
  },
  {
    lng: -43.1835424,
    lat: -22.9728752
  },
  {
    lng: -43.1835049,
    lat: -22.9728369
  },
  {
    lng: -43.1834672,
    lat: -22.9727987
  },
  {
    lng: -43.1834284,
    lat: -22.9727615
  },
  {
    lng: -43.1833359,
    lat: -22.9726826
  },
  {
    lng: -43.183254,
    lat: -22.9726061
  },
  {
    lng: -43.1831856,
    lat: -22.972548
  },
  {
    lng: -43.1831307,
    lat: -22.9724924
  },
  {
    lng: -43.1830663,
    lat: -22.9724344
  },
  {
    lng: -43.1829778,
    lat: -22.9723492
  },
  {
    lng: -43.1828919,
    lat: -22.9722579
  },
  {
    lng: -43.1828256,
    lat: -22.9722016
  },
  {
    lng: -43.1827445,
    lat: -22.9721369
  },
  {
    lng: -43.182662,
    lat: -22.9720757
  },
  {
    lng: -43.182562,
    lat: -22.972006
  },
  {
    lng: -43.182208,
    lat: -22.9717046
  },
  {
    lng: -43.1819371,
    lat: -22.9714823
  },
  {
    lng: -43.1813443,
    lat: -22.971033
  },
  {
    lng: -43.1797297,
    lat: -22.9699117
  },
  {
    lng: -43.1771253,
    lat: -22.9683461
  },
  {
    lng: -43.1768222,
    lat: -22.9681856
  },
  {
    lng: -43.1747032,
    lat: -22.9672101
  },
  {
    lng: -43.1741881,
    lat: -22.9670249
  },
  {
    lng: -43.1733031,
    lat: -22.9666717
  },
  {
    lng: -43.1728847,
    lat: -22.9664988
  },
  {
    lng: -43.1724608,
    lat: -22.9663284
  },
  {
    lng: -43.1714684,
    lat: -22.965884
  },
  {
    lng: -43.170315,
    lat: -22.9652937
  },
  {
    lng: -43.1690383,
    lat: -22.9646021
  },
  {
    lng: -43.1682874,
    lat: -22.9641971
  },
  {
    lng: -43.167528,
    lat: -22.9638073
  },
  {
    lng: -43.1670722,
    lat: -22.9635994
  },
  {
    lng: -43.1662273,
    lat: -22.963313
  },
  {
    lng: -43.1659001,
    lat: -22.9632512
  },
  {
    lng: -43.1651626,
    lat: -22.9631698
  },
  {
    lng: -43.1650364,
    lat: -22.9631797
  },
  {
    lng: -43.164889,
    lat: -22.963239
  },
  {
    lng: -43.1647843,
    lat: -22.9633241
  },
  {
    lng: -43.1647335,
    lat: -22.9634069
  },
  {
    lng: -43.1647264,
    lat: -22.9635003
  },
  {
    lng: -43.1647364,
    lat: -22.9635538
  },
  {
    lng: -43.1647648,
    lat: -22.9636011
  },
  {
    lng: -43.1647864,
    lat: -22.9637077
  },
  {
    lng: -43.1647867,
    lat: -22.9637619
  },
  {
    lng: -43.164762,
    lat: -22.9640857
  },
  {
    lng: -43.1647262,
    lat: -22.9642446
  },
  {
    lng: -43.1647237,
    lat: -22.9642986
  },
  {
    lng: -43.164705,
    lat: -22.9643501
  },
  {
    lng: -43.1645844,
    lat: -22.9644679
  },
  {
    lng: -43.1644932,
    lat: -22.9645359
  },
  {
    lng: -43.1644572,
    lat: -22.9645786
  },
  {
    lng: -43.1643551,
    lat: -22.9646314
  },
  {
    lng: -43.1643103,
    lat: -22.9646663
  },
  {
    lng: -43.1642057,
    lat: -22.964715
  },
  {
    lng: -43.1639789,
    lat: -22.9647681
  },
  {
    lng: -43.16387,
    lat: -22.9648074
  },
  {
    lng: -43.1638163,
    lat: -22.9649036
  },
  {
    lng: -43.1637655,
    lat: -22.9649306
  },
  {
    lng: -43.1636531,
    lat: -22.9649605
  },
  {
    lng: -43.1636005,
    lat: -22.9649841
  },
  {
    lng: -43.1635853,
    lat: -22.9650365
  },
  {
    lng: -43.1635599,
    lat: -22.9650853
  },
  {
    lng: -43.1634717,
    lat: -22.9651531
  },
  {
    lng: -43.1632804,
    lat: -22.965188
  },
  {
    lng: -43.1632316,
    lat: -22.965188
  },
  {
    lng: -43.1630368,
    lat: -22.9651808
  },
  {
    lng: -43.1629463,
    lat: -22.9651478
  },
  {
    lng: -43.1627829,
    lat: -22.9652466
  },
  {
    lng: -43.1625581,
    lat: -22.9653338
  },
  {
    lng: -43.1624634,
    lat: -22.9653538
  },
  {
    lng: -43.1622686,
    lat: -22.9653531
  },
  {
    lng: -43.1621742,
    lat: -22.9653314
  },
  {
    lng: -43.162085,
    lat: -22.9652949
  },
  {
    lng: -43.1619392,
    lat: -22.9653014
  },
  {
    lng: -43.1617982,
    lat: -22.9652665
  },
  {
    lng: -43.1617008,
    lat: -22.9652672
  },
  {
    lng: -43.1616043,
    lat: -22.9652546
  },
  {
    lng: -43.1614687,
    lat: -22.9652043
  },
  {
    lng: -43.1613716,
    lat: -22.9651954
  },
  {
    lng: -43.161325,
    lat: -22.9651824
  },
  {
    lng: -43.1610107,
    lat: -22.9650596
  },
  {
    lng: -43.1608166,
    lat: -22.9650455
  },
  {
    lng: -43.1605815,
    lat: -22.9649865
  },
  {
    lng: -43.1604914,
    lat: -22.9649517
  },
  {
    lng: -43.1602782,
    lat: -22.9648426
  },
  {
    lng: -43.1600308,
    lat: -22.9646982
  },
  {
    lng: -43.1599021,
    lat: -22.9646338
  },
  {
    lng: -43.1597465,
    lat: -22.9645251
  },
  {
    lng: -43.1596639,
    lat: -22.9644773
  },
  {
    lng: -43.1595276,
    lat: -22.9644286
  },
  {
    lng: -43.1594444,
    lat: -22.9643819
  },
  {
    lng: -43.1594127,
    lat: -22.9643476
  },
  {
    lng: -43.1593948,
    lat: -22.9643056
  },
  {
    lng: -43.159245,
    lat: -22.9640732
  },
  {
    lng: -43.1591522,
    lat: -22.9639685
  },
  {
    lng: -43.1590978,
    lat: -22.9638936
  },
  {
    lng: -43.1590505,
    lat: -22.9638146
  },
  {
    lng: -43.1589942,
    lat: -22.9636898
  },
  {
    lng: -43.1589463,
    lat: -22.9636112
  },
  {
    lng: -43.1588494,
    lat: -22.9635101
  },
  {
    lng: -43.1587574,
    lat: -22.9633018
  },
  {
    lng: -43.1586731,
    lat: -22.9631917
  },
  {
    lng: -43.1586582,
    lat: -22.9631027
  },
  {
    lng: -43.1586733,
    lat: -22.9630597
  },
  {
    lng: -43.1587013,
    lat: -22.9630228
  },
  {
    lng: -43.1587169,
    lat: -22.9629802
  },
  {
    lng: -43.1587338,
    lat: -22.9628003
  },
  {
    lng: -43.1587572,
    lat: -22.9626665
  },
  {
    lng: -43.1587739,
    lat: -22.9626241
  },
  {
    lng: -43.1587998,
    lat: -22.962586
  },
  {
    lng: -43.158838,
    lat: -22.9625579
  },
  {
    lng: -43.1589341,
    lat: -22.9625445
  },
  {
    lng: -43.1589789,
    lat: -22.9625265
  },
  {
    lng: -43.1591426,
    lat: -22.9624293
  },
  {
    lng: -43.1592319,
    lat: -22.9623926
  },
  {
    lng: -43.1593259,
    lat: -22.9623686
  },
  {
    lng: -43.1598129,
    lat: -22.9623688
  },
  {
    lng: -43.159837,
    lat: -22.9623295
  },
  {
    lng: -43.1598235,
    lat: -22.9622861
  },
  {
    lng: -43.1597725,
    lat: -22.9622097
  },
  {
    lng: -43.1597052,
    lat: -22.9621442
  },
  {
    lng: -43.1596776,
    lat: -22.9621071
  },
  {
    lng: -43.1596704,
    lat: -22.9620612
  },
  {
    lng: -43.1597181,
    lat: -22.9620516
  },
  {
    lng: -43.1597641,
    lat: -22.9620662
  },
  {
    lng: -43.1598061,
    lat: -22.962089
  },
  {
    lng: -43.1598194,
    lat: -22.9620842
  },
  {
    lng: -43.1598954,
    lat: -22.9620279
  },
  {
    lng: -43.1600272,
    lat: -22.9618946
  },
  {
    lng: -43.1601989,
    lat: -22.9618099
  },
  {
    lng: -43.1602321,
    lat: -22.9617766
  },
  {
    lng: -43.1602502,
    lat: -22.9617347
  },
  {
    lng: -43.1602435,
    lat: -22.9616618
  },
  {
    lng: -43.1602532,
    lat: -22.9616062
  },
  {
    lng: -43.1603023,
    lat: -22.9615624
  },
  {
    lng: -43.1603433,
    lat: -22.9615258
  },
  {
    lng: -43.1603731,
    lat: -22.9614591
  },
  {
    lng: -43.1604335,
    lat: -22.9613663
  },
  {
    lng: -43.1604825,
    lat: -22.9613104
  },
  {
    lng: -43.1604993,
    lat: -22.9612778
  },
  {
    lng: -43.1604821,
    lat: -22.9611711
  },
  {
    lng: -43.1604841,
    lat: -22.9610991
  },
  {
    lng: -43.1604618,
    lat: -22.9610301
  },
  {
    lng: -43.1604759,
    lat: -22.9609592
  },
  {
    lng: -43.160439,
    lat: -22.9609476
  },
  {
    lng: -43.1604,
    lat: -22.9609464
  },
  {
    lng: -43.1604015,
    lat: -22.9609103
  },
  {
    lng: -43.1604233,
    lat: -22.9608802
  },
  {
    lng: -43.1604614,
    lat: -22.9608882
  },
  {
    lng: -43.1604993,
    lat: -22.9608799
  },
  {
    lng: -43.1605298,
    lat: -22.9608572
  },
  {
    lng: -43.1605164,
    lat: -22.9608234
  },
  {
    lng: -43.1604144,
    lat: -22.9607704
  },
  {
    lng: -43.160321,
    lat: -22.9607052
  },
  {
    lng: -43.1602706,
    lat: -22.9606499
  },
  {
    lng: -43.1602154,
    lat: -22.9605545
  },
  {
    lng: -43.1601914,
    lat: -22.9605262
  },
  {
    lng: -43.160131,
    lat: -22.9604804
  },
  {
    lng: -43.1600514,
    lat: -22.9604013
  },
  {
    lng: -43.1599773,
    lat: -22.9603787
  },
  {
    lng: -43.1599458,
    lat: -22.9603573
  },
  {
    lng: -43.1599054,
    lat: -22.960256
  },
  {
    lng: -43.1599101,
    lat: -22.9602201
  },
  {
    lng: -43.1598342,
    lat: -22.9602358
  },
  {
    lng: -43.1598383,
    lat: -22.960164
  },
  {
    lng: -43.1598744,
    lat: -22.9601181
  },
  {
    lng: -43.159884,
    lat: -22.9600897
  },
  {
    lng: -43.1599274,
    lat: -22.9600362
  },
  {
    lng: -43.1599721,
    lat: -22.9600311
  },
  {
    lng: -43.1600119,
    lat: -22.9599716
  },
  {
    lng: -43.1600229,
    lat: -22.9599371
  },
  {
    lng: -43.1600022,
    lat: -22.9599065
  },
  {
    lng: -43.1599701,
    lat: -22.9598858
  },
  {
    lng: -43.1599317,
    lat: -22.9598231
  },
  {
    lng: -43.1599494,
    lat: -22.959791
  },
  {
    lng: -43.1599794,
    lat: -22.9597678
  },
  {
    lng: -43.1600518,
    lat: -22.9597415
  },
  {
    lng: -43.1601451,
    lat: -22.9596766
  },
  {
    lng: -43.1602207,
    lat: -22.9596515
  },
  {
    lng: -43.160245,
    lat: -22.9596316
  },
  {
    lng: -43.1602179,
    lat: -22.9596056
  },
  {
    lng: -43.1601476,
    lat: -22.9595752
  },
  {
    lng: -43.1601345,
    lat: -22.9595411
  },
  {
    lng: -43.1601489,
    lat: -22.95947
  },
  {
    lng: -43.1601939,
    lat: -22.9594112
  },
  {
    lng: -43.1601596,
    lat: -22.959394
  },
  {
    lng: -43.1597873,
    lat: -22.9592873
  },
  {
    lng: -43.1595971,
    lat: -22.9592485
  },
  {
    lng: -43.1594545,
    lat: -22.9591907
  },
  {
    lng: -43.1594256,
    lat: -22.9591661
  },
  {
    lng: -43.159335,
    lat: -22.9590067
  },
  {
    lng: -43.1592599,
    lat: -22.958924
  },
  {
    lng: -43.1592559,
    lat: -22.9588881
  },
  {
    lng: -43.1592637,
    lat: -22.9588484
  },
  {
    lng: -43.1594285,
    lat: -22.9586944
  },
  {
    lng: -43.1594557,
    lat: -22.9586727
  },
  {
    lng: -43.1595958,
    lat: -22.9586034
  },
  {
    lng: -43.1596617,
    lat: -22.9585655
  },
  {
    lng: -43.15969,
    lat: -22.9585407
  },
  {
    lng: -43.1597581,
    lat: -22.9585059
  },
  {
    lng: -43.1597826,
    lat: -22.958476
  },
  {
    lng: -43.1598741,
    lat: -22.9584098
  },
  {
    lng: -43.160067,
    lat: -22.958185
  },
  {
    lng: -43.1601098,
    lat: -22.9580844
  },
  {
    lng: -43.1601859,
    lat: -22.9580021
  },
  {
    lng: -43.1603157,
    lat: -22.9578909
  },
  {
    lng: -43.160073,
    lat: -22.9578512
  },
  {
    lng: -43.1597216,
    lat: -22.9577944
  },
  {
    lng: -43.1596908,
    lat: -22.9577808
  },
  {
    lng: -43.1596667,
    lat: -22.957755
  },
  {
    lng: -43.1596721,
    lat: -22.9577191
  },
  {
    lng: -43.1597002,
    lat: -22.9576834
  },
  {
    lng: -43.1598061,
    lat: -22.9576252
  },
  {
    lng: -43.1599106,
    lat: -22.9575798
  },
  {
    lng: -43.16001,
    lat: -22.9575537
  },
  {
    lng: -43.1600879,
    lat: -22.9575401
  },
  {
    lng: -43.1601508,
    lat: -22.9575178
  },
  {
    lng: -43.1601898,
    lat: -22.9574956
  },
  {
    lng: -43.1602581,
    lat: -22.95745
  },
  {
    lng: -43.1603211,
    lat: -22.9574203
  },
  {
    lng: -43.1604257,
    lat: -22.9573918
  },
  {
    lng: -43.1605277,
    lat: -22.9573746
  },
  {
    lng: -43.160649,
    lat: -22.9573684
  },
  {
    lng: -43.1607328,
    lat: -22.9573703
  },
  {
    lng: -43.1607604,
    lat: -22.9573635
  },
  {
    lng: -43.1607784,
    lat: -22.9573474
  },
  {
    lng: -43.1607831,
    lat: -22.9573264
  },
  {
    lng: -43.1607798,
    lat: -22.9572857
  },
  {
    lng: -43.1607832,
    lat: -22.9572573
  },
  {
    lng: -43.1608127,
    lat: -22.9572055
  },
  {
    lng: -43.1608396,
    lat: -22.9571839
  },
  {
    lng: -43.1608669,
    lat: -22.9571801
  },
  {
    lng: -43.1608939,
    lat: -22.9571838
  },
  {
    lng: -43.160916,
    lat: -22.9572047
  },
  {
    lng: -43.1609321,
    lat: -22.9572376
  },
  {
    lng: -43.1609534,
    lat: -22.9572592
  },
  {
    lng: -43.1609742,
    lat: -22.9572623
  },
  {
    lng: -43.160991,
    lat: -22.9572566
  },
  {
    lng: -43.1610119,
    lat: -22.9572363
  },
  {
    lng: -43.1610159,
    lat: -22.9572202
  },
  {
    lng: -43.1610104,
    lat: -22.9572055
  },
  {
    lng: -43.1609897,
    lat: -22.9571851
  },
  {
    lng: -43.1609816,
    lat: -22.9571733
  },
  {
    lng: -43.1609823,
    lat: -22.9571567
  },
  {
    lng: -43.1609911,
    lat: -22.9571442
  },
  {
    lng: -43.1610253,
    lat: -22.9571332
  },
  {
    lng: -43.1610682,
    lat: -22.9571332
  },
  {
    lng: -43.1611031,
    lat: -22.957143
  },
  {
    lng: -43.1611446,
    lat: -22.9571666
  },
  {
    lng: -43.1611748,
    lat: -22.9571912
  },
  {
    lng: -43.1611955,
    lat: -22.9572183
  },
  {
    lng: -43.1612029,
    lat: -22.9572518
  },
  {
    lng: -43.1611963,
    lat: -22.9572974
  },
  {
    lng: -43.1611856,
    lat: -22.9573369
  },
  {
    lng: -43.1611995,
    lat: -22.9573685
  },
  {
    lng: -43.161225,
    lat: -22.957387
  },
  {
    lng: -43.161268,
    lat: -22.9573969
  },
  {
    lng: -43.1614062,
    lat: -22.9573969
  },
  {
    lng: -43.1615309,
    lat: -22.9573795
  },
  {
    lng: -43.1616583,
    lat: -22.9573685
  },
  {
    lng: -43.1618279,
    lat: -22.9573356
  },
  {
    lng: -43.1618983,
    lat: -22.9573234
  },
  {
    lng: -43.1619554,
    lat: -22.9573166
  },
  {
    lng: -43.1619949,
    lat: -22.9573209
  },
  {
    lng: -43.1620511,
    lat: -22.9573424
  },
  {
    lng: -43.162121,
    lat: -22.957377
  },
  {
    lng: -43.1622094,
    lat: -22.9574265
  },
  {
    lng: -43.1623194,
    lat: -22.9574888
  },
  {
    lng: -43.1623844,
    lat: -22.9575222
  },
  {
    lng: -43.1624233,
    lat: -22.9575295
  },
  {
    lng: -43.1624449,
    lat: -22.9575258
  },
  {
    lng: -43.1624722,
    lat: -22.957508
  },
  {
    lng: -43.162491,
    lat: -22.9574771
  },
  {
    lng: -43.1625091,
    lat: -22.9574178
  },
  {
    lng: -43.162536,
    lat: -22.9573715
  },
  {
    lng: -43.1625615,
    lat: -22.9573233
  },
  {
    lng: -43.1625944,
    lat: -22.9572777
  },
  {
    lng: -43.1626346,
    lat: -22.957243
  },
  {
    lng: -43.1626781,
    lat: -22.9572152
  },
  {
    lng: -43.1627272,
    lat: -22.9571856
  },
  {
    lng: -43.1627842,
    lat: -22.9571524
  },
  {
    lng: -43.1628444,
    lat: -22.9571098
  },
  {
    lng: -43.1628881,
    lat: -22.9570732
  },
  {
    lng: -43.1629215,
    lat: -22.9570388
  },
  {
    lng: -43.1629577,
    lat: -22.9570017
  },
  {
    lng: -43.1630006,
    lat: -22.9569498
  },
  {
    lng: -43.1630343,
    lat: -22.9569066
  },
  {
    lng: -43.1630798,
    lat: -22.9568547
  },
  {
    lng: -43.1631294,
    lat: -22.9568201
  },
  {
    lng: -43.1631938,
    lat: -22.9567918
  },
  {
    lng: -43.1632891,
    lat: -22.9567608
  },
  {
    lng: -43.1634271,
    lat: -22.9567263
  },
  {
    lng: -43.1635854,
    lat: -22.956693
  },
  {
    lng: -43.1637531,
    lat: -22.9566571
  },
  {
    lng: -43.1638522,
    lat: -22.9566362
  },
  {
    lng: -43.1639649,
    lat: -22.9566139
  },
  {
    lng: -43.1640762,
    lat: -22.9565929
  },
  {
    lng: -43.1641769,
    lat: -22.9565706
  },
  {
    lng: -43.1642908,
    lat: -22.9565459
  },
  {
    lng: -43.1643801,
    lat: -22.9564947
  },
  {
    lng: -43.1644195,
    lat: -22.9564713
  },
  {
    lng: -43.1644397,
    lat: -22.9564522
  },
  {
    lng: -43.1644557,
    lat: -22.9564292
  },
  {
    lng: -43.1644692,
    lat: -22.9564009
  },
  {
    lng: -43.16448,
    lat: -22.9563705
  },
  {
    lng: -43.1645108,
    lat: -22.9562571
  },
  {
    lng: -43.1645295,
    lat: -22.9561595
  },
  {
    lng: -43.164543,
    lat: -22.9560842
  },
  {
    lng: -43.1645537,
    lat: -22.955989
  },
  {
    lng: -43.1645645,
    lat: -22.955842
  },
  {
    lng: -43.1645725,
    lat: -22.955684
  },
  {
    lng: -43.1645752,
    lat: -22.9555358
  },
  {
    lng: -43.1645725,
    lat: -22.9553877
  },
  {
    lng: -43.1645644,
    lat: -22.9552222
  },
  {
    lng: -43.1645578,
    lat: -22.9550889
  },
  {
    lng: -43.1645335,
    lat: -22.9549307
  },
  {
    lng: -43.164508,
    lat: -22.9547961
  },
  {
    lng: -43.1644746,
    lat: -22.9546664
  },
  {
    lng: -43.164425,
    lat: -22.9545146
  },
  {
    lng: -43.1643486,
    lat: -22.9543429
  },
  {
    lng: -43.1642735,
    lat: -22.9542491
  },
  {
    lng: -43.164189,
    lat: -22.9541824
  },
  {
    lng: -43.1640294,
    lat: -22.9541195
  },
  {
    lng: -43.1639059,
    lat: -22.9541009
  },
  {
    lng: -43.1637865,
    lat: -22.9540885
  },
  {
    lng: -43.1637008,
    lat: -22.9540985
  },
  {
    lng: -43.1636148,
    lat: -22.9541219
  },
  {
    lng: -43.163525,
    lat: -22.9541428
  },
  {
    lng: -43.1634754,
    lat: -22.9541429
  },
  {
    lng: -43.1634236,
    lat: -22.9541287
  },
  {
    lng: -43.1633901,
    lat: -22.9541104
  },
  {
    lng: -43.1633225,
    lat: -22.9540688
  },
  {
    lng: -43.1632596,
    lat: -22.9540071
  },
  {
    lng: -43.1632032,
    lat: -22.9539614
  },
  {
    lng: -43.1631416,
    lat: -22.9539316
  },
  {
    lng: -43.1630571,
    lat: -22.9539194
  },
  {
    lng: -43.1628867,
    lat: -22.9539145
  },
  {
    lng: -43.1627512,
    lat: -22.9538996
  },
  {
    lng: -43.1626118,
    lat: -22.9538674
  },
  {
    lng: -43.1624803,
    lat: -22.9538268
  },
  {
    lng: -43.1623932,
    lat: -22.9537971
  },
  {
    lng: -43.1623019,
    lat: -22.9537674
  },
  {
    lng: -43.1622041,
    lat: -22.9537365
  },
  {
    lng: -43.1621129,
    lat: -22.9537044
  },
  {
    lng: -43.1620378,
    lat: -22.9536761
  },
  {
    lng: -43.1619144,
    lat: -22.9536391
  },
  {
    lng: -43.1617936,
    lat: -22.9536113
  },
  {
    lng: -43.1617259,
    lat: -22.9535933
  },
  {
    lng: -43.161673,
    lat: -22.9535699
  },
  {
    lng: -43.1616253,
    lat: -22.9535409
  },
  {
    lng: -43.1615577,
    lat: -22.9534908
  },
  {
    lng: -43.1614959,
    lat: -22.9534458
  },
  {
    lng: -43.1614564,
    lat: -22.95341
  },
  {
    lng: -43.1614282,
    lat: -22.953376
  },
  {
    lng: -43.1614028,
    lat: -22.953347
  },
  {
    lng: -43.1613766,
    lat: -22.9533204
  },
  {
    lng: -43.1613599,
    lat: -22.9532982
  },
  {
    lng: -43.1613418,
    lat: -22.953276
  },
  {
    lng: -43.1613284,
    lat: -22.9532599
  },
  {
    lng: -43.1613055,
    lat: -22.9532383
  },
  {
    lng: -43.1612854,
    lat: -22.9532254
  },
  {
    lng: -43.1612485,
    lat: -22.9532143
  },
  {
    lng: -43.1612211,
    lat: -22.9532117
  },
  {
    lng: -43.1612043,
    lat: -22.9532099
  },
  {
    lng: -43.1611915,
    lat: -22.9532025
  },
  {
    lng: -43.1611801,
    lat: -22.9531908
  },
  {
    lng: -43.1611734,
    lat: -22.9531728
  },
  {
    lng: -43.1611621,
    lat: -22.9531555
  },
  {
    lng: -43.161146,
    lat: -22.9531395
  },
  {
    lng: -43.1611265,
    lat: -22.9531321
  },
  {
    lng: -43.161097,
    lat: -22.9531265
  },
  {
    lng: -43.1610696,
    lat: -22.9531192
  },
  {
    lng: -43.1610206,
    lat: -22.9531037
  },
  {
    lng: -43.1610024,
    lat: -22.9530871
  },
  {
    lng: -43.1609836,
    lat: -22.9530525
  },
  {
    lng: -43.1609742,
    lat: -22.9530073
  },
  {
    lng: -43.1609528,
    lat: -22.9529777
  },
  {
    lng: -43.1609246,
    lat: -22.9529574
  },
  {
    lng: -43.1608831,
    lat: -22.9529389
  },
  {
    lng: -43.1608469,
    lat: -22.9529153
  },
  {
    lng: -43.1608147,
    lat: -22.9528845
  },
  {
    lng: -43.1607718,
    lat: -22.9528308
  },
  {
    lng: -43.1607409,
    lat: -22.9527993
  },
  {
    lng: -43.1607115,
    lat: -22.952782
  },
  {
    lng: -43.1606866,
    lat: -22.9527813
  },
  {
    lng: -43.1606699,
    lat: -22.9527931
  },
  {
    lng: -43.1606565,
    lat: -22.9528128
  },
  {
    lng: -43.1606242,
    lat: -22.9528634
  },
  {
    lng: -43.1605458,
    lat: -22.9529512
  },
  {
    lng: -43.1605183,
    lat: -22.952974
  },
  {
    lng: -43.1604914,
    lat: -22.9529919
  },
  {
    lng: -43.1604579,
    lat: -22.9530042
  },
  {
    lng: -43.1603345,
    lat: -22.9530265
  },
  {
    lng: -43.1603051,
    lat: -22.9530451
  },
  {
    lng: -43.1602849,
    lat: -22.9530883
  },
  {
    lng: -43.1602756,
    lat: -22.953134
  },
  {
    lng: -43.1602581,
    lat: -22.9531649
  },
  {
    lng: -43.1601415,
    lat: -22.953239
  },
  {
    lng: -43.1600811,
    lat: -22.953276
  },
  {
    lng: -43.1600141,
    lat: -22.9533032
  },
  {
    lng: -43.1599256,
    lat: -22.9533229
  },
  {
    lng: -43.1598115,
    lat: -22.9533353
  },
  {
    lng: -43.1597003,
    lat: -22.9533415
  },
  {
    lng: -43.1595781,
    lat: -22.9533302
  },
  {
    lng: -43.1594078,
    lat: -22.9533019
  },
  {
    lng: -43.159243,
    lat: -22.9532648
  },
  {
    lng: -43.1591182,
    lat: -22.953229
  },
  {
    lng: -43.159031,
    lat: -22.953208
  },
  {
    lng: -43.1589721,
    lat: -22.9531993
  },
  {
    lng: -43.1588916,
    lat: -22.9532006
  },
  {
    lng: -43.1587629,
    lat: -22.9532217
  },
  {
    lng: -43.1586489,
    lat: -22.9532266
  },
  {
    lng: -43.1585374,
    lat: -22.9532204
  },
  {
    lng: -43.1583564,
    lat: -22.9531982
  },
  {
    lng: -43.1582398,
    lat: -22.9531846
  },
  {
    lng: -43.1580734,
    lat: -22.9531821
  },
  {
    lng: -43.1579126,
    lat: -22.9531784
  },
  {
    lng: -43.1577516,
    lat: -22.9531822
  },
  {
    lng: -43.1576538,
    lat: -22.9531759
  },
  {
    lng: -43.157525,
    lat: -22.9531648
  },
  {
    lng: -43.1575042,
    lat: -22.9531667
  },
  {
    lng: -43.1574854,
    lat: -22.9531796
  },
  {
    lng: -43.1574773,
    lat: -22.9532081
  },
  {
    lng: -43.1574679,
    lat: -22.9532581
  },
  {
    lng: -43.1574538,
    lat: -22.9532821
  },
  {
    lng: -43.1574338,
    lat: -22.9532908
  },
  {
    lng: -43.1574103,
    lat: -22.9532876
  },
  {
    lng: -43.1573573,
    lat: -22.95326
  },
  {
    lng: -43.1573077,
    lat: -22.9532117
  },
  {
    lng: -43.1572622,
    lat: -22.953187
  },
  {
    lng: -43.1572286,
    lat: -22.953187
  },
  {
    lng: -43.1571897,
    lat: -22.953197
  },
  {
    lng: -43.1571508,
    lat: -22.9532068
  },
  {
    lng: -43.1571212,
    lat: -22.9531982
  },
  {
    lng: -43.1570838,
    lat: -22.9531808
  },
  {
    lng: -43.1570435,
    lat: -22.9531808
  },
  {
    lng: -43.157014,
    lat: -22.9531896
  },
  {
    lng: -43.1569737,
    lat: -22.9532229
  },
  {
    lng: -43.1569321,
    lat: -22.9532729
  },
  {
    lng: -43.1569068,
    lat: -22.9533131
  },
  {
    lng: -43.1568852,
    lat: -22.9533272
  },
  {
    lng: -43.1568558,
    lat: -22.953329
  },
  {
    lng: -43.1568283,
    lat: -22.9533242
  },
  {
    lng: -43.1568055,
    lat: -22.9532982
  },
  {
    lng: -43.1567806,
    lat: -22.9532228
  },
  {
    lng: -43.1567632,
    lat: -22.9531957
  },
  {
    lng: -43.1567276,
    lat: -22.9531655
  },
  {
    lng: -43.1566714,
    lat: -22.9531408
  },
  {
    lng: -43.1565781,
    lat: -22.9531117
  },
  {
    lng: -43.1565285,
    lat: -22.9530894
  },
  {
    lng: -43.1564869,
    lat: -22.9530685
  },
  {
    lng: -43.1564548,
    lat: -22.9530474
  },
  {
    lng: -43.1564199,
    lat: -22.9530228
  },
  {
    lng: -43.1563783,
    lat: -22.9530056
  },
  {
    lng: -43.1563421,
    lat: -22.9529969
  },
  {
    lng: -43.15631,
    lat: -22.9529906
  },
  {
    lng: -43.1562523,
    lat: -22.9529957
  },
  {
    lng: -43.1562201,
    lat: -22.9530068
  },
  {
    lng: -43.1561811,
    lat: -22.953034
  },
  {
    lng: -43.156145,
    lat: -22.9530351
  },
  {
    lng: -43.1561142,
    lat: -22.953019
  },
  {
    lng: -43.1560981,
    lat: -22.9529759
  },
  {
    lng: -43.1560953,
    lat: -22.9529413
  },
  {
    lng: -43.1560846,
    lat: -22.9529191
  },
  {
    lng: -43.1560645,
    lat: -22.9529123
  },
  {
    lng: -43.1560451,
    lat: -22.9529153
  },
  {
    lng: -43.1560169,
    lat: -22.9529185
  },
  {
    lng: -43.155992,
    lat: -22.9529104
  },
  {
    lng: -43.1559712,
    lat: -22.9528895
  },
  {
    lng: -43.1559586,
    lat: -22.9528641
  },
  {
    lng: -43.1559137,
    lat: -22.9527424
  },
  {
    lng: -43.1558942,
    lat: -22.9527073
  },
  {
    lng: -43.1558687,
    lat: -22.9526851
  },
  {
    lng: -43.1558365,
    lat: -22.9526721
  },
  {
    lng: -43.1557184,
    lat: -22.9526524
  },
  {
    lng: -43.1556058,
    lat: -22.9526314
  },
  {
    lng: -43.1555723,
    lat: -22.9526116
  },
  {
    lng: -43.1555509,
    lat: -22.9525795
  },
  {
    lng: -43.1555442,
    lat: -22.9525078
  },
  {
    lng: -43.1555241,
    lat: -22.9524745
  },
  {
    lng: -43.1554906,
    lat: -22.9524548
  },
  {
    lng: -43.1554449,
    lat: -22.9524461
  },
  {
    lng: -43.1554006,
    lat: -22.9524559
  },
  {
    lng: -43.155339,
    lat: -22.9524968
  },
  {
    lng: -43.1552827,
    lat: -22.9525251
  },
  {
    lng: -43.1552424,
    lat: -22.9525351
  },
  {
    lng: -43.1551942,
    lat: -22.9525411
  },
  {
    lng: -43.1551592,
    lat: -22.9525412
  },
  {
    lng: -43.1551163,
    lat: -22.9525362
  },
  {
    lng: -43.1550787,
    lat: -22.9525264
  },
  {
    lng: -43.155056,
    lat: -22.9525263
  },
  {
    lng: -43.1550239,
    lat: -22.9525338
  },
  {
    lng: -43.1549647,
    lat: -22.9525702
  },
  {
    lng: -43.1549279,
    lat: -22.9525777
  },
  {
    lng: -43.1548883,
    lat: -22.9525789
  },
  {
    lng: -43.1548461,
    lat: -22.9525596
  },
  {
    lng: -43.1548098,
    lat: -22.9525301
  },
  {
    lng: -43.1547972,
    lat: -22.952493
  },
  {
    lng: -43.1547898,
    lat: -22.9524128
  },
  {
    lng: -43.154765,
    lat: -22.9523479
  },
  {
    lng: -43.1547421,
    lat: -22.9523177
  },
  {
    lng: -43.1547161,
    lat: -22.9522924
  },
  {
    lng: -43.1546831,
    lat: -22.9522708
  },
  {
    lng: -43.1545981,
    lat: -22.9522386
  },
  {
    lng: -43.1545322,
    lat: -22.9522231
  },
  {
    lng: -43.1544928,
    lat: -22.9522152
  },
  {
    lng: -43.1544666,
    lat: -22.9522177
  },
  {
    lng: -43.1544384,
    lat: -22.9522343
  },
  {
    lng: -43.154429,
    lat: -22.952254
  },
  {
    lng: -43.1544317,
    lat: -22.9522695
  },
  {
    lng: -43.1544625,
    lat: -22.9523128
  },
  {
    lng: -43.1544625,
    lat: -22.9523256
  },
  {
    lng: -43.1544551,
    lat: -22.9523324
  },
  {
    lng: -43.1543949,
    lat: -22.9523399
  },
  {
    lng: -43.1543646,
    lat: -22.9523418
  },
  {
    lng: -43.1543426,
    lat: -22.9523392
  },
  {
    lng: -43.1543265,
    lat: -22.9523307
  },
  {
    lng: -43.1543157,
    lat: -22.9523121
  },
  {
    lng: -43.154309,
    lat: -22.9522899
  },
  {
    lng: -43.1542828,
    lat: -22.9522343
  },
  {
    lng: -43.1542727,
    lat: -22.9522232
  },
  {
    lng: -43.154256,
    lat: -22.9522163
  },
  {
    lng: -43.1542365,
    lat: -22.9522146
  },
  {
    lng: -43.1542138,
    lat: -22.9522207
  },
  {
    lng: -43.1541857,
    lat: -22.9522324
  },
  {
    lng: -43.1541567,
    lat: -22.9522343
  },
  {
    lng: -43.1541346,
    lat: -22.9522245
  },
  {
    lng: -43.1541279,
    lat: -22.9521992
  },
  {
    lng: -43.1541299,
    lat: -22.9521398
  },
  {
    lng: -43.1541286,
    lat: -22.9521145
  },
  {
    lng: -43.1541198,
    lat: -22.952091
  },
  {
    lng: -43.1541038,
    lat: -22.9520805
  },
  {
    lng: -43.1540863,
    lat: -22.9520799
  },
  {
    lng: -43.1540635,
    lat: -22.9520928
  },
  {
    lng: -43.1540516,
    lat: -22.9521095
  },
  {
    lng: -43.1540474,
    lat: -22.9521429
  },
  {
    lng: -43.1540462,
    lat: -22.9522115
  },
  {
    lng: -43.1540388,
    lat: -22.9522342
  },
  {
    lng: -43.1540253,
    lat: -22.9522516
  },
  {
    lng: -43.1539979,
    lat: -22.9522671
  },
  {
    lng: -43.1539623,
    lat: -22.9522788
  },
  {
    lng: -43.1539349,
    lat: -22.9522787
  },
  {
    lng: -43.1539167,
    lat: -22.9522719
  },
  {
    lng: -43.1539074,
    lat: -22.9522516
  },
  {
    lng: -43.1539093,
    lat: -22.9522305
  },
  {
    lng: -43.1539274,
    lat: -22.9522059
  },
  {
    lng: -43.1539629,
    lat: -22.9521664
  },
  {
    lng: -43.1539676,
    lat: -22.9521509
  },
  {
    lng: -43.1539636,
    lat: -22.9521348
  },
  {
    lng: -43.1539515,
    lat: -22.9521225
  },
  {
    lng: -43.1539261,
    lat: -22.9521139
  },
  {
    lng: -43.1538812,
    lat: -22.9521052
  },
  {
    lng: -43.1538349,
    lat: -22.9520873
  },
  {
    lng: -43.153792,
    lat: -22.9520528
  },
  {
    lng: -43.1537659,
    lat: -22.952009
  },
  {
    lng: -43.153749,
    lat: -22.9519613
  },
  {
    lng: -43.1537249,
    lat: -22.9519059
  },
  {
    lng: -43.1537048,
    lat: -22.9518812
  },
  {
    lng: -43.1536786,
    lat: -22.9518644
  },
  {
    lng: -43.1536498,
    lat: -22.9518589
  },
  {
    lng: -43.1535821,
    lat: -22.9518509
  },
  {
    lng: -43.1535433,
    lat: -22.9518459
  },
  {
    lng: -43.1534937,
    lat: -22.9518336
  },
  {
    lng: -43.1534426,
    lat: -22.9518101
  },
  {
    lng: -43.1534064,
    lat: -22.9517879
  },
  {
    lng: -43.1533729,
    lat: -22.951773
  },
  {
    lng: -43.1533246,
    lat: -22.9517645
  },
  {
    lng: -43.1532441,
    lat: -22.9517509
  },
  {
    lng: -43.1531945,
    lat: -22.9517361
  },
  {
    lng: -43.1531248,
    lat: -22.9517162
  },
  {
    lng: -43.1530712,
    lat: -22.9517026
  },
  {
    lng: -43.1529814,
    lat: -22.9516891
  },
  {
    lng: -43.1529142,
    lat: -22.951678
  },
  {
    lng: -43.1528486,
    lat: -22.9516669
  },
  {
    lng: -43.1527601,
    lat: -22.9516484
  },
  {
    lng: -43.1526929,
    lat: -22.9516286
  },
  {
    lng: -43.1526152,
    lat: -22.9515989
  },
  {
    lng: -43.1525534,
    lat: -22.9515619
  },
  {
    lng: -43.1524716,
    lat: -22.9515051
  },
  {
    lng: -43.1524193,
    lat: -22.9514704
  },
  {
    lng: -43.152347,
    lat: -22.9514433
  },
  {
    lng: -43.1522706,
    lat: -22.9514482
  },
  {
    lng: -43.1522034,
    lat: -22.9514569
  },
  {
    lng: -43.1521069,
    lat: -22.9514594
  },
  {
    lng: -43.1520572,
    lat: -22.951452
  },
  {
    lng: -43.1520157,
    lat: -22.9514396
  },
  {
    lng: -43.1519635,
    lat: -22.9514162
  },
  {
    lng: -43.1519164,
    lat: -22.9514039
  },
  {
    lng: -43.1518696,
    lat: -22.9514038
  },
  {
    lng: -43.1517905,
    lat: -22.9514248
  },
  {
    lng: -43.1517246,
    lat: -22.951436
  },
  {
    lng: -43.1516434,
    lat: -22.951437
  },
  {
    lng: -43.1515825,
    lat: -22.9514508
  },
  {
    lng: -43.151388,
    lat: -22.9515174
  },
  {
    lng: -43.1513438,
    lat: -22.9515249
  },
  {
    lng: -43.1512875,
    lat: -22.9515174
  },
  {
    lng: -43.1511226,
    lat: -22.9514384
  },
  {
    lng: -43.1510488,
    lat: -22.9514173
  },
  {
    lng: -43.1509402,
    lat: -22.9513989
  },
  {
    lng: -43.1509012,
    lat: -22.9513877
  },
  {
    lng: -43.1508771,
    lat: -22.9513642
  },
  {
    lng: -43.150841,
    lat: -22.9513185
  },
  {
    lng: -43.1508127,
    lat: -22.9513025
  },
  {
    lng: -43.1505164,
    lat: -22.9512371
  },
  {
    lng: -43.1504373,
    lat: -22.9512062
  },
  {
    lng: -43.1503877,
    lat: -22.9511618
  },
  {
    lng: -43.15035,
    lat: -22.9510964
  },
  {
    lng: -43.1503285,
    lat: -22.9510394
  },
  {
    lng: -43.1503072,
    lat: -22.9509988
  },
  {
    lng: -43.1502763,
    lat: -22.9509604
  },
  {
    lng: -43.1501878,
    lat: -22.9508852
  },
  {
    lng: -43.150157,
    lat: -22.9508395
  },
  {
    lng: -43.1501509,
    lat: -22.9507827
  },
  {
    lng: -43.1501576,
    lat: -22.95074
  },
  {
    lng: -43.1501824,
    lat: -22.9507029
  },
  {
    lng: -43.1502253,
    lat: -22.9506678
  },
  {
    lng: -43.1505083,
    lat: -22.950522
  },
  {
    lng: -43.1505438,
    lat: -22.9504924
  },
  {
    lng: -43.1505646,
    lat: -22.9504596
  },
  {
    lng: -43.1505727,
    lat: -22.9504226
  },
  {
    lng: -43.1505741,
    lat: -22.9502874
  },
  {
    lng: -43.1505814,
    lat: -22.950251
  },
  {
    lng: -43.1506103,
    lat: -22.950209
  },
  {
    lng: -43.1506732,
    lat: -22.9501417
  },
  {
    lng: -43.1507128,
    lat: -22.9500843
  },
  {
    lng: -43.1507672,
    lat: -22.9499959
  },
  {
    lng: -43.1508074,
    lat: -22.9499453
  },
  {
    lng: -43.1508422,
    lat: -22.949912
  },
  {
    lng: -43.150869,
    lat: -22.9498886
  },
  {
    lng: -43.1508878,
    lat: -22.9498528
  },
  {
    lng: -43.1508851,
    lat: -22.9498181
  },
  {
    lng: -43.1508731,
    lat: -22.9497861
  },
  {
    lng: -43.1508435,
    lat: -22.9497527
  },
  {
    lng: -43.1508288,
    lat: -22.9497206
  },
  {
    lng: -43.1508234,
    lat: -22.9496847
  },
  {
    lng: -43.1508328,
    lat: -22.9496478
  },
  {
    lng: -43.1508476,
    lat: -22.9495897
  },
  {
    lng: -43.150849,
    lat: -22.949549
  },
  {
    lng: -43.1508382,
    lat: -22.9494958
  },
  {
    lng: -43.1508261,
    lat: -22.9494563
  },
  {
    lng: -43.1508128,
    lat: -22.9494192
  },
  {
    lng: -43.1508034,
    lat: -22.9493699
  },
  {
    lng: -43.1507994,
    lat: -22.9493254
  },
  {
    lng: -43.1508021,
    lat: -22.949276
  },
  {
    lng: -43.1508147,
    lat: -22.9492106
  },
  {
    lng: -43.1508262,
    lat: -22.9491784
  },
  {
    lng: -43.1508462,
    lat: -22.9491543
  },
  {
    lng: -43.1508737,
    lat: -22.949145
  },
  {
    lng: -43.1509099,
    lat: -22.94915
  },
  {
    lng: -43.1509515,
    lat: -22.9491543
  },
  {
    lng: -43.150977,
    lat: -22.949147
  },
  {
    lng: -43.1509897,
    lat: -22.9491333
  },
  {
    lng: -43.1509951,
    lat: -22.9491069
  },
  {
    lng: -43.1509905,
    lat: -22.9490666
  },
  {
    lng: -43.1509912,
    lat: -22.9490451
  },
  {
    lng: -43.1510045,
    lat: -22.9490302
  },
  {
    lng: -43.1510267,
    lat: -22.9490228
  },
  {
    lng: -43.1510629,
    lat: -22.949013
  },
  {
    lng: -43.1510856,
    lat: -22.9489858
  },
  {
    lng: -43.1511024,
    lat: -22.9489451
  },
  {
    lng: -43.1511124,
    lat: -22.9489012
  },
  {
    lng: -43.1511253,
    lat: -22.9488444
  },
  {
    lng: -43.151136,
    lat: -22.948827
  },
  {
    lng: -43.1511527,
    lat: -22.9488185
  },
  {
    lng: -43.1511734,
    lat: -22.9488153
  },
  {
    lng: -43.1511956,
    lat: -22.9488247
  },
  {
    lng: -43.1512359,
    lat: -22.9488573
  },
  {
    lng: -43.1512926,
    lat: -22.9489064
  },
  {
    lng: -43.1513231,
    lat: -22.9489265
  },
  {
    lng: -43.1513529,
    lat: -22.9489389
  },
  {
    lng: -43.1513991,
    lat: -22.9489497
  },
  {
    lng: -43.1514578,
    lat: -22.9489524
  },
  {
    lng: -43.1515168,
    lat: -22.9489438
  },
  {
    lng: -43.1515878,
    lat: -22.9489068
  },
  {
    lng: -43.1516294,
    lat: -22.9488795
  },
  {
    lng: -43.1516629,
    lat: -22.9488364
  },
  {
    lng: -43.1516737,
    lat: -22.9487918
  },
  {
    lng: -43.1516751,
    lat: -22.9487364
  },
  {
    lng: -43.1516885,
    lat: -22.9486388
  },
  {
    lng: -43.1517113,
    lat: -22.9485647
  },
  {
    lng: -43.1517729,
    lat: -22.9484498
  },
  {
    lng: -43.1518562,
    lat: -22.9483571
  },
  {
    lng: -43.1519848,
    lat: -22.9482694
  },
  {
    lng: -43.152115,
    lat: -22.9481978
  },
  {
    lng: -43.1522612,
    lat: -22.9481546
  },
  {
    lng: -43.1525387,
    lat: -22.9480905
  },
  {
    lng: -43.15265,
    lat: -22.9480732
  },
  {
    lng: -43.1527144,
    lat: -22.9480595
  },
  {
    lng: -43.1527762,
    lat: -22.9480312
  },
  {
    lng: -43.1528674,
    lat: -22.9479731
  },
  {
    lng: -43.1529343,
    lat: -22.9479434
  },
  {
    lng: -43.1530188,
    lat: -22.9479188
  },
  {
    lng: -43.1531316,
    lat: -22.9479027
  },
  {
    lng: -43.1532228,
    lat: -22.9478898
  },
  {
    lng: -43.1532992,
    lat: -22.9478731
  },
  {
    lng: -43.1533608,
    lat: -22.9478551
  },
  {
    lng: -43.1533903,
    lat: -22.9478437
  },
  {
    lng: -43.1534272,
    lat: -22.9478317
  },
  {
    lng: -43.1535023,
    lat: -22.9478008
  },
  {
    lng: -43.1535888,
    lat: -22.9477557
  },
  {
    lng: -43.1536905,
    lat: -22.9477017
  },
  {
    lng: -43.1537289,
    lat: -22.9476681
  },
  {
    lng: -43.1537712,
    lat: -22.9476354
  },
  {
    lng: -43.1538014,
    lat: -22.9476254
  },
  {
    lng: -43.153833,
    lat: -22.9476261
  },
  {
    lng: -43.1538825,
    lat: -22.9476354
  },
  {
    lng: -43.1539274,
    lat: -22.9476372
  },
  {
    lng: -43.1539684,
    lat: -22.9476274
  },
  {
    lng: -43.1540139,
    lat: -22.9476027
  },
  {
    lng: -43.1540595,
    lat: -22.9475723
  },
  {
    lng: -43.1541494,
    lat: -22.947489
  },
  {
    lng: -43.1541923,
    lat: -22.9474569
  },
  {
    lng: -43.1542607,
    lat: -22.9474112
  },
  {
    lng: -43.1543332,
    lat: -22.9473804
  },
  {
    lng: -43.1544464,
    lat: -22.9473544
  },
  {
    lng: -43.1545223,
    lat: -22.9473254
  },
  {
    lng: -43.1545899,
    lat: -22.9473142
  },
  {
    lng: -43.1546462,
    lat: -22.9473199
  },
  {
    lng: -43.1546899,
    lat: -22.9473303
  },
  {
    lng: -43.1547327,
    lat: -22.9473356
  },
  {
    lng: -43.1547643,
    lat: -22.9473348
  },
  {
    lng: -43.1547977,
    lat: -22.9473164
  },
  {
    lng: -43.1548194,
    lat: -22.9472865
  },
  {
    lng: -43.15484,
    lat: -22.9472558
  },
  {
    lng: -43.1548686,
    lat: -22.9472312
  },
  {
    lng: -43.1548996,
    lat: -22.9472092
  },
  {
    lng: -43.1549331,
    lat: -22.9471907
  },
  {
    lng: -43.1549638,
    lat: -22.9471685
  },
  {
    lng: -43.1549899,
    lat: -22.9471418
  },
  {
    lng: -43.1550139,
    lat: -22.9471131
  },
  {
    lng: -43.1550332,
    lat: -22.9470818
  },
  {
    lng: -43.1550511,
    lat: -22.9470498
  },
  {
    lng: -43.1550682,
    lat: -22.9470173
  },
  {
    lng: -43.1550851,
    lat: -22.9469848
  },
  {
    lng: -43.1551073,
    lat: -22.946955
  },
  {
    lng: -43.1551306,
    lat: -22.9469261
  },
  {
    lng: -43.1551504,
    lat: -22.946895
  },
  {
    lng: -43.1551683,
    lat: -22.9468629
  },
  {
    lng: -43.1551862,
    lat: -22.9468307
  },
  {
    lng: -43.1552083,
    lat: -22.9468009
  },
  {
    lng: -43.1552254,
    lat: -22.9467685
  },
  {
    lng: -43.1552385,
    lat: -22.9467344
  },
  {
    lng: -43.1552481,
    lat: -22.9466995
  },
  {
    lng: -43.1552505,
    lat: -22.9466635
  },
  {
    lng: -43.1552512,
    lat: -22.9466273
  },
  {
    lng: -43.1552548,
    lat: -22.9465914
  },
  {
    lng: -43.155263,
    lat: -22.946556
  },
  {
    lng: -43.155274,
    lat: -22.9465213
  },
  {
    lng: -43.1552855,
    lat: -22.9464869
  },
  {
    lng: -43.1552963,
    lat: -22.9464522
  },
  {
    lng: -43.1553082,
    lat: -22.9464178
  },
  {
    lng: -43.1553227,
    lat: -22.9463841
  },
  {
    lng: -43.1553296,
    lat: -22.9463487
  },
  {
    lng: -43.1553298,
    lat: -22.9463126
  },
  {
    lng: -43.1553274,
    lat: -22.9462766
  },
  {
    lng: -43.1553234,
    lat: -22.9462406
  },
  {
    lng: -43.1553181,
    lat: -22.9462047
  },
  {
    lng: -43.1553186,
    lat: -22.9461686
  },
  {
    lng: -43.1553277,
    lat: -22.9461336
  },
  {
    lng: -43.1553369,
    lat: -22.9460985
  },
  {
    lng: -43.155348,
    lat: -22.9460639
  },
  {
    lng: -43.1553607,
    lat: -22.9460297
  },
  {
    lng: -43.155372,
    lat: -22.945995
  },
  {
    lng: -43.1553793,
    lat: -22.9459596
  },
  {
    lng: -43.1553858,
    lat: -22.945924
  },
  {
    lng: -43.1553931,
    lat: -22.9458885
  },
  {
    lng: -43.1554007,
    lat: -22.9458532
  },
  {
    lng: -43.1554091,
    lat: -22.9458179
  },
  {
    lng: -43.1554181,
    lat: -22.9457826
  },
  {
    lng: -43.1554272,
    lat: -22.9457475
  },
  {
    lng: -43.1554429,
    lat: -22.9457145
  },
  {
    lng: -43.1554721,
    lat: -22.9456905
  },
  {
    lng: -43.1554975,
    lat: -22.9456633
  },
  {
    lng: -43.1555113,
    lat: -22.9456295
  },
  {
    lng: -43.1555199,
    lat: -22.9455942
  },
  {
    lng: -43.1555272,
    lat: -22.9455587
  },
  {
    lng: -43.1555348,
    lat: -22.9455233
  },
  {
    lng: -43.1555429,
    lat: -22.9454879
  },
  {
    lng: -43.1555529,
    lat: -22.9454531
  },
  {
    lng: -43.1555679,
    lat: -22.9454198
  },
  {
    lng: -43.1555837,
    lat: -22.9453867
  },
  {
    lng: -43.1555975,
    lat: -22.945353
  },
  {
    lng: -43.1556093,
    lat: -22.9453185
  },
  {
    lng: -43.1556127,
    lat: -22.9452826
  },
  {
    lng: -43.155613,
    lat: -22.9452464
  },
  {
    lng: -43.1556125,
    lat: -22.9452103
  },
  {
    lng: -43.1556111,
    lat: -22.9451742
  },
  {
    lng: -43.155611,
    lat: -22.9451381
  },
  {
    lng: -43.1556147,
    lat: -22.9451022
  },
  {
    lng: -43.1556193,
    lat: -22.9450663
  },
  {
    lng: -43.1556234,
    lat: -22.9450304
  },
  {
    lng: -43.1556263,
    lat: -22.9449944
  },
  {
    lng: -43.1556341,
    lat: -22.9449589
  },
  {
    lng: -43.1556463,
    lat: -22.9449245
  },
  {
    lng: -43.1556613,
    lat: -22.9448914
  },
  {
    lng: -43.1556761,
    lat: -22.944858
  },
  {
    lng: -43.1556864,
    lat: -22.9448232
  },
  {
    lng: -43.1556895,
    lat: -22.9447872
  },
  {
    lng: -43.155692,
    lat: -22.9447511
  },
  {
    lng: -43.155699,
    lat: -22.9447155
  },
  {
    lng: -43.1557003,
    lat: -22.9446795
  },
  {
    lng: -43.1557007,
    lat: -22.9446433
  },
  {
    lng: -43.1557009,
    lat: -22.9446072
  },
  {
    lng: -43.1557011,
    lat: -22.9445711
  },
  {
    lng: -43.1557025,
    lat: -22.9445349
  },
  {
    lng: -43.1557101,
    lat: -22.9444995
  },
  {
    lng: -43.1557217,
    lat: -22.9444651
  },
  {
    lng: -43.1557344,
    lat: -22.9444309
  },
  {
    lng: -43.1557493,
    lat: -22.9443976
  },
  {
    lng: -43.1557589,
    lat: -22.9443626
  },
  {
    lng: -43.155769,
    lat: -22.9443276
  },
  {
    lng: -43.1557773,
    lat: -22.9442923
  },
  {
    lng: -43.1557846,
    lat: -22.9442569
  },
  {
    lng: -43.155794,
    lat: -22.9442218
  },
  {
    lng: -43.1558037,
    lat: -22.9441869
  },
  {
    lng: -43.155811,
    lat: -22.9441513
  },
  {
    lng: -43.1558214,
    lat: -22.9441166
  },
  {
    lng: -43.1558358,
    lat: -22.944083
  },
  {
    lng: -43.1558496,
    lat: -22.9440493
  },
  {
    lng: -43.1558569,
    lat: -22.9440138
  },
  {
    lng: -43.1558623,
    lat: -22.943978
  },
  {
    lng: -43.1558705,
    lat: -22.9439427
  },
  {
    lng: -43.1558802,
    lat: -22.9439076
  },
  {
    lng: -43.1558901,
    lat: -22.9438726
  },
  {
    lng: -43.1559006,
    lat: -22.9438379
  },
  {
    lng: -43.1559104,
    lat: -22.943803
  },
  {
    lng: -43.1559196,
    lat: -22.943768
  },
  {
    lng: -43.1559282,
    lat: -22.9437327
  },
  {
    lng: -43.1559361,
    lat: -22.9436972
  },
  {
    lng: -43.1559466,
    lat: -22.9436625
  },
  {
    lng: -43.1559574,
    lat: -22.9436278
  },
  {
    lng: -43.1559688,
    lat: -22.9435934
  },
  {
    lng: -43.1559782,
    lat: -22.9435581
  },
  {
    lng: -43.1559837,
    lat: -22.9435225
  },
  {
    lng: -43.155986,
    lat: -22.9434864
  },
  {
    lng: -43.1559797,
    lat: -22.9434507
  },
  {
    lng: -43.1559682,
    lat: -22.9434339
  },
  {
    lng: -43.1559592,
    lat: -22.9434201
  },
  {
    lng: -43.1559273,
    lat: -22.9433991
  },
  {
    lng: -43.1559027,
    lat: -22.9433711
  },
  {
    lng: -43.1559308,
    lat: -22.9433458
  },
  {
    lng: -43.1558926,
    lat: -22.9433382
  },
  {
    lng: -43.1558161,
    lat: -22.9433243
  },
  {
    lng: -43.1557439,
    lat: -22.9432972
  },
  {
    lng: -43.155648,
    lat: -22.9432349
  },
  {
    lng: -43.1553109,
    lat: -22.9429897
  },
  {
    lng: -43.155273,
    lat: -22.9429269
  },
  {
    lng: -43.1551561,
    lat: -22.9428316
  },
  {
    lng: -43.1551076,
    lat: -22.9427754
  },
  {
    lng: -43.1551031,
    lat: -22.9427396
  },
  {
    lng: -43.1551163,
    lat: -22.9426683
  },
  {
    lng: -43.1550752,
    lat: -22.9426073
  },
  {
    lng: -43.1550197,
    lat: -22.9425567
  },
  {
    lng: -43.1549982,
    lat: -22.9425265
  },
  {
    lng: -43.1549631,
    lat: -22.9425106
  },
  {
    lng: -43.1548469,
    lat: -22.9424994
  },
  {
    lng: -43.15481,
    lat: -22.9424879
  },
  {
    lng: -43.1547268,
    lat: -22.9424415
  },
  {
    lng: -43.1546501,
    lat: -22.9423598
  },
  {
    lng: -43.1546172,
    lat: -22.9423151
  },
  {
    lng: -43.1545781,
    lat: -22.9422132
  },
  {
    lng: -43.1545685,
    lat: -22.9421052
  },
  {
    lng: -43.1545737,
    lat: -22.941943
  },
  {
    lng: -43.1545436,
    lat: -22.9418965
  },
  {
    lng: -43.154485,
    lat: -22.9418938
  },
  {
    lng: -43.154395,
    lat: -22.9418252
  },
  {
    lng: -43.1543368,
    lat: -22.94183
  },
  {
    lng: -43.1542808,
    lat: -22.9418144
  },
  {
    lng: -43.1542226,
    lat: -22.9418196
  },
  {
    lng: -43.1542121,
    lat: -22.9418729
  },
  {
    lng: -43.1541674,
    lat: -22.9419077
  },
  {
    lng: -43.1538817,
    lat: -22.9419653
  },
  {
    lng: -43.1538344,
    lat: -22.9419973
  },
  {
    lng: -43.1537849,
    lat: -22.9419991
  },
  {
    lng: -43.1536803,
    lat: -22.9420376
  },
  {
    lng: -43.1536225,
    lat: -22.9420462
  },
  {
    lng: -43.1535688,
    lat: -22.9420675
  },
  {
    lng: -43.1535108,
    lat: -22.9420743
  },
  {
    lng: -43.1534522,
    lat: -22.9420716
  },
  {
    lng: -43.1530543,
    lat: -22.941983
  },
  {
    lng: -43.1529966,
    lat: -22.9419743
  },
  {
    lng: -43.1529383,
    lat: -22.9419796
  },
  {
    lng: -43.1528806,
    lat: -22.9419711
  },
  {
    lng: -43.1527686,
    lat: -22.9419398
  },
  {
    lng: -43.1525382,
    lat: -22.9419053
  },
  {
    lng: -43.1524892,
    lat: -22.9418755
  },
  {
    lng: -43.1524338,
    lat: -22.9418926
  },
  {
    lng: -43.1523755,
    lat: -22.941895
  },
  {
    lng: -43.1522652,
    lat: -22.9418585
  },
  {
    lng: -43.1522149,
    lat: -22.9418309
  },
  {
    lng: -43.1521765,
    lat: -22.94179
  },
  {
    lng: -43.152129,
    lat: -22.9417602
  },
  {
    lng: -43.1520813,
    lat: -22.941741
  },
  {
    lng: -43.1519643,
    lat: -22.9417182
  },
  {
    lng: -43.1519198,
    lat: -22.9416829
  },
  {
    lng: -43.1519272,
    lat: -22.9416293
  },
  {
    lng: -43.1519589,
    lat: -22.9415838
  },
  {
    lng: -43.151944,
    lat: -22.9415314
  },
  {
    lng: -43.1518109,
    lat: -22.9413539
  },
  {
    lng: -43.1516664,
    lat: -22.9412619
  },
  {
    lng: -43.151639,
    lat: -22.9412141
  },
  {
    lng: -43.1515974,
    lat: -22.9411759
  },
  {
    lng: -43.1515738,
    lat: -22.9411263
  },
  {
    lng: -43.1515353,
    lat: -22.9410857
  },
  {
    lng: -43.151479,
    lat: -22.9410704
  },
  {
    lng: -43.1514312,
    lat: -22.9410394
  },
  {
    lng: -43.1513765,
    lat: -22.9410203
  },
  {
    lng: -43.1513325,
    lat: -22.9409958
  },
  {
    lng: -43.1513226,
    lat: -22.9409424
  },
  {
    lng: -43.1513551,
    lat: -22.9408972
  },
  {
    lng: -43.1514198,
    lat: -22.940875
  },
  {
    lng: -43.1514218,
    lat: -22.9408629
  },
  {
    lng: -43.1513576,
    lat: -22.940854
  },
  {
    lng: -43.1513062,
    lat: -22.940828
  },
  {
    lng: -43.1512993,
    lat: -22.9407742
  },
  {
    lng: -43.151282,
    lat: -22.9407226
  },
  {
    lng: -43.1512241,
    lat: -22.9407155
  },
  {
    lng: -43.1512176,
    lat: -22.9407041
  },
  {
    lng: -43.1512404,
    lat: -22.9406918
  },
  {
    lng: -43.1511835,
    lat: -22.9406798
  },
  {
    lng: -43.1511694,
    lat: -22.9406271
  },
  {
    lng: -43.1512054,
    lat: -22.9405846
  },
  {
    lng: -43.1512146,
    lat: -22.9405329
  },
  {
    lng: -43.1511566,
    lat: -22.9405388
  },
  {
    lng: -43.1511029,
    lat: -22.9405604
  },
  {
    lng: -43.1510757,
    lat: -22.940531
  },
  {
    lng: -43.1510208,
    lat: -22.9405124
  },
  {
    lng: -43.1510408,
    lat: -22.9404617
  },
  {
    lng: -43.1510925,
    lat: -22.9403745
  },
  {
    lng: -43.1511329,
    lat: -22.9403714
  },
  {
    lng: -43.1511587,
    lat: -22.9403463
  },
  {
    lng: -43.1512018,
    lat: -22.9402875
  },
  {
    lng: -43.1512387,
    lat: -22.9402574
  },
  {
    lng: -43.1513043,
    lat: -22.9401528
  },
  {
    lng: -43.1513767,
    lat: -22.9400808
  },
  {
    lng: -43.1514222,
    lat: -22.9400514
  },
  {
    lng: -43.1514821,
    lat: -22.9400157
  },
  {
    lng: -43.1515464,
    lat: -22.9400012
  },
  {
    lng: -43.1516396,
    lat: -22.9400261
  },
  {
    lng: -43.151732,
    lat: -22.9399695
  },
  {
    lng: -43.1517365,
    lat: -22.9398613
  },
  {
    lng: -43.1516857,
    lat: -22.9398346
  },
  {
    lng: -43.151721,
    lat: -22.9397915
  },
  {
    lng: -43.1518841,
    lat: -22.939822
  },
  {
    lng: -43.1518994,
    lat: -22.9397797
  },
  {
    lng: -43.151935,
    lat: -22.9397242
  },
  {
    lng: -43.1519403,
    lat: -22.9396786
  },
  {
    lng: -43.1519682,
    lat: -22.9396309
  },
  {
    lng: -43.1520432,
    lat: -22.9395479
  },
  {
    lng: -43.1520944,
    lat: -22.9395219
  },
  {
    lng: -43.1521516,
    lat: -22.9395098
  },
  {
    lng: -43.15221,
    lat: -22.939509
  },
  {
    lng: -43.1522603,
    lat: -22.9394815
  },
  {
    lng: -43.1523732,
    lat: -22.939458
  },
  {
    lng: -43.1524213,
    lat: -22.9394345
  },
  {
    lng: -43.1521892,
    lat: -22.9394436
  },
  {
    lng: -43.1521543,
    lat: -22.9394381
  },
  {
    lng: -43.1521053,
    lat: -22.9393914
  },
  {
    lng: -43.1519945,
    lat: -22.9393571
  },
  {
    lng: -43.1519573,
    lat: -22.9393153
  },
  {
    lng: -43.151907,
    lat: -22.9392876
  },
  {
    lng: -43.1517465,
    lat: -22.9392225
  },
  {
    lng: -43.1516075,
    lat: -22.9391233
  },
  {
    lng: -43.151538,
    lat: -22.9390363
  },
  {
    lng: -43.1515211,
    lat: -22.9389844
  },
  {
    lng: -43.151548,
    lat: -22.9389362
  },
  {
    lng: -43.1516168,
    lat: -22.9388486
  },
  {
    lng: -43.1516894,
    lat: -22.9387007
  },
  {
    lng: -43.1517429,
    lat: -22.938679
  },
  {
    lng: -43.1519183,
    lat: -22.9386766
  },
  {
    lng: -43.1519698,
    lat: -22.9386508
  },
  {
    lng: -43.152143,
    lat: -22.938626
  },
  {
    lng: -43.1523183,
    lat: -22.9386268
  },
  {
    lng: -43.1524821,
    lat: -22.9386428
  },
  {
    lng: -43.1528938,
    lat: -22.938809
  },
  {
    lng: -43.1529752,
    lat: -22.9388025
  },
  {
    lng: -43.1531308,
    lat: -22.9388773
  },
  {
    lng: -43.1532392,
    lat: -22.938918
  },
  {
    lng: -43.1532546,
    lat: -22.9389105
  },
  {
    lng: -43.1532528,
    lat: -22.9388023
  },
  {
    lng: -43.1533076,
    lat: -22.9387831
  },
  {
    lng: -43.153413,
    lat: -22.9388301
  },
  {
    lng: -43.1534339,
    lat: -22.9387796
  },
  {
    lng: -43.1534304,
    lat: -22.9387255
  },
  {
    lng: -43.15345,
    lat: -22.9386894
  },
  {
    lng: -43.1534991,
    lat: -22.9386627
  },
  {
    lng: -43.1535516,
    lat: -22.9386867
  },
  {
    lng: -43.1536001,
    lat: -22.9387166
  },
  {
    lng: -43.1536387,
    lat: -22.9387576
  },
  {
    lng: -43.1536859,
    lat: -22.9387895
  },
  {
    lng: -43.1537416,
    lat: -22.9388061
  },
  {
    lng: -43.1538853,
    lat: -22.9388992
  },
  {
    lng: -43.1539405,
    lat: -22.9389172
  },
  {
    lng: -43.1540245,
    lat: -22.9389924
  },
  {
    lng: -43.1540771,
    lat: -22.9390159
  },
  {
    lng: -43.1541179,
    lat: -22.9390547
  },
  {
    lng: -43.1540734,
    lat: -22.9390693
  },
  {
    lng: -43.15409,
    lat: -22.9391213
  },
  {
    lng: -43.1541168,
    lat: -22.9391695
  },
  {
    lng: -43.1542099,
    lat: -22.939235
  },
  {
    lng: -43.1542207,
    lat: -22.9392884
  },
  {
    lng: -43.1543543,
    lat: -22.9393936
  },
  {
    lng: -43.1544254,
    lat: -22.9394797
  },
  {
    lng: -43.1545365,
    lat: -22.9395138
  },
  {
    lng: -43.1546362,
    lat: -22.9395704
  },
  {
    lng: -43.1546915,
    lat: -22.9395881
  },
  {
    lng: -43.1548456,
    lat: -22.9395984
  },
  {
    lng: -43.154982,
    lat: -22.939638
  },
  {
    lng: -43.1551752,
    lat: -22.9396607
  },
  {
    lng: -43.1552836,
    lat: -22.9396988
  },
  {
    lng: -43.1553508,
    lat: -22.9397357
  },
  {
    lng: -43.1554098,
    lat: -22.9397827
  },
  {
    lng: -43.1554355,
    lat: -22.9398098
  },
  {
    lng: -43.1554946,
    lat: -22.9399028
  },
  {
    lng: -43.1555814,
    lat: -22.9399754
  },
  {
    lng: -43.1557157,
    lat: -22.9401062
  },
  {
    lng: -43.1561021,
    lat: -22.94035
  },
  {
    lng: -43.1562351,
    lat: -22.9404254
  },
  {
    lng: -43.1563761,
    lat: -22.9404998
  },
  {
    lng: -43.156701,
    lat: -22.9406871
  },
  {
    lng: -43.1568792,
    lat: -22.9407598
  },
  {
    lng: -43.1569909,
    lat: -22.9407919
  },
  {
    lng: -43.1571314,
    lat: -22.9408546
  },
  {
    lng: -43.1572616,
    lat: -22.9409336
  },
  {
    lng: -43.1573836,
    lat: -22.9410234
  },
  {
    lng: -43.1576271,
    lat: -22.9411374
  },
  {
    lng: -43.157695,
    lat: -22.941173
  },
  {
    lng: -43.1577244,
    lat: -22.9411966
  },
  {
    lng: -43.1577767,
    lat: -22.94125
  },
  {
    lng: -43.1578245,
    lat: -22.9413487
  },
  {
    lng: -43.1578384,
    lat: -22.9414196
  },
  {
    lng: -43.1578318,
    lat: -22.9415276
  },
  {
    lng: -43.15781,
    lat: -22.9415969
  },
  {
    lng: -43.1577774,
    lat: -22.9416624
  },
  {
    lng: -43.1576603,
    lat: -22.9418067
  },
  {
    lng: -43.1575671,
    lat: -22.9419651
  },
  {
    lng: -43.1575336,
    lat: -22.9421428
  },
  {
    lng: -43.157563,
    lat: -22.9422841
  },
  {
    lng: -43.1576113,
    lat: -22.9424212
  },
  {
    lng: -43.1576257,
    lat: -22.9424547
  },
  {
    lng: -43.1576775,
    lat: -22.9425081
  },
  {
    lng: -43.15765,
    lat: -22.9426096
  },
  {
    lng: -43.1576592,
    lat: -22.9426348
  },
  {
    lng: -43.1576948,
    lat: -22.9426294
  },
  {
    lng: -43.1577077,
    lat: -22.9427008
  },
  {
    lng: -43.1576972,
    lat: -22.9427027
  },
  {
    lng: -43.1576948,
    lat: -22.9426915
  },
  {
    lng: -43.1576549,
    lat: -22.9426952
  },
  {
    lng: -43.1576856,
    lat: -22.9428322
  },
  {
    lng: -43.157685,
    lat: -22.9429813
  },
  {
    lng: -43.1576762,
    lat: -22.9430529
  },
  {
    lng: -43.1576675,
    lat: -22.9431108
  },
  {
    lng: -43.1576687,
    lat: -22.943158
  },
  {
    lng: -43.1577136,
    lat: -22.9432252
  },
  {
    lng: -43.1578129,
    lat: -22.9433102
  },
  {
    lng: -43.1580919,
    lat: -22.9434651
  },
  {
    lng: -43.1583665,
    lat: -22.9435576
  },
  {
    lng: -43.1586882,
    lat: -22.9436243
  },
  {
    lng: -43.1588372,
    lat: -22.9436387
  },
  {
    lng: -43.158891,
    lat: -22.9436288
  },
  {
    lng: -43.158923,
    lat: -22.9436083
  },
  {
    lng: -43.1590193,
    lat: -22.9435472
  },
  {
    lng: -43.1591478,
    lat: -22.9435037
  },
  {
    lng: -43.1595378,
    lat: -22.9434374
  },
  {
    lng: -43.1602676,
    lat: -22.9433388
  },
  {
    lng: -43.1604868,
    lat: -22.9433171
  },
  {
    lng: -43.1606859,
    lat: -22.9433037
  },
  {
    lng: -43.1610004,
    lat: -22.9432858
  },
  {
    lng: -43.161392,
    lat: -22.943287
  },
  {
    lng: -43.1615121,
    lat: -22.9432981
  },
  {
    lng: -43.1617019,
    lat: -22.9433358
  },
  {
    lng: -43.1617782,
    lat: -22.9433641
  },
  {
    lng: -43.1618762,
    lat: -22.9434154
  },
  {
    lng: -43.1620237,
    lat: -22.9435141
  },
  {
    lng: -43.1623093,
    lat: -22.9437519
  },
  {
    lng: -43.1625132,
    lat: -22.9439582
  },
  {
    lng: -43.1628766,
    lat: -22.9444034
  },
  {
    lng: -43.1630738,
    lat: -22.9447053
  },
  {
    lng: -43.1635761,
    lat: -22.9455167
  },
  {
    lng: -43.1636638,
    lat: -22.9456539
  },
  {
    lng: -43.1637067,
    lat: -22.945728
  },
  {
    lng: -43.1637483,
    lat: -22.9458163
  },
  {
    lng: -43.1637818,
    lat: -22.9459107
  },
  {
    lng: -43.1637839,
    lat: -22.9459694
  },
  {
    lng: -43.1637632,
    lat: -22.9460861
  },
  {
    lng: -43.1637403,
    lat: -22.946154
  },
  {
    lng: -43.1637007,
    lat: -22.9462336
  },
  {
    lng: -43.1635399,
    lat: -22.9464955
  },
  {
    lng: -43.1633917,
    lat: -22.9467413
  },
  {
    lng: -43.163299,
    lat: -22.9469017
  },
  {
    lng: -43.1632239,
    lat: -22.9470303
  },
  {
    lng: -43.1629893,
    lat: -22.9474605
  },
  {
    lng: -43.1629825,
    lat: -22.9474952
  },
  {
    lng: -43.1630054,
    lat: -22.947547
  },
  {
    lng: -43.1630362,
    lat: -22.9475835
  },
  {
    lng: -43.1633695,
    lat: -22.9479139
  },
  {
    lng: -43.1634782,
    lat: -22.9480127
  },
  {
    lng: -43.163574,
    lat: -22.9480799
  },
  {
    lng: -43.1636693,
    lat: -22.9481213
  },
  {
    lng: -43.163798,
    lat: -22.9481609
  },
  {
    lng: -43.1638502,
    lat: -22.9481639
  },
  {
    lng: -43.1639066,
    lat: -22.9481571
  },
  {
    lng: -43.164077,
    lat: -22.9480113
  },
  {
    lng: -43.1642143,
    lat: -22.9479194
  },
  {
    lng: -43.1643411,
    lat: -22.9478496
  },
  {
    lng: -43.1644076,
    lat: -22.9478219
  },
  {
    lng: -43.1645529,
    lat: -22.9477891
  },
  {
    lng: -43.1647127,
    lat: -22.9477639
  },
  {
    lng: -43.1648812,
    lat: -22.9477593
  },
  {
    lng: -43.1649834,
    lat: -22.9477733
  },
  {
    lng: -43.1650918,
    lat: -22.9477973
  },
  {
    lng: -43.1652057,
    lat: -22.9478444
  },
  {
    lng: -43.165406,
    lat: -22.9479897
  },
  {
    lng: -43.1656533,
    lat: -22.9481855
  },
  {
    lng: -43.1659153,
    lat: -22.9483974
  },
  {
    lng: -43.1664078,
    lat: -22.9487932
  },
  {
    lng: -43.1668322,
    lat: -22.949137
  },
  {
    lng: -43.1672353,
    lat: -22.9494593
  },
  {
    lng: -43.1676449,
    lat: -22.9497946
  },
  {
    lng: -43.1677174,
    lat: -22.9498565
  },
  {
    lng: -43.1677637,
    lat: -22.9499071
  },
  {
    lng: -43.1677998,
    lat: -22.9499559
  },
  {
    lng: -43.167812,
    lat: -22.9499817
  },
  {
    lng: -43.1678207,
    lat: -22.9500144
  },
  {
    lng: -43.1678247,
    lat: -22.9500713
  },
  {
    lng: -43.1678306,
    lat: -22.9501343
  },
  {
    lng: -43.1678749,
    lat: -22.9503603
  },
  {
    lng: -43.1679218,
    lat: -22.9505147
  },
  {
    lng: -43.1679922,
    lat: -22.9506722
  },
  {
    lng: -43.1680346,
    lat: -22.9507253
  },
  {
    lng: -43.1681183,
    lat: -22.9508049
  },
  {
    lng: -43.1690069,
    lat: -22.9514871
  },
  {
    lng: -43.1691703,
    lat: -22.9516149
  },
  {
    lng: -43.1692442,
    lat: -22.9516731
  },
  {
    lng: -43.1692639,
    lat: -22.9516782
  },
  {
    lng: -43.1692898,
    lat: -22.9516743
  },
  {
    lng: -43.1693568,
    lat: -22.9516379
  },
  {
    lng: -43.1693713,
    lat: -22.9516357
  },
  {
    lng: -43.1693856,
    lat: -22.9516384
  },
  {
    lng: -43.1694483,
    lat: -22.9516842
  },
  {
    lng: -43.1694607,
    lat: -22.9517051
  },
  {
    lng: -43.1694635,
    lat: -22.9517387
  },
  {
    lng: -43.1694481,
    lat: -22.9518188
  },
  {
    lng: -43.1694524,
    lat: -22.9518434
  },
  {
    lng: -43.1694672,
    lat: -22.951867
  },
  {
    lng: -43.1697008,
    lat: -22.9520536
  },
  {
    lng: -43.1697089,
    lat: -22.952071
  },
  {
    lng: -43.1697048,
    lat: -22.9520988
  },
  {
    lng: -43.1696844,
    lat: -22.9521321
  },
  {
    lng: -43.1696666,
    lat: -22.9521413
  },
  {
    lng: -43.1696442,
    lat: -22.9521398
  },
  {
    lng: -43.1693722,
    lat: -22.9519107
  },
  {
    lng: -43.1693581,
    lat: -22.9519064
  },
  {
    lng: -43.1693438,
    lat: -22.9519099
  },
  {
    lng: -43.1689203,
    lat: -22.952367
  },
  {
    lng: -43.1689137,
    lat: -22.952393
  },
  {
    lng: -43.1689203,
    lat: -22.9524133
  },
  {
    lng: -43.169662,
    lat: -22.9529258
  },
  {
    lng: -43.1696907,
    lat: -22.9529357
  },
  {
    lng: -43.1697243,
    lat: -22.9529277
  },
  {
    lng: -43.1700985,
    lat: -22.9525258
  },
  {
    lng: -43.1701018,
    lat: -22.9525112
  },
  {
    lng: -43.1700958,
    lat: -22.9524954
  },
  {
    lng: -43.1697942,
    lat: -22.9522471
  },
  {
    lng: -43.1697923,
    lat: -22.952243
  },
  {
    lng: -43.1697957,
    lat: -22.9522356
  },
  {
    lng: -43.1698416,
    lat: -22.9521938
  },
  {
    lng: -43.169851,
    lat: -22.9521913
  },
  {
    lng: -43.1698631,
    lat: -22.952196
  },
  {
    lng: -43.1699637,
    lat: -22.9522917
  },
  {
    lng: -43.1699814,
    lat: -22.9522895
  },
  {
    lng: -43.1700361,
    lat: -22.9522707
  },
  {
    lng: -43.1700683,
    lat: -22.9522547
  },
  {
    lng: -43.1700924,
    lat: -22.9522293
  },
  {
    lng: -43.1701159,
    lat: -22.9521602
  },
  {
    lng: -43.1701487,
    lat: -22.9520479
  },
  {
    lng: -43.1701641,
    lat: -22.9520037
  },
  {
    lng: -43.1701939,
    lat: -22.9519186
  },
  {
    lng: -43.1702239,
    lat: -22.9518327
  },
  {
    lng: -43.1702513,
    lat: -22.9517644
  },
  {
    lng: -43.1702635,
    lat: -22.9517509
  },
  {
    lng: -43.1702942,
    lat: -22.9517329
  },
  {
    lng: -43.1703505,
    lat: -22.9517093
  },
  {
    lng: -43.170425,
    lat: -22.9516781
  },
  {
    lng: -43.1704848,
    lat: -22.9516531
  },
  {
    lng: -43.1705549,
    lat: -22.9516237
  },
  {
    lng: -43.1706199,
    lat: -22.9515965
  },
  {
    lng: -43.1706847,
    lat: -22.9515694
  },
  {
    lng: -43.1707588,
    lat: -22.9515384
  },
  {
    lng: -43.1708212,
    lat: -22.9515123
  },
  {
    lng: -43.1708924,
    lat: -22.951479
  },
  {
    lng: -43.1709159,
    lat: -22.9514786
  },
  {
    lng: -43.1709282,
    lat: -22.9514943
  },
  {
    lng: -43.1709631,
    lat: -22.951567
  },
  {
    lng: -43.1709984,
    lat: -22.9516364
  },
  {
    lng: -43.1710342,
    lat: -22.9517067
  },
  {
    lng: -43.1710721,
    lat: -22.9517811
  },
  {
    lng: -43.171172,
    lat: -22.9519775
  }
];

export default polygonCoordsRio2;