import React, { Component } from 'react';
import apiString from '../services/apiString';

import Typography from '@material-ui/core/Typography';

import FooterHome from '../components/FooterHome';

import google_play from '../assets/google_play.png';
import app_store from '../assets/app_store.png';

export default class MobileRport extends Component {

  render() {

    return(

      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={styles.html}>

          <div style={styles.text}>
            <Typography variant="subtitle1" color="textSecondary" align="center">
              {'Para dispositivos móveis em breve o sistema estará:'}
            </Typography>
          </div>

          <img src={google_play} style={styles.img} />
          <img src={app_store} style={styles.img} />

          <div style={styles.text}>
            <Typography variant="subtitle1" color="textSecondary" align="center">
              {'Você pode acessar o sistema por um computador ou notebook.'}
            </Typography>
          </div>

        </div>
        <FooterHome />
      </div>

    );

  }

}

let styles = {
  html: {
    backgroundColor: '#343434',
    height: window.innerHeight,
    width: window.innerWidth,
    margin: -10,
  },
  text: {
    padding: 10,
    marginTop: 30,
  },
  img: {
    width: 350,
    height: 100,
    display: 'flex',
    margin: 10,
    marginLeft: window.innerWidth - (window.innerWidth / 1.065),
  }
}