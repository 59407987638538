let polygonCoords_Queimados = [

  {
    lng: -43.6446422,
    lat: -22.746422
  },
  {
    lng: -43.6446014,
    lat: -22.7469944
  },
  {
    lng: -43.6445051,
    lat: -22.7475077
  },
  {
    lng: -43.6443314,
    lat: -22.7479454
  },
  {
    lng: -43.6440901,
    lat: -22.7481972
  },
  {
    lng: -43.6439332,
    lat: -22.7482814
  },
  {
    lng: -43.6437684,
    lat: -22.7484074
  },
  {
    lng: -43.6433761,
    lat: -22.7486144
  },
  {
    lng: -43.643189,
    lat: -22.7487751
  },
  {
    lng: -43.6430497,
    lat: -22.7489868
  },
  {
    lng: -43.643048,
    lat: -22.7491646
  },
  {
    lng: -43.643027,
    lat: -22.749474
  },
  {
    lng: -43.6430987,
    lat: -22.7497035
  },
  {
    lng: -43.6430999,
    lat: -22.7497074
  },
  {
    lng: -43.6431053,
    lat: -22.7497247
  },
  {
    lng: -43.6432481,
    lat: -22.7503561
  },
  {
    lng: -43.6433282,
    lat: -22.7513273
  },
  {
    lng: -43.6432836,
    lat: -22.7517288
  },
  {
    lng: -43.643222,
    lat: -22.7522832
  },
  {
    lng: -43.6431798,
    lat: -22.7527769
  },
  {
    lng: -43.6431298,
    lat: -22.7531059
  },
  {
    lng: -43.6429719,
    lat: -22.7541109
  },
  {
    lng: -43.6426472,
    lat: -22.7551165
  },
  {
    lng: -43.6422243,
    lat: -22.7561735
  },
  {
    lng: -43.6410093,
    lat: -22.7577106
  },
  {
    lng: -43.6392484,
    lat: -22.7602765
  },
  {
    lng: -43.6384593,
    lat: -22.7607126
  },
  {
    lng: -43.6375992,
    lat: -22.7618242
  },
  {
    lng: -43.6370116,
    lat: -22.7626255
  },
  {
    lng: -43.6369514,
    lat: -22.7628043
  },
  {
    lng: -43.636769,
    lat: -22.7633458
  },
  {
    lng: -43.6369781,
    lat: -22.7641707
  },
  {
    lng: -43.6372408,
    lat: -22.7650801
  },
  {
    lng: -43.637158,
    lat: -22.7661209
  },
  {
    lng: -43.6362164,
    lat: -22.7679103
  },
  {
    lng: -43.63499,
    lat: -22.7691933
  },
  {
    lng: -43.6341185,
    lat: -22.7712185
  },
  {
    lng: -43.6339478,
    lat: -22.771569
  },
  {
    lng: -43.6323431,
    lat: -22.7748653
  },
  {
    lng: -43.6322432,
    lat: -22.7758052
  },
  {
    lng: -43.6323252,
    lat: -22.7766626
  },
  {
    lng: -43.632826,
    lat: -22.7773053
  },
  {
    lng: -43.6335197,
    lat: -22.7780486
  },
  {
    lng: -43.634027,
    lat: -22.7785922
  },
  {
    lng: -43.6348721,
    lat: -22.779221
  },
  {
    lng: -43.6352942,
    lat: -22.7805013
  },
  {
    lng: -43.634865,
    lat: -22.7817575
  },
  {
    lng: -43.6348263,
    lat: -22.7826692
  },
  {
    lng: -43.6347992,
    lat: -22.7833087
  },
  {
    lng: -43.634798,
    lat: -22.7833112
  },
  {
    lng: -43.6346266,
    lat: -22.783298
  },
  {
    lng: -43.634188,
    lat: -22.7832643
  },
  {
    lng: -43.632909,
    lat: -22.7831659
  },
  {
    lng: -43.6294847,
    lat: -22.7829759
  },
  {
    lng: -43.6273681,
    lat: -22.7828172
  },
  {
    lng: -43.6240471,
    lat: -22.7824962
  },
  {
    lng: -43.6237834,
    lat: -22.7824711
  },
  {
    lng: -43.6237975,
    lat: -22.7823989
  },
  {
    lng: -43.6238451,
    lat: -22.7820091
  },
  {
    lng: -43.6238933,
    lat: -22.7816279
  },
  {
    lng: -43.6239578,
    lat: -22.7812582
  },
  {
    lng: -43.6239881,
    lat: -22.7810161
  },
  {
    lng: -43.6240166,
    lat: -22.7807882
  },
  {
    lng: -43.6241233,
    lat: -22.7804371
  },
  {
    lng: -43.6242509,
    lat: -22.7800822
  },
  {
    lng: -43.62441,
    lat: -22.7797392
  },
  {
    lng: -43.6245503,
    lat: -22.7793921
  },
  {
    lng: -43.6247084,
    lat: -22.7790485
  },
  {
    lng: -43.6248774,
    lat: -22.7786973
  },
  {
    lng: -43.6250277,
    lat: -22.7783586
  },
  {
    lng: -43.625208,
    lat: -22.7780161
  },
  {
    lng: -43.6253746,
    lat: -22.7776853
  },
  {
    lng: -43.6255142,
    lat: -22.7773446
  },
  {
    lng: -43.625645,
    lat: -22.7769954
  },
  {
    lng: -43.6258264,
    lat: -22.7766718
  },
  {
    lng: -43.6259659,
    lat: -22.7763046
  },
  {
    lng: -43.6260995,
    lat: -22.7759519
  },
  {
    lng: -43.6262899,
    lat: -22.7756227
  },
  {
    lng: -43.6264462,
    lat: -22.7752866
  },
  {
    lng: -43.6266274,
    lat: -22.7749578
  },
  {
    lng: -43.626769,
    lat: -22.7746185
  },
  {
    lng: -43.6268455,
    lat: -22.7743475
  },
  {
    lng: -43.626869,
    lat: -22.7742645
  },
  {
    lng: -43.6270424,
    lat: -22.7739174
  },
  {
    lng: -43.6271398,
    lat: -22.7735595
  },
  {
    lng: -43.6272327,
    lat: -22.7732066
  },
  {
    lng: -43.6273303,
    lat: -22.7728537
  },
  {
    lng: -43.6272477,
    lat: -22.7724984
  },
  {
    lng: -43.6270423,
    lat: -22.7721886
  },
  {
    lng: -43.6267672,
    lat: -22.7719303
  },
  {
    lng: -43.6264838,
    lat: -22.7716748
  },
  {
    lng: -43.6261854,
    lat: -22.7714393
  },
  {
    lng: -43.6258518,
    lat: -22.7712354
  },
  {
    lng: -43.6255026,
    lat: -22.7710698
  },
  {
    lng: -43.625142,
    lat: -22.7709154
  },
  {
    lng: -43.624757,
    lat: -22.7707759
  },
  {
    lng: -43.6244203,
    lat: -22.7705933
  },
  {
    lng: -43.6241281,
    lat: -22.7703467
  },
  {
    lng: -43.623855,
    lat: -22.7700746
  },
  {
    lng: -43.6235801,
    lat: -22.7697961
  },
  {
    lng: -43.6233138,
    lat: -22.7695316
  },
  {
    lng: -43.6232161,
    lat: -22.7694439
  },
  {
    lng: -43.6230295,
    lat: -22.7692761
  },
  {
    lng: -43.6227641,
    lat: -22.7689936
  },
  {
    lng: -43.6224989,
    lat: -22.7687236
  },
  {
    lng: -43.6222351,
    lat: -22.7684408
  },
  {
    lng: -43.6219476,
    lat: -22.7681857
  },
  {
    lng: -43.6216696,
    lat: -22.7679231
  },
  {
    lng: -43.6213865,
    lat: -22.767658
  },
  {
    lng: -43.6211293,
    lat: -22.7673759
  },
  {
    lng: -43.6208444,
    lat: -22.7671151
  },
  {
    lng: -43.6205649,
    lat: -22.7668533
  },
  {
    lng: -43.6202816,
    lat: -22.7665956
  },
  {
    lng: -43.6200121,
    lat: -22.7663318
  },
  {
    lng: -43.619752,
    lat: -22.7660595
  },
  {
    lng: -43.6194621,
    lat: -22.7658028
  },
  {
    lng: -43.6191673,
    lat: -22.7655451
  },
  {
    lng: -43.6189009,
    lat: -22.7652737
  },
  {
    lng: -43.6186155,
    lat: -22.7650269
  },
  {
    lng: -43.618329,
    lat: -22.7647664
  },
  {
    lng: -43.6180339,
    lat: -22.764528
  },
  {
    lng: -43.6177441,
    lat: -22.7642821
  },
  {
    lng: -43.6174669,
    lat: -22.7640265
  },
  {
    lng: -43.6172171,
    lat: -22.7637398
  },
  {
    lng: -43.6169869,
    lat: -22.7634354
  },
  {
    lng: -43.6167858,
    lat: -22.7631079
  },
  {
    lng: -43.6165854,
    lat: -22.7627891
  },
  {
    lng: -43.6163648,
    lat: -22.7624646
  },
  {
    lng: -43.6161824,
    lat: -22.7621409
  },
  {
    lng: -43.6159663,
    lat: -22.7618398
  },
  {
    lng: -43.6157914,
    lat: -22.7615138
  },
  {
    lng: -43.6157717,
    lat: -22.7613861
  },
  {
    lng: -43.6157348,
    lat: -22.7611467
  },
  {
    lng: -43.6157552,
    lat: -22.760781
  },
  {
    lng: -43.6157106,
    lat: -22.7604151
  },
  {
    lng: -43.6156831,
    lat: -22.7602111
  },
  {
    lng: -43.6156739,
    lat: -22.7601431
  },
  {
    lng: -43.615662,
    lat: -22.760055
  },
  {
    lng: -43.6155771,
    lat: -22.7596954
  },
  {
    lng: -43.6155031,
    lat: -22.7593197
  },
  {
    lng: -43.6154645,
    lat: -22.7590516
  },
  {
    lng: -43.6154482,
    lat: -22.7589381
  },
  {
    lng: -43.6154179,
    lat: -22.7585688
  },
  {
    lng: -43.6153743,
    lat: -22.7582
  },
  {
    lng: -43.615311,
    lat: -22.7578381
  },
  {
    lng: -43.6152989,
    lat: -22.7575427
  },
  {
    lng: -43.6152962,
    lat: -22.7574766
  },
  {
    lng: -43.6152901,
    lat: -22.7571114
  },
  {
    lng: -43.615232,
    lat: -22.7567402
  },
  {
    lng: -43.6152275,
    lat: -22.7563702
  },
  {
    lng: -43.6152013,
    lat: -22.7560048
  },
  {
    lng: -43.6151725,
    lat: -22.7557397
  },
  {
    lng: -43.6151601,
    lat: -22.7556257
  },
  {
    lng: -43.6151753,
    lat: -22.7552607
  },
  {
    lng: -43.6151609,
    lat: -22.7548923
  },
  {
    lng: -43.6151206,
    lat: -22.7545309
  },
  {
    lng: -43.6150793,
    lat: -22.7541498
  },
  {
    lng: -43.6150594,
    lat: -22.7537725
  },
  {
    lng: -43.6150413,
    lat: -22.7534016
  },
  {
    lng: -43.6150046,
    lat: -22.7530382
  },
  {
    lng: -43.6149847,
    lat: -22.7526643
  },
  {
    lng: -43.6149798,
    lat: -22.7522847
  },
  {
    lng: -43.6149602,
    lat: -22.7519023
  },
  {
    lng: -43.6149233,
    lat: -22.7515379
  },
  {
    lng: -43.6149769,
    lat: -22.7511714
  },
  {
    lng: -43.6149319,
    lat: -22.7508093
  },
  {
    lng: -43.6148083,
    lat: -22.7504574
  },
  {
    lng: -43.6146365,
    lat: -22.7501232
  },
  {
    lng: -43.6145531,
    lat: -22.7500037
  },
  {
    lng: -43.6144887,
    lat: -22.7498996
  },
  {
    lng: -43.6143462,
    lat: -22.7496962
  },
  {
    lng: -43.6140712,
    lat: -22.7493196
  },
  {
    lng: -43.6139469,
    lat: -22.7493267
  },
  {
    lng: -43.6138717,
    lat: -22.7493145
  },
  {
    lng: -43.6137805,
    lat: -22.7492667
  },
  {
    lng: -43.6137501,
    lat: -22.7492398
  },
  {
    lng: -43.6137085,
    lat: -22.7492011
  },
  {
    lng: -43.6136685,
    lat: -22.7491801
  },
  {
    lng: -43.6136029,
    lat: -22.7491561
  },
  {
    lng: -43.61355,
    lat: -22.7491559
  },
  {
    lng: -43.6134764,
    lat: -22.749114
  },
  {
    lng: -43.6134156,
    lat: -22.7490663
  },
  {
    lng: -43.6132972,
    lat: -22.7489886
  },
  {
    lng: -43.6129512,
    lat: -22.7488179
  },
  {
    lng: -43.6121804,
    lat: -22.7483985
  },
  {
    lng: -43.6118596,
    lat: -22.7482187
  },
  {
    lng: -43.6115339,
    lat: -22.7480537
  },
  {
    lng: -43.6112546,
    lat: -22.7478739
  },
  {
    lng: -43.6108804,
    lat: -22.7476847
  },
  {
    lng: -43.6106731,
    lat: -22.7476093
  },
  {
    lng: -43.6102421,
    lat: -22.7474762
  },
  {
    lng: -43.6097594,
    lat: -22.7473756
  },
  {
    lng: -43.6092684,
    lat: -22.7472272
  },
  {
    lng: -43.6089398,
    lat: -22.7471451
  },
  {
    lng: -43.6086449,
    lat: -22.7470811
  },
  {
    lng: -43.608474,
    lat: -22.7470742
  },
  {
    lng: -43.6082208,
    lat: -22.747073
  },
  {
    lng: -43.607974,
    lat: -22.7470717
  },
  {
    lng: -43.6077868,
    lat: -22.7470827
  },
  {
    lng: -43.6075754,
    lat: -22.7471473
  },
  {
    lng: -43.6072299,
    lat: -22.7471933
  },
  {
    lng: -43.6069328,
    lat: -22.7472425
  },
  {
    lng: -43.6066388,
    lat: -22.7472858
  },
  {
    lng: -43.6064886,
    lat: -22.7473119
  },
  {
    lng: -43.6063431,
    lat: -22.747356
  },
  {
    lng: -43.6061751,
    lat: -22.747385
  },
  {
    lng: -43.6059116,
    lat: -22.7474165
  },
  {
    lng: -43.6052794,
    lat: -22.7475328
  },
  {
    lng: -43.6047148,
    lat: -22.7476316
  },
  {
    lng: -43.6043264,
    lat: -22.7476925
  },
  {
    lng: -43.6040048,
    lat: -22.7477664
  },
  {
    lng: -43.6039573,
    lat: -22.7477774
  },
  {
    lng: -43.6035508,
    lat: -22.7478352
  },
  {
    lng: -43.6033386,
    lat: -22.7478551
  },
  {
    lng: -43.6031004,
    lat: -22.7478898
  },
  {
    lng: -43.6027619,
    lat: -22.7479602
  },
  {
    lng: -43.6023048,
    lat: -22.7480328
  },
  {
    lng: -43.6018475,
    lat: -22.7481204
  },
  {
    lng: -43.601541,
    lat: -22.7481967
  },
  {
    lng: -43.6012966,
    lat: -22.7482308
  },
  {
    lng: -43.6012927,
    lat: -22.7482314
  },
  {
    lng: -43.6012876,
    lat: -22.748233
  },
  {
    lng: -43.6010737,
    lat: -22.7482962
  },
  {
    lng: -43.6009001,
    lat: -22.7483821
  },
  {
    lng: -43.6007557,
    lat: -22.7484712
  },
  {
    lng: -43.6006097,
    lat: -22.7485991
  },
  {
    lng: -43.6004897,
    lat: -22.7487361
  },
  {
    lng: -43.6004023,
    lat: -22.7488702
  },
  {
    lng: -43.6003051,
    lat: -22.7490342
  },
  {
    lng: -43.600174,
    lat: -22.7493205
  },
  {
    lng: -43.6000042,
    lat: -22.7497501
  },
  {
    lng: -43.5999096,
    lat: -22.7500079
  },
  {
    lng: -43.5998058,
    lat: -22.7501933
  },
  {
    lng: -43.5997305,
    lat: -22.750373
  },
  {
    lng: -43.5996974,
    lat: -22.7504544
  },
  {
    lng: -43.5995886,
    lat: -22.7507222
  },
  {
    lng: -43.5994345,
    lat: -22.7510696
  },
  {
    lng: -43.5993992,
    lat: -22.7511386
  },
  {
    lng: -43.5992671,
    lat: -22.7513827
  },
  {
    lng: -43.5992412,
    lat: -22.7514254
  },
  {
    lng: -43.5991088,
    lat: -22.7517525
  },
  {
    lng: -43.5990719,
    lat: -22.7518221
  },
  {
    lng: -43.5989422,
    lat: -22.7520248
  },
  {
    lng: -43.5988918,
    lat: -22.7520916
  },
  {
    lng: -43.5987238,
    lat: -22.752276
  },
  {
    lng: -43.5986835,
    lat: -22.752313
  },
  {
    lng: -43.5986356,
    lat: -22.7523541
  },
  {
    lng: -43.5986294,
    lat: -22.7523594
  },
  {
    lng: -43.598506,
    lat: -22.7524651
  },
  {
    lng: -43.5984778,
    lat: -22.7524892
  },
  {
    lng: -43.5982939,
    lat: -22.7526014
  },
  {
    lng: -43.5982353,
    lat: -22.7526354
  },
  {
    lng: -43.5981772,
    lat: -22.7526664
  },
  {
    lng: -43.5981053,
    lat: -22.7526855
  },
  {
    lng: -43.5979402,
    lat: -22.7527875
  },
  {
    lng: -43.5979009,
    lat: -22.7528015
  },
  {
    lng: -43.59757,
    lat: -22.752993
  },
  {
    lng: -43.5972232,
    lat: -22.7531592
  },
  {
    lng: -43.597154,
    lat: -22.7531871
  },
  {
    lng: -43.5971061,
    lat: -22.7532031
  },
  {
    lng: -43.5968896,
    lat: -22.753287
  },
  {
    lng: -43.5968408,
    lat: -22.7533246
  },
  {
    lng: -43.5965705,
    lat: -22.7534562
  },
  {
    lng: -43.5965261,
    lat: -22.7534855
  },
  {
    lng: -43.5963641,
    lat: -22.7535747
  },
  {
    lng: -43.5963142,
    lat: -22.7535791
  },
  {
    lng: -43.5960836,
    lat: -22.7536399
  },
  {
    lng: -43.5957077,
    lat: -22.7537507
  },
  {
    lng: -43.5956276,
    lat: -22.7537574
  },
  {
    lng: -43.5955471,
    lat: -22.7537661
  },
  {
    lng: -43.5951632,
    lat: -22.7538532
  },
  {
    lng: -43.5947848,
    lat: -22.7539511
  },
  {
    lng: -43.5944069,
    lat: -22.7540401
  },
  {
    lng: -43.5940138,
    lat: -22.7541339
  },
  {
    lng: -43.5936307,
    lat: -22.7542338
  },
  {
    lng: -43.5932418,
    lat: -22.7543072
  },
  {
    lng: -43.5928632,
    lat: -22.7544045
  },
  {
    lng: -43.592478,
    lat: -22.7544678
  },
  {
    lng: -43.59243,
    lat: -22.7544759
  },
  {
    lng: -43.5922509,
    lat: -22.7545287
  },
  {
    lng: -43.5918462,
    lat: -22.7545358
  },
  {
    lng: -43.5916854,
    lat: -22.7545292
  },
  {
    lng: -43.5915646,
    lat: -22.7544927
  },
  {
    lng: -43.5914369,
    lat: -22.7544658
  },
  {
    lng: -43.5913548,
    lat: -22.7544516
  },
  {
    lng: -43.5912224,
    lat: -22.7544423
  },
  {
    lng: -43.5911507,
    lat: -22.7544299
  },
  {
    lng: -43.5910706,
    lat: -22.7544105
  },
  {
    lng: -43.5909223,
    lat: -22.7543921
  },
  {
    lng: -43.5908427,
    lat: -22.7543822
  },
  {
    lng: -43.5907986,
    lat: -22.7543901
  },
  {
    lng: -43.5906443,
    lat: -22.754365
  },
  {
    lng: -43.5902478,
    lat: -22.7543523
  },
  {
    lng: -43.5898516,
    lat: -22.7543601
  },
  {
    lng: -43.5894442,
    lat: -22.7543774
  },
  {
    lng: -43.5890592,
    lat: -22.7544136
  },
  {
    lng: -43.5890557,
    lat: -22.7544139
  },
  {
    lng: -43.5886623,
    lat: -22.7544208
  },
  {
    lng: -43.5882534,
    lat: -22.7544083
  },
  {
    lng: -43.5881517,
    lat: -22.7543983
  },
  {
    lng: -43.5878607,
    lat: -22.7543697
  },
  {
    lng: -43.5875143,
    lat: -22.7543141
  },
  {
    lng: -43.5874226,
    lat: -22.7543057
  },
  {
    lng: -43.5870618,
    lat: -22.754266
  },
  {
    lng: -43.5866537,
    lat: -22.7542025
  },
  {
    lng: -43.586265,
    lat: -22.7541395
  },
  {
    lng: -43.5858661,
    lat: -22.7540975
  },
  {
    lng: -43.5857728,
    lat: -22.7540828
  },
  {
    lng: -43.5857247,
    lat: -22.7540932
  },
  {
    lng: -43.5856626,
    lat: -22.754092
  },
  {
    lng: -43.5856197,
    lat: -22.7540912
  },
  {
    lng: -43.5855301,
    lat: -22.7540992
  },
  {
    lng: -43.5853792,
    lat: -22.7541188
  },
  {
    lng: -43.5852593,
    lat: -22.754129
  },
  {
    lng: -43.5851332,
    lat: -22.7541462
  },
  {
    lng: -43.5847385,
    lat: -22.7541935
  },
  {
    lng: -43.5843397,
    lat: -22.7542473
  },
  {
    lng: -43.5842816,
    lat: -22.7542543
  },
  {
    lng: -43.5842042,
    lat: -22.7542688
  },
  {
    lng: -43.5841655,
    lat: -22.7542896
  },
  {
    lng: -43.5841306,
    lat: -22.7543037
  },
  {
    lng: -43.5839579,
    lat: -22.7542997
  },
  {
    lng: -43.5836209,
    lat: -22.7542915
  },
  {
    lng: -43.583553,
    lat: -22.7542899
  },
  {
    lng: -43.5831633,
    lat: -22.7542727
  },
  {
    lng: -43.5827766,
    lat: -22.7542127
  },
  {
    lng: -43.5823866,
    lat: -22.7541679
  },
  {
    lng: -43.5819898,
    lat: -22.7541289
  },
  {
    lng: -43.5815977,
    lat: -22.7540901
  },
  {
    lng: -43.5812064,
    lat: -22.7540684
  },
  {
    lng: -43.5811241,
    lat: -22.7540608
  },
  {
    lng: -43.5810097,
    lat: -22.7540575
  },
  {
    lng: -43.5808785,
    lat: -22.7540344
  },
  {
    lng: -43.5803634,
    lat: -22.7540201
  },
  {
    lng: -43.5801556,
    lat: -22.7540421
  },
  {
    lng: -43.5801579,
    lat: -22.7540385
  },
  {
    lng: -43.5799676,
    lat: -22.7540696
  },
  {
    lng: -43.5798583,
    lat: -22.7540884
  },
  {
    lng: -43.5796154,
    lat: -22.7541436
  },
  {
    lng: -43.579562,
    lat: -22.754151
  },
  {
    lng: -43.5794097,
    lat: -22.7541686
  },
  {
    lng: -43.5792486,
    lat: -22.7541901
  },
  {
    lng: -43.5788728,
    lat: -22.754315
  },
  {
    lng: -43.5784897,
    lat: -22.7544189
  },
  {
    lng: -43.5780939,
    lat: -22.7545075
  },
  {
    lng: -43.5777137,
    lat: -22.7546091
  },
  {
    lng: -43.5773197,
    lat: -22.754663
  },
  {
    lng: -43.5769326,
    lat: -22.75477
  },
  {
    lng: -43.5765464,
    lat: -22.7548519
  },
  {
    lng: -43.5764178,
    lat: -22.7548817
  },
  {
    lng: -43.5761562,
    lat: -22.7549422
  },
  {
    lng: -43.5761346,
    lat: -22.7549489
  },
  {
    lng: -43.576087,
    lat: -22.7549637
  },
  {
    lng: -43.5760481,
    lat: -22.7549658
  },
  {
    lng: -43.5759963,
    lat: -22.7549684
  },
  {
    lng: -43.5759134,
    lat: -22.7549791
  },
  {
    lng: -43.5757321,
    lat: -22.7549757
  },
  {
    lng: -43.5753371,
    lat: -22.754917
  },
  {
    lng: -43.5749522,
    lat: -22.7548303
  },
  {
    lng: -43.5745542,
    lat: -22.7547421
  },
  {
    lng: -43.5741642,
    lat: -22.7546511
  },
  {
    lng: -43.5740688,
    lat: -22.7546284
  },
  {
    lng: -43.5740612,
    lat: -22.7546268
  },
  {
    lng: -43.5739616,
    lat: -22.7546062
  },
  {
    lng: -43.5735769,
    lat: -22.7545347
  },
  {
    lng: -43.5732013,
    lat: -22.7544294
  },
  {
    lng: -43.573107,
    lat: -22.7543981
  },
  {
    lng: -43.5731058,
    lat: -22.7543976
  },
  {
    lng: -43.5730482,
    lat: -22.7543748
  },
  {
    lng: -43.5728121,
    lat: -22.754235
  },
  {
    lng: -43.5726842,
    lat: -22.7541717
  },
  {
    lng: -43.5723244,
    lat: -22.7539966
  },
  {
    lng: -43.5722337,
    lat: -22.7539392
  },
  {
    lng: -43.5721904,
    lat: -22.7539132
  },
  {
    lng: -43.5718408,
    lat: -22.7537444
  },
  {
    lng: -43.5717242,
    lat: -22.7536706
  },
  {
    lng: -43.5716143,
    lat: -22.7535998
  },
  {
    lng: -43.5715503,
    lat: -22.7535562
  },
  {
    lng: -43.5713976,
    lat: -22.7534518
  },
  {
    lng: -43.5713174,
    lat: -22.7534288
  },
  {
    lng: -43.5711459,
    lat: -22.7533529
  },
  {
    lng: -43.5708117,
    lat: -22.7531386
  },
  {
    lng: -43.5704761,
    lat: -22.7529429
  },
  {
    lng: -43.5704316,
    lat: -22.7529209
  },
  {
    lng: -43.5703331,
    lat: -22.7528722
  },
  {
    lng: -43.570236,
    lat: -22.7528177
  },
  {
    lng: -43.5701623,
    lat: -22.752794
  },
  {
    lng: -43.570086,
    lat: -22.7527718
  },
  {
    lng: -43.5700596,
    lat: -22.7527668
  },
  {
    lng: -43.5699568,
    lat: -22.7527478
  },
  {
    lng: -43.5698685,
    lat: -22.7527461
  },
  {
    lng: -43.5697836,
    lat: -22.7527354
  },
  {
    lng: -43.5697049,
    lat: -22.7527489
  },
  {
    lng: -43.5694642,
    lat: -22.7528076
  },
  {
    lng: -43.5693649,
    lat: -22.7528511
  },
  {
    lng: -43.5693132,
    lat: -22.7528773
  },
  {
    lng: -43.5691437,
    lat: -22.7530148
  },
  {
    lng: -43.5690762,
    lat: -22.7530581
  },
  {
    lng: -43.5690164,
    lat: -22.7531142
  },
  {
    lng: -43.5688705,
    lat: -22.7533916
  },
  {
    lng: -43.5687637,
    lat: -22.7537507
  },
  {
    lng: -43.5686561,
    lat: -22.7541171
  },
  {
    lng: -43.5685264,
    lat: -22.7544799
  },
  {
    lng: -43.5684793,
    lat: -22.7545388
  },
  {
    lng: -43.5683212,
    lat: -22.7546903
  },
  {
    lng: -43.5682561,
    lat: -22.7547452
  },
  {
    lng: -43.5680502,
    lat: -22.7548991
  },
  {
    lng: -43.5680111,
    lat: -22.7549203
  },
  {
    lng: -43.5677029,
    lat: -22.7550877
  },
  {
    lng: -43.5673865,
    lat: -22.7553101
  },
  {
    lng: -43.5670494,
    lat: -22.7555055
  },
  {
    lng: -43.5667119,
    lat: -22.7557196
  },
  {
    lng: -43.5663911,
    lat: -22.7558906
  },
  {
    lng: -43.566352,
    lat: -22.7559115
  },
  {
    lng: -43.5660116,
    lat: -22.7561035
  },
  {
    lng: -43.5658551,
    lat: -22.7561774
  },
  {
    lng: -43.5656602,
    lat: -22.7562694
  },
  {
    lng: -43.5655822,
    lat: -22.7562869
  },
  {
    lng: -43.5655266,
    lat: -22.756311
  },
  {
    lng: -43.5651773,
    lat: -22.756477
  },
  {
    lng: -43.5651618,
    lat: -22.7564835
  },
  {
    lng: -43.5650652,
    lat: -22.7565239
  },
  {
    lng: -43.5649813,
    lat: -22.756539
  },
  {
    lng: -43.5645964,
    lat: -22.7566232
  },
  {
    lng: -43.5642267,
    lat: -22.75674
  },
  {
    lng: -43.5640848,
    lat: -22.7567928
  },
  {
    lng: -43.563991,
    lat: -22.7568054
  },
  {
    lng: -43.5637694,
    lat: -22.7568586
  },
  {
    lng: -43.5637163,
    lat: -22.7568801
  },
  {
    lng: -43.5633793,
    lat: -22.7569903
  },
  {
    lng: -43.5632566,
    lat: -22.7569995
  },
  {
    lng: -43.562935,
    lat: -22.7570674
  },
  {
    lng: -43.5625703,
    lat: -22.7571946
  },
  {
    lng: -43.5624894,
    lat: -22.7572157
  },
  {
    lng: -43.5621738,
    lat: -22.757298
  },
  {
    lng: -43.5617941,
    lat: -22.7573918
  },
  {
    lng: -43.5614113,
    lat: -22.7574933
  },
  {
    lng: -43.5610438,
    lat: -22.7576214
  },
  {
    lng: -43.5606662,
    lat: -22.7577225
  },
  {
    lng: -43.5602871,
    lat: -22.7578216
  },
  {
    lng: -43.5600988,
    lat: -22.7578339
  },
  {
    lng: -43.5597123,
    lat: -22.7579018
  },
  {
    lng: -43.5596201,
    lat: -22.7579051
  },
  {
    lng: -43.5594848,
    lat: -22.757884
  },
  {
    lng: -43.5594186,
    lat: -22.7578751
  },
  {
    lng: -43.5590719,
    lat: -22.757705
  },
  {
    lng: -43.5589579,
    lat: -22.7576445
  },
  {
    lng: -43.5588439,
    lat: -22.7575737
  },
  {
    lng: -43.558822,
    lat: -22.7575558
  },
  {
    lng: -43.5585254,
    lat: -22.7572931
  },
  {
    lng: -43.5582282,
    lat: -22.7570236
  },
  {
    lng: -43.5579268,
    lat: -22.756768
  },
  {
    lng: -43.5576245,
    lat: -22.7565052
  },
  {
    lng: -43.5573323,
    lat: -22.7562342
  },
  {
    lng: -43.557037,
    lat: -22.7559886
  },
  {
    lng: -43.5567607,
    lat: -22.7557795
  },
  {
    lng: -43.5566157,
    lat: -22.7556639
  },
  {
    lng: -43.556459,
    lat: -22.7555638
  },
  {
    lng: -43.5563805,
    lat: -22.7555137
  },
  {
    lng: -43.5561936,
    lat: -22.7553875
  },
  {
    lng: -43.5560771,
    lat: -22.7553271
  },
  {
    lng: -43.5560084,
    lat: -22.7552794
  },
  {
    lng: -43.5560026,
    lat: -22.7552147
  },
  {
    lng: -43.5560012,
    lat: -22.7551993
  },
  {
    lng: -43.555989,
    lat: -22.7551303
  },
  {
    lng: -43.5559295,
    lat: -22.7550845
  },
  {
    lng: -43.5557762,
    lat: -22.7550342
  },
  {
    lng: -43.5556581,
    lat: -22.7549787
  },
  {
    lng: -43.5555613,
    lat: -22.7549591
  },
  {
    lng: -43.5554573,
    lat: -22.7549866
  },
  {
    lng: -43.5553667,
    lat: -22.7549836
  },
  {
    lng: -43.5552428,
    lat: -22.7549817
  },
  {
    lng: -43.5549748,
    lat: -22.7549751
  },
  {
    lng: -43.554864,
    lat: -22.754962
  },
  {
    lng: -43.5548398,
    lat: -22.7549591
  },
  {
    lng: -43.5547804,
    lat: -22.7549584
  },
  {
    lng: -43.5547467,
    lat: -22.754958
  },
  {
    lng: -43.554637,
    lat: -22.7549713
  },
  {
    lng: -43.5544952,
    lat: -22.7549781
  },
  {
    lng: -43.5543982,
    lat: -22.7549607
  },
  {
    lng: -43.5542982,
    lat: -22.7549619
  },
  {
    lng: -43.5542097,
    lat: -22.754943
  },
  {
    lng: -43.5540852,
    lat: -22.7549589
  },
  {
    lng: -43.5540291,
    lat: -22.7549513
  },
  {
    lng: -43.5539568,
    lat: -22.754945
  },
  {
    lng: -43.553934,
    lat: -22.754947
  },
  {
    lng: -43.5535327,
    lat: -22.7549576
  },
  {
    lng: -43.5531195,
    lat: -22.7549525
  },
  {
    lng: -43.5527188,
    lat: -22.7549393
  },
  {
    lng: -43.5523238,
    lat: -22.7548979
  },
  {
    lng: -43.5522257,
    lat: -22.7548968
  },
  {
    lng: -43.5521004,
    lat: -22.7548776
  },
  {
    lng: -43.5519691,
    lat: -22.7548492
  },
  {
    lng: -43.5515732,
    lat: -22.7547811
  },
  {
    lng: -43.5511785,
    lat: -22.7546751
  },
  {
    lng: -43.5508009,
    lat: -22.754586
  },
  {
    lng: -43.550399,
    lat: -22.7545075
  },
  {
    lng: -43.5503026,
    lat: -22.7544919
  },
  {
    lng: -43.5502302,
    lat: -22.7545009
  },
  {
    lng: -43.5501539,
    lat: -22.7545139
  },
  {
    lng: -43.5498825,
    lat: -22.7547035
  },
  {
    lng: -43.5495946,
    lat: -22.7549534
  },
  {
    lng: -43.5494967,
    lat: -22.7550425
  },
  {
    lng: -43.549428,
    lat: -22.7550833
  },
  {
    lng: -43.5492628,
    lat: -22.7551323
  },
  {
    lng: -43.5491638,
    lat: -22.7551286
  },
  {
    lng: -43.548845,
    lat: -22.7550529
  },
  {
    lng: -43.5486034,
    lat: -22.7549445
  },
  {
    lng: -43.5482659,
    lat: -22.7547917
  },
  {
    lng: -43.5481838,
    lat: -22.7547422
  },
  {
    lng: -43.5481448,
    lat: -22.7547268
  },
  {
    lng: -43.5477996,
    lat: -22.7557025
  },
  {
    lng: -43.547586,
    lat: -22.7562814
  },
  {
    lng: -43.5475353,
    lat: -22.7565043
  },
  {
    lng: -43.5476034,
    lat: -22.7567841
  },
  {
    lng: -43.5477326,
    lat: -22.757047
  },
  {
    lng: -43.5478177,
    lat: -22.7574418
  },
  {
    lng: -43.5477845,
    lat: -22.7578209
  },
  {
    lng: -43.547739,
    lat: -22.7584346
  },
  {
    lng: -43.5477349,
    lat: -22.7588229
  },
  {
    lng: -43.5475297,
    lat: -22.7588679
  },
  {
    lng: -43.5474841,
    lat: -22.7587779
  },
  {
    lng: -43.5474938,
    lat: -22.7585811
  },
  {
    lng: -43.5474955,
    lat: -22.7585473
  },
  {
    lng: -43.5474646,
    lat: -22.7582549
  },
  {
    lng: -43.5472355,
    lat: -22.7573386
  },
  {
    lng: -43.5471195,
    lat: -22.7568745
  },
  {
    lng: -43.547003,
    lat: -22.7561206
  },
  {
    lng: -43.5467388,
    lat: -22.7556563
  },
  {
    lng: -43.5464604,
    lat: -22.7553707
  },
  {
    lng: -43.5461963,
    lat: -22.7552065
  },
  {
    lng: -43.5459679,
    lat: -22.7550923
  },
  {
    lng: -43.5455039,
    lat: -22.7549281
  },
  {
    lng: -43.5453574,
    lat: -22.7548249
  },
  {
    lng: -43.5451313,
    lat: -22.754661
  },
  {
    lng: -43.5450826,
    lat: -22.7546257
  },
  {
    lng: -43.5448672,
    lat: -22.7544537
  },
  {
    lng: -43.5447258,
    lat: -22.7543428
  },
  {
    lng: -43.5443641,
    lat: -22.7541191
  },
  {
    lng: -43.5441167,
    lat: -22.754062
  },
  {
    lng: -43.5438097,
    lat: -22.7540335
  },
  {
    lng: -43.5430336,
    lat: -22.7540619
  },
  {
    lng: -43.5421917,
    lat: -22.754319
  },
  {
    lng: -43.5419537,
    lat: -22.7542714
  },
  {
    lng: -43.5418098,
    lat: -22.7541461
  },
  {
    lng: -43.5415635,
    lat: -22.7538812
  },
  {
    lng: -43.541347,
    lat: -22.7537003
  },
  {
    lng: -43.5409126,
    lat: -22.7535877
  },
  {
    lng: -43.5406498,
    lat: -22.7535195
  },
  {
    lng: -43.5402881,
    lat: -22.7534053
  },
  {
    lng: -43.5401168,
    lat: -22.753272
  },
  {
    lng: -43.5399074,
    lat: -22.7530246
  },
  {
    lng: -43.5396599,
    lat: -22.7529389
  },
  {
    lng: -43.538721,
    lat: -22.7527893
  },
  {
    lng: -43.5378176,
    lat: -22.7525695
  },
  {
    lng: -43.5377143,
    lat: -22.7525263
  },
  {
    lng: -43.5375612,
    lat: -22.7524623
  },
  {
    lng: -43.5373769,
    lat: -22.7522967
  },
  {
    lng: -43.5371639,
    lat: -22.7522085
  },
  {
    lng: -43.5367189,
    lat: -22.7520639
  },
  {
    lng: -43.5364075,
    lat: -22.7519799
  },
  {
    lng: -43.5360453,
    lat: -22.751965
  },
  {
    lng: -43.5359007,
    lat: -22.7519378
  },
  {
    lng: -43.5356286,
    lat: -22.7518812
  },
  {
    lng: -43.5354181,
    lat: -22.7517528
  },
  {
    lng: -43.5352627,
    lat: -22.7515406
  },
  {
    lng: -43.5352197,
    lat: -22.7514255
  },
  {
    lng: -43.535202,
    lat: -22.7513779
  },
  {
    lng: -43.5352161,
    lat: -22.7512273
  },
  {
    lng: -43.5352185,
    lat: -22.7509956
  },
  {
    lng: -43.5352695,
    lat: -22.7506847
  },
  {
    lng: -43.5353134,
    lat: -22.7503012
  },
  {
    lng: -43.5353677,
    lat: -22.7498961
  },
  {
    lng: -43.5353974,
    lat: -22.749456
  },
  {
    lng: -43.5353806,
    lat: -22.7491246
  },
  {
    lng: -43.5351293,
    lat: -22.7488162
  },
  {
    lng: -43.5350239,
    lat: -22.7487259
  },
  {
    lng: -43.5349703,
    lat: -22.7486801
  },
  {
    lng: -43.5349247,
    lat: -22.7486411
  },
  {
    lng: -43.5348693,
    lat: -22.7485937
  },
  {
    lng: -43.5346968,
    lat: -22.7483719
  },
  {
    lng: -43.5346343,
    lat: -22.7482953
  },
  {
    lng: -43.5343853,
    lat: -22.7479901
  },
  {
    lng: -43.5343033,
    lat: -22.7477821
  },
  {
    lng: -43.5342513,
    lat: -22.7475631
  },
  {
    lng: -43.5342202,
    lat: -22.7470698
  },
  {
    lng: -43.5342162,
    lat: -22.7468847
  },
  {
    lng: -43.5341948,
    lat: -22.746624
  },
  {
    lng: -43.5342336,
    lat: -22.7463835
  },
  {
    lng: -43.5343476,
    lat: -22.745897
  },
  {
    lng: -43.5344491,
    lat: -22.745444
  },
  {
    lng: -43.5343943,
    lat: -22.744914
  },
  {
    lng: -43.5341133,
    lat: -22.7440709
  },
  {
    lng: -43.5336857,
    lat: -22.7428146
  },
  {
    lng: -43.5334554,
    lat: -22.7423222
  },
  {
    lng: -43.5331586,
    lat: -22.7418319
  },
  {
    lng: -43.5329876,
    lat: -22.7414269
  },
  {
    lng: -43.5328084,
    lat: -22.7406772
  },
  {
    lng: -43.5323433,
    lat: -22.7389724
  },
  {
    lng: -43.5321958,
    lat: -22.7387189
  },
  {
    lng: -43.5328934,
    lat: -22.7385229
  },
  {
    lng: -43.5338863,
    lat: -22.7382561
  },
  {
    lng: -43.5346108,
    lat: -22.7380677
  },
  {
    lng: -43.5352551,
    lat: -22.7378987
  },
  {
    lng: -43.5364331,
    lat: -22.7375811
  },
  {
    lng: -43.5374108,
    lat: -22.7373164
  },
  {
    lng: -43.5384067,
    lat: -22.7370561
  },
  {
    lng: -43.539609,
    lat: -22.7367473
  },
  {
    lng: -43.5409878,
    lat: -22.7363821
  },
  {
    lng: -43.5418612,
    lat: -22.7361782
  },
  {
    lng: -43.5422047,
    lat: -22.7361306
  },
  {
    lng: -43.541919,
    lat: -22.7353647
  },
  {
    lng: -43.5419115,
    lat: -22.7353445
  },
  {
    lng: -43.5417042,
    lat: -22.73481
  },
  {
    lng: -43.5416943,
    lat: -22.7347835
  },
  {
    lng: -43.5414975,
    lat: -22.7343367
  },
  {
    lng: -43.5413187,
    lat: -22.7339183
  },
  {
    lng: -43.541244,
    lat: -22.7336196
  },
  {
    lng: -43.5412334,
    lat: -22.7334049
  },
  {
    lng: -43.5412362,
    lat: -22.7331707
  },
  {
    lng: -43.5412278,
    lat: -22.7329614
  },
  {
    lng: -43.541223,
    lat: -22.7327814
  },
  {
    lng: -43.5412104,
    lat: -22.7324342
  },
  {
    lng: -43.5412049,
    lat: -22.7322811
  },
  {
    lng: -43.5413009,
    lat: -22.7315352
  },
  {
    lng: -43.5413061,
    lat: -22.7314292
  },
  {
    lng: -43.5413147,
    lat: -22.7310722
  },
  {
    lng: -43.5412659,
    lat: -22.7306535
  },
  {
    lng: -43.5412476,
    lat: -22.7305501
  },
  {
    lng: -43.5411527,
    lat: -22.7300741
  },
  {
    lng: -43.540988,
    lat: -22.729729
  },
  {
    lng: -43.5409342,
    lat: -22.7296461
  },
  {
    lng: -43.5407559,
    lat: -22.7294263
  },
  {
    lng: -43.5406982,
    lat: -22.7293515
  },
  {
    lng: -43.5404057,
    lat: -22.729141
  },
  {
    lng: -43.5400032,
    lat: -22.7289164
  },
  {
    lng: -43.5395687,
    lat: -22.7286575
  },
  {
    lng: -43.5392707,
    lat: -22.7283642
  },
  {
    lng: -43.5389776,
    lat: -22.7280956
  },
  {
    lng: -43.5384799,
    lat: -22.7276741
  },
  {
    lng: -43.5383075,
    lat: -22.7274408
  },
  {
    lng: -43.5385225,
    lat: -22.7271878
  },
  {
    lng: -43.5386132,
    lat: -22.7268873
  },
  {
    lng: -43.5388186,
    lat: -22.7264647
  },
  {
    lng: -43.5390343,
    lat: -22.7260453
  },
  {
    lng: -43.5390135,
    lat: -22.7256511
  },
  {
    lng: -43.539045,
    lat: -22.7253921
  },
  {
    lng: -43.5390683,
    lat: -22.7251996
  },
  {
    lng: -43.5391711,
    lat: -22.724934
  },
  {
    lng: -43.5393474,
    lat: -22.7247966
  },
  {
    lng: -43.5394483,
    lat: -22.7247048
  },
  {
    lng: -43.5395492,
    lat: -22.724613
  },
  {
    lng: -43.5397504,
    lat: -22.7244874
  },
  {
    lng: -43.5399646,
    lat: -22.7243155
  },
  {
    lng: -43.5402161,
    lat: -22.7241556
  },
  {
    lng: -43.5404757,
    lat: -22.7240512
  },
  {
    lng: -43.5405549,
    lat: -22.7240196
  },
  {
    lng: -43.5408195,
    lat: -22.7238019
  },
  {
    lng: -43.5409674,
    lat: -22.7235893
  },
  {
    lng: -43.5411126,
    lat: -22.7232599
  },
  {
    lng: -43.5412041,
    lat: -22.7228783
  },
  {
    lng: -43.5412086,
    lat: -22.7224511
  },
  {
    lng: -43.5412103,
    lat: -22.7222874
  },
  {
    lng: -43.5410855,
    lat: -22.7220502
  },
  {
    lng: -43.5410793,
    lat: -22.7220385
  },
  {
    lng: -43.5410477,
    lat: -22.7219783
  },
  {
    lng: -43.5405893,
    lat: -22.7213365
  },
  {
    lng: -43.540058,
    lat: -22.7205926
  },
  {
    lng: -43.5384854,
    lat: -22.7183905
  },
  {
    lng: -43.5382594,
    lat: -22.7180741
  },
  {
    lng: -43.5381657,
    lat: -22.7179428
  },
  {
    lng: -43.5379121,
    lat: -22.717622
  },
  {
    lng: -43.5381697,
    lat: -22.7173915
  },
  {
    lng: -43.5385166,
    lat: -22.717164
  },
  {
    lng: -43.5389607,
    lat: -22.716843
  },
  {
    lng: -43.5390075,
    lat: -22.7168092
  },
  {
    lng: -43.5392719,
    lat: -22.7166146
  },
  {
    lng: -43.5397087,
    lat: -22.7163124
  },
  {
    lng: -43.5400017,
    lat: -22.7161114
  },
  {
    lng: -43.5403535,
    lat: -22.7159176
  },
  {
    lng: -43.5408562,
    lat: -22.7156325
  },
  {
    lng: -43.5411969,
    lat: -22.7154582
  },
  {
    lng: -43.5413058,
    lat: -22.7154025
  },
  {
    lng: -43.5415051,
    lat: -22.715308
  },
  {
    lng: -43.5416175,
    lat: -22.7152547
  },
  {
    lng: -43.5419486,
    lat: -22.7150978
  },
  {
    lng: -43.5423471,
    lat: -22.7149493
  },
  {
    lng: -43.5425283,
    lat: -22.7148818
  },
  {
    lng: -43.542464,
    lat: -22.7147897
  },
  {
    lng: -43.5423038,
    lat: -22.7145796
  },
  {
    lng: -43.5420817,
    lat: -22.7143226
  },
  {
    lng: -43.5419597,
    lat: -22.714182
  },
  {
    lng: -43.5416868,
    lat: -22.7138671
  },
  {
    lng: -43.5412418,
    lat: -22.7134227
  },
  {
    lng: -43.5410753,
    lat: -22.7132101
  },
  {
    lng: -43.5409584,
    lat: -22.7130609
  },
  {
    lng: -43.5406868,
    lat: -22.7127571
  },
  {
    lng: -43.5404149,
    lat: -22.7124881
  },
  {
    lng: -43.5399829,
    lat: -22.7119974
  },
  {
    lng: -43.539855,
    lat: -22.7118718
  },
  {
    lng: -43.5396615,
    lat: -22.7116816
  },
  {
    lng: -43.5389688,
    lat: -22.711038
  },
  {
    lng: -43.5384226,
    lat: -22.7107201
  },
  {
    lng: -43.5382356,
    lat: -22.7106404
  },
  {
    lng: -43.5379501,
    lat: -22.7105188
  },
  {
    lng: -43.5375328,
    lat: -22.7102368
  },
  {
    lng: -43.5370943,
    lat: -22.7099547
  },
  {
    lng: -43.5367097,
    lat: -22.7097078
  },
  {
    lng: -43.5362985,
    lat: -22.7094169
  },
  {
    lng: -43.5359034,
    lat: -22.709179
  },
  {
    lng: -43.5355932,
    lat: -22.7089907
  },
  {
    lng: -43.5355323,
    lat: -22.7088395
  },
  {
    lng: -43.535598,
    lat: -22.7085388
  },
  {
    lng: -43.5356184,
    lat: -22.708466
  },
  {
    lng: -43.5357148,
    lat: -22.7081227
  },
  {
    lng: -43.5356937,
    lat: -22.7074068
  },
  {
    lng: -43.5356373,
    lat: -22.7069072
  },
  {
    lng: -43.5355366,
    lat: -22.7064236
  },
  {
    lng: -43.5354849,
    lat: -22.7062549
  },
  {
    lng: -43.5354605,
    lat: -22.7061156
  },
  {
    lng: -43.5354347,
    lat: -22.7059672
  },
  {
    lng: -43.535426,
    lat: -22.7059183
  },
  {
    lng: -43.535449,
    lat: -22.7054357
  },
  {
    lng: -43.5354819,
    lat: -22.7053626
  },
  {
    lng: -43.5355086,
    lat: -22.7051581
  },
  {
    lng: -43.5355227,
    lat: -22.7050501
  },
  {
    lng: -43.535564,
    lat: -22.7046912
  },
  {
    lng: -43.535618,
    lat: -22.7043093
  },
  {
    lng: -43.5358114,
    lat: -22.7040976
  },
  {
    lng: -43.536046,
    lat: -22.7039461
  },
  {
    lng: -43.5357939,
    lat: -22.7037915
  },
  {
    lng: -43.5355477,
    lat: -22.7037238
  },
  {
    lng: -43.5352134,
    lat: -22.7036553
  },
  {
    lng: -43.5347766,
    lat: -22.70356
  },
  {
    lng: -43.5345397,
    lat: -22.703523
  },
  {
    lng: -43.5340174,
    lat: -22.7033416
  },
  {
    lng: -43.5338514,
    lat: -22.703201
  },
  {
    lng: -43.5336301,
    lat: -22.7029808
  },
  {
    lng: -43.5335714,
    lat: -22.7029646
  },
  {
    lng: -43.5335466,
    lat: -22.7029577
  },
  {
    lng: -43.5334844,
    lat: -22.7028394
  },
  {
    lng: -43.5333752,
    lat: -22.7026317
  },
  {
    lng: -43.5331401,
    lat: -22.7024209
  },
  {
    lng: -43.5328309,
    lat: -22.70214
  },
  {
    lng: -43.5323754,
    lat: -22.701862
  },
  {
    lng: -43.5321389,
    lat: -22.7017126
  },
  {
    lng: -43.531801,
    lat: -22.7015048
  },
  {
    lng: -43.5315398,
    lat: -22.7013981
  },
  {
    lng: -43.5313717,
    lat: -22.7014199
  },
  {
    lng: -43.5310396,
    lat: -22.701463
  },
  {
    lng: -43.5307123,
    lat: -22.7014388
  },
  {
    lng: -43.5305029,
    lat: -22.7014233
  },
  {
    lng: -43.5302932,
    lat: -22.701178
  },
  {
    lng: -43.5301342,
    lat: -22.7008637
  },
  {
    lng: -43.5300286,
    lat: -22.7007063
  },
  {
    lng: -43.5299621,
    lat: -22.7006072
  },
  {
    lng: -43.5296013,
    lat: -22.7004764
  },
  {
    lng: -43.5292163,
    lat: -22.7004094
  },
  {
    lng: -43.5289618,
    lat: -22.7003405
  },
  {
    lng: -43.5288311,
    lat: -22.7003051
  },
  {
    lng: -43.5285692,
    lat: -22.7000497
  },
  {
    lng: -43.5283345,
    lat: -22.6998042
  },
  {
    lng: -43.5280231,
    lat: -22.6997318
  },
  {
    lng: -43.5275926,
    lat: -22.699699
  },
  {
    lng: -43.5271247,
    lat: -22.699654
  },
  {
    lng: -43.5268733,
    lat: -22.6996323
  },
  {
    lng: -43.5268252,
    lat: -22.6996281
  },
  {
    lng: -43.5264528,
    lat: -22.6994161
  },
  {
    lng: -43.5262993,
    lat: -22.6992623
  },
  {
    lng: -43.5262427,
    lat: -22.6992056
  },
  {
    lng: -43.526047,
    lat: -22.6988213
  },
  {
    lng: -43.5258245,
    lat: -22.6985991
  },
  {
    lng: -43.5257659,
    lat: -22.6985696
  },
  {
    lng: -43.5256828,
    lat: -22.6985278
  },
  {
    lng: -43.5254098,
    lat: -22.6985008
  },
  {
    lng: -43.5250219,
    lat: -22.698489
  },
  {
    lng: -43.5249254,
    lat: -22.6984522
  },
  {
    lng: -43.5248572,
    lat: -22.6984261
  },
  {
    lng: -43.5248023,
    lat: -22.6984052
  },
  {
    lng: -43.5245924,
    lat: -22.6981938
  },
  {
    lng: -43.524391,
    lat: -22.6979613
  },
  {
    lng: -43.5243752,
    lat: -22.6979431
  },
  {
    lng: -43.5242372,
    lat: -22.6976554
  },
  {
    lng: -43.5240269,
    lat: -22.6973542
  },
  {
    lng: -43.5237685,
    lat: -22.6969926
  },
  {
    lng: -43.5235839,
    lat: -22.6967359
  },
  {
    lng: -43.5233625,
    lat: -22.696421
  },
  {
    lng: -43.5231269,
    lat: -22.6962682
  },
  {
    lng: -43.5227171,
    lat: -22.6960442
  },
  {
    lng: -43.5223442,
    lat: -22.6958786
  },
  {
    lng: -43.5221591,
    lat: -22.6956683
  },
  {
    lng: -43.5220701,
    lat: -22.6954754
  },
  {
    lng: -43.5220249,
    lat: -22.6953773
  },
  {
    lng: -43.5218283,
    lat: -22.6950742
  },
  {
    lng: -43.5215906,
    lat: -22.6947847
  },
  {
    lng: -43.5214831,
    lat: -22.6946538
  },
  {
    lng: -43.5210907,
    lat: -22.6942789
  },
  {
    lng: -43.5206672,
    lat: -22.694108
  },
  {
    lng: -43.5203447,
    lat: -22.6941097
  },
  {
    lng: -43.5201765,
    lat: -22.694213
  },
  {
    lng: -43.5199752,
    lat: -22.6943502
  },
  {
    lng: -43.5198217,
    lat: -22.6946848
  },
  {
    lng: -43.5196328,
    lat: -22.6948337
  },
  {
    lng: -43.5192191,
    lat: -22.6949806
  },
  {
    lng: -43.5188564,
    lat: -22.6950236
  },
  {
    lng: -43.5182327,
    lat: -22.6949598
  },
  {
    lng: -43.5176848,
    lat: -22.6948041
  },
  {
    lng: -43.5176181,
    lat: -22.6947527
  },
  {
    lng: -43.5173438,
    lat: -22.6945414
  },
  {
    lng: -43.5171567,
    lat: -22.6944584
  },
  {
    lng: -43.5167404,
    lat: -22.6943666
  },
  {
    lng: -43.5164148,
    lat: -22.6944447
  },
  {
    lng: -43.5160398,
    lat: -22.694476
  },
  {
    lng: -43.5155663,
    lat: -22.6943789
  },
  {
    lng: -43.5152684,
    lat: -22.6942066
  },
  {
    lng: -43.5150694,
    lat: -22.6941506
  },
  {
    lng: -43.5146693,
    lat: -22.6941736
  },
  {
    lng: -43.5143114,
    lat: -22.6941513
  },
  {
    lng: -43.5143088,
    lat: -22.6941511
  },
  {
    lng: -43.5142325,
    lat: -22.6941463
  },
  {
    lng: -43.514046,
    lat: -22.6940635
  },
  {
    lng: -43.5138859,
    lat: -22.6938534
  },
  {
    lng: -43.5138593,
    lat: -22.6935095
  },
  {
    lng: -43.5140427,
    lat: -22.6932175
  },
  {
    lng: -43.5140337,
    lat: -22.692893
  },
  {
    lng: -43.5139244,
    lat: -22.6926023
  },
  {
    lng: -43.513732,
    lat: -22.6919168
  },
  {
    lng: -43.5136135,
    lat: -22.6917727
  },
  {
    lng: -43.5130582,
    lat: -22.6917512
  },
  {
    lng: -43.512186,
    lat: -22.6916858
  },
  {
    lng: -43.5122146,
    lat: -22.6914897
  },
  {
    lng: -43.5132718,
    lat: -22.6913535
  },
  {
    lng: -43.5135538,
    lat: -22.6913155
  },
  {
    lng: -43.5139332,
    lat: -22.6913009
  },
  {
    lng: -43.5139366,
    lat: -22.6913007
  },
  {
    lng: -43.5142227,
    lat: -22.6912896
  },
  {
    lng: -43.5148911,
    lat: -22.6913046
  },
  {
    lng: -43.515594,
    lat: -22.6913851
  },
  {
    lng: -43.5162612,
    lat: -22.691498
  },
  {
    lng: -43.5168759,
    lat: -22.691594
  },
  {
    lng: -43.5173767,
    lat: -22.691648
  },
  {
    lng: -43.5177636,
    lat: -22.6916682
  },
  {
    lng: -43.5183351,
    lat: -22.6916903
  },
  {
    lng: -43.5188099,
    lat: -22.6917114
  },
  {
    lng: -43.5195222,
    lat: -22.6917431
  },
  {
    lng: -43.5202697,
    lat: -22.691767
  },
  {
    lng: -43.5212283,
    lat: -22.6917929
  },
  {
    lng: -43.5219587,
    lat: -22.6917676
  },
  {
    lng: -43.5224596,
    lat: -22.6917262
  },
  {
    lng: -43.5224871,
    lat: -22.691724
  },
  {
    lng: -43.5230076,
    lat: -22.6915986
  },
  {
    lng: -43.5234847,
    lat: -22.6914238
  },
  {
    lng: -43.5239976,
    lat: -22.6911922
  },
  {
    lng: -43.524502,
    lat: -22.6909443
  },
  {
    lng: -43.5249975,
    lat: -22.6906962
  },
  {
    lng: -43.5256344,
    lat: -22.6904005
  },
  {
    lng: -43.5260416,
    lat: -22.6901842
  },
  {
    lng: -43.5266865,
    lat: -22.6899458
  },
  {
    lng: -43.5270394,
    lat: -22.6898596
  },
  {
    lng: -43.5274544,
    lat: -22.6897331
  },
  {
    lng: -43.528081,
    lat: -22.6895598
  },
  {
    lng: -43.52869,
    lat: -22.6893863
  },
  {
    lng: -43.5291748,
    lat: -22.6893014
  },
  {
    lng: -43.5296503,
    lat: -22.6892653
  },
  {
    lng: -43.5301961,
    lat: -22.6892381
  },
  {
    lng: -43.5306185,
    lat: -22.689226
  },
  {
    lng: -43.5310937,
    lat: -22.6892063
  },
  {
    lng: -43.5317713,
    lat: -22.6891886
  },
  {
    lng: -43.5324227,
    lat: -22.6891543
  },
  {
    lng: -43.53281,
    lat: -22.6891337
  },
  {
    lng: -43.5332595,
    lat: -22.6890647
  },
  {
    lng: -43.533824,
    lat: -22.6889397
  },
  {
    lng: -43.5342478,
    lat: -22.6888052
  },
  {
    lng: -43.5344428,
    lat: -22.6887435
  },
  {
    lng: -43.534548,
    lat: -22.6887102
  },
  {
    lng: -43.5346303,
    lat: -22.688672
  },
  {
    lng: -43.5348752,
    lat: -22.6885584
  },
  {
    lng: -43.5351139,
    lat: -22.6884628
  },
  {
    lng: -43.5352355,
    lat: -22.6883984
  },
  {
    lng: -43.535583,
    lat: -22.6882144
  },
  {
    lng: -43.5356722,
    lat: -22.6879888
  },
  {
    lng: -43.5357142,
    lat: -22.6878824
  },
  {
    lng: -43.5359709,
    lat: -22.6876368
  },
  {
    lng: -43.5361669,
    lat: -22.6874492
  },
  {
    lng: -43.5372191,
    lat: -22.6865842
  },
  {
    lng: -43.5377742,
    lat: -22.6859656
  },
  {
    lng: -43.5388844,
    lat: -22.6846342
  },
  {
    lng: -43.5398186,
    lat: -22.6834718
  },
  {
    lng: -43.5401687,
    lat: -22.6829971
  },
  {
    lng: -43.5403142,
    lat: -22.6828789
  },
  {
    lng: -43.5405184,
    lat: -22.6825605
  },
  {
    lng: -43.5406434,
    lat: -22.6824326
  },
  {
    lng: -43.5411884,
    lat: -22.6815487
  },
  {
    lng: -43.5414653,
    lat: -22.6811736
  },
  {
    lng: -43.5419692,
    lat: -22.6807624
  },
  {
    lng: -43.5423588,
    lat: -22.6804505
  },
  {
    lng: -43.5430314,
    lat: -22.6801603
  },
  {
    lng: -43.5437496,
    lat: -22.6799422
  },
  {
    lng: -43.5441726,
    lat: -22.679884
  },
  {
    lng: -43.5447848,
    lat: -22.6799469
  },
  {
    lng: -43.5456812,
    lat: -22.6804091
  },
  {
    lng: -43.5467002,
    lat: -22.6809727
  },
  {
    lng: -43.5471391,
    lat: -22.6812324
  },
  {
    lng: -43.5477609,
    lat: -22.681483
  },
  {
    lng: -43.548269,
    lat: -22.6815294
  },
  {
    lng: -43.5486368,
    lat: -22.6814551
  },
  {
    lng: -43.548955,
    lat: -22.6811951
  },
  {
    lng: -43.5492936,
    lat: -22.6808278
  },
  {
    lng: -43.5496838,
    lat: -22.6804549
  },
  {
    lng: -43.5501266,
    lat: -22.679981
  },
  {
    lng: -43.5505549,
    lat: -22.6796563
  },
  {
    lng: -43.5510269,
    lat: -22.6794575
  },
  {
    lng: -43.551523,
    lat: -22.6794261
  },
  {
    lng: -43.5521981,
    lat: -22.6795039
  },
  {
    lng: -43.5531044,
    lat: -22.6796495
  },
  {
    lng: -43.554066,
    lat: -22.679948
  },
  {
    lng: -43.5544338,
    lat: -22.6799542
  },
  {
    lng: -43.5547305,
    lat: -22.6799031
  },
  {
    lng: -43.5550272,
    lat: -22.6798521
  },
  {
    lng: -43.555438,
    lat: -22.6797405
  },
  {
    lng: -43.5554336,
    lat: -22.679758
  },
  {
    lng: -43.5554022,
    lat: -22.6799656
  },
  {
    lng: -43.5553912,
    lat: -22.6801017
  },
  {
    lng: -43.5553971,
    lat: -22.6802098
  },
  {
    lng: -43.5554197,
    lat: -22.680321
  },
  {
    lng: -43.5554631,
    lat: -22.6805339
  },
  {
    lng: -43.5554808,
    lat: -22.6806573
  },
  {
    lng: -43.5554901,
    lat: -22.6808007
  },
  {
    lng: -43.5554649,
    lat: -22.680907
  },
  {
    lng: -43.5554089,
    lat: -22.6810258
  },
  {
    lng: -43.555311,
    lat: -22.6812351
  },
  {
    lng: -43.5552031,
    lat: -22.6813945
  },
  {
    lng: -43.5549449,
    lat: -22.6817837
  },
  {
    lng: -43.5548598,
    lat: -22.681924
  },
  {
    lng: -43.5548245,
    lat: -22.6820062
  },
  {
    lng: -43.5547942,
    lat: -22.6821244
  },
  {
    lng: -43.554789,
    lat: -22.6822669
  },
  {
    lng: -43.5547933,
    lat: -22.6824358
  },
  {
    lng: -43.5548703,
    lat: -22.6828923
  },
  {
    lng: -43.5549318,
    lat: -22.6831676
  },
  {
    lng: -43.5549621,
    lat: -22.6833291
  },
  {
    lng: -43.5549927,
    lat: -22.6834447
  },
  {
    lng: -43.5550291,
    lat: -22.6836161
  },
  {
    lng: -43.5550885,
    lat: -22.6841837
  },
  {
    lng: -43.5551014,
    lat: -22.684432
  },
  {
    lng: -43.5551054,
    lat: -22.6847098
  },
  {
    lng: -43.5551158,
    lat: -22.6848311
  },
  {
    lng: -43.55514,
    lat: -22.6849271
  },
  {
    lng: -43.5551872,
    lat: -22.6850266
  },
  {
    lng: -43.5552248,
    lat: -22.6850997
  },
  {
    lng: -43.5552448,
    lat: -22.685125
  },
  {
    lng: -43.5552749,
    lat: -22.6851629
  },
  {
    lng: -43.5553043,
    lat: -22.6851999
  },
  {
    lng: -43.5553692,
    lat: -22.6852597
  },
  {
    lng: -43.5555168,
    lat: -22.6853526
  },
  {
    lng: -43.5556409,
    lat: -22.6854215
  },
  {
    lng: -43.5558099,
    lat: -22.6855044
  },
  {
    lng: -43.5558483,
    lat: -22.6855293
  },
  {
    lng: -43.5558757,
    lat: -22.6855472
  },
  {
    lng: -43.5559889,
    lat: -22.685625
  },
  {
    lng: -43.5560712,
    lat: -22.685715
  },
  {
    lng: -43.5561508,
    lat: -22.6858377
  },
  {
    lng: -43.556177,
    lat: -22.6859216
  },
  {
    lng: -43.5562042,
    lat: -22.686024
  },
  {
    lng: -43.5562115,
    lat: -22.6861294
  },
  {
    lng: -43.5562114,
    lat: -22.6862769
  },
  {
    lng: -43.5562063,
    lat: -22.6864204
  },
  {
    lng: -43.5561804,
    lat: -22.6866036
  },
  {
    lng: -43.5561046,
    lat: -22.6869648
  },
  {
    lng: -43.5560582,
    lat: -22.6872433
  },
  {
    lng: -43.556038,
    lat: -22.687394
  },
  {
    lng: -43.5560345,
    lat: -22.6875056
  },
  {
    lng: -43.556039,
    lat: -22.6876051
  },
  {
    lng: -43.5560429,
    lat: -22.6876571
  },
  {
    lng: -43.5560539,
    lat: -22.687735
  },
  {
    lng: -43.5560804,
    lat: -22.6878135
  },
  {
    lng: -43.5561835,
    lat: -22.6880182
  },
  {
    lng: -43.5562559,
    lat: -22.6881644
  },
  {
    lng: -43.556296,
    lat: -22.6882621
  },
  {
    lng: -43.5563186,
    lat: -22.6883549
  },
  {
    lng: -43.5563227,
    lat: -22.6884442
  },
  {
    lng: -43.556316,
    lat: -22.6884816
  },
  {
    lng: -43.5563089,
    lat: -22.6885214
  },
  {
    lng: -43.5562791,
    lat: -22.6886455
  },
  {
    lng: -43.5562072,
    lat: -22.6888098
  },
  {
    lng: -43.5561059,
    lat: -22.6890665
  },
  {
    lng: -43.5560389,
    lat: -22.6892643
  },
  {
    lng: -43.556009,
    lat: -22.6893847
  },
  {
    lng: -43.5560355,
    lat: -22.6893979
  },
  {
    lng: -43.5561301,
    lat: -22.689433
  },
  {
    lng: -43.556268,
    lat: -22.6894725
  },
  {
    lng: -43.5564215,
    lat: -22.6895126
  },
  {
    lng: -43.5566451,
    lat: -22.6895811
  },
  {
    lng: -43.556935,
    lat: -22.6896924
  },
  {
    lng: -43.5574364,
    lat: -22.689872
  },
  {
    lng: -43.5578894,
    lat: -22.6900326
  },
  {
    lng: -43.5579315,
    lat: -22.6900601
  },
  {
    lng: -43.5579634,
    lat: -22.6901046
  },
  {
    lng: -43.5579872,
    lat: -22.6901256
  },
  {
    lng: -43.5580175,
    lat: -22.6901412
  },
  {
    lng: -43.5580513,
    lat: -22.6901511
  },
  {
    lng: -43.5580919,
    lat: -22.6901525
  },
  {
    lng: -43.5581826,
    lat: -22.69015
  },
  {
    lng: -43.5582325,
    lat: -22.6901519
  },
  {
    lng: -43.5582915,
    lat: -22.6901598
  },
  {
    lng: -43.5583406,
    lat: -22.690176
  },
  {
    lng: -43.5589263,
    lat: -22.6903792
  },
  {
    lng: -43.5594603,
    lat: -22.690572
  },
  {
    lng: -43.5595582,
    lat: -22.6906074
  },
  {
    lng: -43.5596932,
    lat: -22.6906586
  },
  {
    lng: -43.5597823,
    lat: -22.6906879
  },
  {
    lng: -43.5598347,
    lat: -22.6907042
  },
  {
    lng: -43.5598845,
    lat: -22.6907089
  },
  {
    lng: -43.5599252,
    lat: -22.6907104
  },
  {
    lng: -43.559963,
    lat: -22.690706
  },
  {
    lng: -43.5600139,
    lat: -22.6906934
  },
  {
    lng: -43.5601221,
    lat: -22.6906627
  },
  {
    lng: -43.5602054,
    lat: -22.690631
  },
  {
    lng: -43.5603466,
    lat: -22.6905725
  },
  {
    lng: -43.5606336,
    lat: -22.6904846
  },
  {
    lng: -43.5610931,
    lat: -22.6903422
  },
  {
    lng: -43.5612493,
    lat: -22.6902958
  },
  {
    lng: -43.5613702,
    lat: -22.6902654
  },
  {
    lng: -43.561468,
    lat: -22.6902574
  },
  {
    lng: -43.561547,
    lat: -22.6902458
  },
  {
    lng: -43.5615857,
    lat: -22.6902269
  },
  {
    lng: -43.5617714,
    lat: -22.6901613
  },
  {
    lng: -43.5619381,
    lat: -22.6900979
  },
  {
    lng: -43.5621232,
    lat: -22.6900438
  },
  {
    lng: -43.5623366,
    lat: -22.6899878
  },
  {
    lng: -43.5625393,
    lat: -22.6899315
  },
  {
    lng: -43.5632133,
    lat: -22.689731
  },
  {
    lng: -43.5637903,
    lat: -22.6895701
  },
  {
    lng: -43.5639806,
    lat: -22.6895335
  },
  {
    lng: -43.5640953,
    lat: -22.6895316
  },
  {
    lng: -43.5642177,
    lat: -22.6895528
  },
  {
    lng: -43.5644048,
    lat: -22.6896133
  },
  {
    lng: -43.5647074,
    lat: -22.6897468
  },
  {
    lng: -43.5647482,
    lat: -22.6897648
  },
  {
    lng: -43.5653118,
    lat: -22.6900181
  },
  {
    lng: -43.565476,
    lat: -22.6901066
  },
  {
    lng: -43.5656652,
    lat: -22.6901645
  },
  {
    lng: -43.565813,
    lat: -22.6902238
  },
  {
    lng: -43.5659811,
    lat: -22.6903268
  },
  {
    lng: -43.5660837,
    lat: -22.6903761
  },
  {
    lng: -43.5662237,
    lat: -22.6904106
  },
  {
    lng: -43.5663093,
    lat: -22.6904135
  },
  {
    lng: -43.5663883,
    lat: -22.6904018
  },
  {
    lng: -43.5664666,
    lat: -22.6903757
  },
  {
    lng: -43.5666226,
    lat: -22.6902864
  },
  {
    lng: -43.5670044,
    lat: -22.6900699
  },
  {
    lng: -43.5671066,
    lat: -22.6900303
  },
  {
    lng: -43.5672189,
    lat: -22.6900255
  },
  {
    lng: -43.5673383,
    lat: -22.6900295
  },
  {
    lng: -43.5678464,
    lat: -22.6901524
  },
  {
    lng: -43.5681636,
    lat: -22.6902118
  },
  {
    lng: -43.5683231,
    lat: -22.6902171
  },
  {
    lng: -43.5684177,
    lat: -22.690203
  },
  {
    lng: -43.5685017,
    lat: -22.6901628
  },
  {
    lng: -43.5690793,
    lat: -22.689921
  },
  {
    lng: -43.5692813,
    lat: -22.6898388
  },
  {
    lng: -43.569466,
    lat: -22.6898105
  },
  {
    lng: -43.5695689,
    lat: -22.6898139
  },
  {
    lng: -43.5697361,
    lat: -22.6898223
  },
  {
    lng: -43.5701114,
    lat: -22.6898749
  },
  {
    lng: -43.5705697,
    lat: -22.6899589
  },
  {
    lng: -43.5708815,
    lat: -22.6900009
  },
  {
    lng: -43.5710122,
    lat: -22.6900052
  },
  {
    lng: -43.5711281,
    lat: -22.6900004
  },
  {
    lng: -43.5712021,
    lat: -22.6899742
  },
  {
    lng: -43.5713312,
    lat: -22.6899038
  },
  {
    lng: -43.571517,
    lat: -22.6897893
  },
  {
    lng: -43.5720908,
    lat: -22.6894062
  },
  {
    lng: -43.5722111,
    lat: -22.6893426
  },
  {
    lng: -43.5722609,
    lat: -22.6893227
  },
  {
    lng: -43.5723536,
    lat: -22.68932
  },
  {
    lng: -43.5724574,
    lat: -22.6893378
  },
  {
    lng: -43.5725866,
    lat: -22.6893707
  },
  {
    lng: -43.5727162,
    lat: -22.6894238
  },
  {
    lng: -43.5729427,
    lat: -22.6895346
  },
  {
    lng: -43.5729631,
    lat: -22.6895434
  },
  {
    lng: -43.5729813,
    lat: -22.6895512
  },
  {
    lng: -43.5730047,
    lat: -22.6895612
  },
  {
    lng: -43.5732068,
    lat: -22.6896478
  },
  {
    lng: -43.5736608,
    lat: -22.6898353
  },
  {
    lng: -43.5739801,
    lat: -22.6899781
  },
  {
    lng: -43.5740965,
    lat: -22.690051
  },
  {
    lng: -43.574333,
    lat: -22.6902114
  },
  {
    lng: -43.5746604,
    lat: -22.6904612
  },
  {
    lng: -43.5748151,
    lat: -22.6906086
  },
  {
    lng: -43.5750764,
    lat: -22.6907914
  },
  {
    lng: -43.5755068,
    lat: -22.6911202
  },
  {
    lng: -43.5758898,
    lat: -22.6914187
  },
  {
    lng: -43.5760965,
    lat: -22.6916018
  },
  {
    lng: -43.5762364,
    lat: -22.6917595
  },
  {
    lng: -43.5764194,
    lat: -22.6918986
  },
  {
    lng: -43.5765419,
    lat: -22.6919837
  },
  {
    lng: -43.5766464,
    lat: -22.6920306
  },
  {
    lng: -43.5767924,
    lat: -22.6920587
  },
  {
    lng: -43.5769625,
    lat: -22.6921021
  },
  {
    lng: -43.5770369,
    lat: -22.6921047
  },
  {
    lng: -43.5771944,
    lat: -22.6920696
  },
  {
    lng: -43.5776749,
    lat: -22.6919907
  },
  {
    lng: -43.5780908,
    lat: -22.6919297
  },
  {
    lng: -43.5782202,
    lat: -22.6919254
  },
  {
    lng: -43.578352,
    lat: -22.6919357
  },
  {
    lng: -43.5784987,
    lat: -22.6919292
  },
  {
    lng: -43.5786295,
    lat: -22.6919018
  },
  {
    lng: -43.5786572,
    lat: -22.6918877
  },
  {
    lng: -43.5787145,
    lat: -22.6918584
  },
  {
    lng: -43.5787684,
    lat: -22.691814
  },
  {
    lng: -43.5787976,
    lat: -22.6917659
  },
  {
    lng: -43.5788337,
    lat: -22.6916774
  },
  {
    lng: -43.5788357,
    lat: -22.6916659
  },
  {
    lng: -43.5789648,
    lat: -22.6917891
  },
  {
    lng: -43.5790837,
    lat: -22.6918789
  },
  {
    lng: -43.5791673,
    lat: -22.6919421
  },
  {
    lng: -43.5792559,
    lat: -22.691974
  },
  {
    lng: -43.5802101,
    lat: -22.6920297
  },
  {
    lng: -43.5807307,
    lat: -22.6920417
  },
  {
    lng: -43.5809214,
    lat: -22.6920366
  },
  {
    lng: -43.5810437,
    lat: -22.6919713
  },
  {
    lng: -43.5812171,
    lat: -22.6918267
  },
  {
    lng: -43.581365,
    lat: -22.6917507
  },
  {
    lng: -43.5814311,
    lat: -22.6917413
  },
  {
    lng: -43.5815611,
    lat: -22.6917631
  },
  {
    lng: -43.5816999,
    lat: -22.6918547
  },
  {
    lng: -43.5819887,
    lat: -22.6920035
  },
  {
    lng: -43.5822558,
    lat: -22.6920938
  },
  {
    lng: -43.5828495,
    lat: -22.6921662
  },
  {
    lng: -43.5837019,
    lat: -22.6922707
  },
  {
    lng: -43.5842625,
    lat: -22.6924345
  },
  {
    lng: -43.5856417,
    lat: -22.692978
  },
  {
    lng: -43.585872,
    lat: -22.6930766
  },
  {
    lng: -43.5913742,
    lat: -22.6893576
  },
  {
    lng: -43.5920775,
    lat: -22.6888822
  },
  {
    lng: -43.59389,
    lat: -22.6876571
  },
  {
    lng: -43.5965961,
    lat: -22.685828
  },
  {
    lng: -43.5967951,
    lat: -22.6856935
  },
  {
    lng: -43.5970274,
    lat: -22.6859197
  },
  {
    lng: -43.5971669,
    lat: -22.6860583
  },
  {
    lng: -43.5974458,
    lat: -22.6863849
  },
  {
    lng: -43.597596,
    lat: -22.6865136
  },
  {
    lng: -43.5978213,
    lat: -22.6867215
  },
  {
    lng: -43.5980252,
    lat: -22.6868997
  },
  {
    lng: -43.598111,
    lat: -22.6870284
  },
  {
    lng: -43.5982076,
    lat: -22.6871471
  },
  {
    lng: -43.5982612,
    lat: -22.6871966
  },
  {
    lng: -43.5983256,
    lat: -22.6873154
  },
  {
    lng: -43.5985938,
    lat: -22.6876124
  },
  {
    lng: -43.5987869,
    lat: -22.6877807
  },
  {
    lng: -43.5989586,
    lat: -22.6878994
  },
  {
    lng: -43.5991625,
    lat: -22.6881073
  },
  {
    lng: -43.5993234,
    lat: -22.6882657
  },
  {
    lng: -43.5994199,
    lat: -22.6884637
  },
  {
    lng: -43.5995058,
    lat: -22.6885528
  },
  {
    lng: -43.599656,
    lat: -22.6886121
  },
  {
    lng: -43.599892,
    lat: -22.6888299
  },
  {
    lng: -43.6001173,
    lat: -22.6890774
  },
  {
    lng: -43.6002246,
    lat: -22.6892754
  },
  {
    lng: -43.6004177,
    lat: -22.6894634
  },
  {
    lng: -43.6006108,
    lat: -22.6896218
  },
  {
    lng: -43.6007825,
    lat: -22.6898396
  },
  {
    lng: -43.6010293,
    lat: -22.690087
  },
  {
    lng: -43.6012438,
    lat: -22.6903246
  },
  {
    lng: -43.6014692,
    lat: -22.6904929
  },
  {
    lng: -43.601673,
    lat: -22.6906611
  },
  {
    lng: -43.6017696,
    lat: -22.6908294
  },
  {
    lng: -43.601909,
    lat: -22.6910373
  },
  {
    lng: -43.6020592,
    lat: -22.6911957
  },
  {
    lng: -43.6023275,
    lat: -22.6914332
  },
  {
    lng: -43.6025742,
    lat: -22.6916708
  },
  {
    lng: -43.6027244,
    lat: -22.6918489
  },
  {
    lng: -43.6028961,
    lat: -22.6920568
  },
  {
    lng: -43.6031858,
    lat: -22.6922647
  },
  {
    lng: -43.6033682,
    lat: -22.6924725
  },
  {
    lng: -43.6035184,
    lat: -22.6926507
  },
  {
    lng: -43.6037377,
    lat: -22.6928048
  },
  {
    lng: -43.6038874,
    lat: -22.6929085
  },
  {
    lng: -43.6041701,
    lat: -22.6929932
  },
  {
    lng: -43.6046175,
    lat: -22.6929828
  },
  {
    lng: -43.6050042,
    lat: -22.6929083
  },
  {
    lng: -43.6052765,
    lat: -22.6928845
  },
  {
    lng: -43.6056737,
    lat: -22.6928395
  },
  {
    lng: -43.6060876,
    lat: -22.6927848
  },
  {
    lng: -43.6065221,
    lat: -22.6927356
  },
  {
    lng: -43.6068443,
    lat: -22.6926606
  },
  {
    lng: -43.6072198,
    lat: -22.6926309
  },
  {
    lng: -43.6075979,
    lat: -22.6925992
  },
  {
    lng: -43.6080802,
    lat: -22.6925688
  },
  {
    lng: -43.6088554,
    lat: -22.6925425
  },
  {
    lng: -43.6092201,
    lat: -22.6924878
  },
  {
    lng: -43.6094707,
    lat: -22.6924591
  },
  {
    lng: -43.6097089,
    lat: -22.6925022
  },
  {
    lng: -43.6100833,
    lat: -22.6926074
  },
  {
    lng: -43.6104063,
    lat: -22.6929378
  },
  {
    lng: -43.6103955,
    lat: -22.6932545
  },
  {
    lng: -43.6104814,
    lat: -22.6937989
  },
  {
    lng: -43.6107818,
    lat: -22.6945116
  },
  {
    lng: -43.6109642,
    lat: -22.6953034
  },
  {
    lng: -43.6110715,
    lat: -22.6956499
  },
  {
    lng: -43.6111252,
    lat: -22.6959468
  },
  {
    lng: -43.6112197,
    lat: -22.6961655
  },
  {
    lng: -43.6112646,
    lat: -22.6962932
  },
  {
    lng: -43.6113719,
    lat: -22.69661
  },
  {
    lng: -43.6114577,
    lat: -22.697095
  },
  {
    lng: -43.6116079,
    lat: -22.6975701
  },
  {
    lng: -43.6117688,
    lat: -22.698065
  },
  {
    lng: -43.6117903,
    lat: -22.6983124
  },
  {
    lng: -43.6118654,
    lat: -22.6985104
  },
  {
    lng: -43.6119512,
    lat: -22.6986192
  },
  {
    lng: -43.6122624,
    lat: -22.6987974
  },
  {
    lng: -43.6126057,
    lat: -22.6989855
  },
  {
    lng: -43.6130241,
    lat: -22.6991141
  },
  {
    lng: -43.6133031,
    lat: -22.699124
  },
  {
    lng: -43.6134854,
    lat: -22.699223
  },
  {
    lng: -43.6136464,
    lat: -22.6993022
  },
  {
    lng: -43.6137108,
    lat: -22.6994705
  },
  {
    lng: -43.6138931,
    lat: -22.6995397
  },
  {
    lng: -43.6139897,
    lat: -22.6996981
  },
  {
    lng: -43.6140132,
    lat: -22.699779
  },
  {
    lng: -43.6142257,
    lat: -22.7000049
  },
  {
    lng: -43.6143652,
    lat: -22.7002524
  },
  {
    lng: -43.6145583,
    lat: -22.7004206
  },
  {
    lng: -43.6149017,
    lat: -22.7008166
  },
  {
    lng: -43.6152235,
    lat: -22.7012422
  },
  {
    lng: -43.6153952,
    lat: -22.70145
  },
  {
    lng: -43.6155132,
    lat: -22.7016974
  },
  {
    lng: -43.6157707,
    lat: -22.7017568
  },
  {
    lng: -43.615878,
    lat: -22.7018855
  },
  {
    lng: -43.6159638,
    lat: -22.7020736
  },
  {
    lng: -43.6163071,
    lat: -22.7025684
  },
  {
    lng: -43.6165646,
    lat: -22.7027466
  },
  {
    lng: -43.6166719,
    lat: -22.7028753
  },
  {
    lng: -43.6168758,
    lat: -22.7032118
  },
  {
    lng: -43.617262,
    lat: -22.7036671
  },
  {
    lng: -43.6174658,
    lat: -22.7038749
  },
  {
    lng: -43.6177126,
    lat: -22.7041817
  },
  {
    lng: -43.6178843,
    lat: -22.7044589
  },
  {
    lng: -43.6179808,
    lat: -22.7045677
  },
  {
    lng: -43.6180667,
    lat: -22.7047459
  },
  {
    lng: -43.6181632,
    lat: -22.7049933
  },
  {
    lng: -43.6181739,
    lat: -22.7052507
  },
  {
    lng: -43.618131,
    lat: -22.7056663
  },
  {
    lng: -43.6181847,
    lat: -22.7059831
  },
  {
    lng: -43.6182169,
    lat: -22.7065076
  },
  {
    lng: -43.6182383,
    lat: -22.7068738
  },
  {
    lng: -43.6181632,
    lat: -22.7069728
  },
  {
    lng: -43.6182061,
    lat: -22.7073885
  },
  {
    lng: -43.6183456,
    lat: -22.7084079
  },
  {
    lng: -43.6183885,
    lat: -22.7085959
  },
  {
    lng: -43.6184851,
    lat: -22.7088533
  },
  {
    lng: -43.6186782,
    lat: -22.7090611
  },
  {
    lng: -43.6190215,
    lat: -22.7094174
  },
  {
    lng: -43.6193541,
    lat: -22.7096351
  },
  {
    lng: -43.6195151,
    lat: -22.7098826
  },
  {
    lng: -43.6198155,
    lat: -22.7101597
  },
  {
    lng: -43.6200515,
    lat: -22.7103774
  },
  {
    lng: -43.6201159,
    lat: -22.7104665
  },
  {
    lng: -43.6207811,
    lat: -22.7111989
  },
  {
    lng: -43.6209742,
    lat: -22.7113473
  },
  {
    lng: -43.6210922,
    lat: -22.7114562
  },
  {
    lng: -43.6212102,
    lat: -22.7115551
  },
  {
    lng: -43.6213068,
    lat: -22.7117234
  },
  {
    lng: -43.6213926,
    lat: -22.7118026
  },
  {
    lng: -43.6214892,
    lat: -22.7118026
  },
  {
    lng: -43.6215428,
    lat: -22.7119213
  },
  {
    lng: -43.6216608,
    lat: -22.7120599
  },
  {
    lng: -43.6218539,
    lat: -22.7121786
  },
  {
    lng: -43.6220578,
    lat: -22.7123964
  },
  {
    lng: -43.6221329,
    lat: -22.712624
  },
  {
    lng: -43.6223689,
    lat: -22.7127329
  },
  {
    lng: -43.6224655,
    lat: -22.7128615
  },
  {
    lng: -43.6226371,
    lat: -22.7130793
  },
  {
    lng: -43.6228517,
    lat: -22.7132772
  },
  {
    lng: -43.6231414,
    lat: -22.7135939
  },
  {
    lng: -43.6233774,
    lat: -22.7138116
  },
  {
    lng: -43.6237851,
    lat: -22.7142174
  },
  {
    lng: -43.6241606,
    lat: -22.7146034
  },
  {
    lng: -43.624343,
    lat: -22.7147716
  },
  {
    lng: -43.6246649,
    lat: -22.7150685
  },
  {
    lng: -43.6250726,
    lat: -22.7155139
  },
  {
    lng: -43.6255017,
    lat: -22.715979
  },
  {
    lng: -43.6256949,
    lat: -22.7161077
  },
  {
    lng: -43.6257914,
    lat: -22.7161868
  },
  {
    lng: -43.6258236,
    lat: -22.7162858
  },
  {
    lng: -43.6259631,
    lat: -22.7164144
  },
  {
    lng: -43.6260382,
    lat: -22.716553
  },
  {
    lng: -43.6261026,
    lat: -22.7166322
  },
  {
    lng: -43.6261455,
    lat: -22.7167014
  },
  {
    lng: -43.6263064,
    lat: -22.7168103
  },
  {
    lng: -43.6265746,
    lat: -22.717127
  },
  {
    lng: -43.6267141,
    lat: -22.7172557
  },
  {
    lng: -43.6268536,
    lat: -22.7172557
  },
  {
    lng: -43.6269287,
    lat: -22.7174239
  },
  {
    lng: -43.6270789,
    lat: -22.7175328
  },
  {
    lng: -43.6271969,
    lat: -22.7177307
  },
  {
    lng: -43.6274973,
    lat: -22.717988
  },
  {
    lng: -43.6277655,
    lat: -22.7182255
  },
  {
    lng: -43.6279694,
    lat: -22.7184729
  },
  {
    lng: -43.6281625,
    lat: -22.7187698
  },
  {
    lng: -43.6284522,
    lat: -22.7189875
  },
  {
    lng: -43.6286453,
    lat: -22.7192448
  },
  {
    lng: -43.6287848,
    lat: -22.7194428
  },
  {
    lng: -43.6288599,
    lat: -22.7197594
  },
  {
    lng: -43.6289457,
    lat: -22.7198881
  },
  {
    lng: -43.6290315,
    lat: -22.720086
  },
  {
    lng: -43.6290768,
    lat: -22.7206513
  },
  {
    lng: -43.6290358,
    lat: -22.7210521
  },
  {
    lng: -43.6284204,
    lat: -22.7225753
  },
  {
    lng: -43.6387097,
    lat: -22.7203619
  },
  {
    lng: -43.6388779,
    lat: -22.7208821
  },
  {
    lng: -43.6390122,
    lat: -22.7217458
  },
  {
    lng: -43.639233,
    lat: -22.722572
  },
  {
    lng: -43.6394581,
    lat: -22.7232153
  },
  {
    lng: -43.6396756,
    lat: -22.7238217
  },
  {
    lng: -43.6397863,
    lat: -22.7242859
  },
  {
    lng: -43.6399722,
    lat: -22.724665
  },
  {
    lng: -43.6401843,
    lat: -22.725233
  },
  {
    lng: -43.6402689,
    lat: -22.7259454
  },
  {
    lng: -43.6402812,
    lat: -22.7260491
  },
  {
    lng: -43.6403402,
    lat: -22.7265448
  },
  {
    lng: -43.6405281,
    lat: -22.7274738
  },
  {
    lng: -43.6405634,
    lat: -22.7277086
  },
  {
    lng: -43.6406467,
    lat: -22.7282622
  },
  {
    lng: -43.6407575,
    lat: -22.7291376
  },
  {
    lng: -43.6409756,
    lat: -22.7298163
  },
  {
    lng: -43.6412072,
    lat: -22.7305769
  },
  {
    lng: -43.6413059,
    lat: -22.7312546
  },
  {
    lng: -43.6414795,
    lat: -22.7322013
  },
  {
    lng: -43.6416362,
    lat: -22.7326972
  },
  {
    lng: -43.6420725,
    lat: -22.7334128
  },
  {
    lng: -43.6426495,
    lat: -22.7340099
  },
  {
    lng: -43.6432553,
    lat: -22.7345487
  },
  {
    lng: -43.6434902,
    lat: -22.7349412
  },
  {
    lng: -43.6435727,
    lat: -22.735246
  },
  {
    lng: -43.6435752,
    lat: -22.7352921
  },
  {
    lng: -43.6437846,
    lat: -22.735582
  },
  {
    lng: -43.6438763,
    lat: -22.7356925
  },
  {
    lng: -43.6442726,
    lat: -22.7362087
  },
  {
    lng: -43.64455,
    lat: -22.736569
  },
  {
    lng: -43.6447499,
    lat: -22.7369547
  },
  {
    lng: -43.6447662,
    lat: -22.7374364
  },
  {
    lng: -43.6447269,
    lat: -22.737837
  },
  {
    lng: -43.6447122,
    lat: -22.7378885
  },
  {
    lng: -43.6445523,
    lat: -22.7384473
  },
  {
    lng: -43.6443783,
    lat: -22.738999
  },
  {
    lng: -43.6442173,
    lat: -22.7396575
  },
  {
    lng: -43.6442212,
    lat: -22.7402149
  },
  {
    lng: -43.6442231,
    lat: -22.7404906
  },
  {
    lng: -43.6442422,
    lat: -22.741425
  },
  {
    lng: -43.6442896,
    lat: -22.7419117
  },
  {
    lng: -43.6443506,
    lat: -22.7425389
  },
  {
    lng: -43.6444022,
    lat: -22.7430014
  },
  {
    lng: -43.6446582,
    lat: -22.7441405
  },
  {
    lng: -43.6448125,
    lat: -22.7448772
  },
  {
    lng: -43.6447927,
    lat: -22.7454563
  },
  {
    lng: -43.6447455,
    lat: -22.7456744
  },
  {
    lng: -43.6446892,
    lat: -22.7459343
  },
  {
    lng: -43.6446422,
    lat: -22.746422
  },

];

export default polygonCoords_Queimados;