let polygonCoords_Mendes = [
  {lat: -22.605661, lng: -43.772112},
  {lat: -22.596501, lng: -43.771244},
  {lat: -22.594987, lng: -43.767193},
  {lat: -22.563566, lng: -43.726784},
  {lat: -22.564688, lng: -43.723950},

  {lat: -22.561959, lng: -43.722747},
  {lat: -22.557226, lng: -43.724328},
  {lat: -22.546196, lng: -43.703972},
  {lat: -22.537081, lng: -43.700797},
  {lat: -22.463109, lng: -43.688585},
  {lat: -22.459561, lng: -43.695815},

  {lat: -22.459824, lng: -43.703851},
  {lat: -22.462922, lng: -43.707369},
  {lat: -22.457453, lng: -43.711308},
  {lat: -22.455096, lng: -43.715187},
  {lat: -22.483807, lng: -43.736826},
  {lat: -22.466975, lng: -43.751318},

  {lat: -22.462925, lng: -43.749400},
  {lat: -22.460236, lng: -43.754087},
  {lat: -22.458676, lng: -43.765924},
  {lat: -22.518090, lng: -43.770070},
  {lat: -22.533118, lng: -43.780738},
  {lat: -22.533309, lng: -43.789247},

  {lat: -22.551567, lng: -43.797612},
  {lat: -22.549881, lng: -43.791827},
  {lat: -22.550911, lng: -43.791920},
  {lat: -22.551978, lng: -43.789319},
  {lat: -22.567377, lng: -43.777793},
  {lat: -22.580903, lng: -43.778901}
];

export default polygonCoords_Mendes;