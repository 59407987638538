let polygonCoords_Buzios = [
  {
    lng: -41.97442,
    lat: -22.7298334
  },
  {
    lng: -41.9747909,
    lat: -22.73042
  },
  {
    lng: -41.9752619,
    lat: -22.7311649
  },
  {
    lng: -41.977681,
    lat: -22.7349908
  },
  {
    lng: -41.9786083,
    lat: -22.7364574
  },
  {
    lng: -41.9799955,
    lat: -22.7386511
  },
  {
    lng: -41.9803809,
    lat: -22.7392607
  },
  {
    lng: -41.9804964,
    lat: -22.7394433
  },
  {
    lng: -41.9814644,
    lat: -22.7410052
  },
  {
    lng: -41.9824725,
    lat: -22.7426038
  },
  {
    lng: -41.9826789,
    lat: -22.7429311
  },
  {
    lng: -41.9834917,
    lat: -22.74422
  },
  {
    lng: -41.9839945,
    lat: -22.7450174
  },
  {
    lng: -41.9847615,
    lat: -22.7462338
  },
  {
    lng: -41.9853235,
    lat: -22.747125
  },
  {
    lng: -41.9860318,
    lat: -22.7482481
  },
  {
    lng: -41.9865976,
    lat: -22.7491465
  },
  {
    lng: -41.987037,
    lat: -22.7498432
  },
  {
    lng: -41.9880674,
    lat: -22.7511966
  },
  {
    lng: -41.9994184,
    lat: -22.7661048
  },
  {
    lng: -41.9999201,
    lat: -22.7656254
  },
  {
    lng: -42.0007226,
    lat: -22.7662812
  },
  {
    lng: -42.008117,
    lat: -22.7762098
  },
  {
    lng: -42.0084193,
    lat: -22.7811768
  },
  {
    lng: -42.0018726,
    lat: -22.7952601
  },
  {
    lng: -41.9676011,
    lat: -22.8220675
  },
  {
    lng: -41.9674494,
    lat: -22.821948
  },
  {
    lng: -41.9673467,
    lat: -22.8218584
  },
  {
    lng: -41.9672169,
    lat: -22.8217178
  },
  {
    lng: -41.9671118,
    lat: -22.8215737
  },
  {
    lng: -41.9670287,
    lat: -22.8214722
  },
  {
    lng: -41.9668895,
    lat: -22.8213279
  },
  {
    lng: -41.9667339,
    lat: -22.8212139
  },
  {
    lng: -41.9665786,
    lat: -22.8210564
  },
  {
    lng: -41.9664034,
    lat: -22.8208695
  },
  {
    lng: -41.9662377,
    lat: -22.8206922
  },
  {
    lng: -41.9661242,
    lat: -22.8205708
  },
  {
    lng: -41.9660231,
    lat: -22.8204769
  },
  {
    lng: -41.9659247,
    lat: -22.820352
  },
  {
    lng: -41.9658088,
    lat: -22.8202135
  },
  {
    lng: -41.9657458,
    lat: -22.820139
  },
  {
    lng: -41.9656474,
    lat: -22.8200499
  },
  {
    lng: -41.965537,
    lat: -22.8199789
  },
  {
    lng: -41.9654564,
    lat: -22.8199607
  },
  {
    lng: -41.9653754,
    lat: -22.8199818
  },
  {
    lng: -41.9652851,
    lat: -22.8200042
  },
  {
    lng: -41.965174,
    lat: -22.8200199
  },
  {
    lng: -41.9650489,
    lat: -22.8200491
  },
  {
    lng: -41.9649512,
    lat: -22.8200643
  },
  {
    lng: -41.9648467,
    lat: -22.8200868
  },
  {
    lng: -41.9647744,
    lat: -22.8200468
  },
  {
    lng: -41.96474,
    lat: -22.8199376
  },
  {
    lng: -41.9647051,
    lat: -22.8198259
  },
  {
    lng: -41.9646728,
    lat: -22.8197171
  },
  {
    lng: -41.9646484,
    lat: -22.8196462
  },
  {
    lng: -41.9645972,
    lat: -22.8195459
  },
  {
    lng: -41.9645156,
    lat: -22.8194188
  },
  {
    lng: -41.9644189,
    lat: -22.8193787
  },
  {
    lng: -41.964319,
    lat: -22.819359
  },
  {
    lng: -41.9642201,
    lat: -22.8193792
  },
  {
    lng: -41.9641119,
    lat: -22.8193979
  },
  {
    lng: -41.9639591,
    lat: -22.8193964
  },
  {
    lng: -41.9638372,
    lat: -22.8192911
  },
  {
    lng: -41.9637963,
    lat: -22.819223
  },
  {
    lng: -41.9637127,
    lat: -22.8191069
  },
  {
    lng: -41.9636204,
    lat: -22.8190477
  },
  {
    lng: -41.963523,
    lat: -22.8190297
  },
  {
    lng: -41.9634107,
    lat: -22.8190312
  },
  {
    lng: -41.9632833,
    lat: -22.8190131
  },
  {
    lng: -41.9631837,
    lat: -22.8189731
  },
  {
    lng: -41.9630659,
    lat: -22.8189432
  },
  {
    lng: -41.9629132,
    lat: -22.8189494
  },
  {
    lng: -41.9627781,
    lat: -22.8189633
  },
  {
    lng: -41.9625953,
    lat: -22.818927
  },
  {
    lng: -41.962508,
    lat: -22.8189101
  },
  {
    lng: -41.9624327,
    lat: -22.8189258
  },
  {
    lng: -41.9623731,
    lat: -22.8189749
  },
  {
    lng: -41.9623301,
    lat: -22.8190278
  },
  {
    lng: -41.9622817,
    lat: -22.8190598
  },
  {
    lng: -41.9621725,
    lat: -22.8190696
  },
  {
    lng: -41.9620593,
    lat: -22.8190616
  },
  {
    lng: -41.9619176,
    lat: -22.8189838
  },
  {
    lng: -41.9617278,
    lat: -22.8188948
  },
  {
    lng: -41.9616269,
    lat: -22.8188902
  },
  {
    lng: -41.9614903,
    lat: -22.8189094
  },
  {
    lng: -41.9613427,
    lat: -22.8189089
  },
  {
    lng: -41.9611777,
    lat: -22.8188933
  },
  {
    lng: -41.9610414,
    lat: -22.8188829
  },
  {
    lng: -41.9608833,
    lat: -22.8188462
  },
  {
    lng: -41.960764,
    lat: -22.8187956
  },
  {
    lng: -41.960649,
    lat: -22.8186982
  },
  {
    lng: -41.9604952,
    lat: -22.8186705
  },
  {
    lng: -41.9603548,
    lat: -22.8186585
  },
  {
    lng: -41.9602208,
    lat: -22.8186415
  },
  {
    lng: -41.9600887,
    lat: -22.8186138
  },
  {
    lng: -41.9599436,
    lat: -22.8185649
  },
  {
    lng: -41.9598149,
    lat: -22.8185629
  },
  {
    lng: -41.9597085,
    lat: -22.8185707
  },
  {
    lng: -41.9596218,
    lat: -22.8186121
  },
  {
    lng: -41.9595827,
    lat: -22.8187144
  },
  {
    lng: -41.959515,
    lat: -22.8188163
  },
  {
    lng: -41.9594781,
    lat: -22.8188894
  },
  {
    lng: -41.9594152,
    lat: -22.8189895
  },
  {
    lng: -41.9593516,
    lat: -22.8190557
  },
  {
    lng: -41.9592257,
    lat: -22.8190749
  },
  {
    lng: -41.9591316,
    lat: -22.819104
  },
  {
    lng: -41.9591305,
    lat: -22.8191425
  },
  {
    lng: -41.9592132,
    lat: -22.8192013
  },
  {
    lng: -41.9592316,
    lat: -22.8192884
  },
  {
    lng: -41.9592048,
    lat: -22.8193562
  },
  {
    lng: -41.9591456,
    lat: -22.8194214
  },
  {
    lng: -41.9591217,
    lat: -22.819489
  },
  {
    lng: -41.959051,
    lat: -22.8195506
  },
  {
    lng: -41.9589594,
    lat: -22.8195819
  },
  {
    lng: -41.958858,
    lat: -22.8195782
  },
  {
    lng: -41.9587518,
    lat: -22.8195685
  },
  {
    lng: -41.9586723,
    lat: -22.8195158
  },
  {
    lng: -41.958611,
    lat: -22.8194177
  },
  {
    lng: -41.9585296,
    lat: -22.8193324
  },
  {
    lng: -41.9584445,
    lat: -22.8192892
  },
  {
    lng: -41.9583731,
    lat: -22.8192549
  },
  {
    lng: -41.9583049,
    lat: -22.8192385
  },
  {
    lng: -41.9582321,
    lat: -22.8191691
  },
  {
    lng: -41.9582213,
    lat: -22.8190576
  },
  {
    lng: -41.9582362,
    lat: -22.818976
  },
  {
    lng: -41.9582288,
    lat: -22.8188968
  },
  {
    lng: -41.9581872,
    lat: -22.818886
  },
  {
    lng: -41.9581147,
    lat: -22.8189403
  },
  {
    lng: -41.958054,
    lat: -22.8189398
  },
  {
    lng: -41.9580123,
    lat: -22.8188442
  },
  {
    lng: -41.9579712,
    lat: -22.8187722
  },
  {
    lng: -41.9579152,
    lat: -22.818693
  },
  {
    lng: -41.9579193,
    lat: -22.8185955
  },
  {
    lng: -41.9578747,
    lat: -22.8184528
  },
  {
    lng: -41.9578177,
    lat: -22.8183364
  },
  {
    lng: -41.9577793,
    lat: -22.818185
  },
  {
    lng: -41.9577052,
    lat: -22.8180617
  },
  {
    lng: -41.957592,
    lat: -22.8179294
  },
  {
    lng: -41.957459,
    lat: -22.8178667
  },
  {
    lng: -41.9573079,
    lat: -22.8177821
  },
  {
    lng: -41.9571276,
    lat: -22.8176672
  },
  {
    lng: -41.9570435,
    lat: -22.8175381
  },
  {
    lng: -41.9568986,
    lat: -22.8174205
  },
  {
    lng: -41.9568494,
    lat: -22.8173122
  },
  {
    lng: -41.9568127,
    lat: -22.8172228
  },
  {
    lng: -41.9567752,
    lat: -22.8171319
  },
  {
    lng: -41.956743,
    lat: -22.8170889
  },
  {
    lng: -41.9566851,
    lat: -22.8171108
  },
  {
    lng: -41.9566428,
    lat: -22.8171592
  },
  {
    lng: -41.9565488,
    lat: -22.8171789
  },
  {
    lng: -41.956373,
    lat: -22.817158
  },
  {
    lng: -41.9562343,
    lat: -22.8170757
  },
  {
    lng: -41.9561556,
    lat: -22.8169523
  },
  {
    lng: -41.9560688,
    lat: -22.8169072
  },
  {
    lng: -41.9559575,
    lat: -22.8168922
  },
  {
    lng: -41.955826,
    lat: -22.8168989
  },
  {
    lng: -41.9556717,
    lat: -22.8169129
  },
  {
    lng: -41.9555189,
    lat: -22.8168721
  },
  {
    lng: -41.9554338,
    lat: -22.8168019
  },
  {
    lng: -41.955395,
    lat: -22.8166934
  },
  {
    lng: -41.9554147,
    lat: -22.8165924
  },
  {
    lng: -41.9553736,
    lat: -22.8165928
  },
  {
    lng: -41.9553134,
    lat: -22.816697
  },
  {
    lng: -41.9552254,
    lat: -22.8168028
  },
  {
    lng: -41.9550918,
    lat: -22.8168674
  },
  {
    lng: -41.9549267,
    lat: -22.8168901
  },
  {
    lng: -41.9548191,
    lat: -22.81682
  },
  {
    lng: -41.954757,
    lat: -22.8166008
  },
  {
    lng: -41.9546888,
    lat: -22.8162753
  },
  {
    lng: -41.9546855,
    lat: -22.8159091
  },
  {
    lng: -41.9546054,
    lat: -22.8157111
  },
  {
    lng: -41.9544826,
    lat: -22.8152971
  },
  {
    lng: -41.9544603,
    lat: -22.8150581
  },
  {
    lng: -41.9544536,
    lat: -22.8149855
  },
  {
    lng: -41.9536808,
    lat: -22.8145224
  },
  {
    lng: -41.9534586,
    lat: -22.8145403
  },
  {
    lng: -41.9528887,
    lat: -22.8148163
  },
  {
    lng: -41.9523338,
    lat: -22.8148213
  },
  {
    lng: -41.9521958,
    lat: -22.8145297
  },
  {
    lng: -41.9508706,
    lat: -22.8137553
  },
  {
    lng: -41.9505703,
    lat: -22.8137567
  },
  {
    lng: -41.9505564,
    lat: -22.8135945
  },
  {
    lng: -41.9503288,
    lat: -22.8135074
  },
  {
    lng: -41.9500583,
    lat: -22.813543
  },
  {
    lng: -41.9490729,
    lat: -22.8132224
  },
  {
    lng: -41.9488025,
    lat: -22.8130532
  },
  {
    lng: -41.9483678,
    lat: -22.8129019
  },
  {
    lng: -41.9480973,
    lat: -22.8129998
  },
  {
    lng: -41.9472459,
    lat: -22.8128082
  },
  {
    lng: -41.9460744,
    lat: -22.8128334
  },
  {
    lng: -41.945891,
    lat: -22.8130879
  },
  {
    lng: -41.9452421,
    lat: -22.8133271
  },
  {
    lng: -41.9451352,
    lat: -22.8134095
  },
  {
    lng: -41.9448733,
    lat: -22.8133586
  },
  {
    lng: -41.945002,
    lat: -22.8135512
  },
  {
    lng: -41.9448801,
    lat: -22.8136545
  },
  {
    lng: -41.9443132,
    lat: -22.8139001
  },
  {
    lng: -41.944156,
    lat: -22.8142212
  },
  {
    lng: -41.943809,
    lat: -22.8142924
  },
  {
    lng: -41.9436613,
    lat: -22.8140082
  },
  {
    lng: -41.9433232,
    lat: -22.8138791
  },
  {
    lng: -41.9430672,
    lat: -22.8138746
  },
  {
    lng: -41.9427871,
    lat: -22.81379
  },
  {
    lng: -41.9427002,
    lat: -22.813603
  },
  {
    lng: -41.9424587,
    lat: -22.8135273
  },
  {
    lng: -41.942449,
    lat: -22.8131444
  },
  {
    lng: -41.9422848,
    lat: -22.8131533
  },
  {
    lng: -41.9421495,
    lat: -22.8128818
  },
  {
    lng: -41.9421737,
    lat: -22.8127616
  },
  {
    lng: -41.9420433,
    lat: -22.8125523
  },
  {
    lng: -41.9418066,
    lat: -22.8123475
  },
  {
    lng: -41.9418066,
    lat: -22.812116
  },
  {
    lng: -41.941966,
    lat: -22.81188
  },
  {
    lng: -41.941995,
    lat: -22.811604
  },
  {
    lng: -41.9418404,
    lat: -22.8115283
  },
  {
    lng: -41.9418694,
    lat: -22.8113146
  },
  {
    lng: -41.9419561,
    lat: -22.8111857
  },
  {
    lng: -41.9419853,
    lat: -22.8109318
  },
  {
    lng: -41.9421681,
    lat: -22.8105692
  },
  {
    lng: -41.9421346,
    lat: -22.810367
  },
  {
    lng: -41.9420404,
    lat: -22.8101755
  },
  {
    lng: -41.9417766,
    lat: -22.8100301
  },
  {
    lng: -41.9414883,
    lat: -22.8099447
  },
  {
    lng: -41.941189,
    lat: -22.8099098
  },
  {
    lng: -41.9408648,
    lat: -22.8098409
  },
  {
    lng: -41.9404483,
    lat: -22.8094819
  },
  {
    lng: -41.9402318,
    lat: -22.8094564
  },
  {
    lng: -41.9401451,
    lat: -22.8094936
  },
  {
    lng: -41.9396621,
    lat: -22.8097607
  },
  {
    lng: -41.9391356,
    lat: -22.8097785
  },
  {
    lng: -41.9390052,
    lat: -22.8100145
  },
  {
    lng: -41.9382227,
    lat: -22.8098364
  },
  {
    lng: -41.9377639,
    lat: -22.809645
  },
  {
    lng: -41.9375562,
    lat: -22.8097652
  },
  {
    lng: -41.9373968,
    lat: -22.8098809
  },
  {
    lng: -41.9369911,
    lat: -22.8097607
  },
  {
    lng: -41.9364211,
    lat: -22.8098008
  },
  {
    lng: -41.9362424,
    lat: -22.8096761
  },
  {
    lng: -41.9358898,
    lat: -22.8092443
  },
  {
    lng: -41.9356193,
    lat: -22.8098587
  },
  {
    lng: -41.935402,
    lat: -22.8099833
  },
  {
    lng: -41.9351363,
    lat: -22.8097874
  },
  {
    lng: -41.9347596,
    lat: -22.809734
  },
  {
    lng: -41.9346797,
    lat: -22.8095622
  },
  {
    lng: -41.934577,
    lat: -22.8095565
  },
  {
    lng: -41.9344521,
    lat: -22.8096863
  },
  {
    lng: -41.9343608,
    lat: -22.80979
  },
  {
    lng: -41.9344022,
    lat: -22.8099477
  },
  {
    lng: -41.9342428,
    lat: -22.8100991
  },
  {
    lng: -41.9341124,
    lat: -22.810055
  },
  {
    lng: -41.9340057,
    lat: -22.8100948
  },
  {
    lng: -41.934061,
    lat: -22.8102863
  },
  {
    lng: -41.933976,
    lat: -22.8103656
  },
  {
    lng: -41.9338312,
    lat: -22.8103128
  },
  {
    lng: -41.9335352,
    lat: -22.8100572
  },
  {
    lng: -41.9332517,
    lat: -22.809961
  },
  {
    lng: -41.9329922,
    lat: -22.8101302
  },
  {
    lng: -41.9329783,
    lat: -22.8102945
  },
  {
    lng: -41.9327962,
    lat: -22.8104349
  },
  {
    lng: -41.932634,
    lat: -22.810452
  },
  {
    lng: -41.9327234,
    lat: -22.8105464
  },
  {
    lng: -41.9327485,
    lat: -22.8107055
  },
  {
    lng: -41.9325999,
    lat: -22.8108779
  },
  {
    lng: -41.9323615,
    lat: -22.8110576
  },
  {
    lng: -41.9323042,
    lat: -22.8112133
  },
  {
    lng: -41.9325099,
    lat: -22.811472
  },
  {
    lng: -41.932626,
    lat: -22.811643
  },
  {
    lng: -41.9325574,
    lat: -22.8119035
  },
  {
    lng: -41.9323179,
    lat: -22.8119626
  },
  {
    lng: -41.9326936,
    lat: -22.812227
  },
  {
    lng: -41.9323124,
    lat: -22.8126883
  },
  {
    lng: -41.9318807,
    lat: -22.8125607
  },
  {
    lng: -41.9316093,
    lat: -22.8123797
  },
  {
    lng: -41.9313417,
    lat: -22.8127852
  },
  {
    lng: -41.9313317,
    lat: -22.8132024
  },
  {
    lng: -41.931102,
    lat: -22.8134107
  },
  {
    lng: -41.930856,
    lat: -22.8137004
  },
  {
    lng: -41.9304803,
    lat: -22.8136878
  },
  {
    lng: -41.9302754,
    lat: -22.8132659
  },
  {
    lng: -41.9301934,
    lat: -22.8129003
  },
  {
    lng: -41.929975,
    lat: -22.8126552
  },
  {
    lng: -41.9296454,
    lat: -22.8125265
  },
  {
    lng: -41.9293124,
    lat: -22.8125733
  },
  {
    lng: -41.9291893,
    lat: -22.8129763
  },
  {
    lng: -41.9290186,
    lat: -22.8131274
  },
  {
    lng: -41.9289281,
    lat: -22.8130919
  },
  {
    lng: -41.9288572,
    lat: -22.8133478
  },
  {
    lng: -41.9289912,
    lat: -22.8136059
  },
  {
    lng: -41.9288616,
    lat: -22.8139207
  },
  {
    lng: -41.9288342,
    lat: -22.8142041
  },
  {
    lng: -41.9286498,
    lat: -22.8143678
  },
  {
    lng: -41.9286986,
    lat: -22.8139877
  },
  {
    lng: -41.9284995,
    lat: -22.8139019
  },
  {
    lng: -41.9285883,
    lat: -22.814223
  },
  {
    lng: -41.9285004,
    lat: -22.8145404
  },
  {
    lng: -41.9283957,
    lat: -22.8145526
  },
  {
    lng: -41.9283304,
    lat: -22.8144448
  },
  {
    lng: -41.9282813,
    lat: -22.8145078
  },
  {
    lng: -41.9281375,
    lat: -22.8145441
  },
  {
    lng: -41.9280214,
    lat: -22.81416
  },
  {
    lng: -41.9279463,
    lat: -22.8142041
  },
  {
    lng: -41.9278301,
    lat: -22.8140341
  },
  {
    lng: -41.927837,
    lat: -22.8137696
  },
  {
    lng: -41.9277624,
    lat: -22.8138448
  },
  {
    lng: -41.9276727,
    lat: -22.8138448
  },
  {
    lng: -41.9275636,
    lat: -22.8137885
  },
  {
    lng: -41.927509,
    lat: -22.8135744
  },
  {
    lng: -41.9275367,
    lat: -22.8130357
  },
  {
    lng: -41.9275367,
    lat: -22.8128433
  },
  {
    lng: -41.9275535,
    lat: -22.812753
  },
  {
    lng: -41.9275001,
    lat: -22.8126603
  },
  {
    lng: -41.9275296,
    lat: -22.8122794
  },
  {
    lng: -41.9273767,
    lat: -22.8124445
  },
  {
    lng: -41.9273043,
    lat: -22.8124082
  },
  {
    lng: -41.9273184,
    lat: -22.812231
  },
  {
    lng: -41.9272867,
    lat: -22.8121153
  },
  {
    lng: -41.9273529,
    lat: -22.8116682
  },
  {
    lng: -41.9273541,
    lat: -22.81145
  },
  {
    lng: -41.9271704,
    lat: -22.8115316
  },
  {
    lng: -41.926993,
    lat: -22.8117879
  },
  {
    lng: -41.92684,
    lat: -22.8119332
  },
  {
    lng: -41.9267437,
    lat: -22.811855
  },
  {
    lng: -41.9266853,
    lat: -22.8118441
  },
  {
    lng: -41.9266511,
    lat: -22.8119787
  },
  {
    lng: -41.926549,
    lat: -22.8120461
  },
  {
    lng: -41.9262825,
    lat: -22.8116746
  },
  {
    lng: -41.926146,
    lat: -22.8113409
  },
  {
    lng: -41.9261324,
    lat: -22.8109001
  },
  {
    lng: -41.9258933,
    lat: -22.8105349
  },
  {
    lng: -41.9259291,
    lat: -22.8102512
  },
  {
    lng: -41.9260513,
    lat: -22.8101009
  },
  {
    lng: -41.9260249,
    lat: -22.809967
  },
  {
    lng: -41.9261819,
    lat: -22.8098552
  },
  {
    lng: -41.926064,
    lat: -22.8097983
  },
  {
    lng: -41.925982,
    lat: -22.8096723
  },
  {
    lng: -41.9257566,
    lat: -22.8097353
  },
  {
    lng: -41.9254902,
    lat: -22.8096723
  },
  {
    lng: -41.9254014,
    lat: -22.8094582
  },
  {
    lng: -41.925396,
    lat: -22.8093284
  },
  {
    lng: -41.9255363,
    lat: -22.8092232
  },
  {
    lng: -41.9255381,
    lat: -22.8091182
  },
  {
    lng: -41.9258911,
    lat: -22.8089586
  },
  {
    lng: -41.9258028,
    lat: -22.8087869
  },
  {
    lng: -41.9257245,
    lat: -22.8087601
  },
  {
    lng: -41.9255111,
    lat: -22.8087914
  },
  {
    lng: -41.9253058,
    lat: -22.8086334
  },
  {
    lng: -41.9252374,
    lat: -22.8082871
  },
  {
    lng: -41.925354,
    lat: -22.8079222
  },
  {
    lng: -41.925566,
    lat: -22.8079338
  },
  {
    lng: -41.925442,
    lat: -22.8078035
  },
  {
    lng: -41.9254689,
    lat: -22.8077554
  },
  {
    lng: -41.9256373,
    lat: -22.8077452
  },
  {
    lng: -41.9258795,
    lat: -22.8075946
  },
  {
    lng: -41.9259967,
    lat: -22.8077205
  },
  {
    lng: -41.9261446,
    lat: -22.8077839
  },
  {
    lng: -41.9262275,
    lat: -22.807751
  },
  {
    lng: -41.9262075,
    lat: -22.807576
  },
  {
    lng: -41.9262557,
    lat: -22.8075126
  },
  {
    lng: -41.9264145,
    lat: -22.8075621
  },
  {
    lng: -41.9262919,
    lat: -22.8074153
  },
  {
    lng: -41.9264174,
    lat: -22.8074001
  },
  {
    lng: -41.926541,
    lat: -22.8074667
  },
  {
    lng: -41.9266588,
    lat: -22.807525
  },
  {
    lng: -41.9268558,
    lat: -22.8076099
  },
  {
    lng: -41.9270121,
    lat: -22.8075739
  },
  {
    lng: -41.9271143,
    lat: -22.8076098
  },
  {
    lng: -41.9271657,
    lat: -22.8077593
  },
  {
    lng: -41.9272266,
    lat: -22.8079266
  },
  {
    lng: -41.9275646,
    lat: -22.8079469
  },
  {
    lng: -41.9275216,
    lat: -22.8080746
  },
  {
    lng: -41.9277197,
    lat: -22.8081508
  },
  {
    lng: -41.9277512,
    lat: -22.8082438
  },
  {
    lng: -41.927849,
    lat: -22.8083081
  },
  {
    lng: -41.927955,
    lat: -22.8081606
  },
  {
    lng: -41.9286799,
    lat: -22.8079882
  },
  {
    lng: -41.9288152,
    lat: -22.8075074
  },
  {
    lng: -41.9292789,
    lat: -22.8074361
  },
  {
    lng: -41.9291147,
    lat: -22.8068217
  },
  {
    lng: -41.9295494,
    lat: -22.8064566
  },
  {
    lng: -41.9305953,
    lat: -22.8062789
  },
  {
    lng: -41.9309523,
    lat: -22.8049413
  },
  {
    lng: -41.9305626,
    lat: -22.8023049
  },
  {
    lng: -41.9298765,
    lat: -22.7999007
  },
  {
    lng: -41.9287374,
    lat: -22.7970699
  },
  {
    lng: -41.9272115,
    lat: -22.794252
  },
  {
    lng: -41.9254683,
    lat: -22.7916022
  },
  {
    lng: -41.9243926,
    lat: -22.7902454
  },
  {
    lng: -41.9237032,
    lat: -22.7894028
  },
  {
    lng: -41.9231083,
    lat: -22.7886555
  },
  {
    lng: -41.9225317,
    lat: -22.78795
  },
  {
    lng: -41.9217192,
    lat: -22.7869505
  },
  {
    lng: -41.9212147,
    lat: -22.7864726
  },
  {
    lng: -41.9209196,
    lat: -22.7865541
  },
  {
    lng: -41.9208492,
    lat: -22.7864226
  },
  {
    lng: -41.9206165,
    lat: -22.7862375
  },
  {
    lng: -41.9206314,
    lat: -22.7861571
  },
  {
    lng: -41.9197696,
    lat: -22.7857038
  },
  {
    lng: -41.9194208,
    lat: -22.7857145
  },
  {
    lng: -41.9189002,
    lat: -22.785565
  },
  {
    lng: -41.9185383,
    lat: -22.7852314
  },
  {
    lng: -41.9184092,
    lat: -22.7851465
  },
  {
    lng: -41.9183265,
    lat: -22.785038
  },
  {
    lng: -41.9184494,
    lat: -22.7847806
  },
  {
    lng: -41.9183978,
    lat: -22.7847464
  },
  {
    lng: -41.918231,
    lat: -22.7847748
  },
  {
    lng: -41.9181583,
    lat: -22.7846671
  },
  {
    lng: -41.9178547,
    lat: -22.7846938
  },
  {
    lng: -41.917592,
    lat: -22.7845683
  },
  {
    lng: -41.9173739,
    lat: -22.7847344
  },
  {
    lng: -41.9172315,
    lat: -22.7847031
  },
  {
    lng: -41.917031,
    lat: -22.7845554
  },
  {
    lng: -41.9169093,
    lat: -22.7844683
  },
  {
    lng: -41.9169811,
    lat: -22.7842235
  },
  {
    lng: -41.9169329,
    lat: -22.7842159
  },
  {
    lng: -41.9168192,
    lat: -22.7843547
  },
  {
    lng: -41.9167615,
    lat: -22.784282
  },
  {
    lng: -41.9164733,
    lat: -22.7840064
  },
  {
    lng: -41.9162369,
    lat: -22.7837538
  },
  {
    lng: -41.9163113,
    lat: -22.7835323
  },
  {
    lng: -41.9165075,
    lat: -22.7825417
  },
  {
    lng: -41.916031,
    lat: -22.7820811
  },
  {
    lng: -41.9137567,
    lat: -22.7806789
  },
  {
    lng: -41.9126217,
    lat: -22.7798956
  },
  {
    lng: -41.9114947,
    lat: -22.7793827
  },
  {
    lng: -41.9093739,
    lat: -22.7785725
  },
  {
    lng: -41.9074365,
    lat: -22.7783316
  },
  {
    lng: -41.9055333,
    lat: -22.7785865
  },
  {
    lng: -41.9045803,
    lat: -22.7790667
  },
  {
    lng: -41.9041142,
    lat: -22.7795535
  },
  {
    lng: -41.9036712,
    lat: -22.7800679
  },
  {
    lng: -41.9035305,
    lat: -22.780313
  },
  {
    lng: -41.9034889,
    lat: -22.7806202
  },
  {
    lng: -41.9035225,
    lat: -22.7807784
  },
  {
    lng: -41.9036015,
    lat: -22.7808691
  },
  {
    lng: -41.9038861,
    lat: -22.7809436
  },
  {
    lng: -41.9040189,
    lat: -22.7809426
  },
  {
    lng: -41.9040862,
    lat: -22.7809612
  },
  {
    lng: -41.904033,
    lat: -22.7810633
  },
  {
    lng: -41.9040884,
    lat: -22.7811398
  },
  {
    lng: -41.9039542,
    lat: -22.7813361
  },
  {
    lng: -41.9039383,
    lat: -22.7814681
  },
  {
    lng: -41.9037727,
    lat: -22.7816174
  },
  {
    lng: -41.9039729,
    lat: -22.7818174
  },
  {
    lng: -41.9040006,
    lat: -22.7819393
  },
  {
    lng: -41.9039036,
    lat: -22.7820049
  },
  {
    lng: -41.9039228,
    lat: -22.7821297
  },
  {
    lng: -41.9040286,
    lat: -22.7822823
  },
  {
    lng: -41.9038196,
    lat: -22.7825005
  },
  {
    lng: -41.9040679,
    lat: -22.7826654
  },
  {
    lng: -41.9040184,
    lat: -22.7827594
  },
  {
    lng: -41.9040515,
    lat: -22.7828419
  },
  {
    lng: -41.9039837,
    lat: -22.78298
  },
  {
    lng: -41.9038298,
    lat: -22.7829485
  },
  {
    lng: -41.9034076,
    lat: -22.7829247
  },
  {
    lng: -41.9032335,
    lat: -22.7828246
  },
  {
    lng: -41.9033119,
    lat: -22.7829603
  },
  {
    lng: -41.9034456,
    lat: -22.7830639
  },
  {
    lng: -41.9035173,
    lat: -22.7832097
  },
  {
    lng: -41.9034615,
    lat: -22.7832623
  },
  {
    lng: -41.9036098,
    lat: -22.7833477
  },
  {
    lng: -41.9036289,
    lat: -22.7834636
  },
  {
    lng: -41.9035539,
    lat: -22.783491
  },
  {
    lng: -41.9035305,
    lat: -22.7835882
  },
  {
    lng: -41.9035349,
    lat: -22.7837035
  },
  {
    lng: -41.9034789,
    lat: -22.7837643
  },
  {
    lng: -41.903417,
    lat: -22.7837116
  },
  {
    lng: -41.9032451,
    lat: -22.7837443
  },
  {
    lng: -41.9032013,
    lat: -22.7837866
  },
  {
    lng: -41.9030913,
    lat: -22.7838162
  },
  {
    lng: -41.9028453,
    lat: -22.7835965
  },
  {
    lng: -41.9028823,
    lat: -22.7834756
  },
  {
    lng: -41.902851,
    lat: -22.7833877
  },
  {
    lng: -41.9028032,
    lat: -22.7832801
  },
  {
    lng: -41.9026783,
    lat: -22.7832115
  },
  {
    lng: -41.9026883,
    lat: -22.7831492
  },
  {
    lng: -41.9028264,
    lat: -22.783019
  },
  {
    lng: -41.9027641,
    lat: -22.7830101
  },
  {
    lng: -41.9027536,
    lat: -22.782906
  },
  {
    lng: -41.90291,
    lat: -22.7828494
  },
  {
    lng: -41.9030122,
    lat: -22.7828545
  },
  {
    lng: -41.9031809,
    lat: -22.7828174
  },
  {
    lng: -41.9030593,
    lat: -22.7827816
  },
  {
    lng: -41.90303,
    lat: -22.7827243
  },
  {
    lng: -41.9031866,
    lat: -22.7826829
  },
  {
    lng: -41.9031509,
    lat: -22.7826246
  },
  {
    lng: -41.9029623,
    lat: -22.7826547
  },
  {
    lng: -41.9029763,
    lat: -22.7825854
  },
  {
    lng: -41.9031691,
    lat: -22.782424
  },
  {
    lng: -41.9030127,
    lat: -22.7823583
  },
  {
    lng: -41.902931,
    lat: -22.7824302
  },
  {
    lng: -41.9024794,
    lat: -22.7825435
  },
  {
    lng: -41.9023009,
    lat: -22.7826776
  },
  {
    lng: -41.9021733,
    lat: -22.7826251
  },
  {
    lng: -41.9021471,
    lat: -22.7825227
  },
  {
    lng: -41.9021623,
    lat: -22.782451
  },
  {
    lng: -41.9021208,
    lat: -22.7824264
  },
  {
    lng: -41.9020293,
    lat: -22.782685
  },
  {
    lng: -41.9019159,
    lat: -22.7827996
  },
  {
    lng: -41.9017673,
    lat: -22.7829516
  },
  {
    lng: -41.9016942,
    lat: -22.7829854
  },
  {
    lng: -41.9015189,
    lat: -22.7831443
  },
  {
    lng: -41.901415,
    lat: -22.7832933
  },
  {
    lng: -41.9012719,
    lat: -22.7832507
  },
  {
    lng: -41.9012127,
    lat: -22.7830882
  },
  {
    lng: -41.9012279,
    lat: -22.7830524
  },
  {
    lng: -41.9011755,
    lat: -22.7829963
  },
  {
    lng: -41.9012363,
    lat: -22.7829309
  },
  {
    lng: -41.9011654,
    lat: -22.7829215
  },
  {
    lng: -41.901124,
    lat: -22.7828937
  },
  {
    lng: -41.9010867,
    lat: -22.7826919
  },
  {
    lng: -41.9010589,
    lat: -22.782853
  },
  {
    lng: -41.900918,
    lat: -22.7830014
  },
  {
    lng: -41.9009795,
    lat: -22.7826754
  },
  {
    lng: -41.9010234,
    lat: -22.7825912
  },
  {
    lng: -41.9010203,
    lat: -22.7824062
  },
  {
    lng: -41.9009667,
    lat: -22.7826476
  },
  {
    lng: -41.9009119,
    lat: -22.782624
  },
  {
    lng: -41.9008477,
    lat: -22.7828935
  },
  {
    lng: -41.9006924,
    lat: -22.7831391
  },
  {
    lng: -41.9006106,
    lat: -22.7831458
  },
  {
    lng: -41.9005723,
    lat: -22.783001
  },
  {
    lng: -41.9005317,
    lat: -22.7830415
  },
  {
    lng: -41.9005047,
    lat: -22.7829698
  },
  {
    lng: -41.9004354,
    lat: -22.7830228
  },
  {
    lng: -41.9004134,
    lat: -22.783096
  },
  {
    lng: -41.9003843,
    lat: -22.7831121
  },
  {
    lng: -41.9003594,
    lat: -22.7830508
  },
  {
    lng: -41.9003154,
    lat: -22.7830555
  },
  {
    lng: -41.9002613,
    lat: -22.7829122
  },
  {
    lng: -41.900209,
    lat: -22.7827065
  },
  {
    lng: -41.9002099,
    lat: -22.7825137
  },
  {
    lng: -41.9003323,
    lat: -22.7824012
  },
  {
    lng: -41.9003723,
    lat: -22.7823597
  },
  {
    lng: -41.900334,
    lat: -22.7822999
  },
  {
    lng: -41.9002326,
    lat: -22.7823108
  },
  {
    lng: -41.90016,
    lat: -22.7822407
  },
  {
    lng: -41.9000868,
    lat: -22.78229
  },
  {
    lng: -41.9000502,
    lat: -22.7822142
  },
  {
    lng: -41.8999944,
    lat: -22.7822127
  },
  {
    lng: -41.8999623,
    lat: -22.7823264
  },
  {
    lng: -41.8998525,
    lat: -22.78249
  },
  {
    lng: -41.8997714,
    lat: -22.7825087
  },
  {
    lng: -41.8997595,
    lat: -22.7823591
  },
  {
    lng: -41.8997254,
    lat: -22.782523
  },
  {
    lng: -41.899642,
    lat: -22.7825398
  },
  {
    lng: -41.8996092,
    lat: -22.7824651
  },
  {
    lng: -41.8995433,
    lat: -22.7824604
  },
  {
    lng: -41.8994909,
    lat: -22.782356
  },
  {
    lng: -41.8994774,
    lat: -22.7822501
  },
  {
    lng: -41.8995223,
    lat: -22.782172
  },
  {
    lng: -41.8996729,
    lat: -22.7821583
  },
  {
    lng: -41.8997646,
    lat: -22.7820818
  },
  {
    lng: -41.8998727,
    lat: -22.7820631
  },
  {
    lng: -41.8999308,
    lat: -22.7821444
  },
  {
    lng: -41.9000516,
    lat: -22.7820639
  },
  {
    lng: -41.9001107,
    lat: -22.7819905
  },
  {
    lng: -41.9001644,
    lat: -22.7819932
  },
  {
    lng: -41.9001831,
    lat: -22.7819689
  },
  {
    lng: -41.9002294,
    lat: -22.7818762
  },
  {
    lng: -41.9001798,
    lat: -22.7816929
  },
  {
    lng: -41.9000333,
    lat: -22.7815132
  },
  {
    lng: -41.8999285,
    lat: -22.7814431
  },
  {
    lng: -41.8998152,
    lat: -22.7814194
  },
  {
    lng: -41.8997494,
    lat: -22.781454
  },
  {
    lng: -41.8996417,
    lat: -22.7815868
  },
  {
    lng: -41.899447,
    lat: -22.7816733
  },
  {
    lng: -41.8992228,
    lat: -22.7816934
  },
  {
    lng: -41.8991641,
    lat: -22.7815931
  },
  {
    lng: -41.899174,
    lat: -22.7815202
  },
  {
    lng: -41.8990287,
    lat: -22.7813926
  },
  {
    lng: -41.8989753,
    lat: -22.7814091
  },
  {
    lng: -41.8988616,
    lat: -22.7814446
  },
  {
    lng: -41.898813,
    lat: -22.7814595
  },
  {
    lng: -41.8986426,
    lat: -22.7815124
  },
  {
    lng: -41.8986178,
    lat: -22.7815202
  },
  {
    lng: -41.8985667,
    lat: -22.7816124
  },
  {
    lng: -41.8987168,
    lat: -22.7817537
  },
  {
    lng: -41.8985274,
    lat: -22.7820795
  },
  {
    lng: -41.8985167,
    lat: -22.7819176
  },
  {
    lng: -41.8982777,
    lat: -22.7820694
  },
  {
    lng: -41.8982264,
    lat: -22.7821019
  },
  {
    lng: -41.8980419,
    lat: -22.7822394
  },
  {
    lng: -41.898011,
    lat: -22.7822626
  },
  {
    lng: -41.8980143,
    lat: -22.7823112
  },
  {
    lng: -41.8980328,
    lat: -22.7823765
  },
  {
    lng: -41.8980818,
    lat: -22.7824241
  },
  {
    lng: -41.8984781,
    lat: -22.7825194
  },
  {
    lng: -41.8985392,
    lat: -22.7825791
  },
  {
    lng: -41.8985162,
    lat: -22.7828005
  },
  {
    lng: -41.8985982,
    lat: -22.7827867
  },
  {
    lng: -41.8986561,
    lat: -22.7828333
  },
  {
    lng: -41.8986619,
    lat: -22.7829505
  },
  {
    lng: -41.8986501,
    lat: -22.7831023
  },
  {
    lng: -41.8986518,
    lat: -22.7832431
  },
  {
    lng: -41.898538,
    lat: -22.7833053
  },
  {
    lng: -41.8985229,
    lat: -22.7834406
  },
  {
    lng: -41.898451,
    lat: -22.7835452
  },
  {
    lng: -41.8983303,
    lat: -22.7835947
  },
  {
    lng: -41.8984001,
    lat: -22.7836713
  },
  {
    lng: -41.898387,
    lat: -22.7837726
  },
  {
    lng: -41.8982248,
    lat: -22.7837976
  },
  {
    lng: -41.8981137,
    lat: -22.7839527
  },
  {
    lng: -41.8978767,
    lat: -22.783754
  },
  {
    lng: -41.8976756,
    lat: -22.783782
  },
  {
    lng: -41.897652,
    lat: -22.7839331
  },
  {
    lng: -41.8975412,
    lat: -22.7839866
  },
  {
    lng: -41.8973123,
    lat: -22.7838895
  },
  {
    lng: -41.8969845,
    lat: -22.7841123
  },
  {
    lng: -41.8964692,
    lat: -22.7842841
  },
  {
    lng: -41.8966202,
    lat: -22.7846095
  },
  {
    lng: -41.8966207,
    lat: -22.7854019
  },
  {
    lng: -41.8964084,
    lat: -22.785447
  },
  {
    lng: -41.8963868,
    lat: -22.7857567
  },
  {
    lng: -41.8962575,
    lat: -22.7858248
  },
  {
    lng: -41.8962324,
    lat: -22.7857427
  },
  {
    lng: -41.8959777,
    lat: -22.7859742
  },
  {
    lng: -41.895708,
    lat: -22.7859904
  },
  {
    lng: -41.8955248,
    lat: -22.7859101
  },
  {
    lng: -41.8950941,
    lat: -22.7857414
  },
  {
    lng: -41.8949785,
    lat: -22.7858951
  },
  {
    lng: -41.8950023,
    lat: -22.7860588
  },
  {
    lng: -41.8946351,
    lat: -22.7861328
  },
  {
    lng: -41.8946482,
    lat: -22.7859675
  },
  {
    lng: -41.8942927,
    lat: -22.7860037
  },
  {
    lng: -41.8942909,
    lat: -22.7857732
  },
  {
    lng: -41.8942289,
    lat: -22.7855206
  },
  {
    lng: -41.8943974,
    lat: -22.7853119
  },
  {
    lng: -41.8942308,
    lat: -22.7851283
  },
  {
    lng: -41.8940084,
    lat: -22.7852015
  },
  {
    lng: -41.8939642,
    lat: -22.7849514
  },
  {
    lng: -41.8938188,
    lat: -22.784945
  },
  {
    lng: -41.8937485,
    lat: -22.7850869
  },
  {
    lng: -41.8936713,
    lat: -22.7853817
  },
  {
    lng: -41.893174,
    lat: -22.7854653
  },
  {
    lng: -41.8929713,
    lat: -22.7848528
  },
  {
    lng: -41.8930869,
    lat: -22.7844317
  },
  {
    lng: -41.8927533,
    lat: -22.7843873
  },
  {
    lng: -41.8925989,
    lat: -22.7840051
  },
  {
    lng: -41.8920563,
    lat: -22.783617
  },
  {
    lng: -41.8920414,
    lat: -22.7833934
  },
  {
    lng: -41.8919296,
    lat: -22.7833172
  },
  {
    lng: -41.8918416,
    lat: -22.7834966
  },
  {
    lng: -41.8917582,
    lat: -22.7833694
  },
  {
    lng: -41.8915486,
    lat: -22.7827475
  },
  {
    lng: -41.8912616,
    lat: -22.78188
  },
  {
    lng: -41.8909048,
    lat: -22.7819258
  },
  {
    lng: -41.890883,
    lat: -22.7812664
  },
  {
    lng: -41.8906076,
    lat: -22.7812664
  },
  {
    lng: -41.8902978,
    lat: -22.7809914
  },
  {
    lng: -41.8902999,
    lat: -22.7806837
  },
  {
    lng: -41.8902623,
    lat: -22.7805536
  },
  {
    lng: -41.8901142,
    lat: -22.780547
  },
  {
    lng: -41.8898388,
    lat: -22.7809702
  },
  {
    lng: -41.889506,
    lat: -22.7812029
  },
  {
    lng: -41.8888979,
    lat: -22.7809914
  },
  {
    lng: -41.8886396,
    lat: -22.7810742
  },
  {
    lng: -41.8881898,
    lat: -22.7808309
  },
  {
    lng: -41.8881276,
    lat: -22.7807315
  },
  {
    lng: -41.8881696,
    lat: -22.7804776
  },
  {
    lng: -41.8878978,
    lat: -22.7801787
  },
  {
    lng: -41.8877161,
    lat: -22.7799175
  },
  {
    lng: -41.8875806,
    lat: -22.7795638
  },
  {
    lng: -41.8875396,
    lat: -22.7793239
  },
  {
    lng: -41.8875696,
    lat: -22.7791058
  },
  {
    lng: -41.8874084,
    lat: -22.7787159
  },
  {
    lng: -41.887444,
    lat: -22.7785739
  },
  {
    lng: -41.8876606,
    lat: -22.7785955
  },
  {
    lng: -41.8875312,
    lat: -22.7784643
  },
  {
    lng: -41.8872484,
    lat: -22.7780461
  },
  {
    lng: -41.8872159,
    lat: -22.777665
  },
  {
    lng: -41.8872525,
    lat: -22.7775378
  },
  {
    lng: -41.8870678,
    lat: -22.7774913
  },
  {
    lng: -41.8870521,
    lat: -22.7773644
  },
  {
    lng: -41.8869771,
    lat: -22.777433
  },
  {
    lng: -41.886898,
    lat: -22.7774659
  },
  {
    lng: -41.8868506,
    lat: -22.7774044
  },
  {
    lng: -41.8867351,
    lat: -22.7773795
  },
  {
    lng: -41.8867203,
    lat: -22.7774878
  },
  {
    lng: -41.8866136,
    lat: -22.7774838
  },
  {
    lng: -41.886588,
    lat: -22.7773868
  },
  {
    lng: -41.8866109,
    lat: -22.7773225
  },
  {
    lng: -41.8865875,
    lat: -22.7772328
  },
  {
    lng: -41.8864932,
    lat: -22.7772321
  },
  {
    lng: -41.8865131,
    lat: -22.7772856
  },
  {
    lng: -41.8864931,
    lat: -22.7774571
  },
  {
    lng: -41.8863365,
    lat: -22.7775374
  },
  {
    lng: -41.8862698,
    lat: -22.7775704
  },
  {
    lng: -41.8862918,
    lat: -22.7776307
  },
  {
    lng: -41.8862578,
    lat: -22.7778777
  },
  {
    lng: -41.8862454,
    lat: -22.7781449
  },
  {
    lng: -41.8861557,
    lat: -22.7780726
  },
  {
    lng: -41.886056,
    lat: -22.7780442
  },
  {
    lng: -41.8859887,
    lat: -22.7778945
  },
  {
    lng: -41.8859191,
    lat: -22.7780349
  },
  {
    lng: -41.885872,
    lat: -22.7782117
  },
  {
    lng: -41.8858491,
    lat: -22.7783891
  },
  {
    lng: -41.8857776,
    lat: -22.778304
  },
  {
    lng: -41.885716,
    lat: -22.7781659
  },
  {
    lng: -41.8856011,
    lat: -22.778329
  },
  {
    lng: -41.8854401,
    lat: -22.7784172
  },
  {
    lng: -41.8853166,
    lat: -22.7784669
  },
  {
    lng: -41.8853611,
    lat: -22.7786348
  },
  {
    lng: -41.8851905,
    lat: -22.7788734
  },
  {
    lng: -41.8850885,
    lat: -22.7789298
  },
  {
    lng: -41.8850343,
    lat: -22.7791944
  },
  {
    lng: -41.8848668,
    lat: -22.7792896
  },
  {
    lng: -41.8847642,
    lat: -22.7795645
  },
  {
    lng: -41.8847355,
    lat: -22.7798394
  },
  {
    lng: -41.8846272,
    lat: -22.7802305
  },
  {
    lng: -41.8845048,
    lat: -22.7804526
  },
  {
    lng: -41.884396,
    lat: -22.7803921
  },
  {
    lng: -41.8843134,
    lat: -22.7805624
  },
  {
    lng: -41.8842093,
    lat: -22.780675
  },
  {
    lng: -41.8839787,
    lat: -22.781015
  },
  {
    lng: -41.8839863,
    lat: -22.7811828
  },
  {
    lng: -41.8838832,
    lat: -22.7813167
  },
  {
    lng: -41.8836715,
    lat: -22.7814675
  },
  {
    lng: -41.883597,
    lat: -22.7816461
  },
  {
    lng: -41.8836431,
    lat: -22.7818018
  },
  {
    lng: -41.8837522,
    lat: -22.7818781
  },
  {
    lng: -41.8840054,
    lat: -22.7818943
  },
  {
    lng: -41.8840052,
    lat: -22.7820885
  },
  {
    lng: -41.8840487,
    lat: -22.7823533
  },
  {
    lng: -41.8839931,
    lat: -22.7825522
  },
  {
    lng: -41.8838703,
    lat: -22.7827747
  },
  {
    lng: -41.8837002,
    lat: -22.7829431
  },
  {
    lng: -41.8836692,
    lat: -22.7829594
  },
  {
    lng: -41.883621,
    lat: -22.7829627
  },
  {
    lng: -41.8835858,
    lat: -22.7829975
  },
  {
    lng: -41.8835956,
    lat: -22.7830715
  },
  {
    lng: -41.883747,
    lat: -22.7831877
  },
  {
    lng: -41.8837835,
    lat: -22.7833335
  },
  {
    lng: -41.8837258,
    lat: -22.7834238
  },
  {
    lng: -41.8836437,
    lat: -22.7835056
  },
  {
    lng: -41.8836757,
    lat: -22.7835584
  },
  {
    lng: -41.8838018,
    lat: -22.783663
  },
  {
    lng: -41.8838684,
    lat: -22.7836982
  },
  {
    lng: -41.8839218,
    lat: -22.7838149
  },
  {
    lng: -41.8838104,
    lat: -22.7843856
  },
  {
    lng: -41.8837849,
    lat: -22.7844802
  },
  {
    lng: -41.8835278,
    lat: -22.7848739
  },
  {
    lng: -41.883458,
    lat: -22.7849427
  },
  {
    lng: -41.8832992,
    lat: -22.784947
  },
  {
    lng: -41.8832239,
    lat: -22.7848865
  },
  {
    lng: -41.8830852,
    lat: -22.7847383
  },
  {
    lng: -41.8830386,
    lat: -22.7845944
  },
  {
    lng: -41.8829516,
    lat: -22.7845264
  },
  {
    lng: -41.8829848,
    lat: -22.7846287
  },
  {
    lng: -41.882763,
    lat: -22.7845033
  },
  {
    lng: -41.882607,
    lat: -22.7843995
  },
  {
    lng: -41.8825701,
    lat: -22.7842963
  },
  {
    lng: -41.8824164,
    lat: -22.7842374
  },
  {
    lng: -41.8823017,
    lat: -22.7843283
  },
  {
    lng: -41.8820085,
    lat: -22.7840921
  },
  {
    lng: -41.8818603,
    lat: -22.7843807
  },
  {
    lng: -41.8817931,
    lat: -22.7843664
  },
  {
    lng: -41.8818042,
    lat: -22.7842236
  },
  {
    lng: -41.8817058,
    lat: -22.7841911
  },
  {
    lng: -41.8817249,
    lat: -22.7838747
  },
  {
    lng: -41.8816971,
    lat: -22.7838726
  },
  {
    lng: -41.8816066,
    lat: -22.7840312
  },
  {
    lng: -41.8815952,
    lat: -22.784167
  },
  {
    lng: -41.8814358,
    lat: -22.7842118
  },
  {
    lng: -41.8813662,
    lat: -22.7841424
  },
  {
    lng: -41.8813426,
    lat: -22.7838674
  },
  {
    lng: -41.8813185,
    lat: -22.7838692
  },
  {
    lng: -41.8813051,
    lat: -22.7840666
  },
  {
    lng: -41.8812417,
    lat: -22.7841324
  },
  {
    lng: -41.8812217,
    lat: -22.7842948
  },
  {
    lng: -41.8811378,
    lat: -22.7843415
  },
  {
    lng: -41.881109,
    lat: -22.7843254
  },
  {
    lng: -41.8811458,
    lat: -22.7840733
  },
  {
    lng: -41.8810746,
    lat: -22.7840091
  },
  {
    lng: -41.8810665,
    lat: -22.7839399
  },
  {
    lng: -41.8811425,
    lat: -22.7836149
  },
  {
    lng: -41.8812291,
    lat: -22.7834262
  },
  {
    lng: -41.8812283,
    lat: -22.7834023
  },
  {
    lng: -41.8811717,
    lat: -22.7833043
  },
  {
    lng: -41.8811753,
    lat: -22.7832006
  },
  {
    lng: -41.8812087,
    lat: -22.7831379
  },
  {
    lng: -41.8811525,
    lat: -22.7830145
  },
  {
    lng: -41.8811693,
    lat: -22.7829465
  },
  {
    lng: -41.8812452,
    lat: -22.7828601
  },
  {
    lng: -41.8812602,
    lat: -22.7826019
  },
  {
    lng: -41.8812302,
    lat: -22.7825015
  },
  {
    lng: -41.8812551,
    lat: -22.7821749
  },
  {
    lng: -41.88137,
    lat: -22.7821417
  },
  {
    lng: -41.8812595,
    lat: -22.7820581
  },
  {
    lng: -41.8812354,
    lat: -22.781991
  },
  {
    lng: -41.8812186,
    lat: -22.7817706
  },
  {
    lng: -41.8812273,
    lat: -22.7815344
  },
  {
    lng: -41.8811955,
    lat: -22.7813337
  },
  {
    lng: -41.8812212,
    lat: -22.7812801
  },
  {
    lng: -41.8811738,
    lat: -22.7811344
  },
  {
    lng: -41.8813092,
    lat: -22.780926
  },
  {
    lng: -41.8813027,
    lat: -22.7808593
  },
  {
    lng: -41.8813065,
    lat: -22.780717
  },
  {
    lng: -41.8813212,
    lat: -22.7806735
  },
  {
    lng: -41.8813324,
    lat: -22.7805738
  },
  {
    lng: -41.881452,
    lat: -22.7803194
  },
  {
    lng: -41.8815301,
    lat: -22.7802398
  },
  {
    lng: -41.881554,
    lat: -22.7802152
  },
  {
    lng: -41.881581,
    lat: -22.7802348
  },
  {
    lng: -41.8815557,
    lat: -22.780425
  },
  {
    lng: -41.8815998,
    lat: -22.780308
  },
  {
    lng: -41.881618,
    lat: -22.7802867
  },
  {
    lng: -41.8816114,
    lat: -22.7802089
  },
  {
    lng: -41.8816455,
    lat: -22.7801235
  },
  {
    lng: -41.8817269,
    lat: -22.7800412
  },
  {
    lng: -41.8818122,
    lat: -22.7800715
  },
  {
    lng: -41.8818758,
    lat: -22.780127
  },
  {
    lng: -41.8819174,
    lat: -22.7800901
  },
  {
    lng: -41.8819396,
    lat: -22.7798793
  },
  {
    lng: -41.8819815,
    lat: -22.7797526
  },
  {
    lng: -41.8819789,
    lat: -22.779542
  },
  {
    lng: -41.8820829,
    lat: -22.7791768
  },
  {
    lng: -41.8820122,
    lat: -22.7791442
  },
  {
    lng: -41.881881,
    lat: -22.779297
  },
  {
    lng: -41.8817986,
    lat: -22.7792547
  },
  {
    lng: -41.8817016,
    lat: -22.7792907
  },
  {
    lng: -41.8816701,
    lat: -22.7791113
  },
  {
    lng: -41.8815858,
    lat: -22.7790567
  },
  {
    lng: -41.881669,
    lat: -22.7788437
  },
  {
    lng: -41.8817593,
    lat: -22.7785479
  },
  {
    lng: -41.8818574,
    lat: -22.7784132
  },
  {
    lng: -41.8819856,
    lat: -22.7782589
  },
  {
    lng: -41.8821217,
    lat: -22.7781759
  },
  {
    lng: -41.8821395,
    lat: -22.7779233
  },
  {
    lng: -41.882128,
    lat: -22.7774366
  },
  {
    lng: -41.8827591,
    lat: -22.7769288
  },
  {
    lng: -41.8830602,
    lat: -22.7770158
  },
  {
    lng: -41.8833178,
    lat: -22.7771739
  },
  {
    lng: -41.8834681,
    lat: -22.7773192
  },
  {
    lng: -41.8837071,
    lat: -22.7772767
  },
  {
    lng: -41.8838799,
    lat: -22.7772096
  },
  {
    lng: -41.8839066,
    lat: -22.7770346
  },
  {
    lng: -41.8835279,
    lat: -22.7766431
  },
  {
    lng: -41.8834132,
    lat: -22.7763363
  },
  {
    lng: -41.88334,
    lat: -22.7759097
  },
  {
    lng: -41.8833902,
    lat: -22.7757544
  },
  {
    lng: -41.8838315,
    lat: -22.7756375
  },
  {
    lng: -41.8840691,
    lat: -22.7757535
  },
  {
    lng: -41.8842319,
    lat: -22.7757757
  },
  {
    lng: -41.8842412,
    lat: -22.7756198
  },
  {
    lng: -41.8842967,
    lat: -22.775236
  },
  {
    lng: -41.8843197,
    lat: -22.7749398
  },
  {
    lng: -41.8845292,
    lat: -22.7749234
  },
  {
    lng: -41.8846918,
    lat: -22.7747284
  },
  {
    lng: -41.8850056,
    lat: -22.7746717
  },
  {
    lng: -41.8850599,
    lat: -22.7745725
  },
  {
    lng: -41.8851114,
    lat: -22.7743156
  },
  {
    lng: -41.8853409,
    lat: -22.7740828
  },
  {
    lng: -41.8851343,
    lat: -22.7739453
  },
  {
    lng: -41.8853081,
    lat: -22.7737452
  },
  {
    lng: -41.8855704,
    lat: -22.7738712
  },
  {
    lng: -41.885698,
    lat: -22.7742516
  },
  {
    lng: -41.8856999,
    lat: -22.7744433
  },
  {
    lng: -41.8857499,
    lat: -22.7745991
  },
  {
    lng: -41.8856797,
    lat: -22.7749017
  },
  {
    lng: -41.88603,
    lat: -22.7749536
  },
  {
    lng: -41.8864462,
    lat: -22.7748829
  },
  {
    lng: -41.8870128,
    lat: -22.7746319
  },
  {
    lng: -41.8875621,
    lat: -22.7742182
  },
  {
    lng: -41.8877764,
    lat: -22.7742799
  },
  {
    lng: -41.8879717,
    lat: -22.7742546
  },
  {
    lng: -41.8881918,
    lat: -22.7741193
  },
  {
    lng: -41.8883703,
    lat: -22.7738428
  },
  {
    lng: -41.8884968,
    lat: -22.7735621
  },
  {
    lng: -41.8888752,
    lat: -22.7728038
  },
  {
    lng: -41.8889476,
    lat: -22.7723662
  },
  {
    lng: -41.8889991,
    lat: -22.7718375
  },
  {
    lng: -41.8889291,
    lat: -22.7713939
  },
  {
    lng: -41.8886164,
    lat: -22.7706455
  },
  {
    lng: -41.8883073,
    lat: -22.7701854
  },
  {
    lng: -41.8879768,
    lat: -22.7697385
  },
  {
    lng: -41.8874986,
    lat: -22.7693483
  },
  {
    lng: -41.8872022,
    lat: -22.769134
  },
  {
    lng: -41.886612,
    lat: -22.7687722
  },
  {
    lng: -41.8861663,
    lat: -22.7685724
  },
  {
    lng: -41.885773,
    lat: -22.7684654
  },
  {
    lng: -41.8852699,
    lat: -22.7684036
  },
  {
    lng: -41.8846114,
    lat: -22.7684375
  },
  {
    lng: -41.8841809,
    lat: -22.7685202
  },
  {
    lng: -41.8837415,
    lat: -22.7686731
  },
  {
    lng: -41.8832461,
    lat: -22.7689908
  },
  {
    lng: -41.8831079,
    lat: -22.769251
  },
  {
    lng: -41.8834357,
    lat: -22.7694902
  },
  {
    lng: -41.8835571,
    lat: -22.7698217
  },
  {
    lng: -41.8836546,
    lat: -22.7702078
  },
  {
    lng: -41.8835394,
    lat: -22.7703952
  },
  {
    lng: -41.8836128,
    lat: -22.7706571
  },
  {
    lng: -41.8835675,
    lat: -22.7709106
  },
  {
    lng: -41.8836718,
    lat: -22.7711084
  },
  {
    lng: -41.8837292,
    lat: -22.771318
  },
  {
    lng: -41.8836702,
    lat: -22.771398
  },
  {
    lng: -41.8834843,
    lat: -22.7713924
  },
  {
    lng: -41.8833799,
    lat: -22.7713315
  },
  {
    lng: -41.8833211,
    lat: -22.7715025
  },
  {
    lng: -41.8832142,
    lat: -22.7716587
  },
  {
    lng: -41.8833009,
    lat: -22.7718186
  },
  {
    lng: -41.8832047,
    lat: -22.7720499
  },
  {
    lng: -41.8830665,
    lat: -22.7721147
  },
  {
    lng: -41.8829329,
    lat: -22.7720736
  },
  {
    lng: -41.8826,
    lat: -22.772137
  },
  {
    lng: -41.8825379,
    lat: -22.7722311
  },
  {
    lng: -41.8823885,
    lat: -22.7724683
  },
  {
    lng: -41.8822415,
    lat: -22.7726151
  },
  {
    lng: -41.8820521,
    lat: -22.7725563
  },
  {
    lng: -41.8819448,
    lat: -22.7725994
  },
  {
    lng: -41.8819281,
    lat: -22.7732102
  },
  {
    lng: -41.8817341,
    lat: -22.7736457
  },
  {
    lng: -41.8817365,
    lat: -22.7739263
  },
  {
    lng: -41.8815812,
    lat: -22.7741042
  },
  {
    lng: -41.8815335,
    lat: -22.7743091
  },
  {
    lng: -41.8814536,
    lat: -22.7745242
  },
  {
    lng: -41.8814767,
    lat: -22.7746929
  },
  {
    lng: -41.8814035,
    lat: -22.774961
  },
  {
    lng: -41.881266,
    lat: -22.775002
  },
  {
    lng: -41.88114,
    lat: -22.7748925
  },
  {
    lng: -41.8809202,
    lat: -22.774712
  },
  {
    lng: -41.8808085,
    lat: -22.7745378
  },
  {
    lng: -41.8807114,
    lat: -22.7744131
  },
  {
    lng: -41.8805412,
    lat: -22.7744935
  },
  {
    lng: -41.8806539,
    lat: -22.7745836
  },
  {
    lng: -41.880662,
    lat: -22.7747252
  },
  {
    lng: -41.8805898,
    lat: -22.7748302
  },
  {
    lng: -41.8803059,
    lat: -22.7748841
  },
  {
    lng: -41.8797891,
    lat: -22.7746594
  },
  {
    lng: -41.8796823,
    lat: -22.7749709
  },
  {
    lng: -41.8795387,
    lat: -22.7751066
  },
  {
    lng: -41.8789744,
    lat: -22.7752729
  },
  {
    lng: -41.8788127,
    lat: -22.7751181
  },
  {
    lng: -41.8787006,
    lat: -22.7753533
  },
  {
    lng: -41.8785709,
    lat: -22.7758602
  },
  {
    lng: -41.8782781,
    lat: -22.7758701
  },
  {
    lng: -41.8780239,
    lat: -22.7758717
  },
  {
    lng: -41.8779247,
    lat: -22.7758148
  },
  {
    lng: -41.8779386,
    lat: -22.7757184
  },
  {
    lng: -41.878087,
    lat: -22.7756104
  },
  {
    lng: -41.8779883,
    lat: -22.7755473
  },
  {
    lng: -41.877895,
    lat: -22.7756227
  },
  {
    lng: -41.8778682,
    lat: -22.7755759
  },
  {
    lng: -41.8778825,
    lat: -22.7754265
  },
  {
    lng: -41.8777926,
    lat: -22.7750533
  },
  {
    lng: -41.8776983,
    lat: -22.7749208
  },
  {
    lng: -41.8776913,
    lat: -22.7746732
  },
  {
    lng: -41.8774724,
    lat: -22.7744445
  },
  {
    lng: -41.8774395,
    lat: -22.7743056
  },
  {
    lng: -41.877513,
    lat: -22.7742177
  },
  {
    lng: -41.8777851,
    lat: -22.7741598
  },
  {
    lng: -41.8778086,
    lat: -22.7739813
  },
  {
    lng: -41.87791,
    lat: -22.7739305
  },
  {
    lng: -41.8781622,
    lat: -22.7738664
  },
  {
    lng: -41.8780115,
    lat: -22.7737895
  },
  {
    lng: -41.8780268,
    lat: -22.7735599
  },
  {
    lng: -41.878082,
    lat: -22.7734838
  },
  {
    lng: -41.8781003,
    lat: -22.7732611
  },
  {
    lng: -41.8781421,
    lat: -22.7732259
  },
  {
    lng: -41.8782682,
    lat: -22.773289
  },
  {
    lng: -41.8783014,
    lat: -22.7732687
  },
  {
    lng: -41.8783273,
    lat: -22.7731493
  },
  {
    lng: -41.8784333,
    lat: -22.7731086
  },
  {
    lng: -41.8784408,
    lat: -22.7730276
  },
  {
    lng: -41.8783752,
    lat: -22.7728367
  },
  {
    lng: -41.8783485,
    lat: -22.7727251
  },
  {
    lng: -41.8782582,
    lat: -22.7727956
  },
  {
    lng: -41.8781466,
    lat: -22.7727097
  },
  {
    lng: -41.8779816,
    lat: -22.7725259
  },
  {
    lng: -41.8776415,
    lat: -22.7720832
  },
  {
    lng: -41.8776989,
    lat: -22.7715965
  },
  {
    lng: -41.8778345,
    lat: -22.7714366
  },
  {
    lng: -41.877969,
    lat: -22.7713979
  },
  {
    lng: -41.8780746,
    lat: -22.7714124
  },
  {
    lng: -41.8782008,
    lat: -22.7714676
  },
  {
    lng: -41.8782313,
    lat: -22.7713142
  },
  {
    lng: -41.8783248,
    lat: -22.7712829
  },
  {
    lng: -41.8781338,
    lat: -22.7710596
  },
  {
    lng: -41.8779413,
    lat: -22.7709965
  },
  {
    lng: -41.877752,
    lat: -22.7707362
  },
  {
    lng: -41.8776452,
    lat: -22.7703137
  },
  {
    lng: -41.8776811,
    lat: -22.7701939
  },
  {
    lng: -41.8776057,
    lat: -22.7701422
  },
  {
    lng: -41.8775379,
    lat: -22.7701673
  },
  {
    lng: -41.8774603,
    lat: -22.7701328
  },
  {
    lng: -41.877332,
    lat: -22.7698975
  },
  {
    lng: -41.8773076,
    lat: -22.7698011
  },
  {
    lng: -41.8774818,
    lat: -22.7695272
  },
  {
    lng: -41.8774446,
    lat: -22.769508
  },
  {
    lng: -41.877269,
    lat: -22.7696781
  },
  {
    lng: -41.8769856,
    lat: -22.7696401
  },
  {
    lng: -41.8768765,
    lat: -22.7696202
  },
  {
    lng: -41.8768412,
    lat: -22.7694522
  },
  {
    lng: -41.8768556,
    lat: -22.7692567
  },
  {
    lng: -41.8769611,
    lat: -22.7691282
  },
  {
    lng: -41.8771768,
    lat: -22.7689698
  },
  {
    lng: -41.8769729,
    lat: -22.7683849
  },
  {
    lng: -41.8771031,
    lat: -22.7683075
  },
  {
    lng: -41.8773631,
    lat: -22.7680769
  },
  {
    lng: -41.8774485,
    lat: -22.7679551
  },
  {
    lng: -41.8775955,
    lat: -22.767592
  },
  {
    lng: -41.877903,
    lat: -22.7675088
  },
  {
    lng: -41.8779378,
    lat: -22.7672664
  },
  {
    lng: -41.8781663,
    lat: -22.7671145
  },
  {
    lng: -41.8785486,
    lat: -22.7669499
  },
  {
    lng: -41.8787085,
    lat: -22.7668846
  },
  {
    lng: -41.8786262,
    lat: -22.7667712
  },
  {
    lng: -41.8784197,
    lat: -22.766807
  },
  {
    lng: -41.8783293,
    lat: -22.7667259
  },
  {
    lng: -41.8783223,
    lat: -22.7666217
  },
  {
    lng: -41.87846,
    lat: -22.7665148
  },
  {
    lng: -41.8785733,
    lat: -22.7662805
  },
  {
    lng: -41.8786286,
    lat: -22.7661567
  },
  {
    lng: -41.8784842,
    lat: -22.7661236
  },
  {
    lng: -41.8784027,
    lat: -22.7659541
  },
  {
    lng: -41.8784883,
    lat: -22.7658179
  },
  {
    lng: -41.8785122,
    lat: -22.7657327
  },
  {
    lng: -41.8785363,
    lat: -22.7656516
  },
  {
    lng: -41.8787847,
    lat: -22.7654126
  },
  {
    lng: -41.8787316,
    lat: -22.7653334
  },
  {
    lng: -41.8786149,
    lat: -22.7654078
  },
  {
    lng: -41.878247,
    lat: -22.7656456
  },
  {
    lng: -41.8780501,
    lat: -22.7657049
  },
  {
    lng: -41.8779411,
    lat: -22.765734
  },
  {
    lng: -41.8778205,
    lat: -22.7657231
  },
  {
    lng: -41.8777824,
    lat: -22.76556
  },
  {
    lng: -41.8779038,
    lat: -22.765335
  },
  {
    lng: -41.8780994,
    lat: -22.765218
  },
  {
    lng: -41.8782128,
    lat: -22.7649837
  },
  {
    lng: -41.8782219,
    lat: -22.7648729
  },
  {
    lng: -41.8781029,
    lat: -22.764831
  },
  {
    lng: -41.8778226,
    lat: -22.7647719
  },
  {
    lng: -41.8775275,
    lat: -22.7647455
  },
  {
    lng: -41.8773432,
    lat: -22.7646452
  },
  {
    lng: -41.877423,
    lat: -22.7644413
  },
  {
    lng: -41.8773462,
    lat: -22.7642951
  },
  {
    lng: -41.8771083,
    lat: -22.7641924
  },
  {
    lng: -41.8769598,
    lat: -22.76418
  },
  {
    lng: -41.8768842,
    lat: -22.7640738
  },
  {
    lng: -41.8769507,
    lat: -22.7638683
  },
  {
    lng: -41.8769408,
    lat: -22.7636625
  },
  {
    lng: -41.8770175,
    lat: -22.7634725
  },
  {
    lng: -41.8769096,
    lat: -22.7633286
  },
  {
    lng: -41.8766936,
    lat: -22.7630591
  },
  {
    lng: -41.8765881,
    lat: -22.7627897
  },
  {
    lng: -41.8765753,
    lat: -22.7624314
  },
  {
    lng: -41.8764596,
    lat: -22.7621799
  },
  {
    lng: -41.8763877,
    lat: -22.7617694
  },
  {
    lng: -41.8763127,
    lat: -22.761609
  },
  {
    lng: -41.8761735,
    lat: -22.76156
  },
  {
    lng: -41.8760379,
    lat: -22.7612978
  },
  {
    lng: -41.8759079,
    lat: -22.7612051
  },
  {
    lng: -41.8757455,
    lat: -22.7611791
  },
  {
    lng: -41.8756979,
    lat: -22.7611767
  },
  {
    lng: -41.8756484,
    lat: -22.761197
  },
  {
    lng: -41.8756335,
    lat: -22.7612188
  },
  {
    lng: -41.8756194,
    lat: -22.7612446
  },
  {
    lng: -41.875608,
    lat: -22.7613157
  },
  {
    lng: -41.8755926,
    lat: -22.7613609
  },
  {
    lng: -41.8755578,
    lat: -22.7614048
  },
  {
    lng: -41.8755282,
    lat: -22.7614117
  },
  {
    lng: -41.8755054,
    lat: -22.7614067
  },
  {
    lng: -41.875478,
    lat: -22.7613919
  },
  {
    lng: -41.8754552,
    lat: -22.7613455
  },
  {
    lng: -41.8754391,
    lat: -22.7613115
  },
  {
    lng: -41.8754224,
    lat: -22.7612718
  },
  {
    lng: -41.8753205,
    lat: -22.7612975
  },
  {
    lng: -41.8751502,
    lat: -22.7613547
  },
  {
    lng: -41.8749556,
    lat: -22.7614587
  },
  {
    lng: -41.8748255,
    lat: -22.7615564
  },
  {
    lng: -41.8747719,
    lat: -22.7616268
  },
  {
    lng: -41.874721,
    lat: -22.7617195
  },
  {
    lng: -41.8749794,
    lat: -22.7621481
  },
  {
    lng: -41.8750952,
    lat: -22.7625185
  },
  {
    lng: -41.8750178,
    lat: -22.7626081
  },
  {
    lng: -41.8750942,
    lat: -22.7628041
  },
  {
    lng: -41.8749893,
    lat: -22.7628066
  },
  {
    lng: -41.874872,
    lat: -22.7627354
  },
  {
    lng: -41.8747684,
    lat: -22.7628035
  },
  {
    lng: -41.8746335,
    lat: -22.7631224
  },
  {
    lng: -41.8745672,
    lat: -22.7632032
  },
  {
    lng: -41.8744555,
    lat: -22.7632481
  },
  {
    lng: -41.8744537,
    lat: -22.7634121
  },
  {
    lng: -41.8747804,
    lat: -22.7637374
  },
  {
    lng: -41.8748864,
    lat: -22.7639784
  },
  {
    lng: -41.8750368,
    lat: -22.7643913
  },
  {
    lng: -41.8752049,
    lat: -22.7647576
  },
  {
    lng: -41.8753573,
    lat: -22.7649814
  },
  {
    lng: -41.8754729,
    lat: -22.7652897
  },
  {
    lng: -41.8754715,
    lat: -22.765561
  },
  {
    lng: -41.8755776,
    lat: -22.7657378
  },
  {
    lng: -41.8755302,
    lat: -22.7657879
  },
  {
    lng: -41.8752823,
    lat: -22.7657688
  },
  {
    lng: -41.8751859,
    lat: -22.7660418
  },
  {
    lng: -41.8751191,
    lat: -22.7661714
  },
  {
    lng: -41.874727,
    lat: -22.7662958
  },
  {
    lng: -41.8742535,
    lat: -22.7663914
  },
  {
    lng: -41.874027,
    lat: -22.7663064
  },
  {
    lng: -41.8737746,
    lat: -22.7660207
  },
  {
    lng: -41.873568,
    lat: -22.7660418
  },
  {
    lng: -41.8735225,
    lat: -22.7661841
  },
  {
    lng: -41.8732468,
    lat: -22.7663381
  },
  {
    lng: -41.872867,
    lat: -22.7660156
  },
  {
    lng: -41.8728256,
    lat: -22.7658496
  },
  {
    lng: -41.8727855,
    lat: -22.7657772
  },
  {
    lng: -41.872648,
    lat: -22.7654247
  },
  {
    lng: -41.8725458,
    lat: -22.7652348
  },
  {
    lng: -41.8725445,
    lat: -22.7648677
  },
  {
    lng: -41.8725875,
    lat: -22.7646195
  },
  {
    lng: -41.8725583,
    lat: -22.7644971
  },
  {
    lng: -41.8728879,
    lat: -22.7644366
  },
  {
    lng: -41.8731748,
    lat: -22.764292
  },
  {
    lng: -41.8730766,
    lat: -22.7641505
  },
  {
    lng: -41.8729636,
    lat: -22.763751
  },
  {
    lng: -41.8727217,
    lat: -22.763367
  },
  {
    lng: -41.8720051,
    lat: -22.762106
  },
  {
    lng: -41.8717436,
    lat: -22.7620423
  },
  {
    lng: -41.8711699,
    lat: -22.7615344
  },
  {
    lng: -41.8710733,
    lat: -22.7613283
  },
  {
    lng: -41.8709483,
    lat: -22.7613295
  },
  {
    lng: -41.8707748,
    lat: -22.7612795
  },
  {
    lng: -41.8708601,
    lat: -22.7614286
  },
  {
    lng: -41.8705078,
    lat: -22.7617924
  },
  {
    lng: -41.8704265,
    lat: -22.7619371
  },
  {
    lng: -41.8704,
    lat: -22.7623092
  },
  {
    lng: -41.8703912,
    lat: -22.7624676
  },
  {
    lng: -41.8701811,
    lat: -22.7624576
  },
  {
    lng: -41.8702015,
    lat: -22.7627184
  },
  {
    lng: -41.8701885,
    lat: -22.7629003
  },
  {
    lng: -41.8701019,
    lat: -22.7631452
  },
  {
    lng: -41.8699743,
    lat: -22.7631792
  },
  {
    lng: -41.8696806,
    lat: -22.7631289
  },
  {
    lng: -41.8696053,
    lat: -22.7631301
  },
  {
    lng: -41.869613,
    lat: -22.7632317
  },
  {
    lng: -41.8695591,
    lat: -22.7632862
  },
  {
    lng: -41.8691914,
    lat: -22.7633
  },
  {
    lng: -41.8690785,
    lat: -22.7631814
  },
  {
    lng: -41.8690053,
    lat: -22.763294
  },
  {
    lng: -41.8687821,
    lat: -22.7632558
  },
  {
    lng: -41.8686973,
    lat: -22.7631239
  },
  {
    lng: -41.8683863,
    lat: -22.762939
  },
  {
    lng: -41.8679779,
    lat: -22.7626903
  },
  {
    lng: -41.8676603,
    lat: -22.762509
  },
  {
    lng: -41.8673489,
    lat: -22.762529
  },
  {
    lng: -41.8671797,
    lat: -22.7620802
  },
  {
    lng: -41.8670836,
    lat: -22.7619772
  },
  {
    lng: -41.8669534,
    lat: -22.762079
  },
  {
    lng: -41.8668123,
    lat: -22.7620865
  },
  {
    lng: -41.8662999,
    lat: -22.7619635
  },
  {
    lng: -41.8661899,
    lat: -22.761873
  },
  {
    lng: -41.8660932,
    lat: -22.7616305
  },
  {
    lng: -41.8659872,
    lat: -22.7616157
  },
  {
    lng: -41.8659555,
    lat: -22.7615717
  },
  {
    lng: -41.8658811,
    lat: -22.7613586
  },
  {
    lng: -41.8657045,
    lat: -22.7610346
  },
  {
    lng: -41.8655933,
    lat: -22.7607251
  },
  {
    lng: -41.8655572,
    lat: -22.7606356
  },
  {
    lng: -41.8655517,
    lat: -22.7604044
  },
  {
    lng: -41.8654097,
    lat: -22.7602228
  },
  {
    lng: -41.8655577,
    lat: -22.7599989
  },
  {
    lng: -41.8656739,
    lat: -22.7599786
  },
  {
    lng: -41.8659984,
    lat: -22.7595667
  },
  {
    lng: -41.8659607,
    lat: -22.7594901
  },
  {
    lng: -41.865834,
    lat: -22.759336
  },
  {
    lng: -41.8655244,
    lat: -22.7591855
  },
  {
    lng: -41.8652949,
    lat: -22.7591749
  },
  {
    lng: -41.8651996,
    lat: -22.7588582
  },
  {
    lng: -41.8651141,
    lat: -22.7589645
  },
  {
    lng: -41.8650635,
    lat: -22.7589517
  },
  {
    lng: -41.8649006,
    lat: -22.7585126
  },
  {
    lng: -41.864759,
    lat: -22.7585358
  },
  {
    lng: -41.8646643,
    lat: -22.7583136
  },
  {
    lng: -41.8647531,
    lat: -22.7581157
  },
  {
    lng: -41.8647513,
    lat: -22.7576037
  },
  {
    lng: -41.8646502,
    lat: -22.7574233
  },
  {
    lng: -41.8644998,
    lat: -22.7573339
  },
  {
    lng: -41.8642841,
    lat: -22.7570699
  },
  {
    lng: -41.8640557,
    lat: -22.7562968
  },
  {
    lng: -41.8637,
    lat: -22.7549001
  },
  {
    lng: -41.863895,
    lat: -22.7543498
  },
  {
    lng: -41.864354,
    lat: -22.7545085
  },
  {
    lng: -41.8650205,
    lat: -22.7551291
  },
  {
    lng: -41.8652465,
    lat: -22.7549936
  },
  {
    lng: -41.8650698,
    lat: -22.7547157
  },
  {
    lng: -41.8649334,
    lat: -22.7545018
  },
  {
    lng: -41.864905,
    lat: -22.754346
  },
  {
    lng: -41.8649473,
    lat: -22.7542984
  },
  {
    lng: -41.865076,
    lat: -22.7542821
  },
  {
    lng: -41.8652339,
    lat: -22.754442
  },
  {
    lng: -41.8653487,
    lat: -22.7545039
  },
  {
    lng: -41.8654377,
    lat: -22.7545028
  },
  {
    lng: -41.8655201,
    lat: -22.7544485
  },
  {
    lng: -41.8654931,
    lat: -22.7543412
  },
  {
    lng: -41.8655505,
    lat: -22.7542651
  },
  {
    lng: -41.8656406,
    lat: -22.7542175
  },
  {
    lng: -41.8657649,
    lat: -22.754248
  },
  {
    lng: -41.8658265,
    lat: -22.7542875
  },
  {
    lng: -41.865863,
    lat: -22.7542427
  },
  {
    lng: -41.8658466,
    lat: -22.7541241
  },
  {
    lng: -41.8657654,
    lat: -22.7539371
  },
  {
    lng: -41.8658094,
    lat: -22.7539319
  },
  {
    lng: -41.865877,
    lat: -22.7539684
  },
  {
    lng: -41.8658969,
    lat: -22.7538534
  },
  {
    lng: -41.8660789,
    lat: -22.7537757
  },
  {
    lng: -41.8661446,
    lat: -22.7535233
  },
  {
    lng: -41.8663008,
    lat: -22.7535102
  },
  {
    lng: -41.8663547,
    lat: -22.7534502
  },
  {
    lng: -41.8664907,
    lat: -22.7535953
  },
  {
    lng: -41.8665582,
    lat: -22.753589
  },
  {
    lng: -41.8665994,
    lat: -22.7533748
  },
  {
    lng: -41.8666763,
    lat: -22.7533801
  },
  {
    lng: -41.8667724,
    lat: -22.7534908
  },
  {
    lng: -41.8668546,
    lat: -22.7534593
  },
  {
    lng: -41.8668916,
    lat: -22.7533449
  },
  {
    lng: -41.8668229,
    lat: -22.753097
  },
  {
    lng: -41.866801,
    lat: -22.7529303
  },
  {
    lng: -41.8668669,
    lat: -22.7528917
  },
  {
    lng: -41.8669888,
    lat: -22.7529509
  },
  {
    lng: -41.8671455,
    lat: -22.7530218
  },
  {
    lng: -41.8672027,
    lat: -22.7530059
  },
  {
    lng: -41.8672026,
    lat: -22.7528848
  },
  {
    lng: -41.8674406,
    lat: -22.75288
  },
  {
    lng: -41.8676329,
    lat: -22.7530516
  },
  {
    lng: -41.8677315,
    lat: -22.753108
  },
  {
    lng: -41.8678117,
    lat: -22.7531169
  },
  {
    lng: -41.8678627,
    lat: -22.7532179
  },
  {
    lng: -41.8679278,
    lat: -22.7532174
  },
  {
    lng: -41.8681406,
    lat: -22.7536514
  },
  {
    lng: -41.8682351,
    lat: -22.7540867
  },
  {
    lng: -41.8682464,
    lat: -22.7543047
  },
  {
    lng: -41.8683429,
    lat: -22.7544553
  },
  {
    lng: -41.8684944,
    lat: -22.7545916
  },
  {
    lng: -41.8687596,
    lat: -22.7549946
  },
  {
    lng: -41.8689241,
    lat: -22.755246
  },
  {
    lng: -41.8690555,
    lat: -22.7553947
  },
  {
    lng: -41.8693051,
    lat: -22.7555053
  },
  {
    lng: -41.8693995,
    lat: -22.7556548
  },
  {
    lng: -41.8696059,
    lat: -22.7556796
  },
  {
    lng: -41.8698446,
    lat: -22.7556784
  },
  {
    lng: -41.8701088,
    lat: -22.7556399
  },
  {
    lng: -41.8703462,
    lat: -22.7555931
  },
  {
    lng: -41.8706332,
    lat: -22.7555052
  },
  {
    lng: -41.8709899,
    lat: -22.7553618
  },
  {
    lng: -41.8712596,
    lat: -22.7552579
  },
  {
    lng: -41.8714727,
    lat: -22.7551379
  },
  {
    lng: -41.8716846,
    lat: -22.7550204
  },
  {
    lng: -41.8719529,
    lat: -22.7548509
  },
  {
    lng: -41.8721299,
    lat: -22.7547471
  },
  {
    lng: -41.8724559,
    lat: -22.754491
  },
  {
    lng: -41.8728501,
    lat: -22.7541795
  },
  {
    lng: -41.873109,
    lat: -22.7539518
  },
  {
    lng: -41.8732605,
    lat: -22.7538405
  },
  {
    lng: -41.8733571,
    lat: -22.7537429
  },
  {
    lng: -41.8733766,
    lat: -22.7534847
  },
  {
    lng: -41.873586,
    lat: -22.7531367
  },
  {
    lng: -41.8736198,
    lat: -22.7529871
  },
  {
    lng: -41.8738564,
    lat: -22.7531803
  },
  {
    lng: -41.8739983,
    lat: -22.7529996
  },
  {
    lng: -41.8739645,
    lat: -22.7527627
  },
  {
    lng: -41.8738293,
    lat: -22.7524386
  },
  {
    lng: -41.8739239,
    lat: -22.7522641
  },
  {
    lng: -41.8738158,
    lat: -22.7519026
  },
  {
    lng: -41.8739037,
    lat: -22.7515972
  },
  {
    lng: -41.8737527,
    lat: -22.7510005
  },
  {
    lng: -41.8733521,
    lat: -22.7509831
  },
  {
    lng: -41.8732415,
    lat: -22.7508074
  },
  {
    lng: -41.8734883,
    lat: -22.7504947
  },
  {
    lng: -41.8733402,
    lat: -22.7503148
  },
  {
    lng: -41.8732678,
    lat: -22.7497289
  },
  {
    lng: -41.8730336,
    lat: -22.7491722
  },
  {
    lng: -41.8727552,
    lat: -22.748168
  },
  {
    lng: -41.8725073,
    lat: -22.7477287
  },
  {
    lng: -41.8720679,
    lat: -22.7474092
  },
  {
    lng: -41.8716329,
    lat: -22.7473975
  },
  {
    lng: -41.87159,
    lat: -22.7472639
  },
  {
    lng: -41.8713921,
    lat: -22.7471811
  },
  {
    lng: -41.8712521,
    lat: -22.7466505
  },
  {
    lng: -41.8712091,
    lat: -22.7460865
  },
  {
    lng: -41.8710751,
    lat: -22.7457905
  },
  {
    lng: -41.8712521,
    lat: -22.7457946
  },
  {
    lng: -41.8710697,
    lat: -22.7449783
  },
  {
    lng: -41.8710283,
    lat: -22.7447196
  },
  {
    lng: -41.8709789,
    lat: -22.7445678
  },
  {
    lng: -41.8709346,
    lat: -22.7445491
  },
  {
    lng: -41.8709097,
    lat: -22.744582
  },
  {
    lng: -41.8709284,
    lat: -22.7447567
  },
  {
    lng: -41.8708987,
    lat: -22.7448509
  },
  {
    lng: -41.8708568,
    lat: -22.7448925
  },
  {
    lng: -41.8708044,
    lat: -22.7448868
  },
  {
    lng: -41.8707879,
    lat: -22.7447876
  },
  {
    lng: -41.8707403,
    lat: -22.7447392
  },
  {
    lng: -41.8706703,
    lat: -22.7447301
  },
  {
    lng: -41.870592,
    lat: -22.7447903
  },
  {
    lng: -41.8705848,
    lat: -22.7449273
  },
  {
    lng: -41.8705043,
    lat: -22.7449566
  },
  {
    lng: -41.8704482,
    lat: -22.7449959
  },
  {
    lng: -41.870346,
    lat: -22.7449063
  },
  {
    lng: -41.870269,
    lat: -22.7447257
  },
  {
    lng: -41.8701142,
    lat: -22.7445616
  },
  {
    lng: -41.8700062,
    lat: -22.7442173
  },
  {
    lng: -41.8698503,
    lat: -22.7441066
  },
  {
    lng: -41.8696438,
    lat: -22.7441383
  },
  {
    lng: -41.8694831,
    lat: -22.7438738
  },
  {
    lng: -41.8696087,
    lat: -22.7436777
  },
  {
    lng: -41.8695766,
    lat: -22.7435853
  },
  {
    lng: -41.8696861,
    lat: -22.7432177
  },
  {
    lng: -41.8694602,
    lat: -22.7432494
  },
  {
    lng: -41.8692993,
    lat: -22.7430265
  },
  {
    lng: -41.8690158,
    lat: -22.7427673
  },
  {
    lng: -41.8687692,
    lat: -22.7427058
  },
  {
    lng: -41.8685652,
    lat: -22.7427944
  },
  {
    lng: -41.8683346,
    lat: -22.7427308
  },
  {
    lng: -41.8681419,
    lat: -22.7426524
  },
  {
    lng: -41.8680373,
    lat: -22.7425192
  },
  {
    lng: -41.8682553,
    lat: -22.7421171
  },
  {
    lng: -41.8681291,
    lat: -22.7417679
  },
  {
    lng: -41.868175,
    lat: -22.7413657
  },
  {
    lng: -41.8684734,
    lat: -22.7412176
  },
  {
    lng: -41.8687917,
    lat: -22.7410181
  },
  {
    lng: -41.8691618,
    lat: -22.7412917
  },
  {
    lng: -41.8694412,
    lat: -22.7414627
  },
  {
    lng: -41.8697213,
    lat: -22.7416301
  },
  {
    lng: -41.8699432,
    lat: -22.7418258
  },
  {
    lng: -41.8700014,
    lat: -22.7417643
  },
  {
    lng: -41.8700454,
    lat: -22.741646
  },
  {
    lng: -41.8699958,
    lat: -22.7415023
  },
  {
    lng: -41.869747,
    lat: -22.7412388
  },
  {
    lng: -41.8698103,
    lat: -22.7410146
  },
  {
    lng: -41.8698128,
    lat: -22.7408184
  },
  {
    lng: -41.8698994,
    lat: -22.7407317
  },
  {
    lng: -41.8700109,
    lat: -22.7406882
  },
  {
    lng: -41.8700874,
    lat: -22.7407443
  },
  {
    lng: -41.8701376,
    lat: -22.7409157
  },
  {
    lng: -41.8702455,
    lat: -22.7409648
  },
  {
    lng: -41.8703857,
    lat: -22.7408901
  },
  {
    lng: -41.8705508,
    lat: -22.7406983
  },
  {
    lng: -41.8706207,
    lat: -22.7404552
  },
  {
    lng: -41.8705001,
    lat: -22.7401306
  },
  {
    lng: -41.8704345,
    lat: -22.7400333
  },
  {
    lng: -41.8703793,
    lat: -22.739908
  },
  {
    lng: -41.8703363,
    lat: -22.7400397
  },
  {
    lng: -41.8702215,
    lat: -22.7400637
  },
  {
    lng: -41.8701199,
    lat: -22.7399511
  },
  {
    lng: -41.8700309,
    lat: -22.7398965
  },
  {
    lng: -41.8700168,
    lat: -22.7397515
  },
  {
    lng: -41.8700736,
    lat: -22.7396676
  },
  {
    lng: -41.8702028,
    lat: -22.7395935
  },
  {
    lng: -41.8703585,
    lat: -22.7398024
  },
  {
    lng: -41.8704513,
    lat: -22.7397088
  },
  {
    lng: -41.8705303,
    lat: -22.7395809
  },
  {
    lng: -41.8704585,
    lat: -22.7390583
  },
  {
    lng: -41.8707234,
    lat: -22.738766
  },
  {
    lng: -41.8709429,
    lat: -22.7387271
  },
  {
    lng: -41.8710544,
    lat: -22.7384295
  },
  {
    lng: -41.8712245,
    lat: -22.7383776
  },
  {
    lng: -41.8712295,
    lat: -22.7381005
  },
  {
    lng: -41.8712886,
    lat: -22.738027
  },
  {
    lng: -41.8713529,
    lat: -22.7377555
  },
  {
    lng: -41.8716645,
    lat: -22.7376905
  },
  {
    lng: -41.871894,
    lat: -22.7378065
  },
  {
    lng: -41.8723864,
    lat: -22.7381813
  },
  {
    lng: -41.8721196,
    lat: -22.737523
  },
  {
    lng: -41.8723632,
    lat: -22.7373973
  },
  {
    lng: -41.8727106,
    lat: -22.7376327
  },
  {
    lng: -41.8730173,
    lat: -22.7377571
  },
  {
    lng: -41.8733271,
    lat: -22.7379793
  },
  {
    lng: -41.873459,
    lat: -22.7377213
  },
  {
    lng: -41.8736598,
    lat: -22.7377571
  },
  {
    lng: -41.8742501,
    lat: -22.738017
  },
  {
    lng: -41.8746418,
    lat: -22.7380677
  },
  {
    lng: -41.8744286,
    lat: -22.7382015
  },
  {
    lng: -41.8747934,
    lat: -22.7384715
  },
  {
    lng: -41.8748359,
    lat: -22.7386175
  },
  {
    lng: -41.8748073,
    lat: -22.7387053
  },
  {
    lng: -41.874599,
    lat: -22.7387605
  },
  {
    lng: -41.8744692,
    lat: -22.7389337
  },
  {
    lng: -41.8742991,
    lat: -22.7389394
  },
  {
    lng: -41.8741932,
    lat: -22.7388546
  },
  {
    lng: -41.8740678,
    lat: -22.7389888
  },
  {
    lng: -41.873983,
    lat: -22.7391045
  },
  {
    lng: -41.8739491,
    lat: -22.7392131
  },
  {
    lng: -41.8739305,
    lat: -22.7393998
  },
  {
    lng: -41.8739617,
    lat: -22.7396846
  },
  {
    lng: -41.8743339,
    lat: -22.7399855
  },
  {
    lng: -41.8743459,
    lat: -22.7400489
  },
  {
    lng: -41.8743828,
    lat: -22.7401092
  },
  {
    lng: -41.8744515,
    lat: -22.7401567
  },
  {
    lng: -41.8744054,
    lat: -22.7402419
  },
  {
    lng: -41.8744089,
    lat: -22.740371
  },
  {
    lng: -41.8742533,
    lat: -22.7405054
  },
  {
    lng: -41.8743117,
    lat: -22.7406016
  },
  {
    lng: -41.8743519,
    lat: -22.7406745
  },
  {
    lng: -41.8743139,
    lat: -22.7407202
  },
  {
    lng: -41.874055,
    lat: -22.740782
  },
  {
    lng: -41.873993,
    lat: -22.7408075
  },
  {
    lng: -41.8739535,
    lat: -22.741054
  },
  {
    lng: -41.8740501,
    lat: -22.7413113
  },
  {
    lng: -41.8742598,
    lat: -22.7415835
  },
  {
    lng: -41.874785,
    lat: -22.7418506
  },
  {
    lng: -41.8753308,
    lat: -22.7421065
  },
  {
    lng: -41.8758127,
    lat: -22.7422736
  },
  {
    lng: -41.8763084,
    lat: -22.742402
  },
  {
    lng: -41.8767694,
    lat: -22.7424615
  },
  {
    lng: -41.8772896,
    lat: -22.742427
  },
  {
    lng: -41.877889,
    lat: -22.7423214
  },
  {
    lng: -41.8778449,
    lat: -22.7421446
  },
  {
    lng: -41.8779159,
    lat: -22.7419991
  },
  {
    lng: -41.8780021,
    lat: -22.7419844
  },
  {
    lng: -41.8780862,
    lat: -22.741925
  },
  {
    lng: -41.8783207,
    lat: -22.7420428
  },
  {
    lng: -41.8786286,
    lat: -22.7419924
  },
  {
    lng: -41.878704,
    lat: -22.7417514
  },
  {
    lng: -41.8788539,
    lat: -22.7414964
  },
  {
    lng: -41.8789591,
    lat: -22.7415353
  },
  {
    lng: -41.878984,
    lat: -22.7411329
  },
  {
    lng: -41.8793568,
    lat: -22.7407778
  },
  {
    lng: -41.8793743,
    lat: -22.7406815
  },
  {
    lng: -41.879562,
    lat: -22.7405873
  },
  {
    lng: -41.8796652,
    lat: -22.7405984
  },
  {
    lng: -41.8797296,
    lat: -22.7405539
  },
  {
    lng: -41.8798704,
    lat: -22.7405428
  },
  {
    lng: -41.8799026,
    lat: -22.7404748
  },
  {
    lng: -41.8799697,
    lat: -22.7404463
  },
  {
    lng: -41.8800354,
    lat: -22.7403016
  },
  {
    lng: -41.880191,
    lat: -22.7402942
  },
  {
    lng: -41.8802218,
    lat: -22.7401309
  },
  {
    lng: -41.880259,
    lat: -22.7400614
  },
  {
    lng: -41.8805121,
    lat: -22.7401644
  },
  {
    lng: -41.8806909,
    lat: -22.7401026
  },
  {
    lng: -41.8807739,
    lat: -22.7401801
  },
  {
    lng: -41.880792,
    lat: -22.7401173
  },
  {
    lng: -41.8808538,
    lat: -22.7400945
  },
  {
    lng: -41.8809229,
    lat: -22.7401448
  },
  {
    lng: -41.8809602,
    lat: -22.740296
  },
  {
    lng: -41.8810131,
    lat: -22.7402114
  },
  {
    lng: -41.8812965,
    lat: -22.7402626
  },
  {
    lng: -41.8814031,
    lat: -22.740227
  },
  {
    lng: -41.8818054,
    lat: -22.7404944
  },
  {
    lng: -41.8820246,
    lat: -22.7406173
  },
  {
    lng: -41.8822257,
    lat: -22.74065
  },
  {
    lng: -41.8823604,
    lat: -22.7407114
  },
  {
    lng: -41.8824881,
    lat: -22.7408306
  },
  {
    lng: -41.8825717,
    lat: -22.7408732
  },
  {
    lng: -41.8825778,
    lat: -22.7409466
  },
  {
    lng: -41.8826164,
    lat: -22.740992
  },
  {
    lng: -41.8825689,
    lat: -22.7410908
  },
  {
    lng: -41.8825962,
    lat: -22.7411549
  },
  {
    lng: -41.8825587,
    lat: -22.741202
  },
  {
    lng: -41.8824483,
    lat: -22.7411913
  },
  {
    lng: -41.8823996,
    lat: -22.741165
  },
  {
    lng: -41.882339,
    lat: -22.7410957
  },
  {
    lng: -41.8822714,
    lat: -22.7410927
  },
  {
    lng: -41.8821992,
    lat: -22.7411255
  },
  {
    lng: -41.8820895,
    lat: -22.7412884
  },
  {
    lng: -41.8820396,
    lat: -22.7413367
  },
  {
    lng: -41.8820211,
    lat: -22.7413643
  },
  {
    lng: -41.8820651,
    lat: -22.7414411
  },
  {
    lng: -41.8820478,
    lat: -22.7415138
  },
  {
    lng: -41.8819584,
    lat: -22.7415338
  },
  {
    lng: -41.8818825,
    lat: -22.7415702
  },
  {
    lng: -41.8818534,
    lat: -22.7416602
  },
  {
    lng: -41.8817471,
    lat: -22.7416978
  },
  {
    lng: -41.8817375,
    lat: -22.7418688
  },
  {
    lng: -41.881784,
    lat: -22.7420826
  },
  {
    lng: -41.8818635,
    lat: -22.7423305
  },
  {
    lng: -41.8819987,
    lat: -22.7426106
  },
  {
    lng: -41.8822499,
    lat: -22.7429366
  },
  {
    lng: -41.8824393,
    lat: -22.7430065
  },
  {
    lng: -41.882587,
    lat: -22.7430906
  },
  {
    lng: -41.8828283,
    lat: -22.7433097
  },
  {
    lng: -41.8829134,
    lat: -22.7434858
  },
  {
    lng: -41.8831583,
    lat: -22.7435833
  },
  {
    lng: -41.8834244,
    lat: -22.7437595
  },
  {
    lng: -41.8835991,
    lat: -22.744058
  },
  {
    lng: -41.8836226,
    lat: -22.7442183
  },
  {
    lng: -41.8837838,
    lat: -22.7444362
  },
  {
    lng: -41.8840265,
    lat: -22.7447235
  },
  {
    lng: -41.8837283,
    lat: -22.7447779
  },
  {
    lng: -41.8835498,
    lat: -22.7448399
  },
  {
    lng: -41.8833576,
    lat: -22.7448051
  },
  {
    lng: -41.8831603,
    lat: -22.7446538
  },
  {
    lng: -41.8828722,
    lat: -22.7445709
  },
  {
    lng: -41.8825919,
    lat: -22.744486
  },
  {
    lng: -41.8823578,
    lat: -22.7444802
  },
  {
    lng: -41.8821545,
    lat: -22.7446244
  },
  {
    lng: -41.8817938,
    lat: -22.7448667
  },
  {
    lng: -41.8816534,
    lat: -22.7450366
  },
  {
    lng: -41.8815159,
    lat: -22.7453706
  },
  {
    lng: -41.8814762,
    lat: -22.7458164
  },
  {
    lng: -41.8815698,
    lat: -22.7463162
  },
  {
    lng: -41.8816425,
    lat: -22.7466067
  },
  {
    lng: -41.8818262,
    lat: -22.7468447
  },
  {
    lng: -41.8819132,
    lat: -22.7467777
  },
  {
    lng: -41.8820178,
    lat: -22.7467365
  },
  {
    lng: -41.8824537,
    lat: -22.7467101
  },
  {
    lng: -41.8825551,
    lat: -22.7467118
  },
  {
    lng: -41.8827004,
    lat: -22.7468069
  },
  {
    lng: -41.8826656,
    lat: -22.7468668
  },
  {
    lng: -41.8827488,
    lat: -22.7469452
  },
  {
    lng: -41.8828038,
    lat: -22.7469034
  },
  {
    lng: -41.8828556,
    lat: -22.7469684
  },
  {
    lng: -41.882901,
    lat: -22.7470798
  },
  {
    lng: -41.8829132,
    lat: -22.7472171
  },
  {
    lng: -41.8828776,
    lat: -22.7473481
  },
  {
    lng: -41.8828468,
    lat: -22.7474022
  },
  {
    lng: -41.8828091,
    lat: -22.7474118
  },
  {
    lng: -41.8825966,
    lat: -22.7476111
  },
  {
    lng: -41.8825826,
    lat: -22.7476115
  },
  {
    lng: -41.8825384,
    lat: -22.7476129
  },
  {
    lng: -41.8825203,
    lat: -22.7476296
  },
  {
    lng: -41.8823935,
    lat: -22.7477225
  },
  {
    lng: -41.8823515,
    lat: -22.7477531
  },
  {
    lng: -41.8823198,
    lat: -22.7478259
  },
  {
    lng: -41.8822737,
    lat: -22.7478531
  },
  {
    lng: -41.8821289,
    lat: -22.747853
  },
  {
    lng: -41.8819576,
    lat: -22.7476431
  },
  {
    lng: -41.8818167,
    lat: -22.7478014
  },
  {
    lng: -41.8817893,
    lat: -22.7478682
  },
  {
    lng: -41.8817578,
    lat: -22.7479512
  },
  {
    lng: -41.8817456,
    lat: -22.7479699
  },
  {
    lng: -41.8816594,
    lat: -22.7480352
  },
  {
    lng: -41.8816135,
    lat: -22.7481063
  },
  {
    lng: -41.8815714,
    lat: -22.7482331
  },
  {
    lng: -41.8815847,
    lat: -22.7484235
  },
  {
    lng: -41.8816344,
    lat: -22.7485917
  },
  {
    lng: -41.8818127,
    lat: -22.7491823
  },
  {
    lng: -41.8818222,
    lat: -22.7492139
  },
  {
    lng: -41.8817712,
    lat: -22.7492609
  },
  {
    lng: -41.8817631,
    lat: -22.7493005
  },
  {
    lng: -41.8817805,
    lat: -22.7493808
  },
  {
    lng: -41.8818288,
    lat: -22.749476
  },
  {
    lng: -41.881975,
    lat: -22.7496567
  },
  {
    lng: -41.8826133,
    lat: -22.7501513
  },
  {
    lng: -41.8827247,
    lat: -22.7502577
  },
  {
    lng: -41.8828668,
    lat: -22.7504419
  },
  {
    lng: -41.8830639,
    lat: -22.7506163
  },
  {
    lng: -41.8834221,
    lat: -22.7508106
  },
  {
    lng: -41.8836304,
    lat: -22.7509511
  },
  {
    lng: -41.8837241,
    lat: -22.7508975
  },
  {
    lng: -41.8842429,
    lat: -22.7513952
  },
  {
    lng: -41.8841385,
    lat: -22.7515056
  },
  {
    lng: -41.8841379,
    lat: -22.7516436
  },
  {
    lng: -41.884319,
    lat: -22.7519487
  },
  {
    lng: -41.8843871,
    lat: -22.7522407
  },
  {
    lng: -41.8843088,
    lat: -22.7524546
  },
  {
    lng: -41.884247,
    lat: -22.7527282
  },
  {
    lng: -41.8842947,
    lat: -22.7529208
  },
  {
    lng: -41.8844043,
    lat: -22.7531762
  },
  {
    lng: -41.8846741,
    lat: -22.7534376
  },
  {
    lng: -41.8850505,
    lat: -22.7537016
  },
  {
    lng: -41.8855421,
    lat: -22.7539179
  },
  {
    lng: -41.8857411,
    lat: -22.7540848
  },
  {
    lng: -41.8858805,
    lat: -22.7543223
  },
  {
    lng: -41.8863587,
    lat: -22.7544039
  },
  {
    lng: -41.8864047,
    lat: -22.7544149
  },
  {
    lng: -41.8869892,
    lat: -22.7546636
  },
  {
    lng: -41.887351,
    lat: -22.7548294
  },
  {
    lng: -41.8882622,
    lat: -22.7550695
  },
  {
    lng: -41.88917,
    lat: -22.7551649
  },
  {
    lng: -41.8900439,
    lat: -22.7551631
  },
  {
    lng: -41.8911375,
    lat: -22.7550597
  },
  {
    lng: -41.8923713,
    lat: -22.7547925
  },
  {
    lng: -41.89353,
    lat: -22.754466
  },
  {
    lng: -41.8946495,
    lat: -22.7540856
  },
  {
    lng: -41.895201,
    lat: -22.753775
  },
  {
    lng: -41.8956582,
    lat: -22.7535091
  },
  {
    lng: -41.8958917,
    lat: -22.7532976
  },
  {
    lng: -41.895948,
    lat: -22.7531183
  },
  {
    lng: -41.8959211,
    lat: -22.7529884
  },
  {
    lng: -41.8958166,
    lat: -22.7529601
  },
  {
    lng: -41.895665,
    lat: -22.7528537
  },
  {
    lng: -41.8955846,
    lat: -22.7527337
  },
  {
    lng: -41.8955282,
    lat: -22.75261
  },
  {
    lng: -41.8954813,
    lat: -22.7524839
  },
  {
    lng: -41.8954934,
    lat: -22.7522377
  },
  {
    lng: -41.8955256,
    lat: -22.7521067
  },
  {
    lng: -41.8956221,
    lat: -22.7519954
  },
  {
    lng: -41.8958499,
    lat: -22.7518841
  },
  {
    lng: -41.8960349,
    lat: -22.7516355
  },
  {
    lng: -41.8961925,
    lat: -22.7516628
  },
  {
    lng: -41.8961997,
    lat: -22.751736
  },
  {
    lng: -41.8964759,
    lat: -22.7517837
  },
  {
    lng: -41.8965073,
    lat: -22.751713
  },
  {
    lng: -41.896608,
    lat: -22.7517364
  },
  {
    lng: -41.8967727,
    lat: -22.7518123
  },
  {
    lng: -41.8970912,
    lat: -22.751585
  },
  {
    lng: -41.8971541,
    lat: -22.7512736
  },
  {
    lng: -41.897169,
    lat: -22.7508978
  },
  {
    lng: -41.8970464,
    lat: -22.7504827
  },
  {
    lng: -41.8971475,
    lat: -22.7504086
  },
  {
    lng: -41.8972011,
    lat: -22.7499314
  },
  {
    lng: -41.8973701,
    lat: -22.7495802
  },
  {
    lng: -41.8975605,
    lat: -22.7494167
  },
  {
    lng: -41.8976392,
    lat: -22.7494112
  },
  {
    lng: -41.8977238,
    lat: -22.7493693
  },
  {
    lng: -41.8978675,
    lat: -22.7494508
  },
  {
    lng: -41.8980231,
    lat: -22.7495429
  },
  {
    lng: -41.8981577,
    lat: -22.7495693
  },
  {
    lng: -41.898267,
    lat: -22.7496228
  },
  {
    lng: -41.8983815,
    lat: -22.749654
  },
  {
    lng: -41.8984585,
    lat: -22.7496791
  },
  {
    lng: -41.8985501,
    lat: -22.7496905
  },
  {
    lng: -41.8987393,
    lat: -22.7499318
  },
  {
    lng: -41.8988149,
    lat: -22.7501061
  },
  {
    lng: -41.8988717,
    lat: -22.750195
  },
  {
    lng: -41.8989793,
    lat: -22.7502097
  },
  {
    lng: -41.899027,
    lat: -22.7502403
  },
  {
    lng: -41.8990425,
    lat: -22.7502982
  },
  {
    lng: -41.8991752,
    lat: -22.7503423
  },
  {
    lng: -41.899226,
    lat: -22.7504759
  },
  {
    lng: -41.8993313,
    lat: -22.7505285
  },
  {
    lng: -41.8993926,
    lat: -22.7506351
  },
  {
    lng: -41.8995876,
    lat: -22.7507966
  },
  {
    lng: -41.8996992,
    lat: -22.7509342
  },
  {
    lng: -41.8998927,
    lat: -22.7510471
  },
  {
    lng: -41.9001992,
    lat: -22.7511334
  },
  {
    lng: -41.9003518,
    lat: -22.7512299
  },
  {
    lng: -41.9004244,
    lat: -22.7513356
  },
  {
    lng: -41.9005607,
    lat: -22.7515418
  },
  {
    lng: -41.9006299,
    lat: -22.7515991
  },
  {
    lng: -41.9007291,
    lat: -22.7516812
  },
  {
    lng: -41.9007214,
    lat: -22.7517988
  },
  {
    lng: -41.9007643,
    lat: -22.7519273
  },
  {
    lng: -41.9007005,
    lat: -22.7520303
  },
  {
    lng: -41.9007269,
    lat: -22.7522437
  },
  {
    lng: -41.9006886,
    lat: -22.7523106
  },
  {
    lng: -41.9006868,
    lat: -22.752398
  },
  {
    lng: -41.9007914,
    lat: -22.7526309
  },
  {
    lng: -41.9007642,
    lat: -22.7526741
  },
  {
    lng: -41.9007681,
    lat: -22.7527191
  },
  {
    lng: -41.9008457,
    lat: -22.7528221
  },
  {
    lng: -41.9010784,
    lat: -22.7530356
  },
  {
    lng: -41.9011163,
    lat: -22.7530632
  },
  {
    lng: -41.9012039,
    lat: -22.7531269
  },
  {
    lng: -41.9012596,
    lat: -22.7532975
  },
  {
    lng: -41.9008282,
    lat: -22.7536998
  },
  {
    lng: -41.9006947,
    lat: -22.7538909
  },
  {
    lng: -41.9007026,
    lat: -22.7539688
  },
  {
    lng: -41.9007803,
    lat: -22.7541644
  },
  {
    lng: -41.9007949,
    lat: -22.7542325
  },
  {
    lng: -41.9008418,
    lat: -22.7542561
  },
  {
    lng: -41.9009035,
    lat: -22.7543477
  },
  {
    lng: -41.9008025,
    lat: -22.7544158
  },
  {
    lng: -41.9007851,
    lat: -22.7545019
  },
  {
    lng: -41.9007104,
    lat: -22.7545993
  },
  {
    lng: -41.9007698,
    lat: -22.7547649
  },
  {
    lng: -41.9008978,
    lat: -22.7549849
  },
  {
    lng: -41.9011225,
    lat: -22.7552095
  },
  {
    lng: -41.9016896,
    lat: -22.7555403
  },
  {
    lng: -41.9021417,
    lat: -22.7556533
  },
  {
    lng: -41.9023124,
    lat: -22.7557219
  },
  {
    lng: -41.9025112,
    lat: -22.7559328
  },
  {
    lng: -41.9025424,
    lat: -22.7560104
  },
  {
    lng: -41.9025923,
    lat: -22.7560755
  },
  {
    lng: -41.9025981,
    lat: -22.756181
  },
  {
    lng: -41.9025891,
    lat: -22.7562794
  },
  {
    lng: -41.9026152,
    lat: -22.7563724
  },
  {
    lng: -41.9029007,
    lat: -22.756457
  },
  {
    lng: -41.9032692,
    lat: -22.7564638
  },
  {
    lng: -41.903547,
    lat: -22.7563589
  },
  {
    lng: -41.9036644,
    lat: -22.7562623
  },
  {
    lng: -41.9036815,
    lat: -22.7562116
  },
  {
    lng: -41.9036635,
    lat: -22.75616
  },
  {
    lng: -41.9034758,
    lat: -22.7559807
  },
  {
    lng: -41.9034259,
    lat: -22.7558492
  },
  {
    lng: -41.9034499,
    lat: -22.7557435
  },
  {
    lng: -41.9034836,
    lat: -22.7556933
  },
  {
    lng: -41.9035556,
    lat: -22.7556996
  },
  {
    lng: -41.9036302,
    lat: -22.7558847
  },
  {
    lng: -41.9037,
    lat: -22.7559675
  },
  {
    lng: -41.9037037,
    lat: -22.7558304
  },
  {
    lng: -41.9037485,
    lat: -22.75577
  },
  {
    lng: -41.9037944,
    lat: -22.755766
  },
  {
    lng: -41.9036659,
    lat: -22.7555221
  },
  {
    lng: -41.903702,
    lat: -22.755456
  },
  {
    lng: -41.9037429,
    lat: -22.7554366
  },
  {
    lng: -41.9038015,
    lat: -22.7554903
  },
  {
    lng: -41.9039,
    lat: -22.7556406
  },
  {
    lng: -41.903944,
    lat: -22.7557341
  },
  {
    lng: -41.903997,
    lat: -22.7558216
  },
  {
    lng: -41.9040599,
    lat: -22.7559223
  },
  {
    lng: -41.9039892,
    lat: -22.7560137
  },
  {
    lng: -41.9039444,
    lat: -22.7560282
  },
  {
    lng: -41.9039157,
    lat: -22.7560749
  },
  {
    lng: -41.9038191,
    lat: -22.7562623
  },
  {
    lng: -41.9037918,
    lat: -22.7564365
  },
  {
    lng: -41.9038667,
    lat: -22.7566279
  },
  {
    lng: -41.9040598,
    lat: -22.7567974
  },
  {
    lng: -41.904492,
    lat: -22.7569698
  },
  {
    lng: -41.9049197,
    lat: -22.756998
  },
  {
    lng: -41.905755,
    lat: -22.7568647
  },
  {
    lng: -41.9069587,
    lat: -22.7564308
  },
  {
    lng: -41.9074791,
    lat: -22.7561464
  },
  {
    lng: -41.908244,
    lat: -22.7555984
  },
  {
    lng: -41.9089054,
    lat: -22.7555627
  },
  {
    lng: -41.9092882,
    lat: -22.7556513
  },
  {
    lng: -41.9095818,
    lat: -22.7557842
  },
  {
    lng: -41.909839,
    lat: -22.7556725
  },
  {
    lng: -41.9102865,
    lat: -22.7556619
  },
  {
    lng: -41.9104814,
    lat: -22.7558734
  },
  {
    lng: -41.9104868,
    lat: -22.7560367
  },
  {
    lng: -41.9104929,
    lat: -22.7562213
  },
  {
    lng: -41.9107032,
    lat: -22.7566084
  },
  {
    lng: -41.9109979,
    lat: -22.7567095
  },
  {
    lng: -41.911193,
    lat: -22.756974
  },
  {
    lng: -41.9111471,
    lat: -22.7573655
  },
  {
    lng: -41.9112962,
    lat: -22.7575242
  },
  {
    lng: -41.9114552,
    lat: -22.7577115
  },
  {
    lng: -41.9115801,
    lat: -22.7578058
  },
  {
    lng: -41.9117331,
    lat: -22.7579213
  },
  {
    lng: -41.911882,
    lat: -22.7580423
  },
  {
    lng: -41.9116529,
    lat: -22.7580236
  },
  {
    lng: -41.9113017,
    lat: -22.7579867
  },
  {
    lng: -41.9109496,
    lat: -22.7579742
  },
  {
    lng: -41.9105453,
    lat: -22.7579909
  },
  {
    lng: -41.9102806,
    lat: -22.7580274
  },
  {
    lng: -41.9099639,
    lat: -22.7580033
  },
  {
    lng: -41.9093214,
    lat: -22.7581327
  },
  {
    lng: -41.9092909,
    lat: -22.7581388
  },
  {
    lng: -41.9091582,
    lat: -22.7583232
  },
  {
    lng: -41.9090739,
    lat: -22.7584928
  },
  {
    lng: -41.9089679,
    lat: -22.7587308
  },
  {
    lng: -41.908797,
    lat: -22.7590422
  },
  {
    lng: -41.9086007,
    lat: -22.759436
  },
  {
    lng: -41.9085275,
    lat: -22.759705
  },
  {
    lng: -41.9084491,
    lat: -22.7599986
  },
  {
    lng: -41.9084616,
    lat: -22.760739
  },
  {
    lng: -41.9085758,
    lat: -22.7615328
  },
  {
    lng: -41.9085623,
    lat: -22.7615909
  },
  {
    lng: -41.9085301,
    lat: -22.7616354
  },
  {
    lng: -41.9083881,
    lat: -22.7617987
  },
  {
    lng: -41.9082758,
    lat: -22.7618965
  },
  {
    lng: -41.9080718,
    lat: -22.7621578
  },
  {
    lng: -41.9080971,
    lat: -22.7629594
  },
  {
    lng: -41.9083489,
    lat: -22.7636725
  },
  {
    lng: -41.9084387,
    lat: -22.7639979
  },
  {
    lng: -41.9085769,
    lat: -22.7643337
  },
  {
    lng: -41.9087022,
    lat: -22.7645872
  },
  {
    lng: -41.9087875,
    lat: -22.7647301
  },
  {
    lng: -41.90883,
    lat: -22.7648495
  },
  {
    lng: -41.9087541,
    lat: -22.7651328
  },
  {
    lng: -41.9086447,
    lat: -22.7653914
  },
  {
    lng: -41.9086405,
    lat: -22.7655863
  },
  {
    lng: -41.9087567,
    lat: -22.7658571
  },
  {
    lng: -41.9094091,
    lat: -22.7668041
  },
  {
    lng: -41.9095602,
    lat: -22.7670302
  },
  {
    lng: -41.9097601,
    lat: -22.7672504
  },
  {
    lng: -41.9098696,
    lat: -22.7673856
  },
  {
    lng: -41.9104485,
    lat: -22.76818
  },
  {
    lng: -41.9109646,
    lat: -22.7686368
  },
  {
    lng: -41.9117975,
    lat: -22.7690792
  },
  {
    lng: -41.912107,
    lat: -22.7691913
  },
  {
    lng: -41.9123858,
    lat: -22.7693731
  },
  {
    lng: -41.9125106,
    lat: -22.7695152
  },
  {
    lng: -41.9125408,
    lat: -22.7695561
  },
  {
    lng: -41.9127158,
    lat: -22.7697384
  },
  {
    lng: -41.9130283,
    lat: -22.7700272
  },
  {
    lng: -41.9136251,
    lat: -22.7704131
  },
  {
    lng: -41.9141179,
    lat: -22.7707038
  },
  {
    lng: -41.9152988,
    lat: -22.7711365
  },
  {
    lng: -41.9165031,
    lat: -22.7713887
  },
  {
    lng: -41.9171548,
    lat: -22.7714505
  },
  {
    lng: -41.9184799,
    lat: -22.7714926
  },
  {
    lng: -41.919526,
    lat: -22.7714629
  },
  {
    lng: -41.920226,
    lat: -22.7714036
  },
  {
    lng: -41.9221894,
    lat: -22.7710969
  },
  {
    lng: -41.9240482,
    lat: -22.7706542
  },
  {
    lng: -41.9261644,
    lat: -22.7700755
  },
  {
    lng: -41.9274921,
    lat: -22.7695833
  },
  {
    lng: -41.9284148,
    lat: -22.7691901
  },
  {
    lng: -41.9298256,
    lat: -22.7685024
  },
  {
    lng: -41.9310863,
    lat: -22.7678323
  },
  {
    lng: -41.9325052,
    lat: -22.7670383
  },
  {
    lng: -41.9332991,
    lat: -22.7665528
  },
  {
    lng: -41.9339321,
    lat: -22.7660638
  },
  {
    lng: -41.9345877,
    lat: -22.7655399
  },
  {
    lng: -41.9368343,
    lat: -22.7637932
  },
  {
    lng: -41.9392108,
    lat: -22.7616065
  },
  {
    lng: -41.9410669,
    lat: -22.7597008
  },
  {
    lng: -41.9424422,
    lat: -22.7581719
  },
  {
    lng: -41.9432072,
    lat: -22.7572187
  },
  {
    lng: -41.9452105,
    lat: -22.7548028
  },
  {
    lng: -41.9463372,
    lat: -22.7531092
  },
  {
    lng: -41.9472182,
    lat: -22.7515293
  },
  {
    lng: -41.9485537,
    lat: -22.750116
  },
  {
    lng: -41.9496227,
    lat: -22.7483556
  },
  {
    lng: -41.9504379,
    lat: -22.7467597
  },
  {
    lng: -41.9508304,
    lat: -22.7459341
  },
  {
    lng: -41.9510208,
    lat: -22.7451683
  },
  {
    lng: -41.9511918,
    lat: -22.7448344
  },
  {
    lng: -41.9500058,
    lat: -22.7440726
  },
  {
    lng: -41.9500843,
    lat: -22.7439988
  },
  {
    lng: -41.9511172,
    lat: -22.7446906
  },
  {
    lng: -41.9511983,
    lat: -22.7445046
  },
  {
    lng: -41.951291,
    lat: -22.7443
  },
  {
    lng: -41.9505837,
    lat: -22.7438159
  },
  {
    lng: -41.9506443,
    lat: -22.7437625
  },
  {
    lng: -41.9512994,
    lat: -22.7442034
  },
  {
    lng: -41.9515141,
    lat: -22.7437903
  },
  {
    lng: -41.9517564,
    lat: -22.7432217
  },
  {
    lng: -41.9523632,
    lat: -22.7425333
  },
  {
    lng: -41.9543673,
    lat: -22.7408316
  },
  {
    lng: -41.9550942,
    lat: -22.7400315
  },
  {
    lng: -41.956339,
    lat: -22.7383003
  },
  {
    lng: -41.956808,
    lat: -22.7376477
  },
  {
    lng: -41.957433,
    lat: -22.7366979
  },
  {
    lng: -41.9578901,
    lat: -22.7357891
  },
  {
    lng: -41.9582392,
    lat: -22.7348702
  },
  {
    lng: -41.9582171,
    lat: -22.7346624
  },
  {
    lng: -41.9580956,
    lat: -22.7344524
  },
  {
    lng: -41.9579984,
    lat: -22.7339085
  },
  {
    lng: -41.9577631,
    lat: -22.7339163
  },
  {
    lng: -41.957527,
    lat: -22.7338837
  },
  {
    lng: -41.957309,
    lat: -22.7334075
  },
  {
    lng: -41.9571943,
    lat: -22.7329101
  },
  {
    lng: -41.9567468,
    lat: -22.7326773
  },
  {
    lng: -41.9563224,
    lat: -22.7326448
  },
  {
    lng: -41.9561386,
    lat: -22.7328148
  },
  {
    lng: -41.9557876,
    lat: -22.7328279
  },
  {
    lng: -41.9553239,
    lat: -22.7324338
  },
  {
    lng: -41.9552092,
    lat: -22.7321587
  },
  {
    lng: -41.9547502,
    lat: -22.731947
  },
  {
    lng: -41.9547102,
    lat: -22.7317696
  },
  {
    lng: -41.9549453,
    lat: -22.7317142
  },
  {
    lng: -41.9553354,
    lat: -22.7318518
  },
  {
    lng: -41.9558403,
    lat: -22.7317459
  },
  {
    lng: -41.956276,
    lat: -22.7317618
  },
  {
    lng: -41.9564599,
    lat: -22.7319047
  },
  {
    lng: -41.9564599,
    lat: -22.7323174
  },
  {
    lng: -41.9567238,
    lat: -22.7325185
  },
  {
    lng: -41.9569992,
    lat: -22.7325185
  },
  {
    lng: -41.9573868,
    lat: -22.7328048
  },
  {
    lng: -41.9575474,
    lat: -22.7328276
  },
  {
    lng: -41.9576865,
    lat: -22.7329131
  },
  {
    lng: -41.9578498,
    lat: -22.732974
  },
  {
    lng: -41.9580489,
    lat: -22.7330036
  },
  {
    lng: -41.9583186,
    lat: -22.7330722
  },
  {
    lng: -41.9583739,
    lat: -22.7330772
  },
  {
    lng: -41.9589863,
    lat: -22.7326426
  },
  {
    lng: -41.9592386,
    lat: -22.7325157
  },
  {
    lng: -41.9598448,
    lat: -22.7325079
  },
  {
    lng: -41.9602174,
    lat: -22.7325202
  },
  {
    lng: -41.9605349,
    lat: -22.7324757
  },
  {
    lng: -41.9608614,
    lat: -22.7326071
  },
  {
    lng: -41.9612309,
    lat: -22.7326249
  },
  {
    lng: -41.9618467,
    lat: -22.7323649
  },
  {
    lng: -41.9626611,
    lat: -22.7321742
  },
  {
    lng: -41.9631283,
    lat: -22.7323174
  },
  {
    lng: -41.963369,
    lat: -22.7326522
  },
  {
    lng: -41.9635588,
    lat: -22.7330046
  },
  {
    lng: -41.9641021,
    lat: -22.7332562
  },
  {
    lng: -41.964536,
    lat: -22.7332032
  },
  {
    lng: -41.9649396,
    lat: -22.7330477
  },
  {
    lng: -41.9653182,
    lat: -22.7328678
  },
  {
    lng: -41.9657657,
    lat: -22.7327937
  },
  {
    lng: -41.9662132,
    lat: -22.7325397
  },
  {
    lng: -41.9665001,
    lat: -22.7324338
  },
  {
    lng: -41.9667755,
    lat: -22.732455
  },
  {
    lng: -41.9670355,
    lat: -22.7324479
  },
  {
    lng: -41.9672689,
    lat: -22.7323174
  },
  {
    lng: -41.9676984,
    lat: -22.7319575
  },
  {
    lng: -41.9680629,
    lat: -22.7316608
  },
  {
    lng: -41.9684422,
    lat: -22.7313844
  },
  {
    lng: -41.9686344,
    lat: -22.7312697
  },
  {
    lng: -41.9693343,
    lat: -22.7308252
  },
  {
    lng: -41.9697205,
    lat: -22.730441
  },
  {
    lng: -41.9701048,
    lat: -22.7300293
  },
  {
    lng: -41.9707275,
    lat: -22.7294587
  },
  {
    lng: -41.9715718,
    lat: -22.729066
  },
  {
    lng: -41.9720931,
    lat: -22.7289623
  },
  {
    lng: -41.9722213,
    lat: -22.7288853
  },
  {
    lng: -41.9722742,
    lat: -22.728923
  },
  {
    lng: -41.9722796,
    lat: -22.7290368
  },
  {
    lng: -41.9722661,
    lat: -22.7292842
  },
  {
    lng: -41.9722071,
    lat: -22.7295761
  },
  {
    lng: -41.972186,
    lat: -22.729729
  },
  {
    lng: -41.9722608,
    lat: -22.7298507
  },
  {
    lng: -41.9724145,
    lat: -22.7299994
  },
  {
    lng: -41.9725183,
    lat: -22.7301006
  },
  {
    lng: -41.9727179,
    lat: -22.7302132
  },
  {
    lng: -41.9730172,
    lat: -22.730254
  },
  {
    lng: -41.9733239,
    lat: -22.7302491
  },
  {
    lng: -41.9736207,
    lat: -22.7301971
  },
  {
    lng: -41.9739605,
    lat: -22.7300922
  },
  {
    lng: -41.97442,
    lat: -22.7298334
  },
];

export default polygonCoords_Buzios;