let polygonCoordsRio31 = [
  {
    lng: -43.4295543,
    lat: -22.8374656
  },
  {
    lng: -43.4297116,
    lat: -22.8374495
  },
  {
    lng: -43.4298579,
    lat: -22.8374502
  },
  {
    lng: -43.4300083,
    lat: -22.8374962
  },
  {
    lng: -43.4301376,
    lat: -22.8375702
  },
  {
    lng: -43.430273,
    lat: -22.8376442
  },
  {
    lng: -43.430364,
    lat: -22.8377505
  },
  {
    lng: -43.4303523,
    lat: -22.8378906
  },
  {
    lng: -43.4303052,
    lat: -22.8379397
  },
  {
    lng: -43.4302632,
    lat: -22.837981
  },
  {
    lng: -43.4302554,
    lat: -22.8380125
  },
  {
    lng: -43.4302696,
    lat: -22.8380409
  },
  {
    lng: -43.4303579,
    lat: -22.8380861
  },
  {
    lng: -43.4304933,
    lat: -22.8381579
  },
  {
    lng: -43.4305696,
    lat: -22.8382776
  },
  {
    lng: -43.4306716,
    lat: -22.838384
  },
  {
    lng: -43.4307923,
    lat: -22.8384624
  },
  {
    lng: -43.4309289,
    lat: -22.8385387
  },
  {
    lng: -43.4310696,
    lat: -22.8385824
  },
  {
    lng: -43.4312251,
    lat: -22.8386115
  },
  {
    lng: -43.4313746,
    lat: -22.8386439
  },
  {
    lng: -43.4315226,
    lat: -22.8386887
  },
  {
    lng: -43.4316594,
    lat: -22.838747
  },
  {
    lng: -43.4317888,
    lat: -22.8388288
  },
  {
    lng: -43.4318073,
    lat: -22.8389715
  },
  {
    lng: -43.4317417,
    lat: -22.8391176
  },
  {
    lng: -43.4316474,
    lat: -22.8392385
  },
  {
    lng: -43.431604,
    lat: -22.839369
  },
  {
    lng: -43.4316507,
    lat: -22.8395087
  },
  {
    lng: -43.4317751,
    lat: -22.8395838
  },
  {
    lng: -43.4318921,
    lat: -22.8396655
  },
  {
    lng: -43.4319428,
    lat: -22.8397826
  },
  {
    lng: -43.4319873,
    lat: -22.8398272
  },
  {
    lng: -43.4320551,
    lat: -22.8398528
  },
  {
    lng: -43.4320968,
    lat: -22.8398376
  },
  {
    lng: -43.4322066,
    lat: -22.8397452
  },
  {
    lng: -43.4323863,
    lat: -22.8397562
  },
  {
    lng: -43.4325119,
    lat: -22.8397841
  },
  {
    lng: -43.4326812,
    lat: -22.8398345
  },
  {
    lng: -43.4328139,
    lat: -22.8398632
  },
  {
    lng: -43.4329687,
    lat: -22.8399127
  },
  {
    lng: -43.433116,
    lat: -22.8399622
  },
  {
    lng: -43.4332633,
    lat: -22.8400185
  },
  {
    lng: -43.4334154,
    lat: -22.8400769
  },
  {
    lng: -43.4335283,
    lat: -22.8401078
  },
  {
    lng: -43.433644,
    lat: -22.8401997
  },
  {
    lng: -43.4337301,
    lat: -22.8403195
  },
  {
    lng: -43.4337964,
    lat: -22.8404457
  },
  {
    lng: -43.433891,
    lat: -22.8405555
  },
  {
    lng: -43.4339957,
    lat: -22.8406562
  },
  {
    lng: -43.4341211,
    lat: -22.8407404
  },
  {
    lng: -43.4342119,
    lat: -22.8408579
  },
  {
    lng: -43.4342698,
    lat: -22.8409841
  },
  {
    lng: -43.4343372,
    lat: -22.841124
  },
  {
    lng: -43.4344404,
    lat: -22.8412316
  },
  {
    lng: -43.4345623,
    lat: -22.8413167
  },
  {
    lng: -43.4346978,
    lat: -22.8413807
  },
  {
    lng: -43.4348111,
    lat: -22.8414668
  },
  {
    lng: -43.4349133,
    lat: -22.841571
  },
  {
    lng: -43.4350263,
    lat: -22.8416639
  },
  {
    lng: -43.435123,
    lat: -22.8418155
  },
  {
    lng: -43.4351221,
    lat: -22.8418438
  },
  {
    lng: -43.4351812,
    lat: -22.8419018
  },
  {
    lng: -43.4352804,
    lat: -22.8420062
  },
  {
    lng: -43.4378748,
    lat: -22.8420182
  },
  {
    lng: -43.4396353,
    lat: -22.8422446
  },
  {
    lng: -43.4498468,
    lat: -22.8459506
  },
  {
    lng: -43.4582097,
    lat: -22.8490357
  },
  {
    lng: -43.4693393,
    lat: -22.8405033
  },
  {
    lng: -43.4824206,
    lat: -22.8304318
  },
  {
    lng: -43.4841904,
    lat: -22.8269677
  },
  {
    lng: -43.4900623,
    lat: -22.815515
  },
  {
    lng: -43.5152864,
    lat: -22.8211038
  },
  {
    lng: -43.5218048,
    lat: -22.822548
  },
  {
    lng: -43.5593869,
    lat: -22.8288849
  },
  {
    lng: -43.5884659,
    lat: -22.8386931
  },
  {
    lng: -43.5908659,
    lat: -22.8506019
  },
  {
    lng: -43.5928925,
    lat: -22.8605864
  },
  {
    lng: -43.5929096,
    lat: -22.8605694
  },
  {
    lng: -43.5941186,
    lat: -22.8593978
  },
  {
    lng: -43.5944061,
    lat: -22.8591225
  },
  {
    lng: -43.594964,
    lat: -22.8585135
  },
  {
    lng: -43.5955562,
    lat: -22.8580706
  },
  {
    lng: -43.5961227,
    lat: -22.8578175
  },
  {
    lng: -43.5966291,
    lat: -22.85777
  },
  {
    lng: -43.5974788,
    lat: -22.8579519
  },
  {
    lng: -43.5988607,
    lat: -22.8582446
  },
  {
    lng: -43.6004829,
    lat: -22.858632
  },
  {
    lng: -43.6011953,
    lat: -22.8588139
  },
  {
    lng: -43.6022011,
    lat: -22.8589825
  },
  {
    lng: -43.6026399,
    lat: -22.8590592
  },
  {
    lng: -43.6035814,
    lat: -22.8592173
  },
  {
    lng: -43.6037663,
    lat: -22.859253
  },
  {
    lng: -43.6057958,
    lat: -22.8596444
  },
  {
    lng: -43.6069202,
    lat: -22.8598816
  },
  {
    lng: -43.6108855,
    lat: -22.8606568
  },
  {
    lng: -43.6160611,
    lat: -22.8616217
  },
  {
    lng: -43.6187219,
    lat: -22.862112
  },
  {
    lng: -43.6210221,
    lat: -22.8624363
  },
  {
    lng: -43.6232366,
    lat: -22.8627922
  },
  {
    lng: -43.6237258,
    lat: -22.8627922
  },
  {
    lng: -43.625142,
    lat: -22.8623019
  },
  {
    lng: -43.6269874,
    lat: -22.8617008
  },
  {
    lng: -43.6287898,
    lat: -22.8610206
  },
  {
    lng: -43.6294421,
    lat: -22.8607913
  },
  {
    lng: -43.631236,
    lat: -22.8603246
  },
  {
    lng: -43.6324853,
    lat: -22.8598416
  },
  {
    lng: -43.6339443,
    lat: -22.8592895
  },
  {
    lng: -43.6354477,
    lat: -22.858671
  },
  {
    lng: -43.6365627,
    lat: -22.8582978
  },
  {
    lng: -43.6374171,
    lat: -22.8580064
  },
  {
    lng: -43.638399,
    lat: -22.8576946
  },
  {
    lng: -43.6394419,
    lat: -22.8573061
  },
  {
    lng: -43.6398635,
    lat: -22.8571374
  },
  {
    lng: -43.640962,
    lat: -22.8567693
  },
  {
    lng: -43.6415223,
    lat: -22.8566006
  },
  {
    lng: -43.6422379,
    lat: -22.8562888
  },
  {
    lng: -43.6428692,
    lat: -22.8560521
  },
  {
    lng: -43.643591,
    lat: -22.8558714
  },
  {
    lng: -43.6442814,
    lat: -22.8558136
  },
  {
    lng: -43.6448933,
    lat: -22.855922
  },
  {
    lng: -43.6457249,
    lat: -22.8561678
  },
  {
    lng: -43.6464389,
    lat: -22.8565004
  },
  {
    lng: -43.6467457,
    lat: -22.8566263
  },
  {
    lng: -43.6473782,
    lat: -22.8567285
  },
  {
    lng: -43.6482436,
    lat: -22.8569228
  },
  {
    lng: -43.6491645,
    lat: -22.857117
  },
  {
    lng: -43.6500188,
    lat: -22.8574135
  },
  {
    lng: -43.6505181,
    lat: -22.8577407
  },
  {
    lng: -43.6511727,
    lat: -22.8581087
  },
  {
    lng: -43.6518273,
    lat: -22.8585892
  },
  {
    lng: -43.6523044,
    lat: -22.8589369
  },
  {
    lng: -43.652726,
    lat: -22.8593458
  },
  {
    lng: -43.6534583,
    lat: -22.8598774
  },
  {
    lng: -43.6546344,
    lat: -22.8606544
  },
  {
    lng: -43.6553556,
    lat: -22.8611349
  },
  {
    lng: -43.6560546,
    lat: -22.8616359
  },
  {
    lng: -43.6569533,
    lat: -22.8623516
  },
  {
    lng: -43.6576855,
    lat: -22.8628627
  },
  {
    lng: -43.6582403,
    lat: -22.8632921
  },
  {
    lng: -43.6588394,
    lat: -22.8634557
  },
  {
    lng: -43.659716,
    lat: -22.8636193
  },
  {
    lng: -43.66077,
    lat: -22.8639157
  },
  {
    lng: -43.6615356,
    lat: -22.8641611
  },
  {
    lng: -43.6629577,
    lat: -22.8646628
  },
  {
    lng: -43.6640348,
    lat: -22.8649324
  },
  {
    lng: -43.6646173,
    lat: -22.8650806
  },
  {
    lng: -43.6649668,
    lat: -22.8651624
  },
  {
    lng: -43.6651887,
    lat: -22.8652033
  },
  {
    lng: -43.6654328,
    lat: -22.8652084
  },
  {
    lng: -43.6656825,
    lat: -22.8652135
  },
  {
    lng: -43.6659765,
    lat: -22.8651777
  },
  {
    lng: -43.6662206,
    lat: -22.8651624
  },
  {
    lng: -43.6663575,
    lat: -22.8651414
  },
  {
    lng: -43.6665867,
    lat: -22.8651317
  },
  {
    lng: -43.6668752,
    lat: -22.8651266
  },
  {
    lng: -43.6671082,
    lat: -22.8651266
  },
  {
    lng: -43.6676075,
    lat: -22.8651113
  },
  {
    lng: -43.6680513,
    lat: -22.8650908
  },
  {
    lng: -43.668434,
    lat: -22.8650908
  },
  {
    lng: -43.6688779,
    lat: -22.8651011
  },
  {
    lng: -43.669072,
    lat: -22.8651624
  },
  {
    lng: -43.6692717,
    lat: -22.8651879
  },
  {
    lng: -43.6695325,
    lat: -22.865234
  },
  {
    lng: -43.6698764,
    lat: -22.8652186
  },
  {
    lng: -43.6702266,
    lat: -22.8651499
  },
  {
    lng: -43.6705199,
    lat: -22.8650704
  },
  {
    lng: -43.6707093,
    lat: -22.864984
  },
  {
    lng: -43.6717903,
    lat: -22.8643905
  },
  {
    lng: -43.6726522,
    lat: -22.8638628
  },
  {
    lng: -43.6729049,
    lat: -22.8638129
  },
  {
    lng: -43.6731231,
    lat: -22.8638182
  },
  {
    lng: -43.6733466,
    lat: -22.8638875
  },
  {
    lng: -43.6734277,
    lat: -22.8639519
  },
  {
    lng: -43.67357,
    lat: -22.8640433
  },
  {
    lng: -43.6736665,
    lat: -22.8641916
  },
  {
    lng: -43.6736878,
    lat: -22.8642706
  },
  {
    lng: -43.6737013,
    lat: -22.864372
  },
  {
    lng: -43.6737227,
    lat: -22.8645252
  },
  {
    lng: -43.6737281,
    lat: -22.8647477
  },
  {
    lng: -43.6740984,
    lat: -22.8645649
  },
  {
    lng: -43.6742968,
    lat: -22.8645748
  },
  {
    lng: -43.6747806,
    lat: -22.8647815
  },
  {
    lng: -43.675241,
    lat: -22.8650839
  },
  {
    lng: -43.6753858,
    lat: -22.8652865
  },
  {
    lng: -43.6756201,
    lat: -22.8651532
  },
  {
    lng: -43.6757497,
    lat: -22.8650771
  },
  {
    lng: -43.6759095,
    lat: -22.8650047
  },
  {
    lng: -43.6760386,
    lat: -22.8649672
  },
  {
    lng: -43.6761674,
    lat: -22.8649648
  },
  {
    lng: -43.6762999,
    lat: -22.8649799
  },
  {
    lng: -43.6764282,
    lat: -22.8650266
  },
  {
    lng: -43.6765372,
    lat: -22.8651117
  },
  {
    lng: -43.6767321,
    lat: -22.865324
  },
  {
    lng: -43.6768484,
    lat: -22.8654337
  },
  {
    lng: -43.6769387,
    lat: -22.8655012
  },
  {
    lng: -43.6770407,
    lat: -22.8655266
  },
  {
    lng: -43.6771429,
    lat: -22.8655239
  },
  {
    lng: -43.6772266,
    lat: -22.865493
  },
  {
    lng: -43.677322,
    lat: -22.8654201
  },
  {
    lng: -43.677391,
    lat: -22.8653259
  },
  {
    lng: -43.6774283,
    lat: -22.8651999
  },
  {
    lng: -43.6774428,
    lat: -22.8648701
  },
  {
    lng: -43.6774768,
    lat: -22.8646826
  },
  {
    lng: -43.6775238,
    lat: -22.8645285
  },
  {
    lng: -43.6776089,
    lat: -22.8643467
  },
  {
    lng: -43.6777239,
    lat: -22.8642003
  },
  {
    lng: -43.6778841,
    lat: -22.8640893
  },
  {
    lng: -43.6780437,
    lat: -22.8640274
  },
  {
    lng: -43.6783467,
    lat: -22.864037
  },
  {
    lng: -43.6786194,
    lat: -22.8640322
  },
  {
    lng: -43.6789536,
    lat: -22.8639438
  },
  {
    lng: -43.6791357,
    lat: -22.8639031
  },
  {
    lng: -43.6794319,
    lat: -22.8638214
  },
  {
    lng: -43.6798499,
    lat: -22.8636915
  },
  {
    lng: -43.6800635,
    lat: -22.8636429
  },
  {
    lng: -43.6805168,
    lat: -22.8631239
  },
  {
    lng: -43.6810157,
    lat: -22.8626766
  },
  {
    lng: -43.6819183,
    lat: -22.8633893
  },
  {
    lng: -43.683497,
    lat: -22.8640921
  },
  {
    lng: -43.6848391,
    lat: -22.8645763
  },
  {
    lng: -43.6861406,
    lat: -22.865251
  },
  {
    lng: -43.6875927,
    lat: -22.8656504
  },
  {
    lng: -43.6904868,
    lat: -22.8667459
  },
  {
    lng: -43.691686,
    lat: -22.8647725
  },
  {
    lng: -43.6918608,
    lat: -22.8646612
  },
  {
    lng: -43.6919761,
    lat: -22.8645639
  },
  {
    lng: -43.6921091,
    lat: -22.8644684
  },
  {
    lng: -43.6922491,
    lat: -22.8644101
  },
  {
    lng: -43.6924036,
    lat: -22.864375
  },
  {
    lng: -43.6925527,
    lat: -22.8643272
  },
  {
    lng: -43.6927167,
    lat: -22.8643248
  },
  {
    lng: -43.6928823,
    lat: -22.8643404
  },
  {
    lng: -43.6930476,
    lat: -22.8643799
  },
  {
    lng: -43.6931984,
    lat: -22.8644146
  },
  {
    lng: -43.6933565,
    lat: -22.8644347
  },
  {
    lng: -43.6935152,
    lat: -22.8644673
  },
  {
    lng: -43.6936691,
    lat: -22.8644905
  },
  {
    lng: -43.6938239,
    lat: -22.8645147
  },
  {
    lng: -43.6939728,
    lat: -22.8645437
  },
  {
    lng: -43.6941359,
    lat: -22.8645645
  },
  {
    lng: -43.6943002,
    lat: -22.8645741
  },
  {
    lng: -43.6944532,
    lat: -22.8645827
  },
  {
    lng: -43.6946135,
    lat: -22.8646037
  },
  {
    lng: -43.6947759,
    lat: -22.8646235
  },
  {
    lng: -43.6949419,
    lat: -22.8646421
  },
  {
    lng: -43.6950869,
    lat: -22.8646575
  },
  {
    lng: -43.695254,
    lat: -22.8646705
  },
  {
    lng: -43.6954044,
    lat: -22.8646825
  },
  {
    lng: -43.6955669,
    lat: -22.8647034
  },
  {
    lng: -43.695728,
    lat: -22.8647165
  },
  {
    lng: -43.695881,
    lat: -22.8647342
  },
  {
    lng: -43.6960369,
    lat: -22.8647585
  },
  {
    lng: -43.696185,
    lat: -22.8647626
  },
  {
    lng: -43.6963471,
    lat: -22.8647655
  },
  {
    lng: -43.6965115,
    lat: -22.8647648
  },
  {
    lng: -43.696664,
    lat: -22.8647633
  },
  {
    lng: -43.6968297,
    lat: -22.8647615
  },
  {
    lng: -43.6969846,
    lat: -22.8647588
  },
  {
    lng: -43.6971322,
    lat: -22.8647573
  },
  {
    lng: -43.6972842,
    lat: -22.8647592
  },
  {
    lng: -43.6974377,
    lat: -22.8647816
  },
  {
    lng: -43.6975991,
    lat: -22.8648492
  },
  {
    lng: -43.6977426,
    lat: -22.8648948
  },
  {
    lng: -43.6978868,
    lat: -22.8649228
  },
  {
    lng: -43.6980268,
    lat: -22.8649655
  },
  {
    lng: -43.6981713,
    lat: -22.8650093
  },
  {
    lng: -43.6983219,
    lat: -22.8650563
  },
  {
    lng: -43.6984786,
    lat: -22.8651066
  },
  {
    lng: -43.6986266,
    lat: -22.8651492
  },
  {
    lng: -43.6987897,
    lat: -22.8652006
  },
  {
    lng: -43.6989279,
    lat: -22.8652467
  },
  {
    lng: -43.6990712,
    lat: -22.8652892
  },
  {
    lng: -43.6992387,
    lat: -22.865345
  },
  {
    lng: -43.6993917,
    lat: -22.8653819
  },
  {
    lng: -43.6995295,
    lat: -22.8654349
  },
  {
    lng: -43.699691,
    lat: -22.8654942
  },
  {
    lng: -43.6998437,
    lat: -22.8655455
  },
  {
    lng: -43.7000064,
    lat: -22.8655879
  },
  {
    lng: -43.7001707,
    lat: -22.8656439
  },
  {
    lng: -43.700329,
    lat: -22.8656921
  },
  {
    lng: -43.700465,
    lat: -22.8657495
  },
  {
    lng: -43.7006249,
    lat: -22.8657962
  },
  {
    lng: -43.7007721,
    lat: -22.8658446
  },
  {
    lng: -43.7009268,
    lat: -22.8658996
  },
  {
    lng: -43.7010741,
    lat: -22.8659608
  },
  {
    lng: -43.7011916,
    lat: -22.8660501
  },
  {
    lng: -43.7012901,
    lat: -22.8661596
  },
  {
    lng: -43.7013782,
    lat: -22.8662718
  },
  {
    lng: -43.7014737,
    lat: -22.8663852
  },
  {
    lng: -43.7015601,
    lat: -22.866515
  },
  {
    lng: -43.7016325,
    lat: -22.8666544
  },
  {
    lng: -43.7017155,
    lat: -22.8667819
  },
  {
    lng: -43.7017971,
    lat: -22.8669047
  },
  {
    lng: -43.7019062,
    lat: -22.86702
  },
  {
    lng: -43.7020336,
    lat: -22.8671266
  },
  {
    lng: -43.7021512,
    lat: -22.8672075
  },
  {
    lng: -43.7022766,
    lat: -22.8672898
  },
  {
    lng: -43.702397,
    lat: -22.8673687
  },
  {
    lng: -43.7025249,
    lat: -22.8674499
  },
  {
    lng: -43.7026501,
    lat: -22.867532
  },
  {
    lng: -43.7027971,
    lat: -22.8676161
  },
  {
    lng: -43.7029483,
    lat: -22.8676972
  },
  {
    lng: -43.7030768,
    lat: -22.8677832
  },
  {
    lng: -43.7031943,
    lat: -22.8678652
  },
  {
    lng: -43.7033402,
    lat: -22.8679365
  },
  {
    lng: -43.7034898,
    lat: -22.8679843
  },
  {
    lng: -43.7036686,
    lat: -22.8680287
  },
  {
    lng: -43.7038191,
    lat: -22.8680837
  },
  {
    lng: -43.7039766,
    lat: -22.8681204
  },
  {
    lng: -43.704134,
    lat: -22.8681649
  },
  {
    lng: -43.7042844,
    lat: -22.8681847
  },
  {
    lng: -43.704443,
    lat: -22.8681966
  },
  {
    lng: -43.7045918,
    lat: -22.8682154
  },
  {
    lng: -43.7047608,
    lat: -22.8682181
  },
  {
    lng: -43.7049083,
    lat: -22.8682211
  },
  {
    lng: -43.705068,
    lat: -22.8682218
  },
  {
    lng: -43.7052306,
    lat: -22.8682245
  },
  {
    lng: -43.7053979,
    lat: -22.8682366
  },
  {
    lng: -43.7055567,
    lat: -22.8682792
  },
  {
    lng: -43.7057245,
    lat: -22.8683238
  },
  {
    lng: -43.7058657,
    lat: -22.8683677
  },
  {
    lng: -43.7060031,
    lat: -22.8684172
  },
  {
    lng: -43.7061484,
    lat: -22.8684713
  },
  {
    lng: -43.7062861,
    lat: -22.8685435
  },
  {
    lng: -43.7064216,
    lat: -22.868605
  },
  {
    lng: -43.7065814,
    lat: -22.8686592
  },
  {
    lng: -43.7067419,
    lat: -22.8686654
  },
  {
    lng: -43.7069147,
    lat: -22.868667
  },
  {
    lng: -43.7070801,
    lat: -22.8686586
  },
  {
    lng: -43.7072279,
    lat: -22.8686138
  },
  {
    lng: -43.7073542,
    lat: -22.8685222
  },
  {
    lng: -43.7074653,
    lat: -22.8684227
  },
  {
    lng: -43.7075963,
    lat: -22.8683328
  },
  {
    lng: -43.7077246,
    lat: -22.8682479
  },
  {
    lng: -43.7078457,
    lat: -22.8681675
  },
  {
    lng: -43.707977,
    lat: -22.8680855
  },
  {
    lng: -43.7081124,
    lat: -22.868025
  },
  {
    lng: -43.7082603,
    lat: -22.8679643
  },
  {
    lng: -43.7083878,
    lat: -22.8679205
  },
  {
    lng: -43.7085897,
    lat: -22.8678535
  },
  {
    lng: -43.7087312,
    lat: -22.8678127
  },
  {
    lng: -43.7088788,
    lat: -22.8677525
  },
  {
    lng: -43.7090269,
    lat: -22.8676979
  },
  {
    lng: -43.7091603,
    lat: -22.8676424
  },
  {
    lng: -43.7093106,
    lat: -22.8675844
  },
  {
    lng: -43.7094437,
    lat: -22.8675244
  },
  {
    lng: -43.7095766,
    lat: -22.8674622
  },
  {
    lng: -43.7097098,
    lat: -22.8673874
  },
  {
    lng: -43.7098478,
    lat: -22.8673206
  },
  {
    lng: -43.70999,
    lat: -22.867237
  },
  {
    lng: -43.7101181,
    lat: -22.8671476
  },
  {
    lng: -43.7102433,
    lat: -22.8670714
  },
  {
    lng: -43.7103771,
    lat: -22.8670009
  },
  {
    lng: -43.7105135,
    lat: -22.8669404
  },
  {
    lng: -43.7106671,
    lat: -22.8668833
  },
  {
    lng: -43.7108152,
    lat: -22.8668384
  },
  {
    lng: -43.7109683,
    lat: -22.866821
  },
  {
    lng: -43.7111186,
    lat: -22.8668024
  },
  {
    lng: -43.7112815,
    lat: -22.8667996
  },
  {
    lng: -43.7114323,
    lat: -22.8668014
  },
  {
    lng: -43.7115872,
    lat: -22.8668215
  },
  {
    lng: -43.7117375,
    lat: -22.8668563
  },
  {
    lng: -43.7118778,
    lat: -22.8669047
  },
  {
    lng: -43.7120401,
    lat: -22.8669643
  },
  {
    lng: -43.7121719,
    lat: -22.8670287
  },
  {
    lng: -43.7123012,
    lat: -22.8670965
  },
  {
    lng: -43.7124326,
    lat: -22.8671761
  },
  {
    lng: -43.7125457,
    lat: -22.8672852
  },
  {
    lng: -43.7126702,
    lat: -22.8674023
  },
  {
    lng: -43.7127935,
    lat: -22.8675084
  },
  {
    lng: -43.7129184,
    lat: -22.8676282
  },
  {
    lng: -43.7130186,
    lat: -22.8677278
  },
  {
    lng: -43.7131245,
    lat: -22.8678349
  },
  {
    lng: -43.7132367,
    lat: -22.8679298
  },
  {
    lng: -43.7133422,
    lat: -22.8680432
  },
  {
    lng: -43.7134484,
    lat: -22.8681527
  },
  {
    lng: -43.7135468,
    lat: -22.8682564
  },
  {
    lng: -43.7136767,
    lat: -22.8683606
  },
  {
    lng: -43.7138033,
    lat: -22.8684507
  },
  {
    lng: -43.71397,
    lat: -22.8686177
  },
  {
    lng: -43.7142341,
    lat: -22.868878
  },
  {
    lng: -43.7145034,
    lat: -22.8692112
  },
  {
    lng: -43.7148803,
    lat: -22.8697081
  },
  {
    lng: -43.7152872,
    lat: -22.8702276
  },
  {
    lng: -43.7156381,
    lat: -22.870744
  },
  {
    lng: -43.7156547,
    lat: -22.8708335
  },
  {
    lng: -43.7156474,
    lat: -22.8709528
  },
  {
    lng: -43.7156488,
    lat: -22.8710926
  },
  {
    lng: -43.7156484,
    lat: -22.8711522
  },
  {
    lng: -43.7156585,
    lat: -22.8711915
  },
  {
    lng: -43.7157837,
    lat: -22.8712523
  },
  {
    lng: -43.7159901,
    lat: -22.8713767
  },
  {
    lng: -43.7163582,
    lat: -22.8716441
  },
  {
    lng: -43.7167285,
    lat: -22.8719068
  },
  {
    lng: -43.7170546,
    lat: -22.8721316
  },
  {
    lng: -43.717252,
    lat: -22.8722703
  },
  {
    lng: -43.7173304,
    lat: -22.8723369
  },
  {
    lng: -43.717497,
    lat: -22.8724344
  },
  {
    lng: -43.7176882,
    lat: -22.8725448
  },
  {
    lng: -43.717891,
    lat: -22.8726315
  },
  {
    lng: -43.718074,
    lat: -22.8726835
  },
  {
    lng: -43.718316,
    lat: -22.8727227
  },
  {
    lng: -43.7185914,
    lat: -22.8727196
  },
  {
    lng: -43.7188066,
    lat: -22.8727027
  },
  {
    lng: -43.7190968,
    lat: -22.8726545
  },
  {
    lng: -43.7194486,
    lat: -22.8725936
  },
  {
    lng: -43.7198365,
    lat: -22.872517
  },
  {
    lng: -43.7202991,
    lat: -22.8724357
  },
  {
    lng: -43.7206166,
    lat: -22.8723664
  },
  {
    lng: -43.7208751,
    lat: -22.8723207
  },
  {
    lng: -43.7211278,
    lat: -22.8722908
  },
  {
    lng: -43.7212943,
    lat: -22.8722683
  },
  {
    lng: -43.7214521,
    lat: -22.8722642
  },
  {
    lng: -43.7217,
    lat: -22.8722875
  },
  {
    lng: -43.7219321,
    lat: -22.8723106
  },
  {
    lng: -43.7222212,
    lat: -22.8723687
  },
  {
    lng: -43.72245,
    lat: -22.8724423
  },
  {
    lng: -43.7226743,
    lat: -22.8725318
  },
  {
    lng: -43.7228087,
    lat: -22.8725755
  },
  {
    lng: -43.7229487,
    lat: -22.8726244
  },
  {
    lng: -43.723192,
    lat: -22.8726768
  },
  {
    lng: -43.7233266,
    lat: -22.8726885
  },
  {
    lng: -43.7234356,
    lat: -22.8727001
  },
  {
    lng: -43.7236277,
    lat: -22.8726989
  },
  {
    lng: -43.7238316,
    lat: -22.8726767
  },
  {
    lng: -43.7240054,
    lat: -22.8726435
  },
  {
    lng: -43.7241376,
    lat: -22.872618
  },
  {
    lng: -43.7242582,
    lat: -22.8726004
  },
  {
    lng: -43.7244448,
    lat: -22.8725753
  },
  {
    lng: -43.724683,
    lat: -22.8725559
  },
  {
    lng: -43.7249096,
    lat: -22.872563
  },
  {
    lng: -43.725153,
    lat: -22.8725996
  },
  {
    lng: -43.725518,
    lat: -22.8726796
  },
  {
    lng: -43.7261416,
    lat: -22.8728467
  },
  {
    lng: -43.7265719,
    lat: -22.872979
  },
  {
    lng: -43.7268695,
    lat: -22.8730664
  },
  {
    lng: -43.7271262,
    lat: -22.8731503
  },
  {
    lng: -43.7273567,
    lat: -22.8732484
  },
  {
    lng: -43.7275422,
    lat: -22.8733509
  },
  {
    lng: -43.7279779,
    lat: -22.8736852
  },
  {
    lng: -43.728216,
    lat: -22.87386
  },
  {
    lng: -43.7287322,
    lat: -22.8742572
  },
  {
    lng: -43.7295578,
    lat: -22.8748967
  },
  {
    lng: -43.730439,
    lat: -22.8755544
  },
  {
    lng: -43.7320668,
    lat: -22.8767891
  },
  {
    lng: -43.7325089,
    lat: -22.8771163
  },
  {
    lng: -43.7332939,
    lat: -22.8776814
  },
  {
    lng: -43.7335106,
    lat: -22.8778545
  },
  {
    lng: -43.7335683,
    lat: -22.8779051
  },
  {
    lng: -43.7336221,
    lat: -22.8779363
  },
  {
    lng: -43.7337546,
    lat: -22.878003
  },
  {
    lng: -43.7342676,
    lat: -22.8782961
  },
  {
    lng: -43.7343958,
    lat: -22.8783704
  },
  {
    lng: -43.7344663,
    lat: -22.878394
  },
  {
    lng: -43.7345619,
    lat: -22.8784025
  },
  {
    lng: -43.7346535,
    lat: -22.878384
  },
  {
    lng: -43.7348203,
    lat: -22.878339
  },
  {
    lng: -43.7349244,
    lat: -22.8783283
  },
  {
    lng: -43.7351391,
    lat: -22.8783514
  },
  {
    lng: -43.7377568,
    lat: -22.8777842
  },
  {
    lng: -43.739214,
    lat: -22.8774901
  },
  {
    lng: -43.7401134,
    lat: -22.8773239
  },
  {
    lng: -43.7408739,
    lat: -22.8771807
  },
  {
    lng: -43.7413874,
    lat: -22.8771389
  },
  {
    lng: -43.742109,
    lat: -22.8771909
  },
  {
    lng: -43.744238,
    lat: -22.8773315
  },
  {
    lng: -43.7447653,
    lat: -22.8773622
  },
  {
    lng: -43.7451928,
    lat: -22.8774262
  },
  {
    lng: -43.7455509,
    lat: -22.8774645
  },
  {
    lng: -43.7457091,
    lat: -22.8775054
  },
  {
    lng: -43.7458978,
    lat: -22.8774799
  },
  {
    lng: -43.7460921,
    lat: -22.877462
  },
  {
    lng: -43.7464724,
    lat: -22.8775463
  },
  {
    lng: -43.7468859,
    lat: -22.8777381
  },
  {
    lng: -43.7473356,
    lat: -22.8780169
  },
  {
    lng: -43.7475493,
    lat: -22.8781703
  },
  {
    lng: -43.7476826,
    lat: -22.8782266
  },
  {
    lng: -43.7477686,
    lat: -22.8782061
  },
  {
    lng: -43.7478796,
    lat: -22.8780041
  },
  {
    lng: -43.7480045,
    lat: -22.8779223
  },
  {
    lng: -43.7481072,
    lat: -22.8779351
  },
  {
    lng: -43.7483376,
    lat: -22.8780834
  },
  {
    lng: -43.7485708,
    lat: -22.8782164
  },
  {
    lng: -43.748754,
    lat: -22.878288
  },
  {
    lng: -43.748976,
    lat: -22.8783059
  },
  {
    lng: -43.7491277,
    lat: -22.8783241
  },
  {
    lng: -43.7493507,
    lat: -22.878571
  },
  {
    lng: -43.7499371,
    lat: -22.8789604
  },
  {
    lng: -43.7516427,
    lat: -22.8798605
  },
  {
    lng: -43.7520058,
    lat: -22.8801562
  },
  {
    lng: -43.7521335,
    lat: -22.8803966
  },
  {
    lng: -43.7522778,
    lat: -22.8808365
  },
  {
    lng: -43.7522334,
    lat: -22.8810973
  },
  {
    lng: -43.752078,
    lat: -22.8814195
  },
  {
    lng: -43.751917,
    lat: -22.8821458
  },
  {
    lng: -43.7516937,
    lat: -22.8832894
  },
  {
    lng: -43.7515006,
    lat: -22.8842785
  },
  {
    lng: -43.7509843,
    lat: -22.8863396
  },
  {
    lng: -43.7507425,
    lat: -22.8873368
  },
  {
    lng: -43.7506915,
    lat: -22.8876985
  },
  {
    lng: -43.750715,
    lat: -22.8878865
  },
  {
    lng: -43.7508603,
    lat: -22.888165
  },
  {
    lng: -43.7514922,
    lat: -22.8886785
  },
  {
    lng: -43.7519437,
    lat: -22.8888304
  },
  {
    lng: -43.7532547,
    lat: -22.8891269
  },
  {
    lng: -43.7535413,
    lat: -22.8891812
  },
  {
    lng: -43.7540908,
    lat: -22.8894994
  },
  {
    lng: -43.754558,
    lat: -22.8896006
  },
  {
    lng: -43.755555,
    lat: -22.8898899
  },
  {
    lng: -43.7560535,
    lat: -22.8902371
  },
  {
    lng: -43.7571722,
    lat: -22.8907412
  },
  {
    lng: -43.7572929,
    lat: -22.8907308
  },
  {
    lng: -43.7578404,
    lat: -22.890477
  },
  {
    lng: -43.7583815,
    lat: -22.8902672
  },
  {
    lng: -43.7588319,
    lat: -22.8902542
  },
  {
    lng: -43.7591486,
    lat: -22.8902896
  },
  {
    lng: -43.7597274,
    lat: -22.8904863
  },
  {
    lng: -43.760135,
    lat: -22.8906267
  },
  {
    lng: -43.7607268,
    lat: -22.8907027
  },
  {
    lng: -43.7611951,
    lat: -22.8906844
  },
  {
    lng: -43.7616605,
    lat: -22.8906441
  },
  {
    lng: -43.7622888,
    lat: -22.890627
  },
  {
    lng: -43.7626972,
    lat: -22.8906741
  },
  {
    lng: -43.7629804,
    lat: -22.8907971
  },
  {
    lng: -43.7631564,
    lat: -22.8909906
  },
  {
    lng: -43.7633783,
    lat: -22.8913546
  },
  {
    lng: -43.7634683,
    lat: -22.8915557
  },
  {
    lng: -43.76355,
    lat: -22.8916936
  },
  {
    lng: -43.7637918,
    lat: -22.8918162
  },
  {
    lng: -43.7641403,
    lat: -22.8919342
  },
  {
    lng: -43.7644237,
    lat: -22.8920517
  },
  {
    lng: -43.7645532,
    lat: -22.8921461
  },
  {
    lng: -43.7645935,
    lat: -22.8922671
  },
  {
    lng: -43.7646439,
    lat: -22.8925914
  },
  {
    lng: -43.7647512,
    lat: -22.8931852
  },
  {
    lng: -43.7648555,
    lat: -22.8934357
  },
  {
    lng: -43.7650594,
    lat: -22.8938216
  },
  {
    lng: -43.7651939,
    lat: -22.8940203
  },
  {
    lng: -43.7654058,
    lat: -22.8941702
  },
  {
    lng: -43.7658657,
    lat: -22.8944263
  },
  {
    lng: -43.7660219,
    lat: -22.8945181
  },
  {
    lng: -43.7661918,
    lat: -22.8947335
  },
  {
    lng: -43.7664745,
    lat: -22.8949251
  },
  {
    lng: -43.7671487,
    lat: -22.8950621
  },
  {
    lng: -43.7679425,
    lat: -22.8950792
  },
  {
    lng: -43.7686545,
    lat: -22.8949749
  },
  {
    lng: -43.7689038,
    lat: -22.8949329
  },
  {
    lng: -43.7690378,
    lat: -22.8948488
  },
  {
    lng: -43.7691216,
    lat: -22.8947617
  },
  {
    lng: -43.7691584,
    lat: -22.8946247
  },
  {
    lng: -43.769184,
    lat: -22.8944053
  },
  {
    lng: -43.7691746,
    lat: -22.8941472
  },
  {
    lng: -43.7691761,
    lat: -22.893977
  },
  {
    lng: -43.7691897,
    lat: -22.8937795
  },
  {
    lng: -43.7692036,
    lat: -22.8935601
  },
  {
    lng: -43.7692525,
    lat: -22.8933821
  },
  {
    lng: -43.769425,
    lat: -22.8932773
  },
  {
    lng: -43.7695758,
    lat: -22.8932818
  },
  {
    lng: -43.7697448,
    lat: -22.8933245
  },
  {
    lng: -43.7698623,
    lat: -22.8934206
  },
  {
    lng: -43.7699973,
    lat: -22.8934949
  },
  {
    lng: -43.7701482,
    lat: -22.8935005
  },
  {
    lng: -43.7703008,
    lat: -22.893493
  },
  {
    lng: -43.7704814,
    lat: -22.8934739
  },
  {
    lng: -43.7706357,
    lat: -22.8934565
  },
  {
    lng: -43.7707874,
    lat: -22.8934218
  },
  {
    lng: -43.7709416,
    lat: -22.8933888
  },
  {
    lng: -43.7711042,
    lat: -22.8933463
  },
  {
    lng: -43.7712536,
    lat: -22.8933142
  },
  {
    lng: -43.7714015,
    lat: -22.8932832
  },
  {
    lng: -43.7715595,
    lat: -22.893247
  },
  {
    lng: -43.7717188,
    lat: -22.8932086
  },
  {
    lng: -43.771867,
    lat: -22.8931629
  },
  {
    lng: -43.7720116,
    lat: -22.8931003
  },
  {
    lng: -43.772157,
    lat: -22.8930521
  },
  {
    lng: -43.7723009,
    lat: -22.8929913
  },
  {
    lng: -43.7724219,
    lat: -22.8929115
  },
  {
    lng: -43.7725466,
    lat: -22.8928264
  },
  {
    lng: -43.7726836,
    lat: -22.8927444
  },
  {
    lng: -43.7728175,
    lat: -22.8926644
  },
  {
    lng: -43.7729518,
    lat: -22.8925919
  },
  {
    lng: -43.7731026,
    lat: -22.892557
  },
  {
    lng: -43.7732503,
    lat: -22.8925518
  },
  {
    lng: -43.7734018,
    lat: -22.8925754
  },
  {
    lng: -43.7735572,
    lat: -22.8926067
  },
  {
    lng: -43.7737076,
    lat: -22.8926453
  },
  {
    lng: -43.7738536,
    lat: -22.8926652
  },
  {
    lng: -43.7739993,
    lat: -22.8926787
  },
  {
    lng: -43.7741441,
    lat: -22.8927031
  },
  {
    lng: -43.7742891,
    lat: -22.8927269
  },
  {
    lng: -43.7744354,
    lat: -22.8927431
  },
  {
    lng: -43.7745517,
    lat: -22.8927477
  },
  {
    lng: -43.7746256,
    lat: -22.8927429
  },
  {
    lng: -43.774693,
    lat: -22.8933857
  },
  {
    lng: -43.7750149,
    lat: -22.8951214
  },
  {
    lng: -43.7751227,
    lat: -22.8957096
  },
  {
    lng: -43.775676,
    lat: -22.895859
  },
  {
    lng: -43.7758249,
    lat: -22.8958942
  },
  {
    lng: -43.7760316,
    lat: -22.8959214
  },
  {
    lng: -43.7762144,
    lat: -22.8959457
  },
  {
    lng: -43.7763786,
    lat: -22.8959671
  },
  {
    lng: -43.7765375,
    lat: -22.8959837
  },
  {
    lng: -43.7767164,
    lat: -22.8960005
  },
  {
    lng: -43.77689,
    lat: -22.8960212
  },
  {
    lng: -43.7770432,
    lat: -22.8960455
  },
  {
    lng: -43.7773498,
    lat: -22.8960994
  },
  {
    lng: -43.7774989,
    lat: -22.8961261
  },
  {
    lng: -43.7776739,
    lat: -22.8961525
  },
  {
    lng: -43.7778324,
    lat: -22.8961812
  },
  {
    lng: -43.7779942,
    lat: -22.8962121
  },
  {
    lng: -43.7781598,
    lat: -22.8962325
  },
  {
    lng: -43.7783302,
    lat: -22.896237
  },
  {
    lng: -43.7785049,
    lat: -22.8961915
  },
  {
    lng: -43.7786155,
    lat: -22.8961024
  },
  {
    lng: -43.7786746,
    lat: -22.895963
  },
  {
    lng: -43.7787091,
    lat: -22.895826
  },
  {
    lng: -43.7787335,
    lat: -22.8956886
  },
  {
    lng: -43.7787605,
    lat: -22.8955336
  },
  {
    lng: -43.7787936,
    lat: -22.8953647
  },
  {
    lng: -43.7788549,
    lat: -22.8952104
  },
  {
    lng: -43.7789274,
    lat: -22.8950763
  },
  {
    lng: -43.779022,
    lat: -22.8949592
  },
  {
    lng: -43.7791373,
    lat: -22.8948548
  },
  {
    lng: -43.7792845,
    lat: -22.8947866
  },
  {
    lng: -43.7794456,
    lat: -22.8947602
  },
  {
    lng: -43.7796001,
    lat: -22.8947354
  },
  {
    lng: -43.779779,
    lat: -22.8947266
  },
  {
    lng: -43.779946,
    lat: -22.8947178
  },
  {
    lng: -43.7801049,
    lat: -22.894721
  },
  {
    lng: -43.7802664,
    lat: -22.8947567
  },
  {
    lng: -43.7804094,
    lat: -22.8948441
  },
  {
    lng: -43.7805157,
    lat: -22.8949533
  },
  {
    lng: -43.7806153,
    lat: -22.8950607
  },
  {
    lng: -43.7807104,
    lat: -22.8951767
  },
  {
    lng: -43.7807826,
    lat: -22.8952995
  },
  {
    lng: -43.7808575,
    lat: -22.8954367
  },
  {
    lng: -43.7809205,
    lat: -22.8955607
  },
  {
    lng: -43.7809783,
    lat: -22.895688
  },
  {
    lng: -43.7810319,
    lat: -22.8958408
  },
  {
    lng: -43.7810617,
    lat: -22.8959823
  },
  {
    lng: -43.7811143,
    lat: -22.8961089
  },
  {
    lng: -43.7811925,
    lat: -22.8962351
  },
  {
    lng: -43.7812735,
    lat: -22.8963735
  },
  {
    lng: -43.7813641,
    lat: -22.8964889
  },
  {
    lng: -43.7814659,
    lat: -22.896589
  },
  {
    lng: -43.7815733,
    lat: -22.8966853
  },
  {
    lng: -43.7816543,
    lat: -22.896805
  },
  {
    lng: -43.7816733,
    lat: -22.8969503
  },
  {
    lng: -43.7816898,
    lat: -22.8972509
  },
  {
    lng: -43.7817222,
    lat: -22.8973838
  },
  {
    lng: -43.7817623,
    lat: -22.8975346
  },
  {
    lng: -43.7817621,
    lat: -22.8976712
  },
  {
    lng: -43.7817382,
    lat: -22.8978207
  },
  {
    lng: -43.7817033,
    lat: -22.8979695
  },
  {
    lng: -43.7816567,
    lat: -22.8981025
  },
  {
    lng: -43.7816227,
    lat: -22.8982364
  },
  {
    lng: -43.7816022,
    lat: -22.8983802
  },
  {
    lng: -43.7815502,
    lat: -22.89852
  },
  {
    lng: -43.7814869,
    lat: -22.8986592
  },
  {
    lng: -43.7814498,
    lat: -22.8987977
  },
  {
    lng: -43.7809912,
    lat: -22.8994446
  },
  {
    lng: -43.7832679,
    lat: -22.9032052
  },
  {
    lng: -43.783466,
    lat: -22.9035455
  },
  {
    lng: -43.7836773,
    lat: -22.9037012
  },
  {
    lng: -43.7839813,
    lat: -22.9038298
  },
  {
    lng: -43.7842765,
    lat: -22.9039583
  },
  {
    lng: -43.7844421,
    lat: -22.9039981
  },
  {
    lng: -43.7847736,
    lat: -22.9040335
  },
  {
    lng: -43.7850699,
    lat: -22.9040357
  },
  {
    lng: -43.7852182,
    lat: -22.9040149
  },
  {
    lng: -43.7853374,
    lat: -22.903939
  },
  {
    lng: -43.7854629,
    lat: -22.9038192
  },
  {
    lng: -43.785553,
    lat: -22.9036827
  },
  {
    lng: -43.7856193,
    lat: -22.9035623
  },
  {
    lng: -43.78568,
    lat: -22.9033871
  },
  {
    lng: -43.7857057,
    lat: -22.9031788
  },
  {
    lng: -43.785736,
    lat: -22.9024324
  },
  {
    lng: -43.7857671,
    lat: -22.9022652
  },
  {
    lng: -43.785816,
    lat: -22.9020954
  },
  {
    lng: -43.7859246,
    lat: -22.9018766
  },
  {
    lng: -43.7860386,
    lat: -22.9017238
  },
  {
    lng: -43.7862479,
    lat: -22.9015003
  },
  {
    lng: -43.7865643,
    lat: -22.9012337
  },
  {
    lng: -43.7868089,
    lat: -22.9010489
  },
  {
    lng: -43.7871868,
    lat: -22.9008734
  },
  {
    lng: -43.7875316,
    lat: -22.9007498
  },
  {
    lng: -43.7878166,
    lat: -22.900686
  },
  {
    lng: -43.7880181,
    lat: -22.9006766
  },
  {
    lng: -43.7883026,
    lat: -22.9006788
  },
  {
    lng: -43.7885628,
    lat: -22.9007356
  },
  {
    lng: -43.7888162,
    lat: -22.9008858
  },
  {
    lng: -43.7890163,
    lat: -22.901041
  },
  {
    lng: -43.78911,
    lat: -22.9011626
  },
  {
    lng: -43.7892263,
    lat: -22.9014159
  },
  {
    lng: -43.78936,
    lat: -22.9017079
  },
  {
    lng: -43.7894735,
    lat: -22.9019421
  },
  {
    lng: -43.7895549,
    lat: -22.9021184
  },
  {
    lng: -43.7895948,
    lat: -22.9022999
  },
  {
    lng: -43.7896165,
    lat: -22.9025251
  },
  {
    lng: -43.7896443,
    lat: -22.9027284
  },
  {
    lng: -43.7896902,
    lat: -22.902899
  },
  {
    lng: -43.7897834,
    lat: -22.9030754
  },
  {
    lng: -43.7899363,
    lat: -22.9032082
  },
  {
    lng: -43.7901132,
    lat: -22.9033084
  },
  {
    lng: -43.7902962,
    lat: -22.9033867
  },
  {
    lng: -43.7904441,
    lat: -22.9034152
  },
  {
    lng: -43.7906929,
    lat: -22.9034171
  },
  {
    lng: -43.7909663,
    lat: -22.9033259
  },
  {
    lng: -43.7913647,
    lat: -22.9031752
  },
  {
    lng: -43.7917697,
    lat: -22.9029477
  },
  {
    lng: -43.7919092,
    lat: -22.9029159
  },
  {
    lng: -43.792176,
    lat: -22.902896
  },
  {
    lng: -43.792644,
    lat: -22.902916
  },
  {
    lng: -43.7930408,
    lat: -22.9029409
  },
  {
    lng: -43.7933602,
    lat: -22.9030038
  },
  {
    lng: -43.7937447,
    lat: -22.9030836
  },
  {
    lng: -43.7942232,
    lat: -22.9032463
  },
  {
    lng: -43.7945154,
    lat: -22.9033748
  },
  {
    lng: -43.7947097,
    lat: -22.9035136
  },
  {
    lng: -43.7948801,
    lat: -22.903674
  },
  {
    lng: -43.7950679,
    lat: -22.9038813
  },
  {
    lng: -43.7951614,
    lat: -22.9040248
  },
  {
    lng: -43.7952774,
    lat: -22.9043166
  },
  {
    lng: -43.7953409,
    lat: -22.9045147
  },
  {
    lng: -43.7954321,
    lat: -22.9049161
  },
  {
    lng: -43.7954847,
    lat: -22.9053124
  },
  {
    lng: -43.7953626,
    lat: -22.9067135
  },
  {
    lng: -43.7949555,
    lat: -22.9075989
  },
  {
    lng: -43.7947928,
    lat: -22.9077955
  },
  {
    lng: -43.7945236,
    lat: -22.9079545
  },
  {
    lng: -43.7941563,
    lat: -22.9080123
  },
  {
    lng: -43.7937861,
    lat: -22.9080021
  },
  {
    lng: -43.79307,
    lat: -22.9078953
  },
  {
    lng: -43.7927555,
    lat: -22.9079216
  },
  {
    lng: -43.7924133,
    lat: -22.9081016
  },
  {
    lng: -43.7921907,
    lat: -22.9083426
  },
  {
    lng: -43.7920317,
    lat: -22.9086312
  },
  {
    lng: -43.7919621,
    lat: -22.9088959
  },
  {
    lng: -43.7919763,
    lat: -22.9091884
  },
  {
    lng: -43.7921317,
    lat: -22.9096077
  },
  {
    lng: -43.7921761,
    lat: -22.9099554
  },
  {
    lng: -43.7921983,
    lat: -22.9103645
  },
  {
    lng: -43.792054,
    lat: -22.9107122
  },
  {
    lng: -43.7918208,
    lat: -22.9111213
  },
  {
    lng: -43.791621,
    lat: -22.9113565
  },
  {
    lng: -43.7913323,
    lat: -22.911694
  },
  {
    lng: -43.7910769,
    lat: -22.9123587
  },
  {
    lng: -43.7909659,
    lat: -22.9130541
  },
  {
    lng: -43.790977,
    lat: -22.9137393
  },
  {
    lng: -43.790836,
    lat: -22.9144574
  },
  {
    lng: -43.7914876,
    lat: -22.9146888
  },
  {
    lng: -43.7956815,
    lat: -22.9167565
  }
];

export default polygonCoordsRio31;