import React, { Component } from 'react';
import api from '../services/api';
import apiString from '../services/apiString';
import apiStringServer from '../services/apiStringServer';
import apiCEP from '../services/apiCEP';

import Header from '../components/Header';
import FooterHome from '../components/FooterHome';

import polygonMendes from './utils/polygonMendes';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Next from '@material-ui/icons/SkipNext';
import Done from '@material-ui/icons/Done';
import Back from '@material-ui/icons/ArrowBack';
import Add from '@material-ui/icons/Add';
import Refresh from '@material-ui/icons/Refresh';
import IconButton from '@material-ui/core/IconButton';
import GpsFixed from '@material-ui/icons/GpsFixed';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import Divider from '@material-ui/core/Divider';
import theme from '../themes/theme';
import { ThemeProvider } from '@material-ui/core';

import InputMask from 'react-input-mask';

import polygonCoords_SJMeriti from './utils/polygonSJMeriti';

import polygonCoords_Rio from './utils/polygonRio';
import polygonCoords_Rio1 from './utils/polygonRio1';
import polygonCoords_Rio2 from './utils/polygonRio2';
import polygonCoords_Rio21 from './utils/polygonRio21';
import polygonCoords_Rio3 from './utils/polygonRio3';
import polygonCoords_Rio31 from './utils/polygonRio31';

import { Map, GoogleApiWrapper, Marker, Polygon } from 'google-maps-react';
import Geocode from "react-geocode";
import { getAreaOfPolygon } from 'geolib';
import { View } from '@react-pdf/renderer';
Geocode.setApiKey("AIzaSyDC6df7s7nceeG2sHhFGu0-nzLppDiHpe0");
Geocode.setLanguage("pt-BR");

let polygonCoords = [
  {lat: -22.990737, lng: -43.420790},
  {lat: -22.991277, lng: -43.423958}
];

export class AddArea extends Component {

  state = {
    login: this.props.location.state.fields.login,
    option: this.props.location.state.fields.option,
    user_type: this.props.location.state.fields.user_type,
    user_id: this.props.location.state.fields.user_id,
    selected_area: this.props.location.state.fields.selected_area,
    selected_city: this.props.location.state.fields.selected_city,

    zoom: 13,
    latitude: -22.5242815,
    longitude: -43.7298658,
    activeMarker: {},
    selectedPlace: {},

    places: [],
    address: '',
    complete_address: '',
    polygon: [],
    finalPolygon: [],
    area: '',
    saveAreaBt: true,

    cepMask: '99999-999',
    area_name: '',
    cep: '',
    street: '',
    number: '',
    district: '',
    city: this.props.location.state.fields.selected_city,
    state: '',
    description: '',

    steps: ['Busca e área', 'Detalhes', 'Confirmação'],
    activeStep: 0,
    completed: {},

    adm_areas: [],
    comunities: [],
    completed_comunities: [],

    polygonCoords_RioGeral: [],

    disabled: true,
    open: false,
    open_error: false,
    open_error2: false,
    open_error3: false,
    msg: '',
  }

  async componentDidMount() {

    if (this.props.location.state) {
      await this.setState({ login: this.props.location.state.fields.login });
      await this.setState({ option: this.props.location.state.fields.option });
      await this.setState({ user_type: this.props.location.state.fields.user_type });
      await this.setState({ selected_area: this.props.location.state.fields.selected_area });
      await this.setState({ selected_city: this.props.location.state.fields.selected_city });
    }

    this.state.polygonCoords_RioGeral = polygonCoords_Rio.concat(polygonCoords_Rio1);
    this.state.polygonCoords_RioGeral = this.state.polygonCoords_RioGeral.concat(polygonCoords_Rio2);
    this.state.polygonCoords_RioGeral = this.state.polygonCoords_RioGeral.concat(polygonCoords_Rio21);
    this.state.polygonCoords_RioGeral = this.state.polygonCoords_RioGeral.concat(polygonCoords_Rio3);
    this.state.polygonCoords_RioGeral = this.state.polygonCoords_RioGeral.concat(polygonCoords_Rio31);

    if (this.state.selected_city.includes('buzios')) {
      await this.setState({ latitude: -22.774660 });
      await this.setState({ longitude: -41.917836 });
    } else {
      if (this.state.selected_city.includes('cabo')) {
        await this.setState({ latitude: -22.755854 });
        await this.setState({ longitude: -42.012410 });
        await this.setState({ zoom: 11 });
      } else {
        if (this.state.selected_city.includes('arraial')) {
          await this.setState({ latitude: -22.933008 });
          await this.setState({ longitude: -42.130081 });
          await this.setState({ zoom: 12 });
        } else {
          if (this.state.selected_city.includes('queimados')) {
            await this.setState({ latitude: -22.727356 });
            await this.setState({ longitude: -43.585626 });
            await this.setState({ zoom: 13 });
          } else {
            if (this.state.selected_city.includes('rio')) {
              await this.setState({ latitude: -22.931987594567158 });
              await this.setState({ longitude: -43.44911393720455 });
              await this.setState({ zoom: 11 });
            } else {
              if (this.state.selected_city.includes('sjmeriti')) {
                await this.setState({ latitude: -22.78631 });
                await this.setState({ longitude: -43.3601559 });
                await this.setState({ zoom: 14 });
              }
            }
          }
        }
      }
    }

    try {
      let resp = await api.get('/user', {
        params: {
          cpf_cnpj: this.state.login,
          selected_city: this.state.selected_city
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('name')) {

            this.setState({ user_id: [resp.data][0].id });

          } else {
            let msg = JSON.stringify(resp.data);
            console.log(msg);
          }
        }
      }
    } catch (err) {
      console.log('Não foi possível buscar os dados do usuário. ' + err);
    }

    this.handleAdmAreas();
    await this.getComunities();

  }

  getComunities = async () => {

    try {
      let resp = await api.get('/comunities', {
        params: {
          selected_city: `municipios_${this.state.selected_city}`
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('comunidade')) {

            await this.setState({ comunities: resp.data });

            let all_comunities = [];
            this.state.comunities.map((item) =>
              all_comunities.push(item.polygon)
            );

            //formatar os polígonos das comunidades da cidade para serem exibidos no mapa com o componente Polygon, usando o exemplo da formatação presente no arquivo polygonSJMeriti.js, lembrando que na formatação de all_comunities, cada item é uma string que segue um padrão como neste exemplo de dois itens: [[-43.25148546299994,-22.994067564999966],[-43.251536560999966,-22.994302722999976]] e [[-43.251536560999966,-22.994302722999976],[-43.25148546299994,-22.994067564999966]].
            let polygons = [];
            all_comunities.map((item) => {
              let polygon = [];
              let coords = item.split('],[');
              coords.map((coord) => {
                let coord1 = coord.replace('[[', '').replace(']]', '').replace('[', '').replace(']', '');
                let lat = parseFloat(coord1.split(',')[1]);
                let lng = parseFloat(coord1.split(',')[0]);
                polygon.push({ lat: lat, lng: lng });
              });
              polygons.push(polygon);
            });

            await this.setState({ completed_comunities: polygons });
            
            //alert(JSON.stringify(this.state.completed_comunities));

          } else {
            let msg = JSON.stringify(resp.data);
            console.log(msg)
          }
        }
      }
    } catch (err) {
      console.log(err);
    }

  }

  handleAdmAreas = async () => {

    try {
      let resp = await api.get('/admareas', {
        params: {
          cpf_cnpj: this.state.login,
          selected_city: this.state.selected_city
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('name')) {

            let polygonStr = '';
          
            for(let i = 0; i < resp.data.length; i++) {
              let polygon = resp.data[i].polygon;
              let area = resp.data[i].area;
              let area_name = resp.data[i].area_name;
              let description = resp.data[i].description;
              let latitude = resp.data[i].latitude;
              let longitude = resp.data[i].longitude;
              polygon = polygon.split('"').join('');
              polygon = polygon.split('[').join('');
              polygon = polygon.split('lat').join('"lat"');
              polygon = polygon.split('lng').join('"lng"');
              polygonStr = polygonStr + `{"polygon":[${polygon}],
              "area":${area},
              "area_name":"${area_name}",
              "description":"${description}",
              "latitude":${latitude},
              "longitude":${longitude}},`;
            }

            polygonStr = `[${polygonStr}]`;
            polygonStr = polygonStr.split(',]').join(']');

            let polygonJson = JSON.parse(polygonStr)

            await this.setState({ adm_areas: polygonJson });

          } else {
            let msg = JSON.stringify(resp.data);
            //alert(msg); 
          }
        }
      }
    } catch (err) {
      alert('Não foi possível buscar os dados do usuário. ' + err);
    }

  }

  handleChangeAdress = async () => {

    await Geocode.fromAddress(this.state.address).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        this.setState({ latitude: lat, longitude: lng });
        this.setState({ open_error: false });
        this.setState({ zoom: 19 })
      },
      (error) => {
        this.setState({ msg: 'Não foi possível encontrar este endereço.' });
        this.setState({ open_error: true });
      }
    );

    try {
      let resp = await apiCEP.get(`/ws/${this.state.address}/json/`);
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('cep')) {
            this.setState({ address: `${resp.data.logradouro} ${resp.data.bairro}` });
          } else {
            this.setState({ msg: "CEP não encontrado." });
            this.setState({ open_error: true });
          }
        }
      }
    } catch (err) {
      this.setState({ address: this.state.address });
    }

  }

  handleCEP = async () => {

    try{
      let resp = await apiCEP.get(`/ws/${this.state.cep}/json/`);
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('cep')) {
            this.setState({ street: resp.data.logradouro });
            this.setState({ district: resp.data.bairro });
            this.setState({ city: resp.data.localidade });
            this.setState({ state: resp.data.uf });
            this.setState({ open_error2: false });
          } else {
            this.setState({ msg: 'CEP não encontrado.' });
            this.setState({ open_error2: true });
            this.setState({ open: false });
          }
        }
      }
    } catch(err) {
      this.setState({ street: '' });
      this.setState({ number: '' });
      this.setState({ district: '' });
      this.setState({ city: '' });
      this.setState({ state: '' });
      this.setState({ msg: 'CEP não encontrado.' });
      this.setState({ open_error2: true });
      this.setState({ open: false });
    }
  }

  getStepContent = (step) => {

    switch (step) {

      case 0:
        return(

          <div>

            <div style={styles.containerStep}>
              <Typography variant="h6" color="textSecondary" align="left">
                {'Buscando o endereço'}
              </Typography>

              <Typography variant="subtitle1" color="textSecondary" align="left">
                {'Instruções: digite o endereço ou CEP e clique no alvo para ajudar na localização no mapa.'}
              </Typography>

              <div style={{ flexDirection: 'row' }}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  name="address"
                  style={{ width: 450 }}
                  id="address"
                  label="Endereço"
                  placeholder="Digite um endereço ou CEP válido."
                  type="text"
                  onChange={this.handleChange}
                  onBlur={this.handleChangeAdress}
                  value={this.state.address}
                />

                <IconButton aria-label="delete" color="primary" style={styles.icon}
                  onClick={this.handleChangeAdress}
                >
                  <GpsFixed />
                </IconButton>
              </div>

              {this.state.open_error ? (
                <Alert
                  style={{ marginBottom: 10 }}
                  severity="error"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        this.setState({ open_error: false });
                        this.setState({ cep: '' });
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {this.state.msg}
                </Alert>
              ) : null}

              <Typography variant="subtitle1" color="textSecondary" align="left">
                {'• Após localizar o endereço, clique com o botão direito do mouse para iniciar a marcação, cada clique criará um novo ponto.'}
              </Typography>
              <Typography variant="subtitle1" color="textSecondary" align="left">
                {'• Segure e arraste um ponto para alterar sua localização.'}
              </Typography>

              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  style={{ textTransform: 'none' }}
                  startIcon={<Refresh />}
                  onClick={async () => {
                    polygonCoords = [
                      { lat: -22.990737, lng: -43.420790 },
                      { lat: -22.991277, lng: -43.423958 }
                    ];
                    await this.setState({ polygon: [] });
                    await this.setState({ area: '' });
                    await this.setState({ saveAreaBt: true });
                  }}
                >
                  Limpar
                </Button>

                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  style={{ textTransform: 'none', marginLeft: 10 }}
                  startIcon={<Add />}
                  onClick={async () => {
                    await this.setState({ finalPolygon: this.state.polygon });
                    await this.setState({ saveAreaBt: false });
                    this.handleArea();
                  }}
                >
                  Finalizar
                </Button>

                {this.state.area != '' ? (
                  <Typography
                    variant="subtitle1"
                    color="textSecondary"
                    align="left"
                    style={{ marginLeft: 10 }}
                  >
                    {`Área de ${this.state.area} m²`}
                  </Typography>
                ) : null}
              </div>
              <Divider style={{ marginTop: 10 }} />
            </div>

          </div>

        );
      
      case 1:
        return(

          <div>

            <div style={styles.containerStep}>
              <Typography variant="h6" color="textSecondary" align="left">
                {'Detalhes da área'}
              </Typography>

              <Typography variant="subtitle1" color="textSecondary" align="left">
                {'Preencha os detalhes sobre a área demarcada.'}
              </Typography>

              <Typography variant="h6" color="textSecondary" align="left">
                {this.state.area ? `Área: ${this.state.area} m²` : 'Área ainda não informada'}
              </Typography>

              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                style={{ marginBottom: 17 }}
                required
                name="area_name"
                id="area_name"
                label="Nome"
                placeholder="Digite um nome para a área."
                type="text"
                onChange={this.handleChange}
                value={this.state.area_name}
              />

              <InputMask
                {...this.props.value}
                mask={this.state.cepMask}
                fullWidth
                maskChar=" "
                name="cep"
                id="cep"
                type="text"
                onChange={this.handleChange}
                onBlur={this.handleCEP}
                value={this.state.cep}
              >
                {(inputProps) =>
                  <TextField
                    {...inputProps}
                    variant="outlined"
                    margin="normal"
                    label="CEP"
                    style={{ marginBottom: 15, marginTop: -2 }}
                  />
                }
              </InputMask>

              {this.state.open_error2 ? (
                <Alert
                  style={{ marginBottom: 10 }}
                  severity="error"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        this.setState({ open_error2: false });
                        this.setState({ cep: '' });
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {this.state.msg}
                </Alert>
              ) : null}

              <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 15 }}>
                <TextField
                  name="street"
                  variant="outlined"
                  required
                  fullWidth
                  id="street"
                  label="Rua"
                  InputProps={{ style: styles.input }}
                  onChange={this.handleChange}
                  value={this.state.street}
                />
                <Typography component="h1" variant="h5" style={{ marginTop: 10 }}>
                  -
                </Typography>
                <TextField
                  name="number"
                  variant="outlined"
                  required
                  id="number"
                  label="Número"
                  InputProps={{ style: styles.input }}
                  onChange={this.handleChange}
                  value={this.state.number}
                  style={{ width: 150 }}
                />
              </div>

              <TextField
                name="district"
                variant="outlined"
                required
                fullWidth
                id="district"
                label="Bairro"
                InputProps={{ style: styles.input }}
                onChange={this.handleChange}
                value={this.state.district}
                style={{ marginBottom: 15 }}
              />

              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <TextField
                  name="city"
                  variant="outlined"
                  required
                  fullWidth
                  id="city"
                  label="Cidade"
                  InputProps={{ style: styles.input }}
                  onChange={this.handleChange}
                  value={this.state.city}
                />
                <Typography component="h1" variant="h5" style={{ marginTop: 10 }}>
                  -
                </Typography>
                <TextField
                  name="state"
                  variant="outlined"
                  required
                  id="state"
                  label="Estado"
                  InputProps={{ style: styles.input }}
                  onChange={this.handleChange}
                  value={this.state.state}
                  style={{ width: 150 }}
                />
              </div>

              <TextField
                name="description"
                variant="outlined"
                multiline
                fullWidth
                style={{ marginTop: 15 }}
                id="description"
                label="Complemento"
                placeholder="Descreva algum detalhe a mais sobre a área"
                InputProps={{ style: styles.input }}
                onChange={this.handleChange}
                value={this.state.description}
              />
            </div>

          </div>

        );

      default:
        return (
          
          <div>

            <div style={styles.containerStep}>
              <Typography variant="h6" color="textSecondary" align="left">
                {'Confirmação de informações'}
              </Typography>

              <Typography variant="subtitle1" color="textSecondary" align="left">
                {`Nome: ${this.state.area_name}`}
              </Typography>
              <Typography variant="subtitle1" color="textSecondary" align="left">
                {`CEP: ${this.state.cep}`}
              </Typography>
              <Typography variant="subtitle1" color="textSecondary" align="left">
                {this.state.street ? `Endereço: ${this.state.street}, ${this.state.number}` : 'Endereço: '}
              </Typography>
              <Typography variant="subtitle1" color="textSecondary" align="left">
                {`Bairro: ${this.state.district}`}
              </Typography>
              <Typography variant="subtitle1" color="textSecondary" align="left">
                {this.state.city ? `Local: ${this.state.city} - ${this.state.state}` : 'Local: '}
              </Typography>
              <Typography variant="subtitle1" color="textSecondary" align="left">
                {`Complemento: ${this.state.description}`}
              </Typography>
              <Typography variant="subtitle1" color="textSecondary" align="left">
                {this.state.area ? `Área: ${this.state.area} m2` : 'Área: '}
              </Typography>
            </div>

          </div>

        );

    }

  }

  totalSteps = () => {
    return this.state.steps.length;
  }

  completedSteps = () => {
    return Object.keys(this.state.completed).length;
  }

  isLastStep = () => {
    return this.state.activeStep === this.totalSteps() - 1;
  }

  allStepsCompleted = () => {
    return this.completedSteps() === this.totalSteps();
  }

  handleNext = () => {
    const newActiveStep =
      this.isLastStep() && !this.allStepsCompleted()
        ?
        this.state.steps.findIndex((step, i) => !(i in this.state.completed))
        : this.state.activeStep + 1;
    this.setState({ activeStep: newActiveStep });
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  handleBack = async () => {
    this.setState({ activeStep: this.state.activeStep - 1 });
  }

  handleStep = (step) => () => {
    this.setState({ activeStep: step });
  }

  handleComplete = () => {
    if (this.completedSteps() === this.totalSteps() - 1) {
      this.setState({ disabled: false });
    } else {
      this.setState({ disabled: true });
    }
    const newCompleted = this.state.completed;
    newCompleted[this.state.activeStep] = true;
    this.setState({ completed: newCompleted });
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    this.handleNext();
  }

  handleReset = () => {
    this.setState({ activeStep: 0 });
    this.setState({ completed: {} });
    this.setState({ disabled: true });
  }

  onMarkerDragEnd = async (coord, latitude) => {
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();

    let index = null;

    for (let i = 0; i < this.state.polygon.length; i++) {

      if (this.state.polygon[i].lat == latitude) {
        index = i;
        break;
      }

    }

    polygonCoords = [
      {lat: -22.990737, lng: -43.420790},
      {lat: -22.991277, lng: -43.423958}
    ];

    this.state.polygon[index] = {lat: lat, lng: lng};
    await this.setState({ polygon: this.state.polygon });

    await this.setState({ latitude: lat });
    await this.setState({ longitude: lng });

    this.finishPolygon();
  }

  finishPolygon = async () => {
    polygonCoords = [
      {lat: -22.990737, lng: -43.420790},
      {lat: -22.991277, lng: -43.423958}
    ];
    polygonCoords = this.state.polygon;
    await this.setState({ polygon: this.state.polygon });
  }

  handleArea = async () => {

    let area = getAreaOfPolygon(this.state.finalPolygon);
    area = parseFloat(area).toFixed(2);

    this.setState({ area });

  }

  handlePolygon = async (coord) => {
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();

    polygonCoords = [
      {lat: -22.990737, lng: -43.420790},
      {lat: -22.991277, lng: -43.423958}
    ];

    await this.state.polygon.push({ lat: lat, lng: lng });
    await this.setState({ polygon: this.state.polygon });

    this.setState({ latitude: lat });
    this.setState({ longitude: lng })

    this.finishPolygon();
  }

  handleAddComunities = async () => {

    //alert(this.state.comunities.length);

    for (let i = 0; i < this.state.comunities.length; i++) {

      const data = new FormData();
      data.append('user_id', this.state.user_id);

      let area = await getAreaOfPolygon(this.state.completed_comunities[i]);
      area = parseFloat(area).toFixed(2);

      data.append('area', area);

      data.append('polygon', JSON.stringify(this.state.completed_comunities[i]));
      data.append('latitude', this.state.completed_comunities[i][0].lat);
      data.append('longitude', this.state.completed_comunities[i][0].lng);

      data.append('area_name', this.state.comunities[i].comunidade);

      data.append('zip_code', '');
      data.append('street', '');
      data.append('number', '');
      data.append('district', '');
      data.append('city', 'São João de Meriti');
      data.append('state', 'RJ');
      data.append('description', '');
      data.append('selected_city', this.state.selected_city);

      try {
        let resp = await api.post('/admarea', data);
        if (resp != null) {
          if (resp.data) {
            if (JSON.stringify(resp.data).includes('registred')) {
  
              document.body.scrollTop = 0;
              document.documentElement.scrollTop = 0;
              this.setState({ msg: 'Cadastro realizado com sucesso.' });
              this.setState({ open_error3: false });
              this.setState({ open: true });
  
            } else {
              document.body.scrollTop = 0;
              document.documentElement.scrollTop = 0;
              this.setState({ msg: resp.data });
              this.setState({ open_error3: true });
              this.setState({ open: false });
            }
          }
        }
      } catch (err) {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        this.setState({ msg: 'Não foi possível incluir esta área ' + err });
        this.setState({ open_error3: true });
        this.setState({ open: false });
      }

    }

  }

  handleSubmit = async (e) => {

    e.preventDefault();

    if (this.state.area_name === '' || this.state.street === '' || this.state.district === '' || this.state.city === '' || 
    this.state.state === '' || this.state.area === '' || this.state.finalPolygon.length === 0) {
      this.setState({ msg: 'Preencha todos os campos obrigatórios e complete todas as etapas.' });
      this.setState({ open_error3: true });
    } else {
      
      this.setState({ open_error3: false });

      const data = new FormData();

      data.append('user_id', this.state.user_id);
      data.append('area', this.state.area);
      data.append('polygon', JSON.stringify(this.state.finalPolygon));
      data.append('latitude', this.state.latitude);
      data.append('longitude', this.state.longitude);
      data.append('area_name', this.state.area_name);
      data.append('zip_code', this.state.cep);
      data.append('street', this.state.street);
      data.append('number', this.state.number);
      data.append('district', this.state.district);
      data.append('city', this.state.city);
      data.append('state', this.state.state);
      data.append('description', this.state.description);
      data.append('selected_city', this.state.selected_city);

      try {
        let resp = await api.post('/admarea', data);
        if (resp != null) {
          if (resp.data) {
            if (JSON.stringify(resp.data).includes('registred')) {

              document.body.scrollTop = 0;
              document.documentElement.scrollTop = 0;
              this.setState({ msg: 'Cadastro realizado com sucesso.' });
              this.setState({ open_error3: false });
              this.setState({ open: true });

            } else {
              document.body.scrollTop = 0;
              document.documentElement.scrollTop = 0;
              this.setState({ msg: resp.data });
              this.setState({ open_error3: true });
              this.setState({ open: false });
            }
          }
        }
      } catch (err) {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        this.setState({ msg: 'Não foi possível incluir esta área ' + err });
        this.setState({ open_error3: true });
        this.setState({ open: false });
      }

    }

  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {

    return(

      <div style={styles.directionColumn}>
        
        <div style={styles.html}>

          <Header
            history={this.props.history}
            login={this.state.login}
            option={this.state.option}
            user_type={this.state.user_type}
            selected_area={this.state.selected_area}
            selected_city={this.state.selected_city}
            city={this.state.selected_city}
            {...this.props}
          />

          <ThemeProvider theme={theme}>
            <div style={styles.directionRow}>

              <div>
                <Map
                  key={'AIzaSyDC6df7s7nceeG2sHhFGu0-nzLppDiHpe0'}
                  google={this.props.google}
                  zoom={this.state.zoom}
                  style={styles.map}
                  mapType="map"
                  center={{
                    lat: this.state.latitude,
                    lng: this.state.longitude
                  }}
                  onRightclick={(t, map, coord) => this.handlePolygon(coord)}
                >

                  {/*this.state.selected_city.includes('sjmeriti') ? (
                    <Polygon
                      paths={polygonCoords_SJMeriti}
                      strokeColor="#001D5F"
                      strokeOpacity={0.5}
                      strokeWeight={2}
                      fillColor="#001D5F"
                      fillOpacity={0.2}
                    />
                  ) : this.state.selected_city.includes('rio') ? (
                    <Polygon
                      paths={this.state.polygonCoords_RioGeral}
                      strokeColor="#001D5F"
                      strokeOpacity={0.5}
                      strokeWeight={2}
                      fillColor="#001D5F"
                      fillOpacity={0.2}
                    />
                  ) : null*/}

                  <Polygon
                    paths={polygonCoords}
                    strokeColor="#0000FF"
                    strokeOpacity={0.8}
                    strokeWeight={2}
                    fillColor="#0000FF"
                    fillOpacity={0.35}
                  />

                  {/*this.state.completed_comunities.map((item) =>
                    <Polygon
                      paths={item}
                      strokeColor="#800"
                      strokeOpacity={0.5}
                      strokeWeight={2}
                      fillColor="#800"
                      fillOpacity={0.2}
                    />
                  )*/}

                  {this.state.polygon.map((item) =>
                    <Marker
                      position={{
                        lat: item.lat,
                        lng: item.lng
                      }}
                      name={'Local atual'}
                      draggable={true}
                      onDragend={(t, map, coord) => this.onMarkerDragEnd(coord, item.lat)}
                    />
                  )}

                  {this.state.adm_areas.map((item) =>
                    <Polygon
                      paths={item.polygon}
                      strokeColor="#0000FF"
                      strokeOpacity={0.8}
                      strokeWeight={2}
                      fillColor="#0000FF"
                      fillOpacity={0.2}
                    />
                  )}

                  {this.state.adm_areas.map((item) =>
                    <Marker
                      position={{
                        lat: item.latitude,
                        lng: item.longitude
                      }}
                      name={`${item.area_name}`}
                      title={`${item.area_name}: ${item.area} m² / ${item.description}`}
                    />
                  )}

                </Map>
              </div>

              <form id="steps_form" onSubmit={this.handleSubmit}>
                <div style={styles.stepper}>
                  <Stepper alternativeLabel nonLinear activeStep={this.state.activeStep}>
                    {this.state.steps.map((label, index) => (
                      <Step key={label}>
                        <StepButton onClick={this.handleStep(index)} completed={this.state.completed[index]}>
                          {label}
                        </StepButton>
                      </Step>
                    ))}
                  </Stepper>
                  <div>
                    {this.allStepsCompleted() ? (
                      <div>
                        <Typography variant="caption" color="textSecondary">
                          Todas as etapas foram concluídas, já pode enviar o registro.
                        </Typography>
                        <Button onClick={this.handleReset} startIcon={<Refresh />}>Refazer</Button>
                      </div>
                    ) : (
                      <div>
                        {this.getStepContent(this.state.activeStep)}
                        <div>
                          <Button disabled={this.state.activeStep === 0} onClick={this.handleBack} startIcon={<Back />}>
                            Voltar
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            style={styles.button}
                            onClick={this.handleNext}
                            startIcon={<Next />}
                          >
                            Próximo
                          </Button>
                          {this.state.activeStep !== this.state.steps.length &&
                            (this.state.completed[this.state.activeStep] ? (
                              <Typography variant="caption" color="textSecondary" style={{ marginLeft: 10 }}>
                                Etapa {this.state.activeStep + 1} já foi concluída
                              </Typography>
                            ) : (
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={this.handleComplete}
                                style={styles.button2}
                                startIcon={<Done />}
                              >
                                {this.completedSteps() === this.totalSteps() - 1 ? 'Finalizar' : 'Completar etapa'}
                              </Button>
                            ))}
                        </div>
                      </div>
                    )}
                  </div>
                  <Button
                    variant="contained"
                    color="primary"
                    style={styles.button3}
                    type="submit"
                    disabled={this.state.disabled}
                    startIcon={<Add />}
                  >
                    Cadastrar área
                  </Button>

                  {/*<Button
                    variant="contained"
                    color="primary"
                    style={styles.button3}
                    type="button"
                    startIcon={<Add />}
                    onClick={() => this.handleAddComunities()}
                  >
                    Cadastrar comunidades
                  </Button>*/}

                  {this.state.open_error3 ? (
                    <Alert
                      style={{ marginBottom: 10 }}
                      severity="error"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            this.setState({ open_error3: false });
                            this.setState({ cep: '' });
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      {this.state.msg}
                    </Alert>
                  ) : null}

                  {this.state.open ? (
                    <Alert
                      style={{ marginBottom: 10 }}
                      severity="success"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            this.setState({ open: false });
                            this.setState({ cep: '' });
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      {this.state.msg}
                    </Alert>
                  ) : null}
                </div>
              </form>

            </div>
          </ThemeProvider>

        </div>
        <FooterHome />
      </div>

    );

  }

}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDC6df7s7nceeG2sHhFGu0-nzLppDiHpe0',
  language: 'pt-BR'
})(AddArea)

let styles = {

  html: {
    backgroundColor: '#E5E6F0', //454545
    height: window.innerHeight,
    width: window.innerWidth,
    margin: -10,
  },

  directionRow: {
    display: 'flex',
    flexDirection: 'row'
  },

  directionColumn: {
    display: 'flex',
    flexDirection: 'column'
  },

  map: {
    height: '90%',
    width: window.innerWidth - (window.innerWidth / 2.5),
    marginLeft: 10,
    marginTop: 10,
    alignSelf: 'flex-start',
  },

  stepper: {
    position: 'relative',
    width: 600,
    marginLeft: window.innerWidth - (window.innerWidth / 2.7),
    marginTop: 10,
    backgroundColor: '#bfcadb', //323232
  },

  containerStep: {
    backgroundColor: '#bfcadb', //323232
    marginTop: 20,
    marginBottom: 20,
    padding: 20,
  },

  button: {
    textTransform: 'none',
  },

  button2: {
    textTransform: 'none',
    marginLeft: 10,
  },

  button3: {
    textTransform: 'none',
    margin: 10,
  },

  icon: {
    marginTop: 20,
    marginLeft: 5,
  },

  input: {
    color: '#fff',
  },

}