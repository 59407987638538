let polygonCoords_SJMeriti = [
  {
    lng: -43.410708,
    lat: -22.7916749
  },
  {
    lng: -43.4103457,
    lat: -22.7914733
  },
  {
    lng: -43.4102923,
    lat: -22.7914197
  },
  {
    lng: -43.4100673,
    lat: -22.7913682
  },
  {
    lng: -43.4096339,
    lat: -22.7906551
  },
  {
    lng: -43.4096074,
    lat: -22.7906114
  },
  {
    lng: -43.4094245,
    lat: -22.7903165
  },
  {
    lng: -43.4091619,
    lat: -22.7900025
  },
  {
    lng: -43.4087485,
    lat: -22.7898365
  },
  {
    lng: -43.4087255,
    lat: -22.7898272
  },
  {
    lng: -43.4086592,
    lat: -22.7898029
  },
  {
    lng: -43.4082721,
    lat: -22.7896609
  },
  {
    lng: -43.4082545,
    lat: -22.7896515
  },
  {
    lng: -43.4081566,
    lat: -22.7895996
  },
  {
    lng: -43.4081451,
    lat: -22.7895935
  },
  {
    lng: -43.4081385,
    lat: -22.7895899
  },
  {
    lng: -43.408101,
    lat: -22.7895415
  },
  {
    lng: -43.4078047,
    lat: -22.7891582
  },
  {
    lng: -43.4074394,
    lat: -22.7886858
  },
  {
    lng: -43.4074078,
    lat: -22.7886077
  },
  {
    lng: -43.4073605,
    lat: -22.7884973
  },
  {
    lng: -43.4073475,
    lat: -22.7884669
  },
  {
    lng: -43.4072962,
    lat: -22.7883471
  },
  {
    lng: -43.4069279,
    lat: -22.7874768
  },
  {
    lng: -43.406835,
    lat: -22.7872573
  },
  {
    lng: -43.4068213,
    lat: -22.7872248
  },
  {
    lng: -43.4067151,
    lat: -22.7870327
  },
  {
    lng: -43.4065456,
    lat: -22.786726
  },
  {
    lng: -43.4065282,
    lat: -22.7866945
  },
  {
    lng: -43.4065071,
    lat: -22.7866591
  },
  {
    lng: -43.4064204,
    lat: -22.7865132
  },
  {
    lng: -43.4063246,
    lat: -22.7863519
  },
  {
    lng: -43.4061628,
    lat: -22.7862375
  },
  {
    lng: -43.4060127,
    lat: -22.7861312
  },
  {
    lng: -43.4057719,
    lat: -22.7859608
  },
  {
    lng: -43.4057037,
    lat: -22.7859125
  },
  {
    lng: -43.4055958,
    lat: -22.7858737
  },
  {
    lng: -43.4055464,
    lat: -22.785856
  },
  {
    lng: -43.4054227,
    lat: -22.7858116
  },
  {
    lng: -43.4050335,
    lat: -22.7856718
  },
  {
    lng: -43.4049918,
    lat: -22.7856568
  },
  {
    lng: -43.4044675,
    lat: -22.7854685
  },
  {
    lng: -43.4039875,
    lat: -22.7852465
  },
  {
    lng: -43.4039235,
    lat: -22.7852169
  },
  {
    lng: -43.4038737,
    lat: -22.7851939
  },
  {
    lng: -43.403771,
    lat: -22.7851464
  },
  {
    lng: -43.4035609,
    lat: -22.7849722
  },
  {
    lng: -43.4034141,
    lat: -22.7848504
  },
  {
    lng: -43.4031268,
    lat: -22.7845196
  },
  {
    lng: -43.4031152,
    lat: -22.7845005
  },
  {
    lng: -43.4027873,
    lat: -22.7839624
  },
  {
    lng: -43.4027525,
    lat: -22.7835097
  },
  {
    lng: -43.4026246,
    lat: -22.7827955
  },
  {
    lng: -43.4026114,
    lat: -22.7827216
  },
  {
    lng: -43.4025305,
    lat: -22.7826076
  },
  {
    lng: -43.4023692,
    lat: -22.7826221
  },
  {
    lng: -43.4023567,
    lat: -22.7826189
  },
  {
    lng: -43.4022775,
    lat: -22.7825987
  },
  {
    lng: -43.4022547,
    lat: -22.7825929
  },
  {
    lng: -43.4020892,
    lat: -22.7824458
  },
  {
    lng: -43.4015459,
    lat: -22.7818688
  },
  {
    lng: -43.4013883,
    lat: -22.7817118
  },
  {
    lng: -43.4009736,
    lat: -22.7812985
  },
  {
    lng: -43.4006678,
    lat: -22.7809938
  },
  {
    lng: -43.4005998,
    lat: -22.780926
  },
  {
    lng: -43.4000649,
    lat: -22.780393
  },
  {
    lng: -43.3997648,
    lat: -22.780094
  },
  {
    lng: -43.3992569,
    lat: -22.7795879
  },
  {
    lng: -43.3990317,
    lat: -22.7793635
  },
  {
    lng: -43.398799,
    lat: -22.7791316
  },
  {
    lng: -43.3987887,
    lat: -22.7791223
  },
  {
    lng: -43.3984354,
    lat: -22.778802
  },
  {
    lng: -43.3983998,
    lat: -22.7787698
  },
  {
    lng: -43.3983122,
    lat: -22.7786903
  },
  {
    lng: -43.397415,
    lat: -22.7778488
  },
  {
    lng: -43.397308,
    lat: -22.7777491
  },
  {
    lng: -43.397281,
    lat: -22.7777239
  },
  {
    lng: -43.3971869,
    lat: -22.7776362
  },
  {
    lng: -43.3971423,
    lat: -22.7775945
  },
  {
    lng: -43.3971317,
    lat: -22.7775843
  },
  {
    lng: -43.3955413,
    lat: -22.7760411
  },
  {
    lng: -43.3953744,
    lat: -22.775908
  },
  {
    lng: -43.3952076,
    lat: -22.775775
  },
  {
    lng: -43.3946897,
    lat: -22.7754921
  },
  {
    lng: -43.3941789,
    lat: -22.7750471
  },
  {
    lng: -43.3932286,
    lat: -22.773965
  },
  {
    lng: -43.3928678,
    lat: -22.7735945
  },
  {
    lng: -43.3927274,
    lat: -22.7734504
  },
  {
    lng: -43.3923797,
    lat: -22.7731575
  },
  {
    lng: -43.3922381,
    lat: -22.7730382
  },
  {
    lng: -43.3922363,
    lat: -22.7730367
  },
  {
    lng: -43.3922346,
    lat: -22.7730352
  },
  {
    lng: -43.3921957,
    lat: -22.7730042
  },
  {
    lng: -43.3919213,
    lat: -22.7727849
  },
  {
    lng: -43.3917802,
    lat: -22.7726722
  },
  {
    lng: -43.3917608,
    lat: -22.772657
  },
  {
    lng: -43.3917069,
    lat: -22.7726137
  },
  {
    lng: -43.3911135,
    lat: -22.771996
  },
  {
    lng: -43.3908566,
    lat: -22.7717286
  },
  {
    lng: -43.390576,
    lat: -22.7713656
  },
  {
    lng: -43.3905259,
    lat: -22.7713008
  },
  {
    lng: -43.3902649,
    lat: -22.7709567
  },
  {
    lng: -43.3900027,
    lat: -22.7707188
  },
  {
    lng: -43.3891307,
    lat: -22.7698506
  },
  {
    lng: -43.3883891,
    lat: -22.7691914
  },
  {
    lng: -43.3881649,
    lat: -22.7689904
  },
  {
    lng: -43.3870597,
    lat: -22.7679994
  },
  {
    lng: -43.3856305,
    lat: -22.7667583
  },
  {
    lng: -43.3855903,
    lat: -22.7667269
  },
  {
    lng: -43.3853843,
    lat: -22.7665657
  },
  {
    lng: -43.3851884,
    lat: -22.7664724
  },
  {
    lng: -43.3851536,
    lat: -22.7664558
  },
  {
    lng: -43.3848398,
    lat: -22.7663808
  },
  {
    lng: -43.38401,
    lat: -22.7662509
  },
  {
    lng: -43.3838734,
    lat: -22.7662296
  },
  {
    lng: -43.3827099,
    lat: -22.7660475
  },
  {
    lng: -43.3817789,
    lat: -22.7659018
  },
  {
    lng: -43.3814405,
    lat: -22.7658486
  },
  {
    lng: -43.3811546,
    lat: -22.7658461
  },
  {
    lng: -43.3809844,
    lat: -22.7658815
  },
  {
    lng: -43.3808214,
    lat: -22.7658955
  },
  {
    lng: -43.3806209,
    lat: -22.7658897
  },
  {
    lng: -43.3804501,
    lat: -22.7658501
  },
  {
    lng: -43.3791322,
    lat: -22.7655439
  },
  {
    lng: -43.3789663,
    lat: -22.7655101
  },
  {
    lng: -43.3778916,
    lat: -22.7652913
  },
  {
    lng: -43.3771654,
    lat: -22.7651696
  },
  {
    lng: -43.3759498,
    lat: -22.7649658
  },
  {
    lng: -43.3756433,
    lat: -22.7648979
  },
  {
    lng: -43.3755961,
    lat: -22.7648874
  },
  {
    lng: -43.3739986,
    lat: -22.7645333
  },
  {
    lng: -43.3739647,
    lat: -22.764516
  },
  {
    lng: -43.3738568,
    lat: -22.7644606
  },
  {
    lng: -43.3737004,
    lat: -22.7643629
  },
  {
    lng: -43.3734147,
    lat: -22.7642641
  },
  {
    lng: -43.373195,
    lat: -22.7642366
  },
  {
    lng: -43.3729464,
    lat: -22.7642393
  },
  {
    lng: -43.372669,
    lat: -22.7642202
  },
  {
    lng: -43.3725692,
    lat: -22.7641845
  },
  {
    lng: -43.3725306,
    lat: -22.7641707
  },
  {
    lng: -43.3724534,
    lat: -22.7641432
  },
  {
    lng: -43.3721348,
    lat: -22.7640718
  },
  {
    lng: -43.3718986,
    lat: -22.7640553
  },
  {
    lng: -43.3716185,
    lat: -22.7640828
  },
  {
    lng: -43.3715064,
    lat: -22.7641219
  },
  {
    lng: -43.371394,
    lat: -22.7641612
  },
  {
    lng: -43.3712809,
    lat: -22.7641673
  },
  {
    lng: -43.3712055,
    lat: -22.7641714
  },
  {
    lng: -43.3704858,
    lat: -22.7640208
  },
  {
    lng: -43.3695392,
    lat: -22.7638228
  },
  {
    lng: -43.3684072,
    lat: -22.763586
  },
  {
    lng: -43.3674184,
    lat: -22.7633792
  },
  {
    lng: -43.3666758,
    lat: -22.7632238
  },
  {
    lng: -43.3662354,
    lat: -22.7631317
  },
  {
    lng: -43.3658119,
    lat: -22.7630431
  },
  {
    lng: -43.3656089,
    lat: -22.7630006
  },
  {
    lng: -43.36556,
    lat: -22.7629924
  },
  {
    lng: -43.3649885,
    lat: -22.7628967
  },
  {
    lng: -43.3636078,
    lat: -22.7626652
  },
  {
    lng: -43.3631762,
    lat: -22.7625929
  },
  {
    lng: -43.3623542,
    lat: -22.7624908
  },
  {
    lng: -43.3623021,
    lat: -22.7624843
  },
  {
    lng: -43.3621962,
    lat: -22.7624635
  },
  {
    lng: -43.3584509,
    lat: -22.7617277
  },
  {
    lng: -43.3554796,
    lat: -22.761144
  },
  {
    lng: -43.3554707,
    lat: -22.7611424
  },
  {
    lng: -43.3535123,
    lat: -22.760799
  },
  {
    lng: -43.3534595,
    lat: -22.7607897
  },
  {
    lng: -43.3533152,
    lat: -22.7607644
  },
  {
    lng: -43.351259,
    lat: -22.7604038
  },
  {
    lng: -43.3504409,
    lat: -22.7602603
  },
  {
    lng: -43.3496218,
    lat: -22.7601167
  },
  {
    lng: -43.3495909,
    lat: -22.7601112
  },
  {
    lng: -43.3480953,
    lat: -22.7598173
  },
  {
    lng: -43.3473716,
    lat: -22.7596751
  },
  {
    lng: -43.3435473,
    lat: -22.7589235
  },
  {
    lng: -43.3419883,
    lat: -22.7586171
  },
  {
    lng: -43.3383363,
    lat: -22.7579489
  },
  {
    lng: -43.337019,
    lat: -22.7577078
  },
  {
    lng: -43.3361689,
    lat: -22.7575523
  },
  {
    lng: -43.3353307,
    lat: -22.7573123
  },
  {
    lng: -43.3343364,
    lat: -22.7570241
  },
  {
    lng: -43.3338365,
    lat: -22.7568852
  },
  {
    lng: -43.3337431,
    lat: -22.7568593
  },
  {
    lng: -43.3321329,
    lat: -22.7565109
  },
  {
    lng: -43.3315533,
    lat: -22.7563855
  },
  {
    lng: -43.3312561,
    lat: -22.7563212
  },
  {
    lng: -43.3292903,
    lat: -22.7559026
  },
  {
    lng: -43.3292912,
    lat: -22.7559239
  },
  {
    lng: -43.3293042,
    lat: -22.7562505
  },
  {
    lng: -43.3293064,
    lat: -22.7563052
  },
  {
    lng: -43.3293068,
    lat: -22.756315
  },
  {
    lng: -43.3293076,
    lat: -22.7563341
  },
  {
    lng: -43.3293083,
    lat: -22.7563532
  },
  {
    lng: -43.3293088,
    lat: -22.7563639
  },
  {
    lng: -43.32931,
    lat: -22.7563953
  },
  {
    lng: -43.32932,
    lat: -22.756646
  },
  {
    lng: -43.3293201,
    lat: -22.7566474
  },
  {
    lng: -43.3293268,
    lat: -22.7568142
  },
  {
    lng: -43.3293297,
    lat: -22.7568879
  },
  {
    lng: -43.3295994,
    lat: -22.7636324
  },
  {
    lng: -43.3296018,
    lat: -22.76368
  },
  {
    lng: -43.3296017,
    lat: -22.7636895
  },
  {
    lng: -43.3296019,
    lat: -22.7636936
  },
  {
    lng: -43.329603,
    lat: -22.7637212
  },
  {
    lng: -43.3296693,
    lat: -22.7653801
  },
  {
    lng: -43.3296703,
    lat: -22.7654056
  },
  {
    lng: -43.3296716,
    lat: -22.7654388
  },
  {
    lng: -43.3296722,
    lat: -22.7654531
  },
  {
    lng: -43.3296723,
    lat: -22.7654545
  },
  {
    lng: -43.3296843,
    lat: -22.7657557
  },
  {
    lng: -43.3297011,
    lat: -22.7663398
  },
  {
    lng: -43.3297634,
    lat: -22.7685043
  },
  {
    lng: -43.3298129,
    lat: -22.7702235
  },
  {
    lng: -43.3298129,
    lat: -22.7702259
  },
  {
    lng: -43.3298458,
    lat: -22.7713694
  },
  {
    lng: -43.3298741,
    lat: -22.7723506
  },
  {
    lng: -43.3298249,
    lat: -22.7735363
  },
  {
    lng: -43.329777,
    lat: -22.7746903
  },
  {
    lng: -43.3297672,
    lat: -22.7749273
  },
  {
    lng: -43.3296234,
    lat: -22.7783918
  },
  {
    lng: -43.3296154,
    lat: -22.7785857
  },
  {
    lng: -43.3296046,
    lat: -22.7788457
  },
  {
    lng: -43.3295666,
    lat: -22.7797633
  },
  {
    lng: -43.329543,
    lat: -22.7800423
  },
  {
    lng: -43.3293106,
    lat: -22.7827896
  },
  {
    lng: -43.3293039,
    lat: -22.7829312
  },
  {
    lng: -43.3293012,
    lat: -22.7829896
  },
  {
    lng: -43.3292992,
    lat: -22.7830324
  },
  {
    lng: -43.3292919,
    lat: -22.7831874
  },
  {
    lng: -43.3292876,
    lat: -22.783279
  },
  {
    lng: -43.3292853,
    lat: -22.7833293
  },
  {
    lng: -43.3292848,
    lat: -22.7833393
  },
  {
    lng: -43.3292845,
    lat: -22.783345
  },
  {
    lng: -43.329284,
    lat: -22.7833559
  },
  {
    lng: -43.3292821,
    lat: -22.783397
  },
  {
    lng: -43.3292815,
    lat: -22.7834095
  },
  {
    lng: -43.3292804,
    lat: -22.7834319
  },
  {
    lng: -43.3292739,
    lat: -22.7835708
  },
  {
    lng: -43.3292715,
    lat: -22.7836224
  },
  {
    lng: -43.3292697,
    lat: -22.7836608
  },
  {
    lng: -43.3292672,
    lat: -22.7837134
  },
  {
    lng: -43.3292644,
    lat: -22.7837729
  },
  {
    lng: -43.3292437,
    lat: -22.7842143
  },
  {
    lng: -43.3292418,
    lat: -22.7842538
  },
  {
    lng: -43.3292411,
    lat: -22.7842704
  },
  {
    lng: -43.3292403,
    lat: -22.784287
  },
  {
    lng: -43.3292402,
    lat: -22.7842886
  },
  {
    lng: -43.3292399,
    lat: -22.7842949
  },
  {
    lng: -43.3292382,
    lat: -22.7843309
  },
  {
    lng: -43.3292368,
    lat: -22.7843616
  },
  {
    lng: -43.3292033,
    lat: -22.785074
  },
  {
    lng: -43.3292013,
    lat: -22.7851178
  },
  {
    lng: -43.3292002,
    lat: -22.7851412
  },
  {
    lng: -43.3292002,
    lat: -22.785148
  },
  {
    lng: -43.3292003,
    lat: -22.7851548
  },
  {
    lng: -43.3292006,
    lat: -22.7851804
  },
  {
    lng: -43.3292012,
    lat: -22.7852357
  },
  {
    lng: -43.3292133,
    lat: -22.7863712
  },
  {
    lng: -43.3292589,
    lat: -22.7869286
  },
  {
    lng: -43.3292623,
    lat: -22.7869692
  },
  {
    lng: -43.3292636,
    lat: -22.7869855
  },
  {
    lng: -43.329265,
    lat: -22.7870022
  },
  {
    lng: -43.3292657,
    lat: -22.7870107
  },
  {
    lng: -43.3292682,
    lat: -22.787042
  },
  {
    lng: -43.3292704,
    lat: -22.7870688
  },
  {
    lng: -43.3292726,
    lat: -22.7870956
  },
  {
    lng: -43.3294359,
    lat: -22.7890884
  },
  {
    lng: -43.3294374,
    lat: -22.7891063
  },
  {
    lng: -43.329442,
    lat: -22.7891624
  },
  {
    lng: -43.3294455,
    lat: -22.7892055
  },
  {
    lng: -43.3294538,
    lat: -22.7893065
  },
  {
    lng: -43.3294812,
    lat: -22.7896406
  },
  {
    lng: -43.3294834,
    lat: -22.7896678
  },
  {
    lng: -43.329485,
    lat: -22.7896877
  },
  {
    lng: -43.3294864,
    lat: -22.7897041
  },
  {
    lng: -43.3294866,
    lat: -22.7897064
  },
  {
    lng: -43.3294897,
    lat: -22.7897443
  },
  {
    lng: -43.3295666,
    lat: -22.7906827
  },
  {
    lng: -43.3297505,
    lat: -22.7921691
  },
  {
    lng: -43.3297547,
    lat: -22.7922034
  },
  {
    lng: -43.3297557,
    lat: -22.7922112
  },
  {
    lng: -43.3297581,
    lat: -22.7922303
  },
  {
    lng: -43.3297583,
    lat: -22.7922325
  },
  {
    lng: -43.329761,
    lat: -22.7922537
  },
  {
    lng: -43.32977,
    lat: -22.7923263
  },
  {
    lng: -43.3297711,
    lat: -22.7923359
  },
  {
    lng: -43.329861,
    lat: -22.7930618
  },
  {
    lng: -43.3300943,
    lat: -22.7949468
  },
  {
    lng: -43.3300965,
    lat: -22.7949648
  },
  {
    lng: -43.3300978,
    lat: -22.7949799
  },
  {
    lng: -43.3301001,
    lat: -22.7950056
  },
  {
    lng: -43.3301496,
    lat: -22.7955599
  },
  {
    lng: -43.3302361,
    lat: -22.7965279
  },
  {
    lng: -43.3302876,
    lat: -22.797105
  },
  {
    lng: -43.3305148,
    lat: -22.7989883
  },
  {
    lng: -43.3306864,
    lat: -22.8004107
  },
  {
    lng: -43.3308095,
    lat: -22.8014308
  },
  {
    lng: -43.3308639,
    lat: -22.8020705
  },
  {
    lng: -43.3308734,
    lat: -22.8021827
  },
  {
    lng: -43.3308744,
    lat: -22.8021951
  },
  {
    lng: -43.3309145,
    lat: -22.8026658
  },
  {
    lng: -43.3316639,
    lat: -22.8027207
  },
  {
    lng: -43.3322192,
    lat: -22.8027613
  },
  {
    lng: -43.3338371,
    lat: -22.8028796
  },
  {
    lng: -43.333921,
    lat: -22.8028858
  },
  {
    lng: -43.3344407,
    lat: -22.8029238
  },
  {
    lng: -43.3347905,
    lat: -22.8029412
  },
  {
    lng: -43.337326,
    lat: -22.8030677
  },
  {
    lng: -43.3381035,
    lat: -22.8030995
  },
  {
    lng: -43.3400351,
    lat: -22.8031785
  },
  {
    lng: -43.3415388,
    lat: -22.8032923
  },
  {
    lng: -43.3425036,
    lat: -22.8033654
  },
  {
    lng: -43.3452409,
    lat: -22.8035552
  },
  {
    lng: -43.3454146,
    lat: -22.8035673
  },
  {
    lng: -43.3454412,
    lat: -22.8035676
  },
  {
    lng: -43.3462958,
    lat: -22.803578
  },
  {
    lng: -43.3467447,
    lat: -22.8036163
  },
  {
    lng: -43.346816,
    lat: -22.8036223
  },
  {
    lng: -43.3469075,
    lat: -22.8036301
  },
  {
    lng: -43.3469629,
    lat: -22.8036348
  },
  {
    lng: -43.3470095,
    lat: -22.8036388
  },
  {
    lng: -43.3470701,
    lat: -22.8036439
  },
  {
    lng: -43.3472183,
    lat: -22.8036566
  },
  {
    lng: -43.3474041,
    lat: -22.8036734
  },
  {
    lng: -43.3476323,
    lat: -22.803694
  },
  {
    lng: -43.3482497,
    lat: -22.8037498
  },
  {
    lng: -43.3483573,
    lat: -22.8037596
  },
  {
    lng: -43.348411,
    lat: -22.8037644
  },
  {
    lng: -43.3484588,
    lat: -22.8037687
  },
  {
    lng: -43.3484648,
    lat: -22.8037693
  },
  {
    lng: -43.3485645,
    lat: -22.8036573
  },
  {
    lng: -43.3486642,
    lat: -22.8035453
  },
  {
    lng: -43.3487118,
    lat: -22.8035156
  },
  {
    lng: -43.3489259,
    lat: -22.8033818
  },
  {
    lng: -43.3490308,
    lat: -22.8033407
  },
  {
    lng: -43.3492268,
    lat: -22.803264
  },
  {
    lng: -43.3495867,
    lat: -22.803192
  },
  {
    lng: -43.3498688,
    lat: -22.8031401
  },
  {
    lng: -43.3500481,
    lat: -22.8031072
  },
  {
    lng: -43.3501559,
    lat: -22.8030874
  },
  {
    lng: -43.3502194,
    lat: -22.8030796
  },
  {
    lng: -43.3508708,
    lat: -22.8029999
  },
  {
    lng: -43.3511449,
    lat: -22.8029664
  },
  {
    lng: -43.3516541,
    lat: -22.8029042
  },
  {
    lng: -43.3520941,
    lat: -22.8028053
  },
  {
    lng: -43.3522455,
    lat: -22.8027713
  },
  {
    lng: -43.3523969,
    lat: -22.8027372
  },
  {
    lng: -43.3528187,
    lat: -22.8026425
  },
  {
    lng: -43.3535645,
    lat: -22.8024593
  },
  {
    lng: -43.3537575,
    lat: -22.8024266
  },
  {
    lng: -43.3539505,
    lat: -22.8023938
  },
  {
    lng: -43.3540101,
    lat: -22.8023983
  },
  {
    lng: -43.3543889,
    lat: -22.8024266
  },
  {
    lng: -43.3546938,
    lat: -22.8024919
  },
  {
    lng: -43.3547553,
    lat: -22.8025051
  },
  {
    lng: -43.3547579,
    lat: -22.8025054
  },
  {
    lng: -43.3548069,
    lat: -22.802511
  },
  {
    lng: -43.3551082,
    lat: -22.8025457
  },
  {
    lng: -43.3554043,
    lat: -22.8025797
  },
  {
    lng: -43.3554094,
    lat: -22.8025803
  },
  {
    lng: -43.3554199,
    lat: -22.8025817
  },
  {
    lng: -43.355885,
    lat: -22.8026454
  },
  {
    lng: -43.3559591,
    lat: -22.8026555
  },
  {
    lng: -43.355998,
    lat: -22.8026605
  },
  {
    lng: -43.3561863,
    lat: -22.8026847
  },
  {
    lng: -43.3563157,
    lat: -22.8027013
  },
  {
    lng: -43.3565217,
    lat: -22.8027112
  },
  {
    lng: -43.3566804,
    lat: -22.8026932
  },
  {
    lng: -43.3568391,
    lat: -22.8026752
  },
  {
    lng: -43.357087,
    lat: -22.8026465
  },
  {
    lng: -43.357405,
    lat: -22.8026098
  },
  {
    lng: -43.3576143,
    lat: -22.8026196
  },
  {
    lng: -43.3579513,
    lat: -22.8026752
  },
  {
    lng: -43.358285,
    lat: -22.802739
  },
  {
    lng: -43.3585293,
    lat: -22.8027857
  },
  {
    lng: -43.3586165,
    lat: -22.8028024
  },
  {
    lng: -43.3586186,
    lat: -22.8028028
  },
  {
    lng: -43.3586306,
    lat: -22.8028052
  },
  {
    lng: -43.3590658,
    lat: -22.8028925
  },
  {
    lng: -43.3593001,
    lat: -22.8029394
  },
  {
    lng: -43.3593015,
    lat: -22.8029399
  },
  {
    lng: -43.3595117,
    lat: -22.8030056
  },
  {
    lng: -43.359692,
    lat: -22.803084
  },
  {
    lng: -43.3597374,
    lat: -22.8031037
  },
  {
    lng: -43.3599369,
    lat: -22.8032215
  },
  {
    lng: -43.3604933,
    lat: -22.8036172
  },
  {
    lng: -43.3605604,
    lat: -22.8036649
  },
  {
    lng: -43.3609758,
    lat: -22.8039604
  },
  {
    lng: -43.3614777,
    lat: -22.8043174
  },
  {
    lng: -43.3615013,
    lat: -22.8043345
  },
  {
    lng: -43.3616151,
    lat: -22.8044176
  },
  {
    lng: -43.3616347,
    lat: -22.8044318
  },
  {
    lng: -43.3617394,
    lat: -22.8044515
  },
  {
    lng: -43.3619095,
    lat: -22.8044286
  },
  {
    lng: -43.361995,
    lat: -22.8044181
  },
  {
    lng: -43.3625146,
    lat: -22.8043543
  },
  {
    lng: -43.3631197,
    lat: -22.8042801
  },
  {
    lng: -43.3636689,
    lat: -22.8042127
  },
  {
    lng: -43.363797,
    lat: -22.804197
  },
  {
    lng: -43.3638778,
    lat: -22.8041871
  },
  {
    lng: -43.3639662,
    lat: -22.8041762
  },
  {
    lng: -43.3642183,
    lat: -22.8041453
  },
  {
    lng: -43.3643619,
    lat: -22.8041277
  },
  {
    lng: -43.3644688,
    lat: -22.8041146
  },
  {
    lng: -43.3645756,
    lat: -22.8041014
  },
  {
    lng: -43.3648597,
    lat: -22.8040782
  },
  {
    lng: -43.3649212,
    lat: -22.8040732
  },
  {
    lng: -43.3650559,
    lat: -22.8040621
  },
  {
    lng: -43.365135,
    lat: -22.8040556
  },
  {
    lng: -43.3651523,
    lat: -22.8040556
  },
  {
    lng: -43.3653141,
    lat: -22.8040556
  },
  {
    lng: -43.3653186,
    lat: -22.8040556
  },
  {
    lng: -43.3653242,
    lat: -22.8040556
  },
  {
    lng: -43.3653296,
    lat: -22.8040556
  },
  {
    lng: -43.3653314,
    lat: -22.8040557
  },
  {
    lng: -43.3653347,
    lat: -22.8040559
  },
  {
    lng: -43.3653644,
    lat: -22.8040579
  },
  {
    lng: -43.3653953,
    lat: -22.80406
  },
  {
    lng: -43.3654359,
    lat: -22.8040628
  },
  {
    lng: -43.3654391,
    lat: -22.804063
  },
  {
    lng: -43.3654752,
    lat: -22.8040655
  },
  {
    lng: -43.3655011,
    lat: -22.8040721
  },
  {
    lng: -43.3655128,
    lat: -22.8040751
  },
  {
    lng: -43.3655558,
    lat: -22.8040861
  },
  {
    lng: -43.3655598,
    lat: -22.8040871
  },
  {
    lng: -43.3655733,
    lat: -22.8040906
  },
  {
    lng: -43.3655838,
    lat: -22.8040932
  },
  {
    lng: -43.3655911,
    lat: -22.8040951
  },
  {
    lng: -43.3656016,
    lat: -22.8040978
  },
  {
    lng: -43.3656064,
    lat: -22.804099
  },
  {
    lng: -43.3656099,
    lat: -22.8040999
  },
  {
    lng: -43.3656125,
    lat: -22.8041006
  },
  {
    lng: -43.3656142,
    lat: -22.804101
  },
  {
    lng: -43.3656163,
    lat: -22.8041016
  },
  {
    lng: -43.3656206,
    lat: -22.8041031
  },
  {
    lng: -43.365667,
    lat: -22.8041189
  },
  {
    lng: -43.3657106,
    lat: -22.8041337
  },
  {
    lng: -43.3657493,
    lat: -22.8041469
  },
  {
    lng: -43.3657696,
    lat: -22.8041538
  },
  {
    lng: -43.3657812,
    lat: -22.804159
  },
  {
    lng: -43.365814,
    lat: -22.8041735
  },
  {
    lng: -43.3658815,
    lat: -22.8042035
  },
  {
    lng: -43.3660121,
    lat: -22.8042616
  },
  {
    lng: -43.3662995,
    lat: -22.8043893
  },
  {
    lng: -43.3663212,
    lat: -22.8044002
  },
  {
    lng: -43.3668059,
    lat: -22.8046425
  },
  {
    lng: -43.3668774,
    lat: -22.8046782
  },
  {
    lng: -43.3669241,
    lat: -22.8047016
  },
  {
    lng: -43.3672481,
    lat: -22.8048636
  },
  {
    lng: -43.3673279,
    lat: -22.8049035
  },
  {
    lng: -43.3673529,
    lat: -22.804916
  },
  {
    lng: -43.367448,
    lat: -22.8049544
  },
  {
    lng: -43.3674711,
    lat: -22.8049638
  },
  {
    lng: -43.3675432,
    lat: -22.8049929
  },
  {
    lng: -43.3676371,
    lat: -22.8050115
  },
  {
    lng: -43.3677768,
    lat: -22.8050392
  },
  {
    lng: -43.3677899,
    lat: -22.8050418
  },
  {
    lng: -43.3678113,
    lat: -22.8050443
  },
  {
    lng: -43.3678175,
    lat: -22.805045
  },
  {
    lng: -43.3680644,
    lat: -22.8050742
  },
  {
    lng: -43.3686495,
    lat: -22.8051126
  },
  {
    lng: -43.3689286,
    lat: -22.805131
  },
  {
    lng: -43.3696393,
    lat: -22.805222
  },
  {
    lng: -43.3699229,
    lat: -22.8052716
  },
  {
    lng: -43.3699868,
    lat: -22.8052828
  },
  {
    lng: -43.3702052,
    lat: -22.8053548
  },
  {
    lng: -43.370242,
    lat: -22.805367
  },
  {
    lng: -43.3702728,
    lat: -22.8053885
  },
  {
    lng: -43.3703078,
    lat: -22.805413
  },
  {
    lng: -43.3704126,
    lat: -22.8054864
  },
  {
    lng: -43.3704334,
    lat: -22.8055055
  },
  {
    lng: -43.3706853,
    lat: -22.805736
  },
  {
    lng: -43.3708503,
    lat: -22.8058872
  },
  {
    lng: -43.3709191,
    lat: -22.8059491
  },
  {
    lng: -43.3709411,
    lat: -22.8059689
  },
  {
    lng: -43.3710034,
    lat: -22.806025
  },
  {
    lng: -43.3711891,
    lat: -22.8061923
  },
  {
    lng: -43.3713429,
    lat: -22.8063308
  },
  {
    lng: -43.3716227,
    lat: -22.8065828
  },
  {
    lng: -43.3717373,
    lat: -22.806686
  },
  {
    lng: -43.3718713,
    lat: -22.8067948
  },
  {
    lng: -43.3719862,
    lat: -22.8068881
  },
  {
    lng: -43.3720648,
    lat: -22.8069519
  },
  {
    lng: -43.3721868,
    lat: -22.8070509
  },
  {
    lng: -43.3722661,
    lat: -22.8071153
  },
  {
    lng: -43.37234,
    lat: -22.8071835
  },
  {
    lng: -43.3725768,
    lat: -22.8072483
  },
  {
    lng: -43.37261,
    lat: -22.8072574
  },
  {
    lng: -43.3726121,
    lat: -22.8072577
  },
  {
    lng: -43.3728196,
    lat: -22.8072897
  },
  {
    lng: -43.3730292,
    lat: -22.8073221
  },
  {
    lng: -43.3732194,
    lat: -22.8073514
  },
  {
    lng: -43.3732923,
    lat: -22.8073626
  },
  {
    lng: -43.3733736,
    lat: -22.8073769
  },
  {
    lng: -43.3736896,
    lat: -22.8074326
  },
  {
    lng: -43.3737006,
    lat: -22.8074342
  },
  {
    lng: -43.3738388,
    lat: -22.8074547
  },
  {
    lng: -43.373961,
    lat: -22.8074727
  },
  {
    lng: -43.3741293,
    lat: -22.8074948
  },
  {
    lng: -43.3742199,
    lat: -22.8075189
  },
  {
    lng: -43.3742844,
    lat: -22.807536
  },
  {
    lng: -43.374509,
    lat: -22.8076412
  },
  {
    lng: -43.3745747,
    lat: -22.8076859
  },
  {
    lng: -43.3748473,
    lat: -22.8078715
  },
  {
    lng: -43.3748502,
    lat: -22.8078736
  },
  {
    lng: -43.3751045,
    lat: -22.8080673
  },
  {
    lng: -43.3751491,
    lat: -22.8081012
  },
  {
    lng: -43.3753511,
    lat: -22.8082476
  },
  {
    lng: -43.3753542,
    lat: -22.8082496
  },
  {
    lng: -43.3755353,
    lat: -22.8083661
  },
  {
    lng: -43.3756077,
    lat: -22.8084081
  },
  {
    lng: -43.3757655,
    lat: -22.8084997
  },
  {
    lng: -43.3758451,
    lat: -22.8085708
  },
  {
    lng: -43.3759247,
    lat: -22.8086419
  },
  {
    lng: -43.3761296,
    lat: -22.8089141
  },
  {
    lng: -43.376183,
    lat: -22.8089849
  },
  {
    lng: -43.3762662,
    lat: -22.8090955
  },
  {
    lng: -43.3763227,
    lat: -22.8091706
  },
  {
    lng: -43.3763568,
    lat: -22.8092787
  },
  {
    lng: -43.3764066,
    lat: -22.8096223
  },
  {
    lng: -43.3764478,
    lat: -22.8099069
  },
  {
    lng: -43.3765125,
    lat: -22.8103229
  },
  {
    lng: -43.3765132,
    lat: -22.8103276
  },
  {
    lng: -43.376546,
    lat: -22.8104849
  },
  {
    lng: -43.3766326,
    lat: -22.8106233
  },
  {
    lng: -43.3767349,
    lat: -22.8107398
  },
  {
    lng: -43.3767676,
    lat: -22.8107637
  },
  {
    lng: -43.3767718,
    lat: -22.8107668
  },
  {
    lng: -43.3768401,
    lat: -22.8108166
  },
  {
    lng: -43.376887,
    lat: -22.8108379
  },
  {
    lng: -43.3769339,
    lat: -22.8108592
  },
  {
    lng: -43.3769965,
    lat: -22.8108706
  },
  {
    lng: -43.3771699,
    lat: -22.8108877
  },
  {
    lng: -43.3778661,
    lat: -22.8108343
  },
  {
    lng: -43.3779509,
    lat: -22.8108258
  },
  {
    lng: -43.3785486,
    lat: -22.8107654
  },
  {
    lng: -43.378663,
    lat: -22.810749
  },
  {
    lng: -43.3794358,
    lat: -22.810638
  },
  {
    lng: -43.379518,
    lat: -22.8106261
  },
  {
    lng: -43.3796062,
    lat: -22.810629
  },
  {
    lng: -43.3796545,
    lat: -22.8106475
  },
  {
    lng: -43.3797124,
    lat: -22.8106758
  },
  {
    lng: -43.3798121,
    lat: -22.8107505
  },
  {
    lng: -43.3798526,
    lat: -22.8107837
  },
  {
    lng: -43.3799325,
    lat: -22.8108627
  },
  {
    lng: -43.3800184,
    lat: -22.8109474
  },
  {
    lng: -43.3800894,
    lat: -22.8109943
  },
  {
    lng: -43.380125,
    lat: -22.8110078
  },
  {
    lng: -43.3801605,
    lat: -22.8110213
  },
  {
    lng: -43.3803026,
    lat: -22.8110554
  },
  {
    lng: -43.3805741,
    lat: -22.8110952
  },
  {
    lng: -43.3807632,
    lat: -22.8111441
  },
  {
    lng: -43.3809522,
    lat: -22.8111606
  },
  {
    lng: -43.3810943,
    lat: -22.811181
  },
  {
    lng: -43.381266,
    lat: -22.8112898
  },
  {
    lng: -43.3814341,
    lat: -22.811396
  },
  {
    lng: -43.3816366,
    lat: -22.811506
  },
  {
    lng: -43.3820387,
    lat: -22.8117094
  },
  {
    lng: -43.3820666,
    lat: -22.8117235
  },
  {
    lng: -43.3821478,
    lat: -22.811742
  },
  {
    lng: -43.3821872,
    lat: -22.811751
  },
  {
    lng: -43.3822286,
    lat: -22.8117604
  },
  {
    lng: -43.3823524,
    lat: -22.8117836
  },
  {
    lng: -43.3825925,
    lat: -22.8118286
  },
  {
    lng: -43.3828452,
    lat: -22.8118751
  },
  {
    lng: -43.3828711,
    lat: -22.8118798
  },
  {
    lng: -43.3830019,
    lat: -22.8119338
  },
  {
    lng: -43.3831587,
    lat: -22.8120308
  },
  {
    lng: -43.3832567,
    lat: -22.8121036
  },
  {
    lng: -43.3834226,
    lat: -22.8122266
  },
  {
    lng: -43.3835704,
    lat: -22.8123176
  },
  {
    lng: -43.3837097,
    lat: -22.8124171
  },
  {
    lng: -43.3837219,
    lat: -22.8124269
  },
  {
    lng: -43.3837743,
    lat: -22.8124691
  },
  {
    lng: -43.3838263,
    lat: -22.8125109
  },
  {
    lng: -43.3839741,
    lat: -22.8126957
  },
  {
    lng: -43.3840788,
    lat: -22.8128867
  },
  {
    lng: -43.3840878,
    lat: -22.8129032
  },
  {
    lng: -43.3842925,
    lat: -22.8132813
  },
  {
    lng: -43.3843096,
    lat: -22.8133243
  },
  {
    lng: -43.384392,
    lat: -22.8135315
  },
  {
    lng: -43.384483,
    lat: -22.8137134
  },
  {
    lng: -43.384484,
    lat: -22.8137147
  },
  {
    lng: -43.3845122,
    lat: -22.813748
  },
  {
    lng: -43.3845597,
    lat: -22.8138041
  },
  {
    lng: -43.3846081,
    lat: -22.8138612
  },
  {
    lng: -43.3847189,
    lat: -22.813938
  },
  {
    lng: -43.3847857,
    lat: -22.8139678
  },
  {
    lng: -43.3848525,
    lat: -22.8139977
  },
  {
    lng: -43.3848545,
    lat: -22.813998
  },
  {
    lng: -43.3849463,
    lat: -22.8140138
  },
  {
    lng: -43.3851609,
    lat: -22.8140507
  },
  {
    lng: -43.3851809,
    lat: -22.8140541
  },
  {
    lng: -43.385246,
    lat: -22.8140653
  },
  {
    lng: -43.3852739,
    lat: -22.8140701
  },
  {
    lng: -43.3854389,
    lat: -22.8140985
  },
  {
    lng: -43.3856417,
    lat: -22.8141334
  },
  {
    lng: -43.3856805,
    lat: -22.81414
  },
  {
    lng: -43.3858427,
    lat: -22.8141679
  },
  {
    lng: -43.3860139,
    lat: -22.8141973
  },
  {
    lng: -43.3860877,
    lat: -22.81421
  },
  {
    lng: -43.3862086,
    lat: -22.8142308
  },
  {
    lng: -43.3862491,
    lat: -22.8142425
  },
  {
    lng: -43.3862726,
    lat: -22.8142493
  },
  {
    lng: -43.3863217,
    lat: -22.8142635
  },
  {
    lng: -43.3863312,
    lat: -22.8142663
  },
  {
    lng: -43.3863471,
    lat: -22.8142709
  },
  {
    lng: -43.3863599,
    lat: -22.8142746
  },
  {
    lng: -43.3863614,
    lat: -22.814275
  },
  {
    lng: -43.3863644,
    lat: -22.8142759
  },
  {
    lng: -43.3863672,
    lat: -22.8142768
  },
  {
    lng: -43.3865934,
    lat: -22.8143481
  },
  {
    lng: -43.3870059,
    lat: -22.8145358
  },
  {
    lng: -43.3871978,
    lat: -22.8146231
  },
  {
    lng: -43.3873815,
    lat: -22.8147028
  },
  {
    lng: -43.3875845,
    lat: -22.8147908
  },
  {
    lng: -43.387701,
    lat: -22.814805
  },
  {
    lng: -43.3878043,
    lat: -22.8147995
  },
  {
    lng: -43.3878062,
    lat: -22.8147994
  },
  {
    lng: -43.3878329,
    lat: -22.8147925
  },
  {
    lng: -43.3879057,
    lat: -22.8147738
  },
  {
    lng: -43.3879858,
    lat: -22.8147174
  },
  {
    lng: -43.3882623,
    lat: -22.8145406
  },
  {
    lng: -43.3883518,
    lat: -22.8144834
  },
  {
    lng: -43.3883698,
    lat: -22.8144719
  },
  {
    lng: -43.3885112,
    lat: -22.8143815
  },
  {
    lng: -43.3885457,
    lat: -22.8143589
  },
  {
    lng: -43.3886571,
    lat: -22.8142859
  },
  {
    lng: -43.3888494,
    lat: -22.8141598
  },
  {
    lng: -43.3888526,
    lat: -22.8141574
  },
  {
    lng: -43.3888621,
    lat: -22.8141505
  },
  {
    lng: -43.3891196,
    lat: -22.8139607
  },
  {
    lng: -43.3891896,
    lat: -22.8139246
  },
  {
    lng: -43.3892597,
    lat: -22.8138884
  },
  {
    lng: -43.3893825,
    lat: -22.8138243
  },
  {
    lng: -43.3896554,
    lat: -22.8137362
  },
  {
    lng: -43.3898691,
    lat: -22.8136719
  },
  {
    lng: -43.3900051,
    lat: -22.813631
  },
  {
    lng: -43.3901217,
    lat: -22.8136367
  },
  {
    lng: -43.3902377,
    lat: -22.813661
  },
  {
    lng: -43.3904532,
    lat: -22.8137689
  },
  {
    lng: -43.3905559,
    lat: -22.8138204
  },
  {
    lng: -43.3910323,
    lat: -22.8132919
  },
  {
    lng: -43.3912072,
    lat: -22.8130972
  },
  {
    lng: -43.3912339,
    lat: -22.8130683
  },
  {
    lng: -43.3920273,
    lat: -22.8121882
  },
  {
    lng: -43.3920951,
    lat: -22.8121129
  },
  {
    lng: -43.3934986,
    lat: -22.810556
  },
  {
    lng: -43.394735,
    lat: -22.8091845
  },
  {
    lng: -43.3948149,
    lat: -22.8090959
  },
  {
    lng: -43.3954039,
    lat: -22.8084424
  },
  {
    lng: -43.3960792,
    lat: -22.8077455
  },
  {
    lng: -43.3962852,
    lat: -22.807533
  },
  {
    lng: -43.3967793,
    lat: -22.8070231
  },
  {
    lng: -43.3968521,
    lat: -22.806948
  },
  {
    lng: -43.3972434,
    lat: -22.8065441
  },
  {
    lng: -43.3972828,
    lat: -22.8065035
  },
  {
    lng: -43.3989021,
    lat: -22.8048324
  },
  {
    lng: -43.3989265,
    lat: -22.8048049
  },
  {
    lng: -43.4001479,
    lat: -22.8034246
  },
  {
    lng: -43.4003788,
    lat: -22.8031637
  },
  {
    lng: -43.4006356,
    lat: -22.8028735
  },
  {
    lng: -43.4010476,
    lat: -22.8024079
  },
  {
    lng: -43.4014873,
    lat: -22.8019111
  },
  {
    lng: -43.4015525,
    lat: -22.8018374
  },
  {
    lng: -43.4016345,
    lat: -22.8017447
  },
  {
    lng: -43.4018672,
    lat: -22.8014817
  },
  {
    lng: -43.402914,
    lat: -22.8003044
  },
  {
    lng: -43.4037718,
    lat: -22.7993483
  },
  {
    lng: -43.4042369,
    lat: -22.7988419
  },
  {
    lng: -43.4055648,
    lat: -22.7973957
  },
  {
    lng: -43.4056303,
    lat: -22.7973244
  },
  {
    lng: -43.4069319,
    lat: -22.7959069
  },
  {
    lng: -43.4069767,
    lat: -22.7958567
  },
  {
    lng: -43.4083686,
    lat: -22.7942968
  },
  {
    lng: -43.4083737,
    lat: -22.794291
  },
  {
    lng: -43.410222,
    lat: -22.7922196
  },
  {
    lng: -43.4102392,
    lat: -22.7922003
  },
  {
    lng: -43.4103423,
    lat: -22.7920848
  },
  {
    lng: -43.4105605,
    lat: -22.7918402
  },
  {
    lng: -43.410708,
    lat: -22.7916749
  }
];

export default polygonCoords_SJMeriti;