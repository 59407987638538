import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#001D5F',
    },
    secondary :{
      main: '#323232', //E5E6F0
    }
  },
});

export default theme;